import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import SearchButton from 'src/components/SearchButton';
import ClearButton from 'src/components/ClearButton';
import PageControl from 'src/components/PageControl';
import AscendSelect from './controls/AscendSelect';
import infoBlueCircle from 'src/assets/images/info-blue-circle.svg';
import arrowDownSvg from 'src/assets/images/ArrowDownGray.svg';
import InputSearch from './controls/inputs/InputSearch';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import ContentLoader from 'react-content-loader';

class AscendDataTable extends React.Component {
  state = {
    menuOpen: false,
    toggleIndex: '',
    headerMenuOpen: false,
  };

  handlerFunction = (operation, objectId, dropdownRef, row) => {
    if (this.props.handlerFunction) {
      this.props.handlerFunction(operation, objectId, dropdownRef, row);
    }
  };

  handlePageChange = (page) => {
    if (this.props.handlePageChange) {
      this.props.handlePageChange(page);
    }
  };

  handleKeyPress = (event) => {
    this.props.handleFilterChange(event);
  };

  handleSortClick = (column) => {
    if (!this.props.handleSortChange) {
      return;
    }
    const {sortBy, sortAscending} = this.props;
    if (column.sortBy) {
      if (sortBy === column.sortBy) {
        this.props.handleSortChange(sortBy, !sortAscending);
      } else {
        this.props.handleSortChange(column.sortBy, true);
      }
    }
  };

  toggleDropdown = (index) => {
    this.setState((prevState) => ({
      toggleIndex: index,
      menuOpen: !prevState.menuOpen,
    }));
  };

  rowIsOpen(index) {
    return this.state.toggleIndex === index;
  }

  BuildSelectDisplay = (props) => {
    const menuOptions = () => {
      let opts = [];
      for (const key in props.options) {
        if (!props.options.hasOwnProperty(key)) {
          continue;
        }
        opts.push(
          <DropdownItem
            key={key}
            tag="div"
            className="clickable fullwidth clickable-dropdown"
            onClick={() => {
              props.onChange(
                isNaN(parseInt(key, 10)) ? key : parseInt(key, 10),
                props.rowId
              );
            }}
          >
            {props.options[key]}
          </DropdownItem>
        );
      }
      return opts;
    };
    if (props.onChange) {
      return (
        <Dropdown
          direction="down"
          className="d-flex justify-content-center align-items-center text-nowrap"
          isOpen={
            this.state.menuOpen &&
            this.rowIsOpen(`${props.displayName}${props.rowId}`)
          }
          toggle={() =>
            this.toggleDropdown(`${props.displayName}${props.rowId}`)
          }
          style={{minWidth: '135px'}}
        >
          <DropdownToggle
            title={`menu${props.rowId}`}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            <span
              className={`nowrap test-svg ${
                props.options[props.value] ? 'tableDropDown' : ''
              }`}
            >
              {props.label}
              <img alt="" src={arrowDownSvg} />
            </span>
          </DropdownToggle>
          <DropdownMenu end={true} className="dropdown-menu-fit-content">
            {menuOptions()}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      return (
        <span className="nowrap">
          {props.options[props.value] ? props.label : ''}
        </span>
      );
    }
  };

  renderContentLoaderHeader = () => {
    return (
      <ContentLoader
        key={'Loader-Header'}
        speed={3}
        height={60}
        width={'100%'}
        backgroundColor="#f1f4f9"
        foregroundColor="#cbe1ff"
        style={{
          backgroundColor: '#ffffff',
        }}
      >
        <rect x="0" y="0" rx="3" ry="0" width="100%" height="60" />
      </ContentLoader>
    );
  };

  renderContentLoaderRows = (index) => {
    return (
      <ContentLoader
        key={`Loader-${index}`}
        speed={3}
        height={60}
        width={'100%'}
        backgroundColor="#f1f4f9"
        foregroundColor="#cbe1ff"
        style={{
          backgroundColor: '#ffffff',
          marginTop: '3px',
        }}
      >
        <rect x="10" y="19" rx="3" ry="3" width="22%" height="22" />
        <rect x="40%" y="22" rx="3" ry="3" width="10%" height="16" />
        <rect x="55%" y="22" rx="3" ry="3" width="10%" height="16" />
        <rect x="70%" y="22" rx="3" ry="3" width="10%" height="16" />
        <rect x="85%" y="22" rx="3" ry="3" width="10%" height="16" />
      </ContentLoader>
    );
  };

  render() {
    const {
      items,
      itemsLoading,
      showSearch,
      showPageControl,
      enableSort,
      currentPage,
      totalItems,
      itemsPerPage,
      helpMessage,
      pullDownList,
      pullDownVisible,
      pullDownValue,
      pullDownOnChange,
      pullDownWidth,
      pullDownDefaultValue,
      headerCenterText,
      pullDownPlaceholder,
      renderExpanded,
      enableExpand,
      isMobile,
    } = this.props;

    const contentLoaders = [];

    contentLoaders.push(this.renderContentLoaderHeader());

    for (var i = 0; i < 10; i++) {
      contentLoaders.push(this.renderContentLoaderRows(i));
    }

    const columnClasses = (columnnum) => {
      if (columnnum === 1) {
        return 'columnpad normal-title';
      } else {
        return 'columnpad secondary-subheader text-center';
      }
    };

    const showGlyph = (sortBy) => {
      if (this.props.sortBy !== '' && this.props.sortBy === sortBy) {
        if (this.props.sortAscending) {
          return this.props.alternateSortImageAsc !== null ? (
            this.props.alternateSortImageAsc
          ) : (
            <i className="nc-icon-glyph-med sortasc" />
          );
        } else {
          return this.props.alternateSortImageDesc !== null ? (
            this.props.alternateSortImageDesc
          ) : (
            <i className="nc-icon-glyph-med sortdec" />
          );
        }
      }
    };

    const showContextMenu = () => {
      return this.props.rowMenuItemList.length > 0;
    };

    return (
      <div>
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          closeOnEsc={true}
          place="top"
        />
        <div className="row padding-bottom-eight">
          <div className="col">
            {showSearch && (
              <div className={'d-flex vertical-center'}>
                <div className="inlineblock padding-right-four max-width-500 col-8">
                  <InputSearch
                    name="filterBy"
                    value={this.props.searchTerm}
                    placeHolder={this.props.searchPlaceholder}
                    onChange={(evt) => {
                      this.props.handleFilterChange(evt, false);
                    }}
                    onEnterKey={this.handleKeyPress}
                    style={{backgroundColor: 'white'}}
                    ariaLabel={`${this.props.searchPlaceholder}`}
                    disabled={itemsLoading}
                  />
                </div>
                <div className="inlineblock padding-right-four mt-auto">
                  <SearchButton
                    onClick={() => {
                      this.props.handleFilterChange(this.props.searchTerm);
                    }}
                    disabled={itemsLoading}
                  />
                </div>
                <div className="inlineblock mt-auto">
                  <ClearButton
                    onClick={() => this.props.handleFilterChange('')}
                    disabled={itemsLoading}
                  />
                </div>
              </div>
            )}
          </div>
          {isMobile === false && headerCenterText && (
            <div className="col-md-4 d-flex justify-content-center">
              <div className="normal-subheader bold ">{headerCenterText}</div>
            </div>
          )}
          {isMobile && <div className="col-md-5" />}
          {!headerCenterText && <div className="col-md-auto" />}
          {pullDownList && pullDownVisible && (
            <div className="row flex-nowrap col">
              <div className={`${headerCenterText ? 'col' : 'col'} pt-2`}>
                <label className="contact-form-label float-right nowrap">
                  {this.props.filterLabel ? `${this.props.filterLabel}:` : ''}
                </label>
              </div>
              <div className="col-auto">
                <AscendSelect
                  ariaLabel={this.props.ariaSelectLabel}
                  label={''}
                  onChange={(e) => pullDownOnChange(e)}
                  options={pullDownList}
                  visible={pullDownVisible}
                  minWidth={pullDownWidth}
                  value={pullDownValue}
                  defaultValue={pullDownDefaultValue}
                  placeholder={pullDownPlaceholder}
                  searchable={true}
                  isDisabled={this.props.itemsLoading}
                />
              </div>
            </div>
          )}

          {helpMessage && (
            <div className="col-md-4">
              <span className="disabled-body padding-top-eight float-right">
                {helpMessage}
              </span>
            </div>
          )}
        </div>
        {!itemsLoading && (
          <table
            style={{textAlign: 'left', height: '100%', width: '100%'}}
            className="bgcolorw"
          >
            <thead>
              <tr key={'rowKey'}>
                {this.props.tableColumns.map((tableColumn, thidx) => {
                  const canSortColumn = tableColumn.sortBy !== undefined;
                  if (tableColumn.displayName === '') {
                    return (
                      <td
                        className="rowbottom"
                        style={{whiteSpace: 'nowrap'}}
                        key={thidx}
                      />
                    );
                  } else if (!enableSort) {
                    return (
                      <th
                        scope="col"
                        style={{
                          whiteSpace:
                            tableColumn.allowWrap === true
                              ? 'normal'
                              : 'nowrap',
                        }}
                        key={thidx}
                        className="rowbottom columnpad normal-body bold text-center"
                        width={tableColumn.minWidth}
                      >
                        {tableColumn.displayName}
                      </th>
                    );
                  } else {
                    const ariaSort =
                      this.props.sortBy === tableColumn.sortBy
                        ? this.props.sortAscending
                          ? 'ascending'
                          : 'descending'
                        : '';
                    return (
                      <th
                        scope="col"
                        aria-sort={ariaSort}
                        style={{
                          whiteSpace: tableColumn.allowWrap
                            ? 'normal'
                            : 'nowrap',
                        }}
                        key={thidx}
                        className="rowbottom columnpad normal-body bold text-center"
                      >
                        <div
                          role={canSortColumn ? 'button' : null}
                          className={canSortColumn ? 'clickable' : null}
                          onClick={() => this.handleSortClick(tableColumn)}
                          onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                              this.handleSortClick(tableColumn);
                              e.preventDefault();
                            }
                          }}
                        >
                          <span tabIndex={canSortColumn ? '0' : null}>
                            {tableColumn.displayName}
                          </span>
                          {tableColumn.tooltipText && (
                            <img
                              style={{width: '14px'}}
                              alt={tableColumn.tooltipText}
                              data-tooltip-id="tooltip"
                              data-tooltip-content={tableColumn.tooltipText}
                              src={infoBlueCircle}
                            />
                          )}
                          {showGlyph(tableColumn.sortBy)}
                        </div>
                      </th>
                    );
                  }
                })}

                {showContextMenu() && items?.length > 0 && (
                  <td className="rowbottom columnpad normal-body bold">
                    {this.props.headerMenuItemList.length === 0 && (
                      <span
                        aria-label="row context menu"
                        className="float-right nowrap"
                      >
                        ACTIONS
                      </span>
                    )}
                    {this.props.headerMenuItemList &&
                      this.props.headerMenuItemList.length > 0 && (
                        <Dropdown
                          aria-label="dropdown menu"
                          direction="down"
                          className="float-right"
                          isOpen={this.state.headerMenuOpen}
                          toggle={() =>
                            this.setState((prev) => ({
                              headerMenuOpen: !prev.headerMenuOpen,
                            }))
                          }
                        >
                          <DropdownToggle
                            title="Header Menu"
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                            }}
                          >
                            <div className="clickable disabled-caption menu-hover">
                              <i className="nc-icon-glyph-med dots gly-rotate-90 menu-hover" />
                            </div>
                          </DropdownToggle>
                          <DropdownMenu
                            end={true}
                            className="dropdown-menu-fit-content"
                          >
                            {this.props.headerMenuItemList.map((item, x) => {
                              return (
                                <DropdownItem
                                  onKeyDown={(e) => {
                                    if (e.key === ' ' || e.key === 'Enter') {
                                      item.handler(item.displayName);
                                    }
                                  }}
                                  key={x}
                                  tag="div"
                                >
                                  <div
                                    className="clickable secondary-subheader fullwidth"
                                    onClick={() =>
                                      item.handler(item.displayName)
                                    }
                                  >
                                    {item.displayName}
                                  </div>
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                  </td>
                )}
              </tr>
            </thead>
            {items?.length === 0 && (
              <caption
                className="bgcolorw columnpad secondary-subheader"
                style={{
                  textAlign: 'center',
                }}
              >
                {this.props.noDataMessage}
              </caption>
            )}
            {this.props.caption && (
              <caption className="visible-hidden">{this.props.caption}</caption>
            )}
            <tbody>
              {items?.map((tableRow, tridx) => {
                const highLighted = (tableRow) => {
                  if (this.props.isRowHighlighted) {
                    return this.props.isRowHighlighted(tableRow);
                  }
                  return false;
                };
                return (
                  <Fragment key={tridx}>
                    <tr
                      className={
                        highLighted(tableRow) ? 'rowHighlight' : 'rowbottom'
                      }
                      key={tridx}
                    >
                      {this.props.tableColumns.map((tableColumn, thidx) => {
                        return (
                          <td key={thidx} className={columnClasses(thidx + 1)}>
                            {tableColumn.showAsSelect
                              ? this.BuildSelectDisplay({
                                  value: tableColumn.content(tableRow).value,
                                  label: tableColumn.content(tableRow).label,
                                  options:
                                    tableColumn.content(tableRow).options,
                                  onChange:
                                    tableColumn.content(tableRow).onChange,
                                  rowId: tableRow.id,
                                  displayName: tableColumn.displayName,
                                })
                              : tableColumn.content(tableRow)}
                          </td>
                        );
                      })}
                      {showContextMenu() && (
                        <td className={columnClasses(2)}>
                          <Dropdown
                            direction="down"
                            className="float-right"
                            isOpen={
                              this.state.menuOpen && this.rowIsOpen(tridx)
                            }
                            toggle={() => this.toggleDropdown(tridx)}
                          >
                            <DropdownToggle
                              title={`Menu`}
                              className="data-table-context-menu"
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                              }}
                            >
                              <div className="clickable disabled-caption">
                                <i
                                  aria-label="row context menu"
                                  className="nc-icon-glyph-med dots gly-rotate-90"
                                />
                              </div>
                            </DropdownToggle>
                            <DropdownMenu
                              end={true}
                              className="dropdown-menu-fit-content"
                            >
                              {this.props.rowMenuItemList
                                .filter((i) => {
                                  return this.props.rowMenuItemListFilter(
                                    i,
                                    tableRow
                                  );
                                })
                                .map((dropdownItemy, dridx) => {
                                  if (
                                    dropdownItemy.isEdit &&
                                    !tableRow.canEdit
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <DropdownItem
                                      key={dridx}
                                      tag="div"
                                      className="clickable fullwidth clickable-dropdown"
                                      onClick={() => {
                                        if (
                                          dropdownItemy.action !== undefined
                                        ) {
                                          dropdownItemy.action(tableRow);
                                        } else {
                                          this.handlerFunction(
                                            dropdownItemy.displayName,
                                            tableRow.id,
                                            tridx,
                                            tableRow
                                          );
                                        }
                                      }}
                                    >
                                      {dropdownItemy.displayName}
                                    </DropdownItem>
                                  );
                                })}
                            </DropdownMenu>
                          </Dropdown>
                        </td>
                      )}
                    </tr>
                    {enableExpand && tableRow[`expanded`] && (
                      <tr key={`subRow${tridx}`}>
                        <td
                          key={`subCol${tridx}`}
                          colSpan={this.props.tableColumns.length + 2}
                        >
                          {renderExpanded(tableRow)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        <span role="status">{itemsLoading && <div>{contentLoaders}</div>}</span>
        <br />
        {showPageControl && (
          <div className="row padding-bottom-eight">
            <div className="col-md-12">
              <div className="d-flex flex-row justify-content-between">
                <div aria-live="assertive" aria-atomic="true">
                  Page {currentPage + 1} of &nbsp;
                  {Math.ceil(totalItems / itemsPerPage)}
                </div>

                <PageControl
                  pageIndex={currentPage}
                  pageCount={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        )}
        <br />
      </div>
    );
  }

  isToggled(rowId) {
    return (
      this.state.toggledRows && this.state.toggledRows.some((x) => x === rowId)
    );
  }
}

AscendDataTable.propTypes = {
  currentPage: PropTypes.number,
  enableSort: PropTypes.bool,
  handleFilterChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  handlerFunction: PropTypes.func,
  handleSortChange: PropTypes.func,
  headerCenterText: PropTypes.string,
  headerMenuItemList: PropTypes.arrayOf(PropTypes.object),
  helpMessage: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsLoading: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  pullDownDefaultValue: PropTypes.object,
  pullDownList: PropTypes.arrayOf(PropTypes.object),
  pullDownOnChange: PropTypes.func,
  pullDownPlaceholder: PropTypes.string,
  pullDownValue: PropTypes.object,
  pullDownVisible: PropTypes.bool,
  pullDownWidth: PropTypes.number,
  rowMenuItemList: PropTypes.arrayOf(PropTypes.object),
  rowMenuItemListFilter: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  searchTerm: PropTypes.string,
  showAsSelect: PropTypes.bool,
  showPageControl: PropTypes.bool,
  showSearch: PropTypes.bool,
  sortAscending: PropTypes.bool,
  sortBy: PropTypes.string,
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalItems: PropTypes.number,
  caption: PropTypes.string,
  renderExpanded: PropTypes.func,
  expandedDataSource: PropTypes.string,
  enableExpand: PropTypes.bool,
  isMobile: PropTypes.bool,
  isRowHighlighted: PropTypes.func,
  noDataMessage: PropTypes.string,
  ariaSelectLabel: PropTypes.string,
  filterLabel: PropTypes.string,
  alternateSortImageAsc: PropTypes.object,
  alternateSortImageDesc: PropTypes.object,
};

AscendDataTable.defaultProps = {
  rowMenuItemList: [],
  headerMenuItemList: [],
  showSearch: true,
  showPageControl: true,
  enableSort: true,
  searchPlaceholder: 'Search...',
  itemsLoading: false,
  currentPage: 0,
  showAsSelect: false,
  pullDownVisible: true,
  rowMenuItemListFilter: () => true,
  enableExpand: false,
  expandedDataSource: 'subRows',
  isMobile: false,
  noDataMessage: 'No Data',
  ariaSelectLabel: 'options to sort table by',
  filterLabel: '',
  alternateSortImageAsc: null,
  alternateSortImageDesc: null,
};

export default AscendDataTable;
