import React from 'react';
import PropTypes from 'prop-types';

import SkillsWidget from '../components/skillTags/SkillsWidget';

class SkillsField extends React.Component {
  render() {
    const {
      input: {value, onChange},
      meta: {touched, error, warning},
    } = this.props;

    const selectedSkills = value || [];
    return (
      <div>
        <div className="padding-bottom-eight">
          <SkillsWidget selectedSkills={selectedSkills} onChange={onChange} />
        </div>
        {touched &&
          ((error && (
            <div className="form-input-error" aria-live="assertive">
              {error}
            </div>
          )) ||
            (warning && (
              <div className="form-input-error" aria-live="assertive">
                {warning}
              </div>
            )) || <div className="form-input-error">&nbsp;</div>)}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    );
  }
}

SkillsField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default SkillsField;
