import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import LoadingCard from './LoadingCard';

class Cardinator extends React.Component {
  buildCards = () => {
    if (this.props.itemsLoading === true) {
      return (
        <div>
          <LoadingCard />
          <br />
        </div>
      );
    } else {
      return this.props.jobs.map((job) =>
        this.props.cardObject(job, this.props.role)
      );
    }
  };

  handleLeftClick = () => {
    if (this.props.currentPage !== 0) {
      this.props.handlePageChange(this.props.currentPage - 1);
    } else {
      const lastPage = Math.ceil(
        this.props.totalItems / this.props.itemsPerPage - 1
      );
      this.props.handlePageChange(lastPage);
    }
  };

  handleRightClick = () => {
    const lastPage = Math.ceil(
      this.props.totalItems / this.props.itemsPerPage - 1
    );
    if (this.props.currentPage !== lastPage) {
      this.props.handlePageChange(this.props.currentPage + 1);
    } else {
      this.props.handlePageChange(0);
    }
  };

  render() {
    const numPages = Math.ceil(this.props.totalItems / this.props.itemsPerPage);

    const displayPages = () => {
      var pagesElements = [];
      if (numPages > 1) {
        pagesElements.push(
          <div
            key="leftClick"
            onClick={this.handleLeftClick}
            className="page-control"
          >
            &#60;
          </div>
        );
        pagesElements.push(
          <div
            key="rightClick"
            onClick={this.handleRightClick}
            className="page-control"
          >
            &#62;
          </div>
        );
      }
      return pagesElements;
    };

    let startIndex = this.props.itemsPerPage * this.props.currentPage + 1;
    let endIndex = startIndex + this.props.itemsPerPage - 1;
    if (endIndex > this.props.totalItems) {
      endIndex = this.props.totalItems;
    }

    return (
      <div>
        {this.buildCards()}
        {this.props.showPageControls && (
          <div className="row padding-bottom-eight">
            <div className="col-md-6">
              <div className="float-left">
                <span className="disabled-body bold">
                  Showing Results: {startIndex} - {endIndex} of{' '}
                  {this.props.totalItems}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="float-right">{displayPages()}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Cardinator.propTypes = {
  jobs: PropTypes.array,
  cardObject: PropTypes.func.isRequired,
  role: PropTypes.string,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  itemsLoading: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func,
  showPageControls: PropTypes.bool,
};

Cardinator.defaultProps = {
  showPageControls: true,
};

const mapStateToProps = (state) => {
  return {
    role: state.profile.role,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Cardinator);
