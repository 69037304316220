import React from 'react';
import PropTypes from 'prop-types';
import WatchIcon from './WatchIcon';
import InvitedIcon from './InvitedIcon';
import {formatDate} from '../utils/miscHelper';
import styles from './SimpleJobCard.module.scss';
import {InView} from 'react-intersection-observer';
import MatchCircle from './MatchCircle';
import classNames from 'classnames';

const SimpleJobCard = (props) => {
  const {
    name,
    team,
    employer,
    matchData,
    showWatchIcon,
    watched,
    invitedDate,
    invitedByName,
    invitedByRole,
    onWatchChanged,
    matchCardStyle,
    viewerType,
    onJobClick,
    onTeamClick,
    onEmployerClick,
    isApprenticeProgram,
    jobId,
    handleVisibilityChange,
  } = props;

  const handleWatchClicked = () => {
    onWatchChanged(!watched);
  };

  const renderMatchWidget = () => {
    if (matchData.matchLevels || matchData.roleRecommendationPercentage) {
      if (viewerType === 'CANDIDATE' || viewerType === 'RECRUITER') {
        return (
          <div>
            <MatchCircle
              viewerType={viewerType}
              matchData={matchData}
              matchCardStyle={matchCardStyle}
              isApprenticeProgram={isApprenticeProgram}
              isCompact
              isVertical
            />
          </div>
        );
      }
    }
    return null;
  };

  const renderInvited = () => {
    if (invitedDate) {
      let invitePhrase = `Invited by ${invitedByName}`;
      return (
        <div className={classNames(['disabled-small', styles.invitedInfo])}>
          {(invitedByRole === 'COMPANY_RECRUITER' ||
            invitedByRole === 'COMPANY_RECRUITER_ADMIN') && <InvitedIcon />}
          <div tabIndex={0}>
            {`${invitePhrase} on ${formatDate(invitedDate)}`}
          </div>
        </div>
      );
    }
    return <div className={styles.placeholderHeight} />;
  };

  return (
    <InView
      threshold={1}
      triggerOnce={true}
      onChange={(inView) => {
        if (handleVisibilityChange) {
          handleVisibilityChange({visible: inView, jobId: jobId});
        }
      }}
    >
      <div className={styles.cardContainer}>
        {renderMatchWidget()}
        <div className={styles.contentContainer}>
          <div className={styles.titleRow}>
            <div
              role="link"
              tabIndex={0}
              className={styles.jobTitle}
              onClick={onJobClick}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  onJobClick();
                }
              }}
            >
              <h3
                className={classNames([
                  'normal-subheader accessibleLink clickable',
                  styles.jobTitleText,
                ])}
              >
                {name}
              </h3>
            </div>
            {showWatchIcon && (
              <WatchIcon
                watched={watched}
                onClick={handleWatchClicked}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleWatchClicked();
                  }
                }}
                className={styles.watchIcon}
              />
            )}
          </div>

          <div
            role="link"
            tabIndex={0}
            className={classNames([
              'secondary-caption accessibleLink clickable',
              styles.teamName,
            ])}
            onClick={onTeamClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                onTeamClick();
              }
            }}
          >
            {team}
          </div>

          <div
            role="link"
            tabIndex={0}
            className={classNames([
              'secondary-caption accessibleLink clickable',
              styles.employerName,
            ])}
            onClick={onEmployerClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                onEmployerClick();
              }
            }}
          >
            {employer}
          </div>

          <div className={styles.bottomRow}>
            <div className={styles.leftSide}>{renderInvited()}</div>
          </div>
        </div>
      </div>
    </InView>
  );
};

SimpleJobCard.propTypes = {
  name: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
  employer: PropTypes.string.isRequired,
  matchData: PropTypes.object.isRequired,
  showWatchIcon: PropTypes.bool,
  watched: PropTypes.bool,
  invitedDate: PropTypes.instanceOf(Date),
  invitedByName: PropTypes.string,
  invitedByRole: PropTypes.string,
  onWatchChanged: PropTypes.func,
  matchCardStyle: PropTypes.object,
  viewerType: PropTypes.string,
  onJobClick: PropTypes.func.isRequired,
  onTeamClick: PropTypes.func.isRequired,
  onEmployerClick: PropTypes.func.isRequired,
  isApprenticeProgram: PropTypes.bool,
  jobId: PropTypes.number,
  handleVisibilityChange: PropTypes.func,
};

SimpleJobCard.defaultProps = {
  showWatchIcon: false,
  matchCardStyle: {},
  viewerType: 'CANDIDATE',
  isApprenticeProgram: false,
};

export default SimpleJobCard;
