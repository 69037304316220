import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';
const StatsDashboard = (props) => {
  const {title, stats, labelPosition, onMouseEnter, onMouseLeave} = props;
  const width = 100 / stats.length;
  return (
    <div>
      <ReactTooltip className="solid-tooltip-dark" closeOnEsc={true} />
      <table
        role="presentation"
        style={{width: '100%'}}
        className="table bgcolorw"
      >
        <tbody>
          {title && (
            <tr>
              <th
                colSpan={stats.length}
                className="bd-darkgraybackground columnpad text-align-center"
              >
                <h2 className="normal-title bold">{title} &nbsp;</h2>
              </th>
            </tr>
          )}
          <tr>
            {stats.map((stat, i) => {
              return (
                <td
                  key={i}
                  style={{
                    width: `${width}%`,
                    verticalAlign: 'top',
                    borderRight:
                      i === stats.length - 1
                        ? ''
                        : '1px solid rgba(0, 78, 125, 0.25)',
                  }}
                  className="columnpad text-align-center"
                >
                  {labelPosition === 'top' && (
                    <div className="disabled-subheader">{stat.label}</div>
                  )}
                  <div
                    onMouseEnter={onMouseEnter && (() => onMouseEnter(stat))}
                    onMouseLeave={onMouseLeave && (() => onMouseLeave(stat))}
                    className="accent-1-display bold nowrap"
                  >
                    {stat.value}
                  </div>
                  {labelPosition === 'bottom' && (
                    <div className="disabled-subheader">{stat.label}</div>
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

StatsDashboard.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string, value: PropTypes.number})
  ).isRequired,
  labelPosition: PropTypes.oneOf(['top', 'bottom']),
  helperText: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

StatsDashboard.defaultProps = {
  labelPosition: 'top',
};

export default StatsDashboard;
