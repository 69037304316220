import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../utils/commonHooks';
import {format} from 'date-fns';
import track from 'react-tracking';
import {track as analyticsTrack, trackVisibility} from 'src/utils/analytics';
import * as analytics from 'src/utils/analytics';
import _ from 'lodash';
import * as jobActions from 'src/actions/jobs';

import WatchIcon from 'src/components/WatchIcon';
import Spotlight from 'src/components/Spotlight';
import JobPreferences from 'src/components/JobPreferences';
import {
  AscendModal,
  AscendModalHeader,
  AscendModalBody,
  AscendModalFooter,
} from 'src/layout';
import SkillsCard from 'src/components/jobProfile/SkillsCard';
import IsDraftInModal from 'src/components/IsDraftInModal';
import {fixUrl} from 'src/utils/fieldHelper';
import * as degreeTypeRankActions from '../actions/DegreeTypeActions';
import medical from '../assets/images/Medical.svg';
import parentalSvg from '../assets/images/Parental.svg';
import smileySvg from '../assets/images/Smiley.svg';
import retirementSvg from '../assets/images/Retirement.svg';
import educationSvg from '../assets/images/Education.svg';
import stocksSvg from '../assets/images/Stocks.svg';
import dentalSvg from '../assets/images/Dental.svg';
import vacationSvg from '../assets/images/Vacation.svg';
import visionSvg from '../assets/images/Vision.svg';
import lifeSvg from '../assets/images/Life.svg';
import styles from './JobProfile.module.scss';
import {getJobCache} from 'src/services/cacheApi';

import * as codesActions from '../actions/invitationCodes';
import * as flashActions from '../actions/flash';
import {getWageRangeText} from 'src/utils/wageHelper';
import {track as mixPanelTrack} from 'src/utils/analytics';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {addressToString, getGoogleMapUrl} from 'src/utils/miscHelper';
import {getAllApprenticePathways} from 'src/actions/apprenticePathwaysActions';
import {ErrorSvg} from '../assets/svg/svgComponents';
import ReportIssueModal from 'src/components/ReportIssueModal';
import JobActionsMenu from './JobActionsMenu';
import LastUpdated from './LastUpdated';

@track(
  (props) => {
    const data = {
      eventName: 'JobProfile',
    };
    if (props.job) {
      data.jobId = props.job.id;
      data.jobName = props.job.name;
      data.jobTeamId = props.job.teamId;
      data.jobTeamName = props.job.team.name;
      data.jobEmployerId = props.job.employerId;
      data.jobEmployerName = props.job.employer.name;
      data.jobEmploymentType = props.job.employmentTypes
        .map((x) => x.name)
        .join(',');
    }
    return data;
  },
  {dispatchOnMount: true}
)
class JobProfile extends React.Component {
  jobActionsRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      job: props.job,
      isMaSegment: props.job.segments.some((x) => x.isApprenticeProgram),
    };
  }

  componentDidMount() {
    if (
      !this.props.degreeTypeRanksLoading &&
      this.props.degreeTypeRank.length === 0
    ) {
      this.props.getDegreeTypesRank();
    }
    if (this.props.role === 'JOB_SEEKER') {
      trackVisibility({
        seekerId: this.props.seekerId,
        jobId: this.props.job.id,
        eventName: 'ROLE_MODAL_OPENED',
      });
    }

    if (
      !this.props.apprenticePathwaysLoading &&
      this.props.pathways.length === 0
    ) {
      this.props.getAllApprenticePathways();
    }
    this.props.getJobForEdit(this.props.job.id);
  }

  handleWatchClicked = () => {
    if (!this.props.watched) {
      mixPanelTrack({
        eventName: 'JobFavorited',
        jobId: this.props.job.id,
        jobEmploymentTypes: this.props.job.employmentTypes
          .map((x) => x.name)
          .join(','),
      });
    }
    this.props.onWatchChanged(!this.props.watched);
  };

  handleAppliedClicked = () => {
    if (this.props.applied) {
      getJobCache(this.props.job.id).then((resp) => {
        analytics.track({
          eventName: "I'veAppliedClicked",
          ...resp.data,
        });
      });
    }
    this.props.onAppliedChanged(!this.props.applied);
  };

  handleEmployerClicked = (id) => {
    this.props.history.push('/employer/' + id);
  };

  handleTeamClicked = (id) => {
    this.props.history.push('/team/' + id);
  };

  handleAppLinkClicked = async () => {
    const {job, history} = this.props;
    const {isMaSegment} = this.state;

    getJobCache(job.id).then((resp) => {
      analyticsTrack({
        eventName: `RoleApplicationLinkClicked`,
        ...resp.data,
      });

      if(this.props.role === 'JOB_SEEKER'){
        trackVisibility({
          seekerId: this.props.seekerId,
          jobId: this.props.job.id,
          eventName: 'APPLY_TO_THIS_ROLE_CLICK',
        });
      }
      
    });

    if (isMaSegment) {
      history.push(`/jobs/apply/${job.id}`);
    } else {
      await this.props.applyToClicked(job.id);
      window.open(fixUrl(job.url));
    }
  };

  getDegreeType = (job) => {
    let degreeRank = this.props.degreeTypeRank.find(
      (x) => x.id === job.degreeTypeRankId
    );
    return degreeRank && degreeRank.name;
  };

  handleReportIssueClick = () => {
    this.setState({
      reportIssueModalIsOpen: true,
    });
  };

  render() {
    const {job} = this.props;
    const {isMaSegment} = this.state;

    if (!job.id) {
      return <div />;
    }

    const renderItem = (content) => {
      return content || 'Not selected';
    };

    const renderBenefits = () => {
      if (this.state.isMaSegment === true) return null;
      if (_.some(job.employmentTypes, {name: 'Full-Time Job'}))
        return (
          <div>
            <div className="normal-subheader bold padding-bottom-sixteen">
              Employee Benefits
            </div>
            {job.medicalInsurance && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={medical} />
                &nbsp;&nbsp;Medical Insurance
              </div>
            )}
            {job.stockOptions && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={stocksSvg} />
                &nbsp;&nbsp;Stock Options
              </div>
            )}
            {job.dentalCoverage && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={dentalSvg} />
                &nbsp;&nbsp;Dental Coverage
              </div>
            )}
            {job.paidVacation && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={vacationSvg} />
                &nbsp;&nbsp;Paid Time Off
              </div>
            )}
            {job.visionCoverage && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={visionSvg} />
                &nbsp;&nbsp;Vision Coverage
              </div>
            )}
            {job.paidParentalLeave && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={parentalSvg} />
                &nbsp;&nbsp;Paid Parental Leave
              </div>
            )}
            {job.lifeInsurance && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={lifeSvg} />
                &nbsp;&nbsp;Life Insurance
              </div>
            )}
            {job.flexibleWorkEnvironment && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={smileySvg} />
                &nbsp;&nbsp;Flexible Work Environment
              </div>
            )}
            {job.retirementPlans && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={retirementSvg} />
                &nbsp;&nbsp;Retirement Plans
              </div>
            )}
            {job.educationalAssistance && (
              <div className="secondary-subheader extraspace">
                <img alt="" src={educationSvg} />
                &nbsp;&nbsp;Education Assistance
              </div>
            )}
          </div>
        );
      return <div />;
    };

    const jobWatch = () => {
      if (this.state.isMaSegment === true) return null;
      if (this.props.role === 'JOB_SEEKER') {
        return (
          <div className="padding-top-two">
            <WatchIcon
              whiteHeart={true}
              floatRight={false}
              className=""
              watched={this.props.watched}
              onClick={this.handleWatchClicked}
            />
          </div>
        );
      }
      return <div />;
    };

    const alreadyApplied = () => {
      if (this.props.role === 'JOB_SEEKER' && !this.state.isMaSegment) {
        return (
          <div className="inlineblock padding-top-eight padding-bottom-eight custom-control custom-checkbox form-check">
            <input
              type="checkbox"
              id="appliedJobCheckbox"
              checked={this.props.applied}
              onChange={this.handleAppliedClicked}
              className="custom-control-input form-check-input checkbox-formatting"
            />
            <label
              className="normal-subheader-white bold custom-control-label form-check-label"
              htmlFor="appliedJobCheckbox"
            >
              I've Applied
            </label>
          </div>
        );
      }
      return <div />;
    };

    const reportIssue = () => {
      return (
        <button
          className={`${styles.reportIssue_link_modal}`}
          onClick={() => this.handleReportIssueClick()}
        >
          <ErrorSvg
            className={`${styles.reportIssue_stroke_white}`}
            width="15px"
          />
          &nbsp;
          <span className="underline normal-subheader-white">
            Report an Issue
          </span>
        </button>
      );
    };

    const showDivider = () => {
      if (
        this.props.job.applicationDeadline &&
        this.props.role === 'JOB_SEEKER'
      ) {
        return (
          <div className="inlineblock padding-top-eight">
            <div className="normal-title-white">
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        );
      } else {
        return <div className="inlineblock padding-top-eight" />;
      }
    };

    const showDeadline = () => {
      if (this.props.job.applicationDeadline) {
        var dateString = format(
          new Date(this.props.job.applicationDeadline),
          'MM/dd/yyyy'
        );
        return (
          <div className="inlineblock padding-top-eight">
            <div className="normal-body-white bold">Deadline: {dateString}</div>
          </div>
        );
      }
    };

    const shouldDisplaySpotlight = () => {
      if (this.state.isMaSegment === true) return null;
      const {job} = this.props;
      return job.showcaseName && job.showcaseInfo && job.showcaseTitle;
    };

    const shouldDisableApply = () => {
      const COMPLETE_AND_APPROVED = 9;

      if (isMaSegment) {
        return (
          this.props.profileStatusId !== COMPLETE_AND_APPROVED ||
          this.props.termsAccepted !== true
        );
      }

      return false;
    };

    const renderDraftInModal = (job) => {
      if (job && job.id && !job.published) {
        return (
          <IsDraftInModal
            titleString="You're viewing a draft of your team's role."
            approveClick={this.jobActionsRef.current?.handlePublish}
          />
        );
      }
    };

    const renderLocation = (job) => {
      let isRemote = false;
      if (job.remoteStatus === 'Remote') {
        isRemote = true;
      }
      const showAddress = isRemote || job.addressLine1;
      if (!showAddress) return '';
      return (
        <div className="secondary-subheader extraspace">
          <i className="nc-icon-glyph pin darkgray" />
          &nbsp;&nbsp;
          {isRemote && 'Remote'}
          {job.addressLine1 && !isRemote && (
            <a
              className="underline"
              href={getGoogleMapUrl(job)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {addressToString(job)}
            </a>
          )}
        </div>
      );
    };

    return (
      <>
        <JobActionsMenu
          job={job}
          onClose={this.props.onClose}
          ref={this.jobActionsRef}
        />

        <ReportIssueModal
          job={job}
          seekerId={this.props.seeker.id}
          isOpen={this.state.reportIssueModalIsOpen}
          onClose={() => {
            this.setState({
              reportIssueModalIsOpen: false,
            });
          }}
        />

        <AscendModal isOpen={this.props.isOpen} onClose={this.props.onClose}>
          <AscendModalHeader
            headerClass="bd-modalheader"
            hasClose={true}
            hasMenu={
              this.props.role === 'ASCEND_RECRUITER' ||
              this.props.role === 'ADMIN' ||
              this.props.role === 'SEGMENT_ADMIN' ||
              this.props.role === 'EMPLOYER_MANAGER'
            }
            isOpen={this.props.isOpen}
            menuList={this.jobActionsRef?.current?.popUpMenu}
          >
            {jobWatch()}
            <div className={`${styles.headerContent}`}>
              <h1
                style={{fontSize: '24px'}}
                className="normal-headline-white bold"
              >
                {job.name}
              </h1>
              <div className="normal-subheader-white clickable">
                <button
                  className={`inlineblock btn ${styles.btnPadding} ${styles.btnWhiteText}`}
                  onClick={() => this.handleEmployerClicked(job.employerId)}
                >
                  {job.employer.name},
                </button>
                <button
                  className={`inlineblock btn ${styles.btnPadding} ${styles.btnWhiteText}`}
                  onClick={() => this.handleTeamClicked(job.teamId)}
                >
                  &nbsp;{job.team.name}
                </button>
              </div>
              <br />
              {(!isMaSegment || (isMaSegment && !this.props.applied)) && (
                <Fragment>
                  <ReactTooltip
                    id="apply-tooltip"
                    className="solid-tooltip-dark"
                    closeOnEsc={true}
                  />
                  <div
                    data-tooltip-id="apply-tooltip"
                    data-tooltip-hidden={!shouldDisableApply()}
                    data-tooltip-place="bottom"
                    data-tooltip-html={
                      'To apply to this role, you must first<br/> complete the steps listed on your<br/> home page.'
                    }
                  >
                    <button
                      type="button"
                      style={{textDecoration: 'none'}}
                      className={`${
                        shouldDisableApply() ? '' : 'clickable'
                      } btn btn-primary`}
                      onClick={() => this.handleAppLinkClicked(job.url)}
                      disabled={shouldDisableApply()}
                    >
                      Apply To This Role!
                    </button>
                  </div>
                </Fragment>
              )}

              <div>
                {showDeadline()}
                {showDivider()}
                {alreadyApplied()}
                {reportIssue()}
              </div>
            </div>
            {(this.props.role === 'COMPANY_RECRUITER' ||
              this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
              this.props.role === 'ASCEND_RECRUITER') &&
              renderDraftInModal(job)}
          </AscendModalHeader>

          <AscendModalBody>
            <div className="padding-32">
              <div className="row">
                <div className="col-lg-3">
                  <JobPreferences
                    job={job}
                    role={this.props.role}
                    degreeType={this.getDegreeType(job)}
                  />
                </div>
                <div className="col-lg-9">
                  <div className="row padding-bottom-sixteen">
                    <div className="col-lg-12">
                      <h2
                        style={{fontSize: '16px'}}
                        className="normal-subheader padding-bottom-eight bold"
                      >
                        What you will do:
                      </h2>
                      <div className="disabled-subheader">{job.info1}</div>
                    </div>
                  </div>
                  <div className="row padding-bottom-sixteen">
                    <div className="col-lg-12">
                      <h2
                        style={{fontSize: '16px'}}
                        className="normal-subheader padding-bottom-eight bold"
                      >
                        What you will learn:
                      </h2>
                      <div className="disabled-subheader">{job.info2}</div>
                    </div>
                  </div>
                  {isMaSegment === false && (
                    <SkillsCard
                      skills={job.skillTags.map((tag) => tag.name)}
                      requiredSkills={job.requiredSkillTags.map(
                        (tag) => tag.name
                      )}
                    />
                  )}
                  <br />
                  {shouldDisplaySpotlight() && (
                    <Spotlight
                      avatarUrl={job.showcaseAvatarUrl}
                      headline="Employee Spotlight"
                      name={job.showcaseName}
                      title={job.showcaseTitle}
                      info={job.showcaseInfo}
                    />
                  )}
                </div>
              </div>
            </div>
          </AscendModalBody>

          <AscendModalFooter footerClass="bd-darkgraybackground">
            <div className="padding-32">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="normal-subheader padding-bottom-sixteen bold">
                    Other Details
                  </h2>
                  <button
                    className={`secondary-subheader extraspace clickable btn ${styles.btnPadding}`}
                    onClick={() => this.handleTeamClicked(job.teamId)}
                  >
                    <i className="nc-icon-glyph users_multiple darkgray" />
                    &nbsp;&nbsp;{job.team.name} Team
                  </button>
                  <div className="secondary-subheader extraspace">
                    <i className="nc-icon-glyph database darkgray" />
                    &nbsp;&nbsp;
                    {renderItem(
                      job.employmentTypes.map((x) => x.name).join(', ')
                    )}
                  </div>

                  {renderLocation(job)}
                  <div className="secondary-subheader extraspace">
                    <i className="nc-icon-glyph business_money-bag darkgray" />
                    &nbsp;&nbsp;
                    {renderItem(getWageRangeText(job, true))}
                  </div>
                  <LastUpdated
                    svgClassName="svg_color_stroke_darkgray"
                    textClassName="secondary-subheader extraspace"
                    publishedDate={job.publishedAt}
                  />
                </div>
                <div className="col-lg-6">{renderBenefits()}</div>
              </div>
            </div>
          </AscendModalFooter>
        </AscendModal>
      </>
    );
  }
}

JobProfile.propTypes = {
  history: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onWatchChanged: PropTypes.func,
  onAppliedChanged: PropTypes.func,
  watched: PropTypes.bool,
  applied: PropTypes.bool,
  matchData: PropTypes.object,
  role: PropTypes.string,
  degreeTypeRank: PropTypes.array,
  getDegreeTypesRank: PropTypes.func.isRequired,
  degreeTypeRanksLoading: PropTypes.bool,
  permissions: PropTypes.array.isRequired,
  createInvitationCodeAsync: PropTypes.func.isRequired,
  setEditInvitationCode: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func,
  applyToClicked: PropTypes.func,
  seekerId: PropTypes.string,
  seeker: PropTypes.object,
  getAllApprenticePathways: PropTypes.func.isRequired,
  termsAccepted: PropTypes.bool,
  profileStatusId: PropTypes.number,
  pathways: PropTypes.array,
  apprenticePathwaysLoading: PropTypes.bool,
  getJobForEdit: PropTypes.func.isRequired,
  jobForEdit: PropTypes.object,
};

JobProfile.defaultProps = {
  isOpen: false,
  watched: false,
  applied: false,
};

const mapStateToProps = (state) => {
  return {
    role: state.profile.role,
    seekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    degreeTypeRank: state.degreeTypeRank.degreeRank,
    degreeTypeRanksLoading: state.degreeTypeRank.degreeRankLoading,
    permissions: state.profile.permissions,
    termsAccepted: state.seekers.seeker.termsAccepted,
    profileStatusId: state.seekers.seeker.profileStatusId,
    pathways: state.apprenticePathways.apprenticePathways,
    apprenticePathwaysLoading:
      state.apprenticePathways.apprenticePathwaysLoading,
    jobForEdit: state.jobs.jobForEdit,
  };
};

const mapDispatchToProps = {
  ...jobActions,
  ...degreeTypeRankActions,
  ...codesActions,
  ...flashActions,
  getAllApprenticePathways,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobProfile)
);
