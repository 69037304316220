import request from 'superagent';
import * as flashActions from '../actions/flash';
import NetworkAuthenticationService from 'src/services/NetworkAuthenticationService';
import {types as authActions} from 'src/reducers/authentication';

const authService = new NetworkAuthenticationService(null, null);

export default function api({dispatch, getState}) {
  return (next) => (action) => {
    if (!isApiCall(action)) {
      //Normal action: pass it on
      return next(action);
    }

    const {
      types,
      uri,
      method,
      body,
      payload = {},
      isAuthenticated = true,
      successMessage,
      failureMessage,
      callback,
    } = action;

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every((type) => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof uri !== 'string') {
      throw new Error('Expected uri to be a string.');
    }

    let token = localStorage.getItem('id_token');

    const [requestType, successType, failureType] = types;

    dispatch({payload, type: requestType});

    let req = request(method, uri);

    if (isAuthenticated && !authService.isAuthenticated()) {
      dispatch({type: authActions.LOGOUT_USER});
      return;
    }

    if (isAuthenticated) {
      req = req.set('Authorization', token);
    }

    // Add build number to headers to identify web version to api
    if (process.env.REACT_APP_VERSION) {
      req = req.set('X-Ai-App-Version', process.env.REACT_APP_VERSION);
    }

    if (body) {
      req = req.send(body);
    }

    return req.end((err, result) => {
      return new Promise((resolve, reject) => {
        if (err) {
          if (err.status === 409) {
            let msg =
              err.response.body.message ||
              'Update Failed: The version loaded on your screen is out of date with the server. Please refresh the page and resubmit to update this record.';
            dispatch(flashActions.addFlashMessage(msg));
            reject(msg);
            return;
          }
          if (failureMessage) {
            dispatch(flashActions.addFlashMessage(failureMessage));
          }
          let error = err;
          if (result && result.body && result.body.error) {
            error = result.body.error;
          }
          dispatch({payload, error: error, type: failureType});
          reject(error);
        } else {
          if (successMessage) {
            dispatch(
              flashActions.addFlashMessage(successMessage, 'notification')
            );
          }
          dispatch({payload, response: result, type: successType});

          // Call the callback function before resolving the promise
          if (typeof callback === 'function') {
            callback({result: result});
          }
          resolve();
        }
      });
    });
  };
}

export function isApiCall(action) {
  const {types} = action;
  return !!types;
}
