import * as types from '../actions/ActionTypes';

const initialState = {
  selectedSkillTags: [],
};

export default function signup(state = initialState, action) {
  switch (action.type) {
    case types.SIGNUP__SELECTED_SKILL_TAGS_CHANGED:
      return {...state, selectedSkillTags: action.selectedSkillTags};

    default:
      return state;
  }
}
