import React from 'react';
import {Link} from 'react-router-dom';
import DocumentTitle from '../components/vendor/DocumentTitle';

class NotFoundPage extends React.Component {
  render() {
    return (
      <DocumentTitle title="Not Found">
        <div className="fullindy">
          <div className="container">
            <br />
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div>
                  <div className="bd-bluebackground">
                    <center>
                      <br />
                      <div className="normal-jumbo-white bold">Not Found</div>
                      <br />
                    </center>
                  </div>
                  <div className="bd-whitebackground">
                    <div className="row">
                      <div className="col-md-2" />
                      <div className="col-md-8">
                        <br />
                        <div className="normal-title text-align-center">
                          The page you are looking for doesn't exist.
                          <br />
                          <br />
                          <Link
                            className="clickable btn btn-primary"
                            to="/home"
                          >
                            Return home
                          </Link>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-2" />
        </div>
      </DocumentTitle>
    );
  }
}

export default NotFoundPage;
