import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import styles from './NewStatsDashboard.module.scss';
import Twisty from './Twisty';
import TimelineButton from './TimelineButton';
import LoadingOverlay from 'react-loading-overlay';

import AscendSelect from './controls/AscendSelect';

const NewStatsDashboard = ({
  title,
  stats,
  dateSetHandler,
  statsLoading,
  pullDownList,
  pullDownWidth,
  pullDownOnChange,
  pullDownValue,
  pullDownDefaultValue,
  pullDownVisible,
  pullDownPlaceholder,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleTwistyClicked = () => {
    setCollapsed(!collapsed);
  };

  return (
    <LoadingOverlay
      role="status"
      active={statsLoading}
      spinner={true}
      text="Loading..."
      styles={{
        overlay: (base) => ({
          ...base,
        }),
      }}
    >
      <div>
        <ReactTooltip className="solid-tooltip-dark" closeOnEsc={true} />
        <div style={{width: '100%'}} className={` ${styles.statsBlock}`}>
          <div className={styles.titleRow}>
            {title && (
              <>
                <div
                  style={{
                    paddingTop: '5px',
                    width: '100%',
                  }}
                >
                  <span
                    colSpan={stats.length}
                    className="bd-graybackground text-align-center"
                  >
                    <div className={`normal-title bold ${styles.title}`}>
                      <button
                        aria-expanded={collapsed ? 'false' : 'true'}
                        style={{
                          paddingTop: '5px',
                        }}
                        onClick={handleTwistyClicked}
                        className="buttonAsLinkTeamCard d-flex"
                      >
                        <Twisty
                          isDown={collapsed}
                          text={title}
                          headerStyles={'normal-title bold'}
                          imageStyle={{marginTop: '7px'}}
                          isH2={true}
                        />
                      </button>
                    </div>
                  </span>
                </div>

                {!collapsed && pullDownList && (
                  <div
                    style={{
                      height: '40px',
                      paddingTop: '11px',
                      marginLeft: 'auto',
                    }}
                  >
                    <AscendSelect
                      onChange={(e) => pullDownOnChange(e)}
                      options={pullDownList}
                      visible={pullDownVisible}
                      minWidth={pullDownWidth}
                      value={pullDownValue}
                      defaultValue={pullDownDefaultValue}
                      placeholder={pullDownPlaceholder}
                    />
                  </div>
                )}

                {!collapsed && (
                  <TimelineButton
                    dateSetHandler={(s, e) => {
                      // setLoading(true);
                      e ? dateSetHandler(s, e) : dateSetHandler(s);
                    }}
                    style={{
                      paddingTop: '5px',
                      marginLeft: 'auto',
                    }}
                  />
                )}
              </>
            )}
          </div>
          {!collapsed && (
            <div className={styles.statRow}>
              {stats.map((stat, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderRight:
                        i === stats.length - 1
                          ? ''
                          : '1px solid rgba(0, 78, 125, 0.25)',
                      gridColumn: `${i + 1}`,
                      gridRow: '1',
                    }}
                    className={`tightcolumnpad text-align-center bgcolorw ${styles.stat}`}
                    tabIndex={0}
                  >
                    <div className="accent-1-display bold">{stat.value}</div>
                    <div className="disabled-subheader">{stat.label}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

NewStatsDashboard.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string, value: PropTypes.number})
  ).isRequired,
  dateSetHandler: PropTypes.func.isRequired,
  statsLoading: PropTypes.bool.isRequired,
  pullDownDefaultValue: PropTypes.object,
  pullDownList: PropTypes.arrayOf(PropTypes.object),
  pullDownOnChange: PropTypes.func,
  pullDownPlaceholder: PropTypes.string,
  pullDownValue: PropTypes.object,
  pullDownVisible: PropTypes.bool,
  pullDownWidth: PropTypes.number,
};

export default NewStatsDashboard;
