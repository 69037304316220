import React, {useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {AscendModal, AscendModalBody} from '../../layout';
import PropTypes from 'prop-types';
import styles from './ChangePassword.module.scss';
import LoadingOverlay from 'react-loading-overlay';
import {makeRequestAsync} from 'src/services/api';

const PasswordInput = (props) => {
  return (
    <>
      <input
        id={props.input.name}
        type="password"
        className="form-control normal-subheader"
        size={36}
        value={props.input.value}
        {...props.input}
        {...props}
      />
      {props.meta.touched &&
        ((props.meta.error && (
          <div className="form-input-error">{props.meta.error}</div>
        )) ||
          (props.meta.warning && (
            <div className="form-input-error">{props.meta.warning}</div>
          )) || <div className="form-input-error">&nbsp;</div>)}
      {!props.meta.touched && <div className="form-input-error">&nbsp;</div>}
    </>
  );
};

PasswordInput.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
};

export default function ChangePasswordModal(params) {
  const formRef = useRef(null);

  const [inputValid, setInputValid] = useState(false);
  const [invalidOldPasswordError, setInvalidOldPasswordError] = useState('');
  const [passwordChangeFailed, setPasswordChangeFailed] = useState(false);
  const [passwordChangeSucceeded, setPasswordChangeSucceeded] = useState(false);
  const [changePasswordSubmitting, setChangePasswordSubmitting] =
    useState(false);

  const passwordOk = (password) => {
    if (!password) return false;
    if (password.length < 8) return false;
    let count = 0;
    if (/[a-z]/.test(password)) count++;
    if (/[A-Z]/.test(password)) count++;
    if (/[0-9]/.test(password)) count++;
    // eslint-disable-next-line no-useless-escape
    if (/[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]/.test(password)) count++;
    return count >= 3;
  };

  const validate = (values) => {
    const errors = {};

    if (Object.keys(values).length > 0) {
      const requiredFields = ['password', 'confirm', 'oldPassword'];

      if (!passwordOk(values.password)) {
        errors.password = 'Weak password';
      }
      if (values.password !== values.confirm) {
        errors.confirm = 'Passwords must match';
      }

      requiredFields.forEach((field) => {
        if (!values[field] || values[field] === '') {
          errors[field] = 'Required';
        }
      });

      setInputValid(Object.keys(errors).length === 0);
    }

    if (
      invalidOldPasswordError &&
      !formRef.current.getState().dirtyFieldsSinceLastSubmit['oldPassword'] &&
      !changePasswordSubmitting
    ) {
      errors['oldPassword'] = invalidOldPasswordError;
    }

    return errors;
  };

  const submitPassword = async (values) => {
    setChangePasswordSubmitting(true);

    let path = `users/changePassword`;
    const data = {password: values.password, oldPassword: values.oldPassword};

    try {
      const response = await makeRequestAsync('POST', path, data, true);

      if (response.status === 200) {
        setPasswordChangeSucceeded(true);
        setChangePasswordSubmitting(false);
      }
    } catch (err) {
      setChangePasswordSubmitting(false);

      if (err.response) {
        if (
          err.response.status === 400 &&
          err.response.data === 'Current password is incorrect'
        ) {
          setInvalidOldPasswordError(err.response.data);
          return;
        }
      }

      setPasswordChangeFailed(true);
      return err;
    }
  };

  const closeForm = () => {
    setInputValid(false);
    setInvalidOldPasswordError('');
    setPasswordChangeFailed(false);
    setPasswordChangeSucceeded(false);
    setChangePasswordSubmitting(false);
    params.onClose();
  };

  return (
    <AscendModal isOpen={params.isOpen} onClose={closeForm} size={'m'}>
      <AscendModalBody hasClose={true} padClose={false}>
        <Form onSubmit={submitPassword} validate={validate}>
          {({handleSubmit, form}) => {
            formRef.current = form;
            return (
              <LoadingOverlay
                role="status"
                active={changePasswordSubmitting}
                spinner={true}
                text="Saving..."
                fadeSpeed={200}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    position: 'fixed',
                  }),
                }}
              >
                <form>
                  {!passwordChangeSucceeded && !passwordChangeFailed && (
                    <div className={`form ${styles.changePasswordContainer}`}>
                      <div className={`${styles.changePasswordHeader}`}>
                        Update your password
                      </div>
                      <div className="normal-caption">
                        Passwords must comply with these rules:
                        <ul>
                          <li>At least 8 characters</li>
                          <li>
                            Contain at least 3 of the following
                            <ul>
                              <li>Lower case letters (a-z)</li>
                              <li>Upper case letters (A-Z)</li>
                              <li>Numbers (0-9)</li>
                              <li>Special characters (!@#$%^&*)</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="form-group">
                        <label
                          className={`secondary-body ${styles.changePasswordFieldLabel}`}
                        >
                          Old password
                        </label>
                        <Field
                          name="oldPassword"
                          label="Old Password"
                          component={PasswordInput}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          className={`secondary-body ${styles.changePasswordFieldLabel}`}
                        >
                          New password
                        </label>
                        <Field
                          name="password"
                          label="Password"
                          component={PasswordInput}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          className={`secondary-body ${styles.changePasswordFieldLabel}`}
                        >
                          Confirm new password
                        </label>
                        <Field
                          name="confirm"
                          label="Confirm Password"
                          component={PasswordInput}
                        />
                      </div>

                      <div className="row float-right">
                        <button
                          type="button"
                          className={`btn btn-secondary col-3 ${styles.changePasswordButton}`}
                          onClick={closeForm}
                        >
                          Cancel
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          onClick={handleSubmit}
                          className={`btn btn-primary col-3 ${styles.changePasswordButton}`}
                          disabled={!inputValid}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}

                  {passwordChangeSucceeded && (
                    <div className={`${styles.changePasswordContainer}`}>
                      <center>
                        <p>
                          <span class="bold">Success!</span>
                          <br />
                          Your password has been changed.
                        </p>
                      </center>

                      <div className="row justify-content-center">
                        <button
                          type="button"
                          className={'clickable btn btn-secondary'}
                          onClick={closeForm}
                        >
                          Return to the Network
                        </button>
                      </div>
                    </div>
                  )}

                  {passwordChangeFailed && (
                    <div className={`${styles.changePasswordContainer}`}>
                      <center>
                        <p>
                          There was an error attempting to save your new
                          password. Please try again.
                        </p>
                        <p>
                          If you continue to experience this error, email{' '}
                          <a
                            style={{textDecoration: 'underline'}}
                            href="mailto:support@ascendindiana.com"
                          >
                            support@ascendindiana.com
                          </a>
                          .
                        </p>
                      </center>

                      <div className="row justify-content-center">
                        <button
                          type="button"
                          className={'clickable btn btn-secondary'}
                          onClick={closeForm}
                        >
                          Return to the Network
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </LoadingOverlay>
            );
          }}
        </Form>
      </AscendModalBody>
    </AscendModal>
  );
}

ChangePasswordModal.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  changePasswordSuccess: PropTypes.bool,
  submitPassword: PropTypes.func,
};
