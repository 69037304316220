import merge from 'deepmerge';
import _ from 'lodash';

import * as types from '../actions/ActionTypes';

const initialState = {
  jobs: [],
  teams: [],
  jobsLoading: false,
  jobsLoaded: false,
  teamsLoading: false,
  allRolesSelected: false,
  myMatchesSelected: true,
  workStyleMatchesSelected: false,
  overallFit: true,
  invitedRolesSelected: false,
  favoriteRolesSelected: false,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  jobsTotalItems: 0,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  teamsTotalItems: 0,
  jobsSortTerm: '',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  roleTypeIds: [],
  employmentTypeIds: [],
  experienceLevelIds: [],
  regionIds: [],
  industryIds: [],
  degreeTypeRankIds: [],
  segmentIds: [],
  jobCategoryTags: [],
  remoteStatus: {},
  apprenticePathwayIds: [],
  hasViewedFinalJob: false,
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export default function jobAndTeamSearch(state = initialState, action) {
  switch (action.type) {
    case types.JOB_AND_TEAM_SEARCH__UPDATE_STATE:
      let newState = merge(state, action.newState, {
        arrayMerge: overwriteMerge,
      });
      if (action.clearWorkStyles) {
        newState.roleTypeIds = [];
        newState.employmentTypeIds = [];
        newState.experienceLevelIds = [];
        newState.locationIds = [];
        newState.industryIds = [];
        newState.degreeTypeRankIds = [];
        newState.segmentIds = [];
        newState.apprenticePathwayIds = [];
      }

      return newState;
    case types.JOB_SEARCH__GET_JOB_MATCHES_REQUEST:
      return {
        ...state,
        jobs: [],
        jobsLoading: true,
        jobsLoaded: false,
      };
    case types.JOB_SEARCH__GET_JOB_MATCHES_SUCCESS:
      return {
        ...state,
        jobs: action.response.body.jobs.map((job) => {
          return {
            ...job,
            matchData: {
              matchLevels: [],
              ...job.matchData,
              showMatchChart:
                job.matchData?.employmentTypeFit &&
                job.matchData?.degreeRankFit &&
                job.matchData?.experienceLevelFit,
            },
          };
        }),
        jobsLoading: false,
        jobsLoaded: true,
        // are these still needed?
        jobsCurrentPage: action.response.body.page,
        jobsCurrentItemsPerPage: action.response.body.itemsPerPage,
        jobsTotalItems: action.response.body.totalItems,
      };
    case types.JOB_SEARCH__GET_JOB_MATCHES_FAILURE:
      return {
        ...state,
        jobs: [],
        jobsLoading: false,
        jobsLoaded: true,
      };

    case types.JOBS__GET_CATEGORY_SUCCESS:
      let categories = action.response.body.jobCategories;

      let jobCategoryTags = {};
      if (categories) {
        _.forEach(categories, (x) => {
          jobCategoryTags[x.id] = {
            id: x.id,
            name: x.name,
            selected: false,
          };
        });
      }
      jobCategoryTags[0] = {id: 0, name: 'Any', selected: true};

      return {
        ...state,
        jobCategoryTags,
      };

    case types.JOB_AND_TEAM_SEARCH_UPDATE_HAS_VIEWED_FINAL_JOB_FLAG:
      return {
        ...state,
        hasViewedFinalJob: true,
      };

    default:
      return state;
  }
}
