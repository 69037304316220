import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import 'url-search-params-polyfill';
import {Button} from 'reactstrap';
import * as jobsActions from 'src/actions/jobs';
import * as teamsActions from 'src/actions/teams';
import * as tableActions from 'src/actions/jobsDataTable';

import {PageHeader, PageFooter} from 'src/layout';
import {CreateJobForm, EditJobForm, CloneJobForm} from 'src/forms/JobForm';
import JobProfile from 'src/components/JobProfile';
import AscendDataTable from 'src/components/AscendDataTable';
import {formatDate} from 'src/utils/miscHelper';
import * as AuthorizeActions from '../../../actions/AuthorizationActions';
import {withRouter} from '../../../utils/commonHooks';
import {DeleteRoleConfirm} from '../../../components/DeleteRoleConfirm';

const searchFields = ['name', 'company', 'description'];
const searchPlaceholder = 'Search by title, company, or description';

class JobListPage extends React.Component {
  state = {
    formOpen: false,
    profileOpen: false,
    areYouSureModalIsOpen: false,
  };

  getParam = (paramname) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramname);
  };

  getJobs = (currentPage, itemsPerPage, searchTerm, sortBy, sortAscending) => {
    const teamId = this.getParam('teamId');
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    this.props.getJobsByTeamAdvanced(
      teamId,
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      true
    );
  };

  getTableState = () => {
    const teamId = this.getParam('teamId');
    if (teamId in this.props.jobsDataTable) {
      return this.props.jobsDataTable[teamId];
    } else {
      return {
        currentPage: 0,
        searchTerm: '',
        sortBy: '',
        sortAscending: true,
      };
    }
  };

  async componentDidMount() {
    const teamId = this.getParam('teamId');
    const employerId = this.getParam('employerId');

    if (!(teamId in this.props.jobsDataTable)) {
      this.props.createJobsDataTableTeam(teamId);
    }

    const tableState = this.getTableState();

    this.getJobs(
      tableState.currentPage,
      this.props.itemsPerPage,
      tableState.searchTerm,
      tableState.sortBy,
      tableState.sortAscending
    );
    this.props.getTeamToCache(teamId);
    this.props.getJobStats(employerId, teamId);
  }

  searchForId = (jobId, jobs) => {
    for (var i = 0; i < jobs.length; i++) {
      if (jobs[i].id === jobId) {
        return jobs[i];
      }
    }
  };

  handleAddJobClicked = () => {
    this.setState({
      formOpen: true,
      formMode: 'create',
    });
  };

  handleModalClose = () => {
    this.setState({
      formOpen: false,
      profileOpen: false,
    });
  };

  handlePageChange = (pageNumber) => {
    const teamId = this.getParam('teamId');
    const tableState = this.getTableState();

    this.props.updateCurrentPage(teamId, pageNumber);
    this.getJobs(
      pageNumber,
      this.props.itemsPerPage,
      tableState.searchTerm,
      tableState.sortBy,
      tableState.sortAscending
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    const teamId = this.getParam('teamId');
    const tableState = this.getTableState();

    this.props.updateSearchTerm(teamId, searchTerm);
    if (search) {
      this.getJobs(
        0,
        this.props.itemsPerPage,
        searchTerm,
        tableState.sortBy,
        tableState.sortAscending
      );
    }
  };

  handleSortChange = (sortBy, sortAscending) => {
    const teamId = this.getParam('teamId');
    const tableState = this.getTableState();

    this.props.updateSortTerm(teamId, sortBy, sortAscending);
    this.getJobs(
      0,
      this.props.itemsPerPage,
      tableState.searchTerm,
      sortBy,
      sortAscending
    );
  };

  handleTableAction = (action, jobId) => {
    switch (action) {
      case 'Clone':
        this.handleCloneClicked(jobId);
        break;
      case 'Delete':
        this.handleDeleteClicked(jobId);
        break;
      case 'Edit':
        this.handleEditClicked(jobId);
        break;
      case 'Profile':
        this.handleProfileClicked(jobId);
        break;
      case 'View':
        this.handleViewClicked(jobId);
        break;
      default:
        this.handleEditClicked(jobId);
        break;
    }
  };

  handleEditClicked = (jobId) => {
    this.props.getJobForEdit(jobId);
    this.props.canEditJob(jobId);
    this.setState({
      formOpen: true,
      formMode: 'edit',
      jobIdToEdit: jobId,
    });
  };

  handleCloneClicked = (jobId) => {
    this.props.getJobForClone(jobId);
    this.setState({
      formOpen: true,
      formMode: 'clone',
      jobIdToEdit: jobId,
    });
  };

  handleViewClicked = (jobId) => {
    let job = this.searchForId(jobId, this.props.jobs);
    this.setState({
      profileOpen: true,
      profileJob: job,
    });
  };

  handleProfileClicked = (jobId) => {
    this.props.history.push('/role/' + jobId);
  };

  handleDeleteClicked = (jobId) => {
    this.setState({
      jobIdToDelete: jobId,
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = (jobId) => {
    this.props.deleteJob(jobId);
    this.setState({
      jobIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClose = () => {
    this.setState({
      jobIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleJobEditSubmit = (job) => {
    this.props.updateJob(job);
    this.setState({formOpen: false});
  };

  handleJobCloneSubmit = (job) => {
    this.props.createJob({...job});
    this.setState({formOpen: false});
  };

  handleJobCreateSubmit = (job) => {
    const teamId = this.getParam('teamId');
    const employerId = this.getParam('employerId');
    this.props.createJob({...job, teamId, employerId});
    this.setState({formOpen: false});
  };

  buildName = (job) => {
    return (
      <div>
        <Button
          type="button"
          className="buttonAsLinkRoles"
          onClick={() => this.handleViewClicked(job.id)}
        >
          {job.name}
        </Button>
      </div>
    );
  };

  buildReq = (job) => {
    return job.req;
  };

  buildDateAdded = (job) => {
    if (job.createdAt) {
      return formatDate(job.createdAt);
    } else {
      return '';
    }
  };

  buildLastModified = (job) => {
    if (job.updatedAt) {
      return formatDate(job.updatedAt);
    } else {
      return '';
    }
  };

  buildPublished = (job) => {
    if (job.published === true) return 'yes';
    if (job.published === false) return 'no';
    return 'n/a';
  };

  render() {
    const teamId = this.getParam('teamId');

    let team = {};
    if (teamId in this.props.teamCache) {
      team = this.props.teamCache[teamId];
    }

    const tableState = this.getTableState();

    const renderEmployerName = () => {
      if (team) {
        return team.employerName;
      } else return '';
    };

    const renderTeamName = () => {
      if (team) {
        return team.name;
      } else return '';
    };

    const tableColumns = [
      {
        displayName: 'NAME',
        content: this.buildName,
        sortBy: 'name',
      },
      {
        displayName: 'REQ',
        content: this.buildReq,
      },
      {
        displayName: 'DATE ADDED',
        content: this.buildDateAdded,
      },
      {
        displayName: 'LAST MODIFIED',
        content: this.buildLastModified,
      },
      {
        displayName: 'PUBLISHED',
        content: this.buildPublished,
      },
    ];

    const rowMenuItemList = [
      {displayName: 'Clone'},
      {displayName: 'Edit'},
      {displayName: 'Profile'},
      {displayName: 'View'},
      {displayName: 'Delete'},
    ];

    return (
      <DocumentTitle title="Roles Admin">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />

                <div className="padding-bottom-sixteen">
                  <div className="row">
                    <div className="col-md-4">
                      <table style={{width: '100%'}} className="bgcolorw">
                        <tbody>
                          <tr>
                            <td
                              colSpan="3"
                              className="bd-darkgraybackground columnpad text-align-center"
                            >
                              <div className="normal-title bold">Roles</div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: '33%',
                                verticalAlign: 'middle',
                                borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                              }}
                              className="columnpad text-align-center"
                            >
                              <div className="accent-1-display bold">
                                {this.props.stats.draftJobs}
                              </div>
                              <div className="disabled-subheader">Draft</div>
                            </td>
                            <td
                              style={{
                                width: '33%',
                                verticalAlign: 'middle',
                                borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                              }}
                              className="columnpad text-align-center"
                            >
                              <div className="accent-1-display bold">
                                {this.props.stats.publishedJobs}
                              </div>
                              <div className="disabled-subheader">
                                Published
                              </div>
                            </td>
                            <td
                              style={{width: '33%', verticalAlign: 'middle'}}
                              className="columnpad text-align-center"
                            >
                              <div className="accent-1-display bold">
                                {this.props.stats.totalJobs}
                              </div>
                              <div className="disabled-subheader">Total</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-4" />
                    <div className="col-md-4" />
                  </div>
                </div>

                {this.state.profileJob && this.state.profileJob.id && (
                  <JobProfile
                    key={this.state.profileJob.id}
                    isOpen={this.state.profileOpen}
                    job={this.state.profileJob}
                    onClose={this.handleModalClose}
                  />
                )}

                {this.state.formMode === 'create' && (
                  <CreateJobForm
                    onSubmit={this.handleJobCreateSubmit}
                    isOpen={this.state.formOpen}
                    onClose={this.handleModalClose}
                    employerId={this.getParam('employerId')}
                    showTeams={false}
                  />
                )}

                {this.state.formMode === 'edit' && (
                  <EditJobForm
                    onSubmit={this.handleJobEditSubmit}
                    onDelete={() =>
                      this.handleDeleteConfirmed(this.state.jobIdToEdit)
                    }
                    isOpen={this.state.formOpen}
                    onClose={this.handleModalClose}
                  />
                )}

                {this.state.formMode === 'clone' && (
                  <CloneJobForm
                    onSubmit={this.handleJobCloneSubmit}
                    isOpen={this.state.formOpen}
                    onClose={this.handleModalClose}
                  />
                )}

                <DeleteRoleConfirm
                  jobName={this.state.jobIdToEdit?.name}
                  onClose={this.handleAreYouSureClosed}
                  onClick={() =>
                    this.handleDeleteConfirmed(this.state.jobIdToDelete)
                  }
                  confirmDeleteIsOpen={this.state.areYouSureModalIsOpen}
                />

                <div>
                  <div
                    className="clickable btn btn-primary float-right"
                    onClick={this.handleAddJobClicked}
                  >
                    + Add Role
                  </div>
                  <div className="normal-headline bold padding-bottom-eight">
                    {renderEmployerName()} {renderTeamName()} Roles Admin
                  </div>

                  <AscendDataTable
                    rowMenuItemList={rowMenuItemList}
                    tableColumns={tableColumns}
                    items={this.props.jobs}
                    handlerFunction={this.handleTableAction}
                    currentPage={tableState.currentPage}
                    itemsPerPage={this.props.itemsPerPage}
                    searchTerm={tableState.searchTerm}
                    sortBy={tableState.sortBy}
                    sortAscending={tableState.sortAscending}
                    totalItems={this.props.totalItems}
                    handlePageChange={this.handlePageChange}
                    handleFilterChange={this.handleFilterChange}
                    handleSortChange={this.handleSortChange}
                    searchPlaceholder={searchPlaceholder}
                    itemsLoading={this.props.jobsLoading}
                  />
                </div>
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

JobListPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  teamCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    team: PropTypes.object,
  }),
  jobs: PropTypes.array.isRequired,
  getJobsByTeamAdvanced: PropTypes.func.isRequired,
  getJobForEdit: PropTypes.func.isRequired,
  getJobForClone: PropTypes.func.isRequired,
  getTeamToCache: PropTypes.func.isRequired,
  getJobStats: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  createJobsDataTableTeam: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  jobsDataTable: PropTypes.object.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  jobsLoading: PropTypes.bool.isRequired,
  stats: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  canEditJob: PropTypes.func.isRequired,
  allowJobEdit: PropTypes.bool,
};

const actions = {
  ...jobsActions,
  ...teamsActions,
  ...tableActions,
  ...AuthorizeActions,
};

function mapStateToProps(state, ownProps) {
  return {
    jobs: state.jobs.jobs,
    jobsLoading: state.jobs.jobsLoading,
    teamCache: state.teams.teamCache,
    page: state.jobs.page,
    itemsPerPage: state.jobs.itemsPerPage,
    totalItems: state.jobs.totalItems,
    stats: state.jobs.stats,
    jobsDataTable: state.jobsDataTable.byTeam,
    allowJobEdit: state.authorization.job.canEdit,
  };
}

export default withRouter(connect(mapStateToProps, actions)(JobListPage));
