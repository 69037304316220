import {makeRequest} from 'src/services/api.js';

export const createInvitation = (invitation) => {
  return makeRequest({
    method: 'POST',
    path: `invitations/`,
    body: invitation,
  });
};

export const createMultipleInvitations = (invitations) => {
  return makeRequest({
    
    path: `invitations/list`,
    method: 'POST',
    body: invitations,
    payload: {invitations},
  });
};