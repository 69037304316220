import * as types from './ActionTypes';

export const createJobCount = (newValue) => async (dispatch) => {
  let jobCount = Math.floor(Math.random() * (48 - 18)) + 18;

  dispatch({
    type: types.APPLICANTS_CREATE_JOB_COUNT,
    payload: newValue === undefined || newValue === 0 ? jobCount : newValue,
  });
};
