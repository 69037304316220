import * as types from '../actions/ActionTypes';
import _ from 'lodash';

const initialState = {
  invitationCodes: [],
  invitationCode: {},
  invitationCodeForEdit: {},
  validating: false,
  isValid: null,
  invitationCodesLoading: false,
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  reports: [],
};

export default function invitationCodes(state = initialState, action) {
  switch (action.type) {
    case types.INVITATION_CODES__GET_REQUEST:
      return {...state, invitationCode: {}};
    case types.INVITATION_CODES__GET_SUCCESS:
      return {...state, invitationCode: action.response.body};
    case types.INVITATION_CODES__GET_ALL_SUCCESS:
      return {
        ...state,
        invitationCodes: action.response.body.invitationCodes,
        reports: action.response.body.reports,
      };
    case types.INVITATION_CODES__GET_ALL_ADVANCED_REQUEST:
      return {
        ...state,
        invitationCodes: [],
        invitationCodesLoading: true,
      };
    case types.INVITATION_CODES__GET_ALL_ADVANCED_SUCCESS:
      return {
        ...state,
        invitationCodes: action.response.body.invitationCodes,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        invitationCodesLoading: false,
        reports: action.response.body.reports,
      };
    case types.INVITATION_CODES__DELETE_SUCCESS:
      return {
        ...state,
        invitationCodes: _.filter(state.invitationCodes, (invitationCode) => {
          return invitationCode.id !== action.payload.invitationCodeId;
        }),
      };
    case types.INVITATION_CODES__CREATE_SUCCESS:
      return {
        ...state,
        invitationCodes: [...state.invitationCodes, action.response.body],
      };
    case types.INVITATION_CODES__UPDATE_SUCCESS:
      const index = _.findIndex(state.invitationCodes, (invitationCode) => {
        return invitationCode.id === action.payload.invitationCode.id;
      });
      return {
        ...state,
        invitationCodes: [
          ...state.invitationCodes.slice(0, index),
          action.response.body,
          ...state.invitationCodes.slice(index + 1),
        ],
      };
    case types.INVITATION_CODES__VALIDATE_REQUEST:
      return {
        ...state,
        isValid: null,
        validating: true,
      };
    case types.INVITATION_CODES__VALIDATE_SUCCESS:
      return {
        ...state,
        isValid: true,
        segment: action.response.body.segment,
        validating: false,
        invitationCodeId: action.response.body.invitationCodeId,
        destinationPage: action.response.body?.destinationPage,
      };
    case types.INVITATION_CODES__VALIDATE_FAILURE:
      return {
        ...state,
        isValid: false,
        validating: false,
      };
    case types.INVITATION_CODES__EDIT_DIALOG_SHOW:
      return {
        ...state,
        invitationCodeForEdit: action.data,
      };
    default:
      return state;
  }
}
