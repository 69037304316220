import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';
import PushButtonArray from 'src/components/controls/buttons/PushButtonArray/PushButtonArray';
import {required} from 'src/utils/intake/intakeCommon';
import TextField from '../../../formFields/TextField';
import CheckboxField from '../../../formFields/CheckboxField';

class Eligibility extends React.Component {
  formRef = React.createRef();

  validate = (values) => {
    const errors = {};
    let requiredFields = ['eligibility'];

    // Check for eligibility and add universityEmail to required fields if needed
    const isStudentOrGraduate = values.eligibility.some(
      (eligibilityItem) =>
        eligibilityItem.value === 'Current Student' ||
        eligibilityItem.value === 'Recent College Graduate'
    );
    const isEmailInaccessible = values.unknownUniversityEmail;

    if (isEmailInaccessible === false && isStudentOrGraduate) {
      requiredFields.push('universityEmail');
    }

    _.forEach(requiredFields, (field) => {
      // Check if the field is empty
      if (!values[field] || values[field].length === 0) {
        errors[field] = 'Required';
      }
    });

    // Validate universityEmail format if it's a required field
    if (isStudentOrGraduate) {
      // Regular expression for validating email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (values.universityEmail && !emailRegex.test(values.universityEmail)) {
        errors.universityEmail = 'Invalid email format';
      }
    }

    return errors;
  };

  buildButtonList = () => [
    {
      buttonValue: 'Current Student',
      buttonText: 'Current Student',
      buttonSubText: 'Any education level',
    },
    {
      buttonValue: 'Recent College Graduate',
      buttonText: 'Recent College Graduate',
      buttonSubText: 'Within last three years',
    },
    {
      buttonValue: 'Unemployed or Underemployed',
      buttonText: 'Unemployed or Underemployed',
    },
    {
      buttonValue: 'Employed',
      buttonText: 'Employed',
      buttonSubText: 'Seeking a new role',
    },
  ];

  render() {
    const {next, previous, headerImage} = this.props;
    const readFormValues = () => {
      return this.formRef?.current?.getState().values;
    };

    return (
      <>
        <PageHeader headerImage={headerImage} />
        <div className={styles.pageContent}>
          <Form
            onSubmit={next}
            validate={this.validate}
            mutators={{
              // expect (field, value) args from the mutator
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={{
              eligibility: this.props.intakeCache?.eligibility || [],
              universityEmail: this.props.intakeCache?.universityEmail || '',
              unknownUniversityEmail:
                this.props.intakeCache.unknownUniversityEmail || false,
            }}
          >
            {({handleSubmit, form, values}) => {
              this.formRef.current = form;

              const universityEmailGiven =
                this.props.profile.email.endsWith('.edu');

              const showUniversityEmail = values.eligibility.some(
                (eligibilityItem) =>
                  eligibilityItem.value === 'Current Student' ||
                  eligibilityItem.value === 'Recent College Graduate'
              );

              return (
                <form onSubmit={handleSubmit} className="mb-0">
                  <div style={{maxWidth: '317px'}}>
                    <div className="mb-2 onboarding-form-label">
                      Which of the following statements apply to you?
                    </div>

                    <Field
                      component={PushButtonArray}
                      title="Which of the following statements apply to you?"
                      name="eligibility"
                      validate={required}
                      buttonList={this.buildButtonList()}
                      displayVertical={true}
                      form={form}
                      multiSelect={true}
                    />
                    {universityEmailGiven === false && showUniversityEmail && (
                      <>
                        <div
                          className="mb-2 onboarding-form-label-textField"
                          style={{textAlign: 'left', paddingLeft: '10px'}}
                        >
                          University Email Address
                        </div>
                        <Field
                          component={TextField}
                          title="University Email Address"
                          name="universityEmail"
                          form={form}
                        />
                        <div className={styles.checkboxFieldCustom}>
                          <div className={styles.checkboxFieldWrapper}>
                            <Field
                              component={CheckboxField}
                              name="unknownUniversityEmail"
                              form={form}
                              label="I do not know or have access to my university email address."
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
        <PageFooter
          next={() => this.formRef?.current?.submit()}
          previous={previous}
          getValues={readFormValues}
        />
      </>
    );
  }
}
Eligibility.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any,
  isMobile: PropTypes.bool,
  headerImage: PropTypes.any,
  profile: PropTypes.any,
};

Eligibility.mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(Eligibility.mapStateToProps)(Eligibility);
