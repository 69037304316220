export const CandidateAdminTabs = {
  ACTIVE: '1',
  INACTIVE: '2',
  TASKS: '3',
  MA_APPROVALS: '4',
  APPROVALS: '5',
};

export const CandidateAdminTabNames = {
  1: 'active',
  2: 'inactive',
  3: 'tasks',
  4: 'maApprovals',
  5: 'approvals',
};
