import {Tooltip as ReactTooltip} from 'react-tooltip';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {TextField, SingleDatePickerField} from 'src/formFields';
import {Form, Field} from 'react-final-form';
import {connect} from 'react-redux';
import CheckboxField from 'src/formFields/CheckboxField';
import {validate} from 'src/utils/modernApprenticeship/modernApprenticeshipCommon';
import infoBlueCircle from 'src/assets/images/info-blue-circle.svg';
import {updateSeekerAction} from 'src/actions/SeekerActions';
import {updateTermsAccepted} from 'src/actions/users';
import * as Layout from '../layout';

import {required} from 'src/utils/intake/intakeCommon';
import {differenceInYears} from 'date-fns';

const FIFTY_YEARS = 1000 * 60 * 60 * 24 * 365 * 50;

const validateForm = (values) => {
  const fields = ['consenterFullName', 'consenterDateOfBirth', 'termsAgreed'];

  let messages = validate(fields, values);
  if (values['consenterDateOfBirth']) {
    const age = differenceInYears(
      new Date(),
      new Date(values['consenterDateOfBirth'])
    );
    if (age <= 17) {
      messages['consenterDateOfBirth'] =
        'Parent or legal guardian must be 18 years of age.';
    }
  }

  if (values['consenterFullName']) {
    const fullName = values['consenterFullName'].trim();
    if (fullName.length < 2) {
      messages['consenterFullName'] =
        'Full Name must be at least two characters long.';
    }
  }

  return messages;
};

class ParentalConsentModal extends React.Component {
  state = {
    isOpen: this.props.isOpen,
  };

  handleToNetworkClick = async (formValues) => {
    let dob = new Date(formValues.consenterDateOfBirth);
    let dobString = dob.toISOString();
    const data = {
      id: this.props.userProfile.seekerId,
      consenterFullName: formValues.consenterFullName,
      consenterDateOfBirth: dobString,
      termsAgreed: formValues.termsAgreed,
    };

    await this.props.updateSeekerAction(data);
    this.props.updateTermsAccepted(
      this.props.userProfile.id,
      formValues.termsAgreed,
      formValues.consenterFullName
    );
    this.setState({
      isOpen: false,
    });
  };

  setScroll = () => {
    if (this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  };

  render() {
    this.setScroll();
    const dataTip =
      'If you are a legally emancipated minor, please contact <a href="mailto:support@ascendindiana.com" class="underline white">support@ascendindiana.com</a> for instructions on how to complete this interest form.';

    return (
      <Fragment>
        <Layout.AscendModal
          isOpen={this.state.isOpen}
          disableClose={true}
          centerVertically={true}
          dialogClassName="noBorder"
        >
          <Layout.AscendModalHeader
            headerClass="bd-modalheader"
            isOpen={this.props.isOpen}
          >
            <div className="normal-headline-white bold text-align-center mb-0">
              Parental Consent Required
            </div>
          </Layout.AscendModalHeader>

          <Layout.AscendModalBody hasClose={false}>
            <Form
              onSubmit={this.handleToNetworkClick}
              validate={validateForm}
              keepDirtyOnReinitialize={true}
              initialValues={{
                termsAgreed: this.props.termsAgreed,
                consenterFullName: this.props.consenterFullName,
                consenterDateOfBirth: this.props.consenterDateOfBirth,
              }}
            >
              {({handleSubmit, form, values}) => {
                return (
                  <form onSubmit={() => handleSubmit(values)}>
                    <div>
                      <div className="secondary-subheader text-align-center">
                        Your parent or legal guardian must review our{' '}
                        <a
                          href={'https://ascendindiana.com/terms-of-service'}
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          terms of service
                        </a>{' '}
                        and{' '}
                        <a
                          href={'https://ascendindiana.com/privacy-policy'}
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          privacy policy
                        </a>
                        . By checking this box, your parent or legal guardian
                        acknowledges that they agree to the terms of service and
                        privacy policy.{' '}
                        <ReactTooltip
                          id="tooltip"
                          className="solid-tooltip-dark"
                          delayHide={1000}
                          clickable={true}
                          closeOnEsc={true}
                        />
                        <img
                          tabIndex={0}
                          className="clickable"
                          style={{width: '5%', maxWidth: '16px'}}
                          alt={''}
                          src={infoBlueCircle}
                          data-tooltip-id="tooltip"
                          data-tooltip-html={dataTip}
                        />
                        <br />
                        <div className="padding-bottom-sixteen padding-top-sixteen inlineblock text-align-center">
                          <Field
                            name="termsAgreed"
                            component={CheckboxField}
                            style={{}}
                            showBackground={false}
                            validate={required}
                            showValidation={true}
                            type={'checkbox'}
                            label={
                              <span>
                                I accept the Terms of Service and Privacy Policy
                              </span>
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <div className="bold text-center">
                          Parent/Legal Guardian: Please provide your full name.
                        </div>
                      </div>
                      <div>
                        <div className="col-sm-12">
                          <Field
                            ariaLabel="Please provide your full name."
                            name="consenterFullName"
                            component={TextField}
                            size={12}
                            showLabel={false}
                            maxLength={64}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="bold text-center">
                          Parent/Legal Guardian: Please provide your date of
                          birth.
                        </div>
                      </div>
                      <div className="row pb-4">
                        <div className="col-sm-12 mt-2 text-center">
                          <div className="d-flex justify-content-center">
                            <Field
                              name="consenterDateOfBirth"
                              ariaLabel="Please provide your date of birth."
                              component={SingleDatePickerField}
                              placeholder="MM/DD/YYYY"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="normal-subheader padding-bottom-sixteen text-align-center">
                        <div>
                          <button
                            disabled={form.getState().valid === false}
                            onClick={handleSubmit}
                            className={`btn btn-primary`}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </Layout.AscendModalBody>
        </Layout.AscendModal>
        {this.props.children}
      </Fragment>
    );
  }
}

ParentalConsentModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  termsAgreed: PropTypes.bool,
  consenterFullName: PropTypes.string,
  consenterDateOfBirth: PropTypes.string,
  userProfile: PropTypes.object,
  updateSeekerAction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  updateTermsAccepted: PropTypes.func.isRequired,
};

ParentalConsentModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state) => {
  let consenterDateOfBirth = state.profile.consenterDateOfBirth;
  if (consenterDateOfBirth === null) {
    consenterDateOfBirth = new Date(Date.now() - FIFTY_YEARS).toISOString();
  }

  return {
    userProfile: state.profile,
    termsAcceptedSuccess: state.users.termsAcceptedSuccess,
    termsAcceptedError: state.users.termsAcceptedError,
    termsAgreed: state.profile.termsAccepted,
    consenterFullName: state.profile.consenterFullName,
    consenterDateOfBirth: consenterDateOfBirth,
  };
};

const mapActionsToProps = {
  updateSeekerAction,
  updateTermsAccepted,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParentalConsentModal);
