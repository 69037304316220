import React from 'react';
import PropTypes from 'prop-types';
import {camelCase} from 'src/utils/miscHelper';

class FilterTagCheckbox extends React.Component {
  handleChange = (event) => {
    this.props.onChange({
      name: event.target.name,
      selected: event.target.checked,
    });
  };

  render() {
    return (
      <div className="custom-control custom-checkbox form-check">
        <input
          id={camelCase(this.props.name)}
          name={this.props.name}
          type="checkbox"
          className="custom-control-input form-check-input"
          checked={this.props.selected && !this.props.disabled}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
        />
        <label
          className="custom-control-label form-check-label"
          htmlFor={camelCase(this.props.name)}
        >
          {this.props.label}
        </label>
      </div>
    );
  }
}

FilterTagCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default FilterTagCheckbox;
