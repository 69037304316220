import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {List} from 'react-content-loader';

import * as jobsActions from '../../actions/jobs';

class CompanyTeamCard extends React.Component {
  componentDidMount() {
    const teamId = this.props.teamId;
    if (!(teamId in this.props.jobsByTeam)) {
      this.props.getJobsByTeamToCache(teamId);
    }
  }

  handleTeamClick = (teamId) => {
    this.props.onTeamClick(teamId);
  };

  handleJobClick = (jobId) => {
    this.props.onJobClick(jobId);
  };

  handleTeamEditClick = (teamId) => {
    this.props.onTeamEditClick(teamId);
  };

  isTeamLoading = () => {
    return !(this.props.teamId in this.props.jobsByTeam);
  };

  render() {
    var jobs = [];
    if (this.props.teamId in this.props.jobsByTeam) {
      jobs = this.props.jobsByTeam[this.props.teamId].slice(0, 3);
    }

    const renderJobs = () => {
      if (jobs.length === 0) {
        return;
      }
      return (
        <>
          <div className="normal-body bold padding-bottom-eight">
            <h4>Recent Published Roles</h4>
          </div>
          {jobs.map((job, i) => {
            return (
              <button
                key={i}
                className="clickable padding-bottom-eight buttonAsLinkTeamCard"
                style={{
                  fontSize: '17px',
                  paddingLeft: '10px',
                }}
                onClick={() => this.handleJobClick(job.id)}
              >
                {job.name}
              </button>
            );
          })}
        </>
      );
    };

    return (
      <div
        className="bd-whitebackground padding-16"
        style={{
          overflowY: 'auto',
        }}
      >
        {this.isTeamLoading() && <List />}
        {!this.isTeamLoading() && (
          <>
            <button
              role="link"
              className="buttonAsLinkTeamCard normal-headline bold clickable padding-bottom-sixteen"
              style={{textDecoration: 'underline'}}
              onClick={() => this.handleTeamClick(this.props.teamId)}
            >
              <h3>{this.props.name}</h3>
            </button>
            <div className="disabled-subheader padding-bottom-twentyfour">
              {this.props.summary}
            </div>
            {renderJobs()}
          </>
        )}
      </div>
    );
  }
}

CompanyTeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  summary: PropTypes.string.isRequired,
  watchedSeekers: PropTypes.array.isRequired,
  onJobClick: PropTypes.func.isRequired,
  onTeamClick: PropTypes.func.isRequired,
  onSeekerClick: PropTypes.func.isRequired,
  onTeamEditClick: PropTypes.func.isRequired,
  getJobsByTeamToCache: PropTypes.func.isRequired,
  jobsByTeam: PropTypes.shape({
    id: PropTypes.number,
    jobs: PropTypes.array,
  }),
};

const mapStateToProps = (state) => {
  return {
    jobsByTeam: state.jobs.jobsByTeam,
  };
};

const mapDispatchToProps = {
  ...jobsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTeamCard);
