import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {AscendModal, AscendModalBody} from '../../layout';
import {FormPasswordInput} from 'src/formFields';

class PasswordModal extends React.Component {
  passwordOk = (password) => {
    if (!password) return false;
    if (password.length < 8) return false;
    let count = 0;
    if (/[a-z]/.test(password)) count++;
    if (/[A-Z]/.test(password)) count++;
    if (/[0-9]/.test(password)) count++;
    // eslint-disable-next-line no-useless-escape
    if (/[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]/.test(password)) count++;
    return count >= 3;
  };

  validate = (values) => {
    const errors = {};
    const requiredFields = ['password', 'confirm'];

    if (!this.passwordOk(values.password)) {
      errors.password = 'Weak password';
    }
    if (values.password !== values.confirm) {
      errors.confirm = 'Passwords must match';
    }

    requiredFields.forEach((field) => {
      if (!values[field] || values[field] === '') {
        errors[field] = 'Required';
      }
    });

    return errors;
  };

  render() {
    return (
      <AscendModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        centerVertically={true}
        disableClose={true}
      >
        <AscendModalBody>
          <div className="padding-32">
            <div className="normal-headline">Please set your password</div>
            <div className="normal-caption">
              Passwords must comply with these rules:
              <ul>
                <li>At least 8 characters</li>
                <li>Contain at least 3 of the following 4 types</li>
                <li>Lower case letters (a-z)</li>
                <li>Upper case letters (A-Z)</li>
                <li>Numbers (0-9)</li>
                <li>Special characters (!@#$%^&*)</li>
              </ul>
            </div>
            <Form onSubmit={this.props.onSubmit} validate={this.validate}>
              {({handleSubmit}) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="form padding-bottom-sixteen">
                      <div className="form-group">
                        <label className="secondary-body">Password</label>
                        <Field
                          name="password"
                          label="Password"
                          component={FormPasswordInput}
                        />
                      </div>

                      <div className="form-group">
                        <label className="secondary-body">
                          Confirm password
                        </label>
                        <Field
                          name="confirm"
                          label="Confirm Password"
                          component={FormPasswordInput}
                        />
                      </div>
                      <button
                        type="submit"
                        className="clickable btn btn-eula float-right"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                );
              }}
            </Form>
            <br />
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

PasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PasswordModal;
