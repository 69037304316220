import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ActionToggleIcon extends React.Component {
  handleMouseEnter = () => {
    if (this.props.message) {
      this.props.onMouseEnter(this.props.message);
    }
  };

  handleMouseLeave = () => {
    this.props.onMouseLeave();
  };

  handleOnFocusIn = () => {
    if (this.props.message) {
      this.props.onFocus(this.props.message);
    }
  };
  handleOnFocusOut = () => {
    this.props.onBlur();
  };

  render() {
    return (
      <div className="inlineblock padding-left-sixteen">
        <div
          role="button"
          aria-label={this.props.message}
          aria-pressed={this.props.ariaPressed}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              this.props.onClick();
            }
          }}
          className="clickable action-icon-container"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.props.onClick}
          onFocus={this.handleOnFocusIn}
          onBlur={this.handleOnFocusOut}
        >
          <div className="action-icon">
            {this.props.glyph && (
              <i
                className={classNames('nc-icon-glyph-med', this.props.glyph)}
              />
            )}
            {this.props.svg && !this.props.svgAsImage && (
              <img src={this.props.svg} alt="" />
            )}
            {this.props.svgAsImage && this.props.svgImage}
            {this.props.svgText && ` ${this.props.svgText}`}
          </div>
        </div>
      </div>
    );
  }
}

ActionToggleIcon.propTypes = {
  glyph: PropTypes.string,
  svg: PropTypes.string,
  svgAsImage: PropTypes.bool,
  svgImage: PropTypes.object,
  svgText: PropTypes.string,
  message: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  ariaPressed: PropTypes.bool,
};
ActionToggleIcon.defaultProps = {
  svgAsImage: false,
};
export default ActionToggleIcon;
