import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import Avatar from '../../components/Avatar';
import {seekerMajor} from '../../utils/fieldHelper';
import MatchCircle from 'src/components/MatchCircle';

class MaximizedSeekerItem extends React.Component {
  render() {
    const {seeker, jobTraits} = this.props;

    const shouldShowChart = () => {
      const matchLevels = seeker.match?.matchLevels;
      if (!matchLevels) {
        return false; // Return false if matchLevels doesn't exist
      }

      const {degreeRankFit, experienceLevelFit, employmentTypeFit} =
        seeker.match;

      if (degreeRankFit === false) {
        return false;
      }
      if (experienceLevelFit === false) {
        return false;
      }
      if (employmentTypeFit === false) {
        return false;
      }

      const valuesAreZero = matchLevels[0] === 0 || matchLevels[1] === 0;
      return !valuesAreZero; // Return false if either value are 0
    };

    const matchData = {
      ...seeker.match,
      seekerTraits: seeker.characterTraits,
      jobTraits: jobTraits,
      showMatchChart: shouldShowChart(),
      matchLevels: seeker.match?.matchLevels
        ? seeker.match?.matchLevels
        : [0, 0],
      isApprenticeProgram: seeker.segments?.some((s) => s.isApprenticeProgram),
    };

    return (
      <tr key={seeker.id}>
        <td width="10%" className="clickable padding-8">
          <Link
            to={`/candidate/${seeker.id}`}
            id={`seekerAvatarLink-${seeker.id}`}
          >
            <Avatar
              url={seeker.avatarUrl}
              label={seeker.firstName + ' ' + seeker.lastName}
              invited={!!_.find(this.props.allInvitations, {id: seeker.id})}
              handpicked={!!_.find(this.props.allHandpicked, {id: seeker.id})}
            />
          </Link>
        </td>
        <td width="30%" className="padding-8">
          <span>
            <Link
              className="clickable normal-body accessibleLink"
              style={{textDecoration: 'none'}}
              to={`/candidate/${seeker.id}`}
            >
              {seeker.firstName + ' ' + seeker.lastName}
            </Link>
          </span>
        </td>
        <td width="50%" className="padding-8">
          <span className="disabled-body">
            {seekerMajor(seeker)}, {seeker.graduationMonth}{' '}
            {seeker.graduationYear}
          </span>
        </td>
        <td width="10%" className="padding-8">
          <div className="match-chart-host" tabIndex={0}>
            <MatchCircle
              viewerType="RECRUITER"
              matchData={matchData}
              isCompact
              noMargin
            />
          </div>
        </td>
      </tr>
    );
  }
}

MaximizedSeekerItem.propTypes = {
  seeker: PropTypes.object.isRequired,
  jobTraits: PropTypes.object.isRequired,
  allInvitations: PropTypes.arrayOf(PropTypes.object).isRequired,
  allHandpicked: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MaximizedSeekerItem;
