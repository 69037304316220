import * as types from './ActionTypes';

export function delayFlashDispatchForScreenReader(actionDispatcher) {
  /*
  When using a screen reader, and when saving a candidate (or other entity)
  that is editied within a modal, the Mac Voice Over screen reader will read
  the re-focused object (often a button) before reading the Success flash message.
  If we delay dispatching this action by an arbitrary amount here, then
  Voice Over will have time to read the flash message after re-focusing
  on the button
  */
  setTimeout(() => {
    actionDispatcher();
  }, 1000);
}

export function addFlashMessage(text, messageType = 'error') {
  return {type: types.FLASH_MESSAGE__CREATE, text, messageType};
}

export function deleteFlashMessage(timestamp) {
  return {type: types.FLASH_MESSAGE__DELETE, timestamp};
}
