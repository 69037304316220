import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  getApprenticePathways,
  getAllApprenticePathways,
} from 'src/actions/apprenticePathwaysActions';
import {useSelector, useDispatch} from 'react-redux';

const colorStyles = {
  multiValueLabel: (styles) => ({
    ...styles,
    backgroundColor: 'rgba(7, 79, 123, 1)',
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'rgba(7, 79, 123, 1)',
    ':hover': {
      backgroundColor: 'rgba(7, 79, 123, 0.75)',
      color: 'white',
    },
  }),
};

export default function ApprenticePathwaySelect(props) {
  const dispatch = useDispatch();

  const [highSchoolId, setHighSchoolId] = useState(null);

  const {metaOverride, meta} = props;

  const {touched, error, warning} = {...meta, ...metaOverride};

  let {apprenticePathways, apprenticePathwaysLoading} = useSelector((state) => {
    return {
      apprenticePathways: state.apprenticePathways.apprenticePathways || [],
      apprenticePathwaysLoading:
        state.apprenticePathways.apprenticePathwaysLoading,
    };
  });

  useEffect(() => {
    if (
      apprenticePathwaysLoading === false &&
      (apprenticePathways?.length === 0 || highSchoolId !== props.highSchoolId)
    ) {
      if (props.hasRequestedHighSchool) {
        dispatch(getApprenticePathways(props.highSchoolId));
        setHighSchoolId(props.highSchoolId);
      } else {
        dispatch(getAllApprenticePathways());
        setHighSchoolId(0);
      }
    }
  }, [
    dispatch,
    props.highSchoolId,
    props.hasRequestedHighSchool,
    apprenticePathways,
    apprenticePathwaysLoading,
    highSchoolId,
  ]);

  return (
    <>
      {props.children}
      <Select
        className="clickable normal-subheader"
        name={props.name}
        options={apprenticePathways}
        isMulti={props.isMulti}
        styles={colorStyles}
        isLoading={apprenticePathwaysLoading}
        onChange={props.onChange}
        value={
          props.value
            ? apprenticePathways.find(
                (pathway) => pathway.value === props.value
              )
            : props.valueObjects
        }
      />

      {touched &&
        ((error && <div className="form-input-error">{error}</div>) ||
          (warning && <div className="form-input-error">{warning}</div>) || (
            <div className="form-input-error">&nbsp;</div>
          ))}
      {!touched && <div className="form-input-error">&nbsp;</div>}
    </>
  );
}

ApprenticePathwaySelect.propTypes = {
  highSchoolId: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,

  children: PropTypes.object,
  isMulti: PropTypes.bool,
  hasRequestedHighSchool: PropTypes.bool,
  meta: PropTypes.object,
  metaOverride: PropTypes.any,
  value: PropTypes.any,
  valueObjects: PropTypes.arrayOf(PropTypes.object),
};

ApprenticePathwaySelect.defaultProps = {
  highSchoolId: 0,
  hasRequestedHighSchool: true,
  valueObjects: [],
  value: null,
};
