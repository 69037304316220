import _ from 'lodash';
import {CandidateAdminTabs} from 'src/utils/CandidateAdminTabs';

import {defaultSeekerTraits} from 'src/utils/traitsHelper';
import * as types from '../actions/ActionTypes';
import {toCurrency} from '../utils/wageHelper';
import {mergeMatches} from '../utils/seekerHelper';

const blank_seeker = {
  id: null,
  firstName: '',
  lastName: '',
  name: '',
  phone: '',
  email: '',
  institutionId: null,
  campusId: null,
  schoolId: null,
  degreeType: null,
  gpa: null,
  graduationMonth: null,
  graduationYear: null,
  characterTraits: defaultSeekerTraits(),
  skillTags: [],
  achievement: {
    level: -1,
    note: '',
  },
  resume: {id: '', uploadedFilename: ''},
  avatarUrl: '',
  interviewedOn: null,
  location: '',
  experience: [],
  highlightsNotes: '',
  invitationMatches: [],
  companyMatches: [],
  summary: '',
  surveyCreativity: 0,
  surveyTeamwork: 0,
  surveyLeadership: 0,
  surveyTravel: 0,
  surveySchedule: 0,
  surveyGroup: 0,
  surveyGoals: 0,
  surveySearchConfidence: 0,
  surveyDegreeValue: 0,
  surveyWorkingIndiana: 0,
  termsAgreed: false,
  url: '',
  reason: '',
  reasonNotes: '',
  matches: [
    {
      jobId: 0,
      jobName: '',
      jobLocation: '',
      teamName: '',
      employerName: '',
      matchData: {
        matchLevels: [],
        jobTraits: [],
        seekerTraits: [],
      },
    },
  ],
  validatingConsentRequest: false,
  isValidConsentRequest: null,
  segments: [],
  watchedEmployers: [],
  watchedJobs: [],
  watchedTeams: [],
  appliedJobs: [],
  noteCount: 0,
  teamWatchers: [],
  invitationResponse: [],
  highSchoolId: null,
};

const initialState = {
  selectedCandidatesTab: CandidateAdminTabs.ACTIVE,
  tabCountsInitialized: false,
  activeTabCountLoading: false,
  inactiveTabCountLoading: false,
  manageStudentApprovalsCountLoading: false,
  manageAdminApprovalsCountLoading: false,
  jobMatches: [],
  jobMatchesLoading: false,
  seekers: [],
  seekerForEdit: {
    segments: [],
  },
  createSeekerLoading: false,
  seekerLoading: false,
  seekersLoading: false,
  statsLoading: false,
  myStatsLoading: false,
  isValid: false,
  seekersPage: 0,
  seekersItemsPerPage: 25,
  seekersTotalItems: 0,
  activeSeekerCount: 0,
  inactiveSeekerCount: 0,
  manageStudentApprovalsCount: 0,
  manageAdminApprovalsCount: 0,
  reports: [],
  stats: {
    activeSeekers: 0,
    cfCompleteSeekers: 0,
    connectedSeekers: 0,
    draftSeekers: 0,
    firstApplicationSubmitted: 0,
    inactiveSeekers: 0,
    invitedSeekers: 0,
    offRampedSeekers: 0,
    pendingSeekers: 0,
    profilesEverPublished: 0,
    publishedSeekers: 0,
    totalSignUpSeekers: 0,
  },
  myStats: {
    activeSeekers: 0,
    connectedSeekers: 0,
    invitedSeekers: 0,
    firstApplicationSubmitted: 0,
    profilesEverPublished: 0,
  },
  jobStats: {
    jobMatchCount: 0,
    jobInvitationCount: 0,
    jobFavorites: 0,
    jobApplicationCount: 0,
    jobHandpickedCount: 0,
  },
  teamStats: {
    teamMatchCount: 0,
  },
  placementStats: {
    placedByAscend: 0,
    contactInFuture: 0,
  },
  surveyStats: {
    degreeValueLess: 0,
    degreeValueSame: 0,
    degreeValueMore: 0,
    degreeValueTotal: 0,
    perspectiveIndianaLess: 0,
    perspectiveIndianaSame: 0,
    perspectiveIndianaMore: 0,
    perspectiveIndianaTotal: 0,
    searchConfidenceLess: 0,
    searchConfidenceSame: 0,
    searchConfidenceMore: 0,
    searchConfidenceTotal: 0,
  },
  seeker: blank_seeker,
  candidateHomePageTracked: false,
  invitationResponse: [],
  optOutLoading: false,
  watchRequestLoading: false,
  savedCandidates: [],
  savedCandidatesLoading: false,
  savedCandidatesItemsPerPage: 25,
  savedCandidatesTotalItems: 0,
  savedCandidatesRequiresRefresh: false,
  candidateListFilterActive: false,
  applicationsLoading: false,
  applicationLoadComplete: false,
  applications: [],
  changeEmailUser: {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
  },
};

export default function seekers(state = initialState, action) {
  let seeker = JSON.parse(JSON.stringify(state.seeker));
  let newAppliedJobs = [];
  let newWatchedJobs = [];
  let newWatchedEmployers = [];
  let newWatchedTeams = [];
  let newTeamWatchers = [];
  let seekerIndex = [];
  let updateSeeker = [];

  switch (action.type) {
    case types.SEEKERS__GET_REQUEST:
      return {
        ...state,
        seeker: blank_seeker,
        seekerLoading: true,
      };
    case types.SEEKERS__FINISH_REQUEST:
      return {
        ...state,
        seeker: {},
        seekerLoading: true,
        isValid: false,
      };
    case types.SEEKERS__GET_JOB_MATCHES_REQUEST:
      return {
        ...state,
        jobMatches: [],
        jobMatchesLoading: true,
      };
    case types.SEEKERS__GET_JOB_MATCHES_SUCCESS:
      return {
        ...state,
        jobMatches: action.response.body.jobs,
        jobMatchesLoading: false,
        // are these still needed?
        jobsCurrentPage: action.response.body.page,
        jobsCurrentItemsPerPage: action.response.body.itemsPerPage,
        jobsTotalItems: action.response.body.totalItems,
      };
    case types.SEEKERS__GET_JOB_MATCHES_FAILURE:
      return {
        ...state,
        jobMatches: [],
        jobMatchesLoading: false,
      };
    case types.SEEKERS__GET_FOR_EDIT_REQUEST:
      return {
        ...state,
        seekerLoading: true,
      };
    case types.SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_REQUEST:
      return {
        ...state,
        questionsLoading: true,
      };
    case types.SEEKERS__GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.response.body.reports,
      };
    case types.SEEKERS__GET_ALL_REQUEST:
      return {
        ...state,
        seekers: [],
      };
    case types.SEEKERS__CREATE_REQUEST:
      return {
        ...state,
        createSeekerLoading: true,
      };
    case types.SEEKERS__CREATE_SUCCESS:
      return {
        ...state,
        seeker: {
          ...action.response.body,
        },
        createSeekerLoading: false,
      };
    case types.SEEKERS__GET_SUCCESS:
      return {
        ...state,
        seeker: {
          ...action.response.body,
          minimumYearlySalary: toCurrency(
            action.response.body.minimumYearlySalary
          ),
          minimumHourlyWage: toCurrency(action.response.body.minimumHourlyWage),
          matches: action.response.body.matches.map((match) => {
            return {
              ...match,
              matchData: {
                ...match.matchData,
                showMatchChart:
                  match.matchData.employmentTypeFit &&
                  match.matchData.degreeRankFit &&
                  match.matchData.experienceLevelFit,
              },
            };
          }),
        },
        seekerLoading: false,
      };
    case types.SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_SUCCESS:
      return {
        ...state,
        questionsAndAnswers: {
          ...action.response.body,
        },
        questionsLoading: false,
      };
    case types.SEEKERS__GET_SEEKER_QUESTIONS_SUCCESS:
      return {
        ...state,
        seeker_questions: action.response.body,
        questionsLoading: false,
      };
    case types.SEEKERS__FINISH_SUCCESS:
      return {
        ...state,
        seeker: action.response.body,
        seekerLoading: false,
        isValid: true,
      };
    case types.SEEKERS__FINISH_FAILURE:
      return {
        ...state,
        seeker: {},
        seekerLoading: false,
        isValid: false,
      };
    case types.SEEKERS__COMPLETE_SUCCESS:
      return {
        ...state,
        seeker: {
          ...action.response.body,
        },
      };
    case types.SEEKERS__GET_FOR_EDIT_SUCCESS:
      return {
        ...state,
        seekerForEdit: {
          ...action.response.body,
          minimumYearlySalary: toCurrency(
            action.response.body.minimumYearlySalary
          ),
          minimumHourlyWage: toCurrency(action.response.body.minimumHourlyWage),
          matches: action.response.body.matches.map((match) => {
            return {
              ...match,
              matchData: {
                ...match.matchData,
                showMatchChart:
                  match.matchData.employmentTypeFit &&
                  match.matchData.degreeRankFit &&
                  match.matchData.experienceLevelFit,
              },
            };
          }),
        },
        seekerLoading: false,
      };
    case types.SEEKERS__GET_FAILURE:
      return state;
    case types.SEEKERS__GET_STATS_REQUEST:
      return {...state, statsLoading: true};
    case types.SEEKERS__GET_STATS_SUCCESS:
      return {...state, stats: action.response.body, statsLoading: false};
    case types.SEEKERS__GET_STATS_FAILURE:
      return {...state, stats: action.response.body, statsLoading: false};
    case types.SEEKERS__GET_MY_STATS_REQUEST:
      return {...state, myStatsLoading: true};
    case types.SEEKERS__GET_MY_STATS_SUCCESS:
      return {...state, myStats: action.response.body, myStatsLoading: false};
    case types.SEEKERS__GET_MY_STATS_FAILURE:
      return {...state, myStats: action.response.body, myStatsLoading: false};
    case types.SEEKERS__GET_JOB_STATS_SUCCESS:
      return {...state, jobStats: action.response.body};
    case types.SEEKERS__GET_TEAM_STATS_SUCCESS:
      return {...state, teamStats: action.response.body};
    case types.SEEKERS__GET_PLACEMENT_STATS_SUCCESS:
      return {...state, placementStats: action.response.body};
    case types.SEEKERS__GET_SURVEY_STATS_SUCCESS:
      return {...state, surveyStats: action.response.body};
    case types.SEEKERS__UPDATE_REQUEST_WITH_LOADING:
      return {...state, seekerLoading: true};
    case types.SEEKERS__UPDATE_REQUEST:
      return {...state, seekerLoading: action.showLoading};
    case types.SEEKERS__UPDATE_SUCCESS:
      let index = _.findIndex(state.seekers, (seeker) => {
        return seeker.id === action.payload.seeker.id;
      });

      if (action.response.body === undefined) {
        return {
          ...state,
        };
      }

      let seekerViewModel = {
        ...action.response.body,
        minimumYearlySalary: toCurrency(
          action.response.body.minimumYearlySalary
        ),
        minimumHourlyWage: toCurrency(action.response.body.minimumHourlyWage),
        matches: action.response.body.matches.map((match) => {
          return {
            ...match,
            matchData: {
              ...match.matchData,
              showMatchChart:
                match.matchData.employmentTypeFit &&
                match.matchData.degreeRankFit &&
                match.matchData.experienceLevelFit,
            },
          };
        }),
      };

      if (index === -1) {
        return {
          ...state,
          seekerLoading: false,
          seeker: seekerViewModel,
          seekerForEdit: seekerViewModel,
        };
      }
      return {
        ...state,
        seeker: seekerViewModel,
        seekerLoading: false,
        seekerForEdit: seekerViewModel,
        seekers: [
          ...state.seekers.slice(0, index),
          seekerViewModel,
          ...state.seekers.slice(index + 1),
        ],
      };
    case types.SEEKERS__UPDATE_FAILURE:
      return state;
    case types.SEEKERS__GET_ALL_SUCCESS:
      return {...state, seekers: action.response.body.seekers};
    case types.SEEKERS__GET_FILTER_COUNTS_REQUEST:
      let activeTabCountLoading = false;
      let inactiveTabCountLoading = false;
      let manageStudentApprovalsCountLoading = false;
      let manageAdminApprovalsCountLoading = false;

      if (state.selectedCandidatesTab === CandidateAdminTabs.ACTIVE) {
        activeTabCountLoading = true;
      }
      if (state.selectedCandidatesTab === CandidateAdminTabs.INACTIVE) {
        inactiveTabCountLoading = true;
      }
      if (state.selectedCandidatesTab === CandidateAdminTabs.MA_APPROVALS) {
        manageStudentApprovalsCountLoading = true;
      }
      if (state.selectedCandidatesTab === CandidateAdminTabs.APPROVALS) {
        manageAdminApprovalsCountLoading = true;
      }
      return {
        ...state,
        tabCountsInitialized: true,
        activeTabCountLoading,
        inactiveTabCountLoading,
        manageStudentApprovalsCountLoading,
        manageAdminApprovalsCountLoading,
      };
    case types.SEEKERS__GET_FILTER_COUNTS_FAILURE:
      return {
        ...state,
        activeTabCountLoading: false,
        inactiveTabCountLoading: false,
        manageStudentApprovalsCountLoading: false,
        manageAdminApprovalsCountLoading: false,
      };
    case types.SEEKERS__GET_FILTER_COUNTS_SUCCESS:
      return {
        ...state,
        ...action.response.body,
        activeTabCountLoading: false,
        inactiveTabCountLoading: false,
        manageStudentApprovalsCountLoading: false,
        manageAdminApprovalsCountLoading: false,
      };
    case types.SEEKERS__GET_ALL_ADVANCED_REQUEST:
      return {
        ...state,
        seekers: [],
        seekersLoading: true,
        selectedCandidatesTab: action.payload.tabName
          ? CandidateAdminTabs[action.payload.tabName.toUpperCase()]
          : state.selectedCandidatesTab,
      };
    case types.SEEKERS__GET_ALL_ADVANCED_SUCCESS:
      return {
        ...state,
        seekersTotalItems: action.response.body.totalItems,
        selectedCandidatesTab: action.payload.tabName
          ? CandidateAdminTabs[action.payload.tabName.toUpperCase()]
          : state.selectedCandidatesTab,
        seekers: action.response.body.seekers.map((seeker) => {
          return {
            ...seeker,
            match: {
              ...seeker.match,
              showMatchChart:
                seeker.match?.employmentTypeFit &&
                seeker.match?.degreeRankFit &&
                seeker.match?.experienceLevelFit,
            },
          };
        }),
        seekersPage: action.response.body.page,
        seekersItemsPerPage: action.response.body.itemsPerPage,
        seekersLoading: false,
      };
    case types.SEEKERS__GET_ALL_ADVANCED_FAILURE:
      return {
        ...state,
        seekers: [],
        seekersLoading: false,
      };
    case types.SEEKERS__DELETE_SUCCESS:
      return {
        ...state,
        seekers: _.filter(state.seekers, (seeker) => {
          return seeker.id !== action.payload.seekerId;
        }),
      };
    case types.SEEKER__RETURNING_USER_EMAIL:
      return {
        ...state,
        userEmailExists: action.payload.userEmailExists,
      };
    case types.SEEKERS__TRAIT_LEVEL_CHANGED:
      seeker.characterTraits[action.trait].level = action.level;
      return {...state, seeker: seeker};
    case types.SEEKERS__TRAIT_NOTE_CHANGED:
      seeker.characterTraits[action.trait].note = action.note;
      return {...state, seeker: seeker};
    case types.SEEKERS__ACHIEVEMENT_NOTE_CHANGED:
      seeker.achievement.note = action.note;
      return {...state, seeker: seeker};
    case types.SEEKERS__INTERESTS_NOTE_CHANGED:
      seeker.interests = action.note;
      return {...state, seeker: seeker};
    case types.SEEKERS__HIGHLIGHTS__CHANGED:
      seeker.highlightsNotes = action.note;
      return {...state, seeker: seeker};
    case types.SEEKERS__ACHIEVEMENT_LEVEL_CHANGED:
      seeker.achievement.level = action.level;
      return {...state, seeker: seeker};
    case types.SEEKERS__CONTACT_INFO_CHANGED:
      seeker.firstName = action.data.firstName;
      seeker.lastName = action.data.lastName;
      seeker.url = action.data.url;
      seeker.location = action.data.location;
      seeker.phone = action.data.phone;
      seeker.email = action.data.email;
      return {...state, seeker: seeker};
    case types.SEEKERS__EDUCATION_CHANGED:
      seeker.graduationMonth = action.data.graduationMonth;
      seeker.graduationYear = action.data.graduationYear;
      seeker.gpa = action.data.gpa;
      seeker.institutionId = action.data.institutionId;
      seeker.educationLevelId = action.data.educationLevelId;
      seeker.majorIds = _.map(seeker.majors, 'id');
      seeker.majorIds[0] = action.data.majorId;
      return {...state, seeker: seeker};
    case types.SEEKERS__EXPERIENCE_CHANGED:
      seeker.experience = action.data;
      return {...state, seeker: seeker};
    case types.SEEKERS__SUMMARY_CHANGED:
      seeker.summary = action.data.summary;
      return {...state, seeker: seeker};
    case types.SEEKERS__RESUME_CHANGED:
      seeker.resume = action.data;
      return {...state, seeker: seeker};
    case types.SEEKERS__AVATAR_CHANGED:
      seeker.avatarUrl = action.data.avatarUrl;
      return {...state, seeker: seeker};
    case types.SEEKERS__SKILLS_CHANGED:
      seeker.skillTags = action.data;
      return {...state, seeker: seeker};
    case types.JOBS__CREATE_WATCH_REQUEST:
      return {...state, watchRequestLoading: true};
    case types.JOBS__CREATE_WATCH_FAILURE:
      return {...state, watchRequestLoading: false};
    case types.JOBS__CREATE_WATCH_SUCCESS:
      const matches = mergeMatches(seeker);
      let newWatchedJob = matches.find((match) => {
        return match.jobId === action.response.body.id;
      });
      if (newWatchedJob) {
        newWatchedJob = {
          ...newWatchedJob,
          id: action.response.body.id,
          name: action.response.body.name,
          published: action.response.body.published,
          matchData: {
            ...newWatchedJob.matchData,
            showMatchChart:
              newWatchedJob.matchData.employmentTypeFit &&
              newWatchedJob.matchData.degreeRankFit &&
              newWatchedJob.matchData.experienceLevelFit,
          },
        };
      }

      return {
        ...state,
        watchRequestLoading: false,
        seeker: {
          ...seeker,
          watchedJobs: [
            ...seeker.watchedJobs,
            newWatchedJob ? {...newWatchedJob} : {...action.response.body},
          ],
        },
        jobStats: {
          ...state.jobStats,
          jobFavorites: state.jobStats.jobFavorites + 1,
        },
      };
    case types.GET_STATUS_SUCCESS:
      return {
        ...state,
        seekerForEdit: {
          ...state.seekerForEdit,
          experience: action.response.data.resume.summary.experience,
        },
      };
    case types.JOBS__DELETE_WATCH_REQUEST:
      return {...state, watchRequestLoading: true};
    case types.JOBS__DELETE_WATCH_FAILURE:
      return {...state, watchRequestLoading: false};
    case types.JOBS__DELETE_WATCH_SUCCESS:
      const deletedJobId = action.payload.jobId;
      newWatchedJobs = _.filter(seeker.watchedJobs, (j) => {
        return j.id !== deletedJobId;
      });
      return {
        ...state,
        watchRequestLoading: false,
        seeker: {
          ...seeker,
          watchedJobs: newWatchedJobs,
        },
        jobStats: {
          ...state.jobStats,
          jobFavorites: state.jobStats.jobFavorites - 1,
        },
      };

    case types.JOBS__DELETE_APPLIED_FAILURE:
    case types.JOBS__CREATE_APPLIED_REQUEST:
      return state;
    case types.JOBS__CREATE_APPLIED_SUCCESS:
      const newAppliedJobId = action.payload.jobId;
      newAppliedJobs = [
        ...seeker.appliedJobs,
        {id: newAppliedJobId, appliedDate: new Date()},
      ];
      return {
        ...state,
        seeker: {
          ...seeker,
          appliedJobs: newAppliedJobs,
        },
      };
    case types.JOBS__CREATE_APPLIED_FAILURE:
    case types.JOBS__DELETE_APPLIED_REQUEST:
    case types.JOBS__DELETE_APPLIED_SUCCESS:
      const deletedAppliedJobId = action.payload.jobId;
      newAppliedJobs = _.filter(seeker.appliedJobs, (j) => {
        return j.id !== deletedAppliedJobId;
      });
      return {
        ...state,
        seeker: {
          ...seeker,
          appliedJobs: newAppliedJobs,
        },
      };
    case types.TEAMS__CREATE_WATCH_SUCCESS:
      const newTeamId = Number(action.payload.teamId);
      newWatchedTeams = [...seeker.watchedTeams, {id: newTeamId}];
      return {
        ...state,
        seeker: {
          ...seeker,
          watchedTeams: newWatchedTeams,
        },
      };
    case types.TEAMS__DELETE_WATCH_SUCCESS:
      const deletedTeamId = Number(action.payload.teamId);
      newWatchedTeams = _.filter(seeker.watchedTeams, (t) => {
        return t.id !== deletedTeamId;
      });
      return {
        ...state,
        seeker: {
          ...seeker,
          watchedTeams: newWatchedTeams,
        },
      };
    case types.TEAMS__CREATE_SEEKER_WATCH_REQUEST:
      return {
        ...state,
        seeker: {
          ...state.seeker,
          teamWatchers: [
            ...state.seeker.teamWatchers,
            {teamId: action.payload.teamId},
          ],
        },
        seekers: state.seekers.map((s) => {
          if (s.id !== action.payload.seekerId) {
            return s;
          }
          return {
            ...s,
            watchingTeamIds: [...s.watchingTeamIds, action.payload.teamId],
          };
        }),
      };
    case types.TEAMS__CREATE_SEEKER_WATCH_SUCCESS:
      newTeamWatchers = [];
      if (seeker.teamWatchers) {
        newTeamWatchers = [
          ...seeker.teamWatchers,
          {
            teamId: action.payload.teamId,
            seekerId: action.payload.seekerId,
          },
        ];
      } else {
        newTeamWatchers = [
          {
            teamId: action.payload.teamId,
            seekerId: action.payload.seekerId,
          },
        ];
      }
      return {
        ...state,
        seeker: {
          ...seeker,
          teamWatchers: newTeamWatchers,
        },
      };
    case types.TEAMS__DELETE_SEEKER_WATCH_REQUEST:
      return {
        ...state,
        seeker: {
          ...state.seeker,
          teamWatchers: state.seeker.teamWatchers.filter((team) => {
            return team.teamId !== action.payload.teamId;
          }),
        },
        seekers: state.seekers.map((s) => {
          if (s.id !== action.payload.seekerId) {
            return s;
          }
          return {
            ...s,
            watchingTeamIds: s.watchingTeamIds.filter(
              (i) => i !== action.payload.teamId
            ),
          };
        }),
      };
    case types.TEAMS__DELETE_SEEKER_WATCH_SUCCESS:
      newTeamWatchers = _.filter(seeker.teamWatchers, (t) => {
        return t.teamId !== action.payload.teamId;
      });
      return {
        ...state,
        seeker: {
          ...seeker,
          teamWatchers: newTeamWatchers,
        },
      };

    case types.EMPLOYERS__CREATE_WATCH_SUCCESS:
      const newEmployerId = Number(action.payload.employerId);
      newWatchedEmployers = [...seeker.watchedEmployers, {id: newEmployerId}];
      return {
        ...state,
        seeker: {
          ...seeker,
          watchedEmployers: newWatchedEmployers,
        },
      };
    case types.EMPLOYERS__DELETE_WATCH_SUCCESS:
      const deletedEmployerId = Number(action.payload.employerId);
      newWatchedEmployers = _.filter(seeker.watchedEmployers, (e) => {
        return e.id !== deletedEmployerId;
      });
      return {
        ...state,
        seeker: {
          ...seeker,
          watchedEmployers: newWatchedEmployers,
        },
      };
    case types.SEEKERS__VALIDATE_CONSENT_REQUEST:
      return {
        ...state,
        isValidConsentRequest: null,
        validatingConsentRequest: true,
      };
    case types.SEEKERS__VALIDATE_CONSENT_SUCCESS:
      return {
        ...state,
        isValidConsentRequest: action.payload.seeker.valid,
        validatingConsentRequest: false,
      };
    case types.SEEKERS__VALIDATE_CONSENT_FAILURE:
      return {
        ...state,
        isValidConsentRequest: false,
        validatingConsentRequest: false,
      };
    case types.SEEKERS__INCREMENT_NOTE_COUNT:
      return {
        ...state,
        seeker: {
          ...state.seeker,
          noteCount:
            state.seeker.id === action.seekerId
              ? state.seeker.noteCount + 1
              : state.seeker.noteCount,
        },
        seekers: state.seekers.map((existingSeeker) => {
          return {
            ...existingSeeker,
            noteCount:
              existingSeeker.id === action.seekerId
                ? existingSeeker.noteCount + 1
                : existingSeeker.noteCount,
          };
        }),
      };
    case types.SEEKERS__TOGGLE_SUPPORT_FLAG:
      return {
        ...state,
        seeker: {
          ...state.seeker,
          seekerSupportFlag:
            state.seeker.id === action.seekerId
              ? !state.seeker.seekerSupportFlag
              : state.seeker.seekerSupportFlag,
        },
        seekers: state.seekers.map((existingSeeker) => {
          return {
            ...existingSeeker,
            seekerSupportFlag:
              existingSeeker.id === action.seekerId
                ? !existingSeeker.seekerSupportFlag
                : existingSeeker.seekerSupportFlag,
          };
        }),
      };
    case types.SEEKERS__DECREMENT_NOTE_COUNT:
      return {
        ...state,
        seeker: {
          ...state.seeker,
          noteCount:
            state.seeker.id === action.seekerId
              ? state.seeker.noteCount - 1
              : state.seeker.noteCount,
        },
        seekers: state.seekers.map((existingSeeker) => {
          return {
            ...existingSeeker,
            noteCount:
              existingSeeker.id === action.seekerId
                ? existingSeeker.noteCount - 1
                : existingSeeker.noteCount,
          };
        }),
      };
    case types.SEEKERS__MIXPANEL_TRACK_CANDIDATE_HOME_PAGE:
      return {
        ...state,
        candidateHomePageTracked: true,
      };
    case types.SEEKERS__UPDATE_ADMIN_APPROVAL:
      return {
        ...state,
        manageAdminApprovalsCount: state.manageAdminApprovalsCount - 1,
        seekers: [...state.seekers.filter((x) => x.id !== action.seeker.id)],
      };
    case types.SEEKERS__UPDATE_APPROVAL:
      seekerIndex = _.findIndex(state.seekers, (seeker) => {
        return seeker.id === action.seeker.id;
      });
      updateSeeker = state.seekers.filter((seeker) => {
        return seeker.id === action.seeker.id;
      })[0];
      updateSeeker = {...updateSeeker, ...action.seeker};
      return {
        ...state,
        seekers: [
          ...state.seekers.slice(0, seekerIndex),
          updateSeeker,
          ...state.seekers.slice(seekerIndex + 1),
        ],
      };
    case types.SEEKERS__INVITE_RESPONSE_REQUEST:
      return {
        ...state,
        seekerLoading: true,
        invitationResponse: [],
      };
    case types.SEEKERS__INVITE_RESPONSE_SUCCESS:
      return {
        ...state,
        seekerLoading: false,
        invitationResponse: action.response.body,
      };
    case types.SEEKERS__INVITE_RESPONSE_FAILURE:
      return {
        ...state,
        seekerLoading: false,
        invitationResponse: [],
      };
    case types.SEEKERS__INVITE_LIST_UPDATED:
      let updateSeekers = [...state.seekers];

      action.seekersToUpdate.forEach((actionSeeker) => {
        seekerIndex = _.findIndex(state.seekers, (seeker) => {
          return seeker.id === actionSeeker.id;
        });

        updateSeeker = state.seekers.filter((seeker) => {
          return seeker.id === actionSeeker.id;
        })[0];

        if (updateSeeker) {
          if (updateSeeker.jobsInvitedToApplyFor) {
            updateSeeker = {
              ...updateSeeker,
              jobsInvitedToApplyFor: [
                ...updateSeeker.jobsInvitedToApplyFor,
                ...actionSeeker.jobsInvitedToApplyFor,
              ],
            };
          } else {
            updateSeeker = {
              ...updateSeeker,
              jobsInvitedToApplyFor: actionSeeker.jobsInvitedToApplyFor,
            };
          }

          updateSeekers = [
            ...updateSeekers.slice(0, seekerIndex),
            updateSeeker,
            ...updateSeekers.slice(seekerIndex + 1),
          ];
        }
      });

      return {
        ...state,
        seekers: updateSeekers,
      };

    case types.SEEKERS__SEEKER_FOR_EDIT_CHANGED:
      return {
        ...state,
        seekerForEdit: action.seekerForEdit,
      };

    case types.SEEKERS__OPT_OUT_REQUEST:
      return {
        ...state,
        optOutLoading: true,
      };
    case types.SEEKERS__OPT_OUT_SUCCESS:
      return {
        ...state,
        optOutLoading: false,
        seeker: {
          ...state.seeker,
          matches: action.response.body.matches,
          invitationMatches: action.response.body.invitationMatches,
        },
      };
    case types.SEEKERS__OPT_OUT_FAILURE:
      return {
        ...state,
        optOutLoading: false,
      };

    case types.SEEKERS__UNDO_OPT_OUT_REQUEST:
      return {
        ...state,
        optOutLoading: true,
      };
    case types.SEEKERS__UNDO_OPT_OUT_SUCCESS:
      return {
        ...state,
        optOutLoading: false,
      };
    case types.SEEKERS__UNDO_OPT_OUT_FAILURE:
      return {
        ...state,
        optOutLoading: false,
      };

    case types.SEEKERS__GET_SAVED_CANDIDATES_REQUEST:
      return {
        ...state,
        savedCandidates: [],
        savedCandidatesLoading: true,
        savedCandidatesRequiresRefresh: false,
      };
    case types.SEEKERS__GET_SAVED_CANDIDATES_SUCCESS:
      return {
        ...state,
        savedCandidatesTotalItems: action.response.body.totalItems,
        savedCandidates: action.response.body.seekers.map((seeker) => {
          return {
            ...seeker,
            match: {
              ...seeker.match,
              showMatchChart:
                seeker.match?.employmentTypeFit &&
                seeker.match?.degreeRankFit &&
                seeker.match?.experienceLevelFit,
            },
            watchingEmployers: seeker.watchingEmployers
              ? seeker.watchingEmployers.filter(
                  (x) =>
                    x.employerId ===
                    parseInt(action.payload.watchingEmployerId, 10)
                )
              : [],
          };
        }),
        savedCandidatesPage: action.response.body.page,
        savedCandidatesItemsPerPage: action.response.body.itemsPerPage,
        savedCandidatesLoading: false,
        savedCandidatesRequiresRefresh: false,
      };
    case types.SEEKERS__GET_SAVED_CANDIDATES_FAILURE:
      return {
        ...state,
        savedCandidates: [],
        savedCandidatesLoading: false,
        savedCandidatesRequiresRefresh: false,
      };

    case types.SEEKERS__SAVED_CANDIDATES_REQUIRES_REFRESH:
      return {
        ...state,
        savedCandidatesRequiresRefresh: action.savedCandidatesRequiresRefresh,
      };

    case types.SEEKERS__CANDIDATE_LIST_FILTER_ACTIVE:
      return {
        ...state,
        candidateListFilterActive: action.active,
      };

    case types.SEEKERS__GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        applicationsLoading: true,
        applicationLoadComplete: false,
        applications: [],
      };
    case types.SEEKERS__GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applicationsLoading: false,
        applicationLoadComplete: true,
        applications: action.response.body,
      };
    case types.SEEKERS__GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        applicationsLoading: false,
        applicationLoadComplete: true,
        applications: [],
      };

    case types.SEEKERS__GET_APPLICATIONS_RESET:
      return {
        ...state,
        applicationsLoading: false,
        applicationLoadComplete: false,
        applications: [],
      };

    case types.SEEKERS__GET_FOR_CONNECTION_REQUEST:
      return {
        ...state,
        seekerLoading: true,
      };
    case types.SEEKERS__GET_FOR_CONNECTION_SUCCESS:
      return {
        ...state,
        seeker: {
          ...action.response.body,
        },
        seekerLoading: false,
      };
    case types.SEEKERS__GET_FOR_CONNECTION_FAILURE:
      return {
        ...state,
        seekerLoading: false,
      };

      case types.SEEKERS__CHANGE_EMAIL_USER_CHANGED:
        return {
          ...state,
          changeEmailUser: action.changeEmailUser,
        };

    default:
      return state;
  }
}
