import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';
import {required} from '../../../utils/intake/intakeCommon';
import {TextField} from '../../../formFields';
import CheckboxField from '../../../formFields/CheckboxField';

class TermsOfUse extends React.Component {
  PARENT_SECTION = 'generalInfoMenu';
  state = {
    submitDisabled: false,
  };

  validate = (values) => {
    const errors = {};
    const requiredFields = ['signature', 'termsOfUse'];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };
  render() {
    const {next, previous, headerImage} = this.props;
    const hasAgreedToTerms =
      this.props.intakeCache[this.PARENT_SECTION] === true &&
      this.props.intakeCache.signature !== undefined &&
      this.props.intakeCache.termsOfUse !== undefined;
    return (
      <>
        <PageHeader headerImage={headerImage} />

        <Form
          onSubmit={next}
          validate={this.validate}
          initialValues={{
            signature: this.props.intakeCache?.signature,
            termsOfUse: this.props.intakeCache?.termsOfUse,
          }}
        >
          {({handleSubmit, form, invalid}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent} style={{height: '100%'}}>
                  <div className={styles.resumeTitle}>
                    Terms of Use & Privacy Policy
                  </div>
                  <p style={{textAlign: 'center'}}>
                    Please review our{' '}
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://ascendindiana.com/terms-of-service"
                    >
                      Terms of Service
                    </a>{' '}
                    &{' '}
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://ascendindiana.com/privacy-policy"
                    >
                      Privacy Policy.
                    </a>
                    <br /> By checking this box you acknowledge that you agree
                    to the terms of use and privacy policy.
                  </p>
                  <div className={`${styles.termsOfUse} form-check`}>
                    <Field
                      name="termsOfUse"
                      component={CheckboxField}
                      validate={required}
                      showBackground={false}
                      style={{}}
                      enabled={!hasAgreedToTerms}
                      className="form-check-input mt-1"
                      type="checkbox"
                    />
                    <label htmlFor="termsOfUse" className="form-check-label">
                      I have read & agree to the{' '}
                      <a
                        className="underline"
                        target="_blank"
                        rel="noreferrer"
                        href="https://ascendindiana.com/terms-of-service"
                      >
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a
                        className="underline"
                        target="_blank"
                        rel="noreferrer"
                        href="https://ascendindiana.com/privacy-policy"
                      >
                        Privacy Policy.
                      </a>
                    </label>
                  </div>

                  <br />
                  <Field
                    label="Sign your full name"
                    name="signature"
                    component={TextField}
                    labelCssClass={'onboarding-form-label'}
                    validate={required}
                    showBackground={false}
                    disabled={hasAgreedToTerms}
                  />
                </div>
                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                  rightButtonDisabled={invalid}
                  rightButtonText="Create Account"
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
TermsOfUse.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any,
  headerImage: PropTypes.any,
};

export default connect()(TermsOfUse);
