import _ from 'lodash';
import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function createEmployer(employer) {
  return {
    types: [
      types.EMPLOYERS__CREATE_REQUEST,
      types.EMPLOYERS__CREATE_SUCCESS,
      types.EMPLOYERS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/employers`,
    method: 'POST',
    body: employer,
    payload: {employer},
    successMessage: 'Employer created.',
    failureMessage: 'Error creating employer.',
  };
}

export function createEmployerWatch(employerId) {
  return {
    types: [
      types.EMPLOYERS__CREATE_WATCH_REQUEST,
      types.EMPLOYERS__CREATE_WATCH_SUCCESS,
      types.EMPLOYERS__CREATE_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}/watches`,
    method: 'POST',
    body: {},
    payload: {employerId},
    successMessage: 'Employer added to favorites',
    failureMessage: 'Error adding employer to favorites',
  };
}

export function getAllEmployers() {
  return {
    types: [
      types.EMPLOYERS__GET_ALL_REQUEST,
      types.EMPLOYERS__GET_ALL_SUCCESS,
      types.EMPLOYERS__GET_ALL_FAILURE,
    ],
    uri: `${apiUrl}/employers?all`,
    method: 'GET',
  };
}

export function getEmployersForSeeker(seekerId) {
  return {
    types: [
      types.EMPLOYERS__GET_ALL_REQUEST,
      types.EMPLOYERS__GET_ALL_SUCCESS,
      types.EMPLOYERS__GET_ALL_FAILURE,
    ],
    uri: `${apiUrl}/employers/for_seeker/${seekerId}?all`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getEmployerForEdit(employerId) {
  return {
    types: [
      types.EMPLOYERS__GET_FOR_EDIT_REQUEST,
      types.EMPLOYERS__GET_FOR_EDIT_SUCCESS,
      types.EMPLOYERS__GET_FOR_EDIT_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}`,
    method: 'GET',
    payload: {employerId},
  };
}

export function getEmployersAdvanced(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false,
  segments = ''
) {
  let uri = `${apiUrl}/employers`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }
  if (segments !== '') {
    p.push('segmentIds=' + segments);
  }
  _.forEach(filters, (value, key) => {
    p.push(`${key}=${encodeURIComponent(value)}`);
  });

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.EMPLOYERS__GET_ALL_ADVANCED_REQUEST,
      types.EMPLOYERS__GET_ALL_ADVANCED_SUCCESS,
      types.EMPLOYERS__GET_ALL_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getEmployersStats() {
  return {
    types: [
      types.EMPLOYERS__GET_STATS_REQUEST,
      types.EMPLOYERS__GET_STATS_SUCCESS,
      types.EMPLOYERS__GET_STATS_FAILURE,
    ],
    uri: `${apiUrl}/employers/stats`,
    method: 'GET',
  };
}

export function getEmployer(employerId) {
  return {
    types: [
      types.EMPLOYERS__GET_REQUEST,
      types.EMPLOYERS__GET_SUCCESS,
      types.EMPLOYERS__GET_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}`,
    method: 'GET',
    payload: {employerId},
  };
}

export function getEmployerToCache(employerId) {
  return {
    types: [
      types.EMPLOYERS__GET_TO_CACHE_REQUEST,
      types.EMPLOYERS__GET_TO_CACHE_SUCCESS,
      types.EMPLOYERS__GET_TO_CACHE_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}`,
    method: 'GET',
    payload: {employerId},
  };
}

export function getEmployerReports(employerId = null) {
  if (employerId) {
    return {
      types: [
        types.EMPLOYERS__GET_REPORTS_REQUEST,
        types.EMPLOYERS__GET_REPORTS_SUCCESS,
        types.EMPLOYERS__GET_REPORTS_FAILURE,
      ],
      uri: `${apiUrl}/employers/${employerId}/reports`,
      method: 'GET',
      payload: {employerId},
    };
  } else {
    return {
      types: [
        types.EMPLOYERS__GET_REPORTS_REQUEST,
        types.EMPLOYERS__GET_REPORTS_SUCCESS,
        types.EMPLOYERS__GET_REPORTS_FAILURE,
      ],
      uri: `${apiUrl}/employers/reports`,
      method: 'GET',
    };
  }
}

export function getEmployerApplications(employerId) {
  return {
    types: [
      types.EMPLOYERS__GET_APPLICATIONS_REQUEST,
      types.EMPLOYERS__GET_APPLICATIONS_SUCCESS,
      types.EMPLOYERS__GET_APPLICATIONS_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}/applications`,
    method: 'GET',
    payload: {employerId},
  };
}

const cleanForUpdate = (employer) => {
  const illegalFields = [
    'id',
    'updatedAt',
    'createdAt',
    'deletedAt',
    'publishedAt',
    'draftJobCount',
    'publishedJobCount',
    'draftTeamCount',
    'publishedTeamCount',
    'industries',
    'regions',
    'isActive',
    'crmUpdatedAt',
    'crmId',
    'strategicBusinessUnit',
  ];
  let cleaned = JSON.parse(JSON.stringify(employer));
  _.forEach(Object.keys(cleaned), (key) => {
    if (_.includes(illegalFields, key)) delete cleaned[key];
  });

  return cleaned;
};

export function updateEmployer(employer) {
  let body = cleanForUpdate(employer);

  return {
    types: [
      types.EMPLOYERS__UPDATE_REQUEST,
      types.EMPLOYERS__UPDATE_SUCCESS,
      types.EMPLOYERS__UPDATE_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employer.id}`,
    method: 'PATCH',
    body: body,
    payload: {employer},
    successMessage: 'Employer saved.',
    failureMessage: 'Error saving employer.',
  };
}

export function deleteEmployer(employerId) {
  return {
    types: [
      types.EMPLOYERS__DELETE_REQUEST,
      types.EMPLOYERS__DELETE_SUCCESS,
      types.EMPLOYERS__DELETE_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}`,
    method: 'DELETE',
    payload: {employerId},
    successMessage: 'Employer deleted.',
    failureMessage: 'Error deleting employer.',
  };
}

export function deleteEmployerWatch(employerId) {
  return {
    types: [
      types.EMPLOYERS__DELETE_WATCH_REQUEST,
      types.EMPLOYERS__DELETE_WATCH_SUCCESS,
      types.EMPLOYERS__DELETE_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}/watches`,
    method: 'DELETE',
    payload: {employerId},
    successMessage: 'Employer removed from favorites',
    failureMessage: 'Error removing employer from favorites',
  };
}

export function getEmployerLocations(employerId) {
  return {
    types: [
      types.EMPLOYERS__GET_LOCATIONS_REQUEST,
      types.EMPLOYERS__GET_LOCATIONS_SUCCESS,
      types.EMPLOYERS__GET_LOCATIONS_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}/location_options`,
    method: 'GET',
    payload: {employerId},
  };
}
export function getEmployersActiveJobs(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];

  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (includeUnPublished) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.EMPLOYERS__GET_ACTIVE_JOBS_REQUEST,
      types.EMPLOYERS__GET_ACTIVE_JOBS_SUCCESS,
      types.EMPLOYERS__GET_ACTIVE_JOBS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getEmployerStatsById(employerId) {
  return {
    types: [
      types.EMPLOYERS__GET_EMPLOYER_STATS_REQUEST,
      types.EMPLOYERS__GET_EMPLOYER_STATS_SUCCESS,
      types.EMPLOYERS__GET_EMPLOYER_STATS_FAILURE,
    ],
    uri: `${apiUrl}/employers/${employerId}/stats`,
    method: 'GET',
    payload: {employerId},
  };
}
