import React from 'react';
import PropTypes from 'prop-types';
import {AscendModal, AscendModalBody} from '../../../layout';
import Select from 'react-select';
import _ from 'lodash';

import {getSortedLookup} from '../../../utils/fieldHelper';

class AssignForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ascendRecruiterId: props.ascendRecruiterId
        ? props.ascendRecruiterId
        : '0',
      dropdownOpen: false,
    };
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.ascendRecruiterId);
  };

  handleChange = (selectedOption) => {
    this.setState({ascendRecruiterId: selectedOption['value']});
  };

  filteredAscendRecruitersBySegment = () => {
    if (this.props.selectedItem === null) return this.props.ascendRecruiters;
    if (this.props.profile === null) return [];
    if (this.props.profile.securityRoleNames.includes('ADMIN')) {
      return this.props.ascendRecruiters;
    }

    return this.props.ascendRecruiters.filter((recruiter) => {
      return (
        recruiter.securityRoleNames.includes('ADMIN') ||
        recruiter.segments.some(
          (recruiterSegment) =>
            this.props.profile.segmentIds.includes(recruiterSegment.id) ||
            this.props.selectedItem.segments
              .map((seekerSegment) => seekerSegment.id)
              .includes(recruiterSegment.id)
        )
      );
    });
  };

  render() {
    const ascendRecruiters = getSortedLookup(
      this.filteredAscendRecruitersBySegment(),
      ['name']
    );

    return (
      <AscendModal isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AscendModalBody hasClose={true}>
          <div className="padding-32">
            <center>
              <div
                className="normal-title padding-top-thirtytwo padding-bottom-eight bold"
                id="assignLabel"
              >
                Assign Manager
              </div>

              <hr />
            </center>

            <div className="row padding-top-sixteen">
              <div className="col-md-3" />
              <div className="col-md-6">
                <Select
                  className="clickable normal-subheader"
                  value={
                    this.state.ascendRecruiterId
                      ? _.find(ascendRecruiters, {
                          value: this.state.ascendRecruiterId,
                        })
                      : null
                  }
                  onChange={this.handleChange}
                  options={ascendRecruiters}
                  aria-labelledby="assignLabel"
                />
              </div>
              <div className="col-md-3" />
            </div>

            <div className="row padding-top-sixteen">
              <div className="col-md-12">
                <button
                  type="button"
                  className="clickable btn btn-primary float-right"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

AssignForm.propTypes = {
  isOpen: PropTypes.bool,
  ascendRecruiterId: PropTypes.number,
  ascendRecruiters: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  profile: PropTypes.object,
};

AssignForm.defaultProps = {
  isOpen: false,
  selectedItem: null,
  profile: null,
};

export default AssignForm;
