import React from 'react';
import PropTypes from 'prop-types';

import AscendDataTable from 'src/components/AscendDataTable';

const ReportsTable = ({title, reports, itemsLoading}) => {
  const columns = [
    {
      displayName: 'REPORT',
      content: (report) => report.label,
    },
    {
      displayName: 'DOWNLOAD LINK',
      content: (report) => (
        <a href={report.url} className="clickable active-subheader underlined">
          Download Report
        </a>
      ),
    },
  ];

  return (
    <div>
      <h2 className="normal-headline bold float-left" tabIndex={0}>
        {title}
      </h2>
      <br />
      <AscendDataTable
        tableColumns={columns}
        items={reports}
        enableSort={false}
        showSearch={false}
        showPageControl={false}
        enableExpand={false}
        itemsLoading={itemsLoading}
      />
    </div>
  );
};

ReportsTable.propTypes = {
  title: PropTypes.string.isRequired,
  reports: PropTypes.array.isRequired,
  itemsLoading: PropTypes.bool,
};

ReportsTable.defaultProps = {
  itemsLoading: false,
};

export default ReportsTable;
