import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import SkillsModal from './SkillsModal';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSeekerAction,
  interviewSkillsChanged,
  updateSeekerAction,
} from 'src/actions/SeekerActions';
import styles from './RecommendedEndCard.module.scss';

export default function RecommendedEndCard({seekerId}) {
  const dispatch = useDispatch();
  const [skillsModalIsOpen, setSkillsModalIsOpen] = useState(false);

  let seeker = useSelector((state) => state.seekers.seeker);

  useEffect(() => {
    if (seeker == null) {
      dispatch(getSeekerAction(seekerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkillsSubmit = (values) => {
    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    dispatch(updateSeekerAction(updatedSeeker));
    setSkillsModalIsOpen(false);
  };

  return (
    <>
      <SkillsModal
        isOpen={skillsModalIsOpen}
        onClose={() => {
          setSkillsModalIsOpen(false);
        }}
        selectedSkills={seeker.skillTags ? seeker.skillTags : []}
        onChange={(selectedSkills) => {
          dispatch(interviewSkillsChanged(selectedSkills));
        }}
        onSubmit={handleSkillsSubmit}
      />

      <div
        style={{height: '127px', minWidth: '240px'}}
        className={`container bd-whitebackground pt-1 pb-1`}
      >
        <div
          className={`row ${styles.endCardText}`}
          style={{
            display: 'flex',
            justifyContent: 'center', // Centers content horizontally
            textAlign: 'center', // Center text horizontally if flex doesn't cover it
          }}
        >
          Get more matches by editing your skills or expanding your preferences.
        </div>

        <div className={`row ${styles.buttonRow}`}>
          <button
            onClick={() => setSkillsModalIsOpen(true)}
            className={`btn btn-secondary col-5 ${styles.leftButton}`}
          >
            Edit Skills
          </button>
          <Link
            to={'/profile'}
            className={`btn btn-secondary col-5 ${styles.rightButton}`}
          >
            View Preferences
          </Link>
        </div>
      </div>
    </>
  );
}

RecommendedEndCard.propTypes = {
  seekerId: PropTypes.string.isRequired,
};
