import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import navLeft from '../assets/images/nav-left.svg';
import navRight from '../assets/images/nav-right.svg';
import DidYouApplyCard from './DidYouApplyCard';
import styles from './DidYouApply.module.scss';
import {useIsMobile} from 'src/utils/commonHooks';
import PropTypes from 'prop-types';
import {getAppliedToClicks, removeAppliedToClick} from 'src/actions/jobs';
import classNames from 'classnames';
import {AppliedSvg} from 'src/assets/svg/svgComponents';

export default function DidYouApply({
  handleJobClick,
  maxHorizontalCardDisplay,
  seekerId,
}) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(991);
  const isSmallScreen = useIsMobile(1200);
  let {appliedToClicks, appliedToClicksLoading, appliedToClickCount} =
    useSelector((state) => {
      return {
        appliedToClicks: state.jobs.appliedToClicks,
        appliedToClicksLoading: state.jobs.appliedToClicksLoading,
        appliedToClickCount: state.jobs.appliedToClickCount,
      };
    });
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isLandscape = () =>
    window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(
    isLandscape() ? 'landscape' : 'portrait'
  );

  const getCardIndexCountToSubtract = useCallback(() => {
    if (isMobile) {
      return 4;
    }

    if (isSmallScreen) {
      return 2;
    }

    return maxHorizontalCardDisplay;
  }, [isSmallScreen, isMobile, maxHorizontalCardDisplay]);

  const handleClick = useCallback(
    (direction) => {
      const carousel = document.querySelector('#carousel');
      if (carousel === null) return;
      const cards = carousel.children;
      let newIndex = currentIndex;

      const screenSizeCardAdjustment = getCardIndexCountToSubtract();

      if (direction === 'previous') {
        newIndex = currentIndex - 1;

        if (newIndex < 0) {
          newIndex = appliedToClicks.length - screenSizeCardAdjustment;
        }
        setCurrentIndex(newIndex);
      } else if (direction === 'next') {
        newIndex = currentIndex + 1;

        if (newIndex > appliedToClicks.length - screenSizeCardAdjustment) {
          newIndex = 0;
        }
        setCurrentIndex(newIndex);
      }

      Object.values(cards).forEach((card) => {
        card.style.transform = isMobile
          ? `translateY(-${149 * newIndex}px)`
          : `translateX(-${231 * newIndex + 15}px)`;
      });
    },
    [
      appliedToClicks.length,
      currentIndex,
      isMobile,
      getCardIndexCountToSubtract,
    ]
  );

  useEffect(() => {
    if (orientation !== (isLandscape() ? 'landscape' : 'portrait')) {
      handleClick('');
      setOrientation(isLandscape() ? 'landscape' : 'portrait');
    }
    if (!hasLoadedOnce && !appliedToClicksLoading) {
      dispatch(getAppliedToClicks(seekerId));
      setHasLoadedOnce(true);
    }
  }, [
    dispatch,
    appliedToClicks.length,
    appliedToClicksLoading,
    hasLoadedOnce,
    appliedToClicks,
    handleClick,
    orientation,
    seekerId,
  ]);

  const handleDismiss = (id) => {
    let dismissedElement = document.getElementById(`${id}`);
    dismissedElement.style.opacity = 0;
    dismissedElement.style.transition = 'opacity 200 ease-out';
    dismissedElement.ontransitionend = () => {
      dispatch(removeAppliedToClick(id));

      if (currentIndex + 3 - (appliedToClicks.length - 1) === 0) {
        handleClick('previous');
      }
      if (appliedToClicks.length - 1 <= 4) {
        const carousel = document.querySelector('#carousel');
        if (carousel === null) return;
        const cards = carousel.children;
        const newIndex = 0;
        setCurrentIndex(newIndex);
        Object.values(cards).forEach((card) => {
          card.style.transform = isMobile
            ? `translateY(-${149 * newIndex}px)`
            : `translateX(-${231 * newIndex + 15}px)`;
        });
      }
    };
  };

  return (
    !appliedToClicksLoading &&
    appliedToClicks.length > 0 && (
      <>
        <div className="row">
          <h2 className="font-20 bold padding-bottom-sixteen no-decoration col-12 d-flex align-items-center">
            <AppliedSvg
              width={'20px'}
              height={'20px'}
              className={styles.appliedSvg}
            />{' '}
            &nbsp; Did you apply to these roles?&nbsp;&nbsp;
            <span className="normal-caption">{appliedToClickCount}</span>
          </h2>
        </div>
        <div
          className={`row ${styles.desktopContainer}`}
          style={
            appliedToClicks.length > 4 && isMobile ? {height: '596px'} : {}
          }
        >
          {!isMobile && (
            <div className="col-auto my-auto" style={{marginRight: 'auto'}}>
              <button
                tabIndex={0}
                className="buttonAsLink pt-2"
                onClick={() => handleClick('previous')}
              >
                <img alt="left" width="50px" height="50px" src={navLeft} />
              </button>
            </div>
          )}
          <div
            id="carousel"
            className={classNames(['col-lg', styles.carousel])}
          >
            {appliedToClicks.map((job) => {
              return (
                <div
                  className={`pb-3 col-auto ${styles.didYouApplyCard}`}
                  key={job.id}
                  id={job.id}
                >
                  <DidYouApplyCard
                    job={job}
                    handleDismiss={() => handleDismiss(job.id)}
                    handleJobClick={handleJobClick}
                  />
                </div>
              );
            })}
          </div>

          {!isMobile && (
            <div className="my-auto col-auto" style={{marginLeft: 'auto'}}>
              <button
                tabIndex={0}
                className="buttonAsLink pt-2"
                onClick={() => handleClick('next')}
              >
                <img alt="right" width="50px" height="50px" src={navRight} />
              </button>
            </div>
          )}
        </div>

        {isMobile && appliedToClicks.length > 4 && (
          <div className="row" style={{paddingBottom: '15px'}}>
            <div
              className="my-auto col"
              style={{marginRight: 'auto', paddingLeft: '15px'}}
            >
              <button
                tabIndex={0}
                className="buttonAsLink pt-2"
                onClick={() => handleClick('previous')}
              >
                <img alt="left" width="50px" height="50px" src={navLeft} />
              </button>
            </div>

            <div
              className="my-auto col"
              style={{marginLeft: 'auto', paddingRight: '15px'}}
            >
              <button
                tabIndex={0}
                className="buttonAsLink pt-2 float-right"
                onClick={() => handleClick('next')}
              >
                <img alt="right" width="50px" height="50px" src={navRight} />
              </button>
            </div>
          </div>
        )}
      </>
    )
  );
}

DidYouApply.propTypes = {
  handleJobClick: PropTypes.func,
  maxHorizontalCardDisplay: PropTypes.number,
  seekerId: PropTypes.string,
};
