import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

class SideBarDisplayItem extends React.Component {
  renderRow = (content, title) => {
    const {whiteBackground} = this.props;
    return (
      <div
        className={`${
          whiteBackground
            ? 'bd-whitebackground-nopadding'
            : 'bd-graybackground-nopadding'
        } padding-bottom-eight`}
      >
        <div
          className={`${
            whiteBackground ? 'bd-whitebackground' : 'bd-med-graybackground'
          } padding-16`}
        >
          <div className="normal-body">{title}</div>
          <div className="disabled-caption">{content || 'Not selected'}</div>
        </div>
      </div>
    );
  };

  renderDetails = (displayText, title, role) => {
    return role === 'ASCEND_RECRUITER' && this.renderRow(displayText, title);
  };

  render() {
    const {displayText, title, role} = this.props;
    return <Fragment>{this.renderDetails(displayText, title, role)}</Fragment>;
  }
}

SideBarDisplayItem.propTypes = {
  role: PropTypes.string.isRequired,
  title: PropTypes.string,
  whiteBackground: PropTypes.bool,
  displayText: PropTypes.string,
};

SideBarDisplayItem.defaultProps = {
  whiteBackground: true,
  title: '',
  displayText: '',
};

export default SideBarDisplayItem;
