import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';

import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  regions: [],
  regionsLoading: false,
};

export default function regions(state = initialState, action) {
  switch (action.type) {
    case types.REGIONS__GET_REQUEST:
      return {...state, regions: [], regionsLoading: true};
    case types.REGIONS__GET_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        regions: action.response.body.regions,
        regionsLoading: false,
      };
    default:
      return state;
  }
}
