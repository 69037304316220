export const remoteStatusTypes = [
  {
    id: 1,
    name: 'In-Person',
    value: 'In-Person',
    label: 'In-Person',
  },
  {
    id: 2,
    name: 'Hybrid or Partially Remote',
    value: 'Hybrid or Partially Remote',
    label: 'Hybrid or Partially Remote',
  },
  {
    id: 3,
    name: 'Temporarily Remote',
    value: 'Temporarily Remote',
    label: 'Temporarily Remote',
  },
  {
    id: 4,
    name: 'Remote',
    value: 'Remote',
    label: 'Remote',
  },
];

export const getLocationText = (remoteStatus) => {
  if (remoteStatus === 'Remote') {
    return remoteStatus;
  }
  if (remoteStatus && remoteStatus !== 'In-Person') {
    return `${remoteStatus} in `;
  }

  return '';
};
export const buildRemoteWorkList = (statusList) => {
  if (!statusList) return '';

  let filterList = '';

  Object.keys(statusList).forEach(function (key, index) {
    if (statusList[key].selected) {
      filterList += `${key},`;
    }
  });

  return filterList.slice(0, -1);
};
