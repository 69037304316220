import React, {useState, useEffect, forwardRef} from 'react';
import style from './CheckboxSelectAscend.module.scss';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import {ChevronDownSvg} from '../../../assets/svg/svgComponents';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownSvg className={style.settingsSvg_fill_gray} />
    </components.DropdownIndicator>
  );
};

const Option = ({children, selectProps, ...props}) => {
  return (
    components.Option && (
      <components.Option
        {...props}
        style={{
          paddingLeft: selectProps.showGlyph ? '35px' : '10px',
        }}
      >
        <div className="custom-control custom-checkbox form-check">
          <input
            id={props.value}
            name={props.label}
            type="checkbox"
            className="custom-control-input form-check-input checkbox-formatting"
            checked={selectProps.selectedTags?.some(
              (x) => x.value === props.value
            )}
            onChange={() => null}
          />
          <label
            className="custom-control-label form-check-label"
            htmlFor={props.label}
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    )
  );
};

Option.propTypes = {
  selectProps: PropTypes.any,
  children: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.any,
};

const ValueContainer = ({children, selectProps, ...props}) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer
        {...props}
        selectProps={selectProps}
        style={{
          paddingLeft: selectProps.showGlyph ? '35px' : '10px',
        }}
      >
        {!!children && (
          <>
            <i
              style={{
                position: 'absolute',
                left: 10,
              }}
            >
              {selectProps.selectorGlyph}
            </i>
          </>
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

ValueContainer.propTypes = {
  selectProps: PropTypes.any,
  children: PropTypes.any,
};

const valueContainerStyle = {
  valueContainer: (provided, state) => {
    return {
      ...provided,
      ...state.style,
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      zIndex: '0',
      color: 'var(--neutral-gray-3)',
    };
  },
};
const placeholderStyle = {
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--neutral-gray-3)',
  }),
};

const SelectAscend = forwardRef(
  (
    {
      placeholder,
      isLoading,
      isDisabled,
      closeMenuOnSelect,
      value,
      onChange,
      options,
      styles,
      className,
      defaultValue,
      selectorGlyph,
      name,
      labelText,
      id,
      selectedTags,
      isSearchable,
      ariaLabeledBy,
    },
    ref
  ) => {
    const [showGlyph, setShowGlyph] = useState(false);
    useEffect(() => {
      if (selectorGlyph === undefined) {
        setShowGlyph(false);
        return;
      }
      setShowGlyph(true);
    }, [selectorGlyph]);

    return (
      <Select
        inputId={id}
        name={name}
        selectorGlyph={selectorGlyph}
        showGlyph={showGlyph}
        aria-label={labelText}
        className={className}
        defaultValue={defaultValue}
        components={{
          DropdownIndicator,
          ValueContainer,
          Option,
        }}
        styles={Object.assign(valueContainerStyle, placeholderStyle, styles)}
        options={options}
        selectedTags={selectedTags}
        onChange={onChange}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={isDisabled}
        value={value}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        isSearchable={isSearchable}
        aria-labelledby={ariaLabeledBy}
      />
    );
  }
);

SelectAscend.propTypes = {
  labelText: PropTypes.string,
  children: PropTypes.any,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.any,
  styles: PropTypes.any,
  className: PropTypes.any,
  defaultValue: PropTypes.any,
  selectorGlyph: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  selectedTags: PropTypes.any,
  isSearchable: PropTypes.bool,
  ariaLabeledBy: PropTypes.string,
};

SelectAscend.defaultProps = {
  closeMenuOnSelect: true,
  isSearchable: true,
};

export default SelectAscend;
