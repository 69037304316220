import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import RenderHtml from '../../components/controls/RenderHtml';

class ApplyToJobPageOne extends Component {
  render() {
    const {applicationData, isMobile} = this.props;

    return (
      <Fragment>
        <RenderHtml
          htmlString={applicationData.description}
          className="padding-fortyeight"
        />

        <div
          className={`col-md-12 ${
            isMobile ? 'd-flex justify-content-center' : ''
          } `}
        >
          <button
            type="button"
            style={{
              width: '210px',
              height: '40px',
              marginRight: isMobile ? 'initial' : '40px',
              marginBottom: '10px',
            }}
            className={`clickable btn btn-primary ${
              isMobile ? '' : 'float-right'
            }`}
            onClick={this.props.onNextPage}
          >
            Continue
          </button>
        </div>
      </Fragment>
    );
  }
}

ApplyToJobPageOne.propTypes = {
  applicationData: PropTypes.object,
  onNextPage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

ApplyToJobPageOne.defaultProps = {
  applicationData: {},
};

const mapStateToProps = (state) => {
  return {
    applicationData: state.jobs.applicationData,
  };
};

export default connect(mapStateToProps)(ApplyToJobPageOne);
