import React from 'react';
import PropTypes from 'prop-types';

import {ProfileSvg} from 'src/assets/svg/svgComponents';

class ListBatchActionDrawer extends React.Component {
  render() {
    const {
      selectionCount,
      handleSelectAllClick,
      handleClearAllClick,
      batchSelectMaximumCount,
      isLoading,
    } = this.props;

    return (
      <>
        <div className="anchoredFooterContainer">
          <div className="anchoredFooterContents col-md-12">
            <span
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ProfileSvg style={{paddingRight: '2px'}} />
              {selectionCount}
              &nbsp;{this.props.noun} Selected
            </span>
            {selectionCount >= batchSelectMaximumCount && (
              <>
                <span
                  style={{
                    marginRight: '16px',
                    color: '#db0300',
                    fontSize: '0.9rem',
                    paddingLeft: '16px',
                  }}
                >
                  Maximum of&nbsp;
                  {batchSelectMaximumCount} Selected &nbsp;
                </span>
              </>
            )}
            <button
              className="buttonAsLink"
              onClick={() => handleSelectAllClick()}
              disabled={isLoading}
              style={{marginLeft: '16px'}}
            >
              <span>Select All on Page</span>
            </button>
            <div
              className="col-md-1"
              style={{flexBasis: 'fit-content', margin: '0 16px 0 16px'}}
            >
              <button
                className="buttonAsLink"
                onClick={() => handleClearAllClick()}
                disabled={isLoading}
              >
                <span>Clear</span>
              </button>
            </div>
            <div
              className="col-md-2"
              style={{flexBasis: 'fit-content', margin: '0 16px 0 16px'}}
            >
              <button
                className="clickable btn btn-primary nowrap"
                onClick={() => this.props.handleBatchInviteClicked()}
                disabled={selectionCount === 0 || isLoading}
              >
                {this.props.buttonText}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ListBatchActionDrawer.propTypes = {
  selectionCount: PropTypes.number,
  handleSelectAllClick: PropTypes.func,
  handleClearAllClick: PropTypes.func,
  batchSelectMaximumCount: PropTypes.number,
  handleBatchInviteClicked: PropTypes.func,
  isLoading: PropTypes.bool,
  noun: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default ListBatchActionDrawer;
