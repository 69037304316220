import * as types from '../actions/ActionTypes';

const initialState = {
  canvas: {},
  showBanner: true
};

const UtilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UTILITY__UPDATE_CANVAS:
      return {
        ...state,
        canvas: action.canvas,
      };
    case types.UTILITY__HIDE_BANNER:
      return {
        ...state,
        showBanner: false,
      };

    default:
      return state;
  }
};

export default UtilityReducer;
