import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styles from './NotificationSettingsPage.module.scss';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import * as Layout from '../../layout';
import OnOffSwitch from '../../formFields/OnOffSwitch';
import {Form, Field} from 'react-final-form';
import * as ACTIONS from '../../actions/ActionTypes';
import {SelectField} from '../../formFields';
import {AscendModal, AscendModalBody} from '../../layout';
import {OnChange} from 'react-final-form-listeners';

import {
  setProfileNotificationOptions,
  getProfileNotificationOptions,
} from '../../actions/profile';
import {
  getRecruiterNotificationOptionsById,
  setRecruiterProfileNotificationOptions,
} from '../../actions/recruiters';
import {ErrorSvg} from 'src/assets/svg/svgComponents';
import LoadingOverlay from 'react-loading-overlay';
import PreviousButton from 'src/components/PreviousButton';
import DocumentTitle from 'src/components/vendor/DocumentTitle';

function CandidateNotifications(props: {
  handleToggle: () => void,
  apprenticeProgram: any,
  options: [{label: string, value: string}, {label: string, value: string}],
}) {
  return (
    <>
      <div className={styles.option}>
        <span>
          <b>Invitations to Apply - Employer</b> <br />
          Send email when an employer invites me to apply to a position.
        </span>
        <Field
          name="INVITE_TO_APPLY_EMPLOYER_NOTIFICATION"
          component={OnOffSwitch}
          selectedValue=""
          unSelectedValue=""
        />
        <OnChange name="INVITE_TO_APPLY_EMPLOYER_NOTIFICATION">
          {props.handleToggle}
        </OnChange>
      </div>
      <div className={styles.option}>
        <span>
          <b>Invitations to Apply - Ascend</b> <br />
          Send email when my recruiter invites me to apply to a role they
          recommend.
        </span>
        <Field
          name="INVITE_TO_APPLY_ASCEND_NOTIFICATION"
          component={OnOffSwitch}
          selectedValue=""
          unSelectedValue=""
        />
        <OnChange name="INVITE_TO_APPLY_ASCEND_NOTIFICATION">
          {props.handleToggle}
        </OnChange>
      </div>
      <div className={styles.option}>
        <span>
          <b>Profile Saved</b> <br />
          Send email when an employer saves my profile in the Network.
        </span>
        <Field
          name="PROFILE_SAVED"
          component={OnOffSwitch}
          selectedValue=""
          unSelectedValue=""
        />
        <OnChange name="PROFILE_SAVED">{props.handleToggle}</OnChange>
      </div>
      <div className={styles.option}>
        <span>
          <b>Favorited Role Reminder</b> <br />
          Send reminder email for Favorited roles.
        </span>
        <Field
          name="SAVED_ROLE_SEEKER_NOTIFICATION"
          component={OnOffSwitch}
          selectedValue=""
          unSelectedValue=""
        />
        <OnChange name="SAVED_ROLE_SEEKER_NOTIFICATION">
          {props.handleToggle}
        </OnChange>
      </div>
      <div className={styles.option}>
        <span>
          <b>Unpublished Roles</b> <br />
          Send email for roles applied to that are no longer available.
        </span>
        <Field
          name="UNPUBLISHED_ROLE_SEEKER_NOTIFICATION"
          component={OnOffSwitch}
          selectedValue=""
          unSelectedValue=""
        />
        <OnChange name="UNPUBLISHED_ROLE_SEEKER_NOTIFICATION">
          {props.handleToggle}
        </OnChange>
      </div>
      {props.apprenticeProgram === false && (
        <>
          <div className={styles.option}>
            <span>
              <b>New Role Recommendations</b> <br />
              Send email with new roles I match to based on my skills and
              preferences.
            </span>
            <Field
              name="NEW_ROLE_NOTIFICATION"
              component={OnOffSwitch}
              selectedValue=""
              unSelectedValue=""
            />
            <OnChange name="NEW_ROLE_NOTIFICATION">
              {props.handleToggle}
            </OnChange>
          </div>

          <div className={styles.select}>
            <div className={styles.frequencyLabel}>Frequency:</div>
            <div>
              <Field
                name="NEW_ROLE_NOTIFICATION_FREQUENCY"
                minimalistStyle={true}
                component={SelectField}
                options={props.options}
                defaultValue={'Weekly'}
              />

              <OnChange name="NEW_ROLE_NOTIFICATION_FREQUENCY">
                {props.handleToggle}
              </OnChange>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function RecruiterNotifications(props: {
  handleToggle: () => void,
  apprenticeProgram: any,
  options: [{label: string, value: string}, {label: string, value: string}],
}) {
  return (
    <>
      <div className={styles.option}>
        <span style={{display: 'inline-block', width: '85%'}}>
          <b>Received a New Application</b> <br />
          Send email when a job seeker applies to a job or internship.
          <br />
          Apprenticeships (if applicable) are not included.
        </span>
        <span
          style={{display: 'inline-block', width: '15%', textAlign: 'right'}}
        >
          <Field
            name="RECEIVED_NEW_APPLICATION_NOTIFICATION"
            component={OnOffSwitch}
            selectedValue=""
            unSelectedValue=""
          />
          <OnChange name="RECEIVED_NEW_APPLICATION_NOTIFICATION">
            {props.handleToggle}
          </OnChange>
        </span>
      </div>
      <div className={styles.option}>
        <span style={{display: 'inline-block', width: '85%'}}>
          <b>Received a New Application – Apprenticeships Only</b> <br />
          Send email when a student applies to a Modern Apprenticeship (if
          applicable).
        </span>
        <span
          style={{display: 'inline-block', width: '15%', textAlign: 'right'}}
        >
          <Field
            name="RECEIVED_NEW_APPRENTICESHIP_APPLICATION_NOTIFICATION"
            component={OnOffSwitch}
            selectedValue=""
            unSelectedValue=""
          />
          <OnChange name="RECEIVED_NEW_APPRENTICESHIP_APPLICATION_NOTIFICATION">
            {props.handleToggle}
          </OnChange>
        </span>
      </div>
      <div className={styles.option}>
        <span style={{display: 'inline-block', width: '85%'}}>
          <b>Weekly Application Summary</b> <br />
          Send an email once per week summarizing the applications received in
          the previous seven days.
        </span>
        <span
          style={{display: 'inline-block', width: '15%', textAlign: 'right'}}
        >
          <Field
            name="WEEKLY_APPLICATION_SUMMARY_NOTIFICATION"
            component={OnOffSwitch}
            selectedValue=""
            unSelectedValue=""
          />
          <OnChange name="WEEKLY_APPLICATION_SUMMARY_NOTIFICATION">
            {props.handleToggle}
          </OnChange>
        </span>
      </div>
      <div className={styles.option}>
        <span style={{display: 'inline-block', width: '85%'}}>
          <b>New Roles Published</b> <br />
          Receive an email when a new position is published which highlights
          recommended job seekers.
        </span>
        <span
          style={{display: 'inline-block', width: '15%', textAlign: 'right'}}
        >
          <Field
            name="NEW_ROLES_PUBLISHED_NOTIFICATION"
            component={OnOffSwitch}
            selectedValue=""
            unSelectedValue=""
          />
          <OnChange name="NEW_ROLES_PUBLISHED_NOTIFICATION">
            {props.handleToggle}
          </OnChange>
        </span>
      </div>
    </>
  );
}

export default function NotificationSettingsPage(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const parameters = useParams();

  const [confirmVisible, setConfirmVisible] = useState(false);

  const defaultNotificationOptions = (subscribe) => {
    const formState = formRef.current.getState();
    let freq = 'Weekly'; // Default
    if (formState?.values.NEW_ROLE_NOTIFICATION_FREQUENCY) {
      freq = formState?.values.NEW_ROLE_NOTIFICATION_FREQUENCY;
    }

    return {
      INVITE_TO_APPLY_EMPLOYER_NOTIFICATION: subscribe,
      INVITE_TO_APPLY_ASCEND_NOTIFICATION: subscribe,
      NEW_ROLE_NOTIFICATION: subscribe,
      NEW_ROLE_NOTIFICATION_FREQUENCY: freq,
      PROFILE_SAVED: subscribe,
      RECEIVED_NEW_APPLICATION_NOTIFICATION: subscribe,
      RECEIVED_NEW_APPRENTICESHIP_APPLICATION_NOTIFICATION: subscribe,
      WEEKLY_APPLICATION_SUMMARY_NOTIFICATION: subscribe,
      NEW_ROLES_PUBLISHED_NOTIFICATION: subscribe,
      SAVED_ROLE_SEEKER_NOTIFICATION: subscribe,
      UNPUBLISHED_ROLE_SEEKER_NOTIFICATION: subscribe,
    };
  };

  const frequency = [
    {label: 'Weekly', value: 'Weekly'},
    {
      label: 'Daily',
      value: 'Daily',
    },
  ];

  const {
    notificationState,
    isApprenticeProgram,
    recruiter,
    recruiters,
    displayOverlay,
  } = useSelector((state) => {
    let notificationOptions = state.profile.notificationOptions;
    let displayOverlay = state.profile.notificationsLoading;
    if (parameters.id) {
      notificationOptions = state.recruiters.recruiter.notifications;
      displayOverlay = state.recruiters.recruitersLoading;
    }
    return {
      notificationState: notificationOptions,
      isApprenticeProgram: state.profile.isApprenticeProgram,
      recruiter: state.recruiters.recruiter,
      recruiters: state.recruiters,
      displayOverlay,
    };
  }, shallowEqual);

  useEffect(() => {
    if (parameters.id) {
      if (!recruiter.id && recruiters.recruitersLoading === false) {
        dispatch(getRecruiterNotificationOptionsById(parameters.id));
      }
    } else {
      if (notificationState.initialized === false) {
        dispatch(getProfileNotificationOptions());
      }
    }
  }, [dispatch, parameters, notificationState, recruiters, recruiter]);

  const setNotificationState = (notificationOptions) => {
    const data = Object.assign({}, notificationOptions);
    delete data['initialized'];

    //Send to api for save
    if (parameters.id) {
      dispatch(setRecruiterProfileNotificationOptions(parameters.id, data));
      dispatch({
        type: ACTIONS.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS,
        recruiter: {...recruiter, notifications: data},
      });
    } else {
      dispatch(setProfileNotificationOptions(data));
      dispatch({
        type: ACTIONS.PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS,
        data: notificationOptions,
      });
    }
  };

  const subscribeToAll = () => {
    const newNotifications = defaultNotificationOptions(true);
    if (parameters.id) {
      dispatch(
        setRecruiterProfileNotificationOptions(parameters.id, newNotifications)
      );

      dispatch({
        type: ACTIONS.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS,
        recruiter: {...recruiter, notifications: newNotifications},
      });
    } else {
      dispatch(setProfileNotificationOptions(newNotifications));
      dispatch({
        type: ACTIONS.PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS,
        data: defaultNotificationOptions(true),
      });
    }
  };

  const handleConfirmYesClicked = () => {
    setConfirmVisible(false);
    const newNotifications = defaultNotificationOptions(false);

    if (parameters.id) {
      dispatch(
        setRecruiterProfileNotificationOptions(parameters.id, newNotifications)
      );

      dispatch({
        type: ACTIONS.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS,
        recruiter: {...recruiter, notifications: newNotifications},
      });
    } else {
      dispatch(setProfileNotificationOptions(newNotifications));
      dispatch({
        type: ACTIONS.PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS,
        data: defaultNotificationOptions(false),
      });
    }
  };

  const toggleOptions = () => {
    const formState = formRef.current.getState();
    if (formState.dirty) {
      setNotificationState(formState.values);
    }
  };

  return (
    <LoadingOverlay
      role="status"
      active={displayOverlay}
      spinner={true}
      text="Loading..."
      fadeSpeed={200}
      styles={{
        overlay: (base) => ({
          ...base,
          position: 'fixed',
        }),
      }}
    >
      <DocumentTitle title="Notification Settings">
        <div className="wholepage">
          <Layout.PageHeader showAuthLink={true} />

          <main id="main" tabIndex="-1">
            <div className={styles.container}>
              <div className={styles.leftPanel}>
                <PreviousButton />
              </div>

              <div className={`${styles.notificationContainer} flex-column`}>
                <Form onSubmit={() => {}} initialValues={notificationState}>
                  {({handleSubmit, form}) => {
                    formRef.current = form;
                    return (
                      <form>
                        <AscendModal
                          isOpen={confirmVisible}
                          onClose={() => setConfirmVisible(false)}
                          centerVertically={true}
                          size="sm"
                          backdrop={'static'}
                        >
                          <AscendModalBody>
                            <div className={styles.modalTitle}>
                              Are you sure you want to opt out of all the email
                              notifications?
                            </div>

                            <div className="row justify-content-center">
                              <button
                                className="clickable btn btn-secondary col-4"
                                onClick={() => setConfirmVisible(false)}
                              >
                                No, cancel
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <button
                                type="button"
                                className="clickable btn btn-primary col-4"
                                onClick={handleConfirmYesClicked}
                              >
                                Yes, opt out
                              </button>
                            </div>
                          </AscendModalBody>
                        </AscendModal>
                        <main id="main" tabIndex="-1">
                          <div className={styles.title}>
                            Notifications
                            {recruiter && parameters.id && (
                              <>
                                <div className={styles.subtitle}>
                                  for {recruiter.employer} recruiter{' '}
                                  {recruiter.name}
                                </div>
                                <div className={styles.notificationReminder}>
                                  Don’t forget to notify the employer that their
                                  notification preferences have been modified.
                                </div>

                                {!recruiter.terms_accepted &&
                                  !displayOverlay && (
                                    <div
                                      className={`${styles.notificationAdminAlert} row`}
                                    >
                                      <ErrorSvg
                                        width="24"
                                        height="24"
                                        className="col-auto p-0"
                                      />
                                      <div className="col">
                                        You can successfully change this user’s
                                        preferences, but they will not begin
                                        receiving notifications until they
                                        accept Ascend’s Terms of Service and
                                        Privacy Policy.
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>

                          {params.profile.role === 'JOB_SEEKER' && (
                            <CandidateNotifications
                              handleToggle={() => toggleOptions()}
                              apprenticeProgram={isApprenticeProgram}
                              options={frequency}
                            />
                          )}
                          {(params.profile.role === 'COMPANY_RECRUITER' ||
                            params.profile.role === 'COMPANY_RECRUITER_ADMIN' ||
                            params.profile.role === 'ASCEND_RECRUITER') && (
                            <RecruiterNotifications
                              handleToggle={() => toggleOptions()}
                              apprenticeProgram={isApprenticeProgram}
                              options={frequency}
                            />
                          )}
                          <div className={styles.buttonContainer}>
                            <div>
                              <button
                                type="button"
                                className="clickable btn btn-secondary "
                                style={{maxWidth: '200px'}}
                                onClick={() => setConfirmVisible(true)}
                              >
                                Opt out of all
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <button
                                type="button"
                                className="clickable btn btn-primary"
                                style={{maxWidth: '200px'}}
                                onClick={subscribeToAll}
                              >
                                Subscribe to all
                              </button>
                            </div>
                          </div>
                        </main>
                      </form>
                    );
                  }}
                </Form>
              </div>
              <div className={styles.rightPanel}></div>
            </div>
          </main>
          <Layout.PageFooter />
        </div>
      </DocumentTitle>
    </LoadingOverlay>
  );
}
