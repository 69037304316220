import React from 'react';
import PropTypes from 'prop-types';

import {Form, Field} from 'react-final-form';
import {useSelector} from 'react-redux';
import ContentLoader from 'react-content-loader';

export default function ReportConnectionIsNetworkRole(props) {
  const {onChange, onSubmit, isNetworkConnection} = props;
  let seekerLoading = useSelector((state) => state.seekers.seekerLoading);

  const renderContentLoader = () => {
    return (
      <ContentLoader
        speed={3}
        height={110}
        width={'100%'}
        backgroundColor="#f1f4f9"
        foregroundColor="#cbe1ff"
        style={{
          backgroundColor: '#ffffff',
        }}
      >
        <rect x="0" y="12" rx="3" ry="3" width="100%" height="35" />
        <rect x="0" y="75" rx="3" ry="3" width="100%" height="35" />
      </ContentLoader>
    );
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => {
        return (
          <form>
            {seekerLoading && renderContentLoader()}

            {!seekerLoading && (
              <label className="normal-body">
                Did you find this role on the Network?
                <div className="row ms-2 mt-2">
                  <label className="radio normal-body">
                    Yes
                    <Field
                      name="isNetworkRole"
                      component="input"
                      type="radio"
                      value={true}
                      checked={isNetworkConnection === true}
                      onChange={() => onChange(true)}
                      autoFocus
                    />
                    <span className="radio-checkmark-orange" />
                  </label>

                  <label className="radio normal-body">
                    No
                    <Field
                      name="isNetworkRole"
                      component="input"
                      type="radio"
                      value={false}
                      checked={isNetworkConnection === false}
                      onChange={() => onChange(false)}
                    />
                    <span className="radio-checkmark-orange" />
                  </label>
                </div>
              </label>
            )}
          </form>
        );
      }}
    </Form>
  );
}

ReportConnectionIsNetworkRole.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  isNetworkConnection: PropTypes.bool,
};
