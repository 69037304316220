import React from 'react';
import PropTypes from 'prop-types';
import {AscendModal, AscendModalBody, AscendModalHeader} from '../../layout';
import './CandidateNotifyMissingRole.scss';

class CandidateNotifyMissingRole extends React.Component {
  render() {
    return (
      <AscendModal
        size="sm"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        centerVertically={true}
        disableClose={false}
      >
        <AscendModalHeader headerClass="bd-modalheader" hasClose={true}>
          <div className="text-align-center ">
            <h1
              style={{fontSize: '24px'}}
              className="normal-headline-white bold mb-0"
            >
              Role Unavailable
            </h1>
          </div>
        </AscendModalHeader>
        <AscendModalBody bodyClass="m-4" hasClose={this.props.hasClose}>
          <div className="modal-container">
            <div className="normal-subheader">
              Sorry, the role you are trying to access is no longer active.
              Please refresh the page to view current roles.
            </div>
            <div className="button-container pt-4">
              <button
                type="button"
                className="clickable btn btn-primary notify-button"
                onClick={() => this.props.onClose()}
              >
                Close
              </button>
            </div>
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

CandidateNotifyMissingRole.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  hasClose: PropTypes.bool,
};

CandidateNotifyMissingRole.defaultProps = {
  isOpen: false,
  hasClose: false,
};

export default CandidateNotifyMissingRole;
