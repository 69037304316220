import React from 'react';
import PropTypes from 'prop-types';

import {AscendModal, AscendModalBody} from '../../layout';

class MessageModal extends React.Component {
  handleSubmit = () => {
    this.props.onSubmit();
  };

  render() {
    return (
      <AscendModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        centerVertically={true}
        disableClose={true}
      >
        <AscendModalBody>
          <div
            className="normal-headline"
            style={{textAlign: 'center', paddingBottom: '8px'}}
          >
            {this.props.message}
          </div>
          <div style={{textAlign: 'center'}}>
            <button
              type="submit"
              className="clickable btn btn-eula"
              onClick={this.handleSubmit}
            >
              {this.props.buttonText}
            </button>
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

MessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MessageModal;
