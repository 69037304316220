import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';

import * as jobsActions from '../../actions/jobs';
import * as cardActions from '../../actions/recruiterJobCards';

import RecruiterJobCardTab from './RecruiterJobCardTab';
import MatchListPanel from './MatchListPanel';
import SeekerListPanel from './SeekerListPanel';
import editgraySvg from '../../assets/images/EditGray.svg';
import {HeartDefaultSvg, VisibleOffSvg} from 'src/assets/svg/svgComponents';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';
import classNames from 'classnames';
import styles from './RecruiterJobCard.module.scss';
import {AscendModal, AscendModalBody} from '../../layout';
import {jobUnPublish} from '../../actions/jobs';
import {track} from '../../utils/analytics';
import {format} from 'date-fns';

class RecruiterJobCard extends React.Component {
  _isMounted = false;
  state = {
    canViewCandidatePII: false,
    confirmVisible: false,
  };

  componentDidMount() {
    this._isMounted = true;
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) return;
      this.setState({
        canViewCandidatePII: resp.data.hasPermission,
      });
    });
    this.props.getJobCard(this.props.job.id);

    if (!(this.props.job.id in this.props.jobCards)) {
      this.props.createJobCard(this.props.job.id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSeekerClick = (id) => {
    this.props.history.push('/candidate/' + id);
  };

  handleTwistyClick = () => {
    const jobId = this.props.job.id;
    this.props.updateJobCardMaximized(
      jobId,
      !this.props.jobCards[jobId].maximized
    );
  };

  handleDiscoverClick = (filterTag = null) => {
    const teamId = this.props.job.teamId;
    const roleId = this.props.job.id;
    const segmentIds = this.props.profile.segmentIds;
    let params = `teamId=${teamId}&roleId=${roleId}`;
    if (segmentIds.length > 0) {
      params += `&segmentIds=${segmentIds.join(',')}`;
    }
    if (filterTag) {
      params += `&filterTag=${filterTag}`;
    }

    this.props.history.push(`/find_candidates?${params}`);
  };

  handleTabClick = (tabIndex) => {
    this.props.updateJobCardTabIndex(this.props.job.id, tabIndex);
  };

  render() {
    const jobId = this.props.job.id;

    // If this job hasn't been added to the jobcard store,
    // just take the defaults. It will be added shortly, since
    // it was dispatched in componentDidMount().
    let tabIndex = 0;
    let maximized = false;
    if (jobId in this.props.jobCards) {
      tabIndex = this.props.jobCards[jobId].tabIndex;
      maximized = this.props.jobCards[jobId].maximized;
    }

    let watches = [];
    let applications = [];
    let invitations = [];
    let allHandpicked = [];
    let handpicked = [];
    let matches = [];
    let matchCount = 0;
    let watchCount = 0;
    let applicationCount = 0;
    let invitationCount = 0;
    if (jobId in this.props.jobCardsByJob) {
      const jobCard = this.props.jobCardsByJob[jobId];
      watches = jobCard.watches;
      applications = jobCard.applications;
      invitations = jobCard.invitations;
      matches = jobCard.matches;
      matches = matches.slice(0, 8);

      matchCount = jobCard.stats.jobMatchCount;
      watchCount = jobCard.stats.jobWatchCount;
      applicationCount = jobCard.stats.jobApplicationCount;
      invitationCount = jobCard.stats.jobInvitationCount;
    }

    const datePosted = format(
      new Date(this.props.job.publishedAt),
      'MM/dd/yyyy'
    );
    const displayDate =
      datePosted !== 'Invalid date' ? datePosted : 'No published date';

    const totalMatches = Number(matchCount);

    const handleUnPublish = () => {
      this.setState({confirmVisible: true});
    };
    const handleConfirmClose = () => {
      this.setState({confirmVisible: false});
    };

    const handleConfirmYesClicked = () => {
      let newJob = this.props.job;
      newJob.published = false;

      track({
        eventName: `UnpublishedAJobClicked_${this.props.profile.role}`,
        jobId: newJob.id,
      });
      this.props.jobUnPublish({
        job: {
          ...newJob,
          unpublishedById: this.props.profile.id,
        },
      });

      this.setState({confirmVisible: false});
    };
    const handleConfirmNoClicked = () => {
      this.setState({confirmVisible: false});
    };

    return (
      <div>
        <AscendModal
          styles={{maxWidth: '450px', width: '100%'}}
          isOpen={this.state.confirmVisible}
          onClose={handleConfirmClose}
          centerVertically={true}
        >
          <AscendModalBody bodyClass={styles.modalBody}>
            <div className={styles.modalTitle}>
              Are you sure you want to unpublish this role?
            </div>
            <div>
              Once unpublished, neither you nor job seekers will see this role
              or be able to apply.
            </div>
            <div className={styles.modalJobTitle}>{this.props.job.name}</div>
            <div className="row justify-content-center">
              <button
                className="clickable btn btn-secondary col-3"
                onClick={handleConfirmNoClicked}
              >
                No, cancel
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="clickable btn btn-primary col-4"
                onClick={handleConfirmYesClicked}
              >
                Yes, unpublish
              </button>
            </div>
          </AscendModalBody>
        </AscendModal>

        <div
          className="bd-whitebackground "
          style={
            this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN'
              ? {padding: '0 20px 20px 20px'}
              : {padding: '20px 20px 20px 20px'}
          }
        >
          {(this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN') && (
            <div
              className="row justify-content-end align-items-center"
              style={{padding: '5px 0 0 0'}}
            >
              <VisibleOffSvg
                className={`{styles.unPublishSvg} col-md-auto p-0`}
              />
              <button
                className={classNames([
                  'clickable',
                  'active-caption',
                  'col-md-auto',
                  styles.unPublishLink,
                ])}
                onClick={handleUnPublish}
              >
                Unpublish this job
              </button>
            </div>
          )}
          <div>
            <div className="padding-bottom-eight">
              {this.props.role === 'ASCEND_RECRUITER' && (
                <>
                  <button
                    className="inlineblock float-right disabled-body clickable padding-left-eight"
                    onClick={this.props.onJobEditClick}
                    style={{border: 'none', background: ' none'}}
                  >
                    <img
                      src={editgraySvg}
                      alt={`Edit ${this.props.job.name}`}
                    />
                  </button>
                  <div className="inlineblock padding-h16 float-right border-radius-3 tag-small">
                    {this.props.job.published ? 'Published' : 'Draft'}
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div className="col-md-10">
                <h4
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  <button
                    className="buttonAsLinkTeamCard"
                    style={{padding: 0, border: 0, textDecoration: 'underline'}}
                    onClick={this.props.onJobClick}
                  >
                    {this.props.job.name}
                  </button>
                </h4>
                <div className="disabled-caption">{displayDate}</div>
              </div>
              <div className="col-md-2" />
            </div>
            <br />

            <Tabs selectedIndex={tabIndex} onSelect={this.handleTabClick}>
              <TabList className="recruiter-job-card-tablist">
                <Tab
                  disabled={!this.state.canViewCandidatePII}
                  selectedClassName="active-recruiter-job-card-tab"
                >
                  <RecruiterJobCardTab
                    enabled={this.state.canViewCandidatePII}
                    glyph="thin_check"
                    title={`${totalMatches} matches`}
                  />
                </Tab>

                <Tab
                  disabled={!this.state.canViewCandidatePII}
                  selectedClassName="active-recruiter-job-card-tab"
                >
                  {tabIndex === 1 && (
                    <RecruiterJobCardTab
                      enabled={this.state.canViewCandidatePII}
                      svg={
                        <HeartDefaultSvg className="svg_color_fill_blue_heart svg_color_stroke_blue_heart" />
                      }
                      title={`${watchCount} interested`}
                    />
                  )}
                  {tabIndex !== 1 && (
                    <RecruiterJobCardTab
                      enabled={this.state.canViewCandidatePII}
                      svg={
                        // heartGraySvg
                        <HeartDefaultSvg className="svg_color_fill_gray_heart svg_color_stroke_gray_heart" />
                      }
                      title={`${watchCount} interested`}
                    />
                  )}
                </Tab>

                <Tab
                  disabled={!this.state.canViewCandidatePII}
                  selectedClassName="active-recruiter-job-card-tab"
                >
                  <RecruiterJobCardTab
                    enabled={this.state.canViewCandidatePII}
                    glyph="letter"
                    title={`${applicationCount} applied`}
                  />
                </Tab>

                <Tab
                  disabled={!this.state.canViewCandidatePII}
                  selectedClassName="active-recruiter-job-card-tab"
                >
                  <RecruiterJobCardTab
                    enabled={this.state.canViewCandidatePII}
                    glyph="interview"
                    title={`${invitationCount} invited`}
                  />
                </Tab>
              </TabList>
              <>
                <TabPanel
                  style={
                    !this.state.canViewCandidatePII ? {display: 'none'} : {}
                  }
                >
                  <MatchListPanel
                    allInvitations={invitations}
                    allHandpicked={allHandpicked}
                    handpickedSeekers={handpicked}
                    matchedSeekers={matches}
                    maximized={maximized}
                    jobTraits={this.props.job.characterTraits}
                    onViewMoreClicked={() => this.handleDiscoverClick()}
                  />
                </TabPanel>

                <TabPanel
                  style={
                    !this.state.canViewCandidatePII ? {display: 'none'} : {}
                  }
                >
                  <SeekerListPanel
                    allInvitations={invitations}
                    allHandpicked={allHandpicked}
                    seekers={watches}
                    totalSeekers={watchCount}
                    maximized={maximized}
                    jobTraits={this.props.job.characterTraits}
                    onViewMoreClicked={() =>
                      this.handleDiscoverClick('interested')
                    }
                  />
                </TabPanel>

                <TabPanel
                  style={
                    !this.state.canViewCandidatePII ? {display: 'none'} : {}
                  }
                >
                  <SeekerListPanel
                    allInvitations={invitations}
                    allHandpicked={allHandpicked}
                    seekers={applications}
                    totalSeekers={applicationCount}
                    maximized={maximized}
                    jobTraits={this.props.job.characterTraits}
                    onViewMoreClicked={() =>
                      this.handleDiscoverClick('applied')
                    }
                  />
                </TabPanel>

                <TabPanel
                  style={
                    !this.state.canViewCandidatePII ? {display: 'none'} : {}
                  }
                >
                  <SeekerListPanel
                    allInvitations={invitations}
                    allHandpicked={allHandpicked}
                    seekers={invitations}
                    totalSeekers={invitationCount}
                    maximized={maximized}
                    jobTraits={this.props.job.characterTraits}
                    onViewMoreClicked={() =>
                      this.handleDiscoverClick('invited')
                    }
                  />
                </TabPanel>
              </>
            </Tabs>
          </div>
        </div>
        {!maximized && this.state.canViewCandidatePII && (
          <center>
            <button
              aria-expanded={maximized}
              onClick={this.handleTwistyClick}
              className="bd-darkgraybackground tightcolumnpad clickable active-caption"
              style={{width: '100%', border: 'none', background: 'none'}}
            >
              Show details
            </button>
          </center>
        )}
        {maximized && this.state.canViewCandidatePII && (
          <center>
            <button
              aria-expanded={maximized}
              onClick={this.handleTwistyClick}
              className="bd-darkgraybackground tightcolumnpad clickable active-caption"
              style={{width: '100%', border: 'none', background: 'none'}}
            >
              Hide details
            </button>
          </center>
        )}
      </div>
    );
  }
}

RecruiterJobCard.propTypes = {
  history: PropTypes.object.isRequired,
  jobCardsByJob: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  jobCards: PropTypes.object,
  getJobCard: PropTypes.func.isRequired,
  createJobCard: PropTypes.func.isRequired,
  updateJobCardTabIndex: PropTypes.func.isRequired,
  updateJobCardMaximized: PropTypes.func.isRequired,
  onJobClick: PropTypes.func.isRequired,
  onJobEditClick: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  jobUnPublish: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobCardsByJob: state.jobs.jobCardsByJob,
    jobCards: state.recruiterJobCards.jobCards,
    profile: state.profile,
  };
};

const mapDispatchToProps = {
  ...jobsActions,
  ...cardActions,
  jobUnPublish,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecruiterJobCard)
);
