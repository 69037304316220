import * as types from '../actions/ActionTypes';
import {filter, findIndex} from 'lodash';

const initialState = {
  matchParameters: [],
  matchParametersLoading: false,
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
};

export default function matchParameters(state = initialState, action) {
  switch (action.type) {
    case types.MATCH_PARAMETERS__GET_ALL_SUCCESS:
      return {...state, matchParameters: action.response.body.matchParameters};
    case types.MATCH_PARAMETERS__GET_ALL_ADVANCED_REQUEST:
      return {
        ...state,
        matchParameters: [],
        matchParametersLoading: true,
      };
    case types.MATCH_PARAMETERS__GET_ALL_ADVANCED_SUCCESS:
      return {
        ...state,
        matchParameters: action.response.body.matchParameters,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        matchParametersLoading: false,
      };
    case types.MATCH_PARAMETERS__DELETE_SUCCESS:
      return {
        ...state,
        matchParameters: filter(state.matchParameters, (matchParameter) => {
          return matchParameter.id !== action.payload.matchParameterId;
        }),
      };
    case types.MATCH_PARAMETERS__CREATE_SUCCESS:
      return {
        ...state,
        matchParameters: [...state.matchParameters, action.response.body],
      };
    case types.MATCH_PARAMETERS__UPDATE_SUCCESS:
      const index = findIndex(state.matchParameters, (matchParameter) => {
        return matchParameter.id === action.payload.matchParameter.id;
      });
      return {
        ...state,
        matchParameters: [
          ...state.matchParameters.slice(0, index),
          action.response.body,
          ...state.matchParameters.slice(index + 1),
        ],
      };
    default:
      return state;
  }
}
