import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import AreYouSureModal from '../components/AreYouSureModal';
import FileDrop from '../components/FileDrop/FileDrop';
import {TextAreaField} from './index';
import Avatar from '../components/Avatar';

export default function PhotoFieldArray({
  meta,
  fields,
  maxFields,
  setUploadingStatus,
}) {
  const [areYouSureModalIsOpen, setAreYouSureModalIsOpen] = useState(false);
  const [removeIndex, setRemoveIndex] = useState('');

  const handleAddField = (photo) => {
    if (fields.length === maxFields) return;
    fields.push({
      imageUrl: photo.s3PublicUrl,
      fileName: photo.fileName,
      sortOrder: fields.length,
    });
  };

  const handleDoRemove = () => {
    fields.remove(removeIndex);
    setRemoveIndex('');
    setAreYouSureModalIsOpen(false);
  };

  const handleRemoveFieldIndex = (index) => {
    setRemoveIndex(index);
    setAreYouSureModalIsOpen(true);
  };

  const handleAreYouSureClose = () => {
    setRemoveIndex('');
    setAreYouSureModalIsOpen(false);
  };

  const handleUpFromIndex = (index) => {
    fields.swap(index, index - 1);
  };

  const handleDownFromIndex = (index) => {
    fields.swap(index, index + 1);
  };

  return (
    <div>
      <AreYouSureModal
        areYouSureText="Are you sure that you want to remove this photo?"
        isOpen={areYouSureModalIsOpen}
        onClose={handleAreYouSureClose}
        onYes={handleDoRemove}
      />

      <div className="form form-group">
        <div className="normal-title padding-16 bold">
          <h2 className="normal-title padding-bottom-sixteen bold">Photos</h2>
          <div className="secondary-body">
            Images should be at least 300 x 300 px and less than 10 MB
          </div>
        </div>
        {meta.error && meta.submitFailed && (
          <div className="form-input-error">{meta.error}</div>
        )}
        {fields.map((photo, index) => {
          fields.value[index].sortOrder = index;
          return (
            <div key={index}>
              <div className="bd-whitebackground padding-16">
                <button
                  className="normal-subheader buttonAsLinkTasks float-right"
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemoveFieldIndex(index);
                  }}
                  tabIndex={0}
                  aria-label="Remove Photo"
                >
                  <i className="nc-icon-glyph-med remove" />
                </button>
                {index !== fields.length - 1 && (
                  <button
                    className="normal-subheader float-right buttonAsLinkTasks"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownFromIndex(index);
                    }}
                    tabIndex={0}
                    aria-label="Move Photo Down"
                  >
                    <i className="nc-icon-glyph-med down_arrow padding-right-sixteen" />
                  </button>
                )}
                {index !== 0 && (
                  <button
                    className="normal-subheader float-right buttonAsLinkTasks"
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpFromIndex(index);
                    }}
                    tabIndex={0}
                    aria-label="Move Photo Up"
                  >
                    <i className="nc-icon-glyph-med up_arrow padding-right-sixteen" />
                  </button>
                )}

                <div className="col-md-12 " style={{marginTop: '24px'}}>
                  <div className="row">
                    <div className="col-3">
                      <Avatar
                        url={fields.value[index].imageUrl}
                        height="8em"
                        width="8em"
                      />
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <div
                          className="normal-title bold col-auto p-0"
                          style={{marginLeft: '12px'}}
                        >
                          Photo {index + 1}
                        </div>
                        <div
                          className="ps-4 secondary-body col"
                          style={{marginTop: '5px'}}
                        >
                          {fields?.value[index]?.fileName}
                        </div>
                      </div>
                      <Field
                        name={`${photo}.altText`}
                        component={TextAreaField}
                        photoIndex={index}
                        label="Alt Text"
                        rows={1}
                        maxLength={512}
                        showCounter={true}
                        showToolTip={true}
                        hideBorder={false}
                        toolTip={
                          'Alt text is a written description of the image that allows screen-reading tools <br/> to describe images to visually impaired users. <br/>It should succinctly describe the specific context, purpose, or emotions <br/>of the image, ideally in 125 characters or less.'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          );
        })}
        {fields.length < maxFields && (
          <div className="col-md-12">
            <FileDrop
              fileReceived={false}
              handleS3File={handleAddField}
              setUploadingStatus={(x) => setUploadingStatus(x)}
              dropErrorMessage="Please drop only JPEG or PNG file types here. Limit to 10 files."
            />
          </div>
        )}
      </div>
    </div>
  );
}

PhotoFieldArray.propTypes = {
  meta: PropTypes.object,
  fields: PropTypes.object,
  maxFields: PropTypes.number,
  setUploadingStatus: PropTypes.func.isRequired,
};
