import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

class SideBarSegmentDisplay extends React.Component {
  renderRow = (content, title) => {
    const {whiteBackground} = this.props;
    return (
      <div
        className={`${
          whiteBackground
            ? 'bd-whitebackground-nopadding'
            : 'bd-graybackground-nopadding'
        } padding-bottom-eight`}
      >
        <div
          className={`${
            whiteBackground ? 'bd-whitebackground' : 'bd-med-graybackground'
          } padding-16`}
        >
          <div className="normal-body">{title}</div>
          <div className="disabled-dark-caption">
            {content || 'Not selected'}
          </div>
        </div>
      </div>
    );
  };

  renderSegments = (parentObject, title, role) => {
    if (parentObject && parentObject.id && parentObject.segments) {
      return (
        role === 'ASCEND_RECRUITER' &&
        parentObject.segments &&
        this.renderRow(
          parentObject.segments.map((x) => x.name).join(', '),
          title
        )
      );
    }
  };

  render() {
    const {parentObject, title, role} = this.props;
    return (
      <Fragment>{this.renderSegments(parentObject, title, role)}</Fragment>
    );
  }
}

SideBarSegmentDisplay.propTypes = {
  parentObject: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  title: PropTypes.string,
  whiteBackground: PropTypes.bool,
};

SideBarSegmentDisplay.defaultProps = {
  whiteBackground: true,
  title: '',
};

export default SideBarSegmentDisplay;
