import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import AreYouSureModal from '../components/AreYouSureModal';

const renderField = ({
  input,
  meta: {touched, error, warning},
  placeholder,
  resourceIndex,
  autoFocus,
}) => (
  <div className="padding-bottom-eight width316">
    <label htmlFor={`${input.name}${resourceIndex}`}>{placeholder}</label>
    <input
      {...input}
      id={`${input.name}${resourceIndex}`}
      type="text"
      size={26}
      className="form-control normal-body bd-graybackground lgsmallplaceholder"
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
  </div>
);

renderField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

class ResourceFieldArray extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      areYouSureModalIsOpen: false,
      removeIndex: '',
    };
  }

  handleAddField = () => {
    const {fields, maxFields} = this.props;
    if (fields.length === maxFields) return;
    fields.push();
  };

  handleDoRemove = () => {
    this.props.fields.remove(this.state.removeIndex);
    this.setState(
      {
        removeIndex: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handleRemoveFieldIndex = (index) => {
    this.setState(
      {
        removeIndex: index,
      },
      () => this.setState({areYouSureModalIsOpen: true})
    );
  };

  handleAreYouSureClose = () => {
    this.setState(
      {
        removeIndex: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handleUpFromIndex = (index) => {
    this.props.fields.swap(index, index - 1);
  };

  handleDownFromIndex = (index) => {
    this.props.fields.swap(index, index + 1);
  };

  render() {
    const {
      fields,
      meta: {error, submitFailed},
      maxFields,
      autofocus,
    } = this.props;
    return (
      <div>
        <AreYouSureModal
          areYouSureText="Are you sure that you want to remove this resources entry?"
          isOpen={this.state.areYouSureModalIsOpen}
          onClose={this.handleAreYouSureClose}
          onYes={this.handleDoRemove}
        />
        <div className="form form-group">
          {error && submitFailed && (
            <div className="form-input-error">{error}</div>
          )}
          {fields.map((resource, index) => {
            return (
              <div key={index}>
                <div className="bd-whitebackground padding-16">
                  <div>
                    <div
                      className="normal-subheader clickable float-right"
                      onClick={() => this.handleRemoveFieldIndex(index)}
                      onKeyDown={(e) => {
                        if (e.key === ' ' || e.key === 'Enter') {
                          e.preventDefault();
                          this.handleRemoveFieldIndex(index);
                        }
                      }}
                      tabIndex={0}
                      aria-label="Remove Resource"
                    >
                      <i className="nc-icon-glyph-med remove" />
                    </div>
                    {index !== fields.length - 1 && (
                      <div
                        className="normal-subheader float-right clickable"
                        onClick={() => this.handleDownFromIndex(index)}
                        onKeyDown={(e) => {
                          if (e.key === ' ' || e.key === 'Enter') {
                            e.preventDefault();
                            this.handleDownFromIndex(index);
                          }
                        }}
                        tabIndex={0}
                        aria-label="Move Resource Down"
                      >
                        <i className="nc-icon-glyph-med down_arrow padding-right-sixteen" />
                      </div>
                    )}
                    {index !== 0 && (
                      <div
                        className="normal-subheader float-right clickable"
                        onClick={() => this.handleUpFromIndex(index)}
                        onKeyDown={(e) => {
                          if (e.key === ' ' || e.key === 'Enter') {
                            e.preventDefault();
                            this.handleUpFromIndex(index);
                          }
                        }}
                        tabIndex={0}
                        aria-label="Move Resource Up"
                      >
                        <i className="nc-icon-glyph-med up_arrow padding-right-sixteen" />
                      </div>
                    )}
                  </div>
                  <Field
                    name={`${resource}.url`}
                    component={renderField}
                    placeholder="Url"
                    resourceIndex={index}
                    autoFocus={autofocus}
                  />
                  <Field
                    name={`${resource}.title`}
                    component={renderField}
                    placeholder="Title"
                    resourceIndex={index}
                  />
                  <Field
                    name={`${resource}.name`}
                    component={renderField}
                    placeholder="Name"
                    resourceIndex={index}
                  />
                </div>
                <br />
              </div>
            );
          })}
          <div>
            {fields.length < maxFields && (
              <button
                type="button"
                className="clickable inlineblock btn-transparent-green"
                onClick={this.handleAddField}
              >
                + add another
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ResourceFieldArray.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  fields: PropTypes.object,
  maxFields: PropTypes.number,
  autofocus: PropTypes.bool,
};

export default ResourceFieldArray;
