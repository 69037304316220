import * as types from './ActionTypes';
import _ from 'lodash';

const apiUrl = process.env.REACT_APP_API_URL;

export function getSkillTags() {
  return {
    types: [
      types.SKILL_TAGS__GET_REQUEST,
      types.SKILL_TAGS__GET_SUCCESS,
      types.SKILL_TAGS__GET_FAILURE,
    ],
    uri: `${apiUrl}/skill_tags`,
    method: 'GET',
  };
}

export function getSkillTree() {
  let uri = `${apiUrl}/skill_tags/tree`;
  return {
    types: [
      types.SKILL_TAGS_TREE__GET_REQUEST,
      types.SKILL_TAGS_TREE__GET_SUCCESS,
      types.SKILL_TAGS_TREE__GET_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getAllSkillTags() {
  let uri = `${apiUrl}/skill_tags`;
  let p = [];
  p.push('all');
  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.SKILL_TAGS__GET_REQUEST,
      types.SKILL_TAGS__GET_SUCCESS,
      types.SKILL_TAGS__GET_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getTopSkillTags(commonlyRequestedCount) {
  let uri = `${apiUrl}/skill_tags`;
  let p = [];
  p.push('commonlyRequestedCount=' + commonlyRequestedCount);
  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.SKILL_TAGS__GET_TOP_REQUEST,
      types.SKILL_TAGS__GET_TOP_SUCCESS,
      types.SKILL_TAGS__GET_TOP_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getSkillTagsAdvanced(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/skill_tags`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.SKILL_TAGS__GET_ADVANCED_REQUEST,
      types.SKILL_TAGS__GET_ADVANCED_SUCCESS,
      types.SKILL_TAGS__GET_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function createSkillTag(skillTag) {
  return {
    types: [
      types.SKILL_TAGS__CREATE_REQUEST,
      types.SKILL_TAGS__CREATE_SUCCESS,
      types.SKILL_TAGS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/skill_tags`,
    method: 'POST',
    body: skillTag,
    payload: {skillTag},
    successMessage: 'Tag created.',
    failureMessage: 'Error creating tag.',
  };
}

export function toggleCategory(categoryId) {
  return {
    type: types.SKILL_TAGS_TREE__CATEGORY_TOGGLE,
    categoryId,
  };
}

export function toggleSubcategory(subcategoryId) {
  return {
    type: types.SKILL_TAGS_TREE__SUBCATEGORY_TOGGLE,
    subcategoryId,
  };
}

export function collapseAllSkillCategories(subcategoryId) {
  return {
    type: types.SKILL_TAGS_TREE__COLLAPSE_ALL,
  };
}
