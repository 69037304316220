import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';

export default function ReportConnectionAttributeRoleToNetwork(props) {
  const formRef = useRef(null);
  const {onSubmit, onChange, roleAttributed} = props;

  return (
    <Form onSubmit={onSubmit}>
      {({form}) => {
        formRef.current = form;
        return (
          <form>
            <fieldset>
              <div className="row">
                <div className="col-auto">
                  <label className="normal-body">
                    Did you receive guidance from your advisor (resume,
                    interview, cover letter support, etc.) that helped you land
                    your role?
                    <div className="row ms-2 mt-2">
                      <label className="radio normal-body">
                        Yes
                        <Field
                          name="isNetworkRole"
                          component="input"
                          type="radio"
                          value={true}
                          checked={roleAttributed === true}
                          onChange={() => onChange(true)}
                          autoFocus
                        />
                        <span className="radio-checkmark-orange" />
                      </label>

                      <label className="radio normal-body">
                        No
                        <Field
                          name="isNetworkRole"
                          component="input"
                          type="radio"
                          value={false}
                          checked={roleAttributed === false}
                          onChange={() => onChange(false)}
                        />
                        <span className="radio-checkmark-orange" />
                      </label>
                    </div>
                  </label>
                </div>
              </div>
            </fieldset>
          </form>
        );
      }}
    </Form>
  );
}

ReportConnectionAttributeRoleToNetwork.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  roleAttributed: PropTypes.bool,
};
