import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import _ from 'lodash';

import * as regionsActions from 'src/actions/regions';

import * as Layout from 'src/layout';
import {AscendCheckGroupField} from 'src/formFields/AscendCheckGroupField/AscendCheckGroupField';
import regionalMap from 'src/assets/images/RegionalMap.svg';
import {BusyButton} from './BusyButton';
import {regionalCityList} from 'src/utils/onBoarding/regionalCityList';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['regionIds'];
  _.forEach(requiredFields, (field) => {
    if (!values[field] || values[field].length === 0) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

class RegionsModal extends React.Component {
  state = {
    selectedRegions: [],
  };

  async componentDidMount() {
    await this.props.getRegions();

    if (this.props.regions.length > 0) {
      this.setState({
        isMobile: window.matchMedia('(max-width: 430px)').matches,
        selectedRegions:
          this.props.regions &&
          this.props.seeker.regions
            .map((x) => x.id)
            .includes(this.props.regions?.find((x) => x.name === 'Any').id)
            ? []
            : this.props.seeker.regions.map((x) => x.id),
      });
    }
  }

  render() {
    const {isOpen, onClose, regions} = this.props;

    const regionalCities = regionalCityList();
    let regionIndex = 0;

    const wildcard = _.find(regions, ['name', 'Any']);
    const sortedRegions = _.reject(regions, ['name', 'Any']).map((t, index) => {
      regionIndex = ++regionIndex;
      const val = {
        label: `${t.order} - ${t.name} region`,
        value: t.id,
        tips: `Major cities: ${regionalCities[regionIndex]}`,
      };

      return val;
    });
    if (wildcard) {
      sortedRegions.unshift({label: 'No Preference', value: wildcard.id});
    }

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            keepDirtyOnReinitialize={true}
            mutators={{
              // expect (field, value) args from the mutator
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={{
              regionIds: this.props.seeker?.regions.map((x) => x.id),
            }}
          >
            {({handleSubmit, form}) => {
              let deskLayout = this.state.isMobile ? '' : 'd-flex flex-row';
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="bold active-headline text-align-center">
                      Where are you interested in working?
                    </div>
                    <div className="normal-subheader text-align-center">
                      If you're looking for remote work, select “No Preference.”
                      You'll be able to filter by remote-only jobs.
                    </div>

                    <div
                      className={deskLayout}
                      style={{justifyContent: 'center'}}
                    >
                      <img
                        width="300px"
                        alt="Indiana map with different regions highlighted"
                        src={regionalMap}
                      />

                      <div className="mt-3 ">
                        <Field name="regionIds">
                          {(props) => (
                            <>
                              <AscendCheckGroupField
                                options={sortedRegions}
                                name={props.input.name}
                                inputValue={props.input.value}
                                onChange={props.input.onChange}
                                meta={props.meta}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="row justify-content-center nowrap">
                      <button
                        type="button"
                        className="btn btn-transparent col-2 me-4"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <BusyButton
                        onClick={handleSubmit}
                        buttonText={'Save'}
                        busyText={'Saving'}
                        className="col-2"
                        style={{width: '210px', height: '40px'}}
                        float={'float-right'}
                        buttonIcon={'save'}
                        alignText={'text-center'}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

RegionsModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  regions: PropTypes.array,
  seeker: PropTypes.object,
};

const actions = {
  ...regionsActions,
};

let EditRegionsModal = (props) => <RegionsModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  return {
    regions: state.regions.regions,
    seeker: state.seekers.seeker,
  };
};

EditRegionsModal = connect(editMapStateToProps, actions)(EditRegionsModal);

export {EditRegionsModal};
