import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

export const API_URL = process.env.REACT_APP_API_URL;

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function parseDate(str, format, locale) {
  try {
    const parsed = dateFnsParse(str, format, new Date(), {locale});
    return parsed;
  } catch (e) {
    if (e instanceof RangeError) {
      return undefined;
    }
    throw e;
  }
}

export function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, {locale});
}

export function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
}

export function randomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
