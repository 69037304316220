import React from 'react';
import {useDispatch} from 'react-redux';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import SkillsTreeWidget from 'src/components/skillTags/SkillsTreeWidget.js';

import {SKILL_TAGS_TREE__COLLAPSE_ALL} from 'src/actions/ActionTypes';

export default function Skills({
  next,
  previous,
  intakeCache,
  isMobile,
  headerImage,
}) {
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};

    if (values.skillTags.length < 5) {
      errors['skillTags'] = 'Please select at least 5 skills';
    }

    return errors;
  };

  return (
    <>
      <PageHeader
        title="Show off your skills"
        subTitle="Add at least 5 skills. The more you select, the more accurate your role matches will be."
        headerImage={headerImage}
      />
      <Form
        onSubmit={next}
        validate={validate}
        keepDirtyOnReinitialize={true}
        initialValues={{skillTags: intakeCache?.skillTags || []}}
      >
        {({handleSubmit, form}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.pageContent}>
                <div className={styles.skillsTree}>
                  <label className="onboarding-form-label">
                    Search for skills and browse the skills library
                  </label>
                  <Field
                    component={SkillsTreeWidget}
                    name="skillTags"
                    placeholder="Search & add skills (ex. data analytics)"
                  />
                </div>
              </div>
              <PageFooter
                next={() => {
                  dispatch({type: SKILL_TAGS_TREE__COLLAPSE_ALL});
                  form.submit();
                }}
                previous={() => {
                  dispatch({type: SKILL_TAGS_TREE__COLLAPSE_ALL});
                  previous(null, form.getState().values);
                }}
                getValues={() => form.getState().values}
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}

Skills.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  listOptions: PropTypes.array.isRequired,
  headerImage: PropTypes.any,
};
