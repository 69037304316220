import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {connect} from 'react-redux';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {Button} from 'reactstrap';
import * as jobsActions from 'src/actions/jobs';
import * as seekersActions from 'src/actions/SeekerActions';
import * as teamsActions from 'src/actions/teams';
import * as employersActions from 'src/actions/employers';

import {
  AscendModal,
  AscendModalBody,
  AscendModalHeader,
  AscendModalFooter,
  PageHeader,
  PageFooter,
} from 'src/layout';

import JobProfile from 'src/components/JobProfile';
import {jobIsWatched} from 'src/utils/jobHelper';
import {jobIsApplied} from 'src/utils/jobHelper';
import AscendDataTable from 'src/components/AscendDataTable';
import WatchIcon from 'src/components/WatchIcon';
import {CandidateInfo} from 'src/components/CandidateInfo';
import CandidateSubMenu from 'src/components/Candidate/CandidateSubMenu';
import {includesApprenticeProgram} from '../../utils/miscHelper';
import {withRouter} from '../../utils/commonHooks';
import PreviousButton from 'src/components/PreviousButton';
import {has_permission, PERMISSIONS} from '../../services/authorizationApi';
import CandidateNotifyMissingRole from '../../components/Candidate/CandidateNotifyMissingRole';
import {format} from 'date-fns';
import infoBlueCircle from 'src/assets/images/info-blue-circle.svg';
import MatchCircle from 'src/components/MatchCircle';

class CandidateApplicationsPage extends React.Component {
  state = {
    jobProfileOpen: false,
    jobId: null,
    currentApplicationsPage: 0,
    currentHandpickedPage: 0,
    currentInvitedPage: 0,
    jobSortTerm: '',
    teamSortTerm: '',
    employerSortTerm: '',
    restrictedSegmentInfoModalIsOpen: false,
  };

  toggleRestrictedSegmentInfoModal = () => {
    this.setState({
      restrictedSegmentInfoModalIsOpen:
        !this.state.restrictedSegmentInfoModalIsOpen,
    });
  };

  componentDidMount() {
    let seekerId;
    if (this.props.match.params.id) {
      seekerId = this.props.match.params.id;
    } else {
      seekerId = this.props.loggedInSeekerId;
    }
    this.props.getSeekerAction(seekerId);
    has_permission(PERMISSIONS.ASCEND_RECRUITER).then((resp) => {
      this.showAdminLinks = resp.data.hasPermission;
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.seeker !== this.props.seeker) {
      this.setState({
        isMaSegment: this.props.seeker.segments.some(
          (x) => x.isApprenticeProgram
        ),
      });
    }
  }

  handleJobWatched = (job, watched) => {
    if (watched) {
      this.props.createJobWatch(job);
    } else {
      this.props.deleteJobWatch(job.id);
    }
  };

  handleJobClicked = (jobId) => {
    this.props.getJobToCache(jobId);
    this.setState({jobProfileOpen: true, jobId: jobId});
  };

  handleProfileClosed = () => {
    this.setState({jobProfileOpen: false});
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  handleJobPageChange = (newPage) => {
    this.setState({currentJobPage: newPage});
  };

  handleJobSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    this.setState({
      jobSortTerm: sortTerm,
      currentJobPage: 0,
    });
  };

  filterHandpicked = (invitations) => {
    return invitations.filter(
      (invitation) =>
        invitation.invitedByRole !== 'COMPANY_RECRUITER' &&
        invitation.invitedByRole !== 'COMPANY_RECRUITER_ADMIN'
    );
  };

  filterInvites = (invitations) => {
    return invitations.filter(
      (invitation) =>
        invitation.invitedByRole === 'COMPANY_RECRUITER' ||
        invitation.invitedByRole === 'COMPANY_RECRUITER_ADMIN'
    );
  };

  render() {
    const {seeker} = this.props;

    var job = {};
    if (this.state.jobId in this.props.jobCache) {
      job = this.props.jobCache[this.state.jobId];
    }

    const findMatchData = (jobId) => {
      const watchedJob = _.find(seeker.watchedJobs, {id: jobId});
      if (
        watchedJob &&
        watchedJob.matchData &&
        watchedJob.matchData.matchLevels
      ) {
        return watchedJob.matchData;
      } else {
        return {
          matchLevels: [0, 0, 0],
          ascendFit: false,
          overallFit: false,
          overallScore: 0,
          skillsScore: 0,
          stylesScore: 0,
          traitsScore: 0,
        };
      }
    };

    const jobTableColumns = [
      {
        displayName: 'ROLE',
        content: (job) => {
          const infoIcon = (
            <>
              &nbsp;
              <img
                style={{width: '16px'}}
                alt="Please note that this employer will not see your application within the Ascend Network."
                data-tooltip-id="application-list-tooltip"
                data-tooltip-delay-hide={200}
                src={infoBlueCircle}
              />
            </>
          );
          const roleLinkComponent = (
            <>
              <button
                className="buttonAsLink"
                onClick={() => this.handleJobClicked(job.id)}
              >
                {job.name}
              </button>
              {job.showRestrictedSegmentFlag && infoIcon}
            </>
          );
          const roleNameComponent = (
            <div style={{color: '#000000'}} tabIndex={0}>
              {job.name}
              {job.showRestrictedSegmentFlag && infoIcon}
            </div>
          );
          if (job.published) {
            return <>{roleLinkComponent}</>;
          }
          return <>{roleNameComponent}</>;
        },
      },
      {
        displayName: 'EMPLOYER',
        content: (job) => {
          return (
            <Link
              className="normal-subheader"
              style={{textDecoration: 'none'}}
              to={'/employer/' + job.employerId}
            >
              <div className="normal-subheader">{job.employerName}</div>
            </Link>
          );
        },
      },
      {
        displayName: 'DATE APPLIED',
        content: (job) => {
          return (
            <div className="normal-subheader" tabIndex={0}>
              {format(new Date(job.appliedDate), 'M/d/yyyy')}
            </div>
          );
        },
      },

      {
        displayName: 'MATCH',
        content: (job) => {
          if (!job.published) {
            return <div tabIndex={0}>Role Closed</div>;
          }
          let matchData = {
            matchLevels: [0, 0],
            ascendFit: false,
            overallFit: false,
            overallScore: 0,
            skillsScore: 0,
            stylesScore: 0,
            traitsScore: 0,
          };
          if (job.matchData && job.matchData.matchLevels) {
            matchData = job.matchData;
          }

          return (
            <div>
              <div className="inlineblock match-chart-host">
                <MatchCircle
                  key={job.id}
                  matchData={matchData}
                  viewerType="CANDIDATE"
                  isApprenticeProgram={includesApprenticeProgram(
                    this.props.seeker
                  )}
                  isCompact
                  noMargin
                />
              </div>
            </div>
          );
        },
      },
      {
        displayName: '',
        content: (job) => {
          if (!job.published) {
            return <div tabIndex={0}></div>;
          }

          return (
            <div>
              <div className="inlineblock float-right">
                <WatchIcon
                  watched={jobIsWatched(job.id, this.props.seeker)}
                  className=""
                  onClick={() =>
                    this.handleJobWatched(
                      job,
                      !jobIsWatched(job.id, this.props.seeker)
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.handleJobWatched(
                        job,
                        !jobIsWatched(job.id, this.props.seeker)
                      );
                    }
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ];

    const invitationTableColumns = [
      {
        displayName: 'ROLE',
        content: (job) => {
          return (
            <button
              className="buttonAsLink"
              onClick={() => this.handleJobClicked(job.jobId)}
            >
              {job.jobName}
            </button>
          );
        },
      },
      {
        displayName: 'EMPLOYER',
        content: (job) => {
          return (
            <Link
              className="normal-subheader"
              style={{textDecoration: 'none'}}
              to={'/employer/' + job.employerId}
            >
              <div className="normal-subheader">{job.employerName}</div>
            </Link>
          );
        },
      },
      {
        displayName: 'MATCH',
        content: (job) => {
          let matchData = {
            matchLevels: [0, 0],
            ascendFit: false,
            overallFit: false,
            overallScore: 0,
            skillsScore: 0,
            stylesScore: 0,
            traitsScore: 0,
          };

          if (job.matchData && job.matchData.matchLevels) {
            matchData = job.matchData;
          }
          return (
            <div>
              <div className="inlineblock match-chart-host">
                <MatchCircle
                  key={job.jobId}
                  matchData={matchData}
                  viewerType="CANDIDATE"
                  isApprenticeProgram={includesApprenticeProgram(
                    this.props.seeker
                  )}
                  isCompact
                  noMargin
                />
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <DocumentTitle title="Applications">
        <div className="wholepage">
          <PageHeader showAdminLinks={this.showAdminLinks} />

          <ReactTooltip
            id="application-list-tooltip"
            className="solid-tooltip-dark"
            globalCloseEvents={'escape'}
            clickable={true}
          >
            Please note that this employer will not see your application within
            the Ascend Network.&nbsp;
            <button
              className="buttonAsLink"
              style={{color: '#fff'}}
              type="button"
              onClick={() => {
                this.toggleRestrictedSegmentInfoModal();
              }}
            >
              Learn why here.
            </button>
          </ReactTooltip>

          <AscendModal
            isOpen={this.state.restrictedSegmentInfoModalIsOpen}
            onClose={this.toggleRestrictedSegmentInfoModal}
            centerVertically={true}
          >
            <AscendModalHeader headerClass="bd-modalheader" hasClose={true}>
              <span className="normal-headline-white bold">
                Further Information
              </span>
            </AscendModalHeader>
            <AscendModalBody>
              Due to data protection policies, we cannot show your personal
              information to this specific employer partner within the Ascend
              Network. You must submit an external application in order for this
              employer to see your information. To submit an external
              application, open the role and click the ‘Apply to this Role!’
              button. Please reach out to your point of contact or&nbsp;
              <a href="mailto:support@ascendindiana.com">
                support@ascendindiana.com
              </a>
              , for more information
            </AscendModalBody>
            <AscendModalFooter
              footerClass="bd-modalbody"
              style={{paddingTop: '0'}}
            >
              <hr />
              <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Button
                  color="primary"
                  onClick={this.toggleRestrictedSegmentInfoModal}
                >
                  Back to my Profile
                </Button>{' '}
              </div>
            </AscendModalFooter>
          </AscendModal>

          {job && job.id && (
            <JobProfile
              key={this.state.jobId}
              isOpen={this.state.jobProfileOpen}
              job={job}
              matchData={findMatchData(this.state.jobId)}
              onClose={this.handleProfileClosed}
              watched={jobIsWatched(this.state.jobId, this.props.seeker)}
              applied={jobIsApplied(this.state.jobId, this.props.seeker)}
              onAppliedChanged={(applied) =>
                this.handleAppliedChanged(this.state.jobId, applied)
              }
              onWatchChanged={(watched) => this.handleJobWatched(job, watched)}
            />
          )}

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <PreviousButton />
              <div className="container">
                {this.props.showJobNotFoundModal && (
                  <CandidateNotifyMissingRole
                    isOpen={this.props.showJobNotFoundModal}
                    onClose={() => this.props.setJobNotFoundModal(false)}
                  />
                )}
                <div className="row">
                  <CandidateInfo
                    seeker={this.props.seeker}
                    isMaSegment={this.state.isMaSegment}
                  />
                </div>
                {this.props.role === 'JOB_SEEKER' && <CandidateSubMenu />}

                <div className="padding-top-sixteen">
                  <div className="normal-title bold" tabIndex={0}>
                    Applications
                  </div>

                  <AscendDataTable
                    showSearch={false}
                    tableColumns={jobTableColumns}
                    items={this.props.seeker.appliedJobs}
                    currentPage={this.state.currentApplicationsPage}
                    itemsPerPage={100}
                    totalItems={100}
                    handlePageChange={this.handleJobPageChange}
                    handleSortChange={this.handleJobSortChange}
                    itemsLoading={this.props.seekerLoading}
                    enableSort={false}
                  />
                </div>

                <div>
                  <div
                    className="normal-title bold padding-top-sixteen"
                    tabIndex={0}
                  >
                    Handpicked by Ascend
                  </div>
                  <AscendDataTable
                    showSearch={false}
                    tableColumns={invitationTableColumns}
                    items={this.filterHandpicked(
                      this.props.seeker.invitationMatches
                    )}
                    currentPage={this.state.currentHandpickedPage}
                    itemsPerPage={100}
                    totalItems={100}
                    handlePageChange={this.handleJobPageChange}
                    handleSortChange={this.handleJobSortChange}
                    itemsLoading={this.props.seekerLoading}
                    enableSort={false}
                  />
                </div>
                <div>
                  <div
                    className="normal-title bold padding-top-sixteen"
                    tabIndex={0}
                  >
                    Invited by Employer
                  </div>
                  <AscendDataTable
                    showSearch={false}
                    tableColumns={invitationTableColumns}
                    items={this.filterInvites(
                      this.props.seeker.invitationMatches
                    )}
                    currentPage={this.state.currentInvitedPage}
                    itemsPerPage={100}
                    totalItems={100}
                    handlePageChange={this.handleJobPageChange}
                    handleSortChange={this.handleJobSortChange}
                    itemsLoading={this.props.seekerLoading}
                    enableSort={false}
                  />
                </div>
              </div>
            </main>
          </div>

          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

CandidateApplicationsPage.propTypes = {
  match: PropTypes.object.isRequired,
  loggedInSeekerId: PropTypes.string,
  seeker: PropTypes.object,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  role: PropTypes.string,
  getSeekerAction: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  createTeamWatch: PropTypes.func.isRequired,
  deleteTeamWatch: PropTypes.func.isRequired,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  createEmployerWatch: PropTypes.func.isRequired,
  deleteEmployerWatch: PropTypes.func.isRequired,
  seekerLoading: PropTypes.bool.isRequired,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loggedInSeekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
    jobCache: state.jobs.jobCache,
    role: state.profile.role,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
  };
}

const actions = {
  ...seekersActions,
  ...teamsActions,
  ...employersActions,
  ...jobsActions,
};

export default withRouter(
  connect(mapStateToProps, actions)(CandidateApplicationsPage)
);
