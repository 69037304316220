import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import FilterTagCheckbox from 'src/components/FilterTagCheckbox';
import arrowUp from 'src/assets/images/ArrowUp.svg';
import styles from './CandidateDiscoveryFilters.module.scss';
import $ from 'jquery';
import * as ACTIONS from 'src/actions/ActionTypes.js';
import InputSearch from 'src/components/controls/inputs/InputSearch';
import {CANDIDATE_DISCOVERY} from './candidateDiscoveryGlobals';

import BusyButtonMini from '../../components/BusyButtonMini';

const ONE_DAY = 24 * 60 * 60 * 1000;
const TEN_DAYS = 10 * ONE_DAY;
const THIRTY_DAYS = 30 * ONE_DAY;

const setAutoFocus = (lastRow, index) => {
  if (lastRow > 0) {
    if (index === lastRow) {
      return true;
    }
  }
  return false;
};
function ItemFilterComponent({
  filteredList,
  textFilterValue,
  itemCountToDisplay,
  dispatch,
  selectedFieldValues,
  filterType,
  reduxDisplayCountName,
  inputNamePrefix,
  applyButtonEnabled,
  applyFilters,
  applyButtonBusy,
  name,
  placeHolderObjectName,
  sourceObjectName,
}) {
  const [lastRow, setLastRow] = useState(0);

  if (filteredList.length === 0 && textFilterValue === '') {
    return <span className={styles.emptyOptions}>Loading...</span>;
  }
  let pagedItems = [];
  const limitItems = itemCountToDisplay < filteredList.length;
  if (limitItems) {
    pagedItems = filteredList.slice(0, itemCountToDisplay);
  } else {
    pagedItems = filteredList;
  }

  return (
    <fieldset>
      <legend style={{position: 'absolute', clipPath: 'circle()'}}>
        {name}
      </legend>
      <InputSearch
        style={{
          backgroundColor: 'var(--neutral-gray-6)',
          paddingLeft: '0.25rem',
          paddingRight: '0.25rem',
        }}
        textBoxStyle={{paddingLeft: 0}}
        placeHolder={`Search for ${placeHolderObjectName}`}
        value={textFilterValue}
        inputId={`${sourceObjectName}-${inputNamePrefix}Search`}
        onChange={(value) => {
          setLastRow(0);
          dispatch({
            type:
              sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
                ? ACTIONS.SAVED_CANDIDATE__FILTER_LIST
                : ACTIONS.CANDIDATE_DISCOVERY__FILTER_LIST,
            filterType: filterType,
            filterValue: value,
          });
        }}
      />
      <p />
      {pagedItems.length === 0 && (
        <span className={styles.emptyOptions}>No matching items</span>
      )}
      {pagedItems.map((item, index) => {
        const checkboxName = `${inputNamePrefix}-${item.id}#${item.name}`;

        return (
          <FilterTagCheckbox
            key={item.id}
            onChange={() => {
              if (sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates) {
                dispatch({
                  type: ACTIONS.SAVED_CANDIDATE__TOGGLE_FILTER_ITEM,
                  toggledName: checkboxName,
                });
              } else {
                dispatch({
                  type: ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM,
                  toggledName: checkboxName,
                });
                dispatch({
                  type:
                    sourceObjectName ===
                    ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED,
                  savedSearchSelectedItem: null,
                });
              }
            }}
            name={checkboxName}
            value={item.id}
            label={item.name}
            selected={selectedFieldValues.includes(checkboxName)}
            autoFocus={setAutoFocus(lastRow, index)}
          />
        );
      })}
      <div className="d-flex justify-content-center mt-2">
        <BusyButtonMini
          onClick={applyFilters}
          buttonText="Apply"
          busyText="Filtering ..."
          busy={applyButtonBusy}
          disabled={!applyButtonEnabled}
          style={{fontSize: '10px', width: '98px'}}
        />
      </div>
      {limitItems && (
        <button
          className={styles.showMoreButtonAsLink}
          onClick={() => {
            setLastRow(pagedItems.length);
            dispatch({
              type:
                sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
                  ? ACTIONS.SAVED_CANDIDATE__INCREASE_LIST_ITEM_COUNT
                  : ACTIONS.CANDIDATE_DISCOVERY__INCREASE_LIST_ITEM_COUNT,
              itemCountName: reduxDisplayCountName,
            });
          }}
        >
          + Show More
        </button>
      )}
    </fieldset>
  );
}

ItemFilterComponent.propTypes = {
  filteredList: PropTypes.array.isRequired,
  textFilterValue: PropTypes.string.isRequired,
  itemCountToDisplay: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedFieldValues: PropTypes.array.isRequired,
  filterType: PropTypes.string.isRequired,
  reduxDisplayCountName: PropTypes.string.isRequired,
  inputNamePrefix: PropTypes.string.isRequired,
  applyButtonEnabled: PropTypes.bool,
  applyFilters: PropTypes.func,
  applyButtonBusy: PropTypes.bool,
  name: PropTypes.string,
  placeHolderObjectName: PropTypes.string,
  sourceObjectName: PropTypes.string,
};

function GradYearComponent({
  graduationYearCountToDisplay,
  filterItemToggled,
  selectedFieldValues,
  getApplyButton,
  dispatch,
  name,
  sourceObjectName,
}) {
  const [lastRow, setLastRow] = useState(0);
  const graduationYears = [];
  for (let i = 1900; i < new Date().getFullYear() + 8; i++) {
    graduationYears.push(i);
  }
  graduationYears.sort((a, b) => (a < b ? 1 : -1));
  let pagedGradYears = [];
  const limitGradYears = graduationYearCountToDisplay < graduationYears.length;
  if (limitGradYears) {
    pagedGradYears = graduationYears.slice(0, graduationYearCountToDisplay);
  } else {
    pagedGradYears = graduationYears;
  }
  return (
    <fieldset>
      <legend style={{position: 'absolute', clipPath: 'circle()'}}>
        {name}
      </legend>
      {pagedGradYears.map((year, index) => {
        const checkboxName = `${sourceObjectName}-graduationYears-${year}#${year}`;
        return (
          <FilterTagCheckbox
            key={year}
            onChange={() => {
              filterItemToggled(checkboxName, sourceObjectName);
            }}
            name={checkboxName}
            value={year}
            label={year}
            selected={selectedFieldValues.includes(checkboxName)}
            autoFocus={setAutoFocus(lastRow, index)}
          />
        );
      })}
      {getApplyButton()}
      {limitGradYears && (
        <button
          className={styles.showMoreButtonAsLink}
          onClick={() => {
            setLastRow(pagedGradYears.length);
            dispatch({
              type:
                sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
                  ? ACTIONS.SAVED_CANDIDATE__INCREASE_LIST_ITEM_COUNT
                  : ACTIONS.CANDIDATE_DISCOVERY__INCREASE_LIST_ITEM_COUNT,
              itemCountName: `${sourceObjectName}GraduationYearCountToDisplay`,
            });
          }}
        >
          + Show More
        </button>
      )}
    </fieldset>
  );
}

GradYearComponent.propTypes = {
  graduationYearCountToDisplay: PropTypes.number.isRequired,
  filterItemToggled: PropTypes.func.isRequired,
  selectedFieldValues: PropTypes.array.isRequired,
  getApplyButton: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string,
  sourceObjectName: PropTypes.string,
};

function RegionsComponent({
  regions,
  regionCountToDisplay,
  filterItemToggled,
  selectedFieldValues,
  getApplyButton,
  dispatch,
  name,
  sourceObjectName,
}) {
  const [lastRow, setLastRow] = useState(0);
  if (regions.length === 0) {
    return <span className={styles.emptyOptions}>Loading...</span>;
  }
  let filteredRegions = [];
  const limitRegions = regionCountToDisplay < regions.length;
  if (limitRegions) {
    filteredRegions = regions.slice(0, regionCountToDisplay);
  } else {
    filteredRegions = regions;
  }
  return (
    <fieldset>
      <legend style={{position: 'absolute', clipPath: 'circle()'}}>
        {name}
      </legend>
      {filteredRegions.map((region, index) => {
        const checkboxName = `${sourceObjectName}-regionIds-${region.id}#${region.name}`;
        return (
          <FilterTagCheckbox
            key={region.id}
            onChange={() => {
              filterItemToggled(checkboxName, sourceObjectName);
            }}
            name={checkboxName}
            value={region.id}
            label={region.name}
            selected={selectedFieldValues.includes(checkboxName)}
            autoFocus={setAutoFocus(lastRow, index)}
          />
        );
      })}
      {getApplyButton()}
      {limitRegions && (
        <button
          className={styles.showMoreButtonAsLink}
          onClick={() => {
            setLastRow(filteredRegions.length);
            dispatch({
              type:
                sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
                  ? ACTIONS.SAVED_CANDIDATE__INCREASE_LIST_ITEM_COUNT
                  : ACTIONS.CANDIDATE_DISCOVERY__INCREASE_LIST_ITEM_COUNT,
              itemCountName: `${sourceObjectName}RegionCountToDisplay`,
            });
          }}
        >
          + Show More
        </button>
      )}
    </fieldset>
  );
}
RegionsComponent.propTypes = {
  regions: PropTypes.array.isRequired,
  regionCountToDisplay: PropTypes.number.isRequired,
  filterItemToggled: PropTypes.func.isRequired,
  selectedFieldValues: PropTypes.array.isRequired,
  getApplyButton: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string,
  sourceObjectName: PropTypes.string,
};

export const CandidateDiscoveryFilters = ({
  applyFiltersClick,
  onClear,
  sourceObjectName,
}) => {
  const dispatch = useDispatch();

  let {
    expandedSectionIndexes,
    employmentTypes,
    experienceLevels,
    degreeTypeRanks,
    segments,
    role,
    regions,
    roleTypeCountToDisplay,
    regionCountToDisplay,
    industryCountToDisplay,
    filteredSkills,
    skillCountToDisplay,
    filteredMajors,
    majorCountToDisplay,
    majorsTextFilter,
    skillTextFilter,
    roleTypeTextFilter,
    filteredRoleTypes,
    filteredIndustries,
    industryTextFilter,
    graduationYearCountToDisplay,
    selectedFieldValues,
    loggedInAfter,
    jobObj,
    applyButtonEnabled,
    applyButtonBusy,
    collapseAllFilterSections,
  } = useSelector((state) => {
    return {
      loggedInAfter:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesLoggedInAfter
          : state.candidateDiscoveryReducer.exploreCandidatesLoggedInAfter,
      expandedSectionIndexes:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer
              .savedCandidatesExpandedSectionIndexes
          : state.candidateDiscoveryReducer.expandedSectionIndexes,
      skillCountToDisplay:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesSkillCountToDisplay
          : state.candidateDiscoveryReducer
              .exploreCandidatesSkillCountToDisplay,
      roleTypeCountToDisplay:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer
              .savedCandidatesRoleTypeCountToDisplay
          : state.candidateDiscoveryReducer
              .exploreCandidatesRoleTypeCountToDisplay,
      regionCountToDisplay:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesRegionCountToDisplay
          : state.candidateDiscoveryReducer
              .exploreCandidatesRegionCountToDisplay,
      industryCountToDisplay:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer
              .savedCandidatesIndustryCountToDisplay
          : state.candidateDiscoveryReducer
              .exploreCandidatesIndustryCountToDisplay,
      selectedFieldValues:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidateSelectedFieldValues
          : state.candidateDiscoveryReducer.selectedFieldValues,
      employmentTypes: state.employmentTypes.employmentTypes,
      experienceLevels: state.experienceLevels.experienceLevels,
      degreeTypeRanks: state.degreeTypeRank.degreeRank,
      segments: state.segments.segmentList,
      role: state.profile.role,
      regions: state.regions.regions,
      roleTypes: state.roleTypes.roleTypes,
      filteredRoleTypes: state.roleTypes.filteredRoleTypes,
      industries: state.industries.industries,
      filteredIndustries: state.industries.filteredIndustries,
      filteredSkills: state.skillTags.filteredSkills,
      skillTextFilter:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer[
              CANDIDATE_DISCOVERY.savedCandidateSkillsFilter
            ]
          : state.candidateDiscoveryReducer[CANDIDATE_DISCOVERY.skillsFilter],
      roleTypeTextFilter:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer[
              CANDIDATE_DISCOVERY.savedCandidateRolesFilter
            ]
          : state.candidateDiscoveryReducer[CANDIDATE_DISCOVERY.rolesFilter],
      industryTextFilter:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer[
              CANDIDATE_DISCOVERY.savedCandidateIndustryFilter
            ]
          : state.candidateDiscoveryReducer[CANDIDATE_DISCOVERY.industryFilter],
      majorsTextFilter:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer[
              CANDIDATE_DISCOVERY.savedCandidateMajorsFilter
            ]
          : state.candidateDiscoveryReducer[CANDIDATE_DISCOVERY.majorsFilter],
      filteredMajors: state.institutions.filteredMajors,
      majorCountToDisplay:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesMajorCountToDisplay
          : state.candidateDiscoveryReducer
              .exploreCandidatesMajorCountToDisplay,
      selectedMajorIds:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidateSelectedMajorIds
          : state.candidateDiscoveryReducer.selectedMajorIds,
      graduationYearCountToDisplay:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer
              .savedCandidatesGraduationYearCountToDisplay
          : state.candidateDiscoveryReducer
              .exploreCandidatesGraduationYearCountToDisplay,
      jobObj:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesJobObj
          : state.candidateDiscoveryReducer.jobObj,
      applyButtonEnabled:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesApplyButtonEnabled
          : state.candidateDiscoveryReducer.applyButtonEnabled,
      applyButtonBusy:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer.savedCandidatesApplyButtonBusy
          : state.candidateDiscoveryReducer.applyButtonBusy,
      collapseAllFilterSections:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? state.candidateDiscoveryReducer
              .savedCandidatesCollapseAllFilterSections
          : state.candidateDiscoveryReducer.collapseAllFilterSections,
    };
  }, shallowEqual);

  const toggleSearchSection = (index, childSectionId) => {
    $(`#${childSectionId}`).toggle(150, 'linear');
    $(`#img${childSectionId}`).toggleClass(styles.down);
    dispatch({
      sectionName: childSectionId,
      type:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? ACTIONS.SAVED_CANDIDATE__TOGGLE_EXPANDED_SECTION
          : ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_EXPANDED_SECTION,
      index,
    });
  };

  const collapseFilterSections = () => {
    $('.expando-section').hide(150, 'linear');
    $('.twisty-chevron').removeClass(styles.down);
  };

  if (collapseAllFilterSections) {
    collapseFilterSections();
    dispatch({
      type:
        sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
          ? ACTIONS.SAVED_CANDIDATE__SET_COLLAPSE_ALL_FILTER_SECTIONS
          : ACTIONS.CANDIDATE_DISCOVERY__SET_COLLAPSE_ALL_FILTER_SECTIONS,
      collapseAllFilterSections: false,
    });
  }

  const applyFilters = () => {
    applyFiltersClick();
    collapseFilterSections();
  };

  regions.sort((r1, r2) => r1.name.localeCompare(r2.name));
  regions = regions.filter((r) => r.name !== 'Any');

  degreeTypeRanks.sort((d1, d2) => d1.name.localeCompare(d2.name));
  segments.sort((s1, s2) => s1.name.localeCompare(s2.name));
  filteredSkills.sort((s1, s2) => s1.name.localeCompare(s2.name));

  const getApplyButton = () => {
    return (
      <div className="d-flex justify-content-center mt-2">
        <div>
          <BusyButtonMini
            onClick={applyFilters}
            buttonText="Apply"
            busyText="Filtering ..."
            busy={applyButtonBusy}
            disabled={!applyButtonEnabled}
            style={{fontSize: '10px', width: '98px'}}
          />
        </div>
      </div>
    );
  };

  const filterItemToggled = (toggledFieldName, sourceObjectName) => {
    if (sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates) {
      dispatch({
        type: ACTIONS.SAVED_CANDIDATE__TOGGLE_FILTER_ITEM,
        toggledName: toggledFieldName,
      });
    } else {
      dispatch({
        type: ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM,
        toggledName: toggledFieldName,
      });
      dispatch({
        type: ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED,
        savedSearchSelectedItem: null,
      });
    }
  };

  const searchSections = [
    {
      name: 'RECENT LOGIN',
      showTwisty: true,
      showSearchSection: () => true,
      childSectionId: `${sourceObjectName}LoggedInAfterSection`,
      filterComponent: () => {
        const loggedInAfterChange = (daysInMilliseconds) => {
          if (sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates) {
            dispatch({
              type: ACTIONS.SAVED_CANDIDATE__DATE_RADIO_SELECT,
              toggledName: CANDIDATE_DISCOVERY.savedCandidatesLoggedInAfter,
              toggledValue: daysInMilliseconds,
            });
          } else {
            dispatch({
              type: ACTIONS.CANDIDATE_DISCOVERY__DATE_RADIO_SELECT,
              toggledName: CANDIDATE_DISCOVERY.exploreCandidatesLoggedInAfter,
              toggledValue: daysInMilliseconds,
            });
            dispatch({
              type: ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED,
              savedSearchSelectedItem: null,
            });
          }
        };
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              RECENT LOGINS
            </legend>
            <label className="radio">
              Any
              <input
                type="radio"
                name={`${sourceObjectName}LoggedInAfter`}
                value=""
                onChange={() => loggedInAfterChange('')}
                checked={loggedInAfter === '' && 'checked'}
              />
              <span className="radio-checkmark-orange" />
            </label>
            <label className="radio">
              Last 10 Days
              <input
                type="radio"
                name={`${sourceObjectName}LoggedInAfter`}
                value="10"
                onChange={() => {
                  loggedInAfterChange(TEN_DAYS);
                }}
                checked={loggedInAfter === TEN_DAYS && 'checked'}
              />
              <span className="radio-checkmark-orange" />
            </label>
            <label className="radio">
              Last 30 Days
              <input
                type="radio"
                name={`${sourceObjectName}LoggedInAfter`}
                value="30"
                onChange={() => {
                  loggedInAfterChange(THIRTY_DAYS);
                }}
                checked={loggedInAfter === THIRTY_DAYS && 'checked'}
              />
              <span className="radio-checkmark-orange" />
            </label>
            {getApplyButton()}
          </fieldset>
        );
      },
    },
    {
      name: 'SKILLS',
      showTwisty: true,
      childSectionId: `${sourceObjectName}Skills`,
      showSearchSection: () => true,
      props: {
        filteredList: filteredSkills,
        textFilterValue: skillTextFilter,
        itemCountToDisplay: skillCountToDisplay,
        dispatch,
        selectedFieldValues: selectedFieldValues.filter((value) =>
          value.includes('skillTagIds')
        ),
        filterType: CANDIDATE_DISCOVERY.skillsFilter,
        reduxDisplayCountName: `${sourceObjectName}SkillCountToDisplay`,
        inputNamePrefix: `${sourceObjectName}-skillTagIds`,
        applyButtonEnabled: applyButtonEnabled,
        applyFilters: applyFilters,
        applyButtonBusy: applyButtonBusy,
        name: 'SKILLS',
        placeHolderObjectName: 'Skills',
        sourceObjectName: sourceObjectName,
      },
      filterComponent: ItemFilterComponent,
    },
    {
      name: 'MAJORS',
      showTwisty: true,
      childSectionId: `${sourceObjectName}Majors`,
      showSearchSection: () => true,
      props: {
        filteredList: filteredMajors,
        textFilterValue: majorsTextFilter,
        itemCountToDisplay: majorCountToDisplay,
        dispatch,
        selectedFieldValues: selectedFieldValues.filter((value) =>
          value.includes('majorIds')
        ),
        filterType: CANDIDATE_DISCOVERY.majorsFilter,
        reduxDisplayCountName: `${sourceObjectName}MajorCountToDisplay`,
        inputNamePrefix: `${sourceObjectName}-majorIds`,
        applyButtonEnabled: applyButtonEnabled,
        applyFilters: applyFilters,
        applyButtonBusy: applyButtonBusy,
        name: 'MAJORS',
        placeHolderObjectName: 'Majors',
        sourceObjectName: sourceObjectName,
      },
      filterComponent: ItemFilterComponent,
    },
    {
      name: 'GRADUATION YEAR',
      showTwisty: true,
      childSectionId: `${sourceObjectName}GraduationYear`,
      showSearchSection: () => true,
      props: {
        graduationYearCountToDisplay: graduationYearCountToDisplay,
        filterItemToggled: filterItemToggled,
        selectedFieldValues: selectedFieldValues,
        getApplyButton: getApplyButton,
        dispatch: dispatch,
        name: 'GRADUATION YEAR',
        sourceObjectName: sourceObjectName,
      },

      filterComponent: GradYearComponent,
    },
    {
      name: 'EMPLOYMENT TYPE',
      showTwisty: true,
      showSearchSection: () => true,
      childSectionId: `${sourceObjectName}EmploymentTypes`,
      filterComponent: () => {
        if (employmentTypes.length === 0) {
          return <span className={styles.emptyOptions}>Loading...</span>;
        }
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              EMPLOYMENT TYPE
            </legend>
            {employmentTypes.map((employmentType) => {
              const checkboxName = `${sourceObjectName}-employmentTypeIds-${employmentType.id}#${employmentType.name}`;
              return (
                <FilterTagCheckbox
                  key={employmentType.id}
                  onChange={() => {
                    filterItemToggled(checkboxName, sourceObjectName);
                  }}
                  name={checkboxName}
                  value={employmentType.id}
                  label={employmentType.name}
                  selected={selectedFieldValues.includes(checkboxName)}
                />
              );
            })}
            {getApplyButton()}
          </fieldset>
        );
      },
    },
    {
      name: 'EXPERIENCE LEVEL',
      showTwisty: true,
      showSearchSection: () => true,
      childSectionId: `${sourceObjectName}ExperienceLevels`,
      filterComponent: () => {
        if (experienceLevels.length === 0) {
          return <span className={styles.emptyOptions}>Loading...</span>;
        }
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              EXPERIENCE LEVEL
            </legend>
            {experienceLevels.map((experienceLevel, index) => {
              const checkboxName = `${sourceObjectName}-experienceLevelIds-${experienceLevel.id}#${experienceLevel.years}`;
              return (
                <div key={index}>
                  <FilterTagCheckbox
                    key={experienceLevel.id}
                    onChange={() => {
                      filterItemToggled(checkboxName, sourceObjectName);
                    }}
                    name={checkboxName}
                    value={experienceLevel.id}
                    label={experienceLevel.years}
                    selected={selectedFieldValues.includes(checkboxName)}
                  />
                </div>
              );
            })}

            {getApplyButton()}
          </fieldset>
        );
      },
    },
    {
      name: 'DEGREE TYPE',
      showTwisty: true,
      showSearchSection: () => true,
      childSectionId: `${sourceObjectName}DegreeTypes`,
      filterComponent: () => {
        if (degreeTypeRanks.length === 0) {
          return <span className={styles.emptyOptions}>Loading...</span>;
        }
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              DEGREE TYPE
            </legend>
            {degreeTypeRanks.map((degreeRank) => {
              const checkboxName = `${sourceObjectName}-degreeTypeRankIds-${degreeRank.id}#${degreeRank.name}`;
              return (
                <FilterTagCheckbox
                  key={degreeRank.id}
                  onChange={() => {
                    filterItemToggled(checkboxName, sourceObjectName);
                  }}
                  name={checkboxName}
                  value={degreeRank.id}
                  label={degreeRank.name}
                  selected={selectedFieldValues.includes(checkboxName)}
                />
              );
            })}

            {getApplyButton()}
          </fieldset>
        );
      },
    },
    {
      name: 'SEGMENT',
      showTwisty: true,
      childSectionId: `${sourceObjectName}Segments`,
      showSearchSection: () =>
        role !== 'COMPANY_RECRUITER' && role !== 'COMPANY_RECRUITER_ADMIN',
      filterComponent: () => {
        if (segments.length === 0) {
          return <span className={styles.emptyOptions}>Loading...</span>;
        }
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              SEGMENT
            </legend>
            {segments.map((segment) => {
              const checkboxName = `${sourceObjectName}-segmentIds-${segment.id}#${segment.name}`;
              return (
                <FilterTagCheckbox
                  key={segment.id}
                  onChange={() => {
                    filterItemToggled(checkboxName, sourceObjectName);
                  }}
                  name={checkboxName}
                  value={segment.id}
                  label={segment.name}
                  selected={selectedFieldValues.includes(checkboxName)}
                />
              );
            })}
            {getApplyButton()}
          </fieldset>
        );
      },
    },
    {
      name: 'REGIONS',
      showTwisty: true,
      childSectionId: `${sourceObjectName}Regions`,
      showSearchSection: () => true,
      props: {
        regions: regions,
        regionCountToDisplay: regionCountToDisplay,
        filterItemToggled: filterItemToggled,
        selectedFieldValues: selectedFieldValues,
        getApplyButton: getApplyButton,
        dispatch: dispatch,
        name: 'REGIONS',
        sourceObjectName: sourceObjectName,
      },
      filterComponent: RegionsComponent,
    },
    {
      name: 'ROLES',
      showTwisty: true,
      childSectionId: `${sourceObjectName}RoleTypes`,
      showSearchSection: () => true,
      filterComponent: ItemFilterComponent,
      props: {
        filterType: CANDIDATE_DISCOVERY.rolesFilter,
        filteredList: filteredRoleTypes,
        textFilterValue: roleTypeTextFilter,
        dispatch,
        selectedFieldValues: selectedFieldValues.filter((item) =>
          item.includes('roleType')
        ),
        reduxDisplayCountName: `${sourceObjectName}RoleTypeCountToDisplay`,
        itemCountToDisplay: roleTypeCountToDisplay,
        inputNamePrefix: `${sourceObjectName}-roleTypeIds`,
        applyButtonEnabled: applyButtonEnabled,
        applyFilters: applyFilters,
        applyButtonBusy: applyButtonBusy,
        name: 'ROLES',
        placeHolderObjectName: 'Roles',
        sourceObjectName: sourceObjectName,
      },
    },
    {
      name: 'INDUSTRIES',
      showTwisty: true,
      childSectionId: `${sourceObjectName}Industries`,
      showSearchSection: () => true,
      filterComponent: ItemFilterComponent,
      props: {
        filteredList: filteredIndustries,
        textFilterValue: industryTextFilter,
        dispatch,
        filterType: CANDIDATE_DISCOVERY.industryFilter,
        selectedFieldValues: selectedFieldValues.filter((item) =>
          item.includes('industry')
        ),
        itemCountToDisplay: industryCountToDisplay,
        reduxDisplayCountName: `${sourceObjectName}IndustryCountToDisplay`,
        inputNamePrefix: `${sourceObjectName}-industryIds`,
        applyButtonEnabled: applyButtonEnabled,
        applyFilters: applyFilters,
        applyButtonBusy: applyButtonBusy,
        name: 'INDUSTRIES',
        placeHolderObjectName: 'Industries',
        sourceObjectName: sourceObjectName,
      },
    },
    {
      name: 'CANDIDATE FIT',
      showTwisty: true,
      childSectionId: `${sourceObjectName}CandidateFit`,
      showSearchSection: () => jobObj !== null,
      filterComponent: () => {
        const overallFitField = 'overallFit-true#Overall Fit';
        const skillsFitField = 'skillsFit-true#Skills Fit';
        const workStylesFitField = 'workStylesFit-true#Work Styles Fit';
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              CANDIDATE FIT
            </legend>
            <FilterTagCheckbox
              onChange={() => {
                filterItemToggled(overallFitField, sourceObjectName);
              }}
              name={overallFitField}
              label="Overall Fit"
              selected={selectedFieldValues.includes(overallFitField)}
              disabled={false}
            />
            <FilterTagCheckbox
              onChange={() => {
                filterItemToggled(skillsFitField, sourceObjectName);
              }}
              name={skillsFitField}
              label="Skills Fit"
              selected={selectedFieldValues.includes(skillsFitField)}
              disabled={false}
            />
            <FilterTagCheckbox
              onChange={() => {
                filterItemToggled(workStylesFitField, sourceObjectName);
              }}
              name={workStylesFitField}
              label="Work Style Fit"
              selected={selectedFieldValues.includes(workStylesFitField)}
              disabled={false}
            />
            {getApplyButton()}
          </fieldset>
        );
      },
    },
    {
      name: 'CANDIDATE ACTIVITY',
      showTwisty: true,
      childSectionId: `${sourceObjectName}CandidateActivity`,
      showSearchSection: () => jobObj !== null,
      filterComponent: () => {
        const interestedField = 'interested-true#Interested';
        const appliedField = 'applied-true#Applied';
        const invitedField = 'invited-true#Invited';
        return (
          <fieldset>
            <legend style={{position: 'absolute', clipPath: 'circle()'}}>
              CANDIDATE ACTIVITY
            </legend>
            <label className="radio">
              Interested
              <input
                type="radio"
                name={interestedField}
                value=""
                onChange={() => {
                  filterItemToggled(interestedField, sourceObjectName);
                }}
                checked={selectedFieldValues.includes(interestedField)}
              />
              <span className="radio-checkmark-orange" />
            </label>
            <label className="radio">
              Applied
              <input
                type="radio"
                name={appliedField}
                value=""
                onChange={() => {
                  filterItemToggled(appliedField, sourceObjectName);
                }}
                checked={selectedFieldValues.includes(appliedField)}
              />
              <span className="radio-checkmark-orange" />
            </label>
            <label className="radio">
              Invited to Apply
              <input
                type="radio"
                name={invitedField}
                value=""
                onChange={() => {
                  filterItemToggled(invitedField, sourceObjectName);
                }}
                checked={selectedFieldValues.includes(invitedField)}
              />
              <span className="radio-checkmark-orange" />
            </label>
            {getApplyButton()}
          </fieldset>
        );
      },
    },
  ];

  return (
    <div className="flex-column">
      <div style={{maxWidth: '185px'}}>
        <h2 className={styles.filterHeader}>Filters</h2>
        {searchSections
          .filter((section) => section.showSearchSection())
          .map((section, index) => {
            return (
              <div key={`${section.name}-${index}`}>
                <button
                  className={styles.buttonAsLink}
                  onClick={() =>
                    toggleSearchSection(index, section.childSectionId)
                  }
                  aria-expanded={expandedSectionIndexes.some(
                    (x) => x.index === index
                  )}
                >
                  <h3 className={`nowrap ${styles.filterHeaders}`}>
                    {section.name}
                  </h3>
                  {section.showTwisty && (
                    <img
                      id={`img${section.childSectionId}`}
                      alt=""
                      src={arrowUp}
                      className={`twisty-chevron ${styles.rotate}`}
                    />
                  )}
                </button>
                <div
                  className={
                    section.showTwisty &&
                    !expandedSectionIndexes.includes(index)
                      ? `${styles.childCheckBox} expando-section`
                      : 'expando-section'
                  }
                  id={section.childSectionId}
                >
                  <section.filterComponent {...section.props} />
                </div>
                <hr className={styles.horizontalRule} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

CandidateDiscoveryFilters.propTypes = {
  applyFiltersClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filterGroupsClosed: PropTypes.bool,
  sourceObjectName: PropTypes.string,
};
