import React, {useState} from 'react';
import {AscendModal, AscendModalBody, AscendModalHeader} from '../../layout';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import AscendSelect from '../controls/AscendSelect';
import styles from './CandidateAssignManager.module.scss';
import OutlineButton from '../controls/buttons/OutlineButton';
import {setCalendlySkip} from '../../actions/IntakeCacheActions';
import {updateSeeker} from '../../actions/seekers';

export default function CandidateAssignManager({
  displayAssignManagerModalVisible,
  seeker,
  onClose,
}) {
  const dispatch = useDispatch();
  const [displayAssignManagerModal, setDisplayAssignManagerModal] = useState(
    displayAssignManagerModalVisible
  );
  const [selectedManager, setSelectedManager] = useState();
  const [selectedManagerId, setSelectedManagerId] = useState();

  const managerList = useSelector(
    (state) => state.ascendRecruiters?.ascendRecruiters
  );

  const currentManager = useSelector((state) =>
    managerList.find((x) => x.userId === state.profile.id)
  );

  const handleAssignToMe = () => {
    setSelectedManager(currentManager);
    setSelectedManagerId({
      id: currentManager.userId,
      label: currentManager.name,
    });
  };

  const handleAssign = (selection) => {
    setSelectedManagerId(selection);
    const manager = managerList.find((x) => x.userId === selection.id);
    setSelectedManager(manager);
  };

  const handleClose = () => {
    onClose();
    setDisplayAssignManagerModal(false);
  };

  const handleSubmit = () => {
    const updatedSeeker = {...seeker, ascendRecruiterId: selectedManager.id};
    dispatch(updateSeeker(updatedSeeker, {success: 'Manager assigned'}));
    dispatch(setCalendlySkip(seeker.userId, true));

    onClose();
    setDisplayAssignManagerModal(false);
  };

  const pullDownList = () => {
    let list = [];
    managerList.map((admin) =>
      list.push({id: admin.userId, label: admin.name})
    );
    return list;
  };

  return (
    <>
      <AscendModal
        isOpen={displayAssignManagerModal}
        onClose={() => {
          onClose();
          setDisplayAssignManagerModal(false);
        }}
        centerVertically={true}
        size="md"
        backdrop={'static'}
      >
        <AscendModalHeader headerClass="bd-modalheader" hasClose={true}>
          <div>
            <h1
              style={{fontSize: '22px'}}
              className="normal-headline-white bold mb-0"
            >
              Override Call & Assign Manager
            </h1>
          </div>
        </AscendModalHeader>

        <AscendModalBody>
          <div className="p-3">
            <p>
              When you click ‘Override’,{' '}
              <b>{`${seeker.firstName} ${seeker.lastName}`}</b> will be able to
              create their account without scheduling a 1:1 call.
              <br /> Select the manager to whom you’d like to assign this
              candidate below.
            </p>
            <div className="flex-row pb-4">
              <AscendSelect
                label={'Manager:'}
                onChange={(e) => {
                  handleAssign(e);
                }}
                options={pullDownList()}
                visible={true}
                value={selectedManagerId}
                placeholder={'Select a Manager'}
                searchable={true}
                isDisabled={false}
                fullWidth={true}
              />
            </div>

            <div className={styles.buttonWrapper}>
              <button
                onClick={handleAssignToMe}
                className="buttonAsLink"
                data-tooltip-id="tooltip"
                data-tooltip-html={'Copy Link'}
              >
                Assign to me
              </button>
            </div>
          </div>

          <footer className="d-flex justify-content-center mt-4">
            <OutlineButton buttonText="Cancel" onClick={handleClose} />
            <button
              onClick={handleSubmit}
              type="button"
              className={`ms-2 btn btn-primary ${styles.assignManagerButton}`}
              disabled={!selectedManagerId}
            >
              Assign & Override
            </button>
          </footer>
        </AscendModalBody>
      </AscendModal>
    </>
  );
}

CandidateAssignManager.propTypes = {
  displayAssignManagerModalVisible: PropTypes.bool,
  seeker: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
