import mixpanel from 'mixpanel-browser';
import {makeRequest} from '../services/api';

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
const reactAppVersion = process.env.REACT_APP_VERSION;
const apiUrl = process.env.REACT_APP_API_URL;

if (apiUrl === undefined) {
  throw new Error(
    'Missing required environment variables! Run app from chamber: chamber exec dev -- npm start'
  );
}

mixpanel.init(mixpanelToken);
mixpanel.register({
  appVersion: reactAppVersion,
  apiUrl: apiUrl,
});

export const track = (data) => {
  const {eventName, registerData, ...eventData} = data;
  if (registerData) {
    mixpanel.register(registerData);
    mixpanel.people.set({
      $name: registerData.name,
      $email: registerData.email,
    });
    if (registerData.id) {
      mixpanel.identify(registerData.id);
    }
  }
  if (eventName) {
    mixpanel.track(eventName, eventData);
    window.analytics.track(eventName, eventData);
  }
};

export const trackVisibility = async ({
  seekerId,
  jobId,
  eventName,
  pageNumber = 0,
}) => {
  makeRequest({
    method: 'POST',
    path: `v2/job_seeker_event`,
    body: {
      seekerId: seekerId,
      jobId: jobId,
      eventName: eventName,
      pageNumber: pageNumber,
    },
    isAuthenticated: true,
  }).then((response) => {
    return response;
  });
};
