import AscendNetworkLogo from 'src/assets/svg/icons/Ascend-Network-logo.svg';
import AscendNetworkLogoBlack from 'src/assets/images/ILI-images/Ascend-Network-black.svg';
import Swoops from 'src/assets/images/Swoops_Background.svg';
import IliSwoops from 'src/assets/images/ILI-images/ILI_Swoop.svg';
import TechpointSwoops from 'src/assets/images/Techpoint_Swoops.svg';
import TechpointLogo from 'src/assets/images/techpoint white logo 2.svg';
import IliLogo from 'src/assets/images/ILI-images/ILI logo_full color.png';
import LogoWithNameAndState from 'src/assets/images/LogoWithNameAndSate.svg';
import EmployIndyLogoWhite from '../assets/images/EmployIndy_Logo_white_with_red.png';
import AscendNetworkWhite from 'src/assets/images/AscendNetworkWhite.svg';

export const signupStyles = {
  ascend: {
    font: 'Manrope',
    fontColor: '#212529',
    ascendLogo: LogoWithNameAndState,
    logo: AscendNetworkLogo,
    logoMobileStyle: {marginBottom: '24px', marginTop: '16px'},
    mobileLogoHeight: '22px',
    backgroundColor: '#F2F6F9',
    backgroundImage: Swoops,
    buttonClass: 'btn_ascend',
    circleColor: '#3061A9',
    dotColor: '#3061a9',
    dotInactiveColor: '#BCBCBC',
    widgetSubText: "Discover the job or internship that's right for <b>you</b>",
    subText:
      "You're just a few minutes away from exploring thousands of opportunities across Indiana.",
    cardContent: [
      {
        title: 'Here’s how to build your profile',
        body: 'Tell us more about you and your background.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Upload your resume to showcase your skills.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Share about your dream role so we can personalize your recommendations.',
      },
      {
        title: 'Here’s How to Build Your Profile',
        body: 'Get paired with a job search expert (at no cost to you) who will guide you through the process.',
      },
    ],
  },
  techpoint: {
    font: 'Manrope',
    fontColor: '#fff',
    ascendLogo: LogoWithNameAndState,
    logo: TechpointLogo,
    logoMobileStyle: {marginBottom: '24px', marginTop: '16px'},
    mobileLogoHeight: '22px',
    backgroundColor: '#000000',
    backgroundImage: TechpointSwoops,
    buttonClass: 'btn_techpoint',
    circleColor: '#000000',
    dotColor: '#ffb000',
    dotInactiveColor: '#BCBCBC',
    widgetSubText: "Discover the job that's right for <b>you</b>",
    subText:
      "You're just a few minutes away from exploring thousands of Indiana opportunities on the Ascend Network.",
    cardContent: [
      {
        title: 'Here’s how to build your profile',
        body: 'Tell us more about you and your background.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Upload your resume to showcase your skills.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Share about your dream role so we can personalize your recommendations.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Get paired with a job search expert (at no cost to you) who will guide you through the process.',
      },
    ],
  },
  ili: {
    font: 'Manrope',
    fontColor: '#373839',
    ascendLogo: AscendNetworkLogoBlack,
    mobileLogoHeight: '165px',
    logo: IliLogo,
    logoMobileStyle: {marginBottom: '24px', marginTop: '16px'},
    backgroundColor: '#F2F6F9',
    backgroundImage: IliSwoops,
    buttonClass: 'btn_ili',
    circleColor: '#129EC0',
    dotColor: '#129EC0',

    dotInactiveColor: '#BCBCBC',
    widgetSubText: "Discover the job that's right for <b>you</b>",
    subText:
      "You're just a few minutes away from exploring thousands of Indiana opportunities on the Ascend Network.",
    cardContent: [
      {
        title: 'Here’s how to build your profile',
        body: 'Tell us more about you and your background.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Upload your resume to showcase your skills.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Share about your dream role so we can personalize your recommendations.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Get paired with a job search expert (at no cost to you) who will guide you through the process.',
      },
    ],
  },
  employindy: {
    font: 'Manrope',
    fontColor: '#FFFFFF',
    ascendLogo: AscendNetworkWhite,
    mobileLogoHeight: '75px',
    logo: EmployIndyLogoWhite,
    logoMobileStyle: {marginTop: '16px'},
    logoDesktopStyle: {marginTop: '16px', width: '400px'},
    backgroundColor: '#0E2749',
    backgroundImage: IliSwoops,
    buttonClass: 'btn_employindy',
    circleColor: '#9E1D20',
    dotColor: '#9E1D20',

    dotInactiveColor: '#BCBCBC',
    widgetSubText: "Discover the job that's right for <b>you</b>",
    subText:
      "You're just a few minutes away from exploring thousands <br/>of full-time opportunities in Central Indiana.",
    cardContent: [
      {
        title: 'Here’s how to build your profile',
        body: 'Tell us more about you and your background.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Upload your resume to showcase your skills.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Share about your dream role so we can personalize your recommendations.',
      },
      {
        title: 'Here’s how to build your profile',
        body: 'Get matched with an Employindy Recruitment Manager (at no cost to you) who will guide you through the process.',
      },
    ],
  },
};
