import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {buildSalaryRange, buildHourlyRange} from '../utils/wageHelper';
import {Field} from 'react-final-form';
import {SelectField} from '../formFields';

export const WageSelector = (props) => {
  const [yearlySalaryRange] = useState(buildSalaryRange());
  const [hourlyWageRange] = useState(buildHourlyRange());

  return (
    <div className="d-flex row mb-5 justify-content-center">
      {props.selectedEmploymentTypes.some(
        (x) => x.name === 'Full-Time Job' && x.collectWageInfo === true
      ) && (
        <div className="d-flex col-6  justify-content-center">
          <div className="ms-n3 me-n3" style={{width: '260px'}}>
            <div className="mb-0">
              <label className="secondary-body bold">
                For full-time jobs, what is the minimum annual income you
                <br /> would consider?
              </label>
            </div>
            <Field
              name="minimumYearlySalary"
              initialValue={props.initialValues.minimumYearlySalary}
              label=""
              isDisabled={yearlySalaryRange.length === 0}
              component={SelectField}
              width="100%"
              options={yearlySalaryRange.map((opt) => {
                return {
                  value: opt.value,
                  label: `${opt.displayValue} (${opt.hourly} / hour)`,
                };
              })}
            />
          </div>
        </div>
      )}
      {props.selectedEmploymentTypes.some(
        (x) => x.name === 'Part-Time Job' && x.collectWageInfo === true
      ) && (
        <div className="d-flex col-6 justify-content-center">
          <div style={{width: '230px'}}>
            <div className="mb-0">
              <label className="secondary-body bold">
                For part-time jobs, what is the minimum hourly wage you would
                consider?
              </label>
            </div>
            <Field
              name="minimumHourlyWage"
              initialValue={props.initialValues.minimumHourlyWage}
              label=""
              isDisabled={hourlyWageRange.length === 0}
              component={SelectField}
              width="100%"
              options={hourlyWageRange.map((opt) => {
                return {
                  value: opt.value,
                  label: `${opt.displayValue}`,
                };
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

WageSelector.propTypes = {
  selectedEmploymentTypes: PropTypes.array,
  initialValues: PropTypes.object,
};
WageSelector.defaultProps = {
  selectedEmploymentTypes: [],
};
