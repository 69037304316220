import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '../../utils/commonHooks';
class ApplyToJobPageThree extends React.Component {
  render() {
    const {isMobile} = this.props;

    return (
      <Fragment>
        <div className="row d-flex justify-content-center padding-fortyeight">
          <div className="normal-title">Application Submitted!</div>
          <br />
          <div className="mt-3 secondary-body">
            Thank you for submitting your application. You will be contacted if
            the employer is interested in interviewing you for this position.
            Please check your email and phone messages regularly.
          </div>

          <div
            className={`mt-3 row ${isMobile ? 'justify-content-center' : ''}`}
          >
            <button
              style={{
                width: '210px',
                height: '40px',
                marginBottom: '10px',
              }}
              className="clickable btn btn-primary"
              onClick={() => this.props.history.push('/find_roles')}
            >
              Explore More Roles
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

ApplyToJobPageThree.propTypes = {
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

export default withRouter(ApplyToJobPageThree);
