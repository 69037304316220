import React from 'react';
import {NavLink} from 'react-router-dom';
import {PERMISSIONS, has_permission} from '../services/authorizationApi';
import _ from 'lodash';

const linkData = [
  {link: '/admin/employers', label: 'EMPLOYERS', order: 10},
  {link: '/admin/alljobs', label: 'ROLES', order: 20},
  {
    link: '/admin/candidates',
    label: 'CANDIDATES',
    order: 40,
    permissionList: [PERMISSIONS.JOB_SEEKER_PII],
  },
  {
    link: '/admin/applications',
    label: 'APPLICATIONS',
    order: 50,
    permissionList: [PERMISSIONS.JOB_SEEKER_PII],
  },
  {
    link: '/admin/connections',
    label: 'CONNECTIONS',
    order: 60,
    permissionList: [PERMISSIONS.JOB_SEEKER_PII],
  },
  {
    link: '/admin/invitations',
    label: 'INVITATIONS',
    order: 70,
    permissionList: [PERMISSIONS.JOB_SEEKER_PII],
  },
  {
    link: '/admin/universities',
    label: 'UNIVERSITIES',
    permissionList: [PERMISSIONS.ADMIN],
    order: 80,
  },
  {
    link: '/admin/codes',
    label: 'CODES',
    permissionList: [
      PERMISSIONS.EDIT_ALL_INVITATION_CODES,
      PERMISSIONS.EDIT_SEGMENT_INVITATION_CODES,
    ],
    order: 90,
  },
];

class AdminLinks extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      unmounting: false,
      linksToShow: [],
    };
  }
  addLink = (item) => {
    let newLinks = this.state.linksToShow;
    for (let link of newLinks) {
      if (item.label === link.label) {
        return;
      }
    }
    newLinks.push(item);
    let sortedLinks = _.sortBy(newLinks, (x) => x.order);
    this.setState({
      linksToShow: sortedLinks,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    let foundPermissions = new Set();

    for (let link of linkData) {
      if (!link.permissionList) {
        this.addLink(link);
        continue;
      }
      for (let permission of link.permissionList) {
        foundPermissions.add(permission);
      }
    }

    this.assignPermissions(foundPermissions);
  }

  assignPermissions = async (foundPermissions) => {
    for (let permission of foundPermissions) {
      const resp = await has_permission(permission);
      if (resp.data.hasPermission) {
        for (const link of linkData) {
          if (!this._isMounted) {
            return;
          }
          if (
            !link.permissionList ||
            link.permissionList.indexOf(permission) === -1
          ) {
            continue;
          }
          this.addLink(link);
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <nav role="navigation" aria-label="main navigation">
        {this.state.linksToShow.map((item, index) => {
          return (
            <NavLink
              to={item.link}
              className="clickable padding-h8 normal-subheader-white"
              key={index}
            >
              {item.label}
            </NavLink>
          );
        })}
      </nav>
    );
  }
}

export default AdminLinks;
