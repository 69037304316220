import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import * as codesActions from '../../../actions/invitationCodes';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {PageHeader, PageFooter} from '../../../layout';
import AscendDataTable from '../../../components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import CodesForm from './CodesForm';
import AreYouSureModal from '../../../components/AreYouSureModal';
import BuildSignUpLink from './BuildSignUpLink';

const blankCode = {
  label: '',
};

const searchFields = ['label'];
const searchPlaceholder = 'Label';

class CodesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsOpen: false,
      headerText: 'New Invitation Code',
      invitationCode: blankCode,
      formType: 'new',
      currentPage: 0,
      currentItemsPerPage: 25,
      searchTerm: '',
      sortTerm: '',
      invitationCodes: [],
      areYouSureModalIsOpen: false,
      removeCodeId: '',
    };
  }

  getCodes = () => {
    let filters = {};
    if (this.state.searchTerm !== '') {
      filters.searchTerm = this.state.searchTerm.trim();
      filters.searchFields = searchFields;
    }
    this.props.getInvitationCodesAdvanced(
      this.state.currentPage,
      this.state.currentItemsPerPage,
      this.state.sortTerm,
      filters
    );
  };

  componentDidMount() {
    this.getCodes();
  }

  handleCreateCode = () => {
    this.props.setEditInvitationCode({label: '', shortUrl: ''});
    this.setState({
      formIsOpen: true,
      headerText: 'New Invitation Code',
      submitType: 'CREATE',
      invitationCode: blankCode,
      formType: 'new',
    });
  };

  handleInvitationCodeSubmit = (invitationCode) => {
    switch (this.state.submitType) {
      case 'CREATE':
        this.props.createInvitationCode(invitationCode);
        break;
      case 'UPDATE':
        this.props.updateInvitationCode(invitationCode);
        break;
      default:
        this.props.createInvitationCode(invitationCode);
        break;
    }
    this.setState({formIsOpen: false});
  };

  handleModalClose = () => {
    this.setState({
      formIsOpen: false,
    });
  };

  handleEditClick = (id) => {
    const code = _.find(this.props.invitationCodes, {id});
    this.props.setEditInvitationCode(code);
    this.setState({
      formIsOpen: true,
      headerText: `Edit Code ${code.label}`,
      submitType: 'UPDATE',
      invitationCode: code,
    });
  };

  handleDeleteClick = (codeId) => {
    this.setState(
      {
        removeCodeId: codeId,
      },
      () => this.setState({areYouSureModalIsOpen: true})
    );
  };

  handleDoRemove = () => {
    this.props.deleteInvitationCode(this.state.removeCodeId);
    this.setState(
      {
        removeCodeId: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handleAreYouSureClose = () => {
    this.setState(
      {
        removeCodeId: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handleSubmitCode = (code) => {
    this.setState({
      formIsOpen: false,
      invitationCode: blankCode,
    });
    this.props.createInvitationCode(code);
  };

  handleCodeSaved = (code) => {
    this.setState({
      formIsOpen: false,
      invitationCode: blankCode,
    });
    this.props.updateInvitationCode(code);
  };

  handlePageChange = (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
      },
      this.getCodes
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    this.setState(
      {
        searchTerm: searchTerm,
        currentPage: 0,
      },
      () => {
        if (search) this.getCodes();
      }
    );
  };

  handleSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    this.setState(
      {
        sortTerm: sortTerm,
        currentPage: 0,
      },
      this.getCodes
    );
  };

  handlerFunction = (operation, id) => {
    switch (operation) {
      case 'Edit':
        this.handleEditClick(id);
        break;
      case 'Delete':
        this.handleDeleteClick(id);
        break;
      default:
        this.handleEditClick(id);
    }
  };

  buildLabel = (invitationCode) => {
    return invitationCode.label;
  };

  buildLink = (invitationCode) => {
    return <BuildSignUpLink invitationCode={invitationCode} />;
  };

  render() {
    const tableColumns = [
      {
        sortBy: 'label',
        displayName: 'LABEL',
        content: this.buildLabel,
      },
      {
        displayName: 'LINK',
        content: this.buildLink,
      },
      {
        displayName: 'DESTINATION PAGE',
        content: (code) => code.destinationPage,
        minWidth: 150,
      },
      {
        displayName: 'TOTAL LOGGED IN',
        content: (code) => code.totalLoginCount,
        minWidth: 139,
      },
      {
        displayName: 'TOTAL PUBLISHED',
        content: (code) => code.published,
        minWidth: 143,
      },
      {
        displayName: 'SHORT URL',
        content: (code) => code.shortUrl,
        minWidth: 120,
      },
    ];

    const rowMenuItemList = [
      {
        displayName: 'Edit',
      },
      {
        displayName: 'Delete',
      },
    ];

    return (
      <DocumentTitle title="Codes Admin">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="codesContainer">
                <CodesForm
                  formType={this.state.formType}
                  isOpen={this.state.formIsOpen}
                  invitationCode={this.state.invitationCode}
                  onSubmit={this.handleInvitationCodeSubmit}
                  headerText={this.state.headerText}
                  onClose={this.handleModalClose}
                  deleteInvitationCode={this.handleDeleteClick}
                />
                <AreYouSureModal
                  areYouSureText="Are you sure that you want to remove this invitation code?"
                  isOpen={this.state.areYouSureModalIsOpen}
                  onClose={this.handleAreYouSureClose}
                  onYes={this.handleDoRemove}
                />
                <br />
                <button
                  className="clickable btn btn-primary float-right"
                  onClick={this.handleCreateCode}
                >
                  + Add Code
                </button>
                <div>
                  <h2 className="normal-headline bold float-left">
                    Invitation Codes
                  </h2>
                  <br />
                  <br />
                  <AscendDataTable
                    rowMenuItemList={rowMenuItemList}
                    tableColumns={tableColumns}
                    items={this.props.invitationCodes}
                    handlerFunction={this.handlerFunction}
                    currentPage={this.state.currentPage}
                    itemsPerPage={this.props.itemsPerPage}
                    searchTerm={this.state.searchTerm}
                    totalItems={this.props.totalItems}
                    handlePageChange={this.handlePageChange}
                    handleFilterChange={this.handleFilterChange}
                    handleSortChange={this.handleSortChange}
                    searchPlaceholder={searchPlaceholder}
                    itemsLoading={this.props.invitationCodesLoading}
                    enableSort={false}
                    helpMessage={
                      <Fragment>
                        <span style={{fontWeight: 'bold'}} tabIndex={0}>
                          &skipintro=true
                        </span>
                        &nbsp; to skip to the resume upload.
                      </Fragment>
                    }
                  />
                </div>
                <ReportsTable
                  title="Invitation Code Reports"
                  reports={this.props.reports}
                />
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

CodesPage.propTypes = {
  invitationCodes: PropTypes.array.isRequired,
  getInvitationCodesAdvanced: PropTypes.func.isRequired,
  deleteInvitationCode: PropTypes.func.isRequired,
  createInvitationCode: PropTypes.func.isRequired,
  updateInvitationCode: PropTypes.func.isRequired,
  invitationCodesLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  setEditInvitationCode: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    invitationCodes: state.invitationCodes.invitationCodes,
    invitationCodesLoading: state.invitationCodes.invitationCodesLoading,
    page: state.invitationCodes.page,
    itemsPerPage: state.invitationCodes.itemsPerPage,
    totalItems: state.invitationCodes.totalItems,
    reports: state.invitationCodes.reports,
  };
}

export default connect(mapStateToProps, codesActions)(CodesPage);
