import React from 'react';
import PropTypes from 'prop-types';

import {AscendModal, AscendModalBody} from '../../layout';
import {track} from 'src/utils/analytics';

export default function TeamBenchModal(props) {
  const handleChecked = (event) => {
    const teamId = event.target.name;
    const checked = event.target.checked;

    if (checked) {
      track({
        eventName: 'SeekerFavorited',
        teamId,
      });
    }

    props.onTeamChecked(parseInt(teamId, 10), checked);
  };

  const handleOkClicked = () => {
    props.onOkClicked();
  };

  return (
    <AscendModal isOpen={props.isOpen} onClose={props.onClose}>
      <AscendModalBody hasClose={true}>
        <fieldset>
          <legend>
            <div className="normal-title bold text-align-center padding-bottom-sixteen">
              Save or remove {props.seekerName} to your team page
            </div>
          </legend>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              {props.teams.map((team) => {
                return (
                  <div
                    key={team.id}
                    className="custom-control custom-checkbox form-check"
                  >
                    <input
                      id={team.id}
                      name={team.id}
                      type="checkbox"
                      checked={props.candidateTeamIds.includes(team.id)}
                      onChange={handleChecked}
                      className="custom-control-input form-check-input checkbox-formatting"
                    />
                    <label
                      className="custom-control-label form-check-label"
                      htmlFor={team.id}
                    >
                      {team.name}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="col-md-3" />
          </div>
        </fieldset>
        <div className="padding-top-sixteen padding-bottom-sixteen">
          <button
            type="button"
            className="float-right clickable btn btn-primary"
            onClick={handleOkClicked}
          >
            Confirm & Close
          </button>
          <br />
        </div>
      </AscendModalBody>
    </AscendModal>
  );
}

TeamBenchModal.propTypes = {
  isOpen: PropTypes.bool,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  candidateTeamIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  seekerName: PropTypes.string.isRequired,
  onTeamChecked: PropTypes.func.isRequired,
  onOkClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
