import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';
import styles from './CreateConnectionsModal.module.scss';

export default function CreateConnectionNotes(props) {
  const {onChange, notes, onView, notesViewed} = props;
  const [editorRef, setEditorRef] = useState(null);

  const inputRef = useRef();

  const addToolbar = () => (
    <div id="addToolbar" className={styles.editorToolbar}>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
    </div>
  );

  const addModules = {
    keyboard: {bindings: {tab: false}},
    toolbar: {
      container: '#addToolbar',
    },
  };

  const formats = ['bold', 'italic', 'list', 'bullet'];
  const maxLength = 2000;

  useEffect(() => {
    if (typeof inputRef.current?.getEditor !== 'function') return;

    setEditorRef(inputRef.current?.getEditor());
  }, [setEditorRef]);

  useEffect(() => {
    if (!notesViewed) {
      onView(true);
    }
  }, [onView, notesViewed]);

  const handleNoteChange = () => {
    if (editorRef) {
      if (editorRef?.getLength() > maxLength) {
        editorRef.deleteText(maxLength, editorRef.getLength());
      }

      onChange(inputRef.current.unprivilegedEditor.getHTML());
    }
  };

  return (
    <>
      {addToolbar()}
      <ReactQuill
        value={notes}
        onChange={handleNoteChange}
        formats={formats}
        modules={addModules}
        tabIndex={0}
        ref={inputRef}
        className={styles.noteEditor}
      />
      {editorRef && (
        <div
          className={'secondary-caption text-end pb-2'}
          aria-live="polite"
          aria-atomic="true"
        >
          {maxLength - editorRef.getLength() + 1} characters remaining
        </div>
      )}
    </>
  );
}

CreateConnectionNotes.propTypes = {
  onChange: PropTypes.func.isRequired,
  notes: PropTypes.string,
  onView: PropTypes.func.isRequired,
  notesViewed: PropTypes.bool,
};
