import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import TextAreaField from './TextAreaField';

class TextAreaFinalFieldArray extends React.Component {
  canAddNewField() {
    const {fields, maxFields} = this.props;

    return !maxFields || fields.length < maxFields;
  }

  handleAddField = () => {
    const {fields} = this.props;
    if (this.canAddNewField()) {
      fields.push();
    }
  };

  handleRemoveField = () => {
    this.props.fields.pop();
  };

  render() {
    const {
      fields,
      meta: {error, submitFailed},
      labelMaker,
      addButtonDescription,
      removeButtonDescription,
      maxLength,
      showCounter,
    } = this.props;
    return (
      <div className="form form-group">
        {error && submitFailed && (
          <div className="form-input-error">{error}</div>
        )}
        {fields.map((field, index) => {
          return (
            <div key={index}>
              <Field
                name={field}
                component={TextAreaField}
                label={labelMaker(index)}
                maxLength={maxLength}
                showCounter={showCounter}
              />
            </div>
          );
        })}
        <div className="padding-top-eight">
          {this.canAddNewField() && (
            <button
              type="button"
              className="clickable inlineblock btn-transparent-green"
              onClick={this.handleAddField}
            >
              + {addButtonDescription}
            </button>
          )}
          {this.canAddNewField() && fields.length > 1 && (
            <div className="active-body inlineblock">&nbsp;/&nbsp;</div>
          )}
          {fields.length > 0 && (
            <button
              type="button"
              className="clickable inlineblock btn-transparent-red"
              onClick={this.handleRemoveField}
            >
              - {removeButtonDescription}
            </button>
          )}
        </div>
      </div>
    );
  }
}

TextAreaFinalFieldArray.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  fields: PropTypes.object,
  addDisabled: PropTypes.bool,
  labelMaker: PropTypes.func.isRequired,
  maxFields: PropTypes.number,
  addButtonDescription: PropTypes.string,
  removeButtonDescription: PropTypes.string,
  maxLength: PropTypes.number,
  showCounter: PropTypes.bool,
};

export default TextAreaFinalFieldArray;
