import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';
import {required} from '../../../utils/intake/intakeCommon';
import CheckboxSelector from '../../../components/selectors/CheckboxSelector/CheckboxSelector';
import AscendCheckbox from 'src/formFields/ascendCheckbox/AscendCheckbox';

class RoleType extends React.Component {
  state = {
    selectedTags: this.props.intakeCache?.roleTypeIds?.includes(
      this.props.listOptions.find((x) => x.name === 'Any').id
    )
      ? []
      : this.props.intakeCache.roleTypeIds,
  };

  validate = (values) => {
    const errors = {};
    const requiredFields = ['roleTypeIds'];
    _.forEach(requiredFields, (field) => {
      if (
        (!values[field] || values[field].length === 0) &&
        values.anyRolePref === false
      ) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  buildSelectOptions = () => {
    const roles = this.props.listOptions
      .filter((x) => x.name !== 'Any')
      .map((x) => {
        return {id: x.id, value: x.id, label: x.name, tagName: x.name};
      });
    return roles;
  };

  render() {
    const {next, previous, isMobile, headerImage} = this.props;

    return (
      <>
        <PageHeader
          title="What kind of work do you want to do?"
          headerImage={headerImage}
          id="roleTypeLabel"
        />
        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            roleTypeIds: this.props.intakeCache?.roleTypeIds,
            anyRolePref: this.props.intakeCache?.anyRolePref,
          }}
        >
          {({handleSubmit, form}) => {
            const handleCheck = (checked) => {
              if (checked) {
                form.mutators.setValue('roleTypeIds', [
                  this.props.listOptions.find((x) => x.name === 'Any').id,
                ]);
                this.setState({selectedTags: []});
              } else {
                form.mutators.setValue('roleTypeIds', []);
                this.setState({selectedTags: []});
              }
            };

            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <div style={{maxWidth: '885px'}}>
                    <div className={styles.pageText}>
                      Let's match you with the kind of work that you are
                      passionate about. Search for the type of work you want to
                      be doing in your next job.
                    </div>

                    <div style={{textAlign: 'left'}}>
                      <Field
                        component={CheckboxSelector}
                        name="roleTypeIds"
                        isMobile={this.props.isMobile}
                        closeMenuOnSelect={false}
                        validate={required}
                        optionsList={this.buildSelectOptions()}
                        form={form}
                        onTagChangeAction={(e) => {
                          if (e?.length > 0) {
                            form.mutators.setValue('anyRolePref', false);
                          }
                          this.setState({selectedTags: e});
                        }}
                        selectedTags={this.state.selectedTags}
                        placeholder="Ex. Accounting, Finance, etc."
                        isSearchable={!isMobile}
                        ariaLabeledBy="roleTypeLabel"
                      />
                    </div>
                    <div className="pt-2" style={{width: '100%'}}>
                      <Field name="anyRolePref">
                        {(props) => {
                          return (
                            <>
                              <AscendCheckbox
                                name={props.input.name}
                                inputValue={props.input.value}
                                onChangeAction={handleCheck}
                                onFocus={props.input.onFocus}
                                meta={props.meta}
                                label="I have no preference"
                                inputOnChange={props.input.onChange}
                                defaultValue={props.input.value}
                              />
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
RoleType.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  listOptions: PropTypes.array.isRequired,
  headerImage: PropTypes.any,
};

export default RoleType;
