import React from 'react';
import PropTypes from 'prop-types';

export default function OffRampFooter({showMA}) {
  return (
    <>
      <a
        style={{marginTop: '20px', marginBottom: '20px'}}
        className="clickable btn btn-primary"
        href="https://www.in.gov/dwd/WorkOne/locations.html"
        target="_blank"
        rel="noreferrer"
      >
        Find a WorkOne Location
      </a>

      <p style={{textAlign: 'center', width: '80%'}}>
        If you are an Indianapolis resident, you can schedule an appointment
        with WorkOne Indy{' '}
        <a
          href="https://workoneindy.as.me/cn"
          target="_blank"
          rel="noreferrer"
          style={{textDecoration: 'underline'}}
        >
          here.
        </a>{' '}
        Call 317-798-0335 or email{' '}
        <a
          href="mailto:questions@workoneindy.com"
          style={{textDecoration: 'underline'}}
        >
          questions@workoneindy.com
        </a>{' '}
        if you don't see availability or have questions.
      </p>
      <p style={{textAlign: 'center', width: '80%'}}>
        For 16-24 year old job seekers in Indianapolis who have not completed
        high school or are not working, you may benefit from participating in
        YES Indy programs. Visit{' '}
        <a
          href="https://yesindy317.org/"
          target="_blank"
          rel="noreferrer"
          style={{textDecoration: 'underline'}}
        >
          their website
        </a>{' '}
        to learn more.
      </p>

      <div style={{width: '80%', fontSize: '0.85rem', textAlign: 'center'}}>
        Do you think you've received this message in error? Email{' '}
        <a
          style={{textDecoration: 'underline'}}
          href="mailto:support@ascendindiana.com"
        >
          support@ascendindiana.com
        </a>{' '}
        for assistance.
      </div>
    </>
  );
}

OffRampFooter.propTypes = {
  showMA: PropTypes.bool,
};

OffRampFooter.defaultProps = {
  showMA: false,
};
