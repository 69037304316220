import {makeRequest} from '../services/api';
import * as types from './ActionTypes';
import {store} from '../index';

const apiUrl = process.env.REACT_APP_API_URL;

export const getRecruiterTasks =
  (page = -1, taskId = null) =>
  async (dispatch) => {
    let path = `v2/tasks?page=${page > -1 ? page : 0}`;

    try {
      dispatch({type: types.TASKS__GET_ALL_REQUEST});
      let response = await makeRequest({
        method: 'GET',
        path: path,
        contentType: 'application/json',
      });
      if (response.status === 200) {
        const actionType =
          page === -1
            ? types.TASKS__GET_ALL_SUCCESS
            : types.TASKS__GET_SUCCESS_NEW_PAGE;
        dispatch({
          type: actionType,
          response: {body: response.data},
          page: page,
          taskId,
        });
      }
    } catch (err) {
      dispatch({
        type: types.TASKS__GET_ALL_ERROR,
        response: err,
      });
    }
  };

export const getTaskCount = () => {
  return {
    types: [
      types.TASKS__GET_COUNTS_REQUEST,
      types.TASKS__GET_COUNTS_SUCCESS,
      types.TASKS__GET_COUNTS_FAILURE,
    ],
    uri: `${apiUrl}/v2/tasks/count`,
    method: 'GET',
  };
};

export const setTaskExpanded = (taskId) => async (dispatch) => {
  return dispatch({
    type: types.TASKS__TOGGLE_EXPANDED_ROW,
    taskId: taskId,
  });
};

export const incrementNoteCount = (seekerId) => (dispatch) => {
  return dispatch({
    type: types.TASKS__INCREMENT_NOTE_COUNT,
    seekerId,
  });
};

export const decrementNoteCount = (seekerId) => (dispatch) => {
  return dispatch({
    type: types.TASKS__DECREMENT_NOTE_COUNT,
    seekerId,
  });
};

export const dismissTaskItemsForTaskId =
  (taskId, taskCountToClear) => async (dispatch) => {
    let path = `v2/tasks/${taskId}?taskCountToClear=${taskCountToClear}`;

    try {
      dispatch({type: types.TASKS__DISMISS_REQUEST});
      let response = await makeRequest({
        method: 'DELETE',
        path: path,
        contentType: 'application/json',
      });
      if (response.status === 200) {
        dispatch({
          type: types.TASKS__DISMISS_SUCCESS,
          response: {body: response.data},
          taskId,
        });
        dispatch(getTaskCount());
      }
    } catch (err) {
      dispatch({
        type: types.TASKS__DISMISS_ERROR,
        response: err,
      });
    }
  };

export const dismissTaskItem =
  (taskItemId, taskId, page) => async (dispatch) => {
    let path = `v2/task_item/${taskItemId}?page=${page}`;

    try {
      dispatch({type: types.TASKS__DISMISS_ITEM_REQUEST});
      let response = await makeRequest({
        method: 'DELETE',
        path: path,
        contentType: 'application/json',
      });
      if (response.status === 200) {
        dispatch({
          type: types.TASKS__DISMISS_ITEM_SUCCESS,
          response: {body: response.data},
          page: page,
          taskId,
        });
        if (
          store.getState().tasksReducer.count !== response.data.tasks.length
        ) {
          dispatch(getTaskCount());
        }
      }
    } catch (err) {
      dispatch({
        type: types.TASKS__DISMISS_ITEM_ERROR,
        response: err,
      });
    }
  };

export const executeTaskItem =
  (taskItemId, taskId, page) => async (dispatch) => {
    let path = `v2/task_item/${taskItemId}?page=${page}`;
    try {
      dispatch({type: types.TASKS__EXECUTE_REQUEST});
      let response = await makeRequest({
        method: 'POST',
        path: path,
        contentType: 'application/json',
      });
      if (response.status === 200) {
        dispatch({
          type: types.TASKS__EXECUTE_SUCCESS,
          response: {body: response.data},
          page: page,
          taskId,
        });
        if (
          store.getState().tasksReducer.count !== response.data.tasks.length
        ) {
          dispatch(getTaskCount());
        }
      }
    } catch (err) {
      dispatch({
        type: types.TASKS__EXECUTE_ERROR,
        response: err,
      });
    }
  };
