import * as types from '../actions/ActionTypes';

const initialState = {
  ascendRecruiters: [],
  ascendRecruiter: {},
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  ascendRecruiterLoading: false,
};

export default function ascendRecruiters(state = initialState, action) {
  switch (action.type) {
    case types.ASCEND_RECRUITERS__GET_REQUEST:
      return {...state, ascendRecruiterLoading: true, ascendRecruiter: {}};
    case types.ASCEND_RECRUITERS__GET_SUCCESS:
      return {
        ...state,
        ascendRecruiterLoading: false,
        ascendRecruiter: action.response.body,
      };
    case types.ASCEND_RECRUITERS__GET_FAILURE:
      return {
        ...state,
        ascendRecruiterLoading: false,
        ascendRecruiter: action.response.body,
      };

    case types.ASCEND_RECRUITERS__GET_ALL_REQUEST:
      return {...state, ascendRecruiters: []};
    case types.ASCEND_RECRUITERS__GET_ALL_SUCCESS:
      return {
        ...state,
        ascendRecruiters: action.response.body.ascendRecruiters,
      };
    case types.ASCEND_RECRUITERS__UPDATE_REQUEST:
      return {...state, ascendRecruiterLoading: true};
    case types.ASCEND_RECRUITERS__UPDATE_FAILURE:
      return {...state, ascendRecruiterLoading: false};
    case types.ASCEND_RECRUITERS__UPDATE_SUCCESS:
      return {
        ...state,
        ascendRecruiterLoading: false,
        ascendRecruiter: action.response.body,
      };
    default:
      return state;
  }
}
