import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'reactstrap';

class OneSkill extends React.Component {
  state = {
    tooltipOpen: false,
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    const {selected, onClick, skillId, definition, label} = this.props;

    return (
      <div className="normal-subheader padding-8 custom-control custom-checkbox form-check">
        <input
          id={`check${skillId}`}
          tabIndex={0}
          type="checkbox"
          checked={selected}
          onChange={onClick}
          className="custom-control-input form-check-input checkbox-formatting"
        />
        <label
          className="normal-subheader custom-control-label form-check-label"
          htmlFor={`check${skillId}`}
        >
          <span id={'skill' + String(skillId)}>
            <Tooltip
              delay={{show: 125, hide: 250}}
              hideArrow={true}
              innerClassName="bd-verydarkgraybackground"
              placement="right"
              isOpen={this.state.tooltipOpen}
              target={'skill' + String(skillId)}
              toggle={this.toggleTooltip}
            >
              <div className="normal-caption-white bd-verydarkgraybackground">
                {definition}
              </div>
            </Tooltip>
            {label}
          </span>
        </label>
      </div>
    );
  }
}

OneSkill.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  skillId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
};

export default OneSkill;
