import React from 'react';
import PropTypes from 'prop-types';
import {AscendModal, AscendModalBody} from '../../../layout';
import {connect} from 'react-redux';
import {withRouter} from '../../../utils/commonHooks';
import Select from 'react-select';
import _ from 'lodash';

import classNames from 'classnames';
import * as employersActions from '../../../actions/employers';
import * as teamsActions from '../../../actions/teams';
import * as jobsActions from '../../../actions/jobs';
import {
  getSortedLookup,
  getSortedLookupWithPublishedJobCount,
  getSortedLookupWithAlreadyApplied,
} from '../../../utils/fieldHelper';

class ApplyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployerId: null,
      selectedTeamId: null,
      selectedJobId: null,
    };
  }

  componentDidMount() {
    if (this.props.employers.length === 0 && !this.props.employersLoading) {
      this.props.getAllEmployers();
    }
  }

  handleEmployerChange = (selectedOption) => {
    this.props.getTeamsByEmployer(selectedOption['value']);
    this.setState({
      selectedEmployerId: selectedOption['value'],
      selectedTeamId: null,
      selectedJobId: null,
    });
  };

  handleTeamChange = (selectedOption) => {
    this.props.getJobsByTeam(selectedOption['value']);
    this.setState({
      selectedTeamId: selectedOption['value'],
      selectedJobId: null,
    });
  };

  handleJobChange = (selectedOption) => {
    this.setState({selectedJobId: selectedOption['value']});
  };

  handleApplyClicked = () => {
    if (this.state.selectedJobId) {
      this.props.onSubmit(this.state.selectedJobId);
      this.setState({
        selectedEmployerId: null,
        selectedTeamId: null,
        selectedJobId: null,
      });
      this.props.onClose();
    }
  };

  checkAlreadyApplied = (jobId) => {
    if (!this.props.seeker.appliedJobs) {
      return false;
    } else if (_.find(this.props.seeker.appliedJobs, ['id', jobId])) {
      return true;
    } else {
      return false;
    }
  };

  makeJobsWithAlreadyApplied = () => {
    var jobsWithAlreadyApplied = [];
    this.props.jobs.forEach((job, index) => {
      job['alreadyApplied'] = this.checkAlreadyApplied(job.id);
      jobsWithAlreadyApplied.push(job);
    });
    return jobsWithAlreadyApplied;
  };

  render() {
    const employersOptions = getSortedLookup(this.props.employers, ['name']);
    const teamsOptions = getSortedLookupWithPublishedJobCount(
      this.props.teams,
      ['name']
    );

    const jobsOptions = getSortedLookupWithAlreadyApplied(
      this.makeJobsWithAlreadyApplied(),
      ['name']
    );

    return (
      <AscendModal isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AscendModalBody hasClose={true}>
          <div className="padding-32">
            <center>
              <div className="normal-title padding-top-thirtytwo padding-bottom-eight bold">
                Mark {this.props.seekerName} as Applied to a Role
              </div>

              <hr />
            </center>
            <div className="row">
              <div className="col-md-6 middle padding-bottom-eight">
                <div
                  className="secondary-body"
                  style={{lineHeight: '36px'}}
                  id="employerLabel"
                >
                  EMPLOYER:
                </div>
              </div>
              <div className="col-md-6">
                <Select
                  className="clickable normal-subheader"
                  value={
                    this.state.selectedEmployerId
                      ? _.find(employersOptions, {
                          value: this.state.selectedEmployerId,
                        })
                      : null
                  }
                  onChange={this.handleEmployerChange}
                  options={employersOptions}
                  isDisabled={this.props.employersLoading}
                  isLoading={this.props.employersLoading}
                  aria-labelledby="employerLabel"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 middle padding-bottom-eight">
                <div
                  className="secondary-body"
                  style={{lineHeight: '36px'}}
                  id="teamLabel"
                >
                  TEAM:
                </div>
              </div>
              <div className="col-md-6">
                <Select
                  className="clickable normal-subheader"
                  value={
                    this.state.selectedTeamId
                      ? _.find(teamsOptions, {
                          value: this.state.selectedTeamId,
                        })
                      : null
                  }
                  onChange={this.handleTeamChange}
                  options={teamsOptions}
                  isDisabled={!this.state.selectedEmployerId}
                  isLoading={this.props.teamsLoading}
                  aria-labelledby="teamLabel"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 middle padding-bottom-eight">
                <div
                  className="secondary-body"
                  style={{lineHeight: '36px'}}
                  id="roleLabel"
                >
                  ROLE:
                </div>
              </div>
              <div className="col-md-6">
                <Select
                  className="clickable normal-subheader"
                  value={
                    this.state.selectedJobId
                      ? _.find(jobsOptions, {
                          value: this.state.selectedJobId,
                        })
                      : null
                  }
                  onChange={this.handleJobChange}
                  options={jobsOptions}
                  isDisabled={!this.state.selectedTeamId}
                  isLoading={this.props.jobsLoading}
                  aria-labelledby="roleLabel"
                />
              </div>
            </div>
            <div
              className="padding-top-sixteen padding-bottom-sixteen"
              style={{margin: '0px auto'}}
            >
              <button
                className={classNames('btn', 'float-right', {
                  clickable: this.state.selectedJobId,
                  'btn-primary': this.state.selectedJobId,
                  'btn-disabled': !this.state.selectedJobId,
                })}
                style={{fontSize: '16px', textAlign: 'center'}}
                onClick={this.handleApplyClicked}
              >
                Mark as Applied
              </button>
            </div>
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

ApplyForm.propTypes = {
  isOpen: PropTypes.bool,
  getAllEmployers: PropTypes.func.isRequired,
  getTeamsByEmployer: PropTypes.func.isRequired,
  getJobsByTeam: PropTypes.func.isRequired,
  employers: PropTypes.array,
  teams: PropTypes.array,
  jobs: PropTypes.array,
  seekerId: PropTypes.string,
  seekerName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  employersLoading: PropTypes.bool,
  teamsLoading: PropTypes.bool,
  jobsLoading: PropTypes.bool,
  seeker: PropTypes.object,
};

ApplyForm.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state) => {
  return {
    employers: state.employers.allEmployers,
    employersLoading: state.employers.employersLoading,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
    jobs: state.jobs.jobs,
    jobsLoading: state.jobs.jobsLoading,
  };
};

const mapDispatchToProps = {
  ...employersActions,
  ...teamsActions,
  ...jobsActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplyForm)
);
