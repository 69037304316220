import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {TextAreaField} from 'src/formFields';
import {
  required,
  validate,
} from '../../../utils/modernApprenticeship/maIntakeCommon';

const validateForm = (values) => {
  const fields = [1, 2, 3].map((n) => `page1Question${n}`);
  return validate(fields, values);
};

class MaOnboardingPageOne extends React.Component {
  renderQuestion = (q) => {
    const validator = q.id !== 4 ? required : null;
    let name = `page1Question${q.id}`;
    return (
      <Fragment key={name}>
        <div className="row padding-bottom-thirtytwo">
          <div className="offset-md-1 col-md-10">
            <div className="disabled-body padding-bottom-sixteen">
              {q.questionText}
            </div>
          </div>
          <div className="row">
            <div className="offset-md-1 col-md-10">
              <Field
                name={name}
                initialValue={this.props.initialValues[name]}
                component={TextAreaField}
                label={name}
                showLabel={false}
                maxLength={2000}
                showCounter={true}
                inputBackground="bd-graybackground"
                validate={validator}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const {questions, isMobile} = this.props;

    return (
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="bd-whitebackground" style={{padding: '48px'}}>
            <Form
              onSubmit={this.props.onSubmit}
              validate={validateForm}
              keepDirtyOnReinitialize={true}
            >
              {({handleSubmit}) => {
                return (
                  <form onKeyPress={this.onKeyPress} onSubmit={handleSubmit}>
                    <div className="d-flex row justify-content-center">
                      <div className="bold active-headline text-align-center mb-4">
                        Tell us more!
                      </div>
                    </div>
                    <div className="d-flex row justify-content-center">
                      <div className="secondary-body text-align-center mb-5">
                        Your responses to these questions will be included on
                        your profile and in applications to employers. Please
                        respond thoughtfully and check for grammatical errors.
                        <hr
                          style={{
                            color: 'secondary-body',
                            backgroundColor: 'secondary-body',
                            height: 2,
                          }}
                        />
                      </div>
                    </div>

                    {questions.map((q) => this.renderQuestion(q))}

                    <div className="row padding-top-sixteen">
                      <div
                        className={`col-md-12 ${
                          isMobile ? 'd-flex justify-content-center' : ''
                        } `}
                      >
                        <button
                          type="submit"
                          style={{
                            width: '210px',
                            height: '40px',
                          }}
                          className={`clickable btn btn-primary ${
                            isMobile ? '' : 'float-right'
                          }`}
                        >
                          Finish
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

MaOnboardingPageOne.propTypes = {
  previousPage: PropTypes.func,
  onSubmit: PropTypes.func,
  questions: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default MaOnboardingPageOne;
