import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import styles from './ResumeSlideOutModal.module.scss';
import {HeartFilledWithoutBorderSvg} from 'src/assets/svg/svgComponents';

const RenderResumeDocument = (props) => {
  if (props.resumeExtension.toLowerCase() === 'pdf') {
    return (
      <iframe
        src={`${props.seekerResumeEmbedUrl}#toolbar=0`}
        width="100%"
        height="99%"
        title="Resume"
        tabIndex={0}
        lang="en"
        frameBorder="0"
      />
    );
  }
  if (props.resumeExtension.toLowerCase() === 'docx') {
    return (
      <>
        <p>Resume is a Word document. Click the link below to download.</p>
        <a href={props.seekerResumePdfUrl}>Download Resume</a>
      </>
    );
  }
  return <img src={props.seekerResumeEmbedUrl} alt="" width="730px" />;
};

RenderResumeDocument.propTypes = {
  seekerResumeEmbedUrl: PropTypes.string,
  seekerResumePdfUrl: PropTypes.string,
  resumeExtension: PropTypes.string,
};

export function ResumeSlideOutModal(props) {
  const heartStyle = props.seekerIsWatched
    ? styles.filled_watch_icon
    : styles.empty_watch_icon;

  return (
    <>
      <SlidingPane
        isOpen={props.isOpen}
        from="right"
        width={props.width}
        className={styles.container}
        onRequestClose={props.onClose}
        closeIcon={
          <button
            className="buttonAsLinkGrayBackground"
            onClick={props.onClose}
            aria-label="Close Window"
          >
            <i className="nc-icon-glyph-med remove" />
          </button>
        }
        title={
          <div className={styles.title}>
            <div className={styles.avatarName}>
              <img
                height={48}
                width={48}
                alt=""
                className="avatar-circle"
                src={props.seekerAvatarUrl}
              />
              <Link
                className="candidate-profile-nav-link-selected text-nowrap"
                to={'/candidate/' + props.seekerId}
              >
                <h2 style={{marginBottom: '0', marginLeft: '32px'}}>
                  {props.seekerName}
                </h2>
              </Link>
            </div>
            <div className={styles.actions}>
              {props.showInviteToApply && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => props.onInviteToApplyClicked(props.seekerId)}
                >
                  Invite To Apply
                </button>
              )}
              {props.showSaveSeeker && (
                <button
                  type="button"
                  className="buttonAsLink"
                  onClick={() => props.onAddWatchClicked(props.seekerId)}
                >
                  <HeartFilledWithoutBorderSvg
                    width="24"
                    height="24"
                    className={heartStyle}
                  />
                </button>
              )}
            </div>
          </div>
        }
      >
        <RenderResumeDocument {...props} />
      </SlidingPane>
    </>
  );
}

ResumeSlideOutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onInviteToApplyClicked: PropTypes.func.isRequired,
  onAddWatchClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  seekerId: PropTypes.string.isRequired,
  seekerName: PropTypes.string.isRequired,
  seekerAvatarUrl: PropTypes.string.isRequired,
  seekerResumeEmbedUrl: PropTypes.string,
  seekerResumePdfUrl: PropTypes.string,
  resumeExtension: PropTypes.string,
  seekerIsWatched: PropTypes.bool.isRequired,
  width: PropTypes.string,
  showSaveSeeker: PropTypes.bool.isRequired,
  showInviteToApply: PropTypes.bool.isRequired,
};

ResumeSlideOutModal.defaultProps = {
  width: '800px',
  showInviteToApply: true,
};
