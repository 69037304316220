import * as types from '../actions/ActionTypes';

const initialState = {
  segmentList: [],
  segmentsLoading: false,
};

export default function segments(state = initialState, action) {
  switch (action.type) {
    case types.SEGMENTS__GET_REQUEST:
      return {...state, segmentsLoading: true};
    case types.SEGMENTS__GET_SUCCESS:
      return {
        ...state,
        segmentList: action.response.body.segment,
        segmentsLoading: false,
      };
    case types.SEGMENTS__GET_FAILURE:
      return {...state, segmentsLoading: false};

    case types.SEGMENTS__GET_PARTNER_REQUEST:
      return {...state, partnerSegmentsLoading: true};

    case types.SEGMENTS__GET_PARTNER_SUCCESS:
      return {
        ...state,
        partnerSegments: action.response.body.segments,
        partnerSegmentsLoading: false,
      };

    case types.SEGMENTS__GET_PARTNER_FAILURE:
      return {...state, partnerSegmentsLoading: false};
    default:
      return state;
  }
}
