import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Mark from '../components/Mark';
import NavLinks from './NavLinks';
import MyAccount from '../components/MyAccount';
import AdminLinks from 'src/components/AdminLinks';
import MenuSkipToMain from '../components/skipToMain/MenuSkipToMain';
import LogoWithNameAndNetwork from 'src/components/LogoWithNameAndNetwork';

class PageHeader extends React.Component {
  render() {
    return (
      <header>
        <MenuSkipToMain />
        <div className="bd-pageheader" style={this.props.headerStyleOverride}>
          <div
            className={
              this.props.headerClassOverride
                ? this.props.headerClassOverride
                : 'container'
            }
          >
            {this.props.role === "JOB_SEEKER" && (
              <div className='pt-3 center mobile-only text-align-center'>
                <LogoWithNameAndNetwork />
              </div>
            )}

            <div
              className={`padding-top-sixteen ${this.props.role === "JOB_SEEKER" ? 'non-mobile-only' : ''}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  paddingLeft: this.props.headerClassOverride ? '20px' : '0',
                  paddingRight: this.props.headerClassOverride ? '20px' : '0',
                  paddingBottom: '16px',
                }}
              >
                <Mark />
                <NavLinks role={this.props.role} />
              </div>
              {this.props.showAuthLink && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '16px',
                  }}
                >
                  <MyAccount />
                </div>
              )}
            </div>
            {this.props.children}
            {!this.props.children && (
              <div
                className="padding-bottom-sixteen"
                style={this.props.titleStyle}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {this.props.title !== null && (
                      <h1 className="normal-display-white">
                        {this.props.title}
                      </h1>
                    )}
                    {this.props.showAdminLinks && <AdminLinks />}
                  </div>
                  {this.props.actionButtons && (
                    <div style={{display: 'flex', gap: '10px'}}>
                      {this.props.actionButtons}
                    </div>
                  )}
                </div>
              </div>
            )}
            {this.props.notificationBanner}
          </div>
        </div>
      </header>
    );
  }
}

PageHeader.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string,
  isApprenticeProgram: PropTypes.bool,
  showAuthLink: PropTypes.bool,
  notificationBanner: PropTypes.object,
  title: PropTypes.string,
  showAdminLinks: PropTypes.bool.isRequired,
  headerStyleOverride: PropTypes.object,
  actionButtons: PropTypes.object,
  titleStyle: PropTypes.object,
  headerClassOverride: PropTypes.string,
};

PageHeader.defaultProps = {
  showAuthLink: true,
  notificationBanner: <Fragment />,
  showAdminLinks: false,
  title: '',
  headerStyleOverride: {fontSize: '16px'},
};

const mapStateToProps = (state) => {
  return {
    role: state.profile.role,
    isApprenticeProgram: state.profile.isApprenticeProgram
  };
};

export default connect(mapStateToProps)(PageHeader);
