import React, {forwardRef, useState} from 'react';
import style from './CheckboxSelectAscend.module.scss';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import {ChevronDownSvg, TrashSvg} from '../../../assets/svg/svgComponents';
import AreYouSureModal from 'src/components/AreYouSureModal';
import {useDispatch} from 'react-redux';
import {deleteSavedSearch} from 'src/actions/recruiters';
import * as ACTIONS from 'src/actions/ActionTypes.js';
import {setSearchTerm} from 'src/actions/candidateDiscoveryActions';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownSvg className={style.settingsSvg_fill_gray} />
    </components.DropdownIndicator>
  );
};

const Option = ({children, selectProps, ...props}) => {
  return (
    components.Option && (
      <components.Option
        {...props}
        style={{
          paddingLeft: '35px',
        }}
      >
        {props.label}{' '}
        <button
          id={props.label}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            selectProps.onDelete(e);
          }}
          className="float-right buttonAsLink"
        >
          <TrashSvg />
        </button>
      </components.Option>
    )
  );
};

Option.propTypes = {
  selectProps: PropTypes.any,
  children: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.any,
};

const NoOptionsMessage = () => {
  return (
    <>
      <center className="normal-title bold">No Saved Searches</center>
      <br />
      <center className="padding-8">
        To add a new saved search, select and apply your desired filters. Then,
        select “Save This Search” from the “Applied Filters” menu.
      </center>
    </>
  );
};

const ValueContainer = ({children, selectProps, ...props}) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer
        {...props}
        selectProps={selectProps}
        style={{
          paddingLeft: selectProps.showGlyph ? '35px' : '10px',
        }}
      >
        {children}
      </components.ValueContainer>
    )
  );
};

ValueContainer.propTypes = {
  selectProps: PropTypes.any,
  children: PropTypes.any,
};

const valueContainerStyle = {
  valueContainer: (provided, state) => {
    return {
      ...provided,
      ...state.style,
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      zIndex: '0',
      color: 'var(--neutral-gray-3)',
    };
  },
};
const placeholderStyle = {
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--neutral-gray-3)',
  }),
};

const DeletableSelect = forwardRef(
  (
    {
      placeholder,
      isLoading,
      isDisabled,
      closeMenuOnSelect,
      value,
      onChange,
      options,
      styles,
      className,
      defaultValue,
      name,
      labelText,
      id,
      selectedTags,
    },
    ref
  ) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [itemForDeletion, setItemForDeletion] = useState(null);

    const dispatch = useDispatch();

    const handleDeletion = () => {
      dispatch(deleteSavedSearch(itemForDeletion));
      dispatch({
        type: ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED,
        savedSearchSelectedItem: null,
      });
      setItemForDeletion(null);
    };

    const openConfirm = (e) => {
      setItemForDeletion(
        options.filter((x) => x.name === e.currentTarget.id)[0].id
      );
      setConfirmModalOpen(true);
    };

    const applyFilters = (e) => {
      dispatch({
        type: ACTIONS.CANDIDATE_DISCOVERY__CLEAR_ALL,
      });
      e.searchObject.selectedFieldValues.forEach((i) => {
        dispatch({
          type: ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM,
          toggledName: i,
        });
      });
      if (e.searchObject.selectedJob) {
        dispatch({
          type: ACTIONS.CANDIDATE_DISCOVERY__JOB_SELECT,
          jobObj: e.searchObject.selectedJob,
        });
      }
      if (e.searchObject.searchTerm) {
        dispatch(setSearchTerm(e.searchObject.searchTerm));
      }
      dispatch({
        type: ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED,
        savedSearchSelectedItem: e,
      });
      onChange();
    };

    return (
      <>
        <AreYouSureModal
          areYouSureText={
            <>
              <div className="normal-subheader bold">
                Are you sure you want to delete this saved search?
              </div>
              <br />
              <div>
                {itemForDeletion
                  ? options.filter((x) => x.id === itemForDeletion)[0].name
                  : ''}
              </div>
            </>
          }
          isOpen={confirmModalOpen}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
          onYes={() => {
            handleDeletion();
            setConfirmModalOpen(false);
          }}
          noText="No, cancel"
          yesText="Yes, delete"
          centerVertically={true}
          reverseButtons={true}
        />
        <Select
          inputId={id}
          name={name}
          aria-label={labelText}
          className={className}
          defaultValue={defaultValue}
          components={{
            DropdownIndicator,
            ValueContainer,
            Option,
            NoOptionsMessage,
          }}
          styles={Object.assign(valueContainerStyle, placeholderStyle, styles)}
          options={options}
          selectedTags={selectedTags}
          onChange={applyFilters}
          placeholder={placeholder}
          isLoading={isLoading}
          isDisabled={isDisabled}
          value={value}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={false}
          blurInputOnSelect={false}
          onDelete={openConfirm}
        />
      </>
    );
  }
);

DeletableSelect.propTypes = {
  labelText: PropTypes.string,
  children: PropTypes.any,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.any,
  styles: PropTypes.any,
  className: PropTypes.any,
  defaultValue: PropTypes.any,
  selectorGlyph: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  selectedTags: PropTypes.any,
};

DeletableSelect.defaultProps = {
  closeMenuOnSelect: true,
  isSearchable: true,
};

export default DeletableSelect;
