import React from 'react';
import PropTypes from 'prop-types';

import * as Layout from '../layout';
import {BusyButton} from './BusyButton';
import SkillsTreeWidget from 'src/components/skillTags/SkillsTreeWidget.js';
import {Field} from 'react-final-form';
import {connect} from 'react-redux';
import {
  getSkillTree,
  getAllSkillTags,
  getTopSkillTags,
} from 'src/actions/skillTags';

class SkillsFieldModal extends React.Component {
  componentDidMount() {
    this.props.getSkillTree();
    this.props.getAllSkillTags();
    this.props.getTopSkillTags(10);
  }

  render() {
    const {isOpen, onClose, onSubmit} = this.props;

    return (
      <Layout.AscendModal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        backdrop={'static'}
      >
        <Layout.AscendModalBody hasClose={false}>
          <div className="padding-32">
            <div>
              <div>
                <div className="active-headline text-align-center padding-bottom-eight bold">
                  Edit Skills
                </div>
                <label className="onboarding-form-label">
                  Search for skills and browse the skills library
                </label>
                <Field
                  component={SkillsTreeWidget}
                  name={this.props.name}
                  placeholder="Search & add skills (ex. data analytics)"
                  defaultValue={[]}
                  skillIdsToExclude={this.props.skillIdsToExclude}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">&nbsp;</div>
            </div>
            <div className="row justify-content-center nowrap">
              <button
                type="button"
                className="btn btn-transparent col-2  me-4"
                onClick={onClose}
              >
                Cancel
              </button>

              <BusyButton
                onClick={onSubmit}
                buttonText={'Save Skills'}
                busyText={'Saving Skills'}
                className="col-2"
                style={{width: '210px', height: '40px'}}
                float={'float-right'}
                buttonIcon={'save'}
                alignText={'text-center'}
              />
            </div>
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

SkillsFieldModal.propTypes = {
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getSkillTree: PropTypes.func.isRequired,
  getAllSkillTags: PropTypes.func.isRequired,
  getTopSkillTags: PropTypes.func.isRequired,
  skillIdsToExclude: PropTypes.array,
};

const actions = {
  getSkillTree,
  getAllSkillTags,
  getTopSkillTags,
};

export default connect(() => {
  return {};
}, actions)(SkillsFieldModal);
