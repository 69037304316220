import _ from 'lodash';
import {makeRequest} from '../../services/api';
import validator from 'validator';

export const findNextPage = (page, totalPages, props) => {
  if (page === totalPages) {
    return [page, totalPages];
  }

  return [++page, totalPages];
};

const hasNumbers = (t) => {
  let regex = /\d/g;
  return regex.test(t);
};

export const required = (value) => (value ? undefined : 'Required');
const minLength = (min) => (value) => {
  if (hasNumbers(value) === false) {
    return `Required`;
  }
  if (hasNumbers(value) && value.trim().length < min) {
    return `Must be 10 digit number`;
  }
  return undefined;
};
export const minPhoneLength = minLength(14);
export const validate = (fields, values) => {
  const errors = {};
  _.forEach(fields, (field) => {
    if (!values[field] || values[field] === null) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

/*
simpleMemoize lifted from final-form code sample for Async Validation:
https://codesandbox.io/s/react-final-form-asynchronous-field-level-validation-example-forked-4s20w?file=/index.js:762-959
Helps to reduce the number of calls to the API when the email
has not changed. Still will call for each character change once
a "valid" email has been entered.
*/
const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

export const asyncValidate = simpleMemoize(async (value) => {
  if (!value) {
    return 'Required';
  }
  if (!validator.isEmail(value)) {
    return;
  }
  const resp = await makeRequest({
    method: 'GET',
    path: `seekers/check_email?email=${encodeURIComponent(value)}`,
    isAuthenticated: false,
  });
  if (resp.data.exists) {
    return 'Email Already Exists';
  }
});
