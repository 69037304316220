import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../../../utils/commonHooks';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import LoadingOverlay from 'react-loading-overlay';

import * as seekersActions from 'src/actions/seekers';

import * as jobsActions from 'src/actions/jobs';
import * as ascendRecruitersActions from 'src/actions/ascendRecruiters';
import * as usersActions from 'src/actions/users';
import * as tableActions from 'src/actions/candidatesDataTable';
import {getProfileStatuses} from 'src/actions/profileStatuses';

import {PageHeader, PageFooter} from 'src/layout';
import AscendDataTable from 'src/components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import Avatar from 'src/components/Avatar';
import AreYouSureModal from 'src/components/AreYouSureModal';
import AssignForm from './AssignForm';
import ApplyForm from './ApplyForm';
import CreateConnectionModal from '../connections/CreateConnectionModal';

import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';
import SearchTab from 'src/components/controls/tabs/SearchTab';
import {seekerMajor} from 'src/utils/fieldHelper';
import {generateSeekerExport} from 'src/services/seekerApi';
import {updateSeekerAction} from '../../../actions/SeekerActions';
import {
  seekerApprovalChanged,
  seekerAdminApprovalChanged,
} from '../../../actions/seekers';
import {getTaskCount} from '../../../actions/taskActions';

import * as SVG from 'src/assets/svg/svgComponents';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import infoBlueCircle from '../../../assets/images/info-blue-circle.svg';
import RequiresAttention from 'src/pages/tasks/RequiresAttention';
import NewStatsDashboard from 'src/components/NewStatsDashboard';
import NotesModal from 'src/components/NotesModal';
import {API_URL} from 'src/utils/helper';
import {getSegments} from 'src/actions/SegmentActions';
import {
  CandidateAdminTabNames,
  CandidateAdminTabs,
} from 'src/utils/CandidateAdminTabs';
import {
  allHighSchoolListAction,
  highSchoolListAction,
} from 'src/actions/schoolActions';
import {MA_INDY_SEGMENT_ID} from 'src/containers/AuthorizedComponent';
import AuthorizationModal from 'src/containers/AuthorizationModal';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import CandidateAssignManager from '../../../components/Candidate/CandidateAssignManager';

export const ILI_SEGMENT_ID = 8;

class CandidateListPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      showAssignManager: false,
      applyFormOpen: false,
      assignFormOpen: false,
      seekerId: null,
      selectedSeeker: null,
      seekerForApply: null,
      ascendRecruiterId: 0,
      currentItemsPerPage: 5,
      areYouSureModalIsOpen: false,
      removeSeekerId: '',
      loading: false,
      loadingText: 'Loading...',
      defaultAvatar: '../src/assets/images/noavatar.svg',
      selectedTab: '1',
      showStatHelperText: false,
      statHelperText: '',
      canViewCandidatePII: false,
      notesModalIsOpen: false,
      notesSeeker: {
        firstName: '',
        lastName: '',
        avatarUrl: '',
        id: null,
      },
      startDate: '',
      endDate: '',
      highSchools: [],
      authorizationModalIsOpen: false,
      changeEmailModalIsOpen: false,
      canChangeOtherEmail: false,
      reportConnectionFormOpen: false,
      seekerForConnection: null,
    };
  }

  filters = () => {
    return this.state.selectedTab !== CandidateAdminTabs.TASKS
      ? this.props[CandidateAdminTabNames[this.state.selectedTab]]
      : this.props.active;
  };

  getInitialTabCounts = () => {
    // For AI-4054 - The Manage Student Approvals tab should pre-filter
    // by pending approval only. The other two tabs will not have any
    // filters applied.
    // The goal of this method is to initialize the tab counts based
    // on the assumptions of the initial state of each tab.
    if (
      this.props.activeTabCountLoading ||
      this.props.inactiveTabCountLoading ||
      this.props.manageStudentApprovalsCountLoading ||
      this.props.manageAdminApprovalsCountLoading ||
      this.props.taskCountsLoading
    )
      return;

    this.props.getSeekerCountForFilters([
      `tabName=${CandidateAdminTabNames[CandidateAdminTabs.ACTIVE]}`,
    ]);
    this.props.getSeekerCountForFilters([
      `tabName=${CandidateAdminTabNames[CandidateAdminTabs.INACTIVE]}`,
    ]);
    this.props.getSeekerCountForFilters([
      `tabName=${
        CandidateAdminTabNames[CandidateAdminTabs.MA_APPROVALS]
      }&isSchoolApproved=null&accepted=null`,
    ]);
    this.props.getSeekerCountForFilters([
      `tabName=${
        CandidateAdminTabNames[CandidateAdminTabs.APPROVALS]
      }&accepted=null`,
    ]);
    this.props.getTaskCount();
  };

  componentDidMount() {
    this._isMounted = true;

    has_permission(PERMISSIONS.READ_LIST_SEEKERS).then((resp) => {
      if (!this._isMounted) return;
      if (resp.data.hasPermission) {
        this.props.getSeekersStats();
        this.props.getMySeekersStats();
      }
    });
    has_permission(PERMISSIONS.READ_ASCEND_RECRUITERS).then((resp) => {
      if (!this._isMounted) return;

      if (resp.data.hasPermission) {
        this.props.getAscendRecruiters();
      }
    });
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) return;

      this.setState({canViewCandidatePII: resp.data.hasPermission});
    });

    has_permission(PERMISSIONS.ALLOWED_TO_CHANGE_OTHER_EMAIL).then((resp) => {
      if (!this._isMounted) return;
      this.setState({canChangeOtherEmail: resp.data.hasPermission});
    });

    if (this.props.profile.segmentIds.length > 0) {
      this.props.profile.segmentIds.forEach((x, i) => {
        highSchoolListAction(x).then((schools) => {
          if (!this._isMounted) return;
          this.setState((state) => ({
            highSchools: state.highSchools.concat(schools),
          }));
        });
      });
    } else {
      allHighSchoolListAction().then((schools) => {
        if (!this._isMounted) return;
        this.setState({
          highSchools: schools,
        });
      });
    }

    if (this.props.seekersLoading) return;

    if (this.props.profileStatuses.length === 0) {
      this.props.getProfileStatuses();
    }
    if (this.props.segments.length === 0) {
      this.props.getSegments();
    }
    if (this.props.profileStatuses.length > 0) {
      this.getSeekers(
        this.props.currentPage,
        this.props.itemsPerPage,
        this.props.sortBy,
        this.props.sortAscending,
        this.filters().allFilters,
        true
      );
    }
    this.props.getSeekerReports();
    if (this.props.candidateListFilterActive === false) {
      this.getInitialTabCounts();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedTab !== this.state.selectedTab) {
      this.getSeekers(
        this.props.currentPage,
        this.props.itemsPerPage,
        this.props.sortBy,
        this.props.sortAscending
      );
    }

    if (
      prevProps.profileStatuses.length !== this.props.profileStatuses.length
    ) {
      this.getSeekers(
        this.props.currentPage,
        this.props.itemsPerPage,
        this.props.sortBy,
        this.props.sortAscending,
        this.filters().allFilters
      );
    }

    if (
      prevState.startDate !== this.state.startDate ||
      prevState.startDate !== this.state.startDate ||
      prevState.recruiterId !== this.state.recruiterId
    ) {
      this.props.getMySeekersStats(
        this.state.startDate,
        this.state.endDate,
        this.state.recruiterId
      );
    }
  }

  cleanFiltersForSearch = (arrayOfFilters = null) => {
    const otherFilters = [];
    const profileStatusIdFilter = [];
    const meetingStatusIdFilter = [];
    let seekerFilters = this.filters().allFilters;
    if (arrayOfFilters !== null) {
      seekerFilters = arrayOfFilters;
    }

    for (const filter of seekerFilters) {
      if (filter.indexOf('profileStatusIds') > -1) {
        profileStatusIdFilter.push(filter);
        continue;
      }
      if (filter.indexOf('meetingStatusIds') > -1) {
        meetingStatusIdFilter.push(filter);
        continue;
      }
      if (filter.indexOf('searchTerm') > -1 && filter === 'searchTerm=') {
        continue;
      }

      otherFilters.push(filter);
    }

    const meetingStatusFilter = this.getMeetingStatusFilter(
      meetingStatusIdFilter
    );

    return [
      ...otherFilters,
      profileStatusIdFilter,
      meetingStatusFilter,
      `tabName=${CandidateAdminTabNames[this.state.selectedTab]}`,
    ];
  };

  /*
  AI-3055 - When using the "Not Met With" quick filter and
  Selecting a specific Meeting Status from the Advanced
  filter drop down, the `meetingStatusIds` query string
  parameter is duplicated in allFilters. Remove the duplicate
  and combine all meeting status ids together for an OR expression
  */
  getMeetingStatusFilter = (uiMeetingStatusIds) => {
    const apiMeetingStatusIds = [];
    for (const uiMeetingId of uiMeetingStatusIds) {
      const idStringArray = uiMeetingId.split('=')[1].split(',');
      for (const idString of idStringArray) {
        if (!apiMeetingStatusIds.includes(idString)) {
          apiMeetingStatusIds.push(idString);
        }
      }
    }

    if (apiMeetingStatusIds.length === 0) {
      return null;
    }

    return `meetingStatusIds=${apiMeetingStatusIds.join(',')}`;
  };

  getSeekers = (
    newPage,
    itemsPerPage,
    sortBy,
    sortAscending,
    filterArray,
    updateCounts = false
  ) => {
    const sortOperator = sortAscending ? '%2b' : '-';
    let sortTerm = '';
    if (sortBy) {
      sortTerm = sortOperator + sortBy;
    }

    const cleanFilters = this.cleanFiltersForSearch(filterArray);
    this.props.getLightSeekersAdvanced(
      newPage,
      itemsPerPage,
      sortTerm,
      cleanFilters,
      CandidateAdminTabNames[this.state.selectedTab]
    );

    if (updateCounts === true) {
      // search filters modified, re-calculate totals
      this.props.getSeekerCountForFilters(cleanFilters);
    }
  };

  handleHomeClick = (seekerId) => {
    this.props.history.push('/candidatehome/' + seekerId);
  };

  handleAuthenticationClick = (seekerId) => {
    const seeker = _.find(this.props.seekers, (seeker) => {
      return seeker.id === seekerId;
    });

    this.setState({authorizationModalIsOpen: true, selectedSeeker: seeker});
  };

  handleViewClick = (seekerId) => {
    this.props.history.push('/candidate/' + seekerId);
  };

  handleDiscoveryClick = (seekerId) => {
    this.props.history.push('/find_roles/' + seekerId);
  };

  handleFavoritesClick = (seekerId) => {
    this.props.history.push('/favorites/' + seekerId);
  };

  handleApplicationsClick = (seekerId) => {
    this.props.history.push('/applications/' + seekerId);
  };

  handleChangeEmailClick = (seekerId) => {
    const seeker = _.find(this.props.seekers, (seeker) => {
      return seeker.id === seekerId;
    });

    this.props.updateChangeEmailUser(seeker);

    this.setState({changeEmailModalIsOpen: true});
  };

  handleDeleteClick = (seekerId) => {
    this.setState(
      {
        removeSeekerId: seekerId,
      },
      () => this.setState({areYouSureModalIsOpen: true})
    );
  };

  handleDoRemove = () => {
    this.props.deleteSeeker(this.state.removeSeekerId);
    this.setState(
      {
        removeSeekerId: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handleAreYouSureClose = () => {
    this.setState(
      {
        removeSeekerId: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handlePageChange = (pageNumber) => {
    this.props.updateCurrentPage(pageNumber);
    this.getSeekers(
      pageNumber,
      this.props.itemsPerPage,
      this.props.sortBy,
      this.props.sortAscending,
      this.filters().allFilters
    );
  };

  handleSortChange = (sortBy, sortAscending) => {
    this.props.updateSortTerm(sortBy, sortAscending);
    this.getSeekers(
      0,
      this.props.itemsPerPage,
      sortBy,
      sortAscending,
      this.filters().allFilters
    );
  };

  handleAssignClick = (seekerId) => {
    const seeker = _.find(this.props.seekers, (seeker) => {
      return seeker.id === seekerId;
    });

    this.setState({
      assignFormOpen: true,
      seekerId: seeker.id,
      selectedSeeker: seeker,
      ascendRecruiterId: seeker.ascendRecruiter ? seeker.ascendRecruiter.id : 0,
    });
  };

  handleApplyClick = (seekerId) => {
    const seeker = _.find(this.props.seekers, (seeker) => {
      return seeker.id === seekerId;
    });
    this.props.getSeeker(seekerId);
    this.setState({
      applyFormOpen: true,
      seekerId: seeker.id,
      seekerName: seeker.firstName + ' ' + seeker.lastName,
    });
  };

  handleReportConnectionClick = (seekerId) => {
    const seeker = _.find(this.props.seekers, (seeker) => {
      return seeker.id === seekerId;
    });
    this.setState({
      reportConnectionFormOpen: true,
      seekerId: seekerId,
      seekerForConnection: seeker,
    });
  };

  handleAssignSubmit = (ascendRecruiterId) => {
    if (ascendRecruiterId === '0') {
      this.props.updateSeeker({
        id: this.state.seekerId,
        ascendRecruiterId: null,
      });
    } else {
      this.props.updateSeeker({
        id: this.state.seekerId,
        ascendRecruiterId: ascendRecruiterId,
      });
    }
    this.setState({assignFormOpen: false, selectedSeeker: null});
  };

  handleApplySubmit = (roleId) => {
    this.props.createJobAppliedBySeeker(roleId, this.state.seekerId);
  };

  handleOnboardClick = async (seekerId) => {
    const {seekers} = this.props;
    let seeker = seekers.find((seeker) => seeker.id === seekerId);
    seeker.offboardedOn = null;
    this.props.updateSeekerAction(seeker);
    this.getSeekers(
      this.props.currentPage,
      this.props.itemsPerPage,
      this.props.sortBy,
      this.props.sortAscending,
      this.filters().allFilters,
      true
    );
  };

  handleCalendlyOverrideClick = (seekerId) => {
    const {seekers} = this.props;
    let seeker = seekers.find((seeker) => seeker.id === seekerId);
    this.setState({selectedSeeker: seeker, showAssignManager: true});
  };

  handleDeactivateProfile = (seekerId) => {
    let seeker = {
      id: seekerId,
      offboardedOn: new Date(),
      published: false,
    };

    this.props.updateSeekerAction(seeker);
  };

  handleNotesClicked = (seeker) => {
    this.setState({notesSeeker: seeker, notesModalIsOpen: true});
  };
  handleFlagClicked = (seeker) => {
    this.props.toggleSeekerSupportFlag(seeker.id);
  };

  handleAdminApprovalChange = (value, seekerId) => {
    let updatedSeeker = {
      id: seekerId,
      accepted: value,
    };

    const seeker = this.props.seekers.find((s) => s.id === seekerId);
    if (!seeker.segments.some((s) => s.showAdminApprovalTab)) {
      this.props.seekerApprovalChanged(updatedSeeker);
    } else {
      this.props.seekerAdminApprovalChanged(updatedSeeker);
    }
    this.props.updateSeekerAction(updatedSeeker);
  };

  handleSchoolApprovalChange = (value, seekerId) => {
    let seeker = {
      id: seekerId,
      isSchoolApproved: value,
    };

    this.props.updateSeekerAction(seeker);
  };

  selectTotalItems = () => {
    if (this.state.selectedTab === CandidateAdminTabs.APPROVALS) {
      return this.props.manageAdminApprovalsCount;
    }
    if (this.state.selectedTab !== CandidateAdminTabs.MA_APPROVALS) {
      return this.props[
        `${CandidateAdminTabNames[this.state.selectedTab]}SeekerCount`
      ];
    }

    return this.props.manageStudentApprovalsCount;
  };

  rowMenuActions = {
    'View Applications': {
      onClick: this.handleApplicationsClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'Change Email': {
      onClick: this.handleChangeEmailClick,
      showMenu: (seeker) =>
        seeker.offboardedOn === null && this.state.canChangeOtherEmail,
    },
    'Track an Application': {
      onClick: this.handleApplyClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'Report a Connection': {
      onClick: this.handleReportConnectionClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'Assign Manager': {
      onClick: this.handleAssignClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'View Matches': {
      onClick: this.handleDiscoveryClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'View Favorites': {
      onClick: this.handleFavoritesClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'View Home Page': {
      onClick: this.handleHomeClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    'Change Work Authorization Status': {
      onClick: this.handleAuthenticationClick,
      showMenu: (seeker) =>
        seeker.offboardedOn === null &&
        seeker.segments.some((s) => s.id === MA_INDY_SEGMENT_ID),
    },
    'Deactivate Profile': {
      onClick: this.handleDeactivateProfile,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    Delete: {
      onClick: this.handleDeleteClick,
      showMenu: (seeker) => seeker.offboardedOn === null,
    },
    Onboard: {
      onClick: this.handleOnboardClick,
      showMenu: (seeker) => seeker.offboardedOn !== null,
    },
    'Calendly Schedule Override': {
      onClick: this.handleCalendlyOverrideClick,
      showMenu: (seeker) =>
        (seeker.profileStatus || 'Incomplete') === 'Incomplete',
    },
  };

  handlerFunction = async (operation, seekerId) => {
    if (!(operation in this.rowMenuActions)) {
      this.handleViewClick(seekerId);
      return;
    }
    this.rowMenuActions[operation].onClick(seekerId);
  };

  buildAvatar = (seeker) => {
    let avatarUrl = seeker.avatarUrl;
    if (avatarUrl === undefined || avatarUrl === null) {
      avatarUrl = this.state.defaultAvatar;
    }
    return <Avatar url={avatarUrl} label={`${seeker.name}`} />;
  };

  buildName = (seeker) => {
    const longName = seeker.lastName + ', ' + seeker.firstName;
    const segments = seeker.segments.map((x) => x.name).join(',');
    const maConsentMessage =
      'This student has not received consent from their parent or legal guardian to use the Ascend Network. <br/>' +
      'They cannot complete their profile or use the Network until consent is received.';
    return (
      <div style={{textAlign: 'left', minWidth: '100px'}} role="none">
        <Link
          className="admin-item-link text-wrap"
          to={'/candidate/' + seeker.id}
        >
          {longName}
          {!seeker.termsAccepted &&
            seeker.segments.some((s) => s.isApprenticeProgram) && (
              <SVG.WarningSvg
                tabIndex={0}
                role="button"
                className="clickable svg_color_stroke_red"
                style={{marginLeft: '8px', marginTop: '-4px'}}
                alt={maConsentMessage.replace('<br/>', '')}
                data-tooltip-id={'tooltip'}
                data-tooltip-place={'right'}
                data-tooltip-html={maConsentMessage}
              />
            )}
        </Link>
        <br />
        <span className="visually-hidden">Segment&nbsp;</span>
        {segments}
      </div>
    );
  };

  buildMatches = (seeker) => {
    const count = seeker.baseOverallMatchCount;
    return (
      <div className="d-flex justify-content-center align-items-center text-nowrap">
        <SVG.BriefcaseSvg alt="" /> &nbsp;{count}
      </div>
    );
  };

  buildInvitations = (seeker) => {
    const count = seeker.invitationMatchCount;
    return (
      <div className="d-flex justify-content-center align-items-center text-nowrap">
        <SVG.InvitationSvg alt="" />
        &nbsp; {count}
      </div>
    );
  };
  buildManager = (seeker) => {
    var longManager =
      seeker.ascendRecruiter && seeker.ascendRecruiter.name
        ? seeker.ascendRecruiter.name
        : 'n/a';
    return longManager;
  };

  buildHighSchool = (seeker) => {
    return (
      <div style={{textAlign: 'left'}} role="none">
        {seeker.highSchool ? seeker.highSchool : ''}
      </div>
    );
  };

  buildMajor = (seeker) => seekerMajor(seeker);

  buildApplications = (seeker) => {
    const count = seeker.applicationCount;
    return (
      <div className="d-flex justify-content-center align-items-center text-nowrap">
        <SVG.AppliedSvg alt="" /> &nbsp;{count}
      </div>
    );
  };

  buildLastApplication = (seeker) => {
    if (!seeker.mostRecentApplication) {
      return '';
    }

    const lDate = new Date(seeker.mostRecentApplication);

    return (
      <div className="d-flex justify-content-center align-items-center text-nowrap">
        <SVG.CalendarSvg alt="" /> &nbsp; {lDate.toLocaleDateString()}
      </div>
    );
  };
  buildProfileStatus = (seeker) => {
    const PUBLISHED = 1;
    const UNPUBLISHED = 2;
    const INCOMPLETE = 4;
    const UNQUALIFIED = 5;
    const DEACTIVATED = 6;
    const AUTO_UNPUBLISHED = 3;
    const COMPLETE_AND_UNAPPROVED = 7;
    const INCOMPLETE_AND_APPROVED = 8;
    const COMPLETE_AND_APPROVED = 9;
    const DECLINED = 10;
    const INCOMPLETE_AND_UNAPPROVED = 11;

    const unqualifiedHelperText =
      seeker.segments.length > 0 && seeker.segments[0].id === MA_INDY_SEGMENT_ID
        ? 'This student does not have proper I-9 documentation.'
        : 'This user is not eligible to use the Ascend Network.';

    const statusImages = {
      [PUBLISHED]: {
        image: <SVG.PublishedSvg alt="" />,
        helperText: 'This profile is currently published.',
      },
      [UNPUBLISHED]: {
        image: <SVG.NotInterestedSvg className="svg_color_stroke_red" alt="" />,
        helperText:
          'This profile was unpublished by an admin or the candidate.',
      },
      [AUTO_UNPUBLISHED]: {
        image: <SVG.CloseSvg className="svg_color_fill_red" alt="" />,
        helperText:
          'This profile was unpublished by the system after 90 days of inactivity.',
      },
      [INCOMPLETE]: {
        image: <SVG.CloseSvg className="svg_color_fill_red" alt="" />,
        helperText: 'This user has not completed sign-up and/or onboarding.',
      },
      [UNQUALIFIED]: {
        image: <SVG.CloseSvg className="svg_color_fill_red" alt="" />,
        helperText: unqualifiedHelperText,
      },
      [DEACTIVATED]: {
        image: <SVG.NotInterestedSvg className="svg_color_stroke_red" alt="" />,
        helperText:
          'This user no longer requires the Ascend Network’s support.',
      },
      [COMPLETE_AND_UNAPPROVED]: {
        image: <SVG.CheckSvg className="svg_color_stroke_green" alt="" />,
        helperText:
          'Student has built their profile but is not yet approved to participate.',
      },
      [INCOMPLETE_AND_APPROVED]: {
        image: <SVG.CloseSvg className="svg_color_fill_red" alt="" />,
        helperText:
          'Student has not completed building their profile but is approved to participate.',
      },
      [COMPLETE_AND_APPROVED]: {
        image: <SVG.ThumbsUpSvg className="svg_color_stroke_green" alt="" />,
        helperText:
          'Student has built their profile and is approved to participate. They can apply to apprenticeships.',
      },
      [DECLINED]: {
        image: <SVG.ThumbsdownSvg className="svg_color_stroke_red" alt="" />,
        helperText:
          'Student was declined from participating. They cannot apply to apprenticeships.',
      },
      [INCOMPLETE_AND_UNAPPROVED]: {
        image: <SVG.CloseSvg className="svg_color_fill_red" alt="" />,
        helperText: 'Student has not completed building their profile.',
      },
    };
    const statusText = seeker.profileStatus || 'Incomplete';

    const toolTipImage =
      seeker.profileStatusId !== null ? (
        statusImages[seeker.profileStatusId] !== undefined ? (
          statusImages[seeker.profileStatusId].image
        ) : (
          <SVG.WarningSvg className="svg_color_stroke_black" alt="" />
        )
      ) : (
        <SVG.CloseSvg className="svg_color_fill_red" alt="" />
      );

    const helperText =
      seeker.profileStatusId !== null
        ? statusImages[seeker.profileStatusId] !== undefined
          ? statusImages[seeker.profileStatusId].helperText
          : 'Profile status is unrecognized.'
        : 'This user has not completed sign-up and/or onboarding.';

    return (
      <div className="justify-content-start align-items-center text-nowrap">
        {toolTipImage}&nbsp; {statusText}
        &nbsp;
        <img
          tabIndex={0}
          role="button"
          className="clickable"
          style={{width: '14px'}}
          alt={helperText}
          src={infoBlueCircle}
          data-tooltip-id={'tooltip'}
          data-tooltip-place={'top'}
          data-tooltip-html={helperText}
        />
      </div>
    );
  };

  buildResumeReviewed = (seeker) => {
    if (seeker.resumeApproved === null || seeker.resumeApproved === undefined) {
      return 'n/a';
    }
    return seeker.resumeApproved ? 'yes' : 'no';
  };

  buildRecruiterColumn = (seeker) => {
    if (seeker.ascendRecruiter === undefined) {
      return;
    }

    let isArray = seeker.ascendRecruiter instanceof Array;

    const name = isArray
      ? seeker.ascendRecruiter[0].name
      : seeker.ascendRecruiter.name;
    const avatarUrl = isArray
      ? seeker.ascendRecruiter[0].profileAvatarUrl
      : seeker.ascendRecruiter.profileAvatarUrl;

    return (
      <div
        className="d-flex justify-content-center align-items-center text-nowrap"
        tabIndex={0}
        data-tooltip-id={'tooltip'}
        data-tooltip-place={'top'}
        data-tooltip-html={name}
      >
        {avatarUrl && <Avatar url={avatarUrl} label={name} />}
      </div>
    );
  };

  buildNotesColumn = (seeker) => {
    return (
      <div
        tabIndex={0}
        onClick={() => {
          this.handleNotesClicked(seeker);
        }}
        className="d-flex justify-content-center align-items-center text-nowrap"
        aria-label="Candidate Notes"
        role="button"
      >
        <div
          style={{position: 'relative'}}
          className="d-flex justify-content-center align-items-center clickable clickable-circle"
        >
          <SVG.MessageDefaultSvg alt="" /> &nbsp; {seeker.noteCount}
        </div>
      </div>
    );
  };

  buildFlagColumn = (seeker) => {
    return (
      <div
        tabIndex={0}
        onClick={() => {
          this.handleFlagClicked(seeker);
        }}
        className="d-flex justify-content-center align-items-center text-nowrap"
        aria-label="flag"
        aria-pressed={seeker.seekerSupportFlag === true}
        role="button"
      >
        <div
          style={{position: 'relative'}}
          className="d-flex justify-content-center align-items-center clickable clickable-circle"
        >
          {seeker.seekerSupportFlag === true && <SVG.FlagSolidSvg alt="" />}
          {seeker.seekerSupportFlag === false && <SVG.FlagSvg alt="" />}
        </div>
      </div>
    );
  };

  buildPublished = (employer) => {
    if (employer.published === true) return 'yes';
    if (employer.published === false) return 'no';
    return 'n/a';
  };

  buildEmail = (seeker) => {
    return seeker.email;
  };

  buildPhone = (seeker) => {
    return seeker.phone;
  };

  buildStatus = (seeker) => {
    return (
      <>
        <button
          className="btn btn-primary me-3"
          onClick={() => {
            this.handleAdminApprovalChange(true, seeker.id);
          }}
        >
          Accept
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => {
            this.handleAdminApprovalChange(false, seeker.id);
          }}
        >
          Decline
        </button>
      </>
    );
  };

  handleNoteAdded = () => {
    this.props.incrementSeekersListNoteCount(this.state.notesSeeker.id);
  };

  handleNoteRemoved = () => {
    this.props.decrementSeekersListNoteCount(this.state.notesSeeker.id);
  };

  rowMenuItemListFilter = (menuText, row) => {
    return this.rowMenuActions[menuText.displayName].showMenu(row);
  };

  getHelperText = (stats) => {
    let msg = '<p>Dashboard Terms Glossary</p><ul>';
    for (let i = 0; i < stats.length; i++) {
      const stat = stats[i];
      msg += `<li><b>${stat['label']}</b> - ${stat['helperText']}</li>`;
    }
    msg += '</ul>';
    return msg;
  };

  handleClearAll = () => {
    this.props.saveCandidateClearFilters(this.state.selectedTab);
    this.props.setCandidateListFilterActive(false);
    this.getSeekers(
      0,
      this.props.itemsPerPage,
      this.props.sortBy,
      this.props.sortAscending,
      [],
      true
    );
  };

  handleTabChange = (eventKey) => {
    this.setState({
      selectedTab: eventKey,
    });
    this.props.updateCurrentPage(0);
    this.props.saveCandidateSelectedTab(eventKey);
  };

  handleStatsEnter = (e) => {
    this.setState({showStatHelperText: true});
    this.setState({statHelperText: e.helperText});
  };

  handleStatsLeave = (e) => {
    this.setState({showStatHelperText: false});
    this.setState({statHelperText: ''});
  };

  getMultiSelectQueryStringArray = () => {
    /*
    This method assumes the redux "active" and "inactive"
    filter objects include an array of objects shaped like:
    {
      label: 'item',
      value: 3
    }
    E.g. Skills would be:
    skillTagIds: [
      {
        label: '.NET',
        value: 1
      },
      {
        label: 'Java',
        value: 2
      }
    ]
    In this case, the `skillTagIds` name should be assigned
    in redux so the below object can pull exactly what needs
    to be sent to the API for searching.
    This method will flatten these items into an array that
    can be sent as a query string. Example Return Value:
    [
      'skillTagIds=1,2',
      'profileStatusIds=1,2'
    ]
    */
    const currentTab = CandidateAdminTabNames[this.state.selectedTab];
    const tabFilters = this.props[currentTab].multiSelect;

    const multiSelectKeys =
      tabFilters !== undefined ? Object.keys(tabFilters) : [];
    let multiSelectQueryStringItems = [];
    for (const key of multiSelectKeys) {
      if (tabFilters[key].length === 0) continue;
      const value = tabFilters[key].map((item) => item.value).join(',');
      multiSelectQueryStringItems.push(`${key}=${value}`);
    }
    return multiSelectQueryStringItems;
  };

  handleFilterButton = ({
    searchTerm,
    searchFilters,
    searchUpdated,
    toggleButtons,
    toggleButtonFilters,
    toggleButtonFiltersUpdated,
    advancedFilters,
  }) => {
    if (!searchTerm) searchTerm = this.filters().searchTerm;
    if (!searchFilters) searchFilters = this.filters().searchFilters;
    if (!searchUpdated) searchUpdated = false;
    if (!toggleButtons) toggleButtons = this.filters().toggleButtons;
    if (!toggleButtonFilters)
      toggleButtonFilters = this.filters().toggleButtonFilters;
    if (!toggleButtonFiltersUpdated) toggleButtonFiltersUpdated = false;
    advancedFilters = this.getMultiSelectQueryStringArray();

    if (searchUpdated) {
      this.props.saveCandidateSearchFilters(
        searchTerm,
        searchFilters,
        this.state.selectedTab
      );
    }

    if (advancedFilters.length > 0) {
      this.props.setCandidateListFilterActive(true);
    } else {
      this.props.setCandidateListFilterActive(false);
    }

    if (toggleButtonFiltersUpdated) {
      if (toggleButtonFilters.length > 0) {
        this.props.setCandidateListFilterActive(true);
      } else {
        this.props.setCandidateListFilterActive(false);
      }

      this.props.saveCandidateToggleButtonFilters(
        toggleButtons,
        toggleButtonFilters,
        this.state.selectedTab
      );
    }
    //advanced filters are already updated in advanced filters page no need to save separately

    let arrayOfFilters = [
      ...toggleButtonFilters,
      ...advancedFilters,
      ...searchFilters,
    ];

    this.props.saveCandidateAllFilters(arrayOfFilters, this.state.selectedTab);
    this.props.updateCurrentPage(0);
    this.getSeekers(
      0,
      this.props.itemsPerPage,
      this.props.sortBy,
      this.props.sortAscending,
      arrayOfFilters,
      true
    );
  };

  getStatsByRecruiterId = (startDate, endDate) => {
    this.setState({startDate: startDate, endDate: endDate});
  };

  handleRecruiterChange = (option) => {
    this.setState({
      recruiterId: option.label === 'Mine' ? -1 : option.id,
      recruiterName: option.label,
    });
  };

  render() {
    const tableColumns = [
      {
        content: this.buildAvatar,
      },
      {
        sortBy: 'name',
        displayName: <div style={{textAlign: 'left', float: 'left'}}>NAME</div>,
        content: this.buildName,
      },
      {
        sortBy: 'baseOverallMatchCount', // AI-4709 - Use Base Recommendation Engine Version for ADMIN display
        displayName: 'MATCHES',
        content: this.buildMatches,
      },
      {
        sortBy: 'invitationMatchCount',
        displayName: 'INVITATIONS',
        content: this.buildInvitations,
      },
      {
        sortBy: 'applicationCount',
        displayName: 'APPLICATIONS',
        content: this.buildApplications,
      },
      {
        sortBy: 'mostRecentApplication',
        displayName: 'LAST APPLICATION',
        content: this.buildLastApplication,
      },

      {
        displayName: 'PROFILE STATUS',
        content: this.buildProfileStatus,
        sortBy: 'profileStatus',
      },
      {
        sortBy: 'manager',
        displayName: 'RECRUITER',
        content: this.buildRecruiterColumn,
      },
      {
        sortBy: 'notes',
        displayName: 'NOTES',
        content: this.buildNotesColumn,
      },
      {
        sortBy: 'seekerSupportFlag',
        displayName: 'FLAG',
        content: this.buildFlagColumn,
      },
    ];

    const approvalOptions = {
      true: 'Approved',
      false: 'Declined',
    };

    const maApprovalTableColumns = [
      {
        displayName: <span className="visually-hidden">Avatar Column</span>,
        content: this.buildAvatar,
      },
      {
        sortBy: 'name',
        displayName: <div style={{textAlign: 'left', float: 'left'}}>NAME</div>,
        content: this.buildName,
      },
      {
        sortBy: 'highSchool',
        displayName: (
          <div style={{textAlign: 'center', float: 'left'}}>HIGH SCHOOL</div>
        ),
        content: this.buildHighSchool,
      },
      {
        displayName: 'ADMIN APPROVAL STATUS',
        sortBy: 'accepted ',
        content: (seeker) => {
          return {
            value: seeker.accepted,
            label: approvalOptions[seeker.accepted] || 'Pending',
            options: approvalOptions,
            onChange: (x, y) => this.handleAdminApprovalChange(x, y),
          };
        },
        showAsSelect: true,
      },
      {
        displayName: 'SCHOOL APPROVAL STATUS',
        sortBy: 'isSchoolApproved ',
        content: (seeker) => {
          return {
            value: seeker.isSchoolApproved,
            label: approvalOptions[seeker.isSchoolApproved] || 'Pending',
            options: approvalOptions,
            onChange: (x, y) => this.handleSchoolApprovalChange(x, y),
          };
        },
        showAsSelect: true,
      },
      {
        displayName: 'PROFILE STATUS',
        content: this.buildProfileStatus,
        sortBy: 'profileStatus',
      },
      {
        sortBy: 'manager',
        displayName: 'RECRUITER',
        content: this.buildRecruiterColumn,
      },
      {
        sortBy: 'notes',
        displayName: 'NOTES',
        content: this.buildNotesColumn,
      },
      {
        sortBy: 'seekerSupportFlag',
        displayName: 'FLAG',
        content: this.buildFlagColumn,
      },
    ];

    const adminApprovalTableColumns = [
      {
        displayName: <span className="visually-hidden">Avatar Column</span>,
        content: this.buildAvatar,
      },
      {
        sortBy: 'name',
        displayName: <div style={{textAlign: 'left', float: 'left'}}>NAME</div>,
        content: this.buildName,
      },
      {
        sortBy: 'email',
        displayName: 'EMAIL ADDRESS',
        content: this.buildEmail,
      },
      {
        sortBy: 'phone',
        displayName: 'PHONE NUMBER',
        content: this.buildPhone,
      },
      {
        displayName: 'ADMIN APPROVAL STATUS',
        content: this.buildStatus,
      },
    ];

    const exportMyCandidates = async () => {
      try {
        document.body.style.overflow = 'hidden';
        this.setState({loading: true});
        const response = await generateSeekerExport();
        window.open(response.data.url, '_blank');
      } finally {
        this.setState({loading: false});
        document.body.style.overflow = 'scroll';
      }
    };

    const getCsvExportUrl = () => {
      let filterArray = this.cleanFiltersForSearch();
      filterArray.push('includeUnPublished');

      let url = `${API_URL}/seekers/csv`;
      url += '?' + filterArray.join('&');

      if (this.props.sortBy !== '') {
        let sortTerm =
          (this.props.sortAscending ? '%2b' : '-') + this.props.sortBy;
        url += `&sort=${sortTerm}`;
      }
      return url;
    };

    const exportFilteredCandidates = async () => {
      const url = getCsvExportUrl();

      this.setState({
        loading: true,
        loadingText:
          'Export can take up to 30 seconds. Please do not refresh the page. If the list being downloaded includes more than 5,000 records, only the first 5,000 will be downloaded.',
      });
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: localStorage.getItem('id_token'),
          },
        });
        const fileName = response.headers
          .get('content-disposition')
          .split('filename=')[1];
        const blob = await response.blob();
        /*
        createObjectUrl creates a filename with a GUID (e.g. "3834ac55-1991-4cda-b698-bd755baab22e.csv")
        so using window.open(file) will download a guid
        to the User. Instead, use the datetime stamped
        filename passed from the server for user-friendliness
        */
        var tempAnchor = window.document.createElement('a');

        tempAnchor.href = window.URL.createObjectURL(blob, {
          type: 'text/csv',
        });
        tempAnchor.download = fileName;
        document.body.appendChild(tempAnchor);
        tempAnchor.click();
        document.body.removeChild(tempAnchor);
      } finally {
        this.setState({loading: false, loadingText: 'Loading...'});
      }
    };

    const headerMenuItemList = [
      {
        displayName: 'Export My Candidates',
        handler: exportMyCandidates,
      },
      {
        displayName: 'Export Current List',
        handler: exportFilteredCandidates,
      },
    ];

    const rowMenuItemList = Object.keys(this.rowMenuActions).map((menu) => {
      return {displayName: menu};
    });

    // For the stats dashboard at the top of the page.
    const stats = [
      [
        {
          label: 'Active Candidates',
          value: this.props.stats.activeSeekers,
        },

        {
          label: 'Profiles Ever Published',
          value: this.props.stats.profilesEverPublished,
        },
        {
          label: 'Submitted First Application',
          value: this.props.stats.firstApplicationSubmitted,
        },
        {
          label: 'Connected',
          value: this.props.stats.connectedSeekers,
        },
      ],
      [
        {
          label: 'Active Candidates',
          value: this.props.myStats.activeSeekers,
        },

        {
          label: 'Profiles Ever Published',
          value: this.props.myStats.profilesEverPublished,
        },
        {
          label: 'Submitted First Application',
          value: this.props.myStats.firstApplicationSubmitted,
        },
        {
          label: 'Connected',
          value: this.props.myStats.connectedSeekers,
        },
      ],
    ];

    const filterDropdowns = [
      {
        divClassName: `col-md-3 ps-3 pe-3`,
        label: 'Admin Approval Status',
        customProps: {
          options: [
            {value: 'null', label: 'Pending'},
            {value: 'true', label: 'Approved'},
            {value: 'false', label: 'Declined'},
          ],
          name: 'accepted',
          className: 'dropdownText',
          value: this.filters().multiSelect.accepted,
          overrideStrings: {selectSomeItems: 'Admin Approval'},
        },
      },
      {
        divClassName: `col-md-3 ps-3 pe-3`,
        label: 'School Approval Status',
        customProps: {
          options: [
            {value: 'null', label: 'Pending'},
            {value: 'true', label: 'Approved'},
            {value: 'false', label: 'Declined'},
          ],
          name: 'isSchoolApproved',
          className: 'dropdownText',
          value: this.filters().multiSelect.isSchoolApproved,
          overrideStrings: {selectSomeItems: 'School Approval'},
        },
      },
      {
        divClassName: `col-md-3 ps-3 pe-3`,
        label: 'High School',
        customProps: {
          options: this.state.highSchools.map((x) => ({
            ...x,
            value: x.id,
            label: x.name,
          })),
          name: 'highSchoolIds',
          className: 'dropdownText',
          value: this.filters().multiSelect.highSchoolIds,
          overrideStrings: {selectSomeItems: 'High School'},
        },
      },
    ];

    const filterButtons = [
      {
        id: 'btnMine',
        buttonText: 'Mine',
        buttonSvg: (
          <SVG.CheckSvg
            style={{marginLeft: '5px'}}
            alt=""
            className="svg_color_fill_orange"
          />
        ),
        action: this.handleFilterButton,
        buttonAttr: `recruiterIds=${this.props.ascendRecruiterId}`,
        enabled: true,
      },
      {
        id: 'btnUnpublished',
        buttonText: 'Unpublished',
        buttonSvg: (
          <SVG.CloseSvg
            alt=""
            style={{marginLeft: '5px'}}
            className="svg_color_fill_orange"
          />
        ),
        action: this.handleFilterButton,
        buttonAttr: 'profileStatusIds=2,3,4,7,8,11',
        enabled: this.state.selectedTab === CandidateAdminTabs.ACTIVE,
      },
      {
        id: 'btnNoinvitations',
        buttonText: 'No Invitations',
        buttonSvg: (
          <SVG.InvitationQuickFilterSvg
            alt=""
            style={{marginLeft: '5px'}}
            className="invitation_svg_color_stroke_orange"
          />
        ),
        action: this.handleFilterButton,
        buttonAttr: 'invitationMatchCount=0',
        enabled: true,
      },
      {
        id: 'btnNoApplications',
        buttonText: 'No Applications',
        buttonSvg: (
          <SVG.StopCircleSvg
            alt=""
            style={{marginLeft: '5px'}}
            className="svg_color_fill_orange"
          />
        ),
        action: this.handleFilterButton,
        buttonAttr: 'applicationCount=0',
        enabled: true,
      },
    ];

    const highLightRow = (row) => {
      if (row?.seekerSupportFlag) {
        return true;
      }
      return false;
    };

    const searchTabList = [
      {
        eventKey: CandidateAdminTabs.ACTIVE,
        show: true,
        title: (
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              fontWeight: 'bold',
            }}
          >
            <div>Active Candidates</div>
            <div className="ps-3">
              {this.props.activeTabCountLoading ? (
                <span
                  className={'align-middle spinner-border spinner-border-sm'}
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>{this.props.activeSeekerCount}</>
              )}
            </div>
          </div>
        ),
      },
      {
        eventKey: CandidateAdminTabs.MA_APPROVALS,
        show:
          this.props.profile.isApprenticeProgram ||
          this.props.profile.securityRoleNames.includes('ADMIN'),
        title: (
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            Manage Student Approvals
            <span className="ps-3">
              {this.props.manageStudentApprovalsCountLoading ? (
                <>
                  <span
                    className={'align-middle spinner-border spinner-border-sm'}
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                <>{this.props.manageStudentApprovalsCount}</>
              )}
            </span>
          </span>
        ),
      },

      {
        eventKey: CandidateAdminTabs.TASKS,
        show: true,
        title: (
          <strong>
            Tasks to Complete &nbsp;&nbsp;&nbsp;&nbsp;{this.props.taskCount}
          </strong>
        ),
      },
      {
        eventKey: CandidateAdminTabs.INACTIVE,
        show: true,
        title: (
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            Inactive Candidates
            <span className="ps-3">
              {this.props.inactiveTabCountLoading ? (
                <>
                  <span
                    className={'align-middle spinner-border spinner-border-sm'}
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                <>{this.props.inactiveSeekerCount}</>
              )}
            </span>
          </span>
        ),
      },
      {
        eventKey: CandidateAdminTabs.APPROVALS,
        show:
          this.props.profile.showAdminApprovalTab ||
          this.props.profile.securityRoleNames.includes('ADMIN'),
        title: (
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            Admin Approvals
            <span className="ps-3">
              {this.props.manageAdminApprovalsCountLoading ? (
                <>
                  <span
                    className={'align-middle spinner-border spinner-border-sm'}
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                <>{this.props.manageAdminApprovalsCount}</>
              )}
            </span>
          </span>
        ),
      },
    ];

    const buildRecruiterList = () => {
      return this.props.ascendRecruiters.map((x) => {
        return {id: x.id, label: x.name};
      });
    };
    const filterList = [{id: -1, label: 'Mine'}, ...buildRecruiterList()];

    return (
      <LoadingOverlay
        role="status"
        active={this.state.loading}
        spinner={true}
        text={this.state.loadingText}
        styles={{
          overlay: (base) => ({
            ...base,
            position: 'fixed',
          }),
        }}
      >
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          clickable={true}
          delayHide={200}
          closeOnEsc={true}
        />
        {this.state.showAssignManager && (
          <CandidateAssignManager
            displayAssignManagerModalVisible={this.state.showAssignManager}
            seeker={this.state.selectedSeeker}
            onClose={() => this.setState({showAssignManager: false})}
          />
        )}

        <div className="wholepage">
          <DocumentTitle title="Candidates Admin">
            <PageHeader title="Administration" showAdminLinks={true} />
          </DocumentTitle>

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                {this.state.assignFormOpen && (
                  <AssignForm
                    isOpen={this.state.assignFormOpen}
                    ascendRecruiterId={this.state.ascendRecruiterId}
                    ascendRecruiters={this.props.ascendRecruiters}
                    profile={this.props.profile}
                    selectedItem={this.state.selectedSeeker}
                    onClose={() => this.setState({assignFormOpen: false})}
                    onSubmit={this.handleAssignSubmit}
                  />
                )}
                <ApplyForm
                  isOpen={this.state.applyFormOpen}
                  seeker={this.props.seekerForApply}
                  seekerId={this.state.seekerId}
                  seekerName={this.state.seekerName}
                  onClose={() => this.setState({applyFormOpen: false})}
                  onSubmit={this.handleApplySubmit}
                />

                <CreateConnectionModal
                  isOpen={this.state.reportConnectionFormOpen}
                  seeker={this.state.seekerForConnection}
                  onClose={() =>
                    this.setState({reportConnectionFormOpen: false})
                  }
                />

                <AreYouSureModal
                  areYouSureText="Are you sure that you want to remove this seeker?"
                  isOpen={this.state.areYouSureModalIsOpen}
                  onClose={this.handleAreYouSureClose}
                  onYes={this.handleDoRemove}
                />
                <NotesModal
                  isOpen={this.state.notesModalIsOpen}
                  onClose={() => {
                    this.setState({notesModalIsOpen: false});
                  }}
                  seeker={this.state.notesSeeker}
                  key={this.state.notesSeeker.id}
                  onNoteAdded={this.handleNoteAdded}
                  onNoteRemoved={this.handleNoteRemoved}
                />
                <AuthorizationModal
                  isOpen={this.state.authorizationModalIsOpen}
                  onClose={() => {
                    this.setState({authorizationModalIsOpen: false});
                  }}
                  seeker={this.state.selectedSeeker}
                />

                <ChangeEmailModal
                  isOpen={this.state.changeEmailModalIsOpen}
                  onClose={() => {
                    this.setState({changeEmailModalIsOpen: false});
                  }}
                  onCancel={() => {
                    this.setState({changeEmailModalIsOpen: false});
                  }}
                  isThirdPartyEmailChange={true}
                />

                <br />
                <div className="padding-bottom-twenty ">
                  <NewStatsDashboard
                    stats={stats[0]}
                    title="All Candidates Dashboard"
                    dateSetHandler={this.props.getSeekersStats}
                    statsLoading={this.props.statsLoading}
                  />

                  <NewStatsDashboard
                    stats={stats[1]}
                    title="Individual Dashboard"
                    dateSetHandler={this.getStatsByRecruiterId}
                    statsLoading={this.props.myStatsLoading}
                    pullDownList={filterList}
                    pullDownDefaultValue={filterList[0]}
                    pullDownValue={filterList.find(
                      (flItem) => flItem.id === this.state.recruiterId
                    )}
                    pullDownOnChange={(x) => this.handleRecruiterChange(x)}
                    pullDownWidth={200}
                  />
                </div>
              </div>
              <div className="container-fluid">
                {!this.props.seekersLoading &&
                  !this.state.canViewCandidatePII && (
                    <h2>You do not have permission to view Candidate PII</h2>
                  )}
                {this.state.canViewCandidatePII && (
                  <div>
                    <h2 className="normal-headline bold padding-bottom-eight">
                      Candidates Admin
                    </h2>
                    <SearchTab
                      tabData={searchTabList}
                      filterButtons={filterButtons}
                      filterButtonAction={this.handleFilterButton}
                      showSearchTab={
                        this.state.selectedTab !== CandidateAdminTabs.TASKS
                      }
                      searchHelperText={'Search by Name or Email'}
                      handleOnSelect={this.handleTabChange}
                      handleClearAll={this.handleClearAll}
                      selectedMultiSelectFilters={this.getMultiSelectQueryStringArray()}
                      useDropdownPanel={
                        this.state.selectedTab ===
                        CandidateAdminTabs.MA_APPROVALS
                      }
                      filterDropdowns={filterDropdowns}
                      itemsLoading={this.props.seekersLoading}
                      showFilter={
                        this.state.selectedTab !== CandidateAdminTabs.APPROVALS
                      }
                    />
                    {this.state.selectedTab !== CandidateAdminTabs.TASKS && (
                      <AscendDataTable
                        rowMenuItemList={rowMenuItemList}
                        rowMenuItemListFilter={this.rowMenuItemListFilter}
                        headerMenuItemList={headerMenuItemList}
                        tableColumns={
                          this.state.selectedTab ===
                          CandidateAdminTabs.MA_APPROVALS
                            ? maApprovalTableColumns
                            : this.state.selectedTab ===
                              CandidateAdminTabs.APPROVALS
                            ? adminApprovalTableColumns
                            : tableColumns
                        }
                        items={this.props.seekers}
                        handlerFunction={this.handlerFunction}
                        currentPage={this.props.currentPage}
                        itemsPerPage={this.props.itemsPerPage}
                        showSearch={false}
                        sortBy={this.props.sortBy}
                        sortAscending={this.props.sortAscending}
                        totalItems={this.selectTotalItems()}
                        handlePageChange={this.handlePageChange}
                        handleSortChange={this.handleSortChange}
                        itemsLoading={this.props.seekersLoading}
                        isRowHighlighted={highLightRow}
                        noDataMessage="No Results. Check to see if filters are applied and spelling is correct."
                      />
                    )}
                    {this.state.selectedTab === CandidateAdminTabs.TASKS && (
                      <div className="container">
                        <div className="row">
                          <div className="col-12">&nbsp;</div>
                        </div>
                        <div className="row">
                          <RequiresAttention />
                        </div>
                      </div>
                    )}
                    <ReportsTable
                      title="Candidate Reports"
                      reports={this.props.reports}
                    />
                  </div>
                )}
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </LoadingOverlay>
    );
  }
}

CandidateListPage.propTypes = {
  history: PropTypes.object.isRequired,
  seekers: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  myStats: PropTypes.object.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,

  updateSortTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  activeSeekerCount: PropTypes.number.isRequired,
  inactiveSeekerCount: PropTypes.number.isRequired,
  manageStudentApprovalsCount: PropTypes.number.isRequired,
  activeTabCountLoading: PropTypes.bool.isRequired,
  inactiveTabCountLoading: PropTypes.bool.isRequired,
  manageStudentApprovalsCountLoading: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number,
  seekersLoading: PropTypes.bool.isRequired,
  ascendRecruiters: PropTypes.array.isRequired,
  getAscendRecruiters: PropTypes.func.isRequired,
  getSeeker: PropTypes.func.isRequired,
  getSeekers: PropTypes.func.isRequired,
  getLightSeekersAdvanced: PropTypes.func.isRequired,
  getSeekerCountForFilters: PropTypes.func.isRequired,
  getSeekersStats: PropTypes.func.isRequired,
  getMySeekersStats: PropTypes.func.isRequired,
  updateSeeker: PropTypes.func.isRequired,
  deleteSeeker: PropTypes.func.isRequired,
  createJobAppliedBySeeker: PropTypes.func.isRequired,
  seekerForApply: PropTypes.object,
  reports: PropTypes.array.isRequired,
  getSeekerReports: PropTypes.func.isRequired,
  updateSeekerAction: PropTypes.func.isRequired,
  seekerApprovalChanged: PropTypes.func.isRequired,
  seekerAdminApprovalChanged: PropTypes.func.isRequired,
  saveCandidateClearFilters: PropTypes.func,
  saveCandidateSelectedTab: PropTypes.func,
  saveCandidateSearchFilters: PropTypes.func,
  saveCandidateToggleButtonFilters: PropTypes.func,
  saveCandidateAllFilters: PropTypes.func,
  ascendRecruiterId: PropTypes.number,
  tasks: PropTypes.array,
  incrementSeekersListNoteCount: PropTypes.func.isRequired,
  decrementSeekersListNoteCount: PropTypes.func.isRequired,
  toggleSeekerSupportFlag: PropTypes.func.isRequired,
  handleNotesClicked: PropTypes.func,
  handleDeactivateProfile: PropTypes.func,
  handleTabChange: PropTypes.func,
  active: PropTypes.object,
  inactive: PropTypes.object,
  approvals: PropTypes.object,
  statsLoading: PropTypes.bool.isRequired,
  myStatsLoading: PropTypes.bool.isRequired,
  profileStatuses: PropTypes.array.isRequired,
  getProfileStatuses: PropTypes.func.isRequired,
  segments: PropTypes.array.isRequired,
  getSegments: PropTypes.func.isRequired,
  getTaskCount: PropTypes.func.isRequired,
  taskCount: PropTypes.number.isRequired,
  taskCountsLoading: PropTypes.bool.isRequired,
  candidateListFilterActive: PropTypes.bool,
  setCandidateListFilterActive: PropTypes.func.isRequired,
  manageAdminApprovalsCountLoading: PropTypes.bool,
  manageAdminApprovalsCount: PropTypes.number,
  updateChangeEmailUser: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    seekers: state.seekers.seekers,
    stats: state.seekers.stats,
    activeSeekerCount: state.seekers.activeSeekerCount,
    inactiveSeekerCount: state.seekers.inactiveSeekerCount,
    candidateListFilterActive: state.seekers.candidateListFilterActive,
    manageStudentApprovalsCount: state.seekers.manageStudentApprovalsCount,
    activeTabCountLoading: state.seekers.activeTabCountLoading,
    tabCountsInitialized: state.seekers.tabCountsInitialized,
    inactiveTabCountLoading: state.seekers.inactiveTabCountLoading,
    manageStudentApprovalsCountLoading:
      state.seekers.manageStudentApprovalsCountLoading,
    myStats: state.seekers.myStats,
    ascendRecruiters: state.ascendRecruiters.ascendRecruiters,
    page: state.seekers.seekersPage,
    itemsPerPage: state.seekers.seekersItemsPerPage,
    seekersLoading: state.seekers.seekersLoading,
    currentPage: state.candidatesDataTable.currentPage,
    sortBy: state.candidatesDataTable.sortBy,
    sortAscending: state.candidatesDataTable.sortAscending,
    seekerForApply: state.seekers.seeker,
    reports: state.seekers.reports,
    ascendRecruiterId: state.profile.ascendRecruiterId,
    tasks: state.tasksReducer.tasks,
    statsLoading: state.seekers.statsLoading,
    myStatsLoading: state.seekers.myStatsLoading,
    profileStatuses: state.profileStatuses.profileStatuses,
    segments: state.segments.segmentList,
    taskCount: state.tasksReducer.count,
    taskCountsLoading: state.tasksReducer.taskCountsLoading,
    manageAdminApprovalsCountLoading:
      state.seekers.manageAdminApprovalsCountLoading,
    manageAdminApprovalsCount: state.seekers.manageAdminApprovalsCount,

    ...state.candidateFilters,
  };
}

const mapDispatchToProps = {
  ...seekersActions,
  ...ascendRecruitersActions,
  ...usersActions,
  ...tableActions,
  ...jobsActions,
  updateSeekerAction,
  getProfileStatuses,
  getSegments,
  seekerApprovalChanged,
  seekerAdminApprovalChanged,
  getTaskCount,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateListPage)
);
