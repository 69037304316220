import React from 'react';
import PropTypes from 'prop-types';
import {shortUrl} from '../../utils/miscHelper';
import {fixUrl} from '../../utils/fieldHelper';
import emailSvg from '../../assets/images/Email.svg';

const renderRow = (title, content) => {
  return (
    <div className="row" style={{paddingBottom: '16px'}}>
      <div className="col-md-12">
        <div className="normal-caption bold" style={{paddingBottom: '8px'}}>
          <h4>{title}</h4>
        </div>
        <div className="disabled-subheader">{content || 'Not selected'}</div>
      </div>
    </div>
  );
};

class TeamDetails extends React.Component {
  render() {
    const {team} = this.props;
    return (
      <div>
        <div className="normal-caption bold padding-bottom-sixteen">
          <h3>TEAM DETAILS</h3>
        </div>
        {renderRow(
          'Role Type(s)',
          team.roleTypes.map((x) => x.name).join(', ')
        )}
        {renderRow('Industries', team.industries.map((x) => x.name).join(', '))}
        {renderRow('Regions', team.regions.map((x) => x.name).join(', '))}
        <h4>
          <div className="normal-caption bold" style={{paddingBottom: '8px'}}>
            Contact
          </div>
        </h4>
        <div className="disabled-subheader extraspace nowrap">
          <i className="nc-icon-glyph users_multiple" />
          &nbsp;&nbsp;{team.teamSize} Employees
        </div>
        <div className="disabled-subheader extraspace nowrap">
          <i className="nc-icon-glyph globe" />
          &nbsp;&nbsp;
          <a
            className="clickable active-subheader accessibleLinkHover"
            href={fixUrl(team.url)}
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: 'underline'}}
          >
            {shortUrl(team.url, 30)}
          </a>
        </div>
        <div className="disabled-subheader extraspace nowrap">
          <img alt="" src={emailSvg} />
          &nbsp;&nbsp;{team.details}
        </div>
        <div className="disabled-subheader extraspace nowrap">
          <i className="nc-icon-glyph pin" />
          &nbsp;&nbsp;{team.location}
        </div>
      </div>
    );
  }
}

TeamDetails.propTypes = {
  team: PropTypes.object.isRequired,
};

export default TeamDetails;
