import * as types from '../actions/ActionTypes';

const initialState = {
  invitations: [],
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  invitationsLoading: false,
  appliedCount: 0,
  reports: [],
  duplicateInvitations: [],
};

export default function invitations(state = initialState, action) {
  switch (action.type) {
    case types.INVITATIONS__GET_ALL_REQUEST:
      return {
        ...state,
        invitations: [],
        invitationsLoading: true,
      };
    case types.INVITATIONS__GET_ALL_SUCCESS:
      return {
        ...state,
        invitations: action.response.body.invitations,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        appliedCount: action.response.body.appliedCount,
        invitationsLoading: false,
      };
    case types.INVITATIONS__CREATE_SUCCESS:
      return {
        ...state,
        invitations: [...state.invitations, action.response.body],
      };
    case types.MULTIPLE_INVITATIONS__CREATE_SUCCESS:
      return {
        ...state,
        duplicateInvitations: action.response.body.duplicateInvitations,
      };
    case types.INVITATIONS__GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.response.body.reports,
      };
    default:
      return state;
  }
}
