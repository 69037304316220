import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import track from 'react-tracking';

import {actions as authActions} from 'src/reducers/authentication';

import LoadingPage from 'src/pages/LoadingPage';
import EulaModal from './EulaModal';
import ParentalConsentModal from './ParentalConsentModal';
import {DeactivatedUserPage, IneligibleUserPage, Login} from 'src/pages';
import ConsentAndAuthorizationModal from './ConsentAndAuthorizationModal';

export const MAP_URLS = [
  '/ma/indy',
  '/ma/uplands',
  '/ma/evansville',
  '/ma/hamilton',
  '/map/indy',
  '/map/uplands',
  '/map/evansville',
  '/map/hamilton',
];

export const MA_INDY_SEGMENT_ID = 3;
export const EMPLOY_INDY_SEGMENT_ID = 2;

const AuthorizedComponent = (allowedRoles) => (WrappedComponent) => {
  @track({})
  class WithAuthorization extends React.Component {
    _isMounted = false;
    state = {
      persistor: window.$persistor,
    };

    shouldComponentUpdate(nextProps, nextState) {
      const {sessionStarting} = this.props;

      if (sessionStarting) {
        return false;
      }
      return true;
    }

    componentDidMount() {
      this._isMounted = true;
      const {startSession, router, sessionStarting} = this.props;
      const {location} = router;

      if (!sessionStarting) {
        const path = location.pathname + location.search;
        startSession(path, true);
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    isOnBoarding = () => {
      const {profile, router} = this.props;
      const {location} = router;
      return (
        profile.role === 'JOB_SEEKER' &&
        (!profile.onboarded || !profile.reonboarded) &&
        location.pathname === '/onboard'
      );
    };

    isMapUrl = (url) => {
      return MAP_URLS.includes(url);
    };

    getMAParentalConsentModal = () => {
      const {profile} = this.props;
      if (this.showConsentAndAuthorization()) {
        return <ConsentAndAuthorizationModal />;
      } else if (this.showParentalConsent()) {
        return <ParentalConsentModal isOpen={true} userId={profile.id} />;
      }
      return null;
    };

    shouldRedirectToIntake = () => {
      const {profile, router} = this.props;
      const {location} = router;
      return (
        profile.role === 'JOB_SEEKER' &&
        location.pathname !== '/intake' &&
        location.pathname !== '/signup' &&
        !this.isMapUrl(location.pathname) &&
        !profile.intakeCompleted &&
        !(profile.isApprenticeProgram || false)
      );
    };

    showEULA = () => {
      const {profile} = this.props;
      if (profile.seekerId !== null && profile.isApprenticeProgram === true) {
        return false;
      }

      return (
        (profile.termsAccepted === null || !profile.termsAccepted) &&
        !this.isOnBoarding() &&
        window.location.pathname !== '/intake' &&
        window.location.pathname !== '/signup' &&
        !this.isMapUrl(window.location.pathname) &&
        window.location.pathname !== '/'
      );
    };

    showParentalConsent = () => {
      const {profile} = this.props;
      if (profile.seekerId === null) {
        return false;
      }
      return (
        (profile.termsAccepted === null || !profile.termsAccepted) &&
        !this.isOnBoarding() &&
        window.location.pathname !== '/intake' &&
        window.location.pathname !== '/signup' &&
        !this.isMapUrl(window.location.pathname) &&
        window.location.pathname !== '/' &&
        profile.isApprenticeProgram
      );
    };

    showConsentAndAuthorization = () => {
      const {profile} = this.props;
      return (
        profile.segmentIds.includes(MA_INDY_SEGMENT_ID) &&
        this.showParentalConsent()
      );
    };

    render() {
      const {profile} = this.props;

      // Turn off Redux-persist for everyone except seekers
      const {persistor} = this.state;
      if (
        profile.role === 'COMPANY_RECRUITER' ||
        profile.role === 'COMPANY_RECRUITER_ADMIN' ||
        (profile.role === 'ASCEND_RECRUITER' && this._isMounted)
      ) {
        persistor.purge();
        persistor.pause();
      }

      if (window.location.pathname === '/login') {
        return <Login />;
      }
      // Still waiting for profile to return from api call...
      if (!profile.isLoaded) {
        return (
          <LoadingPage
            title="Loading Profile"
            message="Your profile is being loaded."
          />
        );
      }

      if (profile.role === 'JOB_SEEKER' && profile.offboarded) {
        return <DeactivatedUserPage />;
      }

      if (this.shouldRedirectToIntake()) {
        this.props.redirectByRole(profile);
      }

      if (allowedRoles.includes(profile.role)) {
        if (
          profile.segmentIds.includes(MA_INDY_SEGMENT_ID) &&
          profile.workStatusAllowed === false &&
          profile.role === 'JOB_SEEKER'
        ) {
          return <IneligibleUserPage />;
        }

        // User's role is authorized, render wrapped component.
        return (
          <>
            {this.getMAParentalConsentModal()}
            {this.showEULA() && <EulaModal isOpen={true} userId={profile.id} />}
            <WrappedComponent {...this.props} />
          </>
        );
      }

      // The user's role is not authorized for this component.
      return (
        <LoadingPage
          title="Redirecting home."
          message="Redirecting to your browser to your home page."
        />
      );
    }

    static propTypes = {
      profile: PropTypes.object.isRequired,
      router: PropTypes.object.isRequired,
      redirectByRole: PropTypes.func.isRequired,
      startSession: PropTypes.func,
      sessionStarting: PropTypes.bool.isRequired,
    };
  }

  const mapStateToProps = (state) => {
    return {
      profile: state.profile,
      router: state.router,
      sessionStarting: state.authentication?.sessionStarting || false,
    };
  };

  const mapDispatchToProps = {
    ...authActions,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthorization);
};

export default AuthorizedComponent;
