import React from 'react';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {SingleDatePickerField} from 'src/formFields';
import NumberField from 'src/formFields/NumberField';
import {isValidPostalCode} from 'src/utils/fieldHelper';

export default function CreateConnectionAdditionalInformation(props) {
  const {onChange, onSubmit, connection} = props;

  const validatePostalCode = (value) => {
    if (!isValidPostalCode(value)) {
      return 'If entered, must be a 5 or 9 digits';
    }
    return null;
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => {
        return (
          <form>
            <OnChange name="startingSalary">
              {(newValue) => {
                onChange({
                  startingSalary: newValue,
                });
              }}
            </OnChange>
            <OnChange name="homePostalCode">
              {(newValue) => {
                onChange({
                  homePostalCode: newValue,
                });
              }}
            </OnChange>
            <OnChange name="startDate">
              {(newValue) => {
                onChange({
                  startDate: newValue,
                });
              }}
            </OnChange>

            <div className="mb-4">
              <label className="secondary-body">Start date (optional)</label>

              <Field
                name="startDate"
                component={SingleDatePickerField}
                isClearable={true}
                initialValue={connection.startDate}
                placeholder={''}
              />
            </div>

            <Field
              name="startingSalary"
              component={NumberField}
              label="Starting salary (optional)"
              initialValue={connection.startingSalary}
            />

            <Field
              name="homePostalCode"
              component={NumberField}
              label="Seeker home zip code (optional)"
              initialValue={connection.homePostalCode}
              validate={validatePostalCode}
            />

            <label className="normal-body">
              Would the seeker be willing to write a success story?
              <div className="row ms-2 mt-2">
                <label className="radio normal-body">
                  Yes
                  <Field
                    name="willingToShareSuccessStory"
                    component="input"
                    type="radio"
                    value={true}
                    checked={connection.willingToShareSuccessStory === true}
                    onChange={() =>
                      onChange({willingToShareSuccessStory: true})
                    }
                    autoFocus
                  />
                  <span className="radio-checkmark-orange" />
                </label>

                <label className="radio normal-body">
                  No
                  <Field
                    name="willingToShareSuccessStory"
                    component="input"
                    type="radio"
                    value={false}
                    checked={connection.willingToShareSuccessStory === false}
                    onChange={() =>
                      onChange({willingToShareSuccessStory: false})
                    }
                  />
                  <span className="radio-checkmark-orange" />
                </label>
              </div>
            </label>
          </form>
        );
      }}
    </Form>
  );
}

CreateConnectionAdditionalInformation.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  connection: PropTypes.object,
};
