import * as types from '../actions/ActionTypes';

import * as connectionConstants from 'src/pages/admin/connections/CreateConnectionConstants';

const initialState = {
  connections: [],
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  connectionsLoading: false,
  appliedCount: 0,
  reports: [],
  connectionsSaving: null,
  connectionSaveFailed: null,
  percentageComplete: 0,
  currentStep: null,
  connection: {
    seekerConnectionId: null,
    isNetworkConnection: null,
    seeker: null,
    seekerDisplayName: '',
    role: {
      employerName: '',
      jobName: '',
      employmentType: null,
      isRemote: false,
      city: '',
      state: null,
      zipCode: '',
      jobId: null,
      employerId: null,
      selectionType: connectionConstants.APPLIEDTOSELECT,
      jobZipCode: '',
    },
    roleAttributed: {
      isAttributed: null,
      label: '',
      otherText: '',
      requiresOther: false,
    },
    seekerNotes: null,
    notesViewed: false,
    notes: null,
    startDate: null,
    startingSalary: null,
    homePostalCode: '',
    willingToShareSuccessStory: null,
  },

  connectionReviewLoading: false,
  recentConnectionLoading: false,
  recentConnection: null,
};

export default function connections(state = initialState, action) {
  switch (action.type) {
    case types.CONNECTIONS__GET_ALL_REQUEST:
      return {
        ...state,
        connections: [],
        connectionsLoading: true,
      };
    case types.CONNECTIONS__GET_ALL_SUCCESS:
      return {
        ...state,
        connections: action.response.body.connections,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        appliedCount: action.response.body.appliedCount,
        connectionsLoading: false,
      };

    case types.CONNECTIONS__GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.response.body.reports,
      };
    case types.SEEKERS__INCREMENT_NOTE_COUNT:
      return {
        ...state,
        connections: state.connections.map((existingConnection) => {
          if (Object.keys(existingConnection.seeker).length === 0) {
            return existingConnection;
          }

          return {
            ...existingConnection,
            seeker: {
              ...existingConnection.seeker,
              noteCount:
                existingConnection.seeker.id === action.seekerId
                  ? existingConnection.seeker.noteCount + 1
                  : existingConnection.seeker.noteCount,
            },
          };
        }),
      };
    case types.SEEKERS__DECREMENT_NOTE_COUNT:
      return {
        ...state,
        connections: state.connections.map((existingConnection) => {
          if (Object.keys(existingConnection.seeker).length === 0) {
            return existingConnection;
          }
          return {
            ...existingConnection,
            seeker: {
              ...existingConnection.seeker,
              noteCount:
                existingConnection.seeker.id === action.seekerId
                  ? existingConnection.seeker.noteCount - 1
                  : existingConnection.seeker.noteCount,
            },
          };
        }),
      };

    case types.CONNECTIONS__CREATE_REQUEST:
      return {
        ...state,
        connectionsSaving: true,
        connectionSaveFailed: false,
      };

    case types.CONNECTIONS__CREATE_SUCCESS:
      return {
        ...state,
        connectionsSaving: false,
        connectionSaveFailed: false,
        connections: [...state.connections, action.response.body],
      };

    case types.CONNECTIONS__CREATE_FAILURE:
      return {
        ...state,
        connectionsSaving: false,
        connectionSaveFailed: true,
      };

    case types.CONNECTIONS__CREATE_RESET:
      return {
        ...state,
        connectionsSaving: null,
        connectionSaveFailed: null,
        percentageComplete: initialState.percentageComplete,
        connection: initialState.connection,
        currentStep: action.step,
      };

    case types.CONNECTIONS__UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step,
        percentageComplete: action.percentageComplete,
      };

    case types.CONNECTIONS__UPDATE_STATE:
      let updatedConnection = {...state.connection};

      Object.entries(action.data).forEach(([key, value]) => {
        updatedConnection[key] = value;
      });

      if (updatedConnection.seeker) {
        updatedConnection['seekerDisplayName'] = updatedConnection.seeker.name
          ? updatedConnection.seeker.name
          : `${updatedConnection.seeker.firstName} ${updatedConnection.seeker.lastName}`;
      }

      return {
        ...state,
        connection: updatedConnection,
      };

    case types.CONNECTIONS__SEEKER_CREATE_REQUEST:
      return {
        ...state,
        connectionsSaving: true,
        connectionSaveFailed: false,
      };

    case types.CONNECTIONS__SEEKER_CREATE_SUCCESS:
      return {
        ...state,
        connectionsSaving: false,
        connectionSaveFailed: false,
        connections: [...state.connections, action.response.body],
      };

    case types.CONNECTIONS__SEEKER_CREATE_FAILURE:
      return {
        ...state,
        connectionsSaving: false,
        connectionSaveFailed: true,
      };

    case types.CONNECTIONS__SEEKER_GET_REQUEST:
      return {
        ...state,
        connectionReviewLoading: true,
      };

    case types.CONNECTIONS__SEEKER_GET_SUCCESS:
      const resp = action.response.body;

      let seekerConnection = {
        ...state.connection,
        seekerConnectionId: resp.id,
        isNetworkConnection: resp.networkJob,
        seeker: {
          seekerId: resp.seekerId,
          name: resp.seekerName,
        },
        seekerDisplayName: resp.seekerName,
        role: {
          employerName: resp.employerId ? '' : resp.employerName,
          jobName: resp.jobId ? '' : resp.jobName,
          employmentType: {
            label: resp.employmentTypeLabel,
            value: resp.employmentTypeId,
          },
          isRemote: resp.locationDetails.isRemote,
          city: resp.locationDetails.city,
          state: resp.locationDetails.state,
          zipCode: resp.locationDetails.zip,
          jobId: resp.jobId,
          employerId: resp.employerId,
          selectionType: resp.jobId
            ? connectionConstants.JOBSEARCH
            : connectionConstants.MANUAL,
        },
        roleAttributed: {
          isAttributed: resp.isAttributedToNetwork,
          label: !resp.isAttributedToNetwork
            ? connectionConstants.NO_DONOTATTRIBUTE
            : resp.jobId
            ? connectionConstants.YES_FOUNDROLEONNETWORK
            : connectionConstants.YES_GUIDANCEFROMMENTOR,
          requiresOther: false,
          otherText: null,
        },
        seekerNotes: resp.notes,
        notesViewed: false,
      };

      return {
        ...state,
        connectionReviewLoading: false,
        connection: seekerConnection,
      };

    case types.CONNECTIONS__SEEKER_GET_FAILURE:
      return {
        ...state,
        connectionReviewLoading: false,
      };

    case types.CONNECTIONS__RECENT_CONNECTION_GET_REQUEST:
      return {
        ...state,
        recentConnectionLoading: true,
        recentConnection: null,
      };
    case types.CONNECTIONS__RECENT_CONNECTION_GET_SUCCESS:
      const hasRecentConnection =
        Object.keys(action.response.body.recentConnection).length > 0;

      return {
        ...state,
        recentConnectionLoading: false,
        recentConnection: hasRecentConnection
          ? action.response.body.recentConnection
          : null,
      };
    case types.CONNECTIONS__RECENT_CONNECTION_GET_FAILURE:
      return {
        ...state,
        recentConnectionLoading: false,
        recentConnection: null,
      };

    default:
      return state;
  }
}
