import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {updateSeekerInviteList} from 'src/actions/seekers';
import {AscendModal, AscendModalBody} from 'src/layout';
import {BusyButton} from './BusyButton';
import {AsyncPaginate} from 'react-select-async-paginate';
import {makeRequest} from 'src/services/api';
import {addFlashMessage} from 'src/actions/flash';
import {track as analyticsTrack} from 'src/utils/analytics';
import {createMultipleInvitations} from 'src/services/invitationApi';
import Select from 'react-select';
import Loading from './Loading';
import Cardinator from 'src/pages/teamProfile/Cardinator';
import InviteToApplySeekerSelect from './InviteToApplySeekerSelect';
import BusyButtonMini from './BusyButtonMini';

const ITEMS_PER_PAGE = 50;
const ITEMS_PER_PAGE_IS_MULTIPLE = 10;

export default function InviteToJobsModal({
  jobs,
  isOpen,
  onClose,
  onSendClicked,
}) {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.profile.id);
  const userSegments = useSelector((state) => state.profile.segmentIds);

  const experienceLevels = useSelector(
    (state) => state.experienceLevels.experienceLevels
  );
  const experienceLevelsLoading = useSelector(
    (state) => state.experienceLevels.experienceLevelsLoading
  );
  const employmentTypes = useSelector(
    (state) => state.employmentTypes.employmentTypes
  );
  const employmentTypesLoading = useSelector(
    (state) => state.employmentTypes.employmentTypesLoading
  );
  const degreeTypeRanks = useSelector(
    (state) => state.degreeTypeRank.degreeRank
  );
  const degreeRankLoading = useSelector(
    (state) => state.degreeTypeRank.degreeRankLoading
  );

  const [selectedSeekers, setSelectedSeekers] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [selectedExperienceLevel, setSelectedExperienceLevel] = useState(null);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState(null);
  const [selectedDegreeType, setSelectedDegreeType] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [seekers, setSeekers] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [seekersLoading, setSeekersLoading] = useState(false);

  const clearState = () => {
    setSelectedSeekers([]);
    setIsSending(false);
    setIsMultiple(false);
    setSelectedExperienceLevel(null);
    setSelectedEmploymentType(null);
    setSelectedDegreeType(null);
    setCurrentPage(0);
    setSeekers(null);
    setTotalItems(null);
    setSeekersLoading(false);
  };

  const handleClose = () => {
    clearState();
    onClose();
  };

  const handleSeekerSelection = (seeker) => {
    const oldList = selectedSeekers;
    if (isMultiple) {
      selectedSeekers.find((x) => x.id === seeker.id)
        ? setSelectedSeekers(selectedSeekers.filter((x) => x.id !== seeker.id))
        : setSelectedSeekers([...oldList, seeker]);
      return;
    }

    setSelectedSeekers([seeker]);
  };

  const asyncLoadSeekerOptions = async (search, prevOptions) => {
    const page = Math.floor(prevOptions.length / ITEMS_PER_PAGE);
    let url = `seekers?itemsPerPage=${ITEMS_PER_PAGE}&page=${page}`;

    if (userSegments.length > 0) {
      url += `&segmentIds=${userSegments.join(',')}`;
    }
    if (search) {
      url += `&searchTerm=${search}&searchFields=firstName,lastName`;
    }
    const seekerNames = await makeRequest({method: 'GET', path: url});
    const hasMore =
      seekerNames.data.totalItems > prevOptions.length + ITEMS_PER_PAGE;

    return {
      options: seekerNames.data.seekers.map((seeker) => ({
        ...seeker,
        label: `${seeker.firstName} ${seeker.lastName}`,
        value: seeker.id,
      })),
      hasMore,
    };
  };

  const handlePageChange = async (pageNumber) => {
    setSeekersLoading(true);
    setCurrentPage(pageNumber);
    let url = `seekers?itemsPerPage=${ITEMS_PER_PAGE_IS_MULTIPLE}&page=${pageNumber}&sort=+name&employmentTypeIds=${selectedEmploymentType.id}&experienceLevelIds=${selectedExperienceLevel.id}&degreeTypeRankIds=${selectedDegreeType.id}`;

    if (userSegments.length > 0) {
      url += `&segmentIds=${userSegments.join(',')}`;
    }
    const seekerNames = await makeRequest({method: 'GET', path: url});
    setSeekers(seekerNames.data.seekers);
    setTotalItems(seekerNames.data.totalItems);
    setSeekersLoading(false);
    return seekerNames.data.seekers;
  };

  const trackInvitation = (seekerId, job) => {
    analyticsTrack({
      eventName: 'InvitedToApply_Admin',
      seekerId: seekerId,
      jobEmploymentType: job.employmentTypes.map((x) => x.name).join(','),
    });
  };

  const handleSendClicked = async () => {
    const jobIds = [...new Set(jobs.map((x) => x.id))];
    const seekerIds = selectedSeekers.map((x) => x.id);
    if (jobIds.length === 0) {
      return;
    }
    setIsSending(true);

    const invitations = {
      userId: userId,
      seekerIds: seekerIds,
      jobIds: jobIds,
    };
    createMultipleInvitations(invitations)
      .then((x) => {
        dispatch(
          updateSeekerInviteList(
            jobs.map((x) => ({
              id: x,
              jobsInvitedToApplyFor: [],
            })),
            jobs,
            x.data.duplicateInvitations
          )
        );
        if (x.data.duplicateInvitations.length === 0) {
          let msg = 'Invitations sent!';
          if (invitations.seekerIds.length === 1) {
            msg = 'Invitation sent!';
          }

          invitations.seekerIds.forEach((seekerId) => {
            jobs.forEach((job) => {
              trackInvitation(seekerId, job);
            });
          });

          dispatch(addFlashMessage(msg, 'notification'));
        }
        clearState();
        onSendClicked(x.data.duplicateInvitations);
      })
      .catch((x) => {
        let msg = 'Error sending invites';
        if (invitations.seekerIds.length === 1) {
          msg = 'Error sending invite';
        }

        clearState();
        dispatch(addFlashMessage(msg, 'error'));
      });
  };

  const handleExperienceLevelChange = (experienceLevel) => {
    setSelectedExperienceLevel(experienceLevel);
  };

  const handleEmploymentTypeChange = (employmentType) => {
    setSelectedEmploymentType(employmentType);
  };

  const handleDegreeTypeChange = (degreeType) => {
    setSelectedDegreeType(degreeType);
  };

  const buildSeekerCard = (seeker) => {
    return (
      <div key={seeker.id}>
        <InviteToApplySeekerSelect
          onChange={handleSeekerSelection}
          selected={
            selectedSeekers.find((x) => x.id === seeker.id) !== undefined
          }
          seeker={seeker}
        />
      </div>
    );
  };

  return (
    <AscendModal isOpen={isOpen} onClose={handleClose}>
      <AscendModalBody hasClose={true}>
        <div className="padding-32">
          <div className="normal-headline text-align-center mb-4">
            Invite to Apply
          </div>
          {!isMultiple && (
            <>
              <div className="bd-whitebackground p-3">
                <div className=" secondary-body ">
                  Choose a job seeker from the list below
                </div>
                <div>
                  <AsyncPaginate
                    defaultOptions
                    debounceTimeout={200}
                    loadOptions={asyncLoadSeekerOptions}
                    onChange={handleSeekerSelection}
                    placeholder="Select..."
                  />
                </div>
              </div>
              <button
                className="btn buttonAsLink"
                onClick={() => setIsMultiple(true)}
              >
                Select multiple candidates
              </button>
            </>
          )}

          {isMultiple && (
            <>
              <div className="container mb-3">
                <div className="row">
                  <div className="col-md-6 middle padding-bottom-eight">
                    <div
                      className="secondary-body"
                      style={{lineHeight: '36px'}}
                      id="experienceLevelLabel"
                    >
                      EXPERIENCE LEVEL:
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="clickable normal-subheader"
                      onChange={handleExperienceLevelChange}
                      options={experienceLevels.map((x) => {
                        return {...x, label: x.years, value: x.id};
                      })}
                      isDisabled={experienceLevelsLoading}
                      isLoading={experienceLevelsLoading}
                      aria-labelledby="experienceLevelLabel"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 middle padding-bottom-eight">
                    <div
                      className="secondary-body"
                      style={{lineHeight: '36px'}}
                      id="employmentTypeLabel"
                    >
                      EMPLOYMENT TYPE:
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="clickable normal-subheader"
                      onChange={handleEmploymentTypeChange}
                      options={employmentTypes.map((x) => {
                        return {...x, label: x.name, value: x.id};
                      })}
                      isDisabled={
                        employmentTypesLoading ||
                        selectedExperienceLevel === null
                      }
                      isLoading={employmentTypesLoading}
                      aria-labelledby="employmentTypeLabel"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 middle padding-bottom-eight">
                    <div
                      className="secondary-body"
                      style={{lineHeight: '36px'}}
                      id="DegreeTypeLabel"
                    >
                      DEGREE TYPE:
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="clickable normal-subheader"
                      onChange={handleDegreeTypeChange}
                      options={degreeTypeRanks.map((x) => {
                        return {...x, label: x.name, value: x.id};
                      })}
                      isDisabled={
                        degreeRankLoading ||
                        selectedExperienceLevel === null ||
                        selectedEmploymentType === null
                      }
                      isLoading={degreeRankLoading}
                      aria-labelledby="degreeTypeLabel"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BusyButtonMini
                      onClick={() => {
                        handlePageChange(0);
                        setCurrentPage(0);
                      }}
                      buttonText={'Search'}
                      busyText={'Searching'}
                      style={{
                        width: '150px',
                        height: '40px',
                      }}
                      float={'float-right'}
                      alignText={'text-center'}
                      className="col-2 ms-2"
                      busy={seekersLoading}
                      disabled={
                        selectedDegreeType === null ||
                        selectedEmploymentType === null ||
                        selectedExperienceLevel === null
                      }
                    />
                  </div>
                </div>
              </div>

              {selectedSeekers.length !== 0 && (
                <div className="text-align-center mb-3">
                  <div className="secondary-title padding-bottom-twentyfour">
                    Selected seekers:
                  </div>

                  {selectedSeekers.map((seeker) => {
                    return <div className="normal-body">{seeker.name}</div>;
                  })}
                  <div className="divider" />
                </div>
              )}

              {(seekers || seekersLoading) && (
                <div className="bd-whitebackground p-3">
                  <div>
                    <div>
                      {seekersLoading && (
                        <span role="status">
                          <Loading />
                        </span>
                      )}

                      {seekers && seekers.length === 0 && !seekersLoading && (
                        <div
                          className="text-align-center"
                          style={{
                            width: '100%',
                          }}
                        >
                          <div className="secondary-title padding-bottom-twentyfour">
                            No Candidates found
                          </div>
                        </div>
                      )}

                      {seekersLoading === false &&
                        seekers &&
                        seekers?.length > 0 && (
                          <div>
                            <Cardinator
                              jobs={seekers}
                              currentPage={currentPage}
                              itemsPerPage={ITEMS_PER_PAGE_IS_MULTIPLE}
                              totalItems={totalItems}
                              itemsLoading={seekersLoading}
                              handlePageChange={handlePageChange}
                              cardObject={buildSeekerCard}
                              checkedJobs={selectedSeekers}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {((!isMultiple && selectedSeekers.length > 0) ||
            (isMultiple && seekers && seekers.length > 0)) && (
            <div
              className="padding-top-sixteen padding-bottom-sixteen"
              style={{margin: '0px auto'}}
            >
              <BusyButton
                onClick={() => {
                  handleSendClicked();
                }}
                buttonText={`Send Invites to ${jobs.length} Roles`}
                busyText={'Sending'}
                style={{width: 'fit-content', height: '40px'}}
                float={'float-right'}
                buttonIcon={'save'}
                alignText={'text-center'}
                disabled={
                  selectedSeekers.length < 1 || seekersLoading || isSending
                }
              />
            </div>
          )}
        </div>
      </AscendModalBody>
    </AscendModal>
  );
}

InviteToJobsModal.propTypes = {
  isOpen: PropTypes.bool,
  jobs: PropTypes.array,
  onClose: PropTypes.func,
  onSendClicked: PropTypes.func,
};
