import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import CandidateAvatar from './CandidateAvatar';
import CandidateName from './CandidateName';
import CandidateEducationText from './CandidateEducationText';
import CandidateMajorText from './CandidateMajorText';
import CandidateMinorText from './CandidateMinorText';

class CandidateInfo extends React.Component {
  render() {
    const {seeker} = this.props;
    const {isMaSegment} = this.props;
    const showEducation =
      seeker.segments && seeker.segments.some((x) => x.requireEducationData);
    return (
      <>
        <div className="col-2">
          <br />
          <CandidateAvatar seeker={seeker} />
        </div>
        <div className="col-md-9">
          <CandidateName seeker={seeker} />
          {showEducation && (
            <Fragment>
              <CandidateEducationText
                seeker={seeker}
                isMaSegment={isMaSegment}
              />
              {isMaSegment === false && <CandidateMajorText seeker={seeker} />}
              {isMaSegment === false && <CandidateMinorText seeker={seeker} />}
            </Fragment>
          )}
        </div>
        <div className="col-1">{this.props.actionIcons}</div>
      </>
    );
  }
}

CandidateInfo.propTypes = {
  seeker: PropTypes.object,
  actionIcons: PropTypes.node,
  isMaSegment: PropTypes.bool,
};

CandidateInfo.defaultProps = {
  seeker: {},
};

export default CandidateInfo;
