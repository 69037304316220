import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import * as flashActions from '../../../actions/flash';
import * as invitationCodeActions from 'src/actions/invitationCodes';
import {getSegments} from 'src/actions/SegmentActions';
import {AscendModal, AscendModalBody} from '../../../layout';
import {connect} from 'react-redux';

import {TextField, SelectField} from 'src/formFields';

class CodesForm extends React.Component {
  componentDidMount() {
    this.props.getSegments();
  }

  render() {
    const validate = (values) => {
      const errors = {};
      const requiredFields = ['label'];

      for (const field of requiredFields) {
        if (!values[field].trim()) {
          errors[field] = 'Required';
        }
      }

      return errors;
    };
    return (
      <AscendModal isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AscendModalBody hasClose={true} onClose={this.props.onClose}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            keepDirtyOnReinitialize={true}
            initialValues={this.props.initialValues}
          >
            {({handleSubmit}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <center>
                    <p className="normal-title bold">{this.props.headerText}</p>
                  </center>

                  <hr />

                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form inlineblock">
                        <div className="form-group">
                          <Field
                            name="label"
                            component={TextField}
                            label="Label"
                            maxLength={128}
                            labelBold={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form inlineblock">
                        <div className="form-group">
                          <Field
                            name="shortUrl"
                            component={TextField}
                            label="Short URL (Optional)"
                            maxLength={128}
                            labelBold={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form inlineblock">
                        <div className="form-group">
                          <Field
                            name="destinationPage"
                            component={TextField}
                            label="Destination Page (Optional)"
                            maxLength={128}
                            placeholder="Ex: /team/40"
                            labelBold={true}
                            labelSubText={
                              'Used to direct new job seekers to a specific team after signing up, rather than their home page.<br/>Must be formatted as: /team/[number]'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Field
                        name="segmentId"
                        component={SelectField}
                        label="Segment (Optional)"
                        width="100%"
                        options={this.props.segments.map((opt) => {
                          return {
                            value: opt.id,
                            label: opt.name,
                          };
                        })}
                        labelBold={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="clickable btn btn-primary border-0 float-right"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

CodesForm.propTypes = {
  isOpen: PropTypes.bool,
  invitationCode: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  deleteInvitationCode: PropTypes.func,
  segments: PropTypes.array.isRequired,
  getSegments: PropTypes.func.isRequired,
  destinationPage: PropTypes.string,
  initialValues: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    segments: state.segments.segmentList,
    initialValues: {
      ...state.invitationCodes.invitationCodeForEdit,
    },
  };
};

const actions = {
  ...flashActions,
  ...invitationCodeActions,
  getSegments,
};

export default connect(mapStateToProps, actions)(CodesForm);
