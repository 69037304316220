import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';
import {required} from '../../../utils/intake/intakeCommon';
import CheckboxSelector from '../../../components/selectors/CheckboxSelector/CheckboxSelector';

import AscendCheckbox from '../../../formFields/ascendCheckbox/AscendCheckbox';

class IndustryPreference extends React.Component {
  state = {
    selectedIndustries: this.props.intakeCache?.industryIds?.includes(
      this.props.listOptions.find((x) => x.name === 'Any').id
    )
      ? []
      : this.props.intakeCache.industryIds,
    industriesList: [],
    noPref: this.props.intakeCache?.noIndustryPref,
  };

  validate = (values) => {
    const errors = {};
    const requiredFields = ['industryIds'];
    _.forEach(requiredFields, (field) => {
      if (
        (!values[field] || values[field].length === 0) &&
        values.anyIndustryPref === false
      ) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  render() {
    const {next, previous, isMobile, headerImage} = this.props;

    return (
      <>
        <PageHeader
          title="What industry do you want to be in?"
          headerImage={headerImage}
        />
        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            industryIds: this.props.intakeCache?.industryIds,
            anyIndustryPref: this.props.intakeCache?.anyIndustryPref,
          }}
        >
          {({handleSubmit, form}) => {
            const handleCheck = (checked) => {
              this.setState({noIndustryPref: checked});
              if (checked) {
                form.mutators.setValue('industryIds', [
                  this.props.listOptions.find((x) => x.name === 'Any').id,
                ]);
                this.setState({selectedIndustries: []});
              } else {
                form.mutators.setValue('industryIds', []);
                this.setState({selectedTags: []});
              }
            };
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <div style={{maxWidth: '700px'}}>
                    <div className={styles.pageText}>
                      Let's match you with the kind of work that you are
                      passionate about. Search for the industries you want to be
                      in or select no preference.
                    </div>

                    <div style={{textAlign: 'left', width: '100%'}}>
                      <Field
                        component={CheckboxSelector}
                        name="industryIds"
                        closeMenuOnSelect={false}
                        isMobile={this.props.isMobile}
                        validate={required}
                        optionsList={this.props.listOptions
                          .filter((x) => x.name !== 'Any')
                          .map((x) => {
                            return {
                              id: x.id,
                              value: x.id,
                              label: x.name,
                              tagName: x.name,
                            };
                          })}
                        form={form}
                        onTagChangeAction={(list) => {
                          if (list?.length > 0) {
                            form.mutators.setValue('anyIndustryPref', false);
                          }
                          this.setState({selectedIndustries: list});
                        }}
                        selectedTags={this.state.selectedIndustries}
                        placeholder="Ex. Technology, Education etc."
                        isSearchable={!isMobile}
                      />
                      <div className="pt-2">
                        <Field name="anyIndustryPref">
                          {(props) => {
                            return (
                              <>
                                <AscendCheckbox
                                  name={props.input.name}
                                  inputValue={props.input.value}
                                  onChangeAction={handleCheck}
                                  onFocus={props.input.onFocus}
                                  meta={props.meta}
                                  label="I have no preference"
                                  inputOnChange={props.input.onChange}
                                  defaultValue={this.state.noPref}
                                />
                              </>
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>

                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
IndustryPreference.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  listOptions: PropTypes.array.isRequired,
  headerImage: PropTypes.any,
};

export default IndustryPreference;
