import _ from 'lodash';

export const CANDIDATE_SEARCH_FIELDS =
  'firstName,lastName,email,universityEmail';
export const INVALID_URL_ERROR_MESSAGE =
  "Must be a valid URL, starting with 'https://'";

export let styles = {
  item: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    color: '#004e7d',
    padding: '2px 6px',
    cursor: 'default',
    background: '#ffffff',
  },

  highlightedItem: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    color: 'white',
    background: '#004e7d',
    padding: '2px 6px',
    cursor: 'default',
  },

  menu: {
    border: 'solid 1px #ccc',
    borderRadius: '3px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    position: 'relative',
    top: '0px',
    left: 0,
    overflow: 'auto',
    maxHeight: '256px',
    width: '100%',
  },
};

export function matchFieldToTerm(item, value) {
  return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}

/**
 * An example of how to implement a relevancy-based sorting method. States are
 * sorted based on the location of the match - For example, a search for "or"
 * will return "Oregon" before "North Carolina" even though "North Carolina"
 * would normally sort above Oregon. Strings where the match is in the same
 * location (or there is no match) will be sorted alphabetically - For example,
 * a search for "or" would return "North Carolina" above "North Dakota".
 */
export function sortFields(a, b, value) {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();
  const valueLower = value.toLowerCase();
  const queryPosA = aLower.indexOf(valueLower);
  const queryPosB = bLower.indexOf(valueLower);
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB;
  }
  return aLower < bLower ? -1 : 1;
}

export function seekerMajor(seeker) {
  if (seeker && seeker.majors && seeker.majors[0] && seeker.majors[0].name) {
    return seeker.majors[0].name;
  }
  return 'No Major Selected';
}

export function seekerMajors(seeker) {
  if (seeker && seeker.majors && seeker.majors[0] && seeker.majors[0].name) {
    return seeker.majors.map((x) => x.name).join(', ');
  } else {
    return 'None Selected';
  }
}

export function seekerMinors(seeker) {
  if (seeker && seeker.minors && seeker.minors[0] && seeker.minors[0].name) {
    return seeker.minors.map((x) => x.name).join(', ');
  } else {
    return '';
  }
}

export function fixUrl(url) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'https://' + url;
  }
  return url;
}

export const trimFields = (data, fields) => {
  const cleaned = {};
  _.forEach(fields, (field) => {
    if (data[field]) {
      cleaned[field] = data[field].trim();
    }
  });
  return {...data, ...cleaned};
};

// This function returns a callback function to be used in redux-form's
// onChange property of the Field component.
//
// NOTE There's a lot of goofiness converting between {id, name}
// and {label, value} here. We should probably change from using
// a list of objects as the field state to using only a list of IDs.
// It would simplify this logic and make it look like the logic in
// pages/onboarding/Industries.js. Maybe we could even refactor this
// to a helper.
export const multiselectChangeHandler = (
  fieldName,
  options,
  change,
  anyLabel = 'Any'
) => {
  return (event, newValue, previousValue) => {
    const wildcardObject = _.find(options, ['label', anyLabel]);

    // if no preference is currently selected...
    if (_.some(newValue, ['id', wildcardObject.value])) {
      // If no preference wasn't selected before,
      // change the selection list to only no preference
      // (deselect all others)
      if (
        previousValue &&
        !_.some(previousValue, ['id', wildcardObject.value])
      ) {
        event.preventDefault(); // don't dispatch the original change event
        change(fieldName, [
          {name: wildcardObject.label, id: wildcardObject.value},
        ]);
      }

      // If no preference was previously selected AND
      // we have anything else selected, change the selection list
      // to everything currently selected except no preference
      // (deselect no preference).
      else if (newValue.length > 1) {
        const selected = _.reject(
          newValue,
          (v) => v.id === wildcardObject.value
        );
        event.preventDefault(); // don't dispatch the original change event
        change(fieldName, selected);
      }
    }
  };
};

export const getSortedLookup = (
  lookup,
  sortBy,
  anyLabel = 'Any',
  includeAny = true
) => {
  let wildcard = _.find(lookup, ['name', 'Any']);
  const sortedLookup = _.chain(lookup)
    .orderBy(sortBy)
    .reject(['name', 'Any'])
    .map((t) => ({label: t.name, value: t.id}))
    .value();
  if (wildcard && includeAny) {
    sortedLookup.unshift({label: anyLabel, value: wildcard.id});
  }
  return sortedLookup;
};

export const getSortedLookupWithPublishedJobCount = (
  lookup,
  sortBy,
  anyLabel = 'Any'
) => {
  let wildcard = _.find(lookup, ['name', 'Any']);
  const sortedLookup = _.chain(lookup)
    .orderBy(sortBy)
    .reject(['name', 'Any'])
    .map((t) => ({
      label: t.name + ' (' + t.publishedJobCount + ')',
      value: t.id,
    }))
    .value();
  if (wildcard) {
    sortedLookup.unshift({label: anyLabel, value: wildcard.id});
  }
  return sortedLookup;
};

export const getSortedLookupWithAlreadyApplied = (
  lookup,
  sortBy,
  anyLabel = 'Any'
) => {
  let wildcard = _.find(lookup, ['name', 'Any']);
  const sortedLookup = _.chain(lookup)
    .orderBy(sortBy)
    .reject(['name', 'Any'])
    .map((t) => ({
      label:
        t.name +
        (t.alreadyApplied && t.alreadyApplied === true ? ' (applied)' : ''),
      value: t.id,
    }))
    .value();
  if (wildcard) {
    sortedLookup.unshift({label: anyLabel, value: wildcard.id});
  }
  return sortedLookup;
};

export const graduationYears = [
  '',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
];

export const graduationMonths = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const gpas = [
  '4.0',
  '3.9',
  '3.8',
  '3.7',
  '3.6',
  '3.5',
  '3.4',
  '3.3',
  '3.2',
  '3.1',
  '3.0',
  '2.9',
  '2.8',
  '2.7',
  '2.6',
  '2.5 or below',
];

export const genders = [
  {label: 'Prefer not to answer', value: 'NULL'},
  {label: 'Male', value: 'Male'},
  {label: 'Female', value: 'Female'},
];

export const updatedGenders = [
  {label: 'Prefer not to answer', value: 'NULL'},
  {label: 'Male', value: 'Male'},
  {label: 'Female', value: 'Female'},
  {label: 'Other / Self-Disclose', value: 'Other'},
];

export const ethnicities = [
  {label: 'Prefer not to answer', value: 'NULL'},
  {label: 'African American', value: 'African American'},
  {label: 'Black (Non-Hispanic)', value: 'Black (Non-Hispanic)'},
  {label: 'Alaska Native', value: 'Alaska Native'},
  {label: 'Asian', value: 'Asian'},
  {label: 'American Indian', value: 'American Indian'},
  {label: 'Asian American', value: 'Asian American'},
  {label: 'Pacific Islander', value: 'Pacific Islander'},
  {label: 'Latino or Hispanic', value: 'Latino or Hispanic'},
  {label: 'Native Hawaiian', value: 'Native Hawaiian'},
  {label: 'Multi-ethnic/Multi-racial', value: 'Multi-ethnic/Multi-racial'},
  {label: 'Person of Color', value: 'Person of Color'},
  {label: 'Other', value: 'Other'},
  {label: 'Caucasian (Non-Hispanic)', value: 'Caucasian (Non-Hispanic)'},
];

export const workStatuses = [
  {label: 'Prefer not to answer', value: 'NULL'},
  {label: 'US Citizen', value: 'US Citizen'},
  {label: 'Permanent Resident', value: 'Permanent Resident'},
  {label: 'H1 Visa', value: 'H1 Visa'},
  {label: 'TN Visa', value: 'TN Visa'},
  {label: 'F1 Visa', value: 'F1 Visa'},
  {label: 'EAD', value: 'EAD'},
];

export const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];

export const gradeLevels = [
  {value: 'freshman', label: 'Freshman'},
  {value: 'sophomore', label: 'Sophomore'},
  {value: 'junior', label: 'Junior'},
  {value: 'senior', label: 'Senior'},
];

export const highSchoolGraduationYears = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = 0; i < 10; i++) {
    let tmp = currentYear + i;
    years.push(tmp.toString());
  }
  return years;
};

export const optionsYesNo = [
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
];

export const maReferralSources = [
  {value: 'Info Session', label: 'Info Session'},
  {value: 'Search Engine', label: 'Search Engine'},
  {value: 'Social Media', label: 'Social Media'},
  {value: 'Friend/Family', label: 'Friend/Family'},
  {value: 'School Staff', label: 'School Staff'},
  {value: 'Other', label: 'Other'},
];

export function isValidHttpsUrl(urlText) {
  let url;
  try {
    url = new URL(urlText);
  } catch {
    return false;
  }

  return url.protocol === 'https:';
}

export function isValidPostalCode(postalCode, isRequired = false) {
  const validPostalCodeLengths = [5, 9];

  if (!isRequired) {
    validPostalCodeLengths.push(0);
  }

  const length = postalCode?.length || 0;

  return validPostalCodeLengths.includes(length);
}
