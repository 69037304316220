import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {makeRequest} from 'src/services/api';
import {withRouter} from 'src/utils/commonHooks';

import {actions as authActions} from 'src/reducers/authentication';

import LoadingPage from '../pages/LoadingPage';
import NetworkAuthenticationService from 'src/services/NetworkAuthenticationService';
import {MAP_URLS} from '../containers/AuthorizedComponent';
import mixpanel from 'mixpanel-browser';

const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;

class CallbackPage extends React.Component {
  auth = new NetworkAuthenticationService(auth0ClientId, auth0Domain);
  componentDidMount() {
    this.auth
      .getAuthResult()
      .then(async (authResult) => {
        this.auth.setSession(authResult);
        this.props.userLoggedIn();

        const callbackPath = localStorage.getItem('savedPath');
        const isMaUser = MAP_URLS.includes(callbackPath);
        const body = {
          ...authResult,
          isMaUser: isMaUser,
          callbackPath: callbackPath,
        };

        await makeRequest({
          method: 'POST',
          path: 'users/login',
          body: body,
          isAuthenticated: false,
        });

        this.props.startSession(null, true);
      })
      .catch((error) => {
        this.props.history.push('/');
      });

    mixpanel.track_links('.hungLoadingScreenLink', 'HungLoadingScreenClicked');
  }

  render() {
    return (
      <LoadingPage
        title="Authenticating..."
        message={
          <>
            If you are stuck or experiencing an unexpectedly long wait time, it
            could be because you tried to access the Ascend Network from an
            outdated link. To avoid this issue, please bookmark this exact
            URL:&nbsp;
            <a
              className="underline accessibleLinkHover hungLoadingScreenLink"
              href={window.location.origin}
            >
              {window.location.origin}
            </a>{' '}
            with no extra characters after “.com”.
            <br />
            <br />
            <a
              className="underline accessibleLinkHover hungLoadingScreenLink"
              href={window.location.origin}
            >
              Click here
            </a>{' '}
            to be redirected to &nbsp;
            <a
              className="underline accessibleLinkHover hungLoadingScreenLink"
              href={window.location.origin}
            >
              {window.location.origin}
            </a>{' '}
            where you should be able to log-in successfully.
            <br />
            <br />
            If you are still having trouble, please email a screenshot to &nbsp;
            <a href="mailto:support@ascendindiana.com">
              support@ascendindiana.com
            </a>
            , and we will be happy to assist you.
          </>
        }
        showSupportText={false}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    profile: state.profile,
  };
};

CallbackPage.propTypes = {
  history: PropTypes.object.isRequired,
  startSession: PropTypes.func.isRequired,
  userLoggedIn: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, authActions)(CallbackPage));
