import React, {useState} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import PropTypes from 'prop-types';

const DropDownMenu = ({menuItems, direction = 'right'}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const defaultMenuItem = (
    <DropdownItem key={1}>Missing menu Item</DropdownItem>
  );

  const toggleDropdown = () => {
    setDropdownOpen((prevDropdownOpen) => !prevDropdownOpen);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setDropdownOpen(false);
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction={'down'}>
      <DropdownToggle
        tag="button"
        type="button"
        className="float-right clickable buttonAsLinkGrayBackground accessibleLink "
        onKeyUp={handleKeyUp}
        aria-label="Show Menu"
      >
        <br />
        <div className="clickable disabled-caption menu-hover">
          <i className="nc-icon-glyph-med dots gly-rotate-90 menu-hover" />
        </div>
      </DropdownToggle>
      {direction === 'left' ? (
        <DropdownMenu start={true}>
          {menuItems ? menuItems : defaultMenuItem}
        </DropdownMenu>
      ) : (
        <DropdownMenu end={true}>
          {menuItems ? menuItems : defaultMenuItem}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

DropDownMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  direction: PropTypes.string,
};

export default DropDownMenu;
