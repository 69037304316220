import * as types from './ActionTypes';
import _ from 'lodash';
import {wageApiHelper} from '../utils/wageHelper';
import {makeRequest} from '../services/api';
import * as flashActions from './flash';
import {track} from 'src/utils/analytics';
import * as dispatchType from './ActionTypes';
import {makeRequestAsync} from 'src/services/api';

const apiUrl = process.env.REACT_APP_API_URL;

const cleanForCreate = (job) => {
  const illegalFields = [
    'id',
    'updatedAt',
    'createdAt',
    'deletedAt',
    'publishedAt',
    'unpublishedAt',
    'strongMatchCount',
    'roleTypes',
    'employmentTypes',
    'industries',
    'regions',
    'matchRequestedAt',
    'isActive',
    'applicationsCount',
    'urlVerified',
  ];
  let cleaned = JSON.parse(JSON.stringify(job));
  _.forEach(Object.keys(cleaned), (key) => {
    if (_.includes(illegalFields, key)) delete cleaned[key];
  });

  return cleaned;
};

export const applyToJobAsync = (jobId, app) => async (dispatch) => {
  dispatch({type: types.JOBS__APPLY_REQUEST});
  makeRequest({
    method: 'POST',
    path: `v2/jobs/${jobId}/apply`,
    body: app,
    isAuthenticated: true,
  })
    .then((response) => {
      dispatch({type: types.JOBS__APPLY_SUCCESS});
    })
    .catch((err) => {
      dispatch(
        flashActions.addFlashMessage('Job application submission failed.')
      );
      dispatch({type: types.JOBS__APPLY_FAILURE, payload: err});
    });
};

export function createJob(job) {
  job = wageApiHelper(job);
  let body = cleanForCreate(job);
  return {
    types: [
      types.JOBS__CREATE_REQUEST,
      types.JOBS__CREATE_SUCCESS,
      types.JOBS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/jobs`,
    method: 'POST',
    body: body,
    payload: {job},
    successMessage: 'Role created.',
    failureMessage: 'Error creating role.',
  };
}

export function createJobWatch(job_view) {
  const jobId = job_view.id || job_view.jobId;
  track({
    eventName: 'JobFavorited',
    jobEmploymentTypes: job_view.employmentTypes.map((x) => x.name).join(','),
    eventData: {
      jobId: jobId,
    },
  });
  return {
    types: [
      types.JOBS__CREATE_WATCH_REQUEST,
      types.JOBS__CREATE_WATCH_SUCCESS,
      types.JOBS__CREATE_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}/watches`,
    method: 'POST',
    body: {},
    payload: {jobId},
    successMessage: 'Role added to favorites',
    failureMessage: 'Error adding role to favorites',
  };
}

export function deleteJobWatch(jobId) {
  return {
    types: [
      types.JOBS__DELETE_WATCH_REQUEST,
      types.JOBS__DELETE_WATCH_SUCCESS,
      types.JOBS__DELETE_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}/watches`,
    method: 'DELETE',
    payload: {jobId},
    successMessage: 'Role removed from favorites',
    failureMessage: 'Error removing role from favorites',
  };
}

export function createJobApplied(jobId) {
  return {
    types: [
      types.JOBS__CREATE_APPLIED_REQUEST,
      types.JOBS__CREATE_APPLIED_SUCCESS,
      types.JOBS__CREATE_APPLIED_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}/applied`,
    method: 'POST',
    body: {},
    payload: {jobId},
    successMessage: 'Added role to application list.',
    failureMessage: 'Error adding role to application list.',
  };
}

export function createJobAppliedBySeeker(jobId, seekerId) {
  let uri = `${apiUrl}/jobs/${jobId}/applied`;
  let p = [];
  p.push('seekerId=' + seekerId);

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__CREATE_APPLIED_REQUEST_BY_SEEKER,
      types.JOBS__CREATE_APPLIED_SUCCESS_BY_SEEKER,
      types.JOBS__CREATE_APPLIED_FAILURE_BY_SEEKER,
    ],
    uri: uri,
    method: 'POST',
    body: {},
    payload: {jobId},
    successMessage: 'Added role to application list.',
    failureMessage: 'Error adding role to application list.',
  };
}

export function getJobStats(employerId, teamId) {
  let uri = `${apiUrl}/jobs/stats`;
  let p = [];
  p.push('employerId=' + employerId);
  p.push('teamId=' + teamId);

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_JOB_STATS_REQUEST,
      types.JOBS__GET_JOB_STATS_SUCCESS,
      types.JOBS__GET_JOB_STATS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getJobHandpickedToCache(jobId) {
  let uri = `${apiUrl}/jobs/${jobId}/handpicked`;

  return {
    types: [
      types.JOBS__GET_HANDPICKED_TO_CACHE_REQUEST,
      types.JOBS__GET_HANDPICKED_TO_CACHE_SUCCESS,
      types.JOBS__GET_HANDPICKED_TO_CACHE_FAILURE,
    ],
    uri: uri,
    payload: {jobId},
    method: 'GET',
  };
}

export function getJob(jobId) {
  return {
    types: [
      types.JOBS__GET_REQUEST,
      types.JOBS__GET_SUCCESS,
      types.JOBS__GET_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}`,
    method: 'GET',
  };
}

export function getJobForEdit(jobId) {
  return {
    types: [
      types.JOBS__GET_FOR_EDIT_REQUEST,
      types.JOBS__GET_FOR_EDIT_SUCCESS,
      types.JOBS__GET_FOR_EDIT_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}`,
    method: 'GET',
  };
}
export const updateJobForEdit = (job) => async (dispatch) => {
  dispatch({type: types.JOBS__GET_FOR_EDIT_SUCCESS, data: {...job}});
};

export function getJobForClone(jobId) {
  return {
    types: [
      types.JOBS__GET_FOR_CLONE_REQUEST,
      types.JOBS__GET_FOR_CLONE_SUCCESS,
      types.JOBS__GET_FOR_CLONE_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}`,
    method: 'GET',
  };
}

export function getJobToCache(jobId) {
  return {
    types: [
      types.JOBS__GET_TO_CACHE_REQUEST,
      types.JOBS__GET_TO_CACHE_SUCCESS,
      types.JOBS__GET_TO_CACHE_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}`,
    payload: {jobId},
    method: 'GET',
  };
}

export function getAllJobSummaries(filters) {
  const qs = _.map(filters, (val, key) => `${key}=${val}`).join('&');
  let uri = `${apiUrl}/v2/jobs/summaries?${qs}`;

  return {
    types: [
      types.JOBS__GET_ALL_SUMMARIES_REQUEST,
      types.JOBS__GET_ALL_SUMMARIES_SUCCESS,
      types.JOBS__GET_ALL_SUMMARIES_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getAllJobs(includeUnPublished = false) {
  let uri = `${apiUrl}/jobs`;
  let p = [];

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_ALL_REQUEST,
      types.JOBS__GET_ALL_SUCCESS,
      types.JOBS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getAllJobsAdvanced(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];

  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (includeUnPublished) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_ALL_ADVANCED_REQUEST,
      types.JOBS__GET_ALL_ADVANCED_SUCCESS,
      types.JOBS__GET_ALL_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getJobsByTeam(teamId, includeUnPublished = false) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push('teamId=' + teamId);

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_BY_TEAM_REQUEST,
      types.JOBS__GET_BY_TEAM_SUCCESS,
      types.JOBS__GET_BY_TEAM_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getJobsByTeamAdvanced(
  teamId,
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];

  p.push('teamId=' + teamId);

  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_BY_TEAM_ADVANCED_REQUEST,
      types.JOBS__GET_BY_TEAM_ADVANCED_SUCCESS,
      types.JOBS__GET_BY_TEAM_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getJobsByTeamToCache(teamId, includeUnPublished = false) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push('teamId=' + teamId);

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_BY_TEAM_TO_CACHE_REQUEST,
      types.JOBS__GET_BY_TEAM_TO_CACHE_SUCCESS,
      types.JOBS__GET_BY_TEAM_TO_CACHE_FAILURE,
    ],
    payload: {teamId},
    uri: uri,
    method: 'GET',
  };
}

export function getJobsByRecruiter(recruiterId) {
  return {
    types: [
      types.JOBS__GET_ALL_REQUEST,
      types.JOBS__GET_ALL_SUCCESS,
      types.JOBS__GET_ALL_FAILURE,
    ],
    uri: `${apiUrl}/jobs?&recruiterId=${recruiterId}`,
    method: 'GET',
  };
}

export function getJobsByEmployer(
  employerId,
  publishedAfter = '',
  includeUnPublished = false,
  itemsPerPage = ''
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push('employerId=' + employerId);

  if (publishedAfter !== '') {
    p.push('publishedAfter=' + publishedAfter);
  }

  if (itemsPerPage !== '') {
    p.push('itemsPerPage=' + itemsPerPage);
  }

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_ALL_REQUEST,
      types.JOBS__GET_ALL_SUCCESS,
      types.JOBS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getJobsByEmployerForConnections(
  employerId,
  publishedAfter = ''
) {
  let uri = `${apiUrl}/jobs/connections`;
  let p = [];
  p.push('employerId=' + employerId);

  if (publishedAfter !== '') {
    p.push('publishedAfter=' + publishedAfter);
  }

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.JOBS__GET_ALL_REQUEST,
      types.JOBS__GET_ALL_SUCCESS,
      types.JOBS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getJobCard(jobId) {
  return {
    types: [
      types.JOBS__GET_JOB_CARD_REQUEST,
      types.JOBS__GET_JOB_CARD_SUCCESS,
      types.JOBS__GET_JOB_CARD_FAILURE,
    ],
    payload: {jobId},
    uri: `${apiUrl}/jobs/${jobId}/jobcard`,
    method: 'GET',
  };
}

export function getJobReports() {
  return {
    types: [
      types.JOBS__GET_REPORTS_REQUEST,
      types.JOBS__GET_REPORTS_SUCCESS,
      types.JOBS__GET_REPORTS_FAILURE,
    ],
    uri: `${apiUrl}/jobs/reports`,
    method: 'GET',
  };
}

const cleanForUpdate = (job) => {
  const illegalFields = [
    'id',
    'employerId',
    'updatedAt',
    'createdAt',
    'deletedAt',
    'publishedAt',
    'unpublishedAt',
    'strongMatchCount',
    'roleTypes',
    'employmentTypes',
    'industries',
    'regions',
    'matchRequestedAt',
    'isActive',
    'applicationsCount',
    'urlVerified',
  ];
  let cleaned = JSON.parse(JSON.stringify(job));
  _.forEach(Object.keys(cleaned), (key) => {
    if (_.includes(illegalFields, key)) delete cleaned[key];
  });

  return cleaned;
};

export function updateJob(job) {
  job = wageApiHelper(job);
  let body = cleanForUpdate(job);

  return {
    types: [
      types.JOBS__UPDATE_REQUEST,
      types.JOBS__UPDATE_SUCCESS,
      types.JOBS__UPDATE_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${job.id}`,
    method: 'PATCH',
    body: body,
    payload: {job},
    successMessage: 'Role saved.',
    failureMessage: 'Error saving role.',
  };
}

export function deleteJob(jobId) {
  return {
    types: [
      types.JOBS__DELETE_REQUEST,
      types.JOBS__DELETE_SUCCESS,
      types.JOBS__DELETE_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}`,
    method: 'DELETE',
    payload: {jobId},
    successMessage: 'Role deleted.',
    failureMessage: 'Error deleting role.',
  };
}

export function resetPublishedDate(jobId) {
  return {
    types: [
      types.JOBS__RESET_PUBLISHED_DATE_REQUEST,
      types.JOBS__RESET_PUBLISHED_DATE_SUCCESS,
      types.JOBS__RESET_PUBLISHED_DATE_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}/resetPublishedDate`,
    method: 'PATCH',
    payload: {jobId},
    successMessage: 'Role published date reset.',
    failureMessage: 'Error resetting published date for role.',
  };
}

export function deleteJobApplied(jobId) {
  return {
    types: [
      types.JOBS__DELETE_APPLIED_REQUEST,
      types.JOBS__DELETE_APPLIED_SUCCESS,
      types.JOBS__DELETE_APPLIED_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}/applied`,
    method: 'DELETE',
    payload: {jobId},
    successMessage: 'Removed role from application list.',
    failureMessage: 'Error removing role from application list.',
  };
}

export const getJobCategories = () => {
  return {
    types: [
      types.JOBS__GET_CATEGORY_REQUEST,
      types.JOBS__GET_CATEGORY_SUCCESS,
      types.JOBS__GET_CATEGORY_FAILURE,
    ],
    uri: `${apiUrl}/v2/job_category`,
    method: 'GET',
  };
};

export function getApplicationData(jobId) {
  return {
    types: [
      types.JOBS__GET_APPLICATION_DATA_REQUEST,
      types.JOBS__GET_APPLICATION_DATA_SUCCESS,
      types.JOBS__GET_APPLICATION_DATA_FAILURE,
    ],
    uri: `${apiUrl}/v2/jobs/${jobId}/application-data`,
    method: 'GET',
  };
}

export function getAppliedToClicks(seekerId) {
  const encodedSeekerId = seekerId ? encodeURIComponent(seekerId) : '';
  const uri = encodedSeekerId
    ? `${apiUrl}/v2/jobs/seeker-applied-to-clicks?seekerId=${encodedSeekerId}`
    : `${apiUrl}/v2/jobs/seeker-applied-to-clicks`;

  return {
    types: [
      types.JOBS__GET_APPLIED_TO_CLICKS_REQUEST,
      types.JOBS__GET_APPLIED_TO_CLICKS_SUCCESS,
      types.JOBS__GET_APPLIED_TO_CLICKS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export const applyToClicked = (jobId) => async (dispatch) => {
  dispatch({type: types.JOBS__APPLY_TO_CLICK_REQUEST});
  makeRequest({
    method: 'POST',
    path: `v2/jobs/${jobId}/apply-to-clicked`,
  })
    .then(() => {
      dispatch({type: types.JOBS__APPLY_TO_CLICK_SUCCESS});
    })
    .catch((err) => {
      dispatch({type: types.JOBS__APPLY_TO_CLICK_FAILURE, payload: err});
    });
};

export function patchAppliedToResponse(id, body, displayMessage) {
  return {
    types: [
      types.JOBS__APPLY_TO_RESPONSE_REQUEST,
      types.JOBS__APPLY_TO_RESPONSE_SUCCESS,
      types.JOBS__APPLY_TO_RESPONSE_FAILURE,
    ],
    uri: `${apiUrl}/v2/jobs/seeker-applied-to-response/${id}`,
    method: 'PATCH',
    body: body,
    successMessage: displayMessage ? 'Added role to application list.' : '',
    failureMessage: displayMessage
      ? 'Unable to apply application. Please try again later.'
      : '',
  };
}

export function patchUndoAppliedToResponse(id, displayMessage) {
  return {
    types: [
      types.JOBS__APPLY_TO_RESPONSE_REQUEST,
      types.JOBS__APPLY_TO_RESPONSE_SUCCESS,
      types.JOBS__APPLY_TO_RESPONSE_FAILURE,
    ],
    uri: `${apiUrl}/v2/jobs/undo-seeker-applied-to-response/${id}`,
    method: 'PATCH',
    successMessage: displayMessage ? 'Removed role from application list.' : '',
    failureMessage: displayMessage
      ? 'Unable to undo application. Please try again later.'
      : '',
  };
}

export function removeAppliedToClick(id) {
  return {type: types.JOBS__REMOVE_APPLY_TO_CLICK, id};
}

export const getEmployerJobsSorted =
  ({employerId, seekerId}) =>
  (dispatch) => {
    let uri = '';

    if (seekerId) {
      uri = `jobs?employerId=${employerId}&sort=-overallscore&includeMatchData`;
    } else {
      uri = `jobs?employerId=${employerId}&sort=-publisheddate`;
    }

    dispatch({
      types: [
        types.JOBS__EMPLOYER_JOBS_SORTED_REQUEST,
        types.JOBS__EMPLOYER_JOBS_SORTED_SUCCESS,
        types.JOBS__EMPLOYER_JOBS_SORTED_FAILURE,
      ],
      uri: `${apiUrl}/${uri}`,
      method: 'GET',
    });
  };

export const jobUnPublish =
  ({job}) =>
  (dispatch) => {
    job = wageApiHelper(job);
    let body = cleanForUpdate(job);

    dispatch({
      types: [
        types.JOBS__EMPLOYER_JOBS_UNPUBLISH_REQUEST,
        types.JOBS__EMPLOYER_JOBS_UNPUBLISH_SUCCESS,
        types.JOBS__EMPLOYER_JOBS_UNPUBLISH_FAILURE,
      ],
      uri: `${apiUrl}/jobs/${job.id}`,
      method: 'PATCH',
      body: body,
      payload: {job},
      successMessage: 'Role unpublished',
      failureMessage: 'Role was not unpublished!',
    });
  };
export const setJobNotFoundModal =
  ({visible}) =>
  (dispatch) => {
    dispatch({
      type: dispatchType.JOBS__GET_TO_CACHE_NOT_FOUND,
      showJobNotFoundModal: visible,
    });
  };
export function reportJobIssue(jobId, body) {
  return {
    types: [
      types.JOBS__REPORT_ISSUE_REQUEST,
      types.JOBS__REPORT_ISSUE_SUCCESS,
      types.JOBS__REPORT_ISSUE_FAILURE,
    ],
    uri: `${apiUrl}/jobs/${jobId}/reportIssue`,
    method: 'POST',
    body: body,
    payload: {jobId},
  };
}

export const getResume = (resumeData) => async (dispatch) => {
  const path = `v2/jobs/resume_urls?uuid=${resumeData.uuid}&uploadedFilename=${resumeData.uploadedFilename}`;

  try {
    const response = await makeRequestAsync('GET', path, {});

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    dispatch(flashActions.addFlashMessage('Failed to get resume urls'));
  }
};
