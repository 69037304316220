import React from 'react';
import PropTypes from 'prop-types';
import {CheckSvg} from 'src/assets/svg/svgComponents';
import tagStyles from 'src/components/tags/TagButton/TagButton.module.scss';
import {updateMaSeekerViewedApprovedStatus} from 'src/actions/seekers';

class MaCandidateStatus extends React.Component {
  componentDidMount() {
    const {seekerId, isCompleteAndApproved} = this.props;
    // Save that the candidate viewed the status.
    if (isCompleteAndApproved) {
      updateMaSeekerViewedApprovedStatus(seekerId);
    }
  }

  render() {
    const {
      candidateOnboarded,
      isAdminApproved,
      isSchoolApproved,
      isCompleteAndApproved,
    } = this.props;

    //Pending: AT LEAST ONE IS NULL BUT NEITHER ARE FALSE
    const candidateIsPending =
      (isAdminApproved === null || isSchoolApproved === null) &&
      isAdminApproved !== false &&
      isSchoolApproved !== false;

    // Approved: BOTH ARE TRUE
    const candidateIsApproved =
      isAdminApproved === true && isSchoolApproved === true;

    // Declined: EITHER ARE FALSE
    const candidateIsDeclined =
      isAdminApproved === false || isSchoolApproved === false;

    return (
      <>
        <div className="row">
          <div className="col-md-12 padding-sixteen">
            <div className="maCardWelcome">
              <div className="fullwidth">
                {isCompleteAndApproved && (
                  <>
                    <strong>Congrats!</strong>&nbsp;&nbsp;You're ready to begin
                    applying to apprenticeships. Click "Explore Roles" to get
                    started.
                  </>
                )}

                {!isCompleteAndApproved && (
                  <>
                    <strong>
                      To begin applying to apprenticeships, complete the steps
                      below.
                    </strong>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 padding-sixteen">
            <div className="maCardContainer">
              <div className="fullwidth">
                <div className="maCardTitle">
                  Step One: Complete Your Profile
                </div>

                {!candidateOnboarded && (
                  <>
                    <div className="bold">
                      <button
                        onClick={this.props.handleSurveyClick}
                        className={`btn btn-primary`}
                      >
                        Click to Complete Profile
                      </button>
                    </div>
                    <div className="maCardFooter">
                      Answer three short essay questions that will be shared
                      with employers in your application materials.
                    </div>
                  </>
                )}
                {candidateOnboarded && (
                  <div className="bold justify-content-center">
                    <div>
                      <div
                        className={'inlineblock bg-Med-grey'}
                        style={{padding: '6px'}}
                      >
                        <CheckSvg
                          width="24"
                          height="24"
                          className={'svg_color_fill_green'}
                        />
                        Complete
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 padding-sixteen">
            <div className="maCardContainer">
              <div className="fullwidth">
                <div className="maCardTitle">
                  Step Two: Get approved to participate
                </div>
                <div
                  className="bold justify-content-center"
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  Status:
                  {candidateIsApproved && (
                    <div
                      className={`${tagStyles.containerApproved} discoveryTag`}
                    >
                      <span
                        aria-label="tag"
                        className={`${tagStyles.tagStatusApproved}`}
                      >
                        Approved
                      </span>
                    </div>
                  )}
                  {candidateIsDeclined && (
                    <div
                      className={`${tagStyles.containerDeclined} discoveryTag`}
                    >
                      <span
                        aria-label="tag"
                        className={`${tagStyles.tagStatusDeclined}`}
                      >
                        Declined
                      </span>
                    </div>
                  )}
                  {candidateIsPending && (
                    <div
                      className={`${tagStyles.containerPending} discoveryTag`}
                    >
                      <span
                        aria-label="tag"
                        className={`${tagStyles.tagStatusPending}`}
                      >
                        Pending
                      </span>
                    </div>
                  )}
                </div>

                {candidateIsApproved && (
                  <div className="maCardFooter">
                    You've been approved by programs administrators and your
                    school to participate in an apprenticeship.
                  </div>
                )}
                {(candidateIsDeclined || candidateIsPending) && (
                  <div className="maCardFooter">
                    To learn more about the approval process, reach out to the
                    program administrator or{' '}
                    <a
                      className="underline"
                      href="mailto:support@ascendindiana.com"
                    >
                      support@ascendindiana.com
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

MaCandidateStatus.propTypes = {
  candidateOnboarded: PropTypes.bool,
  handleSurveyClick: PropTypes.func.isRequired,
  isSchoolApproved: PropTypes.bool,
  isAdminApproved: PropTypes.bool,
  seekerId: PropTypes.string,
  profileStatusId: PropTypes.number,
  isCompleteAndApproved: PropTypes.bool,
};

export default MaCandidateStatus;
