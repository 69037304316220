import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import _ from 'lodash';

import * as employmentActions from 'src/actions/employmentTypes';

import * as Layout from 'src/layout';
import {BusyButton} from './BusyButton';
import {PERMISSIONS, has_permission} from '../services/authorizationApi';
import PushButtonArray from './controls/buttons/PushButtonArray/PushButtonArray';

class EmploymentTypesModal extends React.Component {
  state = {
    isAdmin: false,
  };
  componentDidMount() {
    this.props.getEmploymentTypes();
    has_permission(PERMISSIONS.ADMIN).then((value) => {
      this.setState({isAdmin: value.data.hasPermission});
    });
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = ['employmentTypeIds'];
    _.forEach(requiredFields, (field) => {
      if (!values[field] || values[field].length === 0) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  buildButtonList = () => {
    const maSegment =
      this.props.segments &&
      this.props.segments.some((x) => x.isApprenticeProgram);
    let empTypeList =
      maSegment || this.state.isAdmin
        ? this.props.employmentTypes
        : this.props.employmentTypes.filter((y) => y.name !== 'Apprenticeship');

    const buttons = empTypeList
      .filter((et) => et.showDuringOnboarding)
      .map((x) => {
        return {buttonText: x.name, buttonValue: x.id};
      });
    return buttons;
  };

  render() {
    const {isOpen, onClose} = this.props;

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={this.validate}
            keepDirtyOnReinitialize={true}
            initialValues={{
              employmentTypeIds:
                this.props.employmentTypes
                  .filter((i) =>
                    this.props.seekerEmploymentTypes.includes(i.id)
                  )
                  .map((x) => {
                    return {buttonText: x.name, buttonValue: x.id};
                  }) || [],
            }}
          >
            {({handleSubmit, form}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <center>
                    <div className="normal-title padding-bottom-eight bold">
                      Edit Employment Types
                    </div>
                  </center>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Field
                      component={PushButtonArray}
                      name="employmentTypeIds"
                      buttonList={this.buildButtonList()}
                      displayVertical={true}
                      multiSelect={true}
                      form={form}
                      style={{width: '277px'}}
                    />
                  </div>
                  <div className="row justify-content-center nowrap">
                    <button
                      type="button"
                      className="btn btn-transparent col-2 me-4"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <BusyButton
                      onClick={handleSubmit}
                      buttonText={'Save'}
                      busyText={'Saving'}
                      className="col-2"
                      style={{width: '210px', height: '40px'}}
                      float={'float-right'}
                      buttonIcon={'save'}
                      alignText={'text-center'}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

EmploymentTypesModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  employmentTypes: PropTypes.array,
  getEmploymentTypes: PropTypes.func.isRequired,
  segments: PropTypes.array,
  seekerEmploymentTypes: PropTypes.array,
};

const actions = {
  ...employmentActions,
};

let EditEmploymentTypesModal = (props) => (
  <EmploymentTypesModal {...props} mode="edit" />
);

const editMapStateToProps = (state) => {
  return {
    employmentTypes: state.employmentTypes.employmentTypes,
  };
};

EditEmploymentTypesModal = connect(
  editMapStateToProps,
  actions
)(EditEmploymentTypesModal);

export {EditEmploymentTypesModal};
