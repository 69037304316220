import React from 'react';
import PropTypes from 'prop-types';
import logowithnameandnetwork from '../assets/images/LogoWithNameAndNetwork.svg';
class LogoWithNameAndNetwork extends React.Component {
  render() {
    const logoStyle = {
      ...this.props.style,
      height: this.props.height,
      width: this.props.width,
    };

    return (
      <div style={{position: 'relative'}}>
        <img style={logoStyle} alt="" src={logowithnameandnetwork} />
      </div>
    );
  }
}

LogoWithNameAndNetwork.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
};

LogoWithNameAndNetwork.defaultProps = {
  width: 206,
  height: 50,
  style: {},
};

export default LogoWithNameAndNetwork;
