import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import {withRouter} from '../../../utils/commonHooks';
import track from 'react-tracking';

import * as employersActions from 'src/actions/employers';
import * as tableActions from 'src/actions/employersDataTable';
import * as jobsActions from 'src/actions/jobs';
import * as ascendRecruitersActions from 'src/actions/ascendRecruiters';

import {PageHeader, PageFooter} from 'src/layout';
import {CreateEmployerForm, EditEmployerForm} from 'src/forms/EmployerForm';
import StatsDashboard from 'src/components/StatsDashboard';
import AscendDataTable from 'src/components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import {formatDate} from 'src/utils/miscHelper';
import AreYouSureModal from 'src/components/AreYouSureModal';
import * as segmentActions from 'src/actions/SegmentActions';
import _ from 'lodash';
import {PERMISSIONS} from 'src/services/authorizationApi';
import {CreateJobForm} from 'src/forms/JobForm';
import Avatar from '../../../components/Avatar';
import AssignForm from '../candidates/AssignForm';

const searchFields = ['name'];
const searchPlaceholder = 'Search by name';

@track({eventName: 'EmployerListPage'}, {dispatchOnMount: true})
class EmployerListPage extends React.Component {
  state = {
    formOpen: false,
    jobFormOpen: false,
    areYouSureModalIsOpen: false,
    pullDownOptionList: [],
    segmentFilter: {},
    assignFormOpen: false,
  };

  getEmployers = (
    currentPage,
    itemsPerPage,
    searchTerm,
    sortBy,
    sortAscending,
    segments
  ) => {
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    this.props.getEmployersAdvanced(
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      true,
      segments === 0 ? '' : segments
    );
  };

  async componentDidMount() {
    this.getEmployers(
      this.props.currentPage,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending
    );
    this.props.getEmployersStats();
    this.props.getEmployerReports();
    await this.props.getSegments();
    this.props.getAscendRecruiters();
    this.setState({
      pullDownOptionList: this.buildSegmentsList(),
    });
  }

  handlePageChange = (pageNumber) => {
    this.props.updateCurrentPage(pageNumber);
    this.getEmployers(
      pageNumber,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending,
      this.state.segmentFilter.value
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    this.props.updateSearchTerm(searchTerm);
    if (search) {
      this.getEmployers(
        0,
        this.props.itemsPerPage,
        searchTerm,
        this.props.sortBy,
        this.props.sortAscending,
        this.state.segmentFilter.value
      );
    }
  };

  handleSortChange = (sortBy, sortAscending) => {
    this.props.updateSortTerm(sortBy, sortAscending);
    this.getEmployers(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      sortBy,
      sortAscending,
      this.state.segmentFilter.value
    );
  };

  handlerFunction = (operation, employerId) => {
    switch (operation) {
      case 'View Applications':
        this.handleApplicationsClicked(employerId);
        break;
      case 'Delete':
        this.handleDeleteClicked(employerId);
        break;
      case 'Edit Employer':
        this.handleEditClicked(employerId);
        break;
      case 'Manage Users':
        this.handleRecruitersClick(employerId);
        break;
      case 'View Teams':
        this.handleTeamsClick(employerId);
        break;
      case 'View Reports':
        this.handleReportsClick(employerId);
        break;
      case 'Elevate Candidates':
        this.handleElevateClick(employerId);
        break;
      case 'Post a Job':
        this.handlePostRole(employerId);
        break;
      case 'View Saved Candidates':
        this.handleViewSavedCandidatesClicked(employerId);
        break;
      case 'Assign Manager':
        this.handleAssignClick(employerId);
        break;
      default:
        this.handleViewClick(employerId);
        break;
    }
  };

  handlePostRole = (employerId) => {
    this.setState({
      jobFormOpen: true,
      employerIdForJob: employerId,
    });
  };

  handleEditClicked = (employerId) => {
    this.props.getEmployerForEdit(employerId);
    this.setState({
      formOpen: true,
      formMode: 'edit',
      employerIdToEdit: employerId,
    });
  };

  handleModalClose = () => {
    this.setState({
      formOpen: false,
    });
  };

  handleJobModalClose = () => {
    this.setState({
      jobFormOpen: false,
    });
  };

  handleTeamsClick = (employerId) => {
    this.props.history.push({
      pathname: '/admin/teams',
      search: '?employerId=' + employerId,
    });
  };

  handleViewClick = (employerId) => {
    this.props.history.push('/employer/' + employerId);
  };

  handleReportsClick = (employerId) => {
    this.props.history.push(`/admin/employers/${employerId}/reports`);
  };

  handleElevateClick = (employerId) => {
    this.props.history.push(`/admin/employers/${employerId}/applications`);
  };

  handleRecruitersClick = (employerId) => {
    this.props.history.push('/admin/employers/' + employerId + '/recruiters');
  };

  handleApplicationsClicked = (employerId) => {
    this.props.history.push({
      pathname: '/jobs/applications',
      search: `?employerId=${employerId}`,
    });
  };

  handleDeleteClicked = (employerId) => {
    this.setState({
      employerIdToDelete: employerId,
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = (employerId) => {
    this.props.deleteEmployer(employerId);
    this.setState({
      employerIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClose = () => {
    this.setState({
      employerIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleAddEmployerClicked = () => {
    this.setState({
      formOpen: true,
      formMode: 'create',
    });
  };

  handleEmployerEditSubmit = (employer) => {
    this.props.updateEmployer(employer);
    this.setState({formOpen: false});
  };

  handleEmployerCreateSubmit = (employer) => {
    this.props.createEmployer(employer);
    this.setState({formOpen: false});
  };

  handleViewSavedCandidatesClicked = (employerId) => {
    this.props.history.push(`/saved_candidates/${employerId}`);
  };

  buildName = (employer) => {
    return (
      <Link
        className="underlineLink admin-item-link"
        to={'/employer/' + employer.id}
      >
        {employer.name}
      </Link>
    );
  };
  buildRank = (employer) => {
    if (employer.employerRank) {
      return employer.employerRank;
    } else {
      return '';
    }
  };

  buildDateAdded = (employer) => {
    if (employer.createdAt) {
      return <span className="nowrap">{formatDate(employer.createdAt)}</span>;
    } else {
      return '';
    }
  };

  buildLastModified = (employer) => {
    if (employer.updatedAt) {
      return formatDate(employer.updatedAt);
    } else {
      return '';
    }
  };

  buildTeams = (employer) => {
    return (
      <div>
        <div className="inlineblock bold">{employer.publishedTeamCount}</div>
        <div className="inlineblock">
          &nbsp;/&nbsp;{employer.draftTeamCount}
        </div>
      </div>
    );
  };

  buildRoles = (employer) => {
    return (
      <div>
        <div className="inlineblock bold">{employer.publishedJobCount}</div>
        <div className="inlineblock">&nbsp;/&nbsp;{employer.draftJobCount}</div>
      </div>
    );
  };

  buildPublished = (employer) => {
    if (employer.published === true) return 'yes';
    if (employer.published === false) return 'no';
    return 'n/a';
  };

  buildSegmentsList = () => {
    const {segments} = this.props;
    let segmentList = [];
    _.forEach(segments, (x) => {
      segmentList.push({
        value: x.id,
        label: x.name === 'Any' ? 'All Segments' : x.name,
      });
    });
    return segmentList;
  };

  handleDropDownChange = (e) => {
    this.setState({segmentFilter: e});
    this.getEmployers(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending,
      e.value
    );
  };

  handleJobCreateSubmit = (job) => {
    const employerId = this.state.employerIdForJob;
    this.props.createJob({...job, employerId});
    this.setState({jobFormOpen: false});
  };

  rowMenuItemListFilter = (menuText, row) => {
    const requiresPIIPermission = [
      'View Applications',
      'Elevate Candidates',
      'View Reports',
    ];
    if (
      requiresPIIPermission.includes(menuText.displayName) &&
      !this.props.permissions.includes(PERMISSIONS.JOB_SEEKER_PII)
    ) {
      return false;
    }
    if (menuText.displayName === 'Applications') {
      return this.props.permissions.includes(
        PERMISSIONS.JOB_APPLICATION_BROWSER
      );
    }
    return true;
  };

  buildManagerColumn = ({accountManager}) =>
    accountManager && (
      <div
        className="d-flex justify-content-center align-items-center text-nowrap"
        tabIndex={0}
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-html={accountManager.name}
      >
        {accountManager.profilePicture && (
          <Avatar
            url={accountManager.profilePicture}
            label={accountManager.name}
          />
        )}
      </div>
    );

  handleAssignClick = (employerId) => {
    const employer = _.find(this.props.employers, (employer) => {
      return employer.id === employerId;
    });

    this.setState({
      assignFormOpen: true,
      seekerId: employer.id,
      selectedEmployer: employer,
      ascendRecruiterId: employer.ascendRecruiter
        ? employer.ascendRecruiter.id
        : 0,
    });
  };

  handleAssignSubmit = (accountManagerId) => {
    const managerUserId = this.props.ascendRecruiters.filter(
      (x) => x.id === accountManagerId
    )[0].userId;
    if (accountManagerId === '0') {
      this.props.updateEmployer({
        ...this.state.selectedEmployer,
        accountManagerId: null,
      });
    } else {
      this.props.updateEmployer({
        ...this.state.selectedEmployer,
        accountManagerId: managerUserId,
      });
    }
    this.setState({assignFormOpen: false, selectedEmployer: null});
  };

  render() {
    const {reports} = this.props;

    const tableColumns = [
      {
        sortBy: 'name',
        displayName: 'NAME',
        content: this.buildName,
      },
      {
        displayName: 'DATE ADDED',
        content: this.buildDateAdded,
        sortBy: 'dateadded',
      },
      {
        displayName: 'LAST MODIFIED',
        content: this.buildLastModified,
        sortBy: 'lastmodified',
      },
      {
        displayName: 'TEAMS',
        content: this.buildTeams,
        sortBy: 'teams',
      },
      {
        displayName: 'ROLES',
        content: this.buildRoles,
        sortBy: 'roles',
      },
      {
        displayName: 'PUBLISHED',
        content: this.buildPublished,
        sortBy: 'published',
      },
      {
        displayName: 'RANK',
        content: this.buildRank,
        sortBy: 'employerRank',
        showAsRank: true,
      },
      {
        displayName: 'MANAGER',
        content: this.buildManagerColumn,
      },
    ];

    const rowMenuItemList = [
      {
        displayName: 'Edit Employer',
      },
      {
        displayName: 'Assign Manager',
      },
      {
        displayName: 'Post a Job',
      },
      {
        displayName: 'Manage Users',
      },
      {
        displayName: 'View Applications',
      },
      {
        displayName: 'View Saved Candidates',
      },
      {
        displayName: 'View Teams',
      },
      {
        displayName: 'View Reports',
      },
      {
        displayName: 'Elevate Candidates',
      },
      {
        displayName: 'Delete',
      },
    ];

    const employersStats = [
      {label: 'Published', value: this.props.stats.publishedEmployers},
      {label: 'Draft', value: this.props.stats.draftEmployers},
      {label: 'Total', value: this.props.stats.totalEmployers},
    ];

    const teamsStats = [
      {label: 'Published', value: this.props.stats.publishedTeams},
      {label: 'Draft', value: this.props.stats.draftTeams},
      {label: 'Total', value: this.props.stats.totalTeams},
    ];

    const rolesStats = [
      {label: 'Published', value: this.props.stats.publishedJobs},
      {label: 'Draft', value: this.props.stats.draftJobs},
      {label: 'Total', value: this.props.stats.totalJobs},
    ];

    return (
      <DocumentTitle title="Employers Admin">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          {this.state.jobFormOpen && (
            <CreateJobForm
              onSubmit={this.handleJobCreateSubmit}
              isOpen={this.state.jobFormOpen}
              onClose={this.handleJobModalClose}
              employerId={this.state.employerIdForJob}
            />
          )}

          {this.state.formMode === 'create' && (
            <CreateEmployerForm
              onSubmit={this.handleEmployerCreateSubmit}
              isOpen={this.state.formOpen}
              onClose={this.handleModalClose}
            />
          )}

          {this.state.formMode === 'edit' && (
            <EditEmployerForm
              id={this.state.employerIdToEdit}
              onSubmit={this.handleEmployerEditSubmit}
              onDelete={() =>
                this.handleDeleteConfirmed(this.state.employerIdToEdit)
              }
              isOpen={this.state.formOpen}
              onClose={this.handleModalClose}
            />
          )}

          <AreYouSureModal
            areYouSureText="Are you sure that you want to remove this employer?"
            isOpen={this.state.areYouSureModalIsOpen}
            onClose={this.handleAreYouSureClose}
            onYes={() =>
              this.handleDeleteConfirmed(this.state.employerIdToDelete)
            }
          />

          {this.state.assignFormOpen && (
            <AssignForm
              isOpen={this.state.assignFormOpen}
              ascendRecruiterId={this.state.ascendRecruiterId}
              ascendRecruiters={this.props.ascendRecruiters}
              profile={this.props.profile}
              selectedItem={this.state.selectedEmployer}
              onClose={() => this.setState({assignFormOpen: false})}
              onSubmit={this.handleAssignSubmit}
            />
          )}

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />
                <div className="padding-bottom-sixteen">
                  <div className="row">
                    <div className="col-md-4">
                      <StatsDashboard
                        title="Employers"
                        labelPosition="bottom"
                        stats={employersStats}
                      />
                    </div>
                    <div className="col-md-4">
                      <StatsDashboard
                        title="Teams"
                        labelPosition="bottom"
                        stats={teamsStats}
                      />
                    </div>
                    <div className="col-md-4">
                      <StatsDashboard
                        title="Roles"
                        labelPosition="bottom"
                        stats={rolesStats}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-primary float-right"
                    type="button"
                    onClick={this.handleAddEmployerClicked}
                  >
                    + Add Employer
                  </button>
                  <h2 className="normal-headline bold padding-bottom-eight">
                    Employers Admin
                  </h2>
                  <br />
                  <AscendDataTable
                    rowMenuItemList={rowMenuItemList}
                    rowMenuItemListFilter={this.rowMenuItemListFilter}
                    tableColumns={tableColumns}
                    items={this.props.employers}
                    handlerFunction={this.handlerFunction}
                    currentPage={this.props.currentPage}
                    itemsPerPage={this.props.itemsPerPage}
                    searchTerm={this.props.searchTerm}
                    sortBy={this.props.sortBy}
                    sortAscending={this.props.sortAscending}
                    totalItems={this.props.totalItems}
                    handlePageChange={this.handlePageChange}
                    handleFilterChange={this.handleFilterChange}
                    handleSortChange={this.handleSortChange}
                    searchPlaceholder={searchPlaceholder}
                    itemsLoading={this.props.employersLoading}
                    pullDownOnChange={this.handleDropDownChange}
                    pullDownList={this.state.pullDownOptionList}
                    pullDownPlaceholder={'All Segments'}
                    pullDownWidth={250}
                    ariaSelectLabel={'Select segment to filter by'}
                    filterLabel={'Filter by Segment'}
                  />
                </div>
                <ReportsTable title="Employer Reports" reports={reports} />
              </div>
            </main>
          </div>

          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

EmployerListPage.propTypes = {
  history: PropTypes.object.isRequired,
  employers: PropTypes.array.isRequired,
  createEmployer: PropTypes.func.isRequired,
  getEmployersAdvanced: PropTypes.func.isRequired,
  getEmployersStats: PropTypes.func.isRequired,
  updateEmployer: PropTypes.func.isRequired,
  deleteEmployer: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  employersLoading: PropTypes.bool.isRequired,
  getEmployerForEdit: PropTypes.func.isRequired,
  getEmployerReports: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  getSegments: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  createJob: PropTypes.func.isRequired,
  ascendRecruiters: PropTypes.array.isRequired,
  getAscendRecruiters: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    employers: state.employers.employers,
    stats: state.employers.stats,
    itemsPerPage: state.employers.itemsPerPage,
    totalItems: state.employers.totalItems,
    employersLoading: state.employers.employersLoading,
    currentPage: state.employersDataTable.currentPage,
    searchTerm: state.employersDataTable.searchTerm,
    sortBy: state.employersDataTable.sortBy,
    sortAscending: state.employersDataTable.sortAscending,
    reports: state.employers.reports,
    segments: state.segments.segmentList,
    permissions: state.profile.permissions,
    ascendRecruiters: state.ascendRecruiters.ascendRecruiters,
    profile: state.profile,
  };
}

const mapDispatchToProps = {
  ...employersActions,
  ...tableActions,
  ...segmentActions,
  ...jobsActions,
  ...ascendRecruitersActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerListPage)
);
