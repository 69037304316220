import React from 'react';

import {states} from '../utils/fieldHelper';
import SelectField from './SelectField';

class StateSelectField extends React.Component {
  render() {
    return (
      <SelectField
        {...this.props}
        options={states.map((v, i) => {
          return {
            value: v[0],
            label: v[1],
          };
        })}
      />
    );
  }
}

export default StateSelectField;
