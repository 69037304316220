import _ from 'lodash';
import merge from 'deepmerge';
import debounceAction from 'debounce-action';
import PropTypes from 'prop-types';

import * as types from './ActionTypes';
import {buildRemoteWorkList} from '../pages/jobDiscovery/roleUtil';

const apiUrl = process.env.REACT_APP_API_URL;

const filtersFromState = (state) => {
  const filters = {
    overallFit: state.overallFit,
    employmentTypeIds: [],
    experienceLevelIds: [],
    regionIds: [],
    roleTypeIds: [],
    industryIds: [],
    degreeTypeRankIds: [],
    segmentIds: [],
    remoteStatus: {},
  };

  if (state.jobsSearchTerm) {
    filters.searchTerm = state.jobsSearchTerm.trim();
    filters.searchFields = ['name', 'company', 'description'];
  }

  if (state.handpickedRolesSelected) {
    filters.handpicked = true;
  }

  if (state.invitedRolesSelected) {
    filters.invited = true;
  }

  if (state.workStyleMatchesSelected) {
    filters.workStylesFit = true;
  }

  filters.roleStartFlexibility = state.startDateTag;
  filters.employmentTypeIds = state.employmentTypeIds;
  filters.experienceLevelIds = state.experienceLevelIds;

  filters.regionIds = state.regionIds;
  filters.roleTypeIds = state.roleTypeIds;
  filters.degreeTypeRankIds = state.degreeTypeRankIds;
  filters.industryIds = state.industryIds;
  filters.remoteStatus = buildRemoteWorkList(state.remoteStatus);

  filters.jobCategoryIds = [];
  _.forEach(state.jobCategoryTags, (tagState, tagId) => {
    if (tagState.selected && tagState.name !== 'Any') {
      filters.jobCategoryIds.push(tagId);
    }
  });

  _.forEach(filters, (value, key) => {
    if (!value || value.length === 0) {
      delete filters[key];
    }
  });

  return filters;
};

export function updateAllJobSearchState(newState, clearWorkStyles = false) {
  return {
    type: types.ALL_JOB_SEARCH__UPDATE_STATE,
    newState,
    clearWorkStyles,
  };
}

export function getAllJobMatches(
  employerId,
  page = 0,
  itemsPerPage = 10,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push(`employerId=${employerId}`);
  p.push(`page=${page}`);
  p.push(`itemsPerPage=${itemsPerPage}`);
  p.push('includeMatchData');

  if (sortTerm !== '') {
    p.push(`sort=${sortTerm}`);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.ALL_JOB_SEARCH__GET_JOB_MATCHES_REQUEST,
      types.ALL_JOB_SEARCH__GET_JOB_MATCHES_SUCCESS,
      types.ALL_JOB_SEARCH__GET_JOB_MATCHES_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getEmployerJobs(employerId, newState = {}) {
  const overwriteMerge = (destinationArray, sourceArray, options) =>
    sourceArray;
  return (dispatch, getState) => {
    const finalState = merge(getState().allJobSearch, newState, {
      arrayMerge: overwriteMerge,
    });
    const filters = filtersFromState(finalState);

    dispatch({
      type: types.ALL_JOB_SEARCH__UPDATE_EMPLOYER_ID,
      selectedEmployerId: employerId,
    });
    dispatch(
      getAllJobMatches(
        employerId,
        finalState.jobsCurrentPage,
        finalState.jobsCurrentItemsPerPage,
        finalState.jobsSortTerm,
        filters
      )
    );
  };
}

const getMatchingJobsDebounced = debounceAction(getEmployerJobs, 1000);

export function changeAllJobPageAndSearch(employerId, page) {
  return (dispatch) => {
    const newState = {jobsCurrentPage: page};
    dispatch(updateAllJobSearchState(newState));
    dispatch(getEmployerJobs(employerId, newState));
  };
}

export function changeAllJobSortAndSearch(
  employerId,
  jobsSortTerm,
  jobSortBy,
  jobSortAsc
) {
  return (dispatch) => {
    const newState = {jobsSortTerm, jobSortBy, jobSortAsc, jobsCurrentPage: 0};
    dispatch(updateAllJobSearchState(newState));
    dispatch(getEmployerJobs(employerId, newState));
  };
}

export function changeAllJobSearchTermAndSearch(employerId, jobsSearchTerm) {
  return (dispatch) => {
    const newState = {jobsSearchTerm, jobsCurrentPage: 0};
    dispatch(updateAllJobSearchState(newState));
    dispatch(getEmployerJobs(employerId, newState));
  };
}

export function changeAllFilterTagAndSearch(employerId, group, name, selected) {
  return (dispatch, getState) => {
    let newState = {
      jobsCurrentPage: 0,
      [group]: {
        [name]: {
          selected,
        },
      },
    };

    if (
      [
        'roleTypeIds',
        'industryIds',
        'degreeTypeRankIds',
        'segmentIds',
        'employmentTypeIds',
        'experienceLevelIds',
        'regionIds',
      ].includes(group)
    ) {
      let lookupIds = getState().allJobSearch[group];
      let selectedId = parseInt(name, 10);
      if (lookupIds.indexOf(selectedId) === -1) {
        lookupIds.push(selectedId);
      }
      if (!selected) {
        lookupIds = lookupIds.filter((i) => i !== selectedId);
      }
      newState[group] = lookupIds;
    }

    dispatch(updateAllJobSearchState(newState));

    if (group === 'resultTypeTags') {
      if (name === 'showJobs' && selected) {
        dispatch(getMatchingJobsDebounced(employerId, newState));
      }
    } else {
      const {showJobs} = getState().allJobSearch.resultTypeTags;
      if (showJobs.selected) {
        dispatch(getMatchingJobsDebounced(employerId, newState));
      }
    }
  };
}

export function changeAllFilterRadioAndSearch(employerId, selected) {
  return (dispatch, getState) => {
    let newState = {
      jobsCurrentPage: 0,
      startDateTag: selected,
    };

    dispatch(updateAllJobSearchState(newState));

    const {showJobs} = getState().allJobSearch.resultTypeTags;
    if (showJobs.selected) {
      dispatch(getMatchingJobsDebounced(employerId, newState));
    }
  };
}

export function clearAllFilterGroupAndSearch(employerId, group) {
  return (dispatch, getState) => {
    const newState = {
      [group]: _.cloneDeep(getState().allJobSearch[group]),
    };
    _.forEach(newState[group], (value, key) => {
      if (
        [
          'roleTypeIds',
          'industryIds',
          'degreeTypeRankIds',
          'segmentIds',
          'employmentTypeIds',
          'experienceLevelIds',
          'regionIds',
        ].includes(group)
      ) {
        newState[group] = [];
      } else {
        newState[group][key].selected = false;
      }
      return true; // forEach stops iteration if you return false.
    });
    dispatch(updateAllJobSearchState(newState));
    const {showJobs} = getState().allJobSearch.resultTypeTags;
    if (showJobs.selected) {
      dispatch(getEmployerJobs(employerId, newState));
    }
  };
}

export const propTypes = {
  updateAllJobSearchState: PropTypes.func.isRequired,
  getEmployerJobs: PropTypes.func.isRequired,
  changeAllJobPageAndSearch: PropTypes.func.isRequired,
  changeAllJobSortAndSearch: PropTypes.func.isRequired,
  changeAllJobSearchTermAndSearch: PropTypes.func.isRequired,
  changeAllFilterTagAndSearch: PropTypes.func.isRequired,
  changeAllFilterRadioAndSearch: PropTypes.func.isRequired,
  clearAllFilterGroupAndSearch: PropTypes.func.isRequired,
  allJobSearchProps: PropTypes.object.isRequired,
};
