import * as types from '../actions/ActionTypes';
import {CANDIDATE_DISCOVERY} from 'src/pages/candidateDiscovery/candidateDiscoveryGlobals';

const initialState = {
  institutions: [],
  campuses: [],
  schools: [],
  majors: [],
  filteredMajors: [],
  minors: [],
  educationLevels: [],
  degreeTypes: [],
  industryPreferences: [],
  referralSources: [],
  reports: [],
  states: [],
  statesLoading: false,
  institutionsLoading: false,
  campusesLoading: false,
  fetchSchoolsForSingleCampus: false,
  schoolsLoading: false,
  degreeTypesLoading: false,
  institutionReportsLoading: false,
};

export default function institutions(state = initialState, action) {
  switch (action.type) {
    case types.INSTITUTIONS__GET_REQUEST:
      return {
        ...state,
        institutions: [],
        institutionsLoading: true,
      };
    case types.INSTITUTIONS__GET_REPORTS_REQUEST:
      return {
        ...state,
        reports: [],
        institutionReportsLoading: true,
      };
    case types.INSTITUTIONS__GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.response.body.reports,
        institutionReportsLoading: false,
      };
    case types.CAMPUSES__GET_REQUEST:
      return {...state, campuses: [], schools: [], campusesLoading: true};
    case types.DEGREETYPES__GET_REQUEST:
      return {...state, degreeTypes: [], degreeTypesLoading: true};
    case types.EDUCATIONLEVELS__GET_REQUEST:
      return {...state, educationLevels: [], educationLevelsLoading: true};
    case types.INDUSTRYPREFERENCES__GET_REQUEST:
      return {...state, industryPreferences: []};
    case types.REFERRALSOURCES__GET_REQUEST:
      return {...state, referralSources: []};
    case types.SCHOOLS__GET_REQUEST:
      return {...state, schools: [], schoolsLoading: true};
    case types.INSTITUTIONS__GET_SUCCESS:
      return {
        ...state,
        institutions: action.response.body.institutions,
        institutionsLoading: false,
      };
    case types.DEGREETYPES__GET_SUCCESS:
      return {
        ...state,
        degreeTypes: action.response.body.degreeTypes,
        degreeTypesLoading: false,
      };
    case types.EDUCATIONLEVELS__GET_SUCCESS:
      return {
        ...state,
        educationLevels: action.response.body.educationLevels,
        educationLevelsLoading: false,
      };
    case types.INDUSTRYPREFERENCES__GET_SUCCESS:
      return {
        ...state,
        industryPreferences: action.response.body.industryPreferences,
      };
    case types.REFERRALSOURCES__GET_SUCCESS:
      return {
        ...state,
        referralSources: action.response.body.referralSources,
      };
    case types.CAMPUSES__GET_SUCCESS:
      return {
        ...state,
        campuses: action.response.body.campuses,
        campusesLoading: false,
        fetchSchoolsForSingleCampus: action.response.body.campuses.length === 1,
        firstCampusId: action.response.body.campuses.length
          ? action.response.body.campuses[0].id
          : undefined,
      };
    case types.SCHOOLS__GET_SUCCESS:
      return {
        ...state,
        schools: action.response.body.schools,
        schoolsLoading: false,
        fetchSchoolsForSingleCampus: false,
      };
    case types.SCHOOLS__GET_FAILURE:
      return {
        ...state,
        schoolsLoading: false,
        fetchSchoolsForSingleCampus: false,
      };
    case types.MAJORS__GET_SUCCESS:
      return {
        ...state,
        majors: action.response.body.majors,
        filteredMajors: action.response.body.majors,
      };
    case types.MINORS__GET_SUCCESS:
      return {...state, minors: action.response.body.minors};
    case types.CANDIDATE_DISCOVERY__FILTER_LIST:
      if (action.filterType !== CANDIDATE_DISCOVERY.majorsFilter) {
        return state;
      }
      if (action.filterValue === '') {
        return {
          ...state,
          filteredMajors: state.majors,
        };
      }
      return {
        ...state,
        filteredMajors: state.majors.filter((major) =>
          major.name.toLowerCase().includes(action.filterValue.toLowerCase())
        ),
      };
    case types.CANDIDATE_DISCOVERY__CLEAR_ALL:
      return {...state, majors: state.majors};
    case types.STATES__GET_REQUEST:
      return {...state, states: [], statesLoading: true};
    case types.STATES__GET_SUCCESS:
      return {
        ...state,
        states: action.response.body.states,
        statesLoading: false,
      };
    default:
      return state;
  }
}
