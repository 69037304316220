import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import {Field} from 'react-final-form';
import {connect} from 'react-redux';
import {Row, Col, Container} from 'reactstrap';
import {
  actions as resumeActions,
  getSummaryForForm,
} from 'src/reducers/resumes';
import {makeRequest} from 'src/services/api';
import resumeStatusGif from 'src/assets/images/resume_status.gif';
import MaResumeField from 'src/formFields/MaResumeField';

const ResumeStatusBar = () => (
  <img src={resumeStatusGif} alt="resume status bar" />
);

@track({eventName: 'ModernApprenticeshipResumeUpload'}, {dispatchOnMount: true})
class ApplyToJobResumeUpload extends React.Component {
  state = {
    isUploading: false,
    isUploaded: false,
    uploadFailed: false,
    resumeIsParsing: true,
    resumeParsingTimedOut: false,
    resumeTimeoutId: null,
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  handleUploadError = (error) => {
    this.setState({
      uploadFailed: true,
      isUploading: false,
      isUploaded: false,
    });
    this.props.onUploadError(error);
  };

  checkResumeStatus = (uuid) => {
    if (!this.state.resumeIsParsing) {
      clearTimeout(this.state.resumeTimeoutId);
      return;
    }

    makeRequest({
      method: 'GET',
      path: `resumes/${uuid}/status`,
      isAuthenticated: false,
    }).then((response) => {
      if (!response.data.success) {
        // Wait and try again in a bit.
        this.setState({
          resumeTimeoutId: setTimeout(this.checkResumeStatus(uuid), 300),
        });
      } else {
        this.setState({resumeIsParsing: false});
      }
    });
  };

  handleUploadFinished = ({uuid}) => {
    this.setState({
      isUploading: false,
      isUploaded: true,
      uploadFailed: false,
      resumeTimeoutId: setTimeout(this.checkResumeStatus(uuid), 300),
    });
    this.props.onUploadCompleted();
  };

  handleUploadStarted = () => {
    this.setState({
      isUploading: true,
      isUploaded: false,
      uploadFailed: false,
    });
    this.props.onUploadStarted();
  };

  render() {
    const {
      isUploaded,
      isUploading,
      uploadFailed,
      resumeIsParsing,
      resumeParsingTimedOut,
    } = this.state;

    const renderStatus = () => {
      if (isUploading) {
        return (
          <div>
            Uploading resume...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (uploadFailed) {
        return (
          <span>
            Upload failed. Please try a different file. If this error persists,
            continue with this form and email your resume to{' '}
            <a href="mailto:support@ascendindiana.com">
              support@ascendindiana.com
            </a>
            .
          </span>
        );
      } else if (isUploaded && resumeIsParsing) {
        return (
          <div>
            Secure upload in process ...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (isUploaded && resumeParsingTimedOut) {
        return (
          <span>
            Your resume has been uploaded, but we could not gather data from it.
            Refresh this page to try again. If this error persists, you can
            continue with this form to manually enter your details.
          </span>
        );
      }
    };

    return (
      <Container>
        <Row className="justify-content-center">
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '300px',
            }}
          >
            <p className="disabled-body">Resumes must be smaller than 10MB.</p>
            <Field
              name="uploadedAppResume"
              initialValue={this.props.uploadedAppResume}
              component={MaResumeField}
              onStart={this.handleUploadStarted}
              onError={this.handleUploadError}
              onFinish={this.handleUploadFinished}
              fileTypes={new RegExp('.pdf$|.jpg$|.jpeg$|.png$|.heic$', 'i')}
              fileTypesAccept="application/pdf,image/jpeg,image/png,image/heic"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="normal-subheader bold padding-bottom-sixteen"
              style={{
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              {renderStatus()}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

ApplyToJobResumeUpload.propTypes = {
  onUploadCompleted: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired,
  onUploadStarted: PropTypes.func,
  resumeParsingTimedOut: PropTypes.bool.isRequired,
  uploadedAppResume: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    resumeParsingTimedOut: state.resumes.timedOut,
    resumeSummary: getSummaryForForm(state),
  };
};

const mapDispatchToProps = {
  ...resumeActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyToJobResumeUpload);
