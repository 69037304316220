import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

const minimalStyle = (props) => {
  return {
    indicatorsContainer: (styles) => ({
      ...styles,
      marginLeft: '-7px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'var(--neutral-gray-1)',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    singleValue: (styles, {isFocused}) => ({
      ...styles,
      color: 'var(--secondary-2)',
      fontWeight: 'bold',
      paddingRight: '5px',
      width: '100%',
      '&:hover': {
        color: 'var(--primary-accessibility)',
        cursor: 'pointer',
      },
    }),

    control: (styles) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: 'var(--neutral-gray-5)',
      width: props?.width,
    }),
    option: (styles, {isDisabled, isFocused}) => {
      const getBackgroundColor = () => {
        if (isFocused) return 'var(--secondary-2)';
        if (isDisabled) return null;

        return 'var(--neutral-white)';
      };
      const getForeColor = () => {
        if (isFocused) return 'var(--neutral-white)';
        if (isDisabled) return '#BEBEBE';

        return 'secondary-subheader';
      };
      return {
        ...styles,
        backgroundColor: getBackgroundColor(),
        color: getForeColor(),

        cursor: () => {
          if (isFocused) return 'pointer';
          if (isDisabled) return 'not-allowed';

          return 'default';
        },
      };
    },
  };
};

const colorStyles = (showBackground) => {
  if (showBackground) {
    return {
      control: (styles) => ({
        ...styles,
        backgroundColor: 'var(--neutral-gray-5)',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: 'var(--neutral-gray-2) ',
        textAlign: 'left',
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: '#696C6F',
      }),
    };
  } else {
    return {
      option: (styles, {isDisabled, isFocused, isSelected}) => {
        return {
          ...styles,

          backgroundColor: isDisabled
            ? null
            : isFocused
            ? '#3061a9'
            : isSelected
            ? 'white'
            : 'white',
          color: isDisabled
            ? '#BEBEBE'
            : isFocused
            ? 'white'
            : isSelected
            ? 'secondary-subheader'
            : 'secondary-subheader',
          cursor: isDisabled
            ? 'not-allowed'
            : isFocused
            ? 'pointer'
            : 'default',
        };
      },
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? 'var(--neutral-gray-8)'
          : 'var(--gray-aaa)',
        '&:hover': {
          borderColor: state.isFocused
            ? 'var(--neutral-gray-8)'
            : 'var(--gray-aaa)',
        },
      }),
      placeholder: (styles) => ({
        ...styles,
        color: 'var(--neutral-gray-2) ',
        textAlign: 'left',
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: 'var(--neutral-gray-1)',
      }),
    };
  }
};

class SelectField extends React.Component {
  findDefault(value, options, defaultValue) {
    const dValue = defaultValue ? defaultValue : null;
    return value ? _.find(options, {value}) : dValue;
  }

  findAriaLabel(value, options, defaultValue, label) {
    const selectedValue = this.findDefault(value, options, defaultValue);

    if (selectedValue) {
      return `option field ${label} with a selected value of ${selectedValue.label}`;
    }
    return `option field ${label}`;
  }

  render() {
    const buildLabel = (label) => {
      if (this.props.required) {
        return (
          <>
            {label}&nbsp;
            <span className="required-field">*</span>
          </>
        );
      }
      return label;
    };

    const {
      input: {value, onChange},
      meta,
      metaOverride,
      label,
      message,
      options,
      isLoading,
      isDisabled,
      defaultValue,
      labelCssClass,
      labelBold,
      showBackground,
      isSearchable,
      minimalistStyle,
      width,
    } = this.props;

    const {touched, error, warning} = {...meta, ...metaOverride};

    const labelCss = !labelCssClass
      ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
      : labelCssClass;

    return (
      <>
        <div className="form form-group" style={{width: '100%'}}>
          <div
            className={labelCss}
            id="selectFieldLabel"
            style={{marginBottom: '0.5rem'}}
          >
            {buildLabel(label)}
          </div>

          {this.props.subTextLabel && (
            <label
              style={{
                color: 'var(--neutral-gray-2)',
                fontSize: '14px',
              }}
            >
              {this.props.subTextLabel}
            </label>
          )}
          <Select
            id="selectField"
            className="clickable normal-subheader"
            value={this.findDefault(value, options, defaultValue)}
            onChange={(opt) => onChange(opt ? opt.value : opt)}
            options={options}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isSearchable={isSearchable}
            placeholder={this.props.placeholder}
            isClearable={this.props.isClearable}
            aria-label={this.findAriaLabel(value, options, defaultValue, label)}
            styles={
              minimalistStyle
                ? minimalStyle({width: width})
                : colorStyles(showBackground)
            }
          />
          {touched &&
            ((error && <div className="form-input-error">{error}</div>) ||
              (warning && (
                <div className="form-input-error">{warning}</div>
              )) || <div className="form-input-message">{message}</div>)}
          {!touched && <div className="form-input-message">{message}</div>}
        </div>
      </>
    );
  }
}

SelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  metaOverride: PropTypes.any,
  label: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.object,
  labelCssClass: PropTypes.string,
  labelBold: PropTypes.bool,
  showBackground: PropTypes.bool,
  isSearchable: PropTypes.bool,
  subTextLabel: PropTypes.string,
  minimalistStyle: PropTypes.bool,
  width: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
};

SelectField.defaultProps = {
  isLoading: false,
  isDisabled: false,
  message: ' ',
  isSearchable: true,
  subTextLabel: null,
  minimalistStyle: false,
  required: false,
  isClearable: false,
};

export default SelectField;
