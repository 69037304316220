import React, {Component, Fragment} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Row, Col, Container} from 'reactstrap';
import {TextAreaField} from 'src/formFields';
import ApplyToJobResumeUpload from './ApplyToJobResumeUpload';
class ApplyToJobPageTwo extends Component {
  state = {
    uploading: false,
  };

  validate = (values) => {
    const {applicationData} = this.props;

    const questions = [
      ...(applicationData.standardQuestions || []).map(
        (f) => `standardQuestions${f.id}`
      ),
      ...applicationData.additionalQuestions.map(
        (f) => `additionalQuestions${f.id}`
      ),
    ];

    const errors = questions
      .filter((q) => !values[q])
      .reduce((acc, f) => ({...acc, [f]: 'Required'}), {});

    if (applicationData.resumeRequired && !values.uploadedAppResume) {
      errors['uploadedAppResume'] = 'Required';
    }
    return errors;
  };

  renderQuestion = (q, labelPrefix) => {
    let name = `${labelPrefix}${q.id}`;
    return (
      <div key={`key-${name}`}>
        <Row>
          <Col>
            <Field
              name={name}
              initialValue={this.props.initialValues[name]}
              component={TextAreaField}
              label={q.questionText}
              maxLength={2000}
              showCounter={true}
              inputBackground="bd-graybackground"
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const {uploading, applyInProgress} = this.state;
    const {applicationData, onSubmit, isMobile} = this.props;

    return (
      <Fragment>
        <Form
          onSubmit={onSubmit}
          validate={this.validate}
          keepDirtyOnReinitialize={true}
          initialValues={{}}
        >
          {({handleSubmit, form}) => {
            return (
              <form>
                <Container className="padding-fortyeight">
                  {applicationData.standardQuestions.map((q) =>
                    this.renderQuestion(q, 'standardQuestions')
                  )}
                  {applicationData.additionalQuestions.map((q) =>
                    this.renderQuestion(q, 'additionalQuestions')
                  )}
                  {applicationData.resumeRequired && (
                    <Row className="padding-top-sixteen">
                      <Col>
                        <ApplyToJobResumeUpload
                          onUploadStarted={() =>
                            this.setState(() => ({uploading: true}))
                          }
                          onUploadCompleted={() =>
                            this.setState(() => ({
                              uploading: false,
                            }))
                          }
                          onUploadError={() =>
                            this.setState(() => ({uploading: false}))
                          }
                          uploadedAppResume={
                            this.props.initialValues.uploadedAppResume
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </Container>

                <div
                  className={`col-md-12 ${
                    isMobile ? 'd-flex justify-content-center flexColumn' : ''
                  } `}
                >
                  <button
                    type="button"
                    disabled={uploading || applyInProgress}
                    style={{
                      width: '210px',
                      height: '40px',
                      marginBottom: '10px',
                      marginRight: isMobile ? 'initial' : '40px',
                      alignSelf: 'center',
                    }}
                    className={`clickable btn btn-primary ${
                      isMobile ? '' : 'float-right'
                    }`}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button
                    disabled={uploading || applyInProgress}
                    className={`buttonAsLink mt-2 ${
                      isMobile ? '' : 'float-right me-3'
                    }`}
                    onClick={(e) => {
                      this.props.onPreviousPage(e, form.getState().values);
                    }}
                    style={{
                      width: 'fit-content',
                      alignSelf: 'center',
                    }}
                  >
                    Previous
                  </button>
                </div>
              </form>
            );
          }}
        </Form>
      </Fragment>
    );
  }
}

ApplyToJobPageTwo.propTypes = {
  applicationData: PropTypes.object,
  onPreviousPage: PropTypes.func.isRequired,
  resume: PropTypes.object,
  onSubmit: PropTypes.func,
  applyInProgress: PropTypes.bool,
  initialValues: PropTypes.object,
  isMobile: PropTypes.bool,
};

ApplyToJobPageTwo.defaultProps = {
  applicationData: {},
  resume: {},
};

const mapStateToProps = (state) => {
  return {
    applicationData: state.jobs.applicationData,
    applyInProgress: state.jobs.applyInProgress,
  };
};
export default connect(mapStateToProps)(ApplyToJobPageTwo);
