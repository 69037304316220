import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import {Line} from 'rc-progress';
import {Row, Col, Container} from 'reactstrap';
import * as jobActions from 'src/actions/jobs';
import * as questionActions from 'src/actions/questions';
import * as seekersActions from '../../actions/SeekerActions';
import ApplyToJobPageOne from '../../components/jobProfile/ApplyToJobPageOne';
import ApplyToJobPageTwo from '../../components/jobProfile/ApplyToJobPageTwo';
import ApplyToJobPageThree from '../../components/jobProfile/ApplyToJobPageThree';
import ApplyToJobHeader from '../../components/jobProfile/ApplyToJobHeader';
import PageHeader from '../../layout/PageHeader';
import track from 'react-tracking';

@track({eventName: 'ApplyToJobPage'}, {dispatchOnMount: true})
class ApplyToJobPage extends React.Component {
  state = {
    totalPages: 3,
    page: 1,
    jobId: undefined,
    applicationValues: {},
    isMobile: window.matchMedia('(max-width: 991px)').matches,
  };

  componentDidMount() {
    const {getApplicationData, match} = this.props;

    const jobId = parseInt(match.params.jobId, 10);
    this.setState(() => ({jobId: jobId}));

    getApplicationData(jobId);

    if (!this.props.seeker.id && !this.props.seekerLoading) {
      this.props.getSeekerAction(this.props.seekerId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.applicationValues.uploadedAppResume &&
      this.props.applicationData.resumeRequired &&
      this.props.seeker.resume &&
      this.props.seeker.resume.id !== '' &&
      !this.props.seekerLoading
    ) {
      this.setState({
        applicationValues: {
          uploadedAppResume: {
            uploadedFilename: this.props.seeker.resume.uploadedFilename,
            uuid: this.props.seeker.resume.uuid,
          },
        },
      });
    }
  }

  async handleFormSubmit(values) {
    const {seekerId, applicationData, applyToJobAsync} = this.props;
    const {jobId} = this.state;
    let submitData = {
      jobId: jobId,
      seekerId: seekerId,
    };

    this.setState({applicationValues: values});

    if (applicationData.resumeRequired) {
      submitData.resumeUuid = values.uploadedAppResume.uuid;
    }

    let answers = [
      ...applicationData.standardQuestions.map((q) => ({
        jobQuestionId: q.id,
        answerText: values[`standardQuestions${q.id}`],
      })),
    ];

    answers = [
      ...answers,
      ...applicationData.additionalQuestions.map((q) => ({
        jobQuestionId: q.id,
        answerText: values[`additionalQuestions${q.id}`],
      })),
    ];

    submitData['jobAnswers'] = answers;

    await applyToJobAsync(jobId, submitData);
    this.setState(() => ({page: 3}));
  }

  render() {
    const {loading, applicationData} = this.props;
    const {page, totalPages} = this.state;
    const {name: jobName} = applicationData;
    const employerName = (applicationData || {}).employerName;

    return (
      <DocumentTitle title="Job Application">
        <div className="wholepage">
          <PageHeader title="Job Application" />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <Container>
                <Row>
                  <Col className="offset-md-2 col-md-8">
                    <LoadingOverlay
                      active={loading || this.props.seekerLoading}
                      spinner={true}
                      text="Loading information ..."
                      fadeSpeed={200}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          height: '100vh',
                          position: 'fixed',
                        }),
                      }}
                    >
                      <span>&nbsp;</span>
                    </LoadingOverlay>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {Math.round((page / totalPages) * 100)}% Complete &nbsp;
                    <Line
                      percent={(page / totalPages) * 100}
                      strokeColor="#FF843E"
                      trailColor="#FFFFFF"
                      width="85%"
                    />
                  </Col>
                </Row>

                {page !== totalPages && (
                  <Row>
                    <Col>
                      <ApplyToJobHeader
                        headerText={jobName}
                        subHeaderText={employerName}
                      />
                    </Col>
                  </Row>
                )}

                {applicationData.description && (
                  <Row>
                    <Col className="bd-whitebackground-nopadding">
                      {page === 1 && (
                        <ApplyToJobPageOne
                          onNextPage={() => this.setState(() => ({page: 2}))}
                          isMobile={this.state.isMobile}
                        />
                      )}
                      {page === 2 && (
                        <ApplyToJobPageTwo
                          initialValues={this.state.applicationValues}
                          onPreviousPage={(e, values) => {
                            this.setState(() => ({
                              page: 1,
                              applicationValues: values,
                            }));
                          }}
                          onSubmit={(values) => this.handleFormSubmit(values)}
                          isMobile={this.state.isMobile}
                        ></ApplyToJobPageTwo>
                      )}
                      {page === 3 && (
                        <ApplyToJobPageThree isMobile={this.state.isMobile} />
                      )}
                    </Col>
                  </Row>
                )}
              </Container>
            </main>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ApplyToJobPage.propTypes = {
  loading: PropTypes.bool,
  applyToJobAsync: PropTypes.func,
  getApplicationData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  seekerId: PropTypes.string,
  handleSubmit: PropTypes.func,
  applicationData: PropTypes.object,
  seeker: PropTypes.object,
  seekerLoading: PropTypes.bool,
  getSeekerAction: PropTypes.func,
  getSeekerResumeAction: PropTypes.func,
};

ApplyToJobPage.defaultProps = {
  loading: false,
  job: {},
};

const mapStateToProps = (state) => {
  return {
    loading:
      state.jobs.applicationDataLoading ||
      state.questions.maStandardApplicationQuestionsLoading,
    applicationData: state.jobs.applicationData,
    seekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
  };
};

const mapDispatchToProps = {
  ...jobActions,
  ...questionActions,
  ...seekersActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplyToJobPage)
);
