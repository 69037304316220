import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

export class BusyButton extends React.Component {
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  state = {
    busy: false,
  };

  buttonDisplay = () => {
    if (this.isBusy()) {
      return (
        <Fragment>
          <span
            className={'align-middle spinner-border spinner-border-sm'}
            aria-hidden="true"
          />
          <span role="status">&nbsp;&nbsp;&nbsp;{this.props.busyText}</span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <i className={this.props.buttonIcon + ' nc-icon-glyph-med'} />
          &nbsp;&nbsp;{this.props.buttonText}
        </Fragment>
      );
    }
  };

  handleClick = async () => {
    if (this.props.onClick) {
      this.setState({busy: true});
      try {
        await this.props.onClick();
      } finally {
        if (!this._isMounted) return;
        this.setState({busy: false});
      }
    }
  };

  isBusy = () => {
    if (this.props.isLoading !== null) {
      return this.props.isLoading;
    }

    return this.state.busy;
  };

  render() {
    return (
      <button
        type={this.props.buttonType}
        className={
          (this.props.disabled || this.isBusy() ? '' : 'clickable') +
          ' btn btn-primary fullwidth ' +
          this.props.float +
          ' ' +
          this.props.alignText +
          this.props.className
        }
        style={this.props.style}
        onClick={this.handleClick}
        disabled={this.props.disabled || this.isBusy()}
      >
        {this.buttonDisplay()}
      </button>
    );
  }
}

BusyButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  busyText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonIcon: PropTypes.string,
  style: PropTypes.object,
  float: PropTypes.string,
  disabled: PropTypes.bool,
  alignText: PropTypes.string,
  className: PropTypes.string,
  buttonType: PropTypes.string,
  isLoading: PropTypes.bool,
};

BusyButton.defaultProps = {
  disabled: false,
  buttonType: 'button',
  isLoading: null,
};
