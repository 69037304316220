import * as types from './ActionTypes';
import _ from 'lodash';

const apiUrl = process.env.REACT_APP_API_URL;

export function createInvitation(invitation) {
  return {
    types: [
      types.INVITATIONS__CREATE_REQUEST,
      types.INVITATIONS__CREATE_SUCCESS,
      types.INVITATIONS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/invitations`,
    method: 'POST',
    body: invitation,
    payload: {invitation},
    failureMessage: 'Error creating invitation.',
  };
}

export function createMultipleInvitations(invitations) {
  return {
    types: [
      types.INVITATIONS__CREATE_REQUEST,
      types.INVITATIONS__CREATE_SUCCESS,
      types.INVITATIONS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/invitations/list`,
    method: 'POST',
    body: invitations,
    payload: {invitations},
    failureMessage: 'Error creating invitations.',
  };
}

export function getInvitations(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false
) {
  let uri = `${apiUrl}/invitations`;
  let p = [];

  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.INVITATIONS__GET_ALL_REQUEST,
      types.INVITATIONS__GET_ALL_SUCCESS,
      types.INVITATIONS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getInvitationReports() {
  return {
    types: ['', types.INVITATIONS__GET_REPORTS_SUCCESS, ''],
    uri: `${apiUrl}/invitations/read_reports`,
    method: 'GET',
  };
}
