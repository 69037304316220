import React from 'react';
import loading from 'src/assets/images/loading.gif';
const Loading = () => {
  return (
    <center>
      <br />
      <br />
      <br />
      <img src={loading} height="200" width="200" alt="" />
      <br />
      <br />
      <br />
    </center>
  );
};

export default Loading;
