import React from 'react';
import PropTypes from 'prop-types';
import {AscendModal, AscendModalBody} from 'src/layout';
import styles from './InvitesSentModal.module.scss';

export default function InvitesSentModal(props) {
  return (
    <AscendModal
      dialogClassName={`${styles.invitesSentModal}`}
      isOpen={props.isOpen}
      onClose={props.onClose}
      centerVertically={true}
    >
      <AscendModalBody hasClose={true}>
        <div
          className="padding-h32"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <h1 className="padding-bottom-twentyfour">Invitations Sent!</h1>
          <div className="padding-bottom-twentyfour">
            The batch of invitations you are attempting to send contain
            duplicates, listed below. We intentionally do not allow duplicate
            invitations to avoid sending our candidates repetitive emails. The
            duplicate invitations below have been removed and were not sent to
            the candidates.
          </div>
          {props.duplicateInvitations.map((duplicate, di) => {
            return (
              <div
                key={`${duplicate.jobName}-${di}`}
                style={{alignSelf: 'flex-start', textAlign: 'left'}}
              >
                <span className="bold">{duplicate.jobName}:</span>
                <ul className="padding-top-eight">
                  {duplicate.seekerViews.map((seeker, si) => {
                    return (
                      <li key={`${seeker.seekerName}-${di}-${si}`}>
                        {seeker.seekerName}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}

          <button
            type="button"
            className="clickable btn btn-primary"
            style={{
              marginTop: '16px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
            onClick={props.onClose}
          >
            Got it
          </button>
        </div>
      </AscendModalBody>
    </AscendModal>
  );
}

InvitesSentModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  duplicateInvitations: PropTypes.array,
};
