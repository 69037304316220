import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function getEducationData() {
  return (dispatch) => {
    dispatch(getInstitutions());
    dispatch(getDegreeTypes());
    dispatch(getEducationLevels());
    dispatch(getMajors());
    dispatch(getMinors());
  };
}

export function getInstitutions() {
  return {
    types: [
      types.INSTITUTIONS__GET_REQUEST,
      types.INSTITUTIONS__GET_SUCCESS,
      types.INSTITUTIONS__GET_FAILURE,
    ],
    uri: `${apiUrl}/institutions`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getDegreeTypes() {
  return {
    types: [
      types.DEGREETYPES__GET_REQUEST,
      types.DEGREETYPES__GET_SUCCESS,
      types.DEGREETYPES__GET_FAILURE,
    ],
    uri: `${apiUrl}/degree_types`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getEducationLevels() {
  return {
    types: [
      types.EDUCATIONLEVELS__GET_REQUEST,
      types.EDUCATIONLEVELS__GET_SUCCESS,
      types.EDUCATIONLEVELS__GET_FAILURE,
    ],
    uri: `${apiUrl}/v2/educationLevels`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getReferralSources() {
  return {
    types: [
      types.REFERRALSOURCES__GET_REQUEST,
      types.REFERRALSOURCES__GET_SUCCESS,
      types.REFERRALSOURCES__GET_FAILURE,
    ],
    uri: `${apiUrl}/referral_sources`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getIndustryPreferences() {
  return {
    types: [
      types.INDUSTRYPREFERENCES__GET_REQUEST,
      types.INDUSTRYPREFERENCES__GET_SUCCESS,
      types.INDUSTRYPREFERENCES__GET_FAILURE,
    ],
    uri: `${apiUrl}/industry_preferences`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getStates() {
  return {
    types: [
      types.STATES__GET_REQUEST,
      types.STATES__GET_SUCCESS,
      types.STATES__GET_FAILURE,
    ],
    uri: `${apiUrl}/v2/states`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getCampuses(institutionId) {
  return {
    types: [
      types.CAMPUSES__GET_REQUEST,
      types.CAMPUSES__GET_SUCCESS,
      types.CAMPUSES__GET_FAILURE,
    ],
    uri: `${apiUrl}/campuses?institutionId=${institutionId}`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getSchools(campusId) {
  return {
    types: [
      types.SCHOOLS__GET_REQUEST,
      types.SCHOOLS__GET_SUCCESS,
      types.SCHOOLS__GET_FAILURE,
    ],
    uri: `${apiUrl}/schools?campusId=${campusId}`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getMajors() {
  return {
    types: [
      types.MAJORS__GET_REQUEST,
      types.MAJORS__GET_SUCCESS,
      types.MAJORS__GET_FAILURE,
    ],
    uri: `${apiUrl}/majors`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getMinors() {
  return {
    types: [
      types.MINORS__GET_REQUEST,
      types.MINORS__GET_SUCCESS,
      types.MINORS__GET_FAILURE,
    ],
    uri: `${apiUrl}/minors`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getInstitutionReports() {
  return {
    types: [
      types.INSTITUTIONS__GET_REPORTS_REQUEST,
      types.INSTITUTIONS__GET_REPORTS_SUCCESS,
      types.INSTITUTIONS__GET_REPORTS_FAILURE,
    ],
    uri: `${apiUrl}/institutions/reports`,
    method: 'GET',
  };
}
