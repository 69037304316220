import React from 'react';
import styles from './OutlineButton.module.scss';
import PropTypes from 'prop-types';

export default function OutlineButton({
  buttonText,
  onClick,
  className,
  buttonGlyph,
  style,
  type,
}) {
  return (
    <button
      className={`${styles.outlineButton} ${className}`}
      onClick={onClick}
      style={style}
      type={type}
    >
      {buttonGlyph}&nbsp;&nbsp;{buttonText}&nbsp;&nbsp;
    </button>
  );
}

OutlineButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonGlyph: PropTypes.any,
  style: PropTypes.any,
  type: PropTypes.string,
};

OutlineButton.defaultProps = {
  type: 'submit',
};
