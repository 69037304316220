import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

import {getEmploymentTypes} from 'src/actions/employmentTypes';
import {getStates} from 'src/actions/institutions';

import {getSeekerApplications} from 'src/actions/seekers';
import {getAllEmployers} from 'src/actions/employers';
import {getJobsByEmployer} from 'src/actions/jobs';

import {Form, Field} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';

import {TextField} from 'src/formFields';
import CheckboxField from 'src/formFields/CheckboxField';

import ContentLoader from 'react-content-loader';
import Select from 'react-select';

import * as roleSelectionTypeOptions from 'src/pages/admin/connections/CreateConnectionConstants';

export default function CreateConnectionSelectRole(props) {
  const dispatch = useDispatch();

  const selectStyles = {
    option: (styles, {isFocused, isSelected, isDisabled}) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? 'var(--secondary-3)'
          : isSelected
          ? 'var(--secondary-2)'
          : isDisabled
          ? '#f2f2f2'
          : 'white',
        color: isFocused
          ? 'secondary-subheader'
          : isSelected
          ? 'white'
          : 'secondary-subheader',
        cursor: isFocused ? 'pointer' : 'default',
      };
    },
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
      borderColor: 'var(--gray-aaa)',
    }),
  };

  const {isNetworkConnection, onChange, role, onSubmit, seeker, isTask} = props;

  let employmentTypes = useSelector(
    (state) => state.employmentTypes.employmentTypes
  );
  let employmentTypesLoading = useSelector(
    (state) => state.employmentTypes.employmentTypesLoading
  );

  let states = useSelector((state) => state.institutions.states);
  let statesLoading = useSelector((state) => state.institutions.statesLoading);

  let applications = useSelector((state) => state.seekers.applications);
  let applicationLoadComplete = useSelector(
    (state) => state.seekers.applicationLoadComplete
  );

  let applicationsLoading = useSelector(
    (state) => state.seekers.applicationsLoading
  );

  let employers = useSelector((state) => state.employers.allEmployers);
  let employersLoading = useSelector(
    (state) => state.employers.employersLoading
  );

  let jobs = useSelector((state) => (!role.employerId ? [] : state.jobs.jobs));
  let jobsLoading = useSelector((state) => state.jobs.jobsLoading);

  let employmentTypeOptions = employmentTypes.map((x) => {
    return {value: x.id, label: x.name};
  });

  let stateOptions = states.map((x) => {
    return {value: x.id, label: x.code};
  });

  let applicationOptions = applications.map((x) => {
    return {
      value: x.id,
      label: (
        <>
          <b>{x.name}</b> - {x.employerName} - Applied on{' '}
          {new Date(x.appliedDate).toLocaleDateString()}
        </>
      ),
    };
  });

  let employerOptions = employers.map((x) => {
    return {value: x.id, label: x.name};
  });

  let jobOptions = jobs.map((x) => {
    return {
      value: x.id,
      label: (
        <>
          <b>{x.name}</b> - Published on{' '}
          {new Date(x.publishedAt).toLocaleDateString()}
        </>
      ),
    };
  });

  const updateRoleList = (employerId) => {
    if (!jobsLoading) {
      let d = new Date();
      d.setFullYear(d.getFullYear() - 2);
      dispatch(
        getJobsByEmployer(
          employerId,
          isTask ? '' : d.toISOString(),
          isTask,
          isTask ? 1000 : ''
        )
      );
    }
  };

  const shouldLoadNetworkRoles = () => {
    return (
      (jobs.length === 0 ||
        jobs.some((x) => x.employerId !== role.employerId)) &&
      !jobsLoading &&
      role.employerId &&
      role.jobId &&
      role.selectionType === roleSelectionTypeOptions.JOBSEARCH
    );
  };

  const loadInitialNetworkRoleData = () => {
    if (
      applications.length === 0 &&
      !applicationsLoading &&
      !applicationLoadComplete
    ) {
      dispatch(getSeekerApplications(seeker.id));
    }

    if (employers.length === 0 && !employersLoading) {
      dispatch(getAllEmployers());
    }

    if (shouldLoadNetworkRoles()) {
      updateRoleList(role.employerId);
    }
  };

  const loadInitialData = () => {
    if (employmentTypes.length === 0 && !employmentTypesLoading) {
      dispatch(getEmploymentTypes());
    }

    if (isNetworkConnection) {
      loadInitialNetworkRoleData();
    }

    if (
      (!isNetworkConnection ||
        role.selectionType === roleSelectionTypeOptions.MANUAL) &&
      states.length === 0 &&
      !statesLoading
    ) {
      dispatch(getStates());
    }
  };

  useEffect(() => {
    loadInitialData();
  });

  useEffect(() => {
    if (
      applicationLoadComplete &&
      applications.length === 0 &&
      role.selectionType === roleSelectionTypeOptions.APPLIEDTOSELECT
    ) {
      onChange({
        ...role,
        selectionType: roleSelectionTypeOptions.JOBSEARCH,
      });
    }
  }, [applications, applicationLoadComplete, role, onChange]);

  const displayLoading = useMemo(() => {
    if (
      applicationsLoading &&
      role.selectionType === roleSelectionTypeOptions.APPLIEDTOSELECT
    ) {
      return true;
    }

    if (isTask && jobsLoading) {
      return true;
    }

    return false;
  }, [applicationsLoading, jobsLoading, role.selectionType, isTask]);

  const renderContentLoader = () => {
    return (
      <ContentLoader
        speed={3}
        height={110}
        width={'100%'}
        backgroundColor="#f1f4f9"
        foregroundColor="#cbe1ff"
        style={{
          backgroundColor: '#ffffff',
        }}
      >
        <rect x="0" y="12" rx="3" ry="3" width="100%" height="35" />
        <rect x="0" y="75" rx="3" ry="3" width="100%" height="35" />
      </ContentLoader>
    );
  };

  const validate = (values) => {
    const errors = {};

    if (
      values['isRemote'] === false &&
      (!values['zipCode'] || values['zipCode'] === '')
    ) {
      errors[`zipCode`] = 'If role is not remote, ZIP Code is required';
    }

    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({form}) => {
        return (
          <form>
            <OnChange name="employerName">
              {(newValue) => {
                onChange({
                  ...role,
                  employerName: newValue,
                });
              }}
            </OnChange>
            <OnChange name="jobName">
              {(newValue) => {
                onChange({
                  ...role,
                  jobName: newValue,
                });
              }}
            </OnChange>

            <OnChange name="city">
              {(newValue) => {
                onChange({
                  ...role,
                  city: newValue,
                });
              }}
            </OnChange>
            <OnChange name="zipCode">
              {(newValue) => {
                onChange({
                  ...role,
                  zipCode: newValue,
                });
              }}
            </OnChange>

            <OnChange name="isRemote">
              {(newValue) => {
                form.change('city', '');
                form.change('zipCode', '');

                onChange({
                  ...role,
                  isRemote: newValue,
                  state: null,
                });
              }}
            </OnChange>

            {(!isNetworkConnection ||
              role.selectionType === roleSelectionTypeOptions.MANUAL) && (
              <>
                <Field
                  name="employerName"
                  component={TextField}
                  label="Company"
                  initialValue={role.employerName}
                  autoFocus={true}
                />

                <Field
                  name="jobName"
                  component={TextField}
                  label="Role Title"
                  initialValue={role.jobName}
                />

                <Field name="employmentType">
                  {(props) => (
                    <label className="normal-body">
                      Role Type
                      <Select
                        styles={selectStyles}
                        className="clickable normal-subheader mt-2"
                        value={role.employmentType}
                        onChange={(e) => {
                          onChange({...role, employmentType: e});
                        }}
                        options={employmentTypeOptions}
                        isLoading={employmentTypesLoading}
                      />
                    </label>
                  )}
                </Field>

                <div className="mb-3 mt-3">
                  <Field
                    name="isRemote"
                    component={CheckboxField}
                    initialValue={role.isRemote}
                    label={
                      <span className="normal-body">This role is remote</span>
                    }
                  />
                </div>

                <Field
                  name="city"
                  component={TextField}
                  label="City"
                  disabled={role.isRemote}
                  initialValue={role.city}
                  inputType="text"
                />

                <Field name="state">
                  {(props) => (
                    <label className="normal-body pb-3">
                      State
                      <Select
                        styles={selectStyles}
                        className="clickable normal-subheader mt-2"
                        value={role.state}
                        onChange={(e) => {
                          onChange({...role, state: e});
                        }}
                        options={stateOptions}
                        isDisabled={role.isRemote}
                        isLoading={statesLoading}
                      />
                    </label>
                  )}
                </Field>

                <Field
                  name="zipCode"
                  component={TextField}
                  label="ZIP Code"
                  disabled={role.isRemote}
                  initialValue={role.zipCode}
                />
              </>
            )}

            {displayLoading && renderContentLoader()}

            {!displayLoading &&
              isNetworkConnection &&
              role.selectionType ===
                roleSelectionTypeOptions.APPLIEDTOSELECT && (
                <div>
                  <Field name="application">
                    {(props) => (
                      <label className="normal-body">
                        Select the job from the job seeker's list of
                        applications:
                        <Select
                          styles={selectStyles}
                          className="clickable normal-subheader"
                          value={applicationOptions.find(
                            ({value}) => value === role.jobId
                          )}
                          onChange={(e) => {
                            const application = applications.find(
                              ({id}) => id === e.value
                            );

                            onChange({
                              ...role,
                              employerId: application.employerId,
                              employerName: application.employerName,
                              jobId: application.id,
                              jobName: application.name,
                              jobZipCode: application.postalCode,
                            });
                          }}
                          options={applicationOptions}
                          isLoading={applicationsLoading}
                          autoFocus
                        />
                      </label>
                    )}
                  </Field>

                  <Field name="applicationEmploymentType">
                    {(props) => (
                      <label className="normal-body">
                        Role Type
                        <Select
                          styles={selectStyles}
                          className="clickable normal-subheader mt-2"
                          value={role.employmentType}
                          onChange={(e) => {
                            onChange({...role, employmentType: e});
                          }}
                          options={employmentTypeOptions}
                          isLoading={employmentTypesLoading}
                        />
                      </label>
                    )}
                  </Field>

                  <div
                    className="normal-body"
                    aria-label="Select from all jobs"
                  >
                    Don't see their application?{' '}
                    <button
                      className="buttonAsLink"
                      type="button"
                      onClick={() =>
                        onChange({
                          ...role,
                          selectionType: roleSelectionTypeOptions.JOBSEARCH,
                        })
                      }
                    >
                      Select from all jobs.
                    </button>
                  </div>
                </div>
              )}

            {!displayLoading &&
              isNetworkConnection &&
              role.selectionType === roleSelectionTypeOptions.JOBSEARCH && (
                <div>
                  <div>
                    <Field name="employer">
                      {(props) => (
                        <label className="normal-body">
                          Choose an employer from the list of all companies
                          below to which you'd like to report a connection:
                          <Select
                            styles={selectStyles}
                            className="clickable normal-subheader"
                            value={employerOptions.find(
                              ({value}) => value === role.employerId
                            )}
                            onChange={(e) => {
                              onChange({
                                ...role,
                                employerId: e.value,
                                employerName: e.label,
                                jobId: null,
                                jobName: '',
                              });
                              updateRoleList(e.value);
                            }}
                            options={employerOptions}
                            isLoading={employersLoading}
                            autoFocus
                          />
                        </label>
                      )}
                    </Field>
                  </div>

                  <div>
                    <Field name="role">
                      {(props) => (
                        <label className="normal-body">
                          Choose a role from the list of roles below to which
                          you'd like to report a connection:
                          <Select
                            styles={selectStyles}
                            className="clickable normal-subheader"
                            value={jobOptions.find(
                              ({value}) => value === role.jobId
                            )}
                            onChange={(e) => {
                              const selectedJob = jobs.find(
                                ({id}) => id === e.value
                              );

                              onChange({
                                ...role,
                                jobId: e.value,
                                jobName: selectedJob.name,
                                jobZipCode: selectedJob.postalCode,
                              });
                            }}
                            options={jobOptions}
                            isLoading={jobsLoading}
                          />
                        </label>
                      )}
                    </Field>
                  </div>

                  <Field name="jobSearchEmploymentType">
                    {(props) => (
                      <label className="normal-body">
                        Role Type
                        <Select
                          styles={selectStyles}
                          className="clickable normal-subheader"
                          value={role.employmentType}
                          onChange={(e) => {
                            onChange({...role, employmentType: e});
                          }}
                          options={employmentTypeOptions}
                          isLoading={employmentTypesLoading}
                        />
                      </label>
                    )}
                  </Field>

                  <div className="normal-body" aria-label="Enter role manually">
                    Can't find the job?{' '}
                    <button
                      className="buttonAsLink"
                      type="button"
                      onClick={() => {
                        onChange({
                          ...role,
                          employerId: null,
                          jobId: null,
                          selectionType: roleSelectionTypeOptions.MANUAL,
                        });
                      }}
                    >
                      Enter it manually
                    </button>
                  </div>
                </div>
              )}
          </form>
        );
      }}
    </Form>
  );
}

CreateConnectionSelectRole.propTypes = {
  onChange: PropTypes.func.isRequired,
  isNetworkConnection: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  role: PropTypes.object,
  seeker: PropTypes.object,
  isTask: PropTypes.bool,
};
