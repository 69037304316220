import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import {TextField} from 'src/formFields';
import ReactQuill, {Quill} from 'react-quill';
import styles from '../pages/onboarding2.0/onboarding.module.scss';

class Counter {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.container = document.querySelector(options.container);
    quill.on('text-change', this.update.bind(this));
    this.update(); // Account for initial contents
  }

  calculate() {
    let text = this.quill.getText().trim();
    if (this.options.unit === 'word') {
      text = text.trim();
      return text.length > 0 ? text.split(/\s+/).length : 0;
    } else {
      return text.length;
    }
  }

  update() {
    var length = this.calculate();
    var label = this.options.unit;
    if (length !== 1) {
      label += 's';
    }
    this.container.innerText = length + ' ' + label;
  }
}
Quill.register('modules/counter', Counter);

const renderField = ({
  input,
  meta: {touched, error, warning},
  placeholder,
  label,
  fieldIndex,
  setHasError,
  otherFieldHasError,
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor={label + 'Input' + fieldIndex}
          className="col-md-3 col-form-label onboarding-form-label"
        >
          {label}
        </label>
      )}
      <input
        {...input}
        name={label + 'Input' + fieldIndex}
        id={label + 'Input' + fieldIndex}
        type="text"
        size={26}
        className="form-control onboarding-text-box col-md-8"
        placeholder={placeholder}
      />

      {touched && error && (
        <div className="form-input-error nowrap col-9 offset-3 text-align-right">
          {error}
        </div>
      )}
      {!touched && <div className="form-input-error">&nbsp;</div>}
    </>
  );
};

renderField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

const RenderTextArea = ({
  input,
  meta: {touched, error, warning},
  label,
  index,
}) => {
  const modules = {
    keyboard: {bindings: {tab: false}},
    counter: {
      container: `#counter${index}`,
      unit: 'character',
    },
    toolbar: [
      ['bold', 'italic'],
      [{list: 'ordered'}, {list: 'bullet'}],
    ],
  };

  const formats = ['bold', 'italic', 'list', 'bullet'];

  /*
  Generally I think using state at this level is bad practice.
  However, I was running into issues with the Quill editor not
  allowing input when a new experience was added via the "Add Another"
  button. From looking at the below github issue, others solved
  the same problem by using local state. It worked in this case as well

  https://github.com/quilljs/quill/issues/1940
  */
  const [value, setValue] = useState(input.value);

  useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  return (
    <>
      {label && (
        <label
          htmlFor={label + 'Input' + index}
          className="col-form-label onboarding-form-label"
        >
          {label}
        </label>
      )}

      <ReactQuill
        value={value}
        modules={modules}
        formats={formats}
        onChange={(e) => {
          setValue(e);
          input.onChange(e);
        }}
        className={styles.editor}
        defaultValue={''}
        tabIndex={0}
      />
      <div id={`counter${index}`} className="secondary-caption">
        0
      </div>
      {touched && error && (
        <div className="nowrap form-input-error">{error}</div>
      )}
      {!touched && (
        <div className="nowrap form-input-error col-3 offset-3">&nbsp;</div>
      )}
    </>
  );
};

RenderTextArea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  index: PropTypes.number,
};

class ExperienceFieldArray extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fieldHasError: false,
    };
  }

  componentDidMount() {
    if (this.props.fields.length === 0) {
      this.handleAddField();
    }
  }

  handleAddField = () => {
    const {fields, maxFields} = this.props;
    if (fields.length === maxFields) return;
    fields.push();
  };

  handleDoRemove = (index) => {
    this.props.fields.remove(index);
  };

  handleMoveDown = (index) => {
    this.props.fields.swap(index, index + 1);
  };

  handleMoveUp = (index) => {
    this.props.fields.swap(index, index - 1);
  };

  required = (value) =>
    !this.props.isResumeRequired || value ? undefined : 'Required';

  render() {
    const {fields, maxFields} = this.props;
    return (
      <>
        <div className="form form-group col" style={{textAlign: 'left'}}>
          {fields.map((name, index) => {
            return (
              <Fragment key={index}>
                <div>
                  {index !== 0 && (
                    <span
                      className="normal-subheader float-right padding-left-sixteen"
                      tabIndex={0}
                      role="button"
                      aria-label="Delete Entry"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          this.handleDoRemove(index);
                        }
                      }}
                      onClick={() => this.handleDoRemove(index)}
                    >
                      <i className="nc-icon-glyph-med remove" />
                    </span>
                  )}

                  {index !== fields.length - 1 && (
                    <span
                      className="normal-subheader float-right padding-left-sixteen"
                      tabIndex={0}
                      role="button"
                      aria-label="Move Work Experience Down"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          this.handleMoveDown(index);
                        }
                      }}
                      onClick={() => this.handleMoveDown(index)}
                    >
                      <i className="nc-icon-glyph-med down_arrow" />
                    </span>
                  )}

                  {index !== 0 && (
                    <span
                      className="normal-subheader float-right"
                      tabIndex={0}
                      role="button"
                      aria-label="Move Work Experience Up"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          this.handleMoveUp(index);
                        }
                      }}
                      onClick={() => this.handleMoveUp(index)}
                    >
                      <i className="nc-icon-glyph-med up_arrow" />
                    </span>
                  )}
                </div>
                <Field
                  name={`${name}.company`}
                  otherFieldHasError={this.state.fieldHasError}
                  setHasError={(error) => {
                    this.setState({fieldHasError: error});
                  }}
                  component={TextField}
                  labelCssClass={'onboarding-form-label'}
                  placeholder="Employer"
                  label="Employer"
                  validate={this.required}
                />
                <Field
                  name={`${name}.title`}
                  otherFieldHasError={this.state.fieldHasError}
                  setHasError={(error) => {
                    this.setState({fieldHasError: error});
                  }}
                  component={TextField}
                  labelCssClass={'onboarding-form-label'}
                  placeholder="Title"
                  label="Title"
                  validate={this.required}
                />
                <Field
                  name={`${name}.timeframe`}
                  otherFieldHasError={this.state.fieldHasError}
                  setHasError={(error) => {
                    this.setState({fieldHasError: error});
                  }}
                  component={TextField}
                  labelCssClass={'onboarding-form-label'}
                  placeholder="Time Frame (Month Year - Month Year)"
                  label="Time Frame"
                  validate={this.required}
                />
                <Field
                  name={`${name}.notes`}
                  component={RenderTextArea}
                  labelCssClass={'onboarding-form-label'}
                  placeholder="Description of your experience"
                  label="Description"
                  validate={this.required}
                  index={index}
                />
                <hr />
              </Fragment>
            );
          })}
          <div className="row">
            {fields.length < maxFields && (
              <button
                type="button"
                className="clickable inlineblock btn-transparent-green col-md-4"
                onClick={this.handleAddField}
              >
                + add another
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

ExperienceFieldArray.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  fields: PropTypes.object,
  maxFields: PropTypes.number,
  isResumeRequired: PropTypes.bool,
};

export default ExperienceFieldArray;
