import * as types from '../actions/ActionTypes';
import merge from 'deepmerge';

const initialState = {
  searchTerm: '',
  itemsPerPage: 20,
  currentPage: 0,
  totalItems: 0,
  jobId: 0,
  apprenticeships: true,
  nonApprenticeships: true,
  sortTerm: '-applicationDate',
  updatingStatus: false,
  applicationListing: [],
  applicationListingLoading: false,
  applicationListTotalItems: 0,
  employerId: null,
};

export default function jobAppBrowse(state = initialState, action) {
  switch (action.type) {
    case types.JOBS_APPLICATION_BROWSE__UPDATE_STATE:
      return merge(state, action.newState);
    case types.JOBS_APPLICATION_BROWSE__UPDATE_STATUS_REQUEST:
      return {...state, updatingStatus: true};
    case types.JOBS_APPLICATION_BROWSE__UPDATE_STATUS_SUCCESS:
      return {...state, updatingStatus: false};
    case types.JOBS_APPLICATION_BROWSE__UPDATE_STATUS_FAILURE:
      return {...state, updatingStatus: false};
    case types.JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_REQUEST:
      return {
        ...state,
        applicationListing: state.applicationListing.map((current) => {
          if (
            current.jobApplicationId === action.payload.jobApplicationId &&
            current.jobApplicantId === action.payload.jobApplicantId
          ) {
            return {
              ...current,
              receivedInterview: action.payload.receivedInterview,
            };
          }
          return current;
        }),
      };
    case types.JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_SUCCESS:
      return {...state};
    case types.JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_FAILURE:
      return {
        ...state,
        applicationListing: state.applicationListing.map((current) => {
          if (
            current.jobApplicationId === action.payload.jobApplicationId &&
            current.jobApplicantId === action.payload.jobApplicantId
          ) {
            return {
              ...current,
              receivedInterview: !current.receivedInterview,
            };
          }
          return current;
        }),
      };
    case types.JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_REQUEST:
      return {...state, applicationListingLoading: true};
    case types.JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_FAILURE:
      return {...state, applicationListingLoading: false};
    case types.JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_SUCCESS:
      return {
        ...state,
        applicationListingLoading: false,
        applicationListing: action.response.body.applications,
        applicationListTotalItems: action.response.body.totalCount,
      };
    case types.JOBS_APPLICATION_BROWSE__UPDATE_APPLICATION_LISTING_STATUS:
      const ud = action.updateData;
      let updatedListing = [...state.applicationListing];
      let record = updatedListing.find(
        (x) =>
          x.jobApplicationId === ud.jobApplicationId &&
          x.jobApplicantId === ud.jobApplicantId
      );
      record.statusId = Number(ud.statusId);

      return {...state, applicationListing: updatedListing};

    default:
      return state;
  }
}
