import * as types from './ActionTypes';

export const setJobIdFilter = (jobObj, filterTag) => (dispatch) => {
  if (filterTag === 'interested') {
    dispatch({
      type: types.CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT,
      toggledName: 'interested-true#Interested',
    });
  } else if (filterTag === 'applied') {
    dispatch({
      type: types.CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT,
      toggledName: 'applied-true#Applied',
    });
  } else if (filterTag === 'invited') {
    dispatch({
      type: types.CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT,
      toggledName: 'invited-true#Invited',
    });
  } else {
    dispatch({
      type: types.CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT,
      toggledName: null,
    });
  }
  if (jobObj === null) {
    dispatch({type: types.JOBS__CLEAR_SELECTED_JOB});
  }
  dispatch({
    type: types.CANDIDATE_DISCOVERY__JOB_SELECT,
    jobObj,
    filterTag,
  });
};

export const setWatchingTeamIdFilter = (watchingTeamId) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__TEAM_SELECT,
    watchingTeamId: watchingTeamId,
  });
};

export const setSavedCandidateJobIdFilter =
  (jobObj, filterTag) => (dispatch) => {
    if (jobObj === null) {
      dispatch({type: types.JOBS__CLEAR_SELECTED_JOB});
    }
    dispatch({
      type: types.SAVED_CANDIDATE__JOB_SELECT,
      savedCandidatesJobObj: jobObj,
      filterTag,
    });
  };

export const setCandidateSorting = (sortTerm) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__SORT_CHANGE,
    sortTerm: sortTerm,
  });
};

export const setCandidateCardsPage = (page) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__CHANGE_PAGE,
    page: page,
  });
};

export const setSearchTerm = (term) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__SEARCH_TERM,
    searchTerm: term,
  });
};

export const setPreselectJobRequest = (loading) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__PRESELECT_JOB_REQUEST_LOADING,
    loading: loading,
  });
};

export const setApplyButtonEnabled = (enabled) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__SET_FILTER_BUTTON_ENABLED,
    applyButtonEnabled: enabled,
  });
};

export const setClearFilterButtonEnabled = (enabled) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__SET_CLEAR_FILTER_BUTTON_ENABLED,
    clearAllFiltersEnabled: enabled,
  });
};

export const setApplyButtonBusy = (enabled) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__SET_APPLY_BUSY,
    applyButtonBusy: enabled,
  });
};

export const setCollapseAllFilterSections = (collapsed) => (dispatch) => {
  dispatch({
    type: types.CANDIDATE_DISCOVERY__SET_COLLAPSE_ALL_FILTER_SECTIONS,
    collapseAllFilterSections: collapsed,
  });
};

export const setSavedCandidateSorting = (sortTerm) => (dispatch) => {
  dispatch({
    type: types.SAVED_CANDIDATE__SORT_CHANGE,
    sortTerm: sortTerm,
  });
};

export const setSavedCandidateCardsPage = (page) => (dispatch) => {
  dispatch({
    type: types.SAVED_CANDIDATE__CHANGE_PAGE,
    page: page,
  });
};

export const setSavedCandidatesSearchTerm = (term) => (dispatch) => {
  dispatch({
    type: types.SAVED_CANDIDATE__SEARCH_TERM,
    searchTerm: term,
  });
};

export const setSavedCandidatesApplyButtonEnabled = (enabled) => (dispatch) => {
  dispatch({
    type: types.SAVED_CANDIDATE__SET_FILTER_BUTTON_ENABLED,
    applyButtonEnabled: enabled,
  });
};

export const setSavedCandidateClearFilterButtonEnabled =
  (enabled) => (dispatch) => {
    dispatch({
      type: types.SAVED_CANDIDATE__SET_CLEAR_FILTER_BUTTON_ENABLED,
      clearAllFiltersEnabled: enabled,
    });
  };

export const setSavedCandidatesApplyButtonBusy = (enabled) => (dispatch) => {
  dispatch({
    type: types.SAVED_CANDIDATE__SET_APPLY_BUSY,
    applyButtonBusy: enabled,
  });
};

export const setSavedCandidatesCollapseAllFilterSections =
  (collapsed) => (dispatch) => {
    dispatch({
      type: types.SAVED_CANDIDATE__SET_COLLAPSE_ALL_FILTER_SECTIONS,
      collapseAllFilterSections: collapsed,
    });
  };
