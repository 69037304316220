import React, {useState} from 'react';
import styles from './TimelineButton.module.scss';
import PropTypes from 'prop-types';
import {CheckSvg} from '../assets/svg/svgComponents';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import arrowdown from '../assets/images/ArrowDown.svg';
import {formatDate} from '../utils/helper';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const ONE_DAY = 24 * 60 * 60 * 1000;
const TODAY = new Date();

export default function TimelineButton(props) {
  const [visible, setVisible] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [customStart, setCustomStart] = useState(null);
  const [customEnd, setCustomEnd] = useState(null);
  const [dropDownOptions, setDropDownOptions] = useState([
    {
      label: 'All Time',
      selected: true,
    },
    {
      label: 'Last 7 Days',
      selected: false,
    },
    {
      label: 'Last 30 Days',
      selected: false,
    },
    {
      label: 'Last 365 Days',
      selected: false,
    },
    {
      label: 'Custom',
      selected: false,
    },
  ]);

  const defaultOptions = [
    {
      label: 'All Time',
      selected: false,
    },
    {
      label: 'Last 7 Days',
      selected: false,
    },
    {
      label: 'Last 30 Days',
      selected: false,
    },
    {
      label: 'Last 365 Days',
      selected: false,
    },
    {
      label: 'Custom',
      selected: false,
    },
  ];

  const calculateDates = (key) => {
    let startDate = new Date();
    let options = [...defaultOptions];
    options[key].selected = true;

    if (key === '0') {
      setDropDownOptions(options);
      return '';
    } else if (key === '1') {
      setDropDownOptions(options);
      startDate.setDate(startDate.getDate() - 7);
    } else if (key === '2') {
      setDropDownOptions(options);
      startDate.setDate(startDate.getDate() - 30);
    } else if (key === '3') {
      setDropDownOptions(options);
      startDate.setDate(startDate.getDate() - 365);
    } else {
      return null;
    }
    return startDate.toISOString().slice(0, 10);
  };

  const handleClick = (e) => {
    let setDates = calculateDates(e.currentTarget.id);
    props.dateSetHandler(setDates);
    setShowDate(false);
    setVisible(!visible);
  };

  const toggleVisible = () => {
    if (!showDate) {
      setVisible(!visible);
    }
  };

  const handleCustomClick = () => {
    let options = [...defaultOptions];
    options[4].selected = true;
    setShowDate(true);
  };

  return (
    <Dropdown
      direction="down"
      isOpen={visible}
      toggle={toggleVisible}
      style={props.style}
    >
      <DropdownToggle className={styles.dropdown_toggle}>
        <div className={`form-control border border-darkgrey ${styles.form}`}>
          <div
            className={`clickable secondary-subheader inlineblock ${styles.drop_down_option}`}
          >
            {dropDownOptions.find((x) => x.selected === true).label}
          </div>
          <img
            className={`inlineblock ${styles.caret}`}
            alt=""
            src={arrowdown}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu className={styles.dropdown_menu}>
        {dropDownOptions.map((x, i) => {
          return (
            <DropdownItem
              tag="div"
              id={i}
              key={i}
              toggle={x.label !== 'Custom'}
              className={`dropdown-item ${styles.drop_down_text} w-auto`}
              onClick={(e) => {
                setDropDownOptions(defaultOptions);
                x.label === 'Custom' ? handleCustomClick() : handleClick(e);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setDropDownOptions(defaultOptions);
                  x.label === 'Custom' ? handleCustomClick() : handleClick(e);
                }
              }}
            >
              {x.label}&nbsp;
              <span
                className={x.selected ? `${styles.show}` : `${styles.hide}`}
              >
                <CheckSvg className={styles.svg_color_stroke} />
              </span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
      {showDate && (
        <div className={styles.datePicker}>
          <div>
            <span
              style={{
                gridColumn: 1,
                gridRow: 1,
              }}
            >
              From:
            </span>
            <DayPicker
              selected={customStart}
              onDayClick={(day) => {
                if (customEnd !== null && day > customEnd) {
                  setCustomEnd(null);
                }
                setCustomStart(day);
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              <span
                style={{
                  gridColumn: 2,
                  gridRow: 1,
                }}
              >
                To:
              </span>
              <DayPicker
                selected={customEnd}
                fromDate={customStart ?? new Date()}
                toDate={
                  customStart == null
                    ? new Date(TODAY.getTime() - ONE_DAY)
                    : null
                }
                onDayClick={(day) => {
                  setCustomEnd(day);
                }}
              />
            </div>
            <button
              style={{width: '80px'}}
              disabled={
                customStart === '' ||
                customStart === null ||
                customStart === undefined
              }
              type="button"
              className="clickable btn btn-primary"
              onClick={() => {
                customEnd !== '' &&
                customEnd !== null &&
                customEnd !== undefined
                  ? props.dateSetHandler(
                      formatDate(customStart, 'yyyy-MM-dd'),
                      formatDate(customEnd, 'yyyy-MM-dd')
                    )
                  : props.dateSetHandler(formatDate(customStart, 'yyyy-MM-dd'));
                setShowDate(false);
                setVisible(!visible);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </Dropdown>
  );
}
TimelineButton.propTypes = {
  dateSetHandler: PropTypes.func.isRequired,
  style: PropTypes.object,
};
