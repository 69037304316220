import {differenceInYears} from 'date-fns';
import {Field} from 'react-final-form';
import {required} from 'src/utils/intake/intakeCommon';
import {TextField, SingleDatePickerField} from 'src/formFields';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {updateSeekerAction} from 'src/actions/SeekerActions';
import {updateTermsAccepted} from 'src/actions/users';
import {validate} from 'src/utils/modernApprenticeship/modernApprenticeshipCommon';
import * as Layout from '../layout';
import CheckboxField from 'src/formFields/CheckboxField';
import infoBlueCircle from 'src/assets/images/info-blue-circle.svg';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Wizard from './Wizard';
import {
  AscendRadioField,
  radioYesNoOptions,
} from 'src/formFields/ascendRadioField/AscendRadioField';
import {useNavigate} from 'react-router';

export default function ConsentAndAuthorizationModal({children}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activePage, setActivePage] = useState('1');
  const dataTip =
    'If you are a legally emancipated minor, please contact <a href="mailto:support@ascendindiana.com" class="underline white">support@ascendindiana.com</a> for instructions on how to complete this interest form.';
  const FIFTY_YEARS = 1000 * 60 * 60 * 24 * 365 * 50;

  let {userProfile, termsAgreed, consenterFullName, consenterDateOfBirth} =
    useSelector((state) => {
      return {
        userProfile: state.profile,
        termsAgreed: state.profile.termsAccepted,
        consenterFullName: state.profile.consenterFullName,
        consenterDateOfBirth:
          state.profile.consenterDateOfBirth ||
          new Date(Date.now() - FIFTY_YEARS).toISOString(),
      };
    });

  const pageOneValidate = (values) => {
    const fields = ['consenterFullName', 'consenterDateOfBirth', 'termsAgreed'];

    let messages = validate(fields, values);
    if (values['consenterDateOfBirth']) {
      const age = differenceInYears(
        new Date(),
        new Date(values['consenterDateOfBirth'])
      );
      if (age <= 17) {
        messages['consenterDateOfBirth'] =
          'Parent or legal guardian must be 18 years of age.';
      }
    }

    if (values['consenterFullName']) {
      const fullName = values['consenterFullName'].trim();
      if (fullName.length < 2) {
        messages['consenterFullName'] =
          'Full Name must be at least two characters long.';
      }
    }
    return messages;
  };

  const pageTwoValidate = (values) => {
    const errors = {};
    const requiredFields = ['workStatusAllowed'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  const handleToNetworkClick = async (formValues) => {
    let dob = new Date(formValues.consenterDateOfBirth);
    let dobString = dob.toISOString();
    const data = {
      id: userProfile.seekerId,
      consenterFullName: formValues.consenterFullName,
      consenterDateOfBirth: dobString,
      termsAgreed: formValues.termsAgreed,
      workStatusAllowed: formValues.workStatusAllowed,
    };
    await dispatch(updateSeekerAction(data));
    dispatch(
      updateTermsAccepted(
        userProfile.id,
        formValues.termsAgreed,
        formValues.consenterFullName
      )
    );
    if (formValues.workStatusAllowed === 'false') {
      navigate('/mapofframp');
    }
    setIsOpen(false);
  };

  const handleNext = () => {
    setActivePage('2');
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  return (
    <>
      <Layout.AscendModal
        isOpen={isOpen}
        disableClose={true}
        centerVertically={true}
        dialogClassName="noBorder"
      >
        <Layout.AscendModalHeader headerClass="bd-modalheader" isOpen={isOpen}>
          <div className="normal-headline-white bold text-align-center mb-0">
            Parental Consent Required
          </div>
          <span className="secondary-title-white float-right">
            Page {activePage} of 2
          </span>
        </Layout.AscendModalHeader>

        <Layout.AscendModalBody hasClose={false}>
          <Wizard
            onSubmit={handleToNetworkClick}
            initialValues={{
              termsAgreed: termsAgreed,
              consenterFullName: consenterFullName,
              consenterDateOfBirth: consenterDateOfBirth,
            }}
            handleNext={handleNext}
          >
            <Wizard.Page validate={pageOneValidate}>
              <div>
                <div className="secondary-subheader text-align-center">
                  Your parent or legal guardian must review our{' '}
                  <a
                    href={'https://ascendindiana.com/terms-of-service'}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    terms of service
                  </a>{' '}
                  and{' '}
                  <a
                    href={'https://ascendindiana.com/privacy-policy'}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    privacy policy
                  </a>
                  . By checking this box, your parent or legal guardian
                  acknowledges that they agree to the terms of service and
                  privacy policy.{' '}
                  <ReactTooltip
                    id="tooltip"
                    className="solid-tooltip-dark"
                    delayHide={1000}
                    clickable={true}
                    closeOnEsc={true}
                  />
                  <img
                    tabIndex={0}
                    className="clickable"
                    style={{width: '5%', maxWidth: '16px'}}
                    alt={''}
                    src={infoBlueCircle}
                    data-tooltip-id="tooltip"
                    data-tooltip-html={dataTip}
                  />
                  <br />
                  <div className="padding-bottom-sixteen padding-top-sixteen inlineblock text-align-center">
                    <Field
                      name="termsAgreed"
                      component={CheckboxField}
                      style={{}}
                      validate={required}
                      showValidation={true}
                      type={'checkbox'}
                      label={
                        <span>
                          I accept the Terms of Service and Privacy Policy
                        </span>
                      }
                    />
                  </div>
                </div>

                <div>
                  <div className="bold text-center">
                    Parent/Legal Guardian: Please provide your full name.
                  </div>
                </div>
                <div>
                  <div className="col-sm-12">
                    <Field
                      ariaLabel="Please provide your full name."
                      name="consenterFullName"
                      component={TextField}
                      size={12}
                      showLabel={false}
                      maxLength={64}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="bold text-center">
                    Parent/Legal Guardian: Please provide your date of birth.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 mt-2 text-center">
                    <div className="d-flex justify-content-center">
                      <Field
                        name="consenterDateOfBirth"
                        ariaLabel="Please provide your date of birth."
                        component={SingleDatePickerField}
                        placeholder="MM/DD/YYYY"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Wizard.Page>
            <Wizard.Page validate={pageTwoValidate}>
              <div>
                <div className="secondary-subheader text-align-center">
                  Your student will be required to provide documentation to
                  verify identity and employment authorization. Select{' '}
                  <b>"Yes"</b> to confirm your student is legally authorized to
                  be employed in the United States, now and in the future,{' '}
                  <b>and</b> that you have the{' '}
                  <a
                    href={
                      'https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents'
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    required documentation
                  </a>{' '}
                  for employment eligibility verification to be completed by
                  employer. If your student does not meet this requirement,
                  please select <b>“No”</b>.
                </div>
                <div
                  className="mb-0"
                  style={{
                    maxWidth: 'fit-content',
                    textAlign: 'left',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: '16px',
                  }}
                >
                  <AscendRadioField
                    name="workStatusAllowed"
                    options={radioYesNoOptions}
                    labelCssClass={'mt-0 onboarding-form-label'}
                    label={``}
                  />
                </div>
              </div>
            </Wizard.Page>
          </Wizard>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
      {children}
    </>
  );
}

ConsentAndAuthorizationModal.propTypes = {
  children: PropTypes.node,
};
