import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export const getDegreeTypesRank = () => {
  return (dispatch, getState) => {
    return dispatch({
      types: [
        types.DEGREE_RANK__GET_REQUEST,
        types.DEGREE_RANK__GET_SUCCESS,
        types.DEGREE_RANK_UNITS__GET_FAILURE,
      ],
      uri: `${apiUrl}/degree_types/ranks`,
      method: 'GET',
    });
  };
};
