import React from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './PreviousButton.module.scss';
import {ArrowLeftOrangeSvg} from 'src/assets/svg/svgComponents';

export default function PreviousButton() {
  const navigate = useNavigate();

  return (
    <button className={styles.navigateHome} onClick={() => navigate(-1)}>
      <ArrowLeftOrangeSvg
        className={`styles.svg_color_stroke_orange mb-1 mr-1`}
      />
      Back to Previous Page
    </button>
  );
}
