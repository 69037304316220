import React, {useState} from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import PushButtonArray from 'src/components/controls/buttons/PushButtonArray/PushButtonArray';
import renderSelectField from 'src/components/controls/SelectFieldRenderer';
import {ethnicities, updatedGenders} from 'src/utils/fieldHelper';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {TextField} from 'src/formFields';

export default function DemographicsPage({
  next,
  previous,
  intakeCache,
  headerImage,
}) {
  const [showOtherField, setShowOtherField] = useState(
    intakeCache.gender !== undefined &&
      intakeCache.gender.length > 0 &&
      intakeCache?.gender[0].id === '3'
  );

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['ethnicity', 'gender'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    if (values.gender.length === 0) {
      errors['gender'] = 'Required';
    }
    return errors;
  };

  const buildButtonList = () => {
    return updatedGenders.map((x) => ({
      buttonText: x.label,
      buttonValue: x.value,
      buttonAction: (props, form) => {
        if (x.value === 'Other') {
          setShowOtherField(true);
        } else {
          setShowOtherField(false);
        }
      },
    }));
  };

  return (
    <>
      <PageHeader title="Demographics" headerImage={headerImage} />
      <Form
        onSubmit={next}
        validate={validate}
        keepDirtyOnReinitialize={true}
        initialValues={{
          ethnicity: intakeCache?.ethnicity,
          gender: intakeCache?.gender || [],
          otherGender: intakeCache?.otherGender,
        }}
      >
        {({handleSubmit, form}) => {
          return (
            <form onSubmit={handleSubmit} style={{width: '100%'}}>
              <div className={styles.pageContent}>
                <div className="row">
                  <div className="col-12">
                    <Field
                      name="ethnicity"
                      component={renderSelectField}
                      title="Which of the following best describes you?"
                      options={ethnicities.map((opt) => ({
                        value: opt.value,
                        label: opt.label,
                      }))}
                      labelCssClass={styles.itemHeader}
                      placeholder="Select a race or ethnicity"
                    />
                  </div>
                </div>

                <div className={styles.itemHeader}>
                  What is your gender identity?
                </div>
                <Field
                  component={PushButtonArray}
                  title="What is your gender identity?"
                  name="gender"
                  buttonList={buildButtonList()}
                  displayVertical={true}
                  multiSelect={false}
                  form={form}
                />

                {showOtherField && (
                  <span style={{maxWidth: '210px'}}>
                    <Field
                      name="otherGender"
                      label=""
                      component={TextField}
                      labelCssClass={'onboarding-form-label'}
                      placeholder="Other"
                    />
                  </span>
                )}
                <div className={styles.itemHeader}>
                  This data is used for internal research purposes to better
                  support individuals in their job search.
                  <br />
                  Demographic information is not shared with external parties.
                </div>
              </div>
              <PageFooter
                next={() => form.submit()}
                previous={previous}
                getValues={() => form.getState().values}
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}

DemographicsPage.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.object,
  headerImage: PropTypes.any,
};
