export const APPLIEDTOSELECT = 'AppliedToSelect';
export const JOBSEARCH = 'JobSearch';
export const MANUAL = 'Manual';

export const YES_FOUNDROLEONNETWORK =
  'Yes, they found the role/company on the Ascend Network';
export const YES_GUIDANCEFROMMENTOR =
  'Yes, they received guidance from their Career Mentor';
export const YES_RESUMEORCOVERLETTERSUPPORT =
  'Yes, they received resume or cover letter support';
export const YES_INTERVIEWSUPPORT = 'Yes, they received interview support';
export const NO_DONOTATTRIBUTE =
  'No, they do not attribute securing their job/internship to Ascend';
export const YES_OTHER = 'Yes, other';
export const NO_OTHER = 'No, other';
