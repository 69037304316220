import React from 'react';
import PropTypes from 'prop-types';

const PageControl = ({
  pageIndex,
  pageCount,
  onPageChange,
  sortBy,
  sortDirection,
}) => {
  if (pageCount <= 1) {
    return null;
  }

  const boxes = [];
  boxes.push(
    <button
      aria-label="go to first page"
      key="firstClick"
      onClick={() => {
        if (pageIndex !== 0) {
          onPageChange(0, sortBy, sortDirection);
        }
      }}
      className="page-control"
    >
      &#60;&#60;
    </button>
  );
  boxes.push(
    <button
      aria-label="go to previous page"
      key="leftClick"
      onClick={() => {
        if (pageIndex !== 0) {
          onPageChange(pageIndex - 1, sortBy, sortDirection);
        }
      }}
      className="page-control"
    >
      &#60;
    </button>
  );
  for (
    let i = Math.floor(pageIndex / 10) * 10;
    i < Math.floor(pageIndex / 10 + 1) * 10 && i < pageCount;
    i++
  ) {
    if (i === pageIndex) {
      boxes.push(
        <button
          aria-label={`page ${i + 1}`}
          key={i}
          className="page-control page-control-selected"
        >
          {i + 1}
        </button>
      );
    } else {
      boxes.push(
        <button
          aria-label={`page ${i + 1}`}
          key={i}
          className="page-control"
          onClick={() => onPageChange(i, sortBy, sortDirection)}
        >
          {i + 1}
        </button>
      );
    }
  }
  boxes.push(
    <button
      aria-label="go to next page"
      key="rightClick"
      onClick={() => {
        if (pageIndex + 1 < pageCount)
          onPageChange(pageIndex + 1, sortBy, sortDirection);
      }}
      className="page-control"
    >
      &#62;
    </button>
  );
  boxes.push(
    <button
      aria-label="go to last page"
      key="lastClick"
      onClick={() => {
        if (pageIndex !== pageCount - 1)
          onPageChange(pageCount - 1, sortBy, sortDirection);
      }}
      className="page-control"
    >
      &#62;&#62;
    </button>
  );
  return (
    <nav role="navigation" aria-label="grid pagination">
      {boxes}
    </nav>
  );
};

PageControl.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
};

export default PageControl;
