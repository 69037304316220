import * as types from '../actions/ActionTypes';

const initialState = {
  byEmployer: {},
};

export default function companyRecruitersDataTable(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.RECRUITERS_DT__CREATE_EMPLOYER:
      return {
        ...state,
        byEmployer: {
          ...state.byEmployer,
          [action.employerId]: {
            currentPage: 0,
            currentItemsPerPage: 25,
            searchTerm: '',
            sortBy: '',
            sortAscending: true,
          },
        },
      };
    case types.RECRUITERS_DT__UPDATE_CURRENT_PAGE:
      return {
        ...state,
        byEmployer: {
          ...state.byEmployer,
          [action.employerId]: {
            ...state.byEmployer[action.employerId],
            currentPage: action.page,
          },
        },
      };
    case types.RECRUITERS_DT__UPDATE_SEARCH_TERM:
      return {
        ...state,
        byEmployer: {
          ...state.byEmployer,
          [action.employerId]: {
            ...state.byEmployer[action.employerId],
            searchTerm: action.searchTerm,
            currentPage: 0,
          },
        },
      };
    case types.RECRUITERS_DT__UPDATE_SORT_TERM:
      return {
        ...state,
        byEmployer: {
          ...state.byEmployer,
          [action.employerId]: {
            ...state.byEmployer[action.employerId],
            sortBy: action.sortBy,
            sortAscending: action.sortAscending,
            currentPage: 0,
          },
        },
      };
    default:
      return state;
  }
}
