import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactAutocomplete from 'react-autocomplete';

class SelectFieldInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }
  findDefault(value, options, defaultValue) {
    const dValue = defaultValue ? defaultValue : null;
    return value ? _.find(options, {value}) : dValue;
  }

  findAriaLabel(value, options, defaultValue, label) {
    const selectedValue = this.findDefault(value, options, defaultValue);

    if (selectedValue) {
      return `option field ${label} with a selected value of ${selectedValue.label}`;
    }
    return `option field ${label}`;
  }

  render() {
    const {
      input: {value, onChange},
      meta: {touched, error, warning},
      label,
      message,
      options,
      labelBold,
      labelCssClass,
    } = this.props;

    const labelCss = !labelCssClass
      ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
      : labelCssClass;
    return (
      <div>
        <label className={labelCss}>{label}</label>
        <div className="form form-group">
          <ReactAutocomplete
            key={1}
            items={options}
            shouldItemRender={(item, value) =>
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(item) => item.label}
            renderItem={(item, highlighted) => (
              <div
                key={item.value}
                style={{
                  backgroundColor: highlighted ? '#3061a9' : '#fff',
                  color: highlighted ? '#eee' : '',
                  fontSize: '14px',
                }}
              >
                {item.label}
              </div>
            )}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              this.props.handleValue(e.target.value);
            }}
            onSelect={(value) => {
              onChange(value);
              this.props.handleValue(value);
            }}
            inputProps={{
              className: 'form-control normal-subheader',
              size: '100',
              placeholder: this.props.placeHolder,
            }}
            menuStyle={{
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: 'rgba(255, 255, 255, 1)',
              padding: '5px',
              fontSize: '90%',
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '20%', // TODO: don't cheat, let it flow to the bottom
              zIndex: '10',
            }}
          />

          {touched &&
            ((error && <div className="form-input-error">{error}</div>) ||
              (warning && (
                <div className="form-input-error">{warning}</div>
              )) || <div className="form-input-message">{message}</div>)}
          {!touched && <div className="form-input-message">{message}</div>}
        </div>
      </div>
    );
  }
}

SelectFieldInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeHolder: PropTypes.string,
  handleValue: PropTypes.func,
  labelBold: PropTypes.bool,
  labelCssClass: PropTypes.string,
};

SelectFieldInput.defaultProps = {
  isLoading: false,
  isDisabled: false,
  message: ' ',
};

export default SelectFieldInput;
