import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Layout from '../../layout';
import axios from 'axios';
import {UploadSvg} from 'src/assets/svg/svgComponents';
import {makeRequest} from '../../services/api';
import RangeSlider from '../RangeSlider/RangeSlider';
import Cropper from 'react-easy-crop';
import styles from './ImageCropper.module.scss';
import getCroppedImg from './cropImage';
import * as flashActions from '../../actions/flash';
import {useDispatch} from 'react-redux';

export default function ImageCropperModal({
  isOpen,
  onClose,
  onSubmit,
  previousImage,
}) {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState('1');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    if (image === null && previousImage !== '') {
      setImage(previousImage);
    }
  }, [image, previousImage]);

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);

    const getS3Urls = async () => {
      try {
        const response = await makeRequest({
          method: 'GET',
          path: `v2/public_s3_url?contentType=image/jpeg`,
        });
        return [response.data.uploadUrl, response.data.publicUrl];
      } catch (error) {
        return [null, null];
      }
    };

    const uploadFileToS3 = async (imageBlob) => {
      const [s3UploadUrl, s3PublicUrl] = await getS3Urls();

      if (s3UploadUrl !== null) {
        await axios
          .request({
            method: 'PUT',
            url: s3UploadUrl,
            headers: {
              'Content-Type': 'image/jpeg',
              Accept: '*/*',
              'x-amz-acl': 'public-read',
            },
            data: imageBlob,
          })
          .then(() => {
            onSubmit(s3PublicUrl);
          });
      }
    };

    await uploadFileToS3(croppedImage);
  }, [croppedAreaPixels, image, onSubmit]);

  const onUpload = (e) => {
    e.preventDefault();
    const oneKB = 1024;
    const oneMB = oneKB * 1024;
    const tenMB = 10 * oneMB;
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files[0].size > tenMB) {
      dispatch(flashActions.addFlashMessage('File must be smaller than 10MB'));
      return;
    } else if (
      !(
        /\.(jpg|jpeg|png)$/i.test(files[0].name) &&
        /^image\//.test(files[0].type)
      )
    ) {
      dispatch(
        flashActions.addFlashMessage('Avatar must be in jpeg or png format')
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleSave = async () => {
    await cropImage();
  };

  return (
    <Layout.AscendModal isOpen={isOpen} onClose={onClose}>
      <Layout.AscendModalBody
        bodyClass={`bd-modalbody ${styles.body}`}
        hasClose={false}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className={styles.cropContainer}>
            <RangeSlider
              min="1"
              max="2"
              step="0.01"
              defaultValue={zoom}
              onChange={(e, zoom) => setZoom(e.target.value)}
              isDisabled={image === null}
              rangeLabel="Image Scale"
              rightLabel="Larger"
              leftLabel="Smaller"
            />
            <br />
            <br />
            <div style={{height: '400px', width: '100%', position: 'relative'}}>
              <Cropper
                cropShape="round"
                showGrid={false}
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={1}
                maxZoom={2}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                restrictPosition={true}
                zoomWithScroll={false}
              />
            </div>
          </div>
          <input
            type="file"
            accept=".jpeg, .jpg, .png"
            ref={hiddenFileInput}
            onChange={onUpload}
            style={{display: 'none'}}
          />
          <button
            style={{
              marginTop: '8px',
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'center',
            }}
            className="btn btn-primary"
            onClick={handleUploadClick}
          >
            <UploadSvg className={styles.uploadSvgFill} />
            &nbsp;&nbsp;&nbsp;Upload New Photo
          </button>
        </div>
        <div className="row" style={{justifyContent: 'center'}}>
          <button
            className="clickable btn btn-secondary col-2"
            style={{marginRight: '16px'}}
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="clickable btn btn-primary col-2"
            onClick={() => handleSave()}
            disabled={croppedAreaPixels === null}
          >
            Save
          </button>
        </div>
      </Layout.AscendModalBody>
    </Layout.AscendModal>
  );
}

ImageCropperModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previousImage: PropTypes.any,
};

ImageCropperModal.defaultProps = {
  isOpen: false,
  previousImage: null,
};
