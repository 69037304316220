import {useCallback, useEffect, useState} from 'react';
import {makeRequest} from 'src/services/api';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {has_permission} from '../services/authorizationApi';
import * as flashActions from '../actions/flash';
import {useDispatch} from 'react-redux';

export function useLookup({initialState, lookUpUrl}) {
  const [isLoading, setIsLoading] = useState(false);
  const [lookUpResponse, setLookUpResponse] = useState(initialState);
  useEffect(() => {
    const getLookup = async () => {
      setIsLoading(true);
      const response = await makeRequest({
        method: 'GET',
        path: lookUpUrl,
      });
      setLookUpResponse(response);
      setIsLoading(false);
    };

    getLookup();
  }, [lookUpUrl]);

  return [lookUpResponse, isLoading];
}

export function useIsMobile(mobileWidth) {
  const getIsMobile = useCallback(() => {
    return window.innerWidth <= mobileWidth;
  }, [mobileWidth]);
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [getIsMobile]);

  return isMobile;
}

export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>
) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const push = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        match={{params}}
        location={{location}}
        history={{push}}
        router={{location, navigate, params}}
      />
    );
  }

  return ComponentWithRouterProp;
}

export function useHasPermission(permission) {
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    has_permission(permission)
      .then((result) => {
        if (result.data && result.data.hasPermission) {
          setHasPermission(result.data.hasPermission);
        }
      })
      .catch((error) => {
        dispatch(
          flashActions.addFlashMessage(
            'Error checking permission: ' + permission,
            'error'
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, permission]);

  return [hasPermission, loading];
}
