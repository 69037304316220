import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import infoBlueCircle from '../assets/images/info-blue-circle.svg';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {camelCase} from 'src/utils/miscHelper';

class TextAreaField extends React.Component {
  render() {
    const {
      input,
      meta: {touched, error, warning},
      label,
      showLabel,
      cols,
      rows,
      maxLength,
      labelCssClass,
      hideBorder,
      labelBold,
    } = this.props;

    const labelCss = !labelCssClass
      ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
      : labelCssClass;

    const fieldId = camelCase(label) + 'Input';

    const buildLabel = (label) => {
      if (this.props.required) {
        return (
          <>
            {label}&nbsp;
            <span className="required-field">*</span>
          </>
        );
      }
      return label;
    };
    return (
      <Fragment>
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          closeOnEsc={true}
        />

        <div className="form form-group">
          {label && showLabel && (
            <label htmlFor={fieldId} className={labelCss}>
              {buildLabel(label)}&nbsp;&nbsp;
              {this.props.showToolTip && (
                <img
                  tabIndex={0}
                  className="clickable"
                  style={{width: '12px'}}
                  alt={''}
                  src={infoBlueCircle}
                  data-tooltip-id="tooltip"
                  data-tooltip-place={this.props.toolTipPlacement}
                  data-tooltip-html={this.props.toolTip}
                />
              )}
            </label>
          )}

          <textarea
            aria-describedby={`${fieldId}-id ${fieldId}-error`}
            {...input}
            name={label + 'Input'}
            id={fieldId}
            aria-label={label}
            cols={cols}
            rows={rows}
            maxLength={maxLength}
            className={`form-control normal-subheader ${
              hideBorder ? 'no-border' : ''
            } ${this.props.inputBackground}`}
            placeholder={this.props.helperText}
          />
        </div>
        {this.props.showCounter === true && (
          <div
            id={`${fieldId}-id`}
            className={
              this.props.counterRightAlign
                ? 'secondary-caption text-end'
                : 'secondary-caption'
            }
            aria-live="polite"
            aria-atomic="true"
          >
            {this.props.maxLength - input.value.length} characters remaining
          </div>
        )}
        {touched &&
          ((error && (
            <div id={`${fieldId}-error`} className="form-input-error">
              {error}
            </div>
          )) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </Fragment>
    );
  }
}

TextAreaField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  cols: PropTypes.number,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  showCounter: PropTypes.bool,
  helperText: PropTypes.string,
  inputBackground: PropTypes.string,
  toolTip: PropTypes.string,
  toolTipPlacement: PropTypes.string,
  showToolTip: PropTypes.bool,
  labelCssClass: PropTypes.string,
  hideBorder: PropTypes.bool,
  labelBold: PropTypes.bool,
  required: PropTypes.bool,
  counterRightAlign: PropTypes.bool,
};

TextAreaField.defaultProps = {
  showLabel: true,
  cols: 100,
  rows: 3,
  maxLength: 256,
  showCounter: false,
  helperText: '',
  inputBackground: 'bd-whitebackground',
  showToolTip: false,
  toolTipPlacement: 'top',
  hideBorder: true,
  required: false,
  counterRightAlign: false,
};

export default TextAreaField;
