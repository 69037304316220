export const CANDIDATE_DISCOVERY = {
  skillsFilter: 'skillsFilter',
  rolesFilter: 'rolesFilter',
  industryFilter: 'industryFilter',
  majorsFilter: 'majorsFilter',
  exploreCandidatesLoggedInAfter: 'exploreCandidatesLoggedInAfter',

  savedCandidatesSkillsFilter: 'savedCandidatesSkillsFilter',
  savedCandidatesSavedCandidatesrolesFilter: 'savedCandidatesRolesFilter',
  savedCandidatesIndustryFilter: 'savedCandidatesIndustryFilter',
  savedCandidatesMajorsFilter: 'savedCandidatesMajorsFilter',
  savedCandidatesLoggedInAfter: 'savedCandidatesLoggedInAfter',
  savedCandidates: 'savedCandidates',
  exploreCandidates: 'exploreCandidates',
};
