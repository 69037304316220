import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import track from 'react-tracking';
import {connect} from 'react-redux';

@track({})
class NavLinks extends React.Component {
  @track((props, state, [to]) => ({
    eventName: `NavLinkClicked:${to}`,
  }))
  handleClick = (nav) => {
    // no-op, function is vehicle for tracking decoration
    return;
  };

  render() {
    const links = () => {
      switch (this.props.role) {
        case 'ASCEND_RECRUITER':
          return [
            {to: '/admin/employers', text: 'Home'},
            {to: '/find_candidates', text: 'Explore Candidates'},
            {to: '/recruiter_find_roles', text: 'Explore Roles'},
          ];
        case 'COMPANY_RECRUITER':
        case 'COMPANY_RECRUITER_ADMIN':
          return [
            {to: '/employerhome', text: 'Home'},
            {
              to: `/all_roles/${this.props.profile.employerId}`,
              text: 'My Jobs',
            },
            {to: '/find_candidates', text: 'Explore Candidates'},
            {to: '/jobs/applications', text: 'Applications'},
          ];
        case 'JOB_SEEKER':
          return [
            {to: '/candidatehome', text: 'Home'},
            {to: '/find_roles', text: 'Explore Roles'},
            {to: '/find_employers', text: 'Explore Employers'},
          ];
        default:
          return [];
      }
    };

    return (
      <nav
        role="navigation"
        aria-label="site navigation"
        style={{marginLeft: '10px'}}
      >
        {links().map((nav, i) => {
          return (
            <span className="nav-link-padding" key={i}>
              <NavLink
                onClick={() => this.handleClick(nav.to)}
                to={nav.to}
                className="clickable normal-subheader-white"
              >
                {nav.text}
              </NavLink>
            </span>
          );
        })}
      </nav>
    );
  }
}

NavLinks.propTypes = {
  role: PropTypes.string,
  profile: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(NavLinks);
