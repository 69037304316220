import * as types from './ActionTypes';

export function createTeamsDataTableEmployer(employerId) {
  return {
    type: types.TEAMS_DT__CREATE_EMPLOYER,
    employerId,
  };
}

export function updateCurrentPage(employerId, page) {
  return {
    type: types.TEAMS_DT__UPDATE_CURRENT_PAGE,
    employerId,
    page,
  };
}

export function updateSearchTerm(employerId, searchTerm) {
  return {
    type: types.TEAMS_DT__UPDATE_SEARCH_TERM,
    employerId,
    searchTerm,
  };
}

export function updateSortTerm(employerId, sortBy, sortAscending) {
  return {
    type: types.TEAMS_DT__UPDATE_SORT_TERM,
    employerId,
    sortBy,
    sortAscending,
  };
}
