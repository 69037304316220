import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {TextAreaField} from 'src/formFields';
import {
  required,
  validate,
} from '../../../utils/modernApprenticeship/maIntakeCommon';
import * as Layout from '../../../layout';
import {BusyButton} from '../../BusyButton';

const validateForm = (values) => {
  const fields = [1, 2, 3].map((n) => `QnA${n}`);
  return validate(fields, values);
};
class MaProfileQnAModal extends React.Component {
  renderQuestion = (q) => {
    const validator = q.id !== 4 ? required : null;
    let name = `QnA${q.id}`;
    return (
      <Fragment key={name}>
        <Field
          name={name}
          initialValue={q.answer}
          component={TextAreaField}
          label={q.question}
          maxLength={2000}
          showCounter={true}
          inputBackground="bd-graybackground"
          validate={validator}
        />
      </Fragment>
    );
  };

  render() {
    const {questions, isMobile, isOpen, onClose} = this.props;
    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <div className="row">
            <div className="col-md-12">
              <div className="bd-whitebackground" style={{padding: '16px'}}>
                <Form
                  onSubmit={this.props.onSubmit}
                  validate={validateForm}
                  keepDirtyOnReinitialize={true}
                >
                  {({handleSubmit, form}) => {
                    return (
                      <form
                        onKeyPress={this.onKeyPress}
                        onSubmit={handleSubmit}
                      >
                        <div className="row justify-content-center">
                          <div className="col-md-8">
                            <div className="bold active-headline text-align-center">
                              Tell us more!
                            </div>
                            <div className="secondary-body text-align-center mb-5">
                              Your responses to these questions will be included
                              on your profile and in applications to employers.
                              Please respond thoughtfully and check for
                              grammatical errors.
                            </div>
                            <div className="col-md-1" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">&nbsp;</div>
                        </div>
                        {questions &&
                          questions
                            .filter((x) => x.isRecommendationTrait === false)
                            .map((q) => {
                              return this.renderQuestion(q);
                            })}

                        <div className="row padding-top-sixteen ">
                          <div
                            className={`col-md-12 ${
                              isMobile ? 'd-flex justify-content-center' : ''
                            } `}
                          >
                            <BusyButton
                              onClick={handleSubmit}
                              buttonText={'Save'}
                              busyText={'Saving'}
                              style={{width: '210px', height: '40px'}}
                              float={'float-right'}
                              buttonIcon={'save'}
                              alignText={'text-center'}
                            />
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Form>
              </div>
            </div>
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

MaProfileQnAModal.propTypes = {
  onSubmit: PropTypes.func,
  questions: PropTypes.array,
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default MaProfileQnAModal;
