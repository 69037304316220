import * as types from '../actions/ActionTypes';

const intitialState = {
  maOnboardingQuestions: [],
  maOnboardingQuestionsLoading: false,
};

const QuestionsReducer = (state = intitialState, action) => {
  switch (action.type) {
    case types.QUESTIONS__GET_MA_ONBOARDING_REQUEST:
      return {
        ...state,
        maOnboardingQuestionsLoading: true,
      };
    case types.QUESTIONS__GET_MA_ONBOARDING_REQUEST_FAILURE:
      return {...state, maOnboardingQuestionsLoading: false};
    case types.QUESTIONS__GET_MA_ONBOARDING_REQUEST_SUCCESS:
      return {
        ...state,
        maOnboardingQuestionsLoading: false,
        maOnboardingQuestions: action.response,
      };
    default:
      return state;
  }
};

export default QuestionsReducer;
