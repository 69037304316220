import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Tooltip as ReactTooltip} from 'react-tooltip';

import {
  HeartFilledWithoutBorderSvg,
  ResourceArticleSvg,
  ResourceArticleDisabledSvg,
  InvitationSvg,
} from 'src/assets/svg/svgComponents';

import tagStyles from 'src/components/tags/TagButton/TagButton.module.scss';

import * as seekersActions from 'src/actions/seekers';
import Avatar from '../Avatar';
import {Link} from 'react-router-dom';
import {formatDate} from 'src/utils/miscHelper';
import MatchCircle from '../MatchCircle';

class CandidateCard extends React.Component {
  getInvites = (seeker) => {
    let invites = [];

    if (
      this.props.userRole !== 'COMPANY_RECRUITER' &&
      this.props.userRole !== 'COMPANY_RECRUITER_ADMIN'
    ) {
      return null;
    }

    if (Array.isArray(seeker.jobsInvitedToApplyFor)) {
      invites = seeker.jobsInvitedToApplyFor;
    } else {
      invites = JSON.parse(seeker.jobsInvitedToApplyFor);
    }

    if (invites.length === 0) {
      return null;
    }

    return (
      <>
        {invites
          .filter(
            (invite) =>
              (invite.invitingUserRoleName === 'COMPANY_RECRUITER' ||
                invite.invitingUserRoleName === 'COMPANY_RECRUITER_ADMIN') &&
              invite.employerId === this.props.userEmployer
          )
          .sort((a, b) => {
            return new Date(b.inviteCreatedAt) - new Date(a.inviteCreatedAt);
          })
          .map((invite, i) => {
            return (
              <div
                className="candidateCardInvitationList"
                key={`${invite}-${i}`}
              >
                <InvitationSvg className="candidateCardInvitationIcon" />{' '}
                Invited to apply to{' '}
                <span className="bold" style={{color: 'var(--neutral-gray-1)'}}>
                  {invite.name}
                </span>{' '}
                on {formatDate(invite.inviteCreatedAt)}
              </div>
            );
          })}
      </>
    );
  };

  getWorkExperience = (seeker) => {
    let experience = [];
    if (Array.isArray(seeker.experience)) {
      experience = seeker.experience;
    } else {
      experience = JSON.parse(seeker.experience);
    }

    if (experience.length > 0) {
      let items = experience.slice(0, 2).map((i) => {
        if (i === null) return '';

        return `${i.title} at ${i.company} (${i.timeframe})`;
      });
      return (
        <>
          <strong>Experience: </strong>
          {`${items.join(', ')}`}
        </>
      );
    }
    return null;
  };

  getMajors = (seeker) => {
    if (seeker.majors) {
      let m = seeker.majors.map((x, index) => {
        if (seeker.majors.length === index + 1) {
          return x.name;
        }
        return `${x.name} & `;
      });

      return m.join('');
    }
  };

  getEducationText = (seeker) => {
    let eduText = '';

    if (seeker.educationLevel?.name.includes('or equivalent')) {
      if (seeker.otherInstitution) {
        eduText = seeker.otherInstitution;
      } else if (seeker.highSchool) {
        eduText = `High school diploma,  ${seeker.highSchool} `;
      } else {
        eduText = `High school diploma `;
      }
    } else {
      if (seeker.educationLevel) {
        eduText = `${seeker.educationLevel.name}, `;
      }

      const institutionName = seeker.institution?.name;
      const campusName = seeker.campus?.name;
      const otherInstitution = seeker.otherInstitution;

      if (institutionName === campusName) {
        eduText += `${institutionName}`;
      } else {
        eduText += `${otherInstitution || institutionName || ''}`;
        if (seeker.campus) {
          eduText += `, ${campusName}`;
        }
      }

      if (seeker.degree?.name) {
        eduText += ', ' + seeker.degree?.name;
      }

      if (seeker.school?.name) {
        eduText += ', ' + seeker.school?.name;
      }

      const majors = this.getMajors(seeker);
      if (majors) {
        eduText += `, ${majors} `;
      }
    }

    let graduationYear = '';
    if (seeker.graduationMonth) {
      graduationYear = `${seeker.graduationMonth}`;
    }

    if (seeker.graduationYear) {
      graduationYear += ` ${seeker.graduationYear}`;
    }

    if (graduationYear) {
      eduText += `(${graduationYear})`;
    }

    return eduText;
  };

  getSavedCandidateDetails = (seeker) => {
    if (!seeker.watchingEmployers) return null;

    return (
      <>
        {seeker.watchingEmployers.map((x, index) => {
          return (
            <div className="candidateCardSavedCandidateList" key={index}>
              <HeartFilledWithoutBorderSvg className="candidateCardSavedCandidateIcon" />

              {x.teamName && (
                <>
                  Saved to <span className="bold">{x.teamName}</span> on{' '}
                  {formatDate(x.watchCreatedAt)}
                </>
              )}

              {!x.teamName && <>Saved on {formatDate(x.createdAt)}</>}
            </div>
          );
        })}
      </>
    );
  };

  render() {
    const {
      seeker,
      handleAddWatchClicked,
      seekerIsWatched,
      handleShowResumeClicked,
      handleBatchSelectChanged,
      isSelected,
      allowIndividualInvitation,
    } = this.props;

    const isSeekerApprentice =
      seeker.segments && seeker.segments.some((x) => x.isApprenticeProgram);

    const heartStyle = seekerIsWatched
      ? 'candidateCard_filled_watch_icon'
      : 'candidateCard_empty_watch_icon';

    return (
      <div className="candidateCardContainer border">
        <ReactTooltip
          className="solid-tooltip-dark"
          id={'candidateCardTip'}
          delayHide={200}
          clickable={true}
          closeOnEsc={true}
          place="top"
        />

        <div className="custom-control custom-checkbox form-check">
          <div className="batchActionCheckBox">
            <input
              id={`batchSelect_${seeker.id}`}
              name={`batchSelect_${seeker.id}`}
              type="checkbox"
              className="custom-control-input form-check-input checkbox-formatting"
              checked={isSelected}
              onChange={(e) =>
                handleBatchSelectChanged(e.target.checked, seeker.id)
              }
              aria-label={`Select ${seeker.name} for batch actions`}
            />
            <label
              className="custom-control-label form-check-label"
              htmlFor={`batchSelect_${seeker.id}`}
            ></label>
          </div>
        </div>
        <div className="candidateCardAvatar">
          {seeker && seeker?.avatarUrl && (
            <Avatar height={55} width={55} url={seeker.avatarUrl} />
          )}
        </div>
        <div className="candidateDiscoveryColumnTwo">
          <h2 className="candidateCardName">
            <Link to={`/candidate/${seeker.id}`} className="underline">
              {`${seeker.firstName} ${seeker.lastName}`}
            </Link>
          </h2>
          {seeker.match?.matchLevels && !isSeekerApprentice && (
            <div className="match-chart-host" style={{marginRight: '10px'}}>
              <MatchCircle
                matchData={seeker.match}
                viewerType={'RECRUITER'}
                isApprenticeProgram={
                  seeker.match.roleRecommendationPercentage !== null
                }
                isCompact
                noMargin
              />
            </div>
          )}

          {seeker.experienceLevels &&
            seeker.experienceLevels.map((x, index) => {
              return (
                <div
                  key={index}
                  style={{marginTop: '2px', marginBottom: '2px'}}
                  className={`${tagStyles.container} discoveryTag`}
                >
                  <span aria-label="tag" className="tag-onboarding">
                    {x.years}
                  </span>
                </div>
              );
            })}

          {seeker.employmentTypes &&
            seeker.employmentTypes.map((x, index) => {
              return (
                <span
                  key={index}
                  style={{marginTop: '2px', marginBottom: '2px'}}
                  className={`${tagStyles.container} discoveryTag`}
                >
                  <span aria-label="tag" className="tag-onboarding">
                    {x.name}
                  </span>
                </span>
              );
            })}

          {this.props.isRoleFilterApplied && isSeekerApprentice && (
            <div className={`${tagStyles.containerBlue} discoveryTag`}>
              <span aria-label="tag" className={`${tagStyles.tagMatchBlue}`}>
                Matches Role Pathway
              </span>
            </div>
          )}

          {seeker?.experience?.length > 0 && (
            <div className="candidateCardExp">
              {this.getWorkExperience(seeker)}
            </div>
          )}

          <div className="candidateCardEdu">
            <strong>Education: </strong>
            {this.getEducationText(seeker)}
          </div>

          {this.props.showSavedCandidateDetails && (
            <div className="candidateCardEdu">
              {this.getSavedCandidateDetails(seeker)}
            </div>
          )}

          {seeker?.jobsInvitedToApplyFor?.length > 0 && (
            <div className="candidateCardInvites">
              {this.getInvites(seeker)}
            </div>
          )}
        </div>
        <div className="candidateDiscoveryColumnThree">
          <div className="candidateDiscoveryActionsRowOne">
            {this.props.showSaveSeeker && (
              <div className="candidateCardFav">
                <button
                  type="button"
                  className="buttonAsLink"
                  onClick={() => handleAddWatchClicked(seeker)}
                  data-tooltip-id="candidateCardTip"
                  data-tooltip-content={
                    seekerIsWatched ? 'Unsave candidate' : 'Save candidate'
                  }
                  aria-label="Save candidate"
                >
                  <HeartFilledWithoutBorderSvg
                    width="24"
                    height="24"
                    className={heartStyle}
                  />
                </button>
              </div>
            )}
            <div className="candidateCardResume">
              <button
                type="button"
                className="buttonAsLink"
                onClick={() => {
                  if (!seeker.hasResume) return;
                  handleShowResumeClicked(seeker);
                }}
                data-tooltip-id="candidateCardTip"
                data-tooltip-content={
                  seeker.hasResume ? 'View Resume' : 'Resume Unavailable'
                }
                style={{
                  cursor: seeker.hasResume ? 'cursor' : 'default',
                }}
                aria-label="View resume"
              >
                {seeker.hasResume ? (
                  <ResourceArticleSvg width="24" height="24" />
                ) : (
                  <ResourceArticleDisabledSvg width="24" height="24" />
                )}
              </button>
            </div>
          </div>
          <div
            className="candidateDiscoveryActionsRowTwo"
            data-tooltip-id="candidateCardTip"
            data-tooltip-content={
              !allowIndividualInvitation
                ? 'Individual invitations to apply are disabled while multiple candidates are selected. Use the toolbar to invite all to apply or clear your selection.'
                : ''
            }
            data-tooltip-place="top"
          >
            <button
              className="clickable btn btn-primary nowrap"
              onClick={() => this.props.handleInviteClicked(seeker.id)}
              style={{fontSize: '.75rem'}}
              disabled={!allowIndividualInvitation}
            >
              Invite to Apply
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CandidateCard.propTypes = {
  seeker: PropTypes.object,
  handleInviteClicked: PropTypes.func.isRequired,
  handleAddWatchClicked: PropTypes.func.isRequired,
  handleShowResumeClicked: PropTypes.func.isRequired,
  handleBatchSelectChanged: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  seekerIsWatched: PropTypes.bool.isRequired,
  showSaveSeeker: PropTypes.bool,
  isRoleFilterApplied: PropTypes.bool,
  isSelected: PropTypes.bool,
  allowIndividualInvitation: PropTypes.bool,
  userRole: PropTypes.string,
  userEmployer: PropTypes.number,
  showSavedCandidateDetails: PropTypes.bool,
};

CandidateCard.defaultProps = {};

function mapStateToProps(state) {
  return {
    seekers: state.seekers.seekers,
    stats: state.seekers.stats,
  };
}

const mapDispatchToProps = {
  ...seekersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCard);
