import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class PublishStateBadge extends React.Component {
  render() {
    const label = this.props.published ? 'Published' : 'Draft';

    return (
      <div
        className={classNames(
          [
            'inlineblock',
            'padding-h16',
            'float-right',
            'normal-body-white',
            'bd-darkbluebackground',
            'border-radius-3',
          ],
          {
            'bd-darkbluebackground': this.props.published,
          }
        )}
      >
        {label}
      </div>
    );
  }
}

PublishStateBadge.propTypes = {
  published: PropTypes.bool,
};

export default PublishStateBadge;
