import React from 'react';
import PropTypes from 'prop-types';

import {seekerMajors} from '../../utils/fieldHelper';
import diplomaSvg from 'src/assets/images/diploma.svg';
class CandidateMajorText extends React.Component {
  render() {
    const {seeker} = this.props;
    let text = '\u00A0';
    if (seeker.id) {
      const majors = seekerMajors(seeker);
      text = `Major: ${majors}`;
    }
    return (
      <div
        aria-label="education major"
        className="candidate-profile-title bold padding-bottom-eight"
      >
        {seeker.id && (
          <img className="padding-right-sixteen" src={diplomaSvg} alt="" />
        )}
        {text}
      </div>
    );
  }
}

CandidateMajorText.propTypes = {
  seeker: PropTypes.object,
};

CandidateMajorText.defaultProps = {
  seeker: {},
};

export default CandidateMajorText;
