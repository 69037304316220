import * as types from '../actions/ActionTypes';

const initialState = {
  tasks: [],
  loading: true,
  page: 0,
  count: 0,
  taskCountsLoading: false,
};

export default function tasks(state = initialState, action) {
  switch (action.type) {
    case types.TASKS__INCREMENT_NOTE_COUNT:
      return {
        ...state,
        tasks: state.tasks.map((existingTask) => {
          return {
            ...existingTask,
            taskItems: existingTask.taskItems.map((taskItem) => {
              return {
                ...taskItem,
                noteCount:
                  taskItem.seekerId === action.seekerId
                    ? taskItem.noteCount + 1
                    : taskItem.noteCount,
              };
            }),
          };
        }),
      };
    case types.TASKS__DECREMENT_NOTE_COUNT:
      return {
        ...state,
        tasks: state.tasks.map((existingTask) => {
          return {
            ...existingTask,
            taskItems: existingTask.taskItems.map((taskItem) => {
              return {
                ...taskItem,
                noteCount:
                  taskItem.seekerId === action.seekerId
                    ? taskItem.noteCount - 1
                    : taskItem.noteCount,
              };
            }),
          };
        }),
      };
    case types.SEEKERS__TOGGLE_SUPPORT_FLAG:
      return {
        ...state,
        tasks: state.tasks.map((existingTask) => {
          return {
            ...existingTask,
            taskItems: existingTask.taskItems.map((taskItem) => {
              return {
                ...taskItem,
                seekerSupportFlag:
                  taskItem.seekerId === action.seekerId
                    ? !taskItem.seekerSupportFlag
                    : taskItem.seekerSupportFlag,
              };
            }),
          };
        }),
      };

    case types.TASKS__GET_ALL_REQUEST:
    case types.TASKS__EXECUTE_REQUEST:
    case types.TASKS__DISMISS_ITEM_REQUEST:
    case types.TASKS__DISMISS_REQUEST:
      return {...state, loading: true};
    case types.TASKS__DISMISS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: state.tasks
          .filter((uiTask) =>
            action.response.body.tasks.some(
              (apiTask) => apiTask.id === uiTask.id
            )
          )
          .map((uiTask) => {
            const apiTask = action.response.body.tasks.find(
              (apiTask) => apiTask.id === uiTask.id
            );
            return {
              ...apiTask,
              expanded: uiTask === undefined ? false : uiTask.expanded,
            };
          }),
      };
    case types.TASKS__GET_SUCCESS_NEW_PAGE:
    case types.TASKS__DISMISS_ITEM_SUCCESS:
    case types.TASKS__EXECUTE_SUCCESS:
      const newPageOfTasks = action.response.body.tasks.filter((task) => {
        return task.id === action.taskId;
      });
      let newTask = {};
      if (newPageOfTasks.length === 1) {
        newTask = {...newPageOfTasks[0], expanded: true};
      }
      let allTasks = state.tasks
        .filter((uiTask) =>
          action.response.body.tasks.some((apiTask) => apiTask.id === uiTask.id)
        )
        .map((task) => {
          if (task.id !== action.taskId) {
            return {...task};
          }
          return {...newTask};
        });
      return {
        ...state,
        page: action.page,
        tasks: allTasks,
        loading: false,
      };
    case types.TASKS__GET_ALL_SUCCESS:
      return {
        ...state,
        tasks: action.response.body.tasks.map((task) => {
          return {
            ...task,
            expanded: false,
          };
        }),
        loading: false,
      };
    case types.TASKS__GET_ALL_ERROR:
      return {...state, loading: false};
    case types.TASKS__TOGGLE_EXPANDED_ROW:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          return {
            ...task,
            expanded:
              action.taskId === task.id ? !task.expanded : task.expanded,
          };
        }),
      };
    case types.TASKS__GET_COUNTS_REQUEST:
      return {
        ...state,
        taskCountsLoading: true,
      };
    case types.TASKS__GET_COUNTS_SUCCESS:
      return {
        ...state,
        count: action.response.body.taskCount,
        taskCountsLoading: false,
      };
    case types.TASKS__GET_COUNTS_FAILURE:
      return {
        ...state,
        taskCountsLoading: false,
      };
    default:
      return {...state};
  }
}
