import React from 'react';
import PropTypes from 'prop-types';

import PublishStateBadge from './PublishStateBadge';
import {truncate} from '../../utils/miscHelper';
import jobSvg from '../../assets/images/Job.svg';
import editGraySvg from '../../assets/images/EditGray.svg';

class TeamCard extends React.Component {
  render() {
    const {name, published, teamId, summary, canEdit, openRoleCount} =
      this.props;
    const renderSummary = (summary) => {
      if (summary !== '') {
        return (
          <div className="disabled-body padding-top-sixteen">
            {truncate(summary, 194)}
          </div>
        );
      }
    };

    const renderOpenRoles = () => {
      if (openRoleCount === 0) {
        return (
          <div className="padding-top-sixteen align-bottom">
            <span
              className="disabled-caption"
              style={{position: 'absolute', bottom: '10px'}}
            >
              <img src={jobSvg} alt="" />
              &nbsp;&nbsp;No Open Roles
            </span>
          </div>
        );
      } else {
        return (
          <div className="padding-top-sixteen align-bottom">
            <div
              className="inlineblock accent-1-caption"
              style={{position: 'absolute', bottom: '10px'}}
            >
              <img src={jobSvg} alt="" />
              &nbsp;&nbsp;{openRoleCount} Open Roles
            </div>
          </div>
        );
      }
    };

    return (
      <div className="bd-whitebackground padding-16 height210 overflow-hidden position-relative">
        <h2
          role="button"
          tabIndex={0}
          className="buttonAsLinkTeamCard normal-headline bold clickable inlineblock overflow-hidden nowrap col-lg-8 col-md-7"
          onClick={() => this.props.onTeamClick(teamId)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              this.props.onTeamClick(teamId);
            }
          }}
        >
          {name}
        </h2>
        <div className="inlineblock float-right">
          {canEdit && (
            <button
              aria-label="edit team"
              style={{backgroundColor: 'white'}}
              className="buttonAsLinkTeamCard inlineblock float-right disabled-body padding-left-eight border-0"
              onClick={() => this.props.onTeamEditClick(teamId)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  this.props.onTeamEditClick(teamId);
                }
              }}
            >
              <img src={editGraySvg} alt="" />
            </button>
          )}
          <PublishStateBadge published={published} />
        </div>
        {renderSummary(summary)}
        {renderOpenRoles()}
      </div>
    );
  }
}

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  summary: PropTypes.string.isRequired,
  onTeamClick: PropTypes.func.isRequired,
  onTeamEditClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  openRoleCount: PropTypes.number.isRequired,
};

export default TeamCard;
