import React from 'react';
import PropTypes from 'prop-types';

export default class CheckboxField extends React.Component {
  render() {
    const {
      input,
      label,
      style,
      meta: {touched, error, warning},
      showValidation,
    } = this.props;

    return (
      <div
        style={style}
        className="custom-control custom-checkbox form-check mb-0"
      >
        <input
          {...input}
          type="checkbox"
          id={input.name}
          name={input.name}
          checked={input.checked || input.value}
          onChange={(event) => {
            input.onChange(event.target.checked);
          }}
          onFocus={input.onFocus}
          className="custom-control-input form-check-input checkbox-formatting"
          style={{float: 'none'}}
          {...(this.props.enabled ? {} : {disabled: true})}
        />
        <label
          className="custom-control-label form-check-label mb-0"
          htmlFor={input.name}
        >
          {label}
        </label>
        {touched &&
          showValidation &&
          ((error && (
            <div className="form-input-error text-align-left">{error}</div>
          )) ||
            (warning && (
              <div className="form-input-error text-align-left">{warning}</div>
            )) || <div className="form-input-error">&nbsp;</div>)}
        {!touched && showValidation && (
          <div className="form-input-error">&nbsp;</div>
        )}
      </div>
    );
  }
}

CheckboxField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.any,
  style: PropTypes.object,
  enabled: PropTypes.bool,
  meta: PropTypes.object,
  showValidation: PropTypes.bool,
};

CheckboxField.defaultProps = {
  showValidation: false,
};

CheckboxField.defaultProps = {
  style: {width: '100%', textAlign: 'left'},
  enabled: true,
};
