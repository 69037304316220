import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {Alert} from 'reactstrap';
import _ from 'lodash';

import * as roleActions from '../actions/roleTypes';
import * as industriesActions from '../actions/industries';
import * as regionsActions from '../actions/regions';
import enterprise from '../assets/images/Enterprise.svg';

import {
  MultiSelectField,
  AvatarField,
  TextField,
  OnOffSwitch,
  TextAreaField,
  SkillsModalFinalField,
} from '../formFields';

import {AscendModal, AscendModalBody} from '../layout';
import AreYouSureModal from '../components/AreYouSureModal';
import {
  trimFields,
  multiselectChangeHandler,
  getSortedLookup,
} from '../utils/fieldHelper';
import {OnChange} from 'react-final-form-listeners';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Title is required.';
  }
  if (!values.summary) {
    errors.summary = 'Summary is required';
  }
  if (values.published === true) {
    if (_.isEmpty(values.roleTypes)) {
      errors.roleTypes = 'Required';
    }
    if (_.isEmpty(values.regions)) {
      errors.regions = 'Required';
    }
    if (_.isEmpty(values.industries)) {
      errors.industries = 'Required';
    }
  }
  return errors;
};

const defaults = {
  published: false,
};

class TeamForm extends React.Component {
  state = {
    areYouSureModalIsOpen: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.props.getIndustries();
    this.props.getRoleTypes();
    this.props.getRegions();
  }

  handleDeleteClicked = () => {
    this.setState({
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = () => {
    this.props.onDelete();
    this.props.onClose();
    this.setState({
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClosed = () => {
    this.setState({
      areYouSureModalIsOpen: false,
    });
  };

  onSubmit = (data) => {
    // list all text fields here.
    const fields = [
      'name',
      'location',
      'summary',
      'url',
      'details',
      'spotlightName',
      'spotlightTitle',
      'spotlightInfo',
    ];
    const newData = trimFields(data, fields);
    const skillTagIds = data.skillTags ? data.skillTags.map((t) => t.id) : [];
    const industryIds = data.industries ? data.industries.map((t) => t.id) : [];
    const regionIds = data.regions ? data.regions.map((t) => t.id) : [];
    const roleTypeIds = data.roleTypes ? data.roleTypes.map((t) => t.id) : [];
    const fullTeam = {
      ...newData,
      skillTagIds,
      industryIds,
      regionIds,
      roleTypeIds,
    };

    this.props.onSubmit(fullTeam);
    this.formRef.current.reset();
  };

  handleClosed = () => {
    this.formRef.current.reset();
    this.props.onClose();
  };

  render() {
    const industries = getSortedLookup(this.props.industries, ['name']);

    const regions = getSortedLookup(this.props.regions, ['name']);

    const roleTypes = getSortedLookup(this.props.roleTypes, ['name']);

    const {
      isOpen,
      mode,
      role,
      industriesLoading,
      regionsLoading,
      roleTypesLoading,
    } = this.props;

    return (
      <div>
        <AreYouSureModal
          areYouSureText="Are you sure that you want to remove this team?"
          isOpen={this.state.areYouSureModalIsOpen}
          onClose={this.handleAreYouSureClosed}
          onYes={this.handleDeleteConfirmed}
        />
        <AscendModal isOpen={isOpen} onClose={this.handleClosed}>
          <AscendModalBody hasClose={true}>
            <div className="padding-32">
              <Form
                onSubmit={this.onSubmit}
                validate={validate}
                keepDirtyOnReinitialize={true}
                initialValues={this.props.initialValues}
              >
                {({handleSubmit, form}) => {
                  this.formRef.current = form;
                  return (
                    <form>
                      <OnChange name="industries">
                        {(industries) => {
                          if (industries) {
                            multiselectChangeHandler(
                              'industries',
                              industries,
                              form.change
                            );
                          }
                        }}
                      </OnChange>

                      <OnChange name="regions">
                        {(regions) => {
                          if (regions) {
                            multiselectChangeHandler(
                              'regions',
                              regions,
                              form.change
                            );
                          }
                        }}
                      </OnChange>

                      <OnChange name="roleTypes">
                        {(roleTypes) => {
                          if (roleTypes) {
                            multiselectChangeHandler(
                              'roleTypes',
                              roleTypes,
                              form.change
                            );
                          }
                        }}
                      </OnChange>

                      <center>
                        <img
                          src={enterprise}
                          height="54"
                          width="54"
                          alt="enterprise"
                        />
                        <div className="normal-title padding-top-thirtytwo padding-bottom-eight bold">
                          {mode === 'edit' && 'Edit Team'}
                          {mode === 'create' && 'New Team'}
                        </div>
                      </center>
                      <hr />
                      <div className="normal-title bold padding-top-sixteen padding-bottom-sixteen">
                        General Information
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="name"
                            component={TextField}
                            label="Team Name"
                            size={36}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="location"
                            component={TextField}
                            label="Location"
                            size={36}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="summary"
                            component={TextAreaField}
                            label="Summary"
                            rows={2}
                            maxLength={600}
                          />
                        </div>
                      </div>
                      <div className="normal-title bold padding-bottom-sixteen">
                        Team Details
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="teamSize"
                            component={TextField}
                            label="Team Size"
                            size={36}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="url"
                            component={TextField}
                            label="URL"
                            size={36}
                            maxLength={140}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="details"
                            component={TextField}
                            label="Email / Phone"
                            size={36}
                            maxLength={500}
                          />
                        </div>
                        <div className="col-md-6" />
                      </div>
                      <div className="normal-title bold padding-bottom-sixteen">
                        Candidate Preferences
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="industries"
                            label="Industries"
                            isDisabled={industries.length === 0}
                            isLoading={industriesLoading}
                            component={MultiSelectField}
                            width="100%"
                            options={industries}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="regions"
                            label="Regions"
                            isDisabled={regions.length === 0}
                            isLoading={regionsLoading}
                            component={MultiSelectField}
                            width="100%"
                            options={regions}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="roleTypes"
                            label="Role Types"
                            isDisabled={roleTypes.length === 0}
                            isLoading={roleTypesLoading}
                            component={MultiSelectField}
                            width="100%"
                            options={roleTypes}
                          />
                        </div>
                        <div className="col-md-6" />
                      </div>
                      <div className="normal-title bold padding-bottom-sixteen">
                        Team Skills
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="padding-16 bd-whitebackground"
                            style={{
                              borderRadius: '8px',
                            }}
                          >
                            <Field
                              name="skillTags"
                              component={SkillsModalFinalField}
                              title="Preferred Skills"
                            />
                          </div>
                        </div>
                        <div className="col-md-6" />
                      </div>
                      <div className="normal-title bold padding-top-thirtytwo padding-bottom-sixteen">
                        Spotlight Employee Information:
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="spotlightName"
                            component={TextField}
                            label="Name"
                            maxLength={128}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="spotlightTitle"
                            component={TextField}
                            label="Title"
                            maxLength={128}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="spotlightInfo"
                            component={TextAreaField}
                            label="Info"
                            maxLength={512}
                          />
                        </div>
                      </div>
                      <Field
                        name="spotlightAvatarUrl"
                        component={AvatarField}
                      />
                      {(form.getState().dirty ||
                        form.getState().submitFailed) &&
                        form.getState().invalid && (
                          <div
                            className="padding-bottom-sixteen"
                            style={{clear: 'right'}}
                          >
                            <Alert color="danger">
                              Team is invalid, check fields for errors.
                            </Alert>
                          </div>
                        )}
                      <div
                        style={{clear: 'right'}}
                        className="padding-bottom-thirtytwo"
                      >
                        {mode === 'create' && (
                          <div>
                            <div className="float-left">
                              <Field name="published" component={OnOffSwitch} />
                            </div>
                            <div
                              className="btn-group float-right "
                              role="group"
                            >
                              <button
                                className="clickable btn btn-primary"
                                onClick={handleSubmit}
                                disabled={form.getState().submitting}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}

                        {mode === 'edit' && (
                          <div>
                            <div className="float-left">
                              <Field name="published" component={OnOffSwitch} />
                            </div>
                            <div
                              className="btn-group float-right "
                              role="group"
                            >
                              {role === 'ASCEND_RECRUITER' && (
                                <button
                                  type="button"
                                  className="clickable btn btn-transparent"
                                  onClick={this.handleDeleteClicked}
                                  disabled={form.getState().submitting}
                                >
                                  Archive
                                </button>
                              )}
                              <button
                                className="clickable btn btn-primary"
                                onClick={handleSubmit}
                                disabled={form.getState().submitting}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
          </AscendModalBody>
        </AscendModal>
      </div>
    );
  }
}

TeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  role: PropTypes.string,
  published: PropTypes.bool,
  getIndustries: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  industries: PropTypes.array,
  industriesLoading: PropTypes.bool,
  regions: PropTypes.array,
  regionsLoading: PropTypes.bool,
  roleTypes: PropTypes.array,
  roleTypesLoading: PropTypes.bool,
  initialValues: PropTypes.object,
};

TeamForm.defaultProps = {
  isOpen: false,
};

const actions = {
  ...roleActions,
  ...industriesActions,
  ...regionsActions,
};

let CreateTeamForm = (props) => <TeamForm {...props} mode="create" />;

const createMapStateToProps = (state) => {
  return {
    initialValues: defaults,
    role: state.profile.role,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    roleTypes: state.roleTypes.roleTypes,
    roleTypesLoading: state.roleTypes.roleTypesLoading,
  };
};

CreateTeamForm = connect(createMapStateToProps, actions)(CreateTeamForm);

let EditTeamForm = (props) => <TeamForm {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  const published = state.teams.teamForEdit
    ? state.teams.teamForEdit.published
    : false;
  return {
    role: state.profile.role,
    initialValues: state.teams.teamForEdit,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    roleTypes: state.roleTypes.roleTypes,
    roleTypesLoading: state.roleTypes.roleTypesLoading,
    published,
  };
};

EditTeamForm = connect(editMapStateToProps, actions)(EditTeamForm);

export {CreateTeamForm, EditTeamForm};
