import React, {useEffect, useState} from 'react';
import DocumentTitle from 'src/components/vendor/DocumentTitle';
import {Field, Form} from 'react-final-form';
import {TextField} from 'src/formFields';
import styles from './Login.module.scss';
import {useIsMobile} from 'src/utils/commonHooks';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import {push} from 'redux-first-history';
import NetworkAuthenticationService from 'src/services/NetworkAuthenticationService.js';
import {makeRequest} from 'src/services/api';
import AscendNetworkLogo from 'src/assets/svg/icons/Ascend-Network-logo.svg';
import Swoops from 'src/assets/images/Swoops_Background.svg';

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const isMobile = useIsMobile(990);

  const [isSubmitted, setIsSubmitted] = useState(false);

  let emailChangePasswordError = useSelector(
    (state) => state.authentication.emailChangePasswordError
  );
  let emailChangePasswordSuccess = useSelector(
    (state) => state.authentication.emailChangePasswordSuccess
  );

  useEffect(() => {
    if (emailChangePasswordSuccess && isSubmitted) {
      dispatch(push('/login'));
    }
  }, [emailChangePasswordSuccess, isSubmitted, dispatch]);

  const validate = (values) => {
    const errors = {};

    const email = values['email'];

    if (!email || email === '') {
      errors['email'] = 'Required';
    } else {
      if (!isEmail(email)) {
        errors['email'] = 'Invalid email';
      }
    }

    return errors;
  };

  const onSubmit = (data) => {
    setIsSubmitted(true);
    makeRequest({
      method: 'POST',
      path: 'users/is_google_auth_user',
      isAuthenticated: false,
      body: {
        email: data.email,
      },
    });
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const auth = new NetworkAuthenticationService(auth0ClientId, auth0Domain);
    auth.changePassword(data.email);
  };

  return (
    <DocumentTitle title="Forgot Password">
      <div className="wholepage">
        <div
          className="bd-pagebody"
          style={{
            fontFamily: 'Manrope',
            backgroundColor: '#F2F6F9',
            backgroundImage: `url(${Swoops})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <main id="main" tabIndex="-1">
            <div
              className="container d-flex justify-content-center align-items-center"
              style={{height: '100vh'}}
            >
              <div
                className="bd-whitebackground d-flex flex-column align-items-center rounded ps-3 pe-3"
                style={{
                  width: isMobile ? '90vw' : '33vw',
                  height: 'fit-content',
                  maxWidth: '530px',
                  boxShadow: '0 5px 8px rgba(0, 0, 0, 0.15)',
                }}
              >
                <div className="text-align-center mb-4">
                  <img
                    className="mt-4 mb-4"
                    src={AscendNetworkLogo}
                    alt="ascend logo"
                  />
                  <h1 style={{fontSize: '32px'}}>Forgot your password</h1>
                  <p>
                    Please enter your email address. We will send you an email
                    to reset your password.
                  </p>
                </div>
                <Form onSubmit={onSubmit} validate={validate}>
                  {({handleSubmit}) => {
                    return (
                      <form
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Field
                          name="email"
                          component={TextField}
                          size={32}
                          maxLength={128}
                          label="Email"
                          labelCssClass={'login-label'}
                        />

                        {emailChangePasswordError && (
                          <div className="form-input-error mb-2">
                            {emailChangePasswordError}
                          </div>
                        )}

                        <button
                          className={`btn ${styles.btn_ascend} mt-2`}
                          onClick={handleSubmit}
                        >
                          Send Email
                        </button>

                        <div className="mt-3 text-align-center">
                          <Link to={'/login'} className={`${styles.back}`}>
                            {'<'} Back
                          </Link>
                        </div>
                      </form>
                    );
                  }}
                </Form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DocumentTitle>
  );
}
