import React from 'react';
import PropTypes from 'prop-types';
import noemployers from '../assets/images/NoEmployers.svg';
class NoEmployers extends React.Component {
  render() {
    const logoStyle = {
      ...this.props.style,
      width: this.props.width,
    };

    return (
      <div style={{position: 'relative'}}>
        <img style={logoStyle} alt="" src={noemployers} />
      </div>
    );
  }
}

NoEmployers.propTypes = {
  width: PropTypes.string,
  style: PropTypes.object,
};

NoEmployers.defaultProps = {
  width: '100%',
  style: {},
};

export default NoEmployers;
