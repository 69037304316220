import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {MultiSelect} from 'react-multi-select-component';
import PushButton from 'src/components/controls/buttons/PushButton';
import {gpas, optionsYesNo} from 'src/utils/fieldHelper';

import explorerStyles from './UniversityExplorerPage.module.scss';

export default function UniversityExplorerFilters({
  handleGroupChange,
  handleFilterChange,
  appliedGroups,
  appliedFilters,
  studentSourceData,
}) {
  const [universityOptions, setUniversityOptions] = useState([]);
  const [majorOptions, setMajorOptions] = useState([]);
  const [graduationDateOptions, setGraduationDateOptions] = useState([]);

  useEffect(() => {
    const parseData = () => {
      const universities = [
        ...new Set(studentSourceData.map((x) => x.school)),
      ].sort();

      setUniversityOptions(universities);

      const allMajors = [
        ...new Set(studentSourceData.map((x) => x['major'])),
      ].sort();

      setMajorOptions(allMajors.filter((m) => m !== ''));

      const graduationDates = [
        ...new Set(
          studentSourceData
            .sort((a, b) => {
              if (a.graduationDateValue < b.graduationDateValue) {
                return -1;
              }
              if (a.graduationDateValue > b.graduationDateValue) {
                return 1;
              }
              return 0;
            })
            .map((student) => student.graduationDate)
        ),
      ].map((x) => ({
        graduationDateValue: new Date(x),
        graduationDateDisplay: x,
      }));

      setGraduationDateOptions(graduationDates);
    };

    parseData();
  }, [studentSourceData]);

  return (
    <div
      className={`flex-column ${explorerStyles.universityExplorerFilterContainer}`}
    >
      <div>
        <h2 className={explorerStyles.universityExplorerFilterHeader}>
          Filter By
        </h2>
      </div>

      <MultiSelect
        className={`${
          appliedFilters['school'].length > 0
            ? explorerStyles.universityExplorerSelectedFilter
            : explorerStyles.universityExplorerFilter
        }`}
        labelledBy={'filterUniversity'}
        value={appliedFilters['school']}
        role="listbox"
        hasSelectAll={false}
        onChange={(x) =>
          handleFilterChange({
            name: 'school',
            value: x,
          })
        }
        options={universityOptions.map((opt) => {
          return {value: opt, label: opt};
        })}
        valueRenderer={(selected, _options) => {
          return 'College or University';
        }}
      />

      <MultiSelect
        className={`${
          appliedFilters['graduationDateValue'].length > 0
            ? explorerStyles.universityExplorerSelectedFilter
            : explorerStyles.universityExplorerFilter
        }`}
        labelledBy={'filterGraduationDate'}
        value={appliedFilters['graduationDateValue']}
        role="listbox"
        hasSelectAll={false}
        onChange={(x) =>
          handleFilterChange({
            name: 'graduationDateValue',
            value: x,
          })
        }
        options={graduationDateOptions.map((opt) => {
          return {
            value: opt.graduationDateValue,
            label: opt.graduationDateDisplay,
          };
        })}
        valueRenderer={(selected, _options) => {
          return 'Graduation Date';
        }}
      />

      <MultiSelect
        className={`${
          appliedFilters['major'].length > 0
            ? explorerStyles.universityExplorerSelectedFilter
            : explorerStyles.universityExplorerFilter
        }`}
        labelledBy={'filterMajor'}
        value={appliedFilters['major']}
        role="listbox"
        hasSelectAll={false}
        onChange={(x) =>
          handleFilterChange({
            name: 'major',
            value: x,
          })
        }
        options={majorOptions.map((opt) => {
          return {value: opt, label: opt};
        })}
        valueRenderer={(selected, _options) => {
          return 'Major(s)';
        }}
      />

      <MultiSelect
        className={`${
          appliedFilters['gpa'].length > 0
            ? explorerStyles.universityExplorerSelectedFilter
            : explorerStyles.universityExplorerFilter
        }`}
        labelledBy={'filterGPA'}
        value={appliedFilters['gpa']}
        role="listbox"
        hasSelectAll={false}
        onChange={(x) =>
          handleFilterChange({
            name: 'gpa',
            value: x,
          })
        }
        options={gpas.map((opt) => {
          return {value: opt, label: opt};
        })}
        valueRenderer={(selected, _options) => {
          return 'GPA';
        }}
      />

      <MultiSelect
        className={`${
          appliedFilters['isUnderrepresented'].length > 0
            ? explorerStyles.universityExplorerSelectedFilter
            : explorerStyles.universityExplorerFilter
        }`}
        labelledBy={'filterUnderrepresentedStudents'}
        value={appliedFilters['isUnderrepresented']}
        role="listbox"
        hasSelectAll={false}
        onChange={(x) =>
          handleFilterChange({
            name: 'isUnderrepresented',
            value: x,
          })
        }
        options={optionsYesNo.map((opt) => {
          return {value: opt.label, label: opt.label};
        })}
        valueRenderer={(selected, _options) => {
          return 'Underrepresented Students';
        }}
      />

      <div className={'pt-4 flex-column'}>
        <h2 className={explorerStyles.universityExplorerFilterHeader}>
          Breakdown By
        </h2>
        <h6 className="pt-0">Creates categories for the data being analyzed</h6>

        <div className={`${explorerStyles.universityExplorerGroupByRow}`}>
          <PushButton
            key={'GroupUniversity'}
            id={'GroupUniversity'}
            buttonText={'College or University'}
            buttonValue={'school'}
            action={handleGroupChange}
            childProps={{}}
            isToggled={appliedGroups.includes('school')}
            enabled={true}
            buttonToggledCssClass={
              explorerStyles.universityExplorerSelectedGroup
            }
            className={`${explorerStyles.universityExplorerGroupByButton}`}
          />

          <PushButton
            key={'GroupGPA'}
            id={'GroupGPA'}
            buttonText={'GPA'}
            buttonValue={'gpa'}
            action={handleGroupChange}
            childProps={{}}
            isToggled={appliedGroups.includes('gpa')}
            enabled={true}
            buttonToggledCssClass={
              explorerStyles.universityExplorerSelectedGroup
            }
            className={`${explorerStyles.universityExplorerGroupByButton}`}
          />
        </div>

        <div className={`${explorerStyles.universityExplorerGroupByRow}`}>
          <PushButton
            key={'GroupGraduationDate'}
            id={'GroupGraduationDate'}
            buttonText={'Graduation Date'}
            buttonValue={'graduationDate'}
            action={handleGroupChange}
            childProps={{}}
            isToggled={appliedGroups.includes('graduationDate')}
            enabled={true}
            buttonToggledCssClass={
              explorerStyles.universityExplorerSelectedGroup
            }
            className={`${explorerStyles.universityExplorerGroupByButton}`}
          />

          <PushButton
            key={'GroupMajor'}
            id={'GroupMajor'}
            buttonText={'Major(s)'}
            buttonValue={'major'}
            action={handleGroupChange}
            childProps={{}}
            isToggled={appliedGroups.includes('major')}
            enabled={true}
            buttonToggledCssClass={
              explorerStyles.universityExplorerSelectedGroup
            }
            className={`${explorerStyles.universityExplorerGroupByButton}`}
          />
        </div>
      </div>
    </div>
  );
}

UniversityExplorerFilters.propTypes = {
  handleGroupChange: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  appliedGroups: PropTypes.arrayOf(PropTypes.string),
  appliedFilters: PropTypes.object,
  studentSourceData: PropTypes.arrayOf(PropTypes.object),
};
