import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';

import * as Layout from 'src/layout';
import SingleDatePickerField from 'src/formFields/SingleDatePickerField';
import {BusyButton} from './BusyButton';

const ONE_YEAR = 1000 * 60 * 60 * 24 * 365;

class StartDateModal extends React.Component {
  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const {isOpen, onClose} = this.props;

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            keepDirtyOnReinitialize={true}
            initialValues={{
              availableToStart: this.props.initialValues.availableToStart,
            }}
          >
            {({handleSubmit}) => {
              return (
                <form onKeyPress={this.onKeyPress}>
                  <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-10 text-align-center">
                      <div className="bold normal-subheader padding-bottom-eight">
                        If you selected Full-Time, when are you available to
                        start?
                      </div>
                      <br />
                      <Field
                        name="availableToStart"
                        component={SingleDatePickerField}
                        minDate={new Date()}
                        maxDate={new Date(Date.now() + ONE_YEAR)}
                      />
                    </div>
                    <div className="col-md-1" />
                  </div>
                  <br />

                  <div className="row justify-content-center nowrap">
                    <button
                      type="button"
                      className="btn btn-transparent col-2 me-4"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <BusyButton
                      onClick={handleSubmit}
                      buttonText={'Save'}
                      busyText={'Saving'}
                      className="col-2"
                      style={{width: '210px', height: '40px'}}
                      float={'float-right'}
                      buttonIcon={'save'}
                      alignText={'text-center'}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

StartDateModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

const actions = {};

let EditStartDateModal = (props) => <StartDateModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  const {id, availableToStart} = state.seekers.seekerForEdit;
  return {
    initialValues: {
      id,
      availableToStart,
    },
  };
};

EditStartDateModal = connect(editMapStateToProps, actions)(EditStartDateModal);

export {EditStartDateModal};
