import {makeRequest} from 'src/services/api.js';

export const PERMISSIONS = {
  ADMIN: 'ADMIN',
  ASCEND_RECRUITER: 'ASCEND_RECRUITER',
  COMPANY_RECRUITER: 'COMPANY_RECRUITER',
  COMPANY_RECRUITER_ADMIN: 'COMPANY_RECRUITER_ADMIN',
  JOB_SEEKER: 'JOB_SEEKER',
  READ_COMPANY_RECRUITERS: 'READ_COMPANY_RECRUITERS',
  WRITE_COMPANY_RECRUITERS: 'WRITE_COMPANY_RECRUITERS',
  READ_COMPANY_ADMIN_RECRUITERS: 'READ_COMPANY_ADMIN_RECRUITERS',
  WRITE_COMPANY_ADMIN_RECRUITERS: 'WRITE_COMPANY_ADMIN_RECRUITERS',
  READ_ASCEND_RECRUITERS: 'READ_ASCEND_RECRUITERS',
  READ_LIST_SEEKERS: 'READ_LIST_SEEKERS',
  READ_DELETED_SEEKERS: 'READ_DELETED_SEEKERS',
  EDIT_SEGMENT_SEEKERS: 'EDIT_SEGMENT_SEEKERS',
  EDIT_SEGMENT_JOBS: 'EDIT_SEGMENT_JOBS',
  EDIT_SEGMENT_EMPLOYER: 'EDIT_SEGMENT_EMPLOYER',
  EDIT_ALL_SEEKERS: 'EDIT_ALL_SEEKERS',
  EDIT_ALL_JOBS: 'EDIT_ALL_JOBS',
  EDIT_ALL_EMPLOYERS: 'EDIT_ALL_EMPLOYERS',
  VIEW_ALL_EMPLOYERS: 'VIEW_ALL_EMPLOYERS',
  VIEW_ALL_JOBS: 'VIEW_ALL_JOBS',
  VIEW_ALL_SEEKERS: 'VIEW_ALL_SEEKERS',
  VIEW_ALL_APPLICATIONS: 'VIEW_ALL_APPLICATIONS',
  EDIT_ALL_USERS: 'EDIT_ALL_USERS',
  EDIT_SEGMENT_USERS: 'EDIT_SEGMENT_USERS',
  EDIT_AUTOSCHEDULE_LINKS: 'EDIT_AUTOSCHEDULE_LINKS',
  EDIT_PLACEMENTS: 'EDIT_PLACEMENTS',
  EDIT_ROLES: 'EDIT_ROLES',
  SEGMENT_WAGE_FEATURE: 'SEGMENT_WAGE_FEATURE',
  READ_SEEKER_WAGE_PREFERENCE: 'READ_SEEKER_WAGE_PREFERENCE',
  VIEW_ALL_SEGMENTS: 'VIEW_ALL_SEGMENTS',
  EDIT_ALL_INVITATION_CODES: 'EDIT_ALL_INVITATION_CODES',
  EDIT_SEGMENT_INVITATION_CODES: 'EDIT_SEGMENT_INVITATION_CODES',
  CAN_BE_ACCOUNT_MANAGER: 'CAN_BE_ACCOUNT_MANAGER',
  JOB_APPLICATION_BROWSER: 'JOB_APPLICATION_BROWSER',
  JOB_SEEKER_PII: 'JOB_SEEKER_PII',
  ROLE_CARD_MENU: 'ROLE_CARD_MENU',
  ALLOWED_TO_CHANGE_OTHER_EMAIL: 'ALLOWED_TO_CHANGE_OTHER_EMAIL',
  VIEW_CANDIDATE_INSIGHTS: 'VIEW_CANDIDATE_INSIGHTS',
};

const THIRTY_MINUTES = 1000 * 60 * 30;
/*
  Check whether the user has a given permission
  Permissions are cached in localStorage for faster
  re-query time. The AdminLinks component will
  query permissions on each page load. Caching speeds
  up re-load time of menu items.
  For JOB_SEEKER_PII permission, this is checked many
  times throughout many components. Load-time is helped
  in these cases too.
  A given permission should expire 30 minutes after
  it is cached and will need to be re-queried from
  the API. Logging out will also clear the permissions
  cache.
*/
export const has_permission = (permission) => {
  const permissionsObj = JSON.parse(localStorage.getItem('permissionsObj')) || {
    list: [],
  };
  const thirtyMinutesAgo = Date.now() - THIRTY_MINUTES;

  const lastCheckedAt = permissionsObj[permission]?.lastCheckedAt || 0;
  if (
    permissionsObj.list.includes(permission) &&
    lastCheckedAt > thirtyMinutesAgo
  ) {
    return new Promise((resolve) => {
      resolve({
        data: {hasPermission: permissionsObj[permission].hasPermission},
      });
    });
  }

  return new Promise((resolve) => {
    makeRequest({
      method: 'GET',
      path: `has_permission/${permission}`,
    }).then((resp) => {
      if (!permissionsObj.list.includes(permission)) {
        permissionsObj.list.push(permission);
      }
      permissionsObj[permission] = {
        lastCheckedAt: Date.now(),
        hasPermission: resp.data.hasPermission,
      };

      localStorage.setItem('permissionsObj', JSON.stringify(permissionsObj));
      resolve(resp);
    });
  });
};
