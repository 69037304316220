import React from 'react';
import PropTypes from 'prop-types';
import noroles from '../assets/images/NoRoles.svg';
class NoRoles extends React.Component {
  render() {
    const logoStyle = {
      ...this.props.style,
      width: this.props.width,
    };

    return (
      <div style={{position: 'relative'}}>
        <img style={logoStyle} alt="" src={noroles} />
      </div>
    );
  }
}

NoRoles.propTypes = {
  width: PropTypes.string,
  style: PropTypes.object,
};

NoRoles.defaultProps = {
  width: '100%',
  style: {},
};

export default NoRoles;
