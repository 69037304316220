import React from 'react';
import PropTypes from 'prop-types';

import * as Layout from 'src/layout';

import EducationForm from 'src/forms/EducationForm';
import MaUserInfoEditForm from './modernApprenticeship/candidateProfile/MaUserInfoEditForm';

class EducationModal extends React.Component {
  render() {
    const {isOpen, onClose, onSubmit, disableEmail, isMaSegment} = this.props;
    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          {isMaSegment === false ? (
            <EducationForm
              onSubmit={onSubmit}
              onCancel={onClose}
              disableEmail={disableEmail}
              isMaSegment={isMaSegment}
            />
          ) : (
            <MaUserInfoEditForm
              onSubmit={onSubmit}
              onCancel={onClose}
              isMaSegment={isMaSegment}
            />
          )}
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

EducationModal.propTypes = {
  disableEmail: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isMaSegment: PropTypes.bool,
};
EducationModal.defaultProps = {isMaSegment: false};

export default EducationModal;
