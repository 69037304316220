import React from 'react';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import loadingGif from '../../assets/images/loading.gif';
import resumeSvg from '../../assets/images/Resume.svg';
class NoResumePage extends React.Component {
  render() {
    var thanksDiv = <div />;

    if (this.props.createSeekerLoading === true) {
      thanksDiv = (
        <div className="text-align-center bgcolorw">
          <br />
          <br />
          <br />
          <img src={loadingGif} height="200" width="200" alt="Loading..." />
          <p role="status">Loading...</p>
        </div>
      );
    } else {
      thanksDiv = (
        <div>
          <div className="padding-64 bd-graybackground">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <center>
                  <img
                    className="padding-bottom-sixteen"
                    src={resumeSvg}
                    alt=""
                  />
                </center>
                <div className="normal-title bold padding-bottom-sixteen text-align-center">
                  Your Resume + Our Network
                </div>
                <div className="padding-32 bd-whitebackground text-align-left">
                  <div className="secondary-subheader padding-bottom-thirtytwo text-align-center">
                    We can’t wait to find you a great company. Having a resume
                    will help us make the best connection.
                  </div>
                  <div className="secondary-subheader text-align-center">
                    Reach out to your school’s career services for help writing
                    your resume. Email{' '}
                    <a href="mailto:support@ascendindiana.com">
                      support@ascendindiana.com
                    </a>{' '}
                    if you’d like resources or advice to get started. Please
                    re-complete this form once it’s ready.
                  </div>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <DocumentTitle title="No Resume">
        <div className="fullindy">
          <br />
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">{thanksDiv}</div>
              <div className="col-md-2" />
            </div>
          </div>
          <br />
        </div>
      </DocumentTitle>
    );
  }
}

NoResumePage.propTypes = {
  seeker: PropTypes.object,
  createSeekerLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    seeker: state.seekers.seeker,
    createSeekerLoading: state.seekers.createSeekerLoading,
  };
}

export default connect(mapStateToProps)(NoResumePage);
