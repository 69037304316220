import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import switchOn from '../assets/images/switch-on.svg';
import switchOff from '../assets/images/switch-off.svg';
import {Tooltip as ReactTooltip} from 'react-tooltip';

const PublishToggle = ({published, onChange, disabled}) => {
  const imageText = published ? switchOn : switchOff;

  return (
    <>
      <ReactTooltip
        id="publish-tooltip"
        className="solid-tooltip-dark"
        closeOnEsc={true}
      />
      <div
        data-tooltip-id="publish-tooltip"
        data-tooltip-hidden={!disabled}
        data-tooltip-place="top"
        data-tooltip-html={
          'Your profile cannot be published yet.<br />Reach out to support@ascendindiana.com with questions.'
        }
        className={disabled ? 'disabled' : 'clickable'}
        onClick={(e) => {
          if (!disabled) {
            onChange(e);
          }
        }}
        role="button"
        onKeyDown={(e) => {
          if ((e.key === 'Enter' || e.key === ' ') && !disabled) {
            e.preventDefault();
            onChange(e);
          }
          return;
        }}
        tabIndex={0}
      >
        <img alt="Toggle Publish Status" src={imageText} />
        <div
          className={classNames('inlineblock', {
            'normal-subheader': published,
            'accent-3-subheader': !published && !disabled,
            'disabled-subheader': disabled,
          })}
        >
          &nbsp;&nbsp;Status: {published ? 'Published' : 'Draft'}
        </div>
      </div>
    </>
  );
};

PublishToggle.propTypes = {
  published: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PublishToggle.defaultProps = {
  disabled: false,
};

export default PublishToggle;
