import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {withRouter} from '../../utils/commonHooks';
import {track as analyticsTrack} from '../../utils/analytics';
import 'url-search-params-polyfill';
import {PageHeader, PageFooter} from '../../layout';
import discoveryStyles from './CandidateDiscoveryPage.module.scss';

import PreviousButton from 'src/components/PreviousButton';
import {Tabs, Tab} from 'react-bootstrap';
import ExploreCandidates from './ExploreCandidates';
import SavedCandidates from './SavedCandidates';

class CandidateDiscoveryPage extends React.Component {
  constructor() {
    super();
    this.batchSelectMaximumCount = 100;
  }

  state = {
    tabKey: '1',
    isSavedCandidatesSelected: false,
    displaySavedCandidatesTab: true,
  };

  async componentDidMount() {
    const isSavedCandidateRoute =
      window.location.pathname.includes('saved_candidates');

    this.setState({
      isSavedCandidatesSelected: isSavedCandidateRoute,
      tabKey: isSavedCandidateRoute ? '2' : 1,
      displaySavedCandidatesTab:
        isSavedCandidateRoute || this.props.profile.role !== 'ASCEND_RECRUITER',
    });

    window.addEventListener('resize', this.resize.bind(this));
    this.resize();

    analyticsTrack({eventName: 'CandidateDiscoveryPage'});
  }

  resize() {
    let currentWidth = window.innerWidth <= 1765;
    if (currentWidth !== this.state.isMinimumWidth) {
      this.setState({isMinimumWidth: currentWidth});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  handleTabClick = (tabKey) => {
    this.setState({
      tabKey: tabKey,
      isSavedCandidatesSelected: tabKey === '2',
    });

    if (tabKey === '2') {
      analyticsTrack({eventName: 'SavedCandidatesClicked'});
    }

    if (tabKey === '1' && this.props.profile.role === 'ASCEND_RECRUITER') {
      this.setState({displaySavedCandidatesTab: false});
    }
  };

  render() {
    return (
      <DocumentTitle title="Candidate Discovery">
        <div className="wholepage no-scroll">
          <PageHeader
            showAdminLinks={this.props.profile.permissions.includes(
              'VIEW_ADMIN_MENU'
            )}
          />
          <PreviousButton />
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className={discoveryStyles.mainBody}>
                <Tabs
                  id="searchTab"
                  activeKey={this.state.tabKey}
                  onSelect={this.handleTabClick}
                  defaultActiveKey={
                    this.state.isSavedCandidatesSelected ? '2' : '1'
                  }
                  className={discoveryStyles.exploreTabs}
                >
                  <Tab
                    key={1}
                    eventKey={1}
                    title={'Explore Candidates'}
                    tabClassName={
                      this.state.tabKey === '1'
                        ? discoveryStyles.exploreActiveTabHeader
                        : discoveryStyles.exploreInactiveTabHeader
                    }
                  >
                    <ExploreCandidates
                      id="exploreCandidatesContents"
                      key="exploreCandidatesContents"
                    />
                  </Tab>

                  {this.state.displaySavedCandidatesTab &&
                    (this.props.profile.role === 'COMPANY_RECRUITER' ||
                      this.props.profile.role === 'COMPANY_RECRUITER_ADMIN' ||
                      this.props.profile.role === 'ASCEND_RECRUITER') && (
                      <Tab
                        key={2}
                        eventKey={2}
                        title={'Saved Candidates'}
                        tabClassName={
                          this.state.tabKey === '2'
                            ? discoveryStyles.exploreActiveTabHeader
                            : discoveryStyles.exploreInactiveTabHeader
                        }
                      >
                        <SavedCandidates
                          id="savedCandidatesContents"
                          key="savedCandidatesContents"
                          isTabActive={this.state.tabKey === '2'}
                        />
                      </Tab>
                    )}
                </Tabs>
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

CandidateDiscoveryPage.propTypes = {
  history: PropTypes.object.isRequired,
  profile: PropTypes.object,
};

export default withRouter(CandidateDiscoveryPage);
