import React from 'react';
import PropTypes from 'prop-types';

export default class AscendCheckbox extends React.Component {
  render() {
    const {inputOnChange, label, name, onChangeAction, inputValue, onFocus} =
      this.props;

    return (
      <div
        style={{width: '100%', textAlign: 'left'}}
        className="custom-control custom-checkbox form-check"
      >
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={inputValue}
          onChange={(event) => {
            onChangeAction(event.target.checked);
            inputOnChange(event.target.checked);
          }}
          onFocus={onFocus}
          className="custom-control-input form-check-input checkbox-formatting"
        />
        <label className="custom-control-label form-check-label" htmlFor={name}>
          {label}
        </label>
      </div>
    );
  }
}

AscendCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object,
  inputValue: PropTypes.any,
  onChangeAction: PropTypes.func,
  onFocus: PropTypes.func.isRequired,
  label: PropTypes.string,
  inputOnChange: PropTypes.func.isRequired,
};
