import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

export default function SingleDatePickerField({
  input,
  placeholder,
  minDate = null,
  maxDate = null,
  isClearable = false,
}) {
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (input.value) {
      setStartDate(new Date(input.value));
    } else if (input.value === '') {
      setStartDate(null);
    }
  }, [input.value]);

  const handleChange = (date) => {
    setStartDate(date);
    input.onChange(date);
  };

  return (
    <div>
      <DatePicker
        placeholderText={placeholder}
        isClearable={isClearable}
        selected={startDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        onSelect={handleChange}
        closeOnScroll={true}
        popperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [5, 0],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
              },
            },
          ],
        }}
      />
    </div>
  );
}

SingleDatePickerField.propTypes = {
  meta: PropTypes.object,
  maxFields: PropTypes.number,
  input: PropTypes.object,
  placeholder: PropTypes.string,
  isOutsideRange: PropTypes.func,
  yearAndMonthNavigation: PropTypes.bool,
  startingYear: PropTypes.number,
  openDirection: PropTypes.string,
  required: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  isClearable: PropTypes.bool,
};

SingleDatePickerField.defaultProps = {
  placeholder: 'Select a Date',
};
