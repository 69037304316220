import React from 'react';
import PropTypes from 'prop-types';
import OverallMatchWidget from '../OverallMatchWidget';
import {CloseSvg} from 'src/assets/svg/svgComponents';

class CandidateMatchCard extends React.Component {
  render() {
    const [skillsPercentage, stylePercentage] = this.props.matchData
      .matchLevels || [0, 0];
    const {degreeRankFit, experienceLevelFit, employmentTypeFit} =
      this.props.matchData;

    const failReasons = [];
    if (degreeRankFit === false) {
      failReasons.push('Education Level');
    }
    if (experienceLevelFit === false) {
      failReasons.push('Experience Level Preference');
    }
    if (employmentTypeFit === false) {
      failReasons.push('Employment Type Preference');
    }

    let hoverModalTopPixels = {
      1: '-110px',
      2: '-135px',
      3: '-165px',
    };
    if (failReasons.length > 0) {
      return (
        <div
          style={{
            top: hoverModalTopPixels[failReasons.length],
            ...this.props.cardStyle,
          }}
        >
          <div
            style={{
              padding: '6px',
            }}
          >
            <div className="bd-graybackground">
              <div className="normal-caption text-align-center pt-2">
                You did not match to this role
                <br /> because of your:
                <ul className="list-group" style={{padding: '8px 0 8px 25px'}}>
                  {failReasons.map((reason, index) => {
                    return (
                      <li
                        key={index}
                        className="list-group-item bd-graybackground"
                        style={{
                          border: 'none',
                          padding: '4px',
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <CloseSvg style={{marginRight: '5px'}} />
                        <strong style={{marginTop: '-4px'}}>{reason}</strong>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={this.props.cardStyle}>
          <div className="padding-top-eight">
            <table
              style={{textAlign: 'center', height: '100%', width: '100%'}}
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      width: '33%',
                      verticalAlign: 'middle',
                      borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                    }}
                  >
                    <div className="secondary-body bold extraspace">
                      Skills Match
                    </div>
                    <hr className="black-bar" />
                    <div className="normal-headline bold">{`${skillsPercentage}%`}</div>
                  </td>
                  <td
                    style={{
                      width: '33%',
                      verticalAlign: 'middle',
                    }}
                  >
                    <div className="secondary-body bold extraspace">
                      Work Style
                    </div>
                    <hr className="primary-bar" />
                    <div className="active-headline bold">{`${stylePercentage}%`}</div>
                  </td>
                </tr>

                <tr>
                  <td
                    colSpan="3"
                    style={{
                      verticalAlign: 'middle',
                      padding: '8px 8px 8px 8px',
                    }}
                  >
                    <div className="bd-graybackground">
                      <OverallMatchWidget
                        overallFit={this.props.matchData.overallFit}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}

CandidateMatchCard.propTypes = {
  cardStyle: PropTypes.object,
  matchData: PropTypes.object.isRequired,
};

CandidateMatchCard.defaultProps = {
  cardStyle: {},
  degreeRankFit: true,
};

export default CandidateMatchCard;
