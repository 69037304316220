import React, {useState} from 'react';
import stylesFilterList from './FilterListTags.module.scss';
import stylesTagButton from './FilterTagButton.module.scss';
import PropTypes from 'prop-types';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {CloseCircleSvg} from '../../assets/svg/svgComponents';
import {isEmpty} from '../../utils/helper';
import * as ACTIONS from 'src/actions/ActionTypes.js';
import {CANDIDATE_DISCOVERY} from './candidateDiscoveryGlobals';
import SaveSearchModal from 'src/components/SaveSearchModal';

const ONE_DAY = 24 * 60 * 60 * 1000;
const TEN_DAYS = 10 * ONE_DAY;

function FilterTagButton({
  tag = {tagName: 'Tag Name'},
  removeAction,
  isMobile,
  showRemoveButton,
}) {
  return (
    <div
      className={`${stylesTagButton.container} mt-2 ${
        isMobile ? stylesTagButton.mobileWidth : ''
      }`}
    >
      <span aria-label="tag" className={`tag-onboarding ms-2`}>
        {tag.tagName}
      </span>

      {showRemoveButton && (
        <button
          tabIndex={0}
          type="button"
          aria-label={`remove ${tag.tagName}`}
          className={`${stylesTagButton.rightSide} border-0 `}
          style={{background: 'transparent'}}
          onClick={removeAction}
        >
          <CloseCircleSvg style={{height: '13px', verticalAlign: 'middle'}} />
        </button>
      )}
    </div>
  );
}

FilterTagButton.propTypes = {
  tag: PropTypes.object,
  removeAction: PropTypes.func,
  isMobile: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
};

FilterTagButton.defaultProps = {
  showRemoveButton: true,
};

// "tags" must be an array of objects that contains the tagName field
function TagList({
  tags = [{tagName: 'Tag Name', name: 'react-key-name'}],
  isMobile,
  clearAllButtons,
  enableApplyButton,
  applyFilterAction,
  sourceObjectName,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  let {clearAllFiltersEnabled, savedSearchSelectedItem} = useSelector(
    (state) => {
      if (sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates) {
        return {
          clearAllFiltersEnabled:
            state.candidateDiscoveryReducer
              .savedCandidatesClearAllFiltersEnabled,
        };
      } else {
        return {
          clearAllFiltersEnabled:
            state.candidateDiscoveryReducer.clearAllFiltersEnabled,
          savedSearchSelectedItem:
            state.candidateDiscoveryReducer.savedSearchSelectedItem,
        };
      }
    }
  );

  return (
    <>
      <SaveSearchModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => setIsModalOpen(false)}
      />
      <div className="d-flex flex-wrap align-self-center">
        {isEmpty(tags) === false &&
          tags.map((x) => {
            return (
              <div className="d-flex flex-wrap" key={x.name}>
                <FilterTagButton
                  tag={x}
                  removeAction={() => {
                    if (
                      sourceObjectName !== CANDIDATE_DISCOVERY.savedCandidates
                    ) {
                      dispatch({
                        type: ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED,
                        savedSearchSelectedItem: null,
                      });
                    }

                    x.removeAction();
                  }}
                  isMobile={isMobile}
                />
              </div>
            );
          })}
        {enableApplyButton && (
          <button
            className={`${stylesFilterList.clearAllFiltersButton} btn nowrap `}
            onClick={applyFilterAction}
          >
            Apply Filters
          </button>
        )}

        {isEmpty(tags) === false && clearAllFiltersEnabled && (
          <>
            <button
              className={`btn nowrap ${stylesFilterList.clearAllFiltersButton}`}
              onClick={() => {
                if (clearAllButtons) {
                  clearAllButtons();
                }
                dispatch({
                  type:
                    sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
                      ? ACTIONS.SAVED_CANDIDATE__CLEAR_ALL
                      : ACTIONS.CANDIDATE_DISCOVERY__CLEAR_ALL,
                });
              }}
            >
              Reset Filters
            </button>

            {savedSearchSelectedItem === null &&
              sourceObjectName !== CANDIDATE_DISCOVERY.savedCandidates && (
                <button
                  className={` btn nowrap ${stylesFilterList.clearAllFiltersButton} `}
                  onClick={() => setIsModalOpen(true)}
                >
                  Save This Search
                </button>
              )}
          </>
        )}
      </div>
    </>
  );
}

TagList.propTypes = {
  tags: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  clearAllButtons: PropTypes.func.isRequired,
  applyFilterAction: PropTypes.func.isRequired,
  enableApplyButton: PropTypes.bool,
  enableClearAllFilters: PropTypes.bool,
  sourceObjectName: PropTypes.string,
};

export default function FilterListTags({
  applyFiltersClick,
  onClear,
  sourceObjectName,
}) {
  const dispatch = useDispatch();

  let {selectedFieldValues, applyButtonEnabled, loggedInAfter, selectedJob} =
    useSelector((state) => {
      return {
        selectedFieldValues:
          sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
            ? state.candidateDiscoveryReducer.savedCandidateSelectedFieldValues
            : state.candidateDiscoveryReducer.selectedFieldValues,
        applyButtonEnabled:
          sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
            ? state.candidateDiscoveryReducer.savedCandidatesApplyButtonEnabled
            : state.candidateDiscoveryReducer.applyButtonEnabled,
        loggedInAfter:
          sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
            ? state.candidateDiscoveryReducer.savedCandidatesLoggedInAfter
            : state.candidateDiscoveryReducer.exploreCandidatesLoggedInAfter,
        selectedJob:
          sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
            ? state.candidateDiscoveryReducer.savedCandidatesJobObj
            : state.candidateDiscoveryReducer.jobObj,
      };
    }, shallowEqual);

  let tagsToShow = selectedFieldValues.map((x) => {
    return {
      tagName: x.substring(x.indexOf('#') + 1),
      name: x,
      removeAction: () => {
        dispatch({
          type:
            sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates
              ? ACTIONS.SAVED_CANDIDATE__TOGGLE_FILTER_ITEM
              : ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM,
          toggledName: x,
          source: sourceObjectName,
        });

        applyFiltersClick(x);
      },
    };
  });

  const getDayLabel = (daysInMilliseconds) => {
    if (daysInMilliseconds === '') {
      return 'Any';
    }
    if (daysInMilliseconds === TEN_DAYS) {
      return 'Last 10 Days';
    }
    return 'Last 30 Days';
  };

  if (loggedInAfter !== '') {
    tagsToShow.push({
      tagName: `Logged In ${getDayLabel(loggedInAfter)}`,
      name: loggedInAfter,
      removeAction: () => {
        if (sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates) {
          dispatch({
            type: ACTIONS.SAVED_CANDIDATE__DATE_RADIO_SELECT,
            toggledName: CANDIDATE_DISCOVERY.savedCandidatesLoggedInAfter,
            toggledValue: '',
          });
          applyFiltersClick(CANDIDATE_DISCOVERY.savedCandidatesLoggedInAfter);
        } else {
          dispatch({
            type: ACTIONS.CANDIDATE_DISCOVERY__DATE_RADIO_SELECT,
            toggledName: CANDIDATE_DISCOVERY.exploreCandidatesLoggedInAfter,
            toggledValue: '',
          });
          applyFiltersClick(CANDIDATE_DISCOVERY.exploreCandidatesLoggedInAfter);
        }
      },
    });
  }

  if (selectedJob !== null) {
    tagsToShow.push({
      tagName: `${selectedJob.label}`,
      name: `jobId-${selectedJob.value}`,
      removeAction: () => {
        if (sourceObjectName === CANDIDATE_DISCOVERY.savedCandidates) {
          dispatch({
            type: ACTIONS.SAVED_CANDIDATE__JOB_SELECT,
            savedCandidatesJobObj: null,
          });
        } else {
          dispatch({
            type: ACTIONS.CANDIDATE_DISCOVERY__JOB_SELECT,
            jobObj: null,
          });
        }
      },
    });
  }
  return (
    <>
      {(tagsToShow.length > 0 ||
        (tagsToShow.length === 0 && applyButtonEnabled === true)) && (
        <div className={`${stylesFilterList.tagListContainer}`}>
          <div className="nowrap pt-2">
            {applyButtonEnabled ? 'New Filters to Apply:' : 'Applied Filters:'}
          </div>
          <div className="d-flex flex-row ">
            <TagList
              tags={tagsToShow}
              clearAllButtons={onClear}
              enableApplyButton={applyButtonEnabled}
              applyFilterAction={applyFiltersClick}
              sourceObjectName={sourceObjectName}
            />
          </div>
        </div>
      )}
    </>
  );
}

FilterListTags.propTypes = {
  applyFiltersClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  sourceObjectName: PropTypes.string,
};
