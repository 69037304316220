import merge from 'deepmerge';

import * as types from '../actions/ActionTypes';

const initialState = {
  jobs: [],
  teams: [],
  jobsLoading: false,
  teamsLoading: false,
  allRolesSelected: true,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  jobsTotalItems: 0,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  teamsTotalItems: 0,
  jobsSortTerm: '',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  roleTypeIds: [],
  employmentTypeIds: [],
  experienceLevelIds: [],
  regionIds: [],
  industryIds: [],
  degreeTypeRankIds: [],
  segmentIds: [],
  remoteStatus: [],
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export default function recruiterJobAndTeamSearch(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.RECRUITER_JOB_AND_TEAM_SEARCH__UPDATE_STATE:
      let newState = merge(state, action.newState, {
        arrayMerge: overwriteMerge,
      });
      if (action.clearWorkStyles) {
        newState.roleTypeIds = [];
        newState.employmentTypeIds = [];
        newState.experienceLevelIds = [];
        newState.regionIds = [];
        newState.industryIds = [];
        newState.degreeTypeRankIds = [];
        newState.segmentIds = [];
        newState.remoteStatus = [];
      }
      return newState;
    case types.RECRUITER_JOB_SEARCH__GET_JOBS_REQUEST:
      return {
        ...state,
        jobs: [],
        jobsLoading: true,
      };
    case types.RECRUITER_JOB_SEARCH__GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.response.body.jobs,
        jobsLoading: false,
        jobsCurrentPage: action.response.body.page,
        jobsCurrentItemsPerPage: action.response.body.itemsPerPage,
        jobsTotalItems: action.response.body.totalItems,
      };
    case types.RECRUITER_JOB_SEARCH__GET_JOBS_FAILURE:
      return {
        ...state,
        jobs: [],
        jobsLoading: false,
      };
    case types.RECRUITER_TEAM_SEARCH__GET_TEAMS_REQUEST:
      return {...state, teams: [], teamsLoading: true};
    case types.RECRUITER_TEAM_SEARCH__GET_TEAMS_SUCCESS:
      return {...state, teams: action.response.body.teams, teamsLoading: false};
    case types.RECRUITER_TEAM_SEARCH__GET_TEAMS_FAILURE:
      return {...state, teams: [], teamsLoading: false};
    default:
      return state;
  }
}
