import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import * as jobsActions from 'src/actions/jobs';
import * as seekersActions from 'src/actions/seekers';
import * as teamsActions from 'src/actions/teams';
import * as employersActions from 'src/actions/employers';

import * as Layout from 'src/layout';
import JobProfile from 'src/components/JobProfile';
import {jobIsWatched} from 'src/utils/jobHelper';
import {teamIsWatched} from 'src/utils/teamHelper';
import {employerIsWatched} from 'src/utils/employerHelper';
import {jobIsApplied} from 'src/utils/jobHelper';
import AscendDataTable from 'src/components/AscendDataTable';
import WatchIcon from 'src/components/WatchIcon';
import {CandidateInfo} from 'src/components/CandidateInfo';
import CandidateSubMenu from 'src/components/Candidate/CandidateSubMenu';
import {includesApprenticeProgram} from '../../utils/miscHelper';
import {withRouter} from '../../utils/commonHooks';
import PreviousButton from 'src/components/PreviousButton';
import {has_permission, PERMISSIONS} from '../../services/authorizationApi';
import CandidateNotifyMissingRole from '../../components/Candidate/CandidateNotifyMissingRole';
import MatchCircle from 'src/components/MatchCircle';

class CandidateFavoritesPage extends React.Component {
  state = {
    jobProfileOpen: false,
    jobId: null,
    currentJobPage: 0,
    currentTeamPage: 0,
    currentEmployerPage: 0,
    jobSortTerm: '',
    teamSortTerm: '',
    employerSortTerm: '',
  };

  componentDidMount() {
    let seekerId;
    if (this.props.match.params.id) {
      seekerId = this.props.match.params.id;
    } else {
      seekerId = this.props.loggedInSeekerId;
    }
    if (this.props.seekerLoading === false) {
      this.props.getSeeker(seekerId);
    }
    has_permission(PERMISSIONS.ASCEND_RECRUITER).then((resp) => {
      this.showAdminLinks = resp.data.hasPermission;
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.seeker !== this.props.seeker) {
      this.setState({
        isMaSegment: this.props.seeker.segments.some(
          (x) => x.isApprenticeProgram
        ),
      });
    }
  }

  handleTeamWatched = (teamId, watched) => {
    if (watched) {
      this.props.createTeamWatch(teamId);
    } else {
      this.props.deleteTeamWatch(teamId);
    }
  };

  handleJobWatched = (job, watched) => {
    if (watched) {
      this.props.createJobWatch(job);
    } else {
      this.props.deleteJobWatch(job.id);
    }
  };

  handleEmployerWatched = (employerId, watched) => {
    if (watched) {
      this.props.createEmployerWatch(employerId);
    } else {
      this.props.deleteEmployerWatch(employerId);
    }
  };

  handleJobClicked = (jobId) => {
    this.props.getJobToCache(jobId);
    this.setState({jobProfileOpen: true, jobId: jobId});
  };

  handleProfileClosed = () => {
    this.setState({jobProfileOpen: false});
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  handleJobPageChange = (newPage) => {
    this.setState({currentJobPage: newPage});
  };

  handleJobSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    this.setState({
      jobSortTerm: sortTerm,
      currentJobPage: 0,
    });
  };

  handleTeamPageChange = (newPage) => {
    this.setState({currentTeamPage: newPage});
  };

  handleTeamSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    this.setState({
      teamSortTerm: sortTerm,
      currentTeamPage: 0,
    });
  };

  handleEmployerPageChange = (newPage) => {
    this.setState({currentEmployerPage: newPage});
  };

  handleEmployerSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    this.setState({
      employerSortTerm: sortTerm,
      currentEmployerPage: 0,
    });
  };

  render() {
    const {seeker} = this.props;
    const {isMaSegment} = this.state;

    var job = {};
    if (this.state.jobId in this.props.jobCache) {
      job = this.props.jobCache[this.state.jobId];
    }

    const isDataAvailable = () => {
      return seeker.id !== undefined;
    };

    const findMatchData = (jobId) => {
      const watchedJob = _.find(seeker.watchedJobs, {id: jobId});
      if (watchedJob && watchedJob.matchData) {
        return watchedJob.matchData;
      } else {
        return {
          matchLevels: [0, 0, 0],
          ascendFit: false,
          overallFit: false,
          overallScore: 0,
          skillsScore: 0,
          stylesScore: 0,
          traitsScore: 0,
        };
      }
    };

    const jobTableColumns = [
      {
        displayName: <div style={{textAlign: 'left', width: '100%'}}>ROLE</div>,
        content: (job) => {
          if (job.published) {
            return (
              <span
                className="clickable"
                onClick={() => this.handleJobClicked(job.id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    this.handleJobClicked(job.id);
                  }
                }}
                tabIndex={0}
              >
                {job.name}
              </span>
            );
          } else {
            return job.name;
          }
        },
      },
      {
        displayName: 'EMPLOYER',
        content: (job) => {
          return (
            <Link
              className="normal-subheader"
              style={{textDecoration: 'none'}}
              to={'/employer/' + job.employerId}
            >
              <div className="normal-subheader">{job.employerName}</div>
            </Link>
          );
        },
      },
      {
        displayName: 'MATCH',
        content: (job) => {
          if (!job.published) {
            return <div>Role Closed</div>;
          }
          let matchData = {
            matchLevels: [0, 0],
            ascendFit: false,
            overallFit: false,
            overallScore: 0,
            skillsScore: 0,
            stylesScore: 0,
            traitsScore: 0,
          };
          if (job.matchData) {
            matchData = job.matchData;
          }
          return (
            <div>
              <div className="inlineblock match-chart-host">
                <MatchCircle
                  matchData={matchData}
                  viewerType="CANDIDATE"
                  isApprenticeProgram={includesApprenticeProgram(
                    this.props.seeker
                  )}
                  isCompact
                  noMargin
                />
              </div>
            </div>
          );
        },
      },
      {
        displayName: '',
        content: (job) => {
          if (!job.published) {
            return <div tabIndex={0}></div>;
          }

          return (
            <div>
              <div className="inlineblock float-right">
                <WatchIcon
                  watched={jobIsWatched(job.id, this.props.seeker)}
                  className=""
                  onClick={() => this.handleJobWatched(job, false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.handleJobWatched(job, false);
                    }
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ];

    const teamTableColumns = [
      {
        displayName: 'TEAM',
        minWidth: '150',
        content: (team) => {
          return (
            <Link
              className="normal-subheader"
              style={{textDecoration: 'none'}}
              to={'/team/' + team.id}
            >
              <div className="normal-title">{team.name}</div>
            </Link>
          );
        },
      },
      {
        displayName: 'EMPLOYER',
        minWidth: '200',
        content: (team) => {
          return (
            <Link
              className="normal-subheader"
              style={{textDecoration: 'none'}}
              to={'/employer/' + team.employerId}
            >
              <div className="normal-subheader">{team.employerName}</div>
            </Link>
          );
        },
      },
      {
        displayName: 'SUMMARY',
        content: (team) => {
          return (
            <div>
              <div className="inlineblock" tabIndex={0}>
                {team.summary}
              </div>
              <div className="inlineblock float-right">
                <WatchIcon
                  watched={teamIsWatched(team.id, this.props.seeker)}
                  onClick={() => this.handleTeamWatched(team.id, false)}
                  className=""
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.handleTeamWatched(team.id, false);
                    }
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ];

    const employerTableColumns = [
      {
        displayName: (
          <div style={{textAlign: 'left', width: '100%'}}>EMPLOYER</div>
        ),
        content: (employer) => {
          return (
            <Link
              className="normal-subheader"
              style={{textDecoration: 'none'}}
              to={'/employer/' + employer.id}
            >
              <div className="normal-title">{employer.name}</div>
            </Link>
          );
        },
      },
      {
        displayName: <span>&nbsp;</span>,
        content: (employer) => {
          return (
            <div>
              <div className="inlineblock float-right">
                <WatchIcon
                  watched={employerIsWatched(employer.id, this.props.seeker)}
                  onClick={() => this.handleEmployerWatched(employer.id, false)}
                  className=""
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.handleEmployerWatched(employer.id, false);
                    }
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <DocumentTitle title="Favorites">
        <div className="wholepage">
          <Layout.PageHeader
            title={null}
            showAdminLinks={this.showAdminLinks}
          />

          {job && job.id && (
            <JobProfile
              key={job.id}
              isOpen={this.state.jobProfileOpen}
              job={job}
              matchData={findMatchData(this.state.jobId)}
              onClose={this.handleProfileClosed}
              watched={jobIsWatched(this.state.jobId, this.props.seeker)}
              applied={jobIsApplied(this.state.jobId, this.props.seeker)}
              onAppliedChanged={(applied) =>
                this.handleAppliedChanged(this.state.jobId, applied)
              }
              onWatchChanged={(watched) => this.handleJobWatched(job, watched)}
            />
          )}
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <PreviousButton />
              <div className="container">
                {this.props.showJobNotFoundModal && (
                  <CandidateNotifyMissingRole
                    isOpen={this.props.showJobNotFoundModal}
                    onClose={() => this.props.setJobNotFoundModal(false)}
                  />
                )}
                <div className="row">
                  <CandidateInfo
                    seeker={this.props.seeker}
                    isMaSegment={isMaSegment}
                  />
                </div>
                {this.props.role === 'JOB_SEEKER' && <CandidateSubMenu />}
              </div>
              {isDataAvailable() && (
                <div className="row justify-content-center">
                  <div className="col-9">
                    <br />
                    <h3
                      className="normal-title bold padding-bottom-sixteen mb-0"
                      tabIndex={0}
                    >
                      Roles
                    </h3>
                    <AscendDataTable
                      showSearch={false}
                      tableColumns={jobTableColumns}
                      items={this.props.seeker.watchedJobs}
                      currentPage={this.state.currentJobPage}
                      itemsPerPage={100}
                      totalItems={100}
                      handlePageChange={this.handleTeamPageChange}
                      handleSortChange={this.handleTeamSortChange}
                      itemsLoading={this.props.seekerLoading}
                      enableSort={false}
                    />
                    <h3
                      className="normal-title bold padding-bottom-sixteen mb-0"
                      tabIndex={0}
                    >
                      Teams
                    </h3>
                    <AscendDataTable
                      showSearch={false}
                      tableColumns={teamTableColumns}
                      items={this.props.seeker.watchedTeams}
                      currentPage={this.state.currentTeamPage}
                      itemsPerPage={100}
                      totalItems={100}
                      handlePageChange={this.handleJobPageChange}
                      handleSortChange={this.handleJobSortChange}
                      itemsLoading={this.props.seekerLoading}
                      enableSort={false}
                    />
                    <h3
                      className="normal-title bold padding-bottom-sixteen mb-0"
                      tabIndex={0}
                    >
                      Employers
                    </h3>
                    <AscendDataTable
                      showSearch={false}
                      tableColumns={employerTableColumns}
                      items={this.props.seeker.watchedEmployers}
                      currentPage={this.state.currentEmployerPage}
                      itemsPerPage={100}
                      totalItems={100}
                      handlePageChange={this.handleEmployerPageChange}
                      handleSortChange={this.handleEmployerSortChange}
                      itemsLoading={this.props.seekerLoading}
                      enableSort={false}
                    />
                  </div>
                </div>
              )}
            </main>
          </div>
          <Layout.PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

CandidateFavoritesPage.propTypes = {
  match: PropTypes.object.isRequired,
  loggedInSeekerId: PropTypes.string,
  seeker: PropTypes.object,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  role: PropTypes.string,
  getSeeker: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  createTeamWatch: PropTypes.func.isRequired,
  deleteTeamWatch: PropTypes.func.isRequired,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  createEmployerWatch: PropTypes.func.isRequired,
  deleteEmployerWatch: PropTypes.func.isRequired,
  seekerLoading: PropTypes.bool.isRequired,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loggedInSeekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
    jobCache: state.jobs.jobCache,
    role: state.profile.role,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
  };
}

const actions = {
  ...seekersActions,
  ...teamsActions,
  ...employersActions,
  ...jobsActions,
};

export default withRouter(
  connect(mapStateToProps, actions)(CandidateFavoritesPage)
);
