import React from 'react';
import {Form} from 'react-final-form';
import {updateSeekerAction} from 'src/actions/SeekerActions';
import * as Layout from '../layout';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {AscendRadioField} from 'src/formFields/ascendRadioField/AscendRadioField';
import {BusyButton} from 'src/components/BusyButton';

export default function AuthorizationModal({seeker, isOpen, onClose}) {
  const dispatch = useDispatch();

  const radioYesNoOptions = [
    {
      value: 'true',
      label: (
        <span>
          <b>Yes</b>, this student is legally authorized to be employed in the
          United States.
        </span>
      ),
    },
    {
      value: 'false',
      label: (
        <span>
          <b>No</b>, this student is not legally authorized to be employed in
          the United States.
        </span>
      ),
    },
  ];

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['workStatusAllowed'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  const handleSubmit = async (formValues) => {
    const data = {
      id: seeker.id,
      workStatusAllowed: formValues.workStatusAllowed,
    };
    dispatch(updateSeekerAction(data));
    onClose();
  };

  return (
    <>
      <Layout.AscendModal
        isOpen={isOpen}
        disableClose={true}
        centerVertically={true}
        dialogClassName="noBorder"
      >
        <Layout.AscendModalHeader headerClass="bd-modalheader" isOpen={isOpen}>
          <div className="normal-headline-white bold text-align-center mb-0">
            Change {seeker ? `${seeker.firstName} ${seeker.lastName}` : ''}'s
            employment authorization status
          </div>
        </Layout.AscendModalHeader>

        <Layout.AscendModalBody hasClose={false}>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              workStatusAllowed:
                seeker && seeker.workStatusAllowed
                  ? seeker.workStatusAllowed.toString()
                  : '',
            }}
            validate={validate}
          >
            {({handleSubmit, submitting, values, form}) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="secondary-subheader text-align-center">
                    Please confirm you are editing the correct student and have
                    checked with them before changing their status.
                  </div>
                  <div
                    className="mb-0"
                    style={{
                      maxWidth: 'fit-content',
                      textAlign: 'left',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingTop: '16px',
                    }}
                  >
                    <AscendRadioField
                      name="workStatusAllowed"
                      options={radioYesNoOptions}
                      labelCssClass={'mt-0 onboarding-form-label'}
                      label={``}
                    />
                  </div>
                </div>

                <div className="row justify-content-center nowrap">
                  <button
                    type="button"
                    className="btn btn-transparent col-2 me-4"
                    onClick={onClose}
                  >
                    Cancel
                  </button>

                  <BusyButton
                    buttonText={'Save'}
                    busyText={'Saving'}
                    className="col-2"
                    style={{width: '210px', height: '40px'}}
                    float={'float-right'}
                    buttonIcon={'save'}
                    alignText={'text-center'}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    </>
  );
}

AuthorizationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  seeker: PropTypes.object,
};
