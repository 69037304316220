import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import infoBlueCircleSvg from 'src/assets/images/info-blue-circle.svg';

class NumberField extends React.Component {
  render() {
    const {
      input,
      meta: {touched, error, warning},
      label,
      labelActionText,
      labelActionCallBack,
      icon,
      size,
      maxLength,
      disabled,
      visible,
      showBackground,
      labelBold,
      datamultiline,
      dataeffect,
      datatype,
      dataplace,
      datatip,
      showInfoIcon,
      placeholder,
      labelCssClass,
    } = this.props;

    const wrappedLabel = () => {
      if (icon) {
        return (
          <div
            id={label
              .replace(/\//g, '')
              .replaceAll(' ', '')
              .replaceAll('/', '')
              .replaceAll('.', '')}
          >
            {icon}&nbsp;&nbsp;{label}
          </div>
        );
      } else
        return (
          <>
            {label}
            {labelActionText ? (
              <span
                className="clickable active-body float-right "
                style={{margin: '0', padding: '0'}}
                onClick={labelActionCallBack}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    labelActionCallBack();
                  }
                }}
              >
                {labelActionText}
              </span>
            ) : null}
            {showInfoIcon && (
              <Fragment>
                &nbsp;&nbsp;
                <img
                  tabIndex={0}
                  className="clickable"
                  style={{width: '5%'}}
                  alt={datatip.replace('<br/>', '')}
                  src={infoBlueCircleSvg}
                  data-multiline={datamultiline}
                  data-effect={dataeffect}
                  data-type={datatype}
                  data-place={dataplace}
                  data-tip={datatip}
                  data-html={true}
                />
              </Fragment>
            )}
          </>
        );
    };
    const labelCss = !labelCssClass
      ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
      : labelCssClass;
    return (
      <Fragment>
        {visible && (
          <>
            <div className="form form-group" style={{marginBottom: '0.05rem'}}>
              <label htmlFor={input.name} className={labelCss}>
                {wrappedLabel()}
              </label>
              <input
                id={input.name}
                autoComplete={'off'}
                {...input}
                className={`${
                  showBackground ? 'bd-verylightbluebackground' : ''
                } form-control normal-subheader`}
                disabled={disabled}
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                size={size}
                maxLength={maxLength}
                placeholder={placeholder}
              />
              {touched &&
                ((error && <div className="form-input-error">{error}</div>) ||
                  (warning && (
                    <div className="form-input-error">{warning}</div>
                  )) || <div className="form-input-error">&nbsp;</div>)}
              {!touched && <div className="form-input-error">&nbsp;</div>}
            </div>
          </>
        )}
      </Fragment>
    );
  }
}

NumberField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  labelActionText: PropTypes.string,
  labelActionCallBack: PropTypes.func,
  size: PropTypes.number,
  maxLength: PropTypes.number,
  visible: PropTypes.bool,
  showBackground: PropTypes.bool,
  labelBold: PropTypes.bool,
  datamultiline: PropTypes.bool,
  dataeffect: PropTypes.string,
  datatype: PropTypes.string,
  dataplace: PropTypes.string,
  datatip: PropTypes.string,
  disabled: PropTypes.bool,
  showTipAsText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  labelCssClass: PropTypes.string,
};

NumberField.defaultProps = {
  size: 100,
  maxLength: 64,
  visible: true,
  backgroundColor: false,
};

export default NumberField;
