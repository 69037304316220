import React, {Fragment, forwardRef} from 'react';
import PropTypes from 'prop-types';

const PushButton = forwardRef((props, ref) => {
  const buildButton = (props) => {
    return (
      <>
        {props.isIconLeftAligned && props.buttonSvg}
        {props.buttonText}
        {!props.isIconLeftAligned && props.buttonSvg}
      </>
    );
  };

  const handleClick = (e) => {
    props.action({
      isToggled: !props.isToggled,
      buttonValue: props.buttonValue,
      buttonText: e.target.innerText && e.target.innerText.trim(),
      buttonAttr: props.buttonAttr,
      id: props.id,
      value: props.buttonValue,
    });
  };

  return (
    <Fragment>
      <button
        type="button"
        id={props.id}
        key={props.id}
        ref={ref}
        className={`btn 
        ${props.isToggled ? props.buttonToggledCssClass : 'btn-quick-filter'} ${
          props.className
        }`}
        onClick={(e) => handleClick(e)}
        disabled={!props.enabled}
        aria-pressed={props.isToggled}
        aria-expanded={props.ariaExpanded}
      >
        {buildButton(props)}
        {props.buttonSubText && (
          <div
            className={
              props.isToggled
                ? 'push-btn-onboarding-subtext-selected'
                : 'push-btn-onboarding-subtext'
            }
          >
            {props.buttonSubText}
          </div>
        )}
      </button>
    </Fragment>
  );
});

PushButton.propTypes = {
  id: PropTypes.string,
  buttonValue: PropTypes.any,
  buttonText: PropTypes.string,
  buttonSubText: PropTypes.string,
  buttonSvg: PropTypes.any,
  buttonToggledCssClass: PropTypes.string,
  action: PropTypes.func,
  buttonAttr: PropTypes.string,
  isToggled: PropTypes.bool,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  isIconLeftAligned: PropTypes.bool,
};

PushButton.defaultProps = {
  buttonText: 'Click',
  isToggled: false,
  enabled: true,
  buttonToggledCssClass: 'btn-quick-filter-selected',
  className: '',
  isIconLeftAligned: false,
};

export default PushButton;
