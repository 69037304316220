import React from 'react';
import PropTypes from 'prop-types';

export default function InviteToApplySeekerSelect({
  onChange,
  selected,
  seeker,
}) {
  const handleSelected = () => {
    onChange(seeker);
  };

  return (
    <div className="bd-whitebackground padding-16 ">
      <div className="custom-control custom-checkbox form-check">
        <input
          id={seeker.id}
          name={seeker.id}
          type="checkbox"
          checked={!!selected}
          onChange={handleSelected}
          className="custom-control-input form-check-input"
        />
        <label className="custom-control-label" htmlFor={seeker.id}>
          {seeker.name}
          <br />
          {`${seeker.experienceLevels.map((x) => ` ${x.years}`)} | ${seeker.employmentTypes.map((x) => ` ${x.name}`)} | ${seeker.educationLevel.name}`}
        </label>
      </div>
    </div>
  );
}

InviteToApplySeekerSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  seeker: PropTypes.object,
};
