import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Form} from 'react-final-form';
import * as Layout from '../layout';
import {BusyButton} from './BusyButton';
import {WageSelector} from './WageSelector';
import editSvg from 'src/assets/images/Edit.svg';

const validate = (values) => {
  const errors = {};

  if (values.minimumHourlyWage === '') {
    errors.minimumHourlyWage = 'Select at least one.';
  }
  if (values.minimumYearlySalary === '') {
    errors.minimumYearlySalary = 'Select at least one.';
  }

  return errors;
};

class WagePreferenceModal extends React.Component {
  render() {
    const {isOpen, onClose, employmentTypes} = this.props;
    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            keepDirtyOnReinitialize={true}
          >
            {({handleSubmit}) => {
              return (
                <form>
                  <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-10 text-align-center">
                      <div className="mt-5 bold normal-subheader padding-bottom-eight">
                        <img src={editSvg} alt="" /> &nbsp; Edit minimum salary
                        OR hourly wage.
                      </div>
                      <hr />
                      <br />
                      <WageSelector
                        selectedEmploymentTypes={
                          employmentTypes &&
                          employmentTypes.filter(
                            (x) => x.collectWageInfo === true
                          )
                        }
                        initialValues={this.props.initialValues}
                      />
                    </div>
                    <div className="col-md-1" />
                  </div>
                  <br />

                  <div className="text-align-center nowrap">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-transparent me-4"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <BusyButton
                        onClick={handleSubmit}
                        buttonText={'Save'}
                        busyText={'Saving'}
                        style={{width: '160px', height: '40px'}}
                        float={'float-right'}
                        buttonIcon={'save'}
                        alignText={'text-center'}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

WagePreferenceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  employmentTypes: PropTypes.array,
  minimumYearlySalary: PropTypes.string,
  minimumHourlyWage: PropTypes.string,
  initialValues: PropTypes.object,
};

let EditWagePreferenceModal = (props) => <WagePreferenceModal {...props} />;

const mapStateToProps = (state) => {
  const {id, minimumYearlySalary, minimumHourlyWage, employmentTypes} =
    state.seekers.seekerForEdit;
  return {
    employmentTypes,
    initialValues: {
      id,
      minimumYearlySalary,
      minimumHourlyWage,
    },
  };
};

EditWagePreferenceModal = connect(mapStateToProps)(EditWagePreferenceModal);

export {EditWagePreferenceModal};
