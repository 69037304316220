import React from 'react';
import PropTypes from 'prop-types';

class MarkColor extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 31 49"
        version="1.1"
        role="img"
        aria-label="Ascend Logo"
      >
        <title>mark-color</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fillRule="nonzero" fill={this.props.color}>
            <path d="M29.1864407,2.35981638 L1.44774011,25.6771186 C0.396680791,26.6586864 0,27.0322034 0,28.3033192 L0,40.2558616 C0.00758556252,41.8584949 1.29310719,43.1620139 2.89548023,43.1918785 L7.92492938,43.1918785 C7.51956215,43.1918785 7.60642655,41.8860169 7.60642655,40.2558616 L7.60642655,30.4778249 C7.66491319,29.4712779 8.16646258,28.5424042 8.9759887,27.9413842 L26.7252825,13.0904661 L29.2443503,11.0086158 C30.5762712,9.85042373 30.6920904,9.27132768 30.6920904,8.38241525 L30.6920904,0.868644068 C30.6920904,1.14371469 30.1129944,1.5085452 29.1864407,2.35981638 Z"></path>
            <path d="M11.5066384,39.4306497 L13.0586158,38.0987288 L13.0586158,38.0987288 L27.6170904,25.9174435 C27.9790254,25.6452684 28.6420904,24.7274011 28.6420904,24.0498588 L28.6420904,15.9251412 C28.6420904,16.2841808 27.1190678,17.4655367 26.5254944,17.9519774 L13.1599576,29.0387712 L13.1599576,29.0387712 L11.6340395,30.3793785 C11.1447034,30.8687147 10.7132768,31.557839 10.7132768,32.0992938 L10.7132768,40.1052966 L10.7132768,40.1052966 C10.7132768,40.1052966 10.7132768,40.125565 11.5066384,39.4306497 Z"></path>
            <path d="M25.9840395,31.8387006 L24.9387712,32.7073446 L24.5015537,33.0750706 L12.450565,43.1918785 L24.6984463,43.1918785 C25.3003569,43.1981251 25.8794767,42.9619748 26.3053869,42.5366093 C26.7312971,42.1112437 26.9681882,41.5324266 26.9627119,40.9305085 L26.9627119,30.9816384 C26.7745056,31.1669492 26.1606638,31.7055085 25.9840395,31.8387006 Z"></path>
            <path d="M25.6510593,48.9712571 L1.17846045,48.9712571 C0.533259852,48.9604651 0.0123791387,48.4408642 0,47.7956921 L0,46.6375 C0.0108384916,45.9911969 0.532157399,45.469878 1.17846045,45.4590395 L25.6510593,45.4590395 C26.2973624,45.469878 26.8186813,45.9911969 26.8295198,46.6375 L26.8295198,47.7956921 C26.8171406,48.4408642 26.2962599,48.9604651 25.6510593,48.9712571 Z"></path>
            <path d="M20.8185028,1.24216102 L2.89548023,16.2783898 L2.89548023,16.2783898 L0.972881356,17.931709 C0.208474576,18.6932203 0,19.0117232 0,20.2451977 L0,23.9398305 C0,23.7632062 0.550141243,23.2217514 1.08580508,22.767161 L2.98813559,21.1688559 L2.98813559,21.1688559 L20.9082627,6.1355226 C21.452613,5.58538136 22.0056497,5.06129944 22.0056497,4.05367232 L22.0056497,0 C22.0056497,0.240324859 21.1051554,1.025 20.8185028,1.24216102 Z"></path>
          </g>
        </g>
      </svg>
    );
  }
}

MarkColor.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

// 0.63 ratio
MarkColor.defaultProps = {
  color: '#ffffff',
  width: '31px',
  height: '49px',
};

export default MarkColor;
