import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CandidateMatchCard from './matchWidget/CandidateMatchCard';
import RecruiterMatchCard from './matchWidget/RecruiterMatchCard';

export default function MatchCircle({
  viewerType,
  matchData,
  matchCardStyle = {},
  isApprenticeProgram = false,
  isCompact = false,
  isVertical = false,
  noMargin = false,
  showLabel = false,
}) {
  const labels = [
    <span className="text-align-center d-block mb-1">
      Skills
      <br />
      Match
    </span>,
    <span className="text-align-center d-block mb-1">
      Preferences
      <br />
      Match
    </span>,
  ];
  const trailColors = ['#109AD7', '#004E7D'];
  const nameId = Math.random().toString(36).slice(2, 11);
  const [showChart] = useState(
    matchData.matchLevels ? matchData.showMatchChart : false
  );

  const getChartCssClassNames = (show, leftPosition) => {
    if (show) {
      return `match-card-container-focus ${
        viewerType === 'RECRUITER'
          ? leftPosition < 150
            ? 'match-card-recruiter-right'
            : 'match-card-recruiter'
          : viewerType === 'CANDIDATE'
            ? leftPosition < 300
              ? 'match-card-candidate-right'
              : 'match-card-candidate'
            : ''
      }`;
    }
    return `match-card-container ${
      viewerType === 'RECRUITER'
        ? leftPosition < 150
          ? 'match-card-recruiter-right'
          : 'match-card-recruiter'
        : viewerType === 'CANDIDATE'
          ? leftPosition < 300
            ? 'match-card-candidate-right'
            : 'match-card-candidate'
          : ''
    }`;
  };

  const setChartClass = (id) => {
    if (viewerType === 'CANDIDATE') {
      const chart = document.getElementById(`${nameId}matchCard${id}`);
      var leftPosition = chart.parentElement.getBoundingClientRect().x;

      var className = getChartCssClassNames(false, leftPosition);
      chart.className = `match-button ${className}`;
    }
  };

  const failReasons = [];

  if (matchData.degreeRankFit === false) {
    failReasons.push('Education Level');
  }

  if (matchData.degreeRankFit === undefined && isApprenticeProgram === true) {
    failReasons.push('Education Level');
    failReasons.push('Apprentice Program');
  }

  if (!matchData.experienceLevelFit) {
    failReasons.push('Experience Level Preference');
  }
  if (matchData.employmentTypeFit === false) {
    failReasons.push('Employment Type Preference');
  }

  let hoverModalTopPixels = {
    1: '-110px',
    2: '-140px',
    3: '-160px',
    4: '-180px',
  };
  const getHoverModalTop = (key, defaultValue = '-180px') => {
    return hoverModalTopPixels[key] || defaultValue;
  };

  if (!isApprenticeProgram) {
    return (
      <span
        className="match-chart"
        onMouseEnter={() => setChartClass(matchData.id)}
      >
        <span
          className={`d-flex align-items-center ${
            isVertical ? 'flex-column mt-1' : noMargin ? '' : 'mt-3'
          } ${noMargin ? '' : 'ms-3 me-3'} `}
          aria-describedby={`${nameId}matchCard${matchData.id}`}
        >
          {showChart &&
            matchData.matchLevels.map((x, i) => (
              <span
                key={i}
                className={`me-3 ${isCompact ? 'mb-1' : ''}`}
                style={{
                  width: isCompact ? (showLabel ? '67px' : '50px') : '100px',
                }}
              >
                {!isCompact && showLabel && (
                  <span
                    style={{
                      color: 'var(--neutral-gray-1)',
                      fontSize: isCompact ? '10px' : '16px',
                      fontWeight: '600',
                      lineHeight: '16.5px',
                      letterSpacing: '0.02em',
                      textAlign: 'center',
                    }}
                  >
                    {labels[i]}
                  </span>
                )}
                <CircularProgressbar
                  styles={buildStyles({
                    textColor: 'var(--neutral-gray-1)',
                    pathColor: trailColors[i],
                    trailColor: 'D9D9D9',
                  })}
                  strokeWidth={10}
                  value={x}
                  text={`${x}%`}
                />
                {isCompact && showLabel && (
                  <span
                    style={{
                      color: 'var(--neutral-gray-1)',
                      fontSize: isCompact ? '10px' : '16px',
                      fontWeight: '600',
                      lineHeight: '16.5px',
                      letterSpacing: '0.02em',
                      textAlign: 'center',
                    }}
                  >
                    {labels[i]}
                  </span>
                )}
              </span>
            ))}
          {!showChart && <span className="nowrap match-na pe-2 ps-0">N/A</span>}
        </span>
        <div
          role="tooltip"
          id={`${nameId}matchCard${matchData.id}`}
          className={`match-card-container ${
            viewerType === 'RECRUITER'
              ? 'match-card-recruiter'
              : viewerType === 'CANDIDATE'
                ? 'match-card-candidate'
                : ''
          }`}
          style={{
            top: getHoverModalTop(failReasons.length),
          }}
        >
          {viewerType === 'RECRUITER' && (
            <RecruiterMatchCard
              cardStyle={matchCardStyle}
              jobTraits={matchData.jobTraits}
              matchData={matchData}
            />
          )}
          {viewerType === 'CANDIDATE' && (
            <CandidateMatchCard
              cardStyle={matchCardStyle}
              matchData={matchData}
            />
          )}
        </div>
      </span>
    );
  } else {
    return null;
  }
}

MatchCircle.propTypes = {
  viewerType: PropTypes.string.isRequired,
  matchCardStyle: PropTypes.object,
  matchData: PropTypes.object.isRequired,
  isApprenticeProgram: PropTypes.bool,
  isCompact: PropTypes.bool,
  isVertical: PropTypes.bool,
  noMargin: PropTypes.bool,
  showLabel: PropTypes.bool,
};
