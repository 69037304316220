import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './AllJobsPage.module.scss';
import FilterTagGroup from 'src/components/FilterTagGroup';
import FilterTagCheckbox from 'src/components/FilterTagCheckbox';
import arrowDown from 'src/assets/images/ArrowDown.svg';
import arrowUp from 'src/assets/images/ArrowUp.svg';
import $ from 'jquery';
import _ from 'lodash';
import * as allJobSearchActions from 'src/actions/allJobSearch';
import {remoteStatusTypes} from '../jobDiscovery/roleUtil';

import * as ACTIONS from 'src/actions/ActionTypes.js';
import {API_URL} from 'src/utils/helper.js';

export default function AllJobsFilters({
  employerId,
  isSeeker,
  isMaSeeker,
  isRecruiter,
}) {
  const [expandFilters, setExpandFilters] = useState(false);
  const dispatch = useDispatch();

  let {
    allJobSearchProps,
    experienceLevels,
    experienceLevelsLoading,
    employmentTypes,
    employmentTypesLoading,
    regions,
    regionsLoading,
    roleTypes,
    roleTypesLoading,
    industries,
    industriesLoading,
    segments,
    degreeTypeRanks,
    degreeRankLoading,
  } = useSelector((state) => {
    return {
      allJobSearchProps: state.allJobSearch,
      experienceLevels: state.experienceLevels.experienceLevels,
      experienceLevelsLoading: state.experienceLevels.experienceLevelsLoading,
      employmentTypes: state.employmentTypes.employmentTypes,
      employmentTypesLoading: state.employmentTypes.employmentTypesLoading,
      jobAndTeamSearchProps: state.jobAndTeamSearch,
      recruiterJobAndTeamSearchProps: state.recruiterJobAndTeamSearch,
      regions: state.regions.regions,
      regionsLoading: state.regions.regionsLoading,
      roleTypes: state.roleTypes.roleTypes,
      roleTypesLoading: state.roleTypes.roleTypesLoading,
      industries: state.industries.industries,
      industriesLoading: state.industries.industriesLoading,
      segments: state.segments.segmentList,
      degreeTypeRanks: state.degreeTypeRank.degreeRank,
      degreeRankLoading: state.degreeTypeRank.degreeRankLoading,
    };
  });

  if (experienceLevels.length === 0 && experienceLevelsLoading === false) {
    dispatch({
      types: [
        ACTIONS.EXPERIENCE_LEVELS__GET_REQUEST,
        ACTIONS.EXPERIENCE_LEVELS__GET_SUCCESS,
        ACTIONS.EXPERIENCE_LEVELS__GET_FAILURE,
      ],
      uri: `${API_URL}/v2/experience_levels`,
      method: 'GET',
    });
  }

  if (employmentTypes.length === 0 && employmentTypesLoading === false) {
    dispatch({
      types: [
        ACTIONS.EMPLOYMENT_TYPES__GET_REQUEST,
        ACTIONS.EMPLOYMENT_TYPES__GET_SUCCESS,
        ACTIONS.EMPLOYMENT_TYPES__GET_FAILURE,
      ],
      uri: `${API_URL}/employment_types`,
      method: 'GET',
      isAuthenticated: false,
    });
  }

  if (regions.length === 0 && regionsLoading === false) {
    dispatch({
      types: [
        ACTIONS.REGIONS__GET_REQUEST,
        ACTIONS.REGIONS__GET_SUCCESS,
        ACTIONS.REGIONS__GET_FAILURE,
      ],
      uri: `${API_URL}/regions`,
      method: 'GET',
    });
  }

  if (roleTypes.length === 0 && roleTypesLoading === false) {
    dispatch({
      types: [
        ACTIONS.ROLE_TYPES__GET_REQUEST,
        ACTIONS.ROLE_TYPES__GET_SUCCESS,
        ACTIONS.ROLE_TYPES__GET_FAILURE,
      ],
      uri: `${API_URL}/role_types`,
      method: 'GET',
    });
  }

  if (degreeTypeRanks.length === 0 && degreeRankLoading === false) {
    dispatch({
      types: [
        ACTIONS.DEGREE_RANK__GET_REQUEST,
        ACTIONS.DEGREE_RANK__GET_SUCCESS,
        ACTIONS.DEGREE_RANK_UNITS__GET_FAILURE,
      ],
      uri: `${API_URL}/degree_types/ranks`,
      method: 'GET',
    });
  }

  if (industries.length === 0 && industriesLoading === false) {
    dispatch({
      types: [
        ACTIONS.INDUSTRIES__GET_REQUEST,
        ACTIONS.INDUSTRIES__GET_SUCCESS,
        ACTIONS.INDUSTRIES__GET_FAILURE,
      ],
      uri: `${API_URL}/industries`,
      method: 'GET',
    });
  }

  let empTypeList = isMaSeeker
    ? employmentTypes
    : employmentTypes.filter((y) => y.name !== 'Apprenticeship');

  const handleTagClear = (tagType) => {
    dispatch(
      allJobSearchActions.clearAllFilterGroupAndSearch(employerId, tagType)
    );
  };

  const handleTagChange = (tagType, newTag) => {
    dispatch(
      allJobSearchActions.changeAllFilterTagAndSearch(
        employerId,
        tagType,
        newTag.name,
        newTag.selected
      )
    );
  };

  const handleRadioChange = (value) => {
    dispatch(
      allJobSearchActions.changeAllFilterRadioAndSearch(employerId, value)
    );
  };

  return (
    <>
      {(!isMaSeeker || isRecruiter) && (
        <div className="col-lg-3" id={styles.filter_container}>
          <>
            <h2 className="normal-title">
              <strong>Filters</strong>
            </h2>

            <button
              id={styles.show_filters}
              onClick={() => {
                setExpandFilters(!expandFilters);
                $('#jobDiscoveryFilters').toggle(150, 'linear');
              }}
            >
              {expandFilters ? `Hide` : `Show`} Filters{' '}
              <img
                alt=""
                src={expandFilters ? arrowDown : arrowUp}
                className="twisty-chevron"
              />
            </button>
            <div id="jobDiscoveryFilters">
              <FilterTagGroup
                title="START DATE"
                showTwisty={true}
                expanded={false}
                onClear={() => handleRadioChange('')}
              >
                <label className="radio">
                  Immediate
                  <input
                    id="immediate"
                    name="immediate"
                    type="radio"
                    value="immediate"
                    className="custom-control-input"
                    checked={allJobSearchProps.startDateTag === 'immediate'}
                    onChange={(tag) => handleRadioChange('immediate')}
                  />
                  <span className="radio-checkmark-orange" />
                </label>
                <label className="radio">
                  Flexible
                  <input
                    id="flexible"
                    name="flexible"
                    type="radio"
                    value="immediate"
                    className="custom-control-input"
                    checked={allJobSearchProps.startDateTag === 'flexible'}
                    onChange={(tag) => handleRadioChange('flexible')}
                  />
                  <span className="radio-checkmark-orange" />
                </label>
                <label className="radio">
                  Specific
                  <input
                    id="specific"
                    name="specific"
                    type="radio"
                    value="specific"
                    className="custom-control-input"
                    checked={allJobSearchProps.startDateTag === 'specific'}
                    onChange={(tag) => handleRadioChange('specific')}
                  />
                  <span className="radio-checkmark-orange" />
                </label>
              </FilterTagGroup>
              <FilterTagGroup
                title="EMPLOYMENT TYPE"
                showTwisty={true}
                expanded={false}
                onClear={() => handleTagClear('employmentTypeIds')}
              >
                {employmentTypesLoading && (
                  <span className={styles.emptyOptions}>Loading...</span>
                )}
                {!employmentTypesLoading &&
                  empTypeList.map((eType, index) => {
                    return (
                      <FilterTagCheckbox
                        key={index}
                        onChange={(tag) =>
                          handleTagChange('employmentTypeIds', tag)
                        }
                        name={`${eType.id}employmentType`}
                        label={eType.name}
                        selected={allJobSearchProps.employmentTypeIds.includes(
                          eType.id
                        )}
                      />
                    );
                  })}
              </FilterTagGroup>
              <FilterTagGroup
                title="EXPERIENCE LEVEL"
                showTwisty={true}
                expanded={false}
                onClear={() => handleTagClear('experienceLevelIds')}
              >
                {experienceLevelsLoading && (
                  <span className={styles.emptyOptions}>Loading...</span>
                )}
                {!experienceLevelsLoading &&
                  experienceLevels.map((eType, index) => {
                    return (
                      <FilterTagCheckbox
                        key={index}
                        onChange={(tag) => {
                          handleTagChange('experienceLevelIds', tag);
                        }}
                        name={`${eType.id}experienceLevel`}
                        label={eType.years}
                        selected={allJobSearchProps.experienceLevelIds.includes(
                          eType.id
                        )}
                      />
                    );
                  })}
              </FilterTagGroup>
              <FilterTagGroup
                title="REGIONS"
                showTwisty={true}
                expanded={false}
                maxTags={6}
                onClear={() => handleTagClear('regionIds')}
              >
                {regionsLoading && (
                  <span className={styles.emptyOptions}>Loading...</span>
                )}
                {!regionsLoading &&
                  regions
                    .filter((r) => r.name !== 'Any')
                    .sort((a, b) => a.order - b.order)
                    .map((region, index) => {
                      return (
                        <FilterTagCheckbox
                          key={index}
                          onChange={(tag) => handleTagChange('regionIds', tag)}
                          name={`${region.id}region`}
                          label={
                            region.name === 'Any'
                              ? 'No Preference'
                              : region.name
                          }
                          selected={allJobSearchProps.regionIds.includes(
                            region.id
                          )}
                        />
                      );
                    })}
              </FilterTagGroup>

              <FilterTagGroup
                title="REMOTE WORK"
                showTwisty={true}
                expanded={false}
                onClear={() => handleTagClear('remoteStatus')}
              >
                {remoteStatusTypes.map((remoteStatusType, index) => {
                  return (
                    <FilterTagCheckbox
                      key={index}
                      onChange={(tag) => {
                        handleTagChange('remoteStatus', tag);
                      }}
                      name={`${remoteStatusType.name}`}
                      label={remoteStatusType.name}
                      selected={
                        allJobSearchProps.remoteStatus[remoteStatusType.name]
                          ?.selected
                      }
                    />
                  );
                })}
              </FilterTagGroup>

              <FilterTagGroup
                title="ROLES"
                showTwisty={true}
                expanded={false}
                maxTags={6}
                onClear={() => handleTagClear('roleTypeIds')}
              >
                {roleTypesLoading && (
                  <span className={styles.emptyOptions}>Loading...</span>
                )}
                {!roleTypesLoading &&
                  _.sortBy(
                    roleTypes.filter((r) => r.name !== 'Any'),
                    (x) => x.name
                  ).map((rType, index) => {
                    return (
                      <FilterTagCheckbox
                        key={index}
                        onChange={(tag) => handleTagChange('roleTypeIds', tag)}
                        name={`${rType.id}roleType`}
                        label={
                          rType.name === 'Any' ? 'No Preference' : rType.name
                        }
                        selected={allJobSearchProps.roleTypeIds.includes(
                          rType.id
                        )}
                      />
                    );
                  })}
              </FilterTagGroup>
              <FilterTagGroup
                title="INDUSTRIES"
                showTwisty={true}
                expanded={false}
                maxTags={6}
                onClear={() => handleTagClear('industryIds')}
              >
                {industriesLoading && (
                  <span className={styles.emptyOptions}>Loading...</span>
                )}
                {!industriesLoading &&
                  _.sortBy(
                    industries.filter((r) => r.name !== 'Any'),
                    (x) => x.order
                  ).map((industry, index) => {
                    return (
                      <FilterTagCheckbox
                        key={index}
                        onChange={(tag) => handleTagChange('industryIds', tag)}
                        name={`${industry.id}industry`}
                        label={
                          industry.name === 'Any'
                            ? 'No Preference'
                            : industry.name
                        }
                        selected={allJobSearchProps.industryIds.includes(
                          industry.id
                        )}
                      />
                    );
                  })}
              </FilterTagGroup>
              <FilterTagGroup
                title="DEGREE TYPE"
                showTwisty={true}
                expanded={false}
                maxTags={6}
                onClear={() => handleTagClear('degreeTypeRankIds')}
                tooltip="Intended for Full-time Job seekers. <br/>Only jobs requiring the following selection will be shown."
              >
                {_.sortBy(
                  degreeTypeRanks.filter((r) => r.name !== 'Any'),
                  (x) => x.name
                ).map((degreeType, index) => {
                  return (
                    <FilterTagCheckbox
                      key={index}
                      onChange={(tag) =>
                        handleTagChange('degreeTypeRankIds', tag)
                      }
                      name={`${degreeType.id}degreeType`}
                      label={degreeType.name}
                      selected={allJobSearchProps.degreeTypeRankIds.includes(
                        degreeType.id
                      )}
                    />
                  );
                })}
              </FilterTagGroup>
              {!isSeeker && !isRecruiter && (
                <FilterTagGroup
                  onClear={() => handleTagClear('segmentIds')}
                  title={'SEGMENT'}
                  showTwisty={true}
                  expanded={false}
                  maxTags={6}
                >
                  {_.sortBy(
                    segments.filter((r) => r.name !== 'Any'),
                    (x) => x.name
                  ).map((segment, index) => {
                    return (
                      <FilterTagCheckbox
                        key={index}
                        onChange={(tag) => handleTagChange('segmentIds', tag)}
                        name={`${segment.id}segment`}
                        label={
                          segment.name === 'Any' ? 'All Segments' : segment.name
                        }
                        selected={allJobSearchProps.segmentIds.includes(
                          segment.id
                        )}
                      />
                    );
                  })}
                </FilterTagGroup>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
}

AllJobsFilters.propTypes = {
  employerId: PropTypes.string,
  isSeeker: PropTypes.bool,
  isMaSeeker: PropTypes.bool,
  isRecruiter: PropTypes.bool,
};
