import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Form, Field} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import _ from 'lodash';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import * as institutionsActions from 'src/actions/institutions';
import {BusyButton} from '../components/BusyButton';
import * as segmentActions from '../actions/SegmentActions';
import {getSortedLookup} from 'src/utils/fieldHelper';
import {
  AvatarField,
  TextField,
  SelectFieldArray,
  SelectField,
} from 'src/formFields';
import {graduationMonths, graduationYears, gpas} from 'src/utils/fieldHelper';
import MultiSelectField from '../formFields/MultiSelectField';
import * as employmentActions from '../actions/employmentTypes';
import {OnChange} from 'react-final-form-listeners';
import {
  isValidHttpsUrl,
  INVALID_URL_ERROR_MESSAGE,
} from 'src/utils/fieldHelper';

const majorLabel = (index) => {
  switch (index) {
    case 0:
      return 'Major';
    case 1:
      return 'Second Major';
    case 2:
      return 'Third Major';
    default:
      return 'Major';
  }
};

const minorLabel = (index) => {
  switch (index) {
    case 0:
      return 'Minor (optional)';
    case 1:
      return 'Second Minor (optional)';
    case 2:
      return 'Third Minor (optional)';
    default:
      return 'Minor (optional)';
  }
};

// type = "majors" or "minors"
const generateMajorMinorIds = (seeker, type) => {
  var ids = [];
  if (seeker[type]) {
    ids = seeker[type].map((x) => x.id);
    if (ids.length < 1) {
      return [null];
    } else {
      return ids;
    }
  } else {
    return [null];
  }
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['firstName', 'lastName', 'email'];
  _.forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.linkedinProfile && !isValidHttpsUrl(values.linkedinProfile)) {
    errors.linkedinProfile = INVALID_URL_ERROR_MESSAGE;
  }

  return errors;
};

class EducationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOtherInstitutionField: this.props.showOtherInstitutionField || false,
      campuses: this.props.getCampuses(props.seeker.institutionId),
      schools: this.props.getSchools(props.seeker.campusId),
      changeEmailModalIsOpen: false,
      maSegment: false,
    };
  }

  formRef = React.createRef();

  componentDidMount(): void {
    this.props.getSegments();
    this.props.getEmploymentTypes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.maSegment === this.state.maSegment &&
      this.props.isMaSegment !== this.state.maSegment
    ) {
      this.setState({maSegment: this.props.isMaSegment});
    }
  }

  render() {
    const {
      disableEmail,
      onCancel,
      institutions,
      campuses,
      schools,
      majors,
      minors,
      educationLevels,
      getCampuses,
      institutionsLoading,
      campusesLoading,
      schoolsLoading,
      educationLevelsLoading,
      profile,
      segmentsLoading,
    } = this.props;

    const {maSegment} = this.state;

    const segments = getSortedLookup(this.props.segments, ['name'], 'varies');
    const maSegmentValue = segments.filter((x) => {
      return x.isApprenticeProgram === true;
    });

    const getEmailField = () => {
      if (profile.role === 'JOB_SEEKER') {
        return (
          <Field
            name="email"
            component={TextField}
            disabled={disableEmail}
            size={32}
            maxLength={128}
            label="Email"
            onFocus={this.trackFocus}
            labelActionText={'Update Email'}
            labelActionCallBack={() => {
              this.setState({changeEmailModalIsOpen: true});
            }}
          />
        );
      } else {
        return (
          <Field
            name="email"
            component={TextField}
            disabled={disableEmail}
            size={32}
            maxLength={128}
            label="Email"
            onFocus={this.trackFocus}
          />
        );
      }
    };

    return (
      <Form
        onSubmit={this.props.onSubmit}
        validate={validate}
        mutators={{...arrayMutators}}
        keepDirtyOnReinitialize={true}
        initialValues={this.props.initialValues}
      >
        {({handleSubmit, form}) => {
          this.formRef.current = form;
          return (
            <form>
              <OnChange name="institutionId">
                {(institutionId) => {
                  const opt = _.find(institutions, {name: 'Other'});
                  if (institutionId) {
                    if (opt && institutionId === opt.id) {
                      this.setState({showOtherInstitutionField: true});
                    } else {
                      this.setState({showOtherInstitutionField: false});
                      form.change('otherInstitution', null);
                    }
                    getCampuses(institutionId);
                    form.change('campusId', null);
                    form.change('schoolId', null);
                  }
                }}
              </OnChange>

              <OnChange name="campusId">
                {(campusId) => {
                  if (campusId) {
                    this.props.getSchools(campusId);
                    form.change('schoolId', null);
                  }
                }}
              </OnChange>

              <OnChange name="segments">
                {(segments) => {
                  if (segments.some((x) => x.isApprenticeProgram)) {
                    form.change(
                      'employmentTypes',
                      this.props.employmentTypes.filter(
                        (x) => x.name === 'Apprenticeship'
                      )
                    );
                    this.setState({maSegment: true});
                  } else {
                    this.setState({maSegment: false});
                  }
                }}
              </OnChange>

              <div className="bd-graybackground padding-64">
                <center>
                  <div className="normal-title padding-bottom-eight bold">
                    Edit Information
                  </div>
                </center>
                <hr />
                <div className="row">
                  <div className="col-lg-6">
                    <Field
                      name="firstName"
                      component={TextField}
                      size={32}
                      label="First Name"
                      onFocus={this.trackFocus}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="lastName"
                      component={TextField}
                      size={32}
                      label="Last Name"
                      onFocus={this.trackFocus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">{getEmailField()}</div>

                  <div className="col-lg-6">
                    <Field
                      name="linkedinProfile"
                      component={TextField}
                      size={32}
                      label="LinkedIn"
                      onFocus={this.trackFocus}
                      parse={(value) => value}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Field
                      name="phone"
                      component={TextField}
                      size={32}
                      maxLength={128}
                      label="Mobile Phone"
                      onFocus={this.trackFocus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Field name="avatarUrl" component={AvatarField} />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-6">
                    <Field
                      name="institutionId"
                      label="Institution"
                      message="Don't see your institution? Didn't attend college? Select 'Other.'"
                      isLoading={institutionsLoading}
                      component={SelectField}
                      options={institutions.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                  {this.state.showOtherInstitutionField && (
                    <div className="col-lg-6">
                      <Field
                        name="otherInstitution"
                        label="Enter your Institution"
                        component={TextField}
                        onFocus={this.trackFocus}
                        props={{
                          disabled: !this.state.showOtherInstitutionField,
                        }}
                      />
                    </div>
                  )}
                  <div className="col-lg-6">
                    <Field
                      name="campusId"
                      label="Campus"
                      isLoading={campusesLoading}
                      isDisabled={campuses.length === 0}
                      component={SelectField}
                      options={campuses.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Field
                      name="schoolId"
                      label="School"
                      isDisabled={schools.length === 0}
                      isLoading={schoolsLoading}
                      component={SelectField}
                      options={schools.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="educationLevelId"
                      label="Education Level"
                      isLoading={educationLevelsLoading}
                      component={SelectField}
                      options={educationLevels.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <Field
                      name="graduationMonth"
                      label="Graduation Month"
                      component={SelectField}
                      options={graduationMonths.slice(1).map((opt) => {
                        return {value: opt, label: opt};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="graduationYear"
                      label="Graduation Year"
                      component={SelectField}
                      options={graduationYears.slice(1).map((opt) => {
                        return {value: opt, label: opt};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="gpa"
                      label="Grade Point Average"
                      component={SelectField}
                      options={gpas.map((opt) => {
                        return {value: opt, label: opt};
                      })}
                      onFocus={this.trackFocus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <FieldArray
                      name="majorIds"
                      component={SelectFieldArray}
                      options={majors.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      labelMaker={majorLabel}
                      maxFields={3}
                      onFocus={this.trackFocus}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FieldArray
                      name="minorIds"
                      component={SelectFieldArray}
                      options={minors.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      labelMaker={minorLabel}
                      maxFields={3}
                      onFocus={this.trackFocus}
                    />
                  </div>
                  {this.props.role === 'ASCEND_RECRUITER' && (
                    <div className="col-lg-6">
                      <Field
                        name="segments"
                        label="Segments"
                        isDisabled={segments.length === 0}
                        isLoading={segmentsLoading}
                        component={MultiSelectField}
                        width="100%"
                        options={maSegment === true ? maSegmentValue : segments}
                      />
                    </div>
                  )}
                </div>

                <br />

                <div className="row justify-content-center nowrap">
                  <button
                    type="button"
                    className="btn btn-transparent col-2 me-4"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <BusyButton
                    onClick={
                      this.state.changeEmailModalIsOpen
                        ? (e) => e.preventDefault()
                        : handleSubmit
                    }
                    buttonText={'Save'}
                    busyText={'Saving'}
                    className="col-3"
                    style={{width: '210px', height: '40px'}}
                    float={'float-right'}
                    buttonIcon={'save'}
                    alignText={'text-center'}
                  >
                    Save
                  </BusyButton>
                </div>
              </div>
              <ChangeEmailModal
                isOpen={this.state.changeEmailModalIsOpen}
                onClose={() => {
                  this.setState({changeEmailModalIsOpen: false});
                }}
                onCancel={() => {
                  this.setState({changeEmailModalIsOpen: false});
                }}
              />
            </form>
          );
        }}
      </Form>
    );
  }
}

EducationForm.propTypes = {
  disableEmail: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getCampuses: PropTypes.func.isRequired,
  getSchools: PropTypes.func.isRequired,
  institutionsLoading: PropTypes.bool.isRequired,
  campusesLoading: PropTypes.bool.isRequired,
  schoolsLoading: PropTypes.bool.isRequired,
  educationLevelsLoading: PropTypes.bool.isRequired,
  showOtherInstitutionField: PropTypes.bool.isRequired,
  institutions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  campuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  schools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  majors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  minors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  educationLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  seeker: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  segments: PropTypes.array,
  segmentsLoading: PropTypes.bool,
  getSegments: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isMaSegment: PropTypes.bool,
  employmentTypes: PropTypes.array,
  getEmploymentTypes: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

EducationForm.defaultProps = {
  disableEmail: false,
  isMaSegment: false,
};
const mapStateToProps = (state) => {
  const seeker = state.seekers.seekerForEdit;
  let institutions = [{name: 'Other', id: -1}];
  if (
    state.institutions.institutions &&
    state.institutions.institutions.length > 0
  ) {
    institutions = state.institutions.institutions;
  }
  return {
    employmentTypes: state.employmentTypes.employmentTypes,
    institutions: state.institutions.institutions,
    campuses: state.institutions.campuses,
    schools: state.institutions.schools,
    majors: state.institutions.majors,
    minors: state.institutions.minors,
    educationLevels: state.institutions.educationLevels,
    institutionsLoading: state.institutions.institutionsLoading,
    campusesLoading: state.institutions.campusesLoading,
    schoolsLoading: state.institutions.schoolsLoading,
    educationLevelsLoading: state.institutions.educationLevelsLoading,
    showOtherInstitutionField:
      seeker.institutionId === _.find(institutions, {name: 'Other'}).id,
    seeker: seeker,
    profile: state.profile,
    initialValues: {
      ...seeker,
      majorIds: generateMajorMinorIds(seeker, 'majors'),
      minorIds: generateMajorMinorIds(seeker, 'minors'),
    },
    segments: state.segments.segmentList,
    segmentsLoading: state.segments.segmentsLoading,
    role: state.profile.role,
  };
};

const mapDispatchToProps = {
  ...institutionsActions,
  ...segmentActions,
  ...employmentActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);
