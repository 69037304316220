import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'lodash';
import App from './App';

import '@openfonts/manrope_all';
import './assets/css/FlashMessages.scss';
import './assets/css/fonts.css';
import './assets/css/custom.scss';

import createReduxStore from 'src/store';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';

if (
  !!process.env.REACT_APP_TRACKJS_TOKEN &&
  !!process.env.REACT_APP_TRACKJS_APP
) {
  window._trackJs = {
    token: process.env.REACT_APP_TRACKJS_TOKEN,
    application: process.env.REACT_APP_TRACKJS_APP,
    version: process.env.REACT_APP_VERSION,
    console: {
      display: process.env.REACT_APP_TRACKJS_APP !== 'ascend-network-prd',
    },
    onError: (payload) => {
      if (payload.console) {
        // redact passwords from errors
        _.forEach(payload.console, (x) => {
          let entry = {};
          // message may not be json...
          try {
            entry = JSON.parse(x.message);
            if (_.get(entry, 'req.data.password')) {
              entry.req.data.password = '********';
              x.message = JSON.stringify(entry);
            }
          } catch (err) {
            // no-op
          }
        });
      }
      return true;
    },
  };
  require('trackjs');
}
export const {store, persistor, history} = createReduxStore();

const render = () => {
  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container);
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </Provider>
  );
};

render();
