import React from 'react';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Container from 'reactstrap/esm/Container';
import Row from 'reactstrap/esm/Row';
import Col from 'reactstrap/esm/Col';
import loadingGif from '../../assets/images/loading.gif';
import ascendLogoBluePng from 'src/assets/images/Ascend-Logo-Blue.png';
import threeOfThreeSvg from '../../assets/images/3-of-3.svg';

const isInternational = (seeker) => {
  if (
    seeker.workStatus === 'US Citizen' ||
    seeker.workStatus === 'Permanent Resident' ||
    seeker.workStatus === null // Prefer not to answer
  ) {
    return false;
  }
  return true;
};

class ThanksPage extends React.Component {
  render() {
    var thanksDiv = <div />;

    const calLink = () => {
      return this.props.seeker.autoscheduleLink || false;
    };

    if (this.props.createSeekerLoading === true) {
      thanksDiv = (
        <div className="text-align-center bgcolorw">
          <br />
          <br />
          <br />
          <img src={loadingGif} height="200" width="200" alt="Loading..." />
          <p role="status">Loading...</p>
        </div>
      );
    } else if (isInternational(this.props.seeker)) {
      thanksDiv = (
        <div>
          <br />
          <p className="normal-headline bold" style={{textAlign: 'center'}}>
            Thank you for connecting with Ascend. We are so grateful for your
            time and interest.
          </p>
          <br />
          <p className="secondary-title" style={{textAlign: 'center'}}>
            Unfortunately, our current employer partners do not have any open
            opportunities that support international students. We are hard at
            work to find Indiana employers that do and will be in touch to
            schedule a one-on-one meeting as soon as we secure those
            opportunities.
          </p>
          <p className="secondary-title" style={{textAlign: 'center'}}>
            We hope to be in touch over the coming months!
          </p>
        </div>
      );
    } else if (calLink()) {
      thanksDiv = (
        <div>
          <br />
          <p className="normal-headline bold" style={{textAlign: 'center'}}>
            You’re in! Schedule a meeting.
          </p>
          <br />
          <p className="secondary-title ">
            We’re reviewing your resume now. Step two is to meet your Ascend
            recruiter. They’ll share interview tips, learn about your interests,
            and approve access to your Ascend Network profile. Schedule a
            meeting on campus or over the phone in 60 seconds!
          </p>
          <br />
          <div style={{textAlign: 'center'}}>
            <a className="clickable btn btn-primary" href={calLink()}>
              Click to Schedule a Meeting
            </a>
          </div>
        </div>
      );
    } else {
      thanksDiv = (
        <div className="text-align-center">
          <br />

          <Container>
            <Col className="col-2" />

            <Col>
              <Row className="justify-content-center">
                <img style={{width: '76px'}} alt="" src={ascendLogoBluePng} />
              </Row>
              <Row className="justify-content-center">
                <p
                  className="normal-headline bold pt-2"
                  style={{textAlign: 'center'}}
                >
                  Thank you for signing up!
                </p>
              </Row>
              <Row className="ps-2 justify-content-center">
                <img alt="" src={threeOfThreeSvg} />
              </Row>
            </Col>
            <Col className="col-2" />
          </Container>

          <br />
          <hr />
          <br />
          <p className="secondary-title-med text-align-center">
            We’re reviewing your resume now. We’ll reach out via email, usually
            in 1-2 business days, once we’ve finished. The email will explain
            step two, meeting your recruiter, as well as share any resume
            feedback. <br />
            Keep an eye on your inbox and spam folders.
          </p>
          <br />
          <div style={{textAlign: 'center'}}>
            <a
              className="clickable btn btn-primary"
              href="http://ascendindiana.com"
            >
              Learn more about Ascend
            </a>
          </div>
        </div>
      );
    }

    return (
      <DocumentTitle title="Thanks">
        <div>
          <div className="fullindy">
            <br />

            <div className="container">
              <div className="row h-100">
                <div className="col-md-2" />
                <div className="col-md-8 my-auto">
                  <div className="bd-whitebackground padding-32">
                    <div className="row">
                      <div className="col-md-1" />
                      <div className="col-md-10">{thanksDiv}</div>
                      <div className="col-md-1" />
                    </div>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ThanksPage.propTypes = {
  seeker: PropTypes.object,
  createSeekerLoading: PropTypes.bool.isRequired,
  degreeTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    seeker: state.seekers.seeker,
    createSeekerLoading: state.seekers.createSeekerLoading,
    degreeTypes: state.institutions.degreeTypes,
  };
}

export default connect(mapStateToProps)(ThanksPage);
