import React, {useState} from 'react';
import styles from './Hint.module.scss';
import PropTypes from 'prop-types';
import {CloseSvg} from 'src/assets/svg/svgComponents';
import AddTagButton from '../tags/AddTagButton/AddTagButton';

export default function Hint({hintText, offset = 0, actions = []}) {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div
      className={`${styles.hint}`}
      style={{display: isVisible ? 'flex' : 'none', top: `${offset}px`}}
    >
      <span className={`${styles.content}`}>
        <div className={`${styles.header}`}>QUICK HINT</div>
        <p className={`${styles.text}`}>{hintText}</p>
      </span>
      <span className={`${styles.actions} d-flex flex-wrap`}>
        {actions.map((action) => {
          return (
            <div key={action.id}>
              <AddTagButton
                key={action.name}
                tag={{...action, tagName: action.name}}
                addAction={action.action}
                tagTip={action.definition}
              />
            </div>
          );
        })}
      </span>
      <button
        onClick={() => setIsVisible(false)}
        className={`btn ${styles.close}`}
      >
        <CloseSvg />
      </button>
    </div>
  );
}

Hint.propTypes = {
  hintText: PropTypes.string,
  offset: PropTypes.number,
  actions: PropTypes.array,
};
