import React from 'react';
import PropTypes from 'prop-types';
import styles from './onboarding.module.scss';
import SlidingPane from 'react-sliding-pane';
import {findNextPage} from '../../utils/onBoarding2.0/onBoardingLogic';
import {
  CircleCheckmark,
  CircleFive,
  CircleFour,
  CircleOne,
  CircleThree,
  CircleTwo,
  MenuSvg,
} from 'src/assets/svg/svgComponents';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import * as codesActions from '../../actions/invitationCodes';
import * as seekersActions from '../../actions/SeekerActions';
import * as intakeActions from '../../actions/IntakeCacheActions';
import {onboardingComplete, getProfile} from 'src/actions/profile';
import {updateTermsAccepted} from 'src/actions/users';
import * as canvasInfo from '../../actions/UtilityActions';
import * as employmentActions from 'src/actions/employmentTypes';
import * as roleActions from 'src/actions/roleTypes';
import * as industriesActions from 'src/actions/industries';
import * as regionsActions from 'src/actions/regions';
import * as workStyleActions from 'src/actions/workStyles';
import {getThemeName} from 'src/utils/miscHelper';
import LogoWithName from 'src/components/LogoWithName';
import WorkExperience from './pages/WorkExperience';
import Skills from './pages/Skills';
import DemographicsPage from './pages/DemograpghicsPage';
import OtherDemographicsPage from './pages/OtherDemograpghicsPage';

import GeneralInfo from './pages/GeneralInfo';
import Eligibility from './pages/Eligibility';
import EducationLevel from './pages/EducationLevel';
import WorkStatus from './pages/WorkStatus';
import ResumeUpload from './pages/ResumeUpload';
import TermsOfUse from './pages/TermsOfUse';
import EducationDetails from './pages/EducationDetails';
import WorkStatusOffRamp from './pages/WorkStatusOffRamp';
import EducationOffRamp from './pages/EducationOffRamp';
import LocationPref from './pages/LocationPref';
import EmploymentType from './pages/EmploymentType';
import ExperienceLevel from './pages/ExperienceLevel';
import RoleType from './pages/RoleType';
import IndustryPreference from './pages/IndustryPreference';
import ProfileSummary from './pages/ProfileSummary';
import {
  prepSeekerFields,
  getGraduateDateDiffDays,
  filledIntakeCache,
  createAnalyticsProfile,
} from 'src/utils/onBoarding2.0/onboardingHelpers';
import LoadingOverlay from 'react-loading-overlay';
import CalendlyEmbed from './pages/CalendlyEmbed';
import {makeRequest} from 'src/services/api';
import {
  getInstitutions,
  getEducationLevels,
  getDegreeTypes,
  getMajors,
  getMinors,
} from 'src/actions/institutions';
import {getExperienceLevels} from 'src/actions/experienceLevels';
import {getIndustries} from 'src/actions/industries';
import {getRegions} from 'src/actions/regions';
import {getRoleTypes} from 'src/actions/roleTypes';
import {
  getSkillTree,
  getAllSkillTags,
  getTopSkillTags,
} from 'src/actions/skillTags';

import {isHighContrastMode, toggleHighContrastMode} from 'src/utils/miscHelper';
import {actions} from 'src/reducers/authentication';
import AreYouSureModal from 'src/components/AreYouSureModal';
import {
  Contrast,
  LogOutSvgBlack,
  Onboarding1Svg,
  Onboarding2Svg,
  Onboarding3Svg,
  Onboarding4Svg,
} from 'src/assets/svg/svgComponents';
import {track} from 'src/utils/analytics';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {getFeatureFlag} from 'src/services/featureFlagApi';
import {getPartnerSegment} from '../../actions/SegmentActions';

const ASCEND_SEGMENT = {id: 1, name: 'Ascend'};
const EMPLOY_INDY_SEGMENT = {id: 2, name: 'EmployIndy'};

class OnboardingContainer extends React.Component {
  _isMounted = false;

  state = {
    isOpen: false,
    currentSection: 'generalInfo',
    currentPage: 0,
    prevPage: [],
    validInvitationCode: true,
    loading: true,
    areYouSureModalIsOpen: false,
    contrastMode: false,
  };

  retrievePartnerUrl = () => {
    return localStorage.getItem('partnerURL');
  };

  // If skip calendly is allowed, finish onboarding
  checkForSkipCalendlyFlag = async () => {
    if (this.props.allowCalendlySkip) {
      this.setState({loading: true});
      const onboardedDate = new Date();
      const seeker = {
        ...prepSeekerFields({
          ...this.props.intakeCache,
        }),
        id: this.props.seekerId,
        published: true,
        publishedAt: onboardedDate,
        connectionFormCompletedOn: onboardedDate,
        onboardedOn: onboardedDate,
        reonboardedOn: onboardedDate,
      };

      this.props.onboardingComplete(this.props.intakeCache.avatarUrl);

      await this.props.updateSeekerAction(seeker);

      if (this.props.intakeCache.destinationPage) {
        this.props.history.push(this.props.intakeCache.destinationPage);
      } else {
        this.props.history.push('/candidatehome');
      }
    }
  };

  onNextPage = (newData) => {
    const nextPage = findNextPage({...newData}, this.state.currentPage);
    if (nextPage < 0) {
      const seeker = {
        ...prepSeekerFields({
          ...this.props.intakeCache,
          ...newData,
        }),
        id: this.props.seekerId,
        connectionFormCompletedOn: new Date(),
      };

      this.props.updateSeekerAction(seeker, false);
      return {
        nextSection: 'offRamp',
        nextPage: Math.abs(nextPage) - 1,
      };
    }
    return {offRamped: false};
  };

  isEmployIndy = (intakeCache, props) => {
    const selectedLocations = this.props.itemListCache.regions.filter((x) =>
      props.regionIds.includes(x.id.toString())
    );

    if (selectedLocations.some((region) => region.isEmployIndy)) {
      if (
        intakeCache.educationLevel.isHighSchoolDiploma &&
        getGraduateDateDiffDays(intakeCache.graduationDate) < 60
      ) {
        return true;
      }

      if (
        intakeCache.educationLevel.isCollegeDegree &&
        getGraduateDateDiffDays(intakeCache.graduationDate) < -1095
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  headerImage1 = {
    headerImageSvg: Onboarding1Svg,
    viewBox: '185 50 300 300',
  };
  headerImage2 = {
    headerImageSvg: Onboarding2Svg,
    viewBox: '185 50 300 300',
  };
  headerImage3 = {
    headerImageSvg: Onboarding3Svg,
    viewBox: '185 50 300 300',
  };
  headerImage4 = {
    headerImageSvg: Onboarding4Svg,
    viewBox: '185 50 300 300',
  };

  pages = {
    offRamp: [
      {
        component: WorkStatusOffRamp,
        titleOverride: 'Alternate Resources',
      },
      {
        component: EducationOffRamp,
        titleOverride: 'Alternate Resources',
      },
    ],
    generalInfo: [
      {
        component: GeneralInfo,
        headerImage: this.headerImage1,
        onNextPage: (props) => {
          const seeker = {
            ...prepSeekerFields({
              ...this.props.intakeCache,
              ...props,
            }),
            id: this.props.seekerId,
            connectionFormCompletedOn: null,
          };

          this.props.updateSeekerAction(seeker, false);
        },
      },
      {
        component: Eligibility,
        headerImage: this.headerImage1,
      },
      {
        component: EducationLevel,
        getListOptions: () => this.props.itemListCache.educationLevels,
        onNextPage: this.onNextPage,
        headerImage: this.headerImage1,
      },
      {
        component: WorkStatus,
        onNextPage: this.onNextPage,
        headerImage: this.headerImage1,
      },
      {
        component: ResumeUpload,
        headerImage: this.headerImage1,
      },
      {
        component: TermsOfUse,
        onNextPage: (props) => {
          const seeker = {
            ...prepSeekerFields({
              ...this.props.intakeCache,
              ...props,
            }),
            id: this.props.seekerId,
          };

          makeRequest({
            method: 'PATCH',
            path: `users/${this.props.profile.id}`,
            contentType: 'application/json',
            body: {
              termsAccepted: props.termsOfUse,
              termsAcceptedBy: props.signature,
            },
          }).then(() => {
            this.props.getProfile();
            this.props.updateSeekerAction(seeker, false);
          });

          return {
            cacheModification: {
              termsAccepted: true,
            },
          };
        },
        headerImage: this.headerImage1,
      },
    ],
    yourIdealJob: [
      {
        component: LocationPref,
        getListOptions: () => this.props.itemListCache.regions,
        onNextPage: (props) => {
          if (
            this.props.intakeCache.invitationCode &&
            this.props.intakeCache.segments !== undefined &&
            this.props.intakeCache.segments.length > 0
          ) {
            return this.onNextPage(props);
          }

          const cacheModification = {
            segments: [],
          };

          if (this.isEmployIndy(this.props.intakeCache, props)) {
            cacheModification.segments = [EMPLOY_INDY_SEGMENT];
          } else {
            cacheModification.segments = [ASCEND_SEGMENT];
          }

          return {
            ...this.onNextPage(props),
            cacheModification,
          };
        },
        headerImage: this.headerImage2,
      },
      {
        component: EmploymentType,
        headerImage: this.headerImage2,
      },
      {
        component: ExperienceLevel,
        getListOptions: () => this.props.itemListCache.experienceLevels,
        headerImage: this.headerImage2,
      },
      {
        component: RoleType,
        getListOptions: () => this.props.itemListCache.roleTypes,
        headerImage: this.headerImage2,
      },
      {
        component: IndustryPreference,
        getListOptions: () => this.props.itemListCache.industries,
        headerImage: this.headerImage2,
      },
    ],
    startYourProfile: [
      {
        component: WorkExperience,
        headerImage: this.headerImage3,
      },
      {
        component: EducationDetails,
        getListOptions: () => this.props.itemListCache,
        headerImage: this.headerImage3,
      },
      {
        component: Skills,
        onNextPage: (props) => {
          const seeker = {
            ...prepSeekerFields({
              ...this.props.intakeCache,
              ...props,
            }),
            id: this.props.seekerId,
            createPreliminaryMatches: true,
          };

          this.props.updateSeekerAction(seeker, false);
        },
        getListOptions: () => this.props.itemListCache.topSkillTags,
        headerImage: this.headerImage3,
      },
      {
        component: ProfileSummary,
        headerImage: this.headerImage1,
      },
    ],
    aboutYou: [
      {
        component: DemographicsPage,
        headerImage: this.headerImage1,
      },
      {
        component: OtherDemographicsPage,
        onNextPage: async () => {
          await this.checkForSkipCalendlyFlag();
        },
        headerImage: this.headerImage1,
      },
    ],
    yourRecruiter: [
      {
        component: CalendlyEmbed,
        onNextPage: async () => {
          this.setState({loading: true});
          const onboardedDate = new Date();
          const seeker = {
            ...prepSeekerFields({
              ...this.props.intakeCache,
            }),
            id: this.props.seekerId,
            published: true,
            publishedAt: onboardedDate,
            connectionFormCompletedOn: onboardedDate,
            onboardedOn: onboardedDate,
            reonboardedOn: onboardedDate,
          };

          this.props.onboardingComplete(this.props.intakeCache.avatarUrl);

          await this.props.updateSeekerAction(seeker);

          if (this.props.intakeCache.destinationPage) {
            this.props.history.push(this.props.intakeCache.destinationPage);
          } else {
            this.props.history.push('/candidatehome');
          }
        },
        headerImage: this.headerImage4,
      },
    ],
  };
  menuKeyNames = Object.keys(this.pages).filter((x) => x !== 'offRamp');

  componentDidMount() {
    this._isMounted = true;
    this.setState({contrastMode: isHighContrastMode(this.props.userId)});
    const newCache = {
      ...this.props.intakeCache,
      email: this.props.profile.email,
      universityEmail: this.props.profile.email.endsWith('.edu')
        ? this.props.profile.email
        : null,
    };

    const partnerURL = this.retrievePartnerUrl();
    if (partnerURL) {
      this.props.getPartnerSegment(partnerURL);
    }

    this.props.updateLocalIntake(newCache);

    this.setState({
      isMobile: window.matchMedia('(max-width: 430px)').matches,
    });

    document.documentElement.className = getThemeName(this.props.profile.id);

    this.props.updateCanvasInfo({isMobile: this.state.isMobile});

    this.loadData();

    window.onboarding_autofill = () => {
      makeRequest({
        method: 'PATCH',
        path: `users/${this.props.profile.id}`,
        contentType: 'application/json',
        body: {
          termsAccepted: true,
          termsAcceptedBy: 'autoFill',
        },
      }).then(() => {
        this.props.getProfile();
      });
      this.props.updateLocalIntake(filledIntakeCache);
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.intakeCacheLoading === true) {
      return;
    }
    if (prevProps.intakeCache.cacheInitialized === true) {
      return;
    }
    if (prevProps.intakeCache !== this.props.intakeCache) {
      this.assignEmailToIntakeCache();
      this.assignSegmentToIntakeCache();

      if (this.props.intakeCache.invitationCodeId === undefined) {
        this.loadInvitationCode();
      }

      this.setState({loading: false});
      this.setStartingForm();
    }
  }

  callActionsForApiCache = () => {
    const functionsToCallForCaching = [
      {listPropName: 'institutions', funcToCall: this.props.getInstitutions},
      {
        listPropName: 'educationLevels',
        funcToCall: this.props.getEducationLevels,
      },
      {listPropName: 'majors', funcToCall: this.props.getMajors},
      {listPropName: 'minors', funcToCall: this.props.getMinors},
      {listPropName: 'degreeTypes', funcToCall: this.props.getDegreeTypes},
      {
        listPropName: 'employmentTypes',
        funcToCall: this.props.getEmploymentTypes,
      },
      {
        listPropName: 'experienceLevels',
        funcToCall: this.props.getExperienceLevels,
      },
      {listPropName: 'industries', funcToCall: this.props.getIndustries},
      {listPropName: 'regions', funcToCall: this.props.getRegions},
      {listPropName: 'roleTypes', funcToCall: this.props.getRoleTypes},
      {
        listPropName: 'skillTree',
        funcToCall: () => {
          this.props.getAllSkillTags();
          this.props.getSkillTree();
          this.props.getTopSkillTags(10);
        },
      },
    ];

    if (this.props.itemListCache.institutionsLoading === true) {
      // Assume all APIs have been called if this one is still loading
      return;
    }

    for (const cacheItem of functionsToCallForCaching) {
      if (this.props.itemListCache[cacheItem.listPropName].length > 0) {
        // if we have already called this API, don't call it again
        // Assume all APIs have been called if this one has data
        break;
      }
      cacheItem.funcToCall();
    }
  };

  assignEmailToIntakeCache = () => {
    if (
      this.props.intakeCache.email === undefined &&
      this.props.profile.email !== undefined
    ) {
      const newCache = {
        ...this.props.intakeCache,
        email: this.props.profile.email,
        universityEmail: this.props.profile.email.endsWith('.edu')
          ? this.props.profile.email
          : null,
      };

      this.props.updateLocalIntake(newCache);
    }
  };

  assignSegmentToIntakeCache = () => {
    if (
      this.props.intakeCache.segments === undefined &&
      this.props.partnerSegments !== undefined
    ) {
      const newCache = {
        ...this.props.intakeCache,
        segments: this.props.partnerSegments,
      };
      this.props.updateLocalIntake(newCache);

      if (this.props.seekerId) {
        const seeker = {
          segments: this.props.partnerSegments,
          id: this.props.seekerId,
        };
        this.props.updateSeekerAction(seeker, false);
      }
    }
  };

  trackPage = (args) => {
    const analyticsProfile = createAnalyticsProfile(args.currentIntakeCache);
    const trackedPages = args.currentIntakeCache?.trackedPages || {};
    const trackSection = this.convertToTitle(args.nextSection);
    const trackPage = args.nextPage + 1;

    const trackedPageKeys = Object.keys(trackedPages);
    let shouldSendToMixPanel = false;
    if (!trackedPageKeys.includes(`${args.nextSection}Tracking`)) {
      trackedPages[`${args.nextSection}Tracking`] = [args.nextPage];
      shouldSendToMixPanel = true;
    } else {
      if (
        !trackedPages[`${args.nextSection}Tracking`].includes(args.nextPage)
      ) {
        trackedPages[`${args.nextSection}Tracking`].push(args.nextPage);
        shouldSendToMixPanel = true;
      }
    }
    if (shouldSendToMixPanel) {
      window.analytics.identify(this.props.profile.id, analyticsProfile);
      window.Appcues.identify(this.props.profile.id, analyticsProfile);

      track({
        eventName: `${trackSection} Page ${trackPage}`,
      });
    }

    this.props.updateLocalIntake({trackedPages});

    return trackedPages;
  };

  async loadData() {
    if (!this._isMounted) return;

    this.callActionsForApiCache();

    if (this.props.intakeCacheLoading === false) {
      await this.props.getIntakeCache();

      this.trackPage({
        nextPage: this.state.currentPage,
        nextSection: this.state.currentSection,
        currentIntakeCache: this.props.intakeCache,
      });
    }

    if (!this._isMounted) return;

    if (this.props.seekerLoading === false) {
      this.props.getSeekerAction(this.props.profile.seekerId);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  loadInvitationCode = async () => {
    if (this.props.invitationCodeValidating === true) {
      return;
    }

    const code = this.getParam('code');

    const invitationCodeData = await this.props.validateInvitationCodeAsync(
      code,
      this.retrievePartnerUrl()
    );

    if (!this._isMounted) return;

    const newCache = {
      ...this.props.intakeCache,
      invitationCodeId: invitationCodeData.invitationCodeId,
      invitationCode: invitationCodeData,
    };

    const newSeeker = {
      id: this.props.profile.seekerId,
      invitationCodeId: invitationCodeData.invitationCodeId,
    };

    if (invitationCodeData.segment !== null) {
      newCache.segments = [{...invitationCodeData.segment}];
      newSeeker.segments = [{...invitationCodeData.segment}];
    }

    if (invitationCodeData.destinationPage !== '') {
      const path = invitationCodeData.destinationPage.replace(
        window.location.origin,
        ''
      );
      newCache.destinationPage = path;
    }

    if (this.props.seeker?.connectionFormCompletedOn) {
      return;
    }

    this.props.saveIntakeCache({
      ...newCache,
    });
    this.props.updateLocalIntake(newCache);

    if (this.props.profile.segmentIds.length === 0) {
      await this.props.updateSeekerAction(newSeeker, false);
    }

    getFeatureFlag('allow_resume_skip').then((response) => {
      newCache.isResumeRequired = !response.data.enabled;
      this.props.saveIntakeCache({
        ...newCache,
      });
      this.props.updateLocalIntake(newCache);
    });
  };

  getParam = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

  setStartingForm = () => {
    const {profile, intakeCache, history} = this.props;
    // Sometimes on a page refresh the section is undefined and would show the general info page when seeker has been offRamped
    if (intakeCache.section === undefined) {
      if (profile.intakeCompleted === true && profile.segmentIds.length === 0) {
        this.setState({
          currentPage: 0,
          currentSection: 'offRamp',
        });
        return;
      }
    }
    if (intakeCache.section === 'offRamp') {
      this.setState({
        currentPage: intakeCache.page,
        currentSection: 'offRamp',
      });
      return;
    }

    if (profile.intakeCompleted === true && profile.termsAccepted) {
      history.push('/candidatehome');
      return;
    }

    this.setState({
      currentPage: intakeCache.page || 0,
      currentSection: intakeCache.section || 'generalInfo',
    });
  };

  moveToPage = (props) => {
    this.setState({currentPage: props.page, currentSection: props.section});
  };

  previousPage = (e, values) => {
    let page = this.state.currentPage - 1;
    let section = this.state.currentSection;
    if (section === 'generalInfo' && page === -1) {
      return;
    }

    if (page === -1) {
      let sections = Object.keys(this.pages);
      section = sections[sections.indexOf(section) - 1];
      page = this.pages[section].length - 1;
    }

    const newCache = {...this.props.intakeCache, ...values};

    this.props.updateLocalIntake(newCache);

    this.props.saveIntakeCache({
      ...newCache,
      page: page,
      section: section,
    });

    this.setState({currentPage: page, currentSection: section});
    window.scrollTo(0, 0);
  };

  nextPage = (props) => {
    let newCache = {...this.props.intakeCache, ...props};

    let sections = Object.keys(this.pages);
    const currentPageObj =
      this.pages[this.state.currentSection][this.state.currentPage];
    let nextPage = this.state.currentPage + 1;
    let nextSection = this.state.currentSection;
    let isSectionValidated =
      this.props.intakeCache[`${this.state.currentSection}Menu`] || false;
    const currentSectionIndex = sections.indexOf(this.state.currentSection);
    if (nextPage >= this.pages[this.state.currentSection].length) {
      nextPage = 0;
      if (currentSectionIndex < sections.length - 1) {
        nextSection = sections[currentSectionIndex + 1];
        isSectionValidated = true;
      }
    }

    if (currentPageObj.onNextPage !== undefined) {
      const nextPageObj = currentPageObj.onNextPage(props);
      if (nextPageObj !== undefined) {
        if (nextPageObj.nextPage !== undefined) {
          nextPage = nextPageObj.nextPage;
        }
        if (nextPageObj.nextSection !== undefined) {
          nextSection = nextPageObj.nextSection;
        }
        if (nextPageObj.cacheModification !== undefined) {
          newCache = {
            ...newCache,
            ...nextPageObj.cacheModification,
          };
        }
      }
    }

    this.props.saveIntakeCache({
      ...{
        ...newCache,
        trackedPages: this.trackPage({
          nextPage,
          nextSection,
          currentIntakeCache: newCache,
        }),
      },
      [`${this.state.currentSection}Menu`]: isSectionValidated,
      page: nextPage,
      section: nextSection,
    });

    if (!this._isMounted) return;

    this.setState({
      currentPage: nextPage,
      currentSection: nextSection,
    });
    window.scrollTo(0, 0);
  };

  convertToTitle = (title) => {
    if (title) {
      return title.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }
  };

  navigateToSection = (section) => {
    let sections = Object.keys(this.pages);
    this.setState({
      currentPage: 0,
      currentSection: sections[sections.indexOf(section)],
    });
  };

  generateIcon(item, index) {
    if (
      this.props.intakeCache &&
      this.props.intakeCache[`${item}Menu`] === true
    ) {
      return <CircleCheckmark />;
    }

    switch (index) {
      case 0:
        return <CircleOne />;
      case 1:
        return <CircleTwo />;
      case 2:
        return <CircleThree />;
      case 3:
        return <CircleFour />;
      case 4:
        return <CircleFive />;
      default:
        break;
    }
  }

  onClose = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  onLogoutClick = () => {
    this.props.logoutUser();
  };

  toggleTheme = () => {
    const newThemeName = toggleHighContrastMode(this.props.userId);
    document.documentElement.className = newThemeName;
    this.setState({contrastMode: !this.state.contrastMode});
  };

  logoutComponent = () => {
    const menuPadding = !this.state.isMobile ? '20px' : '0';
    return (
      <>
        <div
          className={styles.logout}
          style={{
            borderTop: '2px solid var(--neutral-gray-5)',
          }}
        >
          <button className="btn btn-menu" onClick={this.toggleTheme}>
            <Contrast alt="" width="24px" />
            <span style={{paddingLeft: menuPadding, fontWeight: '800'}}>
              High Contrast: {this.state.contrastMode ? 'On' : 'Off'}
            </span>
          </button>

          <button
            className="btn btn-menu"
            onClick={() => this.setState({areYouSureModalIsOpen: true})}
          >
            <LogOutSvgBlack alt="" width="24px" />
            <span style={{paddingLeft: menuPadding, fontWeight: '800'}}>
              Log Out
            </span>
          </button>
        </div>
      </>
    );
  };

  titleComponent = () => {
    const currentPage =
      this.pages[this.state?.currentSection][this.state.currentPage];
    if (currentPage.titleOverride !== undefined) {
      return (
        <span style={{fontWeight: 'bold', fontSize: '16px'}}>
          {currentPage.titleOverride}
        </span>
      );
    }
    return (
      <span style={{fontWeight: 'bold', fontSize: '16px'}}>
        {this.convertToTitle(this.state.currentSection)}{' '}
        {this.state.currentPage + 1}/
        {this.pages[this.state.currentSection]?.length}
      </span>
    );
  };

  render() {
    const buildPage = () => {
      if (this.props.intakeCache) {
        const currentPage =
          this.pages[this.state.currentSection][this.state.currentPage];
        let listOptions = [];
        if (currentPage.getListOptions !== undefined) {
          listOptions = currentPage.getListOptions();
        }

        return (
          <currentPage.component
            intakeCache={this.props.intakeCache}
            next={this.nextPage}
            previous={this.previousPage}
            isMobile={this.state.isMobile}
            listOptions={listOptions}
            headerImage={currentPage.headerImage}
            isResumeRequired={this.props.intakeCache.isResumeRequired ?? true}
          />
        );
      }
    };

    return (
      <DocumentTitle
        title={`Sign up - ${this.convertToTitle(this.state.currentSection)} ${
          this.state.currentPage + 1
        }/${this.pages[this.state.currentSection]?.length}`}
      >
        <>
          <AreYouSureModal
            areYouSureText="Are you sure that you want to log out? Not all information may be saved at this point."
            isOpen={this.state.areYouSureModalIsOpen}
            onClose={() => {
              this.setState({areYouSureModalIsOpen: false});
            }}
            onYes={() => {
              this.onLogoutClick();
            }}
          />
          <SlidingPane
            isOpen={this.state.isOpen}
            onRequestClose={(e) => {
              this.setState({isOpen: !this.state.isOpen});
            }}
            from="left"
            className={styles.mobileSidebar}
            closeIcon={
              <button
                type="button"
                className="clickable buttonAsLinkGrayBackground"
                onClick={this.onClose}
                style={{color: '#6f7275', height: '20px'}}
                title="Close Modal"
              >
                <i className="nc-icon-glyph-med remove" />
              </button>
            }
            title={<this.titleComponent />}
          >
            <>
              {this.menuKeyNames.map((item, index) => {
                const previousSectionName = this.menuKeyNames[index - 1];
                const enabled =
                  index === 0
                    ? true
                    : this.props.intakeCache.termsAccepted &&
                      this.props.intakeCache[`${previousSectionName}Menu`];
                return (
                  <button
                    disabled={!enabled}
                    aria-disabled={!enabled}
                    key={index}
                    className={`btn btn-menu ${
                      item === this.state.currentSection
                        ? 'onboarding-menu-active'
                        : ''
                    } ${!enabled && 'btn-menu-disabled'}`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.navigateToSection(item);
                    }}
                  >
                    {this.generateIcon(item, index)}
                    {this.convertToTitle(item)}
                  </button>
                );
              })}
              <this.logoutComponent />
              <div
                className={styles.pageText}
                style={{paddingTop: '8px', paddingLeft: '15px'}}
              >
                Having trouble? Reach out to&nbsp;
                <a
                  style={{textDecoration: 'underline', whiteSpace: 'nowrap'}}
                  href="mailto:support@ascendindiana.com"
                >
                  support@ascendindiana.com
                </a>
                .
              </div>
            </>
          </SlidingPane>
          <LoadingOverlay
            role="status"
            active={this.state.loading}
            spinner={true}
            text="Loading..."
            fadeSpeed={200}
            styles={{
              overlay: (base) => ({
                ...base,
                background: 'rgba(50, 50, 50, 1)',
              }),
            }}
          >
            <div className={styles.onboardingContainer}>
              {!this.state.isMobile && (
                <div className={styles.desktopSidebar}>
                  <div id="desktopOnboardingLogo">
                    <span>
                      <LogoWithName />
                    </span>
                    <h1 className="mb-0" style={{width: '224px'}}>
                      Let's Get You Started
                    </h1>
                  </div>
                  <div id="desktopOnboardingMenuItems" className="ps-4">
                    {this.menuKeyNames.map((item, index) => {
                      const previousSectionName = this.menuKeyNames[index - 1];
                      const enabled =
                        index === 0
                          ? true
                          : this.props.intakeCache.termsAccepted &&
                            this.props.intakeCache[
                              `${previousSectionName}Menu`
                            ];
                      return (
                        <button
                          disabled={!enabled}
                          aria-disabled={!enabled}
                          key={index}
                          className={`btn btn-menu ${
                            item === this.state.currentSection
                              ? 'onboarding-menu-active'
                              : ''
                          } ${!enabled && 'btn-menu-disabled'}`}
                          onClick={(e) => {
                            e.preventDefault();
                            this.navigateToSection(item);
                          }}
                        >
                          {this.generateIcon(item, index)}
                          {this.convertToTitle(item)}
                        </button>
                      );
                    })}
                  </div>

                  <this.logoutComponent />

                  <div
                    className={styles.pageText}
                    style={{paddingTop: '8px', paddingLeft: '35px'}}
                  >
                    Having trouble? Reach out to&nbsp;
                    <a
                      style={{textDecoration: 'underline'}}
                      href="mailto:support@ascendindiana.com"
                    >
                      support@ascendindiana.com
                    </a>
                    .
                  </div>
                </div>
              )}
              <div className={styles.header}>
                {this.state.isMobile && (
                  <MenuSvg
                    role="button"
                    className="clickable"
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                    onKeyDown={(e) => {
                      if (e.key === ' ' || e.key === 'Enter') {
                        this.setState({isOpen: !this.state.isOpen});
                        e.preventDefault();
                      }
                    }}
                  ></MenuSvg>
                )}
                <this.titleComponent />
              </div>

              <div className={styles.main}>
                {this.pages[this.state.currentSection] && buildPage()}
              </div>
            </div>
          </LoadingOverlay>
        </>
      </DocumentTitle>
    );
  }
}

OnboardingContainer.propTypes = {
  updateSeekerAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  values: PropTypes.object,
  saveIntakeCache: PropTypes.func.isRequired,
  getIntakeCache: PropTypes.func.isRequired,
  updateLocalIntake: PropTypes.func.isRequired,
  formCache: PropTypes.object,
  profile: PropTypes.object,
  intakeCache: PropTypes.object,
  intakeCacheLoading: PropTypes.bool.isRequired,
  seekerId: PropTypes.string,
  invitationCode: PropTypes.object.isRequired,
  getSeekerAction: PropTypes.func.isRequired,
  seeker: PropTypes.object,
  history: PropTypes.object.isRequired,
  validateInvitationCodeAsync: PropTypes.func.isRequired,
  invitationCodeValidating: PropTypes.bool,
  errors: PropTypes.object,
  currentSegment: PropTypes.array,
  updateCanvasInfo: PropTypes.func.isRequired,
  seekerLoading: PropTypes.bool.isRequired,
  onboardingComplete: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  updateTermsAccepted: PropTypes.func.isRequired,
  itemListCache: PropTypes.object.isRequired,
  getInstitutions: PropTypes.func.isRequired,
  getEducationLevels: PropTypes.func.isRequired,
  getDegreeTypes: PropTypes.func.isRequired,
  getMajors: PropTypes.func.isRequired,
  getMinors: PropTypes.func.isRequired,
  getEmploymentTypes: PropTypes.func.isRequired,
  getExperienceLevels: PropTypes.func.isRequired,
  getIndustries: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  getSkillTree: PropTypes.func.isRequired,
  getAllSkillTags: PropTypes.func.isRequired,
  getTopSkillTags: PropTypes.func.isRequired,
  userId: PropTypes.number,
  logoutUser: PropTypes.func.isRequired,
  segments: PropTypes.array.isRequired,
  getPartnerSegment: PropTypes.func.isRequired,
  partnerSegments: PropTypes.array,
  allowCalendlySkip: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    seekerId: state.profile.seekerId,
    segments: state.segments.segmentList,
    partnerSegments: state.segments.partnerSegments,
    invitationCode: state.invitationCodes,
    invitationCodeValidating: state.invitationCodes.validating,
    intakeCache: state.intakeCache.intakeData,
    intakeCacheLoading: state.intakeCache.intakeCacheLoading,
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
    itemListCache: {
      ...state.institutions,
      employmentTypes: state.employmentTypes.employmentTypes,
      experienceLevels: state.experienceLevels.experienceLevels,
      industries: state.industries.industries,
      regions: state.regions.regions,
      roleTypes: state.roleTypes.roleTypes,
      skillTree: state.skillTags.skillTree,
      topSkillTags: state.skillTags.topTags,
    },
    userId: state.profile.id,
    allowCalendlySkip: state.intakeCache.allowCalendlySkip,
  };
};
const mapDispatchToProps = {
  ...codesActions,
  ...seekersActions,
  ...intakeActions,
  ...canvasInfo,
  ...employmentActions,
  ...roleActions,
  ...industriesActions,
  ...regionsActions,
  ...workStyleActions,
  onboardingComplete,
  getProfile,
  updateTermsAccepted,
  getExperienceLevels,
  getIndustries,
  getRegions,
  getRoleTypes,
  getSkillTree,
  getInstitutions,
  getEducationLevels,
  getDegreeTypes,
  getMajors,
  getMinors,
  getAllSkillTags,
  getTopSkillTags,
  ...actions,
  getPartnerSegment,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer)
);
