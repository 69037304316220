import React from 'react';
import styles from './TagButton.module.scss';
import PropTypes from 'prop-types';
import {CloseCircleSvg} from '../../../assets/svg/svgComponents';
import {Tooltip as ReactTooltip} from 'react-tooltip';

export default function TagButton({
  tag = {tagName: 'Tag Name'},
  removeAction,
  isMobile,
  showRemoveButton,
  tagTip,
  containerStyle,
}) {
  return (
    <>
      {!isMobile && (
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          closeOnEsc={true}
        />
      )}
      <button
        tabIndex={0}
        className={`${styles.container} mt-2 ${
          isMobile ? styles.mobileWidth : ''
        }`}
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-html={tagTip ? tagTip : null}
        style={containerStyle}
        onClick={() => removeAction(tag)}
        type="button"
        aria-label={`remove ${tag.tagName}`}
      >
        <CloseCircleSvg
          className="ms-2"
          style={{height: '13px', verticalAlign: 'middle'}}
        />
        <span aria-label="tag me-2" className={`tag-onboarding`}>
          {tag.tagName}
        </span>
      </button>
    </>
  );
}

TagButton.propTypes = {
  tag: PropTypes.object,
  removeAction: PropTypes.func,
  isMobile: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  tagTip: PropTypes.string,
  containerStyle: PropTypes.object,
};

TagButton.defaultProps = {
  showRemoveButton: true,
  containerStyle: null,
};
