import React, {useEffect, useState} from 'react';
import styles from './BestMatches.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import mixpanel from 'mixpanel-browser';
import {updateSeekerAction} from 'src/actions/SeekerActions';

export default function BestMatches() {
  const dispatch = useDispatch();
  let seeker = useSelector((state) => state.seekers.seeker);
  let seekerLoading = useSelector((state) => state.seekers.seekerLoading);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (seeker.bestMatchedDismissedAt === null) {
      setShouldShow(true);
    }
  }, [seeker.bestMatchedDismissedAt]);

  const handleClose = () => {
    const updatedSeeker = {
      ...seeker,
      bestMatchedDismissedAt: new Date(),
    };
    if (!updatedSeeker.id) {
      return;
    }
    mixpanel.track('BestMatchesCardDismissed');
    setShouldShow(false);
    dispatch(updateSeekerAction(updatedSeeker, false));
  };

  return (
    <>
      {!seekerLoading && shouldShow && (
        <div className={`pt-4 pe-4 pb-4 ${styles.container}`}>
          <div className="col-md-12 pb-2">
            <div
              className={`container bd-whitebackground pt-1 ps-3 pe-2 pb-1 ${styles.card}`}
            >
              <h3>
                Get the best matches
                <button
                  type="button"
                  className={`clickable buttonAsLinkGrayBackground ${styles.close}`}
                  onClick={handleClose}
                  title="Close"
                >
                  <i className="nc-icon-glyph remove" />
                </button>
              </h3>
              <p>
                We match you to roles based on your preferences and skills. If
                you aren’t satisfied with your matches, try adding more skills,
                or adjusting your preferences. You’ll get new matches in
                minutes.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
