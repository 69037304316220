import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import {Link} from 'react-router-dom';
import {addFlashMessage} from 'src/actions/flash';
import PropTypes from 'prop-types';
import PageControl from 'src/components/PageControl';
import LoadingOverlay from 'react-loading-overlay';
import {
  getRecruiterTasks,
  setTaskExpanded,
  dismissTaskItem,
  executeTaskItem,
  dismissTaskItemsForTaskId,
  incrementNoteCount,
  decrementNoteCount,
  getTaskCount,
} from '../../actions/taskActions';
import {getSeeker} from 'src/actions/seekers';
import * as SVG from '../../assets/svg/svgComponents';
import AscendDataTable from '../../components/AscendDataTable';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import Avatar from 'src/components/Avatar';
import InviteToApplyModalForAscend from 'src/components/InviteToApplyModalForAscend';
import * as svg from 'src/assets/svg/svgComponents';
import NotesModal from 'src/components/NotesModal';
import CreateConnectionModal from 'src/pages/admin/connections/CreateConnectionModal';
import * as seekersActions from '../../actions/seekers';
import AreYouSureModal from 'src/components/AreYouSureModal';

class RequiresAttention extends React.Component {
  DEFAULT_PAGE_SIZE = 20;
  state = {
    taskItemOpenMenuId: null,
    inviteToApplyModalIsOpen: false,
    notesModalIsOpen: false,
    reportConnectionFormOpen: false,
    selectedSeekerForNotes: {
      firstName: '',
      lastName: '',
      avatarUrl: '',
      id: null,
    },
    taskForConnection: null,
    selectedSeekerForConnection: null,
    showConfirmVerifyConnectionDismiss: false,
    taskForDismissal: null,
  };

  componentDidMount() {
    this.props.getRecruiterTasks();
  }

  confirmDismissVerifyConnectionTaskItem = async (taskItem) => {
    this.setState({
      taskForDismissal: taskItem,
      showConfirmVerifyConnectionDismiss: true,
    });
  };

  handlerDismissTaskItem = async (taskItem) => {
    await this.props.dismissTaskItem(
      taskItem.id,
      taskItem.taskId,
      this.props.page
    );

    if (this.state.showConfirmVerifyConnectionDismiss) {
      this.setState({
        showConfirmVerifyConnectionDismiss: false,
        taskForDismissal: null,
      });
    }
  };

  handleDismissMultipleTaskItems = async (task, taskCountToClear) => {
    await this.props.dismissTaskItemsForTaskId(task.id, taskCountToClear);
  };

  handleNotesClick = (taskItem) => {
    this.setState({
      selectedSeekerForNotes: {
        firstName: taskItem.firstName,
        lastName: taskItem.lastName,
        avatarUrl: taskItem.avatarUrl,
        id: taskItem.seekerId,
      },
    });
    this.setState({notesModalIsOpen: true});
    document.body.classList.add('modal-open');
  };

  handleVerifyConnectionClick = (taskItem) => {
    this.setState({
      taskForConnection: taskItem,
      selectedSeekerForConnection: {
        firstName: taskItem.firstName,
        lastName: taskItem.lastName,
        name: taskItem.name,
        id: taskItem.seekerId,
      },
    });

    this.setState({reportConnectionFormOpen: true});
  };

  actions = {
    inviteToApplyModal: {
      displayActionName: 'Invite To Apply Modal',
      displayText: 'Invite these candidates to submit their first application',
      displayDateName: 'Published Profile Date',
      showItemCount: true,
      uiAction: (taskItem) => {
        this.props.getSeeker(taskItem.seekerId);
        this.setState({inviteToApplyModalIsOpen: true});
      },
      dismissHandler: (taskItem) => {
        this.handlerDismissTaskItem(taskItem);
      },
    },
    reviewNewCandidates: {
      displayActionName: 'Review Profile',
      displayText: 'Review your new candidates',
      showItemCount: true,
      displayDateName: null,
      uiAction: (taskItem) => {
        this.props.getSeeker(taskItem.seekerId);
        this.props.history.push(`/candidate/${taskItem.seekerId}`);
      },
      dismissHandler: (taskItem) => {
        this.handlerDismissTaskItem(taskItem);
      },
    },
    reviewApplications: {
      displayActionName: 'Review Applications',
      displayText: 'Check out your new job seeker applications',
      showItemCount: false,
      displayDateName: null,
      uiAction: () => {
        this.props.history.push('/admin/applications?reviewApplications=true');
      },
      dismissHandler: (taskItem) => {
        this.handlerDismissTaskItem(taskItem);
      },
    },
    resendEmail: {
      displayActionName: 'Resend Email',
      displayText: 'These candidates have not published their profile',
      displayDateName: 'Interview Date',
      showItemCount: true,
      uiAction: (taskItem, taskId, page) => {
        this.props.executeTaskItem(taskItem.id, taskId, page);
      },
      dismissHandler: (taskItem) => {
        this.handlerDismissTaskItem(taskItem);
      },
    },
    verifySeekerConnection: {
      displayActionName: 'Verify Connection',
      displayText: 'Verify these candidate reported connections',
      displayDateName: 'Connection Date',
      showItemCount: true,
      uiAction: (taskItem) => {
        this.handleVerifyConnectionClick(taskItem);
      },
      dismissHandler: (taskItem) => {
        this.confirmDismissVerifyConnectionTaskItem(taskItem);
      },
    },
  };

  handleRowAction = (taskItem) => {
    this.actions[taskItem.action].uiAction(
      taskItem,
      taskItem.taskId,
      this.props.page
    );
  };

  taskItemColumns = [
    {
      id: 'name',
      displayName: 'Name',
      headerAlignment: 'text-align-left',

      content: (taskItem) => {
        return (
          <div className="ps-2">
            {!taskItem.seekerId && taskItem.taskDetails}
            {taskItem.seekerId && (
              <>
                <Avatar
                  url={taskItem.avatarUrl}
                  label={taskItem.name}
                  style={{float: 'left', marginRight: '10px'}}
                />
                <Link
                  className="admin-item-link text-nowrap"
                  to={'/candidate/' + taskItem.seekerId}
                >
                  {taskItem.name}
                </Link>
              </>
            )}
          </div>
        );
      },
    },
    {
      id: 'date',
      headerAlignment: 'text-align-center',
      displayName: 'Date',
      content: (taskItem) => {
        if (taskItem.displayDateName === null) return;
        return (
          <>
            {taskItem.displayDateName} <svg.CalendarSvg alt="" />
            {'  '}
            <strong>
              {new Date(taskItem.displayDate).toLocaleDateString()}
            </strong>
          </>
        );
      },
    },
    {
      id: 'action',
      headerAlignment: 'text-align-center',
      displayName: 'Action',
      content: (taskItem) => {
        return (
          <button
            className="buttonAsLinkTaskItem"
            onClick={() => this.handleRowAction(taskItem)}
          >
            {taskItem.displayActionName} &nbsp; <SVG.RightArrowOrangeSvg />
          </button>
        );
      },
    },
    {
      id: 'note',
      headerAlignment: 'text-align-center',
      displayName: 'Note',
      content: (taskItem) => {
        return (
          <>
            {taskItem.seekerId && (
              <button
                className="buttonAsLinkTeamCard clickable-circle"
                style={{paddingLeft: '5px'}}
                onClick={() => {
                  this.handleNotesClick(taskItem);
                }}
              >
                <SVG.MessageDefaultSvg /> &nbsp; {taskItem.noteCount}
              </button>
            )}
          </>
        );
      },
    },

    {
      id: 'seekerSupportFlag',
      headerAlignment: 'text-align-center',
      displayName: 'FLAG',
      content: (taskItem) => {
        return (
          <>
            {taskItem.seekerId && (
              <div
                tabIndex={0}
                onClick={async () => {
                  await this.handleFlagClicked(taskItem);
                }}
                className="d-flex justify-content-center align-items-center text-nowrap"
              >
                <div
                  style={{position: 'relative'}}
                  className="d-flex justify-content-center align-items-center clickable clickable-circle"
                >
                  {taskItem.seekerSupportFlag === true && (
                    <SVG.FlagSolidSvg alt="" />
                  )}
                  {taskItem.seekerSupportFlag === false && (
                    <SVG.FlagSvg alt="" />
                  )}
                </div>
              </div>
            )}
          </>
        );
      },
    },
  ];

  renderExpanded = (task) => {
    if (task === undefined) return null;

    const subRowData = task.taskItems.map((x) => {
      return {
        ...x,
        ...this.actions[task.action],
        action: task.action,
        taskId: task.id,
      };
    });

    return (
      <>
        <table className="table">
          <caption className="visible-hidden">
            This table contains candidates that require your attention
          </caption>
          <tbody key="tableBody">
            {subRowData.map((taskItem, rowIndex) => {
              const highLighted = (row) => {
                if (row?.seekerSupportFlag) {
                  return true;
                }
                return false;
              };
              return (
                <tr
                  key={rowIndex}
                  className={
                    highLighted(taskItem)
                      ? 'rowHighlight rowHoverHighlight'
                      : 'rowHoverHighlight'
                  }
                >
                  {this.taskItemColumns.map((col, colIndex) => {
                    return (
                      <td
                        className="rowbottom"
                        key={colIndex}
                        style={{whiteSpace: 'nowrap', verticalAlign: 'middle'}}
                      >
                        {col.content(taskItem)}
                      </td>
                    );
                  })}
                  <td
                    style={{
                      width: '55px',
                    }}
                    key={`contextKey`}
                    className="rowbottom align-middle"
                  >
                    <Dropdown
                      direction="down"
                      className="float-right"
                      isOpen={this.state.taskItemOpenMenuId === taskItem.id}
                      toggle={() =>
                        this.setState({
                          taskItemOpenMenuId:
                            this.state.taskItemOpenMenuId === null
                              ? taskItem.id
                              : null,
                        })
                      }
                    >
                      <DropdownToggle
                        title=""
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <div className="clickable disabled-caption">
                          <i className="nc-icon-glyph-med dots gly-rotate-90" />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu end={true} title="">
                        <DropdownItem
                          title=""
                          key={`menuIten${rowIndex}Dismiss`}
                          tag="div"
                          className="clickable fullwidth"
                          onClick={() => taskItem.dismissHandler(taskItem)}
                        >
                          Dismiss
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="row padding-bottom-eight">
          <div className="col-md-12">
            <div className="float-right">
              <PageControl
                pageIndex={this.props.page}
                pageCount={Math.ceil(
                  task.totalTaskItemCount / this.DEFAULT_PAGE_SIZE
                )}
                onPageChange={(page) => {
                  this.props.getRecruiterTasks(page, task.id);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  rowMenuItemList = [
    {
      displayName: 'Dismiss All Items',
      action: (task) => this.handleDismissMultipleTaskItems(task, 'all'),
      showForTask: () => true,
    },
    {
      displayName: 'Dismiss Current Page',
      action: (task) =>
        this.handleDismissMultipleTaskItems(task, this.DEFAULT_PAGE_SIZE),
      showForTask: (task) => task.taskItems.length < task.totalTaskItemCount,
    },
  ];

  taskColumns = [
    {
      sortBy: 'taskName',
      displayName: 'Task Details',
      content: (task) => {
        return (
          <>
            <button
              className="buttonAsLinkTasks col-sm-12"
              onClick={() => this.props.setTaskExpanded(task.id)}
            >
              {task.expanded ? <SVG.UpArrowSvg /> : <SVG.DownArrowSvg />}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {'  '}
              <strong>{this.actions[task.action].displayText}</strong>{' '}
              &nbsp;&nbsp;
              {!this.actions[task.action].showItemCount &&
                !task.taskItems[0].seekerId &&
                parseInt(task.taskItems[0].taskDetails, 10)}
              {this.actions[task.action].showItemCount &&
                (task.taskItems.length === task.totalTaskItemCount
                  ? task.totalTaskItemCount
                  : `${task.taskItems.length} of ${
                      task.totalTaskItemCount
                    } (Page ${this.props.page + 1})`)}
            </button>
          </>
        );
      },
    },
  ];

  rowMenuItemListFilter = (menuItem, task) => {
    return menuItem.showForTask(task);
  };

  handleNoteAdded = (note) => {
    this.props.incrementNoteCount(note.seekerId);
  };

  handleNoteRemoved = (note) => {
    this.props.decrementNoteCount(note.seekerId);
  };

  handleFlagClicked = async (seeker) => {
    this.props.toggleSeekerSupportFlag(seeker.seekerId);
  };

  render() {
    return (
      <div className="container">
        <h3>Requires Your Attention</h3>
        <InviteToApplyModalForAscend
          isOpen={this.state.inviteToApplyModalIsOpen}
          seeker={this.props.selectedSeeker}
          onSendClicked={() => {
            this.setState({inviteToApplyModalIsOpen: false});
          }}
          onClose={() => {
            this.setState({inviteToApplyModalIsOpen: false});
          }}
        />
        <LoadingOverlay
          role="status"
          active={this.props.loading}
          spinner={true}
          text="Loading..."
          fadeSpeed={200}
          styles={{
            overlay: (base) => ({
              ...base,
              height: '100px',
              position: 'fixed',
            }),
          }}
        />
        <NotesModal
          isOpen={this.state.notesModalIsOpen}
          onClose={() => {
            this.setState({notesModalIsOpen: false});
            document.body.classList.remove('modal-open');
          }}
          seeker={this.state.selectedSeekerForNotes}
          key={this.state.selectedSeekerForNotes.id}
          onNoteAdded={this.handleNoteAdded}
          onNoteRemoved={this.handleNoteRemoved}
        />

        <CreateConnectionModal
          isOpen={this.state.reportConnectionFormOpen}
          onClose={() => this.setState({reportConnectionFormOpen: false})}
          task={this.state.taskForConnection}
          seeker={this.state.selectedSeekerForConnection}
          dismissTaskItem={this.handlerDismissTaskItem}
        />

        <AscendDataTable
          items={this.props.tasks}
          tableColumns={this.taskColumns}
          rowMenuItemList={this.rowMenuItemList}
          rowMenuItemListFilter={this.rowMenuItemListFilter}
          renderExpanded={this.renderExpanded}
          enableExpand={true}
          enableSort={false}
          showSearch={false}
          showPageControl={false}
          caption="This table contains tasks that require your attention. Each row is expandable and contains a sub-table with line-items for each candidate that requires your attention"
        />

        <AreYouSureModal
          areYouSureText={
            <>
              Are you sure you wish to delete{' '}
              {this.state.taskForDismissal?.firstName}{' '}
              {this.state.taskForDismissal?.lastName}'s unverified connection?
              Once deleted, you will not be able to access their response again.
            </>
          }
          isOpen={this.state.showConfirmVerifyConnectionDismiss}
          onClose={() =>
            this.setState({
              showConfirmVerifyConnectionDismiss: false,
              taskForDismissal: null,
            })
          }
          onYes={() => this.handlerDismissTaskItem(this.state.taskForDismissal)}
          noText="Cancel"
          centerVertically={true}
          size="m"
        />
      </div>
    );
  }
}

RequiresAttention.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  getRecruiterTasks: PropTypes.func.isRequired,
  setTaskExpanded: PropTypes.func.isRequired,
  dismissTaskItem: PropTypes.func.isRequired,
  executeTaskItem: PropTypes.func.isRequired,
  dismissTaskItemsForTaskId: PropTypes.func.isRequired,
  getSeeker: PropTypes.func.isRequired,
  totalTaskItems: PropTypes.number,
  tasks: PropTypes.array.isRequired,
  selectedSeeker: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  incrementNoteCount: PropTypes.func.isRequired,
  decrementNoteCount: PropTypes.func.isRequired,
  toggleSeekerSupportFlag: PropTypes.func.isRequired,
  getTaskCount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    page: state.tasksReducer.page,
    tasks: state.tasksReducer.tasks,
    loading: state.tasksReducer.loading,
    selectedSeeker: state.seekers.seeker,
  };
};

const mapDispatchToProps = {
  addFlashMessage,
  getRecruiterTasks,
  setTaskExpanded,
  dismissTaskItem,
  executeTaskItem,
  dismissTaskItemsForTaskId,
  getSeeker,
  incrementNoteCount,
  decrementNoteCount,
  getTaskCount,
  ...seekersActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequiresAttention)
);
