import React from 'react';
import PropTypes from 'prop-types';
import * as Layout from '../layout';

class ResumeModal extends React.Component {
  render() {
    const iframeFileExtensions = 'pdf';
    const imageExtensions = ['jpg', 'jpeg', 'png'];
    const fileExtension = this.props.downloadFilename?.split('.').pop() || '';
    const viewInIframe = iframeFileExtensions === fileExtension.toLowerCase();
    const showAsImage = imageExtensions.includes(fileExtension.toLowerCase());

    return (
      <Layout.AscendModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="xl"
      >
        <Layout.AscendModalBody hasClose={true}>
          <center>
            <h2>Resume Preview</h2>
            <hr />
            {!viewInIframe && !showAsImage && (
              <p>
                Resume file cannot be previewed, click the link below to
                download this candidate's resume.
                <br />
                <br />
                <a href={this.props.downloadUrl}>
                  {this.props.downloadFilename}
                </a>{' '}
              </p>
            )}
            {showAsImage && (
              <img src={this.props.embedUrl} width="100%" alt="" />
            )}
            {viewInIframe && (
              <iframe
                src={this.props.embedUrl}
                width="100%"
                height="800px"
                title="Resume"
                lang="en"
                tabIndex={0}
                frameBorder="0"
              />
            )}
          </center>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

ResumeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  embedUrl: PropTypes.string,
  downloadUrl: PropTypes.string,
  downloadFilename: PropTypes.string,
};

ResumeModal.defaultProps = {
  isOpen: false,
  downloadFilename: null,
};

export default ResumeModal;
