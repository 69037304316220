import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ViewResumeLink extends Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <button
        className="active-subheader buttonAsLinkGrayBackground orangeHover"
        style={{textDecoration: 'underline'}}
        onClick={this.handleClick}
      >
        View Resume
      </button>
    );
  }
}

ViewResumeLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ViewResumeLink;
