import * as types from './ActionTypes';

export function createJobCard(jobId) {
  return {type: types.JOB_CARD__CREATE, jobId};
}

export function updateJobCardTabIndex(jobId, tabIndex) {
  return {
    type: types.JOB_CARD__UPDATE_TAB_INDEX,
    jobId,
    tabIndex,
  };
}

export function updateJobCardMaximized(jobId, maximized) {
  return {
    type: types.JOB_CARD__UPDATE_MAXIMIZED,
    jobId,
    maximized,
  };
}
