import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';

import {connect} from 'react-redux';
import _ from 'lodash';

import * as industriesActions from 'src/actions/industries';

import * as Layout from 'src/layout';
import {BusyButton} from './BusyButton';
import AscendCheckbox from 'src/formFields/ascendCheckbox/AscendCheckbox';
import CheckboxSelector from './selectors/CheckboxSelector/CheckboxSelector';
import * as seekersActions from 'src/actions/seekers';

class IndustriesModal extends React.Component {
  state = {
    isMobile: false,
  };

  async componentDidMount() {
    await this.props.getIndustries();

    if (this.props.industries.length > 0) {
      this.setState({
        isMobile: window.matchMedia('(max-width: 430px)').matches,
      });
    }
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = ['industryIds'];
    _.forEach(requiredFields, (field) => {
      if (
        (!values[field] || values[field].length === 0) &&
        values.anyIndustryPref === false
      ) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  handleClose = () => {
    this.props.updateSeekerForEdit({
      ...this.props.seekerForEdit,
      industries: this.props.seeker.industries,
    });
    this.props.onClose();
  };

  render() {
    const {isOpen} = this.props;

    return (
      <Layout.AscendModal
        size="lg"
        isOpen={isOpen}
        onClose={this.handleClose}
        backdrop={'static'}
      >
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={this.validate}
            keepDirtyOnReinitialize={true}
            mutators={{
              // expect (field, value) args from the mutator
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={{
              industryIds: this.props.seeker.industries,
              anyIndustryPref: this.props.seeker.industries.some(
                (x) => x.name === 'Any'
              ),
            }}
          >
            {({handleSubmit, form}) => {
              const handleCheck = (checked) => {
                this.setState({noIndustryPref: checked});
                if (checked) {
                  form.mutators.setValue('industryIds', [
                    this.props.industries.find((x) => x.name === 'Any'),
                  ]);
                  this.props.updateSeekerForEdit({
                    ...this.props.seekerForEdit,
                    industries: [],
                  });
                } else {
                  form.mutators.setValue('industryIds', []);
                  this.props.updateSeekerForEdit({
                    ...this.props.seekerForEdit,
                    industries: [],
                  });
                }
              };
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <div className="bold active-headline text-align-center">
                        Tell us about your industry preferences
                      </div>
                      <div className="normal-subheader">
                        Let's match you with the kind of work that you are
                        passionate about. Search for the industries you want to
                        be in or select no preference.
                      </div>
                      <br />
                      <div style={{textAlign: 'left', width: '100%'}}>
                        <Field
                          component={CheckboxSelector}
                          name="industryIds"
                          closeMenuOnSelect={false}
                          isMobile={this.state.isMobile}
                          optionsList={this.props.industries
                            .filter((x) => x.name !== 'Any')
                            .map((x) => {
                              return {
                                id: x.id,
                                value: x.id,
                                label: x.name,
                                tagName: x.name,
                              };
                            })}
                          form={form}
                          onTagChangeAction={(list) => {
                            if (list?.length > 0) {
                              form.mutators.setValue('anyIndustryPref', false);
                            }
                            this.props.updateSeekerForEdit({
                              ...this.props.seekerForEdit,
                              industries: list.map((x) => {
                                return {id: x.id, name: x.tagName};
                              }),
                            });
                          }}
                          selectedTags={this.props.seekerForEdit.industries
                            .filter((x) => x.name !== 'Any')
                            .map((x) => {
                              return {
                                id: x.id,
                                value: x.id,
                                label: x.name,
                                tagName: x.name,
                              };
                            })}
                          placeholder="Ex. Technology, Education etc."
                          isSearchable={!this.state.isMobile}
                        />
                        <div className="pt-2">
                          <Field name="anyIndustryPref">
                            {(props) => {
                              return (
                                <>
                                  <AscendCheckbox
                                    name={props.input.name}
                                    inputValue={props.input.value}
                                    onChangeAction={handleCheck}
                                    onFocus={props.input.onFocus}
                                    meta={props.meta}
                                    label="I have no preference"
                                    inputOnChange={props.input.onChange}
                                    defaultValue={this.state.noPref}
                                  />
                                </>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center nowrap">
                    <button
                      type="button"
                      className="btn btn-transparent col-2 me-4"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <BusyButton
                      onClick={handleSubmit}
                      buttonText={'Save'}
                      busyText={'Saving'}
                      className="col-2"
                      style={{width: '210px', height: '40px'}}
                      float={'float-right'}
                      buttonIcon={'save'}
                      alignText={'text-center'}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

IndustriesModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getIndustries: PropTypes.func.isRequired,
  industries: PropTypes.array,
  seeker: PropTypes.any,
  seekerForEdit: PropTypes.any,
  updateSeekerForEdit: PropTypes.func.isRequired,
};

const actions = {
  ...industriesActions,
  ...seekersActions,
};

let EditIndustriesModal = (props) => <IndustriesModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  return {
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    seeker: state.seekers.seeker,
    seekerForEdit: state.seekers.seekerForEdit,
  };
};

EditIndustriesModal = connect(
  editMapStateToProps,
  actions
)(EditIndustriesModal);

export {EditIndustriesModal};
