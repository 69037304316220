import React from 'react';
import PropTypes from 'prop-types';

import {seekerMinors} from '../../utils/fieldHelper';
import diplomaSvg from 'src/assets/images/diploma.svg';

class CandidateMinorText extends React.Component {
  render() {
    const {seeker} = this.props;
    let text = '\u00A0';
    if (seeker.id) {
      const minors = seekerMinors(seeker);
      if (minors !== '') {
        text = `Minor: ${minors}`;
      }
    }
    return (
      <div
        aria-label="education minor"
        className="candidate-profile-title bold padding-bottom-eight"
      >
        {seeker.id && text !== '\u00A0' && (
          <img className="padding-right-sixteen" src={diplomaSvg} alt="" />
        )}
        {text}
      </div>
    );
  }
}

CandidateMinorText.propTypes = {
  seeker: PropTypes.object,
};

CandidateMinorText.defaultProps = {
  seeker: {},
};

export default CandidateMinorText;
