import * as types from './ActionTypes';
import _ from 'lodash';

const apiUrl = process.env.REACT_APP_API_URL;

export function getApplications(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false,
  recruiterId
) {
  let uri = `${apiUrl}/applications`;

  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }
  if (recruiterId > 0) {
    p.push('ascendRecruiterId=' + recruiterId);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.APPLICATIONS__GET_REQUEST,
      types.APPLICATIONS__GET_SUCCESS,
      types.APPLICATIONS__GET_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getApplicationStats(
  showAllItems = true,
  ascendRecruiterId = null
) {
  let uri = `${apiUrl}/applications/stats`;
  if (!showAllItems) {
    uri +=
      '?showAllItems=false' +
      (ascendRecruiterId === null
        ? ''
        : `&ascendRecruiterId=${ascendRecruiterId}`);
  }

  return {
    types: [
      types.APPLICATIONS__GET_STATS_REQUEST,
      types.APPLICATIONS__GET_STATS_SUCCESS,
      types.APPLICATIONS__GET_STATS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getApplicationReports() {
  let uri = `${apiUrl}/applications/reports`;
  return {
    types: [
      types.APPLICATIONS__GET_REPORTS_REQUEST,
      types.APPLICATIONS__GET_REPORTS_SUCCESS,
      types.APPLICATIONS__GET_REPORTS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function updateApplication(application) {
  return {
    type: types.APPLICATIONS__UPDATE,
    newApplication: application,
  };
}
