import React, {useRef} from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import PageFooter from '../components/PageFooter';
import {RoundAvatarField, TextAreaField} from 'src/formFields';
import PageHeader from '../components/PageHeader';

export default function ProfileSummary({
  next,
  previous,
  intakeCache,
  isMobile,
  headerImage,
}) {
  const formRef = useRef({});

  const readFormValues = () => {
    const val = formRef?.current?.getState().values;

    return val;
  };

  return (
    <>
      <PageHeader
        title="Add a personal touch"
        subTitle="Consider adding a professional profile picture and a personal statement that highlights your experience and abilities that help you stand out from the crowd."
        headerImage={headerImage}
      />
      <div className={styles.pageContent}>
        <Form
          onSubmit={next}
          initialValues={{
            avatarUrl: intakeCache?.avatarUrl,
            summary: intakeCache?.summary,
          }}
        >
          {({handleSubmit, form}) => {
            formRef.current = form;

            return (
              <form onSubmit={handleSubmit}>
                <Field name="avatarUrl" component={RoundAvatarField} />

                <div style={{textAlign: 'left'}}>
                  <Field
                    name="summary"
                    component={TextAreaField}
                    label="Personal Statement (optional)"
                    labelCssClass={'onboarding-form-label'}
                    showCounter={true}
                    maxLength={500}
                    hideBorder={false}
                  />
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      <PageFooter
        next={() => formRef?.current?.submit()}
        previous={previous}
        getValues={readFormValues}
      />
    </>
  );
}

ProfileSummary.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  headerImage: PropTypes.any,
};
