import React, {useEffect, useState} from 'react';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import pluralize from 'pluralize';

import {
  updateCurrentPage,
  updateSearchTerm,
  updateSortTerm,
  updateRecruiter,
} from 'src/actions/connectionsDataTable';
import {
  getConnectionsList,
  getConnectionsReports,
} from 'src/actions/connections';
import {getAscendRecruiters} from 'src/actions/ascendRecruiters';
import {
  decrementSeekersListNoteCount,
  incrementSeekersListNoteCount,
} from 'src/actions/seekers';

import {PageHeader, PageFooter} from 'src/layout';
import AscendDataTable from 'src/components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';
import {
  FlagSolidSvg,
  FlagSvg,
  MessageDefaultSvg,
} from 'src/assets/svg/svgComponents';
import {useDispatch, useSelector} from 'react-redux';
import NotesModal from 'src/components/NotesModal';
import JobProfile from 'src/components/JobProfile';
import CreateConnectionModal from './CreateConnectionModal';

const searchFields = ['name', 'jobName', 'employerName'];

export default function ConnectionsListPage() {
  const dispatch = useDispatch();

  let currentAscendRecruiterId = useSelector(
    (state) => state.profile.ascendRecruiterId
  );
  let connections = useSelector((state) => state.connections.connections);
  let currentPage = useSelector(
    (state) => state.connectionsDataTable.currentPage
  );
  let searchTerm = useSelector(
    (state) => state.connectionsDataTable.searchTerm
  );
  let sortBy = useSelector((state) => state.connectionsDataTable.sortBy);
  let recruiterId = useSelector(
    (state) => state.connectionsDataTable.recruiterId
  );
  let sortAscending = useSelector(
    (state) => state.connectionsDataTable.sortAscending
  );
  let itemsPerPage = useSelector((state) => state.connections.itemsPerPage);
  let totalItems = useSelector((state) => state.connections.totalItems);
  let connectionsLoading = useSelector(
    (state) => state.connections.connectionsLoading
  );
  let reports = useSelector((state) => state.connections.reports);
  let ascendRecruiters = useSelector(
    (state) => state.ascendRecruiters.ascendRecruiters
  );

  const [canViewCandidatePII, setCanViewCandidatePII] = useState(false);
  const [notesSeeker, setNotesSeeker] = useState(null);
  const [notesModalIsOpen, setNotesModalIsOpen] = useState(false);
  const [jobOpen, setJobOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [reportConnectionFormOpen, setReportConnectionFormOpen] =
    useState(false);

  useEffect(() => {
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      setCanViewCandidatePII(resp.data.hasPermission);
    });
  }, []);

  useEffect(() => {
    if (ascendRecruiters.length === 0) {
      dispatch(getAscendRecruiters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reports.length === 0) {
      dispatch(getConnectionsReports());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConnections = () => {
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    recruiterId = recruiterId === -1 ? currentAscendRecruiterId : recruiterId;

    dispatch(
      getConnectionsList(
        currentPage,
        itemsPerPage,
        sortTerm,
        filters,
        recruiterId
      )
    );
  };

  useEffect(() => {
    if (!connectionsLoading) {
      getConnections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, sortBy, sortAscending, recruiterId]);

  useEffect(() => {
    if (!connectionsLoading && searchTerm === '') {
      getConnections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleNotesClicked = (seeker) => {
    setNotesSeeker(seeker);
    setNotesModalIsOpen(true);
  };

  const handlePageChange = (pageNumber) => {
    dispatch(updateCurrentPage(pageNumber));
  };

  const handleFilterChange = (searchTerm, search = true) => {
    dispatch(updateSearchTerm(searchTerm));

    if (searchTerm !== '' && search) {
      getConnections();
    }
  };

  const handleSortChange = (sortBy, sortAscending) => {
    dispatch(updateSortTerm(sortBy, sortAscending));
  };

  const handleConnectionDropdownChange = (option) => {
    dispatch(updateRecruiter(option.id));
  };

  const handleNoteAdded = () => {
    dispatch(incrementSeekersListNoteCount(notesSeeker.id));
  };

  const handleNoteRemoved = () => {
    dispatch(decrementSeekersListNoteCount(notesSeeker.id));
  };

  const handleJobClicked = (job) => {
    setJobOpen(true);
    setSelectedJob(job);
  };

  const buildRecruiterList = () => {
    return ascendRecruiters.map((x) => {
      return {id: x.id, label: x.name};
    });
  };
  const filterList = [
    {id: -2, label: 'All'},
    {id: -1, label: 'Mine'},
    ...buildRecruiterList(),
  ];

  const buildNotesColumn = (connection) => {
    if (Object.keys(connection.seeker).length > 0) {
      return (
        <div
          tabIndex={0}
          onClick={() => {
            handleNotesClicked(connection.seeker);
          }}
          className="d-flex justify-content-center align-items-center text-nowrap"
          aria-label="Candidate Notes"
          role="button"
        >
          <div
            style={{position: 'relative'}}
            className="d-flex justify-content-center align-items-center clickable clickable-circle"
          >
            <MessageDefaultSvg alt="" /> &nbsp; {connection.seeker.noteCount}
          </div>
        </div>
      );
    }
  };

  const buildFlagColumn = (connection) => {
    if (Object.keys(connection.seeker).length > 0) {
      return (
        <div
          className="d-flex justify-content-center align-items-center text-nowrap"
          aria-label="flag"
          aria-pressed={connection.seeker.seekerSupportFlag === true}
        >
          <div
            style={{position: 'relative'}}
            className="d-flex justify-content-center align-items-center"
          >
            {connection.seeker.seekerSupportFlag === true && (
              <FlagSolidSvg alt="" />
            )}
            {connection.seeker.seekerSupportFlag === false && (
              <FlagSvg alt="" />
            )}
          </div>
        </div>
      );
    }
  };

  const highLightRow = (row) => {
    if (row?.seeker.seekerSupportFlag) {
      return true;
    }
    return false;
  };

  const tableColumns = [
    {
      sortBy: 'name',
      displayName: 'JOB SEEKER',
      content: (item) => {
        if (Object.keys(item.seeker).length > 0) {
          return (
            <Link
              className="underlineLink admin-item-link"
              to={`/candidate/${item.seeker.id}`}
            >
              {item.seeker.name}
            </Link>
          );
        } else {
          return item.seekerName;
        }
      },
    },
    {
      sortBy: 'role',
      displayName: 'ROLE',
      content: (item) => {
        if (item.jobName) {
          return item.jobName;
        } else {
          return (
            <div>
              <button
                type="button"
                className="buttonAsLinkRoles"
                onClick={() => handleJobClicked(item.job)}
              >
                {item.job.name}
              </button>
            </div>
          );
        }
      },
    },
    {
      sortBy: 'employerName',
      displayName: 'COMPANY',
      content: (item) => {
        if (item.employerName) {
          return item.employerName;
        } else {
          return (
            <Link
              className="underlineLink admin-item-link"
              to={'/employer/' + item.employer.id}
            >
              {item.employer.name}
            </Link>
          );
        }
      },
    },
    {
      sortBy: 'manager',
      displayName: 'MANAGER',
      content: (item) => item.ascendRecruiter.name,
    },
    {
      sortBy: 'connectionDate',
      displayName: 'CONNECTION DATE',
      content: (item) => {
        let date = new Date(item.connectionDate);
        return date.toLocaleDateString();
      },
    },
    {
      sortBy: 'segment',
      displayName: 'SEGMENT',
      content: (item) => {
        if (item.seekerSegment && item.seekerSegment !== '') {
          return item.seekerSegment.name;
        } else return '';
      },
    },
    {
      sortBy: 'notes',
      displayName: 'NOTES',
      content: buildNotesColumn,
    },
    {
      sortBy: 'seekerSupportFlag',
      displayName: 'FLAG',
      content: buildFlagColumn,
    },
  ];

  let tableControlLabelText = `Showing ${pluralize(
    'connection',
    totalItems,
    true
  )}`;

  const handleReportConnectionClick = () => {
    setReportConnectionFormOpen(true);
  };

  return (
    <DocumentTitle title="Connections Admin">
      <div className="wholepage">
        <PageHeader showAdminLinks={true} title="Administration" />

        <div className="bd-pagebody">
          <main id="main" tabIndex="-1">
            <CreateConnectionModal
              isOpen={reportConnectionFormOpen}
              onClose={() => setReportConnectionFormOpen(false)}
            />

            <div className="container-fluid">
              {notesSeeker && (
                <NotesModal
                  isOpen={notesModalIsOpen}
                  onClose={() => {
                    setNotesModalIsOpen(false);
                  }}
                  seeker={notesSeeker}
                  key={notesSeeker.id}
                  onNoteAdded={handleNoteAdded}
                  onNoteRemoved={handleNoteRemoved}
                />
              )}

              {selectedJob && selectedJob.id && (
                <JobProfile
                  key={selectedJob.id}
                  isOpen={jobOpen}
                  job={selectedJob}
                  onClose={() => setJobOpen(false)}
                />
              )}
              <p />

              <div>
                <button
                  className="btn btn-primary float-right"
                  type="button"
                  onClick={() => handleReportConnectionClick()}
                >
                  + Add Connection
                </button>

                <h2 className="normal-headline bold padding-bottom-twentyfour">
                  Connections
                </h2>

                {!connectionsLoading && !canViewCandidatePII && (
                  <h3>You do not have permission to view Candidate PII</h3>
                )}

                {canViewCandidatePII && (
                  <>
                    <AscendDataTable
                      showSearch={true}
                      tableColumns={tableColumns}
                      items={connections}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      sortBy={sortBy}
                      sortAscending={sortAscending}
                      totalItems={totalItems}
                      handlePageChange={handlePageChange}
                      handleSortChange={handleSortChange}
                      itemsLoading={connectionsLoading}
                      headerCenterText={tableControlLabelText}
                      searchPlaceholder={'Name, Role, or Company'}
                      handleFilterChange={handleFilterChange}
                      searchTerm={searchTerm}
                      pullDownList={filterList}
                      pullDownDefaultValue={filterList[0]}
                      pullDownWidth={230}
                      pullDownOnChange={handleConnectionDropdownChange}
                      isRowHighlighted={highLightRow}
                      filterLabel={'Filter by Manager'}
                    />
                    <ReportsTable
                      title="Connections Reports"
                      reports={reports}
                    />
                  </>
                )}
              </div>
            </div>
          </main>
        </div>

        <PageFooter />
      </div>
    </DocumentTitle>
  );
}
