import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidV4} from 'uuid';
import {connect} from 'react-redux';

import ReactS3Uploader from 'react-s3-uploader';
import * as flashActions from '../actions/flash';
import * as seekersActions from '../actions/SeekerActions';
import * as jobActions from '../actions/jobs';
import {ResumeSlideOutModal} from 'src/components/ResumeSlideOutModal';

const apiUrl = `${process.env.REACT_APP_API_URL}/resumes`;

class MaResumeField extends React.Component {
  state = {
    showResumeModal: false,
    resumeUrlData: {
      fileExtension: '',
      resumeEmbedUrl: '',
      resumePdfUrl: '',
    },
  };

  handlePreprocess = (file, next) => {
    const oneKB = 1024;
    const oneMB = oneKB * 1024;
    const tenMB = 10 * oneMB;

    if (file.size > tenMB) {
      if (this.props.onError) {
        this.props.addFlashMessage('File must be smaller than 10MB');
        this.props.onError();
      }
    } else if (this.props.fileTypes.test(file.name)) {
      this.setState({uploadedFilename: file.name});
      if (this.props.onStart) {
        this.props.onStart();
      }
      next(file);
    }
  };

  // TODO: display error somewhere
  handleError = (error) => {
    this.setState({error});
    if (this.props.onError) {
      this.props.onError(error);
    }
  };

  scrubFilename = (uploadedFilename) => {
    // "fail" file names used for testing file uploads
    // Allow tests of both failing to upload to S3,
    // and parsing data from Sovren
    let uuid = uuidV4();
    if (uploadedFilename === 'fail_upload.pdf') {
      uuid = 'fail_upload';
    }
    if (uploadedFilename === 'fail_parse.pdf') {
      uuid = 'fail_parse';
    }
    this.setState({uuid, uploadedFilename});
    const uploadedExtension = uploadedFilename.split('.').pop();
    return `${uuid}.${uploadedExtension}`;
  };

  handleFinish = () => {
    const {uuid, uploadedFilename} = this.state;
    if (this.props.onFinish) {
      this.props.onFinish({uuid, uploadedFilename});
    }
    this.props.input.onChange({uuid, uploadedFilename});
  };

  clickFileUpload = (e) => {
    if (e.keyCode === undefined) {
      return;
    }

    if (e.keyCode === 32 || e.keyCode === 13) {
      document.getElementById('resumeUpload').click();
    }
  };

  handleClear = () => {
    this.props.input.onChange(null);
    this.setState({
      resumeUrlData: {
        fileExtension: '',
        resumeEmbedUrl: '',
        resumePdfUrl: '',
      },
    });
  };

  handleShowResumeClicked = async () => {
    const resumeUrlResp = await this.props.getResume(this.props.input.value);
    this.setState({
      showResumeModal: true,
      resumeUrlData: resumeUrlResp,
    });
    document.body.classList.add('modal-open');
  };

  render() {
    const {
      meta: {touched, error, warning},
      input: {value},
    } = this.props;

    return (
      <div className={this.props.className}>
        {this.state.resumeUrlData.resumeEmbedUrl !== '' && (
          <ResumeSlideOutModal
            isOpen={this.state.showResumeModal}
            onInviteToApplyClicked={() => {}}
            onAddWatchClicked={() => {}}
            onClose={() => {
              document.body.classList.remove('modal-open');
              this.setState({showResumeModal: false});
            }}
            seekerId={this.props.seeker.id}
            seekerName={`${this.props.seeker.firstName} ${this.props.seeker.lastName}`}
            seekerAvatarUrl={this.props.seeker.avatarUrl}
            seekerResumeEmbedUrl={this.state.resumeUrlData.resumeEmbedUrl}
            seekerResumePdfUrl={this.state.resumeUrlData.resumeEmbedUrl}
            resumeExtension={this.state.resumeUrlData.fileExtension}
            seekerIsWatched={false}
            showSaveSeeker={false}
            showInviteToApply={false}
          />
        )}

        {value.uploadedFilename && (
          <div className="d-flex align-items-center text-align-center justify-content-center">
            <button
              className="btn btn-link disabled-body padding-top-eight padding-bottom-twelve"
              onClick={(e) => {
                e.preventDefault();
                this.handleShowResumeClicked();
              }}
            >
              {value.uploadedFilename}
            </button>

            <button
              type="button"
              className={`clickable buttonAsLinkGrayBackground`}
              onClick={this.handleClear}
              title="remove resume"
            >
              <i className="nc-icon-glyph remove" />
            </button>
          </div>
        )}

        <label
          htmlFor="resumeUpload"
          role="button"
          className={`btn btn-secondary ${
            value.uploadedFilename ? 'disabled' : ''
          }`}
          tabIndex={value.uploadedFilename ? -1 : 0}
          onClick={this.clickFileUpload}
          onKeyDown={this.clickFileUpload}
        >
          Upload Resume
          <ReactS3Uploader
            id="resumeUpload"
            className="visually-hidden"
            style={{display: 'none'}}
            signingUrl={`${apiUrl}/s3_url`}
            signingUrlMethod="POST"
            accept={this.props.fileTypesAccept}
            preprocess={this.handlePreprocess}
            onError={this.handleError}
            onFinish={this.handleFinish}
            uploadRequestHeaders={{}}
            scrubFilename={this.scrubFilename}
          />
        </label>
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>))}
      </div>
    );
  }
}

MaResumeField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  addFlashMessage: PropTypes.func.isRequired,
  onStart: PropTypes.func,
  onError: PropTypes.func,
  onFinish: PropTypes.func,
  fileTypes: PropTypes.any,
  fileTypesAccept: PropTypes.string,
  className: PropTypes.string,
  seeker: PropTypes.object,
  getResume: PropTypes.func.isRequired,
};

MaResumeField.defaultProps = {
  fileTypes: new RegExp('.pdf$|.doc$|.docx$|.jpg$|.jpeg$|.png$|.heic$', 'i'),
  fileTypesAccept:
    'application/pdf,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/heic',
  className: 'd-flex flex-column',
};

const mapStateToProps = (state) => {
  return {seeker: state.seekers.seeker};
};

const actions = {
  ...flashActions,
  ...seekersActions,
  ...jobActions,
};

export default connect(mapStateToProps, actions)(MaResumeField);
