import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import OutlineButton from '../../../components/controls/buttons/OutlineButton';

export default function PageFooter({
  next,
  previous,
  rightButtonText,
  rightButtonDisabled,
  getValues,
  showPreviousButton,
}) {
  return (
    <>
      <footer className={`d-flex ${styles.footer}`}>
        {showPreviousButton && (
          <OutlineButton
            buttonText="Previous"
            className={styles.onboardingNavigationButton}
            onClick={(e) => {
              const val = getValues();
              previous(null, val);
            }}
          />
        )}
        <button
          onClick={next}
          disabled={rightButtonDisabled}
          type="button"
          className={`ms-2 btn btn-primary ${styles.onboardingNavigationButton}`}
        >
          {rightButtonText}
        </button>
      </footer>
    </>
  );
}

PageFooter.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  rightButtonText: PropTypes.string,
  rightButtonDisabled: PropTypes.bool,
  getValues: PropTypes.func.isRequired,
  showPreviousButton: PropTypes.bool,
};
PageFooter.defaultProps = {
  rightButtonDisabled: false,
  rightButtonText: 'Continue',
  showPreviousButton: true,
};
