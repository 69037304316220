import auth0 from 'auth0-js';
import {getUtmKeys, setUtmKeys} from 'src/utils/utmKeys';
import {store} from '../index';
import {types} from 'src/reducers/authentication';

export default class NetworkAuthenticationService {
  constructor(clientId, domain) {
    if (clientId && domain) {
      this.auth0 = new auth0.WebAuth({
        domain: domain,
        clientID: clientId,
        redirectUri: window.location.origin + '/callback',
        responseType: 'token id_token',
        scope: 'openid email user_metadata app_metadata',
      });
      this.clientId = clientId;
      this.domain = domain;
    }

    this.signup = this.signup.bind(this);
    this.login = this.login.bind(this);
    this.mapLogin = this.mapLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.getAuthResult = this.getAuthResult.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  signup(username, password) {
    let loginInfo = {
      email: username,
      password,
      connection: 'Username-Password-Authentication',
    };

    this.auth0.signup(loginInfo, (error) => {
      if (error) {
        store.dispatch({
          type: types.LOGIN_ERROR,
          payload: error.description,
        });
      } else {
        this.auth0.login(loginInfo, function (error) {
          store.dispatch({type: types.LOGIN_ERROR, payload: error.description});
        });
      }
    });
  }

  login(username, password) {
    let loginInfo = {
      username,
      password,
    };

    this.auth0.login(loginInfo, function (error) {
      store.dispatch({type: types.LOGIN_ERROR, payload: error.description});
    });
  }

  mapLogin() {
    this.auth0.authorize();
  }

  logout() {
    // AI-3183 - User Themes for specific users should persist even after logout
    // all other localStorage variables should be cleared.
    const tmpThemeDict = JSON.parse(localStorage.getItem('themeDict'));
    const utmKeys = getUtmKeys();
    localStorage.clear();
    localStorage.setItem('themeDict', JSON.stringify(tmpThemeDict));
    setUtmKeys(utmKeys);
    this.auth0.logout({
      returnTo: window.location.origin,
      clientID: this.clientId,
    });
  }

  changePassword(email) {
    store.dispatch({type: types.EMAIL_CHANGE_PASSWORD_REQUEST});
    this.auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email: email,
      },
      function (error, response) {
        if (error) {
          store.dispatch({
            type: types.EMAIL_CHANGE_PASSWORD_FAILURE,
            payload: error.message,
          });
        } else {
          store.dispatch({type: types.EMAIL_CHANGE_PASSWORD_SUCCESS});
        }
      }
    );
  }

  getAuthResult() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    // Auth0 id_token is set to expire in 36,000 seconds
    // Default the token here to expire in 10 hours, however
    // this will be overriden by the value from /sagas/authentication.js
    // based on configuration of Auth0
    let expiresAt = JSON.stringify(36000 * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  isCallback() {
    if (
      !this.isAuthenticated() &&
      /access_token|id_token|error/.test(window.location.hash)
    ) {
      return true;
    }
    return false;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    let notExpired = new Date().getTime() < expiresAt;
    return notExpired && !!localStorage.getItem('id_token');
  }

  getToken() {
    return localStorage.getItem('id_token');
  }
}
