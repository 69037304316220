import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

import AuthorizedComponent from '../containers/AuthorizedComponent';
import * as pages from '../pages';
import {withRouter} from 'src/utils/commonHooks';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const ConditionalRedirectWrapper = ({component: Component}) => {
  const intakeCompleted = useSelector((state) => state.profile.intakeCompleted);

  if (intakeCompleted) {
    return <Navigate to="/candidatehome" />;
  }

  return <Component />;
};

ConditionalRedirectWrapper.propTypes = {
  component: PropTypes.elementType.isRequired,
};

// In order to restrict routes to certain roles, we wrap those routes'
// components in AuthorizedComponent(roles) where roles is the list
// of allowed roles.
const AllUsers = AuthorizedComponent([
  'ASCEND_RECRUITER',
  'COMPANY_RECRUITER',
  'COMPANY_RECRUITER_ADMIN',
  'JOB_SEEKER',
]);

const Admins = AuthorizedComponent(['ASCEND_RECRUITER']);

const Recruiters = AuthorizedComponent([
  'ASCEND_RECRUITER',
  'COMPANY_RECRUITER',
  'COMPANY_RECRUITER_ADMIN',
]);

const AdminsAndCandidates = AuthorizedComponent([
  'ASCEND_RECRUITER',
  'JOB_SEEKER',
]);

const Candidates = AuthorizedComponent(['JOB_SEEKER']);
//
const CompanyRecruiters = AuthorizedComponent([
  'COMPANY_RECRUITER',
  'COMPANY_RECRUITER_ADMIN',
]);

const withAnalytics =
  (Component, customEvent = null) =>
  (props) => {
    // Pattern lifted from this github issue:
    // https://github.com/zsajjad/react-facebook-pixel/issues/41
    ReactPixel.pageView();
    if (customEvent !== null) {
      ReactPixel.trackCustom(customEvent);
    }
    return <Component {...props} />;
  };

class AscendRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {props, title: document.title};
  }
  componentDidUpdate() {
    if (this.state.title !== document.title) {
      this.setState({title: document.title});
    }
  }
  render() {
    return (
      <>
        <div
          aria-live="assertive"
          aria-atomic="true"
          className="forScreenreader"
        >
          <h1>{this.state.title}, View Loaded</h1>
        </div>
        <Routes>
          <Route path="/login" element={<>{withAnalytics(pages.Login)()}</>} />
          <Route
            path="/forgot_your_password"
            element={<>{withAnalytics(pages.ForgotPassword)()}</>}
          />

          <Route
            path=":partner/login"
            element={<>{withAnalytics(pages.Login)()}</>}
            caseSensitive={false}
          />

          <Route
            path="/home"
            element={<>{withAnalytics(AllUsers(pages.RouteUserPage))()}</>}
          />
          <Route
            exact
            path="/"
            element={<>{withAnalytics(AllUsers(pages.RouteUserPage))()}</>}
          />
          <Route path="/contact" element={<pages.ContactPage />} />
          <Route path="/demo" element={<pages.DemoPage />} />
          <Route path="/sandbox" element={<pages.TestingPage />} />
          <Route
            path="/signup"
            element={<>{withAnalytics(pages.Signup)()}</>}
          />
          <Route
            path=":partner/signup"
            element={<>{withAnalytics(pages.Signup)()}</>}
            caseSensitive={false}
          />
          <Route
            path="/ma/onboard"
            element={<>{withAnalytics(pages.MaOnboardingPage)()}</>}
          />
          <Route
            path="/ma/indy"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/ma/uplands"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/ma/evansville"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/ma/hamilton"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/map/indy"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/map/uplands"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/map/evansville"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />
          <Route
            path="/map/hamilton"
            element={
              <ConditionalRedirectWrapper
                component={() =>
                  withAnalytics(Candidates(pages.ModernApprenticeshipPage))()
                }
              />
            }
          />

          <Route
            path="/jobs/apply/:jobId"
            element={<>{withAnalytics(Candidates(pages.ApplyToJobPage))()}</>}
          />
          <Route
            path="/jobs/applications"
            element={
              <>{withAnalytics(Recruiters(pages.JobApplicationsPage))()}</>
            }
          />
          <Route
            path="/thanks"
            element={<>{withAnalytics(pages.ThanksPage)()}</>}
          />
          <Route
            path="/goodbye"
            element={<>{withAnalytics(pages.GoodbyePage)()}</>}
          />
          <Route
            path="/later"
            element={<>{withAnalytics(pages.LaterPage)()}</>}
          />
          <Route
            path="/noresume"
            element={<>{withAnalytics(pages.NoResumePage)()}</>}
          />
          <Route
            path="/callback"
            element={<>{withAnalytics(pages.CallbackPage)()}</>}
          />
          <Route
            path="/activate"
            element={<>{withAnalytics(pages.ActivatePage)()}</>}
          />
          <Route
            path="/employer/:id"
            element={
              <>{withAnalytics(AllUsers(pages.EmployerProfilePage))()}</>
            }
          />
          <Route
            path="/role/:id"
            element={<>{withAnalytics(AllUsers(pages.JobProfilePage))()}</>}
          />
          <Route
            path="/team/:id"
            element={<>{withAnalytics(AllUsers(pages.TeamProfilePage))()}</>}
          />

          <Route
            exact
            path="/admin/employers"
            element={<>{withAnalytics(Admins(pages.EmployerListPage))()}</>}
          />

          <Route
            path="/admin/applications"
            element={<>{withAnalytics(Admins(pages.ApplicationsListPage))()}</>}
          />

          <Route
            path="/admin/invitations"
            element={<>{withAnalytics(Admins(pages.InvitationsListPage))()}</>}
          />
          <Route
            path="/admin/alljobs"
            element={<>{withAnalytics(Admins(pages.AllJobListPage))()}</>}
          />
          <Route
            path="/admin/teams"
            element={<>{withAnalytics(Admins(pages.TeamListPage))()}</>}
          />
          <Route
            path="/admin/candidates"
            element={<>{withAnalytics(Admins(pages.CandidateListPage))()}</>}
          />
          <Route
            path="/admin/roles"
            element={<>{withAnalytics(Admins(pages.JobListPage))()}</>}
          />
          <Route
            path="/admin/employers/:id/recruiters"
            element={
              <>{withAnalytics(Recruiters(pages.RecruiterListPage))()}</>
            }
          />
          <Route
            path="/admin/employers/:id/reports"
            element={<>{withAnalytics(Admins(pages.EmployerReportsPage))()}</>}
          />
          <Route
            path="/admin/employers/:id/applications"
            element={
              <>{withAnalytics(Admins(pages.CandidateElevationPage))()}</>
            }
          />
          <Route
            path="/admin/codes"
            element={<>{withAnalytics(Admins(pages.CodesPage))()}</>}
          />
          <Route
            path="/admin/universities"
            element={<>{withAnalytics(Admins(pages.UniversitiesPage))()}</>}
          />
          <Route
            path="/admin/employers/:employerId/recruiters/:id/notifications"
            element={
              <>{withAnalytics(AllUsers(pages.NotificationSettingsPage))()}</>
            }
          />
          <Route
            path="/admin/connections"
            element={<>{withAnalytics(Admins(pages.ConnectionsListPage))()}</>}
          />
          <Route
            path="/candidate/:id"
            element={
              <>{withAnalytics(AllUsers(pages.CandidateProfilePage))()}</>
            }
          />
          <Route
            path="/notifications"
            element={
              <>{withAnalytics(AllUsers(pages.NotificationSettingsPage))()}</>
            }
          />
          <Route
            exact
            path="/candidatehome"
            element={
              <>{withAnalytics(Candidates(pages.CandidateHomePage))()}</>
            }
          />
          <Route
            path="/candidatehome/:id"
            element={
              <>
                {withAnalytics(AdminsAndCandidates(pages.CandidateHomePage))()}
              </>
            }
          />
          <Route
            exact
            path="/favorites"
            element={
              <>{withAnalytics(Candidates(pages.CandidateFavoritesPage))()}</>
            }
          />
          <Route
            path="/favorites/:id"
            element={
              <>
                {withAnalytics(
                  AdminsAndCandidates(pages.CandidateFavoritesPage)
                )()}
              </>
            }
          />
          <Route
            path="/companyhome/:id"
            element={<>{withAnalytics(Recruiters(pages.CompanyHomePage))()}</>}
          />
          <Route
            path="/employerhome"
            element={
              <>{withAnalytics(CompanyRecruiters(pages.CompanyHomePage))()}</>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <>{withAnalytics(Candidates(pages.CandidateProfilePage))()}</>
            }
          />
          <Route
            exact
            path="/adminprofile/:id"
            element={<>{withAnalytics(AllUsers(pages.AdminProfilePage))()}</>}
          />
          <Route
            exact
            path="/applications"
            element={
              <>
                {withAnalytics(Candidates(pages.CandidateApplicationsPage))()}
              </>
            }
          />
          <Route
            path="/applications/:id"
            element={
              <>
                {withAnalytics(
                  AdminsAndCandidates(pages.CandidateApplicationsPage)
                )()}
              </>
            }
          />
          <Route
            path="/find_candidates"
            element={
              <>{withAnalytics(Recruiters(pages.CandidateDiscoveryPage))()}</>
            }
          />

          <Route
            path="/saved_candidates/:employerId"
            element={
              <>{withAnalytics(Recruiters(pages.CandidateDiscoveryPage))()}</>
            }
          />

          <Route
            exact
            path="/find_roles"
            element={<>{withAnalytics(Candidates(pages.JobDiscoveryPage))()}</>}
          />
          <Route
            exact
            path="/recruiter_find_roles"
            element={<>{withAnalytics(Admins(pages.JobDiscoveryPage))()}</>}
          />
          <Route
            exact
            path="/find_roles/:id"
            element={
              <>
                {withAnalytics(AdminsAndCandidates(pages.JobDiscoveryPage))()}
              </>
            }
          />

          <Route
            exact
            path="/all_roles/:employerId"
            element={<>{withAnalytics(AllUsers(pages.AllJobsPage))()}</>}
          />
          <Route
            path="/find_employers"
            element={
              <>{withAnalytics(Candidates(pages.EmployerDiscoveryPage))()}</>
            }
          />
          <Route
            path="/intake"
            element={
              <>
                {withAnalytics(
                  Candidates(pages.OnboardingContainer),
                  'conversion'
                )()}
              </>
            }
          />
          <Route
            path="/loginfailed"
            element={<>{withAnalytics(pages.LoginFailed)()}</>}
          />
          <Route
            path="/tasks"
            element={<>{withAnalytics(pages.RequiresAttention)()}</>}
          />
          <Route
            path="/post_a_role"
            element={
              <>{withAnalytics(CompanyRecruiters(pages.CompanyPostJobs))()}</>
            }
          />
          <Route
            path="/application/:id"
            element={<>{withAnalytics(pages.DocumentViewerPage)()}</>}
          />
          <Route
            path="/resume/:id"
            element={<>{withAnalytics(pages.DocumentViewerPage)()}</>}
          />
          <Route
            path="/university_explorer"
            element={
              <>{withAnalytics(Admins(pages.UniversityExplorerPage))()}</>
            }
          />
          <Route
            path="/mapofframp"
            element={<>{withAnalytics(pages.IneligibleUserPage)()}</>}
          />
          <Route
            path="/job_seeker_resources"
            element={
              <>
                {withAnalytics(AdminsAndCandidates(pages.JobSeekerResources))()}
              </>
            }
          />

          <Route
            path="/confirm_email"
            element={<>{withAnalytics(pages.ConfirmEmailPage)()}</>}
          />

          <Route
            path="/report_connection/:seekerId"
            element={<>{withAnalytics(pages.ReportConnection)()}</>}
          />

          <Route
            path="/insights/:id"
            element={
              <>{withAnalytics(Admins(pages.CandidateInsightsPage))()}</>
            }
          />

          <Route element={<>{withAnalytics(pages.NotFoundPage)()}</>} />
        </Routes>
      </>
    );
  }
}

export default withRouter(AscendRoutes);
