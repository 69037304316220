import * as types from './ActionTypes';
import {makeRequest} from '../services/api';

const apiUrl = process.env.REACT_APP_API_URL;

export const updateLocalIntake = (newCache) => (dispatch) => {
  dispatch({
    type: types.INTAKE__UPDATE_LOCAL_DATA,
    newCache,
  });
};

export const getIntakeCache = () => async (dispatch) => {
  try {
    dispatch({
      type: types.INTAKE__GET_REQUEST,
    });

    let response = await makeRequest({method: 'GET', path: 'intake/'});
    if (response.status === 200) {
      dispatch({
        type: types.INTAKE__GET_SUCCESS,
        response: {body: response.data},
      });
      return response.data;
    }
  } catch (err) {
    dispatch({
      type: types.INTAKE__GET_FAILURE,
      payload: err,
    });
  }
};

export const saveIntakeCache = (body) => async (dispatch) => {
  try {
    dispatch({
      type: types.INTAKE__SAVE_REQUEST,
      response: {body: body},
    });
    let response = await makeRequest({method: 'POST', path: 'intake/', body});
    if (response.status === 200) {
      dispatch({
        type: types.INTAKE__SAVE_SUCCESS,
        response: {body: body},
      });
    }
  } catch (err) {
    dispatch({
      type: types.INTAKE__SAVE_FAILURE,
      payload: err,
    });
  }
};

export const setCalendlySkip = (userId, allowSkip) => {
  return {
    types: [
      types.INTAKE__SET_CALENDLY_SKIP_REQUEST,
      types.INTAKE__SET_CALENDLY_SKIP_SUCCESS,
      types.INTAKE__SET_CALENDLY_SKIP_FAILURE,
    ],
    uri: `${apiUrl}/intake/${userId}/`,
    method: 'POST',
    body: {userId: userId, allowCalendlySkip: allowSkip},
    payload: {userId: userId, allowCalendlySkip: allowSkip},
    isAuthenticated: true,
    successMessage: 'Calendly skip set.',
    failureMessage: 'Error setting manager and Calendly skip!',
  };
};
