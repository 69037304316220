import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import Select from 'react-select';
import _ from 'lodash';

const renderField = ({
  input,
  meta: {touched, error, warning},
  options,
  label,
  name,
}) => {
  const selection = _.find(options, {value: input.value});

  const ariaLabel = selection
    ? `option field ${label} with a selected value of ${selection.label}`
    : `option field ${label}`;
  return (
    <>
      <Select
        className="clickable normal-subheader"
        value={_.find(options, {value: input.value})}
        onChange={(opt) => input.onChange(opt ? opt.value : null)}
        options={options}
        isClearable={false}
        aria-label={ariaLabel}
      />
      {touched &&
        ((error && <div className="form-input-error">{error}</div>) ||
          (warning && <div className="form-input-error">{warning}</div>) || (
            <div className="form-input-error">&nbsp;</div>
          ))}
      {!touched && <div className="form-input-error">&nbsp;</div>}
    </>
  );
};

renderField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class SelectFieldArray extends React.Component {
  handleAddField = () => {
    const {fields, maxFields} = this.props;
    if (fields.length === maxFields) return;
    fields.push();
  };

  handleRemoveField = () => {
    this.props.fields.pop();
  };

  render() {
    const {
      fields,
      meta: {error, submitFailed},
      labelMaker,
      options,
      maxFields,
      addButtonDescription,
    } = this.props;
    return (
      <div className="form form-group" style={{width: '100%'}}>
        {fields.map((field, index) => {
          return (
            <Fragment key={index}>
              <span className="onboarding-form-label">{labelMaker(index)}</span>
              <Field
                label={labelMaker(index)}
                name={field}
                component={renderField}
                options={options}
              />
            </Fragment>
          );
        })}
        {error && submitFailed && (
          <div className="form-input-error">{error}</div>
        )}
        {fields.length < maxFields && (
          <button
            type="button"
            className="clickable inlineblock btn-transparent-green"
            onClick={this.handleAddField}
          >
            + {addButtonDescription}
          </button>
        )}
        {fields.length < maxFields && fields.length > 1 && (
          <div className="active-body inlineblock">/</div>
        )}
        {fields.length > 1 && (
          <button
            type="button"
            className="clickable inlineblock btn-transparent-red"
            onClick={this.handleRemoveField}
          >
            - remove
          </button>
        )}
      </div>
    );
  }
}

SelectFieldArray.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  fields: PropTypes.object,
  addDisabled: PropTypes.bool,
  labelMaker: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxFields: PropTypes.number,
  addButtonDescription: PropTypes.string,
};

export default SelectFieldArray;
