import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ErrorSvg, CheckSvg} from '../../assets/svg/svgComponents';

class FlashMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
    };
  }

  manualDismiss() {
    this.setState({isVisible: false});
  }

  render() {
    const {message} = this.props;
    const flashMessageClasses = classNames('flash-message', {
      error: message.messageType === 'error',
      notification: message.messageType === 'notification',
      hide: !this.state.isVisible,
    });

    const flashIcon = () => {
      switch (this.props.message.messageType) {
        case 'error':
          return <ErrorSvg className="errorIcon" alt="" />;
        case 'notification':
          return (
            <CheckSvg
              style={{objectFit: 'contain', width: '24px', height: '24px'}}
              alt=""
            />
          );
        default:
          return (
            <CheckSvg
              style={{objectFit: 'contain', width: '24px', height: '24px'}}
              alt=""
            />
          );
      }
    };

    return (
      <div
        aria-label={`pop up message ${message.text}`}
        className={flashMessageClasses}
      >
        <div className="inlineblock float-left">{flashIcon()}</div>
        <div
          className="inlineblock"
          role="alert"
          style={{width: '80%', textAlign: 'center'}}
        >
          {message.text}
        </div>
        <button
          type="button"
          className="inlineblock float-right clickable buttonAsLinkGrayBackground"
          onClick={this.props.dismiss}
          style={{
            objectFit: 'contain',
            width: '24px',
            height: '24px',
            color: message.messageType === 'error' ? '#fff' : '#373839',
            paddingTop: '3px',
          }}
          title="Close Message"
        >
          <i className="nc-icon-glyph-med remove" />
        </button>
      </div>
    );
  }
}

FlashMessage.propTypes = {
  dismiss: PropTypes.func.isRequired,
  dismissDelay: PropTypes.number,
  message: PropTypes.object.isRequired,
};

export default FlashMessage;
