import React from 'react';
import PropTypes from 'prop-types';

import TagsWidget from '../components/TagsWidget';
import SkillsFieldModal from 'src/components/SkillsFieldModal';

class SkillsModalField extends React.Component {
  state = {
    isOpen: false,
  };

  handleEditClicked = () => {
    this.setState({isOpen: true});
  };

  handleModalClosed = () => {
    this.setState({isOpen: false});
  };

  render() {
    const {
      input: {value, onChange, name},
      meta: {touched, error, warning},
      title,
    } = this.props;

    const selectedSkills = value || [];
    return (
      <div>
        <SkillsFieldModal
          isOpen={this.state.isOpen}
          onClose={this.handleModalClosed}
          onChange={onChange}
          onSubmit={this.handleModalClosed}
          name={name}
          skillIdsToExclude={this.props.skillIdsToExclude}
        />
        <TagsWidget
          onEdit={this.handleEditClicked}
          tags={selectedSkills
            .map((tag) => ({
              tagName: tag.name,
              definition: tag.definition ?? null,
            }))
            .sort((x) => x.name)}
          title={title}
          tagClass="skilltag"
          readOnly={false}
        />
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    );
  }
}

SkillsModalField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  title: PropTypes.string,
  skillIdsToExclude: PropTypes.array,
};

SkillsModalField.defaultProps = {
  title: 'SKILLS & EXPERTISE',
  requiredSkillCount: 5,
};

export default SkillsModalField;
