import React from 'react';
import PropTypes from 'prop-types';
import switchOn from '../assets/images/switch-on.svg';
import switchOff from '../assets/images/switch-off.svg';

class OnOffSwitch extends React.Component {
  checked = false;
  getValue = (value) => {
    if (value !== '') {
      this.checked = value;
    } else {
      this.checked = false;
    }
  };
  render() {
    const {
      input: {value, onChange},
      selectedValue,
      unSelectedValue,
      meta: {touched, error, warning},
    } = this.props;

    this.getValue(value);

    return (
      <div>
        {this.checked && (
          <div>
            <img
              aria-label={`toggle button with value of ${selectedValue}`}
              tabIndex={0}
              role={'button'}
              className="clickable"
              onClick={() => onChange(!value)}
              aria-pressed={true}
              alt=""
              src={switchOn}
              onKeyUp={(e) => {
                if (e.keyCode === 32) {
                  onChange(!value);
                }
              }}
            />

            <div className="normal-subheader inlineblock">
              {selectedValue && <>&nbsp;&nbsp;{selectedValue}</>}
            </div>
          </div>
        )}
        {!this.checked && (
          <div>
            <img
              aria-label={`toggle button with value of ${unSelectedValue}`}
              tabIndex={0}
              role={'button'}
              className="clickable"
              onClick={() => onChange(!value)}
              aria-pressed={false}
              alt=""
              src={switchOff}
              onKeyUp={(e) => {
                if (e.keyCode === 32) {
                  onChange(!value);
                }
              }}
            />
            <div className="accent-3-subheader inlineblock">
              {unSelectedValue && <>&nbsp;&nbsp;{unSelectedValue}</>}
            </div>
          </div>
        )}
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    );
  }
}

OnOffSwitch.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  selectedValue: PropTypes.string,
  unSelectedValue: PropTypes.string,
};
OnOffSwitch.defaultProps = {
  selectedValue: 'Status: Published',
  unSelectedValue: 'Status: Draft',
};

export default OnOffSwitch;
