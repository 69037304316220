export const optionsYesNo = [
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
];

export const optionsYesNoNone = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'},
  {value: 'NULL', label: 'Prefer not to answer'},
];

export const educationOptions = [
  {label: 'Below high school diploma'},
  {label: 'High school diploma or equivalent'},
  {label: 'Currently pursuing associate’s degree'},
  {label: 'Associate’s degree'},
  {label: 'Currently pursuing bachelor’s degree'},
  {label: 'Bachelor’s degree'},
  {label: 'Master’s degree'},
  {label: 'Doctorate degree'},
  {label: 'Prefer not to answer'},
];

export const majorLabel = (index) => {
  switch (index) {
    case 0:
      return 'Major';
    case 1:
      return 'Second Major';
    case 2:
      return 'Third Major';
    default:
      return 'Major';
  }
};

export const minorLabel = (index) => {
  switch (index) {
    case 0:
      return 'Minor (optional)';
    case 1:
      return 'Second Minor (optional)';
    case 2:
      return 'Third Minor (optional)';
    default:
      return 'Minor (optional)';
  }
};

export const marionZips = [
  46107, 46183, 46201, 46202, 46203, 46204, 46205, 46206, 46207, 46208, 46214,
  46214, 46216, 46217, 46217, 46218, 46219, 46220, 46221, 46222, 46224, 46224,
  46225, 46226, 46226, 46227, 46227, 46228, 46229, 46229, 46230, 46231, 46231,
  46234, 46234, 46235, 46235, 46236, 46236, 46237, 46237, 46239, 46239, 46240,
  46240, 46241, 46241, 46242, 46244, 46247, 46247, 46250, 46250, 46251, 46253,
  46253, 46254, 46254, 46256, 46256, 46259, 46259, 46260, 46260, 46268, 46268,
  46278, 46278, 46282,
];

export const seekerFields = {
  campusld: '',
  connectionFormCompletedOn: '',
  degreeTypeld: '',
  email: '',
  ethnicity: '',
  experience: '',
  firstGenStudent: '',
  firstName: '',
  gender: '',
  gpa: '',
  graduationMonth: '',
  graduationYear: '',
  hometownld: '',
  institutionld: '',
  invitationCode: '',
  lastName: '',
  location: '',
  majorlds: '',
  minorlds: '',
  pellGrant: '',
  phone: '',
  referralSourceld: '',
  resume: '',
  schoolld: '',
  segments: '',
  sponsorStatus: '',
  twentyFirstCenturyScholar: '',
  workStatus: '',
  workStatusAllowed: '',
  veteran: '',
  invitationCodeId: '',
};
