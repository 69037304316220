import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function getWorkStyles() {
  return {
    types: [
      types.WORK_STYLES__GET_REQUEST,
      types.WORK_STYLES__GET_SUCCESS,
      types.WORK_STYLES__GET_FAILURE,
    ],
    uri: `${apiUrl}/work_styles`,
    method: 'GET',
    isAuthenticated: false,
  };
}
