import React from 'react';
import PropTypes from 'prop-types';

import Avatar from './Avatar';
import noavatar from '../assets/images/noavatar.svg';

class Spotlight extends React.Component {
  render() {
    const renderSpot = () => {
      if (this.props.avatarUrl) {
        return (
          <div>
            <div className="normal-subheader bold padding-bottom-sixteen">
              <h2 style={{fontSize: '20px'}}>{this.props.headline}</h2>
            </div>
            <div className="bd-whitebackground">
              <div className="row">
                <div className="col-md-3">
                  <div className="padding-16">
                    <Avatar
                      style={{
                        backgroundColor: '#ebebeb',
                        borderRadius: '50%',
                      }}
                      height={89}
                      width={89}
                      url={this.props.avatarUrl}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="padding-16">
                    <h3
                      style={{fontSize: '16px'}}
                      className="normal-subheader bold mb-0"
                    >
                      {this.props.name}
                    </h3>
                    <div className="disabled-caption padding-bottom-sixteen">
                      {this.props.title}
                    </div>
                    <div className="normal-subheader">{this.props.info}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="normal-subheader bold padding-bottom-sixteen">
              <h3>{this.props.headline}</h3>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="white-box">
                      <h3
                        style={{fontSize: '16px'}}
                        className="normal-subheader bold mb-0"
                      >
                        {this.props.name}
                      </h3>
                      <div className="disabled-caption">{this.props.title}</div>
                      <br />
                      <div className="normal-subheader">{this.props.info}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    };

    return <div>{renderSpot()}</div>;
  }
}

Spotlight.propTypes = {
  headline: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

Spotlight.defaultProps = {
  avatarUrl: noavatar,
};

export default Spotlight;
