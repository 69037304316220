import React, {useState} from 'react';
import PropTypes from 'prop-types';
import WatchIcon from './WatchIcon';
import {formatDate} from '../utils/miscHelper';
import styles from './SimpleJobCard.module.scss';
import {Link} from 'react-router-dom';
import {MoreVertical16PxSvg} from 'src/assets/svg/svgComponents';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {useDispatch} from 'react-redux';
import AreYouSureModal from './AreYouSureModal';
import {optOutOfJob} from 'src/actions/seekers';
import {track} from 'src/utils/analytics';
import MatchCircle from './MatchCircle';

export default function RecommendedRoleCard({
  employer,
  employerId,
  invitedDate,
  isApprenticeProgram,
  job,
  matchData,
  name,
  onJobClick,
  onWatchChanged,
  seekerId,
  showWatchIcon,
  viewerType,
  watched,
  showMenu,
}) {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleWatchClicked = () => {
    onWatchChanged(!watched);
  };

  const renderMatchWidget = () => {
    if (matchData.matchLevels || matchData.roleRecommendationPercentage) {
      if (viewerType === 'CANDIDATE' || viewerType === 'RECRUITER') {
        return (
          <div className="match-chart-host">
            <MatchCircle
              viewerType={viewerType}
              matchData={matchData}
              isApprenticeProgram={isApprenticeProgram}
              isCompact
              isVertical
            />
          </div>
        );
      }
    } else {
      return <div />;
    }
  };

  const renderInvited = () => {
    if (invitedDate) {
      return (
        <div>
          <div className="inlineblock disabled-small" tabIndex={0}>
            {`Invited on ${formatDate(invitedDate)}`}
          </div>
        </div>
      );
    } else {
      return <div style={{height: '24px'}} />;
    }
  };

  return (
    <>
      <AreYouSureModal
        areYouSureText={`Are you sure you want to hide ${name} from your role recommendations?`}
        isOpen={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
        }}
        onYes={async () => {
          track({
            eventName: `RoleHidden`,
            jobId: job.id,
            jobName: job.name,
            jobEmploymentType: job.employmentTypes.map((x) => x.name).join(','),
            employerId: employerId,
            employerName: employer,
            pageName: 'Candidate Home Page',
          });
          dispatch(optOutOfJob(job.id, seekerId));
          setConfirmModalOpen(false);
        }}
        noText="Cancel"
        centerVertically={true}
        size={null}
      />

      <div
        style={{minHeight: '126px'}}
        className={`container bd-whitebackground pt-1 pl-3 pr-2 pb-1 d-flex`}
      >
        {renderMatchWidget()}
        <div style={{width: '100%'}}>
          <div className="row" style={{alignItems: 'center'}}>
            <Link
              className={`${
                showWatchIcon ? 'col-9' : 'col-11'
              } accessibleLink clickable secondary-caption long-and-truncated underline`}
              to={`/employer/${employerId}`}
            >
              {employer}
            </Link>
            {showWatchIcon && (
              <WatchIcon
                watched={watched}
                onClick={handleWatchClicked}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleWatchClicked();
                  }
                  return;
                }}
                floatRight={true}
                className={
                  showMenu
                    ? `${styles.watchIcon} col-auto p-0`
                    : `${styles.watchIcon} col-auto pt-2 pr-2`
                }
              />
            )}

            {showMenu && (
              <Dropdown
                isOpen={dropdownOpen}
                direction="down"
                toggle={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
                className="col-auto p-0"
              >
                <DropdownToggle
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    marginBottom: '2px',
                  }}
                  className="p-1"
                >
                  <MoreVertical16PxSvg
                    width="20"
                    height="20"
                    strokeWidth="0.5"
                  />
                </DropdownToggle>
                <DropdownMenu end={true} className="dropdown-menu-fit-content">
                  <DropdownItem
                    onClick={() => setConfirmModalOpen(true)}
                    tag="div"
                    className="clickable clickable-dropdown"
                  >
                    Hide this role
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>

          <div className="row" style={{height: '45px'}}>
            <div
              role="link"
              tabIndex={0}
              className={`accessibleLink clickable`}
              style={{width: '100%'}}
              onClick={onJobClick}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  onJobClick();
                }
                return;
              }}
            >
              <h3 className="accessibleLink two-line-truncated normal-subheader mb-0 bold mt-1 pr-1 col-10">
                {name}
              </h3>
            </div>
          </div>

          <div className={`${styles.bottomRow} row`}>
            <div className="col-8">{renderInvited()}</div>
          </div>
        </div>
      </div>
    </>
  );
}

RecommendedRoleCard.propTypes = {
  employer: PropTypes.string.isRequired,
  employerId: PropTypes.number,
  invitedDate: PropTypes.instanceOf(Date),
  isApprenticeProgram: PropTypes.bool,
  job: PropTypes.object,
  matchData: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onJobClick: PropTypes.func.isRequired,
  onWatchChanged: PropTypes.func,
  seekerId: PropTypes.string,
  showWatchIcon: PropTypes.bool,
  viewerType: PropTypes.string,
  watched: PropTypes.bool,
  showMenu: PropTypes.bool,
};

RecommendedRoleCard.defaultProps = {
  isApprenticeProgram: false,
  showWatchIcon: false,
  viewerType: 'CANDIDATE',
  showMenu: true,
};
