import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../utils/commonHooks';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import ChangeSocialPasswordModal from 'src/components/profile/ChangeSocialPasswordModal';
import ChangePasswordModal from 'src/components/profile/ChangePasswordModal';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import {actions as authActions} from 'src/reducers/authentication';
import {
  getThemeName,
  isHighContrastMode,
  toggleHighContrastMode,
} from 'src/utils/miscHelper';

import Avatar from 'src/components/Avatar';
import userSvg from '../assets/images/User.svg';
import {
  HeartDefaultSvg,
  NotificationBellSvg,
  MultiUserSvg,
  Contrast,
} from '../assets/svg/svgComponents';
import editGraySvg from '../assets/images/EditGray.svg';
import jobSvg from '../assets/images/Job.svg';
import logoutSvg from '../assets/images/Logout.svg';
class MyAccount extends React.Component {
  state = {
    dropdownOpen: false,
    employmentTypesModalIsOpen: false,
    changeEmailModalIsOpen: false,
    isMobile: window.matchMedia('(max-width: 430px)').matches,
  };

  onLoginClick = () => {
    this.props.loginUser();
  };

  onLogoutClick = () => {
    this.props.logoutUser();
  };

  onProfileClick = () => {
    if (this.props.role === 'JOB_SEEKER') {
      this.props.history.push('/profile');
    } else if (
      this.props.role === 'COMPANY_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN'
    ) {
      this.props.history.push('/employerhome');
    }
  };

  onAdminProfileClick = () => {
    this.props.history.push(
      `/adminprofile/${this.props.profile.ascendRecruiterId}`
    );
  };

  onFavoritesClick = () => {
    this.props.history.push('/favorites');
  };

  onApplicationsClick = () => {
    this.props.history.push('/applications');
  };

  onNotificationsClick = () => {
    this.props.history.push('/notifications');
  };
  toggleDropdown = (e) => {
    setTimeout(() => {
      this.setState((prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
      }));
    }, 30);
  };
  onChangeEmailClick = () => {
    this.setState({changeEmailModalIsOpen: true});
  };

  onChangePassword = () => {
    const user = this.props.profile;

    if (user.isSocial) {
      this.setState({changeSocialPasswordModalIsOpen: true});
    } else {
      this.setState({changePasswordModalIsOpen: true});
    }
  };

  onPasswordSubmit = (e) => {
    this.setState({changePasswordModalIsOpen: false});
  };

  onManageUsersClick = () => {
    this.props.history.push(
      `/admin/employers/${this.props.employerId}/recruiters`
    );
  };

  handleContrastChanged = async () => {
    this.setState({loading: true});
    localStorage.removeItem('self_republish');

    this.setState({loading: false});
  };

  toggleTheme = () => {
    const newThemeName = toggleHighContrastMode(this.props.userId);
    document.documentElement.className = newThemeName;
  };

  componentDidMount() {
    document.documentElement.className = getThemeName(this.props.userId);
  }

  render() {
    const renderHomeLink = () => {
      if (this.props.role === 'JOB_SEEKER') {
        return (
          <DropdownItem
            tag="div"
            onClick={this.onProfileClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.onProfileClick();
              }
            }}
          >
            <div className="clickable secondary-body fullwidth">
              <img src={userSvg} alt="" />
              &nbsp;&nbsp;Profile
            </div>
          </DropdownItem>
        );
      } else if (this.props.role === 'ASCEND_RECRUITER') {
        return (
          <DropdownItem
            tag="div"
            onClick={this.onAdminProfileClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.onAdminProfileClick();
              }
            }}
          >
            <div className="clickable secondary-body fullwidth">
              <img src={userSvg} alt="" />
              &nbsp;&nbsp;Profile
            </div>
          </DropdownItem>
        );
      } else if (
        this.props.role === 'COMPANY_RECRUITER' ||
        this.props.role === 'COMPANY_RECRUITER_ADMIN'
      ) {
        return (
          <DropdownItem
            tag="div"
            onClick={this.onProfileClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.onProfileClick();
              }
            }}
          >
            <div className="clickable secondary-body fullwidth">
              <img src={userSvg} alt="" />
              &nbsp;&nbsp;Home
            </div>
          </DropdownItem>
        );
      }
    };

    const renderFavoritesLink = () => {
      if (this.props.role === 'JOB_SEEKER') {
        return (
          <DropdownItem
            tag="div"
            onClick={this.onFavoritesClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.onFavoritesClick();
              }
            }}
          >
            <div className="clickable secondary-body fullwidth ps-1">
              <HeartDefaultSvg className="svg_color_fill_gray_heart svg_color_stroke_gray_heart" />
              &nbsp;&nbsp;&nbsp;&nbsp;Favorites
            </div>
          </DropdownItem>
        );
      } else if (
        this.props.role === 'COMPANY_RECRUITER' ||
        this.props.role === 'COMPANY_RECRUITER_ADMIN'
      ) {
        return <div />;
      }
    };

    const renderDivider = () => {
      if (this.props.role === 'JOB_SEEKER') {
        return <DropdownItem divider />;
      } else if (
        this.props.role === 'COMPANY_RECRUITER' ||
        this.props.role === 'COMPANY_RECRUITER_ADMIN'
      ) {
        return <div />;
      }
    };

    const renderNotifications = () => {
      return (
        <DropdownItem
          tag="div"
          onClick={this.onNotificationsClick}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              this.onNotificationsClick();
            }
          }}
        >
          <div className="clickable secondary-body fullwidth">
            <NotificationBellSvg width="24px" height="24px" />
            &nbsp;&nbsp;Notifications
          </div>
        </DropdownItem>
      );
    };

    const renderChangePassword = () => {
      return (
        <DropdownItem
          tag="div"
          onClick={this.onChangePassword}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              this.onChangePassword();
            }
          }}
        >
          <div className="clickable secondary-body fullwidth">
            <img src={editGraySvg} alt="" />
            &nbsp;&nbsp;Change Password
          </div>
        </DropdownItem>
      );
    };

    const renderChangeEmail = () => {
      return (
        <DropdownItem
          tag="div"
          onClick={this.onChangeEmailClick}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              this.onChangeEmailClick();
            }
          }}
        >
          <div className="clickable secondary-body fullwidth">
            <img src={editGraySvg} alt="" />
            &nbsp;&nbsp;Change Email
          </div>
        </DropdownItem>
      );
    };

    const renderManageUsers = () => {
      if (
        this.props.role === 'COMPANY_RECRUITER' ||
        this.props.role === 'COMPANY_RECRUITER_ADMIN'
      ) {
        return (
          <DropdownItem
            tag="div"
            onClick={this.onManageUsersClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.onChangeEmailClick();
              }
            }}
          >
            <div className="clickable secondary-body fullwidth">
              <MultiUserSvg width="24px" height="24px" />
              &nbsp;&nbsp;&nbsp;&nbsp;Manage Users
            </div>
          </DropdownItem>
        );
      } else {
        return <div />;
      }
    };

    const renderHighContrastMode = () => {
      return (
        <DropdownItem
          tag="div"
          onClick={this.toggleTheme}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              this.toggleTheme();
            }
            return;
          }}
        >
          <div className="clickable secondary-body fullwidth">
            <Contrast
              alt=""
              width="24px"
              height="24px"
              fill="rgba(0, 78, 125, 0.5)"
            />
            &nbsp;&nbsp;High Contrast Mode:{' '}
            {isHighContrastMode(this.props.userId) ? 'On' : 'Off'}
          </div>
        </DropdownItem>
      );
    };

    const renderLogOut = () => {
      return (
        <DropdownItem
          tag="div"
          onClick={this.onLogoutClick}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              this.onLogoutClick();
            }
          }}
        >
          <div className="clickable secondary-body fullwidth">
            <img src={logoutSvg} alt="" />
            &nbsp;&nbsp;Log Out
          </div>
        </DropdownItem>
      );
    };

    const renderEmptyDivider = () => {
      return <DropdownItem divider />;
    };

    const renderApplicationsLink = () => {
      if (this.props.role === 'JOB_SEEKER') {
        return (
          <DropdownItem
            tag="div"
            onClick={this.onApplicationsClick}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                this.onApplicationsClick();
              }
            }}
          >
            <div className="clickable secondary-body fullwidth">
              <img src={jobSvg} alt="" />
              &nbsp;&nbsp;Applications
            </div>
          </DropdownItem>
        );
      } else if (
        this.props.role === 'COMPANY_RECRUITER' ||
        this.props.role === 'COMPANY_RECRUITER_ADMIN'
      ) {
        return <div />;
      }
    };

    if (this.props.isAuthenticated) {
      return (
        <>
          <Dropdown
            isOpen={this.state.dropdownOpen}
            direction="down"
            toggle={this.toggleDropdown}
          >
            <DropdownToggle
              style={{backgroundColor: 'transparent', border: 'none'}}
            >
              <div
                className="clickable"
                style={{outline: 0}}
                data-toggle="dropdown"
              >
                <Avatar
                  height={this.state.isMobile ? 38 : 48}
                  width={this.state.isMobile ? 38 : 48}
                  url={this.props.avatarUrl}
                  label="profileMenu"
                />
              </div>
            </DropdownToggle>
            <DropdownMenu end={true} className="dropdown-menu-fit-content">
              {renderHomeLink()}
              {renderFavoritesLink()}
              {renderApplicationsLink()}
              {renderDivider()}
              {(this.props.role === 'JOB_SEEKER' ||
                this.props.role === 'COMPANY_RECRUITER' ||
                this.props.role === 'COMPANY_RECRUITER_ADMIN') &&
                renderNotifications()}
              {renderChangeEmail()}
              {renderChangePassword()}
              {renderHighContrastMode()}
              {renderEmptyDivider()}
              {renderManageUsers()}
              {renderLogOut()}
            </DropdownMenu>
          </Dropdown>
          <div>
            <ChangeEmailModal
              isOpen={this.state.changeEmailModalIsOpen}
              onClose={(e) => {
                this.setState({changeEmailModalIsOpen: false});
              }}
              onCancel={(e) => {
                this.setState({changeEmailModalIsOpen: false});
              }}
            />

            <ChangeSocialPasswordModal
              isOpen={this.state.changeSocialPasswordModalIsOpen}
              onClose={(e) => {
                this.setState({changeSocialPasswordModalIsOpen: false});
              }}
            />

            <ChangePasswordModal
              isOpen={this.state.changePasswordModalIsOpen}
              onClose={(e) => {
                this.setState({changePasswordModalIsOpen: false});
              }}
            />
          </div>
        </>
      );
    } else {
      return <div></div>;
    }
  }
}

MyAccount.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string,
  role: PropTypes.string,
  userId: PropTypes.number.isRequired,
  employerId: PropTypes.number,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    avatarUrl: state.profile.avatarUrl,
    role: state.profile.role,
    userId: state.profile.id,
    employerId: state.recruiters.recruiter.employerId,
    profile: state.profile,
  };
};

const mapDispatchToProps = {
  ...authActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyAccount)
);
