import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Layout from '../layout';
import {useDispatch, useSelector} from 'react-redux';
import styles from './SaveSearchModal.module.scss';
import {createSavedSearch} from 'src/actions/recruiters';

export default function SaveSearchModal({isOpen, onClose, onSubmit}) {
  const [name, setName] = useState('');

  const dispatch = useDispatch();

  let {selectedFieldValues, selectedJob, searchTerm} = useSelector((state) => {
    return {
      selectedFieldValues: state.candidateDiscoveryReducer.selectedFieldValues,
      selectedJob: state.candidateDiscoveryReducer.jobObj,
      searchTerm: state.candidateDiscoveryReducer.searchTerm,
    };
  });

  let tagsToShow = selectedFieldValues.map((x) => {
    return {
      tagName: x.substring(x.indexOf('#') + 1),
      categoryName: x
        .substring(0, x.indexOf('-'))
        .split(/(?=[A-Z])/)
        .filter((x) => x !== 'Ids')
        .join(' '),
      name: x,
    };
  });

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  let tags = groupBy(tagsToShow, 'categoryName');

  const createSearchObject = () => {
    const search = {
      name: name,
      searchObject: {
        selectedFieldValues: selectedFieldValues,
        selectedJob: selectedJob,
        searchTerm: searchTerm,
      },
    };

    if (search.searchObject.selectedJob === null) {
      delete search.searchObject.selectedJob;
    }
    if (search.searchObject.selectedFieldValues.length === 0) {
      delete search.searchObject.selectedFieldValues;
    }
    return search;
  };

  const handleSubmit = () => {
    const searchObject = createSearchObject();

    dispatch(createSavedSearch(searchObject));
    setName('');
    onSubmit();
  };

  return (
    <Layout.AscendModal
      centerVertically
      dialogClassName={styles.medium_large_modal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Layout.AscendModalHeader
        headerClass="bd-modalheader"
        hasClose={false}
        isOpen={isOpen}
      >
        <center className="padding-bottom-eight bold normal-display-white">
          Confirm Saved Search Details
        </center>
      </Layout.AscendModalHeader>
      <Layout.AscendModalBody hasClose={false}>
        <div className="row padding-bottom-twentyfour">
          <div className="col-12 normal-subheader bold padding-bottom-eight">
            Create a Name for this Saved Search
          </div>
          <div className="col-12">
            <input
              className={`bd-whitebackground form-control normal-subheader`}
              type="text"
              placeholder="Add name of saved search here"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="row padding-bottom-twentyfour">
          {selectedJob && (
            <div className="col-12 normal-header padding-bottom-twentyfour">
              <span className="bold">Role: </span>
              {selectedJob.label}
            </div>
          )}

          <div className="col-12 normal-subheader">
            {Object.keys(tags).map((x) => {
              return (
                <div
                  key={x}
                  className="d-flex flex-wrap padding-bottom-twentyfour"
                >
                  <span className="bold" style={{textTransform: 'capitalize'}}>
                    {x}:&nbsp;
                  </span>
                  {tags[x].map((y, i) => {
                    return (
                      <span key={y.name}>
                        {y.tagName}
                        {tags[x].length - 1 !== i ? <>,&nbsp;</> : ''}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row" style={{justifyContent: 'space-evenly'}}>
          <button
            className="clickable btn btn-secondary col-3 padding-right-eight"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="clickable btn btn-primary col-3"
            onClick={() => handleSubmit()}
            disabled={name === ''}
          >
            Save This Search
          </button>
        </div>
      </Layout.AscendModalBody>
    </Layout.AscendModal>
  );
}

SaveSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SaveSearchModal.defaultProps = {
  isOpen: false,
};
