import _ from 'lodash';
import merge from 'deepmerge';
import debounceAction from 'debounce-action';
import PropTypes from 'prop-types';

import * as types from './ActionTypes';
import {buildRemoteWorkList} from '../pages/jobDiscovery/roleUtil';

const apiUrl = process.env.REACT_APP_API_URL;

const allSelectedState = {
  allRolesSelected: true,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
  apprenticePathwayIds: [],
};

// Create API filters from the new UI state.
const filtersFromState = (state) => {
  const filters = {
    overallFit: state.overallFit,
    employmentTypeIds: [],
    experienceLevelIds: [],
    regionIds: [],
    roleTypeIds: [],
    industryIds: [],
    degreeTypeRankIds: [],
    segmentIds: [],
    remoteStatus: {},
  };

  if (state.jobsSearchTerm) {
    filters.searchTerm = state.jobsSearchTerm.trim();
    filters.searchFields = ['name', 'company', 'description'];
  }

  _.forEach(state.startDateTags, (tagState, tag) => {
    if (tagState.selected === true) {
      filters.roleStartFlexibility = tag;
    }
  });

  filters.employmentTypeIds = state.employmentTypeIds;
  filters.experienceLevelIds = state.experienceLevelIds;
  filters.regionIds = state.regionIds;
  filters.roleTypeIds = state.roleTypeIds;
  filters.industryIds = state.industryIds;
  filters.degreeTypeRankIds = state.degreeTypeRankIds;
  filters.segmentIds = state.segmentIds;
  filters.remoteStatus = buildRemoteWorkList(state.remoteStatus);

  // remove any filters set to false or that have no value.
  _.forEach(filters, (value, key) => {
    if (!value || value.length === 0) {
      delete filters[key];
    }
  });
  return filters;
};

export function updateJobAndTeamSearchState(newState, clearWorkStyles = false) {
  return {
    type: types.RECRUITER_JOB_AND_TEAM_SEARCH__UPDATE_STATE,
    newState,
    clearWorkStyles,
  };
}

export function getFilteredJobs(
  page = 0,
  itemsPerPage = 10,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }
  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.RECRUITER_JOB_SEARCH__GET_JOBS_REQUEST,
      types.RECRUITER_JOB_SEARCH__GET_JOBS_SUCCESS,
      types.RECRUITER_JOB_SEARCH__GET_JOBS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getFilteredTeams(
  page = 0,
  itemsPerPage = 10,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/teams`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.RECRUITER_TEAM_SEARCH__GET_TEAMS_REQUEST,
      types.RECRUITER_TEAM_SEARCH__GET_TEAMS_SUCCESS,
      types.RECRUITER_TEAM_SEARCH__GET_TEAMS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getRecruiterJobs(newState = {}) {
  const overwriteMerge = (destinationArray, sourceArray, options) =>
    sourceArray;

  return (dispatch, getState) => {
    const finalState = merge(getState().recruiterJobAndTeamSearch, newState, {
      arrayMerge: overwriteMerge,
    });

    const filters = filtersFromState(finalState);
    dispatch(
      getFilteredJobs(
        finalState.jobsCurrentPage,
        finalState.jobsCurrentItemsPerPage,
        finalState.jobsSortTerm,
        filters
      )
    );
  };
}

const getRecruiterJobsDebounced = debounceAction(getRecruiterJobs, 1000);

export function changeJobPageAndSearch(page) {
  return (dispatch) => {
    const newState = {jobsCurrentPage: page};
    dispatch(updateJobAndTeamSearchState(newState));
    dispatch(getRecruiterJobs(newState));
  };
}

export function changeTeamPageAndSearch(page) {
  return (dispatch) => {
    const newState = {teamsCurrentPage: page};
    dispatch(updateJobAndTeamSearchState(newState));
  };
}

export function changeJobSortAndSearch(jobsSortTerm, jobSortBy, jobSortAsc) {
  return (dispatch) => {
    const newState = {jobsSortTerm, jobSortBy, jobSortAsc, jobsCurrentPage: 0};
    dispatch(updateJobAndTeamSearchState(newState));
    dispatch(getRecruiterJobs(newState));
  };
}

export function changeJobSearchTermAndSearch(jobsSearchTerm) {
  return (dispatch) => {
    const newState = {jobsSearchTerm, jobsCurrentPage: 0};
    dispatch(updateJobAndTeamSearchState(newState));
    dispatch(getRecruiterJobs(newState));
  };
}

export function changeTeamSortAndSearch(sortTerm) {
  return (dispatch) => {
    const newState = {sortTerm, teamsCurrentPage: 0};
    dispatch(updateJobAndTeamSearchState(newState));
  };
}

export function changeFilterTagAndSearch(group, name, selected) {
  return (dispatch, getState) => {
    // by default, set group.name.selected = selected
    let newState = {
      jobsCurrentPage: 0,
      [group]: {
        [name]: {
          selected,
        },
      },
    };

    if (
      [
        'roleTypeIds',
        'industryIds',
        'degreeTypeRankIds',
        'segmentIds',
        'employmentTypeIds',
        'experienceLevelIds',
        'regionIds',
      ].includes(group)
    ) {
      let lookupIds = getState().recruiterJobAndTeamSearch[group];
      const selectedId = parseInt(name, 10);
      if (lookupIds.indexOf(selectedId) === -1) {
        lookupIds.push(selectedId);
      }
      if (!selected) {
        lookupIds = lookupIds.filter((i) => i !== selectedId);
      }
      newState[group] = lookupIds;
    }
    if (group === 'startDateTags') {
      if (selected) {
        switch (name) {
          case 'immediate':
            newState[group].flexible = {selected: false};
            newState[group].specific = {selected: false};
            break;
          case 'flexible':
            newState[group].immediate = {selected: false};
            newState[group].specific = {selected: false};
            break;
          case 'specific':
            newState[group].immediate = {selected: false};
            newState[group].flexible = {selected: false};
            break;
          default:
            return;
        }
      }
    }

    dispatch(updateJobAndTeamSearchState(newState));
    // If one of the resultTypeTags was selected, only reload the resultType
    // that was selected (if Jobs shown, don't make an API call to get Teams)
    // If a resultTypeTag is deselected, don't reload any.
    if (group === 'resultTypeTags') {
      if (name === 'showJobs' && selected) {
        dispatch(getRecruiterJobsDebounced(newState));
      }
    } else {
      const {showJobs} = getState().recruiterJobAndTeamSearch.resultTypeTags;
      if (showJobs.selected) {
        dispatch(getRecruiterJobsDebounced(newState));
      }
    }
  };
}

export function clearFilterGroupAndSearch(group) {
  return (dispatch, getState) => {
    const newState = {
      [group]: {
        ..._.cloneDeep(getState().recruiterJobAndTeamSearch[group]),
      },
    };

    if (group === 'startDateTags') {
      newState[group].immediate.selected = false;
      newState[group].flexible.selected = false;
      newState[group].specific.selected = false;
    } else {
      newState[group] = [];
    }

    dispatch(updateJobAndTeamSearchState(newState));
    const {showJobs} = getState().recruiterJobAndTeamSearch.resultTypeTags;
    if (showJobs.selected) {
      dispatch(getRecruiterJobs(newState));
    }
  };
}

export function selectAllAndSearch() {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(allSelectedState),
    };
    dispatch(updateJobAndTeamSearchState(newState, true));
    dispatch(getRecruiterJobs(newState));
  };
}

export const propTypes = {
  updateJobAndTeamSearchState: PropTypes.func.isRequired,
  getRecruiterJobs: PropTypes.func.isRequired,
  changeFilterTagAndSearch: PropTypes.func.isRequired,
  changeJobPageAndSearch: PropTypes.func.isRequired,
  changeJobSortAndSearch: PropTypes.func.isRequired,
  changeJobSearchTermAndSearch: PropTypes.func.isRequired,
  changeTeamPageAndSearch: PropTypes.func.isRequired,
  changeTeamSortAndSearch: PropTypes.func.isRequired,
  clearFilterGroupAndSearch: PropTypes.func.isRequired,
  selectAllAndSearch: PropTypes.func.isRequired,
  recruiterJobAndTeamSearchProps: PropTypes.object.isRequired,
};
