import React from 'react';
import ContentLoader from 'react-content-loader';

const CandidateHomeLoaderMobile = (props) => (
  <ContentLoader
    speed={3}
    viewBox="0 0 550 1500"
    backgroundColor="#f1f4f9"
    foregroundColor="#cbe1ff"
    style={{
      backgroundColor: '#ffffff',
    }}
    {...props}
  >
    <rect x="88" y="5" rx="0" ry="0" width="198" height="24" />
    <rect x="105" y="37" rx="0" ry="0" width="384" height="79" />
    <rect x="300" y="83" rx="0" ry="0" width="0" height="1" />
    <rect x="106" y="127" rx="0" ry="0" width="384" height="79" />
    <rect x="88" y="758" rx="0" ry="0" width="198" height="24" />
    <rect x="87" y="488" rx="0" ry="0" width="198" height="24" />
    <rect x="88" y="214" rx="0" ry="0" width="198" height="24" />
    <rect x="88" y="245" rx="0" ry="0" width="416" height="80" />
    <rect x="88" y="332" rx="0" ry="0" width="416" height="80" />
    <rect x="88" y="520" rx="0" ry="0" width="416" height="80" />
    <rect x="88" y="607" rx="0" ry="0" width="416" height="80" />
    <circle cx="295" cy="816" r="27" />
    <rect x="207" y="860" rx="0" ry="0" width="167" height="20" />
    <rect x="94" y="925" rx="0" ry="0" width="397" height="60" />
    <rect x="95" y="1001" rx="0" ry="0" width="141" height="20" />
    <rect x="96" y="1033" rx="0" ry="0" width="393" height="140" />
    <rect x="95" y="1189" rx="0" ry="0" width="393" height="140" />
    <rect x="103" y="1341" rx="0" ry="0" width="376" height="100" />
  </ContentLoader>
);

export default CandidateHomeLoaderMobile;
