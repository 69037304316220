import * as types from '../actions/ActionTypes';

const initialState = {
  degreeRank: [],
  degreeRankLoading: false,
};

export default function degreeTypeRank(state = initialState, action) {
  switch (action.type) {
    case types.DEGREE_RANK__GET_REQUEST:
      return {...state, degreeRankLoading: true};
    case types.DEGREE_RANK__GET_SUCCESS:
      return {
        ...state,
        degreeRank: action.response.body.ranks,
        degreeRankLoading: false,
      };
    case types.DEGREE_RANK_UNITS__GET_FAILURE:
      return {...state, degreeRankLoading: false};

    default:
      return state;
  }
}
