import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import pluralize from 'pluralize';

import * as invitationsActions from 'src/actions/invitations';
import * as invitationsDataTableActions from 'src/actions/invitationsDataTable';
import * as tableActions from 'src/actions/invitationsDataTable';

import {PageHeader, PageFooter} from 'src/layout';
import AscendDataTable from 'src/components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import {formatDate} from 'src/utils/miscHelper';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';

const searchFields = ['name', 'jobName', 'employerName', 'manager'];
const searchPlaceholder = 'Name, Role, Company, Manager';

class InvitationsListPage extends React.Component {
  _isMounted = false;
  state = {
    currentItemsPerPage: 25,
    invitationSourceFilterDropdownLabel: 'All Invitations',
    canViewCandidatePII: false,
  };

  getInvitations = (
    currentPage,
    itemsPerPage,
    searchTerm,
    sortBy,
    sortAscending
  ) => {
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    const invitationSource = this.state.invitationSourceFilterDropdownLabel;
    switch (invitationSource) {
      case 'Employer Invitations':
        filters.onlyEmployers = true;
        break;
      case 'Recruiter Invitations':
        filters.onlyAscend = true;
        break;
      default:
        break;
    }

    this.props.getInvitations(
      currentPage,
      itemsPerPage,
      sortBy ? sortTerm : '',
      filters,
      true
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) return;
      this.setState({
        canViewCandidatePII: resp.data.hasPermission,
      });
    });
    this.getInvitations(
      this.props.currentPage,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending
    );
    this.props.getInvitationReports();
  }

  handlePageChange = (pageNumber) => {
    this.props.updateCurrentPage(pageNumber);
    this.getInvitations(
      pageNumber,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    this.props.updateSearchTerm(searchTerm);

    if (search) {
      this.getInvitations(
        0,
        this.props.itemsPerPage,
        searchTerm,
        this.props.sortBy,
        this.props.sortAscending
      );
    }
  };

  handleInvitationSourceDropdownChange = (term) => {
    this.setState(
      {
        invitationSourceFilterDropdownLabel: term.label,
      },
      () => {
        this.getInvitations(
          0,
          this.props.itemsPerPage,
          this.props.searchTerm,
          this.props.sortBy,
          this.props.sortAscending,
          term.label
        );
      }
    );
  };

  handleSortChange = (sortBy, sortAscending) => {
    this.props.updateSortTerm(sortBy, sortAscending);
    this.getInvitations(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      sortBy,
      sortAscending
    );
  };

  render() {
    const {totalItems, appliedCount} = this.props;

    const tableColumns = [
      {
        displayName: 'NAME',
        content: (item) => (
          <Link className="admin-item-link" to={`/candidate/${item.seeker.id}`}>
            {item.seeker.name}
          </Link>
        ),
      },
      {
        displayName: 'MANAGER',
        content: (item) =>
          item.seeker.ascendRecruiter
            ? item.seeker.ascendRecruiter.name
            : 'n/a',
      },
      {
        displayName: 'INVITED ON',
        content: (item) => formatDate(item.invitedAt),
        sortBy: 'invitedOn',
      },
      {
        displayName: 'ROLE',
        content: (item) => item.job.name,
      },
      {
        displayName: 'EMPLOYER',
        content: (item) => item.job.employerName,
        sortBy: 'employerName',
      },
      {
        displayName: 'APPLIED?',
        content: (item) => (item.applied ? 'YES' : 'NO'),
        sortBy: 'applied',
      },
    ];

    let tableControlLabelText = `Showing ${pluralize(
      'invitation',
      totalItems,
      true
    )}`;
    tableControlLabelText += ` (${pluralize(
      'application',
      appliedCount,
      true
    )})`;

    const filterList = [
      {id: 1, label: 'All Invitations'},
      {id: 2, label: 'Employer Invitations'},
      {id: 3, label: 'Recruiter Invitations'},
    ];

    return (
      <DocumentTitle title="Invitations Admin">
        <div className="wholepage">
          <PageHeader showAdminLinks={true} title="Administration" />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <p />
                <h2 className="normal-headline bold">Role Invitations</h2>

                {!this.props.invitationsLoading &&
                  !this.state.canViewCandidatePII && (
                    <h3>You do not have permission to view Candidate PII</h3>
                  )}
                {this.state.canViewCandidatePII && (
                  <>
                    <AscendDataTable
                      showSearch={true}
                      tableColumns={tableColumns}
                      items={this.props.invitations}
                      currentPage={this.props.currentPage}
                      itemsPerPage={this.props.itemsPerPage}
                      sortBy={this.props.sortBy}
                      sortAscending={this.props.sortAscending}
                      totalItems={this.props.totalItems}
                      handlePageChange={this.handlePageChange}
                      handleSortChange={this.handleSortChange}
                      itemsLoading={this.props.invitationsLoading}
                      headerCenterText={tableControlLabelText}
                      searchPlaceholder={searchPlaceholder}
                      handleFilterChange={this.handleFilterChange}
                      searchTerm={this.props.searchTerm}
                      pullDownList={filterList}
                      pullDownDefaultValue={filterList[0]}
                      pullDownWidth={230}
                      pullDownOnChange={
                        this.handleInvitationSourceDropdownChange
                      }
                    />
                    <ReportsTable
                      title="Invitation Reports"
                      reports={this.props.reports}
                    />
                  </>
                )}
              </div>
            </main>
          </div>

          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

InvitationsListPage.propTypes = {
  invitations: PropTypes.array.isRequired,
  getInvitations: PropTypes.func.isRequired,
  getInvitationReports: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  appliedCount: PropTypes.number.isRequired,
  invitationsLoading: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired,
};

const actions = {
  ...invitationsActions,
  ...invitationsDataTableActions,
  ...tableActions,
};

function mapStateToProps(state, ownProps) {
  return {
    invitations: state.invitations.invitations,
    invitationsLoading: state.invitations.invitationsLoading,
    itemsPerPage: state.invitations.itemsPerPage,
    totalItems: state.invitations.totalItems,
    appliedCount: state.invitations.appliedCount,
    currentPage: state.invitationsDataTable.currentPage,
    searchTerm: state.invitationsDataTable.searchTerm,
    sortBy: state.invitationsDataTable.sortBy,
    sortAscending: state.invitationsDataTable.sortAscending,
    reports: state.invitations.reports,
  };
}

export default connect(mapStateToProps, actions)(InvitationsListPage);
