import React from 'react';
import PropTypes from 'prop-types';
import * as Layout from 'src/layout';

import {BusyButton} from './BusyButton';

import _ from 'lodash';
import {Field, Form} from 'react-final-form';

import PushButtonArray from './controls/buttons/PushButtonArray/PushButtonArray';
import {required} from '../utils/intake/intakeCommon';

class ExperienceLevelModal extends React.Component {
  validate = (values) => {
    const errors = {};
    const requiredFields = ['experienceLevels'];
    _.forEach(requiredFields, (field) => {
      if (!values[field] || values[field].length === 0) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  render() {
    const {isOpen, onClose} = this.props;

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <div className="padding-32">
            <center>
              <div className="normal-title padding-bottom-eight bold">
                Edit Experience Level
              </div>
            </center>
            <hr />
            <br />
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <Form
                  onSubmit={this.props.onSubmit}
                  validate={this.validate}
                  keepDirtyOnReinitialize={true}
                  initialValues={{
                    experienceLevels: this.props.selectedValues.map(
                      (expLevel) => {
                        return {
                          buttonValue: expLevel.id,
                        };
                      }
                    ),
                  }}
                >
                  {({handleSubmit}) => {
                    return (
                      <form onSubmit={() => false}>
                        <div className="d-flex flex-column align-items-center text-center">
                          <span>
                            Select all that apply. <br /> We will only recommend
                            roles to you with <br />
                            the same experience level(s) you choose.
                          </span>
                          <div style={{maxWidth: '250px', paddingTop: '20px'}}>
                            <Field
                              component={PushButtonArray}
                              name="experienceLevels"
                              validate={required}
                              buttonList={this.props.experienceOptions.map(
                                (level) => {
                                  return {
                                    buttonText: `${level.name} (${level.years})`,
                                    buttonValue: level.id,
                                  };
                                }
                              )}
                              displayVertical={true}
                              multiSelect={true}
                            />
                          </div>
                        </div>

                        <div className="row justify-content-center nowrap">
                          <button
                            type="button"
                            className="btn btn-transparent col-2 me-4"
                            onClick={onClose}
                          >
                            Cancel
                          </button>

                          <BusyButton
                            buttonText={'Save'}
                            busyText={'Saving'}
                            className="col-2"
                            style={{width: '210px', height: '40px'}}
                            float={'float-right'}
                            buttonIcon={'save'}
                            alignText={'text-center'}
                            onClick={handleSubmit}
                          />
                        </div>
                      </form>
                    );
                  }}
                </Form>
              </div>
              <div className="col-md-1" />
            </div>{' '}
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

ExperienceLevelModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  experienceOptions: PropTypes.array.isRequired,
  selectedValues: PropTypes.array,
};

export {ExperienceLevelModal};
