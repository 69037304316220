import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ActionIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.isActive,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isActive !== prevState.isActive) {
      return {isActive: nextProps.isActive};
    }
    return null;
  }

  showAriaPressedDiv = (show, activeClass) => {
    const divProps = {
      className: 'action-icon',
    };
    if (show) {
      divProps['aria-pressed'] = this.props.ariaPressed;
    }
    return (
      <div {...divProps}>
        {this.props.glyph && (
          <i
            className={classNames(
              'nc-icon-glyph-med',
              this.props.glyph,
              this.activeClass
            )}
          />
        )}
        {this.props.svg && !this.props.svgAsImage && (
          <img src={this.props.svg} alt="" />
        )}
        {this.props.svgAsImage && this.props.svgImage}
        {this.props.svgText && ` ${this.props.svgText}`}
      </div>
    );
  };

  handleMouseEnter = () => {
    if (this.props.message && this.props.onMouseEnter) {
      this.props.onMouseEnter(this.props.message);
    }
  };

  handleMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }
  };

  handleClick = () => {
    const isActive = !this.state.isActive;
    if (this.props.onClick) {
      this.props.onClick(isActive);
    }
    this.setState({
      isActive: isActive,
    });
  };

  handleOnFocusIn = () => {
    if (this.props.message && this.props.onFocus) {
      this.props.onFocus(this.props.message);
    }
  };
  handleOnFocusOut = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const activeClass = this.state.isActive
      ? this.props.activeClass
      : this.props.inactiveClass;

    return (
      <div className="inlineblock padding-left-sixteen">
        <div
          role="link"
          aria-label={this.props.message}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              this.handleClick();
            }
          }}
          className="clickable action-icon-container"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleClick}
          onFocus={this.handleOnFocusIn}
          onBlur={this.handleOnFocusOut}
        >
          {this.showAriaPressedDiv(this.props.ariaPressed, activeClass)}
        </div>
      </div>
    );
  }
}

ActionIcon.propTypes = {
  glyph: PropTypes.string,
  svg: PropTypes.string,
  svgAsImage: PropTypes.bool,
  svgImage: PropTypes.object,
  svgText: PropTypes.string,
  message: PropTypes.string,
  isActive: PropTypes.bool,
  activeClass: PropTypes.string,
  inactiveClass: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  ariaPressed: PropTypes.bool,
};
ActionIcon.defaultProps = {
  svgAsImage: false,
};
export default ActionIcon;
