import React from 'react';
import blueInvite from '../assets/images/blue_invite.svg';

class InvitedIcon extends React.Component {
  render() {
    return (
      <div
        className="inlineblock"
        style={{backgroundColor: '#fff', borderRadius: '50%'}}
      >
        <img height="16px" width="16px" src={blueInvite} alt="invite icon" />
      </div>
    );
  }
}

export default InvitedIcon;
