import * as types from '../actions/ActionTypes';

const initialState = {
  intakeData: {
    cacheInitialized: false,
    institutionId: '',
    otherInstitution: '',
    campusId: '',
    schoolId: '',
    termsAccepted: false,
  },
  intakeCacheLoading: false,
  intakeCacheSaving: false,
  allowCalendlySkip: false,
};

const UPPER_CASE_BOOLEAN_FIELDS = [
  'seekWorkHelp',
  'resumeHelp',
  'twentyFirstCenturyScholar',
  'pellGrant',
  'firstGenStudent',
  'collegeTrack',
  'graduateLastThreeYears',
  'veteran',
];

const LOWER_CASE_BOOLEAN_FIELDS = [
  'sponsorStatus',
  'workStatusAllowed',
  'pursueMarionJobs',
  'educationStatus',
];

function hydrateYesNoPreferNotToAnswer(fieldName, formData) {
  if (fieldName in formData) {
    if (formData[fieldName] === true) {
      formData[fieldName] = 'Yes';
    } else if (formData[fieldName] === false) {
      formData[fieldName] = 'No';
    } else {
      formData[fieldName] = 'NULL';
    }
  }
}

function hydrateYesOrNo(fieldName, formData) {
  if (fieldName in formData) {
    if (formData[fieldName] === true) {
      formData[fieldName] = 'true';
    } else if (formData[fieldName] === false) {
      formData[fieldName] = 'false';
    }
  }
}

const IntakeCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATUS_SUCCESS: // resume parsed, pull out the experiences for WorkExperience component
      return {
        ...state,
        intakeData: {
          ...state.intakeData,
          experience: action.response.data.resume.summary.experience,
        },
      };
    case types.INTAKE__GET_REQUEST:
      return {
        ...state,
        intakeCacheLoading: true,
      };
    case types.INTAKE__GET_SUCCESS:
      let formData = action.response.body.cache;
      formData.termsAccepted =
        formData.termsOfUse !== undefined && formData.signature !== undefined;

      for (const fieldName of UPPER_CASE_BOOLEAN_FIELDS) {
        hydrateYesNoPreferNotToAnswer(fieldName, formData);
      }

      for (const fieldName of LOWER_CASE_BOOLEAN_FIELDS) {
        hydrateYesOrNo(fieldName, formData);
      }

      return {
        ...state,
        intakeCacheLoading: false,
        intakeData: {...formData, cacheInitialized: true},
        allowCalendlySkip: action.response.body.allowCalendlySkip,
        userId: action.response.body.userId,
      };
    case types.INTAKE__GET_FAILURE:
      return {
        ...state,
        intakeCacheLoading: false,
        intakeCacheLoadingErr: action.payload,
      };
    case types.INTAKE__SAVE_REQUEST:
      const intakeResponseData = action.response.body;
      intakeResponseData.termsAccepted =
        intakeResponseData.termsOfUse !== undefined &&
        intakeResponseData.signature !== undefined;
      for (const fieldName of UPPER_CASE_BOOLEAN_FIELDS) {
        hydrateYesNoPreferNotToAnswer(fieldName, intakeResponseData);
      }

      for (const fieldName of LOWER_CASE_BOOLEAN_FIELDS) {
        hydrateYesOrNo(fieldName, intakeResponseData);
      }

      return {
        ...state,
        intakeCacheSaving: true,
        intakeData: intakeResponseData,
      };
    case types.INTAKE__SAVE_SUCCESS:
      return {
        ...state,
        intakeCacheSaving: false,
      };
    case types.INTAKE__SAVE_FAILURE:
      return {
        ...state,
        intakeCacheSaving: false,
        intakeCacheSavingErr: action.payload,
      };
    case types.INTAKE__UPDATE_LOCAL_DATA:
      return {
        ...state,
        intakeData: {
          ...state.intakeData,
          ...action.newCache,
        },
      };
    case types.INTAKE__GET_BY_USER_REQUEST:
      return {
        ...state,
        intakeCacheLoading: true,
      };
    case types.INTAKE__GET_BY_USER_SUCCESS:
      formData = action.response.body.cache;
      formData.termsAccepted =
        formData.termsOfUse !== undefined && formData.signature !== undefined;

      for (const fieldName of UPPER_CASE_BOOLEAN_FIELDS) {
        hydrateYesNoPreferNotToAnswer(fieldName, formData);
      }

      for (const fieldName of LOWER_CASE_BOOLEAN_FIELDS) {
        hydrateYesOrNo(fieldName, formData);
      }

      return {
        ...state,
        intakeCacheLoading: false,
        intakeData: {...formData, cacheInitialized: true},
        allowCalendlySkip: action.response.body.allowCalendlySkip,
        userId: action.response.body.userId,
      };
    case types.INTAKE__GET_BY_USER_FAILURE:
      return {
        ...state,
        intakeCacheLoading: false,
        intakeCacheLoadingErr: action.payload,
      };

    default:
      return state;
  }
};

export default IntakeCacheReducer;
