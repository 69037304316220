import * as types from '../actions/ActionTypes';
import noAvatarSvg from '../assets/images/noavatar.svg';
import * as analytics from 'src/utils/analytics';

const FIFTY_YEARS = 1000 * 60 * 60 * 24 * 365 * 50;

const initialState = {
  avatarUrl: noAvatarSvg,
  isLoaded: false,
  lastLoginUpdated: false,
  consenterDateOfBirth: new Date(Date.now() - FIFTY_YEARS).toISOString(),
  notificationOptions: {
    INVITE_TO_APPLY_EMPLOYER_NOTIFICATION: true,
    INVITE_TO_APPLY_ASCEND_NOTIFICATION: true,
    NEW_ROLE_NOTIFICATION: true,
    NEW_ROLE_NOTIFICATION_FREQUENCY: 'Weekly',
    RECEIVED_NEW_APPLICATION_NOTIFICATION: false,
    RECEIVED_NEW_APPRENTICESHIP_APPLICATION_NOTIFICATION: false,
    WEEKLY_APPLICATION_SUMMARY_NOTIFICATION: true,
    NEW_ROLES_PUBLISHED_NOTIFICATION: true,
    SAVED_ROLE_SEEKER_NOTIFICATION: true,
    UNPUBLISHED_ROLE_SEEKER_NOTIFICATION: false,
    PROFILE_SAVED: true,
    initialized: false,
  },
  email: '',
  segmentNames: [],
  segmentIds: [],
  securityRoleNames: [],
  seekerId: null,
  employerId: null,
};

function registerProfileAnalytics(profile) {
  const userType = {
    JOB_SEEKER: 'JOB SEEKER',
    COMPANY_RECRUITER: 'EMPLOYER',
    COMPANY_RECRUITER_ADMIN: 'EMPLOYER',
    ASCEND_RECRUITER: 'ADMINISTRATOR',
  };

  const analyticsProfile = {
    ...profile,
    userType: userType[profile.role],
    segment: profile.segmentNames && profile.segmentNames[0],
  };
  try {
    window.analytics.identify(profile.id, analyticsProfile);
    window.Appcues.identify(profile.id, analyticsProfile);
  } catch {}

  delete analyticsProfile['permissions'];

  analytics.track({
    registerData: analyticsProfile,
  });
}

function initializeUserPermissions(profile) {
  let permissionsObj = {
    list: profile.permissions,
  };
  for (let permission of profile.permissions) {
    permissionsObj[permission] = {
      lastCheckedAt: Date.now(),
      hasPermission: true,
    };
  }

  localStorage.setItem('permissionsObj', JSON.stringify(permissionsObj));
}

export default function profile(state = initialState, action) {
  switch (action.type) {
    case types.PROFILE__GET_MIDDLEWARE_SUCCESS:
      registerProfileAnalytics(action.response.body);
      return {
        ...state,
        ...action.response.body,
        isLoaded: true,
      };
    case types.PROFILE__GET_SUCCESS:
      registerProfileAnalytics(action.response.data);
      initializeUserPermissions(action.response.data);

      return {
        ...state,
        ...action.response.data, // <-- response.data here because we are using the new api service.
        isLoaded: true,
      };
    case types.PROFILE__ONBOARDING_COMPLETE:
      let newProfile = {
        ...state,
        onboarded: true,
        reonboarded: true,
        intakeCompleted: true,
        termsAccepted: true,
      };
      if (action.avatarUrl !== undefined && action.avatarUrl !== null) {
        newProfile.avatarUrl = action.avatarUrl;
      }
      return newProfile;

    case types.PROFILE__MODERNAPPRENTICE_INTAKE_COMPLETE:
      return {
        ...state,
        intakeCompleted: true,
        isApprenticeProgram: true,
      };

    case types.USERS__UPDATE_LAST_LOGIN_SUCCESS:
      return {
        ...state,
        lastLoginUpdated: true,
      };
    case types.SEEKERS__AVATAR_CHANGED:
      if (
        state.id === action.data.userId ||
        state.seekerId === action.data.id
      ) {
        return {
          ...state,
          avatarUrl: action.data.avatarUrl,
        };
      } else {
        return {...state};
      }
    case types.SEEKERS__UPDATE_SUCCESS:
      // Needed for completion of (re)onboarding workflow
      // update profile surveyCompleted only if the user is a seeker and they've just
      // updated their own record.
      if (
        state.role === 'JOB_SEEKER' &&
        state.seekerId === action.response.body.id
      ) {
        return {
          ...state,
          surveyCompleted: !!action.response.body.surveyCompletedOn,
          termsAccepted: !!action.response.body.termsAccepted,
          consenterFullName: action.response.body.consenterFullName,
          consenterDateOfBirth: action.response.body.consenterDateOfBirth,
        };
      } else {
        return {...state};
      }

    case types.USERS__UPDATE_TERMS_ACCEPTED_SUCCESS:
      return {
        ...state,
        termsAccepted: true,
        termsAcceptedSuccess: true,
        termsAcceptedError: null,
      };
    case types.USERS__UPDATE_TERMS_ACCEPTED_FAILURE:
      return {
        ...state,
        termsAccepted: null,
        termsAcceptedSuccess: null,
        termsAcceptedError: action.error,
      };
    case types.PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS:
      return {
        ...state,
        notificationsLoading: false,
        notificationOptions: {
          ...state.notificationOptions,
          ...action.data,
          ...action.response?.body.notifications,
        },
      };
    case types.PROFILE__GET_NOTIFICATION_OPTIONS_REQUEST:
      return {
        ...state,
        notificationsLoading: true,
        notificationOptions: {
          ...state.notificationOptions,
          initialized: true,
        },
      };

    default:
      return state;
  }
}
