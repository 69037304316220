import React from 'react';
import DocumentTitle from '../components/vendor/DocumentTitle';

export default function IneligibleUserPage() {
  return (
    <DocumentTitle title="Ineligible">
      <div className="fullindy">
        <div className="container">
          <br />
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div>
                <div className="bd-whitebackground">
                  <div className="row">
                    <div className="col-12">
                      <center>
                        <div className="normal-jumbo bold">
                          Thanks for your interest
                        </div>
                        <hr />
                      </center>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2" />
                    <div className="col-md-8">
                      <div className="normal-title text-align-center">
                        <div className="normal-body">
                          Thank you for your interest in Modern Apprenticeship.
                          To participate, you must be legally authorized to be
                          employed in the United States, now and in the future,
                          and able to provide the{' '}
                          <a
                            href={
                              'https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents'
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                          >
                            required documentation
                          </a>{' '}
                          required documentation for employment eligibility
                          verification.
                          <br />
                          <br />
                          Based on your selection, your student is not eligible
                          to participate in the Modern Apprenticeship Program at
                          this time. If you have questions, would like alternate
                          resources, or believe this was a mistake, please email{' '}
                          <a href="mailto:MAPIndy@employindy.org">
                            MAPIndy@employindy.org
                          </a>{' '}
                          for support.
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2" />
            </div>
          </div>
          <br />
        </div>
        <div className="col-md-2" />
      </div>
    </DocumentTitle>
  );
}
