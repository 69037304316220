import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import * as zipcodesActions from '../../actions/zipcodes';
import {connect} from 'react-redux';
import * as SVG from '../../assets/svg/svgComponents';
import {isHighContrastMode} from 'src/utils/miscHelper';

class ZipWidget extends React.Component {
  lepRef = [];
  constructor(props) {
    super(props);

    this.lepRef[1] = createRef();
    this.lepRef[2] = createRef();
    this.lepRef[3] = createRef();
    this.lepRef[4] = createRef();

    this.state = {
      lepIndex: this.props.lepIndex,
    };
  }

  componentDidMount() {
    this.props.getZipcode(this.props.zipcodeId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleLiveClick = () => {
    this.setState({lepIndex: 1});
  };

  handleEatClick = () => {
    this.setState({lepIndex: 2});
  };

  handlePlayClick = () => {
    this.setState({lepIndex: 3});
  };

  handleEngageClick = () => {
    this.setState({lepIndex: 4});
  };

  shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  render() {
    const zipData = this.props.zipcode;

    const showStar = (stars) => {
      const starClass = isHighContrastMode(this.props.userId)
        ? 'svg_star_color_fill_high_contrast'
        : 'svg_star_color_fill';

      switch (stars) {
        case '1':
          return (
            <SVG.OneStar
              className={`${starClass}`}
              style={{width: '95%'}}
              alt=""
            />
          );
        case '2':
          return (
            <SVG.TwoStar
              className={`${starClass}`}
              style={{width: '95%'}}
              alt=""
            />
          );
        case '3':
          return (
            <SVG.ThreeStar
              className={`${starClass}`}
              style={{width: '95%'}}
              alt=""
            />
          );
        case '4':
          return (
            <SVG.FourStar
              className={`${starClass}`}
              style={{width: '95%'}}
              alt=""
            />
          );
        default:
          return (
            <SVG.ZeroStar
              className={`${starClass}`}
              style={{width: '95%'}}
              alt=""
            />
          );
      }
    };

    const lepcontent = () => {
      const zipIndexData = {
        1: zipData.liveData,
        2: zipData.eatData,
        3: zipData.playData,
        4: zipData.engageData,
      };
      if (this.props.zipcode.id) {
        const dataItems = this.shuffleArray(zipIndexData[this.state.lepIndex]);
        for (var i = 0; i < dataItems.length; i++) {
          return dataItems.map((item, i) => {
            return (
              <div style={{paddingBottom: '8px'}} key={i}>
                <a key={i} href={item.url} target="_blank" rel="noreferrer">
                  <div className="bd-whitebackground-nopadding  clickable">
                    <div className="row">
                      <div className="col-md-4">
                        <img src={item.photo} width="100%" alt="" />
                      </div>
                      <div className="col-md-8">
                        <div className="bd-whitebackground padding-8">
                          <div className="normal-subheader">{item.title}</div>
                          <div className="disabled-body">{item.summary}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            );
          });
        }
      } else {
        return <div />;
      }
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <h2 className="normal-title bold">In the Neighborhood</h2>
          <br />
          <div className="secondary-subheader">{zipData.summary}</div>
          <br />
          <div className="row">
            <div className="col-md-4">
              <div className="bd-whitebackground padding-8 tilesize text-align-center">
                <div className="padding-top-twentyfour padding-bottom-eight">
                  {showStar(zipData.walkability)}
                </div>
                <div className="secondary-small2 bold">
                  Walkability
                  <span className="visually-hidden">{`${zipData.walkability} stars`}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bd-whitebackground padding-8 tilesize text-align-center">
                <div className="padding-top-twentyfour padding-bottom-eight">
                  {showStar(zipData.accessToGreenspace)}
                </div>
                <div className="secondary-small2 bold">
                  Access to Greenspace
                  <span className="visually-hidden">{`${zipData.accessToGreenspace} stars`}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bd-whitebackground padding-8 tilesize text-align-center">
                <div className="padding-top-twentyfour padding-bottom-eight">
                  {showStar(zipData.youngProfessionalFriendly)}
                </div>
                <div className="secondary-small2 bold">
                  Young Professional Friendly
                  <span className="visually-hidden">{`${zipData.youngProfessionalFriendly} stars`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div
            role="tablist"
            className={
              'inlineblock ' +
              (this.state.lepIndex === 1 ? 'bottom-border' : '')
            }
          >
            &nbsp;&nbsp;
            <button
              ref={this.lepRef[1]}
              role="tab"
              className="border-0 normal-body bold clickable "
              style={{background: 'none'}}
              onClick={this.handleLiveClick}
              tabIndex={0}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handleLiveClick();
                  e.preventDefault();
                }
                if (e.key === 'ArrowRight') {
                  this.lepRef[2].current.focus();
                  this.handleEatClick();
                  e.preventDefault();
                }
                if (e.key === 'ArrowLeft') {
                  this.lepRef[4].current.focus();
                  this.handleEngageClick();
                  e.preventDefault();
                }
              }}
            >
              LIVE
            </button>
            &nbsp;&nbsp;
          </div>
          <div
            className={
              'inlineblock ' +
              (this.state.lepIndex === 2 ? 'bottom-border' : '')
            }
          >
            &nbsp;&nbsp;
            <button
              ref={this.lepRef[2]}
              role="tab"
              className="border-0 normal-body bold clickable"
              style={{background: 'none'}}
              onClick={this.handleEatClick}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handleEatClick();
                }
                if (e.key === 'ArrowRight') {
                  this.lepRef[3].current.focus();
                  this.handlePlayClick();
                  e.preventDefault();
                }
                if (e.key === 'ArrowLeft') {
                  this.lepRef[1].current.focus();
                  this.handleLiveClick();
                  e.preventDefault();
                }
              }}
              tabIndex={0}
            >
              EAT
            </button>
            &nbsp;&nbsp;
          </div>
          <div
            className={
              'inlineblock ' +
              (this.state.lepIndex === 3 ? 'bottom-border' : '')
            }
          >
            &nbsp;&nbsp;
            <button
              ref={this.lepRef[3]}
              role="tab"
              className="border-0 normal-body bold clickable"
              style={{background: 'none'}}
              onClick={this.handlePlayClick}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handlePlayClick();
                  e.preventDefault();
                }
                if (e.key === 'ArrowRight') {
                  this.lepRef[4].current.focus();
                  this.handleEngageClick();
                  e.preventDefault();
                }
                if (e.key === 'ArrowLeft') {
                  this.lepRef[2].current.focus();
                  this.handleEatClick();
                  e.preventDefault();
                }
              }}
              tabIndex={0}
            >
              PLAY
            </button>
            &nbsp;&nbsp;
          </div>
          <div
            className={
              'inlineblock ' +
              (this.state.lepIndex === 4 ? 'bottom-border' : '')
            }
          >
            &nbsp;&nbsp;
            <button
              ref={this.lepRef[4]}
              role="tab"
              className="border-0 normal-body bold clickable"
              style={{background: 'none'}}
              onClick={this.handleEngageClick}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handleEngageClick();
                }
                if (e.key === 'ArrowRight') {
                  this.lepRef[1].current.focus();
                  this.handleLiveClick();
                  e.preventDefault();
                }
                if (e.key === 'ArrowLeft') {
                  this.lepRef[3].current.focus();
                  this.handlePlayClick();
                  e.preventDefault();
                }
              }}
              tabIndex={0}
            >
              ENGAGE
            </button>
            &nbsp;&nbsp;
          </div>
          <br />
          <div
            style={{
              height: '460px',
              overflowY: 'auto',
              marginTop: '13px',
              paddingTop: '2px',
            }}
          >
            {lepcontent()}
          </div>
        </div>
      </div>
    );
  }
}

ZipWidget.propTypes = {
  zipcodeId: PropTypes.number.isRequired,
  lepIndex: PropTypes.number,
  getZipcode: PropTypes.func.isRequired,
  zipcode: PropTypes.object,
  userId: PropTypes.number.isRequired,
};

ZipWidget.defaultProps = {
  lepIndex: 1,
};

const mapStateToProps = (state) => {
  return {
    zipcode: state.zipcodes.zipcode,
    userId: state.profile.id,
  };
};

const mapDispatchToProps = {
  ...zipcodesActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZipWidget);
