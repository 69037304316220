import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styles from './MyProgress.module.scss';
import {
  getSeekerAction,
  interviewSkillsChanged,
  updateSeekerAction,
} from 'src/actions/SeekerActions';
import SkillsModal from './SkillsModal';
import {
  AppliedSvg,
  ArrowRightOrangeSvg,
  HeartDefaultSvg,
  MatchBestfitSvg,
} from 'src/assets/svg/svgComponents';

export default function MyProgress({seekerId}) {
  const dispatch = useDispatch();
  let seeker = useSelector((state) => state.seekers.seeker);
  let seekerLoading = useSelector((state) => state.seekers.seekerLoading);
  let seekerJobStats = useSelector((state) => state.seekers.jobStats);
  const [skillsModalIsOpen, setSkillsModalIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (seeker == null && !seekerLoading) {
      dispatch(getSeekerAction(seekerId));
    }
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkillsSubmit = (values) => {
    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    dispatch(updateSeekerAction(updatedSeeker));
    setSkillsModalIsOpen(false);
  };

  return (
    <>
      <SkillsModal
        isOpen={skillsModalIsOpen}
        onClose={() => {
          setSkillsModalIsOpen(false);
        }}
        selectedSkills={seeker.skillTags ? seeker.skillTags : []}
        onChange={(selectedSkills) => {
          dispatch(interviewSkillsChanged(selectedSkills));
        }}
        onSubmit={handleSkillsSubmit}
      />

      <div className={`pe-4 ${styles.container}`}>
        <h2>My Progress</h2>
        {loaded && (
          <>
            <div className="col-lg-12 pb-2">
              <div
                className={`container bd-whitebackground pt-3 ps-3 pe-2 pb-3 ${styles.card}`}
              >
                <h3>{seekerJobStats.jobMatchCount}</h3>
                <h4>
                  <MatchBestfitSvg />
                  &nbsp;&nbsp;
                  {seekerJobStats.jobMatchCount === 1 ? 'Match' : 'Matches'}
                </h4>
                <p>
                  Matches are made based on your preferences and skills. Improve
                  your matches by keeping them up-to-date.
                </p>
                <div className={styles.link_container}>
                  <button
                    onClick={() => setSkillsModalIsOpen(true)}
                    className={`btn buttonAsLink bold`}
                  >
                    Update Skills
                  </button>{' '}
                  &{' '}
                  <span>
                    <Link className={`bold ${styles.svgHover}`} to={'/profile'}>
                      <span className="underlineLink orangeHover pe-1">
                        View Preferences
                      </span>
                      <ArrowRightOrangeSvg />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-12 pb-2">
              <div
                className={`container bd-whitebackground pt-3 ps-3 pe-2 pb-3 ${styles.card}`}
              >
                <h3>
                  {
                    seeker.watchedJobs.filter((job) => job.published === true)
                      .length
                  }
                </h3>
                <h4>
                  <HeartDefaultSvg /> &nbsp;&nbsp;
                  {seeker.watchedJobs.filter((job) => job.published === true)
                    .length === 1
                    ? 'Favorited Role'
                    : 'Favorited Roles'}
                </h4>
                <p>Browse and find roles to return to later.</p>
                {seeker.watchedJobs.filter((job) => job.published === true)
                  .length === 0 && (
                  <Link
                    className={`bold ${styles.svgHover}`}
                    to={'/find_roles'}
                  >
                    <span className="underlineLink orangeHover pe-1">
                      Find Jobs
                    </span>
                    <ArrowRightOrangeSvg
                      className={`styles.svg_color_stroke_orange mr-1`}
                    />
                  </Link>
                )}
                {seeker.watchedJobs.filter((job) => job.published === true)
                  .length > 0 && (
                  <Link
                    className={`bold ${styles.svgHover}`}
                    to={'/find_roles?savedRoles'}
                  >
                    <span className="underlineLink orangeHover pe-1">
                      View Favorited Roles
                    </span>
                    <ArrowRightOrangeSvg className={styles.svgHover} />
                  </Link>
                )}
              </div>
            </div>
            <div className="col-lg-12 pb-2">
              <div
                className={`container bd-whitebackground pt-3 ps-3 pe-2 pb-3 ${styles.card}`}
              >
                <h3>{seeker.appliedJobs.length}</h3>
                <h4>
                  <AppliedSvg className={styles.appliedSvg} />
                  &nbsp;&nbsp;
                  {seeker.appliedJobs.length === 1
                    ? 'Application'
                    : 'Applications'}
                </h4>
                <p>
                  Submitting and tracking applications is key to getting the
                  job.
                </p>
                {seeker.appliedJobs.length === 0 && (
                  <Link
                    className={`bold ${styles.svgHover}`}
                    to={'/find_roles'}
                  >
                    <span className="underlineLink orangeHover pe-1">
                      Find Jobs
                    </span>
                    <ArrowRightOrangeSvg
                      className={`styles.svg_color_stroke_orange mr-1`}
                    />
                  </Link>
                )}
                {seeker.appliedJobs.length > 0 && (
                  <Link
                    className={`bold ${styles.svgHover}`}
                    to={'/applications'}
                  >
                    <span className="underlineLink orangeHover pe-1">
                      View Applications
                    </span>
                    <ArrowRightOrangeSvg
                      className={`styles.svg_color_stroke_orange mr-1`}
                    />
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

MyProgress.propTypes = {
  seekerId: PropTypes.string.isRequired,
};
