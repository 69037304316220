import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';
import {CANDIDATE_DISCOVERY} from 'src/pages/candidateDiscovery/candidateDiscoveryGlobals';

import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  industries: [],
  industriesLoading: false,
  filteredIndustries: [],
};

function industrySort(i1, i2) {
  if (i1.name === 'Any') {
    return -1;
  }
  if (i2.name === 'Any') {
    return 0;
  }
  return i1.name.localeCompare(i2.name);
}

export default function industries(state = initialState, action) {
  let industries = [];
  switch (action.type) {
    case types.INDUSTRIES__GET_REQUEST:
      return {...state, industries: [], industriesLoading: true};
    case types.INDUSTRIES__GET_SUCCESS:
      industries = action.response.body.industries.filter(
        (ind) => ind.name !== 'Any'
      );
      industries.sort(industrySort);

      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        industries: action.response.body.industries,
        filteredIndustries: industries,
        industriesLoading: false,
      };
    case types.CANDIDATE_DISCOVERY__FILTER_LIST:
      if (action.filterType !== CANDIDATE_DISCOVERY.industryFilter) {
        return state;
      }
      industries = state.industries.filter((i) => i.name !== 'Any');
      industries.sort(industrySort);
      if (action.filterValue === '') {
        return {
          ...state,
          filteredIndustries: industries,
        };
      }
      return {
        ...state,
        filteredIndustries: industries.filter(
          (tag) =>
            tag.name !== 'Any' &&
            tag.name.toLowerCase().includes(action.filterValue.toLowerCase())
        ),
      };
    case types.CANDIDATE_DISCOVERY__CLEAR_ALL:
      industries = state.industries.filter((ind) => ind.name !== 'Any');
      industries.sort(industrySort);
      return {...state, filteredIndustries: industries};
    default:
      return state;
  }
}
