import React, {useEffect, useReducer} from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {InlineWidget} from 'react-calendly';
import {useSelector} from 'react-redux';
import {makeRequest} from 'src/services/api';

import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {track} from 'src/utils/analytics';

let calendlyHasAssignedAscendRecruiter = false;
let calendlyNetworkEmbedUrl = '';
const ASCEND_SEGMENT_ID = 1;

const initialCalendlyState = {
  embedUrl: '',
  calendlyUrlFound: null,
  allowSkip: false,
};

const ACTIONS = {
  SET_EMBED_URL: 'SET_EMBED_URL',
  SET_EMBED_URL_NOT_FOUND: 'SET_EMBED_URL_NOT_FOUND',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_EMBED_URL:
      calendlyNetworkEmbedUrl = action.url;
      return {
        ...state,
        embedUrl: action.url,
        allowSkip: action.allowSkip || action.allowCalendlySkip,
        calendlyUrlFound: true,
      };
    case ACTIONS.SET_EMBED_URL_NOT_FOUND:
      return {
        ...state,
        calendlyUrlFound: false,
      };
    default:
      return state;
  }
}

export default function CalendlyEmbed({
  next,
  previous,
  intakeCache,
  headerImage,
}) {
  const profile = useSelector((state) => state.profile);
  const [calendlyState, dispatch] = useReducer(reducer, initialCalendlyState);
  const allowCalendlySkip = useSelector(
    (state) => state.intakeCache.allowCalendlySkip
  );

  let segmentId = ASCEND_SEGMENT_ID;
  if (intakeCache.segments?.length > 0) {
    segmentId = intakeCache.segments[0].id;
  }

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await makeRequest({
        method: 'GET',
        path: `intake/calendly_url?segmentId=${segmentId}`,
      });
      if (!response.data.success) {
        dispatch({type: ACTIONS.SET_EMBED_URL_NOT_FOUND});
        return;
      }
      dispatch({
        type: ACTIONS.SET_EMBED_URL,
        ...response.data,
        allowCalendlySkip,
      });
    };

    fetchUrl();
    return () => {};
  }, [segmentId, allowCalendlySkip]);

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  function calendlyEventScheduled(e) {
    /* Additional Details on the events from: https://developer.calendly.com/api-docs/ZG9jOjI3ODM2MTAz-notifying-the-parent-window
    e.data:
    {
      event: "calendly.event_scheduled"
      payload: {
        event: {
          uri: 'https://api.calendly.com/scheduled_events/dc3f1cfb-16e5-413c-9b48-62afa54c694d'
        }
        invitee: {
          uri: 'https://api.calendly.com/scheduled_events/dc3f1cfb…94d/invitees/ae9eb5bc-4af7-4912-ab69-9a2f46e7418f'
        }
      }
    }
    */
    return e.data.event.indexOf('calendly.event_scheduled') === 0;
  }

  function updateAssignedRecruiter(eventUrl) {
    /*
    I was seeing this API call get made many times before either a state
    variable or the reducer was set. This is likely because the browser's
    event loop was firing multiple times before the reducer was set.
    I'm intentionally using a global variable here so that the variable is set
    during the same thread's execution and would avoid multiple API calls.
    */
    if (!calendlyHasAssignedAscendRecruiter) {
      makeRequest({
        method: 'POST',
        path: 'intake/update_assigned_recruiter',
        contentType: 'application/json',
        body: {
          eventUrl: eventUrl,
          embedUrl: calendlyNetworkEmbedUrl,
        },
      });
      calendlyHasAssignedAscendRecruiter = true;
      track({
        eventName: 'Calendly Event Scheduled',
      });
      next();
    }
  }

  window.addEventListener('message', function (e) {
    if (isCalendlyEvent(e) && calendlyEventScheduled(e)) {
      updateAssignedRecruiter(e.data.payload.event.uri);
    }
  });

  const enableContinue =
    calendlyHasAssignedAscendRecruiter || calendlyState.allowSkip;
  return (
    <>
      <PageHeader
        title="Get to Know Your Recruitment Team"
        headerImage={headerImage}
      />
      <div className={styles.pageContent}>
        <p className={styles.pageSubHeader} style={{maxWidth: '800px'}}>
          Hi {intakeCache.firstName}, it's our job to help you succeed during
          your job search. Schedule a meeting with a member of our team to share
          your interests and receive recommendations. Once you schedule, you'll
          have full access to all the roles in the Ascend Network so you can
          start applying!
        </p>
        {calendlyState.calendlyUrlFound === false && (
          <div className="onboarding-form-label">
            Could not find a calendly link, please contact support
          </div>
        )}
        {calendlyState.calendlyUrlFound === true && (
          <div className={styles.calendlyEmbed}>
            <InlineWidget
              url={calendlyState.embedUrl}
              styles={{
                height: '894px',
                width: '100%',
              }}
              prefill={{
                email: profile.email,
                name: `${intakeCache.firstName || ''} ${
                  intakeCache.lastName || ''
                }`,
              }}
              iframeTitle={'Schedule your appointment, powered by Calendly'}
            />
          </div>
        )}
      </div>
      <PageFooter
        next={next}
        previous={previous}
        getValues={() => {}}
        rightButtonDisabled={!enableContinue}
        rightButtonText={
          calendlyState.allowSkip ? 'Skip Scheduling' : 'Continue'
        }
      />
    </>
  );
}

CalendlyEmbed.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.object,
  headerImage: PropTypes.any,
};
