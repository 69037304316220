import merge from 'deepmerge';

import * as types from '../actions/ActionTypes';

const initialState = {
  jobs: [],
  jobsLoading: false,
  allRolesSelected: false,
  selectedEmployerId: 0,
  myMatchesSelected: true,
  workStyleMatchesSelected: false,
  overallFit: false,
  invitedRolesSelected: false,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  jobsTotalItems: 0,
  jobsSortTerm: '',
  jobsSearchTerm: '',
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
  startDateTag: '',
  roleTypeIds: [],
  employmentTypeIds: [],
  experienceLevelIds: [],
  regionIds: [],
  industryIds: [],
  degreeTypeRankIds: [],
  segmentIds: [],
  jobCategoryTags: [],
  remoteStatus: {},
  accessProhibitedError: false,
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export default function allJobSearch(state = initialState, action) {
  switch (action.type) {
    case types.ALL_JOB_SEARCH__UPDATE_STATE:
      let newState = merge(state, action.newState, {
        arrayMerge: overwriteMerge,
      });
      if (action.clearWorkStyles) {
        newState.roleTypeIds = [];
        newState.employmentTypeIds = [];
        newState.experienceLevelIds = [];
        newState.locationIds = [];
        newState.industryIds = [];
        newState.degreeTypeRankIds = [];
        newState.segmentIds = [];
        newState.remoteStatus = [];
      }

      return newState;
    case types.ALL_JOB_SEARCH__UPDATE_EMPLOYER_ID:
      return {
        ...state,
        selectedEmployerId: action.selectedEmployerId,
      };
    case types.ALL_JOB_SEARCH__GET_JOB_MATCHES_REQUEST:
      return {
        ...state,
        jobs: [],
        jobsLoading: true,
        accessProhibitedError: false,
      };
    case types.ALL_JOB_SEARCH__GET_JOB_MATCHES_SUCCESS:
      return {
        ...state,
        jobs: action.response.body.jobs.map((job) => {
          if (job.matchData) {
            return {
              ...job,
              matchData: {
                matchLevels: [],
                ...job.matchData,
                showMatchChart:
                  job.matchData?.employmentTypeFit &&
                  job.matchData?.degreeRankFit &&
                  job.matchData?.experienceLevelFit,
              },
            };
          } else {
            return job;
          }
        }),
        jobsLoading: false,
        jobsCurrentPage: action.response.body.page,
        jobsCurrentItemsPerPage: action.response.body.itemsPerPage,
        jobsTotalItems: action.response.body.totalItems,
        accessProhibitedError: false,
      };
    case types.ALL_JOB_SEARCH__GET_JOB_MATCHES_FAILURE:
      return {
        ...state,
        jobs: [],
        jobsLoading: false,
        accessProhibitedError: action.error.status === 403,
      };
    case types.JOBS__CREATE_SUCCESS:
      return {
        ...state,
        jobs: [action.response.body, ...state.jobs],
      };

    default:
      return state;
  }
}
