import React, {Fragment} from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import infoBlueCircleSvg from 'src/assets/images/info-blue-circle.svg';
export const PhoneNumberInput = (props) => {
  const {
    input,
    meta: {touched, error, warning},
    label,
    labelActionText,
    labelActionCallBack,
    icon,
    visible,
    showBackground,
    labelBold,
    dataplace,
    datatip,
    showInfoIcon,
    labelCssClass,
  } = props;

  const wrappedLabel = () => {
    if (icon) {
      return (
        <>
          {icon}&nbsp;&nbsp;{label}
        </>
      );
    } else {
      return (
        <div id="phoneMask">
          {label}
          {labelActionText ? (
            <span
              className="clickable active-body float-right "
              style={{margin: '0', padding: '0'}}
              onClick={labelActionCallBack}
              role="button"
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  e.preventDefault();
                  labelActionCallBack();
                }
              }}
            >
              {labelActionText}
            </span>
          ) : null}
          {showInfoIcon && (
            <Fragment>
              &nbsp; &nbsp;
              <img
                tabIndex={0}
                className="clickable"
                style={{width: '5%'}}
                alt={datatip.replace('<br/>', '')}
                src={infoBlueCircleSvg}
                data-tooltip-id="tooltip"
                data-tooltip-place={dataplace}
                data-tooltip-html={datatip}
              />
            </Fragment>
          )}
        </div>
      );
    }
  };

  const labelCss = !labelCssClass
    ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
    : labelCssClass;

  return (
    <Fragment>
      {visible && (
        <div>
          <label className={labelCss}>{wrappedLabel()}</label>
          <div aria-label={label} className="form form-group">
            <InputMask
              aria-labelledby="phoneMask"
              {...input}
              className={`${
                showBackground ? 'bd-verylightbluebackground' : ''
              } form-control normal-subheader`}
              mask="(999) 999-9999"
              type="text"
            />
            {touched &&
              ((error && <div className="form-input-error">{error}</div>) ||
                (warning && <div className="form-input-error">{warning}</div>))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

PhoneNumberInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  labelActionText: PropTypes.string,
  labelActionCallBack: PropTypes.func,
  visible: PropTypes.bool,
  showBackground: PropTypes.bool,
  labelBold: PropTypes.bool,
  datamultiline: PropTypes.bool,
  dataeffect: PropTypes.string,
  datatype: PropTypes.string,
  dataplace: PropTypes.string,
  datatip: PropTypes.string,
  showTipAsText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  labelCssClass: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  visible: true,
  backgroundColor: false,
};
