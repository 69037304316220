import {find} from 'lodash';

export const ascendFitMessage = (fit) => {
  if (fit) {
    return 'Ascend thinks you are a strong fit for this role.';
  } else {
    return 'Ascend does not recommend this role for you.';
  }
};

export const findMatchData = (jobId, matches) => {
  const match = find(matches, (i) => {
    return i.id === jobId || i.jobId === jobId;
  });
  if (match) {
    if (match.match) return match.match;
    if (match.matchData) return match.matchData;
  }
  return null;
};
