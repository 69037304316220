import * as types from './ActionTypes';

export function createJobsDataTableTeam(teamId) {
  return {
    type: types.JOBS_DT__CREATE_TEAM,
    teamId,
  };
}

export function updateCurrentPage(teamId, page) {
  return {
    type: types.JOBS_DT__UPDATE_CURRENT_PAGE,
    teamId,
    page,
  };
}

export function updateSearchTerm(teamId, searchTerm) {
  return {
    type: types.JOBS_DT__UPDATE_SEARCH_TERM,
    teamId,
    searchTerm,
  };
}

export function updateSortTerm(teamId, sortBy, sortAscending) {
  return {
    type: types.JOBS_DT__UPDATE_SORT_TERM,
    teamId,
    sortBy,
    sortAscending,
  };
}
