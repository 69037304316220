import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';

import {Field, Form} from 'react-final-form';
import {SelectField} from 'src/formFields';
import SelectMonthYear from '../../../formFields/selectMonthYear/SelectMonthYear';

class EducationLevel extends React.Component {
  validate = (values) => {
    const errors = {};
    const requiredFields = ['educationLevel', 'graduationDate'];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  render() {
    const {next, previous, educationLevels, headerImage} = this.props;

    return (
      <>
        <PageHeader headerImage={headerImage} />

        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            educationLevel: this.props.intakeCache?.educationLevel,
            graduationDate:
              this.props.intakeCache?.graduationDate ||
              `5/${new Date().getFullYear()}`,
          }}
        >
          {({handleSubmit, form, input}) => {
            const gradDate = form
              .getState()
              .values['graduationDate']?.split('/') ?? [
              5,
              new Date().getFullYear(),
            ];

            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <div style={{textAlign: 'center'}}>
                    <Field
                      name="educationLevel"
                      label="What is your highest level of education received or currently pursuing?"
                      subTextLabel="If you are currently a student, select the education level you are pursuing."
                      labelCssClass={'onboarding-form-label'}
                      component={SelectField}
                      width="100%"
                      options={educationLevels.map((opt) => {
                        return {value: opt, label: opt.name};
                      })}
                      showBackground={false}
                      isSearchable={false}
                      placeholder="Select your education level"
                    />
                  </div>
                  <fieldset className="form-group">
                    <legend />
                    <Field
                      name="graduationDate"
                      label="When was your graduation date or expected graduation date?"
                      labelCssClass={'onboarding-form-label'}
                      component={SelectMonthYear}
                      form={form}
                      width="100%"
                      month={parseInt(gradDate[0], 10)}
                      year={parseInt(gradDate[1], 10)}
                      showBackground={false}
                      monthStyle={{flex: '2'}}
                      yearStyle={{flex: '1'}}
                    />
                  </fieldset>
                </div>
                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
EducationLevel.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any,
  educationLevels: PropTypes.array,
  headerImage: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    educationLevels: state.institutions.educationLevels,
  };
}

export default connect(mapStateToProps)(EducationLevel);
