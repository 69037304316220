import * as types from '../actions/ActionTypes';
import {filter, findIndex} from 'lodash';

const initEmployer = {
  accountManager: {
    email: null,
  },
};

const initialState = {
  employers: [],
  employer: initEmployer,
  employerLoading: false,
  allEmployers: [],
  employersLoading: false,
  employersLoaded: false,
  employerCache: {},
  employerCacheLoading: false,
  reports: [],
  applications: [],
  stats: {
    draftEmployers: 0,
    publishedEmployers: 0,
    totalEmployers: 0,
    draftTeams: 0,
    publishedTeams: 0,
    totalTeams: 0,
    draftJobs: 0,
    publishedJobs: 0,
    totalJobs: 0,
  },
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  employerStatsLoading: false,
  statsByEmployer: {
    draftTeams: 0,
    publishedTeams: 0,
    totalTeams: 0,
    draftJobs: 0,
    publishedJobs: 0,
    totalJobs: 0,
    thirtyDayApplicationCount: 0,
    thirtyDayRecentlySavedCount: 0,
  },
  candidateElevationPageLoading: false,
  locations: [],
  locationsLoading: false,
  activeJobs: [],
  activeJobsItemsPerPage: null,
  activeJobsLoading: false,
  activeJobsPage: null,
  activeJobsTotalItems: null,
};

export default function employers(state = initialState, action) {
  switch (action.type) {
    case types.EMPLOYERS__GET_ALL_REQUEST:
      return {...state, allEmployers: [], employersLoading: true};
    case types.EMPLOYERS__GET_ALL_FAILURE:
      return {
        ...state,
        employersLoading: false,
      };
    case types.EMPLOYERS__GET_REQUEST:
      return {...state, employerLoading: true, employer: initEmployer};
    case types.EMPLOYERS__GET_TO_CACHE_REQUEST:
      return {
        ...state,
        employerCacheLoading: true,
      };
    case types.EMPLOYERS__GET_TO_CACHE_SUCCESS:
      const employerCache = {
        ...state.employerCache,
        [action.payload.employerId]: action.response.body,
      };
      return {
        ...state,
        employerCache: employerCache,
        employerCacheLoading: false,
      };
    case types.EMPLOYERS__GET_ALL_SUCCESS:
      return {
        ...state,
        allEmployers: action.response.body.employers,
        employersLoading: false,
      };
    case types.EMPLOYERS__GET_ALL_ADVANCED_REQUEST:
      return {
        ...state,
        employers: [],
        employersLoading: true,
        employersLoaded: false,
      };
    case types.EMPLOYERS__GET_FOR_EDIT_REQUEST:
      return {...state, employerForEdit: undefined};
    case types.EMPLOYERS__GET_ALL_ADVANCED_FAILURE:
      return {...state, employersLoading: true};
    case types.EMPLOYERS__GET_ALL_ADVANCED_SUCCESS:
      return {
        ...state,
        employers: action.response.body.employers,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        employersLoading: false,
        employersLoaded: true,
      };
    case types.EMPLOYERS__GET_SUCCESS:
      return {
        ...state,
        employerLoading: false,
        employer: action.response.body,
      };
    case types.EMPLOYERS__GET_REPORTS_REQUEST:
      return {...state, reports: []};
    case types.EMPLOYERS__GET_FAILURE:
      return {
        ...state,
        employerLoading: false,
      };
    case types.EMPLOYERS__GET_REPORTS_SUCCESS:
      return {...state, reports: action.response.body.reports};
    case types.EMPLOYERS__GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        candidateElevationPageLoading: true,
      };
    case types.EMPLOYERS__GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        candidateElevationPageLoading: false,
      };
    case types.EMPLOYERS__GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: action.response.body.applications,
        candidateElevationPageLoading: false,
      };
    case types.EMPLOYERS__GET_FOR_EDIT_SUCCESS:
      return {...state, employerForEdit: action.response.body};
    case types.EMPLOYERS__GET_STATS_SUCCESS:
      return {...state, stats: action.response.body};
    case types.EMPLOYERS__GET_EMPLOYER_STATS_FAILURE:
      return {
        ...state,
        employerStatsLoading: false,
      };
    case types.EMPLOYERS__GET_EMPLOYER_STATS_REQUEST:
      return {
        ...state,
        employerStatsLoading: true,
      };
    case types.EMPLOYERS__GET_EMPLOYER_STATS_SUCCESS:
      return {
        ...state,
        employerStatsLoading: false,
        statsByEmployer: action.response.body,
      };
    case types.EMPLOYERS__DELETE_SUCCESS:
      return {
        ...state,
        employers: filter(state.employers, (employer) => {
          return employer.id !== action.payload.employerId;
        }),
      };
    case types.EMPLOYERS__CREATE_SUCCESS:
      return {
        ...state,
        employers: [...state.employers, action.response.body],
      };
    case types.EMPLOYERS__UPDATE_SUCCESS:
      const index = findIndex(state.employers, (employer) => {
        return employer.id === action.payload.employer.id;
      });
      return {
        ...state,
        employers: [
          ...state.employers.slice(0, index),
          action.response.body,
          ...state.employers.slice(index + 1),
        ],
        employerCache: {
          ...state.employerCache,
          [action.payload.employer.id]: action.response.body,
        },
      };
    case types.EMPLOYERS__GET_LOCATIONS_REQUEST:
      return {
        ...state,
        locationsLoading: true,
      };
    case types.EMPLOYERS__GET_LOCATIONS_FAILURE:
      return {
        ...state,
        locationsLoading: false,
      };
    case types.EMPLOYERS__GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.response.body.locations,
        locationsLoading: false,
      };
    case types.EMPLOYERS__GET_ACTIVE_JOBS_SUCCESS:
      return {
        ...state,
        activeJobs: action.response.body.jobs,
        activeJobsPage: action.response.body.page,
        activeJobsItemsPerPage: action.response.body.itemsPerPage,
        activeJobsTotalItems: action.response.body.totalItems,
        activeJobsLoading: false,
      };
    case types.EMPLOYERS__GET_ACTIVE_JOBS_REQUEST:
      return {
        ...state,
        activeJobsLoading: true,
      };
    case types.EMPLOYERS__GET_ACTIVE_JOBS_FAILURE:
      return {
        ...state,
        activeJobsLoading: false,
      };

    case types.JOBS__CREATE_SUCCESS:
      const employerIndex = findIndex(state.employers, (employer) => {
        return employer.id === action.response.body.employerId;
      });

      const employer = state.employers[employerIndex];

      if (action.response.body.published) {
        employer.publishedJobCount++; 
      }
      else {
        employer.draftJobCount++;
      } 

      return {
        ...state,
        employers: [
          ...state.employers.slice(0, employerIndex),
          employer,
          ...state.employers.slice(employerIndex + 1),
        ],
      };

    default:
      return state;
  }
}
