import React from 'react';
import {Field, Form} from 'react-final-form';
import {TextField} from 'src/formFields';
import _ from 'lodash';
import {connect} from 'react-redux';
import {required} from '../utils/intake/intakeCommon';
import DocumentTitle from '../components/vendor/DocumentTitle';
import {ChevronRightSvg} from '../assets/svg/svgComponents';
import {makeRequest} from '../services/api';
import {SelectField} from '../formFields';
class ContactPage extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      organization: '',
      title: '',
      contactType: '',
      other: '',
      organizationsList: [],
      showSuccess: 'hide',
      showForm: 'show',
    };
  }

  componentDidMount() {
    this.handleGetOrganizations();
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = [
      'name',
      'title',
      'organization',
      'email',
      'contactType',
    ];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  handleGetOrganizations = async () => {
    const organizations = await this.getOrganizations();
    this.setState({organizationsList: organizations});
  };

  getOrganizations = async () => {
    const response = await makeRequest({
      method: 'GET',
      path: `v2/contact/entities`,
    });
    if (response.status === 200) {
      return response.data.entities.filter((e) => e.type === 'em');
    }
    return [];
  };

  createNewContact = async (newContact) => {
    newContact.type = 'employer';

    const existingEmployerIndex = this.state.organizationsList.findIndex(
      (o) => o.label === newContact.organization
    );
    if (existingEmployerIndex === -1) {
      newContact.other = newContact.organization;
      delete newContact['organization'];
    } else {
      newContact.organization =
        this.state.organizationsList[existingEmployerIndex].value;
    }

    this.setState({showSuccess: 'show', showForm: 'hide'});
    await makeRequest({
      method: 'POST',
      path: 'v2/contact',
      body: newContact,
      isAuthenticated: false,
    });
  };

  render() {
    document.body.style = 'background-color: #ffffff;';

    return (
      <div className="contactForm">
        <DocumentTitle title="Contact">
          <div className="container">
            {this.state.showSuccess === 'show' && (
              <div className={`${this.state.showSuccess}`} aria-hidden="true">
                <div>
                  <br />
                  <p>Thank you! Our team will be in touch soon.</p>
                </div>
              </div>
            )}

            <div className={`row ${this.state.showForm}`}>
              <Form onSubmit={this.createNewContact} validate={this.validate}>
                {({handleSubmit, form}) => {
                  this.formRef.current = form;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="name"
                        component={TextField}
                        label="Name *"
                        labelCssClass={'contact-form-label'}
                        validate={(x) => {
                          if (!x) {
                            return 'Required';
                          }
                          if (x.length < 2) {
                            return 'Name must be at least 2 characters';
                          }
                        }}
                        placeholder="First and Last Name"
                        onChange={(evt) => {
                          this.setState({name: evt.target.value});
                        }}
                      />
                      <Field
                        name="title"
                        component={TextField}
                        label="Title *"
                        labelCssClass={'contact-form-label'}
                        validate={required}
                        onChange={(evt) => {
                          this.setState({title: evt.target.value});
                        }}
                      />

                      <Field
                        name="contactType"
                        label="Contact Type *"
                        initialValue={''}
                        labelCssClass={'contact-form-label'}
                        component={SelectField}
                        width="100%"
                        options={[
                          {label: 'Employer', value: 'Employer'},
                          {
                            label: 'Education Partner',
                            value: 'Education Partner',
                          },
                          {label: 'Job Seeker', value: 'Job Seeker'},
                          {label: 'Other', value: 'Other'},
                        ]}
                      />

                      <Field
                        name="organization"
                        label="Organization or School *"
                        labelCssClass={'contact-form-label'}
                        component={TextField}
                        validate={required}
                        onChange={(evt) => {
                          this.setState({organization: evt.target.value});
                        }}
                      />
                      <Field
                        name="email"
                        component={TextField}
                        inputType="email"
                        label="Email *"
                        labelCssClass={'contact-form-label'}
                        validate={required}
                        onChange={(evt) => {
                          this.setState({email: evt.target.value});
                        }}
                      />

                      <Field
                        name="howDidYouHearAboutUs"
                        label="How did you hear about us?"
                        initialValue={''}
                        labelCssClass={'contact-form-label'}
                        component={SelectField}
                        width="100%"
                        options={[
                          {
                            label: 'Advertising',
                            value: 'Advertising',
                          },
                          {
                            label: 'Colleague or Friend',
                            value: 'Colleague or Friend',
                          },
                          {label: 'Community Group', value: 'Community Group'},
                          {label: 'EmployIndy', value: 'EmployIndy'},
                          {label: 'Event', value: 'Event'},
                          {
                            label: 'News/Press Release',
                            value: 'News or Press Release',
                          },
                          {label: 'Search Engine', value: 'Search Engine'},
                          {label: 'Social Media', value: 'Social Media'},
                          {label: 'Other Website', value: 'Other Website'},
                          {label: 'Other', value: 'Other'},
                        ]}
                      />

                      <button
                        type="submit"
                        className="clickable btn btn-primary-1"
                        style={{fontFamily: 'Georgia', fontWeight: 'bold'}}
                      >
                        Submit &nbsp;&nbsp;
                        <ChevronRightSvg className="svg_color_fill_white" />
                      </button>
                    </form>
                  );
                }}
              </Form>
            </div>
            <div className="row">
              <div className="col-12">&nbsp;</div>
            </div>
            <div className={`row ${this.state.showForm}`}>
              <p>
                Already have an account?{' '}
                <a
                  href="https://network.ascendindiana.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign in.
                </a>
              </p>
            </div>
          </div>
        </DocumentTitle>
      </div>
    );
  }
}

export default connect()(ContactPage);
