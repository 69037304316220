import React from 'react';
import PropTypes from 'prop-types';
import infoBlueCircleSvg from 'src/assets/images/info-blue-circle.svg';

import {Tooltip as ReactTooltip} from 'react-tooltip';

class CheckboxGroupField extends React.Component {
  render() {
    const {
      input,
      meta: {touched, error, warning},
      options,
      columns,
    } = this.props;

    const checkboxes = options.map(({label, value, tips}, index) => {
      const handleChange = (event) => {
        const selected = [...input.value];
        if (event.target.checked) {
          selected.push(value);
        } else {
          selected.splice(selected.indexOf(value), 1);
        }
        return input.onChange(selected);
      };

      const checked = input.value.includes(value);
      return (
        <div
          key={index}
          style={{width: '100%'}}
          className="padding-bottom-eight custom-control custom-checkbox"
        >
          <ReactTooltip
            id="tooltip"
            className="solid-tooltip-dark"
            closeOnEsc={true}
          />

          <input
            type="checkbox"
            id={`${input.name}[${index}]`}
            name={`${input.name}[${index}]`}
            value={value}
            checked={checked}
            onChange={handleChange}
            onFocus={input.onFocus}
            className="custom-control-input"
          />
          <label
            className="custom-control-label"
            htmlFor={`${input.name}[${index}]`}
          >
            {label} &nbsp;
            {tips && (
              <img
                tabIndex={0}
                className="clickable"
                style={{width: '1.25rem'}}
                alt={tips}
                src={infoBlueCircleSvg}
                data-tooltip-id="tooltip"
                data-tooltip-content={tips}
                data-tooltip-place={this.props.dataPlace}
              />
            )}
          </label>
        </div>
      );
    });

    return (
      <div
        style={{
          WebkitColumnCount: columns,
          MozColumnCount: columns,
          columnCount: columns,
          padding: '2px',
        }}
      >
        {checkboxes}
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    );
  }
}

CheckboxGroupField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      tip: PropTypes.string,
    })
  ).isRequired,
  columns: PropTypes.number,
  dataPlace: PropTypes.string,
};

CheckboxGroupField.defaultProps = {
  columns: 2,
};

export default CheckboxGroupField;
