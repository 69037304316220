import React from 'react';
import loadingGif from '../../assets/images/loading.gif';
class LoadingCard extends React.Component {
  render() {
    return (
      <div className="bd-whitebackground padding-20">
        <div className="text-align-center fullwidth height640 bgcolorw">
          <br />
          <br />
          <br />
          <img src={loadingGif} height="200" width="200" alt="Loading..." />
          <p role="status">Loading...</p>
        </div>
      </div>
    );
  }
}

LoadingCard.propTypes = {};

export default LoadingCard;
