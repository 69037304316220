import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import AscendDataTable from 'src/components/AscendDataTable';

import explorerStyles from './UniversityExplorerPage.module.scss';

import {ArrowDownSvg, ArrowUpSvg} from 'src/assets/svg/svgComponents';

export default function UniversityExplorerTable({
  queryResults,
  columnHeaders,
  fieldMap,
}) {
  const [sortedQueryResults, setSortedQueryResults] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortAscending, setSortAscending] = useState(true);

  const sortResults = useCallback(
    (data, newSortBy, newSortAscending) => {
      let sortableData = [...data];

      if (newSortBy === null) {
        newSortBy = columnHeaders.includes(sortBy) ? sortBy : 'count';
      }

      if (newSortAscending === null) {
        newSortAscending = columnHeaders.includes(sortBy)
          ? sortAscending
          : false;
      }

      if (newSortBy === 'graduationDate') {
        sortableData.forEach(
          (x) => (x['graduationDateValue'] = new Date(x['graduationDate']))
        );
        newSortBy = 'graduationDateValue';
      }

      sortableData.sort((a, b) => {
        if (a[newSortBy] < b[newSortBy]) {
          return newSortAscending ? -1 : 1;
        }
        if (a[newSortBy] > b[newSortBy]) {
          return newSortAscending ? 1 : -1;
        }
        return 0;
      });

      setSortBy(newSortBy);
      setSortAscending(newSortAscending);
      setSortedQueryResults(sortableData);
    },
    [columnHeaders, sortBy, sortAscending]
  );

  useEffect(() => {
    sortResults(queryResults, null, null);
  }, [queryResults, columnHeaders, sortResults]);

  const handleSortChange = (newSortBy, newSortAscending) => {
    sortResults(sortedQueryResults, newSortBy, newSortAscending);
  };

  const columns = columnHeaders.map((col) => {
    const fieldMapMatch = fieldMap.find((field) => field.value === col);
    if (fieldMapMatch) {
      return {
        displayName: fieldMapMatch.display,
        content: (queryResults) => queryResults[col],
        sortBy: fieldMapMatch.value,
      };
    } else {
      return {
        displayName: col,
        content: (queryResults) => queryResults[col],
      };
    }
  });

  return (
    <div
      className={`${explorerStyles.universityExplorerVisualizationTableContainer}`}
    >
      <AscendDataTable
        tableColumns={columns}
        items={sortedQueryResults}
        enableSort={true}
        showSearch={false}
        showPageControl={false}
        enableExpand={false}
        sortBy={sortBy}
        sortAscending={sortAscending}
        handleSortChange={handleSortChange}
        alternateSortImageAsc={
          <ArrowUpSvg
            className={`${explorerStyles.universityExplorerVisualizationTableSort}`}
          />
        }
        alternateSortImageDesc={
          <ArrowDownSvg
            className={`${explorerStyles.universityExplorerVisualizationTableSort}`}
          />
        }
      />
    </div>
  );
}

UniversityExplorerTable.propTypes = {
  queryResults: PropTypes.array,
  columnHeaders: PropTypes.arrayOf(PropTypes.string),
  fieldMap: PropTypes.arrayOf(PropTypes.object),
};
