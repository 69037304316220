import React, {useEffect, useReducer} from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {useDispatch, useSelector} from 'react-redux';
import {OnChange} from 'react-final-form-listeners';
import PageFooter from '../components/PageFooter';
import {SelectField, TextField} from 'src/formFields';
import {majorLabel, minorLabel} from 'src/utils/intake/intake2Lists';
import {getCampuses, getSchools} from 'src/actions/institutions';
import {gpas, graduationMonths} from 'src/utils/fieldHelper';
import PageHeader from '../components/PageHeader';
import {FieldArray} from 'react-final-form-arrays';
import SelectFieldArray from 'src/formFields/SelectFieldArray';

const initialEducationDetailsState = {
  showOtherInstitutionField: false,
  showMajors: false,
  showMinors: false,
  showInstitution: true,
};

const EDUCATION_ACTIONS = {
  EDUCATION_LEVEL_CHANGED: 'EDUCATION_ACTIONS_EDUCATION_LEVEL_CHANGED',
  INSTITUTION_CHANGED: 'EDUCATION_ACTIONS_INSTITUTION_CHANGED',
};

function educationLevelReducer(state, action) {
  switch (action.type) {
    case EDUCATION_ACTIONS.EDUCATION_LEVEL_CHANGED:
      return {
        ...state,
        showOtherInstitutionField: action.showOtherSchoolInput,
        showMajors: action.showMajors,
        showMinors: action.showMinors,
        showInstitution: action.showInstitution,
      };
    case EDUCATION_ACTIONS.INSTITUTION_CHANGED:
      return {
        ...state,
        showOtherInstitutionField: action.isOtherInstitution,
      };
    default:
      return state;
  }
}

export default function EducationDetails({
  next,
  previous,
  intakeCache,
  isMobile,
  headerImage,
}) {
  const reduxDispatch = useDispatch();
  const [educationDetailsState, educationDetailsDispatch] = useReducer(
    educationLevelReducer,
    initialEducationDetailsState
  );

  const {
    campuses,
    fetchSchoolsForSingleCampus,
    schools,
    schoolsLoading,
    institutions,
    majors,
    minors,
    educationLevels,
  } = useSelector((state) => state.institutions);

  const graduationYears = [];
  for (let i = 1900; i < new Date().getFullYear() + 8; i++) {
    graduationYears.push(`${i}`);
  }
  const otherInstitution = institutions.find((x) => x.name === 'Other');
  const otherInstitutionId =
    otherInstitution !== undefined ? otherInstitution.id : null;

  useEffect(() => {
    const initLocalState = () => {
      if (intakeCache.educationLevel !== undefined) {
        educationDetailsDispatch({
          type: EDUCATION_ACTIONS.EDUCATION_LEVEL_CHANGED,
          ...intakeCache.educationLevel,
          showOtherSchoolInput:
            intakeCache.educationLevel.showOtherSchoolInput ||
            intakeCache.institutionId === otherInstitutionId,
        });
      }
    };

    initLocalState();
  }, [
    educationLevels,
    intakeCache.educationLevel,
    intakeCache.institutionId,
    otherInstitutionId,
  ]);

  useEffect(() => {
    if (fetchSchoolsForSingleCampus === true && !schoolsLoading) {
      reduxDispatch(getSchools(campuses[0].id));
    }
  }, [campuses, reduxDispatch, fetchSchoolsForSingleCampus, schoolsLoading]);

  const validate = (values) => {
    const errors = {};
    const requiredFields = [
      'educationLevel',
      'graduationMonth',
      'graduationYear',
      'gpa',
    ];
    if (
      values.educationLevel !== undefined &&
      values.educationLevel.showInstitution
    ) {
      requiredFields.push('institutionId');
    }
    if (
      values.educationLevel !== undefined &&
      educationDetailsState.showInstitution &&
      schools.length > 1
    ) {
      requiredFields.push('schoolId');
    }
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  let graduationMonth = intakeCache?.graduationMonth;
  let graduationYear = intakeCache?.graduationYear;
  const graduationDate = intakeCache.graduationDate || '';
  if (graduationMonth === undefined || graduationMonth === null) {
    const monthIndex = Number(graduationDate.split('/')[0]);
    graduationMonth = graduationMonths[monthIndex];
  }
  if (graduationYear === undefined || graduationYear === null) {
    graduationYear = graduationDate.split('/')[1];
  }

  return (
    <>
      <PageHeader
        title="Add your most recent education"
        headerImage={headerImage}
      />
      <Form
        onSubmit={next}
        validate={validate}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, {changeValue}) => {
            changeValue(state, field, () => value);
          },
        }}
        keepDirtyOnReinitialize={true}
        initialValues={{
          educationLevel: intakeCache?.educationLevel,
          majorIds: intakeCache?.majorIds || [-1],
          minorIds: intakeCache?.minorIds,
          institutionId: intakeCache.institutionId,
          otherInstitution: intakeCache.otherInstitution,
          campusId: intakeCache?.campusId,
          schoolId: intakeCache?.schoolId,
          graduationMonth: graduationMonth,
          graduationYear: graduationYear,
          gpa: intakeCache?.gpa,
        }}
      >
        {({handleSubmit, form}) => {
          let maxMajorMinorFields =
            form.getState().values.educationLevel.maxMajors || 3;

          return (
            <form onSubmit={handleSubmit}>
              <div className={`${styles.pageContent}`}>
                <OnChange name="educationLevel">
                  {(value, previous) => {
                    if (value) {
                      educationDetailsDispatch({
                        type: EDUCATION_ACTIONS.EDUCATION_LEVEL_CHANGED,
                        ...value,
                      });

                      if (!value.showInstitution) {
                        form.change('institutionId', null);
                        form.change('campusId', null);
                        form.change('schoolId', null);
                      }
                    }
                  }}
                </OnChange>

                <OnChange name="institutionId">
                  {(value) => {
                    if (value) {
                      const isOtherInstitution = value === otherInstitutionId;
                      if (!isOtherInstitution) {
                        form.change('otherInstitution', null);
                      }
                      educationDetailsDispatch({
                        type: EDUCATION_ACTIONS.INSTITUTION_CHANGED,
                        isOtherInstitution,
                      });
                      reduxDispatch(getCampuses(value));
                      form.change('campusId', null);
                      form.change('schoolId', null);
                    }
                  }}
                </OnChange>

                <OnChange name="campusId">
                  {(value) => {
                    if (value) {
                      reduxDispatch(getSchools(value));
                      form.change('schoolId', null);
                    }
                  }}
                </OnChange>

                <div className={styles.educationDetails}>
                  <Field
                    name="educationLevel"
                    label="Degree Type"
                    labelCssClass={'onboarding-form-label'}
                    component={SelectField}
                    options={educationLevels.map((opt) => {
                      return {value: opt, label: opt.name};
                    })}
                  />

                  {educationDetailsState.showMajors && (
                    <FieldArray
                      name="majorIds"
                      component={SelectFieldArray}
                      options={majors.map((opt) => {
                        return {
                          value: opt.id,
                          label: opt.name,
                          clinicalMajor: opt.clinicalMajor,
                        };
                      })}
                      labelMaker={(index) => {
                        const fieldsOfStudy = educationLevels
                          .filter((ieo) => ieo.useFieldsOfStudyLabel === true)
                          .map((ieo) => ieo.id);
                        if (
                          fieldsOfStudy.includes(
                            form.getState().values.educationLevel.id
                          )
                        ) {
                          return 'Field of Study';
                        }
                        return majorLabel(index);
                      }}
                      maxFields={maxMajorMinorFields}
                      addButtonDescription={'add another major'}
                      validate={(value) => {
                        if (value.length === 0) {
                          return 'Required';
                        }

                        for (const majorId of value) {
                          if (majorId === -1) {
                            return 'Required';
                          }
                          if (majorId === undefined) {
                            return 'Required';
                          }
                        }

                        return undefined;
                      }}
                    />
                  )}
                  {educationDetailsState.showMinors && (
                    <FieldArray
                      name="minorIds"
                      component={SelectFieldArray}
                      options={minors.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      labelMaker={minorLabel}
                      maxFields={maxMajorMinorFields}
                      addButtonDescription={'add another minor'}
                    />
                  )}

                  {educationDetailsState.showInstitution && (
                    <Field
                      name="institutionId"
                      component={SelectField}
                      options={institutions.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      label="School"
                      labelCssClass={'onboarding-form-label'}
                    />
                  )}

                  {educationDetailsState.showOtherInstitutionField && (
                    <div className="col-md-6">
                      <Field
                        name="otherInstitution"
                        label="Type your school here"
                        component={TextField}
                        props={{
                          disabled:
                            educationDetailsState.showOtherInstitutionField,
                        }}
                        labelCssClass={'onboarding-form-label'}
                      />
                    </div>
                  )}

                  {educationDetailsState.showInstitution &&
                    campuses.length > 1 && (
                      <Field
                        name="campusId"
                        component={SelectField}
                        options={campuses.map((opt) => {
                          return {value: opt.id, label: opt.name};
                        })}
                        label="Campus"
                        labelCssClass={'onboarding-form-label'}
                      />
                    )}

                  {educationDetailsState.showInstitution && schools.length > 1 && (
                    <Field
                      name="schoolId"
                      component={SelectField}
                      options={schools.map((opt) => {
                        return {value: opt.id, label: opt.name};
                      })}
                      label="College"
                      labelCssClass={'onboarding-form-label'}
                    />
                  )}

                  <Field
                    name="graduationMonth"
                    component={SelectField}
                    options={graduationMonths.slice(1).map((opt) => {
                      return {value: opt, label: opt};
                    })}
                    label="Graduation Month"
                    labelCssClass={'onboarding-form-label'}
                  />

                  <Field
                    name="graduationYear"
                    component={SelectField}
                    options={graduationYears.map((opt) => {
                      return {value: opt, label: opt};
                    })}
                    label="Graduation Year"
                    labelCssClass={'onboarding-form-label'}
                  />

                  <Field
                    name="gpa"
                    label="GPA"
                    component={SelectField}
                    options={gpas.map((opt) => {
                      return {value: opt, label: opt};
                    })}
                    labelCssClass={'onboarding-form-label'}
                  />
                </div>
              </div>
              <PageFooter
                next={() => form.submit()}
                previous={previous}
                getValues={() => form.getState().values}
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}

EducationDetails.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.object,
  isMobile: PropTypes.bool,
  headerImage: PropTypes.any,
};
