import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Field, Form} from 'react-final-form';
import {TextField, SelectField} from '../../../formFields';
import renderSelectField from '../../controls/SelectFieldRenderer';
import {
  genders,
  ethnicities,
  gradeLevels,
  highSchoolGraduationYears,
  graduationMonths,
  gpas,
} from '../../../utils/fieldHelper';
import track from 'react-tracking';
import {PhoneNumberInput} from '../../PhoneNumberInput';
import {connect} from 'react-redux';
import _ from 'lodash';
import {highSchoolListAction} from '../../../actions/schoolActions';
import {OnChange} from 'react-final-form-listeners';
import ApprenticePathwaySelect from '../../ApprenticePathwaySelect';

const required = (value) => (value ? undefined : 'Required');
const validate = (fields, values) => {
  const errors = {};
  _.forEach(fields, (field) => {
    if (!values[field] || values[field] === null) {
      errors[field] = 'Required';
    }
  });

  if (
    !values['apprenticePathwayIds'] ||
    values['apprenticePathwayIds'].length === 0
  ) {
    errors['apprenticePathways'] =
      'Please select one or more pathways you are interested in.';
  }

  return errors;
};

const validateForm = (values) => {
  let fields = [
    'firstName',
    'lastName',
    'phone',
    'gradeLevel',
    'highSchool',
    'gpa',
    'gender',
    'ethnicity',
    'graduationYear',
    'graduationMonth',
  ];

  if (values['highSchool'] === 'Other') {
    fields.push('otherInstitution');
  }

  return validate(fields, values);
};
const hasNumbers = (t) => {
  let regex = /\d/g;
  return regex.test(t);
};

const validatePhoneInput = (value) => {
  if (hasNumbers(value) === false) {
    return 'Required';
  }
  if (hasNumbers(value) && value.trim().length < 14) {
    return 'Must be 10 digit number';
  }
  return undefined;
};

@track({eventName: 'ModernApprenticeshipBasicInfo'}, {dispatchOnMount: true})
class MaUserInfoEditForm extends React.Component {
  state = {
    showOtherInstitutionField: false,
    indianaHighSchools: [],
    selectedHighSchoolId: null,
  };

  componentDidMount() {
    this.setState(() => ({
      showOtherInstitutionField:
        this.props.otherInstitutionSelected ||
        this.props.seeker.highSchool === 'Other',
    }));

    highSchoolListAction(this.props.segmentId).then((ret) => {
      this.setState({indianaHighSchools: ret});
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.highSchool !== this.props.highSchool) {
      this.setState(() => ({
        showOtherInstitutionField: this.props.highSchool === 'Other',
      }));
    }
  }

  handleApprenticePathwayChange = (value, form) => {
    const apprenticePathwayList = value.map((e) => e.value);
    form.change('apprenticePathwayIds', apprenticePathwayList);

    this.setState({apprenticePathways: value});
  };

  buildPathwayValues = () => {
    const {seeker} = this.props;
    const {apprenticePathways} = this.state;

    let values =
      apprenticePathways !== undefined
        ? apprenticePathways
        : seeker.apprenticePathways
        ? seeker.apprenticePathways
        : '';

    return values;
  };

  render() {
    const {isMobile} = this.props;

    return (
      <Fragment>
        <Fragment>
          <div className="bd-graybackground row justify-content-center">
            <div className="normal-title bold col-md-4">Edit Information</div>
          </div>
          <hr />
        </Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="bd-whitebackground" style={{padding: '48px'}}>
              <Form
                onSubmit={this.props.onSubmit}
                validate={validateForm}
                keepDirtyOnReinitialize={true}
                initialValues={{
                  firstName: this.props.seeker.firstName,
                  lastName: this.props.seeker.lastName,
                  email: this.props.seeker.email,
                  phone: this.props.seeker.phone,
                  graduationMonth: this.props.seeker.graduationMonth,
                  graduationYear: this.props.seeker.graduationYear,
                  gpa: this.props.seeker.gpa,
                  gradeLevel: this.props.seeker.gradeLevel,
                  highSchool: this.props.seeker.highSchool,
                  highSchoolId: this.state.selectedHighSchoolId
                    ? this.state.selectedHighSchoolId
                    : this.props.seeker.highSchoolId,
                  otherInstitution: this.props.seeker.otherInstitution,
                  gender: this.props.seeker.gender || 'NULL',
                  ethnicity: this.props.seeker.ethnicity || 'NULL',
                  apprenticePathways: this.props.seeker.apprenticePathways,
                  apprenticePathwayIds:
                    this.state.apprenticePathways !== undefined
                      ? this.state.apprenticePathways.map((x) => x.value)
                      : this.props.seeker.apprenticePathways.map(
                          (x) => x.value
                        ),
                }}
              >
                {({handleSubmit, form}) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <OnChange name="highSchool">
                        {(newValue) => {
                          const otherSelected = newValue === 'Other';
                          const selectedHighSchool =
                            this.state.indianaHighSchools.find(
                              (x) => x.value === newValue
                            );

                          this.setState(() => ({
                            showOtherInstitutionField: otherSelected,
                            selectedHighSchoolId: selectedHighSchool.id,
                          }));
                          form.change('otherInstitution', null);

                          this.handleApprenticePathwayChange([], form);
                        }}
                      </OnChange>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="firstName"
                                component={TextField}
                                size={32}
                                label="First Name"
                                maxLength={128}
                                showBackground={true}
                                validate={required}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="lastName"
                                component={TextField}
                                size={32}
                                label="Last Name"
                                maxLength={128}
                                showBackground={true}
                                validate={required}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="email"
                                component={TextField}
                                size={32}
                                label="Email"
                                disabled={true}
                                maxLength={128}
                                showBackground={true}
                                validate={required}
                                disabletips={true}
                                showInfoIcon={false}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'If you do not have an email, use a parent or legal guardian’s email instead.'
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="phone"
                                component={PhoneNumberInput}
                                size={32}
                                label="Phone Number"
                                maxLength={128}
                                showBackground={true}
                                validate={validatePhoneInput}
                                disabletips={true}
                                showInfoIcon={false}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'If you do not have a phone, use a parent or legal guardian’s phone instead.'
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="graduationMonth"
                                title="Graduation Month"
                                labelBold={false}
                                padLabel={false}
                                component={renderSelectField}
                                options={graduationMonths
                                  .slice(1)
                                  .map((opt) => {
                                    return {value: opt, label: opt};
                                  })}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="graduationYear"
                                component={renderSelectField}
                                title="Graduation Year"
                                labelBold={false}
                                padLabel={false}
                                options={highSchoolGraduationYears().map(
                                  (opt) => {
                                    return {value: opt, label: opt};
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="gpa"
                                component={renderSelectField}
                                title="GPA"
                                labelBold={false}
                                padLabel={false}
                                options={gpas.map((opt) => {
                                  return {value: opt, label: opt};
                                })}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="gradeLevel"
                                component={renderSelectField}
                                title="Grade Level"
                                labelBold={false}
                                padLabel={false}
                                options={gradeLevels.map((opt) => ({
                                  value: opt.value,
                                  label: opt.label,
                                }))}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="highSchool"
                                component={SelectField}
                                label="High School"
                                labelBold={false}
                                padLabel={false}
                                message="Don't see your high school? Select 'Other.'"
                                isDisabled={
                                  this.state.indianaHighSchools.length === 0
                                }
                                options={this.state.indianaHighSchools.map(
                                  (opt) => ({
                                    value: opt.value,
                                    label: opt.name,
                                  })
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="otherInstitution"
                                label="Enter your high school"
                                component={TextField}
                                showBackground={true}
                                visible={this.state.showOtherInstitutionField}
                                props={{
                                  disabled:
                                    !this.state.showOtherInstitutionField,
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field name="apprenticePathways">
                                {(props) => (
                                  <>
                                    <ApprenticePathwaySelect
                                      name={props.input.name}
                                      valueObjects={this.buildPathwayValues()}
                                      onChange={(e) => {
                                        this.handleApprenticePathwayChange(
                                          e,
                                          form
                                        );
                                      }}
                                      isMulti={true}
                                      highSchoolId={
                                        form.getState().values.highSchoolId !==
                                        null
                                          ? form.getState().values.highSchoolId
                                          : undefined
                                      }
                                      hasRequestedHighSchool={
                                        form.getState().values.highSchoolId !==
                                        null
                                      }
                                      validate={required}
                                      meta={props.meta}
                                    >
                                      <div
                                        className="secondary-body"
                                        id="selectFieldLabel"
                                      >
                                        Apprentice Pathway(s)
                                      </div>
                                    </ApprenticePathwaySelect>
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="gender"
                                component={renderSelectField}
                                title="Gender"
                                labelBold={false}
                                padLabel={false}
                                options={genders.map((opt) => ({
                                  value: opt.value,
                                  label: opt.label,
                                }))}
                                disabletips={true}
                                showInfoIcon={false}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'We will not share this information with employers. <br/>' +
                                  'It is used for aggregate reporting purposes only.'
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="ethnicity"
                                component={renderSelectField}
                                title="Ethnicity"
                                labelBold={false}
                                padLabel={false}
                                options={ethnicities.map((opt) => ({
                                  value: opt.value,
                                  label: opt.label,
                                }))}
                                disabletips={true}
                                showInfoIcon={false}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'We will not share this information with employers. <br/>' +
                                  'It is used for aggregate reporting purposes only.'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row padding-top-sixteen ">
                        <div
                          className={`col-md-12 ${
                            isMobile ? 'd-flex justify-content-center' : ''
                          } `}
                        >
                          <button
                            type="submit"
                            style={{width: '210px', height: '40px'}}
                            className={`clickable btn btn-primary ${
                              isMobile ? '' : 'float-right'
                            }`}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

MaUserInfoEditForm.propTypes = {
  onSubmit: PropTypes.func,
  previousPage: PropTypes.func,
  segment: PropTypes.string,
  isMobile: PropTypes.bool,
  onCancel: PropTypes.func,
  otherInstitutionSelected: PropTypes.bool,
  highSchool: PropTypes.string,
  segmentId: PropTypes.number,
  seeker: PropTypes.object,
};

MaUserInfoEditForm.defaultProps = {
  previousPage: null,
  segment: '',
  isMobile: false,
  otherInstitutionSelected: false,
};

const mapStateToProps = (state) => {
  const seeker = state.seekers.seekerForEdit;
  return {
    seeker: seeker,
    segmentId: seeker.segments[0].id, // MA should only have one segment
  };
};

export default connect(mapStateToProps)(MaUserInfoEditForm);
