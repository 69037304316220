import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useNavigate, useMatch} from 'react-router-dom';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import _ from 'lodash';
import 'url-search-params-polyfill';

import * as seekersActions from 'src/actions/seekers';
import * as jobsActions from 'src/actions/jobs';
import * as allJobSearchActions from 'src/actions/allJobSearch';

import JobProfile from 'src/components/JobProfile';
import {PageFooter, PageHeader} from 'src/layout';
import {jobIsWatched, jobIsApplied} from 'src/utils/jobHelper';
import {findMatchData} from 'src/utils/matchHelper';
import NoRoles from 'src/components/NoRoles';
import {includesApprenticeProgram} from '../../utils/miscHelper';

import styles from './AllJobsPage.module.scss';
import PageControl from 'src/components/PageControl';
import Select from 'react-select';
import AdvancedJobCard from 'src/components/AdvancedJobCard';
import AllJobsFilters from './AllJobsFilters';
import InputSearch from 'src/components/controls/inputs/InputSearch';
import {getEmployer} from 'src/actions/employers';
import {CompanySvg} from 'src/assets/svg/svgComponents';
import {CreateJobForm} from 'src/forms/JobForm';
import ContentLoader from 'react-content-loader';
import {useIsMobile} from 'src/utils/commonHooks';

export default function AllJobsPage() {
  const isMobile = useIsMobile(430);
  const [jobProfileOpen, setJobProfileOpen] = useState(false);
  const [sortDropdownLabel, setSortDropdownLabel] = useState('Newest First');
  const [jobId, setJobId] = useState(null);
  const [filterList, setFilterList] = useState([
    {id: 1, label: 'Newest First'},
  ]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [sortByStyles] = useState({
    indicatorsContainer: (styles) => ({
      ...styles,
      marginLeft: '-7px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'var(--neutral-gray-1)',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    singleValue: (styles, {isFocused}) => ({
      ...styles,
      color: 'var(--secondary-2)',
      fontWeight: 'bold',
      paddingRight: '5px',
      width: '100%',
      '&:hover': {
        color: 'var(--primary-accessibility)',
        cursor: 'pointer',
      },
    }),

    control: (styles) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: 'var(--neutral-gray-5)',
      width: '250px',
    }),
    option: (styles, {isDisabled, isFocused}) => {
      const getBackgroundColor = () => {
        if (isFocused) return 'var(--secondary-2)';
        if (isDisabled) return null;

        return 'var(--neutral-white)';
      };
      const getForeColor = () => {
        if (isFocused) return 'var(--neutral-white)';
        if (isDisabled) return '#BEBEBE';

        return 'secondary-subheader';
      };
      return {
        ...styles,
        backgroundColor: getBackgroundColor(),
        color: getForeColor(),

        cursor: () => {
          if (isFocused) return 'pointer';
          if (isDisabled) return 'not-allowed';

          return 'default';
        },
      };
    },
  });
  const [isJobFormOpen, setIsJobFormOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let match = useMatch('/all_roles/:employerId');

  let {
    loggedInSeekerId,
    allJobSearchProps,
    seeker,
    seekerLoading,
    jobCache,
    isApprenticeProgram,
    role,
    employer,
  } = useSelector((state) => {
    return {
      loggedInSeekerId: state.profile.seekerId,
      allJobSearchProps: state.allJobSearch,
      seeker: state.seekers.seeker,
      seekerLoading: state.seekers.seekerLoading,
      jobCache: state.jobs.jobCache,
      isApprenticeProgram: state.profile.isApprenticeProgram,
      role: state.profile.role,
      employer: state.employers.employer,
    };
  });

  const isSeeker = role === 'JOB_SEEKER';
  const watchedJobIds = seeker.watchedJobs.map((job) => job.id);

  const isLoading = (seekerOnlyLoading = false) => {
    if (!seekerOnlyLoading) {
      return allJobSearchProps.jobsLoading || seekerLoading;
    }

    return seekerLoading;
  };

  const init = () => {
    const employerId = match.params.employerId;
    if (
      (isFirstLoad &&
        allJobSearchProps.jobs.length === 0 &&
        !allJobSearchProps.jobsLoading) ||
      allJobSearchProps.selectedEmployerId !== employerId
    ) {
      dispatch(
        allJobSearchActions.getEmployerJobs(employerId, {
          jobsSortTerm: isSeeker ? '-roleRecommendationPercentage' : '',
        })
      );
    }

    if (!employer || employer.id !== employerId) {
      dispatch(getEmployer(employerId));
    }

    if (isFirstLoad && isSeeker && loggedInSeekerId) {
      dispatch(seekersActions.getSeeker(loggedInSeekerId));
      setFilterList([
        {id: 1, label: 'Recommended'},
        {id: 2, label: 'Newest First'},
      ]);
      setSortDropdownLabel('Recommended');
    }
    setIsFirstLoad(false);
  };

  if (isFirstLoad) {
    init();
  }

  let tableControlLabelText = `${allJobSearchProps.jobsTotalItems}`;
  if (allJobSearchProps.jobsTotalItems === 1) {
    tableControlLabelText += ' role';
  } else {
    tableControlLabelText += ' roles';
  }

  const handleJobPageChange = (newPage) => {
    dispatch(
      allJobSearchActions.changeAllJobPageAndSearch(
        match.params.employerId,
        newPage
      )
    );
  };

  const handleJobClicked = (id) => {
    dispatch(jobsActions.getJobToCache(id));
    setJobId(id);

    setJobProfileOpen(true);
  };

  const handleFilterChange = (newSearchTerm, search = true) => {
    if (newSearchTerm || newSearchTerm === '') {
      dispatch(
        allJobSearchActions.updateAllJobSearchState({
          jobsSearchTerm: newSearchTerm,
        })
      );
    }
    if (search) {
      dispatch(
        allJobSearchActions.changeAllFilterTagAndSearch(
          match.params.employerId,
          newSearchTerm
        )
      );
    }
  };

  const handleJobWatchClicked = (job) => {
    if (_.includes(watchedJobIds, job.id)) {
      dispatch(jobsActions.deleteJobWatch(job.id));
    } else {
      dispatch(jobsActions.createJobWatch(job));
    }
  };

  const handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  const handleJobSortDropdownChange = (sortBy) => {
    let sortTerm = '';
    if (sortBy.label === 'Recommended') {
      sortTerm = '-roleRecommendationPercentage';
    } else if (sortBy.label === 'Newest First') {
      sortTerm = '-publisheddate'; // publishedAt descending
    }
    dispatch(
      allJobSearchActions.changeAllJobSortAndSearch(
        match.params.employerId,
        sortTerm
      )
    );
    setSortDropdownLabel(sortBy.label);
  };

  const handleProfileClosed = () => {
    setJobProfileOpen(false);
  };

  const handleJobCreateSubmit = (job) => {
    const employerId = match.params.employerId;
    dispatch(jobsActions.createJob({...job, employerId}));
    setIsJobFormOpen(false);
  };

  const renderContentLoader = (index) => {
    if (isMobile) {
      return (
        <ContentLoader
          key={`Loader-${index}`}
          speed={3}
          height={300}
          width={'100%'}
          backgroundColor="#f1f4f9"
          foregroundColor="#cbe1ff"
          style={{
            marginBottom: '20px',
            backgroundColor: '#ffffff',
          }}
        >
          <rect x="5" y="5" rx="0" ry="0" width="97%" height="210" />
          <rect x="10" y="225" rx="3" ry="3" width="260" height="22" />
          <rect x="30" y="255" rx="3" ry="3" width="240" height="15" />
          <rect x="30" y="278" rx="3" ry="3" width="240" height="15" />
        </ContentLoader>
      );
    } else {
      return (
        <ContentLoader
          key={`Loader-${index}`}
          speed={3}
          height={165}
          width={'100%'}
          backgroundColor="#f1f4f9"
          foregroundColor="#cbe1ff"
          style={{
            marginBottom: '18px',
            backgroundColor: '#ffffff',
          }}
        >
          <rect x="5" y="5" rx="0" ry="0" width="270" height="154" />
          <rect x="290" y="18" rx="3" ry="3" width="30%" height="22" />
          <rect x="310" y="50" rx="3" ry="3" width="37%" height="15" />
          <rect x="310" y="75" rx="3" ry="3" width="37%" height="15" />
        </ContentLoader>
      );
    }
  };

  const renderContentLoaders = () => {
    const contentLoaders = [];
    for (var i = 0; i < 4; i++) {
      contentLoaders.push(renderContentLoader(i));
    }
    return contentLoaders;
  };

  return (
    <DocumentTitle title="All Roles">
      <div className="wholepage">
        {allJobSearchProps.accessProhibitedError && (
          <div className={styles.accessProhibitedMessage}>
            Access Prohibited. You do not have permission to view other
            employer's jobs.
          </div>
        )}
        <PageHeader />

        {role === 'ASCEND_RECRUITER' && (
          <CreateJobForm
            onSubmit={handleJobCreateSubmit}
            isOpen={isJobFormOpen}
            onClose={() => setIsJobFormOpen(false)}
            employerId={match.params.employerId}
          />
        )}

        <div className="container">
          {seeker.id && seeker.published === false && (
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="padding-top-twentyfour padding-bottom-twentyfour">
                    <Link to={`/profile`}>
                      <NoRoles />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          <>
            {jobCache[jobId] && jobCache[jobId].id && (
              <JobProfile
                key={jobCache[jobId].id}
                isOpen={jobProfileOpen}
                job={jobCache[jobId]}
                matchData={findMatchData(jobId, allJobSearchProps.jobs)}
                onClose={handleProfileClosed}
                watched={_.includes(watchedJobIds, jobCache[jobId].id)}
                applied={jobIsApplied(jobId, seeker)}
                onAppliedChanged={(applied) =>
                  handleAppliedChanged(jobId, applied)
                }
                onWatchChanged={(watched) =>
                  handleJobWatchClicked(jobCache[jobId])
                }
              />
            )}

            <div
              id={styles.main_content}
              className={
                isApprenticeProgram ? 'row justify-content-center' : 'row'
              }
            >
              {employer && (
                <div className="col-lg-12 padding-bottom-thirtytwo">
                  <div
                    className="normal-display bold"
                    style={{lineHeight: 1.5, color: '#373839'}}
                  >
                    Jobs at {employer.name}
                  </div>
                  <div>
                    <span>
                      <CompanySvg /> &nbsp;
                      <Link
                        style={{fontFamily: 'Manrope'}}
                        className={`accessibleLink buttonAsLink ${styles.greyText}`}
                        to={`/employer/${match.params.employerId}`}
                      >
                        {employer.name}
                      </Link>
                    </span>
                  </div>
                </div>
              )}
              <AllJobsFilters
                employerId={match.params.employerId}
                isSeeker={isSeeker}
                isMaSeeker={isApprenticeProgram}
                isRecruiter={
                  role === 'COMPANY_RECRUITER' ||
                  role === 'COMPANY_RECRUITER_ADMIN'
                }
              />
              <div className="col-lg-9">
                {!allJobSearchProps.accessProhibitedError && isLoading() && (
                  <div>{renderContentLoaders()}</div>
                )}
                {allJobSearchProps.resultTypeTags.showJobs.selected && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h2 className="normal-title padding-bottom-sixteen">
                        <strong>Roles </strong>
                        <span id={styles.mobile_job_count}>
                          ({allJobSearchProps.jobsTotalItems})
                        </span>
                      </h2>
                      {role === 'ASCEND_RECRUITER' && (
                        <button
                          onClick={() => setIsJobFormOpen(true)}
                          className="btn btn-primary"
                        >
                          Post a Job
                        </button>
                      )}
                      {(role === 'COMPANY_RECRUITER' ||
                        role === 'COMPANY_RECRUITER_ADMIN') && (
                        <button
                          onClick={() => navigate('/post_a_role')}
                          className="btn btn-primary"
                        >
                          Post a Job
                        </button>
                      )}
                    </div>
                    <div id={styles.job_discovery_match_cards}>
                      <div className={`${styles.search_and_sort}`}>
                        <div className={styles.search_input}>
                          <InputSearch
                            aria-label="placeholder"
                            id="search-input"
                            style={{backgroundColor: 'white'}}
                            value={allJobSearchProps.jobsSearchTerm}
                            onChange={(e) => {
                              handleFilterChange(e, false);
                            }}
                            onEnterKey={(e) => {
                              handleFilterChange(e);
                            }}
                            placeHolder={
                              'Search by title, company, or description'
                            }
                          />
                        </div>
                        <div
                          className={styles.clear_search}
                          id={styles.desktop_clear_search}
                        >
                          <button
                            className={`pt-1 ps-1 ${styles.clearAllFiltersButton}`}
                            onClick={() => handleFilterChange('')}
                          >
                            <span>Clear Search</span>
                          </button>
                        </div>
                        <div className={styles.sort_search}>
                          <div>Sort by</div>
                          <Select
                            styles={sortByStyles}
                            options={filterList}
                            placeholder="Sort by"
                            onChange={handleJobSortDropdownChange}
                            value={filterList.find(
                              (x) => x.label === sortDropdownLabel
                            )}
                            isOptionDisabled={(x) => x.isDisabled}
                            isSearchable={false}
                          />
                        </div>
                      </div>

                      <div id={styles.desktop_job_count}>
                        <span>{tableControlLabelText}</span>
                      </div>

                      {allJobSearchProps.jobs.map((job) => {
                        return (
                          <div
                            key={job.id}
                            className={styles.job_discovery_card}
                          >
                            <AdvancedJobCard
                              viewerType={isSeeker ? 'CANDIDATE' : 'RECRUITER'}
                              job={job}
                              watched={jobIsWatched(job.id, seeker)}
                              onWatchChanged={(watched) =>
                                handleJobWatchClicked(job, watched)
                              }
                              onJobClick={() => handleJobClicked(job.id)}
                              onEmployerClick={() =>
                                navigate('/employer/' + job.employerId)
                              }
                              isApprenticeProgram={includesApprenticeProgram(
                                seeker
                              )}
                              matchCardStyle={{left: '-277px'}}
                            />
                          </div>
                        );
                      })}

                      {allJobSearchProps.jobsTotalItems >
                        allJobSearchProps.jobsCurrentPage *
                          allJobSearchProps.jobsCurrentItemsPerPage && (
                        <div className="row padding-bottom-eight">
                          <div className="col-lg-12">
                            <div className="d-flex flex-row justify-content-between">
                              <div aria-live="assertive" aria-atomic="true">
                                Page {allJobSearchProps.jobsCurrentPage + 1} of{' '}
                                {Math.ceil(
                                  allJobSearchProps.jobsTotalItems /
                                    allJobSearchProps.jobsCurrentItemsPerPage
                                )}
                              </div>
                              <PageControl
                                pageIndex={allJobSearchProps.jobsCurrentPage}
                                pageCount={Math.ceil(
                                  allJobSearchProps.jobsTotalItems /
                                    allJobSearchProps.jobsCurrentItemsPerPage
                                )}
                                onPageChange={handleJobPageChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        </div>
        <PageFooter />
      </div>
    </DocumentTitle>
  );
}
