// action types
import NetworkAuthenticationService from 'src/services/NetworkAuthenticationService';

export const types = {
  START_SESSION: 'AUTH/START_SESSION',
  SIGNUP_USER: 'AUTH/SIGNUP_USER',
  LOGIN_USER: 'AUTH/LOGIN_USER',
  LOGOUT_USER: 'AUTH/LOGOUT_USER',
  LOGGED_IN: 'AUTH/LOGGED_IN',
  LOGGED_OUT: 'AUTH/LOGGED_OUT',
  REDIRECT_BY_ROLE: 'AUTH/REDIRECT_BY_ROLE',
  LOGIN_ERROR: 'AUTH/LOGIN_ERROR',
  EMAIL_CHANGE_PASSWORD_FAILURE: 'AUTH/EMAIL_CHANGE_PASSWORD_FAILURE',
  EMAIL_CHANGE_PASSWORD_SUCCESS: 'AUTH/EMAIL_CHANGE_PASSWORD_SUCCESS',
  EMAIL_CHANGE_PASSWORD_REQUEST: 'AUTH/EMAIL_CHANGE_PASSWORD_REQUEST',
};

const authService = new NetworkAuthenticationService(null, null);

const initialState = {
  isAuthenticated: authService.isAuthenticated(),
  sessionStarting: false,
  redirecting: false,
  loginError: null,
  emailChangePasswordSuccess: false,
  emailChangePasswordError: null,
};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case types.START_SESSION:
      return {...state, sessionStarting: action.fromAuthorizedComponent};
    case types.REDIRECT_BY_ROLE:
      return {...state, redirecting: true};
    case types.LOGGED_IN:
      return {...state, isAuthenticated: true};
    case types.LOGGED_OUT:
      return {...state, isAuthenticated: false};
    case types.LOGIN_ERROR:
      return {...state, loginError: action.payload};
    case types.EMAIL_CHANGE_PASSWORD_REQUEST:
      return {
        ...initialState,
      };
    case types.EMAIL_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        emailChangePasswordSuccess: false,
        emailChangePasswordError: action.payload,
      };
    case types.EMAIL_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        emailChangePasswordSuccess: true,
        emailChangePasswordError: null,
      };
    default:
      return state;
  }
}

// actions
export const actions = {
  startSession: (path, fromAuthorizedComponent = false) => ({
    type: types.START_SESSION,
    path,
    fromAuthorizedComponent,
  }),
  signupUser: (path, username, password) => ({
    type: types.SIGNUP_USER,
    path,
    username,
    password,
  }),
  loginUser: (path, username, password) => ({
    type: types.LOGIN_USER,
    path,
    username,
    password,
  }),
  logoutUser: () => ({type: types.LOGOUT_USER}),
  userLoggedIn: () => ({type: types.LOGGED_IN}),
  userLoggedOut: () => ({type: types.LOGGED_OUT}),
  redirectByRole: (profile) => ({
    type: types.REDIRECT_BY_ROLE,
    profile: profile,
  }),
};

// selectors
export const selectors = {
  isProfileLoaded: (state) => state.profile.isLoaded,
};
