import React from 'react';
import PropTypes from 'prop-types';
import styles from './RecentlyAppliedCandidates.module.scss';
import iconSVG from 'src/assets/svg/icons/Paper.svg';

const RecentlyAppliedCandidates = ({
  numCandidates,
  applicationListUrl,
  exploreCandidatesUrl,
}) => {
  const getCandidateText = (count) => {
    if (count === 0) {
      return (
        <>
          No candidates have tracked applications to your job(s) in the last 30
          days. <a href={exploreCandidatesUrl}>Click here</a> to see quality
          candidates and send them invitations to apply.
        </>
      );
    } else if (count === 1) {
      return (
        <>
          <a href={applicationListUrl}>
            <strong>1</strong>&nbsp; candidate
          </a>
          &nbsp; applied to your job(s) in the last 30 days.
        </>
      );
    } else if (count > 50) {
      return (
        <>
          <a href={applicationListUrl}>
            <strong>Over 50</strong>&nbsp; candidates
          </a>
          &nbsp; applied to your job(s) in the last 30 days.
        </>
      );
    } else {
      return (
        <>
          <a href={applicationListUrl}>
            <strong>{count}</strong>&nbsp; candidates
          </a>
          &nbsp; applied to your job(s) in the last 30 days.
        </>
      );
    }
  };

  return (
    <div className={styles.candidatePanel}>
      <div className={styles.iconContainer}>
        <img src={iconSVG} alt="Candidate Icon" />
      </div>
      <div className={styles.textContainer}>
        {getCandidateText(numCandidates)}
      </div>
    </div>
  );
};

RecentlyAppliedCandidates.propTypes = {
  numCandidates: PropTypes.number.isRequired,
  applicationListUrl: PropTypes.string.isRequired,
  exploreCandidatesUrl: PropTypes.string.isRequired,
};

export default RecentlyAppliedCandidates;
