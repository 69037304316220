import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';

import {Field, Form} from 'react-final-form';
import {TextField} from 'src/formFields';
import {required} from '../../../utils/intake/intakeCommon';
import {PhoneNumberInput} from '../../../components/PhoneNumberInput';
import NumberField from 'src/formFields/NumberField';
import createDecorator from 'final-form-focus';

class GeneralInfo extends React.Component {
  focusOnErrors = createDecorator();

  formRef = React.createRef();

  validate = (values) => {
    const errors = {};
    const requiredFields = ['firstName', 'lastName', 'phone', 'zipCode'];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Error: This field cannot be left blank';
      }
      if (values[field] && field === 'buttonList') {
        if (values[field].length === 0) {
          errors[field] = 'Select a minimum of one';
        }
      }
    });
    if (values.phone) {
      const numbersOnly = values.phone.replace(/\D/g, '');
      if (numbersOnly.length < 10) {
        errors.phone = 'Phone number requires 10 digits';
      }
    }
    if (values.zipCode && values.zipCode.length < 5) {
      errors.zipCode = 'Zip code requires 5 digits';
    }
    return errors;
  };
  render() {
    const {next, previous, headerImage} = this.props;

    const readFormValues = () => {
      return this.formRef?.current?.getState().values;
    };
    return (
      <>
        <PageHeader
          subTitle="All fields are required unless otherwise noted."
          headerImage={headerImage}
        />
        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            firstName: this.props.intakeCache?.firstName,
            lastName: this.props.intakeCache?.lastName,
            phone: this.props.intakeCache?.phone,
            zipCode: this.props.intakeCache?.zipCode,
          }}
          decorators={[this.focusOnErrors]}
        >
          {({handleSubmit, form}) => {
            this.formRef.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <div style={{textAlign: 'left'}}>
                    <Field
                      name="firstName"
                      component={TextField}
                      label="First Name"
                      labelCssClass={'onboarding-form-label'}
                      validate={required}
                      showBackground={false}
                      autoComplete={'on'}
                    />

                    <Field
                      name="lastName"
                      component={TextField}
                      label="Last Name"
                      labelCssClass={'onboarding-form-label'}
                      validate={required}
                      showBackground={false}
                      autoComplete={'on'}
                    />
                    <Field
                      name="phone"
                      component={PhoneNumberInput}
                      label="Phone Number"
                      labelCssClass={'onboarding-form-label'}
                      validate={required}
                      placeholder="Phone Number"
                      showBackground={false}
                    />
                    <Field
                      name="zipCode"
                      component={NumberField}
                      size={32}
                      label="Zip Code"
                      labelCssClass={'onboarding-form-label'}
                      maxLength={5}
                      showBackground={false}
                      validate={required}
                    />
                  </div>
                </div>
                <PageFooter
                  next={() => this.formRef?.current?.submit()}
                  previous={previous}
                  formValues={this.formRef?.current?.getState().values}
                  getValues={readFormValues}
                  showPreviousButton={false}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
GeneralInfo.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any,
  headerImage: PropTypes.any,
};

export default GeneralInfo;
