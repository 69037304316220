import React from 'react';
import PropTypes from 'prop-types';
import * as Layout from '../layout';

class AreYouSureModal extends React.Component {
  render() {
    return (
      <Layout.AscendModal
        size={this.props.size}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        centerVertically={this.props.centerVertically}
      >
        <Layout.AscendModalBody hasClose={false}>
          <div className="row">
            <div className={`col-12 ${this.props.areYouSureClassName}`}>
              <center>{this.props.areYouSureText}</center>
            </div>
          </div>

          {this.props.detailText && (
            <>
              <div className="row">
                <div className="col-12">&nbsp;</div>
              </div>
              <div className="row">
                <div className={`col-12 ${this.props.detailClassName}`}>
                  <center>{this.props.detailText}</center>
                </div>
              </div>
            </>
          )}

          <div className="row">
            <div className="col-12">&nbsp;</div>
          </div>
          <div
            className="row justify-content-center"
            style={{
              flexDirection: this.props.reverseButtons ? 'row-reverse' : 'row',
            }}
          >
            {!this.props.hideYes && (
              <button
                type="button"
                className="clickable btn btn-primary col-4"
                onClick={() => this.props.onYes()}
              >
                {this.props.yesText}
              </button>
            )}
            <div className="col-1"></div>
            {!this.props.hideNo && (
              <button
                className="clickable btn btn-secondary col-4"
                onClick={() => this.props.onClose()}
              >
                {this.props.noText}
              </button>
            )}
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

AreYouSureModal.propTypes = {
  areYouSureText: PropTypes.any.isRequired,
  detailText: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  hideYes: PropTypes.bool,
  hideNo: PropTypes.bool,
  centerVertically: PropTypes.bool,
  reverseButtons: PropTypes.bool,
  size: PropTypes.string,
  areYouSureClassName: PropTypes.string,
  detailClassName: PropTypes.string,
};

AreYouSureModal.defaultProps = {
  isOpen: false,
  yesText: 'Yes',
  noText: 'No',
  hideNo: false,
  hideYes: false,
  centerVertically: false,
  reverseButtons: false,
  size: 'sm',
  areYouSureClassName: 'normal-subheader',
  detailClassName: 'normal-subheader',
};

export default AreYouSureModal;
