import {checkCacheValid} from 'redux-cache';

import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export const getProfileStatuses = () => {
  return (dispatch, getState) => {
    const isCacheValid = checkCacheValid(getState, 'profileStatuses');
    if (isCacheValid) {
      return null;
    }

    return dispatch({
      types: [
        types.PROFILE_STATUSES__GET_REQUEST,
        types.PROFILE_STATUSES__GET_SUCCESS,
        types.PROFILE_STATUSES__GET_FAILURE,
      ],
      uri: `${apiUrl}/v2/profileStatuses`,
      method: 'GET',
    });
  };
};
