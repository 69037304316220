import _ from 'lodash';
import * as flashActions from '../actions/flash';
import * as types from './ActionTypes';
import {makeRequest} from '../services/api';

const apiUrl = process.env.REACT_APP_API_URL;

export function createInvitationCode(invitationCode) {
  return {
    types: [
      types.INVITATION_CODES__CREATE_REQUEST,
      types.INVITATION_CODES__CREATE_SUCCESS,
      types.INVITATION_CODES__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/invitation_codes`,
    method: 'POST',
    body: invitationCode,
    payload: {invitationCode},
    successMessage: 'Invitation code created.',
    failureMessage: 'Error creating invitation code.',
  };
}

export function getInvitationCode(invitationCodeId) {
  return {
    types: [
      types.INVITATION_CODES__GET_REQUEST,
      types.INVITATION_CODES__GET_SUCCESS,
      types.INVITATION_CODES__GET_FAILURE,
    ],
    uri: `${apiUrl}/invitation_codes/${invitationCodeId}`,
    method: 'GET',
  };
}

export function getInvitationCodes() {
  return {
    types: [
      types.INVITATION_CODES__GET_ALL_REQUEST,
      types.INVITATION_CODES__GET_ALL_SUCCESS,
      types.INVITATION_CODES__GET_ALL_FAILURE,
    ],
    uri: `${apiUrl}/invitation_codes`,
    method: 'GET',
  };
}

export function getInvitationCodesAdvanced(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/invitation_codes`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.INVITATION_CODES__GET_ALL_ADVANCED_REQUEST,
      types.INVITATION_CODES__GET_ALL_ADVANCED_SUCCESS,
      types.INVITATION_CODES__GET_ALL_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function setEditInvitationCode(invitationCode) {
  return {
    type: types.INVITATION_CODES__EDIT_DIALOG_SHOW,
    data: invitationCode,
  };
}

export function updateInvitationCode(invitationCode) {
  let body = {...invitationCode};
  delete body.id;
  delete body.code;
  delete body.updatedAt;
  delete body.createdAt;
  delete body.deletedAt;
  delete body.metWith;
  delete body.published;
  delete body.completed;
  delete body.invited;
  delete body.shortCompleted;

  return {
    types: [
      types.INVITATION_CODES__UPDATE_REQUEST,
      types.INVITATION_CODES__UPDATE_SUCCESS,
      types.INVITATION_CODES__UPDATE_FAILURE,
    ],
    uri: `${apiUrl}/invitation_codes/${invitationCode.id}`,
    method: 'PATCH',
    body: body,
    payload: {invitationCode},
    successMessage: 'Invitation code saved.',
    failureMessage: 'Error saving invitation code.',
  };
}

export function deleteInvitationCode(invitationCodeId) {
  return {
    types: [
      types.INVITATION_CODES__DELETE_REQUEST,
      types.INVITATION_CODES__DELETE_SUCCESS,
      types.INVITATION_CODES__DELETE_FAILURE,
    ],
    uri: `${apiUrl}/invitation_codes/${invitationCodeId}`,
    method: 'DELETE',
    payload: {invitationCodeId},
    successMessage: 'Invitation code deleted.',
    failureMessage: 'Error deleting invitation code.',
  };
}

export function validateInvitationCode(invitationCode) {
  return {
    types: [
      types.INVITATION_CODES__VALIDATE_REQUEST,
      types.INVITATION_CODES__VALIDATE_SUCCESS,
      types.INVITATION_CODES__VALIDATE_FAILURE,
    ],
    uri: `${apiUrl}/invitation_codes/validate?code=${invitationCode}`,
    method: 'GET',
    isAuthenticated: false,
  };
}

export const validateInvitationCodeAsync =
  (invitationCode, partnerUrl) => async (dispatch) => {
    dispatch({
      type: types.INVITATION_CODES__VALIDATE_REQUEST,
    });

    const response = await makeRequest({
      method: 'GET',
      path: `invitation_codes/validate?code=${invitationCode}&partnerUrl=${partnerUrl}`,
    });
    if (response.status === 200) {
      dispatch({
        type: types.INVITATION_CODES__VALIDATE_SUCCESS,
        response: {body: response.data},
      });
      return response.data;
    }
  };

export const createInvitationCodeAsync =
  (invitationCode) => async (dispatch) => {
    dispatch({
      type: types.INVITATION_CODES__CREATE_REQUEST,
    });

    const response = await makeRequest({
      method: 'POST',
      path: `invitation_codes`,
      body: invitationCode,
      contentType: 'application/json',
    });
    if (response.status === 200) {
      dispatch({
        type: types.INVITATION_CODES__CREATE_SUCCESS,
        response: {body: response.data},
      });
      dispatch(
        flashActions.addFlashMessage('Invitation code saved.', 'notification')
      );
      return response.data;
    } else {
      dispatch(flashActions.addFlashMessage('Error saving invitation code.'));
    }
  };
