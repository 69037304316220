import React, {useState, useImperativeHandle, forwardRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as jobsActions from 'src/actions/jobs';
import * as inviteActions from 'src/actions/invitationCodes.js';
import * as flashActions from '../actions/flash';

import {DropdownItem} from 'reactstrap';
import {EditJobForm} from '../forms/JobForm';
import {AscendModal, AscendModalBody} from '../layout';
import styles from './JobProfile.module.scss';
import {toNumber} from '../utils/wageHelper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CodesForm from '../pages/admin/codes/CodesForm';
import {DeleteRoleConfirm} from './DeleteRoleConfirm';
import {isValidHttpsUrl} from 'src/utils/fieldHelper';

const JobActionsMenu = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  // ========= State Management Code =========
  const [state, setState] = useState({
    jobFormOpen: false,
    showErrorsOnLoad: false,
    confirmDeleteIsOpen: false,
    confirmEditIsOpen: false,
    formCodesIsOpen: false,
    validationErrors: [],
    jobForEdit: props.job,
  });

  // ========= Redux Code =========
  // Equivalent of mapStateToProps - gets the state variables into the component
  const role = useSelector((state) => state.profile.role);

  // ========= Form Validation =========
  const validateMa = (values) => {
    let errors = [];

    if (values.apprenticePathwayId === null) {
      errors.push('Apprentice Pathway');
    }

    if (
      (values.remoteStatus !== 'Remote' && !values.addressLine1) ||
      (values.remoteStatus !== 'Remote' && values.addressLine1 === null)
    ) {
      if (
        (values.remoteStatus !== 'Remote' && !values.customLocation) ||
        (values.remoteStatus !== 'Remote' && values.customLocation === null)
      ) {
        errors.push('Employer Location');
      }
    }

    return errors;
  };

  const isPayRangeValid = (minimumPay, maximumPay) => {
    return toNumber(minimumPay) < toNumber(maximumPay);
  };

  const validate = (values) => {
    let errors = [];
    const isMaSegment = (values.segments || []).some(
      (x) => x.isApprenticeProgram
    );

    if ((values.name || '').trim().length === 0) {
      errors.push('Role Name');
    }

    if (_.isEmpty(values.segments)) {
      errors.push('Segment');
    }

    if (isMaSegment) {
      return validateMa(values);
    }

    if (!values.roleStartFlexibility) {
      errors.push('Role start flexibility');
    }

    if (values.numberOfOpenings < 1) {
      errors.push('Number of openings < 1.');
    }
    if (values.numberOfOpenings > 200) {
      errors.push('Number of openings > 200.');
    }

    if (_.isEmpty(values.employmentTypes)) {
      errors.push('Employment Type');
    }

    if (values.degreeTypeRankId === null) {
      errors.push('Degree Type');
    }

    if (_.isEmpty(values.regions)) {
      errors.push('Region');
    }

    if (_.isEmpty(values.roleTypes)) {
      errors.push('Role Type');
    }
    if (_.isEmpty(values.industries)) {
      errors.push('Industry');
    }

    if (_.isEmpty(values.url) || !isValidHttpsUrl(values.url)) {
      errors.push('Role Web URL');
    }

    if (_.isEmpty(values.experienceLevels)) {
      errors.push('Experience Level');
    }
    if (
      (values.remoteStatus !== 'Remote' && !values.addressLine1) ||
      (values.remoteStatus !== 'Remote' && values.addressLine1 === null)
    ) {
      if (
        (values.remoteStatus !== 'Remote' && !values.customLocation) ||
        (values.remoteStatus !== 'Remote' && values.customLocation === null)
      ) {
        errors.push('Employer Location');
      }
    }

    if (
      values.payType === 'Hourly' &&
      values.minimumHourlyWage &&
      values.maximumHourlyWage
    ) {
      if (
        !isPayRangeValid(values.minimumHourlyWage, values.maximumHourlyWage)
      ) {
        errors.push(
          'Maximum hourly wage must be a larger value than the minimum.'
        );
      }
    }

    if (
      values.payType === 'Salary' &&
      values.minimumYearlySalary &&
      values.maximumYearlySalary
    ) {
      if (
        !isPayRangeValid(values.minimumYearlySalary, values.maximumYearlySalary)
      ) {
        errors.push(
          'Maximum yearly salary must be a larger value than the minimum.'
        );
      }
    }

    return errors;
  };

  // ========= Action Creater to keep redux in sync =========

  // Update redux for jobs, and allJobs so the parent tables update
  const updateJobSuccess = (updatedJob) => ({
    type: 'JOBS__UPDATE_SUCCESS',
    payload: {
      job: updatedJob,
    },
    response: {
      body: updatedJob,
    },
  });

  // ========= Event Handler Code =========
  const handleModalClose = () => {
    setState((prevState) => ({
      ...prevState,
      jobFormOpen: false,
      confirmDeleteIsOpen: false,
      confirmEditIsOpen: false,
      formCodesIsOpen: false,
    }));
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleJobEditSubmit = (job) => {
    dispatch(jobsActions.updateJob(job));
    dispatch(jobsActions.updateJobForEdit(job));
    setState((prevState) => ({...prevState, jobForEdit: job}));
    handleModalClose();
  };

  const handleEditJobClicked = (jobId, showErrorsOnLoad) => {
    dispatch(jobsActions.getJobForEdit(jobId));
    setState((prevState) => ({
      ...prevState,
      jobFormOpen: true,
      jobIdToEdit: jobId,
      showErrorsOnLoad: showErrorsOnLoad,
    }));
  };

  const handleDeleteJobClicked = (jobId) => {
    dispatch(jobsActions.deleteJob(jobId));
  };

  const handleConfirmYesClicked = () => {
    setState((prevState) => ({...prevState, confirmDeleteIsOpen: false}));
    dispatch(jobsActions.deleteJob(state.jobForEdit.id));
    handleModalClose();
  };

  const handlePublish = () => {
    let errors = validate(state.jobForEdit);

    if (errors.length > 0) {
      setState((prevState) => ({
        ...prevState,
        confirmEditIsOpen: true,
        validationErrors: errors,
      }));
    } else {
      dispatch(
        jobsActions.updateJob({id: state.jobForEdit.id, published: true})
      );
      dispatch(
        jobsActions.updateJobForEdit({...state.jobForEdit, published: true})
      );
      setState((prevState) => ({
        ...prevState,
        jobForEdit: {...state.jobForEdit, published: true},
      }));
      dispatch(
        updateJobSuccess({
          ...state.jobForEdit,
          published: true,
        })
      );
      props.onMenuChange && props.onMenuChange();
      handleModalClose();
    }
  };

  const handleCodesClicked = (jobId) => {
    if (jobId) {
      dispatch(
        inviteActions.setEditInvitationCode({
          label: '',
          shortUrl: '',
          destinationPage: '/role/' + jobId,
        })
      );
      setState((prevState) => ({...prevState, formCodesIsOpen: true}));
    }
  };
  const handleInvitationCodeSubmit = (invitationCode) => {
    dispatch(inviteActions.createInvitationCodeAsync(invitationCode)).then(
      (response) => {
        const codeUrl = `${window.location.protocol}//${window.location.host}/signup?code=${response.code}`;

        navigator.clipboard
          .writeText(codeUrl)
          .then(() =>
            dispatch(
              flashActions.addFlashMessage(
                'Sign-up link copied. Paste to use.',
                'notification'
              )
            )
          );
      }
    );
    handleModalClose();
  };

  const handleUnpublish = () => {
    dispatch(
      jobsActions.updateJob({id: state.jobForEdit.id, published: false})
    );
    dispatch(
      jobsActions.updateJobForEdit({...state.jobForEdit, published: false})
    );
    dispatch(
      updateJobSuccess({
        ...state.jobForEdit,
        published: false,
      })
    );

    setState((prevState) => ({
      ...prevState,
      jobForEdit: {...state.jobForEdit, published: false},
    }));

    props.onMenuChange && props.onMenuChange();

    handleModalClose();
  };

  // ===== Menu Items =====
  const renderEditRole = () => {
    return (
      <DropdownItem
        key={'edit_role'}
        onClick={() => {
          handleEditJobClicked(state.jobForEdit.id);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleEditJobClicked(state.jobForEdit.id);
          }
        }}
      >
        <div className="clickable secondary-body fullwidth">Edit Role</div>
      </DropdownItem>
    );
  };
  const renderPublishRole = () => {
    return (
      <DropdownItem
        key={'publish_role'}
        onClick={() => {
          handlePublish();
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handlePublish();
          }
        }}
      >
        <div className="clickable secondary-body fullwidth">Publish Role</div>
      </DropdownItem>
    );
  };
  const renderUnPublishRole = () => {
    return (
      <DropdownItem
        key={'unpublish_role'}
        onClick={handleUnpublish}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleUnpublish();
          }
        }}
      >
        <div className="clickable secondary-body fullwidth">Unpublish Role</div>
      </DropdownItem>
    );
  };
  const renderResetPublishDate = () => {
    return (
      <DropdownItem
        key={'reset_publish_date'}
        onClick={() => {
          dispatch(jobsActions.resetPublishedDate(state.jobForEdit.id));
          dispatch(
            updateJobSuccess({
              ...state.jobForEdit,
              publishedAt: new Date().toISOString(),
            })
          );
          handleModalClose();
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            dispatch(jobsActions.resetPublishedDate(state.jobForEdit.id));
            dispatch(
              updateJobSuccess({
                ...state.jobForEdit,
                publishedAt: new Date().toISOString(),
              })
            );
            handleModalClose();
          }
        }}
        disabled={state.jobForEdit.published === false}
      >
        <div className="clickable secondary-body fullwidth">
          Reset Publish Date
        </div>
      </DropdownItem>
    );
  };
  const createInvitationLink = () => {
    return (
      <DropdownItem
        key={'create_invitation_link'}
        onClick={() => handleCodesClicked(state.jobForEdit.id)}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleCodesClicked(state.jobForEdit.id);
          }
        }}
      >
        <div className="clickable secondary-body fullwidth">
          Create Invitation Link
        </div>
      </DropdownItem>
    );
  };
  const renderDeleteRole = () => {
    return (
      <DropdownItem
        key={'delete_role'}
        onClick={() => {
          setState((prevState) => ({...prevState, confirmDeleteIsOpen: true}));
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setState((prevState) => ({
              ...prevState,
              confirmDeleteIsOpen: true,
            }));
          }
        }}
      >
        <div className="clickable secondary-body fullwidth">Delete Role</div>
      </DropdownItem>
    );
  };

  const popUpMenu = [
    renderEditRole(),
    state.jobForEdit.published ? renderUnPublishRole() : renderPublishRole(),
    state.jobForEdit.published && renderResetPublishDate(),
    state.jobForEdit.published && createInvitationLink(),
    renderDeleteRole(),
  ];

  // ==== Public functions for parent component ====
  useImperativeHandle(ref, () => ({
    handlePublish, // Added to keep Role profile experience consistent
    popUpMenu,
  }));

  // ========= Render Method =========
  return (
    <>
      {(role === 'ASCEND_RECRUITER' ||
        role === 'COMPANY_RECRUITER_ADMIN' ||
        role === 'COMPANY_RECRUITER') &&
        state.jobFormOpen && (
          <EditJobForm
            id={state.jobIdToEdit}
            onSubmit={handleJobEditSubmit}
            onDelete={() => handleDeleteJobClicked(state.jobIdToEdit)}
            isOpen={state.jobFormOpen}
            onClose={handleModalClose}
            showErrorsOnLoad={state.showErrorsOnLoad}
          />
        )}

      <DeleteRoleConfirm
        jobName={state.jobForEdit.name}
        onClose={handleModalClose}
        onClick={handleConfirmYesClicked}
        confirmDeleteIsOpen={state.confirmDeleteIsOpen}
      />

      <AscendModal
        styles={{maxWidth: '450px', width: '100%'}}
        isOpen={state.confirmEditIsOpen}
        onClose={() =>
          setState((prevState) => ({...prevState, confirmEditIsOpen: false}))
        }
        centerVertically={true}
      >
        <AscendModalBody bodyClass={styles.modalBody}>
          <div className={styles.modalTitle}>Publish Role</div>
          <div>
            Role is missing required fields. Please add this information to
            publish the role.
          </div>
          <div className={styles.modalJobTitle}>
            {state.jobForEdit.name} is missing: <br />
            <p>{state.validationErrors.join(', ')}</p>
          </div>

          <div className="row justify-content-center">
            <button
              className="clickable btn btn-secondary col-3"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  confirmEditIsOpen: false,
                }))
              }
            >
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="clickable btn btn-primary col-4"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmEditIsOpen: false,
                }));
                handleEditJobClicked(state.jobForEdit.id, true);
              }}
            >
              Edit Role
            </button>
          </div>
        </AscendModalBody>
      </AscendModal>

      {state.formCodesIsOpen && (
        <CodesForm
          isOpen={state.formCodesIsOpen}
          invitationCode={{label: ''}}
          onSubmit={handleInvitationCodeSubmit}
          headerText={'New Invitation Code'}
          onClose={handleModalClose}
          destinationPage={'/role/' + state.jobForEdit.id}
        />
      )}
    </>
  );
});

JobActionsMenu.propTypes = {
  job: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onMenuChange: PropTypes.func,
};

export default JobActionsMenu;
