import React, {useEffect, useState} from 'react';
import * as Layout from 'src/layout';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {BusyButton} from 'src/components/BusyButton';
import {Form, Field} from 'react-final-form';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import {getCompanies} from 'src/actions/companies';
import _ from 'lodash';
import {
  isValidHttpsUrl,
  INVALID_URL_ERROR_MESSAGE,
} from 'src/utils/fieldHelper';

import {
  AvatarField,
  TextField,
  SelectField,
  TextAreaField,
} from 'src/formFields';
import {PhoneNumberInput} from 'src/components/PhoneNumberInput';

export default function AdminProfileModal(params) {
  const dispatch = useDispatch();
  const parameters = useParams();

  const [changeEmailModalIsOpen, setChangeEmailModalIsOpen] = useState(false);

  let {companies, companiesLoading} = useSelector((state) => {
    return {
      companies: state.companies.companies,
      companiesLoading: state.companies.companiesLoading,
    };
  });

  useEffect(() => {
    if (companies.length === 0 && companiesLoading === false) {
      dispatch(getCompanies());
    }
  }, [dispatch, parameters, companies, companiesLoading]);

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['firstName', 'lastName', 'email'];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });

    if (values.externalUrl && !isValidHttpsUrl(values.externalUrl)) {
      errors['externalUrl'] = INVALID_URL_ERROR_MESSAGE;
    }

    return errors;
  };

  return (
    <Layout.AscendModal
      size="lg"
      isOpen={params.isOpen}
      onClose={params.onClose}
    >
      <Layout.AscendModalBody hasClose={true}>
        <Form
          onSubmit={params.onSubmit}
          validate={validate}
          initialValues={params.ascendRecruiter}
        >
          {({handleSubmit, form}) => {
            return (
              <form>
                <div className="bd-graybackground pt-1 pb-1 ps-5 pe-5">
                  <center>
                    <div className="normal-title padding-bottom-eight bold">
                      Edit Information
                    </div>
                    <hr />
                  </center>
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="firstName"
                        component={TextField}
                        size={32}
                        label="First Name"
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="lastName"
                        component={TextField}
                        size={32}
                        label="Last Name"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="email"
                        component={TextField}
                        disabled={true}
                        size={32}
                        maxLength={128}
                        label="Email"
                        labelActionText={'Update Email'}
                        labelActionCallBack={() => {
                          setChangeEmailModalIsOpen(true);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="phone"
                        component={PhoneNumberInput}
                        size={32}
                        label="Work Phone"
                        parse={(value) => value}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="title"
                        component={TextField}
                        size={32}
                        label="Title"
                        parse={(value) => value}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="companyId"
                        label="Company"
                        isDisabled={companies.length === 0}
                        isLoading={companiesLoading}
                        component={SelectField}
                        width="100%"
                        options={companies.map((opt) => {
                          return {value: opt.id, label: opt.name};
                        })}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Field
                        name="externalUrl"
                        component={TextField}
                        maxLength={512}
                        label="Url"
                        parse={(value) => value}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Field
                        name="aboutMe"
                        component={TextAreaField}
                        size={32}
                        label="About Me"
                        showCounter={true}
                        maxLength={350}
                        rows={5}
                        parse={(value) => value}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Field name="avatarUrl" component={AvatarField} />
                    </div>
                  </div>

                  <br />

                  <div className="row justify-content-center nowrap">
                    <button
                      type="button"
                      className="btn btn-transparent col-2 me-4"
                      onClick={params.onClose}
                    >
                      Cancel
                    </button>
                    <BusyButton
                      onClick={
                        changeEmailModalIsOpen
                          ? (e) => e.preventDefault()
                          : handleSubmit
                      }
                      buttonText={'Save'}
                      busyText={'Saving'}
                      className="col-3"
                      style={{width: '210px', height: '40px'}}
                      float={'float-right'}
                      buttonIcon={'save'}
                      alignText={'text-center'}
                    >
                      Save
                    </BusyButton>
                  </div>
                </div>

                <ChangeEmailModal
                  isOpen={changeEmailModalIsOpen}
                  onClose={() => {
                    setChangeEmailModalIsOpen(false);
                  }}
                  onCancel={() => {
                    setChangeEmailModalIsOpen(false);
                  }}
                />
              </form>
            );
          }}
        </Form>
      </Layout.AscendModalBody>
    </Layout.AscendModal>
  );
}
