export const FLASH_MESSAGE__CREATE = 'FLASH_MESSAGE__CREATE';
export const FLASH_MESSAGE__DELETE = 'FLASH_MESSAGE__DELETE';

export const PROFILE__GET_REQUEST = 'PROFILE__GET_REQUEST';
export const PROFILE__GET_SUCCESS = 'PROFILE__GET_SUCCESS';
export const PROFILE__GET_MIDDLEWARE_SUCCESS = 'PROFILE__GET_MIDDLEWARE_SUCCESS';
export const PROFILE__GET_FAILURE = 'PROFILE__GET_FAILURE';

export const PROFILE__ONBOARDING_COMPLETE = 'PROFILE__ONBOARDING_COMPLETE';
export const PROFILE__MODERNAPPRENTICE_INTAKE_COMPLETE = 'PROFILE__MODERNAPPRENTICE_INTAKE_COMPLETE';

export const PROFILE__GET_NOTIFICATION_OPTIONS_REQUEST = 'PROFILE__GET_NOTIFICATION_OPTIONS_REQUEST';
export const PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS = 'PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS';
export const PROFILE__GET_NOTIFICATION_OPTIONS_FAILURE = 'PROFILE__GET_NOTIFICATION_OPTIONS_FAILURE';

export const PROFILE__UPDATE_NOTIFICATION_OPTIONS_REQUEST = 'PROFILE__UPDATE_NOTIFICATION_OPTIONS_REQUEST';
export const PROFILE__UPDATE_NOTIFICATION_OPTIONS_SUCCESS = 'PROFILE__UPDATE_NOTIFICATION_OPTIONS_SUCCESS';
export const PROFILE__UPDATE_NOTIFICATION_OPTIONS_FAILURE = 'PROFILE__UPDATE_NOTIFICATION_OPTIONS_FAILURE';

export const RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_REQUEST = 'RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_REQUEST';
export const RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS = 'RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS';
export const RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_FAILURE = 'RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_FAILURE';
export const RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_RESET = 'RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_RESET';

export const RECRUITER__UPDATE_NOTIFICATION_OPTIONS_REQUEST = 'RECRUITER__UPDATE_NOTIFICATION_OPTIONS_REQUEST';
export const RECRUITER__UPDATE_NOTIFICATION_OPTIONS_SUCCESS = 'RECRUITER__UPDATE_NOTIFICATION_OPTIONS_SUCCESS';
export const RECRUITER__UPDATE_NOTIFICATION_OPTIONS_FAILURE = 'RECRUITER__UPDATE_NOTIFICATION_OPTIONS_FAILURE';

export const USERS__UPDATE_TERMS_ACCEPTED = 'USERS__UPDATE_TERMS_ACCEPTED';
export const USERS__UPDATE_TERMS_ACCEPTED_SUCCESS =
  'USERS__UPDATE_TERMS_ACCEPTED_SUCCESS';
export const USERS__UPDATE_TERMS_ACCEPTED_FAILURE =
  'USERS__UPDATE_TERMS_ACCEPTED_FAILURE';

export const USERS__ACTIVATE_REQUEST = 'USERS__ACTIVATE_REQUEST';
export const USERS__ACTIVATE_SUCCESS = 'USERS__ACTIVATE_SUCCESS';
export const USERS__ACTIVATE_FAILURE = 'USERS__ACTIVATE_FAILURE';

export const USERS__CONFIRM_CHANGE_EMAIL_REQUEST = 'USERS__CONFIRM_CHANGE_EMAIL_REQUEST';
export const USERS__CONFIRM_CHANGE_EMAIL_SUCCESS = 'USERS__CONFIRM_CHANGE_EMAIL_SUCCESS';
export const USERS__CONFIRM_CHANGE_EMAIL_FAILURE = 'USERS__CONFIRM_CHANGE_EMAIL_FAILURE';

export const USERS__UPDATE_LAST_LOGIN_REQUEST =
  'USERS__UPDATE_LAST_LOGIN_REQUEST';
export const USERS__UPDATE_LAST_LOGIN_SUCCESS =
  'USERS__UPDATE_LAST_LOGIN_SUCCESS';
export const USERS__UPDATE_LAST_LOGIN_FAILURE =
  'USERS__UPDATE_LAST_LOGIN_FAILURE';

export const USERS__LIST_BY_PERMISSION_REQUEST =
  'USERS__LIST_BY_PERMISSION_REQUEST';
export const USERS__LIST_BY_PERMISSION_SUCCESS =
  'USERS__LIST_BY_PERMISSION_SUCCESS';
export const USERS__LIST_BY_PERMISSION_FAILURE =
  'USERS__LIST_BY_PERMISSION_FAILURE';

export const RECRUITERS__CREATE_REQUEST = 'RECRUITERS__CREATE_REQUEST';
export const RECRUITERS__CREATE_SUCCESS = 'RECRUITERS__CREATE_SUCCESS';
export const RECRUITERS__CREATE_FAILURE = 'RECRUITERS__CREATE_FAILURE';

export const RECRUITERS__UPDATE_REQUEST = 'RECRUITERS__UPDATE_REQUEST';
export const RECRUITERS__UPDATE_SUCCESS = 'RECRUITERS__UPDATE_SUCCESS';
export const RECRUITERS__UPDATE_FAILURE = 'RECRUITERS__UPDATE_FAILURE';

export const RECRUITERS__GET_ALL_REQUEST = 'RECRUITERS__GET_ALL_REQUEST';
export const RECRUITERS__GET_ALL_SUCCESS = 'RECRUITERS__GET_ALL_SUCCESS';
export const RECRUITERS__GET_ALL_FAILURE = 'RECRUITERS__GET_ALL_FAILURE';

export const RECRUITERS__GET_BY_COMPANY_REQUEST =
  'RECRUITERS__GET_BY_COMPANY_REQUEST';
export const RECRUITERS__GET_BY_COMPANY_SUCCESS =
  'RECRUITERS__GET_BY_COMPANY_SUCCESS';
export const RECRUITERS__GET_BY_COMPANY_FAILURE =
  'RECRUITERS__GET_BY_COMPANY_FAILURE';

export const RECRUITERS__GET_BY_COMPANY_ADVANCED_REQUEST =
  'RECRUITERS__GET_BY_COMPANY_ADVANCED_REQUEST';
export const RECRUITERS__GET_BY_COMPANY_ADVANCED_SUCCESS =
  'RECRUITERS__GET_BY_COMPANY_ADVANCED_SUCCESS';
export const RECRUITERS__GET_BY_COMPANY_ADVANCED_FAILURE =
  'RECRUITERS__GET_BY_COMPANY_ADVANCED_FAILURE';

export const RECRUITERS__GET_REQUEST = 'RECRUITERS__GET_REQUEST';
export const RECRUITERS__GET_SUCCESS = 'RECRUITERS__GET_SUCCESS';
export const RECRUITERS__GET_FAILURE = 'RECRUITERS__GET_FAILURE';

export const RECRUITERS__DELETE_REQUEST = 'RECRUITERS__DELETE_REQUEST';
export const RECRUITERS__DELETE_SUCCESS = 'RECRUITERS__DELETE_SUCCESS';
export const RECRUITERS__DELETE_FAILURE = 'RECRUITERS__DELETE_FAILURE';

export const RECRUITERS__GET_SAVED_SEARCHES_REQUEST = 'RECRUITERS__GET_SAVED_SEARCHES_REQUEST';
export const RECRUITERS__GET_SAVED_SEARCHES_SUCCESS = 'RECRUITERS__GET_SAVED_SEARCHES_SUCCESS';
export const RECRUITERS__GET_SAVED_SEARCHES_FAILURE = 'RECRUITERS__GET_SAVED_SEARCHES_FAILURE';

export const RECRUITERS__CREATE_SAVED_SEARCHES_REQUEST = 'RECRUITERS__CREATE_SAVED_SEARCHES_REQUEST';
export const RECRUITERS__CREATE_SAVED_SEARCHES_SUCCESS = 'RECRUITERS__CREATE_SAVED_SEARCHES_SUCCESS';
export const RECRUITERS__CREATE_SAVED_SEARCHES_FAILURE = 'RECRUITERS__CREATE_SAVED_SEARCHES_FAILURE';

export const RECRUITERS__DELETE_SAVED_SEARCHES_REQUEST = 'RECRUITERS__DELETE_SAVED_SEARCHES_REQUEST';
export const RECRUITERS__DELETE_SAVED_SEARCHES_SUCCESS = 'RECRUITERS__DELETE_SAVED_SEARCHES_SUCCESS';
export const RECRUITERS__DELETE_SAVED_SEARCHES_FAILURE = 'RECRUITERS__DELETE_SAVED_SEARCHES_FAILURE';

export const ASCEND_RECRUITERS__GET_ALL_REQUEST =
  'ASCEND_RECRUITERS__GET_ALL_REQUEST';
export const ASCEND_RECRUITERS__GET_ALL_SUCCESS =
  'ASCEND_RECRUITERS__GET_ALL_SUCCESS';
export const ASCEND_RECRUITERS__GET_ALL_FAILURE =
  'ASCEND_RECRUITERS__GET_ALL_FAILURE';

export const ASCEND_RECRUITERS__GET_REQUEST = 'ASCEND_RECRUITERS__GET_REQUEST';
export const ASCEND_RECRUITERS__GET_SUCCESS = 'ASCEND_RECRUITERS__GET_SUCCESS';
export const ASCEND_RECRUITERS__GET_FAILURE = 'ASCEND_RECRUITERS__GET_FAILURE';

export const ASCEND_RECRUITERS__GET_ADMIN_PROFILE_REQUEST = 'ASCEND_RECRUITERS__GET_ADMIN_PROFILE_REQUEST';
export const ASCEND_RECRUITERS__GET_ADMIN_PROFILE_SUCCESS = 'ASCEND_RECRUITERS__GET_ADMIN_PROFILE_SUCCESS';
export const ASCEND_RECRUITERS__GET_ADMIN_PROFILE_FAILURE = 'ASCEND_RECRUITERS__GET_ADMIN_PROFILE_FAILURE';

export const ASCEND_RECRUITERS__UPDATE_REQUEST = 'ASCEND_RECRUITERS__UPDATE_REQUEST';
export const ASCEND_RECRUITERS__UPDATE_SUCCESS = 'ASCEND_RECRUITERS__UPDATE_SUCCESS';
export const ASCEND_RECRUITERS__UPDATE_FAILURE = 'ASCEND_RECRUITERS__UPDATE_FAILURE';

export const EMPLOYERS__CREATE_REQUEST = 'EMPLOYERS__CREATE_REQUEST';
export const EMPLOYERS__CREATE_SUCCESS = 'EMPLOYERS__CREATE_SUCCESS';
export const EMPLOYERS__CREATE_FAILURE = 'EMPLOYERS__CREATE_FAILURE';

export const EMPLOYERS__CREATE_WATCH_REQUEST =
  'EMPLOYERS__CREATE_WATCH_REQUEST';
export const EMPLOYERS__CREATE_WATCH_SUCCESS =
  'EMPLOYERS__CREATE_WATCH_SUCCESS';
export const EMPLOYERS__CREATE_WATCH_FAILURE =
  'EMPLOYERS__CREATE_WATCH_FAILURE';

export const EMPLOYERS__GET_ALL_REQUEST = 'EMPLOYERS__GET_ALL_REQUEST';
export const EMPLOYERS__GET_ALL_SUCCESS = 'EMPLOYERS__GET_ALL_SUCCESS';
export const EMPLOYERS__GET_ALL_FAILURE = 'EMPLOYERS__GET_ALL_FAILURE';

export const EMPLOYERS__GET_FOR_EDIT_REQUEST =
  'EMPLOYERS__GET_FOR_EDIT_REQUEST';
export const EMPLOYERS__GET_FOR_EDIT_SUCCESS =
  'EMPLOYERS__GET_FOR_EDIT_SUCCESS';
export const EMPLOYERS__GET_FOR_EDIT_FAILURE =
  'EMPLOYERS__GET_FOR_EDIT_FAILURE';

export const EMPLOYERS__GET_ALL_ADVANCED_REQUEST =
  'EMPLOYERS__GET_ALL_ADVANCED_REQUEST';
export const EMPLOYERS__GET_ALL_ADVANCED_SUCCESS =
  'EMPLOYERS__GET_ALL_ADVANCED_SUCCESS';
export const EMPLOYERS__GET_ALL_ADVANCED_FAILURE =
  'EMPLOYERS__GET_ALL_ADVANCED_FAILURE';

export const EMPLOYERS__GET_REQUEST = 'EMPLOYERS__GET_REQUEST';
export const EMPLOYERS__GET_SUCCESS = 'EMPLOYERS__GET_SUCCESS';
export const EMPLOYERS__GET_FAILURE = 'EMPLOYERS__GET_FAILURE';

export const EMPLOYERS__GET_STATS_REQUEST = 'EMPLOYERS__GET_STATS_REQUEST';
export const EMPLOYERS__GET_STATS_SUCCESS = 'EMPLOYERS__GET_STATS_SUCCESS';
export const EMPLOYERS__GET_STATS_FAILURE = 'EMPLOYERS__GET_STATS_FAILURE';

export const EMPLOYERS__GET_TO_CACHE_REQUEST =
  'EMPLOYERS__GET_TO_CACHE_REQUEST';
export const EMPLOYERS__GET_TO_CACHE_SUCCESS =
  'EMPLOYERS__GET_TO_CACHE_SUCCESS';
export const EMPLOYERS__GET_TO_CACHE_FAILURE =
  'EMPLOYERS__GET_TO_CACHE_FAILURE';

export const EMPLOYERS__GET_REPORTS_REQUEST = 'EMPLOYERS__GET_REPORTS_REQUEST';
export const EMPLOYERS__GET_REPORTS_SUCCESS = 'EMPLOYERS__GET_REPORTS_SUCCESS';
export const EMPLOYERS__GET_REPORTS_FAILURE = 'EMPLOYERS__GET_REPORTS_FAILURE';

export const EMPLOYERS__GET_APPLICATIONS_REQUEST =
  'EMPLOYERS__GET_APPLICATIONS_REQUEST';
export const EMPLOYERS__GET_APPLICATIONS_SUCCESS =
  'EMPLOYERS__GET_APPLICATIONS_SUCCESS';
export const EMPLOYERS__GET_APPLICATIONS_FAILURE =
  'EMPLOYERS__GET_APPLICATIONS_FAILURE';

  export const EMPLOYERS__GET_LOCATIONS_REQUEST =
  'EMPLOYERS__GET_LOCATIONS_REQUEST';
export const EMPLOYERS__GET_LOCATIONS_SUCCESS =
  'EMPLOYERS__GET_LOCATIONS_SUCCESS';
export const EMPLOYERS__GET_LOCATIONS_FAILURE =
  'EMPLOYERS__GET_LOCATIONS_FAILURE';

export const EMPLOYERS__UPDATE_REQUEST = 'EMPLOYERS__UPDATE_REQUEST';
export const EMPLOYERS__UPDATE_SUCCESS = 'EMPLOYERS__UPDATE_SUCCESS';
export const EMPLOYERS__UPDATE_FAILURE = 'EMPLOYERS__UPDATE_FAILURE';

export const EMPLOYERS__DELETE_REQUEST = 'EMPLOYERS__DELETE_REQUEST';
export const EMPLOYERS__DELETE_SUCCESS = 'EMPLOYERS__DELETE_SUCCESS';
export const EMPLOYERS__DELETE_FAILURE = 'EMPLOYERS__DELETE_FAILURE';

export const EMPLOYERS__DELETE_WATCH_REQUEST =
  'EMPLOYERS__DELETE_WATCH_REQUEST';
export const EMPLOYERS__DELETE_WATCH_SUCCESS =
  'EMPLOYERS__DELETE_WATCH_SUCCESS';
export const EMPLOYERS__DELETE_WATCH_FAILURE =
  'EMPLOYERS__DELETE_WATCH_FAILURE';

export const SEEKERS__GET_SAVED_CANDIDATES_REQUEST = 'SEEKERS__GET_SAVED_CANDIDATES_REQUEST';
export const SEEKERS__GET_SAVED_CANDIDATES_SUCCESS = 'SEEKERS__GET_SAVED_CANDIDATES_SUCCESS';
export const SEEKERS__GET_SAVED_CANDIDATES_FAILURE = 'SEEKERS__GET_SAVED_CANDIDATES_FAILURE';
export const SEEKERS__SAVED_CANDIDATES_REQUIRES_REFRESH = 'SEEKERS__SAVED_CANDIDATES_REQUIRES_REFRESH';

export const SEEKERS__MIXPANEL_TRACK_CANDIDATE_HOME_PAGE = 'SEEKERS__MIXPANEL_TRACK_CANDIDATE_HOME_PAGE';
export const SEEKERS__INCREMENT_NOTE_COUNT = 'SEEKERS__INCREMENT_NOTE_COUNT';
export const SEEKERS__DECREMENT_NOTE_COUNT = 'SEEKERS__DECREMENT_NOTE_COUNT';

export const SEEKERS__TOGGLE_SUPPORT_FLAG = 'SEEKERS__TOGGLE_SUPPORT_FLAG';

export const SEEKERS__UPDATE_ADMIN_APPROVAL = 'SEEKERS__UPDATE_ADMIN_APPROVAL';
export const SEEKERS__UPDATE_APPROVAL = 'SEEKERS__UPDATE_APPROVAL';


export const SEEKERS__CREATE_REQUEST = 'SEEKERS__CREATE_REQUEST';
export const SEEKERS__CREATE_SUCCESS = 'SEEKERS__CREATE_SUCCESS';
export const SEEKERS__CREATE_FAILURE = 'SEEKERS__CREATE_FAILURE';

export const SEEKERS__CANDIDATE_LIST_FILTER_ACTIVE = 'SEEKERS__CANDIDATE_LIST_FILTER_ACTIVE';



export const CANDIDATE_FILTERS_CLEAR = 'CANDIDATE_FILTERS_CLEAR';
export const CANDIDATE_ADVANCED_FILTER_SELECT = 'CANDIDATE_ADVANCED_FILTER_SELECT';
export const CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT = 'CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT';
export const CANDIDATE_FILTER_SEARCHTERM_UPDATE = 'CANDIDATE_FILTER_SEARCHTERM_UPDATE';
export const  CANDIDATE_FILTER_BUTTON_TOGGLE = 'CANDIDATE_FILTER_BUTTON_TOGGLE';
export const CANDIDATE_UPDATE_ALLFILTERS = 'CANDIDATE_UPDATE_ALLFILTERS';
export const CANDIDATE_CHANGE_TAB = 'CANDIDATE_CHANGE_TAB';

export const SEEKERS__CREATE_FEEDBACK_REQUEST =
  'SEEKERS__CREATE_FEEDBACK_REQUEST';
export const SEEKERS__CREATE_FEEDBACK_SUCCESS =
  'SEEKERS__CREATE_FEEDBACK_SUCCESS';
export const SEEKERS__CREATE_FEEDBACK_FAILURE =
  'SEEKERS__CREATE_FEEDBACK_FAILURE';

export const SEEKERS__GET_ALL_REQUEST = 'SEEKERS__GET_ALL_REQUEST';
export const SEEKERS__GET_ALL_SUCCESS = 'SEEKERS__GET_ALL_SUCCESS';
export const SEEKERS__GET_ALL_FAILURE = 'SEEKERS__GET_ALL_FAILURE';

export const SEEKERS__GET_ALL_ADVANCED_REQUEST = 'SEEKERS__GET_ALL_ADVANCED_REQUEST';
export const SEEKERS__GET_ALL_ADVANCED_SUCCESS = 'SEEKERS__GET_ALL_ADVANCED_SUCCESS';
export const SEEKERS__GET_ALL_ADVANCED_FAILURE = 'SEEKERS__GET_ALL_ADVANCED_FAILURE';

export const SEEKERS__GET_FILTER_COUNTS_REQUEST = 'SEEKERS__GET_FILTER_COUNTS_REQUEST';
export const SEEKERS__GET_FILTER_COUNTS_SUCCESS = 'SEEKERS__GET_FILTER_COUNTS_SUCCESS';
export const SEEKERS__GET_FILTER_COUNTS_FAILURE = 'SEEKERS__GET_FILTER_COUNTS_FAILURE';

export const SEEKERS__GET_FOR_EDIT_REQUEST = 'SEEKERS__GET_FOR_EDIT_REQUEST';
export const SEEKERS__GET_FOR_EDIT_SUCCESS = 'SEEKERS__GET_FOR_EDIT_SUCCESS';
export const SEEKERS__GET_FOR_EDIT_FAILURE = 'SEEKERS__GET_FOR_EDIT_FAILURE';

export const SEEKERS__GET_REQUEST = 'SEEKERS__GET_REQUEST';
export const SEEKERS__GET_SUCCESS = 'SEEKERS__GET_SUCCESS';
export const SEEKERS__GET_FAILURE = 'SEEKERS__GET_FAILURE';

export const SEEKERS__GET_FOR_CONNECTION_REQUEST = 'SEEKERS__GET_FOR_CONNECTION_REQUEST';
export const SEEKERS__GET_FOR_CONNECTION_SUCCESS = 'SEEKERS__GET_FOR_CONNECTION_SUCCESS';
export const SEEKERS__GET_FOR_CONNECTION_FAILURE = 'SEEKERS__GET_FOR_CONNECTION_FAILURE';

export const SEEKERS__GET_STATS_REQUEST = 'SEEKERS__GET_STATS_REQUEST';
export const SEEKERS__GET_STATS_SUCCESS = 'SEEKERS__GET_STATS_SUCCESS';
export const SEEKERS__GET_STATS_FAILURE = 'SEEKERS__GET_STATS_FAILURE';

export const SEEKERS__GET_MY_STATS_REQUEST = 'SEEKERS__GET_MY_STATS_REQUEST';
export const SEEKERS__GET_MY_STATS_SUCCESS = 'SEEKERS__GET_MY_STATS_SUCCESS';
export const SEEKERS__GET_MY_STATS_FAILURE = 'SEEKERS__GET_MY_STATS_FAILURE';

export const SEEKERS__GET_JOB_STATS_REQUEST = 'SEEKERS__GET_JOB_STATS_REQUEST';
export const SEEKERS__GET_JOB_STATS_SUCCESS = 'SEEKERS__GET_JOB_STATS_SUCCESS';
export const SEEKERS__GET_JOB_STATS_FAILURE = 'SEEKERS__GET_JOB_STATS_FAILURE';

export const SEEKERS__GET_TEAM_STATS_REQUEST =
  'SEEKERS__GET_TEAM_STATS_REQUEST';
export const SEEKERS__GET_TEAM_STATS_SUCCESS =
  'SEEKERS__GET_TEAM_STATS_SUCCESS';
export const SEEKERS__GET_TEAM_STATS_FAILURE =
  'SEEKERS__GET_TEAM_STATS_FAILURET';

export const SEEKERS__GET_JOB_MATCHES_REQUEST =
  'SEEKERS__GET_JOB_MATCHES_REQUEST';
export const SEEKERS__GET_JOB_MATCHES_SUCCESS =
  'SEEKERS__GET_JOB_MATCHES_SUCCESS';
export const SEEKERS__GET_JOB_MATCHES_FAILURE =
  'SEEKERS__GET_JOB_MATCHES_FAILURE';

export const SEEKERS__GET_SURVEY_STATS_REQUEST =
  'SEEKERS__GET_SURVEY_STATS_REQUEST';
export const SEEKERS__GET_SURVEY_STATS_SUCCESS =
  'SEEKERS__GET_SURVEY_STATS_SUCCESS';
export const SEEKERS__GET_SURVEY_STATS_FAILURE =
  'SEEKERS__GET_SURVEY_STATS_FAILURE';

export const SEEKERS__GET_PLACEMENT_STATS_REQUEST =
  'SEEKERS__GET_PLACEMENT_STATS_REQUEST';
export const SEEKERS__GET_PLACEMENT_STATS_SUCCESS =
  'SEEKERS__GET_PLACEMENT_STATS_SUCCESS';
export const SEEKERS__GET_PLACEMENT_STATS_FAILURE =
  'SEEKERS__GET_PLACEMENT_STATS_FAILURE';

export const SEEKERS__UPDATE_REQUEST = 'SEEKERS__UPDATE_REQUEST';
export const SEEKERS__UPDATE_REQUEST_WITH_LOADING = 'SEEKERS__UPDATE_REQUEST_WITH_LOADING';
export const SEEKERS__UPDATE_SUCCESS = 'SEEKERS__UPDATE_SUCCESS';
export const SEEKERS__UPDATE_FAILURE = 'SEEKERS__UPDATE_FAILURE';

export const SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_REQUEST =
  'SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_REQUEST';
export const SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_SUCCESS =
  'SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_SUCCESS';
export const SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_FAILURE =
  'SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_FAILURE';

export const SEEKERS__GET_SEEKER_QUESTIONS_REQUEST =
  'SEEKERS__GET_SEEKER_QUESTIONS_REQUEST';
export const SEEKERS__GET_SEEKER_QUESTIONS_SUCCESS =
  'SEEKERS__GET_SEEKER_QUESTIONS_SUCCESS';
export const SEEKERS__GET_SEEKER_QUESTIONS_FAILURE =
  'SEEKERS__GET_SEEKER_QUESTIONS_FAILURE';

export const SEEKERS__NOTIFY_RECRUITER_REQUEST =
  'SEEKERS__NOTIFY_RECRUITER_REQUEST';
export const SEEKERS__NOTIFY_RECRUITER_SUCCESS =
  'SEEKERS__NOTIFY_RECRUITER_SUCCESS';
export const SEEKERS__NOTIFY_RECRUITER_FAILURE =
  'SEEKERS__NOTIFY_RECRUITER_FAILURE';

export const SEEKERS__FINISH_REQUEST = 'SEEKERS__FINISH_REQUEST';
export const SEEKERS__FINISH_SUCCESS = 'SEEKERS__FINISH_SUCCESS';
export const SEEKERS__FINISH_FAILURE = 'SEEKERS__FINISH_FAILURE';

export const SEEKERS__COMPLETE_REQUEST = 'SEEKERS__COMPLETE_REQUEST';
export const SEEKERS__COMPLETE_SUCCESS = 'SEEKERS__COMPLETE_SUCCESS';
export const SEEKERS__COMPLETE_FAILURE = 'SEEKERS__COMPLETE_FAILURE';

export const SEEKERS__DELETE_REQUEST = 'SEEKERS__DELETE_REQUEST';
export const SEEKERS__DELETE_SUCCESS = 'SEEKERS__DELETE_SUCCESS';
export const SEEKERS__DELETE_FAILURE = 'SEEKERS__DELETE_FAILURE';

export const SEEKERS__TRAIT_LEVEL_CHANGED = 'SEEKERS__TRAIT_LEVEL_CHANGED';
export const SEEKERS__TRAIT_NOTE_CHANGED = 'SEEKERS__TRAIT_NOTE_CHANGED';
export const SEEKERS__ACHIEVEMENT_NOTE_CHANGED =
  'SEEKERS__ACHIEVEMENT_NOTE_CHANGED';
export const SEEKERS__INTERESTS_NOTE_CHANGED =
  'SEEKERS__INTERESTS_NOTE_CHANGED';
export const SEEKERS__HIGHLIGHTS__CHANGED = 'SEEKERS__HIGHLIGHTS__CHANGED';
export const SEEKERS__ACHIEVEMENT_LEVEL_CHANGED =
  'SEEKERS__ACHIEVEMENT_LEVEL_CHANGED';
export const SEEKERS__CONTACT_INFO_CHANGED = 'SEEKERS__CONTACT_INFO_CHANGED';
export const SEEKERS__EDUCATION_CHANGED = 'SEEKERS__EDUCATION_CHANGED';
export const SEEKERS__SUMMARY_CHANGED = 'SEEKERS__SUMMARY_CHANGED';
export const SEEKERS__RESUME_CHANGED = 'SEEKERS__RESUME_CHANGED';
export const SEEKERS__AVATAR_CHANGED = 'SEEKERS__AVATAR_CHANGES';
export const SEEKERS__EXPERIENCE_CHANGED = 'SEEKERS__EXPERIENCE_CHANGED';
export const SEEKERS__SKILLS_CHANGED = 'SEEKERS__SKILLS_CHANGED';
export const SEEKER__RETURNING_USER_EMAIL = 'SEEKER__RETURNING_USER_EMAIL';

export const SEEKERS__INVITE_RESPONSE_REQUEST = 'SEEKERS__INVITE_RESPONSE_REQUEST';
export const SEEKERS__INVITE_RESPONSE_SUCCESS = 'SEEKERS__INVITE_RESPONSE_SUCCESS';
export const SEEKERS__INVITE_RESPONSE_FAILURE = 'SEEKERS__INVITE_RESPONSE_FAILURE';

export const SEEKERS__GET_APPLICATIONS_REQUEST = 'SEEKERS__GET_APPLICATIONS_REQUEST';
export const SEEKERS__GET_APPLICATIONS_SUCCESS = 'SEEKERS__GET_APPLICATIONS_SUCCESS';
export const SEEKERS__GET_APPLICATIONS_FAILURE = 'SEEKERS__GET_APPLICATIONS_FAILURE';
export const SEEKERS__GET_APPLICATIONS_RESET = 'SEEKERS__GET_APPLICATIONS_RESET';

export const SEEKERS__INVITE_LIST_UPDATED = 'SEEKERS__INVITE_LIST_UPDATED';

export const SIGNUP__SELECTED_SKILL_TAGS_CHANGED =
  'SIGNUP__SELECTED_SKILL_TAGS_CHANGED';

export const TEAMS__CREATE_REQUEST = 'TEAMS__CREATE_REQUEST';
export const TEAMS__CREATE_SUCCESS = 'TEAMS__CREATE_SUCCESS';
export const TEAMS__CREATE_FAILURE = 'TEAMS__CREATE_FAILURE';

export const TEAMS__CREATE_WATCH_REQUEST = 'TEAMS__CREATE_WATCH_REQUEST';
export const TEAMS__CREATE_WATCH_SUCCESS = 'TEAMS__CREATE_WATCH_SUCCESS';
export const TEAMS__CREATE_WATCH_FAILURE = 'TEAMS__CREATE_WATCH_FAILURE';

export const TEAMS__CREATE_SEEKER_WATCH_REQUEST =
  'TEAMS__CREATE_SEEKER_WATCH_REQUEST';
export const TEAMS__CREATE_SEEKER_WATCH_SUCCESS =
  'TEAMS__CREATE_SEEKER_WATCH_SUCCESS';
export const TEAMS__CREATE_SEEKER_WATCH_FAILURE =
  'TEAMS__CREATE_SEEKER_WATCH_FAILURE';

export const TEAMS__GET_ALL_REQUEST = 'TEAMS__GET_ALL_REQUEST';
export const TEAMS__GET_ALL_SUCCESS = 'TEAMS__GET_ALL_SUCCESS';
export const TEAMS__GET_ALL_FAILURE = 'TEAMS__GET_ALL_FAILURE';

export const TEAMS__GET_ALL_ADVANCED_REQUEST =
  'TEAMS__GET_ALL_ADVANCED_REQUEST';
export const TEAMS__GET_ALL_ADVANCED_SUCCESS =
  'TEAMS__GET_ALL_ADVANCED_SUCCESS';
export const TEAMS__GET_ALL_ADVANCED_FAILURE =
  'TEAMS__GET_ALL_ADVANCED_FAILURE';

export const TEAMS__GET_FOR_EDIT_REQUEST = 'TEAMS__GET_FOR_EDIT_REQUEST';
export const TEAMS__GET_FOR_EDIT_SUCCESS = 'TEAMS__GET_FOR_EDIT_SUCCESS';
export const TEAMS__GET_FOR_EDIT_FAILURE = 'TEAMS__GET_FOR_EDIT_FAILURE';

export const TEAMS__GET_REQUEST = 'TEAMS__GET_REQUEST';
export const TEAMS__GET_SUCCESS = 'TEAMS__GET_SUCCESS';
export const TEAMS__GET_FAILURE = 'TEAMS__GET_FAILURE';

export const TEAMS__GET_TO_CACHE_REQUEST = 'TEAMS__GET_TO_CACHE_REQUEST';
export const TEAMS__GET_TO_CACHE_SUCCESS = 'TEAMS__GET_TO_CACHE_SUCCESS';
export const TEAMS__GET_TO_CACHE_FAILURE = 'TEAMS__GET_TO_CACHE_FAILURE';

export const TEAMS__UPDATE_REQUEST = 'TEAMS__UPDATE_REQUEST';
export const TEAMS__UPDATE_SUCCESS = 'TEAMS__UPDATE_SUCCESS';
export const TEAMS__UPDATE_FAILURE = 'TEAMS__UPDATE_FAILURE';

export const TEAMS__DELETE_REQUEST = 'TEAMS__DELETE_REQUEST';
export const TEAMS__DELETE_SUCCESS = 'TEAMS__DELETE_SUCCESS';
export const TEAMS__DELETE_FAILURE = 'TEAMS__DELETE_FAILURE';

export const TEAMS__DELETE_WATCH_REQUEST = 'TEAMS__DELETE_WATCH_REQUEST';
export const TEAMS__DELETE_WATCH_SUCCESS = 'TEAMS__DELETE_WATCH_SUCCESS';
export const TEAMS__DELETE_WATCH_FAILURE = 'TEAMS__DELETE_WATCH_FAILURE';

export const TEAMS__DELETE_SEEKER_WATCH_REQUEST =
  'TEAMS__DELETE_SEEKER_WATCH_REQUEST';
export const TEAMS__DELETE_SEEKER_WATCH_SUCCESS =
  'TEAMS__DELETE_SEEKER_WATCH_SUCCESS';
export const TEAMS__DELETE_SEEKER_WATCH_FAILURE =
  'TEAMS__DELETE_SEEKER_WATCH_FAILURE';

export const JOBS__CREATE_REQUEST = 'JOBS__CREATE_REQUEST';
export const JOBS__CREATE_SUCCESS = 'JOBS__CREATE_SUCCESS';
export const JOBS__CREATE_FAILURE = 'JOBS__CREATE_FAILURE';

export const JOBS__GET_ALL_REQUEST = 'JOBS__GET_ALL_REQUEST';
export const JOBS__GET_ALL_SUCCESS = 'JOBS__GET_ALL_SUCCESS';
export const JOBS__GET_ALL_FAILURE = 'JOBS__GET_ALL_FAILURE';

export const JOBS__GET_JOB_STATS_REQUEST = 'JOBS__GET_JOB_STATS_REQUEST';
export const JOBS__GET_JOB_STATS_SUCCESS = 'JOBS__GET_JOB_STATS_SUCCESS';
export const JOBS__GET_JOB_STATS_FAILURE = 'JOBS__GET_JOB_STATS_FAILURE';

export const JOBS__GET_ALL_ADVANCED_REQUEST = 'JOBS__GET_ALL_ADVANCED_REQUEST';
export const JOBS__GET_ALL_ADVANCED_SUCCESS = 'JOBS__GET_ALL_ADVANCED_SUCCESS';
export const JOBS__GET_ALL_ADVANCED_FAILURE = 'JOBS__GET_ALL_ADVANCED_FAILURE';

export const JOBS__CLEAR_ALL = 'JOBS__CLEAR_ALL';
export const JOBS__GET_BY_TEAM_REQUEST = 'JOBS__GET_BY_TEAM_REQUEST';
export const JOBS__GET_BY_TEAM_SUCCESS = 'JOBS__GET_BY_TEAM_SUCCESS';
export const JOBS__GET_BY_TEAM_FAILURE = 'JOBS__GET_BY_TEAM_FAILURE';

export const JOBS__GET_BY_TEAM_ADVANCED_REQUEST =
  'JOBS__GET_BY_TEAM_ADVANCED_REQUEST';
export const JOBS__GET_BY_TEAM_ADVANCED_SUCCESS =
  'JOBS__GET_BY_TEAM_ADVANCED_SUCCESS';
export const JOBS__GET_BY_TEAM_ADVANCED_FAILURE =
  'JOBS__GET_BY_TEAM_ADVANCED_FAILURE';

export const JOBS__GET_BY_TEAM_TO_CACHE_REQUEST =
  'JOBS__GET_BY_TEAM_TO_CACHE_REQUEST';
export const JOBS__GET_BY_TEAM_TO_CACHE_SUCCESS =
  'JOBS__GET_BY_TEAM_TO_CACHE_SUCCESS';
export const JOBS__GET_BY_TEAM_TO_CACHE_FAILURE =
  'JOBS__GET_BY_TEAM_TO_CACHE_FAILURE';

export const JOBS__GET_REQUEST = 'JOBS__GET_REQUEST';
export const JOBS__GET_SUCCESS = 'JOBS__GET_SUCCESS';
export const JOBS__GET_FAILURE = 'JOBS__GET_FAILURE';
export const JOBS__CLEAR_SELECTED_JOB = 'JOBS__CLEAR_SELECTED_JOB';

export const JOBS__GET_FOR_EDIT_REQUEST = 'JOBS__GET_FOR_EDIT_REQUEST';
export const JOBS__GET_FOR_EDIT_SUCCESS = 'JOBS__GET_FOR_EDIT_SUCCESS';
export const JOBS__GET_FOR_EDIT_FAILURE = 'JOBS__GET_FOR_EDIT_FAILURE';

export const JOBS__GET_FOR_CLONE_REQUEST = 'JOBS__GET_FOR_CLONE_REQUEST';
export const JOBS__GET_FOR_CLONE_SUCCESS = 'JOBS__GET_FOR_CLONE_SUCCESS';
export const JOBS__GET_FOR_CLONE_FAILURE = 'JOBS__GET_FOR_CLONE_FAILURE';

export const JOBS__GET_TO_CACHE_REQUEST = 'JOBS__GET_TO_CACHE_REQUEST';
export const JOBS__GET_TO_CACHE_SUCCESS = 'JOBS__GET_TO_CACHE_SUCCESS';
export const JOBS__GET_TO_CACHE_FAILURE = 'JOBS__GET_TO_CACHE_FAILURE';
export const JOBS__GET_MATCHES_SUCCESS = 'JOBS__GET_MATCHES_SUCCESS';

export const JOBS__CREATE_WATCH_REQUEST = 'JOBS__CREATE_WATCH_REQUEST';
export const JOBS__CREATE_WATCH_SUCCESS = 'JOBS__CREATE_WATCH_SUCCESS';
export const JOBS__CREATE_WATCH_FAILURE = 'JOBS__CREATE_WATCH_FAILURE';

export const JOBS__CREATE_APPLIED_REQUEST = 'JOBS__CREATE_APPLIED_REQUEST';
export const JOBS__CREATE_APPLIED_SUCCESS = 'JOBS__CREATE_APPLIED_SUCCESS';
export const JOBS__CREATE_APPLIED_FAILURE = 'JOBS__CREATE_APPLIED_FAILURE';

export const JOBS__REPORT_ISSUE_REQUEST = 'JOBS__REPORT_ISSUE_REQUEST';
export const JOBS__REPORT_ISSUE_SUCCESS = 'JOBS__REPORT_ISSUE_SUCCESS';
export const JOBS__REPORT_ISSUE_FAILURE = 'JOBS__REPORT_ISSUE_FAILURE';

export const JOBS__CREATE_APPLIED_REQUEST_BY_SEEKER =
  'JOBS__CREATE_APPLIED_REQUEST_BY_SEEKER';
export const JOBS__CREATE_APPLIED_SUCCESS_BY_SEEKER =
  'JOBS__CREATE_APPLIED_SUCCESS_BY_SEEKER';
export const JOBS__CREATE_APPLIED_FAILURE_BY_SEEKER =
  'JOBS__CREATE_APPLIED_FAILURE_BY_SEEKER';

export const JOBS__GET_WATCHES_REQUEST = 'JOBS__GET_WATCHES_REQUEST';
export const JOBS__GET_WATCHES_SUCCESS = 'JOBS__GET_WATCHES_SUCCESS';
export const JOBS__GET_WATCHES_FAILURE = 'JOBS__GET_WATCHES_FAILURE';

export const JOBS__GET_APPLICATIONS_REQUEST = 'JOBS__GET_APPLICATIONS_REQUEST';
export const JOBS__GET_APPLICATIONS_SUCCESS = 'JOBS__GET_APPLICATIONS_SUCCESS';
export const JOBS__GET_APPLICATIONS_FAILURE = 'JOBS__GET_APPLICATIONS_FAILURE';

export const JOBS__GET_INVITATIONS_REQUEST = 'JOBS__GET_INVITATIONS_REQUEST';
export const JOBS__GET_INVITATIONS_SUCCESS = 'JOBS__GET_INVITATIONS_SUCCESS';
export const JOBS__GET_INVITATIONS_FAILURE = 'JOBS__GET_INVITATIONS_FAILURE';

export const JOBS__GET_REPORTS_REQUEST = 'JOBS__GET_REPORTS_REQUEST';
export const JOBS__GET_REPORTS_SUCCESS = 'JOBS__GET_REPORTS_SUCCESS';
export const JOBS__GET_REPORTS_FAILURE = 'JOBS__GET_REPORTS_FAILURE';

export const JOBS__GET_CATEGORY_REQUEST = 'JOBS__GET_CATEGORY_REQUEST';
export const JOBS__GET_CATEGORY_SUCCESS = 'JOBS__GET_CATEGORY_SUCCESS';
export const JOBS__GET_CATEGORY_FAILURE = 'JOBS__GET_CATEGORY_FAILURE';

export const JOB_AND_TEAM_SEARCH_UPDATE_HAS_VIEWED_FINAL_JOB_FLAG = 'JOB_AND_TEAM_SEARCH_UPDATE_HAS_VIEWED_FINAL_JOB_FLAG';

export const SEEKERS__GET_REPORTS_REQUEST = 'SEEKERS__GET_REPORTS_REQUEST';
export const SEEKERS__GET_REPORTS_SUCCESS = 'SEEKERS__GET_REPORTS_SUCCESS';
export const SEEKERS__GET_REPORTS_FAILURE = 'SEEKERS__GET_REPORTS_FAILURE';

export const JOBS__GET_HANDPICKED_TO_CACHE_REQUEST =
  'JOBS__GET_HANDPICKED_TO_CACHE_REQUEST';
export const JOBS__GET_HANDPICKED_TO_CACHE_SUCCESS =
  'JOBS__GET_HANDPICKED_TO_CACHE_SUCCESS';
export const JOBS__GET_HANDPICKED_TO_CACHE_FAILURE =
  'JOBS__GET_HANDPICKED_TO_CACHE_FAILURE';

export const JOBS__GET_JOB_CARD_REQUEST = 'JOBS__GET_JOB_CARD_REQUEST';
export const JOBS__GET_JOB_CARD_SUCCESS = 'JOBS__GET_JOB_CARD_SUCCESS';
export const JOBS__GET_JOB_CARD_FAILURE = 'JOBS__GET_JOB_CARD_FAILURE';

export const JOBS__UPDATE_REQUEST = 'JOBS__UPDATE_REQUEST';
export const JOBS__UPDATE_SUCCESS = 'JOBS__UPDATE_SUCCESS';
export const JOBS__UPDATE_FAILURE = 'JOBS__UPDATE_FAILURE';

export const JOBS__DELETE_REQUEST = 'JOBS__DELETE_REQUEST';
export const JOBS__DELETE_SUCCESS = 'JOBS__DELETE_SUCCESS';
export const JOBS__DELETE_FAILURE = 'JOBS__DELETE_FAILURE';

export const JOBS__RESET_PUBLISHED_DATE_REQUEST = 'JOBS__RESET_PUBLISHED_DATE_REQUEST';
export const JOBS__RESET_PUBLISHED_DATE_SUCCESS = 'JOBS__RESET_PUBLISHED_DATE_SUCCESS';
export const JOBS__RESET_PUBLISHED_DATE_FAILURE = 'JOBS__RESET_PUBLISHED_DATE_FAILURE';

export const JOBS__DELETE_WATCH_REQUEST = 'JOBS__DELETE_WATCH_REQUEST';
export const JOBS__DELETE_WATCH_SUCCESS = 'JOBS__DELETE_WATCH_SUCCESS';
export const JOBS__DELETE_WATCH_FAILURE = 'JOBS__DELETE_WATCH_FAILURE';

export const JOBS__DELETE_APPLIED_REQUEST = 'JOBS__DELETE_APPLIED_REQUEST';
export const JOBS__DELETE_APPLIED_SUCCESS = 'JOBS__DELETE_APPLIED_SUCCESS';
export const JOBS__DELETE_APPLIED_FAILURE = 'JOBS__DELETE_APPLIED_FAILURE';

export const MATCH_PARAMETERS__CREATE_REQUEST =
  'MATCH_PARAMETERS__CREATE_REQUEST';
export const MATCH_PARAMETERS__CREATE_SUCCESS =
  'MATCH_PARAMETERS__CREATE_SUCCESS';
export const MATCH_PARAMETERS__CREATE_FAILURE =
  'MATCH_PARAMETERS__CREATE_FAILURE';

export const MATCH_PARAMETERS__GET_ALL_REQUEST =
  'MATCH_PARAMETERS__GET_ALL_REQUEST';
export const MATCH_PARAMETERS__GET_ALL_SUCCESS =
  'MATCH_PARAMETERS__GET_ALL_SUCCESS';
export const MATCH_PARAMETERS__GET_ALL_FAILURE =
  'MATCH_PARAMETERS__GET_ALL_FAILURE';

export const MATCH_PARAMETERS__GET_REQUEST = 'MATCH_PARAMETERS__GET_REQUEST';
export const MATCH_PARAMETERS__GET_SUCCESS = 'MATCH_PARAMETERS__GET_SUCCESS';
export const MATCH_PARAMETERS__GET_FAILURE = 'MATCH_PARAMETERS__GET_FAILURE';

export const MATCH_PARAMETERS__GET_ALL_ADVANCED_REQUEST =
  'MATCH_PARAMETERS__GET_ALL_ADVANCED_REQUEST';
export const MATCH_PARAMETERS__GET_ALL_ADVANCED_SUCCESS =
  'MATCH_PARAMETERS__GET_ALL_ADVANCED_SUCCESS';
export const MATCH_PARAMETERS__GET_ALL_ADVANCED_FAILURE =
  'MATCH_PARAMETERS__GET_ALL_ADVANCED_FAILURE';

export const MATCH_PARAMETERS__UPDATE_REQUEST =
  'MATCH_PARAMETERS__UPDATE_REQUEST';
export const MATCH_PARAMETERS__UPDATE_SUCCESS =
  'MATCH_PARAMETERS__UPDATE_SUCCESS';
export const MATCH_PARAMETERS__UPDATE_FAILURE =
  'MATCH_PARAMETERS__UPDATE_FAILURE';

export const MATCH_PARAMETERS__DELETE_REQUEST =
  'MATCH_PARAMETERS__DELETE_REQUEST';
export const MATCH_PARAMETERS__DELETE_SUCCESS =
  'MATCH_PARAMETERS__DELETE_SUCCESS';
export const MATCH_PARAMETERS__DELETE_FAILURE =
  'MATCH_PARAMETERS__DELETE_FAILURE';

export const MATCH_PARAMETERS__RECALC_REQUEST =
  'MATCH_PARAMETERS__RECALC_REQUEST';
export const MATCH_PARAMETERS__RECALC_SUCCESS =
  'MATCH_PARAMETERS__RECALC_SUCCESS';
export const MATCH_PARAMETERS__RECALC_FAILURE =
  'MATCH_PARAMETERS__RECALC_FAILURE';

export const SKILL_TAGS__GET_REQUEST = 'SKILL_TAGS__GET_REQUEST';
export const SKILL_TAGS__GET_SUCCESS = 'SKILL_TAGS__GET_SUCCESS';
export const SKILL_TAGS__GET_FAILURE = 'SKILL_TAGS__GET_FAILURE';

export const SKILL_TAGS_TREE__GET_REQUEST = 'SKILL_TAGS_TREE__GET_REQUEST';
export const SKILL_TAGS_TREE__GET_SUCCESS = 'SKILL_TAGS_TREE__GET_SUCCESS';
export const SKILL_TAGS_TREE__GET_FAILURE = 'SKILL_TAGS_TREE__GET_FAILURE';

export const SKILL_TAGS_TREE__SUBCATEGORY_TOGGLE = 'SKILL_TAGS_TREE__SUBCATEGORY_TOGGLE';
export const SKILL_TAGS_TREE__CATEGORY_TOGGLE = 'SKILL_TAGS_TREE__CATEGORY_TOGGLE';
export const SKILL_TAGS_TREE__COLLAPSE_ALL = 'SKILL_TAGS_TREE__COLLAPSE_ALL';


export const SKILL_TAGS__GET_TOP_REQUEST = 'SKILL_TAGS__GET_TOP_REQUEST';
export const SKILL_TAGS__GET_TOP_SUCCESS = 'SKILL_TAGS__GET_TOP_SUCCESS';
export const SKILL_TAGS__GET_TOP_FAILURE = 'SKILL_TAGS__GET_TOP_FAILURE';

export const SKILL_TAGS__GET_ADVANCED_REQUEST =
  'SKILL_TAGS__GET_ADVANCED_REQUEST';
export const SKILL_TAGS__GET_ADVANCED_SUCCESS =
  'SKILL_TAGS__GET_ADVANCED_SUCCESS';
export const SKILL_TAGS__GET_ADVANCED_FAILURE =
  'SKILL_TAGS__GET_ADVANCED_FAILURE';

export const SKILL_TAGS__CREATE_REQUEST = 'SKILL_TAGS__CREATE_REQUEST';
export const SKILL_TAGS__CREATE_SUCCESS = 'SKILL_TAGS__CREATE_SUCCESS';
export const SKILL_TAGS__CREATE_FAILURE = 'SKILL_TAGS__CREATE_FAILURE';

export const INVITATION_CODES__CREATE_REQUEST =
  'INVITATION_CODES__CREATE_REQUEST';
export const INVITATION_CODES__CREATE_SUCCESS =
  'INVITATION_CODES__CREATE_SUCCESS';
export const INVITATION_CODES__CREATE_FAILURE =
  'INVITATION_CODES__CREATE_FAILURE';

export const INVITATION_CODES__GET_ALL_REQUEST =
  'INVITATION_CODES__GET_ALL_REQUEST';
export const INVITATION_CODES__GET_ALL_SUCCESS =
  'INVITATION_CODES__GET_ALL_SUCCESS';
export const INVITATION_CODES__GET_ALL_FAILURE =
  'INVITATION_CODES__GET_ALL_FAILURE';

export const INVITATION_CODES__GET_ALL_ADVANCED_REQUEST =
  'INVITATION_CODES__GET_ALL_ADVANCED_REQUEST';
export const INVITATION_CODES__GET_ALL_ADVANCED_SUCCESS =
  'INVITATION_CODES__GET_ALL_ADVANCED_SUCCESS';
export const INVITATION_CODES__GET_ALL_ADVANCED_FAILURE =
  'INVITATION_CODES__GET_ALL_ADVANCED_FAILURE';

export const INVITATION_CODES__GET_REQUEST = 'INVITATION_CODES__GET_REQUEST';
export const INVITATION_CODES__GET_SUCCESS = 'INVITATION_CODES__GET_SUCCESS';
export const INVITATION_CODES__GET_FAILURE = 'INVITATION_CODES__GET_FAILURE';

export const INVITATION_CODES__EDIT_DIALOG_SHOW =
  'INVITATION_CODES__EDIT_DIALOG_SHOW';

export const INVITATION_CODES__UPDATE_REQUEST =
  'INVITATION_CODES__UPDATE_REQUEST';
export const INVITATION_CODES__UPDATE_SUCCESS =
  'INVITATION_CODES__UPDATE_SUCCESS';
export const INVITATION_CODES__UPDATE_FAILURE =
  'INVITATION_CODES__UPDATE_FAILURE';

export const INVITATION_CODES__DELETE_REQUEST =
  'INVITATION_CODES__DELETE_REQUEST';
export const INVITATION_CODES__DELETE_SUCCESS =
  'INVITATION_CODES__DELETE_SUCCESS';
export const INVITATION_CODES__DELETE_FAILURE =
  'INVITATION_CODES__DELETE_FAILURE';

export const INVITATION_CODES__VALIDATE_REQUEST =
  'INVITATION_CODES__VALIDATE_REQUEST';
export const INVITATION_CODES__VALIDATE_SUCCESS =
  'INVITATION_CODES__VALIDATE_SUCCESS';
export const INVITATION_CODES__VALIDATE_FAILURE =
  'INVITATION_CODES__VALIDATE_FAILURE';

export const INVITATIONS__CREATE_REQUEST = 'INVITATIONS__CREATE_REQUEST';
export const INVITATIONS__CREATE_SUCCESS = 'INVITATIONS__CREATE_SUCCESS';
export const INVITATIONS__CREATE_FAILURE = 'INVITATIONS__CREATE_FAILURE';

export const MULTIPLE_INVITATIONS__CREATE_REQUEST = 'MULTIPLE_INVITATIONS__CREATE_REQUEST';
export const MULTIPLE_INVITATIONS__CREATE_SUCCESS = 'MULTIPLE_INVITATIONS__CREATE_SUCCESS';
export const MULTIPLE_INVITATIONS__CREATE_FAILURE = 'MULTIPLE_INVITATIONS__CREATE_FAILURE';

export const INVITATIONS__GET_ALL_REQUEST = 'INVITATIONS__GET_REQUEST';
export const INVITATIONS__GET_ALL_SUCCESS = 'INVITATIONS__GET_SUCCESS';
export const INVITATIONS__GET_ALL_FAILURE = 'INVITATIONS__GET_FAILURE';

export const INVITATIONS__GET_REPORTS_SUCCESS =
  'INVITATIONS__GET_REPORTS_SUCCESS';

export const CONNECTIONS__GET_ALL_REQUEST = 'CONNECTIONS__GET_ALL_REQUEST';
export const CONNECTIONS__GET_ALL_SUCCESS = 'CONNECTIONS__GET_ALL_SUCCESS';
export const CONNECTIONS__GET_ALL_FAILURE = 'CONNECTIONS__GET_ALL_FAILURE';

export const CONNECTIONS__CREATE_REQUEST = 'CONNECTIONS__CREATE_REQUEST';
export const CONNECTIONS__CREATE_SUCCESS = 'CONNECTIONS__CREATE_SUCCESS';
export const CONNECTIONS__CREATE_FAILURE = 'CONNECTIONS__CREATE_FAILURE';
export const CONNECTIONS__CREATE_RESET = 'CONNECTIONS__CREATE_RESET';

export const CONNECTIONS__SEEKER_CREATE_REQUEST = 'CONNECTIONS__SEEKER_CREATE_REQUEST';
export const CONNECTIONS__SEEKER_CREATE_SUCCESS = 'CONNECTIONS__SEEKER_CREATE_SUCCESS';
export const CONNECTIONS__SEEKER_CREATE_FAILURE = 'CONNECTIONS__SEEKER_CREATE_FAILURE';

export const CONNECTIONS__SEEKER_GET_REQUEST = 'CONNECTIONS__SEEKER_GET_REQUEST';
export const CONNECTIONS__SEEKER_GET_SUCCESS = 'CONNECTIONS__SEEKER_GET_SUCCESS';
export const CONNECTIONS__SEEKER_GET_FAILURE = 'CONNECTIONS__SEEKER_GET_FAILURE';

export const CONNECTIONS__RECENT_CONNECTION_GET_REQUEST = 'CONNECTIONS__RECENT_CONNECTION_GET_REQUEST';
export const CONNECTIONS__RECENT_CONNECTION_GET_SUCCESS = 'CONNECTIONS__RECENT_CONNECTION_GET_SUCCESS';
export const CONNECTIONS__RECENT_CONNECTION_GET_FAILURE = 'CONNECTIONS__RECENT_CONNECTION_GET_FAILURE';

export const CONNECTIONS__UPDATE_STATE = 'CONNECTIONS__UPDATE_STATE';
export const CONNECTIONS__UPDATE_CURRENT_STEP = 'CONNECTIONS__UPDATE_CURRENT_STEP';

export const CONNECTIONS__GET_REPORTS_REQUEST =
  'CONNECTIONS__GET_REPORTS_REQUEST';
export const CONNECTIONS__GET_REPORTS_SUCCESS =
  'CONNECTIONS__GET_REPORTS_SUCCESS';
export const CONNECTIONS__GET_REPORTS_FAILURE =
  'CONNECTIONS__GET_REPORTS_FAILURE';

export const CANDIDATE_SEARCH__UPDATE_STATE = 'CANDIDATE_SEARCH__UPDATE_STATE';
export const CANDIDATE_SEARCH__CLEAR_ALL = 'CANDIDATE_SEARCH__CLEAR_ALL';

export const JOB_AND_TEAM_SEARCH__UPDATE_STATE =
  'JOB_AND_TEAM_SEARCH__UPDATE_STATE';

export const JOB_SEARCH__GET_JOB_MATCHES_REQUEST =
  'JOB_SEARCH__GET_JOB_MATCHES_REQUEST';
export const JOB_SEARCH__GET_JOB_MATCHES_SUCCESS =
  'JOB_SEARCH__GET_JOB_MATCHES_SUCCESS';
export const JOB_SEARCH__GET_JOB_MATCHES_FAILURE =
  'JOB_SEARCH__GET_JOB_MATCHES_FAILURE';

export const TEAM_SEARCH__GET_TEAM_MATCHES_REQUEST =
  'TEAM_SEARCH__GET_TEAM_MATCHES_REQUEST';
export const TEAM_SEARCH__GET_TEAM_MATCHES_SUCCESS =
  'TEAM_SEARCH__GET_TEAM_MATCHES_SUCCESS';
export const TEAM_SEARCH__GET_TEAM_MATCHES_FAILURE =
  'TEAM_SEARCH__GET_TEAM_MATCHES_FAILURE';

export const RECRUITER_JOB_AND_TEAM_SEARCH__UPDATE_STATE =
  'RECRUITER_JOB_AND_TEAM_SEARCH__UPDATE_STATE';

export const RECRUITER_JOB_SEARCH__GET_JOBS_REQUEST =
  'RECRUITER_JOB_SEARCH__GET_JOBS_REQUESTT';
export const RECRUITER_JOB_SEARCH__GET_JOBS_SUCCESS =
  'RECRUITER_JOB_SEARCH__GET_JOBS_SUCCESS';
export const RECRUITER_JOB_SEARCH__GET_JOBS_FAILURE =
  'RECRUITER_JOB_SEARCH__GET_JOBS_FAILURE';

  export const ALL_JOB_SEARCH__UPDATE_EMPLOYER_ID= "ALL_JOB_SEARCH__UPDATE_EMPLOYER_ID";
  export const ALL_JOB_SEARCH__GET_JOB_MATCHES_REQUEST =
  'ALL_JOB_SEARCH__GET_JOB_MATCHES_REQUEST';
export const ALL_JOB_SEARCH__GET_JOB_MATCHES_SUCCESS =
  'ALL_JOB_SEARCH__GET_JOB_MATCHES_SUCCESS';
export const ALL_JOB_SEARCH__GET_JOB_MATCHES_FAILURE =
  'ALL_JOB_SEARCH__GET_JOB_MATCHES_FAILURE';

  export const ALL_JOB_SEARCH__UPDATE_STATE =
  'ALL_JOB_SEARCH__UPDATE_STATE';

export const RECRUITER_TEAM_SEARCH__GET_TEAMS_REQUEST =
  'RECRUITER_TEAM_SEARCH__GET_TEAMS_REQUEST';
export const RECRUITER_TEAM_SEARCH__GET_TEAMS_SUCCESS =
  'RECRUITER_TEAM_SEARCH__GET_TEAMS_SUCCESS';
export const RECRUITER_TEAM_SEARCH__GET_TEAMS_FAILURE =
  'RECRUITER_TEAM_SEARCH__GET_TEAMS_FAILURE';

export const ZIPCODES__GET_ALL_REQUEST = 'ZIPCODES__GET_ALL_REQUEST';
export const ZIPCODES__GET_ALL_SUCCESS = 'ZIPCODES__GET_ALL_SUCCESS';
export const ZIPCODES__GET_ALL_FAILURE = 'ZIPCODES__GET_ALL_FAILURE';

export const ZIPCODES__GET_REQUEST = 'ZIPCODES__GET_REQUEST';
export const ZIPCODES__GET_SUCCESS = 'ZIPCODES__GET_SUCCESS';
export const ZIPCODES__GET_FAILURE = 'ZIPCODES__GET_FAILURE';

export const JOB_CARD__CREATE = 'JOB_CARD__CREATE';
export const JOB_CARD__UPDATE_TAB_INDEX = 'JOB_CARD__UPDATE_TAB_INDEX';
export const JOB_CARD__UPDATE_MAXIMIZED = 'JOB_CARD__UPDATE_MAXIMIZED';

export const ALL_JOBS_DT__UPDATE_CURRENT_PAGE =
  'ALL_JOBS_DT__UPDATE_CURRENT_PAGE';
export const ALL_JOBS_DT__UPDATE_SEARCH_TERM =
  'ALL_JOBS_DT__UPDATE_SEARCH_TERM';
export const ALL_JOBS_DT__UPDATE_SORT_TERM = 'ALL_JOBS_DT__UPDATE_SORT_TERM';

export const APPLICATIONS_DT__UPDATE_CURRENT_PAGE =
  'APPLICATIONS_DT__UPDATE_CURRENT_PAGE';
export const APPLICATIONS_DT__UPDATE_SEARCH_TERM =
  'APPLICATIONS_DT__UPDATE_SEARCH_TERM';
export const APPLICATIONS_DT__UPDATE_SORT_TERM =
  'APPLICATIONS_DT__UPDATE_SORT_TERM';

export const INVITATIONS_DT__UPDATE_CURRENT_PAGE =
  'INVITATIONS_DT__UPDATE_CURRENT_PAGE';
export const INVITATIONS_DT__UPDATE_SEARCH_TERM =
  'INVITATIONS_DT__UPDATE_SEARCH_TERM';
export const INVITATIONS_DT__UPDATE_SORT_TERM =
  'INVITATIONS_DT__UPDATE_SORT_TERM';

export const SEARCH_DT__UPDATE_CURRENT_PAGE = 'SEARCH_DT__UPDATE_CURRENT_PAGE';
export const SEARCH_DT__UPDATE_SEARCH_TERM = 'SEARCH_DT__UPDATE_SEARCH_TERM';
export const SEARCH_DT__UPDATE_SORT_TERM = 'SEARCH_DT__UPDATE_SORT_TERM';

export const CANDIDATES_DT__UPDATE_CURRENT_PAGE =
  'CANDIDATES_DT__UPDATE_CURRENT_PAGE';
export const CANDIDATES_DT__UPDATE_SEARCH_TERM =
  'CANDIDATES_DT__UPDATE_SEARCH_TERM';
export const CANDIDATES_DT__UPDATE_SORT_TERM =
  'CANDIDATES_DT__UPDATE_SORT_TERM';

export const EMPLOYERS_DT__UPDATE_CURRENT_PAGE =
  'EMPLOYERS_DT__UPDATE_CURRENT_PAGE';
export const EMPLOYERS_DT__UPDATE_SEARCH_TERM =
  'EMPLOYERS_DT__UPDATE_SEARCH_TERM';
export const EMPLOYERS_DT__UPDATE_SORT_TERM = 'EMPLOYERS_DT__UPDATE_SORT_TERM';

export const RECRUITERS_DT__CREATE_EMPLOYER = 'RECRUITERS_DT__CREATE_EMPLOYER';
export const RECRUITERS_DT__UPDATE_CURRENT_PAGE =
  'RECRUITERS_DT__UPDATE_CURRENT_PAGE';
export const RECRUITERS_DT__UPDATE_SEARCH_TERM =
  'RECRUITERS_DT__UPDATE_SEARCH_TERM';
export const RECRUITERS_DT__UPDATE_SORT_TERM =
  'RECRUITERS_DT__UPDATE_SORT_TERM';

export const TEAM_PROFILE_DT__UPDATE_CURRENT_PAGE =
  'TEAM_PROFILE_DT__UPDATE_CURRENT_PAGE';
export const TEAM_PROFILE_DT__CREATE_TEAM = 'TEAM_PROFILE_DT__CREATE_TEAM';

export const TEAMS_DT__CREATE_EMPLOYER = 'TEAMS_DT__CREATE_EMPLOYER';
export const TEAMS_DT__UPDATE_CURRENT_PAGE = 'TEAMS_DT__UPDATE_CURRENT_PAGE';
export const TEAMS_DT__UPDATE_SEARCH_TERM = 'TEAMS_DT__UPDATE_SEARCH_TERM';
export const TEAMS_DT__UPDATE_SORT_TERM = 'TEAMS_DT__UPDATE_SORT_TERM';

export const JOBS_DT__CREATE_TEAM = 'JOBS_DT__CREATE_TEAM';
export const JOBS_DT__UPDATE_CURRENT_PAGE = 'JOBS_DT__UPDATE_CURRENT_PAGE';
export const JOBS_DT__UPDATE_SEARCH_TERM = 'JOBS_DT__UPDATE_SEARCH_TERM';
export const JOBS_DT__UPDATE_SORT_TERM = 'JOBS_DT__UPDATE_SORT_TERM';

export const ALL_MATCHES_DT__UPDATE_CURRENT_PAGE =
  'ALL_MATCHES_DT__UPDATE_CURRENT_PAGE';
export const ALL_MATCHES_DT__UPDATE_SEARCH_TERM =
  'ALL_MATCHES_DT__UPDATE_SEARCH_TERM';
export const ALL_MATCHES_DT__UPDATE_SORT_TERM =
  'ALL_MATCHES_DT__UPDATE_SORT_TERM';

  export const CONNECTIONS_DT__UPDATE_CURRENT_PAGE =
  'CONNECTIONS_DT__UPDATE_CURRENT_PAGE';
export const CONNECTIONS_DT__UPDATE_SEARCH_TERM =
  'CONNECTIONS_DT__UPDATE_SEARCH_TERM';
export const CONNECTIONS_DT__UPDATE_SORT_TERM =
  'CONNECTIONS_DT__UPDATE_SORT_TERM';
  export const CONNECTIONS_DT__UPDATE_RECRUITER =
  'CONNECTIONS_DT__UPDATE_RECRUITER';

export const INSTITUTIONS__GET_REPORTS_REQUEST =
  'INSTITUTIONS__GET_REPORTS_REQUEST';
export const INSTITUTIONS__GET_REPORTS_SUCCESS =
  'INSTITUTIONS__GET_REPORTS_SUCCESS';
export const INSTITUTIONS__GET_REPORTS_FAILURE =
  'INSTITUTIONS__GET_REPORTS_FAILURE';

export const INSTITUTIONS__GET_REQUEST = 'INSTITUTIONS__GET_REQUEST';
export const INSTITUTIONS__GET_SUCCESS = 'INSTITUTIONS__GET_SUCCESS';
export const INSTITUTIONS__GET_FAILURE = 'INSTITUTIONS__GET_FAILURE';

export const EMPLOYMENT_TYPES__GET_REQUEST = 'EMPLOYMENT_TYPES__GET_REQUEST';
export const EMPLOYMENT_TYPES__GET_SUCCESS = 'EMPLOYMENT_TYPES__GET_SUCCESS';
export const EMPLOYMENT_TYPES__GET_FAILURE = 'EMPLOYMENT_TYPES__GET_FAILURE';

export const ROLE_TYPES__GET_REQUEST = 'ROLE_TYPES__GET_REQUEST';
export const ROLE_TYPES__GET_SUCCESS = 'ROLE_TYPES__GET_SUCCESS';
export const ROLE_TYPES__GET_FAILURE = 'ROLE_TYPES__GET_FAILURE';

export const INDUSTRIES__GET_REQUEST = 'INDUSTRIES__GET_REQUEST';
export const INDUSTRIES__GET_SUCCESS = 'INDUSTRIES__GET_SUCCESS';
export const INDUSTRIES__GET_FAILURE = 'INDUSTRIES__GET_FAILURE';

export const REGIONS__GET_REQUEST = 'REGIONS__GET_REQUEST';
export const REGIONS__GET_SUCCESS = 'REGIONS__GET_SUCCESS';
export const REGIONS__GET_FAILURE = 'REGIONS__GET_FAILURE';

export const WORK_STYLES__GET_REQUEST = 'WORK_STYLES__GET_REQUEST';
export const WORK_STYLES__GET_SUCCESS = 'WORK_STYLES__GET_SUCCESS';
export const WORK_STYLES__GET_FAILURE = 'WORK_STYLES__GET_FAILURE';

export const COMPANIES__GET_REQUEST = 'COMPANIES__GET_REQUEST';
export const COMPANIES__GET_SUCCESS = 'COMPANIES__GET_SUCCESS';
export const COMPANIES__GET_FAILURE = 'COMPANIES__GET_FAILURE';

export const DEGREETYPES__GET_REQUEST = 'DEGREETYPES__GET_REQUEST';
export const DEGREETYPES__GET_SUCCESS = 'DEGREETYPES__GET_SUCCESS';
export const DEGREETYPES__GET_FAILURE = 'DEGREETYPES__GET_FAILURE';

export const EDUCATIONLEVELS__GET_REQUEST = 'EDUCATIONLEVELS__GET_REQUEST';
export const EDUCATIONLEVELS__GET_SUCCESS = 'EDUCATIONLEVELS__GET_SUCCESS';
export const EDUCATIONLEVELS__GET_FAILURE = 'EDUCATIONLEVELS__GET_FAILURE';

export const REFERRALSOURCES__GET_REQUEST = 'REFERRALSOURCES__GET_REQUEST';
export const REFERRALSOURCES__GET_SUCCESS = 'REFERRALSOURCES__GET_SUCCESS';
export const REFERRALSOURCES__GET_FAILURE = 'REFERRALSOURCES__GET_FAILURE';

export const INDUSTRYPREFERENCES__GET_REQUEST =
  'INDUSTRYPREFERENCES__GET_REQUEST';
export const INDUSTRYPREFERENCES__GET_SUCCESS =
  'INDUSTRYPREFERENCES__GET_SUCCESS';
export const INDUSTRYPREFERENCES__GET_FAILURE =
  'INDUSTRYPREFERENCES__GET_FAILURE';

export const CAMPUSES__GET_REQUEST = 'CAMPUSES__GET_REQUEST';
export const CAMPUSES__GET_SUCCESS = 'CAMPUSES__GET_SUCCESS';
export const CAMPUSES__GET_FAILURE = 'CAMPUSES__GET_FAILURE';

export const SCHOOLS__GET_REQUEST = 'SCHOOLS__GET_REQUEST';
export const SCHOOLS__GET_SUCCESS = 'SCHOOLS__GET_SUCCESS';
export const SCHOOLS__GET_FAILURE = 'SCHOOLS__GET_FAILURE';

export const MAJORS__GET_REQUEST = 'MAJORS__GET_REQUEST';
export const MAJORS__GET_SUCCESS = 'MAJORS__GET_SUCCESS';
export const MAJORS__GET_FAILURE = 'MAJORS__GET_FAILURE';

export const MINORS__GET_REQUEST = 'MINORS__GET_REQUEST';
export const MINORS__GET_SUCCESS = 'MINORS__GET_SUCCESS';
export const MINORS__GET_FAILURE = 'MINORS__GET_FAILURE';

export const APPLICATIONS__GET_REQUEST = 'APPLICATIONS__GET_REQUEST';
export const APPLICATIONS__GET_SUCCESS = 'APPLICATIONS__GET_SUCCESS';
export const APPLICATIONS__GET_FAILURE = 'APPLICATIONS__GET_FAILURE';
export const APPLICATIONS__UPDATE = 'APPLICATIONS__UPDATE';

export const APPLICATIONS__GET_STATS_REQUEST =
  'APPLICATIONS__GET_STATS_REQUEST';
export const APPLICATIONS__GET_STATS_SUCCESS =
  'APPLICATIONS__GET_STATS_SUCCESS';
export const APPLICATIONS__GET_STATS_FAILURE =
  'APPLICATIONS__GET_STATS_FAILURE';
export const APPLICATIONS__GET_REPORTS_REQUEST =
  'APPLICATIONS__GET_REPORTS_REQUEST';
export const APPLICATIONS__GET_REPORTS_SUCCESS =
  'APPLICATIONS__GET_REPORTS_SUCCESS';
export const APPLICATIONS__GET_REPORTS_FAILURE =
  'APPLICATIONS__GET_REPORTS_FAILURE';

export const STRATEGIC_BUSINESS_UNITS__GET_REQUEST =
  'STRATEGIC_BUSINESS_UNITS__GET_REQUEST';
export const STRATEGIC_BUSINESS_UNITS__GET_SUCCESS =
  'STRATEGIC_BUSINESS_UNITS__GET_SUCCESS';
export const STRATEGIC_BUSINESS_UNITS__GET_FAILURE =
  'STRATEGIC_BUSINESS_UNITS__GET_FAILURE';

// for resumes.js reducer
export const POLL_GET_STATUS_REQUEST = 'RESUMES/POLL_GET_STATUS_REQUEST';
export const POLL_GET_STATUS_SUCCESS = 'RESUMES/POLL_GET_STATUS_SUCCESS';
export const POLL_GET_STATUS_TIMEDOUT = 'RESUMES/POLL_GET_STATUS_TIMEDOUT';
export const GET_STATUS_SUCCESS = 'RESUMES/GET_STATUS_SUCCESS';
export const GET_STATUS_FAILURE = 'RESUMES/GET_STATUS_FAILURE';
export const RESUME_REVIEW_SET_INITIAL_SEGMENT =
  'RESUME_REVIEW_SET_INITIAL_SEGMENT';
export const RESUME_REVIEW_RESET_APPROVED_TOGGLE =
  'RESUME_REVIEW_RESET_APPROVED_TOGGLE';

export const APPLICANTS_CREATE_JOB_COUNT = 'APPLICANTS_CREATE_JOB_COUNT';

export const DEGREE_RANK__GET_REQUEST = 'DEGREE_RANK__GET_REQUEST';
export const DEGREE_RANK__GET_SUCCESS = 'DEGREE_RANK__GET_SUCCESS';
export const DEGREE_RANK_UNITS__GET_FAILURE = 'DEGREE_RANK_UNITS__GET_FAILURE';

export const SEGMENTS__GET_REQUEST = 'SEGMENTS__GET_REQUEST';
export const SEGMENTS__GET_SUCCESS = 'SEGMENTS__GET_SUCCESS';
export const SEGMENTS__GET_SUCCESS_ADD_ANY = 'SEGMENTS__GET_SUCCESS_ADD_ANY';
export const SEGMENTS__GET_FAILURE = 'SEGMENTS__GET_FAILURE';

export const SEGMENTS__GET_PARTNER_REQUEST = 'SEGMENTS__GET_PARTNER_REQUEST';
export const SEGMENTS__GET_PARTNER_SUCCESS = 'SEGMENTS__GET_PARTNER_SUCCESS';
export const SEGMENTS__GET_PARTNER_FAILURE = 'SEGMENTS__GET_PARTNER_FAILURE';

export const SEEKER__GET_CAN_EDIT_REQUEST = 'SEEKER__GET_CAN_EDIT_REQUEST';
export const SEEKER__GET_CAN_EDIT_SUCCESS = 'SEEKER__GET_CAN_EDIT_SUCCESS';
export const SEEKER__GET_CAN_EDIT_FAILURE = 'SEEKER__GET_CAN_EDIT_FAILURE';

export const EMPLOYER__GET_CAN_EDIT_REQUEST = 'EMPLOYER__GET_CAN_EDIT_REQUEST';
export const EMPLOYER__GET_CAN_EDIT_SUCCESS = 'EMPLOYER__GET_CAN_EDIT_SUCCESS';
export const EMPLOYER__GET_CAN_EDIT_FAILURE = 'EMPLOYER__GET_CAN_EDIT_FAILURE';

export const EMPLOYERS__GET_EMPLOYER_STATS_REQUEST = 'EMPLOYERS__GET_EMPLOYER_STATS_REQUEST';
export const EMPLOYERS__GET_EMPLOYER_STATS_SUCCESS = 'EMPLOYERS__GET_EMPLOYER_STATS_SUCCESS';
export const EMPLOYERS__GET_EMPLOYER_STATS_FAILURE = 'EMPLOYERS__GET_EMPLOYER_STATS_FAILURE';


export const JOB__GET_CAN_EDIT_REQUEST = 'JOB__GET_CAN_EDIT_REQUEST';
export const JOB__GET_CAN_EDIT_SUCCESS = 'JOB__GET_CAN_EDIT_SUCCESS';
export const JOB__GET_CAN_EDIT_FAILURE = 'JOB__GET_CAN_EDIT_FAILURE';

export const INTAKE__GET_REQUEST = 'INTAKE__GET_REQUEST';
export const INTAKE__GET_SUCCESS = 'INTAKE__GET_SUCCESS';
export const INTAKE__GET_FAILURE = 'INTAKE__GET_FAILURE';
export const INTAKE__UPDATE_LOCAL_DATA = 'INTAKE__UPDATE_LOCAL_DATA';

export const INTAKE__SAVE_REQUEST = 'INTAKE__SAVE_REQUEST';
export const INTAKE__SAVE_SUCCESS = 'INTAKE__SAVE_SUCCESS';
export const INTAKE__SAVE_FAILURE = 'INTAKE__SAVE_FAILURE';

export const INTAKE__SET_CALENDLY_SKIP_REQUEST = 'INTAKE__SET_CALENDLY_SKIP_REQUEST';
export const INTAKE__SET_CALENDLY_SKIP_SUCCESS = 'INTAKE__SET_CALENDLY_SKIP_SUCCESS';
export const INTAKE__SET_CALENDLY_SKIP_FAILURE = 'INTAKE__SET_CALENDLY_SKIP_FAILURE';

export const INTAKE__GET_BY_USER_REQUEST = 'INTAKE__GET_BY_USER_REQUEST';
export const INTAKE__GET_BY_USER_SUCCESS = 'INTAKE__GET_BY_USER_SUCCESS';
export const INTAKE__GET_BY_USER_FAILURE = 'INTAKE__GET_BY_USER_FAILURE';

export const UTILITY__UPDATE_CANVAS = 'UTILITY__UPDATE_CANVAS';
export const UTILITY__HIDE_BANNER = 'UTILITY__HIDE_BANNER';

export const SEEKERS__VALIDATE_CONSENT_REQUEST =
  'SEEKERS__VALIDATE_CONSENT_REQUEST';
export const SEEKERS__VALIDATE_CONSENT_SUCCESS =
  'SEEKERS__VALIDATE_CONSENT_SUCCESS';
export const SEEKERS__VALIDATE_CONSENT_FAILURE =
  'SEEKERS__VALIDATE_CONSENT_FAILURE';

export const QUESTIONS__GET_MA_ONBOARDING_REQUEST =
  'QUESTIONS__GET_MA_ONBOARDING_REQUEST';
export const QUESTIONS__GET_MA_ONBOARDING_REQUEST_SUCCESS =
  'QUESTIONS__GET_MA_ONBOARDING_REQUEST_SUCCESS';
export const QUESTIONS__GET_MA_ONBOARDING_REQUEST_FAILURE =
  'QUESTIONS__GET_MA_ONBOARDING_REQUEST_FAILURE';

export const JOBS__GET_APPLICATION_DATA_REQUEST =
  'JOBS__GET_APPLICATION_DATA_REQUEST';
export const JOBS__GET_APPLICATION_DATA_SUCCESS =
  'JOBS__GET_APPLICATION_DATA_SUCCESS';
export const JOBS__GET_APPLICATION_DATA_FAILURE =
  'JOBS__GET_APPLICATION_DATA_FAILURE';

export const JOBS__APPLY_REQUEST = 'JOBS__APPLY_REQUEST';
export const JOBS__APPLY_FAILURE = 'JOBS__APPLY_FAILURE';
export const JOBS__APPLY_SUCCESS = 'JOBS__APPLY_SUCCESS';

export const JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_REQUEST =
  'JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_REQUEST';
export const JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_SUCCESS =
  'JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_SUCCESS';
export const JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_FAILURE =
  'JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_FAILURE';

export const JOBS_APPLICATION_BROWSE__UPDATE_STATE =
  'JOBS_APPLICATION_BROWSE__UPDATE_STATE';
export const JOBS_APPLICATION_BROWSE__UPDATE_APPLICATION_LISTING_STATUS =
  'JOBS_APPLICATION_BROWSE__UPDATE_APPLICATION_LISTING_STATUS';

export const JOBS_APPLICATION_BROWSE__UPDATE_STATUS_REQUEST =
  'JOBS_APPLICATION_BROWSE__UPDATE_STATUS_REQUEST';
export const JOBS_APPLICATION_BROWSE__UPDATE_STATUS_SUCCESS =
  'JOBS_APPLICATION_BROWSE__UPDATE_STATUS_SUCCESS';
export const JOBS_APPLICATION_BROWSE__UPDATE_STATUS_FAILURE =
  'JOBS_APPLICATION_BROWSE__UPDATE_STATUS_FAILURE';

export const JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_REQUEST =
  'JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_REQUEST';
export const JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_SUCCESS =
  'JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_SUCCESS';
export const JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_FAILURE =
  'JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_FAILURE';

export const JOBS__GET_ALL_SUMMARIES_REQUEST =
  'JOBS__GET_ALL_SUMMARIES_REQUEST';
export const JOBS__GET_ALL_SUMMARIES_SUCCESS =
  'JOBS__GET_ALL_SUMMARIES_SUCCESS';
export const JOBS__GET_ALL_SUMMARIES_FAILURE =
  'JOBS__GET_ALL_SUMMARIES_FAILURE';

 export const JOBS__GET_APPLIED_TO_CLICKS_REQUEST =
  'JOBS__GET_APPLIED_TO_CLICKS_REQUEST';
export const JOBS__GET_APPLIED_TO_CLICKS_SUCCESS =
  'JOBS__GET_APPLIED_TO_CLICKS_SUCCESS';
export const JOBS__GET_APPLIED_TO_CLICKS_FAILURE =
  'JOBS__GET_APPLIED_TO_CLICKS_FAILURE';

export const JOBS__APPLY_TO_CLICK_REQUEST =
  'JOBS__APPLY_TO_CLICK_REQUEST';
export const JOBS__APPLY_TO_CLICK_SUCCESS =
  'JOBS__APPLY_TO_CLICK_SUCCESS';
export const JOBS__APPLY_TO_CLICK_FAILURE =
  'JOBS__APPLY_TO_CLICK_FAILURE';

 export const JOBS__APPLY_TO_RESPONSE_REQUEST =
  'JOBS__APPLY_TO_RESPONSE_REQUEST';
export const JOBS__APPLY_TO_RESPONSE_SUCCESS =
  'JOBS__APPLY_TO_RESPONSE_SUCCESS';
export const JOBS__APPLY_TO_RESPONSE_FAILURE =
  'JOBS__APPLY_TO_RESPONSE_FAILURE';

export const JOBS__EMPLOYER_JOBS_SORTED_REQUEST =
  'JOBS__EMPLOYER_JOBS_SORTED_REQUEST';
export const JOBS__EMPLOYER_JOBS_SORTED_SUCCESS =
  'JOBS__EMPLOYER_JOBS_SORTED_SUCCESS';
export const JOBS__EMPLOYER_JOBS_SORTED_FAILURE =
  'JOBS__EMPLOYER_JOBS_SORTED_FAILURE';


export const JOBS__EMPLOYER_JOBS_UNPUBLISH_REQUEST='JOBS__EMPLOYER_JOBS_UNPUBLISH_REQUEST';
export const JOBS__EMPLOYER_JOBS_UNPUBLISH_SUCCESS='JOBS__EMPLOYER_JOBS_UNPUBLISH_SUCCESS';
export const JOBS__EMPLOYER_JOBS_UNPUBLISH_FAILURE='JOBS__EMPLOYER_JOBS_UNPUBLISH_FAILURE';


export const JOBS__UNDO_APPLY_TO_RESPONSE_REQUEST =
  'JOBS__UNDO_APPLY_TO_RESPONSE_REQUEST';
export const JOBS__UNDO_APPLY_TO_RESPONSE_SUCCESS =
  'JOBS__UNDO_APPLY_TO_RESPONSE_SUCCESS';
export const JOBS__UNDO_APPLY_TO_RESPONSE_FAILURE =
  'JOBS__UNDO_APPLY_TO_RESPONSE_FAILURE';

  export const JOBS__REMOVE_APPLY_TO_CLICK =
  'JOBS__REMOVE_APPLY_TO_CLICK';

export const TASKS__GET_ALL_REQUEST = 'TASKS__GET_ALL_REQUEST';
export const TASKS__GET_ALL_SUCCESS = 'TASKS__GET_ALL_SUCCESS';
export const TASKS__GET_SUCCESS_NEW_PAGE = 'TASKS__GET_SUCCESS_NEW_PAGE';
export const TASKS__GET_ALL_ERROR = 'TASKS__GET_ALL_ERROR';

export const TASKS__GET_COUNTS_REQUEST = 'TASKS__GET_COUNTS_REQUEST';
export const TASKS__GET_COUNTS_SUCCESS = 'TASKS__GET_COUNTS_SUCCESS';
export const TASKS__GET_COUNTS_FAILURE = 'TASKS__GET_COUNTS_FAILURE';

export const TASKS__TOGGLE_EXPANDED_ROW = 'TASKS__TOGGLE_EXPANDED_ROW';
export const TASKS__INCREMENT_NOTE_COUNT = 'TASKS__INCREMENT_NOTE_COUNT';
export const TASKS__DECREMENT_NOTE_COUNT = 'TASKS__DECREMENT_NOTE_COUNT';

export const TASKS__DISMISS_ITEM_REQUEST = 'TASKS__DISMISS_ITEM_REQUEST';
export const TASKS__DISMISS_ITEM_SUCCESS = 'TASKS__DISMISS_ITEM_SUCCESS';
export const TASKS__DISMISS_ITEM_ERROR = 'TASKS__DISMISS_ITEM_ERROR';

export const TASKS__DISMISS_REQUEST = 'TASKS__DISMISS_REQUEST';
export const TASKS__DISMISS_SUCCESS = 'TASKS__DISMISS_SUCCESS';
export const TASKS__DISMISS_ERROR = 'TASKS__DISMISS_ERROR';

export const TASKS__EXECUTE_REQUEST = 'TASKS__EXECUTE_REQUEST';
export const TASKS__EXECUTE_SUCCESS = 'TASKS__EXECUTE_SUCCESS';
export const TASKS__EXECUTE_ERROR = 'TASKS__EXECUTE_ERROR';

export const  MEETING_STATUSES__GET_REQUEST = 'MEETING_STATUSES__GET_REQUEST';
export const  MEETING_STATUSES__GET_SUCCESS = 'MEETING_STATUSES__GET_SUCCESS';
export const  MEETING_STATUSES__GET_FAILURE = 'MEETING_STATUSES__GET_FAILURE';

export const  PROFILE_STATUSES__GET_REQUEST = 'PROFILE_STATUSES__GET_REQUEST';
export const  PROFILE_STATUSES__GET_SUCCESS = 'PROFILE_STATUSES__GET_SUCCESS';
export const  PROFILE_STATUSES__GET_FAILURE = 'PROFILE_STATUSES__GET_FAILURE';

export const EXPERIENCE_LEVELS__GET_REQUEST = 'EXPERIENCE_LEVELS__GET_REQUEST';
export const EXPERIENCE_LEVELS__GET_SUCCESS = 'EXPERIENCE_LEVELS__GET_SUCCESS';
export const EXPERIENCE_LEVELS__GET_FAILURE = 'EXPERIENCE_LEVELS__GET_FAILURE';

export const APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_REQUEST = 'APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_REQUEST';
export const APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_SUCCESS = 'APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_SUCCESS';
export const APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_FAILURE = 'APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_FAILURE';

export const APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_REQUEST = 'APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_REQUEST';
export const APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_SUCCESS = 'APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_SUCCESS';
export const APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_FAILURE = 'APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_FAILURE';

export const CANDIDATE_DISCOVERY__TOGGLE_EXPANDED_SECTION = 'CANDIDATE_DISCOVERY__TOGGLE_EXPANDED_SECTION';
export const CANDIDATE_DISCOVERY__INCREASE_LIST_ITEM_COUNT = 'CANDIDATE_DISCOVERY__INCREASE_LIST_ITEM_COUNT';
export const CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM = 'CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM';
export const CANDIDATE_DISCOVERY__FILTER_LIST = 'CANDIDATE_DISCOVERY__FILTER_LIST';
export const CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT = 'CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT';
export const CANDIDATE_DISCOVERY__CLEAR_ALL = 'CANDIDATE_DISCOVERY__CLEAR_ALL';
export const CANDIDATE_DISCOVERY__DATE_RADIO_SELECT = 'CANDIDATE_DISCOVERY__DATE_RADIO_SELECT';
export const CANDIDATE_DISCOVERY__JOB_SELECT = 'CANDIDATE_DISCOVERY__JOB_SELECT';
export const CANDIDATE_DISCOVERY__SORT_CHANGE = 'CANDIDATE_DISCOVERY__SORT_CHANGE';
export const CANDIDATE_DISCOVERY__CHANGE_PAGE = 'CANDIDATE_DISCOVERY__CHANGE_PAGE';
export const CANDIDATE_DISCOVERY__SEARCH_TERM = 'CANDIDATE_DISCOVERY__SEARCH_TERM';
export const CANDIDATE_DISCOVERY__TEAM_SELECT = 'CANDIDATE_DISCOVERY__TEAM_SELECT';
export const CANDIDATE_DISCOVERY__PRESELECT_JOB_REQUEST_LOADING = 'CANDIDATE_DISCOVERY__PRESELECT_JOB_REQUEST_LOADING';
export const CANDIDATE_DISCOVERY__SET_FILTER_BUTTON_ENABLED = 'CANDIDATE_DISCOVERY__SET_FILTER_BUTTON_ENABLED';
export const CANDIDATE_DISCOVERY__SET_CLEAR_FILTER_BUTTON_ENABLED = 'CANDIDATE_DISCOVERY__SET_CLEAR_FILTER_BUTTON_ENABLED';
export const CANDIDATE_DISCOVERY__SET_APPLY_BUSY = 'CANDIDATE_DISCOVERY__SET_APPLY_BUSY';
export const CANDIDATE_DISCOVERY__SET_COLLAPSE_ALL_FILTER_SECTIONS = 'CANDIDATE_DISCOVERY__SET_COLLAPSE_ALL_FILTER_SECTIONS';
export const CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED = "CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED";
export const SAVED_CANDIDATE__TOGGLE_EXPANDED_SECTION = 'SAVED_CANDIDATE__TOGGLE_EXPANDED_SECTION';
export const SAVED_CANDIDATE__INCREASE_LIST_ITEM_COUNT = 'SAVED_CANDIDATE__INCREASE_LIST_ITEM_COUNT';
export const SAVED_CANDIDATE__TOGGLE_FILTER_ITEM = 'SAVED_CANDIDATE__TOGGLE_FILTER_ITEM';
export const SAVED_CANDIDATE__FILTER_LIST = 'SAVED_CANDIDATE__FILTER_LIST';
export const SAVED_CANDIDATE__TOGGLE_LIST_DEFAULT = 'SAVED_CANDIDATE__TOGGLE_LIST_DEFAULT';
export const SAVED_CANDIDATE__CLEAR_ALL = 'SAVED_CANDIDATE__CLEAR_ALL';
export const SAVED_CANDIDATE__DATE_RADIO_SELECT = 'SAVED_CANDIDATE__DATE_RADIO_SELECT';
export const SAVED_CANDIDATE__SORT_CHANGE = 'SAVED_CANDIDATE__SORT_CHANGE';
export const SAVED_CANDIDATE__CHANGE_PAGE = 'SAVED_CANDIDATE__CHANGE_PAGE';
export const SAVED_CANDIDATE__SEARCH_TERM = 'SAVED_CANDIDATE__SEARCH_TERM';
export const SAVED_CANDIDATE__SET_FILTER_BUTTON_ENABLED = 'SAVED_CANDIDATE__SET_FILTER_BUTTON_ENABLED';
export const SAVED_CANDIDATE__SET_CLEAR_FILTER_BUTTON_ENABLED = 'SAVED_CANDIDATE__SET_CLEAR_FILTER_BUTTON_ENABLED';
export const SAVED_CANDIDATE__SET_APPLY_BUSY = 'SAVED_CANDIDATE__SET_APPLY_BUSY';
export const SAVED_CANDIDATE__SET_COLLAPSE_ALL_FILTER_SECTIONS = 'SAVED_CANDIDATE__SET_COLLAPSE_ALL_FILTER_SECTIONS';
export const SAVED_CANDIDATE__JOB_SELECT = 'SAVED_CANDIDATE__JOB_SELECT';
export const STATES__GET_REQUEST = 'STATES__GET_REQUEST';
export const STATES__GET_SUCCESS = 'STATES__GET_SUCCESS';
export const STATES__GET_FAILURE = 'STATES__GET_FAILURE';
export const SEEKERS__OPT_OUT_REQUEST = 'SEEKERS__OPT_OUT_REQUEST';
export const SEEKERS__OPT_OUT_SUCCESS = 'SEEKERS__OPT_OUT_SUCCESS';
export const SEEKERS__OPT_OUT_FAILURE = 'SEEKERS__OPT_OUT_FAILURE';

export const SEEKERS__UNDO_OPT_OUT_REQUEST = 'SEEKERS__UNDO_OPT_OUT_REQUEST';
export const SEEKERS__UNDO_OPT_OUT_SUCCESS = 'SEEKERS__UNDO_OPT_OUT_SUCCESS';
export const SEEKERS__UNDO_OPT_OUT_FAILURE = 'SEEKERS__UNDO_OPT_OUT_FAILURE';

export const JOBS__GET_TO_CACHE_NOT_FOUND = 'JOBS__GET_TO_CACHE_NOT_FOUND';


export const SEEKERS__SEEKER_FOR_EDIT_CHANGED = 'SEEKERS__SEEKER_FOR_EDIT_CHANGED';

export const EMPLOYERS__GET_ACTIVE_JOBS_REQUEST = 'EMPLOYERS__GET_ACTIVE_JOBS_REQUEST';
export const EMPLOYERS__GET_ACTIVE_JOBS_SUCCESS = 'EMPLOYERS__GET_ACTIVE_JOBS_SUCCESS';
export const EMPLOYERS__GET_ACTIVE_JOBS_FAILURE = 'EMPLOYERS__GET_ACTIVE_JOBS_FAILURE';

export const ACCESS_PROHIBITED_ERROR = 'ACCESS_PROHIBITED_ERROR';

export const SEEKERS__CHANGE_EMAIL_USER_CHANGED = 'SEEKERS__CHANGE_EMAIL_USER_CHANGED';
