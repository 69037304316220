import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from '../../utils/commonHooks';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import _ from 'lodash';
import 'url-search-params-polyfill';
import $ from 'jquery';

import * as seekersActions from 'src/actions/seekers';
import * as employersActions from '../../actions/employers';
import * as teamsActions from 'src/actions/teams';
import * as jobsActions from 'src/actions/jobs';
import * as jobAndTeamSearchActions from 'src/actions/jobAndTeamSearch';
import * as recruiterJobAndTeamSearchActions from '../../actions/recruiterJobAndTeamSearch';
import * as employmentTypeActions from 'src/actions/employmentTypes';
import * as regionsActions from 'src/actions/regions';
import * as roleTypesActions from 'src/actions/roleTypes';
import * as industriesActions from 'src/actions/industries';
import * as segmentActions from '../../actions/SegmentActions';

import JobProfile from 'src/components/JobProfile';
import FilterTagGroup from 'src/components/FilterTagGroup';
import FilterTagCheckbox from 'src/components/FilterTagCheckbox';
import {PageFooter, PageHeader} from 'src/layout';
import {jobIsWatched, jobIsApplied} from 'src/utils/jobHelper';
import {findMatchData} from 'src/utils/matchHelper';
import NoRoles from 'src/components/NoRoles';
import RoleCard from 'src/pages/jobDiscovery/RoleCard.js';
import * as degreeTypeRankActions from '../../actions/DegreeTypeActions';
import * as searchActions from '../../actions/AscendDataTableAction';
import {includesApprenticeProgram} from '../../utils/miscHelper';
import {getExperienceLevels} from 'src/actions/experienceLevels';
import {
  getApprenticePathways,
  getAllApprenticePathways,
} from 'src/actions/apprenticePathwaysActions';
import styles from 'src/pages/jobDiscovery/JobDiscoveryPage.module.scss';

import PageControl from 'src/components/PageControl';
import AscendSelect from 'src/components/controls/AscendSelect';
import {remoteStatusTypes} from './roleUtil';
import AdvancedJobCard from 'src/components/AdvancedJobCard';
import {
  HeartDefaultSvg,
  InvitationQuickFilterSvg,
} from '../../assets/svg/svgComponents';
import InputSearch from '../../components/controls/inputs/InputSearch';
import DidYouApply from 'src/components/DidYouApply';

import arrowDown from 'src/assets/images/ArrowDown.svg';
import arrowUp from 'src/assets/images/ArrowUp.svg';

import {trackVisibility, track} from '../../utils/analytics';
import ContentLoader from 'react-content-loader';

import PreviousButton from 'src/components/PreviousButton';
import CandidateNotifyMissingRole from '../../components/Candidate/CandidateNotifyMissingRole';
import {InView} from 'react-intersection-observer';
import EducationalCard from 'src/pages/jobDiscovery/EducationalCard';
import ListBatchActionDrawer from 'src/components/Candidate/ListBatchActionDrawer';
import InviteToJobsModal from 'src/components/InviteToJobsModal';
import InvitesSentModal from 'src/components/InvitesSentModal';
import AreYouSureModal from 'src/components/AreYouSureModal';

const TOP_OF_PAGE_MATCH_COUNT_CUTOFF = 9;
const BATCH_SELECT_MAXIMUM_COUNT = 50;
const BATCH_SIZE_FOR_CONFIRM_MODAL = 30;
class JobDiscoveryPage extends React.Component {
  getParam = (paramName) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramName);
  };

  hasParam = (paramName) => {
    const params = new URLSearchParams(window.location.search);
    return params.has(paramName);
  };

  state = {
    jobProfileOpen: false,
    sortDropdownLabel:
      this.props.loggedInSeekerId !== null && !this.props.isApprenticeProgram
        ? 'Recommended'
        : 'Sort by',
    maSeeker: false,
    expandFilters: false,
    isSeeker: false,
    initialApprenticePathwaysRequested: false,
    isMobile: window.matchMedia('(max-width: 430px)').matches,
    employmentType: this.getParam('employment_type'),
    industry: this.getParam('industry'),
    hasParamFilters:
      !!this.getParam('employment_type') || !!this.getParam('industry'),
    allJobsLoaded: false,
    batchActionList: [],
    inviteToJobsModalIsOpen: false,
    duplicateInvitations: [],
  };

  isLoading = () => {
    return (
      this.props.recruiterJobAndTeamSearchProps.jobsLoading ||
      this.props.jobAndTeamSearchProps.jobsLoading ||
      this.props.apprenticePathwaysLoading
    );
  };

  handleEmploymentTypeParam() {
    if (!this.state.employmentType) return;

    const employmentTypeIds = this.state.employmentType.split(',').map(Number);

    for (const id of employmentTypeIds) {
      const tag = {
        name: `${id}employmentType`,
        selected: true,
      };
      this.handleTagChange('employmentTypeIds', tag);
    }
  }

  handleIndustriesParam() {
    if (!this.state.industry) return;

    const industryIds = this.state.industry.split(',').map(Number);

    for (const id of industryIds) {
      const tag = {
        name: `${id}industry`,
        selected: true,
      };
      this.handleTagChange('industryIds', tag);
    }
  }

  componentDidMount() {
    if (this.isLoading()) {
      return;
    }

    if (this.state.hasParamFilters === false) {
      this.props.resetJobAndTeamSearchStateDefault(); // Clear all previous filters from redux
    }

    const maSegment = this.props.isApprenticeProgram;

    const roleIdParam = this.getParam('roleId');

    const shouldLoadSavedRoles = this.hasParam('savedRoles');

    const seekerId = this.getSeekerId();

    if (maSegment) {
      if (seekerId !== null) {
        this.props.getSeekerJobStats(seekerId);
        this.props.getSeeker(seekerId);
      }

      this.handleAllClick();
    } else if (roleIdParam) {
      this.handleJobClicked(roleIdParam);
    } else if (seekerId !== null) {
      this.props.getSeekerJobStats(seekerId);
      this.props.getSeeker(seekerId);
      if (shouldLoadSavedRoles) {
        this.handleOnlySavedRolesClick();
      } else if (this.state.hasParamFilters === false) {
        this.setState({sortDropdownLabel: 'Recommended'});
        this.props.getSeekerMatchingJobs(seekerId);
      }
    } else {
      this.props.getRecruiterJobs();
      this.props.getSegments(false);
    }

    this.props.getEmploymentTypes();
    this.props.getRegions();
    this.props.getRoleTypes();
    this.props.getIndustries();
    this.props.getDegreeTypesRank();
    this.props.getJobCategories();

    if (this.props.experienceLevels.length === 0) {
      this.props.getExperienceLevels();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isApprenticeProgram ||
      this.props.role === 'ASCEND_RECRUITER'
    ) {
      if (prevProps.seeker.highSchoolId !== this.props.seeker.highSchoolId) {
        // high school changed, run by high school
        this.props.getApprenticePathways(this.props.seeker.highSchoolId);
        this.setState({initialApprenticePathwaysRequested: true});
      } else if (
        this.props.role === 'ASCEND_RECRUITER' &&
        !this.state.initialApprenticePathwaysRequested
      ) {
        this.props.getApprenticePathways();
        this.setState({initialApprenticePathwaysRequested: true});
      } else if (
        // admin user with no loaded pathways
        this.state.initialApprenticePathwaysRequested &&
        !this.props.apprenticePathwaysLoading &&
        this.props.apprenticePathways.length === 0
      ) {
        // nothing is currently loading AND there are no pathways AND we did perform the initial request
        this.props.getAllApprenticePathways();
        this.setState({initialApprenticePathwaysRequested: false});
      }
    }

    if (this.state.allJobsLoaded === false) {
      if (this.state.hasParamFilters) {
        this.props.seekerSelectAll();
        this.handleEmploymentTypeParam();
        this.handleIndustriesParam();
      }

      this.setState({allJobsLoaded: true});
    }
  }

  getSeekerId = () => {
    let seekerId = null;
    if (this.props.loggedInSeekerId) {
      seekerId = this.props.loggedInSeekerId;
    } else if (this.props.match?.params.id) {
      seekerId = this.props.match.params.id;
    }
    return seekerId;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.seeker.id) {
      return {
        watchedJobIds: nextProps.seeker.watchedJobs.map((j) => j.id),
      };
    }
    return null;
  }

  handleOnlyMatchesClick = () => {
    this.props.updateSearchTerm('');
    const seekerId = this.getSeekerId();
    this.props.selectOnlyMatchesAndSearch(seekerId);
    this.setState({
      sortDropdownLabel: 'Newest First',
    });
  };

  handleOnlyWorkStyleMatchesClick = () => {
    this.props.updateSearchTerm('');
    const seekerId = this.getSeekerId();
    this.props.selectOnlyWorkStyleMatchesAndSearch(seekerId);
    this.setState({
      sortDropdownLabel: 'Sort by',
    });
  };

  handleOnlySavedRolesClick = () => {
    const seekerId = this.getSeekerId();
    this.props.selectOnlyFavoriteRolesAndSearch(seekerId);

    this.setState({
      sortDropdownLabel: 'Newest First',
    });
  };

  handleOnlyInvitedRolesClick = () => {
    const seekerId = this.getSeekerId();
    this.props.selectOnlyInvitedRolesAndSearch(seekerId);
    this.setState({
      sortDropdownLabel: 'Newest First',
    });
  };

  handleOnlyHandpickedRolesClick = () => {
    const seekerId = this.getSeekerId();
    this.props.selectOnlyHandpickedRolesAndSearch(seekerId);
    this.setState({
      sortDropdownLabel: 'Sort by',
    });
  };

  handleAllClick = () => {
    this.props.updateSearchTerm('');
    const seekerId = this.getSeekerId();

    const maSeeker = this.props.isApprenticeProgram;

    seekerId !== null
      ? this.props.seekerSelectAllAndSearch(
          seekerId,
          maSeeker ? '-matchesMyInterests' : ''
        )
      : this.props.selectAllAndSearch();

    this.setState({
      sortDropdownLabel:
        seekerId !== null && maSeeker ? 'Matches My Interests' : 'Recommended',
    });
  };

  handleTagChange = (tagType, newTag) => {
    const seekerId = this.getSeekerId();

    seekerId !== null
      ? this.props.changeSeekerFilterTagAndSearch(
          seekerId,
          tagType,
          newTag.name,
          newTag.selected
        )
      : this.props.changeFilterTagAndSearch(
          tagType,
          newTag.name,
          newTag.selected
        );
  };

  handleTagClear = (tagType) => {
    const seekerId = this.getSeekerId();
    seekerId !== null
      ? this.props.clearSeekerFilterGroupAndSearch(seekerId, tagType)
      : this.props.clearFilterGroupAndSearch(tagType);
  };

  handleFilterChange = (searchTerm, search = true) => {
    const seekerId = this.getSeekerId();
    const isSeeker = seekerId !== null;
    if (searchTerm || searchTerm === '') {
      isSeeker
        ? this.props.updateSeekerJobAndTeamSearchState({searchTerm})
        : this.props.updateJobAndTeamSearchState({searchTerm});
      this.props.updateSearchTerm(searchTerm);
    }

    if (search) {
      isSeeker
        ? this.props.changeSeekerJobSearchTermAndSearch(seekerId, searchTerm)
        : this.props.changeJobSearchTermAndSearch(searchTerm);
    }
  };

  handleJobSortDropdownChange = (sortBy) => {
    const seekerId = this.getSeekerId();
    const isSeeker = seekerId !== null;

    let sortTerm = '';
    if (sortBy.label === 'Recommended') {
      sortTerm = '-overallscore';
    } else if (sortBy.label === 'Matches My Interests') {
      sortTerm = '-matchesMyInterests';
    } else if (sortBy.label === 'Newest First') {
      sortTerm = '-publisheddate'; // publishedAt descending
    }

    isSeeker
      ? this.props.changeSeekerJobSortAndSearch(seekerId, sortTerm)
      : this.props.changeJobSortAndSearch(sortTerm);
    this.setState({sortDropdownLabel: sortBy.label});
  };

  handleJobWatchClicked = (job) => {
    let newWatchedJobIds = [];
    if (_.includes(this.state.watchedJobIds, job.id)) {
      this.props.deleteJobWatch(job.id);
      newWatchedJobIds = _.reject(this.state.watchedJobIds, (x) => {
        return x === job.id;
      });
    } else {
      this.props.createJobWatch(job);

      newWatchedJobIds = [...this.state.watchedJobIds, job.id];
    }
    this.setState({watchedJobIds: newWatchedJobIds});
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  handleProfileClosed = () => {
    this.setState({jobProfileOpen: false});
  };

  handleJobClicked = (id) => {
    this.props.getJobToCache(id);
    this.setState({jobProfileOpen: true, jobId: id});
  };

  handleJobPageChange = (newPage) => {
    const seekerId = this.getSeekerId();
    seekerId !== null
      ? this.props.changeSeekerJobPageAndSearch(seekerId, newPage)
      : this.props.changeJobPageAndSearch(newPage);
    window.scrollTo(0, 0);
  };

  handleJobSortChange = (sortBy, sortAsc) => {
    const seekerId = this.getSeekerId();
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    seekerId !== null
      ? this.props.changeSeekerJobSortAndSearch(
          seekerId,
          sortTerm,
          sortBy,
          sortAsc
        )
      : this.props.changeJobSortAndSearch(sortTerm, sortBy, sortAsc);
    this.setState({
      sortDropdownLabel: 'Sort by',
    });
  };

  renderContentLoader = (index) => {
    if (this.state.isMobile) {
      return (
        <ContentLoader
          key={`Loader-${index}`}
          speed={3}
          height={300}
          width={'100%'}
          backgroundColor="#f1f4f9"
          foregroundColor="#cbe1ff"
          style={{
            marginBottom: '20px',
            backgroundColor: '#ffffff',
          }}
        >
          <rect x="5" y="5" rx="0" ry="0" width="97%" height="210" />
          <rect x="10" y="225" rx="3" ry="3" width="260" height="22" />
          <rect x="30" y="255" rx="3" ry="3" width="240" height="15" />
          <rect x="30" y="278" rx="3" ry="3" width="240" height="15" />
        </ContentLoader>
      );
    } else {
      return (
        <ContentLoader
          key={`Loader-${index}`}
          speed={3}
          height={165}
          width={'100%'}
          backgroundColor="#f1f4f9"
          foregroundColor="#cbe1ff"
          style={{
            marginBottom: '18px',
            backgroundColor: '#ffffff',
          }}
        >
          <rect x="5" y="5" rx="0" ry="0" width="270" height="154" />
          <rect x="290" y="18" rx="3" ry="3" width="30%" height="22" />
          <rect x="310" y="50" rx="3" ry="3" width="37%" height="15" />
          <rect x="310" y="75" rx="3" ry="3" width="37%" height="15" />
        </ContentLoader>
      );
    }
  };

  handleBatchSelectChanged = (isChecked, job) => {
    let currentList = [].concat(this.state.batchActionList);

    if (isChecked) {
      //add to the array.  Order is not important
      if (currentList.length < BATCH_SELECT_MAXIMUM_COUNT) {
        currentList.push(job);
      }
    } else {
      // find and remove from the array
      const jobIndex = currentList.findIndex((x) => x.id === job.id);
      currentList.splice(jobIndex, 1);
    }

    this.setState({batchActionList: currentList});
  };

  handleSelectAllClick = () => {
    let newList = this.state.batchActionList;
    let listCount = newList.length;

    if (listCount < BATCH_SELECT_MAXIMUM_COUNT) {
      const newSelection = this.props.recruiterJobAndTeamSearchProps.jobs;

      for (var i = 0; i < newSelection.length; i++) {
        if (!newList.includes(newSelection[i])) {
          newList.push(newSelection[i]);

          listCount++;
          if (listCount >= BATCH_SELECT_MAXIMUM_COUNT) {
            break;
          }
        }
      }

      this.setState({batchActionList: newList});
    }
  };

  handleClearAllClick = () => {
    this.setState({batchActionList: []});
  };

  handleBatchInviteClicked = () => {
    const selectedJobs = this.state.batchActionList;

    if (selectedJobs.length > BATCH_SIZE_FOR_CONFIRM_MODAL) {
      this.setState({confirmBatchInviteRequired: true});
    } else {
      this.setState({inviteToJobsModalIsOpen: true});
    }
  };

  processBatchInvite = () => {
    this.setState({inviteToJobsModalIsOpen: true});
  };

  handleInviteToJobSendClicked = (duplicates) => {
    this.setState({
      inviteToJobsModalIsOpen: false,
    });

    if (duplicates && duplicates.length !== 0) {
      this.setState({
        duplicateInvitations: duplicates,
        isInvitesSentModalOpen: true,
      });
    }

    this.handleClearAllClick();
  };

  render() {
    const isSeeker = this.getSeekerId() !== null;
    const propsToUse = isSeeker
      ? 'jobAndTeamSearchProps'
      : 'recruiterJobAndTeamSearchProps';

    const {
      showJobNotFoundModal,
      [propsToUse]: {
        jobs,
        allRolesSelected,
        myMatchesSelected,
        invitedRolesSelected,
        favoriteRolesSelected,
        jobsCurrentPage,
        jobsCurrentItemsPerPage,
        jobsTotalItems,
        resultTypeTags,
        startDateTags,
        employmentTypeIds,
        experienceLevelIds,
        regionIds,
        roleTypeIds,
        industryIds,
        degreeTypeRankIds,
        segmentIds,
        remoteStatus,
        apprenticePathwayIds,
        hasViewedFinalJob,
      },
      jobStats: {
        jobAllCount,
        jobMatchCount,

        jobAllInvitations,
        jobFavorites,
      },
      teamStats: {teamMatchCount},
      regions,
      roleTypes,
      industries,
      apprenticePathways,
    } = this.props;

    const contentLoaders = [];

    for (let i = 0; i < 4; i++) {
      contentLoaders.push(this.renderContentLoader(i));
    }

    const maSeeker =
      this.props.isApprenticeProgram && this.getSeekerId() !== null;

    let empTypeList = maSeeker
      ? this.props.employmentTypes
      : this.props.employmentTypes.filter((y) => y.name !== 'Apprenticeship');

    const sortedApprenticePathways = _.sortBy(
      apprenticePathways,
      (x) => x.order
    );

    const sortedRegions = _.sortBy(
      regions.filter((r) => r.name !== 'Any'),
      (x) => x.order
    );

    const sortedRoleTypes = _.sortBy(
      roleTypes.filter((r) => r.name !== 'Any'),
      (x) => x.name
    );

    const sortedIndustries = _.sortBy(
      industries.filter((r) => r.name !== 'Any'),
      (x) => x.order
    );
    const sortedDegreeTypes = _.sortBy(
      this.props.degreeTypeRanks.filter((r) => r.name !== 'Any'),
      (x) => x.name
    );

    const sortedSegments = _.sortBy(
      this.props.segments.filter((r) => r.name !== 'Any'),
      (x) => x.name
    );

    let roleCards = [];

    roleCards.push({
      count: jobAllCount || 0,
      label: 'All Roles',
      onClick: this.handleAllClick,
      selected: allRolesSelected,
    });
    if (maSeeker === false) {
      roleCards.push({
        count: jobMatchCount + teamMatchCount || 0,
        label: 'My Role Recommendations',
        onClick: this.handleOnlyMatchesClick,
        selected: myMatchesSelected,
      });
    }

    if ((jobAllInvitations || 0) !== 0) {
      roleCards.push({
        count: jobAllInvitations || 0,
        label: "Roles I've Been Invited To",
        onClick: this.handleOnlyInvitedRolesClick,
        selected: invitedRolesSelected,
        image: InvitationQuickFilterSvg,
      });
    }

    if (jobFavorites !== 0) {
      roleCards.push({
        count: jobFavorites || 0,
        label: 'Favorited Roles',
        onClick: this.handleOnlySavedRolesClick,
        selected: favoriteRolesSelected,
        image: HeartDefaultSvg,
      });
    }

    let job = {};
    if (this.state.jobId in this.props.jobCache) {
      job = this.props.jobCache[this.state.jobId];
    }

    let tableControlLabelText = `${jobsTotalItems}`;
    if (jobsTotalItems === 1) {
      tableControlLabelText += ' role';
    } else {
      tableControlLabelText += ' roles';
    }

    const filterList = maSeeker
      ? [
          {id: 1, label: 'Matches My Interests'},
          {id: 2, label: 'Newest First'},
        ]
      : isSeeker
        ? [
            {id: 1, label: 'Recommended'},
            {id: 2, label: 'Newest First'},
          ]
        : [{id: 1, label: 'Newest First'}];

    const showPageControl =
      jobsTotalItems > jobsCurrentPage * jobsCurrentItemsPerPage;

    const handleVisibilityChange = ({visible, jobId}) => {
      if (this.props.role === 'JOB_SEEKER' && visible) {
        trackVisibility({
          seekerId: this.getSeekerId(),
          jobId: jobId,
          eventName: 'EXPLORE_ROLES',
          pageNumber: jobsCurrentPage + 1,
        });
      }
    };

    const renderJobCards = () => {
      const jobCardComponentList = jobs.map((job) => {
        return (
          <div key={job.id} className={styles.job_discovery_card}>
            <AdvancedJobCard
              handleVisibilityChange={handleVisibilityChange}
              viewerType={isSeeker ? 'CANDIDATE' : 'RECRUITER'}
              job={job}
              watched={jobIsWatched(job.id, this.props.seeker)}
              onWatchChanged={(watched) =>
                this.handleJobWatchClicked(job, watched)
              }
              onJobClick={() => this.handleJobClicked(job.id)}
              isApprenticeProgram={includesApprenticeProgram(this.props.seeker)}
              seekerApprenticePathways={this.props.seeker.apprenticePathways}
              matchCardStyle={{left: '-277px'}}
              seekerId={isSeeker ? this.props.seeker.id : null}
              showHideThisRoleButton={this.props.role !== 'ASCEND_RECRUITER'}
              isSelected={this.state.batchActionList.some(
                (item) => item.id === job.id
              )}
              handleBatchSelectChanged={this.handleBatchSelectChanged}
              showCheckbox={!isSeeker}
            />
          </div>
        );
      });

      if (this.isLoading()) {
        return jobCardComponentList;
      }

      if (this.props.profile.seekerId === null) {
        return jobCardComponentList;
      }

      if (this.props.profile.isApprenticeProgram) {
        return jobCardComponentList;
      }

      let cardsToRender = [];
      const seekerHasFewMatches =
        jobMatchCount <= TOP_OF_PAGE_MATCH_COUNT_CUTOFF;
      if (seekerHasFewMatches) {
        cardsToRender.push(
          <EducationalCard
            key="EducationalCard"
            seeker={this.props.seeker}
            seekerHasFewMatches={seekerHasFewMatches}
          />,
          ...jobCardComponentList
        );
      } else {
        cardsToRender.push(
          ...jobCardComponentList,
          <EducationalCard
            key="EducationalCard"
            seeker={this.props.seeker}
            seekerHasFewMatches={seekerHasFewMatches}
          />
        );
      }

      return cardsToRender;
    };

    const handleViewingFinalJob = ({visible, jobCount}) => {
      if (visible) {
        track({
          eventName: 'Final Job Viewed',
          jobCount: jobCount,
        });
        this.props.updateHasViewedFinalJob();
      }
    };

    const shouldRenderFinalJobFlag = () => {
      return (
        jobsCurrentPage + 1 ===
          Math.ceil(jobsTotalItems / jobsCurrentItemsPerPage) &&
        !this.isLoading() &&
        this.props.jobAndTeamSearchProps.myMatchesSelected &&
        this.props.loggedInSeekerId &&
        !hasViewedFinalJob
      );
    };

    return (
      <DocumentTitle
        title={`${isSeeker ? 'Role Discovery' : 'Recruiter Role Discovery'}`}
      >
        <div className="wholepage">
          <PageHeader
            title={`${
              isSeeker ? 'Role Discovery' : 'Recruiter Role Discovery'
            }`}
            showAdminLinks={this.props.permissions.includes('VIEW_ADMIN_MENU')}
          />
          <PreviousButton />
          {showJobNotFoundModal && (
            <CandidateNotifyMissingRole
              isOpen={showJobNotFoundModal}
              onClose={() => this.props.setJobNotFoundModal(false)}
            />
          )}
          {!isSeeker && (
            <>
              <InvitesSentModal
                isOpen={this.state.isInvitesSentModalOpen}
                onClose={() => {
                  this.setState({isInvitesSentModalOpen: false});
                }}
                duplicateInvitations={this.state.duplicateInvitations}
              />

              <AreYouSureModal
                areYouSureText="Are you sure?"
                detailText={`Irrelevant invites can hurt your business reputation. Are you sure you want to send ${this.state.batchActionList.length} invitations to candidates?`}
                yesText="Yes, I'm sure"
                noText="No, cancel send"
                size="m"
                centerVertically={true}
                areYouSureClassName="normal-title"
                isOpen={this.state.confirmBatchInviteRequired}
                onClose={() => {
                  this.setState({confirmBatchInviteRequired: false});
                }}
                onYes={() => {
                  this.processBatchInvite();
                  this.setState({confirmBatchInviteRequired: false});
                }}
              />
              <InviteToJobsModal
                isOpen={this.state.inviteToJobsModalIsOpen}
                jobs={this.state.batchActionList}
                onClose={() => this.setState({inviteToJobsModalIsOpen: false})}
                onSendClicked={this.handleInviteToJobSendClicked}
              />
            </>
          )}
          <div className="container">
            {this.props.seeker.id &&
              this.props.seeker.published === false &&
              this.props.role === 'JOB_SEEKER' &&
              !this.props.isApprenticeProgram && (
                <div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="padding-top-twentyfour padding-bottom-twentyfour">
                        <Link to={`/profile`}>
                          <NoRoles />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <>
              {job && job.id && (
                <JobProfile
                  key={job.id}
                  isOpen={this.state.jobProfileOpen}
                  job={job}
                  matchData={findMatchData(this.state.jobId, this.props.jobs)}
                  onClose={this.handleProfileClosed}
                  watched={_.includes(this.state.watchedJobIds, job.id)}
                  applied={jobIsApplied(this.state.jobId, this.props.seeker)}
                  onAppliedChanged={(applied) =>
                    this.handleAppliedChanged(this.state.jobId, applied)
                  }
                  onWatchChanged={(watched) => this.handleJobWatchClicked(job)}
                />
              )}
              <div className="container ps-0">
                {isSeeker && (
                  <>
                    <h2 className="normal-title padding-top-sixteen">
                      <strong>Select a View</strong>
                    </h2>

                    <div className={`row me-2 ${styles.mobile_view_padding}`}>
                      {roleCards.map((card, index) => {
                        return (
                          <RoleCard
                            key={index}
                            cardNumber={card.count}
                            cardText={card.label}
                            cardOnClick={card.onClick}
                            cardSelected={card.selected}
                            svg={card.image}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                {!isSeeker && (
                  <div
                    className={`row justify-content-md-center ${styles.mobile_view_padding}`}
                  >
                    <RoleCard
                      cardNumber={jobsTotalItems}
                      cardText="All Roles"
                      cardOnClick={this.handleAllClick}
                      cardSelected={allRolesSelected}
                    />
                  </div>
                )}
              </div>

              <div
                id={styles.main_content}
                className={maSeeker ? 'row justify-content-center' : 'row'}
              >
                {
                  <div className="col-lg-3" id={styles.filter_container}>
                    <>
                      <h2 className="normal-title">
                        <strong>Filters</strong>
                      </h2>

                      <button
                        id={styles.show_filters}
                        onClick={() => {
                          this.setState({
                            expandFilters: !this.state.expandFilters,
                          });
                          $('#jobDiscoveryFilters').toggle(150, 'linear');
                        }}
                      >
                        {this.state.expandFilters ? `Hide` : `Show`} Filters{' '}
                        <img
                          alt=""
                          src={this.state.expandFilters ? arrowDown : arrowUp}
                          className="twisty-chevron"
                          style={this.props.imageStyle}
                        />
                      </button>
                      <div id="jobDiscoveryFilters">
                        {!maSeeker && (
                          <FilterTagGroup
                            title="START DATE"
                            showTwisty={true}
                            expanded={false}
                            onClear={() => this.handleTagClear('startDateTags')}
                          >
                            <FilterTagCheckbox
                              onChange={(tag) =>
                                this.handleTagChange('startDateTags', tag)
                              }
                              name="immediate"
                              label="Immediate"
                              selected={startDateTags.immediate.selected}
                            />
                            <FilterTagCheckbox
                              onChange={(tag) =>
                                this.handleTagChange('startDateTags', tag)
                              }
                              name="flexible"
                              label="Flexible"
                              selected={startDateTags.flexible.selected}
                            />
                            <FilterTagCheckbox
                              onChange={(tag) =>
                                this.handleTagChange('startDateTags', tag)
                              }
                              name="specific"
                              label="Specific"
                              selected={startDateTags.specific.selected}
                            />
                          </FilterTagGroup>
                        )}

                        {!maSeeker && (
                          <FilterTagGroup
                            title="EMPLOYMENT TYPE"
                            showTwisty={true}
                            expanded={this.state.hasParamFilters}
                            onClear={() =>
                              this.handleTagClear('employmentTypeIds')
                            }
                          >
                            {_.map(empTypeList, (eType, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) =>
                                    this.handleTagChange(
                                      'employmentTypeIds',
                                      tag
                                    )
                                  }
                                  name={`${eType.id}employmentType`}
                                  label={eType.name}
                                  selected={employmentTypeIds.includes(
                                    eType.id
                                  )}
                                />
                              );
                            })}
                          </FilterTagGroup>
                        )}

                        {!maSeeker && (
                          <FilterTagGroup
                            title="EXPERIENCE LEVEL"
                            showTwisty={true}
                            expanded={false}
                            onClear={() =>
                              this.handleTagClear('experienceLevelIds')
                            }
                          >
                            {_.map(
                              this.props.experienceLevels,
                              (eType, index) => {
                                return (
                                  <FilterTagCheckbox
                                    key={index}
                                    onChange={(tag) => {
                                      this.handleTagChange(
                                        'experienceLevelIds',
                                        tag
                                      );
                                    }}
                                    name={`${eType.id}experienceLevelIds`}
                                    label={eType.years}
                                    selected={experienceLevelIds.includes(
                                      eType.id
                                    )}
                                  />
                                );
                              }
                            )}
                          </FilterTagGroup>
                        )}

                        {!maSeeker && (
                          <FilterTagGroup
                            title="REGIONS"
                            showTwisty={true}
                            expanded={false}
                            maxTags={6}
                            onClear={() => this.handleTagClear('regionIds')}
                          >
                            {_.map(sortedRegions, (region, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) =>
                                    this.handleTagChange('regionIds', tag)
                                  }
                                  name={`${region.id}region`}
                                  label={
                                    region.name === 'Any'
                                      ? 'No Preference'
                                      : region.name
                                  }
                                  selected={regionIds.includes(region.id)}
                                />
                              );
                            })}
                          </FilterTagGroup>
                        )}

                        <FilterTagGroup
                          title="REMOTE WORK"
                          showTwisty={true}
                          expanded={false}
                          onClear={() => this.handleTagClear('remoteStatus')}
                        >
                          {_.map(
                            remoteStatusTypes,
                            (remoteStatusType, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) => {
                                    this.handleTagChange('remoteStatus', tag);
                                  }}
                                  name={remoteStatusType.name}
                                  label={remoteStatusType.name}
                                  selected={
                                    remoteStatus[remoteStatusType.name]
                                      ?.selected
                                  }
                                />
                              );
                            }
                          )}
                        </FilterTagGroup>

                        {!maSeeker && (
                          <FilterTagGroup
                            title="ROLES"
                            showTwisty={true}
                            expanded={false}
                            maxTags={6}
                            onClear={() => this.handleTagClear('roleTypeIds')}
                          >
                            {_.map(sortedRoleTypes, (rType, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) =>
                                    this.handleTagChange('roleTypeIds', tag)
                                  }
                                  name={`${rType.id}roleTypeId`}
                                  label={
                                    rType.name === 'Any'
                                      ? 'No Preference'
                                      : rType.name
                                  }
                                  selected={roleTypeIds.includes(rType.id)}
                                />
                              );
                            })}
                          </FilterTagGroup>
                        )}

                        {!maSeeker && (
                          <FilterTagGroup
                            title="INDUSTRIES"
                            showTwisty={true}
                            expanded={this.state.hasParamFilters}
                            maxTags={6}
                            onClear={() => this.handleTagClear('industryIds')}
                          >
                            {_.map(sortedIndustries, (industry, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) =>
                                    this.handleTagChange('industryIds', tag)
                                  }
                                  name={`${industry.id}industry`}
                                  label={
                                    industry.name === 'Any'
                                      ? 'No Preference'
                                      : industry.name
                                  }
                                  selected={industryIds.includes(industry.id)}
                                />
                              );
                            })}
                          </FilterTagGroup>
                        )}

                        {!maSeeker && (
                          <FilterTagGroup
                            title="DEGREE TYPE"
                            showTwisty={true}
                            expanded={false}
                            maxTags={6}
                            onClear={() =>
                              this.handleTagClear('degreeTypeRankIds')
                            }
                            tooltip={
                              this.state.isMobile
                                ? ''
                                : 'Intended for Full-time Job seekers. <br/>Only jobs requiring the following selection will be shown.'
                            }
                          >
                            {_.map(sortedDegreeTypes, (degreeType, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) =>
                                    this.handleTagChange(
                                      'degreeTypeRankIds',
                                      tag
                                    )
                                  }
                                  name={`${degreeType.id}degreeTypes`}
                                  label={degreeType.name}
                                  selected={degreeTypeRankIds.includes(
                                    degreeType.id
                                  )}
                                />
                              );
                            })}
                          </FilterTagGroup>
                        )}

                        {!isSeeker && (
                          <FilterTagGroup
                            onClear={() => this.handleTagClear('segmentIds')}
                            title={'SEGMENT'}
                            showTwisty={true}
                            expanded={false}
                            maxTags={6}
                          >
                            {_.map(sortedSegments, (segment, index) => {
                              return (
                                <FilterTagCheckbox
                                  key={index}
                                  onChange={(tag) =>
                                    this.handleTagChange('segmentIds', tag)
                                  }
                                  name={`${segment.id}segment`}
                                  label={
                                    segment.name === 'Any'
                                      ? 'All Segments'
                                      : segment.name
                                  }
                                  selected={segmentIds.includes(segment.id)}
                                />
                              );
                            })}
                          </FilterTagGroup>
                        )}

                        {maSeeker && (
                          <FilterTagGroup
                            title="PATHWAYS"
                            showTwisty={true}
                            expanded={false}
                            maxTags={6}
                            onClear={() =>
                              this.handleTagClear('apprenticePathwayIds')
                            }
                          >
                            {_.map(
                              sortedApprenticePathways,
                              (apprenticePathway, index) => {
                                return (
                                  <FilterTagCheckbox
                                    key={index}
                                    onChange={(tag) =>
                                      this.handleTagChange(
                                        'apprenticePathwayIds',
                                        tag
                                      )
                                    }
                                    name={`${apprenticePathway.value}`}
                                    label={apprenticePathway.label}
                                    selected={apprenticePathwayIds.includes(
                                      apprenticePathway.value
                                    )}
                                  />
                                );
                              }
                            )}
                          </FilterTagGroup>
                        )}
                      </div>
                    </>
                  </div>
                }

                <div className="col-lg-9">
                  {resultTypeTags.showJobs.selected && (
                    <>
                      <h2 className="normal-title padding-bottom-sixteen">
                        <strong>Roles </strong>

                        <span id={styles.mobile_job_count}>
                          ({jobsTotalItems})
                        </span>
                      </h2>
                      <div id={styles.job_discovery_match_cards}>
                        <div className={`${styles.search_and_sort}`}>
                          <div className={styles.search_input}>
                            <InputSearch
                              aria-label="placeholder"
                              id="search-input"
                              style={{backgroundColor: 'white'}}
                              value={this.props.searchTerm}
                              onChange={(e) => {
                                this.handleFilterChange(e, false);
                              }}
                              onEnterKey={(e) => {
                                this.handleFilterChange(e);
                              }}
                              placeHolder={
                                'Search by title, company, or description'
                              }
                            />
                          </div>
                          <div
                            className={styles.clear_search}
                            id={styles.desktop_clear_search}
                          >
                            <button
                              className={`pt-1 ps-1 ${styles.clearAllFiltersButton}`}
                              onClick={() => this.handleFilterChange('')}
                            >
                              <span>Clear Search</span>
                            </button>
                          </div>
                          <div className={styles.sort_search}>
                            <AscendSelect
                              ariaLabel="options to sort table by"
                              label={''}
                              onChange={this.handleJobSortDropdownChange}
                              options={filterList}
                              visible={true}
                              minWidth={230}
                              value={
                                this.state.sortDropdownLabel === 'Sort by'
                                  ? maSeeker
                                    ? _.find(filterList, (x) => {
                                        return (
                                          x.label === 'Matches My Interests'
                                        );
                                      })
                                    : null
                                  : _.find(filterList, (x) => {
                                      return (
                                        x.label === this.state.sortDropdownLabel
                                      );
                                    })
                              }
                              placeholder={'Sort By'}
                            />
                          </div>
                        </div>

                        <div id={styles.desktop_job_count}>
                          <span>{tableControlLabelText}</span>
                        </div>
                        <span role="status">
                          {this.isLoading() && (
                            <div className="fullwidth ">{contentLoaders}</div>
                          )}
                        </span>
                        {isSeeker &&
                          jobs?.length === 0 &&
                          !this.isLoading() && (
                            <div className={styles.noResultsText}>
                              No results found
                              {this.props.searchTerm
                                ? ` for "${this.props.searchTerm}".`
                                : '.'}{' '}
                              Please try new search terms or utilize the filters
                              to search for roles tailored to your needs
                            </div>
                          )}

                        <br />
                        {renderJobCards()}

                        {shouldRenderFinalJobFlag() && (
                          <InView
                            threshold={0}
                            triggerOnce={true}
                            onChange={(inView) => {
                              handleViewingFinalJob({
                                visible: inView,
                                jobCount: jobsTotalItems,
                              });
                            }}
                          >
                            <div className="visible-hidden"></div>
                          </InView>
                        )}

                        {showPageControl && (
                          <div className="row padding-bottom-eight">
                            <div className="col-lg-12">
                              <div className="d-flex flex-row justify-content-between">
                                <div aria-live="assertive" aria-atomic="true">
                                  Page {jobsCurrentPage + 1} of{' '}
                                  {Math.ceil(
                                    jobsTotalItems / jobsCurrentItemsPerPage
                                  )}
                                </div>
                                <PageControl
                                  pageIndex={jobsCurrentPage}
                                  pageCount={Math.ceil(
                                    jobsTotalItems / jobsCurrentItemsPerPage
                                  )}
                                  onPageChange={this.handleJobPageChange}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {this.props.loggedInSeekerId &&
                    !maSeeker &&
                    jobs?.length > 0 &&
                    this.props.seeker.published === true && (
                      <DidYouApply
                        handleJobClick={this.handleJobClicked}
                        maxHorizontalCardDisplay={3}
                      />
                    )}
                </div>
              </div>
            </>
          </div>
          <PageFooter />
          {!isSeeker && this.state.batchActionList.length !== 0 && (
            <ListBatchActionDrawer
              selectionCount={this.state.batchActionList.length}
              handleSelectAllClick={this.handleSelectAllClick}
              handleClearAllClick={this.handleClearAllClick}
              batchSelectMaximumCount={this.batchSelectMaximumCount}
              handleBatchInviteClicked={this.handleBatchInviteClicked}
              isLoading={this.isLoading()}
              noun={'Jobs'}
              buttonText={'Send Invites to All'}
            />
          )}
        </div>
      </DocumentTitle>
    );
  }
}

JobDiscoveryPage.propTypes = {
  history: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  getSeeker: PropTypes.func.isRequired,
  getSeekerJobStats: PropTypes.func.isRequired,
  getSeekerTeamStats: PropTypes.func.isRequired,
  getEmploymentTypes: PropTypes.func.isRequired,
  employmentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRegions: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  roleTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getIndustries: PropTypes.func.isRequired,
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRecruiterJobs: PropTypes.func.isRequired,

  ...jobAndTeamSearchActions.propTypes,

  // stats
  jobStats: PropTypes.object.isRequired,
  teamStats: PropTypes.object.isRequired,

  seeker: PropTypes.object,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  getTeams: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  createTeamWatch: PropTypes.func.isRequired,
  deleteTeamWatch: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  loggedInSeekerId: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  degreeTypes: PropTypes.array,
  updateSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isApprenticeProgram: PropTypes.bool,
  getJobCategories: PropTypes.func.isRequired,
  experienceLevels: PropTypes.array,
  getExperienceLevels: PropTypes.func.isRequired,
  apprenticePathways: PropTypes.array,
  apprenticePathwaysLoading: PropTypes.bool,
  getApprenticePathways: PropTypes.func.isRequired,
  getAllApprenticePathways: PropTypes.func.isRequired,
  getSegments: PropTypes.func.isRequired,
  segments: PropTypes.array,
  role: PropTypes.string,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
  seekerSelectAll: PropTypes.func.isRequired,
  resetJobAndTeamSearchStateDefault: PropTypes.func.isRequired,
  updateHasViewedFinalJob: PropTypes.func.isRequired,
  permissions: PropTypes.any,
};

function mapStateToProps(state, ownProps) {
  return {
    loggedInSeekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    jobCache: state.jobs.jobCache,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
    experienceLevels: state.experienceLevels.experienceLevels,

    employmentTypes: state.employmentTypes.employmentTypes,
    regions: state.regions.regions,
    roleTypes: state.roleTypes.roleTypes,
    industries: state.industries.industries,
    apprenticePathways: state.apprenticePathways.apprenticePathways,
    apprenticePathwaysLoading:
      state.apprenticePathways.apprenticePathwaysLoading,
    segments: state.segments.segmentList,
    jobAndTeamSearchProps: state.jobAndTeamSearch,
    recruiterJobAndTeamSearchProps: state.recruiterJobAndTeamSearch,

    // stats
    jobStats: state.seekers.jobStats,
    teamStats: state.seekers.teamStats,
    degreeTypeRanks: state.degreeTypeRank.degreeRank,
    searchTerm: state.ascendDataTable.searchTerm,
    isApprenticeProgram: state.profile.isApprenticeProgram,

    role: state.profile.role,
    seekerJobsLoading: state.jobAndTeamSearch.jobsLoading,
    permissions: state.profile.permissions,
  };
}

const actions = {
  ...seekersActions,
  ...employersActions,
  ...teamsActions,
  ...jobsActions,
  ...jobAndTeamSearchActions,
  ...recruiterJobAndTeamSearchActions,
  ...employmentTypeActions,
  ...regionsActions,
  ...roleTypesActions,
  ...industriesActions,
  ...degreeTypeRankActions,
  ...searchActions,
  ...segmentActions,
  getExperienceLevels,
  getApprenticePathways,
  getAllApprenticePathways,
};

export default withRouter(connect(mapStateToProps, actions)(JobDiscoveryPage));
