import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Avatar from '../../components/Avatar';

class BenchItem extends React.Component {
  render() {
    return (
      <div>
        <div className="inlineblock">
          <Avatar
            height={52}
            width={52}
            shape="circle"
            url={this.props.avatarUrl}
            label={this.props.seekerName}
          />
        </div>
        <div className="inlineblock padding-left-eight">
          <Link
            to={'/candidate/' + this.props.seekerId}
            className="accessibleLinkHover"
            style={{textDecoration: 'underline'}}
          >
            {this.props.seekerName}
          </Link>
        </div>
      </div>
    );
  }
}

BenchItem.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  seekerId: PropTypes.string.isRequired,
  seekerName: PropTypes.string.isRequired,
};

export default BenchItem;
