import * as types from '../actions/ActionTypes';

const initialState = {
  byTeam: {},
};

export default function teamProfileDataTable(state = initialState, action) {
  switch (action.type) {
    case types.TEAM_PROFILE_DT__CREATE_TEAM:
      return {
        ...state,
        byTeam: {
          ...state.byTeam,
          [action.teamId]: {
            currentPage: 0,
            currentItemsPerPage: 25,
            searchTerm: '',
            sortBy: '',
            sortAscending: true,
          },
        },
      };
    case types.TEAM_PROFILE_DT__UPDATE_CURRENT_PAGE:
      return {
        ...state,
        byTeam: {
          ...state.byTeam,
          [action.teamId]: {
            ...state.byTeam[action.teamId],
            currentPage: action.page,
          },
        },
      };
    default:
      return state;
  }
}
