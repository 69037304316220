import React from 'react';
import DocumentTitle from '../components/vendor/DocumentTitle';
import NetworkAuthenticationService from 'src/services/NetworkAuthenticationService.js';

const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;

const auth = new NetworkAuthenticationService(auth0ClientId, auth0Domain);

class LoginFailed extends React.Component {
  render() {
    auth.logout();
    return (
      <DocumentTitle title="Login Attempt Failed">
        <div className="fullindy">
          <div className="container">
            <br />
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div>
                  <div className="bd-bluebackground">
                    <center>
                      <br />
                      <div className="normal-jumbo-white bold">
                        Login Attempt Failed
                      </div>
                      <br />
                    </center>
                  </div>
                  <div className="bd-whitebackground">
                    <div className="row">
                      <div className="col-md-2" />
                      <div className="col-md-8">
                        <br />
                        <div className="normal-title">
                          Oops! This error is on us. Your username and password
                          are correct, but we are unable to find your user
                          profile after you logged in. Please close this window
                          and try again in a few minutes.
                          <br />
                          <br />
                          Please take a screenshot and send to{' '}
                          <a href="mailto:support@ascendindiana.com">
                            support@ascendindiana.com
                          </a>{' '}
                          for additional assistance.
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-2" />
        </div>
      </DocumentTitle>
    );
  }
}

export default LoginFailed;
