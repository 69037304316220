import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import _ from 'lodash';
import Twisty from './Twisty';
import {camelCase} from 'src/utils/miscHelper';

class FilterTagGroup extends React.Component {
  state = {
    open: true,
    hidden: true,
  };
  componentDidMount() {
    this.setState({open: this.props.expanded});
  }

  handleTwistyClicked = () => {
    this.setState({open: !this.state.open, hidden: true});
  };

  handleShowMoreClicked = () => {
    this.setState({hidden: false});
  };

  render() {
    const {showTwisty, maxTags, children} = this.props;
    const hideExtraTags =
      this.state.hidden && !!maxTags && children.length > maxTags;
    let childrenToShow = _.toArray(children);
    if (hideExtraTags) {
      childrenToShow = children.slice(0, maxTags);
    }
    return (
      <>
        {this.props.tooltip !== '' && (
          <ReactTooltip
            className="solid-tooltip-dark"
            id={camelCase(this.props.title)}
            closeOnEsc={true}
            place={'right'}
          />
        )}
        {showTwisty && (
          <button
            aria-expanded={this.state.open}
            className="btn-twisty"
            onClick={this.handleTwistyClicked}
            data-tooltip-html={this.props.tooltip}
            data-tooltip-hidden={this.props.tooltip === ''}
            data-tooltip-id={camelCase(this.props.title)}
            aria-describedby={
              this.props.tooltip ? camelCase(this.props.title) : null
            }
            style={{}}
          >
            <Twisty
              isDown={this.state.open}
              text={this.props.title}
              headerStyles=""
            />
          </button>
        )}
        <hr style={{marginTop: '4px', marginBottom: '4px'}} />
        {this.state.open && (
          <div>
            <table style={{borderSpacing: '0 4px', borderCollapse: 'separate'}}>
              <tbody>
                {childrenToShow.map((child, idx) => (
                  <tr key={idx}>
                    <td>{child}</td>
                  </tr>
                ))}
                {hideExtraTags && (
                  <tr>
                    <td>
                      <div
                        className="clickable active-caption btn"
                        onClick={this.handleShowMoreClicked}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            e.preventDefault();
                            this.handleShowMoreClicked();
                          }
                        }}
                      >
                        {`[+] ${children.length - maxTags} more`}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div
              tabIndex={0}
              role="button"
              className="clickable active-caption underline"
              style={{paddingBottom: '8px'}}
              onClick={this.props.onClear}
              onKeyDown={(e) => {
                if (e.keyCode === 32 || e.keyCode === 13) {
                  e.preventDefault();
                  this.props.onClear();
                }
              }}
            >
              Clear Filter(s)
            </div>
          </div>
        )}
      </>
    );
  }
}

FilterTagGroup.propTypes = {
  onClear: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showTwisty: PropTypes.bool.isRequired,
  maxTags: PropTypes.number.isRequired,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  tooltip: PropTypes.string,
};

FilterTagGroup.defaultProps = {
  showTwisty: false,
  maxTags: 0,
  expanded: true,
};

export default FilterTagGroup;
