import * as types from './ActionTypes';
import {makeRequest} from '../services/api';

export const getMaOnboardingQuestions = () => async (dispatch) => {
  try {
    dispatch({type: types.QUESTIONS__GET_MA_ONBOARDING_REQUEST});

    const response = await makeRequest({
      method: 'GET',
      path: 'v2/questions',
    });
    dispatch({
      type: types.QUESTIONS__GET_MA_ONBOARDING_REQUEST_SUCCESS,
      response: response.data.questions,
    });
    return response;
  } catch (err) {
    dispatch({type: types.QUESTIONS__GET_MA_ONBOARDING_REQUEST_FAILURE});
  }
};
