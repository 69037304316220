import React from 'react';
import PropTypes from 'prop-types';

class RecruiterJobCardTab extends React.Component {
  render() {
    return (
      <div
        className={`active-caption-dark padding-right-sixteen ${
          this.props.enabled ? 'clickable' : ''
        }`}
      >
        {this.props.glyph && (
          <i className={`nc-icon-glyph ${this.props.glyph} color-inactive`} />
        )}
        {this.props.svg}
        &nbsp;&nbsp;{this.props.title}
      </div>
    );
  }
}

RecruiterJobCardTab.propTypes = {
  glyph: PropTypes.string,
  svg: PropTypes.object,
  title: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
};

RecruiterJobCardTab.defaultProps = {
  enabled: true,
};

export default RecruiterJobCardTab;
