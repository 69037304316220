import React from 'react';
import PropTypes from 'prop-types';

import * as Layout from '../layout';
import {BusyButton} from './BusyButton';
import SkillsTreeWidget from 'src/components/skillTags/SkillsTreeWidget.js';
import {Form, Field} from 'react-final-form';
import {connect} from 'react-redux';
import {
  getSkillTree,
  getAllSkillTags,
  getTopSkillTags,
} from 'src/actions/skillTags';

class SkillsModal extends React.Component {
  componentDidMount() {
    if (!this.props.skillTagsLoading && this.props.skillTags.length === 0) {
      this.props.getSkillTree();
      this.props.getAllSkillTags();
      this.props.getTopSkillTags(10);
    }
  }

  render() {
    const {isOpen, onClose, onSubmit, seeker} = this.props;
    const validate = (values) => {
      const errors = {};

      if (values.skillTags.length < 5) {
        errors['skillTags'] = 'Please select at least 5 skills';
      }

      return errors;
    };

    return (
      <Layout.AscendModal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        backdrop={'static'}
      >
        <Layout.AscendModalBody hasClose={false}>
          <div className="padding-32">
            <Form
              onSubmit={onSubmit}
              validate={validate}
              keepDirtyOnReinitialize={true}
              initialValues={{
                skillTags:
                  seeker?.skillTags.map((skill) => {
                    return {
                      ...skill,
                      value: skill.id,
                      label: skill.name,
                    };
                  }) || [],
              }}
            >
              {({handleSubmit}) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div>
                        <div className="active-headline text-align-center padding-bottom-eight bold">
                          Edit Skills
                        </div>
                        <label className="onboarding-form-label">
                          Search for skills and browse the skills library
                        </label>
                        <Field
                          component={SkillsTreeWidget}
                          name="skillTags"
                          placeholder="Search & add skills (ex. data analytics)"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row justify-content-center nowrap">
                      <button
                        type="button"
                        className="btn btn-transparent col-2 me-4"
                        onClick={onClose}
                      >
                        Cancel
                      </button>

                      <BusyButton
                        onClick={handleSubmit}
                        buttonText={'Save Skills'}
                        busyText={'Saving Skills'}
                        className="col-2"
                        style={{width: '210px', height: '40px'}}
                        float={'float-right'}
                        buttonIcon={'save'}
                        alignText={'text-center'}
                        isLoading={this.props.seekerLoading}
                      />
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

SkillsModal.propTypes = {
  isOpen: PropTypes.bool,
  selectedSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  seeker: PropTypes.any,
  seekerLoading: PropTypes.bool.isRequired,
  getSkillTree: PropTypes.func.isRequired,
  getAllSkillTags: PropTypes.func.isRequired,
  getTopSkillTags: PropTypes.func.isRequired,
  skillTags: PropTypes.array.isRequired,
  skillTagsLoading: PropTypes.bool.isRequired,
};

const actions = {
  getSkillTree,
  getAllSkillTags,
  getTopSkillTags,
};

const editMapStateToProps = (state) => {
  return {
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
    skillTags: state.skillTags.tags,
    skillTagsLoading: state.skillTags.skillTagsLoading,
  };
};

export default connect(editMapStateToProps, actions)(SkillsModal);
