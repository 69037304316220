import React from 'react';
import PropTypes from 'prop-types';

class SearchButton extends React.Component {
  render() {
    return (
      <button
        aria-label="search"
        type="button"
        tabIndex={0}
        className="clickable btn btn-primary"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <i className="nc-icon-glyph usersearch" />
      </button>
    );
  }
}

SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SearchButton.defaultProps = {
  disabled: false,
};

export default SearchButton;
