import React from 'react';
import DocumentTitle from '../components/vendor/DocumentTitle';
class DeactivatedUserPage extends React.Component {
  render() {
    return (
      <DocumentTitle title="Deactivated">
        <div className="fullindy">
          <div className="container">
            <br />
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div>
                  <div className="bd-whitebackground">
                    <div className="row">
                      <div className="col-12">
                        <center>
                          <div className="normal-jumbo bold">Deactivated</div>
                          <hr />
                        </center>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2" />
                      <div className="col-md-8">
                        <div className="normal-title text-align-center">
                          <div className="normal-body">
                            Your account has been deactivated, which means you
                            don't currently have permission to access the Ascend
                            Network.
                            <br />
                            <br />
                            If you believe you are seeing this page by accident,
                            please log out and try a different account.
                            <br />
                            <br />
                            To reactivate your account, reach out to{' '}
                            <a href="mailto:support@ascendindiana.com">
                              support@ascendindiana.com
                            </a>
                          </div>
                          <div className="text-align-center fullwidth bgcolorw">
                            <br />
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-2" />
        </div>
      </DocumentTitle>
    );
  }
}

export default DeactivatedUserPage;
