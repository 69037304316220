import {getGraduateDateDiffDays} from './onboardingHelpers';
const WORK_STATUS_OFFRAMP_INDEX = -1;
const EDUCATION_OFFRAMP_INDEX = -2;
const INELIGIBLE_OFFRAMP_INDEX = -3;

export const findNextPage = (props, page) => {
  const {
    sponsorStatus,
    workStatusAllowed,
    educationStatus,
    educationLevel,
    graduationDate,
  } = props;
  switch (page) {
    case 0:
      return 1;
    case 1:
      if (educationStatus.toLowerCase() === 'true') {
        return 3;
      }
      return INELIGIBLE_OFFRAMP_INDEX;
    case 2:
      // Below high school diploma -> go to education off-ramp
      // High School diploma and graduation month/year = in the future -> go to Modern Apprenticeship workflow
      // High School diploma and graduation month/year = current month or in the past -> go to work authorization
      // Associates, Bachelors, Masters, Doctorate, Prefer not to answer -> go to work authorization

      if (educationLevel.shouldOfframp) {
        return EDUCATION_OFFRAMP_INDEX;
      }

      if (
        educationLevel.name.toLowerCase() ===
          'high school diploma or equivalent' &&
        getGraduateDateDiffDays(graduationDate) > 60
      ) {
        return EDUCATION_OFFRAMP_INDEX;
      }

      return 3;

    case 3:
      if (
        sponsorStatus.toLowerCase() === 'true' ||
        workStatusAllowed.toLowerCase() === 'false'
      ) {
        return WORK_STATUS_OFFRAMP_INDEX;
      }

      return 4;
    case 4:
      return 5;
    case 5:
      return 0;

    default:
      return page;
  }
};
