export const intakeEducationOptions = [
  {label: 'Below high school diploma', value: 'Below high school diploma'},
  {
    label: 'High school diploma or equivalent',
    value: 'High school diploma or equivalent',
    showMajors: false,
    showMinors: false,
    showInstitution: false,
    showOtherSchoolInput: true,
    degreeTypeRankId: 6,
  },
  {
    label: "Associate's degree",
    value: "Associate's degree",
    showMajors: true,
    showMinors: true,
    maxMajors: 3,
    showInstitution: true,
    showOtherSchoolInput: false,
    degreeTypeRankId: 2,
  },
  {
    label: "Bachelor's degree",
    value: "Bachelor's degree",
    showMajors: true,
    showMinors: true,
    maxMajors: 3,
    showInstitution: true,
    showOtherSchoolInput: false,
    degreeTypeRankId: 3,
  },
  {
    label: "Master's degree",
    value: "Master's degree",
    showMajors: true,
    showMinors: false,
    useFieldsOfStudyLabel: true,
    maxMajors: 1,
    showInstitution: true,
    showOtherSchoolInput: false,
    degreeTypeRankId: 4,
  },
  {
    label: 'Doctorate degree',
    value: 'Doctorate degree',
    showMajors: true,
    showMinors: false,
    useFieldsOfStudyLabel: true,
    maxMajors: 1,
    showInstitution: true,
    showOtherSchoolInput: false,
    degreeTypeRankId: 5,
  },
  {
    label: 'Prefer not to answer',
    value: 'Prefer not to answer',
    showMajors: false,
    showMinors: false,
    showInstitution: true,
    showOtherSchoolInput: false,
    degreeTypeRankId: null,
  },
];

export const majorLabel = (index) => {
  switch (index) {
    case 0:
      return 'Major';
    case 1:
      return 'Second Major';
    case 2:
      return 'Third Major';
    default:
      return 'Major';
  }
};

export const minorLabel = (index) => {
  switch (index) {
    case 0:
      return 'Minor (optional)';
    case 1:
      return 'Second Minor (optional)';
    case 2:
      return 'Third Minor (optional)';
    default:
      return 'Minor (optional)';
  }
};

export const seekerFields = {
  campusld: '',
  connectionFormCompletedOn: '',
  degreeTypeld: '',
  email: '',
  ethnicity: '',
  experienceLevels: '',
  firstGenStudent: '',
  firstName: '',
  gender: '',
  gpa: '',
  graduationMonth: '',
  graduationYear: '',
  hometownld: '',
  institutionld: '',
  invitationCode: '',
  lastName: '',
  location: '',
  majorlds: '',
  minorlds: '',
  pellGrant: '',
  phone: '',
  referralSourceld: '',
  resume: '',
  schoolld: '',
  segments: '',
  skillTags: '',
  sponsorStatus: '',
  twentyFirstCenturyScholar: '',
  workStatus: '',
  workStatusAllowed: '',
  veteran: '',
  invitationCodeId: '',
};
