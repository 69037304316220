import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class OneSubcategory extends React.Component {
  render() {
    const {onClick, selected, label} = this.props;
    return (
      <div
        className={classNames(
          'normal-subheader padding-top-eight padding-bottom-eight rowbottom rowright',
          {'bd-lightbluebackground': selected}
        )}
        onClick={onClick}
        role="button"
        aria-label={label}
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onClick();
          }
        }}
      >
        <table width="100%">
          <tbody>
            <tr>
              <td width="12%" />
              <td width="88%">{label}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

OneSubcategory.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default OneSubcategory;
