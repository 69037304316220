import {includes} from 'lodash';

export const employerIsWatched = (employerId, seeker) => {
  const watchedEmployerIds = seeker.watchedEmployers.map((e) => e.id);
  return includes(watchedEmployerIds, employerId);
};

export const blankEmployer = {
  name: '',
  location: '',
  tagline: '',
  industry: '',
  companySize: '',
  revenue: '',
  about: '',
  instagram: '',
  twitter: '',
  facebook: '',
  linkedin: '',
  blueBanner: '',
  mainPhoto: '',
  mainPhotoAlt: '',
  resources: [],
  zipcodeId: '46285',
};
