import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {HeartFilledWithoutBorderSvg} from '../assets/svg/svgComponents';

class WatchIcon extends React.Component {
  handleWatchClicked = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const classes = classNames(['clickable', this.props.className]);
    const heartRed = (
      <HeartFilledWithoutBorderSvg
        width="24"
        height="24"
        className={`${
          this.props.floatRight ? 'float-right' : ''
        } svg_color_fill_pink_heart`}
      />
    );
    const heartGray = (
      <HeartFilledWithoutBorderSvg
        width="24"
        height="24"
        strokeWidth="0.5"
        className={`${
          this.props.floatRight ? 'float-right' : ''
        } svg_color_fill_gray_heart svg_color_stroke_gray_heart`}
      />
    );
    const heartWhite = (
      <HeartFilledWithoutBorderSvg
        width="24"
        height="24"
        className={`${
          this.props.floatRight ? 'float-right' : ''
        }  svg_color_fill_white`}
      />
    );
    return (
      <button
        aria-label={'Watch job'}
        aria-pressed={this.props.watched}
        className={classes}
        onClick={this.handleWatchClicked}
        style={{...this.props.style, background: 'none', border: 'none'}}
      >
        {this.props.watched && heartRed}
        {!this.props.watched && !this.props.whiteHeart && heartGray}
        {!this.props.watched && this.props.whiteHeart && heartWhite}
      </button>
    );
  }
}

WatchIcon.propTypes = {
  watched: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  whiteHeart: PropTypes.bool,
  floatRight: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

WatchIcon.defaultProps = {
  whiteHeart: false,
  floatRight: true,
  style: {marginLeft: 0},
  className: 'col-1',
};

export default WatchIcon;
