import React from 'react';
import PropTypes from 'prop-types';

const InfoCardRow = ({title, content, allowEdit, onEdit}) => {
  return (
    <div className="row" style={{paddingBottom: '8px'}}>
      <div className="col-md-12">
        <div className="inlineblock">
          <h3
            style={{
              fontSize: '15px',
              color: 'var(--neutral-gray-1)',
              paddingLeft: '8px',
              paddingBottom: '8px',
            }}
          >
            {title}
          </h3>
        </div>
        <div className="inlineblock float-right">
          {allowEdit && (
            <button
              type="button"
              className="buttonAsLinkCandidateProfile"
              aria-label={`Edit ${title}`}
              onClick={onEdit}
            >
              Edit
            </button>
          )}
        </div>
        <div className="disabled-caption" style={{paddingLeft: '8px'}}>
          {content || 'Not selected'}
        </div>
      </div>
    </div>
  );
};

InfoCardRow.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  allowEdit: PropTypes.bool,
  onEdit: PropTypes.func,
};

InfoCardRow.defaultProps = {
  allowEdit: false,
};

class InfoCard extends React.Component {
  handleEdit = () => {
    if (this.props.onEdit) {
      this.props.onEdit();
    }
  };

  render() {
    const {title, children, allowEdit} = this.props;
    return (
      <div className="bd-whitebackground" style={{padding: '0 4px'}}>
        <div
          className="row"
          style={{paddingTop: '16px', paddingBottom: '16px'}}
        >
          <div className="col-md-12">
            <div className="inlineblock normal-caption bold">
              <h3>{title}</h3>
            </div>
            {allowEdit && (
              <div className="inlineblock float-right">
                <div
                  role="button"
                  aria-label={`${title} edit`}
                  className="clickable candidate-profile-edit-link bold"
                  style={{textDecoration: 'underline'}}
                  onClick={this.handleEdit}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      e.preventDefault();
                      this.handleEdit();
                    }
                  }}
                >
                  Edit
                </div>
              </div>
            )}
          </div>
        </div>
        {children}
        <div style={{paddingBottom: '8px'}} />
      </div>
    );
  }
}

InfoCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
};

InfoCard.defaultProps = {
  allowEdit: true,
};

export {InfoCardRow, InfoCard};
