import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Layout from 'src/layout';
import {makeRequest} from '../../services/api';
import isEmail from 'validator/lib/isEmail';
import * as flashActions from '../../actions/flash';
import {actions as authActions} from '../../reducers/authentication';
import {BusyButton} from '../BusyButton';
import {getUtmKeys, setUtmKeys} from 'src/utils/utmKeys';
import RenderHtml from 'src/components/controls/RenderHtml';

class ChangeEmailModal extends React.Component {
  initialState = {
    email: '',
    confirmEmail: '',
    error: '',
    secondaryMessage: '',
    confirmError: '',
    isOpen: false,
    emailsMatch: false,
    emailExists: false,
    loading: false,
    thirdPartyEmailChangeComplete: false,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isOpen !== this.props.isOpen) {
      this.setState({
        ...this.initialState,
        isOpen: this.props.isOpen,
      });
    }
  }

  handleEmailChange = (newValue) => {
    if (
      isEmail(newValue) &&
      this.state.confirmEmail !== '' &&
      isEmail(this.state.confirmEmail) &&
      newValue !== this.state.confirmEmail
    ) {
      this.setState({
        confirmError: 'Emails do not match!',
        email: newValue,
        emailsMatch: false,
        error: '',
      });
    } else {
      this.setState({
        confirmError: '',
        email: newValue,
        emailsMatch: newValue === this.state.confirmEmail,
        error: '',
      });
    }
  };

  handleConfirmEmailChange = (newValue) => {
    if (isEmail(newValue) && newValue !== this.state.email) {
      this.setState({
        confirmError: 'Emails do not match!',
        confirmEmail: newValue,
        emailsMatch: false,
        error: '',
      });
    } else {
      this.setState({
        confirmError: '',
        confirmEmail: newValue,
        emailsMatch: newValue === this.state.email,
        error: '',
      });
    }
  };

  handleSubmit = async () => {
    let changeEmailUri = `seekers/change_email`;
    let requestBody = {
      oldEmail: this.props.oldEmail,
      newEmail: this.state.email,
    };
    this.setState({loading: true});

    await makeRequest({
      method: 'PATCH',
      path: changeEmailUri,
      body: requestBody,
      isAuthenticated: true,
      contentType: 'application/json',
    })
      .then((response) => {
        if (response?.data?.success) {
          if (this.props.isThirdPartyEmailChange) {
            this.setState({thirdPartyEmailChangeComplete: true});
          } else {
            this.setState({isOpen: false});
            this.props.addFlashMessage(
              'Email has been updated!',
              'notification'
            );
            const utmKeys = getUtmKeys();
            localStorage.clear();
            setUtmKeys(utmKeys);
            this.props.logoutUser();
          }
          return;
        }
        this.setState({error: response.data.message, secondaryMessage: response.data.secondaryMessage});
      })
      .catch((response) => {
        this.setState({error: response?.response?.data?.error?.message});
      })
      .finally(() => {
        this.setState({loading: false});
      });
  };

  render() {
    const {email} = this.state;
    const {onClose, onCancel} = this.props;

    return (
      <Layout.AscendModal
        size="md"
        isOpen={this.state.isOpen}
        onClose={onClose}
      >
        <Layout.AscendModalBody hasClose={true} padClose={false}>
          {!this.state.thirdPartyEmailChangeComplete && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="normal-title bold text-center">
                    Update Email / Username
                  </div>

                  {this.props.isThirdPartyEmailChange && (
                    <div className="text-center secondary-subheader">
                      For {this.props.changeEmailUser.firstName}{' '}
                      {this.props.changeEmailUser.lastName} email change
                      request.
                    </div>
                  )}

                  <hr />
                  <span className="secondary-subheader">
                    Please add{' '}
                    {this.props.isThirdPartyEmailChange ? "the user's" : 'your'}{' '}
                    new email address / username below.
                    <br />{' '}
                    <span className="bold">
                      {' '}
                      This will update{' '}
                      {this.props.isThirdPartyEmailChange
                        ? 'their'
                        : 'your'}{' '}
                      Ascend Network username required to log in.
                    </span>
                    <br /> To finish the email/username update,{' '}
                    {this.props.isThirdPartyEmailChange ? 'the user' : 'you'}{' '}
                    must complete the verification request sent to{' '}
                    {this.props.isThirdPartyEmailChange ? 'their' : 'your'} new
                    email address.
                  </span>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-12">
                  <input
                    type="email"
                    className={
                      'form-control ' +
                      (this.state.error !== '' ? 'border border-danger' : '')
                    }
                    id="changeEmail"
                    placeholder="Enter new email"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    onChange={(event) =>
                      this.handleEmailChange(event.target.value)
                    }
                    aria-label="Enter new email"
                  />

                  {this.state.error.length > 0 && (
                    <div style={{color: 'red'}}>
                      {`${this.state.error}`}
                      <br />                      
                      {this.state.secondaryMessage && (
                          <RenderHtml
                          htmlString={this.state.secondaryMessage}
                        />
                      )}

                      {!this.state.secondaryMessage && (
                        <>
                          If you need assistance, please email{' '}
                          <a href="mailto:support@ascendindiana.com">
                            support@ascendindiana.com
                          </a>. 
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="email"
                    className={
                      'form-control ' +
                      (this.state.confirmError !== ''
                        ? 'border border-danger'
                        : '')
                    }
                    id="confirmEmail"
                    placeholder="Confirm new email"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    onChange={(event) =>
                      this.handleConfirmEmailChange(event.target.value)
                    }
                    aria-label="Confirm new email"
                  />

                  <div style={{color: 'red'}}>
                    {this.state.confirmError !== ''
                      ? this.state.confirmError
                      : null}
                  </div>
                </div>
              </div>
              <br />
              <div className="row justify-content-center nowrap">
                <button
                  type="button"
                  className="btn btn-transparent col-2 me-4"
                  onClick={onCancel}
                >
                  Cancel
                </button>

                <BusyButton
                  buttonText={`Update & ${
                    this.props.isThirdPartyEmailChange ? 'Send' : 'Log Out'
                  }`}
                  busyText={'Changing Email'}
                  onClick={this.handleSubmit}
                  className="col-2"
                  style={{width: '220px', height: '40px'}}
                  float={'float-right'}
                  disabled={!(isEmail(email) && this.state.emailsMatch)}
                />
              </div>
            </>
          )}

          {this.state.thirdPartyEmailChangeComplete && (
            <>
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="normal-title bold ">
                    Email Change Request Submitted
                  </div>
                  <hr />
                  <span className="secondary-subheader text-center">
                    A verification email has been sent to {this.state.email} for{' '}
                    {this.props.changeEmailUser.firstName}{' '}
                    {this.props.changeEmailUser.lastName}'s account.
                  </span>
                </div>
              </div>
              <div className="row justify-content-center">
                <button
                  type="button"
                  className="btn btn-transparent col-2"
                  onClick={onCancel}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    oldEmail: ownProps.isThirdPartyEmailChange
      ? state.seekers.changeEmailUser.email
      : state.profile.email,
    changeEmailUser: state.seekers.changeEmailUser,
  };
};

const mapDispatchToProps = {
  ...flashActions, // Toaster notifications
  ...authActions,
};

ChangeEmailModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  oldEmail: PropTypes.string,
  isThirdPartyEmailChange: PropTypes.bool,
  changeEmailUser: PropTypes.object,
};

ChangeEmailModal.defaultProps = {
  isThirdPartyEmailChange: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);
