import _ from 'lodash';

export const maTraits = [
  {
    label: 'Collaboration',
    name: 'collaboration',
    placeholder:
      'The apprentice needs to enjoys working on a team to accomplish their tasks or goals. ',
  },
  {
    label: 'Competition',
    name: 'competition',
    placeholder:
      'The apprentice needs to be competitive and strives to be the best. ',
  },
  {
    label: 'Conflict Resolution',
    name: 'conflictResolution',
    placeholder:
      'The apprentice needs to be adept at resolving conflicts and must have a calm demeanor. ',
  },
  {
    label: 'Problem Solving',
    name: 'problemSolving',
    placeholder:
      'The apprentice needs to be able to break down challenging problems and discover effective solutions. ',
  },
  {
    label: 'Data Acumen',
    name: 'dataAcumen',
    placeholder:
      'The apprentice needs to be comfortable working with numbers or data sets. ',
  },
  {
    label: 'Attention to Detail',
    name: 'attentionToDetail',
    placeholder:
      'The apprentice needs to be detail oriented when completing their work, above and beyond typical expectations. ',
  },
  {
    label: 'Curiosity',
    name: 'curiosity',
    placeholder: 'The apprentice needs to be curious about how things work. ',
  },
  {
    label: 'Integrity',
    name: 'integrity',
    placeholder:
      'The apprentice needs to be high-integrity and understand the importance of how to handle sensitive information. ',
  },
  {
    label: 'Interpersonal Skills',
    name: 'interpersonalSkills',
    placeholder:
      'The apprentice needs to demonstrate strong interpersonal skills and the ability to building strong relationships with others. ',
  },
  {
    label: 'Process Management',
    name: 'processManagement',
    placeholder:
      'The apprentice needs to be effective at following specific processes exactly as described. ',
  },
  {
    label: 'Technology Acumen',
    name: 'technologyAcumen',
    placeholder:
      'The apprentice needs to be adept at using a computer and common applications, such as Microsoft Office or Google Suite. ',
  },
];

export const traits = [
  {
    label: 'Perseverance',
    name: 'perseverance',
    placeholder: 'Evidence of facing and overcoming a challenge ',
  },
  {
    label: 'Coachability',
    name: 'coachability',
    placeholder:
      'Evidence of the ability to seek, receive, and integrate feedback',
  },
  {
    label: 'Initiative',
    name: 'initiative',
    placeholder:
      'Evidence of the ability to identify a task and begin a process',
  },
  {
    label: 'Organization',
    name: 'organization',
    placeholder:
      'Evidence of the ability to organize themselves to complete a task',
  },
  {
    label: 'Ability to Influence',
    name: 'abilityToInfluence',
    placeholder:
      'Evidence of the ability to effectively listen and persuade others',
  },
  {
    label: 'Processing Power',
    name: 'processingPower',
    placeholder:
      'Evidence of the ability to deconstruct a problem to identify solutions',
  },
];

export const defaultSeekerTraits = () => {
  let data = {};
  _.forEach(traits, (trait) => {
    data[trait.name] = {level: 1, note: ''};
  });
  return data;
};

export const defaultJobTraits = () => {
  let data = {};
  _.forEach(traits, (trait) => {
    data[trait.name] = {level: 1};
  });
  return data;
};

export const getTraitsList = (isModernApprentice) => {
  if (isModernApprentice === true) {
    return maTraits;
  }
  return traits;
};
