import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

class WorkStyle extends React.Component {
  render() {
    const {label, level, showHorizontalRule} = this.props;
    return (
      <div>
        <div
          className="row"
          role="img"
          aria-label={`${label} ${level} out of 5`}
        >
          <div className="col-md-6">
            <span className="normal-caption bold" style={{fontSize: '14px'}}>
              {label}
            </span>
          </div>
          <div className="col-md-6 my-auto">
            {_.range(5).map((i) => {
              return (
                <div
                  key={i}
                  className={classNames([
                    'work-style-circle',
                    'inlineblock',
                    {'work-style-circle-disabled': i + 1 > level},
                  ])}
                />
              );
            })}
          </div>
        </div>
        {showHorizontalRule && <hr className="work-style" />}
      </div>
    );
  }
}

WorkStyle.propTypes = {
  label: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  showHorizontalRule: PropTypes.bool,
};

export default WorkStyle;
