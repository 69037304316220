import React from 'react';
import PropTypes from 'prop-types';

class LogoWithName extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 205 50"
        version="1.1"
        role="img"
        aria-label="Ascend Logo"
      >
        <title>Ascend logo with name</title>
        <defs />
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fillRule="nonzero" fill={this.props.color}>
            <path d="M29.1864407,2.35981638 L1.44774011,25.6771186 C0.396680791,26.6586864 0,27.0322034 0,28.3033192 L0,40.2558616 C0.00758556252,41.8584949 1.29310719,43.1620139 2.89548023,43.1918785 L7.92492938,43.1918785 C7.51956215,43.1918785 7.60642655,41.8860169 7.60642655,40.2558616 L7.60642655,30.4778249 C7.66491319,29.4712779 8.16646258,28.5424042 8.9759887,27.9413842 L26.7252825,13.0904661 L29.2443503,11.0086158 C30.5762712,9.85042373 30.6920904,9.27132768 30.6920904,8.38241525 L30.6920904,0.868644068 C30.6920904,1.14371469 30.1129944,1.5085452 29.1864407,2.35981638 Z" />
            <path d="M11.5066384,39.4306497 L13.0586158,38.0987288 L13.0586158,38.0987288 L27.6170904,25.9174435 C27.9790254,25.6452684 28.6420904,24.7274011 28.6420904,24.0498588 L28.6420904,15.9251412 C28.6420904,16.2841808 27.1190678,17.4655367 26.5254944,17.9519774 L13.1599576,29.0387712 L13.1599576,29.0387712 L11.6340395,30.3793785 C11.1447034,30.8687147 10.7132768,31.557839 10.7132768,32.0992938 L10.7132768,40.1052966 L10.7132768,40.1052966 C10.7132768,40.1052966 10.7132768,40.125565 11.5066384,39.4306497 Z" />
            <path d="M25.9840395,31.8387006 L24.9387712,32.7073446 L24.5015537,33.0750706 L12.450565,43.1918785 L24.6984463,43.1918785 C25.3003569,43.1981251 25.8794767,42.9619748 26.3053869,42.5366093 C26.7312971,42.1112437 26.9681882,41.5324266 26.9627119,40.9305085 L26.9627119,30.9816384 C26.7745056,31.1669492 26.1606638,31.7055085 25.9840395,31.8387006 Z" />
            <path d="M25.6510593,48.9712571 L1.17846045,48.9712571 C0.533259852,48.9604651 0.0123791387,48.4408642 0,47.7956921 L0,46.6375 C0.0108384916,45.9911969 0.532157399,45.469878 1.17846045,45.4590395 L25.6510593,45.4590395 C26.2973624,45.469878 26.8186813,45.9911969 26.8295198,46.6375 L26.8295198,47.7956921 C26.8171406,48.4408642 26.2962599,48.9604651 25.6510593,48.9712571 Z" />
            <path d="M20.8185028,1.24216102 L2.89548023,16.2783898 L2.89548023,16.2783898 L0.972881356,17.931709 C0.208474576,18.6932203 0,19.0117232 0,20.2451977 L0,23.9398305 C0,23.7632062 0.550141243,23.2217514 1.08580508,22.767161 L2.98813559,21.1688559 L2.98813559,21.1688559 L20.9082627,6.1355226 C21.452613,5.58538136 22.0056497,5.06129944 22.0056497,4.05367232 L22.0056497,0 C22.0056497,0.240324859 21.1051554,1.025 20.8185028,1.24216102 Z" />
            <path d="M59.0677966,15.0564972 L54.4350282,15.0564972 L41.1158192,48.8757062 L45.6588277,48.8757062 L48.3834746,41.807839 L65.0411723,41.807839 L67.7658192,48.8757062 L72.5115113,48.8757062 L59.0677966,15.0564972 Z M49.9817797,37.5688559 L56.7456215,20.505791 L63.5094633,37.5688559 L49.9817797,37.5688559 Z" />
            <path d="M74.4138418,46.3913842 L76.6346751,42.9515537 C79.3074031,44.5937402 82.3811041,45.4673606 85.5180085,45.4764124 C89.8612288,45.4764124 91.5753531,44.7699153 91.5753531,41.7412429 C91.5753531,39.317726 89.9596751,38.8139124 85.5180085,38.1074153 C79.5620056,37.0968927 75.6241525,36.0805791 75.6241525,30.3330508 C75.6241525,25.7900424 78.2503531,23.1638418 85.5180085,23.1638418 C89.4558616,23.1638418 92.6872175,23.9716808 95.107839,25.3846751 L92.9883475,28.917161 C90.6739727,27.7484495 88.1104095,27.1592275 85.5180085,27.2001412 C81.0763418,27.2001412 79.9644774,28.410452 79.9644774,30.3301554 C79.9644774,32.6465395 81.4788136,33.2574859 85.6193503,33.9639831 C91.7780367,35.0758475 95.7158898,35.880791 95.7158898,41.5356638 C95.7158898,47.2889831 92.5858757,49.3100282 85.1155367,49.3100282 C81.6814972,49.3216102 77.7465395,48.7135593 74.4138418,46.3913842 Z" />
            <path d="M99.6045198,36.2890537 C99.6045198,28.5146893 102.23072,23.1638418 112.023234,23.1638418 C116.464901,23.1638418 119.493573,24.7795198 121.413277,26.8990113 L118.167444,29.9189972 C116.957133,28.7086864 115.240113,27.3941384 112.008757,27.3941384 C105.850071,27.3941384 104.034605,30.7268362 104.034605,36.3701271 C104.034605,42.0134181 105.751624,45.4561441 112.008757,45.4561441 C114.733404,45.4561441 116.450424,44.5469633 118.378814,42.8299435 L121.306144,45.6559322 C118.679944,48.0794492 116.259322,49.3911017 111.81476,49.3911017 C102.210452,49.3187147 99.6045198,44.0663136 99.6045198,36.2890537 Z" />
            <path d="M136.823023,23.1638418 C126.726483,23.1638418 124.50565,29.0185028 124.50565,36.2890537 C124.50565,43.5596045 126.625141,49.4142655 136.823023,49.4142655 C141.568715,49.4142655 144.597387,48.3052966 147.220692,46.1829096 L144.904308,43.0528955 C142.9875,44.5672316 140.966455,45.5777542 136.92726,45.5777542 C130.667232,45.5777542 129.152895,42.5490819 128.851766,38.0060734 L147.429167,38.0060734 C147.524718,30.3330508 147.220692,23.1638418 136.823023,23.1638418 Z M128.845975,34.0682203 C129.248446,29.8292373 130.965466,27.0003531 136.721681,27.0003531 C142.677684,27.0003531 143.091737,30.1303672 143.282839,34.0682203 L128.845975,34.0682203 Z" />
            <path d="M152.881356,23.9716808 L156.413842,23.7689972 L156.816314,26.1925141 C158.026624,24.5768362 160.348799,23.1638418 166.105014,23.1638418 C174.082062,23.1638418 175.191031,27.4028249 175.191031,33.5615113 L175.191031,48.9075565 L170.749364,48.9075565 L170.749364,34.3722458 C170.749364,30.3330508 170.346893,27.4057203 164.69202,27.4057203 C158.533333,27.4057203 157.424364,30.5357345 157.424364,34.8760593 L157.424364,48.8091102 L152.979802,48.8091102 L152.979802,23.9716808 L152.881356,23.9716808 Z" />
            <path d="M200.367232,13.8983051 L200.367232,26.3141243 C199.156921,24.2872881 196.632062,23.285452 191.391243,23.285452 C182.80904,23.285452 180.388418,28.6362994 180.388418,36.315113 C180.388418,43.9939266 182.800353,49.4374294 191.179873,49.4374294 C196.53072,49.4374294 199.255367,48.4269068 200.871045,46.2060734 L201.374859,49.133404 L204.806003,48.8438559 L204.806003,13.8983051 L200.367232,13.8983051 Z M192.190395,45.4966808 C186.437076,45.4966808 184.92274,41.8628531 184.92274,36.309322 C184.92274,30.8571328 186.437076,27.4259887 192.190395,27.4259887 C198.24774,27.4259887 200.367232,29.646822 200.367232,36.309322 C200.367232,42.971822 198.146398,45.3837571 192.190395,45.4966808 Z" />
          </g>
        </g>
      </svg>
    );
  }
}

LogoWithName.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LogoWithName.defaultProps = {
  color: '#ffffff',
  width: '103px',
  height: '25px',
};

export default LogoWithName;
