import {checkCacheValid} from 'redux-cache';

import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export const getEmploymentTypes = () => {
  return (dispatch, getState) => {
    const isCacheValid = checkCacheValid(getState, 'employmentTypes');
    if (isCacheValid) {
      return null;
    }

    return dispatch({
      types: [
        types.EMPLOYMENT_TYPES__GET_REQUEST,
        types.EMPLOYMENT_TYPES__GET_SUCCESS,
        types.EMPLOYMENT_TYPES__GET_FAILURE,
      ],
      uri: `${apiUrl}/employment_types`,
      method: 'GET',
      isAuthenticated: false,
    });
  };
};
