import React from 'react';
import PropTypes from 'prop-types';

import {withRouter} from '../../utils/commonHooks';
import RenderHtml from 'src/components/controls/RenderHtml';

class ExperienceEditLink extends React.Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div
        className="clickable candidate-profile-edit-link bold orangeHover"
        style={{textDecoration: 'underline'}}
        onClick={this.handleClick}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick(e);
          }
          return;
        }}
        aria-label="Edit Experiences"
        role="button"
      >
        Edit
      </div>
    );
  }
}

ExperienceEditLink.propTypes = {
  onClick: PropTypes.func,
};

class ExperienceWidget extends React.Component {
  render() {
    let rows = [];
    if (this.props.experience) {
      for (var i = 0; i < this.props.experience.length; i++) {
        if (this.props.experience[i] === null) continue;
        var element = (
          <div key={i}>
            <div className="bd-whitebackground padding-16">
              <h3>
                <div className="disabled-caption">
                  {this.props.experience[i]['timeframe']}
                </div>
                <div className="normal-title">
                  {this.props.experience[i]['title']}
                </div>
              </h3>
              <div className="active-body">
                {this.props.experience[i]['company']}
              </div>
              <br />
              <RenderHtml
                className={'secondary-subheader prewrap'}
                htmlString={this.props.experience[i]['notes']}
              ></RenderHtml>
            </div>
            <br />
          </div>
        );
        rows.push(element);
      }
    }

    return (
      <div>
        <div className="inlineblock float-right">
          {this.props.allowEdit && (
            <ExperienceEditLink onClick={this.props.onEdit} />
          )}
        </div>
        <div className="normal-subheader ">
          <h3>Experience</h3>
        </div>
        <br />
        {rows}
      </div>
    );
  }
}

ExperienceWidget.propTypes = {
  onEdit: PropTypes.func,
  experience: PropTypes.array,
  allowEdit: PropTypes.bool.isRequired,
};

export default withRouter(ExperienceWidget);
