import React from 'react';
import PropTypes from 'prop-types';
import styles from './RecentlySavedCandidates.module.scss';
import iconSVG from 'src/assets/svg/icons/heart-default-white.svg';
import {Link} from 'react-router-dom';

const RecentlySavedCandidates = ({
  numSavedCandidates,
  savedCandidatesUrl,
  exploreCandidatesUrl,
}) => {
  const getCandidateText = (count) => {
    if (count === 0) {
      return (
        <>
          Your team has not saved any candidates in the last 30 days.{' '}
          <Link
            style={{fontFamily: 'Manrope'}}
            className={`accessibleLink buttonAsLink`}
            to={exploreCandidatesUrl}
          >
            Click here
          </Link>{' '}
          to browse quality candidates.
        </>
      );
    } else if (count === 1) {
      return (
        <>
          <Link
            style={{fontFamily: 'Manrope'}}
            className={`accessibleLink buttonAsLink`}
            to={savedCandidatesUrl}
          >
            <strong>1</strong> candidate
          </Link>{' '}
          was saved by your team in the last 30 days.
        </>
      );
    } else if (count > 50) {
      return (
        <>
          <Link
            style={{fontFamily: 'Manrope'}}
            className={`accessibleLink buttonAsLink`}
            to={savedCandidatesUrl}
          >
            <strong>Over 50</strong> candidates
          </Link>{' '}
          were saved by your team in the last 30 days.
        </>
      );
    } else {
      return (
        <>
          <Link
            style={{fontFamily: 'Manrope'}}
            className={`accessibleLink buttonAsLink`}
            to={savedCandidatesUrl}
          >
            <strong>{count}</strong> candidates
          </Link>{' '}
          were saved by your team in the last 30 days.
        </>
      );
    }
  };

  return (
    <div className={styles.candidatePanel}>
      <div className={styles.iconContainer}>
        <img src={iconSVG} alt="Saved Candidate Icon" />
      </div>
      <div className={styles.textContainer}>
        {getCandidateText(numSavedCandidates)}
      </div>
    </div>
  );
};

RecentlySavedCandidates.propTypes = {
  numSavedCandidates: PropTypes.number.isRequired,
  savedCandidatesUrl: PropTypes.string.isRequired,
  exploreCandidatesUrl: PropTypes.string.isRequired,
};

export default RecentlySavedCandidates;
