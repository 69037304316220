import React, {Fragment, useState, useEffect, forwardRef} from 'react';
import style from './SelectAscend.module.scss';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import {ChevronDownSvg} from '../../../assets/svg/svgComponents';

const SelectAscend = forwardRef(
  (
    {
      placeholder,
      isLoading,
      isDisabled,
      value,
      onChange,
      options,
      styles,
      className,
      defaultValue,
      selectorGlyph,
      name,
      labelText,
      id,
    },
    ref
  ) => {
    const [showGlyph, setShowGlyph] = useState(false);
    useEffect(() => {
      if (selectorGlyph === undefined) {
        setShowGlyph(false);
        return;
      }
      setShowGlyph(true);
    }, [selectorGlyph]);

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <ChevronDownSvg className={style.settingsSvg_fill_gray} />
        </components.DropdownIndicator>
      );
    };

    const ValueContainer = ({children, ...props}) => {
      return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <Fragment>
                <i
                  style={{
                    position: 'absolute',
                    left: 10,
                  }}
                >
                  {selectorGlyph}
                </i>
              </Fragment>
            )}
            {children}
          </components.ValueContainer>
        )
      );
    };
    const valueContainerStyle = {
      valueContainer: (base) => ({
        ...base,
        paddingLeft: showGlyph ? '35px' : '10px',
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        zIndex: '0',
        color: 'var(--neutral-gray-3)',
      }),
    };
    const placeholderStyle = {
      placeholder: (base) => ({
        ...base,
        color: 'var(--neutral-gray-3)',
      }),
    };

    return (
      <Select
        inputId={id}
        name={name}
        aria-label={labelText}
        className={className}
        defaultValue={defaultValue}
        components={{DropdownIndicator, ValueContainer}}
        styles={Object.assign(valueContainerStyle, placeholderStyle, styles)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={isDisabled}
        value={value}
      />
    );
  }
);

SelectAscend.propTypes = {
  labelText: PropTypes.string,
  children: PropTypes.any,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.any,
  styles: PropTypes.any,
  className: PropTypes.any,
  defaultValue: PropTypes.any,
  selectorGlyph: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default SelectAscend;
