import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {withRouter} from '../../utils/commonHooks';
import {connect} from 'react-redux';
import track from 'react-tracking';

import * as AuthorizeActions from '../../actions/AuthorizationActions';
import * as employersActions from '../../actions/employers';
import * as teamsActions from '../../actions/teams';
import * as seekersActions from '../../actions/seekers';
import * as Layout from '../../layout';
import ZipWidget from './ZipWidget';
import TeamCard from './TeamCard';
import {employerIsWatched} from '../../utils/employerHelper';
import {
  getGoogleMapUrl,
  addressToString,
  splitArrayIntoRows,
  includesApprenticeProgram,
} from '../../utils/miscHelper';
import {EditEmployerForm} from '../../forms/EmployerForm';
import IsDraftWidget from '../../components/IsDraftWidget';
import {shortUrl} from '../../utils/miscHelper';
import {EditTeamForm} from '../../forms/TeamForm';
import {fixUrl} from '../../utils/fieldHelper';
import SideBarSegmentDisplay from '../../components/SideBarSegmentDisplay';
import SideBarDisplayItem from '../../components/SideBarDisplayItem';
import * as usersActions from '../../actions/users';
import {has_permission, PERMISSIONS} from '../../services/authorizationApi';
import rightSvg from '../../assets/images/nav-right.svg';
import leftSvg from '../../assets/images/nav-left.svg';
import {
  ArrowRounded,
  HeartFilledWithoutBorderSvg,
} from 'src/assets/svg/svgComponents';
import editSvg from '../../assets/images/Edit.svg';
import styles from './EmployerProfilePage.module.scss';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, A11y} from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/navigation';
import PhotoGalleryModal from 'src/components/PhotoGalleryModal';

import * as jobsActions from '../../actions/jobs';
import {Link} from 'react-router-dom';
import SimpleJobCard from '../../components/SimpleJobCard';
import {jobIsApplied, jobIsWatched} from '../../utils/jobHelper';
import JobProfile from '../../components/JobProfile';
import _ from 'lodash';
import classNames from 'classnames';
import PreviousButton from 'src/components/PreviousButton';
import CandidateNotifyMissingRole from '../../components/Candidate/CandidateNotifyMissingRole';

@track({eventName: 'EmployerProfilePage'}, {dispatchOnMount: true})
class EmployerProfilePage extends React.Component {
  state = {
    employerIsWatched: false,
    carouselIndex: 0,
    formOpen: false,
    teamFormOpen: false,
    galleryOpen: false,
    galleryInitialSlideIndex: 0,
    userList: [],
    isMobile: false,
    profileOpen: false,
    jobId: null,
  };

  handleEmployerWatchedChanged = () => {
    const employerId = this.props.match.params.id;
    if (this.state.employerIsWatched === false) {
      this.props.createEmployerWatch(employerId);
    } else {
      this.props.deleteEmployerWatch(employerId);
    }
    this.setState({employerIsWatched: !this.state.employerIsWatched});
  };

  handleTeamClick = (teamId) => {
    this.props.history.push('/team/' + teamId);
  };

  handleEditClicked = () => {
    const employerId = this.props.match.params.id;
    this.props.getEmployerForEdit(employerId);
    this.setState({
      formOpen: true,
      formMode: 'edit',
      employerIdToEdit: employerId,
    });
  };

  handleEditTeamClicked = (teamId) => {
    this.props.getTeamForEdit(teamId);
    this.setState({
      teamFormOpen: true,
      teamIdToEdit: teamId,
    });
  };

  handleEmployerEditSubmit = (employer) => {
    this.props.updateEmployer(employer);
    this.setState({formOpen: false});
  };

  handleTeamEditSubmit = (team) => {
    this.props.updateTeam(team);
    this.setState({teamFormOpen: false});
  };

  handleTeamDeleteConfirmed = (teamId) => {
    this.props.deleteTeam(teamId);
  };

  handleModalClose = () => {
    this.setState({
      formOpen: false,
      teamFormOpen: false,
      galleryOpen: false,
      profileOpen: false,
    });
  };

  handleEmployerApproveClick = () => {
    const employerId = this.props.match.params.id;
    let employer = {};
    if (employerId in this.props.employerCache) {
      employer = this.props.employerCache[employerId];
    }
    employer.published = true;
    this.props.updateEmployer(employer);
    this.setState({employerModelOpen: false});
  };

  componentDidMount() {
    has_permission(PERMISSIONS.ASCEND_RECRUITER).then((resp) => {
      this.showAdminLinks = resp.data.hasPermission;
    });

    const handler = (e) => this.setState({matches: e.matches});

    window.matchMedia('(max-width: 760px)').addListener(handler); // This is the only solution that works in safari at the moment

    this.setState(() => ({
      isMobile: window.matchMedia('(max-width: 760px').matches,
    }));

    if (this.props.employerCacheLoading) {
      return;
    }
    this.props.getUsersByPermission(PERMISSIONS.CAN_BE_ACCOUNT_MANAGER);
    const employerId = this.props.match.params.id;
    this.props.getEmployerToCache(employerId);
    this.props.canEditEmployer(employerId);
    this.props.getTeamsByEmployerAdvanced(
      employerId,
      0,
      50,
      '-dateadded',
      {},
      false
    );
    if (this.props.seekerId && this.props.seekerId !== this.props.seeker.id) {
      this.props.getSeeker(this.props.seekerId);
    }

    this.props.getEmployerJobsSorted({
      employerId: employerId,
      seekerId: this.props.seekerId,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    if (
      prevProps.allowEmployerEdit !== this.props.allowEmployerEdit &&
      this.props.allowEmployerEdit
    ) {
      const employerId = this.props.match.params.id;
      this.props.getTeamsByEmployerAdvanced(
        employerId,
        0,
        50,
        '-roles',
        {},
        true
      );
    }
    if (prevProps.usersList !== this.props.usersList) {
      let list = this.props.usersList.map((x) => {
        return {
          value: x.userId,
          label: `${x.firstName} ${x.lastName}`,
        };
      });

      this.setState({userList: list});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.seeker && nextProps.seeker.watchedEmployers) {
      const employerId = nextProps.match.params.id;
      return {
        employerIsWatched: employerIsWatched(
          Number(employerId),
          nextProps.seeker
        ),
      };
    }
    return null;
  }

  handleCarouselClick = (teams, direction) => {
    const max = Math.ceil(teams.length / 2) - 1;
    let newIndex = null;
    if (direction === 'left') {
      newIndex = this.state.carouselIndex - 1;
      if (newIndex < 0) {
        newIndex = max;
      }
    } else {
      newIndex = this.state.carouselIndex + 1;
      if (newIndex > max) {
        newIndex = 0;
      }
    }
    this.setState({carouselIndex: newIndex});
  };

  findUserName = (userId) => {
    const user = this.state.userList.find((x) => x.value === userId);
    if (user) {
      return user.label;
    }
    return '';
  };

  handleWatchChanged = (job, watched) => {
    if (watched) {
      this.props.createJobWatch(job);
    } else {
      this.props.deleteJobWatch(job.id);
    }
  };

  handleJobClicked = (id) => {
    this.props.getJobToCache(id);
    this.setState({
      profileOpen: true,
      jobId: id,
    });
  };

  handleTeamClicked = (id) => {
    this.props.history.push('/team/' + id);
  };

  handleEmployerClicked = (id) => {
    this.props.history.push('/employer/' + id);
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  render() {
    const renderCommaList = (content) => {
      return content || 'Not selected';
    };

    const {role, seeker, teams, match, allowEmployerEdit} = this.props;
    const employerId = match.params.id;

    let employer = {};
    if (employerId in this.props.employerCache) {
      employer = this.props.employerCache[employerId];
    }

    const isEmployerAvailable = () => {
      if (!employer) return false;

      if (!employer.id) return false;

      if (role === 'JOB_SEEKER') {
        if (!seeker.id) return false;
      }
      return true;
    };

    const seekerActionIcons = () => {
      if (this.state.employerIsWatched === true) {
        return (
          <Layout.ActionIcons
            style={
              this.state.isMobile ? {width: 'initial', paddingRight: '8px'} : {}
            }
          >
            <Layout.ActionIcon
              svgImage={
                <HeartFilledWithoutBorderSvg
                  width="24"
                  height="24"
                  className="svg_color_fill_pink_heart"
                />
              }
              message="Favorite this employer."
              ariaPressed={this.state.employerIsWatched}
              svgAsImage={true}
              onClick={() => this.handleEmployerWatchedChanged()}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handleEmployerWatchedChanged();
                  e.preventDefault();
                }
              }}
            />
          </Layout.ActionIcons>
        );
      } else {
        return (
          <Layout.ActionIcons
            style={
              this.state.isMobile ? {width: 'initial', paddingRight: '8px'} : {}
            }
          >
            <Layout.ActionIcon
              svgImage={
                <HeartFilledWithoutBorderSvg
                  width="22"
                  height="22"
                  strokeWidth="0.5"
                  className="svg_color_fill_gray_heart svg_color_stroke_gray_heart"
                />
              }
              message="Favorite this employer."
              svgAsImage={true}
              ariaPressed={this.state.employerIsWatched}
              onClick={() => this.handleEmployerWatchedChanged()}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handleEmployerWatchedChanged();
                  e.preventDefault();
                }
              }}
            />
          </Layout.ActionIcons>
        );
      }
    };

    const recruiterActionIcons = () => {
      return (
        allowEmployerEdit && (
          <Layout.ActionIcons>
            <Layout.ActionIcon
              svg={editSvg}
              message="Edit Employer"
              onClick={() => this.handleEditClicked()}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.handleEditClicked();
                  e.preventDefault();
                }
              }}
            />
          </Layout.ActionIcons>
        )
      );
    };

    const renderCarouselNav = (teams, direction) => {
      const svg = direction === 'left' ? leftSvg : rightSvg;
      const altdirection =
        direction === 'left' ? 'View More Teams Left' : 'View More Teams Right';
      return (
        <div
          role="button"
          aria-label={altdirection}
          tabIndex={0}
          onClick={() => this.handleCarouselClick(teams, direction)}
          className="clickable text-align-center team-carousel-tooltip"
          width="64px"
          height="130px"
          onKeyPress={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              this.handleCarouselClick(teams, direction);
              e.preventDefault();
            }
          }}
        >
          <span className="team-carousel-tooltiptext">View More Teams</span>
          <img
            className="clickable"
            alt=""
            width="50px"
            height="50px"
            src={svg}
          />
        </div>
      );
    };

    const renderTeamRowDouble = (teams, row) => {
      return (
        <div className="row padding-top-sixteen padding-bottom-sixteen ">
          {this.state.isMobile === false && (
            <div className="col-md-1 mx-auto my-auto">
              {renderCarouselNav(teams, 'left')}
            </div>
          )}
          <div className="col-md-5 padding-bottom-sixteen">
            <TeamCard
              canEdit={allowEmployerEdit}
              key={row[0].id}
              published={row[0].published}
              teamId={row[0].id}
              name={row[0].name}
              openRoleCount={row[0].publishedJobCount}
              summary={row[0].summary}
              role={this.props.role}
              onTeamClick={this.handleTeamClick}
              onTeamEditClick={this.handleEditTeamClicked}
            />
          </div>
          <div className="col-md-5 padding-bottom-sixteen">
            <TeamCard
              canEdit={allowEmployerEdit}
              key={row[1].id}
              published={row[1].published}
              teamId={row[1].id}
              name={row[1].name}
              openRoleCount={row[1].publishedJobCount}
              summary={row[1].summary}
              role={this.props.role}
              onTeamClick={this.handleTeamClick}
              onTeamEditClick={this.handleEditTeamClicked}
            />
          </div>

          {this.state.isMobile && (
            <div className="row col-md-5 ">
              <div className="col col-9">
                {renderCarouselNav(teams, 'left')}
              </div>

              <div className="col ">{renderCarouselNav(teams, 'right')}</div>
            </div>
          )}
          {this.state.isMobile === false && (
            <div className="col-md-1 mx-auto my-auto">
              {renderCarouselNav(teams, 'right')}
            </div>
          )}
        </div>
      );
    };

    const renderTeamRowSingle = (teams, row) => {
      return (
        <div className="row padding-top-sixteen padding-bottom-sixteen">
          <div className="col-md-1 my-auto">
            {renderCarouselNav(teams, 'left')}
          </div>
          <div className="col-md-3" />
          <div className="col-md-5 padding-bottom-sixteen">
            <TeamCard
              canEdit={allowEmployerEdit}
              key={row[0].id}
              published={row[0].published}
              teamId={row[0].id}
              name={row[0].name}
              openRoleCount={row[0].publishedJobCount}
              summary={row[0].summary}
              role={this.props.role}
              onTeamClick={this.handleTeamClick}
              onTeamEditClick={this.handleEditTeamClicked}
            />
          </div>
          <div className="col-md-2" />

          <div className="col-md-1 my-auto">
            {renderCarouselNav(teams, 'right')}
          </div>
        </div>
      );
    };

    const renderTeamCards = (teams, index) => {
      if (teams.length === 0 || this.state.isMobile) return <br />;
      const rows = splitArrayIntoRows(teams, 2);
      const row = rows[index];
      if (row.length === 2) {
        return renderTeamRowDouble(teams, row);
      } else {
        return renderTeamRowSingle(teams, row);
      }
    };

    let resourceItems = [];

    if (employer && employer.id && employer.resources) {
      for (let j = 0; j < employer.resources.length; j++) {
        const resItem = employer.resources[j];
        // sometimes null resource items can be added to the json
        if (resItem) {
          resourceItems.push(
            <a
              key={j}
              href={fixUrl(resItem.url)}
              target="_blank"
              rel="noreferrer"
              className="orangeHoverParent"
            >
              <div className=" bd-whitebackground-nopadding padding-bottom-eight clickable">
                <div className="bd-whitebackground padding-16">
                  <div className="normal-body orangeHover">{resItem.title}</div>
                  <div className="disabled-caption d-flex align-items-center">
                    <img
                      src={`http://www.google.com/s2/favicons?domain=${fixUrl(
                        resItem.url
                      )}`}
                      alt={`${resItem.name} favicon`}
                      style={{filter: 'grayscale(1)'}}
                    ></img>
                    &nbsp;&nbsp;
                    {resItem.name}
                  </div>
                </div>
              </div>
            </a>
          );
        }
      }
    }

    const renderName = (employer) => {
      if (employer && employer.id) {
        return (
          <h1 style={{width: '100%'}}>
            <div
              style={this.state.isMobile ? {width: '79%'} : {}}
              className="profile-header bold inlineblock"
            >
              {employer.name}
            </div>
            {this.props.role === 'JOB_SEEKER' &&
              this.state.isMobile &&
              seekerActionIcons()}
          </h1>
        );
      } else {
        return <div className="profile-header bold inlineblock">&nbsp;</div>;
      }
    };

    const renderTagline = (employer) => {
      if (employer && employer.id) {
        return (
          <div className="candidate-profile-title padding-top-sixteen">
            {employer.tagline}
          </div>
        );
      } else {
        return (
          <div className="candidate-profile-title padding-top-sixteen">
            &nbsp;
            <br />
            &nbsp;
          </div>
        );
      }
    };

    const renderDraftWidget = (employer) => {
      if (employer && employer.id && !employer.published) {
        return (
          <IsDraftWidget
            titleString="You're viewing a draft of your company's profile."
            editClick={this.handleEmployerEditClick}
            approveClick={this.handleEmployerApproveClick}
          />
        );
      }
    };

    const buildLocationMapUrl = (location) => {
      return (
        <a
          className="underline"
          href={getGoogleMapUrl(location)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {addressToString(location)}
        </a>
      );
    };

    const jobColumn = () => {
      const role = this.props.role;

      return this.props.jobs?.slice(0, 3)?.map((job) => {
        let matchData = {};
        if (job.matchData !== undefined) {
          matchData = job.matchData;
        }

        let invitedDate = null;
        if ('invitedOn' in job) {
          invitedDate = new Date(job.invitedOn);
        }

        return (
          <div key={job.id} className="mb-2">
            <SimpleJobCard
              showWatchIcon={role === 'JOB_SEEKER'}
              viewerType={'CANDIDATE'}
              watched={jobIsWatched(job.id, this.props.seeker)}
              invitedDate={invitedDate}
              invitedByName={matchData.invitedByName}
              invitedByRole={matchData.invitedByRole}
              onWatchChanged={(watched) =>
                this.handleWatchChanged(job, watched)
              }
              name={job.name}
              team={job.team.name}
              employer={job.employer.name}
              matchData={matchData}
              onJobClick={() => this.handleJobClicked(job.id)}
              onTeamClick={() => this.handleTeamClicked(job.teamId)}
              onEmployerClick={() => this.handleEmployerClicked(job.employerId)}
              isMaSegment={this.state.isMaSegment}
              isApprenticeProgram={includesApprenticeProgram(this.props.seeker)}
            />
          </div>
        );
      });
    };
    const renderRoleCards = () => {
      return <div> {jobColumn()}</div>;
    };

    const findMatchData = () => {
      const match = _.find(this.props.seeker.matches, (i) => {
        return i.jobId === this.state.jobId;
      });
      return match ? match.matchData : null;
    };

    const getJobProfile = () => {
      if (this.state.jobId) {
        let job;

        if (this.state.jobId in this.props.jobCache) {
          job = this.props.jobCache[this.state.jobId];
        }

        if (!job) {
          return null;
        }

        return (
          <JobProfile
            key={job.id}
            job={job}
            isOpen={this.state.profileOpen}
            onClose={this.handleModalClose}
            onWatchChanged={(watched) => this.handleWatchChanged(job, watched)}
            watched={jobIsWatched(this.state.jobId, this.props.seeker)}
            applied={jobIsApplied(this.state.jobId, this.props.seeker)}
            onAppliedChanged={(applied) =>
              this.handleAppliedChanged(this.state.jobId, applied)
            }
            matchData={findMatchData()}
          />
        );
      }
    };
    let employerName = '';
    if (employer && employer.name) {
      employerName = employer.name + ' - ';
    }
    return (
      <DocumentTitle title={`${employerName}Employer Profile`}>
        <div className="wholepage">
          {(this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
            this.props.role === 'ASCEND_RECRUITER') &&
            renderDraftWidget(employer)}
          <Layout.PageHeader
            title={null}
            showAdminLinks={this.showAdminLinks}
          />
          {employer && employer.employerPhotos && (
            <PhotoGalleryModal
              isOpen={this.state.galleryOpen}
              onClose={this.handleModalClose}
              photos={employer.employerPhotos}
              initialSlideIndex={this.state.galleryInitialSlideIndex}
            />
          )}
          {(this.props.role === 'ASCEND_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN') &&
            this.state.formMode === 'edit' && (
              <EditEmployerForm
                id={this.state.employerIdToEdit}
                onSubmit={this.handleEmployerEditSubmit}
                onDelete={() =>
                  this.handleDeleteConfirmed(this.state.employerIdToEdit)
                }
                isOpen={this.state.formOpen}
                onClose={this.handleModalClose}
              />
            )}
          {this.state.teamIdToEdit &&
            (this.props.role === 'ASCEND_RECRUITER' ||
              this.props.role === 'COMPANY_RECRUITER' ||
              this.props.role === 'COMPANY_RECRUITER_ADMIN') && (
              <EditTeamForm
                id={this.state.teamIdToEdit}
                onSubmit={this.handleTeamEditSubmit}
                onDelete={() =>
                  this.handleTeamDeleteConfirmed(this.state.teamIdToEdit)
                }
                isOpen={this.state.teamFormOpen}
                onClose={this.handleModalClose}
              />
            )}
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              {this.props.jobCache && getJobProfile()}
              <PreviousButton />
              <div className="container">
                {this.props.showJobNotFoundModal && (
                  <CandidateNotifyMissingRole
                    isOpen={this.props.showJobNotFoundModal}
                    onClose={() => this.props.setJobNotFoundModal(false)}
                  />
                )}
                <div className="row">
                  <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                  <div className="row padding-bottom-eight">
                    <div className="col-md-10">
                      <div className="inlineblock mb-3 ms-3">
                        {renderName(employer)}
                        {renderTagline(employer)}
                      </div>
                    </div>
                    <div className="col-md-2">
                      {this.props.role === 'JOB_SEEKER' &&
                        !this.state.isMobile &&
                        seekerActionIcons()}
                      {this.props.role === 'ASCEND_RECRUITER' &&
                        recruiterActionIcons()}
                    </div>
                  </div>
                </div>
                {isEmployerAvailable() && (
                  <Fragment>
                    {renderTeamCards(teams, this.state.carouselIndex)}
                    <div className="row">
                      <div className="col-md-9">
                        <div className="bd-whitebackground-nopadding">
                          <div className="row">
                            <div className="col-md-12">
                              <div style={{padding: '24px'}}>
                                <h2 className="normal-title bold padding-bottom-eight">
                                  About Us
                                </h2>
                                <div className="disabled-subheader padding-bottom-sixteen">
                                  {employer.about}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {this.state.isMobile && (
                          <>
                            <div className="bd-whitebackground-nopadding">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="normal-title bold padding-8">
                                    General
                                  </div>
                                  <div className="bd-whitebackground padding-top-sixteen padding-left-sixteen padding-bottom-sixteen">
                                    <div
                                      className={classNames([
                                        styles.normalSubheaderMed,
                                        'padding-bottom-eight',
                                      ])}
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      <i
                                        className="nc-icon-glyph users_multiple"
                                        alt="Number of Employees"
                                      />
                                      &nbsp;&nbsp;
                                      <span>{employer.companySize}</span>
                                    </div>

                                    <div
                                      className={classNames([
                                        styles.normalSubheaderMed,
                                        'padding-bottom-eight',
                                      ])}
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      <i
                                        className="nc-icon-glyph buildings"
                                        alt="Industry"
                                      />
                                      &nbsp;&nbsp;
                                      <span>
                                        {renderCommaList(
                                          employer.industries
                                            .map((x) => x.name)
                                            .join(', ')
                                        )}
                                      </span>
                                    </div>

                                    {employer.locations.map(
                                      (location, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className={classNames([
                                              styles.normalSubheaderMed,
                                              'padding-bottom-eight',
                                            ])}
                                            style={{
                                              display: 'flex',
                                            }}
                                          >
                                            <i
                                              className="nc-icon-glyph pin"
                                              alt="Location"
                                            />
                                            &nbsp;&nbsp;
                                            <span>
                                              {buildLocationMapUrl(location)}
                                              {location.isPrimaryLocation && (
                                                <div>
                                                  {'(Primary Location)'}
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className={classNames([
                                        styles.normalSubheaderMed,
                                        'padding-bottom-eight',
                                      ])}
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      <i
                                        className="nc-icon-glyph globe"
                                        alt="Website"
                                      />
                                      &nbsp;&nbsp;
                                      <span>
                                        <a
                                          className="clickable active-subheader underlined"
                                          style={{
                                            textDecoration: 'none',
                                            fontSize: '14px',
                                          }}
                                          href={fixUrl(employer.url)}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {shortUrl(employer.url, 30)}
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="col-md-12">
                                {this.props.jobsLoading && (
                                  <span className={styles.emptyRoles}>
                                    Loading Roles...
                                  </span>
                                )}
                                {!this.props.jobsLoading && (
                                  <Fragment>
                                    <h2>
                                      <div className="normal-title bold">
                                        <Link
                                          className="buttonAsLink"
                                          to={`/all_roles/${employerId}`}
                                        >
                                          View Roles (
                                          {this.props.totalSortedJobs})
                                        </Link>
                                      </div>
                                    </h2>

                                    {renderRoleCards()}

                                    <div>
                                      <Link
                                        className="buttonAsLink"
                                        to={`/all_roles/${employerId}`}
                                      >
                                        View All Roles
                                      </Link>
                                    </div>
                                    <br />
                                  </Fragment>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <br />
                        {employer.employerPhotos.length > 0 && (
                          <>
                            <div className="bd-whitebackground padding-left-sixteen  padding-right-sixteen">
                              <div className="row">
                                <h2 className="normal-title bold padding-bottom-eight padding-left-twentyfour padding-top-twentyfour">
                                  Photos
                                </h2>
                              </div>
                              <div className="row">
                                <div
                                  id={styles.employerSwiper}
                                  className="col-md-12"
                                >
                                  <Swiper
                                    modules={[Navigation, A11y]}
                                    navigation={{
                                      nextEl: `.${styles.swiperButtonNext}`,
                                      prevEl: `.${styles.swiperButtonPrev}`,
                                    }}
                                    shortSwipes={false}
                                    threshold={10}
                                    breakpoints={{
                                      // when window width is >= 760px
                                      760: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                        slidesPerGroup: 1,
                                      },
                                      320: {
                                        slidesPerView: 1,
                                        spaceBetween: 50,
                                      },
                                      480: {
                                        spaceBetween: 50,
                                      },
                                    }}
                                    loop={employer.employerPhotos.length > 3}
                                    watchSlidesProgress={true}
                                  >
                                    {employer.employerPhotos.map(
                                      (photo, index) => {
                                        return (
                                          <SwiperSlide
                                            className={`${styles.slide}`}
                                            key={index}
                                          >
                                            <button
                                              className="buttonAsLink"
                                              onClick={() => {
                                                this.setState({
                                                  galleryOpen: true,
                                                  galleryInitialSlideIndex:
                                                    index,
                                                });
                                              }}
                                            >
                                              <img
                                                src={
                                                  photo.imageThumbUrl === ''
                                                    ? photo.imageUrl
                                                    : photo.imageThumbUrl
                                                }
                                                alt={photo.altText}
                                              />
                                            </button>
                                          </SwiperSlide>
                                        );
                                      }
                                    )}
                                    <button
                                      aria-label={'Next Image'}
                                      className={`${styles.swiperButtonNext}`}
                                    >
                                      <ArrowRounded
                                        width="100%"
                                        height="35px"
                                      />
                                    </button>
                                    <button
                                      className={`${styles.swiperButtonPrev}`}
                                    >
                                      <ArrowRounded
                                        width="100%"
                                        height="35px"
                                      />
                                    </button>
                                  </Swiper>
                                </div>
                                <div
                                  className="padding-left-eight"
                                  style={{userSelect: 'none'}}
                                >
                                  <button
                                    id={'something'}
                                    className="buttonAsLink"
                                    onClick={() =>
                                      this.setState({galleryOpen: true})
                                    }
                                  >
                                    View All Photos (
                                    {employer.employerPhotos.length})
                                  </button>
                                </div>
                              </div>
                            </div>
                            <br />
                          </>
                        )}
                        {this.state.isMobile && resourceItems.length > 0 && (
                          <>
                            <h2>
                              <div className="normal-title bold">Resources</div>
                            </h2>
                            <br />
                            {resourceItems}
                          </>
                        )}
                        {employer.zipcodeId && (
                          <ZipWidget zipcodeId={employer.zipcodeId} />
                        )}
                      </div>

                      <div className="col-md-3">
                        {!this.state.isMobile && (
                          <>
                            <h2 className="normal-title bold padding-8">
                              General
                            </h2>
                            <div className="bd-whitebackground padding-top-sixteen padding-left-sixteen padding-bottom-sixteen">
                              <div
                                className={classNames([
                                  styles.normalSubheaderMed,
                                  'padding-bottom-eight',
                                ])}
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <i
                                  className="nc-icon-glyph users_multiple col-1 pt-1"
                                  aria-label="Number of Employees"
                                  role="img"
                                />
                                &nbsp;&nbsp;<span>{employer.companySize}</span>
                              </div>
                              <div
                                className={classNames([
                                  styles.normalSubheaderMed,
                                  'padding-bottom-eight',
                                ])}
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <i
                                  className="nc-icon-glyph buildings col-1 pt-1"
                                  aria-label="Industry"
                                  role="img"
                                />
                                &nbsp;&nbsp;
                                <span>
                                  {renderCommaList(
                                    employer.industries
                                      .map((x) => x.name)
                                      .join(', ')
                                  )}
                                </span>
                              </div>

                              {employer.locations.map((location, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={classNames([
                                      styles.normalSubheaderMed,
                                      'padding-bottom-eight',
                                    ])}
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <i
                                      className="nc-icon-glyph pin col-1 pt-1"
                                      aria-label="Location"
                                      role="img"
                                    />
                                    &nbsp;&nbsp;
                                    <span>
                                      {buildLocationMapUrl(location)}
                                      {location.isPrimaryLocation && (
                                        <div>{'(Primary Location)'}</div>
                                      )}
                                    </span>
                                  </div>
                                );
                              })}
                              <div
                                className={classNames([
                                  styles.normalSubheaderMed,
                                  'padding-bottom-eight',
                                ])}
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <i
                                  className="nc-icon-glyph globe col-1 pt-1"
                                  aria-label="Website"
                                  role="img"
                                />
                                &nbsp;&nbsp;
                                <span>
                                  <a
                                    className="clickable active-subheader underlined"
                                    style={{
                                      textDecoration: 'none',
                                      fontSize: '14px',
                                    }}
                                    href={fixUrl(employer.url)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {shortUrl(employer.url, 30)}
                                  </a>
                                </span>
                              </div>
                            </div>
                            <br />
                            <br />
                            {this.props.jobsLoading && (
                              <span className={styles.emptyRoles}>
                                Loading Roles...
                              </span>
                            )}
                            {!this.props.jobsLoading && (
                              <Fragment>
                                <h2>
                                  <div className="normal-title bold">
                                    <Link
                                      className="buttonAsLink"
                                      to={`/all_roles/${employerId}`}
                                    >
                                      View Roles ({this.props.totalSortedJobs})
                                    </Link>
                                  </div>
                                </h2>

                                {renderRoleCards()}

                                <div>
                                  <Link
                                    className="buttonAsLink"
                                    to={`/all_roles/${employerId}`}
                                  >
                                    View All Roles
                                  </Link>
                                </div>
                                <br />
                              </Fragment>
                            )}

                            {resourceItems.length > 0 && (
                              <>
                                <h2>
                                  <div className="normal-title bold">
                                    Resources
                                  </div>
                                </h2>
                                <br />
                                {resourceItems}
                              </>
                            )}
                          </>
                        )}
                        <br />
                        <br />
                        {role === 'ASCEND_RECRUITER' && (
                          <>
                            <h2>
                              <div className="normal-title bold">Segment</div>
                            </h2>
                            <br />
                            <SideBarSegmentDisplay
                              parentObject={employer}
                              role={role}
                            />
                          </>
                        )}
                        <br />

                        {role !== 'COMPANY_RECRUITER' &&
                          role !== 'COMPANY_RECRUITER_ADMIN' &&
                          role !== 'JOB_SEEKER' && (
                            <>
                              <h2>
                                <div className="normal-title bold">
                                  Account Info
                                </div>
                              </h2>
                              <br />
                              <SideBarDisplayItem
                                role={role}
                                title={'Account Manager'}
                                displayText={this.findUserName(
                                  employer.accountManagerId
                                )}
                              />
                            </>
                          )}
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
              <br />
            </main>
          </div>
          <Layout.PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

EmployerProfilePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getEmployerToCache: PropTypes.func.isRequired,
  getSeeker: PropTypes.func.isRequired,
  getTeamsByEmployerAdvanced: PropTypes.func.isRequired,
  employerCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    employer: PropTypes.object,
  }),
  employerCacheLoading: PropTypes.bool.isRequired,
  jobsLoading: PropTypes.bool.isRequired,
  role: PropTypes.string,
  seeker: PropTypes.object,
  teams: PropTypes.array,
  seekerId: PropTypes.string,
  createEmployerWatch: PropTypes.func.isRequired,
  deleteEmployerWatch: PropTypes.func.isRequired,
  updateEmployer: PropTypes.func.isRequired,
  getTeamForEdit: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  getEmployerForEdit: PropTypes.func.isRequired,
  allowEmployerEdit: PropTypes.bool,
  canEditEmployer: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  getUsersByPermission: PropTypes.func.isRequired,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  jobs: PropTypes.array,
  totalSortedJobs: PropTypes.number,
  getEmployerJobsSorted: PropTypes.func.isRequired,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    employerCache: state.employers.employerCache,
    employerCacheLoading: state.employers.employerCacheLoading,
    jobsLoading: state.jobs.jobsLoading,
    teams: state.teams.teams,
    role: state.profile.role,
    seeker: state.seekers.seeker,
    seekerId: state.profile.seekerId,
    allowEmployerEdit: state.authorization.employer.canEdit,
    usersList: state.users.usersListByPermission,
    jobs: state.jobs.jobsByEmployerSorted,
    totalSortedJobs: state.jobs.totalJobsByEmployerSorted,
    jobCache: state.jobs.jobCache,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
  };
};

const mapDispatchToProps = {
  ...employersActions,
  ...seekersActions,
  ...teamsActions,
  ...AuthorizeActions,
  ...usersActions,
  ...jobsActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerProfilePage)
);
