import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import $ from 'jquery';
import arrowrightSvg from '../../assets/images/ArrowRight.svg';
import AddTagButton from 'src/components/tags/AddTagButton/AddTagButton';
import style from 'src/components/selectors/SelectAscend/SelectAscend.module.scss';
import {SearchSvg} from 'src/assets/svg/svgComponents';
import TagButton from 'src/components/tags/TagButton/TagButton';
import * as skillTagsActions from '../../actions/skillTags';
import {Tooltip as ReactTooltip} from 'react-tooltip';
class SkillsTreeWidget extends React.Component {
  constructor(props) {
    super(props);
    this.interval = null; // Define a property to hold the interval reference
  }

  state = {
    showCategories: true,
    tagUpdateText: '',
    showTip: false,
    isMobile: false,
    loaded: false,
  };

  componentDidMount() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 430px)').matches,
      loaded: true,
    });
  }

  handleSkillClick = (skill) => {
    let newSelectedSkills = [];
    const currentValue = this.props.input.value;
    if (
      currentValue.some(
        (dropDown) => skill.id === (dropDown.id || dropDown.value)
      )
    ) {
      newSelectedSkills = currentValue.filter(
        (dropDown) => skill.id !== (dropDown.id || dropDown.value)
      );
      this.setState({tagUpdateText: `${skill.name} removed`});
    } else {
      // Skill does not exist and needs to be added
      newSelectedSkills = [
        ...currentValue,
        {
          ...skill,
          label: skill.name,
          value: skill.id,
        },
      ];
      this.setState({tagUpdateText: `${skill.name} added`});
    }
    this.props.input.onChange(newSelectedSkills);
  };

  convertSkillsToOptions = (skillTags) => {
    const {skillIdsToExclude} = this.props;

    const sortedSkillTags = _.orderBy(
      skillTags.filter((skillTag) => {
        return (
          this.props.input.value.find(
            (selectedSkill) => selectedSkill.id === skillTag.id
          ) === undefined && !skillIdsToExclude.includes(skillTag.id)
        );
      }),
      'name',
      'asc'
    );
    const allSkills = sortedSkillTags.map((skill) => {
      return {
        value: skill.id,
        label: skill.name,
        definition: skill.definition,
      };
    });
    return allSkills;
  };

  sortSkillsForTree = (skillTags) => {
    const {skillIdsToExclude} = this.props;

    const sortedSkillTags = _.orderBy(
      skillTags.filter(function (item) {
        return !skillIdsToExclude.includes(item.id);
      }),
      'name',
      'asc'
    );

    const hasPrinciples = (s) => /^Principles/.test(s.name);

    const withPrinciples = _.filter(sortedSkillTags, hasPrinciples);
    const withOutPrinciples = _.reject(sortedSkillTags, hasPrinciples);
    return [...withPrinciples, ...withOutPrinciples];
  };

  render() {
    const {skillTags, skillTagsLoading, skillIdsToExclude} = this.props;

    const allSkills = this.convertSkillsToOptions(skillTags);
    const {touched, error, warning} = this.props.meta;
    const commonlyRequestedSkills = this.props.topSkills.filter(
      (commonlyRequestedSkill) =>
        this.props.input.value.find(
          (currentValue) =>
            commonlyRequestedSkill.id ===
            (currentValue.id || currentValue.value)
        ) === undefined
    );

    return (
      <div className="row">
        <div
          aria-live="assertive"
          aria-atomic="true"
          className="forScreenreader"
        >
          <h1>{this.state.tagUpdateText}</h1>
        </div>
        {!this.state.isMobile && (
          <ReactTooltip
            id="tooltip"
            className="solid-tooltip-dark"
            closeOnEsc={true}
            isOpen={this.state.showTip}
            anchorSelect={this.state.anchorSelect}
            content={this.state.content}
            closeOnScroll={true}
          />
        )}
        {!this.state.isMobile && (
          <ReactTooltip
            id="tooltip-label"
            className="solid-tooltip-dark"
            closeOnEsc={true}
            closeOnScroll={true}
            hidden={this.state.showTip}
          />
        )}

        <div className="col-12">
          <div className="row">
            <div className="col-12" style={{textAlign: 'left'}}>
              <Select
                isMulti
                joinValues
                selectorGlyph={
                  <SearchSvg className={style.settingsSvg_fill_orange} />
                }
                showGlyph={true}
                value={null}
                options={allSkills}
                onChange={(newSkills) => {
                  const tmpSkills = this.props.input.value;
                  for (const skill of newSkills) {
                    tmpSkills.push(skill);
                  }
                  return this.props.input.onChange(
                    tmpSkills.map((skillValue) => {
                      this.setState({
                        tagUpdateText: `${
                          skillValue.label || skillValue.name
                        } added`,
                      });
                      return {
                        ...skillValue,
                        id: skillValue.value || skillValue.id,
                        name: skillValue.label || skillValue.name,
                      };
                    })
                  );
                }}
                placeholder="Search for skills"
                isLoading={skillTagsLoading}
                isClearable={false}
                aria-labelledby="skillsLabel"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-wrap">
                {this.state.loaded &&
                  this.props.input.value.map((selectedSkill, index) => {
                    return (
                      <TagButton
                        key={index}
                        tag={{...selectedSkill, tagName: selectedSkill.name}}
                        removeAction={() =>
                          this.handleSkillClick(selectedSkill)
                        }
                        tagTip={
                          this.state.isMobile ? null : selectedSkill.definition
                        }
                        isMobile={this.state.isMobile}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">&nbsp;</div>
          </div>
          {touched &&
            ((error && <div className="form-input-error">{error}</div>) ||
              (warning && (
                <div className="form-input-error">{warning}</div>
              )) || <div className="form-input-error">&nbsp;</div>)}

          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="buttonAsLinkCandidateProfile"
                onClick={() => {
                  $(`#accordion`).toggle(150, 'linear');
                  this.setState({showCategories: !this.state.showCategories});
                }}
              >
                <img
                  className={
                    !this.state.showCategories
                      ? 'gly-rotate-180'
                      : 'gly-rotate-90'
                  }
                  alt=""
                  src={arrowrightSvg}
                />
                Browse and select from the skills library
              </button>
            </div>
          </div>
          <div id="accordion" style={{display: 'none'}}>
            {this.props.skillTree.map((category, index) => {
              return (
                <div
                  key={category.id}
                  className="card"
                  style={{textAlign: 'left'}}
                >
                  <div
                    className="card-header"
                    id={`heading${category.id}`}
                    style={{
                      padding: '.01rem',
                    }}
                  >
                    <h5 className="mb-0">
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="collapse"
                        onClick={() => {
                          $(`#${category.id}`).toggle(150, 'linear');
                          this.props.toggleCategory(category.id);
                        }}
                        data-target={`#${category.id}`}
                        aria-expanded="false"
                        aria-controls={category.id}
                        style={{
                          padding: '.01rem',
                          display: 'block',
                          width: ' 100%',
                        }}
                      >
                        <img
                          className={
                            category.expanded
                              ? 'gly-rotate-180'
                              : 'gly-rotate-90'
                          }
                          alt=""
                          src={arrowrightSvg}
                        />
                        {category.name}
                      </button>
                    </h5>
                  </div>

                  <div
                    id={category.id}
                    className="skill-tree"
                    aria-labelledby={`heading${category.id}`}
                    data-parent="#accordion"
                  >
                    {category.categories.map((subcategory, index) => {
                      return (
                        <Fragment key={subcategory.id}>
                          <div
                            className="card-body"
                            style={{
                              paddingBottom: '0.01rem',
                              paddingTop: '.01rem',
                            }}
                          >
                            <h5 className="mb-0">
                              <button
                                type="button"
                                className="btn btn-link"
                                data-target={`#${subcategory.id}`}
                                aria-expanded="false"
                                style={{
                                  padding: '.01rem',
                                  display: 'block',
                                  width: ' 100%',
                                }}
                                onClick={() => {
                                  $(`#${subcategory.id}`).toggle(
                                    150,
                                    'linear',
                                    () => {
                                      this.props.toggleSubcategory(
                                        subcategory.id
                                      );
                                    }
                                  );
                                }}
                              >
                                <img
                                  className={
                                    subcategory.expanded
                                      ? 'gly-rotate-180'
                                      : 'gly-rotate-90'
                                  }
                                  alt=""
                                  src={arrowrightSvg}
                                />
                                {subcategory.name}
                              </button>
                            </h5>
                          </div>
                          <div
                            id={subcategory.id}
                            className="card-body skill-tree"
                            style={{
                              paddingLeft: '65px',
                              paddingTop: '.01rem',
                            }}
                          >
                            {this.sortSkillsForTree(subcategory.skills).map(
                              (skill) => {
                                const labelRef = React.createRef();
                                return (
                                  <div
                                    key={skill.id}
                                    className="custom-control custom-checkbox form-check"
                                    style={{
                                      paddingTop: '0.2rem',
                                    }}
                                  >
                                    <input
                                      id={skill.id}
                                      name={skill.name}
                                      type="checkbox"
                                      className="custom-control-input form-check-input checkbox-formatting"
                                      checked={this.props.input.value.some(
                                        (currentInput) =>
                                          skill.id === currentInput.value
                                      )}
                                      onChange={() => {
                                        this.handleSkillClick(skill);
                                      }}
                                      onFocus={() => {
                                        this.setState({
                                          showTip: true,
                                          anchorSelect: `#label${skill.id}`,
                                          content: skill.definition,
                                          tipId: skill.id,
                                        });
                                      }}
                                      onBlur={() => {
                                        this.setState({showTip: false});
                                      }}
                                    />
                                    <label
                                      id={`label${skill.id}`}
                                      ref={labelRef}
                                      className="custom-control-label form-check-label"
                                      htmlFor={skill.id}
                                      data-tooltip-content={skill.definition}
                                      data-tooltip-id="tooltip-label"
                                      data-tooltip-place="right"
                                      onMouseOver={() => {
                                        this.setState({
                                          showTip: true,
                                          anchorSelect: `#label${skill.id}`,
                                          content: skill.definition,
                                          tipId: skill.id,
                                        });
                                      }}
                                    >
                                      {skill.name}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <p></p>
          {commonlyRequestedSkills.length > 0 && (
            <p className="onboarding-form-label" style={{marginBottom: 0}}>
              {this.props.commonSkillsLabel}
            </p>
          )}
          <div className="d-flex flex-wrap">
            {!this.props.topSkillTagsLoading &&
              commonlyRequestedSkills
                .filter(function (item) {
                  return !skillIdsToExclude.includes(item.id);
                })
                .map((skill) => {
                  return (
                    <div key={skill.id}>
                      <AddTagButton
                        key={skill.name}
                        tag={{...skill, tagName: skill.name}}
                        addAction={(skill) => this.handleSkillClick(skill)}
                        tagTip={skill.definition}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}

SkillsTreeWidget.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  skillTags: PropTypes.array.isRequired,
  skillTree: PropTypes.array,
  topSkills: PropTypes.array.isRequired,
  topSkillTagsLoading: PropTypes.bool.isRequired,
  skillTagsLoading: PropTypes.bool.isRequired,
  toggleCategory: PropTypes.func.isRequired,
  toggleSubcategory: PropTypes.func.isRequired,
  commonSkillsLabel: PropTypes.string,
  skillIdsToExclude: PropTypes.array,
};

SkillsTreeWidget.defaultProps = {
  commonSkillsLabel: 'Do you have any of these commonly requested skills?',
  skillIdsToExclude: [],
};

function mapStateToProps(state, ownProps) {
  return {
    skillTags: state.skillTags.tags,
    skillTree: state.skillTags.skillTree,
    topSkills: state.skillTags.topTags,
    skillTagsLoading: state.skillTags.skillTagsLoading,
    topSkillTagsLoading: state.skillTags.topSkillTagsLoading,
  };
}

const mapDispatchToProps = {
  ...skillTagsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsTreeWidget);
