import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody} from 'reactstrap';

import DropDownMenu from '../components/DropDownMenu';

class AscendModalHeader extends React.Component {
  handleCloseClick = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <>
        <ModalBody key="header" className={this.props.headerClass}>
          {this.props.hasClose && (
            <button
              type="button"
              className="float-right clickable buttonAsLinkGrayBackground"
              onClick={this.handleCloseClick}
              style={{marginTop: '-30px', color: '#fff'}}
              aria-label="Close Modal"
            >
              <br />
              <i className="nc-icon-glyph-med remove" />
            </button>
          )}
          {this.props.hasMenu && (
            <div
              className="float-right"
              style={{
                marginTop: '-30px',
                paddingRight: '10px',
                color: 'var(--neutral-white)',
              }}
            >
              <DropDownMenu menuItems={this.props.menuList} />
            </div>
          )}

          {this.props.children}
        </ModalBody>
      </>
    );
  }
}

AscendModalHeader.propTypes = {
  children: PropTypes.node,
  headerClass: PropTypes.string,
  hasClose: PropTypes.bool,
  onClose: PropTypes.func,
  hasMenu: PropTypes.bool,
  onMenuClick: PropTypes.func,
  menuList: PropTypes.any,
};

AscendModalHeader.defaultProps = {
  headerClass: 'bd-graybackground',
  hasClose: false,
  hasMenu: false,
};

class AscendModalBody extends React.Component {
  handleCloseClick = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <>
        <ModalBody key="body" className={this.props.bodyClass}>
          {this.props.hasClose && (
            <>
              {this.props.padClose && (
                <div className="row">
                  <div className="col-12">&nbsp;</div>
                </div>
              )}
              <div className="bd-modal normal-subheader row">
                <div className="col-11"></div>
                <button
                  type="button"
                  className="col-1 buttonAsLinkGrayBackground"
                  onClick={this.handleCloseClick}
                  title="Close Modal"
                >
                  <i className="nc-icon-glyph-med remove" />
                </button>
              </div>
            </>
          )}
          {this.props.children}
        </ModalBody>
      </>
    );
  }
}

AscendModalBody.propTypes = {
  children: PropTypes.node,
  bodyClass: PropTypes.string,
  hasClose: PropTypes.bool,
  onClose: PropTypes.func,
  padClose: PropTypes.bool,
};

AscendModalBody.defaultProps = {
  bodyClass: 'bd-modalbody',
  hasClose: false,
  isOpen: false,
  padClose: true,
};

class AscendModalFooter extends React.Component {
  render() {
    return (
      <ModalBody
        key="footer"
        className={this.props.footerClass}
        style={this.props.style}
      >
        {this.props.children}
      </ModalBody>
    );
  }
}

AscendModalFooter.propTypes = {
  children: PropTypes.node,
  footerClass: PropTypes.string,
  style: PropTypes.object,
};

AscendModalFooter.defaultProps = {
  footerClass: 'bd-modalfooter',
};

class AscendModal extends React.Component {
  toggle = () => {
    if (this.props.disableClose) return;
    if (this.props.isOpen && this.props.onClose) {
      this.props.onClose();
    }
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const renderChildren = (props) => {
      return React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
          onClose: this.handleClose,
        });
      });
    };

    let style = {...this.props.styles};
    if (this.props.centerVertically) {
      style = {
        ...style,
        top: '25%',
        transform: 'translate(0, -50%) !important',
      };
    }
    return (
      <Modal
        keyboard={false}
        size={this.props.size}
        isOpen={this.props.isOpen}
        toggle={this.toggle}
        style={style}
        backdrop={this.props.backdrop}
        className={this.props.dialogClassName}
      >
        {renderChildren(this.props)}
      </Modal>
    );
  }
}

AscendModal.propTypes = {
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  centerVertically: PropTypes.bool,
  disableClose: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  direction: PropTypes.string,
  dialogClassName: PropTypes.string,
  styles: PropTypes.object,
};

AscendModal.defaultProps = {
  isOpen: false,
  size: 'lg',
  centerVertically: false,
  disableClose: false,
  backdrop: true,
  dialogClassName: '',
  styles: {},
};

export {AscendModal, AscendModalHeader, AscendModalBody, AscendModalFooter};
