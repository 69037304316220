import React from 'react';
import { useState } from 'react';
import {NavLink} from 'react-router-dom';
import {HomeSvg, ProfileSvg, JobsSvg, TipSvg} from 'src/assets/svg/svgComponents';
import MobileNavMenu from './MobileNavMenu';
import styles from './MobileNav.module.scss';
import {useSelector} from 'react-redux';

export default function MobileNavBar() {
    const pathname = window.location.pathname;

    const defaultNavClass = styles.mobileNavDefault;
    const activeNavClass = styles.mobileNavActive;

    const defaultNavIconFillClass = styles.mobileNavIconFillDefault;
    const activeNavIconFillClass = styles.mobileNavIconFillActive;
    
    const defaultNavIconStrokeClass = styles.mobileNavIconStrokeDefault;
    const activeNavIconStrokeClass = styles.mobileNavIconStrokeActive;

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const isApprenticeProgram = useSelector((state) => state.profile.isApprenticeProgram);

    const isActiveMenu = (path) => {
        return pathname === path && !menuIsOpen;
    };
    
    return (
        <div className={`fixed-bottom mobile-only row bgcolorw ${styles.mobileNavContainer}`}>
            <div className={(isActiveMenu('/candidatehome') ? activeNavClass : defaultNavClass) + ` col ${styles.mobileNavOption} ps-4`}>
                <NavLink to="/candidatehome">
                    <HomeSvg className={(isActiveMenu('/candidatehome') ? activeNavIconFillClass : defaultNavIconFillClass) + ` ${styles.mobileNavIcon}`} />
                    <div className={`nowrap ${styles.mobileNavText}`}>
                        Home
                    </div>
                </NavLink>
            </div>
            
            <div className={(isActiveMenu('/find_roles') ? activeNavClass : defaultNavClass) + ` col ${styles.mobileNavOption}`}>
                <NavLink to="/find_roles">
                    <JobsSvg className={(isActiveMenu('/find_roles') ? activeNavIconFillClass : defaultNavIconFillClass) + ` ${styles.mobileNavIcon}`} />
                    <div className={`nowrap ${styles.mobileNavText}`}>
                        Roles
                    </div>
                </NavLink>
            </div>

            <div className={(isActiveMenu('/profile') ? activeNavClass : defaultNavClass) + ` col ${styles.mobileNavOption}`}>
                <NavLink to="/profile">
                    <ProfileSvg className={(isActiveMenu('/profile') ? activeNavIconStrokeClass : defaultNavIconStrokeClass) + ` ${styles.mobileNavIcon}`} />
                    <div className={`nowrap ${styles.mobileNavText}`}>
                        Profile
                    </div>
                </NavLink>
            </div>

            {!isApprenticeProgram && (
                <div className={(isActiveMenu('/job_seeker_resources') ? activeNavClass : defaultNavClass) + ` col ${styles.mobileNavOption}`}>
                    <NavLink to="/job_seeker_resources">
                        <TipSvg className={(isActiveMenu('/job_seeker_resources') ? activeNavIconFillClass : defaultNavIconFillClass) + ` ${styles.mobileNavIcon}`} />
                        <div className={`nowrap ${styles.mobileNavText}`}>
                            Resources
                        </div>
                    </NavLink>
                </div>                
            )}

            <div className={(menuIsOpen ? activeNavClass : defaultNavClass) + ` col pe-4 ${styles.mobileNavOption}`}>
                <MobileNavMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
            </div>
        </div>
    );
}