import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as flashActions from '../../../actions/flash';
import {DuplicateSvg} from '../../../assets/svg/svgComponents';

const BuildSignUpLink = ({invitationCode}) => {
  const dispatch = useDispatch();
  let baseUrl = `${window.location.protocol}//${window.location.host}`;
  let url = `${baseUrl}/signup`;

  const segmentList = useSelector((state) => state.segments.segmentList);

  const segment_id = invitationCode?.segmentId;

  const segment = segmentList.find((seg) => seg.id === segment_id);

  if (segment?.segmentUrl) {
    url = `${baseUrl}/${segment.segmentUrl}/signup`;
  }

  if (invitationCode.showCodeQueryStringToAdmin) {
    url = `${url}?code=${invitationCode.code}`;
  }

  const handleCopy = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    navigator.clipboard.writeText(url).then(() => {
      dispatch(
        flashActions.addFlashMessage('Sign up Link Copied!', 'notification')
      );
    });
  };

  return (
    <div>
      {url}&nbsp;&nbsp;
      <button
        onClick={handleCopy}
        className="buttonAsLink"
        data-tooltip-id="tooltip"
        data-tooltip-html={'Copy Link'}
      >
        <DuplicateSvg className="duplicate_icon_gray" />
      </button>
    </div>
  );
};

BuildSignUpLink.propTypes = {
  invitationCode: PropTypes.any.isRequired,
};

export default BuildSignUpLink;
