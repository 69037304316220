import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useReward} from 'react-rewards';
import {useDispatch} from 'react-redux';

import aboutUs from 'src/assets/images/AboutUs-Icons-3-02.svg';
import {updateSeekerAction} from '../../../actions/SeekerActions';

export default function CreateConnectionConfirmation(props) {
  const {roleAttributed, onClose, connectionSaveFailed, seeker} = props;

  const {reward} = useReward(`connectionReward`, 'confetti', {
    startVelocity: 10,
    spread: 200,
    lifetime: 400,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (roleAttributed.isAttributed && !connectionSaveFailed) {
      reward();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleAttributed, connectionSaveFailed]);

  const moreConfetti = () => {
    reward();
  };

  const onUnpublish = () => {
    onClose();

    dispatch(
      updateSeekerAction({
        id: seeker.id,
        userId: seeker.userId,
        published: false,
      })
    );
  };

  return (
    <>
      <span
        style={{
          position: 'relative',
          top: '20px',
          left: '28%',
          background: 'red',
        }}
        id={`connectionReward`}
      />

      <div className="row">
        <div
          className="col text-align-center align-self-center"
          aria-label="An error occurred"
        >
          {connectionSaveFailed && (
            <div className="secondary-body padding-bottom-twentyfour">
              An error occurred while saving the connection.
            </div>
          )}

          {roleAttributed.isAttributed && !connectionSaveFailed && (
            <>
              <div
                className="normal-title padding-bottom-eight bold"
                onClick={moreConfetti}
                aria-label="Great work"
              >
                Great work!
              </div>

              <div className="secondary-body padding-bottom-twentyfour">
                Congrats on helping another job seeker find their next
                opportunity.
              </div>
            </>
          )}

          {!roleAttributed.isAttributed && !connectionSaveFailed && (
            <>
              <div
                className="normal-title padding-bottom-eight bold"
                aria-label="Thanks for letting us know"
              >
                Thanks for letting us know!
              </div>

              <div className="secondary-body padding-bottom-twentyfour">
                The connection will not be tracked because the job seeker did
                not attribute securing their job/internship to Ascend.
              </div>
            </>
          )}

          <div className="ps-1 secondary-body padding-bottom-twentyfour">
            Would you like to unpublish {seeker.firstName} {seeker.lastName}'s
            account?
          </div>

          <div className="row justify-content-center ">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={onClose}
              >
                Close without
                <br />
                Unpublishing
              </button>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary mb-3" onClick={onUnpublish}>
                Unpublish and
                <br />
                Close
              </button>
            </div>
          </div>
        </div>

        {roleAttributed.isAttributed && !connectionSaveFailed && (
          <div className="col align-items-center text-align-center">
            <img src={aboutUs} alt="" />
          </div>
        )}
      </div>
    </>
  );
}

CreateConnectionConfirmation.propTypes = {
  roleAttributed: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  connectionSaveFailed: PropTypes.bool.isRequired,
  seeker: PropTypes.object.isRequired,
};
