import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {track} from 'src/utils/analytics';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';

import * as AuthorizeActions from '../../actions/AuthorizationActions';
import * as seekersActions from 'src/actions/SeekerActions';
import * as seekers from 'src/actions/seekers';
import * as jobsActions from 'src/actions/jobs';
import * as teamsActions from 'src/actions/teams';
import * as institutionsActions from 'src/actions/institutions';
import * as flashActions from 'src/actions/flash';
import * as ascendRecruitersActions from 'src/actions/ascendRecruiters';

import * as Layout from 'src/layout';
import TagsWidget from 'src/components/TagsWidget';
import ResumeModal from 'src/components/ResumeModal';
import SimpleJobCard from 'src/components/SimpleJobCard';
import InviteToApplyModal from 'src/components/InviteToApplyModal';
import InviteToApplyModalForAscend from 'src/components/InviteToApplyModalForAscend';
import DownloadResumeLink from 'src/components/DownloadResumeLink';
import ViewResumeLink from 'src/components/ViewResumeLink';
import PublishToggle from 'src/components/PublishToggle';
import {CandidateInfoWithEdit} from 'src/components/CandidateInfo';
import JobProfile from 'src/components/JobProfile';
import SkillsModal from 'src/components/SkillsModal';
import {EditEmploymentTypesModal} from 'src/components/EmploymentTypesModal';
import {EditRoleTypesModal} from 'src/components/RoleTypesModal';
import {EditIndustriesModal} from 'src/components/IndustriesModal';
import {EditRegionsModal} from 'src/components/RegionsModal';
import {EditStartDateModal} from 'src/components/StartDateModal';
import {EditKeyFactorsModal} from 'src/components/KeyFactorsModal';
import {EditExperienceModal} from 'src/components/ExperienceModal';
import {EditSummaryModal} from 'src/components/SummaryModal';
import EducationModal from 'src/components/EducationModal';
import {EditResumeUploadModal} from 'src/components/ResumeUploadModal';
import {EditDetailsModal} from 'src/components/DetailsModal';
import {InfoCard, InfoCardRow} from 'src/components/InfoCard';
import NotesModal from 'src/components/NotesModal';

import {findMatchData} from 'src/utils/matchHelper';
import {jobIsApplied, jobIsWatched} from 'src/utils/jobHelper';
import {mergeMatches} from 'src/utils/seekerHelper';
import {formatDate, shortUrl} from 'src/utils/miscHelper';
import {fixUrl} from 'src/utils/fieldHelper';
import ExperienceWidget from 'src/pages/candidateProfile/ExperienceWidget';
import TeamBenchModal from 'src/pages/candidateProfile/TeamBenchModal';
import WorkStyle from 'src/pages/candidateProfile/WorkStyle';
import {seekerCanPublish} from 'src/utils/seekerHelper.js';
import SideBarSegmentDisplay from '../../components/SideBarSegmentDisplay';
import {EditWagePreferenceModal} from '../../components/WagePreferenceModal';
import {has_permission, PERMISSIONS} from '../../services/authorizationApi';
import MaProfileQnAModal from '../../components/modernApprenticeship/candidateProfile/MaProfileQnAModal';
import {
  FlagSolidSvg,
  FlagSvg,
  HeartFilledWithoutBorderSvg,
} from 'src/assets/svg/svgComponents';
import CandidateSubMenu from 'src/components/Candidate/CandidateSubMenu';
import {getSeekerCache} from 'src/services/cacheApi';
import {getExperienceLevels} from 'src/actions/experienceLevels';
import {ExperienceLevelModal} from '../../components/ExperienceLevelModal';
import {RecruiterSaysModal} from '../../components/RecruiterSaysModal';
import {getAllApprenticePathways} from 'src/actions/apprenticePathwaysActions';
import {onboardingComplete} from 'src/actions/profile';
import styles from 'src/pages/candidateProfile/CandidateProfilePage.module.scss';
import PreviousButton from 'src/components/PreviousButton';
import PushButton from 'src/components/controls/buttons/PushButton';
import CandidateNotifyMissingRole from '../../components/Candidate/CandidateNotifyMissingRole';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {MA_INDY_SEGMENT_ID} from 'src/containers/AuthorizedComponent';
import AreYouSureModal from 'src/components/AreYouSureModal';
import AssignForm from '../admin/candidates/AssignForm';
import ApplyForm from '../admin/candidates/ApplyForm';
import CreateConnectionModal from '../admin/connections/CreateConnectionModal';
import AuthorizationModal from 'src/containers/AuthorizationModal';
import ChangeEmailModal from '../../components/profile/ChangeEmailModal';
import {track as analyticsTrack} from 'src/utils/analytics';
import AdminSubMenu from 'src/components/AdminSubMenu';
import {getFeatureFlag} from 'src/services/featureFlagApi';

class SummaryEditLink extends React.Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div
        className="clickable candidate-profile-edit-link bold orangeHover"
        style={{textDecoration: 'underline'}}
        onClick={this.handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick(e);
          }
          return;
        }}
        tabIndex={0}
        role="button"
        aria-label="Edit Personal Summary"
      >
        Edit
      </div>
    );
  }
}

SummaryEditLink.propTypes = {
  onClick: PropTypes.func,
};

class DetailsEditLink extends React.Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div
        className="clickable candidate-profile-edit-link bold orangeHover"
        style={{textDecoration: 'underline'}}
        onClick={this.handleClick}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick(e);
          }
          return;
        }}
        aria-label="Edit User Details"
        role="button"
      >
        Edit
      </div>
    );
  }
}
class SeekerQuestionsEditLink extends React.Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div
        className="clickable candidate-profile-edit-link bold orangeHover"
        style={{textDecoration: 'underline'}}
        onClick={this.handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick(e);
          }
          return;
        }}
        role="button"
        aria-label="Edit Questions"
        tabIndex={0}
      >
        Edit
      </div>
    );
  }
}
DetailsEditLink.propTypes = {
  onClick: PropTypes.func,
};

SeekerQuestionsEditLink.propTypes = {
  onClick: PropTypes.func,
};

class CandidateProfilePage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      jobId: null,
      isMaSegment: false,
      loading: true,
      canViewPII: false,
      notesModalIsOpen: false,
      recruiterSaysModalIsOpen: false,
      seekerCache: null,
      QnA: null,
      inviteToApplyModalIsOpen: false,
      inviteToApplySeeker: null,
      maProfileQnAModalIsOpen: false,
      changeEmailModalIsOpen: false,
      canChangeOtherEmail: false,
    };
    has_permission(PERMISSIONS.ASCEND_RECRUITER).then((resp) => {
      this.showAdminLinks = resp.data.hasPermission;
    });
  }

  async componentDidMount() {
    this._isMounted = true;
    const seekerId = this.getSeekerId();
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) return;
      this.setState({
        canViewPII: resp.data.hasPermission,
      });
    });
    has_permission(PERMISSIONS.READ_ASCEND_RECRUITERS).then((resp) => {
      if (!this._isMounted) return;

      if (resp.data.hasPermission) {
        this.props.getAscendRecruiters();
      }
    });

    has_permission(PERMISSIONS.ALLOWED_TO_CHANGE_OTHER_EMAIL).then((resp) => {
      if (!this._isMounted) return;
      this.setState({canChangeOtherEmail: resp.data.hasPermission});
    });

    if (this.props.profile.securityRoleNames.includes('ADMIN')) {
      this.showAdminSubMenu = true;
      this.adminSubMenuEnabled = true;
    } else {
      has_permission(PERMISSIONS.VIEW_CANDIDATE_INSIGHTS).then((response) => {
        this.showAdminSubMenu = response.data.hasPermission;

        if (response.data.hasPermission) {
          getFeatureFlag('candidate_insights_view').then((response) => {
            this.adminSubMenuEnabled = response.data.enabled;
          });
        }
      });
    }

    if (this.props.seekerLoading) return;

    getSeekerCache(seekerId).then((resp) => {
      if (!this._isMounted) return;
      this.setState({seekerCache: resp.data});
      track({eventName: 'CandidateProfilePage', ...resp.data});
    });

    this.props.getSeekerAction(seekerId);
    await this.props.getSeekerQuestionsAndAnswersAction(seekerId);
    await this.props.getSeekerQuestionsAction();

    this.props.canEditSeeker(seekerId);
    this.props.getEducationData();
    if (this.props.experienceLevels.length === 0) {
      this.props.getExperienceLevels();
    }
    if (
      this.props.role === 'COMPANY_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN'
    ) {
      this.props.getTeamsByRecruiter(this.props.recruiterId);
    }
    this.props.getAllApprenticePathways();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.seeker !== this.props.seeker && !this.props.seekerLoading) {
      const isMaSegment = this.props.seeker.segments.some(
        (x) => x.isApprenticeProgram
      );

      this.setState({
        loading: false,
        isMaSegment: isMaSegment,
      });

      if (
        this.props.profile.seekerId !== undefined &&
        this.props.profile.seekerId !== null &&
        isMaSegment &&
        !(this.props.profile.onboarded || this.props.profile.reonboarded)
      ) {
        this.setState({maProfileQnAModalIsOpen: true});
      }

      if (this.state.QnA === null && isMaSegment) {
        this.readQnAValues();
      }
    }
  }

  getSeekerId = () => {
    let seekerId = null;
    if (this.props.loggedInSeekerId) {
      seekerId = this.props.loggedInSeekerId;
    } else if (this.props.match.params.id) {
      seekerId = this.props.match.params.id;
    }
    return seekerId;
  };

  handleResumeClick = () => {
    track({eventName: 'ResumeViewed', ...this.state.seekerCache});
    this.setState({
      resumeModalIsOpen: true,
    });
  };

  handleResumeDownloadClick = () => {
    track({eventName: 'ResumeDownloaded', ...this.state.seekerCache});
  };

  handleJobClicked = (jobId) => {
    this.props.getJobToCache(jobId);
    this.setState({jobProfileIsOpen: true, jobId: jobId});
  };

  handleWatchChanged = (job_view, watched) => {
    if (watched) {
      this.props.createJobWatch(job_view);
    } else {
      this.props.deleteJobWatch(job_view.id || job_view.jobId);
    }
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  handleCandidateWatchClicked = () => {
    this.setState({teamBenchModalIsOpen: true});
  };

  handleTeamChecked = (teamId, checked) => {
    const seekerId = this.props.seeker.id;
    const team = this.props.teams.find((t) => t.id === teamId);
    const successMessage = `Candidate ${
      checked ? 'saved to' : 'removed from'
    } ${team.name} team`;

    if (checked) {
      this.props.createSeekerWatch(teamId, seekerId, successMessage);
    } else {
      this.props.deleteSeekerWatch(teamId, seekerId, successMessage);
    }
  };

  handleInviteClicked = () => {
    // the inviteToApplySeeker technically is the same as
    // props.seeker, however if the user closes and re-opens
    // the modal, the modal's data will not be refreshed.
    // use a state variable to force the modal's
    // data to be refreshed.
    this.props.getSeekerInviteResponse(this.props.seeker.id);
    this.setState({
      inviteToApplyModalIsOpen: true,
      inviteToApplySeeker: this.props.seeker,
    });
  };

  handleNotesClicked = () => {
    this.setState({notesModalIsOpen: true});
    document.body.classList.add('modal-open');
  };

  handleFlagClicked = () => {
    this.props.toggleSeekerSupportFlag(this.props.seeker.id);
  };

  handleInviteToApplySendClicked = () => {
    this.setState({inviteToApplyModalIsOpen: false, inviteToApplySeeker: null});
  };

  handlePublishChanged = async () => {
    this.setState({loading: true});
    localStorage.removeItem('self_republish');
    const {seeker, updateSeekerAction} = this.props;
    // Check if candidate meets the criteria for publishing.
    if (!seeker.published) {
      if (!seekerCanPublish(seeker, this.props.addFlashMessage)) {
        return;
      }
    }
    const messages = {
      success: seeker.published ? 'Profile Unpublished' : 'Profile Published',
      failure: 'Error changing publish status',
    };
    await updateSeekerAction(
      {id: seeker.id, published: !seeker.published},
      true,
      messages
    );
    this.setState({loading: false});
  };

  handleEditEducation = () => {
    this.setState({educationModalIsOpen: true});
  };

  handleSkillsChanged = (selectedSkills) => {
    this.props.interviewSkillsChanged(selectedSkills);
  };

  handleEducationSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    // clean up any nulls in majors/minors
    const majorIds = _.filter(values.majorIds);
    const minorIds = _.filter(values.minorIds);

    const updatedSeeker = {
      ...seeker,
      ...values,
      majorIds,
      minorIds,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({educationModalIsOpen: false});
  };

  handleSummarySubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({summaryModalIsOpen: false});
  };

  handleRecruiterSaysSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({recruiterSaysModalIsOpen: false});
  };

  handleExperienceLevelSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    if (!seeker.id) {
      return;
    }

    const seekerValues = {
      experienceLevelIds: values.experienceLevels.map(
        (level) => level.buttonValue
      ),
      id: seeker.id,
    };
    const selectedExperienceLevelList = values.experienceLevels
      .map((level) => this.props.experienceLevels[level.buttonValue - 1]?.name)
      .join(', ');

    const existingExperienceLevelList = seeker.experienceLevels
      .map((level) => level.name)
      .join(', ');

    if (selectedExperienceLevelList !== existingExperienceLevelList) {
      await analyticsTrack({
        eventName: 'experienceLevelsChanged',
        displayName: 'Experience Levels Changed',
        seekerId: seeker.id,
        userType: this.props.role,
        segmentId: seeker.segments[0].name,
        experienceLevels: selectedExperienceLevelList,
        numberOfMatches: seeker.matches.length,
      });
    }
    await updateSeekerAction(seekerValues);
    this.setState({experienceLevelModalIsOpen: false});
  };

  handleDetailsSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({detailsModalIsOpen: false});
  };

  handleExperienceSubmit = async (values) => {
    const experience = values.experience.filter((x) => {
      return x != null;
    });

    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
      experience,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({experienceModalIsOpen: false});
  };

  handleSkillsSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    const selectedValues = values.skillTags.map((x) => x.name).join(', ');
    const existingSkillTags = seeker.skillTags
      .map((skill) => skill.name)
      .join(', ');

    if (selectedValues !== existingSkillTags) {
      analyticsTrack({
        eventName: 'SkillsChanged',
        displayName: 'Skills Changed',
        seekerId: seeker.id,
        userType: this.props.role,
        segmentId: seeker.segments[0].name,
        seekerSkillList: selectedValues,
        numberOfMatches: seeker.matches.length,
      });
    }
    await updateSeekerAction(updatedSeeker);
    this.setState({skillsModalIsOpen: false});
  };

  handleResumeSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({resumeUploadModalIsOpen: false});
  };
  handleTraitsSubmit = async (values) => {
    const {seeker, updateSeekerAction} = this.props;

    let seekerAnswers = values.traitQuestions.map((x) => {
      return {
        seekerQuestionId: x.id,
        answerText: x.level,
      };
    });

    const data = {
      id: seeker.id,
      seekerAnswers,
    };
    await updateSeekerAction(data, true);
    this.setState({skillsModalIsOpen: false});
  };

  handleQnASubmit = async (values) => {
    const {seeker, updateSeekerAction, profile, onboardingComplete} =
      this.props;

    let data = {
      id: seeker.id,
      seekerAnswers: this.state.QnA,
    };

    const isMaOnboard =
      seeker.segments.some((x) => x.isApprenticeProgram) &&
      !profile.onboardedOn &&
      !profile.reonboardedOn;

    let messages = {};

    if (isMaOnboard) {
      const now = new Date();
      data = {
        ...data,
        onboardedOn: now,
        reonboardedOn: now,
      };

      messages.success = 'Profile Complete';
    }

    await updateSeekerAction(data, true, true, messages);

    if (isMaOnboard) {
      onboardingComplete();
    }

    this.props.getSeekerQuestionsAndAnswersAction(seeker.id);
    this.setState({skillsModalIsOpen: false, maProfileQnAModalIsOpen: false});
  };

  async readQnAValues(values) {
    return new Promise((resolve) => {
      const formValuesQnA = values || {};
      const {seekerQuestions, seekerQuestionList} = this.props;
      let QnAList = {};
      const questions = _.isEmpty(seekerQuestions)
        ? seekerQuestionList
        : seekerQuestions;

      QnAList =
        questions &&
        questions
          .filter((y) => y.isRecommendationTrait === false)
          .map((q) => {
            const answerText = _.isEmpty(formValuesQnA)
              ? q.answer
              : formValuesQnA[`QnA${q.id}`];
            return {
              seekerQuestionId: q.id,
              answerText: answerText,
            };
          });

      this.setState({QnA: QnAList});
      resolve();
    });
  }

  handleSubmit = async (values) => {
    await this.props.updateSeekerAction({...this.props.seeker, ...values});
  };

  candidateIsWatched = () => {
    const {seeker} = this.props;
    return seeker.teamWatchers && seeker.teamWatchers.length > 0;
  };
  renderQuestion = (q) => {
    const {seekerQuestions, seekerQuestionList} = this.props;

    const questions = _.isEmpty(seekerQuestions)
      ? seekerQuestionList
      : seekerQuestions;

    if (q.answerText) {
      return (
        <Fragment key={`QnA${q.seekerQuestionId}`}>
          <div className="row padding-bottom-sixteen">
            <div className="offset-md-1 col-md-10">
              <div className="normal-caption bold ">
                {questions[q.seekerQuestionId - 1].question}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-1 col-md-10">
              <div className="white-box disabled-body padding-bottom-thirtytwo">
                {q.answerText}
              </div>
            </div>
          </div>
          <br />
          <br />
        </Fragment>
      );
    }
    return null;
  };

  handleChangeEmailClick = (seekerId) => {
    this.props.updateChangeEmailUser(this.props.seeker);

    this.setState({changeEmailModalIsOpen: true});
  };

  render() {
    const {
      seeker,
      role,
      jobCache,
      allowSeekerEdit,
      seekerQuestions,
      seekerQuestionList,
    } = this.props;
    const {isMaSegment} = this.state;

    const skillTags = seeker.skillTags ? seeker.skillTags : [];
    const experience = seeker.experience ? seeker.experience : [];

    let job = {};
    if (this.state.jobId in jobCache) {
      job = jobCache[this.state.jobId];
    }

    const recruiterActionIcons = () => {
      return (
        <div className={styles.buttonRow}>
          <PushButton
            buttonText={
              this.candidateIsWatched() ? 'Candidate Saved' : 'Save Candidate'
            }
            isIconLeftAligned={true}
            buttonSvg={
              this.candidateIsWatched() ? (
                <HeartFilledWithoutBorderSvg
                  width="24"
                  height="24"
                  className="svg_color_fill_pink_heart"
                  style={{marginRight: '8px'}}
                />
              ) : (
                <HeartFilledWithoutBorderSvg
                  width="22"
                  height="22"
                  strokeWidth="0.5"
                  className="svg_color_fill_white svg_color_stroke_blue_heart"
                  style={{marginRight: '8px'}}
                />
              )
            }
            action={this.handleCandidateWatchClicked}
            isToggled={this.candidateIsWatched()}
            className={`btn-secondary ${styles.toggleButton}`}
            buttonToggledCssClass={styles.heartToggleButtonToggled}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              track({
                eventName: 'InvitedToApply_CompanyRecruiter',
                seekerId: this.props.seeker.id,
              });
              this.handleInviteClicked();
            }}
            style={{margin: '4px'}}
          >
            Invite to Apply
          </button>
        </div>
      );
    };

    const menuActions = [
      {
        displayName: 'Edit Profile',
        onClick: this.handleEditEducation,
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'Change Email',
        onClick: this.handleChangeEmailClick,
        showMenu: (seeker) =>
          seeker.offboardedOn === null && this.state.canChangeOtherEmail,
      },
      {
        displayName: 'View Applications',
        onClick: (seekerId) => {
          this.props.history.push('/applications/' + seekerId);
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'Track an Application',
        onClick: (seekerId) => {
          this.setState({
            applyFormOpen: true,
          });
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },

      {
        displayName: 'Report a Connection',
        onClick: (seekerId) => {
          this.setState({
            reportConnectionFormOpen: true,
          });
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },

      {
        displayName: 'Assign Manager',
        onClick: (seekerId) => {
          this.setState({
            assignFormOpen: true,
            ascendRecruiterId: this.props.seeker.ascendRecruiter
              ? this.props.seeker.ascendRecruiter.id
              : 0,
          });
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'View Matches',
        onClick: (seekerId) => {
          this.props.history.push('/find_roles/' + seekerId);
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'View Favorites',
        onClick: (seekerId) => {
          this.props.history.push('/favorites/' + seekerId);
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'View Home Page',
        onClick: (seekerId) => {
          this.props.history.push('/candidatehome/' + seekerId);
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'Change Work Authorization Status',
        onClick: (seekerId) => {
          this.setState({
            authorizationModalIsOpen: true,
          });
        },
        showMenu: (seeker) =>
          seeker.offboardedOn === null &&
          seeker.segments.some((s) => s.id === MA_INDY_SEGMENT_ID),
      },
      {
        displayName: 'Deactivate Profile',
        onClick: (seekerId) => {
          let seeker = {
            id: seekerId,
            offboardedOn: new Date(),
            published: false,
          };

          this.props.updateSeekerAction(seeker);
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'Delete',
        onClick: (seekerId) => {
          this.setState(
            {
              removeSeekerId: seekerId,
            },
            () => this.setState({areYouSureModalIsOpen: true})
          );
        },
        showMenu: (seeker) => seeker.offboardedOn === null,
      },
      {
        displayName: 'Onboard',
        onClick: async (seekerId) => {
          const seeker = {...this.props.seeker, offboardedOn: null};
          this.props.updateSeekerAction(seeker);
        },
        showMenu: (seeker) => seeker.offboardedOn !== null,
      },
    ];

    const handleAssignSubmit = (ascendRecruiterId) => {
      if (ascendRecruiterId === '0') {
        this.props.updateSeekerAction({
          id: this.props.seeker.id,
          ascendRecruiterId: null,
        });
      } else {
        this.props.updateSeekerAction({
          id: this.props.seeker.id,
          ascendRecruiterId: ascendRecruiterId,
        });
      }
      this.setState({assignFormOpen: false});
    };

    const handleApplySubmit = (roleId) => {
      this.props.createJobAppliedBySeeker(roleId, this.props.seeker.id);
    };

    const handleDoRemove = () => {
      this.props.deleteSeeker(this.state.removeSeekerId);
      this.setState(
        {
          removeSeekerId: '',
        },
        () => this.setState({areYouSureModalIsOpen: false})
      );
      this.props.history.push('/admin/candidates');
    };

    const handleAreYouSureClose = () => {
      this.setState(
        {
          removeSeekerId: '',
        },
        () => this.setState({areYouSureModalIsOpen: false})
      );
    };

    const ascendActionIcons = () => {
      return (
        <div className={styles.buttonRow}>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.handleInviteClicked();
            }}
            style={{margin: '4px'}}
          >
            Invite to Apply
          </button>
          <PushButton
            buttonText={seeker.seekerSupportFlag ? 'Flagged' : 'Flag'}
            isIconLeftAligned={true}
            buttonSvg={
              seeker.seekerSupportFlag ? (
                <FlagSolidSvg style={{marginRight: '8px'}} />
              ) : (
                <FlagSvg
                  className="svg_color_stroke_blue_heart"
                  style={{marginRight: '8px'}}
                />
              )
            }
            action={this.handleFlagClicked}
            isToggled={seeker.seekerSupportFlag}
            className={`btn-secondary ${styles.toggleButton}`}
            buttonToggledCssClass={styles.flagToggleButtonToggled}
          />
          <button
            className="btn btn-secondary-neutral"
            onClick={this.handleNotesClicked}
            style={{margin: '4px'}}
          >
            Notes ({this.props.seeker.noteCount})
          </button>

          <Dropdown
            direction="down"
            isOpen={this.state.menuOpen}
            toggle={() => {
              this.setState({menuOpen: !this.state.menuOpen});
            }}
          >
            <DropdownToggle
              className="btn btn-secondary-neutral d-flex align-items-center"
              style={{margin: '4px'}}
              title="More Options"
            >
              <i
                aria-label="row context menu"
                className="nc-icon-glyph-med dots gly-rotate-90"
                style={{fontSize: '16px', lineHeight: '1.5'}}
              />
            </DropdownToggle>
            <DropdownMenu end={true} className="dropdown-menu-fit-content">
              {menuActions
                .filter((action) => {
                  return action.showMenu(this.props.seeker);
                })
                .map((action, index) => {
                  if (action.isEdit) {
                    return null;
                  }

                  return (
                    <DropdownItem
                      key={index}
                      tag="div"
                      className="clickable fullwidth clickable-dropdown"
                      onClick={() => {
                        action.onClick(this.props.seeker.id);
                      }}
                    >
                      {action.displayName}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
        </div>
      );
    };

    const renderApprenticePathwayList = () => {
      if (
        this.props.seeker.apprenticePathways &&
        this.props.seeker.apprenticePathways.length > 0 &&
        this.props.apprenticePathways
      ) {
        return (
          <>
            {this.props.seeker.apprenticePathways.map((x) => (
              <div key={x.value} className="secondary-subheader extraspace">
                {x.label}
              </div>
            ))}
          </>
        );
      }

      return <div></div>;
    };

    const simpleJobCards = () => {
      let jobMatches = mergeMatches(seeker);

      if (jobMatches) {
        if (jobMatches.length >= 3) {
          jobMatches.length = 3;
        }

        return jobMatches.map((jobMatch) => {
          const matchData = {
            ...jobMatch.matchData,
            seekerTraits: seeker.characterTraits,
            jobTraits: jobMatch.jobTraits,
          };

          let invitedDate = null;
          if ('invitedOn' in jobMatch) {
            invitedDate = new Date(jobMatch.invitedOn);
          }

          return (
            <div
              key={jobMatch.jobId}
              style={isMaSegment === true ? {width: 40, height: 40} : {}}
            >
              <SimpleJobCard
                name={jobMatch.jobName}
                team={jobMatch.teamName}
                employer={jobMatch.employerName}
                matchData={matchData}
                viewerType={role === 'JOB_SEEKER' ? 'CANDIDATE' : 'RECRUITER'}
                showWatchIcon={role === 'JOB_SEEKER'}
                watched={jobIsWatched(jobMatch.jobId, seeker)}
                invitedDate={invitedDate}
                invitedByName={jobMatch.invitedByName}
                invitedByRole={jobMatch.invitedByRole}
                onWatchChanged={(watched) =>
                  this.handleWatchChanged(jobMatch, watched)
                }
                matchCardStyle={{left: '-235px'}}
                onJobClick={() => this.handleJobClicked(jobMatch.jobId)}
                onTeamClick={() => {
                  this.props.history.push('/team/' + jobMatch.teamId);
                }}
                onEmployerClick={() => {
                  this.props.history.push('/employer/' + jobMatch.employerId);
                }}
                isMaSegment={isMaSegment}
              />
              <br />
            </div>
          );
        });
      }
    };

    const preferencesRows = [
      {
        canDisplay: isMaSegment === false,
        title: 'Employment Type(s)',
        content: seeker.employmentTypes
          ? seeker.employmentTypes.map((x) => x.name).join(', ')
          : null,
        onEdit: () => {
          this.setState({
            employmentTypesModalIsOpen: true,
          });
        },
      },
      {
        canDisplay: isMaSegment === false,
        title: 'Experience Level',
        content: seeker.experienceLevels
          ? seeker.experienceLevels.map((x) => x.years).join(', ')
          : 'Not Selected',
        onEdit: () => {
          this.setState({
            experienceLevelModalIsOpen: true,
          });
        },
      },
      {
        canDisplay: isMaSegment === false,
        title: 'Role Type(s)',
        content: seeker.roleTypes
          ? seeker.roleTypes.map((x) => x.name).join(', ')
          : null,
        onEdit: () => {
          this.setState({
            roleTypesModalIsOpen: true,
          });
        },
      },
      {
        canDisplay: isMaSegment === false,
        title: 'Industries',
        content: seeker.industries
          ? seeker.industries.map((x) => x.name).join(', ')
          : null,
        onEdit: () => {
          this.setState({
            industriesModalIsOpen: true,
          });
        },
      },
      {
        canDisplay: isMaSegment === false,
        title: 'Location(s)',
        content: seeker.regions
          ? seeker.regions.map((x) => x.name).join(', ')
          : null,
        onEdit: () => {
          this.setState({
            regionsModalIsOpen: true,
          });
        },
      },
      {
        canDisplay: isMaSegment === false,
        title: 'Available Start Date',
        content: formatDate(seeker.availableToStart),
        onEdit: () => {
          this.setState({
            startDateModalIsOpen: true,
          });
        },
      },
      // EmployIndy job seekers only and Never employer!
      {
        canDisplay:
          isMaSegment === false &&
          this.props.employmentTypeIds &&
          this.props.employmentTypeIds.some(
            (x) => x.name === 'Full-Time Job'
          ) &&
          this.showPreferredWage(),
        title: 'Preferred Yearly Salary',
        content: seeker.minimumYearlySalary,
        onEdit: () => {
          this.setState({
            wagePreferenceModalIsOpen: true,
          });
        },
      },
      {
        canDisplay:
          isMaSegment === false &&
          this.props.employmentTypeIds &&
          this.props.employmentTypeIds.some(
            (x) => x.name === 'Part-Time Job'
          ) &&
          this.showPreferredWage(),
        title: 'Preferred Hourly Wage',
        content: seeker.minimumHourlyWage,
        onEdit: () => {
          this.setState({
            wagePreferenceModalIsOpen: true,
          });
        },
      },
    ];

    const shouldDisable = () => {
      if (isMaSegment) {
        return (
          !seeker.accepted ||
          !seeker.isSchoolApproved ||
          seeker.onboardedOn === null
        );
      }
      return false;
    };

    const renderTable = (seeker) => {
      if (seeker && seeker.id) {
        return (
          <div className="row">
            <div className="col-lg-3">
              {allowSeekerEdit && (
                <div style={{paddingBottom: '16px'}}>
                  <PublishToggle
                    published={seeker.published}
                    onChange={this.handlePublishChanged}
                    disabled={shouldDisable()}
                  />
                </div>
              )}

              {role !== 'JOB_SEEKER' && (
                <div style={{paddingBottom: '24px'}}>
                  <div
                    className="normal-caption"
                    style={{
                      paddingBottom: '16px',
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h2 className={styles.sectionHeading}>
                      Their Recruiter Says
                    </h2>
                    {allowSeekerEdit && (
                      <button
                        style={{fontSize: '12pt'}}
                        className="buttonAsLinkCandidateProfile"
                        onClick={() => {
                          this.setState({
                            recruiterSaysModalIsOpen: true,
                          });
                        }}
                        tabIndex={0}
                        aria-label="Edit Their Recruiter Says"
                      >
                        Edit
                      </button>
                    )}
                  </div>
                  <div
                    className="secondary-body"
                    style={{paddingBottom: '16px'}}
                  >
                    {!_.isEmpty(seeker.highlightsNotes)
                      ? `"${seeker.highlightsNotes}"`
                      : ''}
                  </div>
                  <hr style={{margin: 0}} />
                </div>
              )}

              {isMaSegment && (
                <>
                  <div>
                    <div className="inlineblock float-right">
                      {allowSeekerEdit && (
                        <button
                          type="button"
                          className="buttonAsLinkCandidateProfile"
                          aria-label="Edit Pathways"
                          onClick={() => {
                            this.handleEditEducation();
                          }}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                    <div className="inlineblock normal-caption">
                      <h2 className={styles.sectionHeading}>Pathways</h2>
                    </div>
                  </div>

                  {renderApprenticePathwayList()}
                  <hr />
                </>
              )}

              {!isMaSegment && (
                <InfoCard title="Preferences" allowEdit={false}>
                  {preferencesRows.map((row, i) => {
                    return row.canDisplay ? (
                      <InfoCardRow
                        key={i}
                        title={row.title}
                        content={row.content}
                        allowEdit={allowSeekerEdit}
                        onEdit={row.onEdit}
                      />
                    ) : null;
                  })}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="inlineblock">
                        <span
                          className="normal-caption bold"
                          style={{paddingLeft: '8px'}}
                        >
                          <h2 className={styles.sectionHeading}>Key Factors</h2>
                        </span>
                      </div>

                      <div className="inlineblock float-right">
                        {allowSeekerEdit && (
                          <button
                            type="button"
                            className="buttonAsLinkCandidateProfile"
                            aria-label={`Edit Key Factors`}
                            onClick={() => {
                              this.setState({keyFactorsModalIsOpen: true});
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {isMaSegment === false &&
                    !_.isEmpty(seeker.workStyles) &&
                    _.orderBy(seeker.workStyles, ['order']).map((ws, index) => {
                      return (
                        <div key={index} style={{paddingLeft: '8px'}}>
                          <WorkStyle
                            label={ws.name}
                            level={ws.level}
                            showHorizontalRule={
                              index < seeker.workStyles.length - 1
                            }
                          />
                        </div>
                      );
                    })}

                  {_.isEmpty(seeker.workStyles) && (
                    <div
                      className="disabled-caption"
                      style={{paddingLeft: '8px'}}
                    >
                      No work style preferences selected.
                    </div>
                  )}
                </InfoCard>
              )}

              <div style={{paddingBottom: '24px'}} />

              {isMaSegment === false && (
                <TagsWidget
                  onEdit={() => {
                    this.setState({skillsModalIsOpen: true});
                  }}
                  tags={seeker.skillTags
                    .map((tag) => ({
                      tagName: tag.name,
                      definition: tag.definition,
                    }))
                    .sort((x) => x.name)}
                  title="SKILLS & EXPERTISE"
                  tagClass="skilltag"
                  readOnly={!allowSeekerEdit}
                />
              )}
            </div>
            <div className="col-lg-6">
              <div className="inlineblock float-right">
                {isMaSegment === false && allowSeekerEdit && (
                  <SummaryEditLink
                    onClick={() => {
                      this.setState({summaryModalIsOpen: true});
                    }}
                  />
                )}
                {isMaSegment === true && allowSeekerEdit && (
                  <SeekerQuestionsEditLink
                    onClick={() => {
                      this.setState({maProfileQnAModalIsOpen: true});
                    }}
                  />
                )}
              </div>
              {isMaSegment === false && (
                <div className="normal-subheader">
                  <h2 className={styles.sectionHeading}>Personal Summary</h2>
                </div>
              )}
              <br />
              {isMaSegment === false && (
                <div
                  style={{overflowWrap: 'break-word'}}
                  className="secondary-subheader"
                >
                  {seeker.summary}
                </div>
              )}
              <Fragment>
                {isMaSegment === true &&
                  this.state.QnA &&
                  this.state.QnA.map((q) => this.renderQuestion(q))}
              </Fragment>
              <br />
              {isMaSegment === false && (
                <ExperienceWidget
                  allowEdit={allowSeekerEdit}
                  onEdit={() => {
                    this.setState({experienceModalIsOpen: true});
                  }}
                  experience={experience}
                />
              )}
            </div>
            <div className="col-lg-3">
              {isMaSegment === false && seeker.published === true && (
                <>
                  <div className="normal-caption">
                    <h2 className={styles.sectionHeading}>May be a fit for:</h2>
                  </div>
                  <br />
                  {simpleJobCards()}
                  <hr />
                </>
              )}
              <div>
                <div className="inlineblock float-right">
                  {allowSeekerEdit && (
                    <button
                      type="button"
                      className="buttonAsLinkCandidateProfile"
                      aria-label="Edit Resume"
                      onClick={() => {
                        this.setState({resumeUploadModalIsOpen: true});
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="inlineblock normal-caption">
                  <h2 className={styles.sectionHeading}>Resume</h2>
                </div>
              </div>
              <br />
              <p>
                {seeker.resume && (
                  <ViewResumeLink onClick={this.handleResumeClick} />
                )}
              </p>
              <p>
                {seeker.resume && (
                  <DownloadResumeLink
                    url={seeker.resumePdfUrl}
                    onClick={this.handleResumeDownloadClick}
                  />
                )}
              </p>
              <hr />
              <div>
                <div className="inlineblock float-right">
                  {allowSeekerEdit && (
                    <DetailsEditLink
                      onClick={() => {
                        this.setState({detailsModalIsOpen: true});
                      }}
                    />
                  )}
                </div>
                <div className="inlineblock normal-caption">
                  <h2 className={styles.sectionHeading}>User Details</h2>
                </div>
              </div>
              <br />
              <div className={styles.userDetailsText}>
                <i
                  className={`nc-icon-glyph letter ${styles.userDetailsIcon}`}
                />
                <span style={{whiteSpace: 'no-wrap'}}>{seeker.email}</span>
              </div>
              <div className={styles.userDetailsText}>
                <i
                  className={`nc-icon-glyph phone ${styles.userDetailsIcon}`}
                />
                {seeker.phone}
              </div>
              {seeker.url && (
                <div className={styles.userDetailsText}>
                  <i
                    className={`nc-icon-glyph globe ${styles.userDetailsIcon}`}
                  />
                  <a
                    className="clickable active-subheader"
                    style={{textDecoration: 'none'}}
                    href={fixUrl(seeker.url)}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Url"
                  >
                    {shortUrl(seeker.url, 20)}
                  </a>
                </div>
              )}
              {seeker.linkedinProfile && (
                <div className={styles.userDetailsText}>
                  <i
                    className={`nc-icon-glyph linkedin ${styles.userDetailsIcon}`}
                  />
                  <a
                    className="clickable active-subheader"
                    style={{textDecoration: 'none'}}
                    href={fixUrl(seeker.linkedinProfile)}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Linkedin"
                  >
                    {shortUrl(seeker.linkedinProfile, 20)}
                  </a>
                </div>
              )}
              <hr />

              {allowSeekerEdit && role !== 'JOB_SEEKER' && (
                <Fragment>
                  <div className="normal-caption">
                    <h2 className={styles.sectionHeading}>Segment</h2>
                  </div>
                  <br />
                  <SideBarSegmentDisplay parentObject={seeker} role={role} />
                </Fragment>
              )}
            </div>
          </div>
        );
      } else {
        return <div />;
      }
    };

    let actionIcons = null;
    switch (this.props.role) {
      case 'COMPANY_RECRUITER':
      case 'COMPANY_RECRUITER_ADMIN':
        actionIcons = recruiterActionIcons();
        break;
      case 'ASCEND_RECRUITER':
        actionIcons = ascendActionIcons();
        break;
      case 'ADMIN':
      case 'SEGMENT_ADMIN':
      case 'RECRUITER':
      case 'EMPLOYER_MANAGER':
      default:
        actionIcons = null;
    }
    let candidateName = '';
    if (this.props.seeker.firstName !== '') {
      candidateName =
        this.props.seeker.firstName + ' ' + this.props.seeker.lastName + ' - ';
    }

    return (
      <DocumentTitle title={`${candidateName}Candidate Profile`}>
        <LoadingOverlay
          active={this.state.loading}
          spinner={true}
          text="Loading..."
          fadeSpeed={200}
        >
          <div className="wholepage">
            <Layout.PageHeader
              title={null}
              showAdminLinks={this.showAdminLinks}
            />

            <main id="main" tabIndex="-1">
              <PreviousButton />
              {!this.state.canViewPII && !this.state.loading && (
                <div className="row">
                  <div className="col-8 offset-2">
                    <h2>
                      You do not have permission to view candidate profile
                      information
                    </h2>
                  </div>
                </div>
              )}

              {!this.state.loading && this.state.canViewPII && (
                <Fragment>
                  {job && job.id && (
                    <JobProfile
                      key={job.id}
                      isOpen={this.state.jobProfileIsOpen}
                      job={job}
                      matchData={findMatchData(
                        this.state.jobId,
                        this.props.seeker.matches
                      )}
                      onClose={() => {
                        this.setState({jobProfileIsOpen: false});
                      }}
                      watched={jobIsWatched(
                        this.state.jobId,
                        this.props.seeker
                      )}
                      onWatchChanged={(watched) =>
                        this.handleWatchChanged(job, watched)
                      }
                      applied={jobIsApplied(
                        this.state.jobId,
                        this.props.seeker
                      )}
                      onAppliedChanged={(applied) =>
                        this.handleAppliedChanged(this.state.jobId, applied)
                      }
                    />
                  )}

                  <ChangeEmailModal
                    isOpen={this.state.changeEmailModalIsOpen}
                    onClose={() => {
                      this.setState({changeEmailModalIsOpen: false});
                    }}
                    onCancel={() => {
                      this.setState({changeEmailModalIsOpen: false});
                    }}
                    isThirdPartyEmailChange={true}
                  />

                  <RecruiterSaysModal
                    isOpen={this.state.recruiterSaysModalIsOpen}
                    onClose={() => {
                      this.setState({recruiterSaysModalIsOpen: false});
                    }}
                    onSubmit={(values) =>
                      this.handleRecruiterSaysSubmit(values)
                    }
                    highlightsNotes={
                      !_.isEmpty(seeker.highlightsNotes)
                        ? `${seeker.highlightsNotes}`
                        : ''
                    }
                  />
                  <ExperienceLevelModal
                    isOpen={this.state.experienceLevelModalIsOpen}
                    onClose={() => {
                      this.setState({experienceLevelModalIsOpen: false});
                    }}
                    onSubmit={this.handleExperienceLevelSubmit}
                    experienceOptions={this.props.experienceLevels}
                    selectedValues={seeker.experienceLevels}
                  />

                  <EditEmploymentTypesModal
                    segments={seeker.segments}
                    isOpen={this.state.employmentTypesModalIsOpen}
                    onClose={() => {
                      this.setState({employmentTypesModalIsOpen: false});
                    }}
                    onSubmit={async (values) => {
                      const newValues = {
                        employmentTypeIds: values.employmentTypeIds.map(
                          (x) => x.buttonValue
                        ),
                      };

                      const selectedValues = values.employmentTypeIds
                        .map((x) => x.buttonText)
                        .join(', ');
                      const existingEmploymentTypes = seeker.employmentTypes
                        .map((et) => et.name)
                        .join(', ');

                      if (selectedValues !== existingEmploymentTypes) {
                        analyticsTrack({
                          eventName: 'EmploymentTypesChanged',
                          displayName: 'Employment Types Changed',
                          seekerId: seeker.id,
                          userType: role,
                          segmentId: seeker.segments[0].name,
                          seekerEmploymentTypePreferences: selectedValues,
                          numberOfMatches: seeker.matches.length,
                        });
                      }
                      await this.handleSubmit(newValues);
                      this.setState({employmentTypesModalIsOpen: false});
                    }}
                    seekerEmploymentTypes={seeker.employmentTypes.map(
                      (x) => x.id
                    )}
                  />
                  <EditRoleTypesModal
                    isOpen={this.state.roleTypesModalIsOpen}
                    onClose={() => {
                      this.setState({roleTypesModalIsOpen: false});
                    }}
                    onSubmit={async (values) => {
                      const newValues = {
                        roleTypeIds: values.roleTypeIds.map((x) => x.id),
                      };
                      const selectedValues = values.roleTypeIds
                        .map((x) => x.label)
                        .join(', ');
                      const existingRoleTypes = seeker.roleTypes
                        .map((et) => et.name)
                        .join(', ');

                      if (selectedValues !== existingRoleTypes) {
                        analyticsTrack({
                          eventName: 'RoleTypesChanged',
                          displayName: 'Role Types Changed',
                          seekerId: seeker.id,
                          userType: role,
                          segmentId: seeker.segments[0].name,
                          seekerRoleTypePreferences: selectedValues,
                          numberOfMatches: seeker.matches.length,
                        });
                      }
                      await this.handleSubmit(newValues);
                      this.setState({roleTypesModalIsOpen: false});
                    }}
                  />
                  <EditIndustriesModal
                    isOpen={this.state.industriesModalIsOpen}
                    onClose={() => {
                      this.setState({industriesModalIsOpen: false});
                    }}
                    onSubmit={async (values) => {
                      const selectedValues = values.industryIds
                        .map((x) => x.label)
                        .join(', ');
                      const existingIndustriesTypes = seeker.industries
                        .map((et) => et.name)
                        .join(', ');

                      if (selectedValues !== existingIndustriesTypes) {
                        analyticsTrack({
                          eventName: 'IndustryChanged',
                          displayName: 'Industry Modal Opened',
                          seekerId: seeker.id,
                          userType: role,
                          segmentId: seeker.segments[0].name,
                          seekerIndustryPreferences: selectedValues,
                          numberOfMatches: seeker.matches.length,
                        });
                      }
                      await this.handleSubmit(values);
                      this.setState({industriesModalIsOpen: false});
                    }}
                  />
                  <EditRegionsModal
                    isOpen={this.state.regionsModalIsOpen}
                    onClose={() => {
                      this.setState({regionsModalIsOpen: false});
                    }}
                    onSubmit={async (values) => {
                      const selectedValues = values.regionIds
                        .map(
                          (x) =>
                            this.props.regions.find((y) => y.id === x)?.name
                        )
                        .join(', ');
                      const existingRegionsTypes = seeker.regions
                        .map((et) => et.name)
                        .join(', ');

                      if (selectedValues !== existingRegionsTypes) {
                        analyticsTrack({
                          eventName: 'LocationChanged',
                          displayName: 'Location Modal Opened',
                          seekerId: seeker.id,
                          userType: role,
                          segmentId: seeker.segments[0].name,
                          seekerLocationPreferences: selectedValues,
                          numberOfMatches: seeker.matches.length,
                        });
                      }
                      await this.handleSubmit(values);
                      this.setState({regionsModalIsOpen: false});
                    }}
                  />
                  <EditStartDateModal
                    isOpen={this.state.startDateModalIsOpen}
                    onClose={() => {
                      this.setState({startDateModalIsOpen: false});
                    }}
                    onSubmit={async (values) => {
                      if (values.availableToStart !== seeker.availableToStart) {
                        analyticsTrack({
                          eventName: 'startDateChanged',
                          displayName: 'Start Date Modal Opened',
                          seekerId: seeker.id,
                          userType: role,
                          segmentId: seeker.segments[0].name,
                          numberOfMatches: seeker.matches.length,
                        });
                      }
                      await this.handleSubmit(values);
                      this.setState({startDateModalIsOpen: false});
                    }}
                  />
                  <EditWagePreferenceModal
                    isOpen={this.state.wagePreferenceModalIsOpen}
                    onClose={() => {
                      this.setState({wagePreferenceModalIsOpen: false});
                    }}
                    onSubmit={async (values) => {
                      if (
                        values.minimumYearlySalary !==
                        seeker.minimumYearlySalary
                      ) {
                        analyticsTrack({
                          eventName: 'wageModalOpened',
                          displayName: 'Wage Modal Opened',
                          seekerId: seeker.id,
                          userType: role,
                          segmentId: seeker.segments[0].name,
                          numberOfMatches: seeker.matches.length,
                        });
                      }
                      await this.handleSubmit(values);
                      this.setState({wagePreferenceModalIsOpen: false});
                    }}
                  />
                  {isMaSegment === false && (
                    <EditKeyFactorsModal
                      initialValues={seeker.workStyles}
                      isOpen={this.state.keyFactorsModalIsOpen}
                      onClose={() => {
                        this.setState({keyFactorsModalIsOpen: false});
                      }}
                      onSubmit={async (values) => {
                        const selectedValues = values.workStyles
                          .map((x) => `${x.name}-${x.level}`)
                          .join(', ');
                        const existingWorkStyles = seeker.workStyles
                          .map((y) => `${y.name}-${y.level}`)
                          .join(', ');

                        if (selectedValues !== existingWorkStyles) {
                          analyticsTrack({
                            eventName: 'KeyFactorsChanged',
                            displayName: 'Key Factor Modal Opened',
                            seekerId: seeker.id,
                            userType: role,
                            segmentId: seeker.segments[0].name,
                            numberOfMatches: seeker.matches.length,
                          });
                        }
                        await this.handleSubmit(values);
                        this.setState({keyFactorsModalIsOpen: false});
                      }}
                    />
                  )}
                  {isMaSegment === true && (
                    <MaProfileQnAModal
                      questions={
                        _.isEmpty(seekerQuestions)
                          ? seekerQuestionList
                          : seekerQuestions
                      }
                      isOpen={this.state.maProfileQnAModalIsOpen}
                      onClose={() => {
                        this.setState({
                          maProfileQnAModalIsOpen: false,
                        });
                      }}
                      onSubmit={async (values) => {
                        await this.readQnAValues(values);
                        await this.handleQnASubmit(values);
                      }}
                    />
                  )}
                  <ResumeModal
                    isOpen={this.state.resumeModalIsOpen}
                    onClose={() => {
                      this.setState({resumeModalIsOpen: false});
                    }}
                    embedUrl={seeker.resumeEmbedUrl}
                    downloadUrl={seeker.resumePdfUrl}
                    downloadFilename={seeker.resumeDownloadFileName}
                  />
                  <SkillsModal
                    isOpen={this.state.skillsModalIsOpen}
                    onClose={() => {
                      this.setState({skillsModalIsOpen: false});
                    }}
                    selectedSkills={skillTags}
                    onChange={this.handleSkillsChanged}
                    onSubmit={this.handleSkillsSubmit}
                  />
                  <EditExperienceModal
                    isOpen={this.state.experienceModalIsOpen}
                    onClose={() => {
                      this.setState({experienceModalIsOpen: false});
                    }}
                    onSubmit={this.handleExperienceSubmit}
                  />
                  <EditSummaryModal
                    isOpen={this.state.summaryModalIsOpen}
                    onClose={() => {
                      this.setState({summaryModalIsOpen: false});
                    }}
                    onSubmit={(values) => this.handleSummarySubmit(values)}
                  />
                  <EditDetailsModal
                    isOpen={this.state.detailsModalIsOpen}
                    onClose={() => {
                      this.setState({detailsModalIsOpen: false});
                    }}
                    onSubmit={this.handleDetailsSubmit}
                  />
                  <EducationModal
                    isOpen={this.state.educationModalIsOpen}
                    disableEmail={true}
                    onClose={() => {
                      this.setState({educationModalIsOpen: false});
                    }}
                    onSubmit={this.handleEducationSubmit}
                    isMaSegment={isMaSegment}
                    segments={seeker.segments}
                  />
                  <EditResumeUploadModal
                    isOpen={this.state.resumeUploadModalIsOpen}
                    onClose={() => {
                      this.setState({resumeUploadModalIsOpen: false});
                    }}
                    onSubmit={this.handleResumeSubmit}
                  />
                  {(this.props.role === 'COMPANY_RECRUITER' ||
                    this.props.role === 'COMPANY_RECRUITER_ADMIN') &&
                    seeker.teamWatchers && (
                      <TeamBenchModal
                        isOpen={this.state.teamBenchModalIsOpen}
                        teams={this.props.teams}
                        candidateTeamIds={seeker.teamWatchers.map(
                          (t) => t.teamId
                        )}
                        seekerName={`${seeker.firstName} ${seeker.lastName}`}
                        onTeamChecked={this.handleTeamChecked}
                        onOkClicked={() => {
                          this.setState({teamBenchModalIsOpen: false});
                        }}
                        onClose={() => {
                          this.setState({teamBenchModalIsOpen: false});
                        }}
                      />
                    )}
                  {(this.props.role === 'COMPANY_RECRUITER' ||
                    this.props.role === 'COMPANY_RECRUITER_ADMIN') &&
                    this.state.inviteToApplySeeker !== null && (
                      <InviteToApplyModal
                        isOpen={this.state.inviteToApplyModalIsOpen}
                        seeker={this.state.inviteToApplySeeker}
                        onSendClicked={this.handleInviteToApplySendClicked}
                        onClose={() => {
                          this.setState({
                            inviteToApplyModalIsOpen: false,
                            inviteToApplySeeker: null,
                          });
                        }}
                      />
                    )}
                  {this.props.role === 'ASCEND_RECRUITER' && seeker.id && (
                    <>
                      <NotesModal
                        isOpen={this.state.notesModalIsOpen}
                        onClose={() => {
                          document.body.classList.remove('modal-open');
                          this.setState({notesModalIsOpen: false});
                        }}
                        seeker={this.props.seeker}
                      />
                      <InviteToApplyModalForAscend
                        isOpen={this.state.inviteToApplyModalIsOpen}
                        seeker={this.props.seeker}
                        onSendClicked={this.handleInviteToApplySendClicked}
                        onClose={() => {
                          this.setState({inviteToApplyModalIsOpen: false});
                        }}
                      />
                    </>
                  )}
                  <AreYouSureModal
                    areYouSureText="Are you sure that you want to remove this seeker?"
                    isOpen={this.state.areYouSureModalIsOpen}
                    onClose={handleAreYouSureClose}
                    onYes={handleDoRemove}
                  />

                  {this.state.assignFormOpen && (
                    <AssignForm
                      isOpen={this.state.assignFormOpen}
                      ascendRecruiterId={this.state.ascendRecruiterId}
                      ascendRecruiters={this.props.ascendRecruiters}
                      profile={this.props.profile}
                      selectedSeeker={this.props.seeker}
                      onClose={() => this.setState({assignFormOpen: false})}
                      onSubmit={handleAssignSubmit}
                    />
                  )}

                  <ApplyForm
                    isOpen={this.state.applyFormOpen}
                    seeker={this.props.seeker}
                    seekerId={this.props.seeker.id}
                    seekerName={`${seeker.firstName} ${seeker.lastName}`}
                    onClose={() => this.setState({applyFormOpen: false})}
                    onSubmit={handleApplySubmit}
                  />

                  <CreateConnectionModal
                    isOpen={this.state.reportConnectionFormOpen}
                    seeker={this.props.seeker}
                    onClose={() =>
                      this.setState({reportConnectionFormOpen: false})
                    }
                  />

                  <AuthorizationModal
                    isOpen={this.state.authorizationModalIsOpen}
                    onClose={() => {
                      this.setState({authorizationModalIsOpen: false});
                    }}
                    seeker={this.props.seeker}
                  />

                  <div className="bd-pagebody">
                    <div className="container">
                      {this.props.showJobNotFoundModal && (
                        <CandidateNotifyMissingRole
                          isOpen={this.props.showJobNotFoundModal}
                          onClose={() => this.props.setJobNotFoundModal(false)}
                        />
                      )}
                      <div className="row">
                        <div className="col-12">&nbsp;</div>
                      </div>
                      <div className="row">
                        <CandidateInfoWithEdit
                          allowEdit={
                            allowSeekerEdit && role !== 'ASCEND_RECRUITER'
                          }
                          seeker={this.props.seeker}
                          actionIcons={actionIcons}
                          onEditClick={this.handleEditEducation}
                          isMaSegment={isMaSegment}
                        />
                      </div>
                      {this.props.role === 'JOB_SEEKER' && <CandidateSubMenu />}
                      {this.showAdminSubMenu && this.adminSubMenuEnabled && (
                        <AdminSubMenu seekerId={this.props.seeker.id} />
                      )}

                      {this.props.role !== 'JOB_SEEKER' &&
                        (!this.showAdminSubMenu ||
                          !this.adminSubMenuEnabled) && <hr />}
                      {renderTable(this.props.seeker)}
                    </div>
                    <br />
                  </div>
                </Fragment>
              )}
            </main>
            <Layout.PageFooter />
          </div>
        </LoadingOverlay>
      </DocumentTitle>
    );
  }

  buildTraits(index, ws, values) {
    const formValuesTraits = values || {};
    const {seekerQuestions, seekerQuestionList} = this.props;

    const questions = _.isEmpty(seekerQuestions)
      ? seekerQuestionList
      : seekerQuestions;

    const defaultLevel = _.isEmpty(formValuesTraits)
      ? questions.find((x) => x.id === ws.id).answer
      : 0;

    const {traitsUnsaved} = this.state;
    let level = Number(defaultLevel);
    if (formValuesTraits.traitQuestions) {
      let filteredTraits = formValuesTraits.traitQuestions.find(
        (x) => x.id === ws.id
      );
      level = filteredTraits ? filteredTraits.level : 0;
    }

    return (
      <div key={index} style={{paddingLeft: '8px'}}>
        <WorkStyle
          label={ws.name}
          level={traitsUnsaved ? ws.level : level}
          showHorizontalRule={false}
        />
      </div>
    );
  }

  showPreferredWage() {
    const {employmentTypeIds, permissions} = this.props;
    if (employmentTypeIds && permissions) {
      if (
        permissions.includes(PERMISSIONS.READ_SEEKER_WAGE_PREFERENCE) &&
        permissions.includes(PERMISSIONS.SEGMENT_WAGE_FEATURE) &&
        employmentTypeIds.find((x) => x.collectWageInfo === true)
      ) {
        return true;
      }
    }
    return false;
  }
}

CandidateProfilePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loggedInSeekerId: PropTypes.string,
  seeker: PropTypes.object,
  seekerForEdit: PropTypes.object,
  seekerLoading: PropTypes.bool.isRequired,
  getSeekerAction: PropTypes.func.isRequired,
  getSeekerQuestionsAndAnswersAction: PropTypes.func.isRequired,
  role: PropTypes.string,
  recruiterId: PropTypes.number,
  teams: PropTypes.arrayOf(PropTypes.object),
  getTeamsByRecruiter: PropTypes.func,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  createSeekerWatch: PropTypes.func.isRequired,
  deleteSeekerWatch: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  updateSeekerAction: PropTypes.func.isRequired,
  interviewSkillsChanged: PropTypes.func.isRequired,
  getEducationData: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  canEditSeeker: PropTypes.func.isRequired,
  allowSeekerEdit: PropTypes.bool,
  permissions: PropTypes.array.isRequired,
  employmentTypeIds: PropTypes.array,
  seekerQuestions: PropTypes.array,
  getSeekerQuestionsAction: PropTypes.func.isRequired,
  seekerQuestionList: PropTypes.array,
  experienceLevels: PropTypes.array,
  getExperienceLevels: PropTypes.func.isRequired,
  toggleSeekerSupportFlag: PropTypes.func.isRequired,
  getAllApprenticePathways: PropTypes.func.isRequired,
  apprenticePathways: PropTypes.array,
  onboardingComplete: PropTypes.func,
  profile: PropTypes.object,
  getSeekerInviteResponse: PropTypes.func,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
  deleteSeeker: PropTypes.func.isRequired,
  ascendRecruiters: PropTypes.array.isRequired,
  getAscendRecruiters: PropTypes.func.isRequired,
  createJobAppliedBySeeker: PropTypes.func.isRequired,
  seekers: PropTypes.array.isRequired,
  regions: PropTypes.array,
  updateChangeEmailUser: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loggedInSeekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    seekerForEdit: state.seekers.seekerForEdit,
    seekerLoading: state.seekers.seekerLoading,
    role: state.profile.role,
    recruiterId: state.profile.companyRecruiterId,
    teams: state.teams.teams,
    jobCache: state.jobs.jobCache,
    allowSeekerEdit: state.authorization.seeker.canEdit,
    permissions: state.profile.permissions,
    employmentTypeIds: state.seekers.seeker.employmentTypes,
    seekerQuestionList: state.seekers.seeker_questions,
    seekerQuestions:
      state.seekers.questionsAndAnswers &&
      state.seekers.questionsAndAnswers.seeker_questions,
    experienceLevels: state.experienceLevels.experienceLevels,
    apprenticePathways: state.apprenticePathways.apprenticePathways,
    profile: state.profile,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
    ascendRecruiters: state.ascendRecruiters.ascendRecruiters,
    seekers: state.seekers.seekers,
    regions: state.regions.regions,
  };
};

const mapDispatchToProps = {
  ...seekersActions,
  ...seekers,
  ...jobsActions,
  ...teamsActions,
  ...institutionsActions,
  ...flashActions,
  ...AuthorizeActions,
  ...ascendRecruitersActions,
  getExperienceLevels,
  getAllApprenticePathways,
  onboardingComplete,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateProfilePage)
);
