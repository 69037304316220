import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from 'react-bootstrap';
import FilterPanel from 'src/components/controls/filters/FilterPanel';
import DropdownFilterPanel from '../filters/DropdownFilterPanel';

export default function SearchTab(props) {
  const [key, setKey] = useState(1);

  const buildFilterPanel = (show, eventKey) => {
    if (show && !props.useDropdownPanel) {
      return (
        <FilterPanel
          filterButtonAction={props.filterButtonAction}
          filterButtons={props.filterButtons}
          timelineFilterButtonAction={props.timelineFilterButtonAction}
          showTimeline={props.showTimeline}
          searchHelperText={props.searchHelperText}
          dateStartFilter={props.dateStartFilter}
          dateEndFilter={props.dateEndFilter}
          onAdvancedFilterChange={props.handleAdvancedFilterChange}
          handleClearAll={props.handleClearAll}
          currentTabId={eventKey}
          selectedMultiSelectFilters={props.selectedMultiSelectFilters}
          itemsLoading={props.itemsLoading}
          showFilter={props.showFilter}
        />
      );
    } else if (show && props.useDropdownPanel) {
      return (
        <DropdownFilterPanel
          filterButtonAction={props.filterButtonAction}
          filterButtons={props.filterButtons}
          searchHelperText={props.searchHelperText}
          dateStartFilter={props.dateStartFilter}
          dateEndFilter={props.dateEndFilter}
          handleClearAll={props.handleClearAll}
          currentTabId={eventKey}
          selectedMultiSelectFilters={props.selectedMultiSelectFilters}
          filterDropdowns={props.filterDropdowns}
          itemsLoading={props.itemsLoading}
        />
      );
    } else {
      return null;
    }
  };
  const buildList = (data) => {
    return data
      .filter((x) => x.show)
      .map((x, index) => {
        return (
          <Tab
            key={index + 1}
            eventKey={x.eventKey}
            title={x.title}
            tabClassName="blue-tab-header"
          >
            {buildFilterPanel(props.showSearchTab, x.eventKey)}
          </Tab>
        );
      });
  };

  return (
    <div>
      <Tabs
        id="searchTab"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          props.handleOnSelect(k);
        }}
        defaultActiveKey={key}
      >
        {buildList(props.tabData)}
      </Tabs>
    </div>
  );
}

SearchTab.propTypes = {
  tabData: PropTypes.array.isRequired,
  filterButtonAction: PropTypes.func.isRequired,
  searchHelperText: PropTypes.string,
  filterButtons: PropTypes.array,
  timelineFilterButtonAction: PropTypes.func,
  showTimeline: PropTypes.bool,
  dateStartFilter: PropTypes.string,
  dateEndFilter: PropTypes.string,
  handleSearchChange: PropTypes.func,
  handleAdvancedFilterChange: PropTypes.func,
  handleOnSelect: PropTypes.func,
  eventKey: PropTypes.string,
  handleClearAll: PropTypes.func,
  showSearchTab: PropTypes.bool,
  selectedMultiSelectFilters: PropTypes.array,
  useDropdownPanel: PropTypes.bool,
  filterDropdowns: PropTypes.array,
  itemsLoading: PropTypes.bool,
  showFilter: PropTypes.bool,
};

SearchTab.defaultProps = {
  tabData: [],
  filterButtons: [],
  showFilter: true,
  useDropdownPanel: false,
  itemsLoading: false,
};
