import React from 'react';
import PropTypes from 'prop-types';
import TagButton from '../TagButton/TagButton';
import {isEmpty} from '../../../utils/helper';

// Tag list can be any object array that contains the tagName field
export default function TagList({
  tags = [{tagName: 'Tag Name'}],
  removeAction,
  isMobile,
  containerStyle,
}) {
  const handleTagRemove = (tag) => {
    if (removeAction) {
      removeAction(tag);
    }
  };

  return (
    <div className="d-flex flex-wrap">
      {isEmpty(tags) === false &&
        tags.map((x) => {
          return (
            <div className="d-flex flex-wrap" key={x.tagName}>
              <TagButton
                tag={x}
                removeAction={(y) => handleTagRemove(y)}
                isMobile={isMobile}
                containerStyle={containerStyle}
              />
            </div>
          );
        })}
    </div>
  );
}

TagList.propTypes = {
  tags: PropTypes.array.isRequired,
  removeAction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  containerStyle: PropTypes.object,
};
