import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import classNames from 'classnames';

import * as employersActions from 'src/actions/employers';
import * as teamsActions from 'src/actions/teams';
import * as jobsActions from 'src/actions/jobs';
import * as seekersActions from 'src/actions/seekers';
import * as invitationsActions from 'src/actions/invitations';

import {AscendModal, AscendModalBody} from 'src/layout';
import InviteToApplyJobSelect from 'src/components/InviteToApplyJobSelect';
import {BusyButton} from './BusyButton';
import {
  createInvitation,
  createMultipleInvitations,
} from 'src/services/invitationApi';
import {addFlashMessage} from 'src/actions/flash';
import Cardinator from 'src/pages/teamProfile/Cardinator';
import {track as analyticsTrack} from 'src/utils/analytics';

import {
  getSortedLookup,
  getSortedLookupWithPublishedJobCount,
} from 'src/utils/fieldHelper';
import loadingGif from '../assets/images/loading.gif';

const Loading = ({visible}) => {
  if (visible) {
    return (
      <div
        className="text-align-center"
        style={{postion: 'relative', width: '100%', height: '300px'}}
      >
        <img
          src={loadingGif}
          style={{
            position: 'relative',
            width: '200px',
            height: '200px',
          }}
          alt="Loading..."
        />
        <p>Loading...</p>
      </div>
    );
  } else {
    return null;
  }
};

Loading.propTypes = {
  visible: PropTypes.bool.isRequired,
};

const ChoicePrompt = ({visible}) => {
  if (visible) {
    return (
      <div
        className="text-align-center"
        style={{postion: 'relative', width: '100%', height: '300px'}}
      >
        <div className="secondary-title padding-bottom-twentyfour">
          Choose an employer and a team with roles.
        </div>
      </div>
    );
  } else {
    return null;
  }
};

ChoicePrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
};

const SelectedJobs = ({jobs}) => {
  if (jobs.length > 0) {
    return (
      <div className="text-align-center">
        <div className="secondary-title padding-bottom-twentyfour">
          Selected roles:
        </div>

        {jobs.map((job) => {
          // Is this a match record or an actual job record?
          if (job.id) {
            return (
              <div className="normal-body" key={job.id}>
                {job.team.name} - {job.name}
              </div>
            );
          } else {
            return (
              <div className="normal-body" key={job.id}>
                {job.teamName} - {job.jobName}
              </div>
            );
          }
        })}
        <div className="padding-bottom-thirtytwo">
          <div className="divider" />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

SelectedJobs.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const TeamHasNoJobsWarning = ({visible, teamName}) => {
  if (visible) {
    return (
      <div
        className="text-align-center"
        style={{postion: 'relative', width: '100%', height: '300px'}}
      >
        <div className="secondary-title padding-bottom-twentyfour">
          {`${teamName} team has no roles.`}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

TeamHasNoJobsWarning.propTypes = {
  visible: PropTypes.bool.isRequired,
  teamName: PropTypes.string.isRequired,
};

const SeekerHasNoMatchesWarning = ({visible, seekerName}) => {
  if (visible) {
    return (
      <div
        className="text-align-center"
        style={{postion: 'relative', width: '100%', height: '300px'}}
      >
        <div className="secondary-title padding-bottom-twentyfour">
          {`${seekerName} has no Overall Fit matches.`}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

SeekerHasNoMatchesWarning.propTypes = {
  visible: PropTypes.bool.isRequired,
  seekerName: PropTypes.string.isRequired,
};

class InviteToApplyModalForAscend extends React.Component {
  state = {
    selectedEmployerId: null,
    selectedTeamId: null,
    matchesOrRoles: this.props.isMultiple ? 'roles' : 'matches',
    checkedJobs: {},
    selectedJobs: [],
    teams: [],
    currentPage: 0,
    itemsPerPage: 10,
    isSending: false,
  };

  componentDidMount() {
    if (this.props.employers.length === 0 && !this.props.employersLoading) {
      this.props.getAllEmployers();
    }
    if (this.props.seeker) {
      this.props.getSeekerJobMatches(
        this.props.seeker.id,
        this.state.currentPage,
        this.state.itemsPerPage,
        '-overallscore',
        {overallFit: true}
      );
    }
    if (this.props.preselectedJobId) {
      this.setState({
        checkedJobs: {
          ...this.state.checkedJobs,
          [this.props.preselectedJobId]: true,
        },
      });
      this.props.getJob(this.props.preselectedJobId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        checkedJobs: {},
        selectedJobs: [],
        teams: [],
      });
    } else if (
      this.props.preselectedJobId &&
      this.state.matchesOrRoles === 'matches' &&
      this.props.preselectedJob?.id === this.props.preselectedJobId &&
      this.props.preselectedJob !== prevProps.preselectedJob
    ) {
      this.setState((oldState) => {
        const newState = {
          checkedJobs: {
            ...oldState.checkedJobs,
            [this.props.preselectedJobId]: true,
          },
          selectedJobs: [...oldState.selectedJobs, this.props.preselectedJob],
        };
        return newState;
      });
    }
  }

  handleEmployerChange = (selectedOption) => {
    this.props.getTeamsByEmployer(selectedOption['value']);
    this.setState({
      selectedEmployerId: selectedOption['value'],
      selectedTeamId: null,
    });
  };

  handleTeamChange = (selectedOption) => {
    this.props.getJobsByTeam(selectedOption['value']);
    this.setState({selectedTeamId: selectedOption['value']});
  };

  handleChecked = (jobState) => {
    this.setState((oldState) => {
      const newState = {
        checkedJobs: {
          ...oldState.checkedJobs,
          ...jobState,
        },
      };

      _.forEach(jobState, (value, key) => {
        if (value) {
          const job =
            this.state.matchesOrRoles === 'roles'
              ? _.find(this.props.roleJobs, {id: parseInt(key, 10)})
              : _.find(this.props.jobs, {id: parseInt(key, 10)});
          newState.selectedJobs = [...oldState.selectedJobs, job];
        } else {
          newState.selectedJobs = _.filter(oldState.selectedJobs, (job) => {
            return job.id !== parseInt(key, 10);
          });
        }
      });
      return newState;
    });
  };

  handleMatchChecked = (jobState) => {
    this.setState((oldState) => {
      const newState = {
        checkedJobs: {
          ...oldState.checkedJobs,
          ...jobState,
        },
      };

      _.forEach(jobState, (value, key) => {
        if (value) {
          const jobId = parseInt(key, 10);

          if (jobId === this.props.preselectedJobId) {
            newState.selectedJobs = [
              ...oldState.selectedJobs,
              this.props.preselectedJob,
            ];
          } else {
            const job =
              this.state.matchesOrRoles === 'roles'
                ? _.find(this.props.roleJobs, {id: jobId})
                : _.find(this.props.jobs, {id: jobId});

            newState.selectedJobs = [...oldState.selectedJobs, job];
          }
        } else {
          newState.selectedJobs = _.filter(oldState.selectedJobs, (job) => {
            return job.id !== parseInt(key, 10);
          });
        }
      });
      return newState;
    });
  };

  selectedJobIds = () => {
    return Object.keys(this.state.checkedJobs)
      .filter((x) => {
        return this.state.checkedJobs[x];
      })
      .map((x) => parseInt(x, 10));
  };

  trackInvitation = (seekerId, job) => {
    analyticsTrack({
      eventName: 'InvitedToApply_Admin',
      seekerId: seekerId,
      jobEmploymentType: job.employmentTypes.map((x) => x.name).join(','),
    });
  };

  handleSendClicked = async () => {
    const selected = this.selectedJobIds();
    if (selected.length === 0) {
      return;
    }
    const data = {
      userId: this.props.userId,
      seekerId: this.props.seeker.id,
      jobIds: selected,
    };
    try {
      await createInvitation(data);

      this.props.updateSeekerInviteList(
        [this.props.seeker],
        this.state.selectedJobs
      );

      this.state.selectedJobs.forEach((job) => {
        this.trackInvitation(this.props.seeker.id, job);
      });

      this.props.addFlashMessage('Invite sent!', 'notification');
    } catch (error) {
      this.props.addFlashMessage('Error sending invite.', 'error');
      throw error;
    }
    this.props.onSendClicked();
  };

  handleSendMultiple = () => {
    if (this.selectedJobIds().length === 0) {
      return;
    }
    this.setState({isSending: true});
    const invitations = {
      userId: this.props.userId,
      seekerIds: this.props.batchActionList,
      jobIds: this.selectedJobIds(),
    };

    createMultipleInvitations(invitations).then((x) => {
      this.props.updateSeekerInviteList(
        this.props.batchActionList.map((x) => ({
          id: x,
          jobsInvitedToApplyFor: [],
        })),
        this.state.selectedJobs,
        x.data.duplicateInvitations
      );
      if (x.data.duplicateInvitations.length === 0) {
        let msg = 'Invitations sent!';
        if (invitations.seekerIds.length === 1) {
          msg = 'Invitation sent!';
        }

        invitations.seekerIds.forEach((seekerId) => {
          this.state.selectedJobs.forEach((job) => {
            this.trackInvitation(seekerId, job);
          });
        });

        this.props.addFlashMessage(msg, 'notification');
      }
      this.setState({isSending: false});
      this.props.onSendClicked(x.data.duplicateInvitations);
    });
  };

  buttonChange = (matchesOrRoles) => {
    if (matchesOrRoles === 'matches') {
      this.setState({matchesOrRoles: 'matches'});
    } else if (matchesOrRoles === 'roles') {
      this.setState({matchesOrRoles: 'roles'});
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  checkAlreadyApplied = (jobId) => {
    if (this.props.seeker && !this.props.seeker.appliedJobs) {
      return false;
    } else if (
      this.props.seeker &&
      _.find(this.props.seeker.appliedJobs, ['id', jobId])
    ) {
      return true;
    } else {
      return false;
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({currentPage: pageNumber});

    if (this.props.seeker) {
      this.props.getSeekerJobMatches(
        this.props.seeker.id,
        pageNumber,
        this.state.itemsPerPage,
        '-overallscore',
        {overallFit: true}
      );
    }
  };

  buildSeekerJobCard = (match) => {
    const {seeker, invitationMatches} = this.props;

    const existingInvite = seeker
      ? _.find(invitationMatches, ['jobId', match.id])
      : null;

    return (
      <div key={match.id}>
        <InviteToApplyJobSelect
          key={match.id}
          jobId={match.id}
          jobName={match.name}
          location={match.location}
          applied={this.checkAlreadyApplied(match.id)}
          teamName={match.team.name}
          employerName={match.employer.name}
          onChange={this.handleMatchChecked}
          invitationDate={existingInvite ? existingInvite.invitedOn : null}
          selected={!!this.state.checkedJobs[match.id]}
          job={match}
        />
      </div>
    );
  };

  renderPreselectedJob = () => {
    if (this.props.preselectedJob?.id && this.state.currentPage === 0) {
      return (
        <div className="padding-bottom-twentyfour">
          {this.buildSeekerJobCard(this.props.preselectedJob)}
        </div>
      );
    }
  };

  render() {
    const {
      isOpen,
      employers,
      employersLoading,
      teams,
      teamsLoading,
      seeker,
      jobs,
      roleJobs,
      jobsLoading,
      jobMatchesLoading,
      invitationMatches,
      seekerLoading,
    } = this.props;

    const selectedTeamName = this.state.selectedTeam
      ? this.state.selectedTeam.name
      : '';

    const sortedEmployers = getSortedLookup(employers, ['name']);

    const sortedTeams = getSortedLookupWithPublishedJobCount(teams, ['name']);

    const multipleVerbage =
      this.props.isMultiple || seeker === null
        ? `${this.props.batchActionList.length} candidates`
        : `${seeker.name}`;

    return (
      <AscendModal isOpen={isOpen} onClose={this.handleClose}>
        <AscendModalBody hasClose={true}>
          <div className="padding-32">
            <div className="normal-headline text-align-center">
              Invite to Apply
            </div>
            {this.props.isMultiple === false && (
              <center>
                <div
                  className="btn-group padding-top-sixteen padding-bottom-sixteen"
                  role="group"
                >
                  <button
                    type="button"
                    className={classNames(
                      'clickable',
                      'btn',
                      {'btn-white': this.state.matchesOrRoles !== 'matches'},
                      {
                        'btn-lightblue':
                          this.state.matchesOrRoles === 'matches',
                      }
                    )}
                    onClick={() => this.buttonChange('matches')}
                  >
                    Matches
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'clickable',
                      'btn',
                      {'btn-white': this.state.matchesOrRoles !== 'roles'},
                      {'btn-lightblue': this.state.matchesOrRoles === 'roles'}
                    )}
                    onClick={() => this.buttonChange('roles')}
                  >
                    All Roles
                  </button>
                </div>
              </center>
            )}
            {this.props.isMultiple === true && <br />}
            {this.state.matchesOrRoles === 'roles' && (
              <div className="container">
                <div className="row">
                  <div className="col-md-6 middle padding-bottom-eight">
                    <div
                      className="secondary-body"
                      style={{lineHeight: '36px'}}
                      id="employerLabel"
                    >
                      EMPLOYER:
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="clickable normal-subheader"
                      value={
                        this.state.selectedEmployerId
                          ? _.find(sortedEmployers, {
                              value: this.state.selectedEmployerId,
                            })
                          : null
                      }
                      onChange={this.handleEmployerChange}
                      options={sortedEmployers}
                      isDisabled={employersLoading}
                      isLoading={employersLoading}
                      aria-labelledby="employerLabel"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 middle padding-bottom-eight">
                    <div
                      className="secondary-body"
                      style={{lineHeight: '36px'}}
                      id="teamLabel"
                    >
                      TEAM:
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="clickable normal-subheader"
                      value={
                        this.state.selectedTeamId
                          ? _.find(sortedTeams, {
                              value: this.state.selectedTeamId,
                            })
                          : null
                      }
                      onChange={this.handleTeamChange}
                      options={sortedTeams}
                      isDisabled={!this.state.selectedEmployerId}
                      isLoading={teamsLoading}
                      aria-labelledby="teamLabel"
                    />
                  </div>
                </div>
              </div>
            )}

            <br />

            {!jobMatchesLoading && (
              <SelectedJobs jobs={this.state.selectedJobs} />
            )}

            {this.state.matchesOrRoles === 'matches' && jobs && (
              <div>
                <span role="status">
                  <Loading visible={jobMatchesLoading || seekerLoading} />
                </span>

                <SeekerHasNoMatchesWarning
                  visible={jobs?.length === 0 && !jobMatchesLoading}
                  seekerName={multipleVerbage}
                />

                {seekerLoading === false &&
                  seeker &&
                  jobs?.length > 0 &&
                  !jobMatchesLoading && (
                    <div>
                      <div className="text-align-center secondary-body padding-bottom-twentyfour">
                        Choose the role(s) you would like to invite{' '}
                        {multipleVerbage} to apply for.
                      </div>
                      <div className="padding-bottom-thirtytwo">
                        <div className="divider" />
                      </div>
                      {this.renderPreselectedJob()}

                      <Cardinator
                        jobs={jobs}
                        currentPage={this.state.currentPage}
                        itemsPerPage={this.state.itemsPerPage}
                        totalItems={seeker.baseOverallMatchCount}
                        itemsLoading={this.props.jobMatchesLoading}
                        handlePageChange={this.handlePageChange}
                        cardObject={this.buildSeekerJobCard}
                        checkedJobs={this.state.checkedJobs}
                      />
                    </div>
                  )}
              </div>
            )}

            {this.state.matchesOrRoles === 'roles' && (
              <div>
                <span role="status">
                  <Loading visible={jobsLoading || seekerLoading} />
                </span>

                <ChoicePrompt
                  visible={
                    roleJobs.length === 0 &&
                    !jobsLoading &&
                    !seekerLoading &&
                    this.state.selectedTeamId === null
                  }
                />

                <TeamHasNoJobsWarning
                  visible={
                    roleJobs &&
                    roleJobs.length === 0 &&
                    !jobsLoading &&
                    !seekerLoading &&
                    this.state.selectedTeamId !== null
                  }
                  teamName={selectedTeamName}
                />

                {this.state.selectedTeamId &&
                  roleJobs.length !== 0 &&
                  !jobsLoading &&
                  !seekerLoading && (
                    <div>
                      <div className="text-align-center secondary-body padding-bottom-twentyfour">
                        Choose the role(s) you would like to invite{' '}
                        {multipleVerbage} to apply for.
                      </div>
                      <div className="padding-bottom-thirtytwo">
                        <div className="divider" />
                      </div>

                      {roleJobs.map((job) => {
                        const existingInvite = seeker
                          ? _.find(invitationMatches, ['jobId', job.id])
                          : null;

                        return (
                          <InviteToApplyJobSelect
                            key={job.id}
                            jobId={job.id}
                            jobName={job.name}
                            location={job.location}
                            applied={this.checkAlreadyApplied(job.id)}
                            teamName={job.team.name}
                            employerName={job.employer.name}
                            onChange={this.handleChecked}
                            invitationDate={
                              existingInvite ? existingInvite.invitedOn : null
                            }
                            selected={!!this.state.checkedJobs[job.id]}
                            job={job}
                          />
                        );
                      })}
                    </div>
                  )}
              </div>
            )}

            <div
              className="padding-top-sixteen padding-bottom-sixteen"
              style={{margin: '0px auto'}}
            >
              <BusyButton
                onClick={() => {
                  this.props.isMultiple
                    ? this.handleSendMultiple()
                    : this.handleSendClicked();
                }}
                buttonText={
                  this.props.isMultiple
                    ? `Send ${this.props.batchActionList.length} Invites`
                    : 'Send Invite'
                }
                busyText={'Sending'}
                style={{width: '210px', height: '40px'}}
                float={'float-right'}
                buttonIcon={'save'}
                alignText={'text-center'}
                disabled={
                  this.selectedJobIds().length < 1 ||
                  jobMatchesLoading ||
                  this.state.isSending
                }
              />
            </div>
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

InviteToApplyModalForAscend.propTypes = {
  isOpen: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  employers: PropTypes.arrayOf(PropTypes.object),
  teams: PropTypes.arrayOf(PropTypes.object),
  jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
  roleJobs: PropTypes.arrayOf(PropTypes.object).isRequired,
  jobsLoading: PropTypes.bool,
  onSendClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  createInvitation: PropTypes.func.isRequired,
  createMultipleInvitations: PropTypes.func.isRequired,
  getAllEmployers: PropTypes.func.isRequired,
  getTeamsByEmployer: PropTypes.func.isRequired,
  getJobsByTeam: PropTypes.func.isRequired,
  employersLoading: PropTypes.bool,
  teamsLoading: PropTypes.bool,
  seeker: PropTypes.object,
  addFlashMessage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  getSeekerJobMatches: PropTypes.func.isRequired,
  preselectedJobId: PropTypes.number,
  getJob: PropTypes.func.isRequired,
  preselectedJob: PropTypes.object,
  jobMatchesLoading: PropTypes.bool,
  batchActionList: PropTypes.array,
  isMultiple: PropTypes.bool,
  updateSeekerInviteList: PropTypes.func,
  invitationMatches: PropTypes.array,
  seekerLoading: PropTypes.bool,
  tracking: PropTypes.object,
};

InviteToApplyModalForAscend.defaultProps = {
  seeker: null,
  batchActionList: [],
  isMultiple: false,
  invitationMatches: [],
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.profile.id,
    employers: state.employers.allEmployers,
    employersLoading: state.employers.employersLoading,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
    jobsLoading: state.jobs.jobsLoading,
    jobMatchesLoading: state.seekers.jobMatchesLoading,
    itemsPerPage: state.jobs.itemsPerPage,
    jobs: state.seekers.jobMatches,
    roleJobs: state.jobs.jobs,
    preselectedJob: ownProps.preselectedJobId ? state.jobs.job : {},
    invitationMatches: state.seekers.invitationResponse.invitationMatches,
    seekerLoading: state.seekers.seekerLoading,
  };
};

const mapDispatchToProps = {
  ...employersActions,
  ...teamsActions,
  ...jobsActions,
  ...invitationsActions,
  ...seekersActions,
  addFlashMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteToApplyModalForAscend);
