import React from 'react';
import PropTypes from 'prop-types';
import styles from './RoleCard.module.scss';

class RoleCard extends React.Component {
  render() {
    const renderJobCard = (number, text, clickHandler, selected, svg) => {
      const Svg = svg;
      const ariaText = `Show table with ${number} ${text}`;
      return (
        <button
          tabIndex={0}
          aria-label={ariaText}
          className={`${
            selected ? 'bd-bluebackground' : 'bd-darkgraybackground'
          } ${styles.metric_button} col-lg-auto`}
          onClick={() => clickHandler()}
        >
          <div className="align-items-center">
            <span
              className={`col ${styles.normal_metric} ${
                selected && `${styles.selected_metric}`
              }`}
            >
              {svg && (
                <Svg
                  className={`ms-1 me-1 ${
                    selected
                      ? 'invitation_svg_color_stroke_white'
                      : 'invitation_svg_color_stroke_gray'
                  }`}
                />
              )}

              <span className="ms-0">{number}</span>
            </span>
            <span
              className={
                selected
                  ? 'justify-content-start secondary-subheader-white bold p-0'
                  : `justify-content-start rolecard-subheader p-0`
              }
            >
              {text}
            </span>
          </div>
        </button>
      );
    };

    return renderJobCard(
      this.props.cardNumber,
      this.props.cardText,
      this.props.cardOnClick,
      this.props.cardSelected,
      this.props.svg
    );
  }
}

RoleCard.propTypes = {
  cardNumber: PropTypes.number,
  cardText: PropTypes.string.isRequired,
  cardOnClick: PropTypes.func.isRequired,
  cardSelected: PropTypes.bool.isRequired,
  svg: PropTypes.any,
};

export default RoleCard;
