export const regionalCityList = () => {
  return {
    1: 'Hammond, Gary, Portage, Valparaiso, Michigan City, Crown Point, Hobart',
    2: 'South Bend, Elkhart, Mishawaka, Goshen, Warsaw',
    3: 'Fort Wayne, Marion, Huntington, Angola',
    4: 'Lafayette, Kokomo, West Lafayette, Logansport',
    5: 'Carmel, Fishers, Zionsville, Greenwood, Anderson, Greenfield, Avon, Shelbyville, Plainfield',
    6: 'Muncie, Richmond, New Castle',
    7: 'Terre Haute, Greencastle, Brazil',
    8: 'Bloomington, Bedford, Washington',
    9: 'Columbus, Greensburg, Madison, Batesville',
    10: 'Jeffersonville, New Albany, Salem',
    11: 'Evansville, Vincennes, Jasper',
    12: 'Indianapolis',
  };
};
