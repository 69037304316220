import * as types from '../actions/ActionTypes';
import {filter, findIndex} from 'lodash';

const initialState = {
  recruiters: [],
  recruiter: {},
  recruitersLoading: false,
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  savedSearches: [],
};

export default function recruiters(state = initialState, action) {
  switch (action.type) {
    case types.RECRUITERS__GET_REQUEST:
      return {...state, recruiter: {}, recruitersLoading: true};
    case types.RECRUITERS__GET_ALL_REQUEST:
      return {...state, recruiters: {}};
    case types.RECRUITERS__GET_FAILURE:
      return {
        ...state,
        recruitersLoading: false,
      };
    case types.RECRUITERS__GET_SUCCESS:
      return {
        ...state,
        recruiter: action.response.body,
        recruitersLoading: false,
      };
    case types.RECRUITERS__GET_ALL_SUCCESS:
      return {...state, recruiters: action.response.body};
    case types.RECRUITERS__GET_BY_COMPANY_SUCCESS:
      return {...state, recruiters: action.response.body.recruiters};
    case types.RECRUITERS__GET_BY_COMPANY_ADVANCED_REQUEST:
      return {
        ...state,
        recruiters: [],
        recruitersLoading: true,
      };
    case types.RECRUITERS__GET_BY_COMPANY_ADVANCED_SUCCESS:
      return {
        ...state,
        recruiters: action.response.body.recruiters,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        recruitersLoading: false,
      };
    case types.RECRUITERS__DELETE_SUCCESS:
      return {
        ...state,
        recruiters: filter(state.recruiters, (recruiter) => {
          return recruiter.id !== action.payload.recruiterId;
        }),
      };
    case types.RECRUITERS__CREATE_SUCCESS:
      return {
        ...state,
        recruiters: [...state.recruiters, action.response.body],
      };
    case types.RECRUITERS__UPDATE_SUCCESS:
      const index = findIndex(state.recruiters, (recruiter) => {
        return recruiter.id === action.payload.recruiter.id;
      });
      return {
        ...state,
        recruiters: [
          ...state.recruiters.slice(0, index),
          action.response.body,
          ...state.recruiters.slice(index + 1),
        ],
      };
    case types.RECRUITERS__CREATE_SAVED_SEARCHES_REQUEST:
      return {...state, recruitersLoading: true};
    case types.RECRUITERS__CREATE_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        savedSearches: action.response.body.savedSearches,
        recruitersLoading: false,
      };
    case types.RECRUITERS__CREATE_SAVED_SEARCHES_FAILURE:
      return {
        ...state,
        recruitersLoading: false,
      };
    case types.RECRUITERS__GET_SAVED_SEARCHES_REQUEST:
      return {...state, savedSearches: [], recruitersLoading: true};
    case types.RECRUITERS__GET_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        savedSearches: action.response.body.savedSearches,
        recruitersLoading: false,
      };
    case types.RECRUITERS__GET_SAVED_SEARCHES_FAILURE:
      return {
        ...state,
        recruitersLoading: false,
      };
    case types.RECRUITERS__DELETE_SAVED_SEARCHES_REQUEST:
      return {...state, recruitersLoading: true};
    case types.RECRUITERS__DELETE_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        savedSearches: action.response.body.savedSearches,
        recruitersLoading: false,
      };
    case types.RECRUITERS__DELETE_SAVED_SEARCHES_FAILURE:
      return {
        ...state,
        recruitersLoading: false,
      };
    case types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_REQUEST:
      return {
        ...state,
        recruitersLoading: true,
      };
    case types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        recruitersLoading: false,
        recruiter: action.recruiter ?? action.response.body,
      };
    case types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_RESET:
      return {...state, recruiter: {}};

    default:
      return state;
  }
}
