import React from 'react';
import './MenuSkipToMain.css';

class MenuSkipToMain extends React.Component {
  render() {
    return (
      <button
        className="skip-main"
        onClick={() => document.getElementById('main').focus()}
      >
        Skip to main content
      </button>
    );
  }
}

export default MenuSkipToMain;
