import React from 'react';
import PropTypes from 'prop-types';
import TagList from 'src/components/tags/TagList/TagList';

import explorerStyles from './UniversityExplorerPage.module.scss';

export default function UniversityExplorerFilterTags({
  appliedFilters,
  appliedGroups,
  handleFilterTagRemove,
  handleGroupTagRemove,
}) {
  const getFilterTags = (fieldname) => {
    const filterValues = appliedFilters[fieldname];
    const filterTags = filterValues.map((x) => {
      return {
        tagName: fieldname === 'graduationDateValue' ? x.label : x.value,
        name: fieldname,
      };
    });

    return filterTags;
  };

  let groupTagsToShow = appliedGroups.map((x) => {
    return {
      tagName: x.display,
      name: x.value,
    };
  });

  return (
    <div
      className={`column ${explorerStyles.universityExplorerFilterTagContainer}`}
    >
      {Object.values(appliedFilters).some((x) => x.length > 0) && (
        <div className="pb-12">
          <h3 className={`${explorerStyles.universityExplorerFilterTagHeader}`}>
            Applied Filters:
          </h3>

          {appliedFilters['school'].length > 0 && (
            <div className={`${explorerStyles.universityExplorerFilterTagRow}`}>
              <div
                className={`${explorerStyles.universityExplorerFilterTagRowTitle}`}
              >
                College or University
              </div>
              <TagList
                key={'collegeFilters'}
                tags={getFilterTags('school')}
                removeAction={handleFilterTagRemove}
                isMobile={false}
                containerStyle={{
                  borderColor: 'black',
                  backgroundColor: 'var(--neutral-gray-6)',
                }}
              ></TagList>
            </div>
          )}

          {appliedFilters['graduationDateValue'].length > 0 && (
            <div className={`${explorerStyles.universityExplorerFilterTagRow}`}>
              <div
                className={`${explorerStyles.universityExplorerFilterTagRowTitle}`}
              >
                Graduation Date
              </div>
              <TagList
                key={'graduationDateFilters'}
                tags={getFilterTags('graduationDateValue')}
                removeAction={handleFilterTagRemove}
                isMobile={false}
                containerStyle={{
                  borderColor: 'black',
                  backgroundColor: 'var(--neutral-gray-6)',
                }}
              ></TagList>
            </div>
          )}

          {appliedFilters['major'].length > 0 && (
            <div className={`${explorerStyles.universityExplorerFilterTagRow}`}>
              <div
                className={`${explorerStyles.universityExplorerFilterTagRowTitle}`}
              >
                Major
              </div>
              <TagList
                key={'majorFilters'}
                tags={getFilterTags('major')}
                removeAction={handleFilterTagRemove}
                isMobile={false}
                containerStyle={{
                  borderColor: 'black',
                  backgroundColor: 'var(--neutral-gray-6)',
                }}
              ></TagList>
            </div>
          )}

          {appliedFilters['gpa'].length > 0 && (
            <div className={`${explorerStyles.universityExplorerFilterTagRow}`}>
              <div
                className={`${explorerStyles.universityExplorerFilterTagRowTitle}`}
              >
                GPA
              </div>
              <TagList
                key={'gpaFilters'}
                tags={getFilterTags('gpa')}
                removeAction={handleFilterTagRemove}
                isMobile={false}
                containerStyle={{
                  borderColor: 'black',
                  backgroundColor: 'var(--neutral-gray-6)',
                }}
              ></TagList>
            </div>
          )}

          {appliedFilters['isUnderrepresented'].length > 0 && (
            <div className={`${explorerStyles.universityExplorerFilterTagRow}`}>
              <div
                className={`${explorerStyles.universityExplorerFilterTagRowTitle}`}
              >
                Underrepresented Students
              </div>
              <TagList
                key={'underrepresentedStudentFilters'}
                tags={getFilterTags('isUnderrepresented')}
                removeAction={handleFilterTagRemove}
                isMobile={false}
                containerStyle={{
                  borderColor: 'black',
                  backgroundColor: 'var(--neutral-gray-6)',
                }}
              ></TagList>
            </div>
          )}
        </div>
      )}

      {appliedGroups.length > 0 && (
        <div className={`${explorerStyles.universityExplorerGroupRow} `}>
          <h3
            className={`${explorerStyles.universityExplorerFilterTagHeader} ${explorerStyles.universityExplorerFilterTagRowTitle}`}
          >
            Breakdown:
          </h3>
          <TagList
            key={'appliedGroups'}
            tags={groupTagsToShow}
            removeAction={handleGroupTagRemove}
            isMobile={false}
            containerStyle={{
              borderColor: 'black',
              backgroundColor: 'var(--neutral-gray-6)',
            }}
          ></TagList>
        </div>
      )}
    </div>
  );
}

UniversityExplorerFilterTags.propTypes = {
  appliedGroups: PropTypes.arrayOf(PropTypes.object),
  appliedFilters: PropTypes.object,
  handleFilterTagRemove: PropTypes.func,
  handleGroupTagRemove: PropTypes.func,
};
