import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useReward} from 'react-rewards';
import {useDispatch} from 'react-redux';
import {push} from 'redux-first-history';
import {
  patchAppliedToResponse,
  patchUndoAppliedToResponse,
} from 'src/actions/jobs';
import {track} from 'src/utils/analytics';

export default function DidYouApplyCard({job, handleDismiss, handleJobClick}) {
  const dispatch = useDispatch();
  const {reward, isAnimating} = useReward(`reward${job.id}`, 'confetti', {
    startVelocity: 10,
  });
  const [response, setResponse] = useState(null);

  const handleResponseClick = (response) => {
    track({
      eventName: `DidYouApply_${response.toLocaleUpperCase()}`,
      jobId: job.id,
    });
    setResponse(response);
    if (response === 'yes') {
      dispatch(patchAppliedToResponse(job.id, {applied: true}, true));
      reward();
    } else {
      dispatch(patchAppliedToResponse(job.id, {applied: false}));
    }
  };

  const handleUndo = () => {
    dispatch(patchUndoAppliedToResponse(job.id, response === 'yes'));
    setResponse(null);
  };

  const handleEmployerClick = () => {
    dispatch(push('/employer/' + job.employerId));
  };

  return (
    <>
      <div
        className={`container bd-whitebackground pt-1 ps-3 pe-2 pb-1`}
        style={{height: '133px'}}
      >
        <span
          style={{
            position: 'relative',
            top: '90px',
            left: '28%',
            background: 'red',
          }}
          id={`reward${job.id}`}
        />
        <div className="row">
          <button
            className={`buttonAsLinkTeamCard long-and-truncated col-12`}
            style={{paddingLeft: '15px', paddingRight: '15px'}}
            onClick={() => handleJobClick(job.jobId)}
          >
            <h3
              className="normal-subheader accessibleLink bold mt-1 mb-0 pe-1"
              style={{textOverflow: 'ellipsis', overflow: 'hidden'}}
            >
              {job.jobName}
            </h3>
          </button>
        </div>

        <button
          className={`buttonAsLinkTeamCard secondary-caption long-and-truncated`}
          style={{paddingBottom: '16px', width: 'inherit'}}
          onClick={handleEmployerClick}
        >
          {job.employerName}
        </button>

        {!response && (
          <>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-evenly',
                paddingBottom: '8px',
              }}
            >
              <button
                type="button"
                className="clickable btn btn-primary col-4"
                disabled={isAnimating}
                onClick={() => handleResponseClick('yes')}
              >
                Yes
              </button>

              <button
                className="clickable btn btn-secondary col-4"
                onClick={() => handleResponseClick('no')}
              >
                No
              </button>
            </div>
          </>
        )}

        {response && (
          <>
            {response === 'yes' && (
              <div
                className="bold"
                style={{fontSize: '10px', textAlign: 'center'}}
              >
                High five! Keep up the great work.
              </div>
            )}
            {response === 'no' && (
              <div
                className="bold"
                style={{fontSize: '12px', textAlign: 'center'}}
              >
                Thanks for letting us know.
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              }}
            >
              <button
                className="buttonAsLink"
                style={{fontSize: '12px'}}
                onClick={() => handleUndo()}
              >
                Undo
              </button>
              <button
                className="buttonAsLink"
                style={{fontSize: '12px'}}
                onClick={handleDismiss}
              >
                Dismiss
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

DidYouApplyCard.propTypes = {
  job: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func,
  handleJobClick: PropTypes.func,
};
