import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import 'url-search-params-polyfill';

import * as seekersActions from '../../actions/seekers';
import * as jobsActions from '../../actions/jobs';
import * as teamsActions from '../../actions/teams';

import * as employmentTypeActions from '../../actions/employmentTypes';
import * as regionsActions from '../../actions/regions';
import * as roleTypesActions from '../../actions/roleTypes';
import * as industriesActions from '../../actions/industries';
import * as searchActions from '../../actions/AscendDataTableAction';

import * as degreeTypeRankActions from '../../actions/DegreeTypeActions';
import * as segmentActions from '../../actions/SegmentActions';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';

import {getExperienceLevels} from '../../actions/experienceLevels';
import InviteToApplyModal from '../../components/InviteToApplyModal';

import InviteToApplyModalForAscend from '../../components/InviteToApplyModalForAscend';

import AreYouSureModal from '../../components/AreYouSureModal';

import TeamBenchModal from '../candidateProfile/TeamBenchModal';
import {ResumeSlideOutModal} from '../../components/ResumeSlideOutModal';
import {getSeekerResumeAction} from '../../actions/SeekerActions';
import InputSearch from '../../components/controls/inputs/InputSearch';

import {CandidateDiscoveryFilters} from './CandidateDiscoveryFilters';
import {getMajors} from 'src/actions/institutions';
import {getAllSkillTags} from 'src/actions/skillTags';
import styles from '../../components/controls/filters/FilterPanel.module.scss';

import Select from 'react-select';
import * as candidateDiscoveryActions from '../../actions/candidateDiscoveryActions';
import PageControl from '../../components/PageControl';

import CandidateCard from '../../components/CandidateCards/CandidateCard';

import ListBatchActionDrawer from '../../components/Candidate/ListBatchActionDrawer';

import {AsyncPaginate} from 'react-select-async-paginate';
import discoveryStyles from './CandidateDiscoveryPage.module.scss';

import {makeRequest} from 'src/services/api';
import FilterListTags from './FilterListTags';
import {CANDIDATE_DISCOVERY} from './candidateDiscoveryGlobals';
import DeletableSelect from 'src/components/selectors/DeletableSelector/DeletableSelector';
import {getSavedSearches} from 'src/actions/recruiters';
import InvitesSentModal from 'src/components/InvitesSentModal';
import ContentLoader from 'react-content-loader';

class ExploreCandidates extends React.Component {
  constructor() {
    super();
    this.batchSelectMaximumCount = 100;
  }

  _isMounted = false;

  state = {
    employerRecruiterList: [],
    currentSeeker: null,
    teamBenchModalIsOpen: false,
    showResumeModal: false,
    isWatched: false,
    pageChanged: false,
    resumeUrlData: {
      fileExtension: '',
      resumeEmbedUrl: '',
      resumePdfUrl: '',
    },
    batchActionList: [],
    confirmBatchInviteRequired: false,
    duplicateInvitations: [],
    isMinimumWidth: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentSeeker !== null &&
      prevState.currentSeeker !== this.state.currentSeeker
    ) {
      const watched = this.findSeekerIsWatched(this.state.currentSeeker);
      this.setState({isWatched: watched});
    }

    if (this.shouldRunSearch(prevProps)) {
      this.runSearch();
    }
    if (prevProps.seekers !== this.props.seekers) {
      this.props.setApplyButtonBusy(false);
    }
  }

  saveSearchChanged(prevProps) {
    if (this.props.savedSearchSelectedItem === null) {
      return false;
    }

    return (
      prevProps.savedSearchSelectedItem !== this.props.savedSearchSelectedItem
    );
  }

  shouldRunSearch(prevProps) {
    const pageChange =
      prevProps.currentPage !== this.props.currentPage &&
      this.state.pageChanged;
    if (pageChange) {
      this.setState({pageChanged: false});
    }

    return (
      prevProps.sortTerm !== this.props.sortTerm ||
      prevProps.currentJobObj !== this.props.currentJobObj ||
      prevProps.searchTerm !== this.props.searchTerm ||
      this.saveSearchChanged(prevProps) ||
      pageChange
    );
  }

  async componentDidMount() {
    this._isMounted = true;

    window.addEventListener('resize', this.resize.bind(this));
    this.resize();

    // analyticsTrack({eventName: 'CandidateDiscoveryPage'});
    const roleId = this.getParam('roleId');

    this.props.getEmploymentTypes();
    this.props.getRegions();
    this.props.getRoleTypes();
    this.props.getIndustries();
    this.props.getAllSkillTags();
    this.props.getMajors();
    this.props.getDegreeTypesRank();
    this.props.getSegments(false);
    this.props.getSavedSearches();

    if (
      (this.props.role === 'COMPANY_RECRUITER' ||
        this.props.role === 'COMPANY_RECRUITER_ADMIN') &&
      this.props.recruiterId
    ) {
      this.props.getJobsByRecruiter(this.props.recruiterId);
      this.props.getTeams();
    }

    if (this.props.experienceLevels.length === 0) {
      this.props.getExperienceLevels();
    }

    if (roleId) {
      await this.handleJobPreselect(Number(roleId));
    } else {
      this.runSearch();
    }
  }

  resize() {
    let currentWidth = window.innerWidth <= 1765;
    if (currentWidth !== this.state.isMinimumWidth) {
      this.setState({isMinimumWidth: currentWidth});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
    this._isMounted = false;
  }

  getParam = (paramName) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramName);
  };

  handleJobPreselect = async (jobId) => {
    const cachedJob = this.props.jobs.find((job) => job.id === jobId);
    let jobDropDownObj = {};
    if (cachedJob === undefined) {
      if (this.props.preselectJobRequestLoading === true) return;

      this.props.setPreselectJobRequest(true);
      const apiJob = await makeRequest({method: 'GET', path: `jobs/${jobId}`});

      this.props.setPreselectJobRequest(false);

      jobDropDownObj = {
        label: `${apiJob.data.name} - ${apiJob.data.employer.name}`,
        value: apiJob.data.id,
      };
    } else {
      jobDropDownObj = {
        label: `${cachedJob.name} - ${cachedJob.employer.name}`,
        value: cachedJob.id,
      };
    }
    const filterTag = this.getParam('filterTag');
    this.props.setJobIdFilter(jobDropDownObj, filterTag);
    // Modify sort direction and trigger runSearch from componentDidUpdate
    if (!filterTag) {
      this.props.setCandidateSorting('-overallScore');
    }
  };

  runSearch = (filterToExclude) => {
    this.props.setApplyButtonBusy(true);
    if (this.props.seekersLoading) return;

    this.props.setApplyButtonEnabled(false);

    const filters = {};

    for (const value of this.props.selectedFieldValues) {
      if (value === filterToExclude) continue;

      const truncatedValue = value.replace(
        `${CANDIDATE_DISCOVERY.exploreCandidates}-`,
        ''
      );

      const [key, id] = truncatedValue.split('-');
      if (filters[key] === undefined) {
        filters[key] = [];
      }

      const separatorPosition = id.indexOf('#');
      if (separatorPosition === -1) continue;

      filters[key].push(id.substring(0, separatorPosition));
    }

    let filterTag = this.getParam('filterTag');

    if (typeof filterToExclude === 'string') {
      if (filterToExclude.includes(filterTag)) {
        filterTag = null;
      }
    }

    let candidateActivity = ['applied', 'interested', 'invited'];
    let candidateActivityExists = candidateActivity.some(
      (activity) => activity in filters && filters[activity].includes('true')
    );

    if (this.props.currentJobObj !== null) {
      filters.jobId = this.props.currentJobObj.value;

      // Don't include matching if any candidate activity filters are selected
      if (!filterTag && !candidateActivityExists) {
        filters.overallFit = true;
      }
    }

    if (this.props.searchTerm) {
      filters.searchTerm = this.props.searchTerm;
      filters.searchFields = [
        'firstName',
        'lastName',
        'institution',
        'major',
        'email',
        'manager',
      ];
    }

    if (
      filterToExclude !== CANDIDATE_DISCOVERY.exploreCandidatesLoggedInAfter &&
      this.props.loggedInAfter !== ''
    ) {
      const date = new Date(new Date() - this.props.loggedInAfter);
      filters.loggedInAfter = date.toISOString();
    }

    if (this.props.selectedFieldValues.length > 0) {
      this.props.setClearFilterButtonEnabled(true);
    } else {
      this.props.setClearFilterButtonEnabled(false);
    }

    let sortTerm;
    if (filterTag || candidateActivityExists) {
      sortTerm = '-publishedAt';
    } else if (
      typeof filterToExclude === 'string' &&
      filterToExclude?.includes('jobId-')
    ) {
      sortTerm = '-publishedAt';
    } else {
      sortTerm = this.props.sortTerm;
    }

    this.props.getSeekersAdvanced(
      this.props.currentPage,
      this.props.itemsPerPage,
      sortTerm,
      filters
    );
  };

  handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    this.setState({pageChanged: true});
    this.props.setCandidateCardsPage(newPage);
  };

  handleSearchTermChange = (searchTerm) => {
    this.props.setSearchTerm(searchTerm);
  };

  handleInviteClicked = (seekerId) => {
    let seeker = this.props.seekers.find((x) => x.id === seekerId);
    if (seeker?.matches === undefined) {
      seeker = {...seeker, matches: []};
    }
    const watched = this.findSeekerIsWatched(seeker);
    this.props.getSeekerInviteResponse(seeker.id);
    this.setState({
      inviteToApplyModalIsOpen: true,
      currentSeeker: seeker,
      isWatched: watched,
    });
  };

  findSeekerIsWatched = (seeker) => {
    if (seeker.watchingTeamIds) {
      return this.props.teams.some((x) =>
        seeker.watchingTeamIds.includes(x.id)
      );
    }
    return false;
  };

  showTeamList = (seeker) => {
    if (typeof seeker !== 'object' && seeker !== null) {
      //seekerId was passed and not the seeker object
      seeker = this.props.seekers.find((x) => x.id === seeker);
    }
    if (seeker.teamWatchers === undefined) {
      seeker = {...seeker, teamWatchers: []};
    }

    this.setState({teamBenchModalIsOpen: true, currentSeeker: seeker});
  };

  handleTeamChecked = (teamId, checked) => {
    const team = this.props.teams.find((t) => t.id === teamId);
    const successMessage = `Candidate ${
      checked ? 'saved to' : 'removed from'
    } ${team.name} team`;
    if (this.state.currentSeeker.watchingTeamIds.includes(teamId)) {
      this.props.deleteSeekerWatch(
        teamId,
        this.state.currentSeeker.id,
        successMessage
      );
      this.setState({
        currentSeeker: {
          ...this.state.currentSeeker,
          watchingTeamIds: this.state.currentSeeker.watchingTeamIds.filter(
            (x) => x !== teamId
          ),
        },
      });

      this.props.updateSavedCandidatesRequiresRefresh();
      return;
    }
    this.props.createSeekerWatch(
      teamId,
      this.state.currentSeeker.id,
      successMessage
    );
    this.setState({
      currentSeeker: {
        ...this.state.currentSeeker,
        watchingTeamIds: [...this.state.currentSeeker.watchingTeamIds, teamId],
      },
    });

    this.props.updateSavedCandidatesRequiresRefresh();
  };

  handleInviteToApplySendClicked = (duplicates) => {
    this.setState({
      inviteToApplyModalIsOpen: false,
      currentSeeker: null,
    });

    if (duplicates && duplicates.length !== 0) {
      this.setState({
        duplicateInvitations: duplicates,
        isInvitesSentModalOpen: true,
      });
    }

    this.handleClearAllCandidatesClick();
  };

  handleShowResumeClicked = async (seeker) => {
    const watched = this.findSeekerIsWatched(seeker);
    const resumeUrlResp = await this.props.getSeekerResumeAction(seeker.id);
    this.setState({
      currentSeeker: seeker,
      showResumeModal: true,
      resumeUrlData: resumeUrlResp,
      isWatched: watched,
    });
    document.body.classList.add('modal-open');
  };

  handleBatchSelectChanged = (isChecked, seekerId) => {
    let currentList = [].concat(this.state.batchActionList);

    if (isChecked) {
      //add to the array.  Order is not important
      if (currentList.length < this.batchSelectMaximumCount) {
        currentList.push(seekerId);
      }
    } else {
      // find and remove from the array
      const seekerIndex = currentList.findIndex((id) => id === seekerId);
      currentList.splice(seekerIndex, 1);
    }

    this.setState({batchActionList: currentList});
  };

  handleJobToMatch = (jobObj) => {
    this.props.setJobIdFilter(jobObj);
  };

  handleClearRoleFilter = async () => {
    if (this.props.currentJobObj) {
      this.handleJobToMatch(null);
    }
  };

  handleSortOption = (props) => {
    this.props.setCandidateSorting(props.value);
  };

  handleSelectAllCandidatesClick = () => {
    let newList = this.state.batchActionList;
    let listCount = newList.length;

    if (listCount < this.batchSelectMaximumCount) {
      const newSelection = this.props.seekers.map((seeker) => {
        return seeker.id;
      });

      for (var i = 0; i < newSelection.length; i++) {
        if (!newList.includes(newSelection[i])) {
          newList.push(newSelection[i]);

          listCount++;
          if (listCount >= this.batchSelectMaximumCount) {
            break;
          }
        }
      }

      this.setState({batchActionList: newList});
    }
  };

  handleClearAllCandidatesClick = () => {
    this.setState({batchActionList: []});
  };

  handleBatchInviteClicked = () => {
    const selectedCandidates = this.state.batchActionList;

    if (selectedCandidates.length > 50) {
      this.setState({confirmBatchInviteRequired: true});
    } else {
      this.setState({currentSeeker: null, inviteToApplyModalIsOpen: true});
    }
  };

  processBatchInvite = () => {
    this.setState({currentSeeker: null, inviteToApplyModalIsOpen: true});
  };

  asyncLoadJobOptions = async (search, prevOptions) => {
    const itemsPerPage = 50;
    const page = Math.floor(prevOptions.length / itemsPerPage);
    let url = `v2/jobs/employer_name_groups?itemsPerPage=${itemsPerPage}&page=${page}`;

    if (search) {
      url += `&search=${search}`;
    }
    const jobNames = await makeRequest({method: 'GET', path: url});
    const recruiterResponse = await has_permission(
      PERMISSIONS.COMPANY_RECRUITER
    );
    const recruiterAdminResponse = await has_permission(
      PERMISSIONS.COMPANY_RECRUITER_ADMIN
    );
    const isCompanyRecruiter =
      recruiterResponse.data.hasPermission ||
      recruiterAdminResponse.data.hasPermission;

    const hasMore = jobNames.data.totalRows > prevOptions.length + itemsPerPage;
    return {
      options: jobNames.data.results.map((result) => {
        const rowLabel = isCompanyRecruiter
          ? `${result.jobName} - ${result.employerName}`
          : `${result.jobName} - ${result.employerName} (Role ID: ${result.jobId})`;

        return {
          label: rowLabel,
          value: result.jobId,
          employerId: result.employerId,
          employerName: result.employerName,
        };
      }),
      hasMore,
    };
  };

  sortByStyles = {
    indicatorsContainer: (styles) => ({
      ...styles,
      marginLeft: '-7px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'var(--neutral-gray-1)',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    singleValue: (styles, {isFocused}) => ({
      ...styles,
      color: 'var(--secondary-2)',
      fontWeight: 'bold',
      paddingRight: '5px',
      overflow: 'visible',
      textOverflow: '',
      width: '100%',
      '&:hover': {
        color: 'var(--primary-accessibility)',
        cursor: 'pointer',
      },
    }),

    control: (styles) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: 'var(--neutral-gray-5)',
      width: '140px',
    }),
    option: (styles, {isDisabled, isFocused}) => {
      const getBackgroundColor = () => {
        if (isFocused) return 'var(--secondary-2)';
        if (isDisabled) return null;

        return 'var(--neutral-white)';
      };
      const getForeColor = () => {
        if (isFocused) return 'var(--neutral-white)';
        if (isDisabled) return '#BEBEBE';

        return 'secondary-subheader';
      };
      return {
        ...styles,
        backgroundColor: getBackgroundColor(),
        color: getForeColor(),

        cursor: () => {
          if (isFocused) return 'pointer';
          if (isDisabled) return 'not-allowed';

          return 'default';
        },
      };
    },
  };

  getInviteToApplyModalKey = () => {
    let key = '';

    if (
      this.state.currentSeeker !== null &&
      this.state.inviteToApplyModalIsOpen
    ) {
      key += this.state.currentSeeker.id;

      if (this.props.currentJobObj !== null) {
        key += this.props.currentJobObj.value;
      }
    }

    if (this.state.batchActionList.length > 0) {
      key += 'batchInviteModal';
    }

    return key;
  };

  renderContentLoader = (index) => {
    return (
      <ContentLoader
        key={`Loader-${index}`}
        speed={3}
        height={150}
        width={'91%'}
        backgroundColor="#f1f4f9"
        foregroundColor="#cbe1ff"
        style={{
          margin: '0 30px 25px 30px',
          backgroundColor: '#ffffff',
        }}
      >
        <circle cx="65" cy="45" r="28" />
        <rect x="110" y="18" rx="3" ry="3" width="25%" height="22" />
        <rect x="110" y="60" rx="3" ry="3" width="60%" height="15" />
        <rect x="110" y="85" rx="3" ry="3" width="60%" height="15" />

        <rect
          x="100%"
          y="110"
          rx="6"
          ry="6"
          width="112"
          height="30"
          transform="translate(-122, 0)"
        />
      </ContentLoader>
    );
  };

  render() {
    const {seekersLoading} = this.props;
    const contentLoaders = [];

    for (var i = 0; i < 4; i++) {
      contentLoaders.push(this.renderContentLoader(i));
    }

    const sortOptions = [
      {label: 'Most Recent', value: '-publishedAt', isDisabled: false},
      {
        label: 'Best Match',
        value: '-overallScore',
        isDisabled: this.props.currentJobObj === null,
      },
    ];
    const checkPathwaysMatch = (seeker) => {
      // Check that the seeker has at least one pathway that matches the current job pathway
      if (seeker.apprenticePathways) {
        return seeker.apprenticePathways.some(
          (pathway) => pathway.value === this.props.currentJobPathway
        );
      }
    };

    return (
      // <DocumentTitle title="Candidate Discovery">
      <div className="wholepage no-scroll">
        <div
          className={
            this.state.batchActionList.length !== 0 ? 'achoredFooterMargin' : ''
          }
        >
          <InvitesSentModal
            isOpen={this.state.isInvitesSentModalOpen}
            onClose={() => {
              this.setState({isInvitesSentModalOpen: false});
            }}
            duplicateInvitations={this.state.duplicateInvitations}
          />

          <AreYouSureModal
            areYouSureText="Are you sure?"
            detailText={`Irrelevant invites can hurt your business reputation. Are you sure you want to send invitations to ${this.state.batchActionList.length} candidates?`}
            yesText="Yes, I'm sure"
            noText="No, cancel send"
            size="m"
            centerVertically={true}
            areYouSureClassName="normal-title"
            isOpen={this.state.confirmBatchInviteRequired}
            onClose={() => {
              this.setState({confirmBatchInviteRequired: false});
            }}
            onYes={() => {
              this.processBatchInvite();
              this.setState({confirmBatchInviteRequired: false});
            }}
          />
          {this.state.currentSeeker && (
            <ResumeSlideOutModal
              isOpen={this.state.showResumeModal}
              onInviteToApplyClicked={this.handleInviteClicked}
              onAddWatchClicked={this.showTeamList}
              onClose={() => {
                document.body.classList.remove('modal-open');
                this.setState({showResumeModal: false});
              }}
              seekerId={this.state.currentSeeker.id}
              seekerName={`${this.state.currentSeeker.firstName} ${this.state.currentSeeker.lastName}`}
              seekerAvatarUrl={this.state.currentSeeker.avatarUrl}
              seekerResumeEmbedUrl={this.state.resumeUrlData.resumeEmbedUrl}
              seekerResumePdfUrl={this.state.resumeUrlData.resumePdfUrl}
              resumeExtension={this.state.resumeUrlData.fileExtension}
              seekerIsWatched={this.state.isWatched}
              showSaveSeeker={
                this.props.profile.permissions.includes(
                  PERMISSIONS.COMPANY_RECRUITER
                ) ||
                this.props.profile.permissions.includes(
                  PERMISSIONS.COMPANY_RECRUITER_ADMIN
                )
              }
            />
          )}
          {(this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN') &&
            this.state.currentSeeker?.teamWatchers && (
              <TeamBenchModal
                isOpen={this.state.teamBenchModalIsOpen}
                teams={this.props.teams}
                candidateTeamIds={this.state.currentSeeker.watchingTeamIds}
                seekerName={`${this.state.currentSeeker.firstName} ${this.state.currentSeeker.lastName}`}
                onTeamChecked={this.handleTeamChecked}
                onOkClicked={() => {
                  this.setState({teamBenchModalIsOpen: false});
                }}
                onClose={() => {
                  this.setState({teamBenchModalIsOpen: false});
                }}
              />
            )}

          {(this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN') && (
            <InviteToApplyModal
              key={this.getInviteToApplyModalKey()}
              isOpen={this.state.inviteToApplyModalIsOpen}
              seeker={this.state.currentSeeker}
              onSendClicked={this.handleInviteToApplySendClicked}
              onClose={() => {
                this.setState({
                  inviteToApplyModalIsOpen: false,
                });
              }}
              preselectedJobId={this.props.currentJobObj?.value}
              hasPreselectedJob={
                this.props.currentJobObj !== null ? true : false
              }
              batchActionList={this.state.batchActionList}
            />
          )}
          {this.props.role === 'ASCEND_RECRUITER' && (
            <InviteToApplyModalForAscend
              key={this.getInviteToApplyModalKey()}
              isOpen={this.state.inviteToApplyModalIsOpen}
              seeker={this.state.currentSeeker}
              onSendClicked={this.handleInviteToApplySendClicked}
              onClose={() => {
                this.setState({inviteToApplyModalIsOpen: false});
              }}
              preselectedJobId={this.props.currentJobObj?.value}
              batchActionList={this.state.batchActionList}
              isMultiple={this.state.batchActionList.length > 0}
            />
          )}

          <>
            <div
              className="row ms-0"
              style={{
                backgroundColor: 'white',
                borderRadius: '0px 0px 10px 10px ',
                padding: '24px 0',
              }}
            >
              <div className="col-4">
                <InputSearch
                  placeHolder="Type and press enter to search"
                  value={this.props.searchTerm}
                  onEnterKey={(value) =>
                    this.handleSearchTermChange(value, false)
                  }
                  style={{backgroundColor: '#f1f4f9'}}
                  textBoxStyle={{backgroundColor: '#f1f4f9'}}
                />
              </div>
              <div className="col-5">
                <div>
                  <AsyncPaginate
                    defaultOptions
                    debounceTimeout={200}
                    placeholder="Filter candidates by role"
                    value={this.props.currentJobObj}
                    loadOptions={this.asyncLoadJobOptions}
                    onChange={this.handleJobToMatch}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#f1f4f9',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <DeletableSelect
                  options={this.props.savedSearches.map((x) => {
                    return {...x, label: x.name, value: x.searchObject};
                  })}
                  value={this.props.savedSearchSelectedItem}
                  placeholder="Saved Searches"
                  onChange={(props) => {
                    this.props.setCollapseAllFilterSections(true);
                  }}
                  isOptionDisabled={(x) => x.isDisabled}
                />
              </div>
            </div>
            <div
              className={`${discoveryStyles.rowTwo}  mb-4`}
              style={{marginTop: '24px'}}
            >
              <div style={{minWidth: '160px'}}></div>
              <div className={`${discoveryStyles.filterTags} `}>
                <FilterListTags
                  applyFiltersClick={(props) => {
                    this.runSearch(props);
                    this.props.setCollapseAllFilterSections(true);
                  }}
                  onClear={async () => {
                    await this.handleJobToMatch(null);
                    this.runSearch();
                    this.props.setCollapseAllFilterSections(true);
                  }}
                  sourceObjectName={CANDIDATE_DISCOVERY.exploreCandidates}
                />
              </div>
            </div>

            <div className={`${discoveryStyles.rowTwo} padding-top-sixteen`}>
              <div
                id="exploreCandidateDiscoveryFilters"
                className={`${styles.candidateFilterPanel}`}
              >
                <CandidateDiscoveryFilters
                  sourceObjectName={CANDIDATE_DISCOVERY.exploreCandidates}
                  applyFiltersClick={this.runSearch}
                  onClear={async () => {
                    await this.handleJobToMatch(null);
                    this.runSearch();
                  }}
                  key={`${CANDIDATE_DISCOVERY.exploreCandidates}-candidateDiscoveryFilters`}
                />
              </div>
              <div id={discoveryStyles.candidateSearchContentResults}>
                <div className="candidateCardSortContainer">
                  <div id="seekerCount" className="mb-n2">
                    {this.props.seekerTotalCount > 0 &&
                      `${this.props.seekerTotalCount} Candidates`}
                  </div>
                  <div className="candidateCardSort pe-0">
                    <div>Sort by</div>
                    <Select
                      styles={this.sortByStyles}
                      options={sortOptions}
                      placeholder="Sort by"
                      onChange={this.handleSortOption}
                      value={sortOptions.find(
                        (x) => x.value === this.props.sortTerm
                      )}
                      isOptionDisabled={(x) => x.isDisabled}
                    />
                  </div>
                </div>
                <span role="status">
                  {(seekersLoading ||
                    this.props.preselectJobRequestLoading) && (
                    <div id={discoveryStyles.loadingView}>{contentLoaders}</div>
                  )}
                </span>
                {this.props.seekers.length === 0 &&
                  !seekersLoading &&
                  !this.props.preselectJobRequestLoading && (
                    <div id={discoveryStyles.noResultsFound}>
                      <h2>No Candidates found</h2>
                    </div>
                  )}

                <div className="cardContainer">
                  {this.props.seekers?.length > 0 &&
                    this.props.seekers.map((seeker, index) => {
                      if (seeker) {
                        return (
                          <CandidateCard
                            key={`${CANDIDATE_DISCOVERY.exploreCandidates}-${index}`}
                            isRoleFilterApplied={checkPathwaysMatch(seeker)}
                            seeker={seeker}
                            showSaveSeeker={
                              this.props.profile.permissions.includes(
                                PERMISSIONS.COMPANY_RECRUITER
                              ) ||
                              this.props.profile.permissions.includes(
                                PERMISSIONS.COMPANY_RECRUITER_ADMIN
                              )
                            }
                            handleInviteClicked={this.handleInviteClicked}
                            seekerIsWatched={this.findSeekerIsWatched(seeker)}
                            handleAddWatchClicked={() =>
                              this.showTeamList(seeker)
                            }
                            handleShowResumeClicked={
                              this.handleShowResumeClicked
                            }
                            handleBatchSelectChanged={
                              this.handleBatchSelectChanged
                            }
                            allowIndividualInvitation={
                              this.state.batchActionList.length === 0
                            }
                            isSelected={this.state.batchActionList.includes(
                              seeker.id
                            )}
                            userRole={this.props.profile.role}
                            userEmployer={this.props.profile.employerId}
                            showSavedCandidateDetails={false}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
                <div className="row padding-bottom-eight candidateCardPageControl">
                  <div className="col-md-12">
                    {this.props.seekers?.length > 0 && (
                      <div className="d-flex flex-row justify-content-between">
                        <div aria-live="assertive" aria-atomic="true">
                          Page {this.props.currentPage + 1} of{' '}
                          {Math.ceil(
                            this.props.seekerTotalCount /
                              this.props.itemsPerPage
                          )}
                        </div>
                        <PageControl
                          pageIndex={this.props.currentPage}
                          pageCount={Math.ceil(
                            this.props.seekerTotalCount /
                              this.props.itemsPerPage
                          )}
                          onPageChange={this.handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
          </>
        </div>
        {this.state.batchActionList.length !== 0 && (
          <ListBatchActionDrawer
            selectionCount={this.state.batchActionList.length}
            handleSelectAllClick={this.handleSelectAllCandidatesClick}
            handleClearAllClick={this.handleClearAllCandidatesClick}
            batchSelectMaximumCount={this.batchSelectMaximumCount}
            handleBatchInviteClicked={this.handleBatchInviteClicked}
            isLoading={seekersLoading}
            noun={'Candidates'}
            buttonText={'Invite All to Apply'}
          />
        )}
      </div>
    );
  }
}

ExploreCandidates.propTypes = {
  history: PropTypes.object.isRequired,
  seekers: PropTypes.array.isRequired,
  getSeekersAdvanced: PropTypes.func.isRequired,
  getJobHandpickedToCache: PropTypes.func.isRequired,
  seekersLoading: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  seekerTotalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  getJobToCache: PropTypes.func.isRequired,
  jobs: PropTypes.array,
  getJobsByTeam: PropTypes.func.isRequired,
  getJobsByRecruiter: PropTypes.func.isRequired,
  teams: PropTypes.array,
  getTeams: PropTypes.func.isRequired,
  role: PropTypes.string,

  handpickedByJob: PropTypes.shape({
    id: PropTypes.number,
    handpicked: PropTypes.array,
  }),
  recruiterId: PropTypes.number,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getEmploymentTypes: PropTypes.func.isRequired,
  employmentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRegions: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  roleTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getIndustries: PropTypes.func.isRequired,
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  getDegreeTypesRank: PropTypes.func.isRequired,
  degreeTypes: PropTypes.array,

  getSegments: PropTypes.func.isRequired,
  currentEmployerId: PropTypes.number,
  getExperienceLevels: PropTypes.func.isRequired,
  createSeekerWatch: PropTypes.func.isRequired,
  deleteSeekerWatch: PropTypes.func.isRequired,
  getSeekerResumeAction: PropTypes.func.isRequired,
  employerJobs: PropTypes.array,
  getEmployerNameGroups: PropTypes.func,
  setCandidateSorting: PropTypes.func,
  setJobIdFilter: PropTypes.func,
  currentJob: PropTypes.object,
  isJobsLoading: PropTypes.bool,
  sortTerm: PropTypes.string,
  selectedFieldValues: PropTypes.any,
  loggedInAfter: PropTypes.any,
  setCandidateCardsPage: PropTypes.func,
  setSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  currentJobObj: PropTypes.object,
  getMajors: PropTypes.func,
  getAllSkillTags: PropTypes.func,
  experienceLevels: PropTypes.array,
  preselectJobRequestLoading: PropTypes.bool,
  setPreselectJobRequest: PropTypes.func,
  setApplyButtonEnabled: PropTypes.func,
  setClearFilterButtonEnabled: PropTypes.func,
  applyButtonBusy: PropTypes.bool,
  setApplyButtonBusy: PropTypes.func,
  setCollapseAllFilterSections: PropTypes.func,
  profile: PropTypes.object,
  getSavedSearches: PropTypes.func,
  savedSearches: PropTypes.array,
  savedSearchSelectedItem: PropTypes.object,
  getSeekerInviteResponse: PropTypes.func,
  currentJobPathway: PropTypes.number,
  toggledName: PropTypes.string,
  updateSavedCandidatesRequiresRefresh: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    seekers: state.seekers.seekers,
    seekersLoading: state.seekers.seekersLoading,
    itemsPerPage: state.seekers.seekersItemsPerPage,
    seekerTotalCount: state.seekers.seekersTotalItems,

    jobCache: state.jobs.jobCache,
    jobCacheLoading: state.jobs.jobCacheLoading,
    jobs: state.jobs.jobs,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
    recruiterId: state.profile.companyRecruiterId,
    handpickedByJob: state.jobs.handpickedByJob,
    role: state.profile.role,

    employmentTypes: state.employmentTypes.employmentTypes,
    regions: state.regions.regions,
    roleTypes: state.roleTypes.roleTypes,
    industries: state.industries.industries,

    segments: state.segments.segmentList,
    degreeTypeRanks: state.degreeTypeRank.degreeRank,
    currentEmployerId: state.recruiters.recruiter.employerId,
    experienceLevels: state.experienceLevels.experienceLevels,
    employerJobs: state.employers.jobsByEmployer,
    employerJobsPage: state.employers.jobsByEmployerPage,
    employerJobsTotalCount: state.employers.jobsByEmployerTotalCount,
    currentJob: state.employers.jobsByEmployerCurrentJob,
    isJobsLoading: state.employers.isLoading,

    sortTerm: state.candidateDiscoveryReducer.sortTerm,
    searchTerm: state.candidateDiscoveryReducer.searchTerm,
    selectedFieldValues: state.candidateDiscoveryReducer.selectedFieldValues,
    loggedInAfter:
      state.candidateDiscoveryReducer.exploreCandidatesLoggedInAfter,
    currentPage: state.candidateDiscoveryReducer.page,
    currentJobObj: state.candidateDiscoveryReducer.jobObj,
    toggledName: state.candidateDiscoveryReducer.toggledName,
    preselectJobRequestLoading:
      state.candidateDiscoveryReducer.preselectJobRequestLoading,
    applyButtonBusy: state.candidateDiscoveryReducer.applyButtonBusy,
    profile: state.profile,
    savedSearches: state.recruiters.savedSearches,
    savedSearchSelectedItem:
      state.candidateDiscoveryReducer.savedSearchSelectedItem,
    currentJobPathway: state.jobs.job.apprenticePathwayId,
  };
}

const actions = {
  ...seekersActions,
  ...jobsActions,
  ...teamsActions,

  ...employmentTypeActions,
  ...regionsActions,
  ...roleTypesActions,
  ...industriesActions,
  ...degreeTypeRankActions,
  ...searchActions,
  ...segmentActions,
  getExperienceLevels,
  getSeekerResumeAction,
  getAllSkillTags,
  getMajors,
  ...candidateDiscoveryActions,
  getSavedSearches,
};

export default withRouter(connect(mapStateToProps, actions)(ExploreCandidates));
