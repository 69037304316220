import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Form, Field} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {Alert} from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';

import * as roleActions from 'src/actions/roleTypes';
import * as employmentActions from 'src/actions/employmentTypes';
import * as industriesActions from 'src/actions/industries';
import * as regionsActions from 'src/actions/regions';
import * as degreeTypeRankActions from '../actions/DegreeTypeActions';
import * as segmentActions from '../actions/SegmentActions';
import * as usersActions from '../actions/users';
import ApprenticePathwaySelect from 'src/components/ApprenticePathwaySelect';
import {RadioGroupField} from 'src/formFields/RadioGroupField';
import {toNumber} from 'src/utils/wageHelper';

import {
  MultiSelectField,
  SelectField,
  AvatarField,
  SingleDatePickerField,
  TextField,
  OnOffSwitch,
  TextAreaField,
  SkillsModalFinalField,
  TextAreaFinalFieldArray,
  RichTextEdit,
} from 'src/formFields';

import {AscendModal, AscendModalBody} from 'src/layout';
import AreYouSureModal from 'src/components/AreYouSureModal';
import {illegalWorkStyleTags} from 'src/utils/tagsHelper';
import {
  trimFields,
  multiselectChangeHandler,
  getSortedLookup,
} from 'src/utils/fieldHelper';
import {buildSalaryRange, buildHourlyRange} from '../utils/wageHelper';
import {canEditJob} from '../actions/AuthorizationActions';
import {getEmployerLocations} from '../actions/employers';
import {PERMISSIONS} from '../services/authorizationApi';
import enterprise from '../assets/images/Enterprise.svg';
import medicalSvg from '../assets/images/Medical.svg';
import parentalSvg from '../assets/images/Parental.svg';
import smileySvg from '../assets/images/Smiley.svg';
import retirementSvg from '../assets/images/Retirement.svg';
import educationSvg from '../assets/images/Education.svg';
import stocksSvg from '../assets/images/Stocks.svg';
import dentalSvg from '../assets/images/Dental.svg';
import vacationSvg from '../assets/images/Vacation.svg';
import visionSvg from '../assets/images/Vision.svg';
import lifeSvg from '../assets/images/Life.svg';
import CheckboxField from '../formFields/CheckboxField';
import {makeRequest} from '../services/api';
import {remoteStatusTypes} from '../pages/jobDiscovery/roleUtil';
import LocationFieldArray from 'src/formFields/LocationFieldArray';
import {track} from '../utils/analytics';
import {getTeamsByEmployer} from '../actions/teams';
import {DeleteRoleConfirm} from '../components/DeleteRoleConfirm';
import {
  isValidHttpsUrl,
  INVALID_URL_ERROR_MESSAGE,
} from 'src/utils/fieldHelper';

const hourlyPayOptions = buildHourlyRange();
const salaryPayOptions = buildSalaryRange();

const benefits = [
  {
    name: 'medicalInsurance',
    label: 'Medical Insurance',
    image: medicalSvg,
  },
  {name: 'stockOptions', label: 'Stock Options', image: stocksSvg},
  {name: 'dentalCoverage', label: 'Dental Coverage', image: dentalSvg},
  {name: 'paidVacation', label: 'Paid Time Off', image: vacationSvg},
  {name: 'visionCoverage', label: 'Vision Coverage', image: visionSvg},
  {
    name: 'paidParentalLeave',
    label: 'Paid Parental Leave',
    image: parentalSvg,
  },
  {name: 'lifeInsurance', label: 'Life Insurance', image: lifeSvg},
  {
    name: 'flexibleWorkEnvironment',
    label: 'Flexible Work Environment',
    image: smileySvg,
  },
  {
    name: 'retirementPlans',
    label: 'Retirement Plans',
    image: retirementSvg,
  },
  {
    name: 'educationalAssistance',
    label: 'Education Assistance',
    image: educationSvg,
  },
];

const isPayRangeValid = (minimumPay, maximumPay) => {
  return toNumber(minimumPay) < toNumber(maximumPay);
};

const validateMa = (values, page) => {
  const errors = {};

  if (values.apprenticePathwayId === null) {
    errors.apprenticePathwayId = 'Required';
  }

  if (values.remoteStatus !== 'Remote' && values.employerLocationId === null) {
    errors.employerLocationId = 'Required';
  }

  // For rich text, also validate by striping out html tags.
  if (
    page >= 2 &&
    (_.isEmpty(values.description) ||
      !values.description.replace(/<(.|\n)*?>/g, '').trim().length)
  ) {
    errors.description = 'Required';
  }

  if (
    page >= 3 &&
    values.additionalQuestions &&
    values.additionalQuestions.length
  ) {
    errors.additionalQuestions = new Array(values.additionalQuestions.length);
    values.additionalQuestions.forEach((q, i) => {
      if (!q || !q.trim().length) {
        errors.additionalQuestions[i] = 'Text is required.';
      }
    });
  }

  return errors;
};

const validate = (values, page, showTeams) => {
  const errors = {};
  const isMaSegment = (values.segments || []).some(
    (x) => x.isApprenticeProgram
  );

  if ((values.name || '').trim().length === 0) {
    errors.name = 'Title is required.';
  }

  if (showTeams && (values.teamId === null || values.teamId === undefined)) {
    errors.teamId = 'Required';
  }

  if (values.published === true) {
    if (isMaSegment) {
      return validateMa(values, page);
    }

    if (!values.roleStartFlexibility) {
      errors.roleStartFlexibility = 'Role start flexibility is required.';
    }
    // Make sure there are no overlaps between preferred and required skills
    if (
      _.intersectionBy(values.skillTags, values.requiredSkillTags, (s) => s.id)
        .length
    ) {
      let message = 'A skill may only be preferred OR required, not both.';
      errors.skillTags = message;
      errors.requiredSkillTags = message;
    }
    if (illegalWorkStyleTags(values.workStyleTags)) {
      errors.workStyleTags =
        'Work style tags category overlap, please remove last tag';
    }
    if (values.numberOfOpenings < 1) {
      errors.numberOfOpenings = 'Number of openings must be at least one.';
    }
    if (values.numberOfOpenings > 200) {
      errors.numberOfOpenings = 'Number of openings must be less than 200.';
    }
    if (_.isEmpty(values.drugTest)) {
      errors.drugTest = 'Required';
    }
    if (_.isEmpty(values.backgroundCheck)) {
      errors.backgroundCheck = 'Required';
    }
    if (_.isEmpty(values.employmentTypes)) {
      errors.employmentTypes = 'Required';
    }

    if (
      values.degreeTypeRankId === null ||
      values.degreeTypeRankId === undefined
    ) {
      errors.degreeTypeRankId = 'Required';
    }

    if (_.isEmpty(values.regions)) {
      errors.regions = 'Required';
    }

    if (_.isEmpty(values.roleTypes)) {
      errors.roleTypes = 'Required';
    }
    if (_.isEmpty(values.industries)) {
      errors.industries = 'Required';
    }

    if (_.isEmpty(values.url)) {
      errors.url = 'Required';
    }
    if (values.url && !isValidHttpsUrl(values.url)) {
      errors.url = INVALID_URL_ERROR_MESSAGE;
    }
    if (_.isEmpty(values.experienceLevels)) {
      errors.experienceLevels = 'Required';
    }
    if (
      values.remoteStatus !== 'Remote' &&
      values.employerLocationId === null
    ) {
      errors.employerLocationId = 'Required';
    }
  }
  if (
    values.payType === 'Hourly' &&
    values.minimumHourlyWage &&
    values.maximumHourlyWage
  ) {
    if (!isPayRangeValid(values.minimumHourlyWage, values.maximumHourlyWage)) {
      errors.maximumHourlyWage =
        'Maximum salary or wage must be a larger value than the minimum.';
    }
  }

  if (
    values.payType === 'Salary' &&
    values.minimumYearlySalary &&
    values.maximumYearlySalary
  ) {
    if (
      !isPayRangeValid(values.minimumYearlySalary, values.maximumYearlySalary)
    ) {
      errors.maximumYearlySalary =
        'Maximum salary or wage must be a larger value than the minimum.';
    }
  }

  return errors;
};

const validateLocation = (values) => {
  let error = undefined;
  if (!_.isEmpty(values)) {
    values.forEach((location) => {
      if (
        location &&
        (location.addressLine1 === undefined ||
          location.city === undefined ||
          location.stateId === undefined ||
          location.postalCode === undefined)
      ) {
        error =
          'Address Line 1, City, State, and ZIP Code are required when a location is added.';
      }
    });
  }

  return error;
};

const defaults = {
  published: false,
  scraped: false,
  roleRank: 0,
  numberOfOpenings: 1,
  skillTags: [],
  requiredSkillTags: [],
  apprenticePathwayId: null,
  isPaidRole: true,
  employerLocationId: null,
  customLocation: [
    {
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateId: '',
      postalCode: '',
    },
  ],
};

const optionsYesNo = [
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
  {value: 'unknown', label: 'Unknown'},
];

class TopHeader extends React.Component {
  render() {
    const {mode} = this.props;
    return (
      <Fragment>
        <center>
          <img src={enterprise} height="54" width="54" alt="enterprise" />
          <div className="normal-title padding-top-thirtytwo padding-bottom-eight bold">
            {mode === 'edit' && 'Edit Role'}
            {mode === 'create' && 'New Role'}
            {mode === 'clone' && 'Cloned Role'}
          </div>
        </center>
        <hr />
      </Fragment>
    );
  }
}
TopHeader.propTypes = {
  mode: PropTypes.string,
};

class SubHeader extends React.Component {
  render() {
    const {text, subtext} = this.props;
    return (
      <Fragment>
        <center>
          <div className="normal-title bold">{text}</div>
          {subtext && (
            <Fragment>
              <br />
              <div className="normal-subheader">{subtext}</div>
            </Fragment>
          )}
        </center>
      </Fragment>
    );
  }
}
SubHeader.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
};

class NavigationActionButtons extends React.Component {
  render() {
    const {totalPages, page, disabled, handlePreviousClick, handleSubmitClick} =
      this.props;
    return (
      <Fragment>
        {totalPages > 1 && page !== 1 && (
          <button
            className="clickable btn btn-primary me-2"
            onClick={handlePreviousClick}
            type="button"
            disabled={disabled}
          >
            Previous
          </button>
        )}
        <button
          className="clickable btn btn-primary"
          onClick={handleSubmitClick}
          type="button"
          disabled={disabled}
        >
          {page === totalPages && <Fragment>Save</Fragment>}
          {page < totalPages && <Fragment>Continue</Fragment>}
        </button>
      </Fragment>
    );
  }
}
NavigationActionButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
};

class JobForm extends React.Component {
  state = {
    totalPages: this.props.totalPages,
    page: 1,
    areYouSureModalIsOpen: false,
    userList: [],
    maSegment: false,
    payTypes: ['Hourly', 'Salary'],
    selectedPayType: this.props.initialValues
      ? this.props.initialValues.payType
      : '',
    apprenticePathwayId: this.props.initialValues
      ? this.props.initialValues.apprenticePathwayId
      : null,
    roleStarts: ['Immediate', 'Flexible', 'Specific'],
    experienceLevelsList: [
      {
        id: 1,
        name: 'Internships and Entry Level',
        years: '0-3 years',
      },
      {
        id: 2,
        name: 'Mid-Level',
        years: '4-7 years',
      },
      {
        id: 3,
        name: 'Senior Level',
        years: '8+ years',
      },
    ],
    isUnpaid: this.props.initialValues
      ? !this.props.initialValues.isPaidRole
      : false,
    hasLoadedLocations: false,
    values: {},
    hasLoadedTeams: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.setState(() => ({page: 1}));
    this.props.getIndustries();
    this.props.getRoleTypes();
    this.props.getEmploymentTypes();
    this.props.getRegions();
    this.props.getDegreeTypesRank();
    this.props.getSegments();
    this.props.getUsersByPermission(PERMISSIONS.CAN_BE_ACCOUNT_MANAGER);

    makeRequest({
      method: 'GET',
      path: 'v2/experience_levels',
      body: {},
      contentType: 'application/json',
    }).then((results) => {
      if (results.status === 200) {
        this.setState({experienceLevelsList: results.data.experienceLevels});
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    if (
      (prevProps.jobId !== this.props.jobId ||
        (this.props.mode === 'create' && !this.state.hasLoadedLocations)) &&
      !this.props.locationsLoading &&
      this.props.initialValues
    ) {
      this.props.getEmployerLocations(
        this.props.employerId
          ? this.props.employerId
          : this.props.initialValues.employerId
      );
      this.setState({
        hasLoadedLocations: true,
        values: {
          ...this.props.initialValues,
          employerLocationId: this.setLocationSelector(),
          customLocation: this.setCustomLocation(),
        },
      });
    }

    if (
      (prevProps.jobId !== this.props.jobId ||
        (this.props.mode === 'create' && !this.state.hasLoadedTeams)) &&
      !this.props.teamsLoading &&
      this.props.initialValues
    ) {
      this.props.getTeamsByEmployer(
        this.props.employerId
          ? this.props.employerId
          : this.props.initialValues.employerId
      );
      this.setState({hasLoadedTeams: true});
    }

    if (prevProps.jobId !== this.props.jobId && this.props.jobId) {
      this.props.canEditJob(this.props.jobId);
    }
    if (prevProps.usersList !== this.props.usersList) {
      let list = this.props.usersList.map((x) => {
        return {
          value: x.userId,
          label: `${x.firstName} ${x.lastName}`,
        };
      });

      this.setState({userList: list});
    }

    if (prevState.maSegment !== this.state.maSegment) {
      this.setState({
        payTypes: this.state.maSegment ? ['Hourly'] : ['Hourly', 'Salary'],
      });
    }
  }

  setLocationSelector = () => {
    if (this.props.mode === 'create') {
      return null;
    }
    if (
      this.props.initialValues &&
      this.props.initialValues.employerLocationId
    ) {
      return this.props.initialValues.employerLocationId;
    }
    return 'custom';
  };

  setCustomLocation = () => {
    if (
      this.props.initialValues &&
      this.props.initialValues.employerLocationId !== null &&
      this.props.initialValues.employerLocationId !== 'custom'
    ) {
      return [{}];
    }
    return [
      {
        addressLine1: this.props.initialValues
          ? this.props.initialValues.addressLine1
          : '',
        addressLine2: this.props.initialValues
          ? this.props.initialValues.addressLine2
          : '',
        city: this.props.initialValues ? this.props.initialValues.city : '',
        stateId: this.props.initialValues
          ? this.props.initialValues.stateId
          : '',
        postalCode: this.props.initialValues
          ? this.props.initialValues.postalCode
          : '',
      },
    ];
  };

  findUserName = (userId) => {
    const user = this.state.userList.find((x) => x.value === userId);
    if (user) {
      return user.label;
    }
    return '';
  };

  handleDeleteClicked = () => {
    this.setState({
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = () => {
    this.props.onDelete();
    this.props.onClose();
    this.setState({
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClosed = () => {
    this.setState({
      areYouSureModalIsOpen: false,
    });
  };

  handleAppInstructionSelected = (opt) => {
    this.setState({applicationInstructionSelection: opt});
    let text = opt.value;
    if (opt.value === 'Other') {
      text = '';
    }
    this.formRef.current.change('applicationInstructions', text);
  };

  onSubmit = (data) => {
    // list all text fields here.
    const fields = [
      'name',
      'req',
      'location',
      'info1',
      'info2',
      'showcaseName',
      'showcaseTitle',
      'showcaseInfo',
      'url',
      'roleStartFlexibility',
      'availableToStart',
      'applicationInstructions',
    ];

    const newData = trimFields(data, fields);
    const skillTagIds = data.skillTags ? data.skillTags.map((t) => t.id) : [];
    const requiredSkillTagIds = data.requiredSkillTags
      ? data.requiredSkillTags.map((t) => t.id)
      : [];
    const industryIds = data.industries ? data.industries.map((t) => t.id) : [];
    const regionIds = data.regions ? data.regions.map((t) => t.id) : [];
    const roleTypeIds = data.roleTypes ? data.roleTypes.map((t) => t.id) : [];
    const employmentTypeIds = data.employmentTypes
      ? data.employmentTypes.map((t) => t.id)
      : [];

    const degreeTypeRankId =
      data.degreeTypeRankId && data.degreeTypeRankId.value
        ? data.degreeTypeRankId.value
        : data.degreeTypeRankId;

    const isPaidRole = !this.state.isUnpaid;

    let fullRole = {
      ...newData,
      name: data.name.trim(),
      skillTagIds,
      requiredSkillTagIds,
      industryIds,
      regionIds,
      roleTypeIds,
      employmentTypeIds,
      degreeTypeRankId,
      drugTest: data.drugTest === 'unknown' ? null : data.drugTest,
      backgroundCheck:
        data.backgroundCheck === 'unknown' ? null : data.backgroundCheck,
      transportationAssistance:
        data.transportationAssistance === 'unknown'
          ? null
          : data.transportationAssistance,
      publicTransportationAccess:
        data.publicTransportationAccess === 'unknown'
          ? null
          : data.publicTransportationAccess,
      req: data.req || '',
      isPaidRole,
      minimumYearlySalary:
        data.minimumYearlySalary === 'unpaid' ? null : data.minimumYearlySalary,
      maximumYearlySalary:
        data.maximumYearlySalary === 'unpaid' ? null : data.maximumYearlySalary,
      minimumHourlyWage:
        data.minimumHourlyWage === 'unpaid' ? null : data.minimumHourlyWage,
      maximumHourlyWage:
        data.maximumHourlyWage === 'unpaid' ? null : data.maximumHourlyWage,
      customLocation:
        data.employerLocationId === 'custom' ? data.customLocation[0] : null,
      employerLocationId:
        data.employerLocationId !== 'custom' ? data.employerLocationId : null,
      payType: !isPaidRole ? '' : data.payType,
      teamId: data.teamId,
    };

    if (this.state.maSegment) {
      const additionalQuestions = (data.additionalQuestions || []).map((q) =>
        q.trim()
      );
      const resumeRequired = data.resumeRequired || false;
      fullRole = {...fullRole, additionalQuestions, resumeRequired};
    } else {
      delete fullRole['additionalQuestions'];
      delete fullRole['description'];
      delete fullRole['resumeRequired'];
    }
    if (fullRole.customLocation === null) {
      delete fullRole['customLocation'];
    }

    if (data.unpublishedById) {
      fullRole.unpublishedById = data.unpublishedById;
    }

    this.props.onSubmit(fullRole);
    this.setState(() => ({page: 1, values: {}}));
    this.formRef.current.reset();
  };

  handleClosed = () => {
    this.formRef.current.reset();
    this.props.onClose();
    this.setState(() => ({page: 1, values: {}}));
  };

  handleSubmitOrContinueClick(values, page, totalPages) {
    if (this.props.published === true && values.published === false) {
      track({
        eventName: `UnpublishedAJobClicked_${this.props.profile.role}`,
        jobId: values.id,
      });
      values.unpublishedById = this.props.profile.id;
    }

    if (page === totalPages) {
      this.onSubmit(values);
    } else {
      this.setState(() => ({
        page: page + 1,
        values: values,
      }));
    }
  }

  render() {
    const {
      isOpen,
      mode,
      industriesLoading,
      regionsLoading,
      roleTypesLoading,
      employmentTypesLoading,
      degreeTypesLoading,
      segmentsLoading,
      allowJobEdit,
    } = this.props;

    const {maSegment, payTypes, selectedPayType, roleStarts, page, totalPages} =
      this.state;

    const roleStartOptions = roleStarts.map((roleStart) => ({
      value: roleStart,
      label: roleStart,
    }));

    let payTypeOptions = payTypes.map((payType) => ({
      value: payType,
      label: payType,
    }));

    payTypeOptions.push({value: 'unpaid', label: 'Position is Unpaid'});

    const industries = getSortedLookup(
      this.props.industries,
      ['name'],
      'Varies'
    );

    const segments = _.sortBy(this.props.segments, ['name']).map((s) => ({
      label: s.name,
      value: s.id,
      isApprenticeProgram: s.isApprenticeProgram,
    }));
    const maSegmentValues = segments.filter((x) => {
      return x.isApprenticeProgram === true;
    });

    const applicationInstructionsOpts = [
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Type "Ascend" in referral source text box',
        value: 'Type "Ascend" in referral source text box',
      },
      {
        label: 'Select "Ascend" from referral source drop-down menu',
        value: 'Select "Ascend" from referral source drop-down menu',
      },
      {
        label:
          'Select “Other” from referral source drop-down menu and type in Ascend',
        value:
          'Select “Other” from referral source drop-down menu and type in Ascend',
      },
      {
        label: 'Include "Ascend Candidate" in email subject line',
        value: 'Include "Ascend Candidate" in email subject line',
      },
      {
        label: 'Indicate "Ascend Candidate" in cover letter',
        value: 'Indicate "Ascend Candidate" in cover letter',
      },
    ];

    const regions = getSortedLookup(this.props.regions, ['name']);

    const roleTypes = getSortedLookup(this.props.roleTypes, ['name']);

    let empTypeList = maSegment
      ? this.props.employmentTypes
      : this.props.employmentTypes.filter((y) => y.name !== 'Apprenticeship');

    const employmentTypes = _.orderBy(empTypeList, ['name']).map((x) => ({
      label: x.name,
      value: x.id,
    }));

    const degreeTypes = _.orderBy(this.props.degreeTypes, ['name']).map(
      (x) => ({label: x.name, value: x.id})
    );

    const optionsExperienceLevel = () => {
      if (this.state.experienceLevelsList) {
        return this.state.experienceLevelsList.map((x) => ({
          value: x.id,
          label: x.years,
        }));
      }
      return [{id: 0, label: 'List error'}];
    };

    const isLocationDisabled = (values) => {
      if (values.remoteStatus === 'Remote') {
        return true;
      }
      return false;
    };

    const isMaximumYearlySalaryDisabled = (values) => {
      if (this.state.isUnpaid) return true;

      if (!values.minimumYearlySalary) return true;

      return values.minimumYearlySalary === 'unpaid';
    };

    const isMaximumHourlyWageDisabled = (values) => {
      if (this.state.isUnpaid) return true;

      if (!values.minimumHourlyWage) return true;

      return values.minimumHourlyWage === 'unpaid';
    };

    return (
      <div>
        <AreYouSureModal
          areYouSureText="Are you sure that you want to remove this role?"
          isOpen={this.state.areYouSureModalIsOpen}
          onClose={this.handleAreYouSureClosed}
          onYes={this.handleDeleteConfirmed}
        />

        <DeleteRoleConfirm
          jobName={this.props.initialValues?.name}
          onClose={this.handleAreYouSureClosed}
          onClick={this.handleDeleteConfirmed}
          confirmDeleteIsOpen={this.state.areYouSureModalIsOpen}
        />

        <AscendModal isOpen={isOpen} onClose={this.handleClosed}>
          <AscendModalBody hasClose={true}>
            <div className="padding-32">
              <TopHeader mode={mode} />
              <Form
                {...this.props}
                onSubmit={(values) =>
                  this.handleSubmitOrContinueClick(values, page, totalPages)
                }
                validate={(values) =>
                  validate(values, page, this.props.showTeams)
                }
                mutators={{...arrayMutators}}
                keepDirtyOnReinitialize={true}
                initialValues={{
                  ...this.state.values,
                  payType: this.props.initialValues
                    ? !this.props.initialValues.isPaidRole
                      ? 'unpaid'
                      : this.props.initialValues.payType
                    : '',
                }}
              >
                {({handleSubmit, form, submitFailed, values}) => {
                  this.formRef.current = form;
                  return (
                    <form>
                      <OnChange name="segments">
                        {(segments) => {
                          if (
                            segments &&
                            segments.some((x) => x.isApprenticeProgram)
                          ) {
                            const onlyMaSelections = segments.filter(
                              (x) => x.isApprenticeProgram === true
                            );
                            form.change('experienceLevelIds', []);

                            if (!maSegment) {
                              form.change('segments', onlyMaSelections);
                            }
                            // Set the employment type to Apprenticeship for maSegment
                            form.change(
                              'employmentTypes',
                              this.props.employmentTypes.filter(
                                (x) => x.name === 'Apprenticeship'
                              )
                            );
                            this.setState({maSegment: true, totalPages: 3});
                          } else {
                            this.setState({maSegment: false, totalPages: 1});
                          }
                        }}
                      </OnChange>

                      <OnChange name="applicationDeadline">
                        {(applicationDeadline) => {
                          if (applicationDeadline && form.getState().dirty) {
                            const dateContainer = document.getElementById(
                              'applicationDeadlineContainer'
                            );

                            const clearButton =
                              dateContainer.getElementsByTagName('BUTTON')[0];

                            if (clearButton) {
                              clearButton.focus();
                            }
                          }
                        }}
                      </OnChange>

                      <OnChange name="experienceLevels">
                        {(experienceLevels) => {
                          if (experienceLevels.length > 0) {
                            const selected = experienceLevels.map((x) => x.id);
                            form.change('experienceLevelIds', selected);
                          }
                        }}
                      </OnChange>

                      <OnChange name="apprenticePathwayId">
                        {(apprenticePathway) => {
                          if (apprenticePathway) {
                            const selected = isNaN(apprenticePathway)
                              ? apprenticePathway.value
                              : Number(apprenticePathway);

                            form.change('apprenticePathwayId', selected);
                          }
                        }}
                      </OnChange>

                      <OnChange name="industries">
                        {(industries) => {
                          if (industries) {
                            multiselectChangeHandler(
                              'industries',
                              industries,
                              form.change,
                              'Varies'
                            );
                          }
                        }}
                      </OnChange>

                      <OnChange name="regions">
                        {(regions) => {
                          if (regions) {
                            multiselectChangeHandler(
                              'regions',
                              regions,
                              form.change
                            );
                          }
                        }}
                      </OnChange>

                      <OnChange name="roleTypes">
                        {(roleTypes) => {
                          if (roleTypes) {
                            multiselectChangeHandler(
                              'roleTypes',
                              roleTypes,
                              form.change
                            );
                          }
                        }}
                      </OnChange>

                      <OnChange name="payType">
                        {(payType) => {
                          if (payType) {
                            if (payType === 'unpaid') {
                              form.change('minimumYearlySalary', 'unpaid');
                              form.change('maximumYearlySalary', 'unpaid');
                              form.change('minimumHourlyWage', 'unpaid');
                              form.change('maximumHourlyWage', 'unpaid');
                              this.setState({isUnpaid: true});
                            } else {
                              if (form.getState().dirtyFields.payType) {
                                form.change('minimumYearlySalary', null);
                                form.change('maximumYearlySalary', null);
                                form.change('minimumHourlyWage', null);
                                form.change('maximumHourlyWage', null);
                              }
                              this.setState({
                                selectedPayType: payType,
                                isUnpaid: false,
                              });
                            }
                          }
                        }}
                      </OnChange>

                      <OnChange name="minimumYearlySalary">
                        {(minimumYearlySalary) => {
                          if (!minimumYearlySalary) {
                            form.change('maximumYearlySalary', null);
                          }
                        }}
                      </OnChange>

                      <OnChange name="minimumHourlyWage">
                        {(minimumHourlyWage) => {
                          if (!minimumHourlyWage) {
                            form.change('maximumHourlyWage', null);
                          }
                        }}
                      </OnChange>

                      <OnChange name={'remoteStatus'}>
                        {(remote) => {
                          if (remote && remote === 'Remote') {
                            form.change('employerLocationId', null);
                          }
                        }}
                      </OnChange>

                      {page === 1 && (
                        <Fragment>
                          <SubHeader text={'Basic Information'} />
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="segments"
                                label="Segment Visibility (show only to this segment)"
                                isDisabled={segments.length === 0}
                                isLoading={segmentsLoading}
                                component={MultiSelectField}
                                options={
                                  maSegment === true
                                    ? maSegmentValues
                                    : segments
                                }
                              />
                            </div>
                            {!this.state.maSegment && (
                              <div className="col-md-6">
                                <Field
                                  name="experienceLevels"
                                  label="Experience Level"
                                  component={MultiSelectField}
                                  options={optionsExperienceLevel()}
                                  metaOverride={
                                    values.experienceLevels?.length === 0 &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="name"
                                component={TextField}
                                label="Role Title"
                                metaOverride={
                                  !values.name &&
                                  submitFailed === false &&
                                  this.props.showErrorsOnLoad && {
                                    touched: true,
                                    error: 'Title is required.',
                                  }
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              {maSegment === false && (
                                <Field
                                  name="req"
                                  component={TextField}
                                  label="Req ID"
                                  maxLength={32}
                                />
                              )}

                              {maSegment && (
                                <Field name="apprenticePathwayId">
                                  {(props) => {
                                    let value = isNaN(props.input.value)
                                      ? props.input.value.value
                                      : props.input.value;
                                    return (
                                      <>
                                        <ApprenticePathwaySelect
                                          name={props.input.name}
                                          value={value}
                                          onChange={props.input.onChange}
                                          isMulti={false}
                                          hasRequestedHighSchool={false}
                                          meta={props.meta}
                                          metaOverride={
                                            value &&
                                            submitFailed === false &&
                                            this.props.showErrorsOnLoad && {
                                              touched: true,
                                              error: 'Required',
                                            }
                                          }
                                        >
                                          <label
                                            className="secondary-body"
                                            id="labelmaReferralSource"
                                          >
                                            <span>Pathway</span>
                                          </label>
                                        </ApprenticePathwaySelect>
                                      </>
                                    );
                                  }}
                                </Field>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                labelCssClass="editRoleSelect"
                                name="remoteStatus"
                                component={SelectField}
                                label="Remote Status"
                                options={remoteStatusTypes}
                              />
                            </div>
                            {this.props.showTeams && (
                              <div className="col-md-6">
                                <Field
                                  name="teamId"
                                  component={SelectField}
                                  label="Team"
                                  options={this.props.teams.map((x) => {
                                    return {
                                      value: x.id,
                                      label: x.name,
                                    };
                                  })}
                                  isDisabled={this.props.teamsLoading}
                                />
                              </div>
                            )}
                            <div className="col-md-12">
                              {this.props.locationOptions && (
                                <Field
                                  name="employerLocationId"
                                  component={SelectField}
                                  options={this.props.locationOptions
                                    .map((opt) => {
                                      return {
                                        value: opt.id,
                                        label: opt.label,
                                      };
                                    })
                                    .concat([
                                      {
                                        value: 'custom',
                                        label: '+ Add Custom Address',
                                      },
                                    ])}
                                  label="Location"
                                  isDisabled={isLocationDisabled(
                                    form.getState().values
                                  )}
                                />
                              )}
                            </div>
                            {form?.getFieldState('employerLocationId')
                              ?.value === 'custom' &&
                              form?.getFieldState('remoteStatus') !==
                                'Remote' && (
                                <div className="col-md-12">
                                  <FieldArray
                                    name="customLocation"
                                    component={LocationFieldArray}
                                    maxFields={1}
                                    validate={validateLocation}
                                    title="Custom Address"
                                  />
                                </div>
                              )}
                            <div className="col-md-3">
                              <Field
                                name="numberOfOpenings"
                                component={TextField}
                                label="Number of Openings"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="drugTest"
                                label="Drug Test?"
                                component={SelectField}
                                options={optionsYesNo}
                                labelCssClass="editRoleSelect"
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="backgroundCheck"
                                label="Background Check?"
                                component={SelectField}
                                options={optionsYesNo}
                                labelCssClass="editRoleSelect"
                              />
                            </div>
                          </div>
                          {maSegment === true && (
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  name="transportationAssistance"
                                  label="Transportation Assistance?"
                                  component={SelectField}
                                  options={optionsYesNo}
                                  labelCssClass="editRoleSelect"
                                />
                              </div>
                              <div className="col-md-6">
                                <Field
                                  name="publicTransportationAccess"
                                  label="Public Transportation Access?"
                                  component={SelectField}
                                  options={optionsYesNo}
                                />
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                name="info1"
                                component={TextAreaField}
                                label="What will this person do?"
                                maxLength={256}
                                showCounter={true}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                name="info2"
                                component={TextAreaField}
                                label="What will they learn? What will their impact be?"
                                maxLength={256}
                                showCounter={true}
                              />
                            </div>
                          </div>
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  name="ascendInsights"
                                  component={TextAreaField}
                                  label="Ascend Insights"
                                  maxLength={256}
                                  showCounter={true}
                                />
                              </div>
                            </div>
                          )}
                          <div>
                            <div className="col-md-12 padding-16 bd-whitebackground">
                              <RadioGroupField
                                name="roleStartFlexibility"
                                options={roleStartOptions}
                                legendText="Start Date"
                                legendClassName="normal-title padding-bottom-sixteen bold"
                              />

                              {form?.getFieldState('roleStartFlexibility')
                                ?.value === 'Specific' && (
                                <div className="col-md-6 padding-top-sixteen padding-right-sixteen">
                                  <Field
                                    name="availableToStart"
                                    component={TextField}
                                    label="Available to Start"
                                    maxLength={32}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          {maSegment === false && (
                            <>
                              <div className="row normal-title padding-bottom-sixteen bold">
                                <div className="col-md-12">
                                  Required Degree Type
                                </div>
                              </div>
                              <div>
                                <Field
                                  name="degreeTypeRankId"
                                  label={''}
                                  isDisabled={degreeTypes.length === 0}
                                  isLoading={degreeTypesLoading}
                                  component={SelectField}
                                  options={degreeTypes}
                                  metaOverride={
                                    !values.degreeTypeRankId &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>
                            </>
                          )}

                          {maSegment === false && (
                            <div className="normal-title bold padding-bottom-sixteen">
                              Candidate Preferences
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  name="industries"
                                  label="Industries"
                                  isDisabled={industries.length === 0}
                                  isLoading={industriesLoading}
                                  component={MultiSelectField}
                                  width="100%"
                                  options={industries}
                                  metaOverride={
                                    values.industries?.length === 0 &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>

                              <div className="col-md-6">
                                <Field
                                  name="regions"
                                  label="Regions"
                                  isDisabled={regions.length === 0}
                                  isLoading={regionsLoading}
                                  component={MultiSelectField}
                                  width="100%"
                                  options={regions}
                                  metaOverride={
                                    values.regions?.length === 0 &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  name="roleTypes"
                                  label="Role Types"
                                  isDisabled={roleTypes.length === 0}
                                  isLoading={roleTypesLoading}
                                  component={MultiSelectField}
                                  width="100%"
                                  options={roleTypes}
                                  metaOverride={
                                    values.roleTypes?.length === 0 &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <Field
                                  name="employmentTypes"
                                  label="Employment Types"
                                  isDisabled={employmentTypes.length === 0}
                                  isLoading={employmentTypesLoading}
                                  component={MultiSelectField}
                                  width="100%"
                                  options={employmentTypes}
                                  metaOverride={
                                    values.employmentTypes?.length === 0 &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="normal-title bold padding-bottom-sixteen">
                              Role Skills
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-6">
                                <div
                                  className="padding-16 bd-whitebackground"
                                  style={{
                                    borderRadius: '8px',
                                  }}
                                >
                                  <Field
                                    name="skillTags"
                                    component={SkillsModalFinalField}
                                    title="Preferred Skills"
                                    skillIdsToExclude={
                                      form
                                        .getState()
                                        .values.requiredSkillTags?.map(
                                          (skill) => skill.id
                                        ) ?? []
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className="padding-16 bd-whitebackground"
                                  style={{
                                    borderRadius: '8px',
                                  }}
                                >
                                  <Field
                                    name="requiredSkillTags"
                                    component={SkillsModalFinalField}
                                    title="Required Skills"
                                    skillIdsToExclude={
                                      form
                                        .getState()
                                        .values.skillTags?.map(
                                          (skill) => skill.id
                                        ) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div>
                            <div className="col-md-12 padding-16 bd-whitebackground margin-top-sixteen">
                              <RadioGroupField
                                name="payType"
                                options={payTypeOptions}
                                legendText="Wage Information"
                                legendClassName="normal-title padding-bottom-sixteen bold"
                              />
                              <div className="row padding-top-sixteen ">
                                {selectedPayType === 'Salary' && (
                                  <>
                                    <div className="col-md-6">
                                      <Field
                                        name="minimumYearlySalary"
                                        label="Minimum Salary"
                                        component={SelectField}
                                        isDisabled={this.state.isUnpaid}
                                        isClearable={true}
                                        options={
                                          this.state.isUnpaid
                                            ? [
                                                {
                                                  value: 'unpaid',
                                                  label: `Unpaid`,
                                                },
                                              ]
                                            : salaryPayOptions.map((opt) => {
                                                return {
                                                  value: opt.value,
                                                  label:
                                                    opt.hourly === null
                                                      ? `${opt.displayValue}`
                                                      : `${opt.displayValue} (${opt.hourly} / hour)`,
                                                };
                                              })
                                        }
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Field
                                        name="maximumYearlySalary"
                                        label="Maximum Salary"
                                        component={SelectField}
                                        isDisabled={isMaximumYearlySalaryDisabled(
                                          form.getState().values
                                        )}
                                        isClearable={true}
                                        options={
                                          this.state.isUnpaid
                                            ? [
                                                {
                                                  value: 'unpaid',
                                                  label: `Unpaid`,
                                                },
                                              ]
                                            : salaryPayOptions.map((opt) => {
                                                return {
                                                  value: opt.value,
                                                  label:
                                                    opt.hourly === null
                                                      ? `${opt.displayValue}`
                                                      : `${opt.displayValue} (${opt.hourly} / hour)`,
                                                };
                                              })
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                                {selectedPayType !== 'Salary' && (
                                  <>
                                    <div className="col-md-6">
                                      <Field
                                        name="minimumHourlyWage"
                                        label="Minimum Hourly Wage"
                                        component={SelectField}
                                        isDisabled={this.state.isUnpaid}
                                        isClearable={true}
                                        options={
                                          this.state.isUnpaid
                                            ? [
                                                {
                                                  value: 'unpaid',
                                                  label: `Unpaid`,
                                                },
                                              ]
                                            : hourlyPayOptions.map((opt) => {
                                                return {
                                                  value: opt.value,
                                                  label: opt.displayValue,
                                                };
                                              })
                                        }
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Field
                                        name="maximumHourlyWage"
                                        label="Maximum Hourly Wage"
                                        component={SelectField}
                                        isDisabled={isMaximumHourlyWageDisabled(
                                          form.getState().values
                                        )}
                                        isClearable={true}
                                        options={
                                          this.state.isUnpaid
                                            ? [
                                                {
                                                  value: 'unpaid',
                                                  label: `Unpaid`,
                                                },
                                              ]
                                            : hourlyPayOptions.map((opt) => {
                                                return {
                                                  value: opt.value,
                                                  label: opt.displayValue,
                                                };
                                              })
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {maSegment === false && (
                            <div className="normal-title padding-bottom-sixteen bold">
                              Showcase Employee
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-8">
                                <Field
                                  name="showcaseName"
                                  component={TextField}
                                  label="Employee Name"
                                  maxLength={128}
                                />
                              </div>
                              <div className="col-md-4">
                                <Field
                                  name="showcaseTitle"
                                  component={TextField}
                                  label="Employee Title"
                                  maxLength={128}
                                />
                              </div>
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  name="showcaseInfo"
                                  component={TextAreaField}
                                  label="Tell us about a day in the life of the employee"
                                  maxLength={512}
                                  showCounter={true}
                                />
                              </div>
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  name="showcaseAvatarUrl"
                                  component={AvatarField}
                                />
                              </div>
                              <div className="col-md-6" />
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="normal-title padding-bottom-sixteen bold">
                              URL to Apply
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  name="url"
                                  component={TextField}
                                  label="Where can candidates apply for this role?"
                                  maxLength={512}
                                  metaOverride={
                                    !values.url &&
                                    submitFailed === false &&
                                    this.props.showErrorsOnLoad && {
                                      touched: true,
                                      error: 'Required',
                                    }
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="normal-title padding-bottom-sixteen bold">
                              Application deadline:
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div
                                className="col-md-12"
                                id="applicationDeadlineContainer"
                              >
                                <Field
                                  name="applicationDeadline"
                                  component={SingleDatePickerField}
                                  isClearable={true}
                                  minDate={(() => {
                                    let today = new Date();
                                    let selectedDate = new Date(
                                      form.getState().values
                                        .applicationDeadline ?? today.getTime()
                                    );
                                    if (selectedDate < today)
                                      return selectedDate;
                                    return today;
                                  })()}
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          {maSegment === false && (
                            <div
                              className="normal-title padding-bottom-sixteen bold"
                              id="applicationLabel"
                            >
                              Application Information
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-12">
                                <Select
                                  className="clickable normal-subheader"
                                  value={
                                    this.state.applicationInstructionSelection
                                  }
                                  onChange={this.handleAppInstructionSelected}
                                  options={applicationInstructionsOpts}
                                  aria-labelledby="applicationLabel"
                                />
                              </div>
                            </div>
                          )}
                          {maSegment === false && (
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  name="applicationInstructions"
                                  component={TextAreaField}
                                  maxLength={512}
                                  label="Provide further instructions for applying to this role."
                                  showCounter={true}
                                />
                              </div>
                            </div>
                          )}

                          {maSegment === false && (
                            <fieldset>
                              <legend className="normal-title padding-bottom-sixteen bold">
                                Employee Benefits
                              </legend>
                              <div className="row padding-bottom-eight">
                                <div className="col-md-12">
                                  <div className="secondary-body">
                                    Select all of the benefits that apply
                                  </div>
                                </div>
                              </div>

                              <div className="row padding-bottom-thirtytwo">
                                {benefits.map((benefit) => (
                                  <div key={benefit.name} className="col-md-6">
                                    <div className="bd-whitebackground">
                                      <div className="inlineblock columnpad">
                                        <div className="normal-body cblabel">
                                          <Field
                                            label={benefit.label}
                                            name={benefit.name}
                                            type="checkbox"
                                            id={benefit.name}
                                            component={CheckboxField}
                                          />
                                        </div>
                                      </div>

                                      <div className="columnpad inlineblock float-right">
                                        <img alt="" src={benefit.image} />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          )}

                          {maSegment === false && (
                            <div className="normal-title padding-bottom-sixteen bold">
                              <div className="row padding-bottom-thirtytwo">
                                <div className="col-md-6">
                                  Role Rank
                                  <Field
                                    name="roleRank"
                                    component={SelectField}
                                    options={[
                                      {label: 'High', value: 1},
                                      {label: 'Medium', value: 2},
                                      {label: 'Low', value: 3},
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.values && maSegment === false && (
                            <div className="normal-title padding-bottom-sixteen bold">
                              <div className="row padding-bottom-thirtytwo">
                                <div className="col-md-6">
                                  Account Manager
                                  <div
                                    style={{
                                      fontSize: '1rem',
                                      fontWeight: 400,
                                      paddingLeft: '14px',
                                    }}
                                  >
                                    {this.findUserName(
                                      this.state.values.employerAccountManagerId
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {form.getState().submitFailed &&
                            form.getState().invalid && (
                              <div
                                className="padding-bottom-sixteen"
                                style={{clear: 'right'}}
                              >
                                <Alert color="danger">
                                  Role is invalid, check fields for errors.
                                </Alert>
                              </div>
                            )}
                        </Fragment>
                      )}
                      {totalPages !== 1 && page === 2 && (
                        <Fragment>
                          <SubHeader
                            text={'Job Description'}
                            subtext={
                              'Please describe the job opportunity, responsibilities, and full details of the position.'
                            }
                          />
                          <div className="row padding-bottom-sixteen">
                            <div className="col-md-12 ">
                              <Field
                                component={RichTextEdit}
                                name="description"
                              />
                            </div>
                          </div>
                        </Fragment>
                      )}
                      {totalPages !== 1 && page === 3 && (
                        <Fragment>
                          <SubHeader
                            text={'Additional Application Questions'}
                            subtext={
                              'Are there additional questions the apprentice must complete as a part of their application? If so, please add those below.'
                            }
                          />
                          <div className="row padding-bottom-sixteen">
                            <div className="col-md-12">
                              <FieldArray
                                name="additionalQuestions"
                                component={TextAreaFinalFieldArray}
                                maxLength={500}
                                showCounter={true}
                                labelMaker={(i) => `Question ${i + 1}`}
                                addButtonDescription="Add Question"
                                removeButtonDescription="Remove Question"
                              ></FieldArray>
                            </div>
                          </div>
                          <div className="row padding-bottom-sixteen">
                            <div className="col-md-12">
                              <Field
                                name="resumeRequired"
                                selectedValue="Resume Required: Yes"
                                unSelectedValue="Resume Required: No"
                                component={OnOffSwitch}
                              />
                            </div>
                          </div>
                        </Fragment>
                      )}
                      {/* buttons */}
                      <div
                        style={{clear: 'right'}}
                        className="padding-bottom-thirtytwo"
                      >
                        {mode === 'create' && (
                          <div>
                            {page === 1 && (
                              <div className="float-left">
                                <Field
                                  name="published"
                                  selectedValue="Status: Published"
                                  unSelectedValue="Status: Draft"
                                  component={OnOffSwitch}
                                />
                              </div>
                            )}
                            {maSegment === false && (
                              <div className="float-left ms-3">
                                <Field
                                  name="scraped"
                                  selectedValue="Scraped: Yes"
                                  unSelectedValue="Scraped: No"
                                  component={OnOffSwitch}
                                />
                              </div>
                            )}

                            <div className="float-right" role="group">
                              <NavigationActionButtons
                                disabled={form.getState().submitting}
                                page={page}
                                totalPages={totalPages}
                                handleSubmitClick={handleSubmit}
                                handlePreviousClick={() =>
                                  this.setState(() => ({
                                    page: page - 1,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        )}

                        {mode === 'edit' && (
                          <div>
                            {page === 1 && (
                              <div className="float-left">
                                <Field
                                  name="published"
                                  selectedValue="Status: Published"
                                  unSelectedValue="Status: Draft"
                                  component={OnOffSwitch}
                                />
                              </div>
                            )}
                            {maSegment === false && (
                              <div className="float-left ms-3">
                                <Field
                                  name="scraped"
                                  selectedValue="Scraped: Yes"
                                  unSelectedValue="Scraped: No"
                                  component={OnOffSwitch}
                                />
                              </div>
                            )}
                            <div className="float-right" role="group">
                              {allowJobEdit && page === 1 && (
                                <button
                                  type="button"
                                  className="clickable btn btn-transparent"
                                  onClick={this.handleDeleteClicked}
                                  disabled={form.getState().submitting}
                                >
                                  Archive
                                </button>
                              )}
                              <NavigationActionButtons
                                disabled={form.getState().submitting}
                                page={page}
                                totalPages={totalPages}
                                handleSubmitClick={handleSubmit}
                                handlePreviousClick={() =>
                                  this.setState(() => ({
                                    page: page - 1,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        )}

                        {mode === 'clone' && (
                          <div>
                            {page === 1 && (
                              <div className="float-left">
                                <Field
                                  name="published"
                                  selectedValue="Status: Published"
                                  unSelectedValue="Status: Draft"
                                  component={OnOffSwitch}
                                />
                              </div>
                            )}
                            <div className="float-left ms-3">
                              <Field
                                name="scraped"
                                selectedValue="Scraped: Yes"
                                unSelectedValue="Scraped: No"
                                component={OnOffSwitch}
                              />
                            </div>

                            <div className="float-right" role="group">
                              <NavigationActionButtons
                                disabled={form.getState().submitting}
                                page={page}
                                totalPages={totalPages}
                                handleSubmitClick={handleSubmit}
                                handlePreviousClick={() =>
                                  this.setState(() => ({
                                    page: page - 1,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
          </AscendModalBody>
        </AscendModal>
      </div>
    );
  }
}

JobForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  published: PropTypes.bool,
  roleStartFlexibility: PropTypes.string,
  payType: PropTypes.string,
  industries: PropTypes.array,
  industriesLoading: PropTypes.bool,
  segments: PropTypes.array,
  segmentsLoading: PropTypes.bool,
  regions: PropTypes.array,
  regionsLoading: PropTypes.bool,
  roleTypes: PropTypes.array,
  roleTypesLoading: PropTypes.bool,
  employmentTypes: PropTypes.array,
  employmentTypesLoading: PropTypes.bool,
  degreeTypes: PropTypes.array,
  degreeTypesLoading: PropTypes.bool,
  getIndustries: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  getEmploymentTypes: PropTypes.func.isRequired,
  getDegreeTypesRank: PropTypes.func.isRequired,
  getSegments: PropTypes.func.isRequired,
  scraped: PropTypes.bool,
  roleRank: PropTypes.number,
  allowJobEdit: PropTypes.bool,
  getUsersByPermission: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  usersLoading: PropTypes.bool,
  isMaSegment: PropTypes.bool,
  totalPages: PropTypes.number,
  initialValues: PropTypes.object,
  jobId: PropTypes.any,
  canEditJob: PropTypes.func,
  getEmployerLocations: PropTypes.func,
  locationOptions: PropTypes.arrayOf(PropTypes.object),
  locationsLoading: PropTypes.bool,
  employerId: PropTypes.number,
  profile: PropTypes.object,
  showErrorsOnLoad: PropTypes.bool,
  teams: PropTypes.arrayOf(PropTypes.object),
  teamsLoading: PropTypes.bool,
  getTeamsByEmployer: PropTypes.func,
  showTeams: PropTypes.bool,
};

JobForm.defaultProps = {
  isOpen: false,
  isMaSegment: false,
  showErrorsOnLoad: false,
  showTeams: true,
};

const actions = {
  ...roleActions,
  ...employmentActions,
  ...industriesActions,
  ...regionsActions,
  ...degreeTypeRankActions,
  ...segmentActions,
  ...usersActions,
  canEditJob,
  getEmployerLocations,
  getTeamsByEmployer,
};

let CreateJobForm = (props) => <JobForm {...props} mode="create" />;

const filterSegmentsForJobForm = (segments) => {
  return segments.filter((x) => x.showOnJobForm);
};

const createMapStateToProps = (state) => {
  return {
    skillTagsAllowed: state.skillTags.tags,
    initialValues: defaults,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    segments: filterSegmentsForJobForm(state.segments.segmentList),
    segmentsLoading: state.segments.segmentsLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    roleTypes: state.roleTypes.roleTypes,
    roleTypesLoading: state.roleTypes.roleTypesLoading,
    employmentTypes: state.employmentTypes.employmentTypes,
    employmentTypesLoading: state.employmentTypes.employmentTypesLoading,
    degreeTypes: state.degreeTypeRank.degreeRank,
    degreeTypesLoading: state.degreeTypeRank.degreeRankLoading,
    usersList: state.users.usersListByPermission,
    usersLoading: state.users.usersLoading,
    totalPages: 1,
    locationOptions: state.employers.locations,
    locationsLoading: state.employers.locationsLoading,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
  };
};

CreateJobForm = connect(createMapStateToProps, actions)(CreateJobForm);

let EditJobForm = (props) => <JobForm {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  const published = state.jobs.jobForEdit
    ? state.jobs.jobForEdit.published
    : false;

  const jobId = state.jobs.jobForEdit ? state.jobs.jobForEdit.id : null;
  let maSegment = state.jobs.jobForEdit
    ? state.jobs.jobForEdit.segments
    : false;
  maSegment = maSegment && maSegment.some((x) => x.isApprenticeProgram);
  return {
    isMaSegment: maSegment,
    skillTagsAllowed: state.skillTags.tags,
    usersList: state.users.usersListByPermission,
    initialValues: state.jobs.jobForEdit,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    segments: filterSegmentsForJobForm(state.segments.segmentList),
    segmentsLoading: state.segments.segmentsLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    roleTypes: state.roleTypes.roleTypes,
    roleTypesLoading: state.roleTypes.roleTypesLoading,
    employmentTypes: state.employmentTypes.employmentTypes,
    employmentTypesLoading: state.employmentTypes.employmentTypesLoading,
    degreeTypes: state.degreeTypeRank.degreeRank,
    degreeTypesLoading: state.degreeTypeRank.degreeRankLoading,
    allowJobEdit: state.authorization.job.canEdit,
    usersLoading: state.users.usersLoading,
    jobId: jobId,
    currentUserId: state.profile.id,
    published,
    totalPages: maSegment ? 3 : 1,
    locationOptions: state.employers.locations,
    locationsLoading: state.employers.locationsLoading,
    profile: state.profile,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
  };
};

EditJobForm = connect(editMapStateToProps, actions)(EditJobForm);

let CloneJobForm = (props) => <JobForm {...props} mode="clone" />;

const cloneMapStateToProps = (state) => {
  const published = state.jobs.jobForClone
    ? state.jobs.jobForClone.published
    : false;

  let maSegment = state.jobs.jobForClone?.segments;
  maSegment = maSegment && maSegment.some((x) => x.isApprenticeProgram);

  return {
    isMaSegment: maSegment,
    skillTagsAllowed: state.skillTags.tags,
    initialValues: state.jobs.jobForClone,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    segments: filterSegmentsForJobForm(state.segments.segmentList),
    segmentsLoading: state.segments.segmentsLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    roleTypes: state.roleTypes.roleTypes,
    roleTypesLoading: state.roleTypes.roleTypesLoading,
    employmentTypes: state.employmentTypes.employmentTypes,
    employmentTypesLoading: state.employmentTypes.employmentTypesLoading,
    degreeTypes: state.degreeTypeRank.degreeRank,
    degreeTypesLoading: state.degreeTypeRank.degreeRankLoading,
    usersList: state.users.usersListByPermission,
    usersLoading: state.users.usersLoading,
    published,
    totalPages: maSegment ? 3 : 1,
    locationOptions: state.employers.locations,
    locationsLoading: state.employers.locationsLoading,
    jobId: state.jobs.jobForClone?.id ?? null,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
  };
};

CloneJobForm = connect(cloneMapStateToProps, actions)(CloneJobForm);

export {CreateJobForm, EditJobForm, CloneJobForm};
