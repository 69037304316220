import {combineReducers} from 'redux';
import flashMessages from './flashMessages';
import users from './users';
import employers from './employers';
import teams from './teams';
import jobs from './jobs';
import recruiters from './recruiters';
import ascendRecruiters from './ascendRecruiters';
import matchParameters from './matchParameters';
import seekers from './seekers';
import skillTags from './skillTags';
import invitationCodes from './invitationCodes';
import profile from './profile';
import invitations from './invitations';
import candidateSearch from './candidateSearch';
import allJobSearch from './allJobSearch';
import jobAndTeamSearch from './jobAndTeamSearch';
import recruiterJobAndTeamSearch from './recruiterJobAndTeamSearch';
import zipcodes from './zipcodes';
import signup from './signup';
import recruiterJobCards from './recruiterJobCards';
import allJobsDataTable from './allJobsDataTable';
import candidatesDataTable from './candidatesDataTable';
import employersDataTable from './employersDataTable';
import companyRecruitersDataTable from './companyRecruitersDataTable';
import teamsDataTable from './teamsDataTable';
import jobsDataTable from './jobsDataTable';
import allMatchesDataTable from './allMatchesDataTable';
import teamProfileDataTable from './teamProfileDataTable';
import institutions from './institutions';
import applications from './applications';
import applicationsDataTable from './applicationsDataTable';
import invitationsDataTable from './invitationsDataTable';
import employmentTypes from './employmentTypes';
import roleTypes from './roleTypes';
import industries from './industries';
import regions from './regions';
import workStyles from './workStyles';
import companies from './companies';
import strategicBusinessUnits from './strategicBusinessUnits';
import {default as resumes} from './resumes';
import {default as authentication} from './authentication';
import {default as applicants} from './ApplicantsReducer';
import degreeTypeRank from './DegreeTypeReducer';

import {default as ascendDataTable} from './AscendDataTableReducer';
import {default as intakeCache} from './IntakeCacheReducer';
import {default as utility} from './UtilityReducer';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {persistReducer, createTransform} from 'redux-persist';
import {default as segments} from './SegmentsReducer';
import {default as candidateFilters} from './candidateFiltersReducer';
import {default as authorization} from './AuthorizationReducer';
import {default as questions} from './QuestionsReducer';
import {default as jobAppBrowse} from './jobAppBrowse';
import tasksReducer from './tasksReducer';
import meetingStatuses from './meetingStatuses';
import profileStatuses from './profileStatuses';
import experienceLevels from './experienceLevels';
import apprenticePathways from './apprenticePathways';
import candidateDiscoveryReducer from './candidateDiscoveryReducer';
import connections from './connections';
import connectionsDataTable from './connectionsDataTable';

const removeResumeTransform = createTransform(
  (inboundState, key) => {
    return {...inboundState};
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // Never cache the Connection Form Resume. Form gets
    // "Stuck" on refreshing the page.
    let returnState = {...outboundState};
    let connectionKeys = ['connectionForm', 'finishConnectionForm'];
    for (let i = 0; i < connectionKeys.length; i++) {
      let form_key = connectionKeys[i];
      if (!returnState[form_key]) {
        continue;
      }
      returnState[form_key] = {
        ...outboundState[form_key],
        values: {
          ...outboundState[form_key].values,
          resume: null,
        },
      };
    }
    return returnState;
  },
  // define which reducers this transform gets called for.
  {whitelist: ['form']}
);

const persistConfig = {
  key: 'ascend-network-redux-cache',
  storage,
  whitelist: ['form'],
  transforms: [removeResumeTransform],
};

export const rootReducer = (routerReducer) => {
  const root = combineReducers({
    router: routerReducer,
    allJobsDataTable,
    allMatchesDataTable,
    applicants,
    applications,
    applicationsDataTable,
    ascendDataTable,
    ascendRecruiters,
    authentication,
    authorization,
    candidateDiscoveryReducer,
    candidateFilters,
    candidateSearch,
    candidatesDataTable,
    companyRecruitersDataTable,
    degreeTypeRank,
    employers,
    employersDataTable,
    employmentTypes,
    experienceLevels,
    flashMessages,
    industries,
    institutions,
    intakeCache,
    invitationCodes,
    invitations,
    invitationsDataTable,
    allJobSearch,
    jobAndTeamSearch,
    jobAppBrowse,
    jobs,
    jobsDataTable,
    matchParameters,
    meetingStatuses,
    apprenticePathways,
    profile,
    profileStatuses,
    questions,
    recruiterJobAndTeamSearch,
    recruiterJobCards,
    recruiters,
    regions,
    resumes,
    roleTypes,
    seekers,
    segments,
    signup,
    skillTags,
    strategicBusinessUnits,
    tasksReducer,
    teamProfileDataTable,
    teams,
    teamsDataTable,
    users,
    utility,
    workStyles,
    companies,
    zipcodes,
    connections,
    connectionsDataTable,
  });
  return persistReducer(persistConfig, root);
};
