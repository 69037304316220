import * as types from './ActionTypes';
import {makeRequest} from '../services/api';

export const canEditSeeker = (seekerId) => async (dispatch) => {
  dispatch({
    type: types.SEEKER__GET_CAN_EDIT_REQUEST,
  });
  makeRequest({
    method: 'GET',
    path: `segments/verify/can_edit_seeker/${seekerId}`,
  })
    .then((response) => {
      dispatch({
        type: types.SEEKER__GET_CAN_EDIT_SUCCESS,
        response: {canEdit: response.data.canEdit, seekerId: seekerId},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.SEEKER__GET_CAN_EDIT_FAILURE,
        payload: err,
      });
    });
};

export const canEditEmployer = (employerId) => async (dispatch) => {
  dispatch({
    type: types.EMPLOYER__GET_CAN_EDIT_REQUEST,
  });
  makeRequest({
    method: 'GET',
    path: `segments/verify/can_edit_employer/${employerId}`,
  })
    .then((response) => {
      dispatch({
        type: types.EMPLOYER__GET_CAN_EDIT_SUCCESS,
        response: {canEdit: response.data.canEdit, employerId: employerId},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.EMPLOYER__GET_CAN_EDIT_FAILURE,
        payload: err,
      });
    });
};

export const canEditJob = (jobId) => async (dispatch) => {
  dispatch({
    type: types.JOB__GET_CAN_EDIT_REQUEST,
  });

  makeRequest({
    method: 'GET',
    path: `segments/verify/can_edit_job/${jobId}`,
  })
    .then((response) => {
      dispatch({
        type: types.JOB__GET_CAN_EDIT_SUCCESS,
        response: {canEdit: response.data.canEdit, jobId: jobId},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.JOB__GET_CAN_EDIT_FAILURE,
        payload: err,
      });
    });
};
