import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Avatar from '../../components/Avatar';
import MaximizedSeekerItem from './MaximizedSeekerItem';

class SeekerListPanel extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.maximized !== this.props.maximized &&
      this.props.seekers.length > 0
    ) {
      this.focusFirstMatchedSeeker();
    }
  }
  focusFirstMatchedSeeker() {
    const firstSeeker = document.getElementById(
      `seekerAvatarLink-${this.props.seekers[0].id}`
    );
    if (firstSeeker) {
      firstSeeker.focus();
    }
  }
  render() {
    const avatars = (seekers) => {
      return seekers.map((seeker, i) => {
        return (
          <div className="inlineblock padding-h4 clickable" key={i}>
            <Link to={`/candidate/${seeker.id}`}>
              <Avatar url={seeker.avatarUrl} />
            </Link>
          </div>
        );
      });
    };

    if (this.props.maximized) {
      return (
        <div className="row">
          <table
            style={{width: '100%'}}
            className="padding-8"
            role="presentation"
          >
            <tbody>
              {this.props.seekers.map((seeker, i) => {
                return (
                  <MaximizedSeekerItem
                    key={i}
                    seeker={seeker}
                    jobTraits={this.props.jobTraits}
                    allInvitations={this.props.allInvitations}
                    allHandpicked={this.props.allHandpicked}
                  />
                );
              })}
            </tbody>
          </table>
          {this.props.totalSeekers > 0 && (
            <div style={{width: '100%'}}>
              <button
                className="inlineblock clickable active-caption buttonAsLinkTeamCard"
                style={{
                  paddingLeft: '8px',
                  paddingTop: '8px',
                  textDecoration: 'underline',
                }}
                onClick={this.props.onViewMoreClicked}
              >
                View more
              </button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-8">{avatars(this.props.seekers)}</div>
        </div>
      );
    }
  }
}

SeekerListPanel.propTypes = {
  seekers: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalSeekers: PropTypes.number.isRequired,
  jobTraits: PropTypes.object,
  allInvitations: PropTypes.arrayOf(PropTypes.object).isRequired,
  allHandpicked: PropTypes.arrayOf(PropTypes.object).isRequired,
  maximized: PropTypes.bool.isRequired,
  onViewMoreClicked: PropTypes.func.isRequired,
};

export default SeekerListPanel;
