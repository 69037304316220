import React from 'react';
import PropTypes from 'prop-types';

class StatsTable extends React.Component {
  render() {
    const {employer} = this.props;
    let teamCount = employer.draftTeamCount + employer.publishedTeamCount;
    if (isNaN(teamCount)) {
      teamCount = '';
    }
    let jobCount = employer.draftJobCount + employer.publishedJobCount;
    if (isNaN(jobCount)) {
      jobCount = '';
    }

    return (
      <div className="row">
        <div className="col-md-4">
          <table style={{width: '100%'}} className="bgcolorw">
            <tbody>
              <tr>
                <td
                  colSpan="3"
                  className="bd-darkgraybackground columnpad text-align-center"
                >
                  <h2
                    className="normal-title bold"
                    style={{lineHeight: '30px', margin: 'initial'}}
                  >
                    Teams
                  </h2>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '33%',
                    verticalAlign: 'middle',
                    borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                  }}
                  className="columnpad text-align-center"
                >
                  <div className="accent-1-display bold">
                    {employer.publishedTeamCount}
                  </div>
                  <div className="disabled-subheader">Published</div>
                </td>
                <td
                  style={{
                    width: '33%',
                    verticalAlign: 'middle',
                    borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                  }}
                  className="columnpad text-align-center"
                >
                  <div className="accent-1-display bold">
                    {employer.draftTeamCount}
                  </div>
                  <div className="disabled-subheader">Draft</div>
                </td>
                <td
                  style={{width: '33%', verticalAlign: 'middle'}}
                  className="columnpad text-align-center"
                >
                  <div className="accent-1-display bold">{teamCount}</div>
                  <div className="disabled-subheader">Total</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-4">
          <table style={{width: '100%'}} className="bgcolorw">
            <tbody>
              <tr>
                <td
                  colSpan="3"
                  className="bd-darkgraybackground columnpad text-align-center"
                >
                  <h2
                    className="normal-title bold"
                    style={{lineHeight: '30px', margin: 'initial'}}
                  >
                    Roles
                  </h2>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '33%',
                    verticalAlign: 'middle',
                    borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                  }}
                  className="columnpad text-align-center"
                >
                  <div className="accent-1-display bold">
                    {employer.publishedJobCount}
                  </div>
                  <div className="disabled-subheader">Published</div>
                </td>
                <td
                  style={{
                    width: '33%',
                    verticalAlign: 'middle',
                    borderRight: '1px solid rgba(0, 78, 125, 0.25)',
                  }}
                  className="columnpad text-align-center"
                >
                  <div className="accent-1-display bold">
                    {employer.draftJobCount}
                  </div>
                  <div className="disabled-subheader">Draft</div>
                </td>
                <td
                  style={{width: '33%', verticalAlign: 'middle'}}
                  className="columnpad text-align-center"
                >
                  <div className="accent-1-display bold">{jobCount}</div>
                  <div className="disabled-subheader">Total</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-4"></div>
      </div>
    );
  }
}

StatsTable.propTypes = {
  employer: PropTypes.object.isRequired,
};

export default StatsTable;
