import React from 'react';
import {withRouter} from '../../utils/commonHooks';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import track from 'react-tracking';
import {findNextPage} from '../../utils/modernApprenticeship/modernApprenticeshipCommon';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import LoadingOverlay from 'react-loading-overlay';
import MaBasicUserInfo from '../../components/modernApprenticeship/MaBasicUserInfo';
import MaInterestAssessment from '../../components/modernApprenticeship/MaInterestAssessment';
import * as seekersActions from '../../actions/SeekerActions';
import * as codesActions from '../../actions/invitationCodes';
import * as profileActions from '../../actions/profile';
import * as employmentTypes from '../../actions/employmentTypes';
import {LoadingPage} from '..';
import * as institutionActions from '../../actions/institutions';

@track({eventName: 'ModernApprenticeship'}, {dispatchOnMount: true})
class ModernApprenticeshipForm extends React.Component {
  state = {
    page: 1,
    loading: true,
    totalPages: 2,
    prevPage: [],
    initialValuesLoaded: false,
    initialValues: null,
    invitationCode: null,
    seekerInfo: {},
    highSchoolChanged: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      page: 1,
      invitationCode: this.getInvitationCode(),
      isMobile: window.matchMedia('(max-width: 991px)').matches,
    };
  }

  getInvitationCode = () => {
    const pathName = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    switch (pathName) {
      case '/map/indy':
      case '/ma/indy':
        return 'b7e8f711edc5';
      case '/map/uplands':
      case '/ma/uplands':
        return 'fff75d5b0c57';
      case '/map/evansville':
      case '/ma/evansville':
        return 'dc955cfa1e49';
      case '/map/hamilton':
      case '/ma/hamilton':
        return '0eb93e4a0725';
      default:
        return params.get('code');
    }
  };

  componentDidMount() {
    const handler = (e) => this.setState({matches: e.matches});
    window.matchMedia('(max-width: 430px)').addListener(handler); // This is the only solution that works in safari at the moment
    this.setState({loading: false});

    this.props.getEducationLevels();
    this.props.getEmploymentTypes();

    if (this.state.invitationCode) {
      this.props.validateInvitationCode(this.state.invitationCode);
    }
  }

  previousPage = (e, values) => {
    let pageHistory = this.state.prevPage.filter((x) => x !== this.state.page);
    const newValues = {...this.state.seekerInfo, ...values};

    this.setState({seekerInfo: {...newValues}});
    this.setState({prevPage: pageHistory});
    let page = pageHistory.slice(-1)[0];

    if (this.state.page > 1) {
      this.moveToPage({
        page: page,
        pages: pageHistory,
        totalPages: this.state.totalPages,
      });
    }
    e.preventDefault();
  };

  sendFirstMapAdTrack = () => {
    if (this.state.page > 1) {
      return;
    }

    const haveSentAdTrack = localStorage.getItem('firstMapContinue');
    if (haveSentAdTrack !== null) {
      return;
    }

    if (!process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
      // Only run this code if the PIXEL ID environment variable
      // is set. Should only be set in production environment.
      return;
    }

    ReactPixel.trackCustom('First MAP Continue Click');
    window.gtag('event', 'conversion', {
      send_to: 'AW-753938058/wvldCNaFn_MBEIrdwOcC',
      event_callback: null,
    });
    localStorage.setItem('firstMapContinue', true);
  };

  nextPage = (props) => {
    this.sendFirstMapAdTrack();
    const highSchoolChanged =
      props.highSchoolId !== undefined &&
      props.highSchoolId !== this.state.seekerInfo.highSchoolId;

    const newValues = {...this.state.seekerInfo, ...props};

    this.setState({
      seekerInfo: {...newValues},
      highSchoolChanged: highSchoolChanged,
    });

    let pageHistory = this.storePreviousPage(this.state.page);
    const nextPage = findNextPage(
      this.state.page,
      this.state.totalPages,
      props
    );
    if (nextPage) {
      let page = nextPage[0];
      let totalPages = nextPage[1];

      this.moveToPage({page: page, totalPages: totalPages, pages: pageHistory});
    }
  };

  moveToPage = (props) => {
    window.scrollTo(0, 0);
    this.setState({page: props.page});
  };

  storePreviousPage = (page) => {
    if (this.state.prevPage && this.state.prevPage.includes(page) === false) {
      const pages = this.state.prevPage.concat(page);
      this.setState({prevPage: pages});
      return pages;
    }
    return this.state.prevPage;
  };

  updateSeeker = async (formValues) => {
    const pathways = formValues.apprenticePathwaySelections.map((a) => a.value);

    const empTypeId = this.props.employmentTypes
      .filter((y) => y.name === 'Apprenticeship')
      .map((z) => z.id);

    const seekerDegreeType = this.props.educationLevels.find(
      (x) => x.name === 'High school diploma or equivalent'
    );

    const {profile} = this.props;

    const seeker = {
      ...formValues,
      yaIntake: true,
      apprenticePathwayIds: pathways,
      segments: [{id: this.props.segment.id}],
      employmentTypeIds: empTypeId,
      invitationCode: this.state.invitationCode,
      educationLevelId: seekerDegreeType.id,
      termsAgreed: false,
      id: profile.seekerId,
      connectionFormCompletedOn: new Date(),
    };

    delete seeker.apprenticePathwaySelections;
    delete seeker.county;

    await this.props.updateSeekerAction(seeker, true);
    this.props.modernApprenticeIntakeComplete();
    this.props.history.push('/candidatehome');
  };

  handleSubmit = async (props) => {
    const formValues = this.state.seekerInfo
      ? {...this.state.seekerInfo, ...props}
      : {};

    await this.updateSeeker(formValues);
  };

  render() {
    const {page, totalPages, invitationCode} = this.state;
    const {validating, isValid} = this.props;

    if (!invitationCode || (!validating && !isValid)) {
      return (
        <div className="bd-graybackground padding-64">
          <p>Your invitation code is invalid.</p>
          <p>
            Please email{' '}
            <a href="mailto:support@ascendindiana.com">
              support@ascendindiana.com
            </a>{' '}
            if you have questions.
          </p>
        </div>
      );
    }

    if (validating) {
      return (
        <LoadingPage
          title="Validating Code"
          message="Checking your invitation code."
        />
      );
    }

    return (
      <DocumentTitle title="Ascend Modern Apprenticeship">
        <div className="fullindy">
          <ReactTooltip
            id="tooltip"
            className="solid-tooltip-dark"
            closeOnEsc={true}
          />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <LoadingOverlay
                  active={this.state.loading}
                  spinner={true}
                  text="Loading information ..."
                  fadeSpeed={200}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      height: '100vh',
                      position: 'fixed',
                    }),
                  }}
                >
                  <span>&nbsp;</span>
                </LoadingOverlay>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8 normal-body-white text-align-right">
                Page {page} of {totalPages}
              </div>
              <div className="col-md-2" />
            </div>
            <div className="row">
              <div className="col-md-12">
                {page === 1 && (
                  <MaBasicUserInfo
                    onSubmit={this.nextPage}
                    segment={this.state.segment}
                    isMobile={this.state.isMobile}
                    previousPage={this.previousPage}
                    initialValues={this.state.seekerInfo}
                  />
                )}
                {page === 2 && (
                  <MaInterestAssessment
                    onSubmit={this.handleSubmit}
                    segment={this.state.segment}
                    isMobile={this.state.isMobile}
                    previousPage={this.previousPage}
                    initialValues={this.state.seekerInfo}
                    highSchoolChanged={this.state.highSchoolChanged}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ModernApprenticeshipForm.propTypes = {
  location: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  validateInvitationCode: PropTypes.func.isRequired,
  validating: PropTypes.bool, // invitationCodes
  isValid: PropTypes.bool, // invitationCodes
  updateSeekerAction: PropTypes.func.isRequired,
  educationLevels: PropTypes.array,
  getEducationLevels: PropTypes.func.isRequired,
  employmentTypes: PropTypes.array,
  getEmploymentTypes: PropTypes.func.isRequired,
  segment: PropTypes.object,
  modernApprenticeIntakeComplete: PropTypes.func,
  profile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

ModernApprenticeshipForm.defaultProps = {
  segment: {},
};

const mapStateToProps = (state) => {
  return {
    validating: state.invitationCodes.validating,
    isValid: state.invitationCodes.isValid,
    educationLevels: state.institutions.educationLevels,
    employmentTypes: state.employmentTypes.employmentTypes,
    segment: state.invitationCodes.segment,
    profile: state.profile,
  };
};
const mapDispatchToProps = {
  ...seekersActions,
  ...codesActions,
  ...institutionActions,
  ...employmentTypes,
  ...profileActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModernApprenticeshipForm)
);
