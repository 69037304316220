import React from 'react';
import * as Layout from 'src/layout';
import {useNavigate} from 'react-router';

export default function RecentlyReportedConnectionModal(params) {
  const navigate = useNavigate();
  const closeForm = () => {
    params.onClose();
  };
  const recentConnection = params.recentConnection;

  return (
    <Layout.AscendModal size="lg" isOpen={params.isOpen} onClose={closeForm}>
      <Layout.AscendModalHeader headerClass="bd-modalheader" hasClose={false}>
        <center className="padding-bottom-eight bold normal-display-white">
          Recent Connection Reported
        </center>
      </Layout.AscendModalHeader>

      <Layout.AscendModalBody>
        <div>
          It looks like {recentConnection.seekerName} has a connection reported
          to their profile in the last 30 days. Connection details:
        </div>

        <div className="pt-3 ps-3">
          Title:&nbsp;<b>{recentConnection.jobName}</b>
        </div>

        <div className="pt-3 ps-3">
          Employer:&nbsp;<b>{recentConnection.employerName}</b>
        </div>

        <div className="pt-3 ps-3">
          Employment type:&nbsp;<b>{recentConnection.employmentType}</b>
        </div>

        <div className="pt-3 ps-3">
          Connection reported on:&nbsp;
          <b>
            {new Date(
              recentConnection.connectionReportingDate
            ).toLocaleDateString()}
          </b>
        </div>

        <div className="pt-3 ps-3">
          Reported by:&nbsp;<b>{recentConnection.reportedByName}</b>
        </div>

        {recentConnection.verifiedByName && (
          <div className="pt-3 ps-3">
            Verified by:&nbsp;<b>{recentConnection.verifiedByName}</b>
          </div>
        )}

        <div className="row pt-4" style={{justifyContent: 'space-evenly'}}>
          <button
            className="clickable btn btn-secondary col-4 padding-right-eight"
            onClick={() => closeForm()}
          >
            Back
          </button>
          <button
            type="button"
            className="clickable btn btn-primary col-4"
            onClick={() => navigate('/admin/connections')}
          >
            Go to Connections Table
          </button>
        </div>
      </Layout.AscendModalBody>
    </Layout.AscendModal>
  );
}
