import React, {Fragment} from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {withRouter} from '../../utils/commonHooks';
import {debounce} from 'lodash';
import * as jobActions from 'src/actions/jobs';
import LoadingOverlay from 'react-loading-overlay';

import * as jobAppBrowseActions from 'src/actions/jobAppBrowse';
import * as searchActions from '../../actions/AscendDataTableAction';

import {PageHeader} from 'src/layout';
import AscendDataTable from 'src/components/AscendDataTable';
import DocumentTitle from 'src/components/vendor/DocumentTitle';
import {addFlashMessage} from 'src/actions/flash';
import styles from './JobApplicationsPage.module.scss';
import {PERMISSIONS, has_permission} from 'src/services/authorizationApi';
import {format} from 'date-fns';
import {InfoSvg} from 'src/assets/svg/svgComponents';

class ApplicationStatusSelect extends React.Component {
  render() {
    const statuses = [
      {value: undefined, label: ''},
      {value: 1, label: 'In Review'},
      {value: 2, label: 'Declined'},
      {value: 3, label: 'Interviewing'},
      {value: 4, label: 'Offer Extended'},
      {value: 5, label: 'Offer Accepted'},
      {value: 6, label: 'Offer Declined'},
    ];
    return (
      <select
        tabIndex={0}
        aria-label="Application Status Select"
        className="clickable normal-subheader form-control form-select"
        style={{
          backgroundSize: '10px 10px',
          backgroundPosition: 'right 0.25rem center',
          width: '160px',
        }}
        onChange={(e) => this.props.onChange(e.target.value, this.props.ids)}
        value={this.props.value || undefined}
      >
        {statuses.map((s) => (
          <option key={s.label} value={s.value}>
            {s.label}
          </option>
        ))}
      </select>
    );
  }
}
ApplicationStatusSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  ids: PropTypes.shape({
    jobApplicantId: PropTypes.number,
    jobApplicationId: PropTypes.number,
  }),
  record: PropTypes.object,
};

export class JobTypeSelect extends React.Component {
  render() {
    return (
      <Fragment>
        <label id="roleTypeLabel" className="normal-caption bold">
          Filter applications by role type:
        </label>
        <select
          aria-labelledby="roleTypeLabel"
          name="roleType"
          value={this.props.currentJobTypeSelection}
          className="clickable normal-subheader form-control form-select"
          onChange={(e) => this.props.onChange(e.target.value)}
          disabled={this.props.disabled}
        >
          <option value="all">All</option>
          <option value="apprenticeships">Apprenticeships</option>
          <option value="non-apprenticeships">Jobs and Internships</option>
        </select>
      </Fragment>
    );
  }
}
JobTypeSelect.propTypes = {
  currentJobTypeSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

class JobsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentJobSelection: props.currentJobSelection || 0,
    };
    this.handleChangeDebounced = debounce(this.doChange, 750);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.currentJobSelection !== prevState.currentJobSelection &&
      prevState.currentJobSelection
    ) {
      return {currentJobSelection: prevState.currentJobSelection};
    } else {
      return {currentJobSelection: nextProps.currentJobSelection};
    }
  }

  doChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.currentJobSelection);
    }
  }

  handleChange = (evt) => {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({
      [name]: value,
    });

    this.handleChangeDebounced();
  };

  render() {
    const {roles} = this.props;
    let dd = [
      <option key={0} value={0}>
        All
      </option>,
    ];
    dd.push(
      <option className="bold" key="published" disabled>
        -- Published --
      </option>
    );
    dd = [
      ...dd,
      roles
        .filter((r) => r.published)
        .map((r) => (
          <option key={r.jobId} value={r.jobId}>
            {r.label}
          </option>
        )),
    ];
    dd.push(
      <option className="bold" key="unpublished" disabled>
        -- Unpublished --
      </option>
    );
    dd = [
      ...dd,
      roles
        .filter((r) => !r.published)
        .map((r) => (
          <option key={r.jobId} value={r.jobId}>
            {r.label}
          </option>
        )),
    ];

    return (
      <Fragment>
        <label id="jobSelectionLabel" className="normal-caption bold">
          Filter applications by role:
        </label>
        <select
          aria-labelledby="jobSelectionLabel"
          name="currentJobSelection"
          value={this.state.currentJobSelection}
          className="clickable normal-subheader form-control form-select"
          onChange={this.handleChange}
          disabled={this.props.disabled}
        >
          {dd}
        </select>
      </Fragment>
    );
  }
}

JobsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  roles: PropTypes.array,
  currentJobSelection: PropTypes.number,
  disabled: PropTypes.bool,
};

class JobApplicationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleType: null,
      role: null,
      roles: [],
      columns: [],
      loading: false,
      canViewCandidatePII: false,
    };

    has_permission(PERMISSIONS.ASCEND_RECRUITER).then((resp) => {
      this.showAdminLinks = resp.data.hasPermission;
    });
  }

  async handleJobAppStatusChange(newValue, ids) {
    await this.props.updateJobApplicationStatusAsync(newValue, ids);
  }

  handleJobPageChange = (newPage) => {
    this.props.changePageNumberAndSearch(newPage);
  };

  handleSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = `${sortOperator}${sortBy}`;
    this.props.changeSortAndSearch(sortTerm);
  };

  handleFilterChange = (searchTerm, search = true) => {
    if (searchTerm || searchTerm === '') {
      this.props.updateSearchState({searchTerm});
      this.props.updateSearchTerm(searchTerm);
    }

    if (search) {
      this.props.changeSearchTermAndSearch(searchTerm);
    }
  };

  refreshJobSummaries(apprenticeshipFlags) {
    const employerId = this.getParam('employerId');
    this.props.getAllJobSummaries({...apprenticeshipFlags, employerId});
  }

  handleJobTypeChange(value) {
    const types = {
      apprenticeships: value === 'all' || value === 'apprenticeships',
      nonApprenticeships: value === 'all' || value === 'non-apprenticeships',
    };

    this.props.changeJobTypeAndSearch(types);
    this.refreshJobSummaries(types);
  }

  handleJobSelectionChange(value) {
    value = value === 'all' ? '' : value;
    this.props.changeJobSelectionAndSearch(value);
  }

  handleDownloadApplication(url) {
    this.setState({loading: true});
    fetch(url, {
      headers: {
        Authorization: localStorage.getItem('id_token'),
      },
    })
      .then((res) => {
        res.blob().then((blob) => {
          var file = window.URL.createObjectURL(blob);
          window.open(file);
        });
      })
      .catch(() => {
        this.props.addFlashMessage(
          'Failed to download PDF - it this error Persists, contact support.'
        );
      })
      .finally(() => this.setState({loading: false}));
  }

  handleReceivedAnInterviewCheck = (application) => {
    this.props.updateJobApplicationReceivedAnInterview(
      application.jobApplicationId,
      application.jobApplicantId,
      !application.receivedInterview
    );
  };

  getColumns() {
    let cols = [
      {
        sortBy: 'seekerName',
        displayName: 'NAME',
        content: (s) => {
          if (s.published) {
            return (
              <a
                className="underlined active-subheader"
                href={`/candidate/${s.seekerId}`}
              >
                {s.seekerName}
              </a>
            );
          } else {
            return (
              <div className="secondary-subheader nowrap">
                {s.seekerName}{' '}
                <InfoSvg
                  width="12"
                  height="12"
                  className={`${styles.unpublishedInfoIcon} svg_color_stroke_blue`}
                  data-tooltip-id="unpublished-tooltip"
                  data-tooltip-place="top"
                  data-tooltip-html="This profile is no longer published. You can download their resume instead."
                />
              </div>
            );
          }
        },
      },
      {sortBy: 'jobName', displayName: 'ROLE', content: (s) => s.jobName},
      {
        sortBy: 'applicationDate',
        displayName: 'APPLIED ON',
        content: (s) => s.applicationDateFormatted,
      },
      {
        displayName: 'RESUME',
        tooltipText:
          'If hiring an apprentice, resumes will only download if required by the application.',
        content: (s) => {
          if (s.resumePdfUrl) {
            return (
              <a className="underlined active-subheader" href={s.resumePdfUrl}>
                Download Resume
              </a>
            );
          } else {
            return <div>No Resume</div>;
          }
        },
      },
      {
        displayName: 'APPLICATION',
        tooltipText: 'Only apprentice applications can be downloaded directly.',
        content: (s) => {
          if (s.applicationPdfUrl) {
            return (
              <button
                className={`${styles.buttonAsLink} underlined active-subheader`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleDownloadApplication(s.applicationPdfUrl);
                  return false;
                }}
              >
                Download Application
              </button>
            );
          } else {
            return <div>No Application</div>;
          }
        },
      },

      {
        sortBy: 'receivedAnInterview',
        displayName: 'RECEIVED AN INTERVIEW?',
        allowWrap: true,
        content: (app) => (
          <div className="custom-control custom-checkbox centered-checkbox form-check">
            <input
              type="checkbox"
              id={`interview-${app.jobApplicantId}-${app.jobApplicationId}`}
              name={`interview-${app.jobApplicantId}-${app.jobApplicationId}`}
              className="custom-control-input form-check-input checkbox-formatting"
              value={app.receivedInterview}
              checked={app.receivedInterview}
              onChange={() => {
                this.handleReceivedAnInterviewCheck(app);
              }}
            />
            <label
              className="custom-control-label form-check-label"
              htmlFor={`interview-${app.jobApplicantId}-${app.jobApplicationId}`}
            >
              <span
                style={{
                  position: 'absolute',
                  border: 0,
                  height: 0,
                  margin: 0,
                  overflow: 'hidden',
                  padding: 0,
                }}
              ></span>
            </label>
          </div>
        ),
      },

      {
        sortBy: 'statusId',
        displayName: 'STATUS',
        content: (app) => (
          <ApplicationStatusSelect
            value={app.statusId}
            ids={{
              jobApplicantId: app.jobApplicantId,
              jobApplicationId: app.jobApplicationId,
            }}
            onChange={(newValue, ids) =>
              this.handleJobAppStatusChange(newValue, ids)
            }
          ></ApplicationStatusSelect>
        ),
      },
    ];

    if (!this.props.showingAllTypes && this.props.showingNonApprentieships) {
      cols = cols.filter((c) => c.displayName !== 'APPLICATION');
    }

    return cols;
  }

  getParam = (paramname) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramname);
  };

  componentDidMount() {
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      this.setState({canViewCandidatePII: resp.data.hasPermission});
    });
    const employerId = this.getParam('employerId');
    let args = {
      apprenticeships: true,
      nonApprenticeships: true,
    };

    this.props.updateSearchState({employerId});
    this.props.getMatchingJobApplications(args);
    this.refreshJobSummaries(args);
  }

  render() {
    const columns = this.getColumns();

    const {
      applicationListing,
      applicationListingLoading,
      currentJobSelection,
      currentJobTypeSelection,
      currentPage,
      itemsPerPage,
      jobs,
      searchTerm,
      sortTerm,
      summariesLoading,
      totalItems,
    } = this.props;

    return (
      <Fragment>
        <LoadingOverlay
          role="status"
          active={this.state.loading}
          spinner={true}
          text="Loading..."
          fadeSpeed={200}
          styles={{
            overlay: (base) => ({
              ...base,
              height: '100px',
              position: 'fixed',
            }),
          }}
        />
        <DocumentTitle title="Applications">
          <div>
            <ReactTooltip
              id="tooltip"
              className="solid-tooltip-dark"
              closeOnEsc={true}
            />
            <ReactTooltip
              id="unpublished-tooltip"
              className="solid-tooltip-dark"
              closeOnEsc={true}
            />
            <h1>
              <PageHeader
                title="Role Applications"
                showAdminLinks={this.showAdminLinks}
              />
            </h1>

            <main id="main" tabIndex="-1">
              {!applicationListingLoading &&
                !summariesLoading &&
                !this.state.canViewCandidatePII && (
                  <h2>You do not have permission to view Candidate PII</h2>
                )}
              {this.state.canViewCandidatePII && (
                <div className="bd-pagebody padding-top-eight">
                  <Row className="padding-top-sixteen padding-bottom-sixteen">
                    <Col className=" offset-md-1 col-md-3">
                      <JobTypeSelect
                        currentJobTypeSelection={currentJobTypeSelection}
                        onChange={(v) => this.handleJobTypeChange(v)}
                        disabled={applicationListingLoading || summariesLoading}
                      />
                    </Col>
                    <Col className="offset-md-4 col-md-3">
                      <JobsSelect
                        currentJobSelection={currentJobSelection}
                        onChange={(v) => this.handleJobSelectionChange(v)}
                        roles={jobs}
                        disabled={applicationListingLoading || summariesLoading}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="offset-md-1 col-md-10">
                      <AscendDataTable
                        caption="Applications for Employer"
                        handleFilterChange={this.handleFilterChange}
                        handlePageChange={this.handleJobPageChange}
                        handleSortChange={this.handleSortChange}
                        items={applicationListing}
                        itemsLoading={
                          applicationListingLoading || summariesLoading
                        }
                        pullDownVisible={false}
                        searchPlaceHolder={'Applicant, Role'}
                        showSearch={true}
                        searchTerm={searchTerm}
                        showPageControl={true}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        sortAscending={sortTerm.startsWith('%2b')}
                        sortBy={sortTerm.replace('%2b', '').replace('-', '')}
                        tableColumns={columns}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </main>
          </div>
        </DocumentTitle>
      </Fragment>
    );
  }
}

JobApplicationsPage.propTypes = {
  applicationListing: PropTypes.array,
  applicationListingLoading: PropTypes.bool,
  changeJobSelectionAndSearch: PropTypes.func.isRequired,
  changeJobTypeAndSearch: PropTypes.func.isRequired,
  changePageNumberAndSearch: PropTypes.func,
  changeSearchTermAndSearch: PropTypes.func.isRequired,
  changeSortAndSearch: PropTypes.func.isRequired,
  currentJobSelection: PropTypes.number,
  currentJobTypeSelection: PropTypes.string,
  currentPage: PropTypes.number,
  getAllJobSummaries: PropTypes.func,
  getMatchingJobApplications: PropTypes.func,
  itemsPerPage: PropTypes.number,
  jobs: PropTypes.array,
  searchTerm: PropTypes.string.isRequired,
  showingAllTypes: PropTypes.bool,
  showingApprenticeships: PropTypes.bool,
  showingNonApprentieships: PropTypes.bool,
  sortTerm: PropTypes.string,
  summariesLoading: PropTypes.bool,
  totalItems: PropTypes.number,
  updateSearchState: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateJobApplicationStatusAsync: PropTypes.func,
  location: PropTypes.object.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  updateJobApplicationReceivedAnInterview: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let jobTypeSelection = 'all';
  if (!state.jobAppBrowse.apprenticeships) {
    jobTypeSelection = 'non-apprenticeships';
  }
  if (!state.jobAppBrowse.nonApprenticeships) {
    jobTypeSelection = 'apprenticeships';
  }
  return {
    searchTerm: state.ascendDataTable.searchTerm,
    itemsPerPage: state.jobAppBrowse.itemsPerPage,
    currentPage: state.jobAppBrowse.currentPage,
    applicationListingLoading: state.jobAppBrowse.applicationListingLoading,
    applicationListing: state.jobAppBrowse.applicationListing.map((x) => ({
      ...x,
      applicationDateFormatted: format(
        new Date(x.applicationDate),
        'MM/dd/yyyy'
      ),
    })),
    totalItems: state.jobAppBrowse.applicationListTotalItems,
    jobs: state.jobs.summaries,
    summariesLoading: state.jobs.summariesLoading,
    currentJobTypeSelection: jobTypeSelection,
    showingApprenticeships: state.jobAppBrowse.apprenticeships,
    showingNonApprentieships: state.jobAppBrowse.nonApprenticeships,
    showingAllTypes:
      state.jobAppBrowse.apprenticeships &&
      state.jobAppBrowse.nonApprenticeships,
    sortTerm: state.jobAppBrowse.sortTerm,
    currentJobSelection: state.jobAppBrowse.jobId,
  };
}

const actions = {
  ...jobActions,
  ...jobAppBrowseActions,
  ...searchActions,
  addFlashMessage,
};

export default withRouter(
  connect(mapStateToProps, actions)(JobApplicationsPage)
);
