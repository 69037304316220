import React from 'react';
import {Field, Form} from 'react-final-form';
import {TextField, AutoCompleteField} from 'src/formFields';
import _ from 'lodash';
import {connect} from 'react-redux';
import {required, minPhoneLength} from '../../utils/intake/intakeCommon';
import {PhoneNumberInput} from 'src/components/PhoneNumberInput';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {ChevronRightSvg} from '../../assets/svg/svgComponents';
import {makeRequest} from '../../services/api';
import PushButtonArray from '../controls/buttons/PushButtonArray/PushButtonArray';
import TagSelector from '../selectors/TagSelector/TagSelector';

class TestingPage extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      organization: '',
      title: '',
      other: '',
      organizationsList: [],
      showSucess: 'hide',
      showForm: 'show',
    };
  }

  componentDidMount() {
    this.handleGetOrganizations();
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = ['name', 'title', 'organization', 'phone', 'email'];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    if (values['myButtonListField'].length === 0) {
      errors['myButtonListField'] = 'Select a minimum of one';
    }
    return errors;
  };

  buildButtonList = () => [
    {
      buttonValue: 1,
      buttonText: 'Button1',
      buttonAction: (props, form) => {
        form.mutators.setValue('name', 'John Smith');
      },
    },
    {
      buttonValue: 2,
      buttonText: 'Button2',
    },
    {
      buttonValue: 3,
      buttonText: 'Button3',
      buttonAction: (props, form) => {
        form.mutators.setValue('name', 'John Doe');
      },
    },
  ];

  handleGetOrganizations = async () => {
    const organizations = await this.getOrganizations();
    this.setState({organizationsList: organizations});
  };

  getOrganizations = async () => {
    const response = await makeRequest({
      method: 'GET',
      path: `v2/contact/entities`,
    });
    if (response.status === 200) {
      return response.data.entities.filter((e) => e.type === 'em');
    }
    return [];
  };

  createNewContact = async (newContact) => {
    newContact.type = 'employer';

    const existingEmployerIndex = this.state.organizationsList.findIndex(
      (o) => o.label === newContact.organization
    );
    if (existingEmployerIndex === -1) {
      newContact.other = newContact.organization;
      delete newContact['organization'];
    } else {
      newContact.organization =
        this.state.organizationsList[existingEmployerIndex].value;
    }

    this.setState({showSucess: 'show', showForm: 'hide', newContact});
    await makeRequest({
      method: 'POST',
      path: 'v2/contact',
      body: newContact,
      isAuthenticated: false,
    });
  };

  buildSelectOptions = () => [
    {id: 123, value: 444, label: 'item1', tagName: 'Item1'},
    {id: 124, value: 333, label: 'item2', tagName: 'Item2'},
    {id: 125, value: 222, label: 'item3', tagName: 'Item3'},
    {id: 126, value: 111, label: 'item4', tagName: 'Item4'},
  ];

  render() {
    document.body.style = 'background-color: #ffffff;';

    return (
      <div className="contactForm">
        <DocumentTitle title="Contact">
          <div className="container">
            {this.state.showSucess === 'show' && (
              <div className={`${this.state.showSucess}`} aria-hidden="true">
                <div>
                  <br />
                  <p>Thank you.</p>
                  <p>POSTED NEW CONTACT:</p>
                  <pre>{JSON.stringify(this.state.newContact, null, 2)}</pre>
                </div>
              </div>
            )}

            <div className={`row ${this.state.showForm}`}>
              <Form
                onSubmit={this.createNewContact}
                validate={this.validate}
                mutators={{
                  // expect (field, value) args from the mutator
                  setValue: ([field, value], state, {changeValue}) => {
                    changeValue(state, field, () => value);
                  },
                }}
                initialValues={{
                  myButtonListField: [],
                }}
              >
                {({handleSubmit, form}) => {
                  this.formRef.current = form;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="name"
                        component={TextField}
                        label="Name *"
                        labelCssClass={'contact-form-label'}
                        validate={required}
                        placeholder="First and Last Name"
                        onChange={(evt) => {
                          this.setState({name: evt.target.value});
                        }}
                      />
                      <Field
                        name="title"
                        component={TextField}
                        label="Title *"
                        labelCssClass={'contact-form-label'}
                        validate={required}
                        onChange={(evt) => {
                          this.setState({title: evt.target.value});
                        }}
                      />
                      <Field
                        name="organization"
                        label="Organization *"
                        labelCssClass={'contact-form-label'}
                        component={AutoCompleteField}
                        options={this.state.organizationsList}
                        validate={required}
                        formatCreateLabel="Add as Other Organization.."
                      />
                      <Field
                        name="phone"
                        component={PhoneNumberInput}
                        label="Phone *"
                        labelCssClass={'contact-form-label'}
                        showBackground={false}
                        validate={(x) => {
                          return minPhoneLength(x);
                        }}
                        onChange={(evt) => {
                          this.setState({phone: evt.target.value});
                        }}
                      />
                      <Field
                        name="email"
                        component={TextField}
                        inputType="email"
                        label="Email *"
                        labelCssClass={'contact-form-label'}
                        validate={required}
                        onChange={(evt) => {
                          this.setState({email: evt.target.value});
                        }}
                      />

                      <Field
                        component={PushButtonArray}
                        name="myButtonListField"
                        validate={required}
                        buttonList={this.buildButtonList()}
                        displayVertical={true}
                        multiSelect={true}
                        form={form}
                      />

                      <div className="mb-2">
                        <TagSelector optionsList={this.buildSelectOptions()} />
                      </div>

                      <button
                        type="submit"
                        className="clickable btn btn-primary-1"
                      >
                        Get in Touch &nbsp;&nbsp;
                        <ChevronRightSvg className="svg_color_fill_white" />
                      </button>
                    </form>
                  );
                }}
              </Form>
            </div>
            <div className="row">
              <div className="col-12">&nbsp;</div>
            </div>
            <div className={`row ${this.state.showForm}`}>
              <p>
                Already have an account?{' '}
                <a
                  href="https://network.ascendindiana.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign in.
                </a>
              </p>
            </div>
          </div>
        </DocumentTitle>
      </div>
    );
  }
}

export default connect()(TestingPage);
