import React, {useState} from 'react';
import PropTypes from 'prop-types';
import UniversityExplorerChart from './UniversityExplorerChart';
import UniversityExplorerTable from './UniversityExplorerTable';
import pdf from './dummyChart.pdf';
import xls from './dummyTable.xlsx';
import AreYouSureModal from 'src/components/AreYouSureModal';

import explorerStyles from './UniversityExplorerPage.module.scss';

export default function UniversityExplorerVisualization({
  appliedGroups,
  queryResults,
  columnHeaders,
  fieldMap,
  studentSourceData,
}) {
  const [displayType, setDisplayType] = useState('table');

  const [areYouSureIsOpen, setAreYouSureIsOpen] = useState(false);
  return (
    <>
      <div
        className={`${explorerStyles.universityExplorerVisualizationContainer}`}
      >
        <div className={`${explorerStyles.universityExplorerViewSelectionRow}`}>
          View as&nbsp;&nbsp;&nbsp;
          <button
            className={`${
              displayType === 'table'
                ? explorerStyles.universityExplorerViewSelectionLinkSelected
                : explorerStyles.universityExplorerViewSelectionLink
            }`}
            onClick={() => setDisplayType('table')}
          >
            Table
          </button>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <button
            className={`${
              displayType === 'chart'
                ? explorerStyles.universityExplorerViewSelectionLinkSelected
                : explorerStyles.universityExplorerViewSelectionLink
            }`}
            onClick={() => setDisplayType('chart')}
          >
            Chart
          </button>
        </div>

        {displayType === 'chart' && (
          <UniversityExplorerChart
            queryResults={queryResults}
            studentSourceData={studentSourceData}
            appliedGroups={appliedGroups}
          />
        )}

        {displayType === 'table' && (
          <UniversityExplorerTable
            queryResults={queryResults}
            columnHeaders={columnHeaders}
            fieldMap={fieldMap}
          />
        )}

        <div>
          <h3>Actions</h3>
          <div>
            <button
              type="button"
              className="clickable btn btn-secondary"
              onClick={() => window.open(pdf)}
            >
              Export as PDF
            </button>
            <button
              type="button"
              className="clickable btn btn-secondary ms-2"
              onClick={() => window.open(xls)}
            >
              Export to Excel
            </button>
            <button
              type="button"
              className="clickable btn btn-secondary ms-2"
              onClick={() => setAreYouSureIsOpen(true)}
            >
              Schedule Strategy Discussion with Ascend
            </button>
          </div>
        </div>
      </div>
      <div className="column">
        <strong>How it works:</strong> Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
        qui officia deserunt mollit anim id est laborum.
      </div>
      <AreYouSureModal
        areYouSureText="Would you like to schedule a meeting?"
        isOpen={areYouSureIsOpen}
        onClose={() => setAreYouSureIsOpen(false)}
        onYes={() => setAreYouSureIsOpen(false)}
      />
    </>
  );
}

UniversityExplorerVisualization.propTypes = {
  appliedGroups: PropTypes.arrayOf(PropTypes.string),
  appliedFilters: PropTypes.object,
  queryResults: PropTypes.array,
  columnHeaders: PropTypes.arrayOf(PropTypes.string),
  fieldMap: PropTypes.arrayOf(PropTypes.object),
  studentSourceData: PropTypes.arrayOf(PropTypes.object),
};

UniversityExplorerVisualization.defaultProps = {
  columnHeaders: [],
};
