import * as types from '../actions/ActionTypes';

const initialState = {
  apprenticePathways: [],
  apprenticePathwaysLoading: false,
};

export default function apprenticePathways(state = initialState, action) {
  switch (action.type) {
    case types.APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_REQUEST:
    case types.APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_REQUEST:
      return {
        ...state,
        apprenticePathways: [],
        apprenticePathwaysLoading: true,
      };
    case types.APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_SUCCESS:
    case types.APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_SUCCESS:
      return {
        ...state,
        apprenticePathways: action.response.body.apprenticePathways,
        apprenticePathwaysLoading: false,
      };
    case types.APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_FAILURE:
    case types.APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_FAILURE:
      return {
        ...state,
        apprenticePathwaysLoading: false,
      };
    default:
      return state;
  }
}
