import React from 'react';
import ContentLoader from 'react-content-loader';

const CandidateHomeLoader = (props) => (
  <ContentLoader
    speed={3}
    viewBox="0 0 600 400"
    backgroundColor="#f1f4f9"
    foregroundColor="#cbe1ff"
    style={{
      marginBottom: '18px',
      marginLeft: '18px',
      backgroundColor: '#ffffff',
    }}
    {...props}
  >
    <rect x="29" y="233" rx="0" ry="0" width="91" height="9" />
    <rect x="29" y="162" rx="0" ry="0" width="82" height="8" />
    <rect x="29" y="88" rx="0" ry="0" width="114" height="9" />
    <circle cx="50" cy="266" r="15" />
    <rect x="78" y="256" rx="0" ry="0" width="103" height="8" />
    <rect x="230" y="257" rx="0" ry="0" width="152" height="61" />
    <rect x="447" y="4" rx="0" ry="0" width="68" height="10" />
    <rect x="446" y="179" rx="0" ry="0" width="128" height="61" />
    <rect x="447" y="109" rx="0" ry="0" width="128" height="61" />
    <rect x="45" y="26" rx="0" ry="0" width="82" height="54" />
    <rect x="5" y="5" rx="0" ry="0" width="133" height="13" />
    <rect x="457" y="255" rx="0" ry="0" width="111" height="76" />
    <rect x="447" y="17" rx="0" ry="0" width="128" height="85" />
    <rect x="136" y="26" rx="0" ry="0" width="82" height="54" />
    <rect x="29" y="102" rx="0" ry="0" width="89" height="54" />
    <rect x="128" y="102" rx="0" ry="0" width="89" height="54" />
    <rect x="227" y="101" rx="0" ry="0" width="89" height="54" />
    <rect x="29" y="174" rx="0" ry="0" width="89" height="54" />
    <rect x="128" y="174" rx="0" ry="0" width="89" height="54" />
    <rect x="227" y="173" rx="0" ry="0" width="89" height="54" />
  </ContentLoader>
);

export default CandidateHomeLoader;
