import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import DocumentTitle from '../components/vendor/DocumentTitle';
import LoadingOverlay from 'react-loading-overlay';

export default function DocumentViewerPage() {
  let {id} = useParams();
  const {pathname} = useLocation();

  const [file, setFile] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [documentType, setDocumentType] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const handleDocumentResponse = async (res) => {
      setResponseStatus(res.status);

      res.blob().then((blob) => {
        if (res.status === 200) {
          setFile(window.URL.createObjectURL(blob));
        }
      });
    };

    const requestedDocumentType = pathname.includes('resume')
      ? 'Resume'
      : 'Application';

    setDocumentType(requestedDocumentType);

    if (requestedDocumentType === 'Application') {
      fetch(`${apiUrl}/applications/export_guid/${id}`).then((res) => {
        handleDocumentResponse(res);
      });
    }

    if (requestedDocumentType === 'Resume') {
      fetch(`${apiUrl}/resumes/export_guid/${id}`).then((res) => {
        handleDocumentResponse(res);
      });
    }

    // we only need this to run on first load, so we ignore this rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingOverlay
      role="status"
      active={responseStatus === null}
      spinner={true}
      text="Loading..."
      styles={{
        overlay: (base) => ({
          ...base,
          height: '100vh',
          width: '100vw',
        }),
      }}
    >
      {responseStatus === 404 && (
        <div className="text-center">
          <h1>{documentType} Not Found</h1>
          <p>
            The {documentType.toLowerCase()} you have requested is not
            available. To view all applications for your employer, visit&nbsp;
            <a href="https://network.ascendindiana.com/jobs/applications">
              https://network.ascendindiana.com/jobs/applications
            </a>
          </p>
        </div>
      )}

      {responseStatus === 410 && (
        <div className="text-center">
          <h1>Link Automatically Expired</h1>

          {documentType === 'Application' && (
            <p>
              To protect student data, we automatically expire these links after
              120 days. To download this application, visit&nbsp;
              <a href="https://network.ascendindiana.com/jobs/applications">
                https://network.ascendindiana.com/jobs/applications
              </a>
            </p>
          )}

          {documentType === 'Resume' && (
            <p>
              To protect job seeker data, we automatically expire these links
              after 120 days. To view all applications for your employer,
              visit&nbsp;
              <a href="https://network.ascendindiana.com/jobs/applications">
                https://network.ascendindiana.com/jobs/applications
              </a>
            </p>
          )}
        </div>
      )}

      {file !== null && (
        <DocumentTitle title={`${documentType} Viewer`}>
          <object
            data={file}
            type="application/pdf"
            style={{height: '100vh', width: '100vw'}}
          >
            <div>No PDF Viewer Installed</div>
          </object>
        </DocumentTitle>
      )}
    </LoadingOverlay>
  );
}
