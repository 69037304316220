import * as types from './ActionTypes';
import _ from 'lodash';
import {makeRequestAsync} from 'src/services/api';

const apiUrl = process.env.REACT_APP_API_URL;

export function createRecruiter(recruiter) {
  return {
    types: [
      types.RECRUITERS__CREATE_REQUEST,
      types.RECRUITERS__CREATE_SUCCESS,
      types.RECRUITERS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/recruiters`,
    method: 'POST',
    body: recruiter,
    successMessage: 'Recruiter created.',
    failureMessage: 'Error creating recruiter.',
  };
}

export function getRecruiter(recruiterId) {
  return {
    types: [
      types.RECRUITERS__GET_REQUEST,
      types.RECRUITERS__GET_SUCCESS,
      types.RECRUITERS__GET_FAILURE,
    ],
    uri: `${apiUrl}/recruiters/${recruiterId}`,
    method: 'GET',
  };
}

export function getRecruitersByCompany(employerId) {
  return {
    types: [
      types.RECRUITERS__GET_BY_COMPANY_REQUEST,
      types.RECRUITERS__GET_BY_COMPANY_SUCCESS,
      types.RECRUITERS__GET_BY_COMPANY_FAILURE,
    ],
    uri: `${apiUrl}/recruiters?employerId=${employerId}`,
    method: 'GET',
  };
}

export function getRecruitersByCompanyAdvanced(
  employerId,
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/recruiters`;
  let p = [];
  p.push('employerId=' + employerId);
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${encodeURIComponent(value)}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.RECRUITERS__GET_BY_COMPANY_ADVANCED_REQUEST,
      types.RECRUITERS__GET_BY_COMPANY_ADVANCED_SUCCESS,
      types.RECRUITERS__GET_BY_COMPANY_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function updateRecruiter(recruiter) {
  let body = {...recruiter};
  delete body.id;
  delete body.updatedAt;

  return {
    types: [
      types.RECRUITERS__UPDATE_REQUEST,
      types.RECRUITERS__UPDATE_SUCCESS,
      types.RECRUITERS__UPDATE_FAILURE,
    ],
    uri: `${apiUrl}/recruiters/${recruiter.id}`,
    method: 'PATCH',
    body: body,
    payload: {recruiter},
    successMessage: 'Recruiter saved.',
    failureMessage: 'Error saving recruiter.',
  };
}

export function deleteRecruiter(recruiterId, callback) {
  return {
    types: [
      types.RECRUITERS__DELETE_REQUEST,
      types.RECRUITERS__DELETE_SUCCESS,
      types.RECRUITERS__DELETE_FAILURE,
    ],
    uri: `${apiUrl}/recruiters/${recruiterId}`,
    method: 'DELETE',
    payload: {recruiterId},
    successMessage: 'Recruiter deleted.',
    failureMessage: 'Error deleting recruiter.',
    callback: callback,
  };
}

export const getRecruiterListByEmployer = async (employerId) => {
  let path = `recruiters?employerId=${employerId}`;

  try {
    let response = await makeRequestAsync('GET', path, {});
    if (response.status === 200) {
      if (response.data) {
        return response.data.recruiters;
      } else {
        return [];
      }
    }
  } catch (err) {
    return err;
  }
};

export function getSavedSearches() {
  return {
    types: [
      types.RECRUITERS__GET_SAVED_SEARCHES_REQUEST,
      types.RECRUITERS__GET_SAVED_SEARCHES_SUCCESS,
      types.RECRUITERS__GET_SAVED_SEARCHES_FAILURE,
    ],
    uri: `${apiUrl}/v2/saved_searches`,
    method: 'GET',
  };
}

export function createSavedSearch(savedSearch) {
  return {
    types: [
      types.RECRUITERS__CREATE_SAVED_SEARCHES_REQUEST,
      types.RECRUITERS__CREATE_SAVED_SEARCHES_SUCCESS,
      types.RECRUITERS__CREATE_SAVED_SEARCHES_FAILURE,
    ],
    uri: `${apiUrl}/v2/saved_search`,
    body: savedSearch,
    method: 'POST',
    successMessage: 'Added a new saved search.',
    failureMessage: 'Failed to save search.',
  };
}

export function deleteSavedSearch(searchId) {
  return {
    types: [
      types.RECRUITERS__DELETE_SAVED_SEARCHES_REQUEST,
      types.RECRUITERS__DELETE_SAVED_SEARCHES_SUCCESS,
      types.RECRUITERS__DELETE_SAVED_SEARCHES_FAILURE,
    ],
    uri: `${apiUrl}/v2/saved_search/${searchId}`,
    method: 'DELETE',
  };
}

export function getRecruiterNotificationOptionsById(id) {
  return {
    types: [
      types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_REQUEST,
      types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_SUCCESS,
      types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_FAILURE,
    ],
    uri: `${apiUrl}/recruiters/recruiter_notification_options/${id}`,
    method: 'GET',
  };
}

export function resetRecruiterNotificationOptionsById(seekerId) {
  return (dispatch) => {
    dispatch({
      type: types.RECRUITER__GET_NOTIFICATION_OPTIONS_BY_ID_RESET,
    });
  };
}

export function setRecruiterProfileNotificationOptions(recruiterId, options) {
  return {
    types: [
      types.RECRUITER__UPDATE_NOTIFICATION_OPTIONS_REQUEST,
      types.RECRUITER__UPDATE_NOTIFICATION_OPTIONS_SUCCESS,
      types.RECRUITER__UPDATE_NOTIFICATION_OPTIONS_FAILURE,
    ],
    uri: `${apiUrl}/recruiters/${recruiterId}/recruiter_notification_options`,
    method: 'POST',
    body: options,
    payload: options,
    successMessage: 'Preference saved.',
    failureMessage: 'Preference NOT saved.',
  };
}
