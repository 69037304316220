import React, {useEffect, useState} from 'react';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {PageHeader} from '../../layout';
import {AscendModal, AscendModalBody, AscendModalHeader} from '../../layout';
import LoadingOverlay from 'react-loading-overlay';
import {push} from 'redux-first-history';
import {useSelector, useDispatch} from 'react-redux';
import {confirmChangeEmail} from 'src/actions/users';

export default function ConfirmEmailPage() {
  const dispatch = useDispatch();
  const [displayEmailChangeModal, setDisplayEmailChangeModal] = useState(false);

  let confirmChangeEmailError = useSelector(
    (state) => state.users.confirmChangeEmailError
  );
  let confirmChangeEmailSuccess = useSelector(
    (state) => state.users.confirmChangeEmailSuccess
  );

  let confirmChangeEmailErrorMessage = useSelector(
    (state) => state.users.confirmChangeEmailErrorMessage
  );

  const getParam = (paramname) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramname);
  };

  useEffect(() => {
    const token = getParam('token');
    const newEmail = getParam('new_email');

    dispatch(confirmChangeEmail(token, newEmail));
  }, [dispatch]);

  useEffect(() => {
    if (confirmChangeEmailSuccess || confirmChangeEmailError) {
      setDisplayEmailChangeModal(true);
    }
  }, [confirmChangeEmailSuccess, confirmChangeEmailError]);

  const handleClose = () => {
    dispatch(push('/home'));
  };

  return (
    <DocumentTitle title="Confirm Email Change">
      <div className="wholepage" style={{overflow: 'hidden'}}>
        <PageHeader title="Confirm Email Change" showAdminLinks={false} />

        <LoadingOverlay
          role="status"
          active={!confirmChangeEmailSuccess && !confirmChangeEmailError}
          spinner={true}
          text="Loading..."
          fadeSpeed={200}
          styles={{
            overlay: (base) => ({
              ...base,
              position: 'fixed',
            }),
          }}
        >
          <AscendModal
            isOpen={displayEmailChangeModal}
            onClose={() => setDisplayEmailChangeModal(false)}
            centerVertically={true}
            size="sm"
            backdrop={'static'}
          >
            <AscendModalHeader headerClass="bd-modalheader" hasClose={true}>
              <div className="text-align-center ">
                <h1
                  style={{fontSize: '24px'}}
                  className="normal-headline-white bold mb-0"
                >
                  Confirming New Email
                </h1>
              </div>
            </AscendModalHeader>

            <AscendModalBody>
              {confirmChangeEmailSuccess && (
                <div className="text-align-center">
                  Your email address was changed successfully
                </div>
              )}

              {confirmChangeEmailError && (
                <div className="text-align-center">
                  {confirmChangeEmailErrorMessage ||
                    'There was an error attempting to save your new email address.'}{' '}
                  Please email{' '}
                  <a
                    style={{textDecoration: 'underline'}}
                    href="mailto:support@ascendindiana.com"
                  >
                    support@ascendindiana.com
                  </a>{' '}
                  for assistance.
                </div>
              )}

              <div className="row justify-content-center mt-4">
                <button
                  className="clickable btn btn-primary col-4 "
                  onClick={() => handleClose()}
                >
                  Close
                </button>
              </div>
            </AscendModalBody>
          </AscendModal>
        </LoadingOverlay>
      </div>
    </DocumentTitle>
  );
}
