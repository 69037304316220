import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';

import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  profileStatuses: [],
  profileStatusesLoading: false,
};

export default function profileStatuses(state = initialState, action) {
  switch (action.type) {
    case types.PROFILE_STATUSES__GET_REQUEST:
      return {...state, roleTypes: [], roleTypesLoading: true};
    case types.PROFILE_STATUSES__GET_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        profileStatuses: action.response.body.profileStatuses,
        profileStatusesLoading: false,
      };
    default:
      return state;
  }
}
