import React from 'react';
import PropTypes from 'prop-types';

import editWhiteSvg from 'src/assets/images/EditWhite.svg';

class CandidateName extends React.Component {
  render() {
    const {seeker, allowEdit} = this.props;
    let text = '\u00A0';
    if (seeker.id) {
      text = `${seeker.firstName} ${seeker.lastName}`;
    }
    return (
      <h1 className="profile-header bold padding-top-thirtytwo padding-bottom-sixteen">
        &nbsp;{text}
        &nbsp;&nbsp;&nbsp;
        {seeker.id && allowEdit && (
          <button
            type="button"
            onClick={this.props.onEditClick}
            className="clickable btn btn-primary"
          >
            <img src={editWhiteSvg} alt="" /> Edit&nbsp;
          </button>
        )}
      </h1>
    );
  }
}

CandidateName.propTypes = {
  seeker: PropTypes.object,
  onEditClick: PropTypes.func,
  allowEdit: PropTypes.bool.isRequired,
};

CandidateName.defaultProps = {
  seeker: {},
  allowEdit: false,
};

export default CandidateName;
