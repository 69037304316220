import * as types from './ActionTypes';
import _ from 'lodash';
import merge from 'deepmerge';
import {makeRequest} from '../services/api';
import * as flashActions from './flash';

const apiUrl = process.env.REACT_APP_API_URL;

export const updateJobApplicationStatusAsync =
  (statusId, {jobApplicationId, jobApplicantId}) =>
  async (dispatch) => {
    const body = {
      statusId,
      jobApplicationId,
      jobApplicantId,
    };

    dispatch({type: types.JOBS_APPLICATION_BROWSE__UPDATE_STATUS_REQUEST});
    makeRequest({
      method: 'PATCH',
      path: `applications/status`,
      body: body,
      isAuthenticated: true,
    })
      .then((response) => {
        dispatch({
          type: types.JOBS_APPLICATION_BROWSE__UPDATE_APPLICATION_LISTING_STATUS,
          updateData: {...body},
        });
        dispatch({type: types.JOBS_APPLICATION_BROWSE__UPDATE_STATUS_SUCCESS});
        dispatch(
          flashActions.addFlashMessage('Status updated', 'notification')
        );
      })
      .catch((err) => {
        dispatch({type: types.JOBS_APPLICATION_BROWSE__UPDATE_STATUS_FAILURE});
        flashActions.addFlashMessage('Failed to update status.');
      });
  };

export function updateJobApplicationReceivedAnInterview(
  jobApplicationId,
  jobApplicantId,
  receivedInterview
) {
  return {
    types: [
      types.JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_REQUEST,
      types.JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_SUCCESS,
      types.JOBS_APPLICATION_BROWSE__UPDATE_RECEIVED_AN_INTERVIEW_FAILURE,
    ],
    uri: `${apiUrl}/applications/mark_interviewed`,
    method: 'POST',
    body: {jobApplicationId, jobApplicantId, receivedInterview},
    payload: {jobApplicationId, jobApplicantId, receivedInterview},
    successMessage: 'Interview status saved.',
    failureMessage: 'Error saving interview status.',
  };
}

export function updateSearchState(newState) {
  return {
    type: types.JOBS_APPLICATION_BROWSE__UPDATE_STATE,
    newState,
  };
}

export function changeSortAndSearch(sortTerm) {
  return (dispatch) => {
    const newState = {sortTerm, currentPage: 0};
    dispatch(updateSearchState(newState));
    dispatch(getMatchingJobApplications(newState));
  };
}

export function changeSearchTermAndSearch(searchTerm) {
  return (dispatch) => {
    const newState = {searchTerm, currentPage: 0};
    dispatch(updateSearchState(newState));
    dispatch(getMatchingJobApplications(newState));
  };
}

export function changeJobTypeAndSearch(jobTypes) {
  return (dispatch) => {
    const newState = {currentPage: 0, ...jobTypes};
    dispatch(updateSearchState(newState));
    dispatch(getMatchingJobApplications(newState));
  };
}

export function changePageNumberAndSearch(pageNumber) {
  return (dispatch) => {
    const newState = {currentPage: pageNumber};
    dispatch(updateSearchState(newState));
    dispatch(getMatchingJobApplications(newState));
  };
}

export function changeJobSelectionAndSearch(jobId) {
  const jobIdNumber = jobId && jobId !== '0' ? _.parseInt(jobId) : null;
  return (dispatch) => {
    const newState = {currentPage: 0, jobId: jobIdNumber};
    dispatch(updateSearchState(newState));
    dispatch(getMatchingJobApplications(newState));
  };
}

export function appSearchAction(newState) {
  const qs = _.map(newState, (val, key) => `${key}=${val}`).join('&');
  const uri = `${apiUrl}/applications/listing?${qs}`;
  return {
    types: [
      types.JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_REQUEST,
      types.JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_SUCCESS,
      types.JOBS_APPLICATION_BROWSE__GET_APPLICATION_LISTING_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    failureMessage: 'Unable to fetch application listing.',
  };
}

// construct state for search and then dispatch the actual search
export function getMatchingJobApplications(newState = {}) {
  return (dispatch, getState) => {
    const {
      searchTerm,
      itemsPerPage,
      currentPage,
      totalItems,
      sortTerm,
      apprenticeships,
      nonApprenticeships,
      employerId,
    } = getState().jobAppBrowse;
    const initialSearchState = {
      searchTerm,
      itemsPerPage,
      currentPage,
      totalItems,
      sortTerm,
      apprenticeships,
      nonApprenticeships,
      employerId,
    };

    let finalState = merge(initialSearchState, newState);

    if ('jobId' in finalState && finalState['jobId'] == null) {
      delete finalState['jobId'];
    }

    dispatch(appSearchAction(finalState));
  };
}
