import React from 'react';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as applicantsActions from '../../actions/ApplicantsActions';
import ConnectionHeader from '../../components/connectionForm/ConnectionHeader';
import ascendLogoWhitePng from 'src/assets/images/Ascend-Logo-White.png';
import loadingGif from '../../assets/images/loading.gif';
import paperPlaneSvg from 'src/assets/images/paper_plane.svg';

const isInternational = (seeker) => {
  if (
    seeker.workStatus === 'US Citizen' ||
    seeker.workStatus === 'Permanent Resident' ||
    seeker.workStatus === 'NULL'
  ) {
    return false;
  }
  return true;
};

class LaterPage extends React.Component {
  componentDidMount() {
    if (this.props.jobCount === undefined || this.props.jobCount === 0) {
      this.props.createJobCount();
    }
  }

  render() {
    var thanksDiv = <div />;

    if (this.props.createSeekerLoading === true) {
      thanksDiv = (
        <div className="text-align-center bgcolorw">
          <br />
          <br />
          <br />
          <img src={loadingGif} height="200" width="200" alt="Loading..." />
          <p role="status">Loading...</p>
        </div>
      );
    } else if (isInternational(this.props.seeker)) {
      thanksDiv = (
        <div>
          <div className="bd-darkbluebackground">
            <ConnectionHeader
              logo={ascendLogoWhitePng}
              titleStyle="normal-jumbo-white bold"
              title="Thanks!"
            />
          </div>
          <div className="padding-64 bd-graybackground">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <div className="normal-title bold padding-bottom-sixteen">
                  Thank you for connecting with Ascend. We are so grateful for
                  your time and interest.
                </div>
                <div className="normal-body padding-top-sixteen">
                  Unfortunately, our current employer partners do not have any
                  open opportunities that support international students. We are
                  hard at work to find Indiana employers that do and will be in
                  touch to schedule a one-on-one meeting as soon as we secure
                  those opportunities.
                </div>
                <div className="normal-body padding-top-sixteen">
                  We hope to be in touch over the coming months!
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
      );
    } else {
      thanksDiv = (
        <div>
          <div className="bd-darkbluebackground">
            <br />
            <ConnectionHeader
              logo={ascendLogoWhitePng}
              titleStyle="normal-jumbo-white bold"
              title="Thanks!"
            />
            <br />
          </div>
          <div className="padding-64-short-top bd-graybackground">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <div className="d-flex justify-content-center padding-bottom-sixteen">
                  <img width="20%" height="20%" src={paperPlaneSvg} alt="" />
                </div>
                <div className="d-flex normal-title bold  justify-content-center">
                  We can’t wait to review your resume.
                </div>

                <div className="normal-body padding-top-sixteen">
                  You just received an email from network@ascendindiana.com to
                  complete your signup. Please complete in the next 48 hours. If
                  you can’t find the email, first check spam, then send a note
                  to{' '}
                  <a href="mailto:support@ascendindiana.com">
                    support@ascendindiana.com
                  </a>
                  .
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <DocumentTitle title="Connect Later">
        <div className="fullindy">
          <br />
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">{thanksDiv}</div>
              <div className="col-md-2" />
            </div>
          </div>
          <br />
        </div>
      </DocumentTitle>
    );
  }
}

LaterPage.propTypes = {
  seeker: PropTypes.object,
  createSeekerLoading: PropTypes.bool.isRequired,
  createJobCount: PropTypes.func.isRequired,
  jobCount: PropTypes.number,
};

const actions = {
  ...applicantsActions,
};

function mapStateToProps(state, ownProps) {
  return {
    seeker: state.seekers.seeker,
    createSeekerLoading: state.seekers.createSeekerLoading,
    jobCount: state.applicants.jobCount,
  };
}

export default connect(mapStateToProps, actions)(LaterPage);
