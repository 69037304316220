import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../Avatar';

class CandidateAvatar extends React.Component {
  render() {
    const {seeker} = this.props;
    if (seeker.id) {
      return <Avatar height={86} width={86} url={seeker.avatarUrl || ''} />;
    }
    return <div height="86px" width="86px" />;
  }
}

CandidateAvatar.propTypes = {
  seeker: PropTypes.object,
};

CandidateAvatar.defaultProps = {
  seeker: {},
};

export default CandidateAvatar;
