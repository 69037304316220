import React from 'react';
import PropTypes from 'prop-types';

import {AscendModal, AscendModalBody, AscendModalHeader} from '../../layout';

class ErrorModal extends React.Component {
  handleSubmit = () => {
    this.props.onSubmit();
  };

  render() {
    return (
      <AscendModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        centerVertically={true}
        disableClose={true}
      >
        <AscendModalHeader
          headerClass="bd-modalheader"
          hasClose={false}
          isOpen={this.props.isOpen}
        >
          <div className="text-align-center ">
            <h1
              style={{fontSize: '24px'}}
              className="normal-headline-white bold mb-0"
            >
              We were unable to set your password automatically.
            </h1>
          </div>
        </AscendModalHeader>
        <AscendModalBody>
          <div className="ps-3 pt-3 pe-3 pb-3">
            Please try again.
            <br />
            <br />
            <ul>
              <li>
                Ensure that no part of your email address appears in your
                password
              </li>
              <li>Ensure that you have a strong connection to the internet</li>
            </ul>
            If you’re still unable to set your password, please email{' '}
            <a href="mailto:support@ascendindiana.com">
              support@ascendindiana.com
            </a>{' '}
            for assistance.
          </div>
          <div style={{textAlign: 'center'}}>
            <button
              type="submit"
              className="clickable btn btn-eula"
              onClick={this.handleSubmit}
            >
              Try Again
            </button>
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorModal;
