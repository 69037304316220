import * as types from '../actions/ActionTypes';

const initialState = {
  jobCount: 0,
};

const applicantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.APPLICANTS_CREATE_JOB_COUNT:
      return {
        ...state,
        jobCount: action.payload,
      };
    default:
      return state;
  }
};

export default applicantsReducer;
