import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../components/vendor/DocumentTitle';
import * as seekersActions from 'src/actions/seekers';
import * as Layout from 'src/layout';
import {CandidateInfo} from 'src/components/CandidateInfo';
import AdminSubMenu from 'src/components/AdminSubMenu';
import {withRouter} from '../utils/commonHooks';
import PreviousButton from 'src/components/PreviousButton';
import {has_permission, PERMISSIONS} from '../services/authorizationApi';
import {getFeatureFlag} from 'src/services/featureFlagApi';
import {CalendarSvg, MousePointerSvg} from 'src/assets/svg/svgComponents';
import LoadingOverlay from 'react-loading-overlay';

class CandidateInsightsPage extends React.Component {
  state = {isMaSegment: false};

  componentDidMount() {
    let seekerId;
    if (this.props.match.params.id) {
      seekerId = this.props.match.params.id;
    }
    if (this.props.seekerLoading === false) {
      this.props.getSeeker(seekerId);
    }

    if (this.props.profile.securityRoleNames.includes('ADMIN')) {
      this.showAdminSubMenu = true;
      this.adminSubMenuEnabled = true;
    } else {
      has_permission(PERMISSIONS.VIEW_CANDIDATE_INSIGHTS).then((response) => {
        this.showAdminSubMenu = response.data.hasPermission;

        if (response.data.hasPermission) {
          getFeatureFlag('candidate_insights_view').then((response) => {
            this.adminSubMenuEnabled = response.data.enabled;
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.seeker !== this.props.seeker) {
      this.setState({
        isMaSegment: this.props.seeker.segments.some(
          (x) => x.isApprenticeProgram
        ),
      });
    }
  }

  render() {
    const renderInsight = (
      title,
      Icon,
      value,
      includeHorizontalLine = false,
      iconSize = {width: 16, height: 16}
    ) => {
      return (
        <>
          {value && (
            <>
              <div className="row">
                <div className="col-auto p-0 d-flex align-items-center">
                  <Icon
                    className="svg_color_stroke_blue"
                    width={iconSize.width}
                    height={iconSize.height}
                  />
                </div>
                <div className="col">
                  <div className="bold">{title}</div>
                  <div>{value}</div>
                </div>
              </div>
              {includeHorizontalLine && <hr />}
            </>
          )}
        </>
      );
    };

    return (
      <DocumentTitle title="Insights">
        <LoadingOverlay
          active={this.props.seekerLoading}
          spinner={true}
          text="Loading..."
          fadeSpeed={200}
        >
          <div className="wholepage">
            <Layout.PageHeader title={null} showAdminLinks={true} />
            <div className="bd-pagebody">
              <main id="main" tabIndex="-1">
                <PreviousButton />
                {!this.props.seekerLoading && (
                  <div className="container">
                    <div className="row">
                      <CandidateInfo
                        seeker={this.props.seeker}
                        isMaSegment={this.state.isMaSegment}
                      />
                    </div>
                    {this.showAdminSubMenu && this.adminSubMenuEnabled && (
                      <AdminSubMenu seekerId={this.props.match.params.id} />
                    )}
                    <div className="d-flex justify-content-center">
                      <div className="col-11">
                        <div className="font-20 bold">Insights</div>
                        <div
                          className="row"
                          style={{
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className="col candidate-insight-container">
                            {renderInsight(
                              'Last Login',
                              CalendarSvg,
                              this.props.seeker.lastLoginAt
                            )}
                          </div>
                          <div className="col candidate-insight-container">
                            {renderInsight(
                              'Application Clicks',
                              MousePointerSvg,
                              this.props.seekerEventStats?.applyClickCount
                            )}
                          </div>

                          <div className="col"></div>

                          <div className="col"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </main>
            </div>
            <Layout.PageFooter />
          </div>
          )
        </LoadingOverlay>
      </DocumentTitle>
    );
  }
}

CandidateInsightsPage.propTypes = {
  match: PropTypes.object.isRequired,
  seeker: PropTypes.object,
  getSeeker: PropTypes.func.isRequired,
  seekerLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object,
  seekerEventStats: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
    profile: state.profile,
    seekerEventStats: state.seekers.seeker.eventStats,
  };
}

const actions = {
  ...seekersActions,
};

export default withRouter(
  connect(mapStateToProps, actions)(CandidateInsightsPage)
);
