import _ from 'lodash';
import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function getConnectionsList(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  recruiterId
) {
  let uri = `${apiUrl}/placements`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }
  if (recruiterId > 0) {
    p.push('ascendRecruiterId=' + recruiterId);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  p = p.filter((p1) => p1 !== '' && p1 !== null);
  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.CONNECTIONS__GET_ALL_REQUEST,
      types.CONNECTIONS__GET_ALL_SUCCESS,
      types.CONNECTIONS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getConnectionsReports() {
  let uri = `${apiUrl}/connections/reports`;
  return {
    types: [
      types.CONNECTIONS__GET_REPORTS_REQUEST,
      types.CONNECTIONS__GET_REPORTS_SUCCESS,
      types.CONNECTIONS__GET_REPORTS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function createConnection(connection) {
  return {
    types: [
      types.CONNECTIONS__CREATE_REQUEST,
      types.CONNECTIONS__CREATE_SUCCESS,
      types.CONNECTIONS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/placements`,
    method: 'POST',
    body: connection,
  };
}

export function createSeekerConnection(connection) {
  return {
    types: [
      types.CONNECTIONS__SEEKER_CREATE_REQUEST,
      types.CONNECTIONS__SEEKER_CREATE_SUCCESS,
      types.CONNECTIONS__SEEKER_CREATE_FAILURE,
    ],
    uri: `${apiUrl}/seeker_connections`,
    method: 'POST',
    body: connection,
    isAuthenticated: false,
  };
}

export function getSeekerConnectionFromTask(taskId) {
  return {
    types: [
      types.CONNECTIONS__SEEKER_GET_REQUEST,
      types.CONNECTIONS__SEEKER_GET_SUCCESS,
      types.CONNECTIONS__SEEKER_GET_FAILURE,
    ],
    uri: `${apiUrl}/seeker_connections/task/${taskId}`,
    method: 'GET',
    isAuthenticated: true,
  };
}

export function getRecentConnection(seekerId) {
  return {
    types: [
      types.CONNECTIONS__RECENT_CONNECTION_GET_REQUEST,
      types.CONNECTIONS__RECENT_CONNECTION_GET_SUCCESS,
      types.CONNECTIONS__RECENT_CONNECTION_GET_FAILURE,
    ],
    uri: `${apiUrl}/placements/recent_for_seeker/${seekerId}`,
    method: 'GET',
    isAuthenticated: true,
  };
}
