import {seekerFields} from '../intake/intake2Lists';
import {store} from '../../index';

export const prepSeekerFields = (fieldData) => {
  let newSeeker = {...seekerFields};

  if (fieldData.employmentTypeIds) {
    fieldData.employmentTypeIds = fieldData.employmentTypeIds.map(
      (x) => x.value
    );
  }

  if (fieldData.experienceLevelIds) {
    fieldData.experienceLevelIds = fieldData.experienceLevelIds.map(
      (x) => x.value
    );
  }

  if (fieldData.skillTagIds) {
    fieldData.skillTagIds = fieldData.skillTagIds.map((x) => x.value);
  }

  if (fieldData.educationStatus) {
    fieldData.educationStatus = fieldData.educationStatus === true;
  }

  if (fieldData.educationLevel) {
    fieldData.educationLevelId = fieldData.educationLevel.id;
  }

  if (fieldData.workStatusAllowed !== undefined) {
    fieldData.workStatus =
      fieldData.workStatusAllowed === 'true' &&
      fieldData.sponsorStatus === 'false'
        ? 'US Citizen'
        : 'F1 Visa';
    fieldData.workStatusAllowed = fieldData.workStatusAllowed === 'true';
  }

  if (fieldData.sponsorStatus !== undefined) {
    fieldData.sponsorStatus = fieldData.sponsorStatus === 'true';
  }

  Object.keys(fieldData).forEach(function (key) {
    if (key in newSeeker || fieldData.hasOwnProperty(key)) {
      newSeeker[key] = fieldData[key];
    }
  });

  if (fieldData.gender?.length === 1) {
    newSeeker.gender = fieldData.gender[0].value;
    if (fieldData.gender === 'Other') {
      newSeeker.gender = fieldData.otherGender;
    }
  } else {
    newSeeker.gender = '';
  }

  if (fieldData.experience && fieldData.experience.length > 0) {
    if (fieldData.experience[0].company === '') {
      newSeeker.experience = [];
    }
  }

  // Remove empty fields
  for (let propName in newSeeker) {
    if (
      newSeeker[propName] === null ||
      newSeeker[propName] === undefined ||
      newSeeker[propName] === '' ||
      newSeeker[propName] === 'NULL'
    ) {
      delete newSeeker[propName];
    }
  }
  return newSeeker;
};

export const getGraduateDateDiffDays = (graduationDateField) => {
  const [gradMonth, gradYear] = graduationDateField
    .split('/')
    .map((i) => Number(i));
  const graduationDate = new Date(gradYear, gradMonth - 1);
  const gradDateDifference =
    Math.floor(graduationDate - new Date()) / (1000 * 60 * 60 * 24);
  return gradDateDifference;
};

export const filledIntakeCache = {
  anyIndustryPref: true,
  anyRolePref: true,
  cacheInitialized: true,
  campusId: null,
  demographics: [
    {
      buttonValue: 4,
    },
  ],
  educationLevel: {
    degreeTypeRankId: 3,
    id: 4,
    isCollegeDegree: true,
    isEmployIndy: true,
    isHighSchoolDiploma: false,
    maxMajors: 3,
    name: "Bachelor's degree",
    shouldOfframp: false,
    showInstitution: true,
    showMajors: true,
    showMinors: true,
    showOtherSchoolInput: false,
    useFieldsOfStudyLabel: false,
  },
  eligibility: [
    {
      buttonText: 'Current Student \u00a0\u00a0\nAny education level',
      buttonValue: 'Current Student',
      id: '0',
      isToggled: true,
      value: 'Current Student',
    },
  ],
  employmentTypeIds: [
    {
      buttonText: 'Full-Time Job',
      buttonValue: 9,
      id: '0',
      isToggled: true,
      value: 9,
    },
  ],
  ethnicity: 'NULL',
  experience: [
    {
      company: 'Technology USA',
      notes:
        '<ul><li>Condense complex technical and logistical terms to assist customers with understanding products and services</li><li>Recommend home theater solutions on a per client basis, taking into account needs, wants, desires, and budget</li><li>Uphold daily sales requirement (e.g. daily revenue, services, credit card applications) by viewing hourly records</li></ul>',
      timeframe: '2018 to current',
      title: 'Customer Service Team Lead',
    },
    {
      company: "Fuzzy's Fast Food",
      notes:
        '<ul><li>Maintain daily record of transactions and generate reports for management team</li><li>Lead restocking and reordering efforts with corporate office</li><li>Train a team of eight to provide exemplary service to our customers, which averaged over 200 per day</li><li>Created schedules for team based on group availability and tracked attendance</li></ul>',
      timeframe: '2016 to 2018',
      title: 'Assistant Manager',
    },
  ],
  experienceLevelIds: [
    {
      buttonText: 'Internships and Entry Level (0-3 years)',
      buttonValue: 1,
      id: '0',
      isToggled: true,
      value: 1,
    },
  ],
  firstGenStudent: 'No',
  firstName: 'Auto',
  gender: [
    {
      buttonText: 'Prefer not to answer',
      buttonValue: 'NULL',
      id: '0',
      isToggled: true,
      value: 'NULL',
    },
  ],
  generalInfoMenu: true,
  gpa: '4.0',
  graduationDate: '5/2022',
  graduationMonth: 'May',
  graduationYear: '2022',
  industryIds: [114],
  institutionId: 417,
  invitationCodeId: 39,
  lastName: 'Seeker',
  majorIds: [2926],
  otherInstitution: ' asdf',
  page: 0,
  pellGrant: 'No',
  phone: '(123) 123-1234',
  regionIds: ['25'],
  roleTypeIds: [100],
  schoolId: '',
  section: 'yourRecruiter',
  segments: [
    {
      createdAt: '2020-06-01T18:44:37Z',
      deletedAt: null,
      id: 1,
      isApprenticeProgram: false,
      name: 'Ascend',
      requireEducationData: true,
      showConfidenceSurvey: true,
      updatedAt: null,
    },
  ],
  signature: 'asdf',
  skillTags: [
    {
      id: 31,
      label: '.NET',
      name: '.NET',
      value: 31,
    },
    {
      id: 175,
      label: '3D Modeling Software',
      name: '3D Modeling Software',
      value: 175,
    },
    {
      id: 167,
      label: 'API Integration',
      name: 'API Integration',
      value: 167,
    },
    {
      id: 154,
      label: 'Account Management',
      name: 'Account Management',
      value: 154,
    },
    {
      id: 107,
      label: 'Accounting Software',
      name: 'Accounting Software',
      value: 107,
    },
    {
      id: 108,
      label: 'Accounts Payable',
      name: 'Accounts Payable',
      value: 108,
    },
    {
      id: 109,
      label: 'Accounts Receivable',
      name: 'Accounts Receivable',
      value: 109,
    },
    {
      id: 140,
      label: 'Accredited Adviser in Insurance (AAI)',
      name: 'Accredited Adviser in Insurance (AAI)',
      value: 140,
    },
  ],
  sponsorStatus: 'false',
  startYourProfileMenu: true,
  termsAccepted: true,
  termsOfUse: true,
  trackedPages: {
    aboutYouTracking: [0, 1],
    generalInfoTracking: [0, 1, 2, 3, 4, 5],
    startYourProfileTracking: [0, 1, 2, 3],
    yourIdealJobTracking: [0, 1, 2, 3, 4],
    yourRecruiterTracking: [0],
  },
  twentyFirstCenturyScholar: 'No',
  veteran: 'No',
  workStatusAllowed: 'true',
  yourIdealJobMenu: true,
  zipCode: '12345',
};

export const createAnalyticsProfile = (intakeCache) => {
  const analyticsProfile = {};
  const state = store.getState();

  if (intakeCache.segments) {
    analyticsProfile.segmentNames = intakeCache.segments.map((x) => x.name);
    analyticsProfile.segment = intakeCache.segments[0].name;
    analyticsProfile.segmentIds = intakeCache.segments[0].id;
  }

  if (intakeCache.gpa) {
    analyticsProfile.gpa = intakeCache.gpa;
  }

  if (intakeCache.graduationMonth) {
    analyticsProfile.graduationMonth = intakeCache.graduationMonth;
  }

  if (intakeCache.graduationYear) {
    analyticsProfile.graduationYear = intakeCache.graduationYear;
  }

  if (intakeCache.educationLevel) {
    analyticsProfile.seekerEducationLevel = intakeCache.educationLevel.name;
  }

  if (intakeCache.employmentTypeIds) {
    analyticsProfile.seekerEmploymentTypePreferences =
      intakeCache.employmentTypeIds.map((x) => x.buttonText).toString();
  }

  if (
    intakeCache.majorIds &&
    intakeCache.majorIds[0] !== -1 &&
    state.institutions.majors.length > 0
  ) {
    const majors = state.institutions.majors;

    analyticsProfile.primaryMajor = majors.filter(
      (x) => x.id === intakeCache.majorIds[0]
    )[0].name;

    if (intakeCache.majorIds[1]) {
      analyticsProfile.secondaryMajor = majors.filter(
        (x) => x.id === intakeCache.majorIds[1]
      )[0].name;
    }
  }

  if (intakeCache.industryIds) {
    analyticsProfile.seekerIndustryPreferences = intakeCache.industryIds
      .map((x) => x.tagName)
      .toString();
  }

  if (intakeCache.regionIds) {
    const selectedLocations = state.regions.regions.filter((x) =>
      intakeCache.regionIds.includes(x.id.toString())
    );

    analyticsProfile.seekerLocationPreferences = selectedLocations
      .map((x) => x.name)
      .toString();
  }

  if (intakeCache.roleTypeIds) {
    analyticsProfile.seekerRoleTypePreferences = intakeCache.roleTypeIds
      .map((x) => x.tagName)
      .toString();
  }

  if (intakeCache.institutionId && state.institutions.institutions.length > 0) {
    const institutions = state.institutions.institutions;

    analyticsProfile.university = institutions.filter(
      (x) => x.id === intakeCache.institutionId
    )[0].name;
  }

  return analyticsProfile;
};
