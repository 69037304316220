import React from 'react';
import PropTypes from 'prop-types';
import * as usersActions from '../actions/users';
import {connect} from 'react-redux';
import {PERMISSIONS} from '../services/authorizationApi';
import {makeRequest} from '../services/api';

const renderRow = (title, content) => {
  return (
    <div className="row" style={{paddingBottom: '8px'}}>
      <div className="col-md-12">
        <h2
          className="normal-caption bold"
          style={{paddingLeft: '8px', fontSize: '15px'}}
        >
          {title}
        </h2>
        <div className="disabled-caption" style={{paddingLeft: '8px'}}>
          {content || 'Not selected'}
        </div>
      </div>
    </div>
  );
};

const makeStartDateText = (roleStartFlexibility, availableToStart) => {
  if (!roleStartFlexibility) {
    return 'Not selected';
  } else if (roleStartFlexibility === 'Specific') {
    return availableToStart;
  } else return roleStartFlexibility;
};

const renderInstructions = (applicationInstructions) => {
  if (applicationInstructions && applicationInstructions !== '') {
    return (
      <div className="row" style={{paddingBottom: '8px'}}>
        <div className="col-md-12">
          <h2
            className="normal-caption bold"
            style={{paddingLeft: '8px', fontSize: '15px'}}
          >
            Application Instructions
          </h2>
          <div className="disabled-caption" style={{paddingLeft: '8px'}}>
            {applicationInstructions}
          </div>
        </div>
      </div>
    );
  }
};

class JobPreferences extends React.Component {
  state = {
    userList: [],
  };

  componentDidMount() {
    this.props.getUsersByPermission(PERMISSIONS.CAN_BE_ACCOUNT_MANAGER);

    makeRequest({
      method: 'GET',
      path: 'v2/experience_levels',
      body: {},
      contentType: 'application/json',
    }).then((results) => {
      if (results.status === 200) {
        this.setState({experienceLevelsList: results.data.experienceLevels});
      }
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot): void {
    if (prevProps.usersList !== this.props.usersList) {
      let list = this.props.usersList.map((x) => {
        return {
          value: x.userId,
          label: `${x.firstName} ${x.lastName}`,
        };
      });

      this.setState({userList: list});
    }
  }
  findUserName = (userId) => {
    const user = this.state.userList.find((x) => x.value === userId);
    if (user) {
      return user.label;
    }
    return '';
  };
  render() {
    const {job, degreeType, pathways} = this.props;
    const isMaSegment = job.segments.some((x) => x.isApprenticeProgram);

    const getDegreeTypeText = (employmentTypes) => {
      const list = [
        'Spring Internship',
        'Fall Internship',
        'Summer Internship',
      ];

      if (employmentTypes.some((x) => list.indexOf(x.name) !== -1)) {
        return 'Degree Type In Progress';
      }
      return 'Minimum Degree Required';
    };

    const makePathway = (pathId) => {
      const pathway = pathways.filter((e) => e.value === pathId);
      if (pathway.length > 0) {
        return pathway[0].label;
      }
    };

    return (
      <div style={{padding: '0 4px'}}>
        {isMaSegment === true &&
          renderRow('Pathway', makePathway(job.apprenticePathwayId))}
        {renderRow(
          'Start Date',
          makeStartDateText(job.roleStartFlexibility, job.availableToStart)
        )}
        {isMaSegment === false &&
          renderRow(
            'Experience Level',
            job.experienceLevels.map((x) => `${x.name} (${x.years})`).join(', ')
          )}
        {isMaSegment === false &&
          renderRow(
            'Role Type(s)',
            job.roleTypes.map((x) => x.name).join(', ')
          )}
        {isMaSegment === false &&
          renderRow('Industries', job.industries.map((x) => x.name).join(', '))}
        {isMaSegment === false &&
          renderRow('Region(s)', job.regions.map((x) => x.name).join(', '))}
        {isMaSegment === false &&
          renderInstructions(job.applicationInstructions)}
        {isMaSegment === false &&
          renderRow(getDegreeTypeText(job.employmentTypes), degreeType)}
        {isMaSegment === false &&
          this.props.role === 'ASCEND_RECRUITER' &&
          job.segments &&
          renderRow('Segment', job.segments.map((x) => x.name).join(', '))}
        {isMaSegment === false &&
          this.props.role === 'ASCEND_RECRUITER' &&
          renderRow(
            'Account Manager',
            this.findUserName(job.employerAccountManagerId)
          )}
        {isMaSegment === true &&
          renderRow('Number of Openings', job.numberOfOpenings)}
        {isMaSegment === true &&
          renderRow(
            'Drug Test?',
            job.drugTest === null ? 'Unknown' : job.drugTest ? 'Yes' : 'No'
          )}
        {isMaSegment === true &&
          renderRow(
            'Background Check?',
            job.backgroundCheck === null
              ? 'Unknown'
              : job.backgroundCheck
              ? 'Yes'
              : 'No'
          )}
        {isMaSegment === true &&
          renderRow(
            'Transportation Assistance',
            job.transportationAssistance === null
              ? 'Unknown'
              : job.transportationAssistance
              ? 'Yes'
              : 'No'
          )}
        {isMaSegment === true &&
          renderRow(
            'Public Transportation Access?',
            job.publicTransportationAccess === null
              ? 'Unknown'
              : job.publicTransportationAccess
              ? 'Yes'
              : 'No'
          )}
      </div>
    );
  }
}

JobPreferences.propTypes = {
  job: PropTypes.object.isRequired,
  degreeType: PropTypes.string,
  role: PropTypes.string.isRequired,
  usersList: PropTypes.array,
  getUsersByPermission: PropTypes.func.isRequired,
  pathways: PropTypes.array,
};
const mapStateToProps = (state) => {
  return {
    usersList: state.users.usersListByPermission,
    pathways: state.apprenticePathways.apprenticePathways,
  };
};
const mapDispatchToProps = {
  ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobPreferences);
