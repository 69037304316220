import {AscendModal, AscendModalBody} from '../layout';
import styles from './JobProfile.module.scss';
import React from 'react';

export function DeleteRoleConfirm(props: {
  confirmDeleteIsOpen: boolean,
  jobName: string,
  onClose: () => void,
  onClick: () => void,
}) {
  return (
    <AscendModal
      styles={{maxWidth: '450px', width: '100%'}}
      isOpen={props.confirmDeleteIsOpen}
      onClose={props.onClose}
      centerVertically={true}
    >
      <AscendModalBody bodyClass={styles.modalBody}>
        <div className={styles.modalTitle}>
          Are you sure you want to delete this role?
        </div>
        <div>
          Once deleted, this role and all its information will be removed.
          <br /> Job seekers will no longer be able to apply, but you will still
          be able to see previous applicants in your applications tab.
        </div>
        <div className={styles.modalJobTitle}>{props.jobName}</div>
        <div className="row justify-content-center">
          <button
            className="clickable btn btn-secondary col-3"
            onClick={props.onClose}
          >
            No, cancel
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button
            type="button"
            className="clickable btn btn-primary col-4"
            onClick={props.onClick}
          >
            Yes, delete role
          </button>
        </div>
      </AscendModalBody>
    </AscendModal>
  );
}
