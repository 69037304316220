import React from 'react';
import styles from './InputSearch.module.scss';
import PropTypes from 'prop-types';
import SearchSvg from 'src/assets/images/search.svg';

const InputSearch = (props) => {
  return (
    <div
      className={props.className}
      style={{...props.containerStyle, width: '100%'}}
    >
      <div className="input-group" style={{flexWrap: 'nowrap'}}>
        <div
          style={props.style}
          className={`${styles.searchIcon} input-group-text border-end-0 rounded-start`}
        >
          <img
            src={props.glyph ? props.glyph : SearchSvg}
            className={styles.settingsSvg_fill_orange}
            alt="Search"
          />
        </div>
        <input
          style={props.textBoxStyle}
          autoComplete="off"
          className={`${styles.searchBox} form-control border-start-0 border`}
          placeholder={props.placeHolder}
          defaultValue={props.value}
          aria-label={props.ariaLabel}
          type="search"
          id={props.inputId}
          key={props.inputId}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e.target.value);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && props.onEnterKey) {
              props.onEnterKey(e.target.value);
              return;
            }
          }}
          ref={(element) => {
            if (element && props.value === '') {
              element.value = '';
            }
            (element || {}).onsearch = (e) => {
              if (e.target.value === '' && props.onEnterKey) {
                props.onEnterKey(e.target.value);
              }
            };
          }}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

InputSearch.propTypes = {
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  onEnterKey: PropTypes.func,
  onChange: PropTypes.func,
  searchDelay: PropTypes.number,
  style: PropTypes.any,
  textBoxStyle: PropTypes.any,
  className: PropTypes.any,
  glyph: PropTypes.any,
  ariaLabel: PropTypes.string,
  inputId: PropTypes.string,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.any,
};

InputSearch.defaultProps = {
  placeHolder: 'Search ...',
  searchDelay: 1000,
  inputId: 'search-input',
  disabled: false,
};

export default InputSearch;
