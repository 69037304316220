import * as types from './ActionTypes';
const apiUrl = process.env.REACT_APP_API_URL;

export function getAllApprenticePathways() {
  let uri = `${apiUrl}/v2/apprenticePathways/all`;
  return {
    types: [
      types.APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_REQUEST,
      types.APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_SUCCESS,
      types.APPRENTICEPATHWAYS__GET_ALL_APPRENTICEPATHWAYS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getApprenticePathways(highSchoolId = 0) {
  let uri =
    highSchoolId === 0
      ? `${apiUrl}/v2/apprenticePathways/all`
      : `${apiUrl}/v2/apprenticePathwaysByHighSchool/${highSchoolId}`;

  return {
    types: [
      types.APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_REQUEST,
      types.APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_SUCCESS,
      types.APPRENTICEPATHWAYS__GET_APPRENTICEPATHWAYS_BY_HIGHSCHOOL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    isAuthenticated: false,
  };
}
