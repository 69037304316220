import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {Button} from 'reactstrap';
import {
  AscendModal,
  AscendModalBody,
  AscendModalHeader,
  AscendModalFooter,
} from 'src/layout';

import * as applicationsActions from 'src/actions/applications';
import * as tableActions from 'src/actions/applicationsDataTable';
import * as recruiterActions from 'src/actions/ascendRecruiters';
import * as seekersActions from 'src/actions/seekers';

import {PageHeader, PageFooter} from 'src/layout';
import AscendDataTable from 'src/components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import StatsDashboard from 'src/components/StatsDashboard';
import {updateApplication} from 'src/services/applicationApi';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';

import {formatDate} from 'src/utils/miscHelper';
import * as SVG from 'src/assets/svg/svgComponents';
import NotesModal from 'src/components/NotesModal';
import {graduationMonths} from 'src/utils/fieldHelper';
import {format} from 'date-fns';
import infoBlueCircle from 'src/assets/images/info-blue-circle.svg';

const searchFields = [
  'firstName',
  'lastName',
  'jobName',
  'employerName',
  'manager',
  'institution',
  'email',
];
const searchPlaceholder = 'Name, Company, AR';

function isUrsp(seeker) {
  if (
    seeker.twentyFirstCenturyScholar ||
    seeker.pellGrant ||
    seeker.firstGenStudent
  ) {
    return true;
  }

  if (seeker.ethnicity === null) {
    return false;
  }
  return seeker.ethnicity !== 'Caucasian (Non-Hispanic)';
}

class ApplicationsListPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      currentItemsPerPage: 25,
      showAllItems: true,
      loading: false,
      recruiterId: null,
      recruiterName: '',
      canViewCandidatePII: false,
      notesModalIsOpen: false,
      notesSeeker: {
        firstName: '',
        lastName: '',
        avatarUrl: '',
        id: null,
      },
      restrictedSegmentInfoModalIsOpen: false,
      hoveredApplication: null,
    };
  }

  toggleRestrictedSegmentInfoModal = () => {
    this.setState({
      restrictedSegmentInfoModalIsOpen:
        !this.state.restrictedSegmentInfoModalIsOpen,
    });
  };

  getParam = (paramname) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramname);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) {
        return;
      }
      this.setState({
        canViewCandidatePII: resp.data.hasPermission,
      });
    });
    this.props.getApplicationStats();
    const reviewApplications = this.getParam('reviewApplications');
    if (reviewApplications === 'true') {
      this.props.updateSortTerm('appliedOn', false);
      this.handleSortDropDownChange({id: -2, label: 'Mine'});
    } else {
      this.refreshApplications(
        this.props.currentPage,
        this.props.itemsPerPage,
        this.props.searchTerm,
        this.props.sortBy,
        this.props.sortAscending,
        this.state.showAllItems
      );
    }

    this.props.getAscendRecruiters();
  }
  buildRecruiterList = () => {
    return this.props.ascendRecruiters.map((x) => {
      return {id: x.id, label: x.name};
    });
  };

  refreshApplicationStats = (showAllItems = null, recruiterId = null) => {
    showAllItems =
      showAllItems === null ? this.state.showAllItems : showAllItems;
    if (recruiterId === null) {
      recruiterId = this.state.recruiterId;
    }
    if (recruiterId === -2) {
      recruiterId = this.props.currentAscendRecruiterId;
    }

    this.props.getApplicationStats(showAllItems, recruiterId);
  };

  refreshApplications = (
    currentPage,
    itemsPerPage,
    searchTerm,
    sortBy,
    sortAscending,
    showAllItems,
    recruiterId
  ) => {
    let filters = {
      showAllItems: showAllItems,
    };
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    recruiterId =
      recruiterId === -2 ? this.props.currentAscendRecruiterId : recruiterId;

    this.props.getApplicationReports();

    this.refreshApplicationStats(showAllItems, recruiterId);
    this.props.getApplications(
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      true,
      recruiterId
    );
  };

  handlePageChange = (pageNumber) => {
    this.props.updateCurrentPage(pageNumber);
    this.refreshApplications(
      pageNumber,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending,
      this.state.showAllItems,
      this.state.recruiterId
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    this.props.updateSearchTerm(searchTerm);

    if (search) {
      this.refreshApplications(
        0,
        this.props.itemsPerPage,
        searchTerm,
        this.props.sortBy,
        this.props.sortAscending,
        this.state.showAllItems,
        this.state.recruiterId
      );
    }
  };

  handleSortDropDownChange = (option) => {
    this.setState({
      showAllItems: option.label === 'All',
      recruiterId: option.id,
      recruiterName: option.label,
    });
    this.refreshApplications(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending,
      option.label === 'All',
      option.id
    );
  };

  handleSortChange = (sortBy, sortAscending) => {
    this.props.updateSortTerm(sortBy, sortAscending);
    this.refreshApplications(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      sortBy,
      sortAscending,
      this.state.showAllItems,
      this.state.recruiterId
    );
  };

  handleRankClick = async (rank, application_id) => {
    try {
      document.body.style.overflow = 'hidden';
      this.setState({loading: true});
      await updateApplication({id: application_id, recruiterRank: rank});
      let app = _.find(this.props.applications, {id: application_id});
      app.recruiterRank = rank;
      this.props.updateApplication(app);
    } finally {
      this.setState({loading: false});
      document.body.style.overflow = 'scroll';
    }
  };

  handleAdvocateClick = async (application) => {
    try {
      document.body.style.overflow = 'hidden';
      this.setState({loading: true});
      await updateApplication({
        id: application.id,
        recruiterAdvocated: !application.recruiterAdvocated,
      });
      let app = _.find(this.props.applications, {id: application.id});
      app.recruiterAdvocated = !application.recruiterAdvocated;
      this.props.updateApplication(app);
      this.refreshApplicationStats();
    } finally {
      this.setState({loading: false});
      document.body.style.overflow = 'scroll';
    }
  };

  handleNotesClicked = (seeker) => {
    this.setState({notesSeeker: seeker, notesModalIsOpen: true});
  };

  handleNoteAdded = () => {
    this.props.incrementSeekersListNoteCount(this.state.notesSeeker.id);
  };

  handleNoteRemoved = () => {
    this.props.decrementSeekersListNoteCount(this.state.notesSeeker.id);
  };

  buildNotesColumn = ({seeker}) => {
    return (
      <div
        tabIndex={0}
        onClick={() => {
          this.handleNotesClicked(seeker);
        }}
        className="d-flex justify-content-center align-items-center text-nowrap"
      >
        <div
          style={{position: 'relative'}}
          className="d-flex justify-content-center align-items-center clickable clickable-circle"
        >
          <SVG.MessageDefaultSvg alt="" /> &nbsp; {seeker.noteCount}
        </div>
      </div>
    );
  };

  handleFlagClicked = async (application) => {
    try {
      document.body.style.overflow = 'hidden';
      this.setState({loading: true});
      await this.props.toggleSeekerSupportFlag(application.seeker.id);
      await this.props.incrementSeekersListNoteCount(application.seeker.id);

      let app = _.find(this.props.applications, {id: application.id});
      this.props.updateApplication({
        ...app,
        seeker: {
          ...app.seeker,
          seekerSupportFlag: !app.seeker.seekerSupportFlag,
          noteCount: app.seeker.noteCount + 1,
        },
      });
    } finally {
      this.setState({loading: false});
      document.body.style.overflow = 'scroll';
    }
  };

  buildFlagColumn = (application) => {
    return (
      <button
        tabIndex={0}
        onClick={() => {
          this.handleFlagClicked(application);
        }}
        className="d-flex justify-content-center align-items-center text-nowrap buttonAsLink"
      >
        <div
          style={{position: 'relative'}}
          className="d-flex justify-content-center align-items-center clickable clickable-circle"
        >
          {application.seeker.seekerSupportFlag === true && (
            <SVG.FlagSolidSvg alt="" />
          )}
          {application.seeker.seekerSupportFlag === false && (
            <SVG.FlagSvg alt="" />
          )}
        </div>
      </button>
    );
  };

  getStats(rowFilter) {
    // For the stats dashboard at the top of the page.
    return [
      {
        label: 'Unique Applicants',
        value: this.props.stats.applicantCount,
        row: 1,
      },
      {
        label: 'Unique Advocated',
        value: this.props.stats.uniqueAdvocatedCandidates,
        row: 1,
      },
      {
        label: 'Advocated Applications',
        value: this.props.stats.advocatedCandidates,
        row: 1,
      },
      {
        label: 'Roles',
        value: this.props.stats.jobCount,
        row: 1,
      },
      {
        label: 'Published Applicants',
        value: this.props.stats.publishedApplicantCount,
        row: 1,
      },
      {
        label: 'Unique Applicants',
        value: this.props.stats.urspApplicantCount,
        row: 2,
      },
      {
        label: 'Unique Advocated',
        value: this.props.stats.urspUniqueAdvocatedCandidates,
        row: 2,
      },
      {
        label: 'Advocated Applications',
        value: this.props.stats.urspAdvocatedCandidates,
        row: 2,
      },
      {
        label: 'Roles',
        value: this.props.stats.urspJobCount,
        row: 2,
      },
      {
        label: 'Published Applicants',
        value: this.props.stats.urspPublishedApplicantCount,
        row: 2,
      },
    ].filter((x) => {
      return x.row === rowFilter;
    });
  }

  render() {
    const rankOptions = {
      1: 'High',
      2: 'Medium',
      3: 'Low',
    };
    const tableColumns = [
      {
        displayName: 'NAME',
        sortBy: 'name',
        content: (app) => (
          <>
            <Link
              className="admin-item-link"
              to={`/candidate/${app.seeker.id}`}
            >
              {`${app.seeker.firstName} ${app.seeker.lastName}`}
            </Link>
            {app.showRestrictedSegmentFlag && (
              <>
                &nbsp;
                <img
                  style={{width: '16px'}}
                  alt="Please note that employer users for this employer will not see this application within the Network UI."
                  data-tooltip-id="application-list-tooltip"
                  data-tooltip-delay-hide={200}
                  src={infoBlueCircle}
                  onMouseEnter={() => {
                    this.setState({hoveredApplication: app});
                  }}
                />
              </>
            )}
          </>
        ),
      },
      {
        displayName: 'GRADUATE',
        sortBy: 'graduationDate',
        content: (app) =>
          app.seeker.graduationMonth && app.seeker.graduationYear
            ? format(
                new Date(
                  app.seeker.graduationYear,
                  graduationMonths
                    .filter((x) => x !== '')
                    .indexOf(app.seeker.graduationMonth)
                ),
                'yyyy-MM'
              )
            : 'n/a',
      },
      {
        displayName: 'URSP',
        sortBy: 'ursp',
        content: (app) => (isUrsp(app.seeker) ? 'Yes' : 'No'),
      },
      {
        displayName: 'MANAGER',
        sortBy: 'manager',
        content: (app) =>
          app.seeker.ascendRecruiterName
            ? app.seeker.ascendRecruiterName
            : 'n/a',
      },
      {
        displayName: 'APPLIED ON',
        sortBy: 'appliedOn',
        content: (app) => (
          <span className="nowrap">{formatDate(app.createdAt)}</span>
        ),
      },
      {
        displayName: 'ROLE',
        sortBy: 'jobName',
        content: (app) => (
          <Link className="admin-item-link" to={`/role/${app.job.id}`}>
            {app.job.name}
          </Link>
        ),
      },
      {
        displayName: 'COMPANY',
        sortBy: 'employerName',
        content: (app) => (
          <Link
            className="admin-item-link"
            to={`/employer/${app.job.employerId}`}
          >
            {app.job.employerName}
          </Link>
        ),
      },
      {
        displayName: 'RANK',
        sortBy: 'recruiterRank',
        content: (app) => {
          return {
            value: app.recruiterRank,
            label: rankOptions[app.recruiterRank] || 'Unranked',
            options: rankOptions,
            onChange: (x, y) => this.handleRankClick(x, y),
          };
        },
        showAsSelect: true,
      },
      {
        displayName: 'ADVOCATED',
        sortBy: 'recruiterAdvocated',
        content: (app) => (
          <div className="custom-control custom-checkbox centered-checkbox form-check">
            <input
              type="checkbox"
              id={`advocacy${app.id}`}
              name={`advocacy${app.id}`}
              className="custom-control-input form-check-input checkbox-formatting me-0"
              value={app.recruiterAdvocated}
              checked={app.recruiterAdvocated && 'checked'}
              onChange={() => {
                this.handleAdvocateClick(app);
              }}
            />
            <label
              className="custom-control-label form-check-label"
              htmlFor={`advocacy${app.id}`}
            >
              <span
                style={{
                  position: 'absolute',
                  border: 0,
                  height: 0,
                  margin: 0,
                  overflow: 'hidden',
                  padding: 0,
                }}
              >{`${app.seeker.firstName} ${app.seeker.lastName} advocacy`}</span>
            </label>
          </div>
        ),
      },
      {
        sortBy: 'notes',
        displayName: 'NOTES',
        content: this.buildNotesColumn,
      },
      {
        sortBy: 'seekerSupportFlag',
        displayName: 'FLAG',
        content: this.buildFlagColumn,
      },
    ];

    let prefixLabel = !!this.props.searchTerm ? 'Filtering' : '';
    prefixLabel +=
      ' ' + (this.state.showAllItems ? 'All' : this.state.recruiterName);
    prefixLabel += ' (AY / SEM)';
    let tableControlLabelText = `${prefixLabel} - ${this.props.currentYearApplicationCount} / ${this.props.semesterApplicationCount}`;

    const filterList = [
      {id: -1, label: 'All'},
      {id: -2, label: 'Mine'},
      ...this.buildRecruiterList(),
    ];

    const highLightRow = (row) => {
      if (row?.seeker?.seekerSupportFlag) {
        return true;
      }
      return false;
    };

    return (
      <DocumentTitle title="Applications Admin">
        <div className="wholepage">
          <ReactTooltip
            id="application-list-tooltip"
            className="solid-tooltip-dark"
            globalCloseEvents={'escape'}
            clickable={true}
          >
            Please note that employer users for this employer will not see this
            application within the Network UI.{' '}
            <button
              className="buttonAsLink"
              style={{color: '#fff'}}
              type="button"
              onClick={() => {
                this.toggleRestrictedSegmentInfoModal();
              }}
            >
              Learn why here.
            </button>
          </ReactTooltip>

          <AscendModal
            isOpen={this.state.restrictedSegmentInfoModalIsOpen}
            onClose={this.toggleRestrictedSegmentInfoModal}
            centerVertically={true}
          >
            <AscendModalHeader headerClass="bd-modalheader" hasClose={true}>
              <span className="normal-headline-white bold">
                Further Information
              </span>
            </AscendModalHeader>
            <AscendModalBody>
              This seeker is in the{' '}
              {this.state.hoveredApplication?.seeker.segments[0].name} segment,
              and the employer is in the{' '}
              {this.state.hoveredApplication?.job.employerSegmentName} segment
              {this.state.hoveredApplication?.job.employerSegmentName.includes(
                ','
              ) && <>(s)</>}
              . Employers can only see personal information for seekers within
              their same segment. This rule is enforced to protect seekers'
              personal information. Employers can still view this seeker's
              information offsite, if the seeker has applied via their official
              application channels.
            </AscendModalBody>
            <AscendModalFooter
              footerClass="bd-modalbody"
              style={{paddingTop: '0'}}
            >
              <hr />
              <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Button
                  color="primary"
                  onClick={this.toggleRestrictedSegmentInfoModal}
                >
                  Back to Applications Table
                </Button>{' '}
              </div>
            </AscendModalFooter>
          </AscendModal>

          <PageHeader title="Administration" showAdminLinks={true} />

          <LoadingOverlay
            active={this.state.loading}
            spinner={true}
            text="Saving..."
            fadeSpeed={200}
            styles={{
              overlay: (base) => ({
                ...base,
                height: '100px',
                position: 'fixed',
              }),
            }}
          />
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <NotesModal
                  isOpen={this.state.notesModalIsOpen}
                  onClose={() => {
                    this.setState({notesModalIsOpen: false});
                  }}
                  seeker={this.state.notesSeeker}
                  key={this.state.notesSeeker.id}
                  onNoteAdded={this.handleNoteAdded}
                  onNoteRemoved={this.handleNoteRemoved}
                />
                <br />

                <div className="padding-bottom-eight">
                  <StatsDashboard
                    stats={this.getStats(1)}
                    labelPosition="bottom"
                    title="All Applications (Current Semester)"
                  />
                </div>
                <div className="padding-bottom-eight">
                  <StatsDashboard
                    stats={this.getStats(2)}
                    labelPosition="bottom"
                    title="URSP Students Only "
                  />
                </div>
                <br />
              </div>

              <div className="container-fluid">
                <h2 className="normal-headline bold float-left">
                  Role Applications
                </h2>
                <br />
                <br />
                {!this.props.applicationsLoading &&
                  !this.state.canViewCandidatePII && (
                    <h2>You do not have permission to view Candidate PII</h2>
                  )}
                {this.state.canViewCandidatePII && (
                  <Fragment>
                    <AscendDataTable
                      showSearch={true}
                      tableColumns={tableColumns}
                      items={this.props.applications}
                      currentPage={this.props.currentPage}
                      itemsPerPage={this.props.itemsPerPage}
                      sortBy={this.props.sortBy}
                      sortAscending={this.props.sortAscending}
                      totalItems={this.props.totalItems}
                      handlePageChange={this.handlePageChange}
                      handleSortChange={this.handleSortChange}
                      searchTerm={this.props.searchTerm}
                      handleFilterChange={this.handleFilterChange}
                      itemsLoading={this.props.applicationsLoading}
                      searchPlaceholder={searchPlaceholder}
                      headerCenterText={tableControlLabelText}
                      pullDownList={filterList}
                      pullDownDefaultValue={filterList[0]}
                      pullDownValue={filterList.find(
                        (flItem) => flItem.id === this.state.recruiterId
                      )}
                      pullDownOnChange={(x) => this.handleSortDropDownChange(x)}
                      pullDownWidth={200}
                      isRowHighlighted={highLightRow}
                    />
                    <ReportsTable
                      title="Application Reports"
                      reports={this.props.reports}
                    />
                  </Fragment>
                )}
              </div>
            </main>
          </div>

          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

ApplicationsListPage.propTypes = {
  applications: PropTypes.array.isRequired,
  ascendRecruiterId: PropTypes.number,
  stats: PropTypes.object.isRequired,
  getApplications: PropTypes.func.isRequired,
  getApplicationStats: PropTypes.func.isRequired,
  getApplicationReports: PropTypes.func.isRequired,
  updateApplication: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  semesterApplicationCount: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  applicationsLoading: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired,
  getAscendRecruiters: PropTypes.func.isRequired,
  ascendRecruiters: PropTypes.array,
  currentAscendRecruiterId: PropTypes.number,
  currentYearApplicationCount: PropTypes.number,
  location: PropTypes.object.isRequired,
  incrementSeekersListNoteCount: PropTypes.func.isRequired,
  decrementSeekersListNoteCount: PropTypes.func.isRequired,
  toggleSeekerSupportFlag: PropTypes.func.isRequired,
};

const actions = {
  ...seekersActions,
  ...applicationsActions,
  ...tableActions,
  ...recruiterActions,
};

function mapStateToProps(state, ownProps) {
  return {
    applications: state.applications.applications,
    location: state.router.location,
    stats: state.applications.stats,
    applicationsLoading: state.applications.applicationsLoading,
    itemsPerPage: state.applications.itemsPerPage,
    totalItems: state.applications.totalItems,
    semesterApplicationCount: state.applications.semesterApplicationCount,
    currentPage: state.applicationsDataTable.currentPage,
    searchTerm: state.applicationsDataTable.searchTerm,
    sortBy: state.applicationsDataTable.sortBy,
    sortAscending: state.applicationsDataTable.sortAscending,
    reports: state.applications.reports,
    ascendRecruiters: state.ascendRecruiters.ascendRecruiters,
    currentAscendRecruiterId: state.profile.ascendRecruiterId,
    currentYearApplicationCount: state.applications.currentYearApplicationCount,
  };
}

export default connect(mapStateToProps, actions)(ApplicationsListPage);
