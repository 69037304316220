import React from 'react';
import BannerNotification from 'src/components/BannerNotification/BannerNotification';
import Hint from 'src/components/Hint/Hint';
import DocumentTitle from '../components/vendor/DocumentTitle';

class DemoPage extends React.Component {
  state = {
    resources: [],
  };
  render() {
    return (
      <DocumentTitle title="Demo">
        <div>
          <div className="bd-graybackground overflowx">
            <div
              style={{width: '75vw', marginLeft: 'auto', marginRight: 'auto'}}
            >
              <div className="bd-whitebackground padding-16 mb-3">
                <h3>
                  <div className="normal-title">GraphQL example</div>
                </h3>
                <p>
                  When clicked, this button will perform a GraphQL query to
                  www.ascendindiana.com/api and will return the most recent
                  entries
                  <br />
                </p>
                <form>
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <input
                        type="number"
                        id="limitCount"
                        className="form-control"
                        placeholder="Resources To Fetch (5)"
                      />
                    </div>
                    <div className="col-sm-7">&nbsp;</div>
                  </div>
                  <button
                    className={`btn btn-primary`}
                    type="button"
                    onClick={async () => {
                      const headers = {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_RESOURCES_TOKEN}`,
                      };
                      const limit =
                        document.getElementById('limitCount').value || 5;
                      const query = JSON.stringify({
                        query: `{entries(limit: ${limit}) {
                          id
                          dateCreated @formatDateTime(format: "Y-m-d")
                          title
                          url
                          calloutText
                          calloutDescription
                          calloutImage
                          ... on resources_download_Entry {
                            calloutImage {
                              title
                              url
                            }
                          }
                          ... on resources_post_Entry {
                            calloutImage {
                              title
                              url
                            }
                          }
                      }}`,
                      });

                      const response = await fetch(
                        process.env.REACT_APP_RESOURCES_URL,
                        {
                          method: 'POST',
                          headers,
                          body: query,
                        }
                      );
                      const entryResp = await response.json();
                      this.setState({resources: entryResp.data.entries});
                    }}
                  >
                    Fetch Data
                  </button>
                </form>
                {this.state.resources.length > 0 && (
                  <div>
                    <h3>Resources</h3>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '10px',
                      }}
                    >
                      {this.state.resources.map((resource) => (
                        <div class="card" style={{width: '18rem;'}}>
                          <img
                            class="card-img-top"
                            src={`https://ascendindiana.com${resource.calloutImage[0].url}`}
                            alt={resource.calloutText}
                            height="360"
                            width="750"
                          />
                          <div class="card-body">
                            <h5 class="card-title">{resource.title}</h5>
                            <p class="card-text" style={{textWrap: 'wrap'}}>
                              {resource.calloutDescription}
                            </p>
                            <a
                              href={resource.url}
                              class="btn btn-primary"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {resource.calloutText}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="bd-whitebackground padding-16">
                <h3>
                  <div className="normal-title">
                    This is an example of the Banner Notification component
                  </div>
                </h3>
                <div className="active-body">
                  The Banner Notification takes <code>title</code>,{' '}
                  <code>bannerText</code>, <code>isDarkMode</code>, and{' '}
                  <code>actions</code>
                  <br /> <code>isDarkMode</code> is a boolean that toggles dark
                  mode seen below. <br /> <code>actions</code> are a list of
                  possible buttons in the form of
                  <pre>
                    <code>{`
  [
    {
      label: string,
      action: function,
    },
  ]
                `}</code>
                  </pre>{' '}
                  <code>title</code> and <code>bannerText</code> are self
                  explanatory.
                </div>
                <br />
              </div>
              <BannerNotification
                title="Test"
                bannerText="I'm a Banner Notification!!"
                actions={[
                  {
                    label: 'Learn More',
                    action: () => alert('More Info'),
                  },
                  {
                    label: "or Don't",
                    action: () => alert('Fine, be that way'),
                  },
                ]}
              />
              <div className="bd-whitebackground padding-16">
                <h3>
                  <div className="normal-title">
                    This is an example of the Banner Notification component in
                    dark mode
                  </div>
                </h3>
                <div className="active-body">
                  This mode is largely the same as normal, but with a background
                  color and border.
                </div>
                <br />
              </div>
              <BannerNotification
                title="Dark Mode Test"
                bannerText="This is a test for Dark mode of the Banner Notifications!"
                isDarkMode
              />
              <div className="bd-whitebackground padding-16">
                <h3>
                  <div className="normal-title">
                    This is an example of the Banner Notification component but
                    with long text
                  </div>
                </h3>
                <br />
              </div>
              <BannerNotification
                title="Long Text"
                bannerText={
                  <>
                    <div>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      In id ligula malesuada, finibus diam at, finibus velit.
                      Vestibulum non porttitor risus, ac commodo felis. In
                      iaculis, odio ut auctor dictum, augue augue tincidunt
                      lectus, in vestibulum odio arcu at arcu. Nullam tempus
                      justo convallis sem aliquam rhoncus. In vulputate sapien
                      eget venenatis commodo. Integer enim lacus, imperdiet non
                      magna in, pretium ultricies augue. Aliquam gravida velit
                      nec viverra elementum. Duis imperdiet cursus elementum.
                      Duis vel consectetur ligula, vel volutpat mi. Proin cursus
                      fringilla enim mattis tempor.
                    </div>
                  </>
                }
                actions={[
                  {
                    label: ' Single Button',
                    action: () => alert('You clicked a single button'),
                  },
                ]}
              />
              <div style={{position: 'relative'}}>
                <div
                  style={{height: '150px'}}
                  className="bd-whitebackground padding-16"
                >
                  <h3>
                    <div className="normal-title">
                      This is an example of the Hint component
                    </div>
                  </h3>
                  <div className="active-body">
                    The Hint component take in <code>hintText</code> and{' '}
                    <code>offset</code>.
                    <br />
                    <code>hintText</code> is the displayed text.
                    <br />
                    <code>offset</code> is the height of the sibling element
                    that Hint will be attaching to. Both Hint and the sibling
                    need to be in a relative positioned container.
                  </div>
                  <br />
                </div>
                <Hint
                  hintText="Adding more relevant skills to your profile can help improve your candidacy by 25%"
                  offset={150}
                  actions={[
                    {
                      name: 'Logging',
                      action: () => alert('pressed'),
                    },
                    {
                      name: 'Microsoft Excel',
                      action: () => alert('pressed'),
                    },
                  ]}
                />
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <iframe
              src="http://localhost:3000/contact"
              title="contact"
              id="ascendContactForm"
              style={{width: '100%', height: '800px'}}
              frameBorder="0"
            />
            <table className="fixedtable">
              <tbody>
                <tr>
                  <td className="disabled-subheader" width="300px">
                    Normal
                  </td>
                  <td className="disabled-subheader" width="300px">
                    Secondary
                  </td>
                  <td className="disabled-subheader" width="300px">
                    Disabled
                  </td>
                  <td className="disabled-subheader" width="300px">
                    Active
                  </td>
                  <td className="disabled-subheader" width="300px">
                    Accent 1
                  </td>
                  <td className="disabled-subheader" width="300px">
                    Accent 2
                  </td>
                  <td className="disabled-subheader" width="300px">
                    Accent 3
                  </td>
                </tr>
                <tr>
                  <td className="normal-mega">Mega</td>
                  <td className="secondary-mega">Mega</td>
                  <td className="disabled-mega">Mega</td>
                  <td className="active-mega">Mega</td>
                  <td className="accent-1-mega">Mega</td>
                  <td className="accent-2-mega">Mega</td>
                  <td className="accent-3-mega">Mega</td>
                </tr>
                <tr>
                  <td className="normal-mega bold">Mega 2</td>
                  <td className="secondary-mega bold">Mega 2</td>
                  <td className="disabled-mega bold">Mega 2</td>
                  <td className="active-mega bold">Mega 2</td>
                  <td className="accent-1-mega bold">Mega 2</td>
                  <td className="accent-2-mega bold">Mega 2</td>
                  <td className="accent-3-mega bold">Mega 2</td>
                </tr>
                <tr>
                  <td className="normal-jumbo">Jumbo</td>
                  <td className="secondary-jumbo">Jumbo</td>
                  <td className="disabled-jumbo">Jumbo</td>
                  <td className="active-jumbo">Jumbo</td>
                  <td className="accent-1-jumbo">Jumbo</td>
                  <td className="accent-2-jumbo">Jumbo</td>
                  <td className="accent-3-jumbo">Jumbo</td>
                </tr>
                <tr>
                  <td className="normal-jumbo bold">Jumbo 2</td>
                  <td className="secondary-jumbo bold">Jumbo 2</td>
                  <td className="disabled-jumbo bold">Jumbo 2</td>
                  <td className="active-jumbo bold">Jumbo 2</td>
                  <td className="accent-1-jumbo bold">Jumbo 2</td>
                  <td className="accent-2-jumbo bold">Jumbo 2</td>
                  <td className="accent-3-jumbo bold">Jumbo 2</td>
                </tr>
                <tr>
                  <td className="normal-display">Display</td>
                  <td className="secondary-display">Display</td>
                  <td className="disabled-display">Display</td>
                  <td className="active-display">Display</td>
                  <td className="accent-1-display">Display</td>
                  <td className="accent-2-display">Display</td>
                  <td className="accent-3-display">Display</td>
                </tr>
                <tr>
                  <td className="normal-display bold">Display 2</td>
                  <td className="secondary-display bold">Display 2</td>
                  <td className="disabled-display bold">Display 2</td>
                  <td className="active-display bold">Display 2</td>
                  <td className="accent-1-display bold">Display 2</td>
                  <td className="accent-2-display bold">Display 2</td>
                  <td className="accent-3-display bold">Display 2</td>
                </tr>
                <tr>
                  <td className="normal-headline">Headline</td>
                  <td className="secondary-headline">Headline</td>
                  <td className="disabled-headline">Headline</td>
                  <td className="active-headline">Headline</td>
                  <td className="accent-1-headline">Headline</td>
                  <td className="accent-2-headline">Headline</td>
                  <td className="accent-3-headline">Headline</td>
                </tr>
                <tr>
                  <td className="normal-headline bold">Headline 2</td>
                  <td className="secondary-headline bold">Headline 2</td>
                  <td className="disabled-headline bold">Headline 2</td>
                  <td className="active-headline bold">Headline 2</td>
                  <td className="accent-1-headline bold">Headline 2</td>
                  <td className="accent-2-headline bold">Headline 2</td>
                  <td className="accent-3-headline bold">Headline 2</td>
                </tr>
                <tr>
                  <td className="normal-title">Title</td>
                  <td className="secondary-title">Title</td>
                  <td className="disabled-title">Title</td>
                  <td className="active-title">Title</td>
                  <td className="accent-1-title">Title</td>
                  <td className="accent-2-title">Title</td>
                  <td className="accent-3-title">Title</td>
                </tr>
                <tr>
                  <td className="normal-title bold">Title 2</td>
                  <td className="secondary-title bold">Title 2</td>
                  <td className="disabled-title bold">Title 2</td>
                  <td className="active-title bold">Title 2</td>
                  <td className="accent-1-title bold">Title 2</td>
                  <td className="accent-2-title bold">Title 2</td>
                  <td className="accent-3-title bold">Title 2</td>
                </tr>
                <tr>
                  <td className="normal-subheader">Subheader</td>
                  <td className="secondary-subheader">Subheader</td>
                  <td className="disabled-subheader">Subheader</td>
                  <td className="active-subheader">Subheader</td>
                  <td className="accent-1-subheader">Subheader</td>
                  <td className="accent-2-subheader">Subheader</td>
                  <td className="accent-3-subheader">Subheader</td>
                </tr>
                <tr>
                  <td className="normal-subheader bold">Subheader 2</td>
                  <td className="secondary-subheader bold">Subheader 2</td>
                  <td className="disabled-subheader bold">Subheader 2</td>
                  <td className="active-subheader bold">Subheader 2</td>
                  <td className="accent-1-subheader bold">Subheader 2</td>
                  <td className="accent-2-subheader bold">Subheader 2</td>
                  <td className="accent-3-subheader bold">Subheader 2</td>
                </tr>
                <tr>
                  <td className="normal-body">Body</td>
                  <td className="secondary-body">Body</td>
                  <td className="disabled-body">Body</td>
                  <td className="active-body">Body</td>
                  <td className="accent-1-body">Body</td>
                  <td className="accent-2-body">Body</td>
                  <td className="accent-3-body">Body</td>
                </tr>
                <tr>
                  <td className="normal-body bold">Body 2</td>
                  <td className="secondary-body bold">Body 2</td>
                  <td className="disabled-body bold">Body 2</td>
                  <td className="active-body bold">Body 2</td>
                  <td className="accent-1-body bold">Body 2</td>
                  <td className="accent-2-body bold">Body 2</td>
                  <td className="accent-3-body bold">Body 2</td>
                </tr>
                <tr>
                  <td className="normal-caption">Caption</td>
                  <td className="secondary-caption">Caption</td>
                  <td className="disabled-caption">Caption</td>
                  <td className="active-caption">Caption</td>
                  <td className="accent-1-caption">Caption</td>
                  <td className="accent-2-caption">Caption</td>
                  <td className="accent-3-caption">Caption</td>
                </tr>
                <tr>
                  <td className="normal-caption bold">Caption 2</td>
                  <td className="secondary-caption bold">Caption 2</td>
                  <td className="disabled-caption bold">Caption 2</td>
                  <td className="active-caption bold">Caption 2</td>
                  <td className="accent-1-caption bold">Caption 2</td>
                  <td className="accent-2-caption bold">Caption 2</td>
                  <td className="accent-3-caption bold">Caption 2</td>
                </tr>
                <tr>
                  <td className="normal-small">Small</td>
                  <td className="secondary-small">Small</td>
                  <td className="disabled-small">Small</td>
                  <td className="active-small">Small</td>
                  <td className="accent-1-small">Small</td>
                  <td className="accent-2-small">Small</td>
                  <td className="accent-3-small">Small</td>
                </tr>
                <tr>
                  <td className="normal-small bold">Small 2</td>
                  <td className="secondary-small bold">Small 2</td>
                  <td className="disabled-small bold">Small 2</td>
                  <td className="active-small bold">Small 2</td>
                  <td className="accent-1-small bold">Small 2</td>
                  <td className="accent-2-small bold">Small 2</td>
                  <td className="accent-3-small bold">Small 2</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <div className="bd-darkbluebackground overflowx">
            <table className="fixedtable">
              <tbody>
                <tr>
                  <td className="disabled-subheader-white" width="300px">
                    Normal
                  </td>
                  <td className="disabled-subheader-white" width="300px">
                    Secondary
                  </td>
                  <td className="disabled-subheader-white" width="300px">
                    Disabled
                  </td>
                </tr>
                <tr>
                  <td className="normal-mega-white">Mega</td>
                  <td className="secondary-mega-white">Mega</td>
                  <td className="disabled-mega-white">Mega</td>
                </tr>
                <tr>
                  <td className="normal-mega-white bold">Mega 2</td>
                  <td className="secondary-mega-white bold">Mega 2</td>
                  <td className="disabled-mega-white bold">Mega 2</td>
                </tr>
                <tr>
                  <td className="normal-jumbo-white">Jumbo</td>
                  <td className="secondary-jumbo-white">Jumbo</td>
                  <td className="disabled-jumbo-white">Jumbo</td>
                </tr>
                <tr>
                  <td className="normal-jumbo-white bold">Jumbo 2</td>
                  <td className="secondary-jumbo-white bold">Jumbo 2</td>
                  <td className="disabled-jumbo-white bold">Jumbo 2</td>
                </tr>
                <tr>
                  <td className="normal-display-white">Display</td>
                  <td className="secondary-display-white">Display</td>
                  <td className="disabled-display-white">Display</td>
                </tr>
                <tr>
                  <td className="normal-display-white bold">Display 2</td>
                  <td className="secondary-display-white bold">Display 2</td>
                  <td className="disabled-display-white bold">Display 2</td>
                </tr>
                <tr>
                  <td className="normal-headline-white">Headline</td>
                  <td className="secondary-headline-white">Headline</td>
                  <td className="disabled-headline-white">Headline</td>
                </tr>
                <tr>
                  <td className="normal-headline-white bold">Headline 2</td>
                  <td className="secondary-headline-white bold">Headline 2</td>
                  <td className="disabled-headline-white bold">Headline 2</td>
                </tr>
                <tr>
                  <td className="normal-title-white">Title</td>
                  <td className="secondary-title-white">Title</td>
                  <td className="disabled-title-white">Title</td>
                </tr>
                <tr>
                  <td className="normal-title-white bold">Title 2</td>
                  <td className="secondary-title-white bold">Title 2</td>
                  <td className="disabled-title-white bold">Title 2</td>
                </tr>
                <tr>
                  <td className="normal-subheader-white">Subheader</td>
                  <td className="secondary-subheader-white">Subheader</td>
                  <td className="disabled-subheader-white">Subheader</td>
                </tr>
                <tr>
                  <td className="normal-subheader-white bold">Subheader 2</td>
                  <td className="secondary-subheader-white bold">
                    Subheader 2
                  </td>
                  <td className="disabled-subheader-white bold">Subheader 2</td>
                </tr>
                <tr>
                  <td className="normal-body-white">Body</td>
                  <td className="secondary-body-white">Body</td>
                  <td className="disabled-body-white">Body</td>
                </tr>
                <tr>
                  <td className="normal-body-white bold">Body 2</td>
                  <td className="secondary-body-white bold">Body 2</td>
                  <td className="disabled-body-white bold">Body 2</td>
                </tr>
                <tr>
                  <td className="normal-caption-white">Caption</td>
                  <td className="secondary-caption-white">Caption</td>
                  <td className="disabled-caption-white">Caption</td>
                </tr>
                <tr>
                  <td className="normal-caption-white bold">Caption 2</td>
                  <td className="secondary-caption-white bold">Caption 2</td>
                  <td className="disabled-caption-white bold">Caption 2</td>
                </tr>
                <tr>
                  <td className="normal-small-white">Small</td>
                  <td className="secondary-small-white">Small</td>
                  <td className="disabled-small-white">Small</td>
                </tr>
                <tr>
                  <td className="normal-small-white bold">Small 2</td>
                  <td className="secondary-small-white bold">Small 2</td>
                  <td className="disabled-small-white bold">Small 2</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph twistyright" /> &nbsp;Right Arrow
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med twistyright" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph pencil" /> &nbsp;pencil
              </h4>
              <pre>&lt;i className="nc-icon-glyph pencil" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med remove" /> &nbsp;remove
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med remove" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med pencil" /> &nbsp;pencil
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med pencil" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph letter" /> &nbsp;letter
              </h4>
              <pre>&lt;i className="nc-icon-glyph letter" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph twistydown" /> &nbsp;twistydown
              </h4>
              <pre>&lt;i className="nc-icon-glyph twistydown" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med twistydown" /> &nbsp;twistydown
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med twistydown" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph twistyup" /> &nbsp;twistyup
              </h4>
              <pre>&lt;i className="nc-icon-glyph twistyup" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-title exclamation" />{' '}
                &nbsp;exclamation
              </h4>
              <pre>&lt;i className="nc-icon-glyph-title exclamation" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph letter" /> &nbsp;letter
              </h4>
              <pre>&lt;i className="nc-icon-glyph letter" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph globe" /> &nbsp;globe
              </h4>
              <pre>&lt;i className="nc-icon-glyph globe" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph phone" /> &nbsp;phone
              </h4>
              <pre>&lt;i className="nc-icon-glyph phone" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med sortasc" /> &nbsp;sortasc
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med sortasc" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med sortdec" /> &nbsp;sortdec
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med sortdec" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph pin" /> &nbsp;pin
              </h4>
              <pre>&lt;i className="nc-icon-glyph pin" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph linkedin" /> &nbsp;linkedin
              </h4>
              <pre>&lt;i className="nc-icon-glyph linkedin" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph graduation_hat" />{' '}
                &nbsp;graduation_hat
              </h4>
              <pre>&lt;i className="nc-icon-glyph graduation_hat" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph database" /> &nbsp;database
              </h4>
              <pre>&lt;i className="nc-icon-glyph database" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med doctor" /> &nbsp;doctor
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med doctor" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph dentist" /> &nbsp;dentist
              </h4>
              <pre>&lt;i className="nc-icon-glyph dentist" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph glasses" /> &nbsp;glasses
              </h4>
              <pre>&lt;i className="nc-icon-glyph glasses" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph usersearch" /> &nbsp;usersearch
              </h4>
              <pre>&lt;i className="nc-icon-glyph usersearch" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph circleremove" /> &nbsp;circleremove
              </h4>
              <pre>&lt;i className="nc-icon-glyph circleremove" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med squareremove" />{' '}
                &nbsp;squareremove
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med squareremove" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med stock" /> &nbsp;stock
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med stock" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph eye" /> &nbsp;eye
              </h4>
              <pre>&lt;i className="nc-icon-glyph eye" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph buildings" /> &nbsp;buildings
              </h4>
              <pre>&lt;i className="nc-icon-glyph buildings" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med buildings" /> &nbsp;buildings
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med buildings" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med ban" /> &nbsp;ban
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med ban" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph sport_trophy" /> &nbsp;sport_trophy
              </h4>
              <pre>&lt;i className="nc-icon-glyph sport_trophy" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph small_x" /> &nbsp;small_x
              </h4>
              <pre>&lt;i className="nc-icon-glyph small_x" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph interview" /> &nbsp;interview
              </h4>
              <pre>&lt;i className="nc-icon-glyph interview" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph letter" /> &nbsp;letter
              </h4>
              <pre>&lt;i className="nc-icon-glyph letter" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-big ribbon_award" />{' '}
                &nbsp;ribbon_award
              </h4>
              <pre>&lt;i className="nc-icon-glyph-big ribbon_award" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med interview" /> &nbsp;interview
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med interview" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med user_signup" />{' '}
                &nbsp;user_signup
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med user_signup" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-mega two_users" /> &nbsp;two_users
              </h4>
              <pre>&lt;i className="nc-icon-glyph-mega two_users" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-mega calendar" /> &nbsp;calendar
              </h4>
              <pre>&lt;i className="nc-icon-glyph-mega calendar" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-mega letter" /> &nbsp;letter
              </h4>
              <pre>&lt;i className="nc-icon-glyph-mega letter" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph users_multiple" />{' '}
                &nbsp;users_multiple
              </h4>
              <pre>&lt;i className="nc-icon-glyph users_multiple" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med log_in" /> &nbsp;log_in
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med log_in" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med log_out" /> &nbsp;log_out
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med log_out" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med gear" /> &nbsp;gear
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med gear" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph flag" /> &nbsp;flag
              </h4>
              <pre>&lt;i className="nc-icon-glyph flag" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph business_money-bag" />{' '}
                &nbsp;business_money-bag
              </h4>
              <pre>
                &lt;i className="nc-icon-glyph business_money-bag" /&gt;
              </pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med twitter" /> &nbsp;twitter
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med twitter" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med dots" /> &nbsp;dots
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med dots" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med instagram" /> &nbsp;instagram
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med instagram" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med facebook" /> &nbsp;facebook
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med facebook" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med linkedin" /> &nbsp;linkedin
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med linkedin" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med search" /> &nbsp;search
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med search" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med thin_check" /> &nbsp;thin_check
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med thin_check" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med down_arrow" /> &nbsp;down_arrow
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med down_arrow" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med up_arrow" /> &nbsp;up_arrow
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med up_arrow" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph thin_check" /> &nbsp;thin_check
              </h4>
              <pre>&lt;i className="nc-icon-glyph thin_check" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph like" /> &nbsp;like
              </h4>
              <pre>&lt;i className="nc-icon-glyph like" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph dislike" /> &nbsp;dislike
              </h4>
              <pre>&lt;i className="nc-icon-glyph dislike" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-huge chat_bubble" />{' '}
                &nbsp;chat_bubble
              </h4>
              <pre>&lt;i className="nc-icon-glyph-huge chat_bubble" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med cloud_upload" />{' '}
                &nbsp;cloud_upload
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med cloud_upload" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med save" /> &nbsp;save
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med save" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-big paper_award" />{' '}
                &nbsp;paper_award
              </h4>
              <pre>&lt;i className="nc-icon-glyph-big paper_award" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-big big_trophy" /> &nbsp;big_trophy
              </h4>
              <pre>&lt;i className="nc-icon-glyph-big big_trophy" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-big little_trophy" />{' '}
                &nbsp;little_trophy
              </h4>
              <pre>&lt;i className="nc-icon-glyph-big little_trophy" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph pen" /> &nbsp;pen
              </h4>
              <pre>&lt;i className="nc-icon-glyph pen" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med building" /> &nbsp;building
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med building" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med link" /> &nbsp;link
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med link" /&gt;</pre>
            </div>
            <div className="col-md-4">
              <h4>
                &nbsp;&nbsp;&nbsp;
                <i className="nc-icon-glyph-med trash" /> &nbsp;trash
              </h4>
              <pre>&lt;i className="nc-icon-glyph-med trash" /&gt;</pre>
            </div>
          </div>
          ​ ​ ​ ​
        </div>
      </DocumentTitle>
    );
  }
}

export default DemoPage;
