import React from 'react';
import PropTypes from 'prop-types';
import inviteSvg from '../assets/images/invite.svg';

// “Sharing tea with a fascinating stranger is one of life’s true delights.” —Iroh

class Avatar extends React.Component {
  render() {
    const badgeHeight = this.props.height / 3;
    const badgeWidth = badgeHeight;
    const top = this.props.height - badgeHeight;
    const left = this.props.width - badgeWidth;
    const translateY = 0.06 * badgeHeight;

    const badgeStyle = {
      backgroundColor: '#fff',
      borderRadius: '50%',
      position: 'absolute',
      height: `${badgeHeight + 2}px`,
      width: `${badgeWidth + 2}px`,
      top: `${top}px`,
      left: `${left}px`,
      border: '1px solid #3061a9',
    };

    const avatarStyle = {
      ...this.props.style,
      height: this.props.height,
      width: this.props.width,
    };

    return (
      <div style={{position: 'relative', zIndex: 0}}>
        <img
          style={avatarStyle}
          alt={this.props.label}
          className={`avatar-${this.props.shape}`}
          src={this.props.url}
        />
        {this.props.invited && (
          <div style={badgeStyle}>
            <img
              height={`${badgeHeight}px`}
              width={`${badgeWidth}px`}
              style={{
                position: 'absolute',
                transform: `translateY(${translateY}px)`,
              }}
              src={inviteSvg}
              alt="invite icon"
            />
          </div>
        )}
      </div>
    );
  }
}

Avatar.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shape: PropTypes.string,
  url: PropTypes.string.isRequired,
  style: PropTypes.object,
  invited: PropTypes.bool,
  handpicked: PropTypes.bool,
  label: PropTypes.string,
};

Avatar.defaultProps = {
  height: 32,
  width: 32,
  shape: 'circle',
  style: {},
  invited: false,
  handpicked: false,
  label: '',
};

export default Avatar;
