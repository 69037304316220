import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import * as jobsActions from 'src/actions/jobs';
import * as tableActions from 'src/actions/allJobsDataTable';

import {PageHeader, PageFooter} from 'src/layout';
import {EditJobForm, CloneJobForm} from 'src/forms/JobForm';
import JobProfile from 'src/components/JobProfile';
import AscendDataTable from 'src/components/AscendDataTable';
import ReportsTable from 'src/components/ReportsTable';
import {formatDate} from 'src/utils/miscHelper';
import * as AuthorizeActions from '../../../actions/AuthorizationActions';
import {toCurrency} from '../../../utils/wageHelper';
import {Button} from 'reactstrap';
import {withRouter} from '../../../utils/commonHooks';
import {DeleteRoleConfirm} from '../../../components/DeleteRoleConfirm';

const searchFields = ['name', 'company', 'description'];
const searchPlaceholder = 'Search by title, company, or description';

class AllJobListPage extends React.Component {
  state = {
    editModalOpen: false,
    profileOpen: false,
    currentItemsPerPage: 25,
    areYouSureModalIsOpen: false,
    publishFilterDropdownLabel: 'All Roles',
    includeUnpublished: true,
  };

  getAllJobs = (
    currentPage,
    itemsPerPage,
    searchTerm,
    sortBy,
    sortAscending
  ) => {
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    this.props.getAllJobsAdvanced(
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      this.state.includeUnpublished
    );
  };

  componentDidMount() {
    if (this.props.allJobsLoading) {
      return;
    }
    this.getAllJobs(
      this.props.currentPage,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending
    );
    this.props.getJobReports();
  }

  searchForId = (jobId) => {
    let job = this.props.allJobs.find((x) => x.id === jobId);
    return {
      ...job,
      minimumYearlySalary: toCurrency(job.minimumYearlySalary),
      maximumYearlySalary: toCurrency(job.maximumYearlySalary),
      minimumHourlyWage: toCurrency(job.minimumHourlyWage),
      maximumHourlyWage: toCurrency(job.maximumHourlyWage),
    };
  };

  handleModalClose = () => {
    this.setState({
      formOpen: false,
      profileOpen: false,
    });
  };

  handleProfileClicked = (jobId) => {
    this.props.history.push('/role/' + jobId);
  };

  handlePageChange = (pageNumber) => {
    this.props.updateCurrentPage(pageNumber);
    this.getAllJobs(
      pageNumber,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    this.props.updateSearchTerm(searchTerm);
    this.props.updateSortTerm(
      searchTerm === '' ? '' : this.props.sortBy,
      this.props.sortAscending
    );

    if (search) {
      this.getAllJobs(
        0,
        this.props.itemsPerPage,
        searchTerm,
        searchTerm && this.props.sortBy,
        searchTerm && this.props.sortAscending
      );
    }
  };

  handleFilterPublishedDropdownChange = (term) => {
    this.setState(
      {
        publishFilterDropdownLabel: term.label,
        includeUnpublished: term.label === 'All Roles',
      },
      () => {
        this.getAllJobs(
          0,
          this.props.itemsPerPage,
          this.props.searchTerm,
          this.props.sortBy,
          this.props.sortAscending
        );
      }
    );
  };

  handleSortChange = (sortBy, sortAscending) => {
    this.props.updateSortTerm(sortBy, sortAscending);
    this.getAllJobs(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      sortBy,
      sortAscending
    );
  };

  handleTableAction = async (action, jobId) => {
    switch (action) {
      case 'Clone':
        this.handleCloneClicked(jobId);
        break;
      case 'Edit':
        await this.handleEditClicked(jobId);
        break;
      case 'Profile':
        this.handleProfileClicked(jobId);
        break;
      case 'View':
        this.handleViewClicked(jobId);
        break;
      case 'Delete':
        this.handleDeleteClicked(jobId);
        break;
      default:
        await this.handleEditClicked(jobId);
        break;
    }
  };

  handleEditClicked = async (jobId) => {
    this.props.getJobForEdit(jobId);
    await this.props.canEditJob(jobId);

    this.setState({
      formOpen: true,
      formMode: 'edit',
      jobIdToEdit: jobId,
    });
  };

  handleCloneClicked = (jobId) => {
    this.props.getJobForClone(jobId);
    this.setState({
      formOpen: true,
      formMode: 'clone',
      jobIdToClone: jobId,
    });
  };

  handleViewClicked = (jobId) => {
    let job = this.searchForId(jobId);
    this.setState({
      profileOpen: true,
      profileJob: job,
    });
  };

  handleDeleteClicked = (jobId) => {
    this.setState({
      jobIdToDelete: jobId,
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = (jobId) => {
    this.props.deleteJob(jobId);
    this.setState({
      jobIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClose = () => {
    this.setState({
      jobIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleJobEditSubmit = (job) => {
    this.props.updateJob(job);
    this.props.updateJobForEdit(job);
    this.setState({formOpen: false});
  };

  handleJobCloneSubmit = (job) => {
    this.props.createJob({...job});
    this.setState({formOpen: false});
  };

  buildName = (job) => {
    return (
      <Button
        type="button"
        className="buttonAsLinkRoles"
        onClick={() => this.handleViewClicked(job.id)}
      >
        {job.name}
      </Button>
    );
  };

  buildCompany = (job) => {
    return (
      <Link className="buttonAsLinkRoles" to={`/employer/${job.employerId}`}>
        {job.employer.name}
      </Link>
    );
  };

  buildTeam = (job) => {
    return (
      <Link className="buttonAsLinkRoles" to={`/team/${job.teamId}`}>
        {job.team.name}
      </Link>
    );
  };

  buildPublished = (job) => {
    if (job.published === true) return 'yes';
    if (job.published === false) return 'no';
    return 'n/a';
  };

  buildDatePublished = (job) => {
    return job.publishedAt ? formatDate(job.publishedAt) : 'n/a';
  };
  searchJobById = (jobId) => {
    return this.props.allJobs.find((job) => job.id === jobId);
  };

  render() {
    const {reports} = this.props;
    const tableColumns = [
      {
        sortBy: 'name',
        displayName: 'NAME',
        content: this.buildName,
      },
      {
        displayName: 'COMPANY',
        content: this.buildCompany,
        sortBy: 'employername',
      },
      {
        displayName: 'TEAM',
        content: this.buildTeam,
        sortBy: 'teamname',
      },
      {
        displayName: 'APPLICATIONS',
        content: (item) => (
          <div style={{textAlign: 'center'}}>{item.applicationsCount}</div>
        ),
        sortBy: 'applicationsCount',
      },
      {
        displayName: 'PUBLISHED',
        content: (item) => (
          <div style={{textAlign: 'center'}}>{this.buildPublished(item)}</div>
        ),
        sortBy: 'published',
      },
      {
        displayName: 'DATE PUBLISHED',
        content: this.buildDatePublished,
        sortBy: 'publishedDate',
      },
    ];

    const rowMenuItemList = [
      {displayName: 'Clone', isEdit: true},
      {displayName: 'Edit', isEdit: true},
      {displayName: 'Profile', isEdit: false},
      {displayName: 'View', isEdit: false},
      {displayName: 'Delete', isEdit: true},
    ];

    const filterList = [
      {id: 1, label: 'All Roles'},
      {id: 2, label: 'Published Only'},
    ];

    return (
      <DocumentTitle title="Roles Admin">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                {this.state.profileJob && this.state.profileJob.id && (
                  <JobProfile
                    key={this.state.profileJob.id}
                    isOpen={this.state.profileOpen}
                    job={this.state.profileJob}
                    onClose={this.handleModalClose}
                  />
                )}

                {this.state.formMode === 'edit' && (
                  <EditJobForm
                    id={this.state.jobIdToEdit}
                    onSubmit={this.handleJobEditSubmit}
                    onDelete={() =>
                      this.handleDeleteConfirmed(this.state.jobIdToEdit)
                    }
                    isOpen={this.state.formOpen}
                    onClose={this.handleModalClose}
                  />
                )}

                {this.state.formMode === 'clone' && (
                  <CloneJobForm
                    id={this.state.jobIdToClone}
                    onSubmit={this.handleJobCloneSubmit}
                    isOpen={this.state.formOpen}
                    onClose={this.handleModalClose}
                  />
                )}
                <DeleteRoleConfirm
                  jobName={this.searchJobById(this.state.jobIdToDelete)?.name}
                  onClose={() => this.handleAreYouSureClose()}
                  onClick={() =>
                    this.handleDeleteConfirmed(this.state.jobIdToDelete)
                  }
                  confirmDeleteIsOpen={this.state.areYouSureModalIsOpen}
                />
                <br />

                <div>
                  <h2 className="normal-headline bold float-left">All Roles</h2>
                  <br />
                  <br />
                  <AscendDataTable
                    rowMenuItemList={rowMenuItemList}
                    showSearch={true}
                    tableColumns={tableColumns}
                    items={this.props.allJobs}
                    handlerFunction={this.handleTableAction}
                    currentPage={this.props.currentPage}
                    itemsPerPage={this.props.itemsPerPage}
                    sortBy={this.props.sortBy}
                    sortAscending={this.props.sortAscending}
                    totalItems={this.props.totalItems}
                    handlePageChange={this.handlePageChange}
                    handleSortChange={this.handleSortChange}
                    searchPlaceholder={searchPlaceholder}
                    searchTerm={this.props.searchTerm}
                    handleFilterChange={this.handleFilterChange}
                    itemsLoading={this.props.allJobsLoading}
                    pullDownOnChange={this.handleFilterPublishedDropdownChange}
                    pullDownList={filterList}
                    pullDownDefaultValue={filterList[0]}
                    pullDownWidth={230}
                  />
                </div>
                <ReportsTable title="Role Reports" reports={reports} />
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

AllJobListPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  allJobs: PropTypes.array.isRequired,
  getAllJobsAdvanced: PropTypes.func.isRequired,
  getJobForEdit: PropTypes.func.isRequired,
  updateJobForEdit: PropTypes.func.isRequired,
  getJobForClone: PropTypes.func.isRequired,
  getJobReports: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  allJobsLoading: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired,
  canEditJob: PropTypes.func.isRequired,
  allowJobEdit: PropTypes.bool,
};

const actions = {
  ...jobsActions,
  ...tableActions,
  ...AuthorizeActions,
};

function mapStateToProps(state, ownProps) {
  return {
    allJobs: state.jobs.allJobs,
    allJobsLoading: state.jobs.allJobsLoading,
    itemsPerPage: state.jobs.allJobsItemsPerPage,
    totalItems: state.jobs.allJobsTotalItems,
    currentPage: state.allJobsDataTable.currentPage,
    searchTerm: state.allJobsDataTable.searchTerm,
    sortBy: state.allJobsDataTable.sortBy,
    sortAscending: state.allJobsDataTable.sortAscending,
    reports: state.jobs.reports,
    allowJobEdit: state.authorization.job.canEdit,
  };
}

export default withRouter(connect(mapStateToProps, actions)(AllJobListPage));
