import _ from 'lodash';

import {defaultJobTraits} from './traitsHelper';

export const jobIsWatched = (jobId, seeker) => {
  if (!seeker.watchedJobs || !jobId) return false;
  const watchedJobIds = seeker.watchedJobs.map((j) => j.id);
  return _.includes(watchedJobIds, jobId);
};

export const jobIsApplied = (jobId, seeker) => {
  if (!seeker.appliedJobs) return false;
  const appliedJobIds = seeker.appliedJobs.map((j) => j.id);
  return _.includes(appliedJobIds, jobId);
};

export const blankJob = (teamId, employerId) => {
  return {
    name: '',
    employerId: employerId,
    teamId: teamId,
    employer: {
      name: '',
    },
    team: {
      name: '',
      location: '',
    },
    payType: 'Salary',
    hourlyRange: '',
    salaryRange: '',
    req: '',
    info1: '',
    info2: '',
    skillTags: [],
    requiredSkillTags: [],
    workStyleTags: [],
    skillsInfo: '',
    stylesInfo: '',
    showcaseName: '',
    showcaseTitle: '',
    showcaseInfo: '',
    showcaseAvatarUrl: '',
    url: '',
    characterTraits: defaultJobTraits(),
    applicationDeadline: null,
    ascendInsights: '',
  };
};
