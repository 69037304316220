import React from 'react';
import PropTypes from 'prop-types';
import {AsyncPaginate} from 'react-select-async-paginate';
import {makeRequest} from 'src/services/api';

export default function CreateConnectionSelectSeeker(props) {
  const {onChange, seeker} = props;

  const seekerOnChange = (e) => {
    const selectedSeeker = {
      id: e.value,
      name: e.label,
      segments: e.segments,
    };
    onChange(selectedSeeker);
  };

  const asyncLoadSeekerOptions = async (search, prevOptions) => {
    const itemsPerPage = 50;
    const page = Math.floor(prevOptions.length / itemsPerPage);
    let url = `seekers/select-list?itemsPerPage=${itemsPerPage}&page=${page}&sort=+name`;

    if (search) {
      url += `&searchTerm=${search}&searchFields=firstName,lastName,email`;
    }

    const seekerResponse = await makeRequest({method: 'GET', path: url});

    const hasMore =
      seekerResponse.data.totalItems > prevOptions.length + itemsPerPage;

    let options = seekerResponse.data.seekers.map((result) => {
      return {
        label: `${result.name}`,
        value: result.public_id,
        segments: result.segments,
      };
    });

    return {
      options: options,
      hasMore,
    };
  };

  return (
    <label className="normal-body pb-4">
      Choose a job seeker from the list below to which you'd like to track a
      connection:
      <AsyncPaginate
        defaultOptions
        debounceTimeout={200}
        value={seeker ? {label: seeker.name, value: seeker.id} : null}
        loadOptions={asyncLoadSeekerOptions}
        onChange={(e) => seekerOnChange(e)}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: '#f1f4f9',
            color: 'yellow',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? 'var(--secondary-3)'
              : state.isSelected
              ? 'var(--secondary-2)'
              : 'white',
            color: state.isFocused
              ? 'secondary-subheader'
              : state.isSelected
              ? 'white'
              : 'secondary-subheader',
          }),
        }}
      />
    </label>
  );
}

CreateConnectionSelectSeeker.propTypes = {
  seeker: PropTypes.object,
  onChange: PropTypes.func,
};
