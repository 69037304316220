import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import Avatar from '../../components/Avatar';
import MaximizedSeekerItem from './MaximizedSeekerItem';

class MatchListPanel extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.maximized !== this.props.maximized &&
      this.props.matchedSeekers.length > 0
    ) {
      this.focusFirstMatchedSeeker();
    }
  }
  focusFirstMatchedSeeker() {
    const firstMatchedSeeker = document.getElementById(
      `seekerAvatarLink-${this.props.matchedSeekers[0].id}`
    );
    if (firstMatchedSeeker) {
      firstMatchedSeeker.focus();
    }
  }
  render() {
    const avatars = (seekers) => {
      return seekers.map((seeker) => {
        return (
          <div className="inlineblock padding-h4 clickable" key={seeker.id}>
            <Link to={`/candidate/${seeker.id}`}>
              <Avatar
                label={seeker.name}
                url={seeker.avatarUrl}
                invited={!!_.find(this.props.allInvitations, {id: seeker.id})}
                handpicked={!!_.find(this.props.allHandpicked, {id: seeker.id})}
              />
            </Link>
          </div>
        );
      });
    };

    if (this.props.maximized) {
      return (
        <div className="row">
          {this.props.matchedSeekers.length > 0 && (
            <>
              <div style={{width: '100%'}}>
                <div className="normal-body bold padding-8">Matches</div>
                <div style={{width: '100%'}} className="padding-bottom-eight">
                  <table
                    style={{width: '100%'}}
                    className="padding-8"
                    role="presentation"
                  >
                    <tbody>
                      {this.props.matchedSeekers.map((seeker) => {
                        return (
                          <MaximizedSeekerItem
                            allHandpicked={this.props.allHandpicked}
                            allInvitations={this.props.allInvitations}
                            key={seeker.id}
                            seeker={seeker}
                            jobTraits={this.props.jobTraits}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{width: '100%'}}>
                <button
                  className="inlineblock clickable active-caption buttonAsLinkTeamCard"
                  style={{paddingLeft: '8px', textDecoration: 'underline'}}
                  onClick={this.props.onViewMoreClicked}
                >
                  View more
                </button>
              </div>
            </>
          )}
        </div>
      );
    } else {
      const allSeekers = _.uniqBy(
        [...this.props.handpickedSeekers, ...this.props.matchedSeekers],
        'id'
      );
      return (
        <div className="row">
          <div className="col-md-8">{avatars(allSeekers)}</div>
        </div>
      );
    }
  }
}

MatchListPanel.propTypes = {
  handpickedSeekers: PropTypes.arrayOf(PropTypes.object).isRequired,
  matchedSeekers: PropTypes.arrayOf(PropTypes.object).isRequired,
  jobTraits: PropTypes.object,
  maximized: PropTypes.bool.isRequired,
  onViewMoreClicked: PropTypes.func.isRequired,
  allInvitations: PropTypes.arrayOf(PropTypes.object).isRequired,
  allHandpicked: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MatchListPanel;
