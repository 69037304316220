import * as types from '../actions/ActionTypes';

const initialState = {
  zipcodes: [],
  zipcode: {},
  zipcodesLoading: false,
};

export default function zipcodes(state = initialState, action) {
  switch (action.type) {
    case types.ZIPCODES__GET_REQUEST:
      return {
        ...state,
        zipcode: {},
      };
    case types.ZIPCODES__GET_ALL_REQUEST:
      return {
        ...state,
        zipcodes: [],
        zipcodesLoading: true,
      };
    case types.ZIPCODES__GET_ALL_SUCCESS:
      return {
        ...state,
        zipcodes: action.response.body.zipcodes,
        zipcodesLoading: false,
      };
    case types.ZIPCODES__GET_SUCCESS:
      return {
        ...state,
        zipcode: action.response.body,
      };

    default:
      return state;
  }
}
