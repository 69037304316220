import React from 'react';
import styles from '../onboarding.module.scss';
import OffRampFooter from './OffRampFooter';

export default function WorkStatusOffRamp() {
  return (
    <>
      <div className={styles.pageContent} style={{paddingTop: '10px'}}>
        <h2>Alternate Resources</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p style={{textAlign: 'center', width: '80%'}}>
            Thank you for connecting with Ascend. We are so grateful for your
            time and interest.
          </p>
          <br />
          <p style={{textAlign: 'center', width: '80%'}}>
            Unfortunately, our current employer partners do not have
            opportunities for individuals who are not authorized to work in the
            United States or will now or in the future require sponsorship for
            employment visa status.
          </p>
          <p style={{textAlign: 'center', width: '80%'}}>
            For assistance with your job search, we recommend visiting your
            local WorkOne Center or career services office if you are a student.
          </p>
          <OffRampFooter />
        </div>
      </div>
    </>
  );
}

WorkStatusOffRamp.propTypes = {};
