import React, {useCallback, useEffect, useRef, useState} from 'react';
import navLeft from '../assets/images/nav-left.svg';
import navRight from '../assets/images/nav-right.svg';
import {useDispatch} from 'react-redux';
import styles from './JobCardCarousel.module.scss';
import {useIsMobile} from 'src/utils/commonHooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {createJobWatch, deleteJobWatch} from '../actions/jobs';
import RecommendedRoleCard from './RecommendedRoleCard';
import {Link} from 'react-router-dom';

/**
 * A Carousel component that allows the display of a list of Job Cards from a
 * list of jobs.
 *
 * For these jobs to be displayed, they must have the PUBLISHED flag set to true.
 * WATCHED property for each must be included as well.
 *
 * If ENDCARD is provided, it will be displayed at the end of the carousel. This can be a component
 * such as RecommendedEndCard or html code.
 *
 * Each JOB should have the following properties:
 * - id: The id of the job
 * - employerName: The name of the employer
 * - employerId: The id of the employer
 * - invitedOn: The date the job was invited on
 * - matchData: The match data for the job
 * - name: The name of the job
 * - published: A boolean flag to indicate if the job is published
 * - watched: A boolean flag to indicate if the job is watched
 *
 * @param {Object} props - (see below for the props this component expects)
 *
 * @param {Array} props.jobs - an array of jobs to be displayed
 * @param {Number} props.maxHorizontalCardDisplay - the maximum number of cards to be displayed horizontally
 * @param {String} props.title - the title of the carousel
 * @param {Boolean} props.isSeeker - a boolean flag to indicate if the user is a seeker
 * @param {Function} props.onJobClick - a function to be called when a job card is clicked
 * @param {Boolean} props.isApprenticeProgram - a boolean flag to indicate if the job is an apprentice program
 * @param {String} props.viewerType - a string to indicate the type of user viewing the carousel
 * @param {Boolean} props.showMenu - a boolean flag to indicate if the 3-dot menu should be shown
 * @param {Object} props.endCard - the end card to be displayed
 * @returns {React.Component} - returns the JobCardCarousel component
 * @component
 * @example
 * <JobCardCarousel
 *     jobs={matchJobs}
 *     handleJobClick={this.handleJobClicked}
 *     isApprenticeProgram={includesApprenticeProgram(this.props.seeker)}
 *     onJobClick={this.handleJobClicked}
 *     isMaSegment={this.state.isMaSegment}
 *     isSeeker={role === 'JOB_SEEKER'}
 *     title={'Role Recommendations'}
 *     titleLink={`/find_roles`}
 *     onEmployerClick={this.handleEmployerClicked}
 *     onTeamClick={this.handleTeamClicked}
 *     viewerType={role === 'JOB_SEEKER' ? 'CANDIDATE' : 'RECRUITER'}
 *     endCard={this.getEndCard(matches.length, seeker)}
 *  />
 */
export default function JobCardCarousel({
  jobs,
  endCard,
  title,
  titleLink,
  titleLinkClassName,
  isSeeker,
  onJobClick,
  isApprenticeProgram,
  showMenu = false,
  viewerType = 'CANDIDATE',
  seekerId,
  icon,
}) {
  const getUniqueName = (leadingText) => {
    return leadingText + Math.random().toString(36).slice(2, 11);
  };

  const carouselRef = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useIsMobile(991);
  const isSmallScreen = useIsMobile(930);
  const [carouselName] = useState(getUniqueName('carousel_'));
  const [currentPage, setCurrentPage] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Adjust ITEMS_PER_PAGE based on screen width
  const calculateItemsPerPage = () => {
    if (screenWidth <= 1420) {
      return isSmallScreen && jobList.length > 1 ? 1 : 2; // Adjusted for screen width 1420px
    } else {
      return isSmallScreen && jobList.length > 2 ? 2 : 3;
    }
  };

  const ITEMS_PER_PAGE = calculateItemsPerPage();
  const totalPages = Math.ceil(jobList.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getPagedJobs = useCallback(() => {
    if (isMobile) {
      return {pagedJobList: jobList, currentlyDisplayedCount: jobList.length}; // Return all jobs and count if mobile
    }
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const jobsToReturn = jobList.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    return {
      pagedJobList: jobsToReturn,
      currentlyDisplayedCount: jobsToReturn.length,
    };
  }, [isMobile, jobList, currentPage, ITEMS_PER_PAGE]);

  const isLandscape = () =>
    window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(
    isLandscape() ? 'landscape' : 'portrait'
  );

  const handleClick = useCallback(
    (direction) => {
      let newPage = currentPage;

      if (direction === 'home') {
        newPage = 1;
      } else if (direction === 'previous') {
        newPage = currentPage === 1 ? totalPages : currentPage - 1;
      } else if (direction === 'next') {
        newPage = currentPage === totalPages ? 1 : currentPage + 1;
      }

      setCurrentPage(newPage);
    },
    [currentPage, totalPages]
  );

  useEffect(() => {
    setJobList((prevJobList) => jobs.filter((job) => job.published === true));
  }, [jobs]);

  useEffect(() => {
    if (orientation !== (isLandscape() ? 'landscape' : 'portrait')) {
      handleClick('');
      setOrientation(isLandscape() ? 'landscape' : 'portrait');
    }
  }, [handleClick, orientation]);

  const handleWatchChanged = (job, watched) => {
    if (watched) {
      dispatch(createJobWatch(job));
    } else {
      dispatch(deleteJobWatch(job.id));
    }

    if (jobList.length < 6) {
      handleClick('home'); // We are going to 4 saved jobs so Reset to Home
    }
  };
  const renderJobCard = (job) => {
    const matchData = job.matchData || {
      matchLevels: [0, 0],
      ascendFit: false,
      overallFit: false,
      overallScore: 0,
      skillsScore: 0,
      stylesScore: 0,
      traitsScore: 0,
    };

    const invitedDate = 'invitedOn' in job ? new Date(job.invitedOn) : null;

    return (
      <RecommendedRoleCard
        employer={job.employerName || ''}
        employerId={job.employerId}
        invitedDate={invitedDate}
        isApprenticeProgram={isApprenticeProgram}
        job={job}
        matchData={matchData}
        name={job.name}
        onJobClick={() => onJobClick(job.id)}
        onWatchChanged={(watched) => handleWatchChanged(job, watched)}
        seekerId={seekerId}
        showWatchIcon={isSeeker}
        viewerType={viewerType}
        watched={job.watched === undefined ? true : job.watched}
        showMenu={showMenu}
      />
    );
  };

  let carouselSizeClass;

  if (isMobile && jobList.length > 3) {
    carouselSizeClass = classNames([styles.carouselMobile, styles.fixedHeight]);
  } else if (isMobile) {
    carouselSizeClass = styles.carouselMobile;
  } else if (isSmallScreen) {
    carouselSizeClass = classNames([styles.carouselSmall, styles.navButtons]);
  } else {
    carouselSizeClass = styles.carouselLarge;
  }
  const {pagedJobList, currentlyDisplayedCount} = getPagedJobs();

  return (
    jobList &&
    jobList.length > 0 && (
      <>
        <div className="flex-row align-items-center d-flex flex-nowrap pb-2">
          {!isMobile && (
            <div
              className="col-auto flex-shrink-0"
              style={{minWidth: '60px'}} //Fix the first column width for alignment
            ></div>
          )}

          <Link
            to={titleLink}
            className={`${titleLinkClassName} underline font-20 bold`}
          >
            {icon !== undefined && <>{icon}</>}
            {title}
          </Link>
          <span className="normal-caption bold pt-1 nowrap">
            &nbsp;&nbsp;{jobList.length}
          </span>
        </div>

        <div
          className={classNames([styles.carousel, carouselSizeClass])}
          id={carouselName}
          ref={carouselRef}
        >
          {!isMobile && jobList.length > 3 && (
            <div className="p-0" style={{maxWidth: '50px'}}>
              <button
                tabIndex={0}
                className="buttonAsLink pt-2"
                onClick={() => handleClick('previous')}
              >
                <img alt="left" width="50px" height="50px" src={navLeft} />
              </button>
            </div>
          )}

          {!isMobile && jobList.length <= 3 && (
            <div className="p-0" style={{width: '50px'}}></div>
          )}

          {pagedJobList &&
            pagedJobList.map((job) => {
              return (
                <div
                  key={job.id}
                  style={
                    !isMobile && !isSmallScreen
                      ? {
                          paddingLeft: '15px',
                          minWidth: '311px',
                          maxHeight: '126px',
                        }
                      : {}
                  }
                >
                  {job.published && renderJobCard(job)}
                </div>
              );
            })}
          {!isMobile &&
            endCard &&
            currentPage === totalPages &&
            currentlyDisplayedCount < 3 && (
              <div style={{paddingLeft: '15px'}} key={'endCard'} id={'endCard'}>
                {endCard}
              </div>
            )}

          {isMobile && endCard && (
            <div key={'endCard'} id={'endCard'}>
              {endCard}
            </div>
          )}

          {!isMobile && jobList.length <= 3 && (
            <div
              className="p-0"
              style={{width: '50px', gridColumnStart: 6}}
            ></div>
          )}

          {!isMobile && jobList.length > 3 && (
            <div className="p-0" style={{gridColumnStart: 6}}>
              <button
                tabIndex={0}
                className="buttonAsLink pt-2"
                onClick={() => handleClick('next')}
              >
                <img alt="right" width="50px" height="50px" src={navRight} />
              </button>
            </div>
          )}
        </div>
      </>
    )
  );
}

JobCardCarousel.propTypes = {
  jobs: PropTypes.array,
  maxHorizontalCardDisplay: PropTypes.number,
  title: PropTypes.string,
  titleLink: PropTypes.string,
  titleLinkClassName: PropTypes.string,
  isSeeker: PropTypes.bool,
  onJobClick: PropTypes.func,
  isApprenticeProgram: PropTypes.bool,
  viewerType: PropTypes.string,
  showMenu: PropTypes.bool,
  endCard: PropTypes.any,
  seekerId: PropTypes.string,
  icon: PropTypes.any,
};
