import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';
import regionalMapSvg from '../../../assets/images/RegionalMap.svg';

import {regionalCityList} from '../../../utils/onBoarding/regionalCityList';
import * as regionsActions from 'src/actions/regions';
import {AscendCheckGroupField} from '../../../formFields/AscendCheckGroupField/AscendCheckGroupField';
import {required} from '../../../utils/intake/intakeCommon';
import {camelCase} from 'src/utils/miscHelper';

class LocationPref extends React.Component {
  formRef = React.createRef();

  componentDidMount() {
    this.props.getRegions();
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = ['regionIds'];
    _.forEach(requiredFields, (field) => {
      if (!values[field] || values[field].length === 0) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  render() {
    const {next, previous, headerImage} = this.props;
    const readFormValues = () => {
      return this.formRef?.current?.getState().values;
    };

    const {regions} = this.props;

    const regionalCities = regionalCityList();
    let regionIndex = 0;

    const wildcard = _.find(regions, ['name', 'Any']);
    const sortedRegions = _.reject(regions, ['name', 'Any']).map((t, index) => {
      regionIndex = ++regionIndex;
      const val = {
        label: `${t.order} - ${t.name} region`,
        id: `${camelCase(t.name)}Region`,
        value: `${t.id}`,
        tips: `Major cities: ${regionalCities[regionIndex]}`,
      };

      return val;
    });
    if (wildcard) {
      sortedRegions.unshift({
        label: 'No Preference',
        value: wildcard.id.toString(),
      });
    }
    return (
      <>
        <PageHeader
          title="Where would you like to work?"
          subTitle="If you’re looking for remote work, select “No Preference.” You’ll be able to filter by remote-only jobs."
          headerImage={headerImage}
        />
        <div className={styles.pageContent}>
          <Form
            onSubmit={next}
            validate={this.validate}
            mutators={{
              // expect (field, value) args from the mutator
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={{
              regionIds: this.props.intakeCache?.regionIds,
            }}
          >
            {({handleSubmit, form}) => {
              this.formRef.current = form;
              let deskLayout = this.props.isMobile ? '' : 'd-flex flex-row';
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className={deskLayout}>
                      <img
                        width="300px"
                        alt="Indiana map with different regions highlighted"
                        src={regionalMapSvg}
                      />

                      <div className="mt-3 ">
                        <Field name="regionIds" validate={required}>
                          {(props) => (
                            <>
                              <AscendCheckGroupField
                                options={sortedRegions}
                                name={props.input.name}
                                inputValue={props.input.value}
                                onChange={props.input.onChange}
                                meta={props.meta}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>

        <PageFooter
          next={() => this.formRef?.current?.submit()}
          previous={previous}
          getValues={readFormValues}
        />
      </>
    );
  }
}
LocationPref.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any,
  regions: PropTypes.array,
  regionsLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  getRegions: PropTypes.func,
  headerImage: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
  };
}
const actions = {
  ...regionsActions,
};

export default connect(mapStateToProps, actions)(LocationPref);
