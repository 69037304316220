import React from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {withRouter} from '../../../utils/commonHooks';
import * as recruitersActions from '../../../actions/recruiters';
import * as employersActions from '../../../actions/employers';
import * as teamsActions from '../../../actions/teams';
import * as tableActions from '../../../actions/companyRecruitersDataTable';
import {updateChangeEmailUser} from '../../../actions/seekers';

import EditRecruiterForm from './EditRecruiterForm';
import {PageHeader, PageFooter} from '../../../layout';
import AscendDataTable from '../../../components/AscendDataTable';
import AreYouSureModal from '../../../components/AreYouSureModal';
import tagStyles from '../../../components/tags/TagButton/TagButton.module.scss';
import {actions as authActions} from 'src/reducers/authentication';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';

const blankRecruiter = (employerId) => {
  return {
    email: '',
    firstName: '',
    lastName: '',
    teams: [],
    employerId: parseInt(employerId, 10),
    securityRoleNames: [],
  };
};

const searchFields = ['lastName', 'firstName', 'email'];
const searchPlaceholder = 'Name';

class RecruiterListPage extends React.Component {
  constructor(props) {
    super(props);
    const employerId = this.props.match.params.id;
    this.state = {
      editModalOpen: false,
      formType: 'CREATE',
      recruiter: blankRecruiter(employerId),
      areYouSureModalIsOpen: false,
      removeRecruiterId: '',
      changeEmailModalIsOpen: false,
      canChangeOtherEmail: false,
    };
  }

  getRecruiters = (
    currentPage,
    itemsPerPage,
    searchTerm,
    sortBy,
    sortAscending
  ) => {
    const employerId = this.props.match.params.id;
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    this.props.getRecruitersByCompanyAdvanced(
      employerId,
      currentPage,
      itemsPerPage,
      sortTerm,
      filters
    );
  };

  getTableState = () => {
    const employerId = this.props.match.params.id;
    if (employerId in this.props.dataTable) {
      return this.props.dataTable[employerId];
    } else {
      return {
        currentPage: 0,
        searchTerm: '',
        sortBy: '',
        sortAscending: true,
      };
    }
  };

  componentDidMount() {
    const employerId = this.props.match.params.id;
    this.props.getEmployer(employerId);
    this.props.getTeamsByEmployer(employerId, true);

    if (!(employerId in this.props.dataTable)) {
      this.props.createRecruitersDataTableEmployer(employerId);
    }

    const tableState = this.getTableState();
    this.getRecruiters(
      tableState.currentPage,
      this.props.itemsPerPage,
      tableState.searchTerm,
      tableState.sortBy,
      tableState.sortAscending
    );

    has_permission(PERMISSIONS.ALLOWED_TO_CHANGE_OTHER_EMAIL).then((resp) => {
      this.setState({canChangeOtherEmail: resp.data.hasPermission});
    });
  }

  handleCreateUser = () => {
    this.setState({
      editModalOpen: true,
      formType: 'CREATE',
    });
  };

  handleModalClose = () => {
    this.setState({
      editModalOpen: false,
    });
  };

  searchForId = (recruiterId) => {
    for (var i = 0; i < this.props.recruiters.length; i++) {
      if (this.props.recruiters[i].id === recruiterId) {
        return this.props.recruiters[i];
      }
    }
  };

  handlePageChange = (pageNumber) => {
    const employerId = this.props.match.params.id;
    const tableState = this.getTableState();

    this.props.updateCurrentPage(employerId, pageNumber);
    this.getRecruiters(
      pageNumber,
      this.props.itemsPerPage,
      tableState.searchTerm,
      tableState.sortBy,
      tableState.sortAscending
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    const employerId = this.props.match.params.id;
    const tableState = this.getTableState();

    this.props.updateSearchTerm(employerId, searchTerm);
    if (search) {
      this.getRecruiters(
        0,
        this.props.itemsPerPage,
        searchTerm,
        tableState.sortBy,
        tableState.sortAscending
      );
    }
  };

  handleSortChange = (sortBy, sortAscending) => {
    const employerId = this.props.match.params.id;
    const tableState = this.getTableState();

    this.props.updateSortTerm(employerId, sortBy, sortAscending);
    this.getRecruiters(
      0,
      this.props.itemsPerPage,
      tableState.searchTerm,
      sortBy,
      sortAscending
    );
  };

  handlerFunction = (operation, recruiterId) => {
    switch (operation) {
      case 'Edit':
        this.handleEditClick(recruiterId);
        break;
      case 'Change Email':
        this.handleChangeEmailClick(recruiterId);
        break;
      case 'Delete':
        this.handleDeleteClick(recruiterId);
        break;
      case 'Manage Notifications':
        this.handleManageNotificationsClick(recruiterId);
        break;
      default:
        this.handleEditClick(recruiterId);
    }
  };

  handleManageNotificationsClick = (recruiterId) => {
    this.props.resetRecruiterNotificationOptionsById();

    const employerId = this.props.match.params.id;
    this.props.history.push(
      `/admin/employers/${employerId}/recruiters/${recruiterId}/notifications`,
      {}
    );
  };

  handleEditClick = (recruiterId) => {
    const recruiter = this.searchForId(recruiterId);
    this.setState({
      editModalOpen: true,
      formType: 'EDIT',
      recruiter: recruiter,
    });
  };

  handleDeleteClick = (recruiterId) => {
    this.setState(
      {
        removeRecruiterId: recruiterId,
      },
      () => this.setState({areYouSureModalIsOpen: true})
    );
  };

  handleChangeEmailClick = (recruiterId) => {
    const recruiter = this.searchForId(recruiterId);

    this.props.updateChangeEmailUser(recruiter);

    this.setState({changeEmailModalIsOpen: true});
  };

  handleDoRemove = () => {
    this.props.deleteRecruiter(this.state.removeRecruiterId, () => {
      const deletedUserId =
        this.props.recruiters.find(
          (user) => user.id === this.state.removeRecruiterId
        )?.userId || null;

      if (this.props.currentUserId === deletedUserId) {
        localStorage.clear();
        this.props.logoutUser();
      }

      this.setState(
        {
          removeRecruiterId: '',
        },
        () => this.setState({areYouSureModalIsOpen: false})
      );
    });
  };

  handleAreYouSureClose = () => {
    this.setState(
      {
        removeRecruiterId: '',
      },
      () => this.setState({areYouSureModalIsOpen: false})
    );
  };

  handleUserSubmit = (user) => {
    const employerId = this.props.match.params.id;
    const newUser = {
      ...user,
      employerId: parseInt(employerId, 10),
      activationUrl: `${window.location.protocol}//${window.location.host}/activate`,
    };

    this.setState({
      editModalOpen: false,
      recruiter: blankRecruiter(employerId),
    });

    this.props.createRecruiter(newUser);
  };

  handleUserSaved = (recruiter) => {
    const employerId = this.props.match.params.id;
    this.setState({
      editModalOpen: false,
      recruiter: blankRecruiter(employerId),
    });
    this.props.updateRecruiter({
      id: recruiter.id,
      firstName: recruiter.firstName,
      lastName: recruiter.lastName,
      email: recruiter.email,
      teams: recruiter.teams,
      securityRoleNames: recruiter.securityRoleNames,
    });
  };

  renderAdminTag = () => {
    return (
      <div
        className={`${tagStyles.containerBlue} ${tagStyles.containerNarrow} ${tagStyles.inlineMargin} discoveryTag col-md-auto`}
        tabIndex={0}
        data-tooltip-id="tooltip"
        data-tooltip-content={
          'Admins can add, edit, and delete users for this account.'
        }
      >
        <span aria-label="tag" className={`${tagStyles.tagMatchBlue}`}>
          Admin
        </span>
      </div>
    );
  };

  buildName = (recruiter) => {
    return (
      <div className="row padding-10">
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          delayHide={200}
          clickable={true}
          closeOnEsc={true}
        />

        <div className="columnpad secondary-subheader col-md-auto">
          {recruiter.name}
        </div>

        {recruiter.securityRoleNames.includes('COMPANY_RECRUITER_ADMIN') &&
          this.renderAdminTag()}
      </div>
    );
  };

  buildEmail = (recruiter) => {
    return recruiter.email;
  };

  rowMenuItemList = () => {
    let menuOptions = [];

    if (
      this.props.role === 'ASCEND_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN'
    ) {
      menuOptions.push({
        displayName: 'Edit',
      });
    }

    if (this.state.canChangeOtherEmail) {
      menuOptions.push({
        displayName: 'Change Email',
      });
    }

    if (
      this.props.role === 'ASCEND_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
      this.props.role === 'SEGMENT_ADMIN' ||
      this.props.role === 'EMPLOYER_MANAGER'
    ) {
      menuOptions.push({
        displayName: 'Manage Notifications',
      });
    }

    if (
      this.props.role === 'ASCEND_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN'
    ) {
      menuOptions.push({
        displayName: 'Delete',
      });
    }

    return menuOptions;
  };

  render() {
    const tableState = this.getTableState();

    const tableColumns = [
      {
        displayName: 'NAME',
        content: this.buildName,
        sortBy: 'lastName',
      },
      {
        displayName: 'EMAIL',
        content: this.buildEmail,
      },
    ];

    const renderEmployerName = (employer) => {
      if (employer) {
        return employer.name;
      } else return '';
    };

    const renderAddUserButton = () => {
      return (
        <div
          className="float-right"
          data-tooltip-id="tooltip"
          data-tooltip-html={
            this.props.role !== 'ASCEND_RECRUITER' &&
            this.props.role !== 'COMPANY_RECRUITER_ADMIN'
              ? 'You do not have permission to make changes to your team. Email <a href="mailto:support@ascendindiana.com" class="underline white">support@ascendindiana.com</a> if this is incorrect.'
              : ''
          }
        >
          <button
            className="clickable btn btn-primary"
            onClick={this.handleCreateUser}
            disabled={
              this.props.role !== 'ASCEND_RECRUITER' &&
              this.props.role !== 'COMPANY_RECRUITER_ADMIN'
            }
          >
            + Add User
          </button>
        </div>
      );
    };

    const formTitle =
      this.state.formType === 'CREATE' ? 'Create User' : 'Edit User';
    const formSubmit =
      this.state.formType === 'CREATE'
        ? this.handleUserSubmit
        : this.handleUserSaved;

    const getRecruiterInfo = () => {
      const employerId = this.props.match.params.id;

      if (this.state.formType === 'EDIT') {
        return this.state.recruiter;
      }
      return blankRecruiter(employerId);
    };

    return (
      <DocumentTitle title="Employer Users Admin">
        <div className="wholepage">
          <ReactTooltip
            id="tooltip"
            className="solid-tooltip-dark"
            delayHide={200}
            clickable={true}
            closeOnEsc={true}
          />
          <PageHeader
            title="Administration"
            showAdminLinks={this.props.role === 'ASCEND_RECRUITER'}
          />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />

                <EditRecruiterForm
                  title={formTitle}
                  submitButtonText="Save"
                  isOpen={this.state.editModalOpen}
                  recruiter={getRecruiterInfo()}
                  teams={this.props.teams}
                  onSubmit={formSubmit}
                  onClose={this.handleModalClose}
                />

                <AreYouSureModal
                  areYouSureText="Are you sure that you want to remove this recruiter?"
                  isOpen={this.state.areYouSureModalIsOpen}
                  onClose={this.handleAreYouSureClose}
                  onYes={this.handleDoRemove}
                />

                <ChangeEmailModal
                  isOpen={this.state.changeEmailModalIsOpen}
                  onClose={() => {
                    this.setState({changeEmailModalIsOpen: false});
                  }}
                  onCancel={() => {
                    this.setState({changeEmailModalIsOpen: false});
                  }}
                  isThirdPartyEmailChange={true}
                />

                <div>
                  <h2 className="normal-headline bold float-left">
                    {renderEmployerName(this.props.employer)} Users
                  </h2>
                  {renderAddUserButton()}

                  <br />
                  <br />
                  <AscendDataTable
                    rowMenuItemList={this.rowMenuItemList()}
                    tableColumns={tableColumns}
                    items={this.props.recruiters}
                    handlerFunction={this.handlerFunction}
                    currentPage={tableState.currentPage}
                    itemsPerPage={this.props.itemsPerPage}
                    searchTerm={tableState.searchTerm}
                    sortBy={tableState.sortBy}
                    sortAscending={tableState.sortAscending}
                    totalItems={this.props.totalItems}
                    handlePageChange={this.handlePageChange}
                    handleFilterChange={this.handleFilterChange}
                    handleSortChange={this.handleSortChange}
                    searchPlaceholder={searchPlaceholder}
                    itemsLoading={this.props.recruitersLoading}
                  />
                </div>

                {this.props.role !== 'ASCEND_RECRUITER' &&
                  this.props.role !== 'COMPANY_RECRUITER_ADMIN' && (
                    <div className="row">
                      <div className="col-md-12 padding-bottom-sixteen">
                        <div className="cardBase">
                          <div className="fullwidth">
                            Do you believe you should have permission to add,
                            edit, or remove team members or need help making
                            changes?
                            <br />
                            Email a member of our team or reach us at{' '}
                            <a href="mailto:support@ascendindiana.com">
                              support@ascendindiana.com
                            </a>{' '}
                            for assistance.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </main>
          </div>

          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

RecruiterListPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  recruiters: PropTypes.array.isRequired,
  employer: PropTypes.object,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  createRecruiter: PropTypes.func.isRequired,
  deleteRecruiter: PropTypes.func.isRequired,
  getRecruitersByCompanyAdvanced: PropTypes.func.isRequired,
  getEmployer: PropTypes.func.isRequired,
  getTeamsByEmployer: PropTypes.func.isRequired,
  updateRecruiter: PropTypes.func.isRequired,
  recruitersLoading: PropTypes.bool.isRequired,
  dataTable: PropTypes.object.isRequired,
  createRecruitersDataTableEmployer: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  role: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  resetRecruiterNotificationOptionsById: PropTypes.func.isRequired,
  updateChangeEmailUser: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    recruiters: state.recruiters.recruiters,
    employer: state.employers.employer,
    teams: state.teams.teams,
    recruitersLoading: state.recruiters.recruitersLoading,
    itemsPerPage: state.recruiters.itemsPerPage,
    totalItems: state.recruiters.totalItems,
    dataTable: state.companyRecruitersDataTable.byEmployer,
    role: state.profile.role,
    currentUserId: state.profile.id,
  };
}

const actions = {
  ...recruitersActions,
  ...employersActions,
  ...teamsActions,
  ...tableActions,
  ...authActions,
  updateChangeEmailUser,
};

export default withRouter(connect(mapStateToProps, actions)(RecruiterListPage));
