import React from 'react';
import PropTypes from 'prop-types';

import {Form, Field} from 'react-final-form';
import {TextAreaField} from 'src/formFields';
import {OnChange} from 'react-final-form-listeners';

export default function ReportConnectionFeedback(props) {
  const {onChange, onSubmit} = props;

  return (
    <Form onSubmit={onSubmit}>
      {() => {
        return (
          <form>
            <OnChange name="feedback">
              {(newValue) => {
                onChange(newValue);
              }}
            </OnChange>

            <label className="normal-body">
              Do you have any additional feedback you’d like to share with the
              team?
              <div className="row ms-2 mt-2">
                <Field
                  id="feedback"
                  name="feedback"
                  component={TextAreaField}
                  size={32}
                  maxLength={200}
                  aria-label="Feedback"
                  showCounter={true}
                  hideBorder={false}
                  label=""
                />
              </div>
            </label>
          </form>
        );
      }}
    </Form>
  );
}

ReportConnectionFeedback.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};
