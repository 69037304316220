import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from '../../utils/commonHooks';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import _ from 'lodash';

import * as seekersActions from '../../actions/seekers';
import * as employersActions from '../../actions/employers';
import * as tableActions from '../../actions/employersDataTable';

import AscendDataTable from '../../components/AscendDataTable';
import {PageHeader, PageFooter} from '../../layout';
import WatchIcon from '../../components/WatchIcon';
import NoEmployers from '../../components/NoEmployers';
import PreviousButton from 'src/components/PreviousButton';

const searchFields = ['name'];
const searchPlaceholder = 'Name';

class EmployerDiscoveryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      watchedEmployerIds: [],
      searchTerm: '',
      employers: [],
    };
  }

  getEmployers = (
    currentPage,
    itemsPerPage,
    searchTerm,
    sortBy,
    sortAscending
  ) => {
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    this.props.getEmployersAdvanced(
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      false
    );
  };

  getSeekerId = () => {
    if (this.props.loggedInSeekerId) {
      return this.props.loggedInSeekerId;
    } else if (this.props.params.id) {
      return this.props.params.id;
    } else {
      return null;
    }
  };

  static getDerivedStateFromProps(nextProps) {
    // Seeker has just loaded...

    if (nextProps.seeker.watchedEmployers) {
      return {
        watchedEmployerIds: nextProps.seeker.watchedEmployers.map((e) => e.id),
      };
    }
    return null;
  }

  componentDidMount() {
    const seekerId = this.getSeekerId();
    this.getEmployers(0, 25, '', '', true);
    this.props.getEmployersStats();
    this.props.getSeeker(seekerId);
  }

  searchForId = (employerId) => {
    for (var i = 0; i < this.props.employers.length; i++) {
      if (this.props.employers[i].id === employerId) {
        return this.props.employers[i];
      }
    }
  };

  handlePageChange = (pageNumber) => {
    this.props.updateCurrentPage(pageNumber);
    this.getEmployers(
      pageNumber,
      this.props.itemsPerPage,
      this.props.searchTerm,
      this.props.sortBy,
      this.props.sortAscending
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    this.props.updateSearchTerm(searchTerm);
    if (search) {
      this.getEmployers(
        0,
        this.props.itemsPerPage,
        searchTerm,
        this.props.sortBy,
        this.props.sortAscending
      );
    }
  };

  handleSortChange = (sortBy, sortAscending) => {
    this.props.updateSortTerm(sortBy, sortAscending);
    this.getEmployers(
      0,
      this.props.itemsPerPage,
      this.props.searchTerm,
      sortBy,
      sortAscending
    );
  };

  handlerFunction = (operation, employerId) => {
    switch (operation) {
      case 'View':
        this.handleViewClick(employerId);
        break;
      default:
        this.handleViewClick(employerId);
        break;
    }
  };

  handleModalClose = () => {
    this.setState({
      formIsOpen: false,
    });
  };

  handleViewClick = (employerId) => {
    this.props.history.push('/employer/' + employerId);
  };

  handleEmployerWatchClicked = (employerId) => {
    let newWatchedEmployerIds = [];
    if (_.includes(this.state.watchedEmployerIds, employerId)) {
      this.props.deleteEmployerWatch(employerId);
      newWatchedEmployerIds = _.reject(this.state.watchedEmployerIds, (x) => {
        return x === employerId;
      });
    } else {
      this.props.createEmployerWatch(employerId);
      newWatchedEmployerIds = [...this.state.watchedEmployerIds, employerId];
    }
    this.setState({watchedEmployerIds: newWatchedEmployerIds});
  };

  buildTeams = (employer) => {
    return (
      <div>
        <div className="inlineblock">{employer.publishedTeamCount}</div>
      </div>
    );
  };

  buildRoles = (employer) => {
    return (
      <div>
        <div className="inlineblock">{employer.publishedJobCount}</div>
      </div>
    );
  };

  render() {
    const tableColumns = [
      {
        sortBy: 'name',
        displayName: 'NAME',
        content: (employer) => {
          return (
            <Link
              className="accessibleLink"
              style={{textDecoration: 'none'}}
              to={'/employer/' + employer.id}
            >
              {employer.name}
              <div className="disabled-caption">{employer.tagline}</div>
            </Link>
          );
        },
      },
      {
        displayName: 'TEAMS',
        content: this.buildTeams,
        sortBy: 'teams',
      },
      {
        displayName: 'ROLES',
        content: this.buildRoles,
        sortBy: 'roles',
      },
      {
        displayName: 'FAVORITE',
        content: (employer) => {
          return (
            <WatchIcon
              watched={_.includes(this.state.watchedEmployerIds, employer.id)}
              onClick={() => this.handleEmployerWatchClicked(employer.id)}
              className=""
            />
          );
        },
      },
    ];

    return (
      <DocumentTitle title="Employer Discovery">
        <div className="wholepage">
          <PageHeader title="Employer Discovery" />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <PreviousButton />
              <div className="container">
                <br />
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      {this.props.seeker.id &&
                        this.props.seeker.published === false &&
                        !this.props.profile.isApprenticeProgram && (
                          <div className="padding-bottom-twentyfour">
                            <Link to={`/profile`}>
                              <NoEmployers />
                            </Link>
                          </div>
                        )}
                      {this.props.seeker.id &&
                        (this.props.seeker.published === true ||
                          this.props.profile.isApprenticeProgram) && (
                          <div>
                            <AscendDataTable
                              tableColumns={tableColumns}
                              items={this.props.employers}
                              handlerFunction={this.handlerFunction}
                              currentPage={this.props.currentPage}
                              itemsPerPage={this.props.itemsPerPage}
                              searchTerm={this.props.searchTerm}
                              sortBy={this.props.sortBy}
                              sortAscending={this.props.sortAscending}
                              totalItems={this.props.totalItems}
                              handlePageChange={this.handlePageChange}
                              handleFilterChange={this.handleFilterChange}
                              handleSortChange={this.handleSortChange}
                              searchPlaceholder={searchPlaceholder}
                              itemsLoading={this.props.employersLoading}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

EmployerDiscoveryPage.propTypes = {
  history: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  seeker: PropTypes.object,
  employers: PropTypes.array.isRequired,
  getEmployersAdvanced: PropTypes.func.isRequired,
  getEmployersStats: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  employersLoading: PropTypes.bool.isRequired,
  loggedInSeekerId: PropTypes.string,
  getSeeker: PropTypes.func.isRequired,
  createEmployerWatch: PropTypes.func.isRequired,
  deleteEmployerWatch: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    loggedInSeekerId: state.profile.seekerId,
    employers: state.employers.employers,
    stats: state.employers.stats,
    seeker: state.seekers.seeker,
    itemsPerPage: state.employers.itemsPerPage,
    totalItems: state.employers.totalItems,
    employersLoading: state.employers.employersLoading,
    currentPage: state.employersDataTable.currentPage,
    searchTerm: state.employersDataTable.searchTerm,
    sortBy: state.employersDataTable.sortBy,
    sortAscending: state.employersDataTable.sortAscending,
  };
};

const mapDispatchToProps = {
  ...seekersActions,
  ...employersActions,
  ...tableActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerDiscoveryPage)
);
