import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from '../components/DropDownMenu';
import WatchIcon from './WatchIcon';
import styles from './AdvancedJobCard.module.scss';
import {PERMISSIONS} from 'src/services/authorizationApi';

import {
  CompanySvg,
  LocationSvg,
  SalarySvg,
  NotInterestedSvg,
} from 'src/assets/svg/svgComponents';
import {
  addressToString,
  formatDate,
  getGoogleMapUrl,
  truncate,
} from '../utils/miscHelper';
import InvitedIcon from './InvitedIcon';
import {Link} from 'react-router-dom';
import tagStyles from 'src/components/tags/TagButton/TagButton.module.scss';
import {InView} from 'react-intersection-observer';
import classNames from 'classnames';
import {useHasPermission, useIsMobile} from 'src/utils/commonHooks';
import {getWageRangeText} from 'src/utils/wageHelper';
import JobActionsMenu from './JobActionsMenu';
import LastUpdated from './LastUpdated';
import {optOutOfJob, undoOptOutOfJob} from 'src/actions/seekers';
import {useDispatch} from 'react-redux';
import {track} from 'src/utils/analytics';
import MatchCircle from './MatchCircle';

export default function AdvancedJobCard({
  job,
  watched,
  onWatchChanged,
  matchCardStyle,
  viewerType,
  onJobClick,
  isApprenticeProgram,
  seekerApprenticePathways,
  handleVisibilityChange,
  seekerId,
  showHideThisRoleButton,
  handleBatchSelectChanged,
  isSelected,
  showCheckbox = false,
}) {
  const dispatch = useDispatch();
  const jobActionsRef = useRef(null);

  const isMobile = useIsMobile(760);
  const [menuVisible] = useHasPermission(PERMISSIONS.ROLE_CARD_MENU);
  const [menuChanged, setMenuChanged] = useState(false);
  const [jobActionMenu, setJobActionMenu] = useState([]);
  const [dismissed, setDismissed] = useState(false);

  // This callback toggles the menuChanged state to force a re-render for publish and unpublish toggle.
  const handleMenuChange = useCallback(() => {
    setMenuChanged(!menuChanged);
  }, [menuChanged]);

  useEffect(() => {
    // Get the new menu and load it into state.
    // This is so the text on the pop-up menu will toggle between Publish and UnPublish
    setJobActionMenu(jobActionsRef.current?.popUpMenu);
  }, [menuChanged]);

  const handleJobClick = () => {
    onJobClick();
  };

  const handleWatchClicked = () => {
    onWatchChanged(!watched);
  };

  const handleHideClicked = () => {
    setDismissed(true);
    track({
      eventName: `RoleHidden`,
      jobId: job.id,
      jobName: job.name,
      jobEmploymentType: job.employmentTypes.map((x) => x.name).join(','),
      employerId: job.employerId,
      employerName: job.employer.name,
      pageName: 'Explore Roles',
    });
    dispatch(optOutOfJob(job.id, seekerId));
  };

  const handleUndoClicked = () => {
    setDismissed(false);
    dispatch(undoOptOutOfJob(job.id, seekerId));
  };

  const renderInvited = () => {
    if (job.invitedDate) {
      let invitePhrase = 'Invited by ' + job.invitedByName;

      return (
        <div className={`${styles.greyText}`}>
          {(job.invitedByRole === 'COMPANY_RECRUITER' ||
            job.invitedByRole === 'COMPANY_RECRUITER_ADMIN') && <InvitedIcon />}

          <div
            className={`${styles.greyText} inlineblock disabled-small`}
            tabIndex={0}
          >
            {`${invitePhrase} on ${formatDate(new Date(job.invitedDate))}`}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderMatchWidget = () => {
    if (
      job.matchData &&
      (job.matchData.matchLevels || job.matchData.roleRecommendationPercentage)
    ) {
      if (
        viewerType === 'RECRUITER' ||
        (viewerType === 'CANDIDATE' && !isApprenticeProgram)
      ) {
        return (
          <div
            className={styles.matchContainer}
            style={{justifyContent: 'center'}}
          >
            <MatchCircle
              viewerType={viewerType}
              matchData={job.matchData}
              matchCardStyle={matchCardStyle}
              isApprenticeProgram={isApprenticeProgram}
              isCompact={isMobile}
              showLabel
            />
          </div>
        );
      }
    } else {
      return <div />;
    }
  };

  const renderJobEditMenu = () => {
    if (menuVisible) {
      return (
        <>
          <div style={{marginTop: '-15px', color: 'var(--neutral-gray-1)'}}>
            <DropDownMenu menuItems={jobActionMenu} />
          </div>
        </>
      );
    }

    return <div />;
  };

  const renderApprenticePathwayMatchTag = () => {
    if (seekerApprenticePathways.length > 0 && job.apprenticePathwayId) {
      if (
        seekerApprenticePathways.findIndex(
          (pathway) => pathway.value === job.apprenticePathwayId
        ) !== -1
      ) {
        return (
          <div className={`${tagStyles.containerGreen} discoveryTag`}>
            <span aria-label="tag" className={`${tagStyles.tagMatchGreen}`}>
              Matches My Interests
            </span>
          </div>
        );
      }
    }

    return <div />;
  };

  const showPay = (job) => {
    if (
      job.payType?.toLowerCase() === 'hourly' &&
      job.minimumHourlyWage &&
      job.minimumHourlyWage !== '0.00' &&
      job.minimumHourlyWage !== null
    ) {
      return true;
    }

    if (
      job.payType?.toLowerCase() === 'salary' &&
      job.minimumYearlySalary &&
      job.minimumYearlySalary !== '0.00' &&
      job.minimumYearlySalary !== null
    ) {
      return true;
    }

    if (!job.isPaidRole) {
      return true;
    }

    return false;
  };

  const renderLocation = (job) => {
    let isRemote = false;
    if (job.remoteStatus === 'Remote') {
      isRemote = true;
    }
    const showAddress = isRemote || job.addressLine1;
    if (!showAddress) {
      return '';
    }
    return (
      <div className={`${styles.greyText}`}>
        <LocationSvg /> &nbsp;{isRemote && 'Remote'}
        {job.addressLine1 && !isRemote && (
          <a
            className="underline"
            href={getGoogleMapUrl(job)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {truncate(addressToString(job), isMobile ? 40 : 64)}
          </a>
        )}
      </div>
    );
  };

  if (!dismissed) {
    return (
      <InView
        threshold={1}
        triggerOnce={true}
        onChange={(inView, entry) => {
          if (handleVisibilityChange) {
            handleVisibilityChange({visible: inView, jobId: job.id});
          }
        }}
      >
        <JobActionsMenu
          job={job}
          ref={jobActionsRef}
          onMenuChange={handleMenuChange}
        />
        <div className={'bd-whitebackground pt-1 ps-1 pe-1 pb-1 jobCard'}>
          {showCheckbox && (
            <div className="custom-control custom-checkbox form-check">
              <div style={{top: '4px'}} className="batchActionCheckBox">
                <input
                  id={`batchSelect_${job.id}`}
                  name={`batchSelect_${job.id}`}
                  type="checkbox"
                  className="custom-control-input form-check-input checkbox-formatting"
                  checked={isSelected}
                  onChange={(e) =>
                    handleBatchSelectChanged(e.target.checked, job)
                  }
                  aria-label={`Select ${job.name} for batch actions`}
                />
                <label
                  className="custom-control-label form-check-label"
                  htmlFor={`batchSelect_${job.id}`}
                ></label>
              </div>
            </div>
          )}
          <div
            className={`${styles.containerColumn} ${isMobile ? '' : 'orderSecond'}`}
          >
            <div
              className={`${styles.containerRowHeader}`}
              style={{width: isMobile ? '90%' : ''}}
            >
              <button
                tabIndex={0}
                className={classNames([
                  styles.leftRowHeader,
                  'accessibleLink',
                  'buttonAsLink',
                  'bold',
                  'mt-1',
                ])}
                style={{
                  color: '#373839',
                  textDecoration: 'none',
                  fontSize: '20px',
                  textAlign: 'left',
                  width: '100%',
                }}
                onClick={handleJobClick}
              >
                <h3 className="mb-0 margin-top-four"> {job.name}</h3>
              </button>

              <div
                className={classNames([styles.actions, styles.rightRowHeader])}
                style={{
                  flexDirection: isMobile ? 'column' : '',
                  position: isMobile ? 'absolute' : '',
                  left: isMobile ? '85%' : '',
                  width: isMobile ? 'fit-content' : '',
                }}
              >
                {renderJobEditMenu()}

                {viewerType === 'CANDIDATE' && showHideThisRoleButton && (
                  <button
                    className="btn"
                    title="Hide this role"
                    onClick={() => {
                      handleHideClicked();
                    }}
                  >
                    <NotInterestedSvg height={24} width={24} />
                  </button>
                )}

                {viewerType === 'CANDIDATE' && (
                  <WatchIcon
                    watched={watched}
                    onClick={handleWatchClicked}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        handleWatchClicked();
                      }
                      return;
                    }}
                    floatRight={true}
                    className={styles.watchIcon}
                  />
                )}
              </div>
            </div>

            <div className="containerRow">
              <div
                className="info"
                style={{fontSize: isMobile ? '12px' : 'initial'}}
              >
                <span>
                  <CompanySvg /> &nbsp;
                  <Link
                    style={{fontFamily: 'Manrope'}}
                    className={`accessibleLink buttonAsLink ${styles.greyText}`}
                    to={`/employer/${job.employerId}`}
                  >
                    {job.employer.name}
                  </Link>
                </span>

                {renderLocation(job)}
                {showPay(job) && (
                  <div className={`${styles.greyText}`}>
                    <SalarySvg />
                    &nbsp;&nbsp;
                    {getWageRangeText(job, false)}
                  </div>
                )}
                <LastUpdated
                  textClassName={`${styles.greyText}`}
                  publishedDate={job.publishedAt}
                  enableToolTip={true}
                />
                <>{renderInvited()}</>
              </div>
            </div>
            <div className={`${tagStyles.tagContainerRight}`}>
              {renderApprenticePathwayMatchTag()}
            </div>
          </div>
          {renderMatchWidget()}
        </div>
      </InView>
    );
  } else {
    return (
      <div
        className={`bd-whitebackground pt-1 ps-1 pe-1 pb-1 jobCard ${styles.banner}`}
      >
        <span className={`${styles.content}`}>
          <div className={`${styles.header}`}>This role has been hidden.</div>
          <p className={`${styles.text}`}>
            We will not show you this role again.
          </p>
        </span>
        <span className={`${styles.actions}`}>
          <button
            className="btn buttonAsLink bold"
            style={{marginRight: '16px'}}
            onClick={(e) => handleUndoClicked()}
          >
            Undo
          </button>
          <Link
            className="btn buttonAsLink bold"
            style={{marginRight: '16px'}}
            target="_blank"
            to={'/profile'}
          >
            Update job preferences
          </Link>
        </span>
      </div>
    );
  }
}

AdvancedJobCard.propTypes = {
  job: PropTypes.object.isRequired,
  watched: PropTypes.bool,
  onWatchChanged: PropTypes.func,
  matchCardStyle: PropTypes.object,
  viewerType: PropTypes.string,
  onJobClick: PropTypes.func.isRequired,
  isApprenticeProgram: PropTypes.bool,
  seekerApprenticePathways: PropTypes.arrayOf(PropTypes.object),
  handleVisibilityChange: PropTypes.func,
  seekerId: PropTypes.string,
  showHideThisRoleButton: PropTypes.bool,
  handleBatchSelectChanged: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

AdvancedJobCard.defaultProps = {
  matchCardStyle: {},
  viewerType: 'CANDIDATE',
  isApprenticeProgram: false,
  seekerApprenticePathways: [],
  showHideThisRoleButton: false,
};
