import {Tooltip as ReactTooltip} from 'react-tooltip';
import PropTypes from 'prop-types';
import React, {createRef} from 'react';
import {makeRequest} from '../../services/api';
import validator from 'validator';
import * as types from '../../actions/ActionTypes';
import {marionZips} from './intakeLists';
import {seekerFields} from './intakeLists';
import _ from 'lodash';
import infoBlueCircle from 'src/assets/images/info-blue-circle.svg';
import {Field} from 'react-final-form';
import styles from './intake.module.scss';

const hasNumbers = (t) => {
  let regex = /\d/g;
  return regex.test(t);
};

export const renderRadioField = (props) => {
  const {
    input,
    meta: {touched, error, warning},
    label,
    options,
    disabletips,
    dataplace,
    datatip,
    showTipAsText,
    showInfoIcon,
    wideFormat,
    restrictTooltip,
  } = props;
  let inputRef = [];

  return (
    <div className="row">
      <div className={`${wideFormat ? 'col-md-7' : 'col-md-6'} `}>
        <div className="secondary-subheader padding-bottom-thirtytwo">
          {label}
          <ReactTooltip
            id={datatip}
            className="solid-tooltip-dark"
            delayHide={1000}
            clickable={true}
            closeOnEsc={true}
          />
          {showInfoIcon && (
            <img
              tabIndex={0}
              className="clickable"
              style={{width: '5%'}}
              alt={''}
              src={infoBlueCircle}
              data-tooltip-id={datatip}
              data-tooltip-place={dataplace}
              data-tooltip-html={datatip}
              data-class={
                restrictTooltip
                  ? 'solid-tooltip-dark restrictedTooltip'
                  : 'solid-tooltip-dark'
              }
            />
          )}
          <p className="disabled-body">{showTipAsText ? datatip : null}</p>
        </div>
      </div>
      <div className={`${wideFormat ? 'col-md-5' : 'col-md-6'} `}>
        {options.map((opt, index) => {
          inputRef[index] = createRef();

          return (
            <div
              key={index}
              style={{display: 'inline-block', paddingRight: '8px'}}
              onKeyUp={(e) => {
                if (e.keyCode === 32) {
                  inputRef[index].current.click();
                }
              }}
            >
              <label
                tabIndex={0}
                className="radio normal-body"
                data-tooltip-id="tooltip"
                data-tooltip-hidden={disabletips}
                data-tooltip-place={dataplace}
                data-tooltip-html={datatip}
              >
                {opt.label}
                <input
                  ref={inputRef[index]}
                  id={`${index} ${label}`}
                  tabIndex={-1}
                  value={opt.value}
                  type="radio"
                  checked={input.value === opt.value}
                  onChange={input.onChange}
                  aria-label={
                    input.value === opt.value
                      ? `option field for ${label} is currently selected`
                      : `option field for ${label} is NOT currently selected`
                  }
                />
                <span className="radio-checkmark" />
              </label>
            </div>
          );
        })}
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    </div>
  );
};
renderRadioField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  disabletips: PropTypes.bool,
  classNameTip: PropTypes.string,
  datamultiline: PropTypes.bool,
  dataeffect: PropTypes.string,
  datatype: PropTypes.string,
  dataplace: PropTypes.string,
  datatip: PropTypes.string,
  showTipAsText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  wideFormat: PropTypes.bool,
};
renderRadioField.defaultProps = {
  showInfoIcon: false,
  wideFormat: false,
};

export const renderFinalRadioField = (
  name,
  label,
  options,
  disabletips,
  datamultiline,
  dataeffect,
  dataplace,
  datatip,
  showTipAsText,
  showInfoIcon,
  wideFormat
) => {
  let inputRef = [];

  return (
    <div className="row">
      <div className={`${wideFormat ? 'col-md-7' : 'col-md-6'} `}>
        <div className="secondary-subheader padding-bottom-thirtytwo">
          {label}
          <ReactTooltip
            id={datatip}
            className="solid-tooltip-dark"
            delayHide={1000}
            clickable={true}
            closeOnEsc={true}
          />
          {showInfoIcon && (
            <img
              tabIndex={0}
              className="clickable"
              style={{width: '5%'}}
              alt={''}
              src={infoBlueCircle}
              data-tooltip-id="tooltip"
              data-tooltip-place={dataplace}
              data-tooltip-html={datatip}
            />
          )}
          <p className="disabled-body">{showTipAsText ? datatip : null}</p>
        </div>
      </div>
      <div className={`${wideFormat ? 'col-md-5' : 'col-md-6'} `}>
        {options.map((opt, index) => {
          inputRef[index] = createRef();

          return (
            <div
              key={index}
              style={{display: 'inline-block', paddingRight: '8px'}}
              onKeyUp={(e) => {
                if (e.keyCode === 32) {
                  inputRef[index].current.click();
                }
              }}
            >
              <label
                tabIndex={0}
                className="radio normal-body"
                data-tooltip-id="tooltip"
                data-tooltip-hidden={disabletips}
                data-tooltip-place={dataplace}
                data-tooltip-html={datatip}
              >
                {opt.label}

                <Field name={name} type="radio" value={opt.value}>
                  {({input, meta}) => {
                    return (
                      <>
                        <input
                          name={input.name}
                          ref={inputRef[index]}
                          id={`${index} ${label}`}
                          tabIndex={-1}
                          value={opt.value}
                          type="radio"
                          checked={input.checked}
                          onChange={input.onChange}
                          aria-label={
                            input.value === opt.value
                              ? `option field for ${label} is currently selected`
                              : `option field for ${label} is NOT currently selected`
                          }
                        />
                        <span className="radio-checkmark" />
                        {meta.touched &&
                          index === 0 &&
                          ((meta.error && (
                            <div
                              className={`form-input-error ${styles.radioInputError}`}
                            >
                              {meta.error}
                            </div>
                          )) ||
                            (meta.warning && (
                              <div
                                className={`form-input-error ${styles.radioInputError}`}
                              >
                                {meta.warning}
                              </div>
                            )) || (
                              <div
                                className={`form-input-error ${styles.radioInputError}`}
                              >
                                &nbsp;
                              </div>
                            ))}
                        {!meta.touched && (
                          <div
                            className={`form-input-error ${styles.radioInputError}`}
                          >
                            &nbsp;
                          </div>
                        )}
                      </>
                    );
                  }}
                </Field>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
renderFinalRadioField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  disabletips: PropTypes.bool,
  classNameTip: PropTypes.string,
  datamultiline: PropTypes.bool,
  dataeffect: PropTypes.string,
  datatype: PropTypes.string,
  dataplace: PropTypes.string,
  datatip: PropTypes.string,
  showTipAsText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  wideFormat: PropTypes.bool,
};
renderFinalRadioField.defaultProps = {
  showInfoIcon: false,
  wideFormat: false,
};

export const required = (value) => (value ? undefined : 'Required');

const minLength = (min) => (value) => {
  if (hasNumbers(value) === false) {
    return `Required`;
  }
  if (hasNumbers(value) && value.trim().length < min) {
    return `Must be 10 digit number`;
  }
  return undefined;
};
export const minPhoneLength = minLength(14);

export const validate = (fields, values) => {
  const errors = {};
  _.forEach(fields, (field) => {
    if (!values[field] || values[field] === null) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export const validateEmail = (values) =>
  new Promise((resolve) =>
    makeRequest({
      method: 'GET',
      path: `seekers/check_email?email=${encodeURIComponent(values.email)}`,
      isAuthenticated: false,
    }).then((response) => {
      resolve(response.data.exists);
    })
  );

export const asyncValidate = (values, dispatch) => {
  if (values && validator.isEmail(values.email)) {
    return validateEmail(values).then((response) => {
      dispatch({
        type: types.SEEKER__RETURNING_USER_EMAIL,
        payload: {userEmailExists: response},
      });
      if (response) {
        // eslint-disable-next-line no-throw-literal
        throw {email: 'Email Already Exists'};
      }
    });
  }
};

export const isIndianaZip = (zipCode) => {
  return zipCode >= 46000 && zipCode <= 47999;
};
export const isMarionCounty = (zipCode) => {
  return marionZips.includes(Number(zipCode));
};

export const mergeSeekerFields = (fieldData) => {
  let newSeeker = {...seekerFields};
  Object.keys(fieldData).forEach(function (key) {
    if (key in newSeeker || fieldData.hasOwnProperty(key)) {
      newSeeker[key] = fieldData[key];
    }
  });

  // Remove empty fields
  for (let propName in newSeeker) {
    if (
      newSeeker[propName] === null ||
      newSeeker[propName] === undefined ||
      newSeeker[propName] === '' ||
      newSeeker[propName] === 'NULL'
    ) {
      delete newSeeker[propName];
    }
  }
  return newSeeker;
};

export const setDegreeType = (degreeTypes, educationLevel) => {
  let degreeId = 0;
  switch (educationLevel.toLowerCase()) {
    case 'below high school diploma':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'HS')[0].id;
      break;
    case 'high school diploma or equivalent':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'HS')[0].id;
      break;

    case 'currently pursuing associate’s degree':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'AA')[0].id;
      break;
    case 'associate’s degree':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'AA')[0].id;
      break;
    case 'currently pursuing bachelor’s degree':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'BA')[0].id;
      break;
    case 'bachelor’s degree':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'BA')[0].id;
      break;
    case 'master’s degree':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'MS')[0].id;
      break;
    case 'doctorate degree':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'PHD')[0].id;
      break;
    case 'prefer not to answer':
      degreeId = degreeTypes.filter((x) => x.abbreviation === 'BA')[0].id;
      break;

    default:
  }
  return degreeId;
};
