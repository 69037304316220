import React from 'react';
import PropTypes from 'prop-types';
import WatchIcon from '../../components/WatchIcon';
import tagStyles from '../../components/tags/TagButton/TagButton.module.scss';
import LastUpdated from 'src/components/LastUpdated';
import MatchCircle from 'src/components/MatchCircle';

class TeamJobCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      watched: props.watched,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.watched !== prevState.watched && nextProps.watched) {
      return {watched: nextProps.watched};
    }
    return null;
  }

  handleWatchClicked = () => {
    this.props.onWatchChanged(!this.state.watched);
    this.setState((oldState) => ({
      watched: !oldState.watched,
    }));
  };

  render() {
    return (
      <div className="bd-whitebackground padding-8">
        <div>
          <div className="row">
            <div className="col-md-10">
              <button
                className="buttonAsLinkTeamCard accessibleLink normal-title clickable"
                onClick={this.props.onJobClick}
              >
                {this.props.name}
              </button>
              <LastUpdated
                textClassName="disabled-caption"
                publishedDate={this.props.datePosted}
              />
            </div>

            <div className="col-md-2 padding-top-two padding-left-twentyfour">
              <WatchIcon
                watched={this.state.watched}
                onClick={this.handleWatchClicked}
                floatRight={true}
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-md-9">
              <div className="secondary-body pb-2">{this.props.summary}</div>
            </div>
            <div
              className="col-md-3 align-self-end"
              style={{position: 'relative'}}
            >
              <MatchCircle
                viewerType={'CANDIDATE'}
                matchData={this.props.matchData}
                isApprenticeProgram={this.props.isApprenticeProgram}
                isCompact
                noMargin
              />
            </div>
          </div>

          {this.props.matchesInterest && (
            <div className={`${tagStyles.tagContainerRight} pt-1`}>
              <div className={`${tagStyles.containerGreen} discoveryTag`}>
                <span aria-label="tag" className={`${tagStyles.tagMatchGreen}`}>
                  Matches My Interests
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

TeamJobCard.propTypes = {
  name: PropTypes.string.isRequired,
  datePosted: PropTypes.string,
  summary: PropTypes.string,
  matchData: PropTypes.object.isRequired,
  watched: PropTypes.bool,
  onWatchChanged: PropTypes.func,
  onJobClick: PropTypes.func,
  matchesInterest: PropTypes.bool,
  isApprenticeProgram: PropTypes.bool,
};

TeamJobCard.defaultProps = {
  matchesInterest: false,
};

export default TeamJobCard;
