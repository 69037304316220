import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Field, Form} from 'react-final-form';
import {TextField} from '../../formFields';
import renderSelectField from '../controls/SelectFieldRenderer';
import {Row} from 'reactstrap';

import {
  minPhoneLength,
  required,
  validate,
} from '../../utils/modernApprenticeship/modernApprenticeshipCommon';
import {
  genders,
  ethnicities,
  gradeLevels,
  highSchoolGraduationYears,
  graduationMonths,
  gpas,
} from '../../utils/fieldHelper';
import MaHeader from './MaHeader';
import HavingTrouble from '../connectionForm/HavingTrouble';
import track from 'react-tracking';
import {PhoneNumberInput} from '../PhoneNumberInput';
import {highSchoolListAction} from '../../actions/schoolActions';
import {OnChange} from 'react-final-form-listeners';

const validateForm = (values) => {
  const fields = [
    'firstName',
    'lastName',
    'phone',
    'gradeLevel',
    'highSchool',
    'gpa',
    'gender',
    'ethnicity',
    'graduationYear',
    'graduationMonth',
  ];

  if (values['highSchool'] === 'Other') {
    fields.push('otherInstitution');
  }
  return validate(fields, values);
};

@track({eventName: 'ModernApprenticeshipBasicInfo'}, {dispatchOnMount: true})
class MaBasicUserInfo extends React.Component {
  state = {
    showOtherInstitutionField: false,
    indianaHighSchools: [],
  };

  componentDidMount() {
    this.setState(() => ({
      showOtherInstitutionField:
        this.props.otherInstitutionSelected ||
        this.props.initialValues.highSchool === 'Other',
    }));

    highSchoolListAction(this.props.segment.id).then((ret) => {
      this.setState({indianaHighSchools: ret});
    });
  }

  render() {
    let headerText = {
      title: 'Modern Apprenticeship',
      titleStyle: 'normal-headline bold',
      subTitle: 'High School Student Interest Form',
      subTitleStyle: 'normal-body bold',
    };
    const {isMobile} = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="bd-whitebackground">
              <MaHeader
                titleStyle={headerText.titleStyle}
                title={headerText.title}
                subTitle={headerText.subTitle}
                subTitleStyle={headerText.subTitleStyle}
              />
              <Form
                onSubmit={this.props.onSubmit}
                validate={validateForm}
                keepDirtyOnReinitialize={true}
                initialValues={{
                  firstName: this.props.initialValues.firstName,
                  lastName: this.props.initialValues.lastName,
                  phone: this.props.initialValues.phone,
                  graduationMonth: this.props.initialValues.graduationMonth,
                  graduationYear: this.props.initialValues.graduationYear,
                  gpa: this.props.initialValues.gpa,
                  gradeLevel: this.props.initialValues.gradeLevel,
                  highSchool: this.props.initialValues.highSchool,
                  highSchoolId: this.props.initialValues.highSchoolId,
                  otherInstitution: this.props.initialValues.otherInstitution,
                  gender: this.props.initialValues.gender,
                  ethnicity: this.props.initialValues.ethnicity,
                }}
              >
                {({handleSubmit, form}) => {
                  return (
                    <form onSubmit={handleSubmit} style={{padding: '48px'}}>
                      <OnChange name="highSchool">
                        {(newValue) => {
                          const otherSelected = newValue === 'Other';
                          const selectedHighSchool =
                            this.state.indianaHighSchools.find(
                              (x) => x.value === newValue
                            );
                          this.setState(() => ({
                            showOtherInstitutionField: otherSelected,
                          }));
                          form.change('otherInstitution', null);
                          form.change('highSchoolId', selectedHighSchool.id);
                        }}
                      </OnChange>
                      <div className="row">
                        <div className="col-md-1" />
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="firstName"
                                component={TextField}
                                size={32}
                                label="First Name"
                                maxLength={128}
                                showBackground={true}
                                validate={required}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="lastName"
                                component={TextField}
                                size={32}
                                label="Last Name"
                                maxLength={128}
                                showBackground={true}
                                validate={required}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="phone"
                                component={PhoneNumberInput}
                                size={32}
                                label="Phone Number"
                                maxLength={128}
                                showBackground={false}
                                validate={(x) => {
                                  return minPhoneLength(x);
                                }}
                                disabletips={true}
                                showInfoIcon={true}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'If you do not have a phone, use a parent or legal guardian’s phone instead.'
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="graduationMonth"
                                title="Graduation Month"
                                labelBold={false}
                                padLabel={false}
                                component={renderSelectField}
                                options={graduationMonths
                                  .slice(1)
                                  .map((opt) => {
                                    return {value: opt, label: opt};
                                  })}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="graduationYear"
                                component={renderSelectField}
                                title="Graduation Year"
                                labelBold={false}
                                padLabel={false}
                                options={highSchoolGraduationYears().map(
                                  (opt) => {
                                    return {value: opt, label: opt};
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="gpa"
                                component={renderSelectField}
                                title="GPA"
                                labelBold={false}
                                padLabel={false}
                                options={gpas.map((opt) => {
                                  return {value: opt, label: opt};
                                })}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="gradeLevel"
                                component={renderSelectField}
                                title="Grade Level"
                                labelBold={false}
                                padLabel={false}
                                options={gradeLevels.map((opt) => ({
                                  value: opt.value,
                                  label: opt.label,
                                }))}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {this.state.indianaHighSchools.length > 0 && (
                                <Field
                                  name="highSchool"
                                  component={renderSelectField}
                                  title="High School"
                                  labelBold={false}
                                  padLabel={false}
                                  caption="Don't see your high school? Select 'Other.'"
                                  isDisabled={
                                    this.state.indianaHighSchools.length === 0
                                  }
                                  options={this.state.indianaHighSchools.map(
                                    (opt) => ({
                                      value: opt.value,
                                      label: opt.name,
                                    })
                                  )}
                                />
                              )}
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="otherInstitution"
                                label="Enter your high school"
                                component={TextField}
                                showBackground={true}
                                visible={this.state.showOtherInstitutionField}
                                props={{
                                  disabled:
                                    !this.state.showOtherInstitutionField,
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="gender"
                                component={renderSelectField}
                                title="Gender"
                                labelBold={false}
                                padLabel={false}
                                options={genders.map((opt) => ({
                                  value: opt.value,
                                  label: opt.label,
                                }))}
                                disabletips={true}
                                showInfoIcon={true}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'We will not share this information with employers. <br/>' +
                                  'It is used for aggregate reporting purposes only.'
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="ethnicity"
                                component={renderSelectField}
                                title="Ethnicity"
                                labelBold={false}
                                padLabel={false}
                                options={ethnicities.map((opt) => ({
                                  value: opt.value,
                                  label: opt.label,
                                }))}
                                disabletips={true}
                                showInfoIcon={true}
                                dataeffect="solid"
                                datatype="dark"
                                dataplace="top"
                                datatip={
                                  'We will not share this information with employers. <br/>' +
                                  'It is used for aggregate reporting purposes only.'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1" />

                      <br />

                      <Row className="row padding-top-sixteen ">
                        <div
                          className={`col-md-12 ${
                            isMobile ? 'd-flex justify-content-center' : ''
                          } `}
                        >
                          <button
                            type="submit"
                            style={{
                              width: '210px',
                              height: '40px',
                            }}
                            className={`clickable btn btn-primary ${
                              isMobile ? '' : 'float-right'
                            }`}
                          >
                            Continue
                          </button>
                        </div>
                      </Row>
                      <HavingTrouble />
                    </form>
                  );
                }}
              </Form>
            </div>
          </div>

          <div className="col-md-2" />
        </div>
      </Fragment>
    );
  }
}

MaBasicUserInfo.propTypes = {
  onSubmit: PropTypes.func,
  change: PropTypes.func,
  segment: PropTypes.object,
  isMobile: PropTypes.bool,
  otherInstitutionSelected: PropTypes.bool,
  initialValues: PropTypes.object,
};

MaBasicUserInfo.defaultProps = {
  segment: {},
  otherInstitutionSelected: false,
};

const mapStateToProps = (state) => {
  return {
    segment: state.invitationCodes.segment,
  };
};

export default connect(mapStateToProps)(MaBasicUserInfo);
