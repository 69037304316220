import React from 'react';
import PropTypes from 'prop-types';

class ActionIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  handleMouseEnter = (message) => {
    this.setState({message: message});
  };

  handleMouseLeave = () => {
    this.setState({message: null});
  };
  handleOnBlur = () => {
    this.setState({message: null});
  };
  handleOnFocus = (message) => {
    this.setState({message: message});
  };

  render() {
    const renderChildren = (props) => {
      return React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
          onFocus: this.handleOnFocus,
          onBlur: this.handleOnBlur,
        });
      });
    };

    return (
      <div
        className="action-icons inlineblock float-right"
        style={this.props.style}
      >
        {renderChildren(this.props)}
        {this.state.message && (
          <div className="action-icon-message">
            <span className="normal-caption">{this.state.message}</span>
          </div>
        )}
      </div>
    );
  }
}

ActionIcons.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default ActionIcons;
