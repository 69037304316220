import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import DocumentTitle from '../../components/vendor/DocumentTitle';
import {BusyButton} from 'src/components/BusyButton';
import {Line} from 'rc-progress';

import ReportConnectionIsNetworkRole from './ReportConnectionIsNetworkRole';
import ReportConnectionSelectRole from './ReportConnectionSelectRole';
import ReportConnectionAttributeRoleToNetwork from './ReportConnectionAttributeRoleToNetwork';
import ReportConnectionSummary from './ReportConnectionSummary';
import ReportConnectionConfirmation from './ReportConnectionConfirmation';
import ReportConnectionFeedback from './ReportConnectionFeedback';
import * as roleSelectionTypeOptions from './ReportConnectionConstants';
import LoadingOverlay from 'react-loading-overlay';

import {createSeekerConnection} from 'src/actions/connections';
import {getSeekerForConnection} from 'src/actions/seekers';
import {useParams} from 'react-router-dom';
import {track as analyticsTrack} from 'src/utils/analytics';

export default function ReportConnection(params) {
  let {seekerId} = useParams();
  const dispatch = useDispatch();
  const initialState = {
    currentStep: 1,
    isNetworkConnection: null,
    seeker: null,
    role: {
      employerName: '',
      jobName: '',
      employmentType: null,
      isRemote: false,
      city: '',
      state: null,
      zipCode: '',
      jobId: null,
      employerId: null,
      selectionType: roleSelectionTypeOptions.APPLIEDTOSELECT,
      jobZipCode: '',
    },
    roleAttributed: null,
    notes: null,
  };
  const [currentStep, setCurrentStep] = useState(initialState.currentStep);
  const [isNetworkConnection, setIsNetworkConnection] = useState(
    initialState.isNetworkConnection
  );
  const [role, setRole] = useState(initialState.role);
  const [roleAttributed, setRoleAttributed] = useState(
    initialState.roleAttributed
  );
  const [notes, setNotes] = useState(null);

  let connectionsSaving = useSelector(
    (state) => state.connections.connectionsSaving
  );
  let connectionSaveFailed = useSelector(
    (state) => state.connections.connectionSaveFailed
  );
  let seeker = useSelector((state) => state.seekers.seeker);
  let seekerLoading = useSelector((state) => state.seekers.seekerLoading);

  useEffect(() => {
    if (seeker.id == null && !seekerLoading) {
      dispatch(getSeekerForConnection(seekerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    {
      stepNumber: 1,
      component: ReportConnectionIsNetworkRole,
      args: {
        onChange: (e) => {
          setIsNetworkConnection(e);
          if (e === true) {
            setRoleAttributed(e);
          } else {
            setRoleAttributed(initialState.roleAttributed);
          }
        },
        isNetworkConnection: isNetworkConnection,
      },
      showBackButton: false,
      disableNextButton: () => isNetworkConnection === null,
      isComplete: () => {
        return isNetworkConnection !== null;
      },
      useInPercentageCalculation: true,
    },
    {
      stepNumber: 2,
      component: ReportConnectionSelectRole,
      args: {
        onChange: setRole,
        isNetworkConnection: isNetworkConnection,
        role: role,
        seeker: seeker,
      },
      showBackButton: true,
      disableNextButton: () => {
        if (
          role.selectionType === roleSelectionTypeOptions.MANUAL ||
          !isNetworkConnection
        ) {
          return (
            role.employerName === '' ||
            role.jobName === '' ||
            role.employmentType === null ||
            (role.isRemote === false && role.zipCode === '')
          );
        }

        if (role.jobId && role.employmentType) return false;

        return true;
      },
      isComplete: () => {
        if (
          role.selectionType === roleSelectionTypeOptions.MANUAL ||
          !isNetworkConnection
        ) {
          // manual must have company, title, type, zipcode (if not remote)
          return (
            role.employerName !== '' &&
            role.jobName !== '' &&
            role.employmentType !== null &&
            (role.isRemote === true || role.zipCode !== '')
          );
        }

        //selecting a role
        return role.jobId !== null && role.employmentType !== null;
      },
      useInPercentageCalculation: true,
      onNext: () => {
        if (roleAttributed && isNetworkConnection) {
          setCurrentStep(4);
        } else {
          setCurrentStep(currentStep + 1);
        }
      },
      onBack: () => {
        setRole(initialState.role);
        setCurrentStep(currentStep - 1);
      },
    },
    {
      stepNumber: 3,
      component: ReportConnectionAttributeRoleToNetwork,
      args: {
        onChange: setRoleAttributed,
        roleAttributed: roleAttributed,
        isNetworkConnection: isNetworkConnection,
      },
      showBackButton: true,
      disableNextButton: () => roleAttributed === null,
      isComplete: () => {
        return roleAttributed !== null;
      },
      useInPercentageCalculation: true,
    },
    {
      stepNumber: 4,
      component: ReportConnectionFeedback,
      args: {
        seeker: seeker,
        onChange: setNotes,
      },
      showBackButton: true,
      disableNextButton: () => false,
      isComplete: () => {
        return currentStep > 4;
      },
      useInPercentageCalculation: true,
      onBack: () => {
        if (roleAttributed && isNetworkConnection) {
          setCurrentStep(2);
        } else {
          setCurrentStep(currentStep - 1);
        }
      },
    },
    {
      stepNumber: 5,
      component: ReportConnectionSummary,
      args: {
        seeker: seeker,
        roleAttributed: roleAttributed,
        role: role,
        notes: notes,
      },
      showBackButton: true,
      disableNextButton: () => false,
    },
    {
      stepNumber: 6,
      component: ReportConnectionConfirmation,
      args: {
        roleAttributed: roleAttributed,
        connectionSaveFailed: connectionSaveFailed,
      },
      showBackButton: false,
    },
  ];

  const calculatePercentComplete = () => {
    if (!currentStep) {
      return 0;
    }
    let totalSteps = steps.filter(
      (step) => step.useInPercentageCalculation
    ).length;

    let stepsComplete = steps.filter(
      (step) => step.useInPercentageCalculation && step.isComplete() === true
    ).length;

    return Math.round(100 * (stepsComplete / totalSteps));
  };

  function reportAnalyticsEvent(stepNumber) {
    let percentComplete = calculatePercentComplete();

    if (stepNumber === 3 && percentComplete === 75) {
      // If the user manually fills out the job details it adds another step. We skip so page 3 is the same for both flows
      return;
    }

    let eventName = '';
    let displayName = '';

    switch (stepNumber) {
      case 1:
        eventName = 'seekerReportedConnectionPg1';
        displayName = 'Seeker reported connection page 1/3';
        break;
      case 2:
        eventName = 'seekerReportedConnectionPg2';
        displayName = 'Seeker reported connection page 2/3';
        break;
      case 3:
        eventName = 'seekerReportedConnectionPg3';
        displayName = 'Seeker reported connection page 3/3';
        break;
      case 4:
        eventName = 'seekerReportedConnectionPg3';
        displayName = 'Seeker reported connection page 3/3';
        break;
      default:
        eventName = 'seekerReportedConnectionSubmitted';
        displayName = 'Seeker reported connection submitted';
    }

    analyticsTrack({
      eventName: eventName,
      displayName: displayName,
      seekerId: seeker.id,
      segment: seeker.segments[0].name,
    });
  }

  const handleNextClick = () => {
    const step = steps[currentStep - 1];

    reportAnalyticsEvent(step.stepNumber);

    if (step.onNext !== undefined) {
      step.onNext();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const renderStep = () => {
    const step = steps.find((x) => x.stepNumber === currentStep);
    const args = step.args;

    if (seeker.segments && seeker.segments.length === 0 && !seekerLoading) {
      return (
        <span className="justify-content-center text-align-center">
          We are unable to report a connection to your profile at this time.
          Please reach out to your Career Mentor or{' '}
          <a href="mailto:support@ascendindiana.com">
            support@ascendindiana.com
          </a>{' '}
          for help regarding your connection.
        </span>
      );
    }

    return <step.component onSubmit={handleNextClick} {...args} />;
  };

  const renderBackButton = () => {
    if (steps[currentStep - 1].showBackButton) {
      return (
        <button
          type="button"
          className="btn btn-secondary me-4"
          onClick={() => {
            const step = steps[currentStep - 1];
            if (step.onBack !== undefined) {
              step.onBack();
              return;
            }
            setCurrentStep(currentStep - 1);
          }}
          style={{width: 'fit-content'}}
        >
          Back
        </button>
      );
    }
    return null;
  };

  const handleSubmit = () => {
    reportAnalyticsEvent(0);

    let location = `${role.city || ''} ${role.state?.label || ''}`.trim();
    const zipCode = role.zipCode.trim();

    if (location.length > 0 && zipCode.length > 0) {
      location += ', ';
    }
    location += zipCode;

    const newConnection = {
      seekerId: seeker.id,
      seekerName: seeker.name,
      employerId: role.employerId,
      employerName: role.employerId ? null : role.employerName,
      jobId: role.jobId,
      jobName: role.jobId ? null : role.jobName,
      employmentTypeId: role.employmentType.value,
      networkEmployer: role.employerId ? 1 : 0,
      networkJob: role.jobId ? 1 : 0,
      networkSeeker: 1,
      placementDate: new Date(),
      offerRescinded: 0,
      ascendRecruiterId: seeker.ascendRecruiterId,
      location: location.trim(),
      isAttributedToNetwork: roleAttributed,
      notes: notes,
    };

    dispatch(createSeekerConnection(newConnection));
  };

  useEffect(() => {
    if (
      currentStep === 5 &&
      connectionSaveFailed === false &&
      connectionsSaving === false
    ) {
      setCurrentStep(6);
    }
  }, [currentStep, connectionSaveFailed, connectionsSaving]);

  return (
    <DocumentTitle title="Report a Connection">
      <div className="wholepage">
        <LoadingOverlay
          role="status"
          active={connectionsSaving}
          spinner={true}
          text="Saving..."
          fadeSpeed={200}
          styles={{
            overlay: (base) => ({
              ...base,
              position: 'fixed',
            }),
          }}
        >
          <div className="bd-graybackground container pt-1 pb-1 ps-5 pe-5">
            {(currentStep < 6 || connectionsSaving) &&
              seeker.segments &&
              seeker.segments.length !== 0 && (
                <center>
                  <div className="normal-title padding-bottom-eight bold">
                    Track Your New Role
                  </div>
                  <p>Tell us more about this opportunity</p>
                  <div
                    className="text-align-right d-flex align-items-center"
                    style={{textWrap: 'nowrap'}}
                  >
                    <Line
                      percent={calculatePercentComplete()}
                      strokeColor="#275084"
                      trailColor="#cbe1ff"
                      strokeWidth={1}
                    />
                    <span style={{marginLeft: '16px'}}>
                      {calculatePercentComplete()}% Complete
                    </span>{' '}
                  </div>
                  <hr />
                </center>
              )}

            <div className="row bd-whitebackground pt-2">{renderStep()}</div>

            <br />
            {seeker.segments && seeker.segments.length !== 0 && (
              <div className="row justify-content-center">
                {renderBackButton()}
                {currentStep < steps.length - 1 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleNextClick}
                    disabled={steps[currentStep - 1].disableNextButton()}
                    style={{width: 'fit-content'}}
                  >
                    Next
                  </button>
                )}
                {currentStep === steps.length - 1 && (
                  <BusyButton
                    onClick={handleSubmit}
                    buttonText={'Submit Feedback'}
                    busyText={'Submitting'}
                    className="col-3"
                    style={{width: '210px', height: '40px'}}
                    float={'float-right'}
                    buttonIcon={'save'}
                    alignText={'text-center'}
                    disabled={steps[currentStep - 1].disableNextButton()}
                  >
                    Submit Feedback
                  </BusyButton>
                )}
              </div>
            )}
          </div>
        </LoadingOverlay>
      </div>
    </DocumentTitle>
  );
}
