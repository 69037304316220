import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {withRouter} from '../../utils/commonHooks';
import {connect} from 'react-redux';
import track from 'react-tracking';
import * as analytics from 'src/utils/analytics.js';

import * as teamsActions from '../../actions/teams';
import * as jobsActions from '../../actions/jobs';
import * as recruitersActions from '../../actions/recruiters';
import * as employerActions from '../../actions/employers';

import * as Layout from '../../layout';
import CompanyTeamCard from 'src/pages/companyHome/CompanyTeamCard';
import {EditTeamForm} from '../../forms/TeamForm';
import JobProfile from '../../components/JobProfile';
import {blankJob} from '../../utils/jobHelper';
import {splitArrayIntoRows} from '../../utils/miscHelper';
import {getJobCache, getEmployerCache} from 'src/services/cacheApi';
import styles from './CompanyHomePage.module.scss';

import AscendDataTable from '../../components/AscendDataTable';
import * as tableActions from 'src/actions/employersDataTable';
import {Button} from 'reactstrap';
import RecentlyAppliedCandidates from './RecentlyAppliedCandidates';
import ClientSuccessManager from './ClientSuccessManager';
import RecentlySavedCandidates from './RecentlySavedCandidates';
import {List, Facebook} from 'react-content-loader';

class CompanyHomePage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      jobProfileOpen: false,
      job: blankJob('', ''),
      jobId: '',
      teamFormOpen: false,
      employerCache: null,
      jobList: [],
      isMobile: false,
      currentJobPage: 0,
      jobSortTerm: '',
      sortBy: '',
      sortAscending: true,
    };
  }

  searchForJobId = (jobId) => {
    this.props.getJobToCache(jobId);
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.updateMobileState);
    this.updateMobileState();
    let recruiterId = null;
    if (this.props.loggedInCompanyRecruiterId) {
      recruiterId = this.props.loggedInCompanyRecruiterId;
    } else {
      recruiterId = this.props.match.params.id;
    }
    if (this.props.teamsLoading || this.props.recruiterLoading) return;

    analytics.track({eventName: 'CompanyHomePage'});
    this.props.getRecruiter(recruiterId);
    this.props.getTeamsByRecruiter(recruiterId, true);
    if (this.props.employerId !== null) {
      this.getAllJobs(0, 5, this.props.employerId.toString());
      this.props.getEmployerStatsById(this.props.employerId);
      this.props.getEmployer(this.props.employerId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.recruiter !== prevProps.recruiter &&
      this.props.recruiter.employerId !== undefined
    ) {
      getEmployerCache(this.props.recruiter.employerId).then((resp) => {
        if (!this._isMounted) return;
        this.setState({
          employerCache: resp.data,
        });
      });
    }
  }

  updateMobileState = () => {
    const isMobile = window.innerWidth <= 600;
    this.setState({isMobile});
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateMobileState);
  }

  getAllJobs = (currentPage, itemsPerPage, searchTerm, sortTerm) => {
    let filters = {companyHomePage: true};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = ['companyid'];
    }

    this.props.getEmployersActiveJobs(
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      this.state.includeUnpublished
    );
  };

  handleModalClose = () => {
    this.setState({
      teamFormOpen: false,
      jobProfileOpen: false,
    });
  };

  handleJobClick = (jobId) => {
    getJobCache(jobId).then((resp) => {
      analytics.track({
        eventName: 'JobClicked',
        ...resp.data,
      });
    });
    this.searchForJobId(jobId);
    this.setState({
      jobProfileOpen: true,
      jobId: jobId,
    });
  };

  handleEditTeamClicked = (teamId) => {
    this.props.getTeamForEdit(teamId);
    this.setState({
      teamFormOpen: true,
      teamIdToEdit: teamId,
    });
  };

  handleTeamEditSubmit = (team) => {
    this.props.updateTeam(team);
    this.setState({teamFormOpen: false});
  };

  handleTeamDeleteConfirmed = (teamId) => {
    this.props.deleteTeam(teamId);
  };

  handleTeamClick = (id) => {
    analytics.track({
      eventName: 'TeamClicked',
      ...this.state.employerCache,
    });
    this.props.history.push('/team/' + id);
  };

  @track((props, state, [id]) => ({
    eventName: 'SeekerClicked',
    page: 'CompanyHomePage',
    seekerId: id,
  }))
  handleSeekerClick = (id) => {
    this.props.history.push('/candidate/' + id);
  };

  @track((props, state) => ({
    eventName: 'EmployerClicked',
    page: 'CompanyHomePage',
    employerId: props.recruiter.employerId,
  }))
  handleCompanyClicked = () => {
    if (this.props.recruiter.employerId) {
      this.props.history.push('/employer/' + this.props.recruiter.employerId);
    }
  };
  handleAddJobsClicked = () => {
    this.props.history.push('/post_a_role');
  };

  handleJobPageChange = (newPage) => {
    this.setState({currentJobPage: newPage});
    this.getAllJobs(
      newPage,
      5,
      this.props.employerId.toString(),
      this.state.jobSortTerm
    );
  };

  handleJobSortChange = (sortBy, sortAsc) => {
    const sortOperator = sortAsc ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;
    this.setState({
      sortBy: sortBy,
      sortAscending: sortAsc,
      jobSortTerm: sortTerm,
      currentJobPage: 0,
    });
    this.getAllJobs(0, 5, this.props.employerId.toString(), sortTerm);
  };

  render() {
    const tableColumns = [
      {
        sortBy: 'name',
        displayName: (
          <div style={{textAlign: 'left', float: 'left'}}>JOB TITLE</div>
        ),

        content: (job) => {
          return (
            <Button
              type="button"
              className="buttonAsLinkRoles"
              onClick={() => this.handleJobClick(job.id)}
            >
              {job.name}
            </Button>
          );
        },
      },
      {
        displayName: 'DAYS ACTIVE',
        sortBy: 'daysActiveCount',
        content: (job) => (
          <div style={{textAlign: 'center', verticalAlign: 'center'}}>
            {job.daysActiveCount}
          </div>
        ),
      },
      {
        displayName: 'MATCHES',
        sortBy: 'matchCount',
        content: (job) => (
          <div style={{textAlign: 'center'}}>{job.matchCount}</div>
        ),
      },
      {
        displayName: 'VIEWS',
        sortBy: 'viewsCount',
        content: (job) => (
          <div style={{textAlign: 'center'}}>{job.viewsCount}</div>
        ),
      },
      {
        displayName: 'APPLICATION CLICKS',
        content: (job) => (
          <div style={{textAlign: 'center'}}>{job.applyClicksCount}</div>
        ),
      },
      {
        displayName: 'INVITATIONS',
        sortBy: 'invitationsCount',
        content: (job) => (
          <div style={{textAlign: 'center'}}>{job.invitationsCount}</div>
        ),
      },
      {
        displayName: 'APPLICATIONS',
        sortBy: 'applicationsCount',
        content: (job) => (
          <div style={{textAlign: 'center'}}>{job.applicationsCount}</div>
        ),
      },
    ];
    const rowMenuItemList = [
      {
        displayName: 'View Applicants',
      },
      {
        displayName: 'View Matches',
      },
    ];
    const handlerFunction = (operation, jobId, index, row) => {
      switch (operation) {
        case 'View Applicants':
          this.props.history.push(
            `/find_candidates?teamId=${row.teamId}&roleId=${row.id}&filterTag=applied`
          );
          break;

        case 'View Matches':
          this.props.history.push(
            `/find_candidates?teamId=${row.teamId}&roleId=${row.id}`
          );
          break;

        default:
          this.handleViewClick(row.id);
          break;
      }
    };

    const renderLoadingTeams = () => {
      return (
        <div className={styles.loadingTeamCards}>
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className={styles.loadingTeamCard}>
              <List />
            </div>
          ))}
        </div>
      );
    };

    const {recruiter, role, teams} = this.props;

    let job = {};
    if (this.state.jobId in this.props.jobCache) {
      job = this.props.jobCache[this.state.jobId];
    }

    const renderTeamRow = (row) => {
      return row.map((team) => {
        return (
          <div key={team.id} className="col-md-6 padding-bottom-sixteen">
            <CompanyTeamCard
              key={team.id}
              published={team.published}
              teamId={team.id}
              role={this.props.role}
              name={team.name}
              summary={team.summary}
              watchedSeekers={team.watchedSeekers}
              onTeamEditClick={this.handleEditTeamClicked}
              onJobClick={this.handleJobClick}
              onTeamClick={this.handleTeamClick}
              onSeekerClick={this.handleSeekerClick}
            />
          </div>
        );
      });
    };

    const renderTeamCards = (teams) => {
      const rows = splitArrayIntoRows(teams, 3);
      return rows.map((row, i) => {
        return (
          <div key={i} className="row">
            {renderTeamRow(row)}
          </div>
        );
      });
    };

    const recruiterActionIcons = (employerId) => {
      return (
        <div className="d-flex">
          <button
            className={`${styles.profileButton} nowrap`}
            onClick={this.handleCompanyClicked}
          >
            View Employer Profile
          </button>

          <button
            className={`${styles.postJobButton} btn btn-primary ms-2 nowrap`}
            onClick={this.handleAddJobsClicked}
          >
            Post a Job
          </button>
        </div>
      );
    };

    return (
      <DocumentTitle title="Employer Home">
        <div className="wholepage">
          <Layout.PageHeader
            title={this.state.isMobile ? '' : 'Dashboard'}
            actionButtons={
              (role === 'COMPANY_RECRUITER' ||
                role === 'COMPANY_RECRUITER_ADMIN') &&
              recruiterActionIcons(recruiter.employerId)
            }
            titleStyle={{
              backgroundColor: 'var(--secondary-2)',
              padding: '20px',
              fullWidth: true,
            }}
            headerStyleOverride={{
              backgroundColor: 'var(--secondary-1)',
              fontSize: '16px',
            }}
            headerClassOverride={'None'}
          />

          <main id="main" tabIndex="-1">
            {this.state.teamIdToEdit && (
              <EditTeamForm
                id={this.state.teamIdToEdit}
                onSubmit={this.handleTeamEditSubmit}
                onDelete={() =>
                  this.handleTeamDeleteConfirmed(this.state.teamIdToEdit)
                }
                isOpen={this.state.teamFormOpen}
                onClose={this.handleModalClose}
              />
            )}

            {job && job.id && (
              <JobProfile
                key={job.id}
                job={job}
                isOpen={this.state.jobProfileOpen}
                onClose={this.handleModalClose}
              />
            )}

            <div className="bd-pagebody">
              <div className={styles.flexContainer}>
                <br />

                <div className="row">
                  <div
                    className={styles.mainContent}
                    style={{minWidth: '1200px', maxWidth: '1200px'}}
                  >
                    <div className={styles.tableTitle}>
                      Active Jobs
                      {!this.props.activeJobsLoading &&
                        `(${this.props.activeJobsTotalItems})`}
                    </div>
                    <AscendDataTable
                      showSearch={false}
                      rowMenuItemList={rowMenuItemList}
                      handlerFunction={handlerFunction}
                      tableColumns={tableColumns}
                      items={this.props.activeJobs}
                      currentPage={this.state.currentJobPage}
                      itemsPerPage={5}
                      totalItems={this.props.activeJobsTotalItems}
                      handlePageChange={this.handleJobPageChange}
                      handleSortChange={this.handleJobSortChange}
                      itemsLoading={this.props.activeJobsLoading}
                      sortBy={this.state.sortBy}
                      sortAscending={this.state.sortAscending}
                    />
                  </div>
                </div>
                <br />
                <br />

                <div className={`flex-row ${styles.mainContent}`}>
                  <div className={styles.teamRow}>
                    <div className={styles.teamsSection}>
                      <h2 className="inlineblock align-middle bold">
                        My Teams
                      </h2>
                      <br />
                      {this.props.teamsLoading && renderLoadingTeams()}
                      {!this.props.teamsLoading && renderTeamCards(teams)}
                    </div>

                    <div className={styles.panelContainer}>
                      {this.props.employerStatsLoading && (
                        <>
                          <div className={styles.loadingStatCard}>
                            <Facebook />
                          </div>
                          <div className={styles.loadingStatCard}>
                            <Facebook />
                          </div>
                        </>
                      )}
                      {!this.props.employerStatsLoading && (
                        <>
                          <RecentlyAppliedCandidates
                            numCandidates={this.props.thirtyDayApplicationCount}
                            applicationListUrl="/jobs/applications"
                            exploreCandidatesUrl="/find_candidates"
                          />
                          <RecentlySavedCandidates
                            numSavedCandidates={
                              this.props.thirtyDayRecentlySavedCount
                            }
                            exploreCandidatesUrl="/find_candidates"
                            savedCandidatesUrl={`/saved_candidates/${this.props.recruiter.employerId}`}
                          />
                        </>
                      )}
                      {this.props.employerLoading && (
                        <div className={styles.loadingStatCard}>
                          <Facebook />
                        </div>
                      )}
                      {!this.props.employerLoading && (
                        <ClientSuccessManager
                          accountManager={this.props.accountManager}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </main>
          <Layout.PageFooter
            footerStyleOverride={{backgroundColor: 'var(--secondary-1)'}}
            footerClassOverride={'container-fluid container-fluid-override'}
          />
        </div>
      </DocumentTitle>
    );
  }
}

CompanyHomePage.propTypes = {
  history: PropTypes.object.isRequired,
  getRecruiter: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  getTeamForEdit: PropTypes.func.isRequired,
  getTeamsByRecruiter: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  recruiter: PropTypes.object,
  jobCache: PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  role: PropTypes.string,
  loggedInCompanyRecruiterId: PropTypes.number,
  teams: PropTypes.array,
  match: PropTypes.object.isRequired,
  teamsLoading: PropTypes.bool.isRequired,
  recruiterLoading: PropTypes.bool.isRequired,
  getEmployersActiveJobs: PropTypes.func.isRequired,
  employerId: PropTypes.number,
  activeJobs: PropTypes.array,
  activeJobsItemsPerPage: PropTypes.number,
  activeJobsLoading: PropTypes.bool,
  activeJobsPage: PropTypes.number,
  activeJobsTotalItems: PropTypes.number,
  updateSortTerm: PropTypes.func.isRequired,
  getEmployerStatsById: PropTypes.func.isRequired,
  thirtyDayApplicationCount: PropTypes.number,
  thirtyDayRecentlySavedCount: PropTypes.number,
  getEmployer: PropTypes.func.isRequired,
  employerLoading: PropTypes.bool,
  employerStatsLoading: PropTypes.bool,
  accountManager: PropTypes.object,
};

const mapStateToProps = (state) => {
  let managerInfo = null;
  if (state.employers.employer.accountManager.email) {
    managerInfo = {
      ...state.employers.employer.accountManager,
      profileAvatarUrl: state.employers.employer.accountManager.profilePicture,
    };
  }

  return {
    recruiter: state.recruiters.recruiter,
    jobCache: state.jobs.jobCache,
    role: state.profile.role,
    loggedInCompanyRecruiterId: state.profile.companyRecruiterId,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
    recruiterLoading: state.recruiters.recruitersLoading,
    employerId: state.profile.employerId,
    activeJobs: state.employers.activeJobs,
    activeJobsItemsPerPage: state.employers.activeJobsItemsPerPage,
    activeJobsLoading: state.employers.activeJobsLoading,
    activeJobsPage: state.employers.activeJobsPage,
    activeJobsTotalItems: state.employers.activeJobsTotalItems,
    applications: state.applications.applications,
    employerStatsLoading: state.employers.employerStatsLoading,
    thirtyDayApplicationCount:
      state.employers.statsByEmployer.thirtyDayApplicationCount,
    thirtyDayRecentlySavedCount:
      state.employers.statsByEmployer.thirtyDayRecentlySavedCount,
    accountManager: managerInfo,
    employerLoading: state.employers.employerLoading,
  };
};

const mapDispatchToProps = {
  ...teamsActions,
  ...jobsActions,
  ...recruitersActions,
  ...employerActions,
  ...tableActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyHomePage)
);
