import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {withRouter} from '../../utils/commonHooks';
import {connect} from 'react-redux';
import track from 'react-tracking';
import _ from 'lodash';

import * as Layout from '../../layout';
import * as jobActions from '../../actions/jobs';
import * as seekersActions from '../../actions/seekers';
import * as degreeTypeActions from '../../actions/DegreeTypeActions';

import Spotlight from '../../components/Spotlight';
import JobPreferences from '../../components/JobPreferences';
import OverallMatchWidget from '../../components/OverallMatchWidget';
import SkillsCard from '../../components/jobProfile/SkillsCard';
import IsDraftWidget from '../../components/IsDraftWidget';

import {toCurrency} from '../../utils/wageHelper';

import medicalSvg from '../../assets/images/Medical.svg';
import stocksSvg from '../../assets/images/Stocks.svg';
import dentalSvg from '../../assets/images/Dental.svg';
import vacationSvg from '../../assets/images/Vacation.svg';
import visionSvg from '../../assets/images/Vision.svg';
import parentalSvg from '../../assets/images/Parental.svg';
import lifeSvg from '../../assets/images/Life.svg';
import smileySvg from '../../assets/images/Smiley.svg';
import retirementSvg from '../../assets/images/Retirement.svg';
import educationSvg from '../../assets/images/Education.svg';
import WatchIcon from '../../components/WatchIcon';
import styles from '../../components/JobProfile.module.scss';
import {getLocationText} from '../jobDiscovery/roleUtil';
import {getWageRangeText} from 'src/utils/wageHelper';
import {getJobCache} from '../../services/cacheApi';
import {track as analyticsTrack, trackVisibility} from '../../utils/analytics';
import {fixUrl} from '../../utils/fieldHelper';
import {addressToString, getGoogleMapUrl} from 'src/utils/miscHelper';
import {ErrorSvg} from '../../assets/svg/svgComponents';
import ReportIssueModal from '../../components/ReportIssueModal';
import {getAllApprenticePathways} from 'src/actions/apprenticePathwaysActions';
import {format} from 'date-fns';
import LastUpdated from 'src/components/LastUpdated';

class JobProfilePage extends React.Component {
  state = {
    reportIssueModalIsOpen: false,
  };
  componentDidMount() {
    const jobId = this.getJobIdFromUrl();
    if (!this.props.jobCacheLoading && !(jobId in this.props.jobCache)) {
      this.props.getJobToCache(jobId);
    }
    if (this.props.role === 'JOB_SEEKER') {
      this.props.getSeeker(this.props.seekerId);
    }

    if (!this.props.degreeTypeRanksLoading) {
      this.props.getDegreeTypesRank();
    }
    if (this.props.role === 'JOB_SEEKER') {
      trackVisibility({
        seekerId: this.props.seekerId,
        jobId: jobId,
        eventName: 'ROLE_MODAL_OPENED',
      });
    }
    this.props.getAllApprenticePathways();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.jobCache !== this.props.jobCache) {
      const data = {
        eventName: 'JobProfilePage',
      };

      var job = this.getJobFromJobCache();

      if (job) {
        data.jobId = job.id;
        data.jobName = job.name;
        data.jobTeamId = job.teamId;
        data.jobTeamName = job.team.name;
        data.jobEmployerId = job.employerId;
        data.jobEmployerName = job.employer.name;
        data.jobEmploymentTypes = job.employmentTypes
          .map((x) => x.name)
          .join(',');
      }
      this.props.tracking.trackEvent(data);
    }
  }

  getJobIdFromUrl() {
    return parseInt(this.props.match.params.id, 10);
  }

  getJobFromJobCache() {
    const jobId = this.getJobIdFromUrl();

    if (!(jobId in this.props.jobCache)) {
      return {};
    }

    let job = this.props.jobCache[jobId];
    job = {
      ...job,
      minimumYearlySalary: toCurrency(job.minimumYearlySalary),
      maximumYearlySalary: toCurrency(job.maximumYearlySalary),
      minimumHourlyWage: toCurrency(job.minimumHourlyWage),
      maximumHourlyWage: toCurrency(job.maximumHourlyWage),
    };

    return job;
  }
  handleEditClick = () => {};

  handleApproveClick = () => {
    const jobId = this.getJobIdFromUrl();
    var job = {};
    if (jobId in this.props.jobCache) {
      job = this.props.jobCache[jobId];
    }
    job.published = true;
    this.props.updateJob(job);
  };

  handleWatchClicked = () => {
    const jobId = this.getJobIdFromUrl();
    var job = {};
    if (jobId in this.props.jobCache) {
      job = this.props.jobCache[jobId];
    }

    const isWatched = this.props.seeker.watchedJobs.some((x) => x.id === jobId);
    if (isWatched) {
      this.props.deleteJobWatch(jobId);
    } else {
      this.props.createJobWatch(job);
    }
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.deleteJobApplied(jobId);
    } else {
      this.props.createJobApplied(jobId);
    }
  };

  handleEmployerClicked = (id) => {
    this.props.history.push('/employer/' + id);
  };

  handleTeamClicked = (id) => {
    this.props.history.push('/team/' + id);
  };

  handleReportIssueClick = () => {
    this.setState({
      reportIssueModalIsOpen: true,
    });
  };

  getDegreeType = (job) => {
    if (!this.props.degreeTypeRank) {
      return 'NA';
    }

    let degreeRank = this.props.degreeTypeRank.find(
      (x) => x.id === job.degreeTypeRankId
    );
    return degreeRank && degreeRank.name;
  };

  @track((props, state, [to]) => ({
    eventName: `RoleApplicationLinkClicked`,
    roleUrl: to,
  }))
  handleAppLinkClicked = async (job) => {
    const {history} = this.props;
    const isMaSegment = this.props.jobCache[
      this.getJobIdFromUrl()
    ].segments.some((x) => x.isApprenticeProgram);
    getJobCache(job.id).then((resp) => {
      analyticsTrack({
        eventName: `RoleApplicationLinkClicked`,
        ...resp.data,
      });

      if (this.props.role === 'JOB_SEEKER') {
        trackVisibility({
          seekerId: this.props.seekerId,
          jobId: resp.data.id,
          eventName: 'APPLY_TO_THIS_ROLE_CLICK',
        });
      }
    });

    if (isMaSegment) {
      history.push(`/jobs/apply/${job.id}`);
    } else {
      await this.props.applyToClicked(job.id);
      window.open(fixUrl(job.url));
    }
  };

  render() {
    const jobId = this.getJobIdFromUrl();
    let job = this.getJobFromJobCache();

    const isJobAvailable = () => {
      return jobId in this.props.jobCache;
    };

    const renderItem = (content) => {
      return content || 'Not selected';
    };

    const renderBenefits = () => {
      if (_.some(job.employmentTypes, {name: 'Full-Time Job'}))
        return (
          <div>
            <h2 className="normal-subheader padding-bottom-sixteen bold">
              Employee Benefits
            </h2>
            {job.medicalInsurance && (
              <div className="other-details extraspace">
                <img alt="" src={medicalSvg} />
                &nbsp;&nbsp;Medical Insurance
              </div>
            )}
            {job.stockOptions && (
              <div className="other-details extraspace">
                <img alt="" src={stocksSvg} />
                &nbsp;&nbsp;Stock Options
              </div>
            )}
            {job.dentalCoverage && (
              <div className="other-details extraspace">
                <img alt="" src={dentalSvg} />
                &nbsp;&nbsp;Dental Coverage
              </div>
            )}
            {job.paidVacation && (
              <div className="other-details extraspace">
                <img alt="" src={vacationSvg} />
                &nbsp;&nbsp;Paid Time Off
              </div>
            )}
            {job.visionCoverage && (
              <div className="other-details extraspace">
                <img alt="" src={visionSvg} />
                &nbsp;&nbsp;Vision Coverage
              </div>
            )}
            {job.paidParentalLeave && (
              <div className="other-details extraspace">
                <img alt="" src={parentalSvg} />
                &nbsp;&nbsp;Paid Parental Leave
              </div>
            )}
            {job.lifeInsurance && (
              <div className="other-details extraspace">
                <img alt="" src={lifeSvg} />
                &nbsp;&nbsp;Life Insurance
              </div>
            )}
            {job.flexibleWorkEnvironment && (
              <div className="other-details extraspace">
                <img alt="" src={smileySvg} />
                &nbsp;&nbsp;Flexible Work Environment
              </div>
            )}
            {job.retirementPlans && (
              <div className="other-details extraspace">
                <img alt="" src={retirementSvg} />
                &nbsp;&nbsp;Retirement Plans
              </div>
            )}
            {job.educationalAssistance && (
              <div className="other-details extraspace">
                <img alt="" src={educationSvg} />
                &nbsp;&nbsp;Education Assistance
              </div>
            )}
          </div>
        );
      return <div />;
    };

    const renderDraftWidget = (employer) => {
      if (job && job.id && !job.published) {
        return (
          <IsDraftWidget
            titleString="You're viewing a draft of this role."
            editClick={this.handleEditClick}
            approveClick={this.handleApproveClick}
          />
        );
      }
    };

    const ascendFitMarkup = () => {
      if (
        this.props.role === 'JOB_SEEKER' &&
        this.props.matchData &&
        this.props.matchData.matchLevels
      ) {
        return (
          <div>
            <div className="bd-graybackground">
              <OverallMatchWidget
                overallFit={this.props.matchData.overallFit}
              />
            </div>
          </div>
        );
      }
      return <div />;
    };

    const matchData = () => {
      if (
        this.props.role === 'JOB_SEEKER' &&
        this.props.matchData &&
        this.props.matchData.matchLevels
      ) {
        return (
          <div>
            <center>
              <div className="row bd-whitebackground">
                <div className="col-md-6 my-auto">{ascendFitMarkup()}</div>
                <div className="col-md-2 grayborderleft my-auto">
                  <div className="align-self-center">
                    <div className="secondary-caption bold extraspace">
                      Skills Match
                    </div>
                    <hr className="black-bar" />
                    <div className="normal-headline bold">
                      {this.props.matchData.matchLevels[0]}%
                    </div>
                  </div>
                </div>
                <div className="col-md-2 grayborderleft my-auto">
                  <div className="align-self-center">
                    <div className="secondary-caption bold extraspace">
                      Work Style
                    </div>
                    <hr className="primary-bar" />
                    <div className="active-headline bold">
                      {this.props.matchData.matchLevels[1]}%
                    </div>
                  </div>
                </div>
                <div className="col-md-2 grayborderleft my-auto">
                  <div className="align-self-center">
                    <div className="secondary-caption bold extraspace">
                      Network Fit
                    </div>
                    <hr className="green-bar" />
                    <div className="accent-1-headline bold">
                      {this.props.matchData.matchLevels[2]}%
                    </div>
                  </div>
                </div>
              </div>
            </center>
            <br />
          </div>
        );
      }
    };

    const shouldDisplaySpotlight = (job) => {
      return job.showcaseName && job.showcaseInfo && job.showcaseTitle;
    };

    const showDeadline = (job) => {
      if (job.applicationDeadline) {
        var dateString = format(
          new Date(job.applicationDeadline),
          'MM/dd/yyyy'
        );
        return (
          <div className="inlineblock padding-top-eight">
            <div className="bold">Deadline: {dateString}</div>
          </div>
        );
      }
    };

    const showDivider = (job) => {
      if (job.applicationDeadline && this.props.role === 'JOB_SEEKER') {
        return (
          <span style={{color: '--var(--neutral-gray-1)'}}>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          </span>
        );
      } else {
        return <div className="inlineblock padding-top-eight" />;
      }
    };
    const alreadyApplied = (job) => {
      if (
        job.id &&
        this.props.role === 'JOB_SEEKER' &&
        !job.segments.some((x) => x.isApprenticeProgram)
      ) {
        let applied = false;
        // search for job.id in seeker.appliedJobs
        let index = _.findIndex(this.props.seeker.appliedJobs, {id: job.id});
        if (index === -1) {
          applied = false;
        } else {
          applied = true;
        }
        return (
          <div className="inlineblock padding-top-eight custom-control custom-checkbox form-check">
            <input
              type="checkbox"
              id="appliedJobCheckbox"
              checked={applied}
              onChange={() => this.handleAppliedChanged(job.id, applied)}
              className="custom-control-input form-check-input checkbox-formatting"
            />
            <label
              className=" bold custom-control-label form-check-label"
              htmlFor="appliedJobCheckbox"
              style={{color: '--var(--neutral-gray-1)'}}
            >
              I've Applied
            </label>
          </div>
        );
      }
      return <div />;
    };

    const reportIssue = () => {
      return (
        <div className="flex-row">
          <div className="d-flex justify-content-end">
            <button
              className={`${styles.reportIssue_link_page} `}
              onClick={() => this.handleReportIssueClick()}
            >
              <ErrorSvg
                className={`${styles.reportIssue_stroke_black}`}
                width="15px"
              />
              &nbsp;<span className="underline">Report an Issue</span>
            </button>
          </div>
        </div>
      );
    };

    const jobWatch = () => {
      if (this.props.role === 'JOB_SEEKER') {
        return (
          <WatchIcon
            className=""
            whiteHeart={false}
            floatRight={true}
            watched={this.props.seeker.watchedJobs.some((x) => x.id === jobId)}
            onClick={this.handleWatchClicked}
          />
        );
      }
      return null;
    };

    return (
      <DocumentTitle title="Role Profile">
        <div className="wholepage">
          {(this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
            this.props.role === 'ASCEND_RECRUITER') &&
            renderDraftWidget(job)}

          <ReportIssueModal
            job={job}
            seekerId={this.props.seeker.id}
            isOpen={this.state.reportIssueModalIsOpen}
            onClose={() => {
              this.setState({
                reportIssueModalIsOpen: false,
              });
            }}
          />

          <Layout.PageHeader />

          <main id="main" tabIndex="-1">
            <div className="bd-pagebody">
              {isJobAvailable() && (
                <>
                  <div className="bd-graybackground padding-top-thirtytwo padding-bottom-thirtytwo">
                    <div className=" container">
                      <div className="flex-row">
                        <div className="d-flex justify-content-end">
                          {jobWatch()}
                        </div>
                      </div>
                      <div className="text-align-center ">
                        <h1 className="text-align-center profile-header bold">
                          {job.name}
                        </h1>
                        <div className="secondary-subheader clickable">
                          <button
                            className={`accessibleLinkUnderlined inlineblock btn ${styles.btnPadding} `}
                            onClick={() =>
                              this.handleEmployerClicked(job.employerId)
                            }
                          >
                            {job.employer.name.trim()}
                          </button>
                          ,&nbsp;
                          <button
                            className={`accessibleLinkUnderlined inlineblock btn ${styles.btnPadding} `}
                            onClick={() => this.handleTeamClicked(job.teamId)}
                          >
                            {job.team.name.trim()}
                          </button>
                        </div>
                        <br />
                        {!this.props.applied && (
                          <Fragment>
                            <div>
                              <button
                                type="button"
                                style={{textDecoration: 'none'}}
                                className={`clickable btn btn-primary`}
                                onClick={() => this.handleAppLinkClicked(job)}
                              >
                                Apply To This Role!
                              </button>
                            </div>
                          </Fragment>
                        )}

                        <div>
                          {showDeadline(job)}
                          {showDivider(job)}
                          {alreadyApplied(job)}
                          {reportIssue()}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <hr />
                        </div>
                      </div>
                      {matchData()}
                      <div className="row">
                        <div className="col-md-3">
                          <JobPreferences
                            job={job}
                            role={this.props.role}
                            degreeType={this.getDegreeType(job)}
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="row padding-bottom-sixteen">
                            <div className="col-md-12">
                              <h2
                                style={{fontSize: '16px'}}
                                className="normal-subheader padding-bottom-eight bold"
                              >
                                What you will do:
                              </h2>
                              <div className="disabled-subheader">
                                {job.info1}
                              </div>
                            </div>
                          </div>
                          <div className="row padding-bottom-sixteen">
                            <div className="col-md-12">
                              <h2
                                style={{fontSize: '16px'}}
                                className="normal-subheader padding-bottom-eight bold"
                              >
                                What you will learn:
                              </h2>
                              <div className="disabled-subheader">
                                {job.info2}
                              </div>
                            </div>
                          </div>

                          {!job.segments.some((x) => x.isApprenticeProgram) && (
                            <SkillsCard
                              skills={job.skillTags.map((tag) => tag.name)}
                              requiredSkills={job.requiredSkillTags.map(
                                (tag) => tag.name
                              )}
                            />
                          )}

                          <br />
                          {shouldDisplaySpotlight(job) && (
                            <Spotlight
                              avatarUrl={job.showcaseAvatarUrl}
                              headline="Employee Spotlight"
                              name={job.showcaseName}
                              title={job.showcaseTitle}
                              info={job.showcaseInfo}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bd-darkgraybackground padding-top-thirtytwo padding-bottom-thirtytwo">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <h2 className="normal-subheader padding-bottom-sixteen bold">
                            Other Details
                          </h2>
                          <div
                            className="other-details extraspace clickable"
                            onClick={() => this.handleTeamClicked(job.teamId)}
                          >
                            <i className="nc-icon-glyph users_multiple darkgray" />
                            &nbsp;&nbsp;{job.team.name} Team
                          </div>
                          <div className="other-details extraspace">
                            <i className="nc-icon-glyph database darkgray" />
                            &nbsp;&nbsp;
                            {renderItem(
                              job.employmentTypes.map((x) => x.name).join(', ')
                            )}
                          </div>

                          {job.addressLine1 && (
                            <div className="other-details extraspace">
                              <i className="nc-icon-glyph pin darkgray" />
                              &nbsp;&nbsp;
                              {getLocationText(job.remoteStatus)}
                              <a
                                className="underline"
                                href={getGoogleMapUrl(job)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {addressToString(job)}
                              </a>
                            </div>
                          )}
                          <div className="other-details extraspace">
                            <i className="nc-icon-glyph business_money-bag darkgray" />
                            &nbsp;&nbsp;
                            {renderItem(getWageRangeText(job, true))}
                          </div>
                          <LastUpdated
                            svgClassName="svg_color_stroke_darkgray"
                            textClassName="other-details extraspace"
                            publishedDate={job.publishedAt}
                          />
                        </div>
                        <div className="col-md-6"> {renderBenefits()}</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </main>
          <Layout.PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

JobProfilePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  getSeeker: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
  watched: PropTypes.bool,
  applied: PropTypes.bool,
  matchData: PropTypes.object,
  role: PropTypes.string,
  seekerId: PropTypes.string,
  seeker: PropTypes.object,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  degreeTypeRank: PropTypes.array,
  getDegreeTypesRank: PropTypes.func.isRequired,
  degreeTypeRanksLoading: PropTypes.bool,
  permissions: PropTypes.array.isRequired,
  applyToClicked: PropTypes.func,
  getAllApprenticePathways: PropTypes.func.isRequired,
  jobCacheLoading: PropTypes.bool,
  tracking: PropTypes.object,
};

JobProfilePage.defaultProps = {
  watched: false,
  applied: false,
  degreeTypeRank: [],
  seekerId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    jobCache: state.jobs.jobCache,
    role: state.profile.role,
    seekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    degreeTypeRank: state.degreeTypeRank.degreeRank,
    permissions: state.profile.permissions,
    jobCacheLoading: state.jobs.jobCacheLoading,
  };
};

const mapDispatchToProps = {
  ...jobActions,
  ...seekersActions,
  ...degreeTypeActions,
  getAllApprenticePathways,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobProfilePage)
);
