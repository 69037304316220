import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {withRouter} from '../../../utils/commonHooks';
import track from 'react-tracking';

import * as employersActions from 'src/actions/employers';

import {PageHeader, PageFooter} from 'src/layout';
import ReportsTable from 'src/components/ReportsTable';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';

@track({eventName: 'EmployerReportsPage'}, {dispatchOnMount: true})
class EmployerReportsPage extends React.Component {
  state = {
    canViewCandidatePII: false,
  };
  componentDidMount() {
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      this.setState({canViewCandidatePII: resp.data.hasPermission});
    });

    const employerId = this.props.match.params.id;
    this.props.getEmployer(employerId);
    this.props.getEmployerReports(employerId);
  }

  render() {
    const {reports, employer} = this.props;
    const title =
      employer && employer.name ? `${employer.name} Reports` : 'Reports';
    return (
      <DocumentTitle title="Employer Reports">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />
                {reports.length >= 0 && !this.state.canViewCandidatePII && (
                  <h2>You do not have permission to view Candidate PII</h2>
                )}
                {this.state.canViewCandidatePII && (
                  <div className="padding-bottom-sixteen">
                    <ReportsTable title={title} reports={reports} />
                  </div>
                )}
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

EmployerReportsPage.propTypes = {
  match: PropTypes.object.isRequired,
  getEmployer: PropTypes.func.isRequired,
  getEmployerReports: PropTypes.func.isRequired,
  employer: PropTypes.object,
  reports: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    reports: state.employers.reports,
    employer: state.employers.employer,
  };
};

const mapDispatchToProps = {
  ...employersActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerReportsPage)
);
