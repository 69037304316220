import React from 'react';
import PropTypes from 'prop-types';

const EmailPreview = ({applications, recruiterName, baseUrl, selectedDate}) => {
  return (
    <div style={{backgroundColor: '#FFFFFF'}}>
      {applications.map((app, i) => {
        const profileUrl = `${baseUrl}/candidate/${app.seeker.id}`;
        const seeker = app.seeker;
        let graduationMessage = `expected ${seeker.graduationMonth} ${seeker.graduationYear} graduation`;
        let jsDate = Date.parse(
          `${seeker.graduationMonth} ${seeker.graduationYear}`
        );
        if (jsDate < Date.now()) {
          graduationMessage = `${seeker.graduationMonth} ${seeker.graduationYear} graduate`;
        }
        return (
          <p key={i}>
            <span className="bold">
              {app.job.name} {app.job.req}
            </span>
            <br />
            <a href={profileUrl}>{seeker.name}</a>
            &nbsp;– {seeker.institutionName}, {seeker.major} major,{' '}
            {graduationMessage}
            <br />"{seeker.highlightsNotes}"
          </p>
        );
      })}
    </div>
  );
};

EmailPreview.propTypes = {
  applications: PropTypes.array.isRequired,
  recruiterName: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default EmailPreview;
