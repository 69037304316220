import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export const getExperienceLevels = () => {
  return (dispatch, getState) => {
    return dispatch({
      types: [
        types.EXPERIENCE_LEVELS__GET_REQUEST,
        types.EXPERIENCE_LEVELS__GET_SUCCESS,
        types.EXPERIENCE_LEVELS__GET_FAILURE,
      ],
      uri: `${apiUrl}/v2/experience_levels`,
      method: 'GET',
    });
  };
};
