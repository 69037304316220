import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import {Field} from 'react-final-form';
import {connect} from 'react-redux';

import {
  actions as resumeActions,
  getSummaryForForm,
} from 'src/reducers/resumes';

import {ResumeField} from 'src/formFields';
import resumeStatusGif from 'src/assets/images/resume_status.gif';

const ResumeStatusBar = () => (
  <img src={resumeStatusGif} alt="resume status bar" />
);

@track({eventName: 'ModernApprenticeshipResumeUpload'}, {dispatchOnMount: true})
class ResumeUpload extends React.Component {
  state = {
    isUploading: false,
    isUploaded: false,
    uploadFailed: false,
    continueDisplayStyle: 'none',
  };

  componentDidUpdate(prevProps, prevState, snapshot): void {
    const {isUploaded, isUploading, uploadFailed} = this.state;
    const {resumeIsParsing, resumeParsingTimedOut} = this.props;

    if (
      !resumeIsParsing &&
      prevProps.resumeIsParsing !== resumeIsParsing &&
      !isUploading &&
      !uploadFailed &&
      isUploaded
    ) {
      this.props.handleUploadCompleted();
      return;
    }

    const showNextButton =
      uploadFailed &&
      !resumeIsParsing &&
      !isUploading &&
      prevProps.resumeIsParsing !== resumeIsParsing &&
      prevProps.resumeParsingTimedOut !== resumeParsingTimedOut &&
      prevState.uploadFailed !== uploadFailed;
    if (showNextButton) {
      this.setState({
        continueDisplayStyle: 'block',
      });
    }
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  handleUploadError = (error) => {
    this.setState({
      uploadFailed: true,
      isUploading: false,
      isUploaded: false,
      continueDisplayStyle: 'block',
    });
    this.props.handleUploadError(error);
  };

  handleUploadFinished = () => {
    this.setState({
      isUploading: false,
      isUploaded: true,
      uploadFailed: false,
    });
  };

  handleUploadStarted = () => {
    this.setState({
      isUploading: true,
      isUploaded: false,
      uploadFailed: false,
    });
  };

  render() {
    const {resumeIsParsing, resumeParsingTimedOut} = this.props;
    const {isUploaded, isUploading, uploadFailed} = this.state;

    const renderStatus = () => {
      if (isUploading) {
        return (
          <div>
            Uploading resume...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (uploadFailed) {
        return (
          <span>
            Upload failed. Please try a different file. If this error persists,
            continue with this form and email your resume to{' '}
            <a href="mailto:support@ascendindiana.com">
              support@ascendindiana.com
            </a>
            .
          </span>
        );
      } else if (isUploaded && resumeIsParsing) {
        return (
          <div>
            Secure upload in process ...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (isUploaded && resumeParsingTimedOut) {
        return (
          <span>
            Your resume has been uploaded, but we could not gather data from it.
            Refresh this page to try again. If this error persists, you can
            continue with this form to manually enter your details.
          </span>
        );
      }
    };

    return (
      <Fragment>
        <div className="padding-20 bd-graybackground">
          <div className="row">
            <div className="col-md-12 text-align-center">
              <div className="normal-subheader bold padding-bottom-sixteen pt-3">
                If you have a resume prepared, please upload it here. If you do
                not have a resume, you will be supported in creating one should
                you be selected for the program. Resume must be uploaded as a
                PDF and less than 10MB.{' '}
              </div>
              <div style={{margin: 'auto', width: '220px'}}>
                <br />
                <Field
                  name="resume"
                  component={ResumeField}
                  onStart={this.handleUploadStarted}
                  onError={this.handleUploadError}
                  onFinish={this.handleUploadFinished}
                  fileTypes={new RegExp('.pdf$', 'i')}
                  fileTypesAccept="application/pdf"
                  initialValue={this.props.initialValues.resume}
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="normal-subheader bold padding-bottom-sixteen"
                    style={{
                      margin: 'auto',
                      width: '75%',
                      textAlign: 'center',
                    }}
                  >
                    {renderStatus()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ResumeUpload.propTypes = {
  handleUploadCompleted: PropTypes.func.isRequired,
  handleUploadError: PropTypes.func.isRequired,
  handleUploadStarted: PropTypes.func,
  resumeParsingTimedOut: PropTypes.bool.isRequired,
  resumeIsParsing: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    resumeIsParsing: state.resumes.isParsing,
    resumeParsingTimedOut: state.resumes.timedOut,
    resumeSummary: getSummaryForForm(state),
  };
};

const mapDispatchToProps = {
  ...resumeActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeUpload);
