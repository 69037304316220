import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

const colourStyles = {
  multiValueLabel: (styles) => ({
    ...styles,
    backgroundColor: 'rgba(7, 79, 123, 1)',
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'rgba(7, 79, 123, 1)',
    ':hover': {
      backgroundColor: 'rgba(7, 79, 123, 0.75)',
      color: 'white',
    },
  }),
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'var(--neutral-gray-8)' : 'var(--gray-aaa)',
    '&:hover': {
      borderColor: state.isFocused
        ? 'var(--neutral-gray-8)'
        : 'var(--gray-aaa)',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--neutral-gray-2)',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'var(--neutral-gray-1)',
  }),
};

class MultiSelectField extends React.Component {
  handleValuesSelected = (values) => {
    const selectedValues = values.map((s) => ({
      id: s.value,
      name: s.label,
      ...s,
    }));
    this.props.input.onChange(selectedValues);
  };

  render() {
    const buildLabel = (label) => {
      if (this.props.required) {
        return (
          <>
            {label}&nbsp;
            <span className="required-field">*</span>
          </>
        );
      }
      return label;
    };

    const {
      input: {value, name},
      meta,
      metaOverride,
      label,
      options,
      isLoading,
      isDisabled,
    } = this.props;

    const {touched, error, warning} = {...meta, ...metaOverride};

    return (
      <div>
        <span className="secondary-body" id={`multiSelectLabel${name}`}>
          {buildLabel(label)}
        </span>
        <div className="form form-group">
          <Select
            className="clickable normal-subheader"
            value={_.filter(options, (s) =>
              _.some(value, (selected) => selected.id === s.value)
            )}
            onChange={this.handleValuesSelected}
            options={options}
            isMulti
            joinValues
            styles={colourStyles}
            isDisabled={isDisabled}
            isLoading={isLoading}
            placeholder={isLoading ? '' : undefined}
            aria-labelledby={`multiSelectLabel${name} multiSelectLabel${name}-error`}
          />
          {touched &&
            ((error && (
              <div
                id={`multiSelectLabel${name}-error`}
                className="form-input-error"
              >
                {error}
              </div>
            )) ||
              (warning && (
                <div className="form-input-error">{warning}</div>
              )) || <div className="form-input-error">&nbsp;</div>)}
          {!touched && <div className="form-input-error">&nbsp;</div>}
        </div>
      </div>
    );
  }
}

MultiSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  metaOverride: PropTypes.any,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
};

MultiSelectField.defaultProps = {
  isLoading: false,
  isDisabled: false,
  required: false,
};

export default MultiSelectField;
