import * as types from '../actions/ActionTypes';
import _ from 'lodash';

const initialState = {
  applications: [],
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  semesterApplicationCount: 0,
  applicationsLoading: false,
  reports: [],
  stats: {
    applicantCount: 0,
    publishedApplicantCount: 0,
    jobCount: 0,
    uniqueAdvocatedCandidates: 0,
    advocatedCandidates: 0,
    urspApplicantCount: 0,
    urspUniqueAdvocatedCandidates: 0,
    urspAdvocatedCandidates: 0,
    urspJobCount: 0,
    urspPublishedApplicantCount: 0,
    currentYearApplicationCount: 0,
    startDate: null,
  },
};

export default function applications(state = initialState, action) {
  switch (action.type) {
    case types.APPLICATIONS__GET_REQUEST:
      return {
        ...state,
        applications: [],
        applicationsLoading: true,
      };
    case types.APPLICATIONS__GET_SUCCESS:
      return {
        ...state,
        applications: action.response.body.applications,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        semesterApplicationCount: action.response.body.semesterApplicationCount,
        currentYearApplicationCount:
          action.response.body.currentYearApplicationCount,
        applicationsLoading: false,
      };
    case types.APPLICATIONS__GET_STATS_SUCCESS:
      return {...state, stats: action.response.body};
    case types.APPLICATIONS__UPDATE:
      let appId = _.findIndex(
        state.applications,
        (o) => o.id === action.newApplication.id
      );
      let apps = JSON.parse(JSON.stringify(state.applications));
      apps[appId] = action.newApplication;
      return {
        ...state,
        applications: apps,
      };
    case types.APPLICATIONS__GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.response.body.reports,
      };

    default:
      return state;
  }
}
