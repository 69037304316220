import * as types from '../actions/ActionTypes';

const initialState = {};

export default function users(state = initialState, action) {
  switch (action.type) {
    case types.USERS__ACTIVATE_REQUEST:
      return {
        ...state,
        activationSuccess: null,
        activationError: null,
      };
    case types.USERS__ACTIVATE_SUCCESS:
      return {
        ...state,
        activationSuccess: true,
        activationError: null,
      };
    case types.USERS__ACTIVATE_FAILURE:
      return {
        ...state,
        activationError: action.error,
      };
    case types.USERS__UPDATE_TERMS_ACCEPTED:
      return {
        ...state,
        termsAcceptedSuccess: null,
        termsAcceptedError: null,
      };
    case types.USERS__UPDATE_TERMS_ACCEPTED_SUCCESS:
      return {
        ...state,
        termsAccepted: true,
        termsAcceptedSuccess: true,
        termsAcceptedError: null,
      };
    case types.USERS__UPDATE_TERMS_ACCEPTED_FAILURE:
      return {
        ...state,
        termsAccepted: null,
        termsAcceptedSuccess: null,
        termsAcceptedError: action.error,
      };

    case types.USERS__LIST_BY_PERMISSION_REQUEST:
      return {...state, usersLoading: true};
    case types.USERS__LIST_BY_PERMISSION_SUCCESS:
      return {
        ...state,
        usersListByPermission: action.response.body.users,
        usersLoading: false,
      };
    case types.USERS__LIST_BY_PERMISSION_FAILURE:
      return {
        ...state,
        usersLoading: false,
        error: action.error,
      };

    case types.USERS__CONFIRM_CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        confirmChangeEmailSuccess: null,
        confirmChangeEmailError: null,
        confirmChangeEmailErrorMessage: null,
      };
    case types.USERS__CONFIRM_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        confirmChangeEmailSuccess: action.response.body.success ?? true,
        confirmChangeEmailError: !action.response.body.success ?? false,
        confirmChangeEmailErrorMessage: action.response.body.message ?? null,
      };
    case types.USERS__CONFIRM_CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        confirmChangeEmailError: action.error,
      };

    default:
      return state;
  }
}
