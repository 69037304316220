import _ from 'lodash';

// seeker.matches and seeker.invitationMatches must be merged and
// de-duplicated so we can make SimpleJobCards from them.
export const mergeMatches = (seeker) => {
  const invitationMatches = seeker.invitationMatches
    ? seeker.invitationMatches
    : [];
  const results = [];
  _.forEach(invitationMatches, (i) => {
    results.push(i);
  });
  _.forEach(seeker.matches, (i) => {
    if (!_.find(invitationMatches, {jobId: i.jobId})) {
      results.push(i);
    }
  });
  return [...new Map(results.map((item) => [item.jobId, item])).values()]; // Remove duplicates if we have any;
};

export const cleanForUpdate = (seeker) => {
  const illegalFields = [
    'watchedEmployers',
    'teamWatchers',
    'authId',
    'workStyleTags',
    'id',
    'updatedAt',
    'createdAt',
    'crmId',
    'watchedJobs',
    'crmUpdatedAt',
    'watchedTeams',
    'surveyCompletedOn',
    'teamMatches',
    'matches',
    'resumeUrl',
    'resumePdfUrl',
    'resumeUuid',
    'email',
    'invitationMatches',
    'appliedJobs',
    'publishedAt',
    'institution',
    'institutionName',
    'campus',
    'school',
    'degreeType',
    'hometown',
    'referralSource',
    'industryPreference',
    'majors',
    'minors',
    'roleTypes',
    'industries',
    'regions',
    'matchRequestedAt',
    'isActive',
    'referralSource',
    'watchingEmployers',
  ];
  let cleaned = JSON.parse(JSON.stringify(seeker));
  _.forEach(Object.keys(cleaned), (key) => {
    if (key === 'avatarUrl') {
      let avatarUrlValue = cleaned[key];
      if (
        avatarUrlValue === null ||
        avatarUrlValue === '' ||
        avatarUrlValue.includes('None')
      ) {
        delete cleaned[key];
      }
    }

    if (_.includes(illegalFields, key)) {
      delete cleaned[key];
    }
  });
  return cleaned;
};

export const seekerCanPublish = (seeker, addFlashMessage) => {
  if (
    seeker.segments.some((x) => x.isApprenticeProgram) &&
    seeker.onboardedOn !== null
  ) {
    return true;
  }

  if (
    seeker.segments.some((x) => x.isApprenticeProgram) &&
    seeker.onboardedOn === null
  ) {
    addFlashMessage('Candidate has not onboarded yet');
    return false;
  }

  if (seeker.experience) {
    const experienceLength = seeker.experience.length;
    if (experienceLength < 1) {
      addFlashMessage('At least 1 experience entry is required');
      return false;
    }
  }
  return true;
};
