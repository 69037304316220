import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';

import {AscendModal, AscendModalBody} from 'src/layout';
import InviteToApplyJobSelect from 'src/components/InviteToApplyJobSelect';
import {BusyButton} from './BusyButton';
import {
  createInvitation,
  createMultipleInvitations,
} from 'src/services/invitationApi';
import {addFlashMessage} from 'src/actions/flash';
import {updateSeekerInviteList} from 'src/actions/seekers';

import {useLookup} from 'src/utils/commonHooks';
import Cardinator from 'src/pages/teamProfile/Cardinator';
import {getJob} from 'src/actions/jobs';
import * as types from 'src/actions/ActionTypes';
import {track as analyticsTrack} from 'src/utils/analytics';

export default function InviteToApplyModal(props) {
  const [isMultiple, setIsMultiple] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();

  let {
    userId,
    companyRecruiterId,
    preselectedJob,
    preSelectedJobLoading,
    invitationResponse,
    seekerLoading,
  } = useSelector((state) => {
    return {
      userId: state.profile.id,
      companyRecruiterId: state.profile.companyRecruiterId,
      preselectedJob: state.jobs.job,
      preSelectedJobLoading: state.jobs.jobLoading,
      invitationResponse: state.seekers.invitationResponse,
      seekerLoading: state.seekers.seekerLoading,
    };
  });

  const [jobResponse, isJobsLoading] = useLookup({
    initialState: {data: {jobs: []}},
    lookUpUrl: `jobs?recruiterId=${companyRecruiterId}`,
  });

  const jobs = jobResponse.data.jobs;
  const {appliedJobs, invitationMatches} =
    isMultiple && invitationResponse !== undefined
      ? {appliedJobs: [], invitationMatches: []}
      : invitationResponse;
  if (
    props.preselectedJobId !== -1 &&
    !preSelectedJobLoading &&
    props.preselectedJobId !== preselectedJob.id
  ) {
    dispatch(getJob(props.preselectedJobId));
  }

  const [checkedJobs, setCheckedJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);

  useEffect(() => {
    setCheckedJobs([props.preselectedJobId.toString()]);
    setIsMultiple(props.batchActionList.length > 0);
  }, [props.batchActionList.length, props.preselectedJobId]);

  let initialInvites = [];
  if (props.preselectedJobId !== -1) {
    initialInvites = [props.preselectedJobId];
  }
  const [newJobIdInvites, setNewJobIdInvites] = useState(initialInvites);

  const handleChecked = (clickedJob) => {
    const toggleJobId = Object.keys(clickedJob)[0];

    if (checkedJobs.includes(toggleJobId)) {
      setCheckedJobs(checkedJobs.filter((jobId) => jobId !== toggleJobId));
      setNewJobIdInvites(
        newJobIdInvites.filter((jobId) => jobId !== toggleJobId)
      );
      setSelectedJobs(
        selectedJobs.filter((job) => job.id.toString() !== toggleJobId)
      );

      return;
    }

    setCheckedJobs([...checkedJobs, ...Object.keys(clickedJob)]);
    setNewJobIdInvites([...newJobIdInvites, ...Object.keys(clickedJob)]);
    setSelectedJobs([
      ...selectedJobs,
      jobs.filter((job) => job.id.toString() === toggleJobId)[0],
    ]);
  };

  const checkAlreadyApplied = (jobId) => {
    if (!appliedJobs) {
      return false;
    } else if (_.find(appliedJobs, ['id', jobId])) {
      return true;
    } else {
      return false;
    }
  };

  const trackInvitation = (seekerId, job) => {
    analyticsTrack({
      eventName: 'InvitedToApply_CompanyRecruiter',
      seekerId: seekerId,
      jobEmploymentType: job.employmentTypes.map((x) => x.name).join(','),
    });
  };

  const handleSendClicked = async () => {
    if (checkedJobs.length === 0) {
      return;
    }

    const data = {
      userId: userId,
      seekerId: props.seeker.id,
      jobIds: newJobIdInvites,
    };
    try {
      await createInvitation(data);
      dispatch(updateSeekerInviteList([props.seeker], selectedJobs));
      dispatch(addFlashMessage('Invite sent!', 'notification'));

      selectedJobs.forEach((job) => {
        trackInvitation(props.seeker.id, job);
      });
    } catch (error) {
      dispatch(
        addFlashMessage('Error sending invitation, please try again', 'error')
      );
      throw error;
    }
    props.onSendClicked();
  };

  const handleSendMultiple = () => {
    if (checkedJobs.length === 0) {
      return;
    }

    setIsSending(true);

    const invitations = {
      userId: userId,
      seekerIds: props.batchActionList,
      jobIds: newJobIdInvites,
    };
    createMultipleInvitations(invitations).then((x) => {
      dispatch(
        updateSeekerInviteList(
          props.batchActionList.map((x) => ({
            id: x,
            jobsInvitedToApplyFor: [],
          })),
          selectedJobs,
          x.data.duplicateInvitations
        )
      );
      if (x.data.duplicateInvitations.length === 0) {
        let msg = 'Invitations sent!';
        if (invitations.seekerIds.length === 1) {
          msg = 'Invitation sent!';
        }
        dispatch({
          type: types.FLASH_MESSAGE__CREATE,
          text: msg,
          messageType: 'notification',
        });
      }

      invitations.seekerIds.forEach((seekerId) => {
        selectedJobs.forEach((job) => {
          trackInvitation(seekerId, job);
        });
      });

      setIsSending(false);
      props.onSendClicked(x.data.duplicateInvitations);
    });
  };

  const buildSeekerJobCard = (match) => {
    const existingInvite = invitationMatches
      ? invitationMatches.find((invite) => {
          return invite.jobId === match.id;
        })
      : [];

    return (
      <div key={match.id}>
        <InviteToApplyJobSelect
          key={match.id}
          jobId={match.id}
          jobName={match.name}
          location={match.location}
          applied={checkAlreadyApplied(match.id)}
          teamName={match.team.name}
          employerName={match.employer.name}
          onChange={handleChecked}
          invitationDate={existingInvite ? existingInvite.invitedOn : null}
          selected={checkedJobs.includes(match.id.toString())}
          job={match}
        />
      </div>
    );
  };

  const renderPreselectedJob = () => {
    if (preselectedJob?.id) {
      return (
        <div className="padding-bottom-twentyfour">
          {buildSeekerJobCard(preselectedJob)}
        </div>
      );
    }
  };

  if (!isJobsLoading && jobs.length === 0) {
    return (
      <AscendModal isOpen={props.isOpen} onClose={props.onClose}>
        <AscendModalBody hasClose={true}>
          <div className="normal-headline text-align-center">
            Invite to Apply
          </div>
          <div className="secondary-body text-align-center padding-bottom-twentyfour">
            Your teams have no roles.
          </div>
        </AscendModalBody>
      </AscendModal>
    );
  }

  const multipleVerbage =
    isMultiple || props.seeker === null
      ? `${props.batchActionList.length} candidates`
      : `${props.seeker.name}`;

  return (
    <AscendModal isOpen={props.isOpen} onClose={props.onClose}>
      <AscendModalBody hasClose={true}>
        <div className="padding-32">
          <LoadingOverlay
            role="status"
            active={isJobsLoading || seekerLoading}
            spinner={true}
            text="Loading..."
            styles={{
              overlay: (base) => ({
                ...base,
              }),
            }}
          >
            <div className="normal-headline text-align-center">
              Invite to Apply
            </div>
            <div className="secondary-body text-align-center padding-bottom-twentyfour">
              Choose the role(s) you would like to invite {multipleVerbage} to
              apply for.
            </div>

            <div className="padding-bottom-thirtytwo">
              <div className="divider" />
            </div>

            {renderPreselectedJob()}

            <Cardinator
              jobs={jobs}
              showPageControls={false}
              itemsLoading={false}
              cardObject={buildSeekerJobCard}
              checkedJobs={checkedJobs}
            />
          </LoadingOverlay>
          <div className="padding-top-sixteen padding-bottom-sixteen">
            <BusyButton
              onClick={() => {
                isMultiple ? handleSendMultiple() : handleSendClicked();
              }}
              buttonText={
                isMultiple
                  ? `Send ${props.batchActionList.length} Invites`
                  : 'Send Invite'
              }
              busyText={'Sending'}
              style={{width: '210px', height: '40px'}}
              float={'float-right'}
              buttonIcon={'save'}
              alignText={'text-center'}
              disabled={newJobIdInvites.length === 0 || isSending}
            />
            <br />
          </div>
        </div>
      </AscendModalBody>
    </AscendModal>
  );
}

InviteToApplyModal.propTypes = {
  isOpen: PropTypes.bool,
  onSendClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  seeker: PropTypes.object,
  preselectedJobId: PropTypes.number,
  hasPreselectedJob: PropTypes.bool,
  batchActionList: PropTypes.array,
};

InviteToApplyModal.defaultProps = {
  preselectedJobId: -1,
  batchActionList: [],
};
