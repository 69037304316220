import * as types from '../actions/ActionTypes';

const initialState = {
  currentPage: 0,
  itemsPerPage: 25,
  searchTerm: '',
  sortBy: '-createdAt',
  sortAscending: true,
  recruiterId: -2,
};

export default function connectionsDataTable(state = initialState, action) {
  switch (action.type) {
    case types.CONNECTIONS_DT__UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case types.CONNECTIONS_DT__UPDATE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
        currentPage: 0,
      };
    case types.CONNECTIONS_DT__UPDATE_SORT_TERM:
      return {
        ...state,
        sortBy: action.sortBy,
        sortAscending: action.sortAscending,
        currentPage: 0,
      };
    case types.CONNECTIONS_DT__UPDATE_RECRUITER:
      return {
        ...state,
        recruiterId: action.id,
      };
    default:
      return state;
  }
}
