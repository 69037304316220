import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

class RenderHtml extends Component {
  render() {
    const cleanHtml = sanitizeHtml(this.props.htmlString);
    const {className} = this.props;
    return (
      <Fragment>
        <div
          dangerouslySetInnerHTML={{__html: cleanHtml}}
          className={className}
        />
      </Fragment>
    );
  }
}

RenderHtml.propTypes = {
  htmlString: PropTypes.string,
  className: PropTypes.string,
};
RenderHtml.defaultProps = {
  htmlString: '',
  className: '',
};

export default RenderHtml;
