import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import {createReduxHistoryContext} from 'redux-first-history';
import {createBrowserHistory} from 'history';

import createSagaMiddleware from 'redux-saga';
import {rootReducer} from './reducers/index';
import api from './middleware/api';
import {thunk} from 'redux-thunk';
import {cacheEnhancer} from 'redux-cache';
import {persistStore} from 'redux-persist';
import rootSaga from './sagas';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const sagaMiddleware = createSagaMiddleware();

export default function createReduxStore() {
  const {createReduxHistory, routerMiddleware, routerReducer} =
    createReduxHistoryContext({
      history: createBrowserHistory(),
      //other options if needed
    });

  const store = createStore(
    rootReducer(routerReducer),
    composeEnhancer(
      applyMiddleware(routerMiddleware, sagaMiddleware, api, thunk),
      cacheEnhancer()
    )
  );

  const history = createReduxHistory(store);
  // listen and notify Segment of client-side page updates
  let prevPath = null;
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      window.analytics.page();
    }
  });

  const persistor = persistStore(store);
  window.$persistor = persistor;
  sagaMiddleware.run(rootSaga);
  return {store: store, history: history, persistor: persistor};
}
