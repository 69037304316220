import React from 'react';
import PropTypes from 'prop-types';
import RenderHtml from 'src/components/controls/RenderHtml';

export default function CreateConnectionSummary(props) {
  const {connection} = props;

  const seeker = connection.seeker;
  const role = connection.role;

  const fieldTitleClass = `bold ${connection.willingToShareSuccessStory !== null ? "col-5" : "col-4"}`;

  return (
    <>
      <div className="row pb-2">
        <label className={fieldTitleClass}>Job Seeker:</label>
        <span className="col">
          {seeker.name || `${seeker.firstName} ${seeker.lastName}`}
        </span>
      </div>

      <div className="row pb-2">
        <label className={fieldTitleClass}>Company:</label>
        <span className="col">{connection.role.employerName}</span>
      </div>

      <div className="row pb-2">
        <label className={fieldTitleClass}>Role:</label>
        <span className="col">{connection.role.jobName}</span>
      </div>

      {role.employmentType && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Role Type:</label>
          <span className="col">{role.employmentType.label}</span>
        </div>
      )}

      {role.isRemote !== null && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Is Remote:</label>
          <span className="col">{role.isRemote ? 'Yes' : 'No'}</span>
        </div>
      )}

      {role.city && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>City:</label>
          <span className="col">{role.city}</span>
        </div>
      )}

      {role.state && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>State:</label>
          <span className="col">{role.state.label}</span>
        </div>
      )}

      {(role.zipCode || role.jobZipCode) && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>ZIP Code:</label>
          <span className="col">{role.zipCode || role.jobZipCode}</span>
        </div>
      )}

      {connection.startDate && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Start Date:</label>
          <span className="col">
            {connection.startDate.toLocaleDateString()}
          </span>
        </div>
      )}

      {connection.startingSalary && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Starting Salary:</label>
          <span className="col">{connection.startingSalary}</span>
        </div>
      )}

      {connection.homePostalCode && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Seeker Home ZIP Code :</label>
          <span className="col">{connection.homePostalCode}</span>
        </div>
      )}

      {connection.willingToShareSuccessStory !== null && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Willing to Share Success Story:</label>
          <span className="col">
            {connection.willingToShareSuccessStory ? 'Yes' : 'No'}
          </span>
        </div>
      )}

      <div className="row pb-2">
        <label className={fieldTitleClass}>Support:</label>
        <span className="col">
          {connection.roleAttributed.label}
          {connection.roleAttributed.otherText && (
            <>: {connection.roleAttributed.otherText}</>
          )}
        </span>
      </div>

      {connection.seekerNotes && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Seeker Notes:</label>
          <RenderHtml
            className="col"
            htmlString={
              connection.seekerNotes.length > 350
                ? `${connection.seekerNotes.substring(0, 350)}...`
                : connection.seekerNotes
            }
          ></RenderHtml>
        </div>
      )}

      {connection.notes && (
        <div className="row pb-2">
          <label className={fieldTitleClass}>Admin Notes:</label>
          <RenderHtml
            className="col"
            htmlString={
              connection.notes.length > 350
                ? `${connection.notes.substring(0, 350)}...`
                : connection.notes
            }
          ></RenderHtml>
        </div>
      )}

      {!role.zipCode && !role.jobZipCode && (
        <div className="mt-4 pb-2 text-align-center">
          <label className="bold">
            Note: The connection to be created does not include a ZIP code.
          </label>
        </div>
      )}
    </>
  );
}

CreateConnectionSummary.propTypes = {
  connection: PropTypes.object,
};
