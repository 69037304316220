import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default function FormPasswordInput({
  showLabel,
  showReset,
  labelCssClass,
  ...props
}) {
  return (
    <>
      <div className="d-flex justify-content-between align-items-baseline">
        {showLabel && (
          <label
            id={`${props.input.name}-label`}
            htmlFor={props.input.name}
            className={labelCssClass ? labelCssClass : 'secondary-body'}
          >
            {props.label}
          </label>
        )}
        {showReset && (
          <Link
            className="underline"
            style={{fontSize: '14px'}}
            to={'/forgot_your_password'}
          >
            Forgot Your Password?
          </Link>
        )}
      </div>
      <input
        id={props.input.name}
        type="password"
        className="form-control normal-subheader"
        size={36}
        value={props.input.value}
        {...props.input}
        {...props}
      />
      {props.meta.touched &&
        ((props.meta.error && (
          <div className="form-input-error">{props.meta.error}</div>
        )) ||
          (props.meta.warning && (
            <div className="form-input-error">{props.meta.warning}</div>
          )) || <div className="form-input-error">&nbsp;</div>)}
      {!props.meta.touched && <div className="form-input-error">&nbsp;</div>}
    </>
  );
}

FormPasswordInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showReset: PropTypes.bool,
  labelCssClass: PropTypes.string,
};

FormPasswordInput.defaultProps = {
  showLabel: false,
  showReset: false,
};
