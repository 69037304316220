import * as types from '../actions/ActionTypes';
import {CANDIDATE_DISCOVERY} from 'src/pages/candidateDiscovery/candidateDiscoveryGlobals';

const initialState = {
  tags: [],
  filteredSkills: [],
  topTags: [],
  skillTags: [],
  skillTree: [],
  skillTagsLoading: false,
  topSkillTagsLoading: false,
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
};

export default function skillTags(state = initialState, action) {
  switch (action.type) {
    case types.SKILL_TAGS_TREE__GET_REQUEST:
      return {...state, skillTagsLoading: true};
    case types.SKILL_TAGS_TREE__COLLAPSE_ALL:
      return {
        ...state,
        skillTree: state.skillTree.map((category) => {
          return {
            ...category,
            expanded: false,
            categories: category.categories.map((subcategory) => {
              return {
                ...subcategory,
                expanded: false,
              };
            }),
          };
        }),
      };
    case types.SKILL_TAGS_TREE__CATEGORY_TOGGLE:
      return {
        ...state,
        skillTree: state.skillTree.map((category) => {
          if (category.id !== action.categoryId) {
            return category;
          }
          return {
            ...category,
            expanded: !category.expanded,
          };
        }),
      };
    case types.SKILL_TAGS_TREE__SUBCATEGORY_TOGGLE:
      return {
        ...state,
        skillTree: state.skillTree.map((category) => {
          return {
            ...category,
            categories: category.categories.map((subcategory) => {
              if (subcategory.id !== action.subcategoryId) return subcategory;
              return {
                ...subcategory,
                expanded: !subcategory.expanded,
              };
            }),
          };
        }),
      };
    case types.SKILL_TAGS_TREE__GET_SUCCESS:
      return {
        ...state,
        skillTagsLoading: false,
        skillTree: action.response.body.skillTagTree.map((category) => {
          return {
            ...category,
            categories: category.categories.map((subcategory) => {
              return {
                ...subcategory,

                expanded: false,
              };
            }),
            expanded: false,
          };
        }),
      };
    case types.SKILL_TAGS_TREE__GET_FAILURE:
      return {...state, skillTagsLoading: false};
    case types.SKILL_TAGS__GET_REQUEST:
      return {...state, tags: [], skillTagsLoading: true};
    case types.SKILL_TAGS__GET_SUCCESS:
      return {
        ...state,
        tags: action.response.body.skillTags,
        filteredSkills: action.response.body.skillTags,
        skillTagsLoading: false,
      };
    case types.SKILL_TAGS__GET_TOP_REQUEST:
      return {...state, topTags: [], topSkillTagsLoading: true};
    case types.SKILL_TAGS__GET_TOP_SUCCESS:
      return {
        ...state,
        topTags: action.response.body.skillTags,
        topSkillTagsLoading: false,
      };
    case types.SKILL_TAGS__GET_ADVANCED_REQUEST:
      return {
        ...state,
        tags: [],
        skillTagsLoading: true,
      };
    case types.SKILL_TAGS__GET_ADVANCED_SUCCESS:
      return {
        ...state,
        tags: action.response.body.skillTags,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        skillTagsLoading: false,
      };
    case types.SKILL_TAGS__CREATE_SUCCESS:
      return {
        ...state,
        skillTags: [...state.skillTags, action.response.body],
      };
    case types.CANDIDATE_DISCOVERY__FILTER_LIST:
      if (action.filterType !== CANDIDATE_DISCOVERY.skillsFilter) {
        return state;
      }
      if (action.filterValue === '') {
        return {
          ...state,
          filteredSkills: state.tags,
        };
      }
      return {
        ...state,
        filteredSkills: state.tags.filter((tag) =>
          tag.name.toLowerCase().includes(action.filterValue.toLowerCase())
        ),
      };
    case types.CANDIDATE_DISCOVERY__CLEAR_ALL:
      return {...state, filteredSkills: state.tags};
    default:
      return state;
  }
}
