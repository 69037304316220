import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import OneSkill from './OneSkill';
import OneSubcategory from './OneSubcategory';
import OneCategory from './OneCategory';

const buildSkillTree = (skillTags) => {
  // The following line is very important as it makes sure that our
  // hierachichal tree to properly sorted alphabetically at all three levels.
  const skills = _.orderBy(skillTags, ['category', 'subcategory', 'name']);
  const tree = {
    categories: {},
  };

  skills.forEach((skill) => {
    const {categoryId, subcategoryId, category, subcategory} = skill;

    if (!tree.categories[categoryId]) {
      tree.categories[categoryId] = {
        categoryId,
        label: category,
        subcategories: {},
      };
    }

    if (!tree.categories[categoryId].subcategories[subcategoryId]) {
      tree.categories[categoryId].subcategories[subcategoryId] = {
        subcategoryId,
        label: subcategory,
        skills: [],
      };
    }

    tree.categories[categoryId].subcategories[subcategoryId].skills.push(skill);
  });

  return tree;
};

class Categories extends React.Component {
  state = {
    skillTree: [],
    selectedCategoryId: null,
    selectedSubcategoryId: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // This is run the first time the skill tree is generated
    // Autoselect Cross-Functional / General
    if (prevState.skillTree.length === 0) {
      const newSkillTree = buildSkillTree(nextProps.allSkills);
      const crossFunctionalCategory = _.find(newSkillTree.categories, [
        'label',
        'Interpersonal & Data',
      ]);
      if (!crossFunctionalCategory) return null;
      const generalSubcategory = _.find(
        newSkillTree.categories[crossFunctionalCategory.categoryId]
          .subcategories,
        ['label', 'General']
      );
      return {
        skillTree: newSkillTree,
        selectedCategoryId: String(crossFunctionalCategory.categoryId),
        selectedSubcategoryId: generalSubcategory.subcategoryId,
      };
    }
    return null;
  }

  handleCategoryClicked = (categoryId) => {
    const {skillTree, selectedCategoryId} = this.state;
    const subcategories = skillTree.categories[categoryId].subcategories;
    const subcategoryCount = Object.keys(subcategories).length;

    if (selectedCategoryId === categoryId) {
      this.setState({selectedCategoryId: null, selectedSubcategoryId: null});
    } else {
      // If there is a General subcategory, go ahead and select it
      const generalSubcategory = _.find(subcategories, ['label', 'General']);
      if (generalSubcategory) {
        this.setState({
          selectedCategoryId: categoryId,
          selectedSubcategoryId: generalSubcategory.subcategoryId,
        });
      }
      // If there is only one subcategory, select it by default.
      else if (subcategoryCount === 1) {
        // subcategoryId must be Int in order to display as highlighted.
        const subcategoryId = parseInt(Object.keys(subcategories)[0], 10);
        this.setState({
          selectedCategoryId: categoryId,
          selectedSubcategoryId: subcategoryId,
        });
      } else {
        this.setState({
          selectedCategoryId: categoryId,
          selectedSubcategoryId: null,
        });
      }
    }
  };

  handleSubcategoryClicked = (categoryId, subcategoryId) => {
    if (this.state.selectedSubcategoryId === subcategoryId) {
      this.setState({selectedSubcategoryId: null});
    } else {
      this.setState({
        selectedCategoryId: categoryId,
        selectedSubcategoryId: subcategoryId,
      });
    }
  };

  render() {
    const renderSubCategories = (categoryId) => {
      if (!categoryId) return;

      // If the label begins with Principles, we want to appear at the top
      const hasGeneral = (s) => /^General$/.test(s.label);

      const unsortedSubcategories =
        this.state.skillTree.categories[categoryId].subcategories;

      const withGeneral = _.filter(unsortedSubcategories, hasGeneral);
      const withOutGeneral = _.reject(unsortedSubcategories, hasGeneral);
      const subcategories = [...withGeneral, ...withOutGeneral];

      return _.map(subcategories, (subcategory) => {
        return (
          <OneSubcategory
            key={`subCategory${subcategory.subcategoryId}`}
            onClick={() =>
              this.handleSubcategoryClicked(
                categoryId,
                subcategory.subcategoryId
              )
            }
            selected={
              this.state.selectedSubcategoryId === subcategory.subcategoryId
            }
            label={subcategory.label}
          />
        );
      });
    };

    const renderCategories = () => {
      const categories = this.state.skillTree.categories;
      return _.map(categories, (category, categoryId) => {
        const selected = this.state.selectedCategoryId === categoryId;
        return (
          <Fragment key={`catFragment${categoryId}`}>
            <OneCategory
              key={`parent${categoryId}`}
              onClick={() => this.handleCategoryClicked(categoryId)}
              selected={selected}
              label={category.label}
            />
            {selected && renderSubCategories(categoryId)}
          </Fragment>
        );
      });
    };

    const renderSkills = () => {
      const {selectedCategoryId, selectedSubcategoryId, skillTree} = this.state;
      if (!(selectedCategoryId && selectedSubcategoryId)) return;

      const skills =
        skillTree.categories[selectedCategoryId].subcategories[
          selectedSubcategoryId
        ].skills;

      // If the label begins with Principles, we want to appear at the top
      const hasPrinciples = (s) => /^Principles/.test(s.label);

      const withPrinciples = _.filter(skills, hasPrinciples);
      const withOutPrinciples = _.reject(skills, hasPrinciples);
      const sorted = [...withPrinciples, ...withOutPrinciples];

      return sorted.map((skill) => {
        return (
          <OneSkill
            key={skill.value}
            onClick={() => this.props.onChange(skill)}
            skillId={skill.value}
            definition={skill.definition}
            selected={_.some(this.props.selectedSkills, ['value', skill.value])}
            label={skill.label}
          />
        );
      });
    };

    return (
      <div style={{clear: 'both'}} className="normal-body bd-whitebackground">
        <table style={{width: '100%'}} className="bgcolorw">
          <tbody>
            <tr>
              <td
                style={{
                  verticalAlign: 'top',
                  width: '50%',
                }}
              >
                {renderCategories()}
              </td>
              <td
                style={{
                  verticalAlign: 'top',
                  width: '50%',
                  paddingLeft: '30px',
                }}
              >
                {renderSkills()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Categories.propTypes = {
  allSkills: PropTypes.array.isRequired,
  selectedSkills: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Categories;
