import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {withRouter} from '../../../utils/commonHooks';
import {track} from '../../../utils/analytics';
import _ from 'lodash';
import classNames from 'classnames';

import * as employersActions from 'src/actions/employers';

import {PageHeader, PageFooter} from 'src/layout';
import EmailPreview from 'src/pages/admin/candidateElevation/EmailPreview';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';
import {getEmployerCache} from 'src/services/cacheApi';

class CandidateElevationPage extends React.Component {
  _isMounted = false;
  state = {
    selectedDateButton: 'All',
    canViewCandidatePII: false,
  };

  componentDidMount() {
    this._isMounted = true;
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) return;
      this.setState({canViewCandidatePII: resp.data.hasPermission});
    });

    if (this.props.candidateElevationPageLoading) {
      return;
    }

    const employerId = this.props.match.params.id;
    this.props.getEmployerApplications(employerId);

    getEmployerCache(employerId).then((resp) => {
      track({eventName: 'CandidateElevationPage', ...resp.data});
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDateButtonClicked = (name) => {
    this.setState({selectedDateButton: name});
  };

  render() {
    const {applications, recruiterName} = this.props;
    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    const filteredApps = _.filter(applications, (app) => {
      const appDate = new Date(app.createdAt);
      let startDate = new Date();
      switch (this.state.selectedDateButton) {
        case 'All':
          startDate = null;
          break;
        case 'Last Week':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'Last Month':
          startDate.setDate(startDate.getDate() - 30);
          break;
        default:
          startDate = null;
      }

      if (startDate) {
        return appDate >= startDate;
      }
      return true;
    });

    const uniqueSeekers = _.uniqBy(
      filteredApps.map((app) => app.seeker),
      'id'
    );

    return (
      <>
        <DocumentTitle title="Candidate Elevation"></DocumentTitle>
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />
                <div>
                  <div className="inlineblock normal-subheader bold">
                    Filter by Application Date:&nbsp;&nbsp;
                  </div>
                  <div className="inlineblock btn-group" role="group">
                    {['All', 'Last Week', 'Last Month'].map((name) => {
                      return (
                        <button
                          key={name}
                          type="button"
                          className={classNames(
                            'clickable',
                            'btn',
                            {
                              'btn-secondary':
                                this.state.selectedDateButton !== name,
                            },
                            {
                              'btn-primary':
                                this.state.selectedDateButton === name,
                            }
                          )}
                          onClick={() => this.handleDateButtonClicked(name)}
                        >
                          {name}
                        </button>
                      );
                    })}
                  </div>
                </div>
                <br />
                {filteredApps.length === 0 && (
                  <p>
                    <b>No Applications to Display</b>
                  </p>
                )}
                {filteredApps.length >= 0 &&
                  !this.state.canViewCandidatePII && (
                    <h2>You do not have permission to view Candidate PII</h2>
                  )}
                {filteredApps.length >= 0 && this.state.canViewCandidatePII && (
                  <>
                    <EmailPreview
                      applications={filteredApps}
                      recruiterName={recruiterName}
                      baseUrl={baseUrl}
                      selectedDate={this.state.selectedDateButton}
                    />
                    <div>
                      <span className="normal-subheader">RESUME DOWNLOADS</span>
                      <ul>
                        {uniqueSeekers.map((seeker, i) => {
                          return (
                            <li key={i}>
                              <a
                                href={seeker.resumePdfUrl}
                              >{`${seeker.name.replace(
                                ' ',
                                '_'
                              )}_resume.pdf`}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </>
    );
  }
}

CandidateElevationPage.propTypes = {
  match: PropTypes.object.isRequired,
  getEmployerApplications: PropTypes.func.isRequired,
  applications: PropTypes.array,
  recruiterName: PropTypes.string,
  candidateElevationPageLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    applications: state.employers.applications,
    candidateElevationPageLoading:
      state.employers.candidateElevationPageLoading,
    recruiterName: state.profile.name,
  };
};

const mapDispatchToProps = {
  ...employersActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateElevationPage)
);
