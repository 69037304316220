import * as types from './ActionTypes';
import {makeRequest} from '../services/api';

const apiUrl = process.env.REACT_APP_API_URL;

export function getZipcodes() {
  return {
    types: [
      types.ZIPCODES__GET_ALL_REQUEST,
      types.ZIPCODES__GET_ALL_SUCCESS,
      types.ZIPCODES__GET_ALL_FAILURE,
    ],
    uri: `${apiUrl}/zipcodes`,
    method: 'GET',
  };
}

export function getZipcode(zipcodeId) {
  return {
    types: [
      types.ZIPCODES__GET_REQUEST,
      types.ZIPCODES__GET_SUCCESS,
      types.ZIPCODES__GET_FAILURE,
    ],
    uri: `${apiUrl}/zipcodes/${zipcodeId}`,
    method: 'GET',
    payload: {zipcodeId},
  };
}
export const getZipCodesAsync = () => async (dispatch) => {
  try {
    dispatch({
      type: types.ZIPCODES__GET_REQUEST,
    });

    let response = await makeRequest({method: 'GET', path: 'zipcodes/'});
    if (response.status === 200) {
      dispatch({
        type: types.ZIPCODES__GET_SUCCESS,
        response: {body: response.data},
      });
    }
  } catch (err) {
    dispatch({
      type: types.ZIPCODES__GET_FAILURE,
      payload: err,
    });
  }
};
