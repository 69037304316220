import React from 'react';
import PropTypes from 'prop-types';

import PushButton from '../PushButton';

class PushButtonArray extends React.Component {
  buildButtons = () => {
    const {
      buttonList,
      displayVertical,
      input,
      form,
      meta: {touched, error, warning},
      style,
    } = this.props;

    const setToggle = (buttonProps) => {
      let newValue = [];
      if (this.props.multiSelect) {
        newValue = input.value.filter(
          (x) => x.buttonValue !== buttonProps.buttonValue
        );
      }

      if (buttonProps.isToggled) {
        newValue.push(buttonProps);
      }
      input.onChange(newValue);
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
      if (buttonList[buttonProps.id].buttonAction) {
        buttonList[buttonProps.id].buttonAction(buttonProps, form);
      }
    };

    const buttons = buttonList.map((x, index) => {
      return (
        <PushButton
          id={index.toString()}
          key={index}
          buttonValue={x.buttonValue}
          buttonText={x.buttonText}
          buttonSubText={x.buttonSubText}
          buttonToggledCssClass="push-btn-onboarding-selected"
          action={setToggle}
          isToggled={input.value.some(
            (inputValue) => inputValue.buttonValue === x.buttonValue
          )}
          buttonSvg={x.glyph ? x.glyph : null}
          value={x.value}
        />
      );
    });

    return (
      <div
        className={displayVertical ? 'd-flex flex-column' : 'd-flex flex-row'}
        style={style}
      >
        {buttons}
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    );
  };

  render() {
    return this.buildButtons();
  }
}

PushButtonArray.propTypes = {
  input: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  displayVertical: PropTypes.bool,
  buttonList: PropTypes.array,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

PushButtonArray.defaultProps = {
  displayVertical: false,
  multiSelect: false,
  style: {},
};

export default PushButtonArray;
