import * as types from '../actions/ActionTypes';

const initialState = {
  seeker: {
    canEdit: false,
    seekerId: 0,
  },
  employer: {
    canEdit: false,
    employerId: 0,
  },
  job: {
    canEdit: false,
    jobId: 0,
  },
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case types.SEEKER__GET_CAN_EDIT_REQUEST:
      return {
        ...state,
        seeker: {
          canEdit: false,
          seekerId: 0,
        },
      };
    case types.SEEKER__GET_CAN_EDIT_SUCCESS:
      return {
        ...state,
        seeker: {
          canEdit: action.response.canEdit,
          seekerId: action.response.seekerId,
        },
      };
    case types.EMPLOYER__GET_CAN_EDIT_REQUEST:
      return {
        ...state,
        employer: {
          canEdit: false,
          employerId: 0,
        },
      };
    case types.EMPLOYER__GET_CAN_EDIT_SUCCESS:
      return {
        ...state,
        employer: {
          canEdit: action.response.canEdit,
          employerId: action.response.employerId,
        },
      };

    case types.JOB__GET_CAN_EDIT_REQUEST:
      return {
        ...state,
        job: {
          canEdit: false,
          jobId: 0,
        },
      };
    case types.JOB__GET_CAN_EDIT_SUCCESS:
      return {
        ...state,
        job: {
          canEdit: action.response.canEdit,
          jobId: action.response.jobId,
        },
      };

    default:
      return state;
  }
}
