import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import infoBlueCircleSvg from 'src/assets/images/info-blue-circle.svg';

class TextField extends React.Component {
  render() {
    const buildLabel = (label) => {
      if (this.props.required) {
        return (
          <>
            {label}&nbsp;
            <span className="required-field">*</span>
          </>
        );
      }
      return label;
    };

    const {
      input,
      meta,
      metaOverride,
      label,
      labelActionText,
      labelActionCallBack,
      icon,
      size,
      maxLength,
      disabled,
      visible,
      showBackground,
      labelBold,
      datamultiline,
      dataeffect,
      datatype,
      dataplace,
      datatip,
      showInfoIcon,
      placeholder,
      inputType,
      labelCssClass,
      labelSubText,
      hideBorder,
      ariaLabel,
      autoFocus,
    } = this.props;

    const {touched, error, warning} = {...meta, ...metaOverride};

    const wrappedLabel = () => {
      if (icon) {
        return (
          <div
            id={label
              .replace(/\//g, '')
              .replaceAll(' ', '')
              .replaceAll('/', '')
              .replaceAll('.', '')}
          >
            {icon}&nbsp;&nbsp;{buildLabel(label)}
          </div>
        );
      } else
        return (
          <>
            {buildLabel(label)}
            {labelActionText ? (
              <span
                className={'clickable active-body float-right'}
                style={{margin: '0', padding: '0'}}
                onClick={labelActionCallBack}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    labelActionCallBack();
                  }
                }}
              >
                {labelActionText}
              </span>
            ) : null}

            {showInfoIcon && (
              <Fragment>
                &nbsp;&nbsp;
                <img
                  tabIndex={0}
                  className="clickable"
                  style={{width: '5%'}}
                  alt={datatip.replace('<br/>', '')}
                  src={infoBlueCircleSvg}
                  data-multiline={datamultiline}
                  data-effect={dataeffect}
                  data-type={datatype}
                  data-place={dataplace}
                  data-tip={datatip}
                  data-html={true}
                />
              </Fragment>
            )}
          </>
        );
    };
    const labelCss = !labelCssClass
      ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
      : labelCssClass;

    const renderSubText = () => {
      return {__html: labelSubText};
    };
    return (
      <Fragment>
        {visible && (
          <>
            <div className="form form-group" style={{marginBottom: '0.05rem'}}>
              <label
                id={`${input.name}-label`}
                htmlFor={input.name}
                className={labelCss}
              >
                {wrappedLabel()}
              </label>
              {labelSubText ? (
                <div
                  className="secondary-body mt-n2"
                  dangerouslySetInnerHTML={renderSubText()}
                />
              ) : null}

              <input
                id={input.name}
                autoComplete={this.props.autoComplete}
                {...input}
                className={`form-control normal-subheader ${
                  hideBorder ? 'no-border' : ''
                }
                ${
                  showBackground === true
                    ? 'bd-verylightbluebackground'
                    : 'bd-whitebackground'
                } `}
                disabled={disabled}
                type={inputType}
                size={size}
                maxLength={maxLength}
                placeholder={placeholder}
                value={input.value}
                aria-describedby={`${input.name}-error ${input.name}-label`}
                aria-label={ariaLabel}
                autoFocus={autoFocus}
              />
              {touched &&
                ((error && (
                  <div
                    id={`${input.name}-error`}
                    className="form-input-error"
                    role="alert"
                  >
                    {error}
                  </div>
                )) ||
                  (warning && (
                    <div className="form-input-error">{warning}</div>
                  )) || <div className="form-input-error">&nbsp;</div>)}
              {!touched && <div className="form-input-error">&nbsp;</div>}
            </div>
          </>
        )}
      </Fragment>
    );
  }
}

TextField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  metaOverride: PropTypes.any,
  icon: PropTypes.element,
  label: PropTypes.string,
  labelActionText: PropTypes.string,
  labelActionCallBack: PropTypes.func,
  labelSubText: PropTypes.string,
  size: PropTypes.number,
  maxLength: PropTypes.number,
  visible: PropTypes.bool,
  showBackground: PropTypes.bool,
  labelBold: PropTypes.bool,
  datamultiline: PropTypes.bool,
  dataeffect: PropTypes.string,
  datatype: PropTypes.string,
  dataplace: PropTypes.string,
  datatip: PropTypes.string,
  disabled: PropTypes.bool,
  showTipAsText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  inputType: PropTypes.string,
  labelCssClass: PropTypes.string,
  hideBorder: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
};

TextField.defaultProps = {
  size: 100,
  maxLength: 64,
  visible: true,
  backgroundColor: false,
  inputType: 'text',
  required: false,
  autoComplete: 'off',
  showBackground: false,
  autoFocus: false,
};

export default TextField;
