import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Select from 'react-select';
import _ from 'lodash';
import infoBlueCircleSvg from 'src/assets/images/info-blue-circle.svg';

class renderSelectField extends React.Component {
  findDefault = (value, options, defaultValue) => {
    const dValue = defaultValue ? defaultValue : null;
    return value ? _.find(options, {value}) : dValue;
  };

  findAriaLabel(value, options, defaultValue, label) {
    const selectedValue = this.findDefault(value, options, defaultValue);

    if (selectedValue) {
      return `option field ${label} with a selected value of ${selectedValue.label}`;
    }
    return `option field ${label}`;
  }

  renderMessage() {
    const {
      meta: {touched, error, warning},
    } = this.props;

    if (touched) {
      if (error) return <div className="form-input-error">{error}</div>;
      if (warning) return <div className="form-input-error">{warning}</div>;
      return <div className="form-input-error">&nbsp;</div>;
    }
    return <div className="form-input-error">&nbsp;</div>;
  }

  render() {
    const {
      input,
      title,
      caption,
      options,
      minWidth,
      labelBold,
      padLabel,
      dataplace,
      datatip,
      showInfoIcon,
      isDisabled,
      placeholder,
    } = this.props;

    let labelCssClass = 'secondary-body';
    if (labelBold) {
      labelCssClass = 'normal-body bold';
    }
    if (padLabel) {
      labelCssClass += 'padding-bottom-sixteen';
    }

    return (
      <Fragment>
        <label className={labelCssClass} id={`label${input.name}`}>
          {title} &nbsp;&nbsp;
          {showInfoIcon && (
            <img
              tabIndex={0}
              className="clickable"
              style={{width: '5%'}}
              alt={datatip.replace('<br/>', '')}
              src={infoBlueCircleSvg}
              data-tooltip-id="tooltip"
              data-tooltip-place={dataplace}
              data-tooltip-html={datatip}
            />
          )}{' '}
        </label>
        <div
          style={{
            display: 'inline-block',
            minWidth: minWidth ? minWidth : '100%',
            textAlign: 'left',
          }}
        >
          <Select
            className="clickable normal-subheader"
            value={this.findDefault(input.value, options, null)}
            onChange={(opt) => input.onChange(opt.value)}
            options={options}
            isDisabled={isDisabled}
            placeholder={placeholder}
            aria-labelledby={`label${input.name}`}
          />
        </div>
        <div className="disabled-body">{caption}</div>
        {this.renderMessage()}
      </Fragment>
    );
  }
}

renderSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  caption: PropTypes.string,
  minWidth: PropTypes.string,
  labelBold: PropTypes.bool,
  padLabel: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  disabletips: PropTypes.bool,
  classNameTip: PropTypes.string,
  datamultiline: PropTypes.bool,
  dataeffect: PropTypes.string,
  datatype: PropTypes.string,
  dataplace: PropTypes.string,
  datatip: PropTypes.string,
  showTipAsText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
renderSelectField.defaultProps = {
  labelBold: true,
  padLabel: true,
  showInfoIcon: false,
  placeholder: '',
};

export default renderSelectField;
