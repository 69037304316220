import React, {Children} from 'react';
import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';
import EnvironmentBanner from '../EnvironmentBanner';

class DocumentTitle extends React.Component {
  render() {
    return (
      <>
        {process.env.REACT_APP_ENVIRONMENT_NAME !== 'Production' && (
          <EnvironmentBanner />
        )}
        {this.props.children && Children.only(this.props.children)}
      </>
    );
  }
}

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
};

function reducePropsToState(propsList) {
  let innermostProps = propsList[propsList.length - 1];
  if (innermostProps) {
    return innermostProps.title;
  }
}

function handleStateChangeOnClient(title) {
  document.title = title || '';
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(DocumentTitle);
