import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';

import * as Layout from '../layout';
import {TextAreaField} from 'src/formFields';
import {BusyButton} from './BusyButton';

class SummaryModal extends React.Component {
  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const {isOpen, onClose} = this.props;
    if (this.props.initialValues) {
      return (
        <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
          <Layout.AscendModalBody hasClose={true}>
            <Form
              onSubmit={this.props.onSubmit}
              keepDirtyOnReinitialize={true}
              initialValues={{summary: this.props.initialValues.summary}}
            >
              {({handleSubmit}) => {
                return (
                  <form onKeyPress={this.onKeyPress}>
                    <div className="padding-32">
                      <center>
                        <div className="normal-title padding-bottom-eight bold">
                          <h1>Edit Personal Summary</h1>
                        </div>
                      </center>
                      <hr />
                      <div className="disabled-body padding-bottom-sixteen">
                        Personal summaries that stand out follow this structure:
                        (1) sentence that shares who you are – i.e. school, grad
                        year, major. (2) sentence that describes the type of
                        work you’d like to do and how you might add value to an
                        employer. (3) sentence that speaks to who you are as an
                        individual – i.e. what you are passionate/excited about.
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label
                            htmlFor="summaryText"
                            className="secondary-body"
                          >
                            Personal Summary
                          </label>
                          <Field
                            id="summaryText"
                            name="summary"
                            component={TextAreaField}
                            label=""
                            maxLength={500}
                            showCounter={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-align-center nowrap">
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-transparent me-4"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <BusyButton
                          onClick={handleSubmit}
                          buttonText={'Save'}
                          busyText={'Saving'}
                          style={{width: '210px', height: '40px'}}
                          float={'float-right'}
                          buttonIcon={'save'}
                          alignText={'text-center'}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </Layout.AscendModalBody>
        </Layout.AscendModal>
      );
    } else {
      return <div />;
    }
  }
}

SummaryModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
};

const actions = {};

let EditSummaryModal = (props) => <SummaryModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  return {
    initialValues: state.seekers.seekerForEdit,
  };
};

EditSummaryModal = connect(editMapStateToProps, actions)(EditSummaryModal);

export {EditSummaryModal};
