import React, {useState} from 'react';
import styles from './FilterPanel.module.scss';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import InputSearch from 'src/components/controls/inputs/InputSearch';
import * as SVG from 'src/assets/svg/svgComponents';
import {CANDIDATE_SEARCH_FIELDS} from 'src/utils/fieldHelper';
import {CandidateAdminTabNames} from 'src/utils/CandidateAdminTabs';
import {BusyButton} from 'src/components/BusyButton';
import {MultiSelect} from 'react-multi-select-component';
import {saveCandidateAdvancedIndividualFilters} from 'src/actions/seekers';

export default function DropdownFilterPanel({
  filterButtonAction,
  handleClearAll,
  currentTabId,
  searchHelperText,
  filterDropdowns,
  selectedMultiSelectFilters,
  itemsLoading,
}) {
  const dispatch = useDispatch();

  const [unappliedFilters, setUnappliedFilters] = useState(false);

  let {candidateFilters} = useSelector((state) => {
    return {
      candidateFilters: state.candidateFilters,
    };
  });

  const filters = () => {
    return candidateFilters[CandidateAdminTabNames[currentTabId]];
  };

  const handleEnterKeyPress = (searchBoxText) => {
    var filters = [];
    filters.push('searchTerm=' + searchBoxText);
    filters.push('searchFields=' + CANDIDATE_SEARCH_FIELDS);
    filterButtonAction({
      searchTerm: searchBoxText,
      searchFilters: filters,
      searchUpdated: true,
    });
  };

  const handleClear = () => {
    handleClearAll();
  };

  const buildSearch = (inputVal) => {
    return (
      <InputSearch
        style={{backgroundColor: 'var(--neutral-gray-5)'}}
        onEnterKey={handleEnterKeyPress}
        placeHolder={searchHelperText}
        value={inputVal}
        disabled={itemsLoading}
      />
    );
  };

  const handleSubmit = () => {
    filterButtonAction({});
    setUnappliedFilters(false);
  };

  const handleFilterChange = (changedFilter) => {
    dispatch(
      saveCandidateAdvancedIndividualFilters(currentTabId, changedFilter)
    );

    setUnappliedFilters(true);
  };

  return (
    <div style={{background: '#fff'}}>
      <div className={styles.content_container}>
        <div className={`${styles.aside_1} border-end`}>
          {filters() && buildSearch(filters().searchTerm)}
        </div>
        <div className={`${styles.main} ${styles.dropdown_text}`}>
          <div
            className={styles.quickFilters}
            style={{width: '100%', flexWrap: 'wrap'}}
          >
            {filterDropdowns.map((dropDownComponent, index) => {
              return (
                <div
                  key={index}
                  className={
                    itemsLoading
                      ? `${dropDownComponent.divClassName} ${styles.disabledFilterContainer}`
                      : `${dropDownComponent.divClassName}`
                  }
                >
                  <label
                    id={'filter' + index}
                    className="normal-body mb-2"
                    style={{fontWeight: 'bold'}}
                  >
                    {dropDownComponent.label}
                  </label>
                  <MultiSelect
                    labelledBy={'filter' + index}
                    aria-label={dropDownComponent.label}
                    role="listbox"
                    onChange={(x) =>
                      handleFilterChange({
                        name: dropDownComponent.customProps.name,
                        value: x,
                      })
                    }
                    disabled={itemsLoading}
                    {...dropDownComponent.customProps}
                  />
                </div>
              );
            })}

            <BusyButton
              disabled={!unappliedFilters}
              onClick={() => {
                handleSubmit();
              }}
              busyText={'Searching'}
              buttonText=" Apply Filters"
              className="clickable btn btn-primary col-md-3"
              style={{width: '200px', height: '40px', marginTop: '28px'}}
              float={'float-right'}
              alignText={'text-center'}
            >
              Apply Filters
            </BusyButton>
            <div style={{flexBasis: '100%', height: 0}}></div>
            <div
              className={
                itemsLoading
                  ? `col-md-1.5 ${styles.disabledFilterContainer}`
                  : `col-md-1.5`
              }
              style={{marginLeft: 'auto', paddingTop: '8px'}}
            >
              {selectedMultiSelectFilters.length !== 0 && (
                <span disabled className={`${styles.advancedFilterNotice}`}>
                  <SVG.FilterSvg
                    alt=""
                    className="svg_color_fill_orange"
                    style={{marginTop: '-2px'}}
                  ></SVG.FilterSvg>{' '}
                  {selectedMultiSelectFilters.length} filters applied
                </span>
              )}
              <button
                className={`${styles.clearAllFiltersButton}`}
                onClick={handleClear}
                disabled={itemsLoading}
              >
                <SVG.ResetSvg height="15px" width="15px" />
                <span>Clear All Filters</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DropdownFilterPanel.propTypes = {
  filterButtons: PropTypes.array,
  filterButtonAction: PropTypes.func.isRequired,
  searchHelperText: PropTypes.string,
  handleClearAll: PropTypes.func,
  currentTabId: PropTypes.string,
  filterDropdowns: PropTypes.array,
  selectedMultiSelectFilters: PropTypes.array,
  itemsLoading: PropTypes.bool,
};

DropdownFilterPanel.defaultProps = {
  searchHelperText: 'Search by name',
  itemsLoading: true,
};
