import React from 'react';
import LogoWithName from '../components/LogoWithName';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styles from './MobileNav.module.scss';
import MobileNavBar from './MobileNavBar';

class PageFooter extends React.Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer>
        <div className="bd-pagefooter" style={this.props.footerStyleOverride}>
          <div
            className={
              this.props.footerClassOverride
                ? this.props.footerClassOverride
                : 'container'
            }
          >
            <br />
            <div className="row">
              <div className="col-md-4">
                <LogoWithName />
              </div>
              <div className="col-md-4 align-self-center">
                <div className="normal-caption-white text-align-center">
                  © {year} Ascend. All rights reserved.
                </div>
              </div>
              <div className="col-md-4 align-self-center ">
                <a
                  style={{textDecoration: 'none'}}
                  href="https://ascendindiana.typeform.com/to/QSEPCr"
                  target="_blank"
                  rel="noreferrer"
                  className="normal-subheader-white float-right"
                >
                  Feedback Form
                </a>
              </div>
            </div>
          </div>
        </div>

        {this.props.profile.role === 'JOB_SEEKER' && (
          <>
            <div className={`${styles.mobileNavContainer} mobile-only`}></div>
            <MobileNavBar />
          </>
        )}
      </footer>
    );
  }
}

PageFooter.propTypes = {
  footerClassOverride: PropTypes.string,
  footerStyleOverride: PropTypes.object,
  profile: PropTypes.object.isRequired,
};

PageFooter.mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(PageFooter.mapStateToProps)(PageFooter);