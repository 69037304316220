import axios from 'axios';
import * as flashActions from '../actions/flash';
import {store} from '../index';

const apiUrl = process.env.REACT_APP_API_URL;

export const makeRequest = ({
  method,
  path,
  body,
  isAuthenticated = true,
  contentType = '',
}) => {
  const url = `${apiUrl}/${path}`;
  const headers = {};

  if (contentType.length > 0) {
    headers['content-type'] = contentType;
  }

  if (isAuthenticated) {
    headers['Authorization'] = localStorage.getItem('id_token');
  }

  // Add build number to headers to identify web version to api
  if (process.env.REACT_APP_VERSION) {
    headers['X-Ai-App-Version'] = process.env.REACT_APP_VERSION;
  }

  return axios
    .request({
      method,
      url,
      headers,
      data: body,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 409) {
        let msg =
          error.response.data.message ||
          'Update Failed: The version loaded on your screen is out of date with the server. Please refresh the page and resubmit to update this record.';
        store.dispatch(flashActions.addFlashMessage(msg));
        return error.response;
      } else {
        throw error;
      }
    });
};

export const makeRequestAsync = async (
  method,
  path,
  body = '',
  isAuthenticated = true
) => {
  return makeRequest({
    method: method,
    path: path,
    body: body,
    isAuthenticated: isAuthenticated,
    contentType: 'application/json',
  });
};
