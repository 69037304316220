import React, {useEffect, useState} from 'react';
import DocumentTitle from 'src/components/vendor/DocumentTitle';
import {PageFooter, PageHeader} from 'src/layout';
import styles from './JobSeekerResources.module.scss';
import PageControl from 'src/components/PageControl';
import mixpanel from 'mixpanel-browser';

function trackResourceClick(url) {
  mixpanel.track('Job Seeker Resource Clicked', {
    url: url,
  });
  return true;
}

export default function JobSeekerResources() {
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);

  const itemsPerPage = 9;

  useEffect(() => {
    let apiLoading = false;

    const fetchResources = async () => {
      apiLoading = true;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_RESOURCES_TOKEN}`,
      };
      const query = JSON.stringify({
        query: `{entries(limit: ${itemsPerPage}, offset: ${offset}, relatedToCategories: [{title: "Job Seeker Resources"}]) {
                    id
                    dateCreated @formatDateTime(format: "Y-m-d")
                    title
                    url
                    calloutText
                    calloutDescription
                    calloutImage
                    ... on resources_download_Entry {
                      calloutImage {
                        title
                        url @transform (width: 750, height: 360)
                      }
                    }
                    ... on resources_post_Entry {
                      calloutImage {
                        title
                        url @transform (width: 750, height: 360)
                      }
                    }
                  },
              total: entryCount(relatedToCategories: [{title: "Job Seeker Resources"}])}`,
      });

      const response = await fetch(process.env.REACT_APP_RESOURCES_URL, {
        method: 'POST',
        headers,
        body: query,
      });
      const entryResp = await response.json();
      setResources(entryResp.data.entries);
      setTotalItems(entryResp.data.total);
      apiLoading = false;
    };

    if (!apiLoading) {
      fetchResources();
    }
  }, [offset]);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    setOffset(page * itemsPerPage);
  };

  return (
    <DocumentTitle title="Job Seeker Resources">
      <div className="wholepage">
        <PageHeader />
        <div className="bd-pagebody">
          <main id="main" tabIndex="-1">
            <div className="container">
              <h3 className={`${styles.page_header}`}>Job Seeker Resources</h3>
              <div className={styles.grid}>
                {resources.map((resource, index) => (
                  <div className={`card ${styles.resource_card}`} key={index}>
                    <a
                      style={{width: '100%'}}
                      href={resource.url}
                      target="_blank"
                      rel="noreferrer"
                      className="resourcesLink"
                      onClick={() => {
                        return trackResourceClick(resource.url);
                      }}
                    >
                      <img
                        className={`card-img-top ${styles.resource_img}`}
                        src={`https://ascendindiana.com${resource.calloutImage[0].url}`}
                        alt={resource.calloutText}
                        height="360"
                        width="750"
                      />
                    </a>
                    <div className={`card-body ${styles.resource_card_body}`}>
                      <a
                        href={resource.url}
                        className="underline resourcesLink"
                        target="_blank"
                        rel="noreferrer"
                        style={{color: '#373839'}}
                        onClick={() => {
                          return trackResourceClick(resource.url);
                        }}
                      >
                        <h5
                          className={`card-title ${styles.resource_card_title}`}
                        >
                          {resource.title}
                        </h5>
                      </a>
                      <p className={`card-text ${styles.resource_card_text}`}>
                        {resource.calloutDescription}
                      </p>
                      <a
                        href={resource.url}
                        className="btn btn-primary resourcesLink"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          return trackResourceClick(resource.url);
                        }}
                      >
                        {resource.calloutText}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="float-right padding-bottom-eight">
                <PageControl
                  pageIndex={currentPage}
                  pageCount={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </main>
        </div>
        <PageFooter />
      </div>
    </DocumentTitle>
  );
}
