import React from 'react';
import PropTypes from 'prop-types';
import starBlueSvg from '../../assets/images/StarBlue.svg';

class SkillsCard extends React.Component {
  render() {
    const renderSkill = (skill) => {
      return (
        <li key={skill}>
          <label className="starlabel normal-subheader">
            <div className="verticallyCenter skillStarContainer">
              <img className="skillstar" alt="" src={starBlueSvg} />
            </div>
            <div className="verticallyCenter">{skill}</div>
          </label>
        </li>
      );
    };

    const renderSkills = (skills) => {
      return (
        <ol className="column-list">
          {skills.map((skill) => {
            return renderSkill(skill);
          })}
        </ol>
      );
    };

    return (
      <>
        <div className="bd-graybackground padding-bottom-sixteen">
          <h2
            style={{fontSize: '16px'}}
            className="normal-subheader padding-bottom-eight bold"
          >
            Required Skills
          </h2>
          {this.props.requiredSkills.length > 0 &&
            renderSkills(this.props.requiredSkills)}
          {this.props.requiredSkills.length === 0 && (
            <div className="disabled-subheader">
              This role has no required skills.
            </div>
          )}
        </div>
        <div className="bd-graybackground padding-bottom-sixteen">
          <h2
            style={{fontSize: '16px'}}
            className="normal-subheader padding-bottom-eight bold"
          >
            Preferred Skills
          </h2>
          {this.props.skills.length > 0 && renderSkills(this.props.skills)}

          {this.props.skills.length === 0 && (
            <div className="disabled-subheader">
              This role has no preferred skills.
            </div>
          )}
        </div>
      </>
    );
  }
}

SkillsCard.propTypes = {
  skills: PropTypes.array.isRequired,
  requiredSkills: PropTypes.array.isRequired,
};

export default SkillsCard;
