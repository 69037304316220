import {makeRequestAsync} from 'src/services/api';

export const highSchoolListAction = async (segmentId) => {
  let path = `v2/highSchools/${segmentId}`;

  try {
    let response = await makeRequestAsync('GET', path, {});
    if (response.status === 200) {
      if (response.data.highSchools) {
        const list = response.data.highSchools.map((item) => ({
          value: item.name,
          name: item.name,
          other: item.showOtherTextBox,
          id: item.id,
        }));
        return list;
      }
      return null;
    }
  } catch (err) {
    return err;
  }
};

export const allHighSchoolListAction = async () => {
  let path = `v2/highSchools/all`;

  try {
    let response = await makeRequestAsync('GET', path, {});
    if (response.status === 200) {
      if (response.data.highSchools) {
        const list = response.data.highSchools.map((item) => ({
          value: item.name,
          name: item.showOtherTextBox
            ? `${item.name} (${item.segmentName})`
            : item.name,
          other: item.showOtherTextBox,
          id: item.id,
        }));
        return list;
      }
      return null;
    }
  } catch (err) {
    return err;
  }
};
