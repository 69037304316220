import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';

import * as skillTagsActions from '../../actions/skillTags';

import Categories from './Categories';

const secondary2Rgb = '48, 97, 169';
const colourStyles = {
  multiValueLabel: (styles) => ({
    ...styles,
    backgroundColor: `rgba(${secondary2Rgb}, 1)`,
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    backgroundColor: `rgba(${secondary2Rgb}, 1)`,
    ':hover': {
      backgroundColor: `rgba(${secondary2Rgb}, 0.75)`,
      color: 'white',
    },
  }),
};

class SkillsWidget extends React.Component {
  state = {
    showCategories: true,
    selectedSkills: this.props.selectedSkills,
  };

  componentDidMount = () => {
    if (!this.props.skillTags.length) {
      this.props.getAllSkillTags();
    }
  };

  handleSkillClick = (skill) => {
    const {selectedSkills} = this.state;
    let newSelectedSkills = {};
    if (_.some(selectedSkills, (s) => s.id === skill.value)) {
      // Skill exists and needs to be removed
      newSelectedSkills = _.reject(selectedSkills, (s) => s.id === skill.value);
    } else {
      // Skill does not exist and needs to be added
      newSelectedSkills = [
        ...selectedSkills,
        {id: skill.value, name: skill.label},
      ];
    }
    this.setState({selectedSkills: newSelectedSkills});
    this.props.onChange(newSelectedSkills);
  };

  handleSkillsSelected = (skills) => {
    const selectedSkills = skills.map((s) => ({id: s.value, name: s.label}));
    this.setState({selectedSkills});
    this.props.onChange(selectedSkills);
  };

  handleShowCategories = () => {
    this.setState({showCategories: true});
  };

  handleHideCategories = () => {
    this.setState({showCategories: false});
  };

  convertSkillsToOptions = (skillTags) => {
    // The skills coming from the API need to be converted to the format that
    // react-select expects. Also, these should be sorted by name/label in the
    // dropdown.
    const sortedSkillTags = _.orderBy(skillTags, 'name', 'asc');
    const allSkills = sortedSkillTags.map((tag) => {
      return {
        value: tag.id,
        label: tag.name,
        category: tag.category,
        categoryId: tag.skillCategoryId,
        subcategory: tag.subcategory,
        subcategoryId: tag.skillSubcategoryId,
        definition: tag.definition,
      };
    });
    return allSkills;
  };

  render() {
    const {skillTags, skillTagsLoading} = this.props;
    const {selectedSkills} = this.state;
    const allSkills = this.convertSkillsToOptions(skillTags);
    return (
      <div className="row">
        <div className="col-md-12">
          <label
            className="normal-subheader padding-bottom-sixteen"
            id="skillsLabel"
          >
            The skills you select impact the jobs/internships the Ascend Network
            connects you to. <br />
            Maximize your options by taking your time and selecting at least 12
            - 15 skills.
          </label>
          <div className="row">
            <div className="col-md-12">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select
                isMulti
                joinValues
                value={_.filter(allSkills, (s) =>
                  _.some(selectedSkills, (selected) => selected.id === s.value)
                )}
                options={allSkills}
                styles={colourStyles}
                onChange={this.handleSkillsSelected}
                isLoading={skillTagsLoading}
                isClearable={false}
                aria-labelledby="skillsLabel"
              />
            </div>
          </div>
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-md-4">
              <span className="dark-caption">
                Skills Selected: {selectedSkills.length}
              </span>
            </div>
            {!this.state.showCategories && (
              <div className="offset-md-4 col-md-4">
                <button
                  type="button"
                  onClick={this.handleShowCategories}
                  className="btn btn-transparent"
                >
                  View all by Category
                </button>
              </div>
            )}
            {this.state.showCategories && (
              <Fragment>
                <div className="offset-md-4 col-md-4">
                  <button
                    type="button"
                    onClick={this.handleHideCategories}
                    className="btn btn-transparent"
                  >
                    Hide all by Category
                  </button>
                </div>
              </Fragment>
            )}
          </div>
          {this.state.showCategories && (
            <Fragment>
              <div className="row">&nbsp;</div>
              <div className="row">
                <div className="col-md-12">
                  <Categories
                    allSkills={allSkills}
                    selectedSkills={_.filter(allSkills, (s) =>
                      _.some(
                        selectedSkills,
                        (selected) => selected.id === s.value
                      )
                    )}
                    onChange={this.handleSkillClick}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

SkillsWidget.propTypes = {
  getAllSkillTags: PropTypes.func.isRequired,
  skillTags: PropTypes.array,
  skillTagsLoading: PropTypes.bool.isRequired,
  selectedSkills: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    skillTags: state.skillTags.tags,
    skillTagsLoading: state.skillTags.skillTagsLoading,
  };
}

const mapDispatchToProps = {
  ...skillTagsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsWidget);
