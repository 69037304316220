import React, {Fragment} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';

class RichTextEdit extends React.Component {
  static minimimalModules = {
    toolbar: [
      ['bold', 'italic'],
      [{list: 'ordered'}, {list: 'bullet'}],
    ],
  };

  constructor(props) {
    super(props);
    this.state = {text: ''};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.setState({text: value});
  }

  render() {
    const {
      input,
      meta: {touched, error, warning},
      message,
    } = this.props;
    return (
      <Fragment>
        <ReactQuill
          {...input}
          modules={RichTextEdit.minimimalModules}
          onChange={(newValue, delta, source) => {
            if (source === 'user') {
              input.onChange(newValue);
            }
          }}
          onBlur={(range, source, quill) => {
            input.onBlur(quill.getHTML());
          }}
        />
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-message">{message}</div>
            ))}
        {!touched && <div className="form-input-message">{message}</div>}
      </Fragment>
    );
  }
}

RichTextEdit.propTypes = {
  meta: PropTypes.object,
  message: PropTypes.string,
  input: PropTypes.any,
};
export default RichTextEdit;
