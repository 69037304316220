import React from 'react';
import PropTypes from 'prop-types';
import * as Layout from '../../layout';
import styles from './ChangePassword.module.scss';

class ChangeSocialPasswordModal extends React.Component {
  render() {
    return (
      <Layout.AscendModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        centerVertically={true}
        size="m"
      >
        <Layout.AscendModalBody hasClose={true} padClose={false}>
          <div className={`column ${styles.changePasswordContainer}`}>
            <div className={`col-12`}>
              <center>
                <p>
                  Your account is authenticated through Google, which means we
                  don't save your password. Please go to your Google account
                  settings to change your password. Contact{' '}
                  <a
                    href="mailto:support@ascendindiana.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{textDecoration: 'underline'}}
                  >
                    support@ascendindiana.com
                  </a>{' '}
                  if you have questions.
                </p>
              </center>
            </div>
            <div className="row justify-content-center pt-4">
              <button
                type="button"
                className={`clickable btn btn-secondary`}
                onClick={() => this.props.onClose()}
              >
                Return to the Network{' '}
              </button>
            </div>
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

ChangeSocialPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ChangeSocialPasswordModal;
