import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {Link} from 'react-router-dom';
import {withRouter} from '../../../utils/commonHooks';
import 'url-search-params-polyfill';

import * as teamsActions from '../../../actions/teams';
import * as employersActions from '../../../actions/employers';
import * as tableActions from '../../../actions/teamsDataTable';

import {PageHeader, PageFooter} from '../../../layout';
import {CreateTeamForm, EditTeamForm} from '../../../forms/TeamForm';
import StatsTable from './StatsTable';
import AscendDataTable from '../../../components/AscendDataTable';
import {formatDate} from '../../../utils/miscHelper';
import AreYouSureModal from '../../../components/AreYouSureModal';

const searchFields = ['name'];
const searchPlaceholder = 'Name';

class TeamListPage extends React.Component {
  state = {
    formOpen: false,
    areYouSureModalIsOpen: false,
  };

  getParam = (paramname) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramname);
  };

  getTeams = (currentPage, itemsPerPage, searchTerm, sortBy, sortAscending) => {
    let filters = {};
    if (searchTerm !== '') {
      filters.searchTerm = searchTerm.trim();
      filters.searchFields = searchFields;
    }

    const sortOperator = sortAscending ? '%2b' : '-';
    const sortTerm = sortOperator + sortBy;

    const employerId = this.getParam('employerId');
    this.props.getTeamsByEmployerAdvanced(
      employerId,
      currentPage,
      itemsPerPage,
      sortTerm,
      filters,
      true
    );
  };

  getTableState = () => {
    const employerId = this.getParam('employerId');
    if (employerId in this.props.dataTable) {
      return this.props.dataTable[employerId];
    } else {
      return {
        currentPage: 0,
        searchTerm: '',
        sortBy: '',
        sortAscending: true,
      };
    }
  };

  componentDidMount() {
    const employerId = this.getParam('employerId');
    if (employerId in this.props.dataTable) {
      this.props.createTeamsDataTableEmployer(employerId);
    }

    const tableState = this.getTableState();

    this.getTeams(
      tableState.currentPage,
      this.props.itemsPerPage,
      tableState.searchTerm,
      tableState.sortBy,
      tableState.sortAscending
    );
    this.props.getEmployerToCache(employerId);
  }

  searchForId = (teamId) => {
    for (var i = 0; i < this.props.teams.length; i++) {
      if (this.props.teams[i].id === teamId) {
        return this.props.teams[i];
      }
    }
  };

  handleAddTeamClicked = () => {
    this.setState({
      formOpen: true,
      formMode: 'create',
    });
  };

  handleModalClose = () => {
    this.setState({
      formOpen: false,
    });
  };

  handlePageChange = (pageNumber) => {
    const employerId = this.getParam('employerId');
    const tableState = this.getTableState();

    this.props.updateCurrentPage(employerId, pageNumber);
    this.getTeams(
      pageNumber,
      this.props.itemsPerPage,
      tableState.searchTerm,
      tableState.sortBy,
      tableState.sortAscending
    );
  };

  handleFilterChange = (searchTerm, search = true) => {
    const employerId = this.getParam('employerId');
    const tableState = this.getTableState();

    this.props.updateSearchTerm(employerId, searchTerm);
    if (search) {
      this.getTeams(
        0,
        this.props.itemsPerPage,
        searchTerm,
        tableState.sortBy,
        tableState.sortAscending
      );
    }
  };

  handleSortChange = (sortBy, sortAscending) => {
    const employerId = this.getParam('employerId');
    const tableState = this.getTableState();

    this.props.updateSortTerm(employerId, sortBy, sortAscending);
    this.getTeams(
      0,
      this.props.itemsPerPage,
      tableState.searchTerm,
      sortBy,
      sortAscending
    );
  };

  handlerFunction = (operation, teamId) => {
    switch (operation) {
      case 'Delete':
        this.handleDeleteClicked(teamId);
        break;
      case 'Edit':
        this.handleEditClicked(teamId);
        break;
      case 'Roles':
        this.handleJobsClicked(teamId);
        break;
      case 'View':
        this.handleViewClicked(teamId);
        break;
      default:
        this.handleViewClicked(teamId);
        break;
    }
  };

  handleEditClicked = (teamId) => {
    this.props.getTeamForEdit(teamId);
    this.setState({
      formOpen: true,
      formMode: 'edit',
      teamIdToEdit: teamId,
    });
  };

  handleViewClicked = (teamId) => {
    this.props.history.push('/team/' + teamId);
  };

  handleJobsClicked = (teamId) => {
    const employerId = this.getParam('employerId');
    this.props.history.push({
      pathname: '/admin/roles',
      search: '?employerId=' + employerId + '&teamId=' + teamId,
    });
  };

  handleDeleteClicked = (teamId) => {
    this.setState({
      teamIdToDelete: teamId,
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = (teamId) => {
    this.props.deleteTeam(teamId);
    this.setState({
      teamIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClose = () => {
    this.setState({
      teamIdToDelete: undefined,
      areYouSureModalIsOpen: false,
    });
  };

  handleTeamEditSubmit = (team) => {
    this.props.updateTeam(team);
    this.setState({formOpen: false});
  };

  handleTeamCreateSubmit = (team) => {
    const employerId = this.getParam('employerId');
    this.props.createTeam({...team, employerId});
    this.setState({formOpen: false});
  };

  buildName = (team) => {
    return (
      <Link
        className="normal-title"
        style={{textDecoration: 'none'}}
        to={'/team/' + team.id}
      >
        {team.name}
      </Link>
    );
  };

  buildLocation = (team) => {
    return team.location;
  };

  buildDateAdded = (team) => {
    if (team.createdAt) {
      return formatDate(team.createdAt);
    } else {
      return '';
    }
  };

  buildDateModified = (team) => {
    if (team.updatedAt) {
      return formatDate(team.updatedAt);
    } else {
      return '';
    }
  };

  buildSize = (team) => {
    return team.teamSize;
  };

  buildJobCount = (team) => {
    return (
      <div>
        <div className="inlineblock bold">{team.publishedJobCount}</div>
        <div className="inlineblock">&nbsp;/&nbsp;{team.draftJobCount}</div>
      </div>
    );
  };

  buildPublished = (team) => {
    if (team.published === true) return 'yes';
    if (team.published === false) return 'no';
    return 'n/a';
  };

  render() {
    let employer = {};
    const employerId = this.getParam('employerId');
    if (employerId in this.props.employerCache) {
      employer = this.props.employerCache[employerId];
    }

    const tableState = this.getTableState();

    const renderEmployerName = () => {
      if (employer) {
        return employer.name;
      } else return '';
    };

    const tableColumns = [
      {
        displayName: 'NAME',
        content: this.buildName,
        sortBy: 'name',
      },
      {
        displayName: 'LOCATION',
        content: this.buildLocation,
      },
      {
        displayName: 'DATE ADDED',
        content: this.buildDateAdded,
      },
      {
        displayName: 'DATE MODIFIED',
        content: this.buildDateModified,
      },
      {
        displayName: 'SIZE',
        content: this.buildSize,
      },
      {
        displayName: 'ROLES',
        content: this.buildJobCount,
      },
      {
        displayName: 'PUBLISHED',
        content: this.buildPublished,
      },
    ];

    const rowMenuItemList = [
      {
        displayName: 'Edit',
      },
      {
        displayName: 'Roles',
      },
      {
        displayName: 'View',
      },
      {
        displayName: 'Delete',
      },
    ];

    return (
      <DocumentTitle title="Teams Admin">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          {this.state.formMode === 'create' && (
            <CreateTeamForm
              onSubmit={this.handleTeamCreateSubmit}
              isOpen={this.state.formOpen}
              onClose={this.handleModalClose}
            />
          )}

          {this.state.formMode === 'edit' && (
            <EditTeamForm
              id={this.state.teamIdToEdit}
              onSubmit={this.handleTeamEditSubmit}
              onDelete={() =>
                this.handleDeleteConfirmed(this.state.teamIdToEdit)
              }
              isOpen={this.state.formOpen}
              onClose={this.handleModalClose}
            />
          )}

          <AreYouSureModal
            areYouSureText="Are you sure that you want to remove this team?"
            isOpen={this.state.areYouSureModalIsOpen}
            onClose={this.handleAreYouSureClose}
            onYes={() => this.handleDeleteConfirmed(this.state.teamIdToDelete)}
          />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />
                <div className="padding-bottom-sixteen">
                  <StatsTable employer={employer} />
                </div>

                <div>
                  <button
                    className="btn btn-primary float-right"
                    onClick={this.handleAddTeamClicked}
                  >
                    + Add Team
                  </button>
                  <h2
                    className="normal-headline bold padding-bottom-eight"
                    style={{lineHeight: '36px', margin: 'initial'}}
                  >
                    {renderEmployerName()} Teams Admin
                  </h2>

                  <AscendDataTable
                    rowMenuItemList={rowMenuItemList}
                    tableColumns={tableColumns}
                    items={this.props.teams}
                    handlerFunction={this.handlerFunction}
                    currentPage={tableState.currentPage}
                    itemsPerPage={this.props.itemsPerPage}
                    searchTerm={tableState.searchTerm}
                    sortBy={tableState.sortBy}
                    sortAscending={tableState.sortAscending}
                    totalItems={this.props.totalItems}
                    handlePageChange={this.handlePageChange}
                    handleFilterChange={this.handleFilterChange}
                    handleSortChange={this.handleSortChange}
                    searchPlaceholder={searchPlaceholder}
                    itemsLoading={this.props.teamsLoading}
                  />
                </div>
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

TeamListPage.propTypes = {
  history: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  employerCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    employer: PropTypes.object,
  }),
  getTeamsByEmployerAdvanced: PropTypes.func.isRequired,
  getEmployerToCache: PropTypes.func.isRequired,
  getTeamForEdit: PropTypes.func.isRequired,
  createTeam: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  teamsLoading: PropTypes.bool.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateSortTerm: PropTypes.func.isRequired,
  dataTable: PropTypes.object.isRequired,
  createTeamsDataTableEmployer: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
};

const actions = {
  ...teamsActions,
  ...employersActions,
  ...tableActions,
};

function mapStateToProps(state, ownProps) {
  return {
    employerCache: state.employers.employerCache,
    teams: state.teams.teams,
    teamsLoading: state.teams.teamsLoading,
    itemsPerPage: state.teams.itemsPerPage,
    totalItems: state.teams.totalItems,
    dataTable: state.teamsDataTable.byEmployer,
    query: ownProps.location.query,
  };
}

export default withRouter(connect(mapStateToProps, actions)(TeamListPage));
