import {push} from 'redux-first-history';
import {call, put, takeEvery, takeLatest, select} from 'redux-saga/effects';

import {makeRequest} from 'src/services/api';
import {
  actions as authActions,
  selectors as authSelectors,
  types,
} from 'src/reducers/authentication';

// HACK: we might move profile to the auth reducer.
// For now, we need to import profile's types so we can
// dispatch GET user/profile result to profile reducer.
import * as actionTypes from 'src/actions/ActionTypes';
import {getUtmKeys, setUtmKeys} from 'src/utils/utmKeys';
import NetworkAuthenticationService from 'src/services/NetworkAuthenticationService';
import {MAP_URLS} from 'src/containers/AuthorizedComponent';

const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;

const auth = new NetworkAuthenticationService(auth0ClientId, auth0Domain);

function* loadProfile(redirectPath) {
  // Now that we are logged in, check if we have the user's profile.
  // If we don't, we need to load it and set last logged in timestamp.

  // select() lets us read the state. We'll use a selector function from
  // the authentication reducer to filter the state down to isProfileLoaded.
  const isProfileLoaded = yield select(authSelectors.isProfileLoaded);

  if (isProfileLoaded) {
    return;
  }

  // Make an API call and wait on the response.
  try {
    const profileResponse = yield call(makeRequest, {
      method: 'GET',
      path: `users/profile`,
    });
    // `expires_at` checked in the /src/services/NetworkAuthenticationService.js `isAuthenticated()`
    // method. The response here returns the value from the `idToken`,
    // the default response from the NetworkAuthenticationService returns the `accessToken`
    // expiration time, thus we need the idToken from the API here.
    localStorage.setItem('expires_at', profileResponse.data.exp * 1000);

    // Tell the profile reducer we succeeded and to set the profile.
    yield put({
      type: actionTypes.PROFILE__GET_SUCCESS,
      response: profileResponse,
    });

    // Update lastLoginAt by making an API call.
    const userId = profileResponse.data.id;

    yield call(makeRequest, {
      method: 'PATCH',
      path: `users/${userId}`,
      body: {lastLoginAt: new Date()},
    });
    if (redirectPath) {
      yield put(push(redirectPath));
      return;
    }
    yield put({
      type: types.REDIRECT_BY_ROLE,
      profile: profileResponse.data,
    });
  } catch (error) {
    yield put(push('/loginfailed'));
    throw error;
  }
}

// This saga should be used for both AuthenticatedComponent
function* startSession(action) {
  let redirectPath = localStorage.getItem('savedPath');
  if (redirectPath === null && action.path !== null && action.path !== '/') {
    redirectPath = action.path;
  }

  if (auth.isAuthenticated()) {
    localStorage.removeItem('savedPath');
    yield call(loadProfile, redirectPath);
    return;
  }

  // If not returning from a callback, and not authenticated,
  // redirect to the login page
  if (action.path) {
    localStorage.setItem('savedPath', action.path);
    localStorage.setItem('partnerURL', action.path); // Do not remove - Needed for url signup
  }
  if (action.path && MAP_URLS.includes(action.path)) {
    yield call(auth.mapLogin);
    return;
  }
  yield put(push('/login'));
}

function* signupUser(action) {
  if (action.path) {
    localStorage.setItem('savedPath', action.path);
    localStorage.setItem('partnerURL', action.path); // Do not remove - Needed for url signup
  }
  yield call(auth.signup, action.username, action.password);
}

function* loginUser(action) {
  if (action.path) {
    localStorage.setItem('savedPath', action.path);
  }
  yield call(auth.login, action.username, action.password);
}

function* logoutUser() {
  const utmKeys = getUtmKeys();
  localStorage.clear();
  setUtmKeys(utmKeys);
  yield call(auth.logout);
  yield put(authActions.userLoggedOut());
}

const MAP_INTAKE_REDIRECTS = {
  3: '/map/indy',
  4: '/map/uplands',
  5: '/map/evansville',
  6: '/map/hamilton',
};

function* redirectByRole(action) {
  switch (action.profile.role) {
    case 'JOB_SEEKER':
      if (
        action.profile.intakeCompleted === true &&
        action.profile.segmentIds.length > 0
      ) {
        yield put(push('/candidatehome'));
        return;
      }
      let segmentId = -1;
      if (action.profile.segmentIds) {
        segmentId = action.profile.segmentIds[0];
      }
      if (segmentId in MAP_INTAKE_REDIRECTS) {
        yield put(push(MAP_INTAKE_REDIRECTS[segmentId]));
      } else {
        const invitationCode = localStorage.getItem('invitationCode');

        yield put(
          push(`/intake${invitationCode ? `?code=${invitationCode}` : ''}`)
        );
      }
      break;
    case 'COMPANY_RECRUITER':
    case 'COMPANY_RECRUITER_ADMIN':
      yield put(push('/employerhome'));
      break;
    default:
      yield put(push('/admin/employers'));
      return;
  }
}

//
// export watchers for each saga.
//
export const sagas = [
  takeLatest(types.START_SESSION, startSession),
  takeEvery(types.SIGNUP_USER, signupUser),
  takeEvery(types.LOGIN_USER, loginUser),
  takeEvery(types.LOGOUT_USER, logoutUser),
  takeEvery(types.REDIRECT_BY_ROLE, redirectByRole),
];
