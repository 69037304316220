import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';

import {ResumeField} from '../../../formFields';

import resumeStatusGif from '../../../assets/images/resume_status.gif';

import {
  actions as resumeActions,
  getSummaryForIntakeForm,
} from '../../../reducers/resumes';

const ResumeStatusBar = (props) => (
  <img src={resumeStatusGif} alt="resume status bar" />
);

class ResumeUpload extends React.Component {
  state = {
    isUploading: false,
    isUploaded: false,
    uploadFailed: false,
    enableNext: false,
  };

  formRef = React.createRef();

  validate = (values) => {
    const errors = {};
    const requiredFields = ['resume'];
    _.forEach(requiredFields, (field) => {
      if (!values[field] && this.props.isResumeRequired) {
        errors[field] = 'Required';
      }
    });

    return errors;
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const {isUploaded, isUploading, uploadFailed} = this.state;
    const {resumeIsParsing, resumeParsingTimedOut} = this.props;

    if (
      !resumeIsParsing &&
      prevProps.resumeIsParsing !== resumeIsParsing &&
      !isUploading &&
      !uploadFailed &&
      isUploaded
    ) {
      this.formRef?.current?.submit();
    }

    const showNextButton =
      uploadFailed &&
      !resumeIsParsing &&
      !isUploading &&
      prevProps.resumeIsParsing !== resumeIsParsing &&
      prevProps.resumeParsingTimedOut !== resumeParsingTimedOut &&
      prevState.uploadFailed !== uploadFailed;
    if (showNextButton) {
      this.setState({
        enableNext: true,
      });
    }
  };

  handleUploadError = (error) => {
    this.setState({
      uploadFailed: true,
      isUploading: false,
      isUploaded: false,
      continueDisplayStyle: 'block',
    });
  };

  // If a resume has been uploaded, we need to start polling for parsed
  // info.
  handleUploadFinished = ({uuid}) => {
    this.setState({
      isUploading: false,
      isUploaded: true,
      uploadFailed: false,
    });
    this.props.pollGetStatus(uuid);
  };

  handleUploadStarted = () => {
    this.setState({
      isUploading: true,
      isUploaded: false,
      uploadFailed: false,
    });
  };

  render() {
    const {
      next,
      previous,
      resumeIsParsing,
      resumeParsingTimedOut,
      headerImage,
      isResumeRequired,
    } = this.props;
    const {isUploaded, isUploading, uploadFailed} = this.state;
    const readFormValues = () => {
      const val = this.formRef?.current?.getState().values;

      return val;
    };

    const renderStatus = () => {
      if (isUploading) {
        return (
          <div>
            Uploading resume...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (uploadFailed) {
        return (
          <span>
            Upload failed. Please try a different file. If this error persists,
            continue with this form and email your resume to{' '}
            <a
              style={{textDecoration: 'underline'}}
              href="mailto:support@ascendindiana.com"
            >
              support@ascendindiana.com
            </a>
            .
          </span>
        );
      } else if (isUploaded && resumeIsParsing) {
        return (
          <div>
            Secure upload in process ...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (isUploaded && resumeParsingTimedOut) {
        return (
          <span>
            Your resume has been uploaded, but we could not gather data from it.
            Refresh this page to try again. If this error persists, you can
            continue with this form to manually enter your details.
          </span>
        );
      }
    };

    const renderResumeText = () => {
      if (isResumeRequired) {
        return (
          <>
            <p className={styles.resumeUploadText} style={{maxWidth: '700px'}}>
              Let's start by uploading your resume. We'll use this to
              pre-populate your profile. Don't have one? No problem. Check out
              our resources at the bottom of the page.
            </p>
            <p className={styles.resumeUploadText} style={{maxWidth: '700px'}}>
              Is your resume on a different device? Log out and log back in from
              that device to continue.
            </p>
          </>
        );
      } else {
        return (
          <>
            <p className={styles.resumeUploadText} style={{maxWidth: '700px'}}>
              Let’s start by uploading your resume. We’ll use this to
              pre-populate your profile.
            </p>
            <p className={styles.resumeUploadText} style={{maxWidth: '700px'}}>
              Don’t have one? No problem. Select <b>Continue</b> to skip
              uploading your resume for now.
            </p>
          </>
        );
      }
    };

    return (
      <>
        <PageHeader headerImage={headerImage} />
        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            resume: this.props.intakeCache?.resume,
          }}
        >
          {({handleSubmit, form}) => {
            this.formRef.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <div
                  className={styles.pageContent}
                  style={{paddingTop: '10px'}}
                >
                  {renderResumeText()}
                  <p
                    className={styles.resumeUploadText}
                    style={{maxWidth: '700px'}}
                  >
                    Resumes must be smaller than 10MB.
                  </p>

                  <div className="row">
                    <div className="col-12">
                      <Field
                        name="resume"
                        component={ResumeField}
                        onStart={this.handleUploadStarted}
                        onError={this.handleUploadError}
                        onFinish={this.handleUploadFinished}
                        className=""
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-6 d-flex justify-content-center">
                      {renderStatus()}
                    </div>
                  </div>

                  <div className={styles.resumeTitle}>Resume Resources</div>
                  <div className={styles.resumeTerms}>
                    Try our{' '}
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://ascendnetwork-public-prd.s3.us-east-2.amazonaws.com/generic-assets/Resume_Template.docx"
                    >
                      resume template
                    </a>{' '}
                    and review our{' '}
                    <a
                      className="underline"
                      href="https://ascendindiana.com/resources/advice-for-creating-your-resume"
                      rel="noreferrer"
                      target="_blank"
                    >
                      resume guide.
                    </a>
                    <br /> Students should visit their career services office,
                    while all others can receive free resume support from&nbsp;
                    <a
                      className="underline"
                      href="https://workoneindy.com/contact/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      WorkOne Indy
                    </a>
                    .
                  </div>
                </div>
                <PageFooter
                  next={() => this.formRef?.current?.submit()}
                  previous={previous}
                  getValues={readFormValues}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
ResumeUpload.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  pollGetStatus: PropTypes.func.isRequired,
  resumeIsParsing: PropTypes.bool.isRequired,
  resumeParsingTimedOut: PropTypes.bool.isRequired,
  resumeSummary: PropTypes.object,
  intakeCache: PropTypes.any,
  headerImage: PropTypes.any,
  isResumeRequired: PropTypes.bool,
};
const mapStateToProps = (state) => {
  return {
    resumeIsParsing: state.resumes.isParsing,
    resumeParsingTimedOut: state.resumes.timedOut,
    initialValues: {
      ...getSummaryForIntakeForm(state.resumes),
    },
  };
};
const mapDispatchToProps = {
  ...resumeActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeUpload);
