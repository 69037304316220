import React, {createRef} from 'react';
import PropTypes from 'prop-types';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import styles from './NotesModal.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import inviteSvg from '../assets/images/invite.svg';
import Avatar from './Avatar';
import {addNote, deleteNote, editNote, getNotes} from 'src/actions/seekers';
import RenderHtml from './controls/RenderHtml';
import {formatDate} from 'src/utils/miscHelper';
import InputSearch from './controls/inputs/InputSearch';
import AreYouSureModal from './AreYouSureModal';
import {connect} from 'react-redux';

class NotesModal extends React.Component {
  _isMounted = false;
  state = {
    notes: [],
    text: '',
    searchTerm: '',
    confirmModalOpen: false,
    noteForDeletion: -1,
    loadingNotes: false,
  };

  notesBodyRef = createRef();

  componentDidMount() {
    this._isMounted = true;
    if (this.props.seeker.id !== null) {
      this.handleGetNotes();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.seeker.id !== prevProps.seeker.id &&
      this.props.seeker.id !== null &&
      !this.state.loadingNotes
    ) {
      this.handleGetNotes();
    }
  }

  canEditOrDelete(noteUser) {
    return noteUser.id === this.props.userId;
  }

  handleChange(value) {
    this.setState({text: value});
  }

  handleEditChange(value, id) {
    const updatedNotes = [...this.state.notes];
    updatedNotes.find((x) => x.id === id).text = value;

    this.setState({
      notes: updatedNotes,
    });
  }

  handleGetNotes = async () => {
    this.setState({loadingNotes: true});
    const notes = await getNotes(this.props.seeker.id);
    this.setState({notes, loadingNotes: false});
  };

  addNote = async () => {
    const newNote = await addNote(this.props.seeker.id, {
      text: this.state.text,
    });
    this.setState({notes: [...this.state.notes, newNote]});
    this.setState({text: ''});
    if (this.props.onNoteAdded) {
      this.props.onNoteAdded({seekerId: this.props.seeker.id});
    }
    this.notesBodyRef.current.scrollTo(0, 0);
  };

  editNote = async (note) => {
    await editNote(note.id, {
      text: note.text,
    });
    const updatedNotes = [...this.state.notes];
    updatedNotes.find((x) => x.id === note.id).isEditing = false;

    this.setState({
      notes: updatedNotes,
    });
  };

  handleDeleteNote = async (id) => {
    await deleteNote(id);
    this.setState({
      notes: this.state.notes.filter((x) => x.id !== id),
    });
    if (this.props.onNoteRemoved) {
      this.props.onNoteRemoved({seekerId: this.props.seeker.id});
    }
  };

  toggleEdit = (id, ref) => {
    const updatedNotes = [...this.state.notes];
    if (updatedNotes.find((x) => x.id === id).isEditing) {
      updatedNotes.find((x) => x.id === id).isEditing = false;
    } else {
      ref.current.scrollIntoView({behavior: 'smooth'});
      updatedNotes.find((x) => x.id === id).isEditing = true;
    }

    this.setState({
      notes: updatedNotes,
    });
  };

  searchNotes = (event) => {
    this.setState({searchTerm: event});
  };

  customButton = () => (
    <div>
      <img height="28px" width="24px" src={inviteSvg} alt="add note" />
    </div>
  );

  addToolbar = () => (
    <div id="addToolbar">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>

      <button className="ql-addNote" style={{float: 'right'}}>
        {this.customButton()}
      </button>
    </div>
  );

  editToolbar = (note) => (
    <div id="editToolbar">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>

      <button
        className="ql-addNote"
        onClick={() => this.editNote(note)}
        style={{float: 'right'}}
      >
        {this.customButton()}
      </button>
    </div>
  );

  addModules = {
    keyboard: {bindings: {tab: false}},
    toolbar: {
      container: '#addToolbar',
      handlers: {
        addNote: this.addNote,
      },
    },
  };

  editModules = {
    keyboard: {bindings: {tab: false}},
    toolbar: {
      container: '#editToolbar',
      handlers: {
        editNote: this.editNote,
      },
    },
  };

  formats = ['bold', 'italic', 'list', 'bullet'];

  render() {
    const notes = this.state.notes
      .filter((note) => {
        return note.text
          .toLowerCase()
          .includes(this.state.searchTerm.toLowerCase());
      })
      .sort(function compare(a, b) {
        var dateA = new Date(a.createdAt);
        var dateB = new Date(b.createdAt);
        return dateB - dateA;
      })
      .map((note, i) => {
        const ref = createRef();

        return (
          <div className={styles.note} key={i} ref={ref}>
            <div className={styles.innerHeader}>
              <span style={{display: 'flex', flexDirection: 'row'}}>
                <Avatar
                  height={48}
                  width={48}
                  url={note.user.avatarUrl}
                  label="User Profile"
                ></Avatar>
                <span
                  style={{
                    width: '300px',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    marginLeft: '8px',
                  }}
                >
                  <span
                    className="bold"
                    style={{display: 'flex', flexDirection: 'row'}}
                  >
                    &nbsp;{note.user.name}&nbsp;
                  </span>
                  left a note &nbsp;
                  <span className={styles.break}></span>
                  <span>{formatDate(note.createdAt)}</span>
                </span>
              </span>
              {this.canEditOrDelete(note.user) && (
                <span style={{float: 'right', marginTop: '-60px'}}>
                  <button
                    className="clickable btn"
                    aria-label="Edit Note"
                    onClick={() => this.toggleEdit(note.id, ref)}
                  >
                    <i className="nc-icon-glyph-med pencil" />
                  </button>
                  <button
                    className="clickable btn"
                    aria-label="Delete Note"
                    onClick={() =>
                      this.setState({
                        confirmModalOpen: true,
                        noteForDeletion: note.id,
                      })
                    }
                  >
                    <i className="nc-icon-glyph-med trash" />
                  </button>
                </span>
              )}
            </div>
            {note.isEditing ? (
              <div style={{marginTop: '20px', marginBottom: '8px'}}>
                {this.editToolbar(note)}
                <ReactQuill
                  value={note.text}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') {
                      this.handleEditChange(newValue, note.id);
                    }
                  }}
                  modules={this.editModules}
                  formats={this.formats}
                  className={styles.editor}
                  tabIndex={0}
                />
              </div>
            ) : (
              <RenderHtml
                className={styles.innerBody}
                htmlString={note.text}
              ></RenderHtml>
            )}
          </div>
        );
      });

    return (
      <>
        <AreYouSureModal
          areYouSureText="Are you sure you want to delete this note?"
          isOpen={this.state.confirmModalOpen}
          onClose={() => {
            this.setState({confirmModalOpen: false, noteForDeletion: -1});
          }}
          onYes={() => {
            this.handleDeleteNote(this.state.noteForDeletion);
            this.setState({confirmModalOpen: false, noteForDeletion: -1});
          }}
          noText="Cancel"
        />
        <SlidingPane
          isOpen={this.props.isOpen}
          onRequestClose={this.props.onClose}
          from="right"
          width="800px"
          className={styles.notesContainer}
          closeIcon={
            <button
              type="button"
              className="clickable buttonAsLinkGrayBackground"
              onClick={this.props.onClose}
              style={{color: '#6f7275', height: '20px'}}
              title="Close Modal"
            >
              <i className="nc-icon-glyph-med remove" />
            </button>
          }
          title={
            <>
              <Avatar
                height={48}
                width={48}
                url={this.props.seeker.avatarUrl}
                label="Candidate Profile"
              ></Avatar>
              &nbsp;
              <span className={styles.notesHeader}>
                {`${this.props.seeker.firstName} ${this.props.seeker.lastName}`}
                &nbsp;&nbsp;
              </span>
              <span className={styles.actions}>
                <InputSearch
                  onEnterKey={this.searchNotes}
                  placeHolder="Search Notes"
                  value={this.state.searchTerm}
                  className={`${styles.searchbar}`}
                />
              </span>
            </>
          }
        >
          <div className={styles.notesBody} ref={this.notesBodyRef}>
            <div>{notes}</div>
          </div>
          <div className={styles.notesFooter}>
            {this.addToolbar()}
            <ReactQuill
              value={this.state.text}
              onChange={(newValue, delta, source) => {
                if (source === 'user') {
                  this.handleChange(newValue);
                }
              }}
              modules={this.addModules}
              formats={this.formats}
              className={styles.editor}
              tabIndex={0}
            />
          </div>
        </SlidingPane>
      </>
    );
  }
}

NotesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  seeker: PropTypes.object.isRequired,
  onNoteAdded: PropTypes.func,
  onNoteRemoved: PropTypes.func,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userId: state.profile.id,
  };
};

export default connect(mapStateToProps)(NotesModal);
