import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import AreYouSureModal from '../components/AreYouSureModal';
import TextField from './TextField';
import SelectField from './SelectField';
import {useDispatch, useSelector} from 'react-redux';
import {getStates} from 'src/actions/institutions';
import CheckboxField from './CheckboxField';
import styles from './LocationFieldArray.module.scss';

export default function LocationFieldArray({
  meta,
  fields,
  maxFields,
  title = 'Locations',
  required = false,
}) {
  const [areYouSureModalIsOpen, setAreYouSureModalIsOpen] = useState(false);
  const [removeIndex, setRemoveIndex] = useState('');
  const [initialLoaded, setInitialLoaded] = useState(false);
  const dispatch = useDispatch();
  let {states, statesLoading} = useSelector((state) => {
    return {
      states: state.institutions.states,
      statesLoading: state.institutions.statesLoading,
    };
  });

  const handleAddField = useCallback(() => {
    if (fields.length === maxFields) return;
    fields.push({sortOrder: fields.length});
  }, [fields, maxFields]);

  useEffect(() => {
    if (
      !initialLoaded &&
      Array.isArray(meta.initial) &&
      meta.initial.length === 0 &&
      fields.length === 0
    ) {
      handleAddField();
      setInitialLoaded(true);
    }

    if (states.length === 0 && !statesLoading) {
      dispatch(getStates());
    }
  }, [
    dispatch,
    handleAddField,
    initialLoaded,
    meta.initial,
    states.length,
    statesLoading,
    fields.length,
  ]);

  const handleDoRemove = () => {
    fields.remove(removeIndex);
    setRemoveIndex('');
    setAreYouSureModalIsOpen(false);
  };

  const handleRemoveFieldIndex = (index) => {
    setRemoveIndex(index);
    setAreYouSureModalIsOpen(true);
  };

  const handleAreYouSureClose = () => {
    setRemoveIndex('');
    setAreYouSureModalIsOpen(false);
  };

  const handleUpFromIndex = (index) => {
    fields.swap(index, index - 1);
  };

  const handleDownFromIndex = (index) => {
    fields.swap(index, index + 1);
  };

  const buildTitle = (title) => {
    if (required) {
      return (
        <>
          {title}&nbsp;
          <span className="required-field">*</span>
        </>
      );
    }
    return title;
  };

  const isPrimaryLocationSelected = fields.value?.some(
    (location) => location.isPrimaryLocation
  );

  return (
    <div>
      <AreYouSureModal
        areYouSureText="Are you sure that you want to remove this location?"
        isOpen={areYouSureModalIsOpen}
        onClose={handleAreYouSureClose}
        onYes={handleDoRemove}
      />

      <div className={`form form-group ${styles.locationArray}`}>
        <div className="secondary-body padding-bottom-eight">
          {buildTitle(title)}
        </div>
        {fields.length > 0 ? (
          fields.map((location, index) => {
            fields.value[index].sortOrder = index;
            return (
              <div key={index}>
                <div className="bd-whitebackground padding-16">
                  {maxFields !== 1 && (
                    <button
                      className="normal-subheader buttonAsLinkTasks float-right"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveFieldIndex(index);
                      }}
                      tabIndex={0}
                      aria-label="Remove Location"
                    >
                      <i className="nc-icon-glyph-med remove" />
                    </button>
                  )}
                  {index !== fields.length - 1 && maxFields !== 1 && (
                    <button
                      className="normal-subheader float-right buttonAsLinkTasks"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownFromIndex(index);
                      }}
                      tabIndex={0}
                      aria-label="Move Location Down"
                    >
                      <i className="nc-icon-glyph-med down_arrow padding-right-sixteen" />
                    </button>
                  )}
                  {index !== 0 && maxFields !== 1 && (
                    <button
                      className="normal-subheader float-right buttonAsLinkTasks"
                      onClick={(e) => {
                        e.preventDefault();
                        handleUpFromIndex(index);
                      }}
                      tabIndex={0}
                      aria-label="Move Location Up"
                    >
                      <i className="nc-icon-glyph-med up_arrow padding-right-sixteen" />
                    </button>
                  )}
                  <div className="col-md-12" style={{marginTop: '24px'}}>
                    <Field
                      name={`${location}.addressLine1`}
                      component={TextField}
                      placeholder="Ex: 1100 Fake Address"
                      locationIndex={index}
                      label="Address 1"
                      required={required}
                      ariaLabel={required ? 'Address 1 can not be blank.' : ''}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      name={`${location}.addressLine2`}
                      component={TextField}
                      placeholder="Ex: Suite 222"
                      locationIndex={index}
                      label="Address 2"
                    />
                  </div>
                  <div
                    style={{
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    }}
                    className="row"
                  >
                    <div className="col-md-4">
                      <Field
                        name={`${location}.city`}
                        component={TextField}
                        placeholder="Ex: Indianapolis"
                        locationIndex={index}
                        label="City"
                        required={required}
                        ariaLabel={required ? 'City can not be blank.' : ''}
                      />
                    </div>
                    <div className="col-md-4">
                      <Field
                        name={`${location}.stateId`}
                        component={SelectField}
                        locationIndex={index}
                        label="State"
                        defaultValue={14}
                        required={required}
                        options={states.map((state) => ({
                          value: state.id,
                          label: state.code,
                        }))}
                      />
                    </div>
                    <div className="col-md-4">
                      <Field
                        name={`${location}.postalCode`}
                        component={TextField}
                        placeholder="Ex: 46202"
                        locationIndex={index}
                        label="ZIP Code"
                        maxLength={5}
                        required={required}
                        ariaLabel={
                          required ? 'Postal code can not be blank.' : ''
                        }
                      />
                    </div>
                  </div>
                  {maxFields !== 1 && (
                    <div className="row" style={{paddingLeft: '30px'}}>
                      <Field
                        name={`${location}.isPrimaryLocation`}
                        component={CheckboxField}
                        label="Primary Location?"
                        type="checkbox"
                        disabled={
                          isPrimaryLocationSelected &&
                          !fields.value[index].isPrimaryLocation
                        }
                      />
                    </div>
                  )}
                </div>
                <br />
              </div>
            );
          })
        ) : (
          <div>No locations added.</div>
        )}
        <div>
          {fields.length < maxFields && (
            <button
              type="button"
              className="clickable inlineblock btn-transparent-green"
              onClick={handleAddField}
            >
              + add {fields.length === 0 ? 'a' : 'another'} location
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

LocationFieldArray.propTypes = {
  meta: PropTypes.object,
  fields: PropTypes.object,
  maxFields: PropTypes.number,
  title: PropTypes.string,
  required: PropTypes.bool,
};
