/*
DEPRECATED - DO NOT USE
Leaving in place temporarily to avoid breaking the Web Build.
*/

import merge from 'deepmerge';

import * as types from '../actions/ActionTypes';

const initialState = {
  searchTerm: '',
  sortTerm: '%2bname',
  currentTeamId: 0,
  currentJobId: 0,
  showFilters: false,
  filters: {},
  currentPage: 0,
  currentItemsPerPage: 25,
  candidateFitTags: {
    overallFit: {
      disabled: true,
      selected: false,
    },
    ascendFit: {
      disabled: true,
      selected: false,
    },
    skillsFit: {
      disabled: true,
      selected: false,
    },
    workStyleFit: {
      disabled: true,
      selected: false,
    },
  },
  candidateInterestTags: {
    interested: {
      disabled: true,
      selected: false,
    },
    applied: {
      disabled: true,
      selected: false,
    },
    invited: {
      disabled: true,
      selected: false,
    },
    handpicked: {
      disabled: true,
      selected: false,
    },
  },
  publishedProfileTags: {
    tenDays: {
      selected: false,
    },
    thirtyDays: {
      selected: false,
    },
  },
  roleTypeIds: [],
  employmentTypeIds: [],
  experienceLevelIds: [],
  regionIds: [],
  industryIds: [],
  degreeTypeRankIds: [],
  segmentIds: [],
};

const clearAllState = {
  searchTerm: '',
  sortTerm: '%2bname',
  currentTeamId: 0,
  currentJobId: 0,
  showFilters: false,
  filters: {},
  currentPage: 0,
  currentItemsPerPage: 25,
  candidateFitTags: {
    overallFit: {
      disabled: true,
      selected: false,
    },
    ascendFit: {
      disabled: true,
      selected: false,
    },
    skillsFit: {
      disabled: true,
      selected: false,
    },
    workStyleFit: {
      disabled: true,
      selected: false,
    },
  },
  candidateInterestTags: {
    interested: {
      disabled: true,
      selected: false,
    },
    applied: {
      disabled: true,
      selected: false,
    },
    invited: {
      disabled: true,
      selected: false,
    },
    handpicked: {
      disabled: true,
      selected: false,
    },
  },
  publishedProfileTags: {
    tenDays: {
      selected: false,
    },
    thirtyDays: {
      selected: false,
    },
  },
  roleTypeIds: [],
  employmentTypeIds: [],
  experienceLevelIds: [],
  regionIds: [],
  industryIds: [],
  degreeTypeRankIds: [],
  segmentIds: [],
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export default function candidateSearch(state = initialState, action) {
  switch (action.type) {
    case types.CANDIDATE_SEARCH__CLEAR_ALL:
      const clearStateCopy = JSON.parse(JSON.stringify(clearAllState));
      return clearStateCopy;
    case types.CANDIDATE_SEARCH__UPDATE_STATE:
      return merge(state, action.newState, {arrayMerge: overwriteMerge});

    default:
      return state;
  }
}
