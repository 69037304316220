import React from 'react';
import PropTypes from 'prop-types';
// import nomatches from '../assets/images/NoMatches.svg';
import {Link} from 'react-router-dom';

import styles from './NoMatches.module.scss';
import {HeartFilledWithoutBorderSvg} from 'src/assets/svg/svgComponents';

const NoMatches = () => {
  return (
    <div className={styles.container}>
      <div className={styles.sideBar}>&nbsp;</div>
      <div className={styles.content}>
        <div className={styles.columnOne}>
          <p>To see your job matches you must first publish your profile.</p>
          <Link
            className="btn btn-primary"
            to={`/profile`}
          >
            View your Profile
          </Link>
        </div>
        <div className={styles.columnTwo}>
          <div className={styles.noMatchJobCard}>
            <div className={styles.jobCardRow}>
              <h4 className={styles.jobName}>Job Title</h4>
              <HeartFilledWithoutBorderSvg
                width="18"
                height="18"
                className={styles.heartIcon}
              />
            </div>
            <div className={styles.companyRow}>
              <span className={`nowrap ${styles.companyName}`}>
                Company Name
              </span>
              <div className={styles.noMatchBarChart}>
                <div className={styles.barChartRow}>&nbsp;</div>
                <div className={styles.barChartRow}>&nbsp;</div>
                <div className={styles.barChartRow}>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NoMatches.propTypes = {
  width: PropTypes.string,
  style: PropTypes.object,
};

NoMatches.defaultProps = {
  width: '100%',
  style: {},
};

export default NoMatches;
