import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function updateLastLogin(userId) {
  return {
    types: [
      types.USERS__UPDATE_LAST_LOGIN_REQUEST,
      types.USERS__UPDATE_LAST_LOGIN_SUCCESS,
      types.USERS__UPDATE_LAST_LOGIN_FAILURE,
    ],
    uri: `${apiUrl}/users/${userId}`,
    method: 'PATCH',
    body: {lastLoginAt: new Date()},
  };
}

export function updateTermsAccepted(userId, accepted, acceptedBy) {
  return {
    types: [
      types.USERS__UPDATE_TERMS_ACCEPTED,
      types.USERS__UPDATE_TERMS_ACCEPTED_SUCCESS,
      types.USERS__UPDATE_TERMS_ACCEPTED_FAILURE,
    ],
    uri: `${apiUrl}/users/${userId}`,
    method: 'PATCH',
    body: {termsAccepted: accepted, termsAcceptedBy: acceptedBy},
  };
}

export function activateUser(token, password, changeEmail) {
  return {
    types: [
      types.USERS__ACTIVATE_REQUEST,
      types.USERS__ACTIVATE_SUCCESS,
      types.USERS__ACTIVATE_FAILURE,
    ],
    uri: `${apiUrl}/users/activate`,
    method: 'POST',
    body: {token, password, changeEmail},
    isAuthenticated: false,
  };
}

export function getUsersByPermission(permission) {
  return {
    types: [
      types.USERS__LIST_BY_PERMISSION_REQUEST,
      types.USERS__LIST_BY_PERMISSION_SUCCESS,
      types.USERS__LIST_BY_PERMISSION_FAILURE,
    ],
    uri: `${apiUrl}/users/permission/${permission}`,
    method: 'GET',
    failureMessage: 'Error getting users by permission.',
  };
}

export function confirmChangeEmail(token, newEmail) {
  return {
    types: [
      types.USERS__CONFIRM_CHANGE_EMAIL_REQUEST,
      types.USERS__CONFIRM_CHANGE_EMAIL_SUCCESS,
      types.USERS__CONFIRM_CHANGE_EMAIL_FAILURE,
    ],
    uri: `${apiUrl}/users/confirm_change_email`,
    method: 'POST',
    body: {token, newEmail},
    isAuthenticated: false,
  };
}
