import React, {Fragment, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import style from '../SelectAscend/SelectAscend.module.scss';
import TagList from '../../tags/TagList/TagList';
import {SearchSvg} from '../../../assets/svg/svgComponents';
import SelectAscend from '../SelectAscend/SelectAscend';
import {isEmpty} from '../../../utils/helper';

export default function TagSelector({
  optionsList,
  placeholder,
  isDisabled,
  isLoading,
  selectedTags,
  onTagChangeAction,
  name,
  labelText,
  id,
  input,
  meta: {touched, error, warning},
  isMobile,
}) {
  let key = useRef(false);

  const [visibleTags, setVisibleTags] = useState(optionsList);
  const [selectedTagList, setSelectedTagList] = useState(selectedTags);

  useEffect(() => {
    setSelectedTagList(selectedTags);
    key.current = !key.current;
  }, [selectedTags]);

  useEffect(() => {
    if (isEmpty(optionsList) === false && isEmpty(selectedTagList) === false) {
      const filtered = optionsList.filter(
        (x) => !selectedTagList.some((y) => x.id === y.id)
      );
      setVisibleTags(filtered);
    } else {
      setVisibleTags(optionsList); // no selected tags so use default list
    }
  }, [selectedTagList, onTagChangeAction, optionsList]);

  const handleTagRemove = (tag) => {
    const filteredList = selectedTagList.filter((x) => x.id !== tag.id);
    setSelectedTagList(filteredList);
    input.onChange(filteredList);
    if (onTagChangeAction) {
      onTagChangeAction(filteredList);
    }
  };

  return (
    <Fragment>
      <SelectAscend
        name={name}
        id={id}
        labelText={labelText}
        options={visibleTags !== undefined ? visibleTags : optionsList}
        onChange={(x) => {
          const newList = [...selectedTagList, x];
          setSelectedTagList(newList);
          input.onChange(newList);
          if (onTagChangeAction) {
            onTagChangeAction(newList);
          }
          key.current = !key.current;
        }}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={isDisabled}
        value={null}
        selectorGlyph={<SearchSvg className={style.settingsSvg_fill_orange} />}
      />
      <TagList
        key={key.current}
        tags={selectedTagList}
        removeAction={handleTagRemove}
        isMobile={isMobile}
      />
      {touched &&
        ((error && <div className="form-input-error">{error}</div>) ||
          (warning && <div className="form-input-error">{warning}</div>) || (
            <div className="form-input-error">&nbsp;</div>
          ))}
      {!touched && <div className="form-input-error">&nbsp;</div>}
    </Fragment>
  );
}

TagSelector.propTypes = {
  input: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  name: PropTypes.string,
  labelText: PropTypes.string,
  id: PropTypes.any,
  optionsList: PropTypes.array,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectedTags: PropTypes.array,
  onTagChangeAction: PropTypes.func,
  isMobile: PropTypes.bool,
};

TagSelector.defaultProps = {
  optionsList: [],
  selectedTags: [],
};
