import * as types from './ActionTypes';

export const updateCanvasInfo =
  (canvasInfo = {isMobile: false}) =>
  (dispatch) => {
    dispatch({
      type: types.UTILITY__UPDATE_CANVAS,
      canvas: {isMobile: canvasInfo.isMobile},
    });
  };
