import React, {useReducer} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {DiscoverSvg} from 'src/assets/svg/svgComponents';
import styles from './EducationalCard.module.scss';
import {useNavigate} from 'react-router-dom';
import SkillsModal from 'src/components/SkillsModal';
import {updateSeeker} from 'src/actions/seekers';
import $ from 'jquery';

const EDUCATIONAL_CARD_LOCAL_STORAGE_KEY = 'educationCardHidden';
const EDUCATIONAL_CARD_ID = 'educationCard';
const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const EDUCATIONAL_CARD_MATCH_TEXT = {
  true: {
    header: "Not what you're looking for?",
    body: 'Try adding more details to your skills and job preferences to see more roles.',
  },
  false: {
    header: 'Happy with your matches?',
    body: 'Our data shows that seekers with 10 or more matches are more likely to find a relevant role. Your matches are determined by your profile’s skills and your job preferences. If you’d like to see different roles, edit your skills or job preferences on your profile.',
  },
};

function getShowEducationCardSettingFromLocalStorage() {
  let storageSetting = localStorage.getItem(EDUCATIONAL_CARD_LOCAL_STORAGE_KEY);
  if (storageSetting === null) {
    return true;
  }
  storageSetting = JSON.parse(storageSetting);
  const oneDayAgo = Date.now() - ONE_DAY;

  if (storageSetting.setAt > oneDayAgo) {
    return false;
  }

  return true;
}

const initialState = {
  isVisible: true,
  skillsModalOpen: false,
  haveSubmitted: false,
  showEducationCard: null,
};

const ACTIONS = {
  OPEN_SKILLS_MODAL: 'OPEN_SKILLS_MODAL',
  CLOSE_SKILLS_MODAL: 'CLOSE_SKILLS_MODAL',
  SET_HAVE_SUBMITTED: 'SET_HAVE_SUBMITTED',
  HIDE_CARD: 'HIDE_CARD',
  GET_EDUCATION_STATE_FROM_LOCAL_STORAGE:
    'GET_EDUCATION_STATE_FROM_LOCAL_STORAGE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.GET_EDUCATION_STATE_FROM_LOCAL_STORAGE:
      return {
        ...state,
        showEducationCard: getShowEducationCardSettingFromLocalStorage(),
      };
    case ACTIONS.OPEN_SKILLS_MODAL:
      return {
        ...state,
        skillsModalOpen: true,
      };
    case ACTIONS.CLOSE_SKILLS_MODAL:
      return {
        ...state,
        skillsModalOpen: false,
        haveSubmitted: false,
      };
    case ACTIONS.SET_HAVE_SUBMITTED:
      return {
        ...state,
        haveSubmitted: true,
      };
    case ACTIONS.HIDE_CARD:
      localStorage.setItem(
        EDUCATIONAL_CARD_LOCAL_STORAGE_KEY,
        JSON.stringify({
          setAt: Date.now(),
        })
      );
      return {
        ...state,
        showEducationCard: false,
      };
    default:
      throw new Error(`Invalid action type '${action.type}'`);
  }
}

export default function EducationalCard(props) {
  const [cardState, cardDispatch] = useReducer(reducer, initialState);
  const globalDispatch = useDispatch();
  const seekerLoading = useSelector((state) => state.seekers.seekerLoading);
  const navigate = useNavigate();

  if (cardState.showEducationCard === null) {
    cardDispatch({type: ACTIONS.GET_EDUCATION_STATE_FROM_LOCAL_STORAGE});
  }
  const handleSkillsSubmit = (formData) => {
    const updatedSeeker = {
      ...props.seeker,
      skillTags: formData.skillTags,
    };
    globalDispatch(updateSeeker(updatedSeeker));
    cardDispatch({type: ACTIONS.SET_HAVE_SUBMITTED});
    cardDispatch({type: ACTIONS.HIDE_CARD});
  };

  if (!seekerLoading && cardState.skillsModalOpen && cardState.haveSubmitted) {
    cardDispatch({type: ACTIONS.CLOSE_SKILLS_MODAL});
  }

  return (
    <div
      id={EDUCATIONAL_CARD_ID}
      className={styles.educationalCard}
      style={{
        display: cardState.showEducationCard ? 'flex' : 'none',
      }}
    >
      <SkillsModal
        isOpen={
          cardState.skillsModalOpen ||
          (seekerLoading && cardState.haveSubmitted)
        }
        onClose={() => {
          cardDispatch({type: ACTIONS.CLOSE_SKILLS_MODAL});
        }}
        selectedSkills={props.seeker.skillTags}
        onSubmit={handleSkillsSubmit}
      />
      <div
        style={{
          display: 'flex',
        }}
      >
        <DiscoverSvg className={styles.discoverSvg} />

        <div>
          <p
            style={{
              fontWeight: 700,
              fontSize: '16px',
              marginTop: '20px',
            }}
          >
            {EDUCATIONAL_CARD_MATCH_TEXT[props.seekerHasFewMatches].header}
          </p>
          <p
            style={{
              fontSize: '14px',
            }}
          >
            {EDUCATIONAL_CARD_MATCH_TEXT[props.seekerHasFewMatches].body}
          </p>
        </div>
      </div>

      <div className={styles.preferenceLinks}>
        <button
          className={`btn buttonAsLink ${styles.link}`}
          onClick={() => {
            cardDispatch({
              type: ACTIONS.OPEN_SKILLS_MODAL,
            });
          }}
        >
          Update Skills
        </button>
        <button
          className={`btn buttonAsLink ${styles.link}`}
          onClick={() => {
            cardDispatch({type: ACTIONS.HIDE_CARD});
            setTimeout(() => {
              navigate('/profile');
            }, 100);
          }}
        >
          Update Job Preferences
        </button>
      </div>
      <button
        onClick={() => {
          $(`#${EDUCATIONAL_CARD_ID}`).toggle(() => {
            cardDispatch({type: ACTIONS.HIDE_CARD});
          });
        }}
        className={`btn buttonAsLink ${styles.close} `}
      >
        <i className="nc-icon-glyph remove" />
      </button>
    </div>
  );
}

EducationalCard.propTypes = {
  seeker: PropTypes.object.isRequired,
  seekerHasFewMatches: PropTypes.bool.isRequired,
};
