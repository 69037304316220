import * as types from './ActionTypes';

export function updateCurrentPage(page) {
  return {
    type: types.CONNECTIONS_DT__UPDATE_CURRENT_PAGE,
    page: page,
  };
}

export function updateSearchTerm(searchTerm) {
  return {
    type: types.CONNECTIONS_DT__UPDATE_SEARCH_TERM,
    searchTerm: searchTerm,
  };
}

export function updateSortTerm(sortBy, sortAscending) {
  return {
    type: types.CONNECTIONS_DT__UPDATE_SORT_TERM,
    sortBy: sortBy,
    sortAscending: sortAscending,
  };
}

export function updateRecruiter(id) {
  return {
    type: types.CONNECTIONS_DT__UPDATE_RECRUITER,
    id: id,
  };
}
