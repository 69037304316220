import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import _ from 'lodash';

import * as roleTypeActions from 'src/actions/roleTypes';

import * as Layout from 'src/layout';
import {BusyButton} from './BusyButton';
import CheckboxSelector from './selectors/CheckboxSelector/CheckboxSelector';
import AscendCheckbox from 'src/formFields/ascendCheckbox/AscendCheckbox';
import * as seekersActions from 'src/actions/seekers';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['roleTypeIds'];
  _.forEach(requiredFields, (field) => {
    if (
      (!values[field] || values[field].length === 0) &&
      values.anyRolePref === false
    ) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

class RoleTypesModal extends React.Component {
  state = {
    isMobile: false,
  };

  async componentDidMount() {
    await this.props.getRoleTypes();

    if (this.props.roleTypes.length > 0) {
      this.setState({
        isMobile: window.matchMedia('(max-width: 430px)').matches,
      });
    }
  }

  buildSelectOptions = () => {
    const roles = this.props.roleTypes
      .filter((x) => x.name !== 'Any')
      .map((x) => {
        return {id: x.id, value: x.id, label: x.name, tagName: x.name};
      });
    return roles;
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  handleClose = () => {
    this.props.updateSeekerForEdit({
      ...this.props.seekerForEdit,
      roleTypes: this.props.seeker.roleTypes,
    });
    this.props.onClose();
  };

  render() {
    const {isOpen} = this.props;

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={this.handleClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            mutators={{
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={{
              roleTypeIds: this.props.seeker.roleTypes,
              anyRolePref: this.props.seeker.roleTypes.some(
                (x) => x.name === 'Any'
              ),
            }}
          >
            {({handleSubmit, form}) => {
              const handleCheck = (checked) => {
                if (checked) {
                  form.mutators.setValue('roleTypeIds', [
                    this.props.roleTypes.find((x) => x.name === 'Any'),
                  ]);
                  this.props.updateSeekerForEdit({
                    ...this.props.seekerForEdit,
                    roleTypes: [],
                  });
                } else {
                  form.mutators.setValue('roleTypeIds', []);
                  this.props.updateSeekerForEdit({
                    ...this.props.seekerForEdit,
                    roleTypes: [],
                  });
                }
              };

              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div style={{maxWidth: '885px'}}>
                      <div className="bold active-headline text-align-center">
                        Tell us about your role preferences
                      </div>
                      <div className="text-align-center normal-subheader padding-bottom-thirtytwo">
                        Let's match you with the kind of work that you are
                        passionate about. Search for the type of work you want
                        to be doing in your next job.
                      </div>
                      <div style={{textAlign: 'left'}}>
                        <Field
                          component={CheckboxSelector}
                          name="roleTypeIds"
                          isMobile={this.state.isMobile}
                          closeMenuOnSelect={false}
                          optionsList={this.buildSelectOptions()}
                          form={form}
                          onTagChangeAction={(e) => {
                            if (e?.length > 0) {
                              form.mutators.setValue('anyRolePref', false);
                            }
                            this.props.updateSeekerForEdit({
                              ...this.props.seekerForEdit,
                              roleTypes: e.map((x) => {
                                return {id: x.id, name: x.tagName};
                              }),
                            });
                          }}
                          selectedTags={this.props.seekerForEdit.roleTypes
                            .filter((x) => x.name !== 'Any')
                            .map((x) => {
                              return {
                                id: x.id,
                                value: x.id,
                                label: x.name,
                                tagName: x.name,
                              };
                            })}
                          placeholder="Ex. Accounting, Finance, etc."
                          isSearchable={!this.state.isMobile}
                        />
                      </div>
                      <div className="pt-2" style={{width: '100%'}}>
                        <Field name="anyRolePref">
                          {(props) => {
                            return (
                              <>
                                <AscendCheckbox
                                  name={props.input.name}
                                  inputValue={props.input.value}
                                  onChangeAction={handleCheck}
                                  onFocus={props.input.onFocus}
                                  meta={props.meta}
                                  label="I have no preference"
                                  inputOnChange={props.input.onChange}
                                  defaultValue={props.input.value}
                                />
                              </>
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center nowrap">
                    <button
                      type="button"
                      className="btn btn-transparent col-2 me-4"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <BusyButton
                      onClick={handleSubmit}
                      buttonText={'Save'}
                      busyText={'Saving'}
                      className="col-2"
                      style={{width: '210px', height: '40px'}}
                      float={'float-right'}
                      buttonIcon={'save'}
                      alignText={'text-center'}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

RoleTypesModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  roleTypes: PropTypes.array,
  roleTypesLoading: PropTypes.bool,
  seeker: PropTypes.any,
  seekerForEdit: PropTypes.any,
  updateSeekerForEdit: PropTypes.func.isRequired,
};

const actions = {
  ...roleTypeActions,
  ...seekersActions,
};

let EditRoleTypesModal = (props) => <RoleTypesModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  return {
    seeker: state.seekers.seeker,
    seekerForEdit: state.seekers.seekerForEdit,
    roleTypes: state.roleTypes.roleTypes,
  };
};

EditRoleTypesModal = connect(editMapStateToProps, actions)(EditRoleTypesModal);

export {EditRoleTypesModal};
