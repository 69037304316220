import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/esm/Container';
import Row from 'reactstrap/esm/Row';
import Col from 'reactstrap/esm/Col';
import ascendLogoBluePng from 'src/assets/images/Ascend Wordmark.png';

const ConnectionHeader = ({
  title,
  subTitle,
  logo,
  progressImage,
  titleStyle,
  subTitleStyle,
}) => {
  return (
    <Fragment>
      <Container style={{paddingTop: '8px'}}>
        <Row>
          <Col className="col-2">
            <img style={{width: '76px'}} alt="" src={logo} />
          </Col>
          <Col>
            <Row
              className={`${titleStyle} justify-content-center pt-4 text-align-center`}
            >
              {title}
            </Row>
            {progressImage && (
              <Row className="justify-content-center ps-2 pt-3 pb-3">
                <img alt="" src={progressImage} />
              </Row>
            )}
          </Col>
          <Col className="col-2">
            <Row className="flex-row justify-content-center " />
          </Col>
        </Row>
        <Row>
          <Col className="col-1" />
          <Col
            className={`${subTitleStyle} justify-content-center text-align-center`}
          >
            {subTitle}
          </Col>
          <Col className="col-1" />
        </Row>
      </Container>
    </Fragment>
  );
};

ConnectionHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleStyle: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  progressImage: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleStyle: PropTypes.string,
};

ConnectionHeader.defaultProps = {
  logo: ascendLogoBluePng,
};

export default ConnectionHeader;
