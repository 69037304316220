import React from 'react';
import PropTypes from 'prop-types';

class SingleCheckbox extends React.Component {
  render() {
    const {
      input,
      meta: {touched, error, warning},
    } = this.props;

    return (
      <div>
        <div className="form form-group">
          <table width="100%">
            <tbody>
              <tr>
                <td width="15%">
                  <div>
                    <input {...input} type="checkbox" onFocus={input.onFocus} />
                  </div>
                </td>
                <td width="85%">
                  {touched &&
                    ((error && (
                      <div className="form-input-error">{error}</div>
                    )) ||
                      (warning && (
                        <div className="form-input-error">{warning}</div>
                      )) || <div className="form-input-error">&nbsp;</div>)}
                  {!touched && <div className="form-input-error">&nbsp;</div>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

SingleCheckbox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

SingleCheckbox.defaultProps = {};

export default SingleCheckbox;
