import * as types from './ActionTypes';
import _ from 'lodash';

const apiUrl = process.env.REACT_APP_API_URL;

export function createTeam(team) {
  return {
    types: [
      types.TEAMS__CREATE_REQUEST,
      types.TEAMS__CREATE_SUCCESS,
      types.TEAMS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/teams`,
    method: 'POST',
    body: team,
    payload: {team},
    successMessage: 'Team created.',
    failureMessage: 'Error creating team.',
  };
}

export function createTeamWatch(teamId) {
  return {
    types: [
      types.TEAMS__CREATE_WATCH_REQUEST,
      types.TEAMS__CREATE_WATCH_SUCCESS,
      types.TEAMS__CREATE_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}/watches`,
    method: 'POST',
    body: {},
    payload: {teamId},
    successMessage: 'Team added to favorites',
    failureMessage: 'Error adding team to favorites',
  };
}

export function createSeekerWatch(
  teamId,
  seekerId,
  successMessage = 'Team Watch Added'
) {
  return {
    types: [
      types.TEAMS__CREATE_SEEKER_WATCH_REQUEST,
      types.TEAMS__CREATE_SEEKER_WATCH_SUCCESS,
      types.TEAMS__CREATE_SEEKER_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}/seeker_watches?seekerId=${seekerId}`,
    method: 'POST',
    body: {},
    payload: {teamId, seekerId},
    successMessage,
    failureMessage: 'Error adding seeker to watch list.',
  };
}

export function getTeams() {
  return {
    types: [
      types.TEAMS__GET_ALL_REQUEST,
      types.TEAMS__GET_ALL_SUCCESS,
      types.TEAMS__GET_ALL_FAILURE,
    ],
    uri: `${apiUrl}/teams`,
    method: 'GET',
  };
}

export function getTeamsByEmployer(employerId, includeUnPublished = false) {
  let uri = `${apiUrl}/teams`;
  let p = [];
  p.push('employerId=' + employerId);

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.TEAMS__GET_ALL_REQUEST,
      types.TEAMS__GET_ALL_SUCCESS,
      types.TEAMS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getTeamsByEmployerAdvanced(
  employerId,
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  includeUnPublished = false
) {
  let uri = `${apiUrl}/teams`;
  let p = [];
  p.push('employerId=' + employerId);
  if (includeUnPublished === true) p.push('includeUnPublished');
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.TEAMS__GET_ALL_ADVANCED_REQUEST,
      types.TEAMS__GET_ALL_ADVANCED_SUCCESS,
      types.TEAMS__GET_ALL_ADVANCED_FAILURE,
    ],
    payload: {employerId},
    uri: uri,
    method: 'GET',
  };
}

export function getTeamsByRecruiter(recruiterId, includeUnPublished = false) {
  let uri = `${apiUrl}/teams`;
  let p = [];
  p.push('recruiterId=' + recruiterId);

  if (includeUnPublished === true) p.push('includeUnPublished');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.TEAMS__GET_ALL_REQUEST,
      types.TEAMS__GET_ALL_SUCCESS,
      types.TEAMS__GET_ALL_FAILURE,
    ],
    payLoad: {recruiterId},
    uri: uri,
    method: 'GET',
  };
}

export function getTeam(teamId) {
  return {
    types: [
      types.TEAMS__GET_REQUEST,
      types.TEAMS__GET_SUCCESS,
      types.TEAMS__GET_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}`,
    method: 'GET',
    payload: {teamId},
  };
}

export function getTeamForEdit(teamId) {
  return {
    types: [
      types.TEAMS__GET_FOR_EDIT_REQUEST,
      types.TEAMS__GET_FOR_EDIT_SUCCESS,
      types.TEAMS__GET_FOR_EDIT_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}`,
    method: 'GET',
    payload: {teamId},
  };
}

export function getTeamToCache(team_id) {
  return {
    types: [
      types.TEAMS__GET_TO_CACHE_REQUEST,
      types.TEAMS__GET_TO_CACHE_SUCCESS,
      types.TEAMS__GET_TO_CACHE_FAILURE,
    ],
    uri: `${apiUrl}/teams/${team_id}`,
    method: 'GET',
    payload: {team_id},
  };
}

const cleanForUpdate = (team) => {
  const illegalFields = [
    'id',
    'employerId',
    'updatedAt',
    'createdAt',
    'deletedAt',
    'watchersCount',
    'jobs',
    'watchedSeekers',
    'employerName',
    'draftJobCount',
    'publishedJobCount',
    'publishedAt',
    'roleTypes',
    'employmentTypes',
    'industries',
    'regions',
    'skillTags',
    'matchRequestedAt',
    'isActive',
  ];
  let cleaned = JSON.parse(JSON.stringify(team));
  _.forEach(Object.keys(cleaned), (key) => {
    if (_.includes(illegalFields, key)) delete cleaned[key];
  });

  return cleaned;
};

export function updateTeam(team) {
  let body = cleanForUpdate(team);

  return {
    types: [
      types.TEAMS__UPDATE_REQUEST,
      types.TEAMS__UPDATE_SUCCESS,
      types.TEAMS__UPDATE_FAILURE,
    ],
    uri: `${apiUrl}/teams/${team.id}`,
    method: 'PATCH',
    body: body,
    payload: {team},
    successMessage: 'Team saved.',
    failureMessage: 'Error saving team.',
  };
}

export function deleteTeam(teamId) {
  return {
    types: [
      types.TEAMS__DELETE_REQUEST,
      types.TEAMS__DELETE_SUCCESS,
      types.TEAMS__DELETE_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}`,
    method: 'DELETE',
    payload: {teamId},
    successMessage: 'Team deleted.',
    failureMessage: 'Error deleting team.',
  };
}

export function deleteTeamWatch(teamId) {
  return {
    types: [
      types.TEAMS__DELETE_WATCH_REQUEST,
      types.TEAMS__DELETE_WATCH_SUCCESS,
      types.TEAMS__DELETE_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}/watches`,
    method: 'DELETE',
    payload: {teamId},
    successMessage: 'Team removed from favorites',
    failureMessage: 'Error removing team favorites',
  };
}

export function deleteSeekerWatch(
  teamId,
  seekerId,
  successMessage = 'Watched Removed'
) {
  return {
    types: [
      types.TEAMS__DELETE_SEEKER_WATCH_REQUEST,
      types.TEAMS__DELETE_SEEKER_WATCH_SUCCESS,
      types.TEAMS__DELETE_SEEKER_WATCH_FAILURE,
    ],
    uri: `${apiUrl}/teams/${teamId}/seeker_watches?seekerId=${seekerId}`,
    method: 'DELETE',
    payload: {teamId, seekerId},
    successMessage,
    failureMessage: 'Error removing seeker from watch list.',
  };
}
