import * as types from '../actions/ActionTypes';

const initialState = {
  experienceLevels: [],
  experienceLevelsLoading: false,
};

export default function experienceLevels(state = initialState, action) {
  switch (action.type) {
    case types.EXPERIENCE_LEVELS__GET_REQUEST:
      return {...state, experienceLevels: [], experienceLevelsLoading: true};
    case types.EXPERIENCE_LEVELS__GET_SUCCESS:
      return {
        ...state,
        experienceLevels: action.response.body.experienceLevels,
        experienceLevelsLoading: false,
      };
    case types.EXPERIENCE_LEVELS__GET_FAILURE:
      return {
        ...state,
        experienceLevelsLoading: false,
      };
    default:
      return state;
  }
}
