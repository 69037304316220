import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

const renderRadio = (checked, onClick, name, label) => {
  return (
    <Fragment>
      <label
        className="radio"
        style={{
          paddingBottom: '9px',
          marginBottom: '0.5rem',
          height: '22px',
          width: '22px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <input
          type="radio"
          aria-label={`${name} ${label}`}
          name={`${name}`}
          checked={checked}
          onChange={onClick}
          style={{cursor: 'auto'}}
        />
        <span className="radio-checkmark" />
      </label>
    </Fragment>
  );
};

// input.value = [{id: 1, level: 0}, ...]
class RadioGridField extends React.Component {
  handleClick = (id, level) => {
    const {value, onChange} = this.props.input;

    const index = _.findIndex(value, ['id', id]);

    let newValue = [...value];
    if (index !== -1) {
      newValue.splice(index, 1);
    }
    newValue.push({id, level});
    onChange(newValue);
  };

  render() {
    const {
      input,
      options,
      responses,
      meta: {touched, error, warning},
    } = this.props;
    const headers = responses.map(({label}, index) => {
      return (
        <th key={index} className="columnpad text-align-center disabled-body">
          {label}
        </th>
      );
    });

    const rows = options.map(({id, name}, optIndex) => {
      const radios = responses.map(({level, label}, respIndex) => {
        const checked = _.some(
          input.value,
          (v) => v.id === id && v.level === level
        );
        return (
          <td
            key={respIndex}
            className="columnpad text-align-center"
            width="13%"
          >
            {renderRadio(
              checked,
              () => this.handleClick(id, level),
              name,
              label
            )}
          </td>
        );
      });

      return (
        <tr
          key={optIndex}
          className={classNames({
            'bd-verylightbluebackground': optIndex % 2 === 0,
            'bd-whitebackground': optIndex % 2 !== 0,
          })}
        >
          <th width="35%">
            <div className="columnpad normal-body bold">{name}</div>
          </th>
          {radios}
        </tr>
      );
    });

    return (
      <div>
        <fieldset>
          <legend></legend>
          <div className="padding-bottom-sixteen">
            <table width="100%">
              <thead>
                <tr>
                  <td />
                  {headers}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </fieldset>
        {touched &&
          ((error && <div className="form-input-error">{error}</div>) ||
            (warning && <div className="form-input-error">{warning}</div>) || (
              <div className="form-input-error">&nbsp;</div>
            ))}
        {!touched && <div className="form-input-error">&nbsp;</div>}
      </div>
    );
  }
}

RadioGridField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default RadioGridField;
