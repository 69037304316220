import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';

import {AscendModal, AscendModalBody} from '../../../layout';
import {TextField, SelectField} from 'src/formFields';
import CheckboxField from 'src/formFields/CheckboxField';
import {OnChange} from 'react-final-form-listeners';
import _ from 'lodash';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['email', 'firstName', 'lastName', 'permission'];
  _.forEach(requiredFields, (field) => {
    if (!values[field] || values[field].length === 0) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

class EditRecruiterForm extends React.Component {
  handleSubmit = (recruiter) => {
    let data = {
      ...recruiter,
      firstName: recruiter.firstName.trim(),
      lastName: recruiter.lastName.trim(),
      email: recruiter.email.trim(),
      securityRoleNames: [recruiter.permission],
    };
    // The teams field in the form only holds an array of integer ids.
    // So we need to map it back to what the API expects:
    // e.g. [{id: 1}]
    const teams = recruiter.teams.map((id) => {
      return {id};
    });

    this.props.onSubmit({...data, teams});
  };

  render() {
    const {title, submitButtonText, recruiter, teams} = this.props;

    // The teams field from API looks like this:
    // [{id: 1, name: 'foo'}]
    // So we need to map it to just an array of the ids
    // before setting the initial state of the form.
    const initialState = {
      ...recruiter,
      teams: recruiter.teams.map((t) => t.id),
      permission:
        recruiter.securityRoleNames.length === 0
          ? ''
          : recruiter.securityRoleNames[0],
    };
    return (
      <div>
        <AscendModal isOpen={this.props.isOpen} onClose={this.props.onClose}>
          <AscendModalBody bodyClass="bd-modalbody-24" hasClose={true}>
            <Form
              onSubmit={this.handleSubmit}
              keepDirtyOnReinitialize={true}
              initialValues={initialState}
              validate={validate}
            >
              {({handleSubmit, form}) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <header>
                      <center>
                        <h1 className="normal-title bold">{title}</h1>
                      </center>
                    </header>
                    <hr />
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <Field
                          name="firstName"
                          ariaLabel="First Name"
                          component={TextField}
                          label="First Name"
                          size={36}
                          maxLength={64}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Field
                          name="lastName"
                          ariaLabel="Last Name"
                          component={TextField}
                          label="Last Name"
                          size={36}
                          maxLength={64}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Field
                          name="email"
                          ariaLabel="Email Address"
                          component={TextField}
                          label="Email Address"
                          size={36}
                          maxLength={64}
                          disabled={this.props.recruiter.email.length > 0}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <Field
                          name="permission"
                          ariaLabel="Permissions"
                          component={SelectField}
                          label="Permissions"
                          size={36}
                          options={[
                            {
                              value: 'COMPANY_RECRUITER_ADMIN',
                              label: (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<strong>Admin</strong><br />Can add, edit, and delete users',
                                  }}
                                />
                              ),
                            },
                            {
                              value: 'COMPANY_RECRUITER',
                              label: (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<strong>Recruiter</strong><br />Cannot make changes to account users',
                                  }}
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    </div>

                    {teams.length > 0 && (
                      <div className="row">
                        <div className="col-md-12">
                          <span id="teams" className="secondary-body">
                            Teams
                          </span>
                          <br />
                          <fieldset>
                            <legend />
                            {teams.map((team) => (
                              <div className="row" key={team.id}>
                                <OnChange name={team.name}>
                                  {(value) => {
                                    form.change(
                                      'teams',
                                      teams
                                        .filter(
                                          (team) =>
                                            form.getState().values[
                                              team.name
                                            ] === true
                                        )
                                        .map((t) => t.id)
                                    );
                                  }}
                                </OnChange>

                                <Field
                                  label={team.name}
                                  name={team.name}
                                  type="checkbox"
                                  id={`team${team.id}`}
                                  component={CheckboxField}
                                  initialValue={initialState.teams.some(
                                    (x) => x === team.id
                                  )}
                                />
                              </div>
                            ))}
                          </fieldset>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{width: '100%', textAlign: 'center'}}>
                          <button
                            type="submit"
                            className="clickable btn btn-primary"
                          >
                            {submitButtonText}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </AscendModalBody>
        </AscendModal>
      </div>
    );
  }
}

EditRecruiterForm.propTypes = {
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  recruiter: PropTypes.object.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

EditRecruiterForm.defaultProps = {
  title: 'Edit Recruiter',
  submitButtonText: 'Edit',
  isOpen: false,
};

export default EditRecruiterForm;
