import React, {useEffect, useState} from 'react';
import DocumentTitle from 'src/components/vendor/DocumentTitle';
import Avatar from 'src/components/Avatar';
import * as Layout from 'src/layout';
import PreviousButton from 'src/components/PreviousButton';
import editWhiteSvg from 'src/assets/images/EditWhite.svg';
import {InvitationSvg, LinkSvg} from 'src/assets/svg/svgComponents';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
  updateAscendRecruiter,
  getAscendRecruiter,
} from 'src/actions/ascendRecruiters';
import AdminProfileModal from 'src/pages/admin/profile/AdminProfileModal';

export default function AdminProfilePage() {
  const [isEditAdminProfileModalIsOpen, setIsEditAdminProfileModalIsOpen] =
    useState(false);

  const dispatch = useDispatch();
  const parameters = useParams();
  let {ascendRecruiter, ascendRecruiterLoading, profile} = useSelector(
    (state) => {
      return {
        ascendRecruiter: state.ascendRecruiters.ascendRecruiter,
        ascendRecruiterLoading: state.ascendRecruiters.ascendRecruiterLoading,
        profile: state.profile,
      };
    }
  );

  useEffect(() => {
    if (!ascendRecruiter.id && ascendRecruiterLoading === false) {
      dispatch(getAscendRecruiter(parameters.id));
    }
  }, [dispatch, parameters, ascendRecruiter, ascendRecruiterLoading, profile]);

  const handleEditClick = () => {
    setIsEditAdminProfileModalIsOpen(true);
  };

  const handleSubmitClick = (values) => {
    dispatch(updateAscendRecruiter(values));
    setIsEditAdminProfileModalIsOpen(false);
  };

  return (
    <LoadingOverlay
      role="status"
      active={ascendRecruiterLoading}
      spinner={true}
      text="Loading..."
      fadeSpeed={200}
      styles={{
        overlay: (base) => ({
          ...base,
          position: 'fixed',
        }),
      }}
    >
      <DocumentTitle title={`${ascendRecruiter.name} Profile`}>
        <div className="wholepage">
          <Layout.PageHeader
            title={null}
            showAdminLinks={profile.permissions.includes('VIEW_ADMIN_MENU')}
          />
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <PreviousButton />

              <div className="container">
                <div className="row">
                  <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                  <div className="col-md-1">
                    <Avatar
                      height={86}
                      width={86}
                      url={ascendRecruiter.avatarUrl || ''}
                    />
                  </div>

                  <div className="col-md-9 ps-5">
                    <h1 className="profile-header bold padding-top-twenty padding-bottom-eight mb-0">
                      {ascendRecruiter.name} &nbsp;&nbsp;&nbsp;
                      {ascendRecruiter.userId === profile.id && (
                        <button
                          type="button"
                          onClick={() => handleEditClick()}
                          className="clickable btn btn-primary"
                        >
                          <img src={editWhiteSvg} alt="" /> Edit&nbsp;
                        </button>
                      )}
                    </h1>

                    <div
                      aria-label="email"
                      className="candidate-profile-title bold padding-bottom-sixteen"
                    >
                      <InvitationSvg
                        width="24px"
                        height="24px"
                        className="email_icon me-2 ms-1 mb-1"
                      />

                      {ascendRecruiter.email}
                    </div>

                    {ascendRecruiter.externalUrl && (
                      <div
                        aria-label="externalUrl"
                        className="candidate-profile-title bold padding-bottom-sixteen"
                      >
                        <LinkSvg
                          width="24px"
                          height="24px"
                          className="link_icon me-2 ms-1 mb-1"
                        />

                        <a
                          href={ascendRecruiter.externalUrl}
                          target="_blank"
                          className="underline"
                          rel="noopener noreferrer"
                        >
                          {ascendRecruiter.externalUrl}
                        </a>
                      </div>
                    )}

                    {ascendRecruiter.aboutMe && (
                      <div
                        aria-label="about me"
                        className="candidate-profile-title bold"
                      >
                        <span className="inlineblock me-2 ms-1">
                          About me: {ascendRecruiter.aboutMe}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <br />
            </main>
            <div className="anchoredPageFooterContainer">
              <Layout.PageFooter />
            </div>
          </div>

          <AdminProfileModal
            isOpen={isEditAdminProfileModalIsOpen}
            onClose={() => {
              setIsEditAdminProfileModalIsOpen(false);
            }}
            onSubmit={handleSubmitClick}
            ascendRecruiter={ascendRecruiter}
          />
        </div>
      </DocumentTitle>
    </LoadingOverlay>
  );
}
