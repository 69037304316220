import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import {getYear, add} from 'date-fns';
import {graduationMonths} from 'src/utils/fieldHelper';

class SelectMonthYear extends React.Component {
  state = {
    month: this.props.month,
    year: this.props.year,
  };

  componentDidMount() {
    this.props.form.mutators.setValue(
      'graduationDate',
      `${this.props.month}/${this.props.year}`
    );
  }
  render() {
    const {
      input: {onChange},
      meta: {touched, error, warning},
      label,
      message,
      labelBold,
      labelCssClass,
    } = this.props;

    const labelCss = !labelCssClass
      ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
      : labelCssClass;

    const monthOptions = graduationMonths
      .filter((x) => x !== '')
      .map((label, value) => (
        <option key={`month-${value}`} value={value + 1}>
          {label}
        </option>
      ));
    const yearOptions = _.range(
      this.props.startingYear || this.props.startingYear,
      getYear(add(new Date(), {years: 8}))
    ).map((year) => (
      <option key={`year-${year}`} value={year}>
        {year}
      </option>
    ));
    const onMonthSelect = (value) => {
      this.setState({month: value});
      onChange(`${value}/${this.state.year}`);
    };
    const onYearSelect = (value) => {
      this.setState({year: value});
      onChange(`${this.state.month}/${value}`);
    };
    return (
      <div>
        <span className={labelCss}>
          <div aria-label={label} id="SelectMonthYearFieldLabel">
            {label}
          </div>
        </span>
        <div className="form row">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <select
              className="custom-select form-select"
              value={this.state.month}
              onChange={(e) => onMonthSelect(parseInt(e.target.value, 10))}
              aria-label="Select Month"
              style={this.props.monthStyle}
            >
              {monthOptions}
            </select>
            &nbsp;
            <select
              className="custom-select form-select"
              value={this.state.year}
              onChange={(e) => onYearSelect(e.target.value)}
              aria-label="Select Year"
              style={this.props.yearStyle}
            >
              {yearOptions}
            </select>
          </div>

          {touched &&
            ((error && <div className="form-input-error">{error}</div>) ||
              (warning && (
                <div className="form-input-error">{warning}</div>
              )) || <div className="form-input-message">{message}</div>)}
          {!touched && <div className="form-input-message">{message}</div>}
        </div>
      </div>
    );
  }
}

SelectMonthYear.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
  startingYear: PropTypes.number,
  labelBold: PropTypes.bool,
  labelCssClass: PropTypes.string,
  monthStyle: PropTypes.object,
  yearStyle: PropTypes.object,
};

SelectMonthYear.defaultProps = {
  message: ' ',
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  startingYear: 1900,
  monthStyle: {},
  yearStyle: {},
};

export default SelectMonthYear;
