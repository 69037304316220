import React, {useRef, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {TextField} from 'src/formFields';
import * as connectionConstants from 'src/pages/admin/connections/CreateConnectionConstants';

export default function CreateConnectionAttributeRoleToNetwork(props) {
  const formRef = useRef(null);
  const {onSubmit, onChange, roleAttributed, isNetworkConnection} = props;

  const attributedOptions = useMemo(() => {
    return [
      {
        isAttributed: true,
        label: connectionConstants.YES_FOUNDROLEONNETWORK,
        requiresOther: false,
      },
      {
        isAttributed: true,
        label: connectionConstants.YES_GUIDANCEFROMMENTOR,
        requiresOther: false,
      },
      {
        isAttributed: true,
        label: connectionConstants.YES_RESUMEORCOVERLETTERSUPPORT,
        requiresOther: false,
      },
      {
        isAttributed: true,
        label: connectionConstants.YES_INTERVIEWSUPPORT,
        requiresOther: false,
      },
      {
        isAttributed: false,
        label: connectionConstants.NO_DONOTATTRIBUTE,
        requiresOther: false,
      },
      {
        isAttributed: true,
        label: connectionConstants.YES_OTHER,
        requiresOther: true,
      },
      {
        isAttributed: false,
        label: connectionConstants.NO_OTHER,
        requiresOther: true,
      },
    ];
  }, []);

  useEffect(() => {
    // nothing has been selected here yet
    if (roleAttributed.isAttributed === null && isNetworkConnection) {
      onChange(attributedOptions[0]);
    }
  }, [roleAttributed, isNetworkConnection, attributedOptions, onChange]);

  const renderOtherTextField = (option, index) => {
    if (option.requiresOther) {
      return (
        <div className="col-6">
          <Field
            name={`other_${option.isAttributed}`}
            component={TextField}
            style={{width: '100%'}}
            disabled={roleAttributed.label !== option.label}
            key={`other_${index}`}
          />
        </div>
      );
    }
    return null;
  };

  const renderRadioButtons = (form) => {
    return attributedOptions.map((option, index) => {
      return (
        <div className="row" key={index}>
          <div className={`${option.requiresOther ? 'col-3' : 'col-auto'}`}>
            <label className="radio mt-3 normal-body">
              {option.label}
              <Field
                name="attributedReason"
                component="input"
                type="radio"
                value={option.label}
                checked={roleAttributed.label === option.label}
                key={index}
                autoFocus={index === 0 ? true : false}
              />
              <span className="radio-checkmark-orange" />
            </label>
          </div>

          {renderOtherTextField(option, index)}
        </div>
      );
    });
  };

  const validate = (values) => {
    const errors = {};

    const selectedAttribute = attributedOptions.find(
      ({label}) => label === values.attributedReason
    );

    if (selectedAttribute) {
      if (selectedAttribute['requiresOther'] === true) {
        const otherFieldName = `other_${selectedAttribute.isAttributed}`;

        if (!values[otherFieldName]) {
          errors[`other_${selectedAttribute.isAttributed}`] = 'Required';
        }
      }
    }

    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({form}) => {
        formRef.current = form;
        return (
          <form>
            <OnChange name="attributedReason">
              {(newValue) => {
                const selectedAttribute = attributedOptions.find(
                  ({label}) => label === newValue
                );

                form.change('other_true', null);
                form.change('other_false', null);

                selectedAttribute['otherText'] = null;
                onChange(selectedAttribute);
              }}
            </OnChange>

            <OnChange name="other_true">
              {(newValue) => {
                onChange({...roleAttributed, otherText: newValue});
              }}
            </OnChange>

            <OnChange name="other_false">
              {(newValue) => {
                onChange({...roleAttributed, otherText: newValue});
              }}
            </OnChange>
            <fieldset>{renderRadioButtons(form)}</fieldset>
          </form>
        );
      }}
    </Form>
  );
}

CreateConnectionAttributeRoleToNetwork.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  roleAttributed: PropTypes.object,
  isNetworkConnection: PropTypes.bool.isRequired,
};
