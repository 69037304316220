import * as types from './ActionTypes';

export function createTeamProfileDataTableTeam(teamId) {
  return {
    type: types.TEAM_PROFILE_DT__CREATE_TEAM,
    teamId,
  };
}

export function updateCurrentPage(teamId, page) {
  return {
    type: types.TEAM_PROFILE_DT__UPDATE_CURRENT_PAGE,
    teamId,
    page,
  };
}
