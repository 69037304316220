import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';
import {CANDIDATE_DISCOVERY} from 'src/pages/candidateDiscovery/candidateDiscoveryGlobals';
import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  roleTypes: [],
  roleTypesLoading: false,
  filteredRoleTypes: [],
};

export default function roleTypes(state = initialState, action) {
  let roleTypes = [];
  switch (action.type) {
    case types.ROLE_TYPES__GET_REQUEST:
      return {...state, roleTypes: [], roleTypesLoading: true};
    case types.ROLE_TYPES__GET_SUCCESS:
      roleTypes = action.response.body.roleTypes.filter(
        (r) => r.name !== 'Any'
      );
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        roleTypes: action.response.body.roleTypes,
        filteredRoleTypes: roleTypes,
        roleTypesLoading: false,
      };
    case types.CANDIDATE_DISCOVERY__FILTER_LIST:
      if (action.filterType !== CANDIDATE_DISCOVERY.rolesFilter) {
        return state;
      }
      roleTypes = state.roleTypes;
      roleTypes = action.response.body.roleTypes.filter(
        (r) => r.name !== 'Any'
      );
      if (action.filterValue === '') {
        return {
          ...state,
          filteredRoleTypes: roleTypes,
        };
      }
      return {
        ...state,
        filteredRoleTypes: roleTypes.filter(
          (tag) =>
            tag.name !== 'Any' &&
            tag.name.toLowerCase().includes(action.filterValue.toLowerCase())
        ),
      };
    case types.CANDIDATE_DISCOVERY__CLEAR_ALL:
      roleTypes = state.roleTypes;
      roleTypes = action.response.body.roleTypes.filter(
        (r) => r.name !== 'Any'
      );
      return {...state, filteredRoleTypes: roleTypes};
    default:
      return state;
  }
}
