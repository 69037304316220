import * as types from '../actions/ActionTypes';

const initialState = {
  companies: [],
  companiesLoading: false,
};

export default function companies(state = initialState, action) {
  switch (action.type) {
    case types.COMPANIES__GET_REQUEST:
      return {...state, companies: [], companiesLoading: true};
    case types.COMPANIES__GET_SUCCESS:
      return {
        ...state,
        companies: action.response.body.companies,
        companiesLoading: false,
      };
    default:
      return state;
  }
}
