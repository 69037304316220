import _ from 'lodash';
import merge from 'deepmerge';
import debounceAction from 'debounce-action';
import PropTypes from 'prop-types';

import * as types from './ActionTypes';
import {buildRemoteWorkList} from '../pages/jobDiscovery/roleUtil';

const apiUrl = process.env.REACT_APP_API_URL;

const onlyMatchesSelectedState = {
  overallFit: true,
  allRolesSelected: false,
  myMatchesSelected: true,
  workStyleMatchesSelected: false,
  invitedRolesSelected: false,
  favoriteRolesSelected: false,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '-publisheddate',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
};

const onlyWorkStyleMatchesSelectedState = {
  overallFit: false,
  allRolesSelected: false,
  myMatchesSelected: false,
  workStyleMatchesSelected: true,
  invitedRolesSelected: false,
  favoriteRolesSelected: false,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
};

const onlyInvitedRolesSelectedState = {
  overallFit: false,
  allRolesSelected: false,
  myMatchesSelected: false,
  workStyleMatchesSelected: false,
  invitedRolesSelected: true,
  favoriteRolesSelected: false,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '-publisheddate',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
};

const onlyHandpickedRolesSelectedState = {
  overallFit: false,
  allRolesSelected: false,
  myMatchesSelected: false,
  workStyleMatchesSelected: false,
  invitedRolesSelected: false,
  favoriteRolesSelected: false,
  handpickedRolesSelected: true,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
};

const onlyFavoriteRolesSelectedState = {
  overallFit: false,
  allRolesSelected: false,
  myMatchesSelected: false,
  workStyleMatchesSelected: false,
  invitedRolesSelected: false,
  favoriteRolesSelected: true,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '-publisheddate',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
};

const allSelectedState = {
  overallFit: false,
  allRolesSelected: true,
  myMatchesSelected: false,
  workStyleMatchesSelected: false,
  invitedRolesSelected: false,
  favoriteRolesSelected: false,
  handpickedRolesSelected: false,
  jobsCurrentPage: 0,
  jobsCurrentItemsPerPage: 10,
  teamsCurrentPage: 0,
  teamsCurrentItemsPerPage: 10,
  jobsSortTerm: '-overallscore',
  jobsSearchTerm: '',
  teamsSortTerm: '-publishedJobCount',
  roleTypeIds: [],
  employmentTypeIds: [],
  experienceLevelIds: [],
  regionIds: [],
  industryIds: [],
  remoteStatus: {},
  degreeTypeRankIds: [],
  segmentIds: [],
  apprenticePathwayIds: [],
  startDateTags: {
    immediate: {
      selected: false,
    },
    flexible: {
      selected: false,
    },
    specific: {
      selected: false,
    },
  },
  resultTypeTags: {
    showJobs: {
      selected: true,
    },
  },
};

// Create API filters from the new UI state.
const filtersFromState = (state) => {
  const filters = {
    overallFit: state.overallFit,
  };

  if (state.jobsSearchTerm) {
    filters.searchTerm = state.jobsSearchTerm.trim();
    filters.searchFields = ['name', 'company', 'description'];
  }

  if (state.handpickedRolesSelected) {
    filters.handpicked = true;
  }

  if (state.invitedRolesSelected) {
    filters.hasInvitations = true;
  }
  if (state.favoriteRolesSelected) {
    filters.favorites = true;
  }

  if (state.workStyleMatchesSelected) {
    filters.workStylesFit = true;
  }

  _.forEach(state.startDateTags, (tagState, tag) => {
    if (tagState.selected === true) {
      filters.roleStartFlexibility = tag;
    }
  });

  filters.employmentTypeIds = state.employmentTypeIds;
  filters.experienceLevelIds = state.experienceLevelIds;

  filters.regionIds = state.regionIds;
  filters.roleTypeIds = state.roleTypeIds;
  filters.degreeTypeRankIds = state.degreeTypeRankIds;
  filters.industryIds = state.industryIds;
  filters.remoteStatus = buildRemoteWorkList(state.remoteStatus);
  filters.apprenticePathwayIds = state.apprenticePathwayIds;

  // Job Category for Modern Apprenticeship
  filters.jobCategoryIds = [];
  _.forEach(state.jobCategoryTags, (tagState, tagId) => {
    if (tagState.selected && tagState.name !== 'Any') {
      filters.jobCategoryIds.push(tagId);
    }
  });

  // remove any filters set to false or that have no value.
  _.forEach(filters, (value, key) => {
    if (!value || value.length === 0) {
      delete filters[key];
    }
  });

  return filters;
};

export function updateSeekerJobAndTeamSearchState(
  newState,
  clearWorkStyles = false
) {
  return {
    type: types.JOB_AND_TEAM_SEARCH__UPDATE_STATE,
    newState,
    clearWorkStyles,
  };
}

export function getSeekerJobMatches(
  seekerId,
  page = 0,
  itemsPerPage = 10,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);

  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  p.push(`seekerId=${seekerId}`);

  p.push('includeMatchData');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.JOB_SEARCH__GET_JOB_MATCHES_REQUEST,
      types.JOB_SEARCH__GET_JOB_MATCHES_SUCCESS,
      types.JOB_SEARCH__GET_JOB_MATCHES_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getSeekerTeamMatches(
  seekerId,
  page = 0,
  itemsPerPage = 10,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/seekers/${seekerId}/team_matches`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.TEAM_SEARCH__GET_TEAM_MATCHES_REQUEST,
      types.TEAM_SEARCH__GET_TEAM_MATCHES_SUCCESS,
      types.TEAM_SEARCH__GET_TEAM_MATCHES_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getSeekerMatchingJobs(seekerId, newState = {}) {
  const overwriteMerge = (destinationArray, sourceArray, options) =>
    sourceArray;
  return (dispatch, getState) => {
    const finalState = merge(getState().jobAndTeamSearch, newState, {
      arrayMerge: overwriteMerge,
    });

    const filters = filtersFromState(finalState);
    dispatch(
      getSeekerJobMatches(
        seekerId,
        finalState.jobsCurrentPage,
        finalState.jobsCurrentItemsPerPage,
        finalState.jobsSortTerm,
        filters
      )
    );
  };
}

const getMatchingJobsDebounced = debounceAction(getSeekerMatchingJobs, 1000);

export function getSeekerMatchingTeams(seekerId, newState = {}) {
  return (dispatch, getState) => {
    const finalState = merge(getState().jobAndTeamSearch, newState);
    const filters = filtersFromState(finalState);
    // HACK: for now, remove job search terms from filters.
    delete filters.searchTerm;
    delete filters.searchFields;
    dispatch(
      getSeekerTeamMatches(
        seekerId,
        finalState.teamsCurrentPage,
        finalState.teamsCurrentItemsPerPage,
        finalState.teamsSortTerm,
        filters
      )
    );
  };
}

export function changeSeekerJobPageAndSearch(seekerId, page) {
  return (dispatch) => {
    const newState = {jobsCurrentPage: page};
    dispatch(updateSeekerJobAndTeamSearchState(newState));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function changeSeekerTeamPageAndSearch(seekerId, page) {
  return (dispatch) => {
    const newState = {teamsCurrentPage: page};
    dispatch(updateSeekerJobAndTeamSearchState(seekerId, newState));
    dispatch(getSeekerMatchingTeams(seekerId, newState));
  };
}

export function changeSeekerJobSortAndSearch(
  seekerId,
  jobsSortTerm,
  jobSortBy,
  jobSortAsc
) {
  return (dispatch) => {
    const newState = {jobsSortTerm, jobSortBy, jobSortAsc, jobsCurrentPage: 0};
    dispatch(updateSeekerJobAndTeamSearchState(newState));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function changeSeekerJobSearchTermAndSearch(seekerId, jobsSearchTerm) {
  return (dispatch) => {
    const newState = {jobsSearchTerm, jobsCurrentPage: 0};
    dispatch(updateSeekerJobAndTeamSearchState(newState));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function changeSeekerTeamSortAndSearch(seekerId, sortTerm) {
  return (dispatch) => {
    const newState = {sortTerm, teamsCurrentPage: 0};
    dispatch(updateSeekerJobAndTeamSearchState(newState));
    dispatch(getSeekerMatchingTeams(seekerId, newState));
  };
}

export function changeSeekerFilterTagAndSearch(
  seekerId,
  group,
  name,
  selected
) {
  return (dispatch, getState) => {
    // by default, set group.name.selected = selected
    let newState = {
      jobsCurrentPage: 0,
      [group]: {
        [name]: {
          selected,
        },
      },
    };
    if (
      [
        'roleTypeIds',
        'industryIds',
        'degreeTypeRankIds',
        'segmentIds',
        'employmentTypeIds',
        'experienceLevelIds',
        'regionIds',
        'apprenticePathwayIds',
      ].includes(group)
    ) {
      let lookupIds = getState().jobAndTeamSearch[group];
      let selectedId = parseInt(name, 10);
      if (lookupIds.indexOf(selectedId) === -1) {
        lookupIds.push(selectedId);
      }
      if (!selected) {
        lookupIds = lookupIds.filter((i) => i !== selectedId);
      }
      newState[group] = lookupIds;
    }
    if (group === 'startDateTags') {
      if (selected) {
        switch (name) {
          case 'immediate':
            newState[group].flexible = {selected: false};
            newState[group].specific = {selected: false};
            break;
          case 'flexible':
            newState[group].immediate = {selected: false};
            newState[group].specific = {selected: false};
            break;
          case 'specific':
            newState[group].immediate = {selected: false};
            newState[group].flexible = {selected: false};
            break;
          default:
            return;
        }
      }
    }

    dispatch(updateSeekerJobAndTeamSearchState(newState));
    // If one of the resultTypeTags was selected, only reload the resultType
    // that was selected (if Jobs shown, don't make an API call to get Teams)
    // If a resultTypeTag is deselected, don't reload any.
    if (group === 'resultTypeTags') {
      if (name === 'showJobs' && selected) {
        dispatch(getMatchingJobsDebounced(seekerId, newState));
      }
    } else {
      const {showJobs} = getState().jobAndTeamSearch.resultTypeTags;
      if (showJobs.selected) {
        dispatch(getMatchingJobsDebounced(seekerId, newState));
      }
    }
  };
}

export function clearSeekerFilterGroupAndSearch(seekerId, group) {
  return (dispatch, getState) => {
    const newState = {
      [group]: {
        ..._.cloneDeep(getState().jobAndTeamSearch[group]),
      },
    };

    if (group === 'startDateTags') {
      newState[group].immediate.selected = false;
      newState[group].flexible.selected = false;
      newState[group].specific.selected = false;
    } else {
      newState[group] = [];
    }

    dispatch(updateSeekerJobAndTeamSearchState(newState));
    const {showJobs} = getState().jobAndTeamSearch.resultTypeTags;
    if (showJobs.selected) {
      dispatch(getSeekerMatchingJobs(seekerId, newState));
    }
  };
}

export function selectOnlyMatchesAndSearch(seekerId) {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(onlyMatchesSelectedState),
    };
    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function selectOnlyInvitedRolesAndSearch(seekerId) {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(onlyInvitedRolesSelectedState),
    };
    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function selectOnlyFavoriteRolesAndSearch(seekerId) {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(onlyFavoriteRolesSelectedState),
    };
    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function selectOnlyWorkStyleMatchesAndSearch(seekerId) {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(onlyWorkStyleMatchesSelectedState),
    };
    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function selectOnlyHandpickedRolesAndSearch(seekerId) {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(onlyHandpickedRolesSelectedState),
    };
    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}

export function seekerSelectAllAndSearch(seekerId, sortTerm) {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(allSelectedState),
    };

    if (sortTerm) {
      newState.jobsSortTerm = sortTerm;
    }

    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
    dispatch(getSeekerMatchingJobs(seekerId, newState));
  };
}
export function seekerSelectAll() {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(allSelectedState),
    };

    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
  };
}

export function resetJobAndTeamSearchStateDefault() {
  return (dispatch) => {
    const newState = {
      ..._.cloneDeep(allSelectedState),
    };

    dispatch(updateSeekerJobAndTeamSearchState(newState, true));
  };
}

export function updateHasViewedFinalJob() {
  return {
    type: types.JOB_AND_TEAM_SEARCH_UPDATE_HAS_VIEWED_FINAL_JOB_FLAG,
  };
}

export const propTypes = {
  updateSeekerJobAndTeamSearchState: PropTypes.func.isRequired,
  getSeekerMatchingJobs: PropTypes.func.isRequired,
  changeSeekerFilterTagAndSearch: PropTypes.func.isRequired,
  changeSeekerJobPageAndSearch: PropTypes.func.isRequired,
  changeSeekerJobSortAndSearch: PropTypes.func.isRequired,
  changeSeekerJobSearchTermAndSearch: PropTypes.func.isRequired,
  changeSeekerTeamPageAndSearch: PropTypes.func.isRequired,
  changeSeekerTeamSortAndSearch: PropTypes.func.isRequired,
  clearSeekerFilterGroupAndSearch: PropTypes.func.isRequired,
  selectOnlyMatchesAndSearch: PropTypes.func.isRequired,
  selectOnlyWorkStyleMatchesAndSearch: PropTypes.func.isRequired,
  selectOnlyInvitedRolesAndSearch: PropTypes.func.isRequired,
  selectOnlyFavoriteRolesAndSearch: PropTypes.func.isRequired,
  selectOnlyHandpickedRolesAndSearch: PropTypes.func.isRequired,
  seekerSelectAllAndSearch: PropTypes.func.isRequired,
  jobAndTeamSearchProps: PropTypes.object.isRequired,
};
