import React from 'react';
import PropTypes from 'prop-types';

class IsDraftWidget extends React.Component {
  render() {
    return (
      <div className="row bd-redbackground text-align-center">
        <div className="col-md-12">
          <div className="columnpad">
            <div className="normal-title-white inlineblock">
              {this.props.titleString}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div
              tabIndex={0}
              role="button"
              aria-label="Approve and publish"
              className="normal-title-white clickable underline bold inlineblock"
              onClick={this.props.approveClick}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  this.props.approveClick();
                }
              }}
            >
              Approve & Publish
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IsDraftWidget.propTypes = {
  titleString: PropTypes.string.isRequired,
  approveClick: PropTypes.func.isRequired,
};

export default IsDraftWidget;
