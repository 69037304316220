import React, {createRef, useState} from 'react';
import {Field} from 'react-final-form';

import PropTypes from 'prop-types';

export const RadioGroupField = ({
  name,
  options,
  errorMessage,
  radioMarginTop = '10px',
  onChange,
  legendText,
  legendClassName,
  metaOverride,
}) => {
  let inputRef = [];
  const [fieldErrors, setFieldErrors] = useState();

  const getColumnSizeClass = (optionCount) => {
    switch (optionCount) {
      case 2:
        return 'col-md-6';
      case 3:
        return 'col-md-4';
      case 4:
        return 'col-md-3';
      default:
        return 'col-md-4';
    }
  };

  const adjustedClassName = 'radio ' + getColumnSizeClass(options.length);

  return (
    <>
      <fieldset>
        <legend className={legendClassName} id={`label-${name}`}>
          {legendText}
        </legend>
        {options.map((opt, index) => {
          inputRef[index] = createRef();

          return (
            <label
              className={adjustedClassName}
              key={`label-${opt.value}`}
              style={{
                marginTop: radioMarginTop,
                paddingRight: '5px',
                display: 'flex',
                float: 'left',
              }}
            >
              {opt.label}

              <Field name={name} type="radio" value={opt.value}>
                {({input, meta}) => {
                  const {touched, error, warning} = {...meta, ...metaOverride};

                  return (
                    <>
                      <input
                        key={`input-${opt.value}`}
                        name={input.name}
                        ref={inputRef[index]}
                        id={`${index} ${opt.label}`}
                        value={opt.value}
                        type="radio"
                        checked={input.checked}
                        onChange={() => {
                          input.onChange(opt.value);
                          if (onChange) {
                            onChange(opt.value);
                          }
                        }}
                      />
                      <span className="radio-checkmark" />

                      {touched &&
                        ((error && <div>{setFieldErrors(error)}</div>) ||
                          (warning && <div>{setFieldErrors(warning)}</div>) || (
                            <div>{setFieldErrors(errorMessage)}</div>
                          ))}
                      {!touched && <div>{errorMessage}</div>}
                    </>
                  );
                }}
              </Field>
            </label>
          );
        })}
      </fieldset>
      {fieldErrors && <div className="form-input-error">{fieldErrors}</div>}
    </>
  );
};
RadioGroupField.propTypes = {
  name: PropTypes.string,
  radioHeight: PropTypes.string,
  radioMarginTop: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),

  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  legendText: PropTypes.string,
  legendClassName: PropTypes.string,
  metaOverride: PropTypes.any,
};
