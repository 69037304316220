import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function getProfile() {
  return {
    types: [
      types.PROFILE__GET_REQUEST,
      types.PROFILE__GET_MIDDLEWARE_SUCCESS,
      types.PROFILE__GET_FAILURE,
    ],
    uri: `${apiUrl}/users/profile`,
    method: 'GET',
  };
}

export function onboardingComplete(avatarUrl) {
  return {
    type: types.PROFILE__ONBOARDING_COMPLETE,
    avatarUrl,
  };
}

export function modernApprenticeIntakeComplete(avatarUrl) {
  return {
    type: types.PROFILE__MODERNAPPRENTICE_INTAKE_COMPLETE,
    avatarUrl,
  };
}

export function getProfileNotificationOptions() {
  return {
    types: [
      types.PROFILE__GET_NOTIFICATION_OPTIONS_REQUEST,
      types.PROFILE__GET_NOTIFICATION_OPTIONS_SUCCESS,
      types.PROFILE__GET_NOTIFICATION_OPTIONS_FAILURE,
    ],
    uri: `${apiUrl}/users/user_notification_options`,
    method: 'GET',
  };
}

export function setProfileNotificationOptions(options) {
  return {
    types: [
      types.PROFILE__UPDATE_NOTIFICATION_OPTIONS_REQUEST,
      types.PROFILE__UPDATE_NOTIFICATION_OPTIONS_SUCCESS,
      types.PROFILE__UPDATE_NOTIFICATION_OPTIONS_FAILURE,
    ],
    uri: `${apiUrl}/users/update_user_notification_options`,
    method: 'POST',
    body: options,
    payload: options,
    successMessage: 'Preference saved.',
    failureMessage: 'Preference NOT saved.',
  };
}
