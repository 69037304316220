export const shortString = (s, l, startAtEnd = false) => {
  const stop_chars = [' ', '/', '&'];
  const acceptable_shortness = l * 0.8; // When to start looking for stop characters
  const str = startAtEnd ? s.split('').reverse().join('') : s;
  let short_s = '';

  for (var i = 0; i < l - 1; i++) {
    short_s += str[i];
    if (i >= acceptable_shortness && stop_chars.indexOf(str[i]) >= 0) {
      break;
    }
  }
  if (startAtEnd) {
    return short_s.split('').reverse().join('');
  }
  return short_s;
};

export const shortUrl = (url, length = 50) => {
  if (!url) {
    return '';
  }
  const chunk_l = length / 2;
  const shortUrl = url.replace('http://', '').replace('https://', '');

  if (shortUrl.length <= length) {
    return shortUrl;
  }

  const start_chunk = shortString(shortUrl, chunk_l, false);
  const end_chunk = shortString(shortUrl, chunk_l, true);
  return start_chunk + '..' + end_chunk;
};

// a: array
// n: number of columns in each row
// returns: array of column arrays (aka rows)
export const splitArrayIntoRows = (a, n) => {
  let output = [];
  let len = a.length;
  let i = 0;
  while (i < len) {
    let thisChunkSize = n;
    if (i + thisChunkSize >= len) {
      thisChunkSize = len - i;
    }
    output.push(a.slice(i, (i += thisChunkSize)));
  }
  return output;
};

export const formatDate = (dateString) => {
  if (!dateString) return null;
  var date = new Date(dateString);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var strDate = month + '/' + day + '/' + year;
  return strDate;
};

export const truncate = (text, chars) => {
  if (text) {
    if (text.length > chars) {
      return text.substr(0, chars - 1) + ' ...';
    } else {
      return text;
    }
  } else {
    return text;
  }
};

export const convertJsonDate = (jsonDate) => {
  const lDate = new Date(jsonDate);
  return lDate.toLocaleDateString();
};

/* For AI-3183 - Store the 'theme' perference
such that multiple users can select different
options on the same browser, and those options
should persist after logging out and back in.
Store a dictionary of userId / preference in
the 'theme' variable, and read it out as needed
*/
export const getThemeName = (userId) => {
  const theme = JSON.parse(localStorage.getItem('themeDict')) || {
    [userId]: 'default',
  };
  return theme[userId];
};

export const isHighContrastMode = (userId) => {
  const theme = JSON.parse(localStorage.getItem('themeDict')) || {
    [userId]: 'default',
  };
  return theme[userId] === 'highContrast';
};

export const toggleHighContrastMode = (userId) => {
  let theme = JSON.parse(localStorage.getItem('themeDict')) || {
    [userId]: 'default',
  };

  if ((theme[userId] || 'default') === 'default') {
    theme[userId] = 'highContrast';
  } else {
    theme[userId] = 'default';
  }
  localStorage.setItem('themeDict', JSON.stringify(theme));
  return theme[userId];
};

export const includesApprenticeProgram = (seeker) => {
  if (seeker && seeker.segments) {
    return seeker.segments.some((x) => x.isApprenticeProgram);
  }

  return false;
};

export const addressToString = (objAddress) => {
  let address = objAddress.addressLine1.trim();
  if (objAddress.addressLine2 !== '') {
    address += ' ' + objAddress.addressLine2.trim();
  }
  address += ', ' + objAddress.city;
  address += ', ' + objAddress.state.code;
  address += ' ' + objAddress.postalCode;

  return address;
};

export const getGoogleMapUrl = (address) => {
  let encodedAddress = '';

  if (
    address.city === undefined ||
    address.state?.code === undefined ||
    address.postalCode === undefined
  ) {
    encodedAddress = encodeURIComponent(address);
  } else {
    encodedAddress = encodeURIComponent(addressToString(address));
  }

  return `https://www.google.com/maps/search/${encodedAddress}`;
};

export const camelCase = (str) => {
  if (str === null || str === undefined) return str;
  if (typeof str !== 'string') return str;
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
