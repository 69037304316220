import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {deleteFlashMessage} from '../../actions/flash';

import FlashMessage from './FlashMessage';

class FlashMessages extends Component {
  dismissFlash(stamp) {
    this.props.dispatch(deleteFlashMessage(stamp));
  }

  render() {
    const flashMessages = this.props.flashMessages.map((message, index) => {
      return (
        <CSSTransition
          key={index}
          className="flash-wrapper"
          timeout={{enter: 800}}
        >
          <FlashMessage
            key={index}
            message={message}
            dismiss={() => {
              this.dismissFlash(message.timestamp);
            }}
          />
        </CSSTransition>
      );
    });

    return (
      <Fragment>
        <TransitionGroup
          className="flash-wrapper"
          aria-live="assertive"
          aria-atomic="true"
        >
          {flashMessages}
        </TransitionGroup>
      </Fragment>
    );
  }
}

FlashMessages.propTypes = {
  dispatch: PropTypes.func.isRequired,
  flashMessages: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    flashMessages: state.flashMessages?.list || [],
  };
}

export default connect(mapStateToProps)(FlashMessages);
