import _ from 'lodash';
import * as types from './ActionTypes';
import * as flashActions from './flash';
import {makeRequestAsync} from 'src/services/api';

const apiUrl = process.env.REACT_APP_API_URL;

export function getAscendRecruiters() {
  let uri = `${apiUrl}/ascend_recruiters?itemsPerPage=1000&`;

  return {
    types: [
      types.ASCEND_RECRUITERS__GET_ALL_REQUEST,
      types.ASCEND_RECRUITERS__GET_ALL_SUCCESS,
      types.ASCEND_RECRUITERS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getAscendRecruiter(recruiterId) {
  return {
    types: [
      types.ASCEND_RECRUITERS__GET_REQUEST,
      types.ASCEND_RECRUITERS__GET_SUCCESS,
      types.ASCEND_RECRUITERS__GET_FAILURE,
    ],
    uri: `${apiUrl}/ascend_recruiters/${recruiterId}`,
    method: 'GET',
  };
}

const cleanForUpdate = (adminProfile) => {
  const illegalFields = ['id', 'email', 'initials', 'name', 'userId'];
  let cleaned = JSON.parse(JSON.stringify(adminProfile));
  _.forEach(Object.keys(cleaned), (key) => {
    if (_.includes(illegalFields, key)) delete cleaned[key];
  });

  return cleaned;
};

export const updateAscendRecruiter = (ascendRecruiter) => async (dispatch) => {
  let body = cleanForUpdate(ascendRecruiter);
  let path = `ascend_recruiters/${ascendRecruiter.id}`;

  try {
    dispatch({type: types.ASCEND_RECRUITERS__UPDATE_REQUEST});
    let response = await makeRequestAsync('PATCH', path, body);
    if (response.status === 200) {
      dispatch({
        type: types.ASCEND_RECRUITERS__UPDATE_SUCCESS,
        payload: {ascendRecruiter: response.data},
        response: {body: response.data},
      });

      dispatch({
        type: types.SEEKERS__AVATAR_CHANGED,
        data: response.data,
      });

      flashActions.delayFlashDispatchForScreenReader(() => {
        dispatch(
          flashActions.addFlashMessage('Profile saved.', 'notification')
        );
      });
    }
  } catch (err) {
    flashActions.delayFlashDispatchForScreenReader(() => {
      dispatch(flashActions.addFlashMessage('Error saving profile.'));
    });
    dispatch({
      type: types.ASCEND_RECRUITERS__UPDATE_FAILURE,
      payload: err,
    });
  }
};
