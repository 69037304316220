import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {TextField} from 'src/formFields';
import {AscendModal, AscendModalBody} from '../layout';
import {Field, Form} from 'react-final-form';
import * as userActions from 'src/actions/users';
import {connect} from 'react-redux';
import CheckboxField from 'src/formFields/CheckboxField';

class EulaModal extends React.Component {
  state = {
    isOpen: this.props.isOpen,
  };

  formRef = React.createRef();

  handleToNetworkClick = () => {
    // Fire Action to Update EULA for User ID
    this.props.updateTermsAccepted(
      this.props.userId,
      this.formRef.current.getFieldState('eula').value,
      this.formRef.current.getFieldState('termsAcceptedby').value
    );

    // Hide the Model Dialog by Setting isOpen to false
    this.setState({
      isOpen: false,
    });
  };

  setScroll = () => {
    if (this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  };

  render() {
    this.setScroll();
    return (
      <Fragment>
        <AscendModal
          isOpen={this.state.isOpen}
          disableClose={true}
          centerVertically={true}
        >
          <AscendModalBody bodyClass="bd-modalbody-24" hasClose={false}>
            <Form onSubmit={() => {}}>
              {({handleSubmit, form}) => {
                this.formRef.current = form;
                return (
                  <div style={{padding: '24px'}}>
                    <div
                      className="normal-headline bold padding-bottom-sixteen"
                      style={{textAlign: 'center'}}
                    >
                      Terms of Service and Privacy Policy
                    </div>
                    <div
                      className="secondary-subheader centered-text"
                      style={{textAlign: 'center'}}
                    >
                      Please review our{' '}
                      <a
                        href={'https://ascendindiana.com/terms-of-service'}
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        terms of service
                      </a>{' '}
                      and{' '}
                      <a
                        href={'https://ascendindiana.com/privacy-policy'}
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        privacy policy
                      </a>
                      .
                      <div
                        className="padding-bottom-sixteen"
                        style={{textAlign: 'center'}}
                      >
                        By checking this box you acknowledge that you agree to
                        the terms of service and privacy policy.
                      </div>
                      <div
                        className="padding-bottom-sixteen inlineblock"
                        style={{textAlign: 'center'}}
                      >
                        <Field
                          name="eula"
                          component={CheckboxField}
                          style={{}}
                          showBackground={false}
                          required
                          label={
                            <span>
                              I have read & agree to the{' '}
                              <a
                                className="underline"
                                target="_blank"
                                rel="noreferrer"
                                href="https://ascendindiana.com/terms-of-service"
                              >
                                terms of service
                              </a>{' '}
                              and{' '}
                              <a
                                className="underline"
                                target="_blank"
                                rel="noreferrer"
                                href="https://ascendindiana.com/privacy-policy"
                              >
                                privacy policy
                              </a>
                              .
                            </span>
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mx-auto secondary-body">
                        Please provide your full name.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <Field
                          ariaLabel="Please provide your full name."
                          name="termsAcceptedby"
                          component={TextField}
                          size={12}
                          label=""
                          showLabel={false}
                          maxLength={64}
                          showBackground={false}
                        />
                      </div>
                    </div>

                    <div className="normal-subheader padding-bottom-sixteen">
                      <div
                        className="normal-subheader"
                        style={{textAlign: 'center'}}
                      >
                        Click the button below to begin.
                      </div>
                      <br />
                      <div style={{width: '100%', textAlign: 'center'}}>
                        <button
                          disabled={
                            !form.getFieldState('eula')?.value ||
                            !form.getFieldState('termsAcceptedby')?.value ||
                            !/\S/.test(
                              form.getFieldState('termsAcceptedby')?.value
                            )
                          }
                          onClick={this.handleToNetworkClick}
                          className={`clickable btn btn-primary`}
                        >
                          Take me to the Network
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Form>
          </AscendModalBody>
        </AscendModal>
        {this.props.children}
      </Fragment>
    );
  }
}

EulaModal.propTypes = {
  updateTermsAccepted: PropTypes.func.isRequired,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  url: PropTypes.string,
  eula: PropTypes.bool,
  termsAcceptedby: PropTypes.string,
  userProfile: PropTypes.object,
  userId: PropTypes.number.isRequired,
};

EulaModal.defaultProps = {
  isOpen: false,
  eula: false,
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.profile,
    termsAcceptedSuccess: state.users.termsAcceptedSuccess,
    termsAcceptedError: state.users.termsAcceptedError,
  };
};

const mapActionsToProps = {
  ...userActions,
};

export default connect(mapStateToProps, mapActionsToProps)(EulaModal);
