import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {ExperienceFieldArray} from 'src/formFields';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';

export default function WorkExperience({
  next,
  previous,
  intakeCache,
  headerImage,
  isResumeRequired,
}) {
  const blankExperience = {
    company: '',
    notes: '',
    timeframe: '',
    title: '',
  };
  const requiredArray = (value) =>
    !isResumeRequired || (value && value.length) > 0 ? undefined : 'Required';

  let initialExperience = [blankExperience];
  if (intakeCache.experience && intakeCache.experience.length > 0) {
    initialExperience = intakeCache.experience;
  }

  return (
    <>
      <PageHeader
        title="Add your most recent work experience"
        subTitle={
          isResumeRequired ? null : (
            <>
              Adding experiences is optional but makes you shine for employers.
              Select <b>Continue</b> when you’re ready to move on.
            </>
          )
        }
        headerImage={headerImage}
      />
      <Form
        onSubmit={next}
        mutators={{...arrayMutators}}
        initialValues={{
          experience: initialExperience,
        }}
      >
        {({handleSubmit, form}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.pageContent}>
                <div style={{maxWidth: '700px'}}>
                  <FieldArray
                    name="experience"
                    component={ExperienceFieldArray}
                    maxFields={20}
                    validate={requiredArray}
                    blankExperience={blankExperience}
                    isResumeRequired={isResumeRequired}
                  />
                </div>
              </div>
              <PageFooter
                next={() => form.submit()}
                previous={previous}
                getValues={() => form.getState().values}
              />
            </form>
          );
        }}
      </Form>
    </>
  );
}

WorkExperience.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  headerImage: PropTypes.any,
  isResumeRequired: PropTypes.bool,
};
