import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';

import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  meetingStatuses: [],
  meetingStatusesLoading: false,
};

export default function meetingStatuses(state = initialState, action) {
  switch (action.type) {
    case types.MEETING_STATUSES__GET_REQUEST:
      return {...state, roleTypes: [], roleTypesLoading: true};
    case types.MEETING_STATUSES__GET_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        meetingStatuses: action.response.body.meetingStatuses,
        meetingStatusesLoading: false,
      };
    default:
      return state;
  }
}
