import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './RangeSlider.module.scss';
import {isHighContrastMode} from 'src/utils/miscHelper';

export default function RangeSlider({
  min = 0,
  max = 100,
  step = 1,
  defaultValue = 0,
  onChange = () => {},
  isDisabled = false,
  rangeLabel = '',
  leftLabel = '',
  rightLabel = '',
}) {
  const inputRef = useRef();
  const [isChanging, setIsChanging] = useState(false);

  const getPercent = useMemo(
    () => (value) => ((value - min) / (max - min)) * 100,
    [max, min]
  );

  const changeInputProgressPercentStyle = useCallback(() => {
    inputRef.current.style.setProperty(
      '--webkitProgressPercent',
      `${getPercent(inputRef.current.value)}%`
    );
  }, [getPercent]);

  useEffect(() => {
    changeInputProgressPercentStyle();
    const inputElement = inputRef.current;

    const handleUpAndLeave = () => setIsChanging(false);
    const handleDown = () => setIsChanging(true);

    inputElement.addEventListener('mousemove', changeInputProgressPercentStyle);
    inputElement.addEventListener('mousedown', handleDown);
    inputElement.addEventListener('mouseup', handleUpAndLeave);
    inputElement.addEventListener('mouseleave', handleUpAndLeave);
    return () => {
      inputElement.removeEventListener(
        'mousemove',
        changeInputProgressPercentStyle
      );
      inputElement.removeEventListener('mousedown', handleDown);
      inputElement.removeEventListener('mouseup', handleUpAndLeave);
      inputElement.removeEventListener('mouseleave', handleUpAndLeave);
    };
  }, [isChanging, changeInputProgressPercentStyle]);

  useEffect(() => {
    if (!inputRef?.current) return;
    changeInputProgressPercentStyle();
  }, [inputRef, changeInputProgressPercentStyle]);

  const highContrastMode = () => {
    return isHighContrastMode ? 'highContrast' : '';
  };
  return (
    <div className={styles.rangeContainer}>
      {leftLabel !== '' && (
        <span className={styles.sideLabel}>{leftLabel}</span>
      )}
      <input
        tabIndex={0}
        className={`${styles.ascendRange} ${highContrastMode}`}
        type="range"
        name="range"
        aria-label={rangeLabel}
        ref={inputRef}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={isDisabled}
      />
      {rightLabel !== '' && (
        <span className={styles.sideLabel}>{rightLabel}</span>
      )}
    </div>
  );
}

RangeSlider.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  rangeLabel: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
};
