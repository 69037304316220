import * as types from '../actions/ActionTypes';

// jobCards: {
//   156: {
//     tabIndex: 0,
//     maximized: false
//   }
// }
const initialState = {
  jobCards: {},
};

export default function recruiterJobCards(state = initialState, action) {
  switch (action.type) {
    case types.JOB_CARD__CREATE:
      return {
        ...state,
        jobCards: {
          ...state.jobCards,
          [action.jobId]: {
            tabIndex: 0,
            maximized: false,
          },
        },
      };
    // Assumes job card exists already
    case types.JOB_CARD__UPDATE_TAB_INDEX:
      return {
        ...state,
        jobCards: {
          ...state.jobCards,
          [action.jobId]: {
            ...state.jobCards[action.jobId],
            tabIndex: action.tabIndex,
          },
        },
      };
    // Assumes job card exists already
    case types.JOB_CARD__UPDATE_MAXIMIZED:
      return {
        ...state,
        jobCards: {
          ...state.jobCards,
          [action.jobId]: {
            ...state.jobCards[action.jobId],
            maximized: action.maximized,
          },
        },
      };
    default:
      return state;
  }
}
