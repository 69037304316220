import * as ACTIONS from '../actions/ActionTypes';
import {CANDIDATE_DISCOVERY} from 'src/pages/candidateDiscovery/candidateDiscoveryGlobals';

const initialSavedCandidateState = {
  savedCandidatesExpandedSectionIndexes: [],

  savedCandidatesRoleTypeCountToDisplay: 6,
  savedCandidatesRegionCountToDisplay: 6,
  savedCandidatesIndustryCountToDisplay: 6,
  savedCandidatesSkillCountToDisplay: 6,
  savedCandidatesMajorCountToDisplay: 6,
  savedCandidatesGraduationYearCountToDisplay: 6,
  savedCandidateSelectedFieldValues: [],
  [CANDIDATE_DISCOVERY.savedCandidateSkillsFilter]: '',
  [CANDIDATE_DISCOVERY.savedCandidateRolesFilter]: '',
  [CANDIDATE_DISCOVERY.savedCandidateIndustryFilter]: '',
  [CANDIDATE_DISCOVERY.savedCandidateMajorsFilter]: '',
  savedCandidatesLoggedInAfter: '',
  savedCandidatesSortTerm: '-publishedAt',
  savedCandidatesPage: 0,
  savedCandidatesSearchTerm: '',
  savedCandidatesApplyButtonEnabled: false,
  savedCandidatesClearAllFiltersEnabled: false,
  savedCandidatesCollapseAllFilterSections: false,
  savedCandidatesApplyButtonBusy: false,
  savedCandidatesJobObj: null,
  watchingTeamId: null,
};

const initialExploreCandidateState = {
  expandedSectionIndexes: [],
  exploreCandidatesRoleTypeCountToDisplay: 6,
  exploreCandidatesRegionCountToDisplay: 6,
  exploreCandidatesIndustryCountToDisplay: 6,
  exploreCandidatesSkillCountToDisplay: 6,
  exploreCandidatesMajorCountToDisplay: 6,
  exploreCandidatesGraduationYearCountToDisplay: 6,
  selectedFieldValues: [],
  [CANDIDATE_DISCOVERY.skillsFilter]: '',
  [CANDIDATE_DISCOVERY.rolesFilter]: '',
  [CANDIDATE_DISCOVERY.industryFilter]: '',
  [CANDIDATE_DISCOVERY.majorsFilter]: '',
  exploreCandidatesLoggedInAfter: '',
  sortTerm: '-publishedAt',
  page: 0,
  itemsPerPage: 25,
  seekersTotalItems: 0,
  jobObj: null,
  searchTerm: '',
  preselectJobRequestLoading: false,
  applyButtonEnabled: false,
  clearAllFiltersEnabled: false,
  collapseAllFilterSections: false,
  applyButtonBusy: false,
  savedSearchSelectedItem: null,
};

const initialState = {
  ...initialExploreCandidateState,
  ...initialSavedCandidateState,
};

const REDUCER_ACTIONS = {};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_EXPANDED_SECTION] = (
  state,
  action
) => {
  if (state.expandedSectionIndexes.some((x) => x.index === action.index)) {
    return {
      ...state,
      collapseAllFilterSections: false,
      expandedSectionIndexes: state.expandedSectionIndexes.filter(
        (i) => i.index !== action.index
      ),
    };
  }
  return {
    ...state,
    collapseAllFilterSections: false,
    expandedSectionIndexes: [
      ...state.expandedSectionIndexes,
      {index: action.index, sectionName: action.sectionName},
    ],
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__INCREASE_LIST_ITEM_COUNT] = (
  state,
  action
) => {
  return {
    ...state,
    [action.itemCountName]: state[action.itemCountName] + 10,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_LIST_ITEM] = (
  state,
  action
) => {
  const interestedField = 'interested-true#Interested';
  const appliedField = 'applied-true#Applied';
  const invitedField = 'invited-true#Invited';
  if (state.selectedFieldValues.includes(action.toggledName)) {
    // Un-toggle a selected filter
    return {
      ...state,
      selectedFieldValues: state.selectedFieldValues.filter(
        (i) => i !== action.toggledName
      ),
      toggledName: action.toggledName,
      applyButtonEnabled: true,
    };
  }

  // we need to make sure only one candidate activity is selected at a time. API can only handle one
  if (
    action.toggledName === interestedField ||
    action.toggledName === appliedField ||
    action.toggledName === invitedField
  ) {
    let filterList = state.selectedFieldValues.filter(
      (i) => i !== interestedField && i !== appliedField && i !== invitedField
    );

    return {
      ...state,
      selectedFieldValues: [...filterList, action.toggledName],
      page: 0,
      clearAllFiltersEnabled: true,
      applyButtonEnabled: true,
    };
  }

  // Toggle a selected filter
  return {
    ...state,
    selectedFieldValues: [...state.selectedFieldValues, action.toggledName],
    page: 0,
    clearAllFiltersEnabled: true,
    applyButtonEnabled: true,
  };
};
REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__TOGGLE_LIST_DEFAULT] = (
  state,
  action
) => {
  return {
    ...state,
    selectedFieldValues: action.toggledName ? [action.toggledName] : [],
    page: 0,
    clearAllFiltersEnabled: true,
    applyButtonEnabled: false,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__DATE_RADIO_SELECT] = (
  state,
  action
) => {
  return {
    ...state,
    [action.toggledName]: action.toggledValue,
    page: 0,
    applyButtonEnabled: true,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__FILTER_LIST] = (state, action) => {
  return {
    ...state,
    [action.filterType]: action.filterValue,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__CLEAR_ALL] = (state) => {
  return {...state, ...initialExploreCandidateState};
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__JOB_SELECT] = (state, action) => {
  const sort =
    !action.filterTag && action.jobObj !== null
      ? '-overallScore'
      : '-publishedAt';

  return {
    ...state,
    jobObj: action.jobObj,
    page: 0,
    sortTerm: sort,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__TEAM_SELECT] = (state, action) => {
  return {
    ...state,
    watchingTeamId: action.watchingTeamId,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SORT_CHANGE] = (state, action) => {
  return {
    ...state,
    sortTerm: action.sortTerm,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__CHANGE_PAGE] = (state, action) => {
  return {
    ...state,
    page: action.page,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SEARCH_TERM] = (state, action) => {
  return {
    ...state,
    searchTerm: action.searchTerm,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SET_FILTER_BUTTON_ENABLED] = (
  state,
  action
) => {
  return {
    ...state,
    applyButtonEnabled: action.applyButtonEnabled,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SET_CLEAR_FILTER_BUTTON_ENABLED] =
  (state, action) => {
    return {
      ...state,
      clearAllFiltersEnabled: action.clearAllFiltersEnabled,
    };
  };

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SET_COLLAPSE_ALL_FILTER_SECTIONS] =
  (state, action) => {
    return {
      ...state,
      collapseAllFilterSections: action.collapseAllFilterSections,
    };
  };

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SET_APPLY_BUSY] = (
  state,
  action
) => {
  return {
    ...state,
    applyButtonBusy: action.applyButtonBusy,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__PRESELECT_JOB_REQUEST_LOADING] = (
  state,
  action
) => {
  return {
    ...state,
    preselectJobRequestLoading: action.loading,
  };
};

REDUCER_ACTIONS[ACTIONS.CANDIDATE_DISCOVERY__SAVED_SEARCH_FILTER_CHANGED] = (
  state,
  action
) => {
  return {
    ...state,
    savedSearchSelectedItem: action.savedSearchSelectedItem,
  };
};

export default function candidateDiscoveryReducer(
  state = initialState,
  action
) {
  if (REDUCER_ACTIONS[action.type]) {
    return REDUCER_ACTIONS[action.type](state, action);
  }
  return state;
}

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__TOGGLE_EXPANDED_SECTION] = (
  state,
  action
) => {
  if (
    state.savedCandidatesExpandedSectionIndexes.some(
      (x) => x.index === action.index
    )
  ) {
    return {
      ...state,

      savedCandidatesCollapseAllFilterSections: false,
      savedCandidatesExpandedSectionIndexes:
        state.savedCandidatesExpandedSectionIndexes.filter(
          (i) => i.index !== action.index
        ),
    };
  }
  return {
    ...state,
    savedCandidatesCollapseAllFilterSections: false,
    savedCandidatesExpandedSectionIndexes: [
      ...state.savedCandidatesExpandedSectionIndexes,
      {index: action.index, sectionName: action.sectionName},
    ],
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__INCREASE_LIST_ITEM_COUNT] = (
  state,
  action
) => {
  return {
    ...state,
    [action.itemCountName]: state[action.itemCountName] + 10,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__TOGGLE_FILTER_ITEM] = (
  state,
  action
) => {
  const interestedField = 'interested-true#Interested';
  const appliedField = 'applied-true#Applied';
  const invitedField = 'invited-true#Invited';

  if (state.savedCandidateSelectedFieldValues.includes(action.toggledName)) {
    // Un-toggle a selected filter
    return {
      ...state,
      savedCandidateSelectedFieldValues:
        state.savedCandidateSelectedFieldValues.filter(
          (i) => i !== action.toggledName
        ),
      toggledName: action.toggledName,
      savedCandidatesApplyButtonEnabled: true,
    };
  }

  // we need to make sure only one candidate activity is selected at a time. API can only handle one
  if (
    action.toggledName === interestedField ||
    action.toggledName === appliedField ||
    action.toggledName === invitedField
  ) {
    let filterList = state.savedCandidateSelectedFieldValues.filter(
      (i) => i !== interestedField && i !== appliedField && i !== invitedField
    );

    return {
      ...state,
      savedCandidateSelectedFieldValues: [...filterList, action.toggledName],
      page: 0,
      savedCandidatesClearAllFiltersEnabled: true,
      savedCandidatesApplyButtonEnabled: true,
    };
  }

  // Toggle a selected filter
  return {
    ...state,
    savedCandidateSelectedFieldValues: [
      ...state.savedCandidateSelectedFieldValues,
      action.toggledName,
    ],
    savedCandidatesPage: 0,
    savedCandidatesClearAllFiltersEnabled: true,
    savedCandidatesApplyButtonEnabled: true,
  };
};
REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__TOGGLE_LIST_DEFAULT] = (
  state,
  action
) => {
  return {
    ...state,
    savedCandidateSelectedFieldValues: action.toggledName
      ? [action.toggledName]
      : [],
    savedCandidatesPage: 0,
    savedCandidatesClearAllFiltersEnabled: true,
    savedCandidatesApplyButtonEnabled: false,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__DATE_RADIO_SELECT] = (
  state,
  action
) => {
  return {
    ...state,
    [action.toggledName]: action.toggledValue,
    savedCandidatesPage: 0,
    savedCandidatesApplyButtonEnabled: true,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__FILTER_LIST] = (state, action) => {
  return {
    ...state,
    [action.filterType]: action.filterValue,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__CLEAR_ALL] = (state) => {
  return {...state, ...initialSavedCandidateState};
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__SORT_CHANGE] = (state, action) => {
  return {
    ...state,
    savedCandidatesSortTerm: action.sortTerm,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__CHANGE_PAGE] = (state, action) => {
  return {
    ...state,
    savedCandidatesPage: action.page,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__SEARCH_TERM] = (state, action) => {
  return {
    ...state,
    savedCandidatesSearchTerm: action.searchTerm,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__SET_FILTER_BUTTON_ENABLED] = (
  state,
  action
) => {
  return {
    ...state,
    savedCandidatesApplyButtonEnabled: action.applyButtonEnabled,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__SET_CLEAR_FILTER_BUTTON_ENABLED] = (
  state,
  action
) => {
  return {
    ...state,
    savedCandidatesClearAllFiltersEnabled: action.clearAllFiltersEnabled,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__SET_COLLAPSE_ALL_FILTER_SECTIONS] = (
  state,
  action
) => {
  return {
    ...state,
    savedCandidatesCollapseAllFilterSections: action.collapseAllFilterSections,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__SET_APPLY_BUSY] = (state, action) => {
  return {
    ...state,
    savedCandidatesApplyButtonBusy: action.applyButtonBusy,
  };
};

REDUCER_ACTIONS[ACTIONS.SAVED_CANDIDATE__JOB_SELECT] = (state, action) => {
  const sort =
    !action.filterTag && action.savedCandidatesJobObj !== null
      ? '-overallScore'
      : '-publishedAt';

  return {
    ...state,
    savedCandidatesJobObj: action.savedCandidatesJobObj,
    page: 0,
    sortTerm: sort,
  };
};
