import React from 'react';
import { useState } from 'react';

import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {
    CompanySvg, 
    HomeSvg, 
    LogOutSvgBlack, 
    JobsSvg,
    MenuSvg, 
    TipSvg, 
    HeartDefaultSvg, 
    NotificationBellSvg,
    Contrast,
    EditSvg,
    CloseSvg
} from 'src/assets/svg/svgComponents';
import {useNavigate} from 'react-router';
import {actions as authActions} from 'src/reducers/authentication';
import {useDispatch, useSelector} from 'react-redux';
import {isHighContrastMode, toggleHighContrastMode} from 'src/utils/miscHelper';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import ChangePasswordModal from 'src/components/profile/ChangePasswordModal';
import Avatar from 'src/components/Avatar';
import styles from './MobileNav.module.scss';

export default function MobileMenuOptions(params) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const menuIsOpen = params.menuIsOpen;
    const setMenuIsOpen = params.setMenuIsOpen;

    const [changeEmailModalIsOpen, setChangeEmailModalIsOpen] = useState(false);
    const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] = useState(false);

    const userId = useSelector((state) => state.profile.id);
    const avatarUrl = useSelector((state) => state.profile.avatarUrl);
    const seekerName = useSelector((state) => state.profile.name);

    const toggleDropdown = () => {
        setMenuIsOpen(!menuIsOpen);
    };
    
    const renderDivider = () => {
        return <DropdownItem divider />;
    };
  
    const onNavigateClick = (destination) => {
        navigate(destination);
    };
    
    const onLogoutClick = () => {
        dispatch(authActions.logoutUser());
    };

    const onChangeEmailClick = () => {
        setChangeEmailModalIsOpen(true);
    };

    const onChangePasswordClick = () => {
        setChangePasswordModalIsOpen(true);
    };
        
    const toggleTheme = () => {
        const newThemeName = toggleHighContrastMode(userId);
        document.documentElement.className = newThemeName;
    };

    const renderMenuItem = (text, Icon, action, className) => {
        return (
            <DropdownItem
              tag="div"
              onClick={action}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  action();
                }
              }}
            >
              <div className={`clickable normal-body fullwidth p-1 ${styles.mobileNavMenuOption}`}>
                <Icon className={`${styles.mobileNavMenuIcon} ${className}`} />
                &nbsp;&nbsp;{text}
              </div>
            </DropdownItem>
          );
    };

    const renderMenuHeader = () => {
        return (
            <div className={`row ${styles.mobileNavMenuHeader}`}>
                <div className='col-auto'>
                    <Avatar
                        height={38}
                        width={38}
                        url={avatarUrl}
                        label="profileMenu"
                    />
                </div>
                <div className='col pe-0 ps-0'>
                    <div className={styles.mobileNavMenuSeekerName}>
                        {seekerName}
                    </div>
                    <div>
                        <button
                            onClick={() => onNavigateClick('/profile')}
                            className={`buttonAsLink ${styles.mobileNavMenuProfileLink}`}
                        >
                            View Profile
                        </button>
                    </div>
                </div>
                <div className={`col-auto ${styles.mobileNavMenuClose}`}>
                    <button
                        onClick={() => setMenuIsOpen(false)}
                        className="buttonAsLink"
                    >
                        <CloseSvg className={`col ${styles.mobileNavMenuIcon}`}/>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <Dropdown
                isOpen={menuIsOpen}
                direction="up"
                toggle={toggleDropdown}
            >
                <DropdownToggle className={`p-0 border-0 ${styles.mobileNavMenuToggle}`}>
                    <div>
                        <MenuSvg className={(menuIsOpen ? styles.mobileNavIconFillActive : styles.mobileNavIconFillDefault) + ` ${styles.mobileNavIcon}`} />
                        <div className={`nowrap ${styles.mobileNavText}`}>
                            Menu
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu className={styles.mobileNavMenuDropdown}>
                    {renderMenuHeader()}
                    {renderDivider()}
                    {renderMenuItem("Home", HomeSvg, () => onNavigateClick("/candidatehome"), styles.menuFill)}
                    {renderMenuItem("Explore Roles", JobsSvg, () => onNavigateClick("/find_roles"), styles.menuFill)}
                    {renderMenuItem("Explore Employers",  CompanySvg, () => onNavigateClick("/find_employers"), styles.menuFill)}
                    {renderMenuItem("Resources", TipSvg, () => onNavigateClick("/job_seeker_resources"), styles.menuFill)}
                    {renderDivider()}
                    {renderMenuItem("Favorites",  HeartDefaultSvg, () => onNavigateClick("/favorites"), styles.menuStroke)}
                    {renderMenuItem("Applications", JobsSvg, () => onNavigateClick("/applications"), styles.menuFill)}
                    {renderMenuItem("Notifications", NotificationBellSvg, () => onNavigateClick("/notifications"), styles.menuFill)}
                    {renderDivider()}
                    {renderMenuItem("Change Email", EditSvg, () => onChangeEmailClick(), styles.menuFill)}
                    {renderMenuItem("Change Password", EditSvg, () => onChangePasswordClick(), styles.menuFill)}
                    {renderMenuItem(`High Contrast Mode: ${isHighContrastMode(userId) ? 'On' : 'Off'}`, Contrast, () => toggleTheme(), styles.menuFill)}
                    {renderDivider()}
                    {renderMenuItem("Log Out", LogOutSvgBlack, onLogoutClick, styles.menuLogout)}
                   
                </DropdownMenu>
            </Dropdown>

            <ChangeEmailModal
              isOpen={changeEmailModalIsOpen}
              onClose={(e) => {
                setChangeEmailModalIsOpen(false);
              }}
              onCancel={(e) => {
                setChangeEmailModalIsOpen(false);
              }}
            />

            <ChangePasswordModal
              isOpen={changePasswordModalIsOpen}
              onClose={(e) => {
                setChangePasswordModalIsOpen(false);
              }}
            />
        </>
    );
}