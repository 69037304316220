import React, {useState, useEffect, useMemo} from 'react';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {PageHeader, PageFooter} from '../../layout';
import UniversityExplorerFilters from './UniversityExplorerFilters';
import UniversityExplorerFilterTags from './UniversityExplorerFilterTags';
import UniversityExplorerVisualization from './UniversityExplorerVisualization';

import explorerStyles from './UniversityExplorerPage.module.scss';

export default function UniversityExplorerPage() {
  const [appliedGroups, setAppliedGroups] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({
    school: [],
    graduationDateValue: [],
    major: [],
    gpa: [],
    isUnderrepresented: [],
  });
  const [queryResults, setQueryResults] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [studentSourceData, setStudentSourceData] = useState([]);

  const [fieldMap] = useState([
    {
      value: 'school',
      display: 'College or University',
    },
    {
      value: 'gpa',
      display: 'GPA',
    },
    {
      value: 'graduationDate',
      display: 'Graduation Date',
    },
    {
      value: 'major',
      display: 'Major(s)',
    },
    {
      value: 'isUnderrepresented',
      display: 'Underrepresented Students',
    },
    {
      value: 'count',
      display: 'Total',
    },
  ]);

  useEffect(() => {
    let studentData = require('./dsaData.json');

    // make a date value for sorting
    studentData.forEach(
      (student) =>
        (student['graduationDateValue'] = new Date(student['graduationDate']))
    );

    setStudentSourceData(studentData);
  }, []);

  useEffect(() => {
    // APPLY FILTERS
    let students = [...studentSourceData];

    // university
    if (appliedFilters['school'].length > 0) {
      students = students.filter((s) =>
        appliedFilters['school'].map((f) => f.value).includes(s.school)
      );
    }

    // gpa
    if (appliedFilters['gpa'].length > 0) {
      students = students.filter((s) =>
        appliedFilters['gpa'].map((f) => f.value).includes(s.gpa)
      );
    }

    // major
    if (appliedFilters['major'].length > 0) {
      students = students.filter((s) =>
        appliedFilters['major'].map((f) => f.value).includes(s.major)
      );
    }

    // underrepresented
    if (appliedFilters['isUnderrepresented'].length > 0) {
      students = students.filter((s) =>
        appliedFilters['isUnderrepresented']
          .map((f) => f.value)
          .includes(s.isUnderrepresented)
      );
    }

    // graduation date
    if (appliedFilters['graduationDateValue'].length > 0) {
      students = students.filter((s) =>
        appliedFilters['graduationDateValue']
          .map((f) => f.label)
          .includes(s.graduationDate)
      );
    }

    if (appliedGroups.length === 0) {
      setColumnHeaders(['No Filters', 'count']);
      setQueryResults([
        {
          'No Filters': 'All Students',
          count: students.length,
          chartGroup: 'All Students',
        },
      ]);
    } else {
      let newColumnHeaders = appliedGroups.map((group) => group);
      newColumnHeaders.push('count');

      const newQueryResults = students.reduce((accumulator, current) => {
        const existingEntryIndex = accumulator.findIndex((a) => {
          return appliedGroups.every((group) => {
            return a[group] === current[group];
          });
        });

        if (existingEntryIndex >= 0) {
          accumulator[existingEntryIndex].count++;
        } else {
          const newEntry = {
            count: 1,
            chartGroup: '',
          };

          appliedGroups.forEach((group) => {
            newEntry[group] = current[group];
            newEntry['chartGroup'] +=
              (newEntry['chartGroup'] === '' ? '' : ' | ') +
              current[group].replace(' - ', ' ');
          });

          accumulator.push(newEntry);
        }

        return accumulator;
      }, []);

      setQueryResults(
        newQueryResults.sort((a, b) => {
          const countA = a.count; // ignore upper and lowercase
          const countB = b.count; // ignore upper and lowercase
          if (countA > countB) {
            return -1;
          }
          if (countA < countB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      );
      setColumnHeaders(newColumnHeaders);
    }
  }, [appliedGroups, appliedFilters, studentSourceData]);

  const displayFilterTags = useMemo(() => {
    if (appliedGroups.length > 0) return true;

    const filterCounts = Object.keys(appliedFilters).map((filter) => {
      return appliedFilters[filter].length;
    });

    return filterCounts.some((filter) => filter > 0);
  }, [appliedFilters, appliedGroups]);

  const handleFilterTagRemove = (e) => {
    let newFilters = {...appliedFilters};

    const filterValueArray = newFilters[e.name];

    const index =
      e.name === 'graduationDateValue'
        ? filterValueArray.findIndex((tag) => tag.label === e.tagName)
        : filterValueArray.findIndex((tag) => tag.value === e.tagName);

    if (index > -1) {
      filterValueArray.splice(index, 1);
    }

    newFilters[e.name] = filterValueArray;

    setAppliedFilters(newFilters);
  };

  const handleGroupTagRemove = (e) => {
    let newGroups = [...appliedGroups];
    const index = newGroups.indexOf(e.name.toLowerCase());
    if (index > -1) {
      newGroups.splice(index, 1);
    }

    setAppliedGroups(newGroups);
  };

  const handleFilterChange = (e) => {
    let newFilters = {...appliedFilters};

    if (e.name === 'graduationDate') {
      newFilters[e.name] = e.value;
    } else {
      newFilters[e.name] = e.value;
    }

    setAppliedFilters(newFilters);
  };

  const handleGroupChange = (e) => {
    const fieldName = e.buttonValue;
    let newGroups = [...appliedGroups];

    if (e.isToggled) {
      if (newGroups.indexOf(fieldName) === -1) {
        newGroups.push(fieldName);
      }
    } else {
      const index = newGroups.indexOf(fieldName);
      if (index > -1) {
        newGroups.splice(index, 1);
      }
    }

    setAppliedGroups(newGroups);
  };

  return (
    <DocumentTitle title="Candidate Discovery">
      <div className="wholepage" style={{overflow: 'hidden'}}>
        <PageHeader title="University Data Explorer" showAdminLinks={false} />

        <main id="main" tabIndex="-1" style={{overflow: 'hidden'}}>
          <div className="bd-pagebody">
            <div
              className={explorerStyles.universityExplorerMainBody}
              style={{position: 'relative'}}
            >
              <div
                className={`${explorerStyles.universityExplorerRowTwo}  mb-4`}
                style={{marginTop: '24px'}}
              >
                <div className={`${explorerStyles.universityExplorerRowTwo}`}>
                  <div
                    id="universityFilters"
                    className={`${explorerStyles.universityExplorerFilterPanel}`}
                  >
                    <UniversityExplorerFilters
                      handleGroupChange={handleGroupChange}
                      handleFilterChange={handleFilterChange}
                      appliedGroups={appliedGroups}
                      appliedFilters={appliedFilters}
                      studentSourceData={studentSourceData}
                    />
                  </div>
                </div>

                <div className="column ms-4">
                  {displayFilterTags && (
                    <div
                      className={`${explorerStyles.universityExplorerFilterTags}`}
                    >
                      <UniversityExplorerFilterTags
                        appliedGroups={fieldMap.filter((field) =>
                          appliedGroups.includes(field.value)
                        )}
                        appliedFilters={appliedFilters}
                        handleFilterTagRemove={handleFilterTagRemove}
                        handleGroupTagRemove={handleGroupTagRemove}
                      />
                    </div>
                  )}

                  <UniversityExplorerVisualization
                    appliedGroups={appliedGroups}
                    appliedFilters={appliedFilters}
                    queryResults={queryResults}
                    columnHeaders={columnHeaders}
                    fieldMap={fieldMap}
                    studentSourceData={studentSourceData}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <PageFooter />
      </div>
    </DocumentTitle>
  );
}
