import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../components/vendor/DocumentTitle';
import loadingGif from '../assets/images/loading.gif';
class LoadingPage extends React.Component {
  render() {
    return (
      <DocumentTitle title="Loading">
        <div className="fullindy">
          <div className="container">
            <br />
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div>
                  <div className="bd-whitebackground">
                    <div className="row">
                      <div className="col-12">
                        <center>
                          <div className="normal-jumbo bold">Loading...</div>
                          <hr />
                        </center>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2" />
                      <div className="col-md-8">
                        <br />
                        <div className="normal-title text-align-center">
                          {this.props.title}
                          <br />
                          <br />
                          <div className="normal-body">
                            {this.props.message}
                            {this.props.showSupportText && (
                              <>
                                <br />
                                <br />
                                If this message appears for more than 10
                                seconds, please email a screen shot to{' '}
                                <a href="mailto:support@ascendindiana.com">
                                  support@ascendindiana.com
                                </a>
                              </>
                            )}
                          </div>
                          <div className="text-align-center fullwidth bgcolorw">
                            <br />
                            <br />
                            <br />
                            <img
                              src={loadingGif}
                              height="200"
                              width="200"
                              alt="Loading..."
                            />
                            <p role="status">Loading...</p>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-2" />
        </div>
      </DocumentTitle>
    );
  }
}

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
  showSupportText: PropTypes.bool,
};

LoadingPage.defaultProps = {
  showSupportText: true,
};

export default LoadingPage;
