import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import * as flashActions from '../actions/flash';

import Avatar from '../components/Avatar';
import noavatar from '../assets/images/noavatar.svg';
import ImageCropperModal from 'src/components/ImageCropper/ImageCropperModal';

class AvatarField extends React.Component {
  state = {isCropModalOpen: false};

  onUploadFinish = (avatarUrl) => {
    this.props.input.onChange(avatarUrl);
    this.setState({isCropModalOpen: false});
  };

  onClose = () => {
    this.setState({isCropModalOpen: false});
  };

  render() {
    const {
      input: {value},
    } = this.props;
    return (
      <>
        <ImageCropperModal
          isOpen={this.state.isCropModalOpen}
          onClose={this.onClose}
          onSubmit={this.onUploadFinish}
          previousImage={value}
        />

        <div className="row padding-bottom-thirtytwo">
          <div className="col-md-4">
            <Avatar
              style={{borderRadius: '50%'}}
              height={89}
              width={89}
              url={value ? value : noavatar}
            />
          </div>
          <div className="col-md-8">
            <div className="normal-subheader bold padding-bottom-eight">
              Photo (optional)
            </div>

            <div className="disabled-caption padding-bottom-twelve">
              Image should be at least 300 x 300px and less than 10MB
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                this.setState({isCropModalOpen: true});
              }}
              className="btn btn-primary"
            >
              Upload a photo
            </button>
          </div>
        </div>
      </>
    );
  }
}

AvatarField.propTypes = {
  input: PropTypes.object,
  addFlashMessage: PropTypes.func.isRequired,
};

const actions = {
  ...flashActions,
};

export default connect(null, actions)(AvatarField);
