import React from 'react';
import PropTypes from 'prop-types';
import gradSvg from 'src/assets/images/grad.svg';
class CandidateEducationText extends React.Component {
  render() {
    const {seeker, isMaSegment} = this.props;

    let text = '\u00A0';
    const graduationMonth = seeker.graduationMonth || '';
    const graduationYear = seeker.graduationYear || '';
    const graduationDate = `${graduationMonth} ${graduationYear}`;

    const buildMaEducationText = () => {
      if (!seeker.highSchool && !seeker.otherInstitution) {
        return 'None Selected';
      }

      let institution = '';
      if (
        seeker.highSchool.toLowerCase() === 'other' &&
        seeker.otherInstitution
      ) {
        institution = `${seeker.otherInstitution}`;
        if (!graduationDate) {
          return institution;
        }

        return `${institution}, ${graduationDate}`;
      }

      if (!graduationDate) {
        return `${seeker.highSchool} (HS) `;
      }

      return `${seeker.highSchool} (HS), ${graduationDate}`;
    };

    const buildEducationLevelText = () => {
      if (!seeker.educationLevel) {
        return 'None Selected';
      }

      return seeker.educationLevel.name && ` ${seeker.educationLevel.name}, `;
    };

    if (seeker.id) {
      const institution =
        (seeker.institutionName && `${seeker.institutionName}, `) || '';
      const educationLevel = buildEducationLevelText();

      text = isMaSegment
        ? buildMaEducationText()
        : `${institution}${educationLevel}${graduationMonth} ${graduationYear}`;
    }
    return (
      <div
        aria-label="education"
        className="candidate-profile-title bold padding-bottom-eight"
      >
        {seeker.id && (
          <img className="padding-right-sixteen" src={gradSvg} alt="" />
        )}
        {text}
      </div>
    );
  }
}

CandidateEducationText.propTypes = {
  seeker: PropTypes.object,
  isMaSegment: PropTypes.bool,
};

CandidateEducationText.defaultProps = {
  seeker: {},
  isMaSegment: false,
};

export default CandidateEducationText;
