import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';

import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  employmentTypes: [],
  employmentTypesLoading: false,
};

export default function employmentTypes(state = initialState, action) {
  switch (action.type) {
    case types.EMPLOYMENT_TYPES__GET_REQUEST:
      return {...state, employmentTypes: [], employmentTypesLoading: true};
    case types.EMPLOYMENT_TYPES__GET_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        employmentTypes: action.response.body.employmentTypes,
        employmentTypesLoading: false,
      };
    default:
      return state;
  }
}
