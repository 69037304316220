import React from 'react';
import {CalendarSvg, InfoSvg} from 'src/assets/svg/svgComponents';
import PropTypes from 'prop-types';
import differenceInDays from 'date-fns/differenceInDays';
import {Tooltip as ReactTooltip} from 'react-tooltip';

export default function LastUpdated({
  publishedDate,
  textClassName,
  svgClassName,
  enableToolTip,
}) {
  const createTimeframe = () => {
    const currentDate = new Date();
    const timeDifference = differenceInDays(
      currentDate,
      new Date(publishedDate)
    );

    if (timeDifference === 0) {
      return 'today';
    } else if (timeDifference === 1) {
      return 'yesterday';
    } else if (timeDifference >= 2 && timeDifference <= 30) {
      return `${timeDifference} days ago`;
    } else {
      return '30+ days ago';
    }
  };

  return (
    <>
      <ReactTooltip
        id="apply-tooltip"
        className="solid-tooltip-dark"
        closeOnEsc={true}
      />
      <div
        style={{display: 'flex', alignItems: 'center'}}
        className={textClassName}
      >
        <CalendarSvg className={svgClassName} />
        &nbsp; Updated {createTimeframe()}
        &nbsp; &nbsp;
        {enableToolTip && (
          <InfoSvg
            style={{marginTop: '3px'}}
            width="12"
            height="12"
            data-tooltip-id="apply-tooltip"
            data-tooltip-hidden={!enableToolTip}
            data-tooltip-place="top"
            data-tooltip-html="This indicates the last time our team confirmed that the role is still open."
          />
        )}
      </div>
    </>
  );
}

LastUpdated.propTypes = {
  publishedDate: PropTypes.string,
  textClassName: PropTypes.any,
  svgClassName: PropTypes.any,
  enableToolTip: PropTypes.bool,
};

LastUpdated.defaultProps = {
  enableToolTip: false,
};
