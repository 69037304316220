import React, {useState} from 'react';
import PropTypes from 'prop-types';

import explorerStyles from './UniversityExplorerPage.module.scss';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

export default function UniversityExplorerChart({queryResults, appliedGroups}) {
  const [showStackedBar, setShowStackedBar] = useState(false);

  const [stackedQueryResults] = useState([
    {
      name: 'Ball State University',
      ClassA: {name: 'Accounting', count: 10},
      ClassB: {name: 'Architecture', count: 20},
      ClassC: {name: '', count: 30},
    },
    {
      name: 'Purdue University',
      ClassA: {name: 'Project Management', count: 90},
      ClassB: {name: 'Physical Therapy', count: 120},
      ClassC: {name: 'Sports Medicine', count: 50},
    },
    {
      name: 'Grace College',
      ClassA: {name: 'Accounting', count: 8},
      ClassB: {name: 'Physical Therapy', count: 12},
      ClassC: {name: 'Literature', count: 45},
    },
    {
      name: 'Indiana University - Kokomo',
      ClassA: {name: 'Accounting', count: 8},
      ClassB: {name: 'Project Management', count: 34},
      ClassC: {name: 'Biochemistry', count: 12},
    },
  ]);

  const colors = [
    '#2ABFEE',
    '#327693',
    '#FDB17F',
    '#636CE9',
    '#E97063',
    '#973764',
    '#F1CAF1',
    '#00B9B9',
  ];

  const renderCustomBarLabel = ({payload, x, y, width, height, value}) => {
    return (
      <text x={width + x} y={y + height / 2} fill="#666" dy={6} dx={6}>
        {value}
      </text>
    );
  };

  const calculateAxisWidth = () => {
    if (appliedGroups.length <= 1) {
      return 120;
    }

    return 150;
  };

  return (
    <div>
      <div
        className={`row ${explorerStyles.universityExplorerVisualizationChartContainer}`}
      >
        {!showStackedBar && (
          <ResponsiveContainer
            width="100%"
            height={queryResults.length === 1 ? 120 : queryResults.length * 60}
          >
            <BarChart
              data={queryResults}
              layout="vertical"
              margin={{top: 5, right: 30, left: 40, bottom: 20}}
            >
              <CartesianGrid strokeDasharray="3 3" />

              <Tooltip />
              <Bar
                dataKey="count"
                name="Total"
                barSize={30}
                maxBarSize={30}
                label={renderCustomBarLabel}
              >
                {queryResults.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    stroke={colors[index % colors.length]}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Bar>
              <YAxis
                dataKey="chartGroup"
                domain={[0, 1000]}
                type="category"
                width={calculateAxisWidth()}
              />
              <XAxis
                type="number"
                label={{
                  value: 'Number of Students',
                  position: 'bottom',
                  offset: 0,
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        )}

        {showStackedBar && (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={stackedQueryResults}
              layout="vertical"
              margin={{top: 5, right: 30, left: 50, bottom: 5}}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="name" />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="ClassA.count"
                name="ClassA"
                stackId="a"
                fill="#2ABFEE"
                barSize={30}
                maxBarSize={30}
              />
              <Bar
                dataKey="ClassB.count"
                name="ClassB"
                stackId="a"
                fill="#327693"
                barSize={30}
                maxBarSize={30}
              />
              <Bar
                dataKey="ClassC.count"
                name="ClassC"
                stackId="a"
                fill="#FDB17F"
                barSize={30}
                maxBarSize={30}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>

      <div className="text-align-right">
        <button
          className={`${explorerStyles.universityExplorerViewSelectionLink}`}
          onClick={() => setShowStackedBar(!showStackedBar)}
        >
          Toggle
        </button>
      </div>
    </div>
  );
}

UniversityExplorerChart.propTypes = {
  queryResults: PropTypes.array,
  appliedGroups: PropTypes.arrayOf(PropTypes.string),
};
