import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';

import {CloudUploadSvg} from '../../assets/svg/svgComponents';
import {makeRequest} from '../../services/api';
import { v4 as uuidV4 } from 'uuid';

const ONE_KB = 1024;
const ONE_MB = ONE_KB * 1024;
const TEN_MB = ONE_MB * 10;

export default function FileDrop(props) {
  const {
    fileReceived,
    handleS3File,
    setUploadingStatus,
    maxFiles = 10,
    maxFileSize = TEN_MB,
    dropErrorMessage = 'Please drop only PDF or Word file type here. Limit to 10 files.',
  } = props;
  const contentTypes = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    jpeg: 'image/jpeg',
    jpg: 'image/jpg',
    png: 'image/png',
  };
  const getS3Urls = async (fileExt = 'pdf') => {
    try {
      let uuid = uuidV4();
      const response = await makeRequest({
        method: 'GET',
        path: `v2/public_s3_url?objectName=${uuid}.${fileExt.toLowerCase()}&contentType=${
          contentTypes[fileExt.toLowerCase()]
        }`,
      });
      return [response.data.uploadUrl, response.data.publicUrl];
    } catch (error) {
      return [null, null];
    }
  };

  const getFileExtension = (filename) => {
    return filename.name.split('.').pop();
  };

  const onDrop = async (acceptedFiles) => {
    setUploadingStatus(true);
    const uploadFile = async (file, s3UploadUrl) => {
      const fileExt = getFileExtension(file);
      await axios.request({
        method: 'PUT',
        url: s3UploadUrl,
        headers: {
          'Content-Type': contentTypes[fileExt.toLowerCase()],
          Accept: '*/*',
          'x-amz-acl': 'public-read',
        },
        data: file,
      });
    };

    for (const file of acceptedFiles) {
      const fileExt = getFileExtension(file);

      const [s3UploadUrl, s3PublicUrl] = await getS3Urls(fileExt.toLowerCase());

      if (s3UploadUrl !== null) {
        await uploadFile(file, s3UploadUrl);
        handleS3File({
          s3UploadUrl: s3UploadUrl,
          s3PublicUrl: s3PublicUrl,
          fileName: file.name,
        });
      }
    }
    setUploadingStatus(false);
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    maxSize: maxFileSize,
    accept: {
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
  });

  const dropzoneStyle = useMemo(() => {
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: 'var(--neutral-gray-2)',
      borderStyle: 'dashed',
      backgroundColor: '#fff',
      color: 'var(--neutral-gray-1)',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    };

    const focusedStyle = {
      borderStyle: 'solid',
      borderColor: 'black',
      borderWidth: 3,
    };

    const activeStyle = {
      borderColor: '#bd4b0b',
    };

    const acceptStyle = {
      borderColor: '#bd4b0b',
    };

    const rejectStyle = {
      borderColor: '#ff1744',
    };
    return {
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
  }, [isFocused, isDragActive, isDragReject, isDragAccept]);

  const dzInput = getInputProps();

  return (
    <>
      {!fileReceived && (
        <div
          {...getRootProps({
            'aria-label': 'File drop upload area',
            style: dropzoneStyle,
          })}
        >
          <input
            {...getInputProps({
              name: 'applicationPdf',
            })}
            id="applicationPdf"
            onChange={(x) => {
              dzInput.onChange(x);
              // pdfFileInput.onChange(x);
            }}
          />
          {isDragActive && isDragAccept && (
            <p style={{margin: 'auto'}}>Drop the files here ...</p>
          )}
          {isDragActive && isDragReject && (
            <p style={{margin: 'auto'}}>{dropErrorMessage}</p>
          )}
          {!isDragActive && (
            <p style={{margin: 'auto'}}>
              <CloudUploadSvg style={{width: '24px', height: '24px'}} /> &nbsp;
              Drag and drop files here, or click to select files
            </p>
          )}
        </div>
      )}
    </>
  );
}

FileDrop.propTypes = {
  handleS3File: PropTypes.func,
  setFileReceived: PropTypes.func,
  fileReceived: PropTypes.bool,
  setUploadingStatus: PropTypes.func,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  dropErrorMessage: PropTypes.string,
};
