import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import arrowDown from '../assets/images/ArrowDown.svg';
import arrowUp from '../assets/images/ArrowUp.svg';

class Twisty extends React.Component {
  render() {
    const {isDown} = this.props;
    const imgSource = isDown ? arrowDown : arrowUp;
    return (
      <Fragment>
        {this.props.isH2 && (
          <h2
            className={`${this.props.headerStyles} nowrap`}
            style={{paddingTop: '10px', fontSize: '13px', fontWeight: '600'}}
          >
            {this.props.text}
          </h2>
        )}
        {!this.props.isH2 && (
          <h3
            className={`${this.props.headerStyles} nowrap`}
            style={{paddingTop: '10px', fontSize: '13px', fontWeight: '600'}}
          >
            {this.props.text}
          </h3>
        )}
        <img
          alt=""
          src={imgSource}
          className="twisty-chevron"
          style={this.props.imageStyle}
        />
      </Fragment>
    );
  }
}

Twisty.propTypes = {
  isDown: PropTypes.bool,
  text: PropTypes.string.isRequired,
  headerStyles: PropTypes.string,
  imageStyle: PropTypes.object,
  isH2: PropTypes.bool,
};

Twisty.defaultProps = {
  isDown: true,
  text: '',
  headerStyles: 'nowrap col-md-9',
  imageStyle: {},
  isH2: false,
};

export default Twisty;
