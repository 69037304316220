import React, {useState} from 'react';
import styles from './BannerNotification.module.scss';
import PropTypes from 'prop-types';
import {CloseSvg} from 'src/assets/svg/svgComponents';
import {Link} from 'react-router-dom';

export default function BannerNotification({
  title,
  bannerText,
  isDarkMode,
  isClosable = true,
  actions = [],
  link,
}) {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div
      className={
        isDarkMode ? `${styles.banner} ${styles.darkMode}` : `${styles.banner}`
      }
      style={{display: isVisible ? 'flex' : 'none'}}
    >
      <span className={`${styles.content}`}>
        <div className={`${styles.header}`}>{title}</div>
        <p className={`${styles.text}`}>{bannerText}</p>
      </span>
      {actions.length > 0 && (
        <span className={`${styles.actions}`}>
          {actions.map((x, i) => {
            return (
              <button
                className={`btn ${
                  i === 0 ? 'btn-primary' : 'btn-secondary-neutral'
                }`}
                style={{marginRight: i === 0 ? '8px' : 0}}
                onClick={x.action}
              >
                {x.label}
              </button>
            );
          })}
        </span>
      )}
      {link && (
        <span className={`${styles.actions}`} style={{justifyContent: 'end'}}>
          <Link className="btn btn-primary" target="_blank" to={link.to}>
            {link.label}
          </Link>
        </span>
      )}
      {isClosable && (
        <button
          onClick={() => setIsVisible(false)}
          className={`btn ${styles.close}`}
        >
          <CloseSvg />
        </button>
      )}
    </div>
  );
}

BannerNotification.propTypes = {
  title: PropTypes.string,
  bannerText: PropTypes.any,
  isDarkMode: PropTypes.bool,
  isClosable: PropTypes.bool,
  actions: PropTypes.array,
  link: PropTypes.any,
};
