import React from 'react';
import styles from '../onboarding.module.scss';
import OffRampFooter from './OffRampFooter';

export default function EducationOffRamp() {
  return (
    <>
      <div className={styles.pageContent} style={{paddingTop: '10px'}}>
        <h2>Alternate Resources</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p style={{textAlign: 'center', width: '80%'}}>
            We’re sorry! Currently, the Ascend Network does not have
            opportunities for job seekers who have yet to obtain a high school
            diploma or equivalent.
          </p>
          <br />
          <p style={{textAlign: 'center', width: '80%'}}>
            Are you looking for Indy Modern Apprenticeship Program (MAP)? If so,
            please email{' '}
            <a href="mailto:support@ascendindiana.com">
              support@ascendindiana.com
            </a>{' '}
            for assistance.
          </p>
          <br />
          <p style={{textAlign: 'center', width: '80%'}}>
            If not, we recommend scheduling an appointment with your local
            WorkOne Center. Work One Centers are state and federally funded
            American Job Centers that provide free career services and job
            placement support. WorkOne can help you create or refine a resume,
            explore career paths, and provide career advice. They can also
            direct you to resources relating to transportation, childcare, and
            continued education services.
          </p>
          <OffRampFooter showMA={true} />
        </div>
      </div>
    </>
  );
}

EducationOffRamp.propTypes = {};
