import React, {createRef, useState} from 'react';
import {Field} from 'react-final-form';

import PropTypes from 'prop-types';

export const radioYesNoOptions = [
  {value: 'true', label: 'Yes'},
  {value: 'false', label: 'No'},
];

export const AscendRadioField = ({
  name,
  label,
  options,
  labelCssClass,
  labelBold,
  errorMessage,
  radioHeight = '48px',
  radioMarginTop = '10px',
  onChange,
}) => {
  let inputRef = [];
  const labelCss = !labelCssClass
    ? `${labelBold ? 'normal-body bold' : 'secondary-body'}`
    : labelCssClass;
  const [fieldErrors, setFieldErrors] = useState();

  return (
    <>
      <div className="form form-group">
        <div style={{width: '100%', textAlign: 'left'}}>
          <fieldset>
            {label && (
              <legend className={labelCss} id={`label-${name}`}>
                {label}
              </legend>
            )}
            {options.map((opt, index) => {
              inputRef[index] = createRef();

              return (
                <div
                  style={{
                    display: 'flex',
                    height: radioHeight,
                  }}
                  key={index}
                >
                  <label
                    className="radio"
                    style={{
                      marginTop: radioMarginTop,
                      paddingRight: '5px',
                    }}
                  >
                    {opt.label}

                    <Field name={name} type="radio" value={opt.value}>
                      {({input, meta}) => {
                        const {touched, error, warning} = meta;
                        return (
                          <>
                            <input
                              name={input.name}
                              ref={inputRef[index]}
                              id={`${index} ${label}`}
                              value={opt.value}
                              type="radio"
                              checked={input.checked}
                              onChange={() => {
                                input.onChange(opt.value);
                                if (onChange) {
                                  onChange(opt.value);
                                }
                              }}
                              aria-label={
                                input.value === opt.value
                                  ? `option field for ${label} is currently selected`
                                  : `option field for ${label} is NOT currently selected`
                              }
                            />
                            <span className="radio-checkmark" />
                            {touched &&
                              ((error && <div>{setFieldErrors(error)}</div>) ||
                                (warning && (
                                  <div>{setFieldErrors(warning)}</div>
                                )) || (
                                  <div>{setFieldErrors(errorMessage)}</div>
                                ))}
                            {!touched && <div>{errorMessage}</div>}
                          </>
                        );
                      }}
                    </Field>
                  </label>
                </div>
              );
            })}
          </fieldset>
        </div>
        {fieldErrors && <div className="form-input-error">{fieldErrors}</div>}
      </div>
    </>
  );
};
AscendRadioField.propTypes = {
  name: PropTypes.string,
  radioHeight: PropTypes.string,
  radioMarginTop: PropTypes.string,
  label: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.any,
    })
  ),

  labelCssClass: PropTypes.string,
  labelBold: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
};
