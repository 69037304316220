// action types
import * as types from '../actions/ActionTypes';

// reducer
export const initialState = {
  isParsing: false,
  timedOut: false,
  segment: null,
  approved: true,
};

export default function resumes(state = initialState, action) {
  switch (action.type) {
    case types.POLL_GET_STATUS_REQUEST:
      return {...state, isParsing: true};
    case types.POLL_GET_STATUS_SUCCESS:
      return {...state, isParsing: false};
    case types.POLL_GET_STATUS_TIMEDOUT:
      return {...state, isParsing: false, timedOut: true};
    case types.GET_STATUS_SUCCESS:
      return {...state, ...action.response.data.resume};
    case types.GET_STATUS_FAILURE:
      return {...state, error: action.error};
    case types.RESUME_REVIEW_SET_INITIAL_SEGMENT:
      return {
        ...state,
        segment: action.segment,
      };
    case types.RESUME_REVIEW_RESET_APPROVED_TOGGLE:
      return {
        ...state,
        approved: action.approved,
      };
    default:
      return state;
  }
}

// actions
export const actions = {
  pollGetStatus: (uploadId) => ({
    type: types.POLL_GET_STATUS_REQUEST,
    uploadId,
  }),
};

// selectors

// munge resume summary to work with connectionForm fields
export const getSummaryForForm = (state) => {
  const fixName = (name) => {
    if (!name || name.length < 2) {
      return name;
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };
  const {summary} = state.resumes;
  if (!summary) {
    return;
  }
  return {
    ...summary,
    firstName: fixName(summary.firstName),
    lastName: fixName(summary.lastName),
  };
};

/*
  The intake form gathers name, email, and phone number
  before asking for a resume upload. If the resume's
  email is different than the user's login email,
  then the user's login may be broken after overwriting
  with the resume's email.
*/
export const getSummaryForIntakeForm = (resumesState) => {
  const {summary} = resumesState;
  if (!summary) {
    return;
  }
  for (let x of ['firstName', 'lastName', 'email', 'phone']) {
    delete summary[x];
  }
  return summary;
};

export const loadSegment = (segment) => ({
  type: types.RESUME_REVIEW_SET_INITIAL_SEGMENT,
  segment,
});

export const setResumeApproved = (approved) => ({
  type: types.RESUME_REVIEW_RESET_APPROVED_TOGGLE,
  approved,
});
