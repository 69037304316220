import React from 'react';
import styles from './AddTagButton.module.scss';
import PropTypes from 'prop-types';
import {AddCircleSvg} from '../../../assets/svg/svgComponents';
import {Tooltip as ReactTooltip} from 'react-tooltip';

export default function AddTagButton({
  tag = {tagName: 'Tag Name'},
  addAction,
  isMobile,
  tagTip,
}) {
  return (
    <>
      <ReactTooltip
        id="tooltip"
        className="solid-tooltip-dark"
        closeOnEsc={true}
      />
      <button
        tabIndex={0}
        className={`clickable ${styles.container} mt-2 ${
          isMobile ? styles.mobileWidth : ''
        }`}
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-html={tagTip ? tagTip : null}
        onClick={() => addAction(tag)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            addAction(tag);
          }
        }}
      >
        <div
          aria-label={`add ${tag.tagName}`}
          className={`${styles.leftSide} border-0`}
          style={{background: 'transparent', paddingLeft: 0}}
        >
          <AddCircleSvg className={`ms-2 ${styles.addSvgClass}`} />
          <label
            aria-label="tag"
            className={`clickable me-2 ${
              isMobile ? styles.rightSideMobile : styles.rightSideDesktop
            }`}
          >
            {tag.tagName}
          </label>
        </div>
      </button>
    </>
  );
}

AddTagButton.propTypes = {
  tag: PropTypes.object,
  addAction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  tagTip: PropTypes.string,
};
