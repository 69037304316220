import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {AscendModal, AscendModalBody, AscendModalHeader} from 'src/layout';
import {TextField} from 'src/formFields';
import {Field, Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {track} from 'src/utils/analytics';
import {reportJobIssue} from 'src/actions/jobs';
import BusyButtonMini from './BusyButtonMini';

export default function ReportIssueModal({job, seekerId, isOpen, onClose}) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [issueSubmitted, setIssueSubmitted] = useState(false);

  let {reportSuccessful, reportSaving, userProfile} = useSelector((state) => {
    return {
      reportSuccessful: state.jobs.reportSuccessful,
      reportSaving: state.jobs.reportSaving,
      userProfile: state.profile,
    };
  });

  const validate = (values) => {
    const errors = {};

    if (!values['issueReason']) {
      errors['otherIssueReason'] = 'Please select a reason';
    }

    if (values['issueReason'] === 'Other' && !values['otherIssueReason']) {
      errors['otherIssueReason'] =
        'If "Other" is selected, a reason must be entered';
    }
    return errors;
  };

  useEffect(() => {
    setIssueSubmitted(false);
  }, [isOpen]);

  const handleSubmitIssueClicked = async (values) => {
    let employmentTypes = '';
    if (job.employmentTypes && job.employmentTypes.length > 0) {
      employmentTypes = job.employmentTypes.map((et) => et.name).join(', ');
    }
    let userSegment = '';
    if (userProfile.segmentNames && userProfile.segmentNames.length > 0) {
      userSegment = userProfile.segmentNames.join(',');
    }
    let jobConnected = job.placements && job.placements.length > 0;

    let trackObj = {
      eventName: 'RoleIssueReported',
      eventData: {
        jobName: job.name,
        jobId: job.id,
        jobEmployerName: job.employer.name,
        jobEmploymentType: employmentTypes,
        jobDatePublished: job.publishedAt,
        jobConnected: jobConnected,
        userType: userProfile.securityRoleNames.join(','),
        email: userProfile.email,
        userSegment: userSegment,
      },
    };

    track(trackObj);

    dispatch(
      reportJobIssue(job.id, {
        seekerId: seekerId,
        issueReason: values.issueReason,
        otherIssueReason:
          values.issueReason === 'Other' ? values.otherIssueReason : null,
      })
    );
    setIssueSubmitted(true);
  };

  return (
    <AscendModal
      size="s"
      isOpen={isOpen}
      onClose={onClose}
      centerVertically={true}
    >
      <AscendModalHeader headerClass="bd-modalheader" hasClose={true}>
        <div className="text-align-center ">
          <h1
            style={{fontSize: '24px'}}
            className="normal-headline-white bold mb-0"
          >
            Report an Issue
          </h1>
        </div>
      </AscendModalHeader>

      <AscendModalBody>
        <Form onSubmit={handleSubmitIssueClicked} validate={validate}>
          {({handleSubmit, form}) => {
            formRef.current = form;
            return (
              <form>
                <OnChange name="issueReason">
                  {(issueReason) => {
                    if (issueReason !== 'Other') {
                      form.change('otherIssueReason', null);
                    }
                  }}
                </OnChange>

                {(!issueSubmitted || reportSaving) && (
                  <>
                    <fieldset>
                      <label className="radio ms-4 mt-0 mb-0 fontWeight600">
                        Position has been filled
                        <Field
                          name="issueReason"
                          component="input"
                          type="radio"
                          value="Position has been filled"
                        />
                        <span className="radio-checkmark-orange" />
                      </label>

                      <label className="radio ms-4 mt-3 mb-0 fontWeight600">
                        Link does not work
                        <Field
                          name="issueReason"
                          component="input"
                          type="radio"
                          value="Link does not work"
                        />
                        <span className="radio-checkmark-orange" />
                      </label>

                      <div className="row ms-0">
                        <label className="radio ms-4 mt-3 mb-0 fontWeight600">
                          Other:
                          <Field
                            name="issueReason"
                            component="input"
                            type="radio"
                            value="Other"
                          />
                          <span className="radio-checkmark-orange" />
                        </label>
                        &nbsp;
                        <Field
                          name="otherIssueReason"
                          component={TextField}
                          size={32}
                          maxLength={100}
                          disabled={
                            formRef.current.getState().values['issueReason'] !==
                            'Other'
                          }
                        />
                      </div>
                    </fieldset>
                    <div className="row justify-content-center mt-1">
                      <button
                        type="button"
                        className="btn btn-secondary-neutral me-2"
                        onClick={onClose}
                        style={{width: '150px', height: '40px'}}
                        disabled={reportSaving}
                      >
                        Cancel
                      </button>
                      <BusyButtonMini
                        onClick={handleSubmit}
                        buttonText={'Submit'}
                        busyText={'Submitting'}
                        style={{
                          width: '150px',
                          height: '40px',
                        }}
                        float={'float-right'}
                        alignText={'text-center'}
                        className="col-2 ms-2"
                        busy={reportSaving}
                      />
                    </div>
                  </>
                )}

                {issueSubmitted && !reportSaving && (
                  <>
                    {reportSuccessful && (
                      <div className="fontWeight600 text-align-center">
                        Thank you for helping us identify issues and for
                        improving the quality of the Ascend Network for all
                        users.
                      </div>
                    )}

                    {!reportSuccessful && (
                      <div className="fontWeight600 text-align-center">
                        An error occurred while logging the issue. Please
                        contact{' '}
                        <a
                          href="mailto:support@ascendindiana.com"
                          className="underline"
                        >
                          support@ascendindiana.com
                        </a>{' '}
                        so we can address the problem.
                      </div>
                    )}
                    <div className="row justify-content-center mt-4">
                      <button
                        type="button"
                        className="btn btn-secondary-neutral"
                        onClick={onClose}
                        style={{
                          width: '150px',
                          height: '40px',
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </>
                )}
              </form>
            );
          }}
        </Form>
      </AscendModalBody>
    </AscendModal>
  );
}

ReportIssueModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  issueReason: PropTypes.string,
  otherIssueReason: PropTypes.string,
  seekerId: PropTypes.string,
  job: PropTypes.object,
};
