import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import _ from 'lodash';

import * as workStyleActions from 'src/actions/workStyles';

import * as Layout from 'src/layout';
import {RadioGridField} from 'src/formFields';
import Loading from 'src/components/Loading';
import {BusyButton} from './BusyButton';

const responses = [
  {label: 'Not at all important', level: 1},
  {label: 'Not that important', level: 2},
  {label: 'Somewhat important', level: 3},
  {label: 'Very important', level: 4},
  {label: 'Extremely important', level: 5},
];

class KeyFactorsModal extends React.Component {
  componentDidMount() {
    this.props.getWorkStyles();
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const {isOpen, onClose, workStylesLoading} = this.props;

    const workStyles = _.orderBy(this.props.workStyles, ['order']);

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            keepDirtyOnReinitialize={true}
            initialValues={{workStyles: this.props.initialValues.workStyles}}
          >
            {({handleSubmit}) => {
              return (
                <form onKeyPress={this.onKeyPress}>
                  <fieldset>
                    <legend>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="bold active-headline text-align-center">
                            How important are the following factors as you
                            consider an opportunity?
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                    </legend>
                    <br />
                    {workStylesLoading && <Loading />}
                    {!workStylesLoading && (
                      <Field
                        name="workStyles"
                        component={RadioGridField}
                        options={workStyles}
                        responses={responses}
                      />
                    )}
                  </fieldset>
                  <div className="row justify-content-center nowrap">
                    <button
                      type="button"
                      className="btn btn-transparent col-2 me-4"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <BusyButton
                      onClick={handleSubmit}
                      buttonText={'Save'}
                      busyText={'Saving'}
                      className="col-2"
                      style={{width: '210px', height: '40px'}}
                      float={'float-right'}
                      buttonIcon={'save'}
                      alignText={'text-center'}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

KeyFactorsModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getWorkStyles: PropTypes.func.isRequired,
  workStyles: PropTypes.array,
  workStylesLoading: PropTypes.bool,
  initialValues: PropTypes.object,
};

const actions = {
  ...workStyleActions,
};

let EditKeyFactorsModal = (props) => <KeyFactorsModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  const {id, workStyles} = state.seekers.seekerForEdit;
  return {
    workStyles: state.workStyles.workStyles,
    workStylesLoading: state.workStyles.workStylesLoading,
    initialValues: {
      id,
      workStyles,
    },
  };
};

EditKeyFactorsModal = connect(
  editMapStateToProps,
  actions
)(EditKeyFactorsModal);

export {EditKeyFactorsModal};
