import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Layout from '../layout';

import styles from './PhotoGalleryModal.module.scss';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Thumbs, A11y} from 'swiper/modules';

import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import {ArrowRounded} from '../assets/svg/svgComponents';
import classNames from 'classnames';

export default function PhotoGalleryModal({
  isOpen = false,
  onClose,
  photos,
  initialSlideIndex,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <Layout.AscendModal
      size="xl"
      isOpen={isOpen}
      onClose={() => {
        setThumbsSwiper(null);
        onClose();
      }}
    >
      <Layout.AscendModalBody hasClose={true}>
        <div>
          <Swiper
            onSwiper={setThumbsSwiper}
            breakpoints={{
              // when window width is >= 760px
              760: {
                slidesPerView: 6,
                spaceBetween: 6,
              },
              320: {
                slidesPerView: 4,
                spaceBetween: 0,
                slidesPerGroup: 1,
                slidesPerGroupSkip: 1,
              },
            }}
            navigation={{
              nextEl: `.${styles.swiperButtonNext}`,
              prevEl: `.${styles.swiperButtonPrev}`,
            }}
            shortSwipes={false}
            loop={false}
            modules={[Navigation, Thumbs, A11y]}
            className={classNames([
              'padding-bottom-twentyfour',
              'padding-top-eight',
              styles.topSwiper,
            ])}
            initialSlide={initialSlideIndex}
            cssMode={true}
            threshold={1}
            a11y={{
              firstSlideMessage: '',
              lastSlideMessage: '',
              nextSlideMessage: '',
              prevSlideMessage: '',
            }}
          >
            {photos.map((photo, index) => {
              return (
                <SwiperSlide
                  className={styles.thumbsSlide}
                  key={index}
                  data-hash={`photo${photo.id}`}
                >
                  <img
                    src={
                      photo.imageThumbUrl === ''
                        ? photo.imageUrl
                        : photo.imageThumbUrl
                    }
                    alt={photo.altText}
                  />
                </SwiperSlide>
              );
            })}

            <div
              aria-label={'Next Image'}
              tabIndex={0}
              className={classNames([styles.swiperButtonNext])}
            >
              <ArrowRounded width="100%" height="35px" />
            </div>

            <div
              aria-label={'Previous Image'}
              tabIndex={0}
              className={classNames([styles.swiperButtonPrev])}
            >
              <ArrowRounded width="100%" height="35px" />
            </div>
          </Swiper>
          <div id={styles.largeSwiper}>
            <Swiper
              className="outline-bullet"
              modules={[Navigation, Pagination, Thumbs, A11y]}
              navigation={{
                nextEl: `.${styles.swiperButtonNext}`,
                prevEl: `.${styles.swiperButtonPrev}`,
              }}
              thumbs={{
                swiper: thumbsSwiper,
              }}
              pagination={true}
              loop={true}
              autoHeight={true}
              initialSlide={initialSlideIndex}
              a11y={{
                firstSlideMessage: '',
                lastSlideMessage: '',
                nextSlideMessage: '',
                prevSlideMessage: '',
              }}
              style={{
                '--swiper-pagination-bullet-inactive-color': 'transparent',
                '--swiper-pagination-bullet-opacity': 1,
                '--swiper-pagination-color': '#3061a9',
                '--swiper-pagination-bullet-size': '6px',
                '--swiper-pagination-bullet-inactive-opacity': 1,
              }}
            >
              {photos.map((photo, index) => {
                return (
                  <SwiperSlide className={styles.mainSlide} key={index}>
                    <img
                      aria-label={`Image ${index + 1} of ${photos.length}`}
                      tabIndex={0}
                      src={photo.imageUrl}
                      alt={photo.altText}
                    />
                  </SwiperSlide>
                );
              })}

              <div
                aria-label={'Next Image'}
                tabIndex={0}
                className={classNames([
                  styles.swiperButtonNext,
                  styles.largeSwiperVertical,
                ])}
              >
                <ArrowRounded width="100%" height="65px" />
              </div>
              <div
                aria-label={'Previous Image'}
                tabIndex={0}
                className={classNames([
                  styles.swiperButtonPrev,
                  styles.largeSwiperVertical,
                ])}
              >
                <ArrowRounded width="100%" height="65px" />
              </div>
            </Swiper>
          </div>
        </div>
      </Layout.AscendModalBody>
    </Layout.AscendModal>
  );
}

PhotoGalleryModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  photos: PropTypes.array.isRequired,
  initialSlideIndex: PropTypes.number,
};
