import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './HelpFromAnExpert.module.scss';
import Avatar from './Avatar';
import {
  DuplicateSvg,
  LinkSvg,
  InvitationSvg,
  ProfileSvg,
} from 'src/assets/svg/svgComponents';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {Link} from 'react-router-dom';
import {useIsMobile} from 'src/utils/commonHooks';

export default function HelpFromAnExpert({ascendRecruiter, firstPublishedAt}) {
  const [dataTip, setDataTip] = useState('Copy Email');
  const isMobile = useIsMobile(991);

  const copyEmail = () => {
    navigator.clipboard.writeText(ascendRecruiter.email).then(() => {
      setDataTip('Email Copied');
      setTimeout(function () {
        setDataTip('Copy Email');
      }, 3000);
    });
  };

  const createZeroState = () => {
    const hours_since_published = Math.floor(
      (new Date() - new Date(firstPublishedAt)) / 1000 / 60 / 60
    );

    if (hours_since_published < 24) {
      return null;
    }

    return (
      <div
        className="mb-3"
        style={{
          marginLeft: isMobile ? 'initial' : '58px',
          marginRight: isMobile ? 'initial' : '58px',
        }}
      >
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          clickable={true}
          closeOnEsc={true}
        />
        <div className="flex-row align-items-center d-flex flex-nowrap pb-1">
          <ProfileSvg width={'20px'} height={'20px'} /> &nbsp;&nbsp;
          <h2 className="font-20 bold no-decoration col-auto p-0 mb-0">
            Need some help?
          </h2>
        </div>

        <div
          className={`bd-whitebackground ${styles.banner}`}
          style={{minHeight: '110px'}}
        >
          <span
            className={`ps-3 pb-3 pt-3 pe-3 ${styles.content}`}
            style={{flexBasis: '100%'}}
          >
            <h3>Looks like you don’t have a contact assigned yet…</h3>
            {
              <p className="mb-1">
                For some reason, you don’t have an assigned point of contact
                yet. Please reach out to{' '}
                <a
                  href="mailto:support@ascendindiana.com"
                  className="underline"
                >
                  support@ascendindiana.com
                </a>{' '}
                so we can assist in assigning you to a point of contact.
              </p>
            }
          </span>
        </div>
      </div>
    );
  };

  const createCard = () => {
    return (
      <div
        className="mb-3"
        style={{
          marginLeft: isMobile ? 'initial' : '58px',
          marginRight: isMobile ? 'initial' : '58px',
        }}
      >
        <ReactTooltip
          id="tooltip"
          className="solid-tooltip-dark"
          clickable={true}
          closeOnEsc={true}
        />
        <div className="flex-row align-items-center d-flex flex-nowrap pb-1">
          <ProfileSvg width={'20px'} height={'20px'} /> &nbsp;&nbsp;
          <h2 className="font-20 bold no-decoration col-auto p-0 mb-0">
            Need some help?
          </h2>
        </div>

        <div className={`bd-whitebackground ${styles.banner}`}>
          <span className={`ps-3 pb-3 pt-3 pe-3`}>
            <Avatar
              height={75}
              width={75}
              url={ascendRecruiter.profileAvatarUrl}
            />
          </span>
          <span className={`ps-3 pb-3 pt-3 pe-3 ${styles.content}`}>
            <h3>{ascendRecruiter.name}</h3>
            {ascendRecruiter.title && (
              <p className="mb-1">
                <ProfileSvg className="profile_icon_gray" />
                &nbsp;&nbsp;{' '}
                {ascendRecruiter.company
                  ? `${ascendRecruiter.title} at ${ascendRecruiter.company.name}`
                  : ascendRecruiter.title}
              </p>
            )}
            <p className="mb-1">
              <InvitationSvg className="email_icon_gray" />
              &nbsp;&nbsp; {ascendRecruiter.email}&nbsp;&nbsp;
              <button
                onClick={copyEmail}
                className="buttonAsLink"
                data-tooltip-id="tooltip"
                data-tooltip-html={dataTip}
              >
                <DuplicateSvg className="duplicate_icon_gray" />
              </button>
            </p>
            {ascendRecruiter.externalUrl && (
              <p className="mb-1">
                <LinkSvg className="link_icon_gray" />
                &nbsp;&nbsp;{' '}
                <Link
                  to={ascendRecruiter.externalUrl}
                  target="_blank"
                  className="underline"
                >
                  {ascendRecruiter.externalUrl}
                </Link>
              </p>
            )}
          </span>
          <span className={`ps-3 pb-3 pe-3 ${styles.content}`}>
            {ascendRecruiter.aboutMe && (
              <p className="mb-1">{ascendRecruiter.aboutMe}</p>
            )}
          </span>
        </div>
      </div>
    );
  };

  return ascendRecruiter ? createCard() : createZeroState();
}

HelpFromAnExpert.propTypes = {
  ascendRecruiter: PropTypes.object,
  firstPublishedAt: PropTypes.string,
};
