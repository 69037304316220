import * as types from './ActionTypes';
import {cleanForUpdate} from '../utils/seekerHelper';
import {wageApiHelper} from '../utils/wageHelper';
import * as flashActions from './flash';
import {makeRequestAsync} from 'src/services/api';

export const completeSeekerAction =
  (seeker, messages = {}) =>
  async (dispatch) => {
    let body = cleanForUpdate(seeker);
    let path = `seekers/${seeker.id}/finish`;

    const successMessage = messages.success || 'Candidate saved.';
    const failureMessage = messages.failure || 'Error saving candidate.';

    try {
      let response = await makeRequestAsync('PATCH', path, body);
      if (response.status === 200) {
        dispatch(flashActions.addFlashMessage(successMessage, 'notification'));
        dispatch({
          type: types.SEEKERS__COMPLETE_SUCCESS,
          response: {body: response.data},
        });
      }
    } catch (err) {
      dispatch(flashActions.addFlashMessage(failureMessage));
      dispatch({
        type: types.SEEKERS__COMPLETE_FAILURE,
        payload: err,
      });
    }
  };

export const getSeekerAction = (seekerId) => async (dispatch) => {
  let path = `seekers/${seekerId}`;
  try {
    dispatch({
      type: types.SEEKERS__GET_REQUEST,
    });
    let response = await makeRequestAsync('GET', path);
    if (response.status === 200) {
      dispatch({
        type: types.SEEKERS__GET_SUCCESS,
        response: {body: response.data},
      });
      dispatch({
        type: types.SEEKERS__GET_FOR_EDIT_SUCCESS,
        response: {body: response.data},
      });
    }
  } catch (err) {
    dispatch({
      type: types.SEEKERS__GET_FAILURE,
      payload: err,
    });
  }
};

export const sendSeekerRecruiterNotifyAction =
  (seekerId, messages) => async (dispatch) => {
    let path = `seekers/${seekerId}`;
    let body = {
      notifyRecruiter: true,
    };
    try {
      let response = await makeRequestAsync('PATCH', path, body);
      if (response.status === 200) {
        dispatch(
          flashActions.addFlashMessage(messages.success, 'notification')
        );
        dispatch({
          type: types.SEEKERS__NOTIFY_RECRUITER_SUCCESS,
          payload: {seeker: response.data},
          response: {body: response.data},
        });
      }
    } catch (err) {
      dispatch(flashActions.addFlashMessage(messages.failure));
      dispatch({
        type: types.SEEKERS__NOTIFY_RECRUITER_FAILURE,
        payload: err,
      });
    }
  };

export const validateConsentRequestAction =
  (seekerId, completionCode) => async (dispatch) => {
    const path = `seekers/${seekerId}/consent/validate`;
    const data = {completionCode: completionCode};

    try {
      dispatch({type: types.SEEKERS__VALIDATE_CONSENT_REQUEST});

      const response = await makeRequestAsync('POST', path, data, false);

      if (response.status === 200) {
        dispatch({
          type: types.SEEKERS__VALIDATE_CONSENT_SUCCESS,
          payload: {seeker: response.data},
        });
      }
    } catch (err) {
      dispatch(
        flashActions.addFlashMessage(
          'Failed to determine is parental consent is required for seeker.'
        )
      );
      dispatch({
        type: types.SEEKERS__VALIDATE_CONSENT_FAILURE,
        payload: err,
      });
    }
  };

export const createSeekerAction =
  (seeker, isAuthenticated = true) =>
  async (dispatch) => {
    seeker = wageApiHelper(seeker);

    let path = `seekers/`;
    const successMessage = 'Candidate created.';
    const failureMessage = 'Error creating candidate!';

    try {
      let response = await makeRequestAsync(
        'POST',
        path,
        seeker,
        isAuthenticated
      );
      if (response.status === 200) {
        dispatch(flashActions.addFlashMessage(successMessage, 'notification'));
        dispatch({
          type: types.SEEKERS__CREATE_SUCCESS,
          payload: {seeker: response.data},
          response: {body: response.data},
        });
      }
    } catch (err) {
      dispatch(flashActions.addFlashMessage(failureMessage));
      dispatch({
        type: types.SEEKERS__UPDATE_FAILURE,
        payload: err,
      });
    }
  };

export const updateSeekerAction =
  (seeker, showMsg = true, isAuthenticated = true, messages = {}) =>
  async (dispatch) => {
    seeker = wageApiHelper(seeker);

    let body = cleanForUpdate(seeker);
    let path = `seekers/${seeker.id}`;

    const successMessage = messages.success || 'Candidate saved.';
    const failureMessage = messages.failure || 'Error saving candidate.';

    try {
      dispatch({type: types.SEEKERS__UPDATE_REQUEST, showLoading: showMsg});
      let response = await makeRequestAsync(
        'PATCH',
        path,
        body,
        isAuthenticated
      );
      if (response.status === 200) {
        if (showMsg) {
          flashActions.delayFlashDispatchForScreenReader(() => {
            dispatch(
              flashActions.addFlashMessage(successMessage, 'notification')
            );
          });
        }
        dispatch({
          type: types.SEEKERS__UPDATE_SUCCESS,
          payload: {seeker: response.data},
          response: {body: response.data},
        });

        dispatch({
          type: types.SEEKERS__AVATAR_CHANGED,
          data: response.data,
        });
      }
    } catch (err) {
      flashActions.delayFlashDispatchForScreenReader(() => {
        dispatch(flashActions.addFlashMessage(failureMessage));
      });
      dispatch({
        type: types.SEEKERS__UPDATE_FAILURE,
        payload: err,
      });
    }
  };

export const getSeekerQuestionsAndAnswersAction =
  (seekerId) => async (dispatch) => {
    let path = `seekers/${seekerId}/questions`;
    try {
      dispatch({type: types.SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_REQUEST});
      let response = await makeRequestAsync('GET', path);
      if (response.status === 200) {
        dispatch({
          type: types.SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_SUCCESS,
          payload: {seeker: response.data},
          response: {body: response.data},
        });
      }
    } catch (err) {
      dispatch({
        type: types.SEEKERS__GET_SEEKER_QUESTIONS_ANSWERS_FAILURE,
        payload: err,
      });
    }
  };

export const getSeekerQuestionsAction = () => async (dispatch) => {
  let path = `v2/questions`;
  try {
    dispatch({type: types.SEEKERS__GET_SEEKER_QUESTIONS_REQUEST});
    let response = await makeRequestAsync('GET', path);
    if (response.status === 200) {
      // Add the question prop to match the seekers prop naming
      let responseData = {};
      if (response.data.questions) {
        responseData = response.data.questions.map((x) => {
          return {
            ...x,
            question: x.questionText,
          };
        });
      }
      dispatch({
        type: types.SEEKERS__GET_SEEKER_QUESTIONS_SUCCESS,
        payload: {seeker: responseData},
        response: {body: responseData},
      });
    }
  } catch (err) {
    dispatch({
      type: types.SEEKERS__GET_SEEKER_QUESTIONS_FAILURE,
      payload: err,
    });
  }
};

export const getSeekerResumeAction = (seekerId) => async (dispatch) => {
  const path = `seekers/${seekerId}/resume_urls`;

  try {
    const response = await makeRequestAsync('GET', path, {});

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    dispatch(flashActions.addFlashMessage('Failed to get resume urls'));
  }
};

// Non-API Actions

export function interviewTraitNoteChanged(trait, note) {
  return {type: types.SEEKERS__TRAIT_NOTE_CHANGED, trait: trait, note: note};
}

export function interviewAchievementNoteChanged(note) {
  return {type: types.SEEKERS__ACHIEVEMENT_NOTE_CHANGED, note: note};
}

export function interviewInterestsNoteChanged(note) {
  return {type: types.SEEKERS__INTERESTS_NOTE_CHANGED, note: note};
}

export function interviewHighlightsNoteChanged(note) {
  return {type: types.SEEKERS__HIGHLIGHTS__CHANGED, note: note};
}

export function interviewAchievementLevelChanged(level) {
  return {type: types.SEEKERS__ACHIEVEMENT_LEVEL_CHANGED, level: level};
}

export function interviewContactInfoChanged(data) {
  return {type: types.SEEKERS__CONTACT_INFO_CHANGED, data: data};
}

export function interviewEducationChanged(data) {
  return {type: types.SEEKERS__EDUCATION_CHANGED, data: data};
}

export function interviewSummaryChanged(data) {
  return {type: types.SEEKERS__SUMMARY_CHANGED, data: data};
}

export function interviewResumeChanged(data) {
  return {type: types.SEEKERS__RESUME_CHANGED, data: data};
}

export function interviewAvatarChanged(data) {
  return {type: types.SEEKERS__AVATAR_CHANGED, data: data};
}

export function interviewExperienceChanged(data) {
  return {type: types.SEEKERS__EXPERIENCE_CHANGED, data: data};
}

export function interviewSkillsChanged(data) {
  return {type: types.SEEKERS__SKILLS_CHANGED, data: data};
}

export function mixPanelTrackCandidateHomePage() {
  return {type: types.SEEKERS__MIXPANEL_TRACK_CANDIDATE_HOME_PAGE};
}
