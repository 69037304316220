import React, {Fragment, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import style from '../SelectAscend/SelectAscend.module.scss';
import TagList from '../../tags/TagList/TagList';
import {SearchSvg} from '../../../assets/svg/svgComponents';
import CheckboxSelectAscend from '../CheckboxSelectAscend/CheckboxSelectAscend';

/*
Note: This component has a known issue whtn the `closeMenuOnSelect` prop is set to `false`
The Focused item will always revert to the top-most item in the options list. This bug is
present in the underlying react-select component. See:
https://github.com/JedWatson/react-select/issues/4370 for some discussions on this. Their
main work-around is to also set `hideSelectedOptions` to `true` so that once an item
is selected it is removed from the list. Setting it here has not solved our issue
*/

export default function CheckboxSelector({
  optionsList,
  placeholder,
  isDisabled,
  closeMenuOnSelect,
  isLoading,
  selectedTags,
  onTagChangeAction,
  name,
  labelText,
  id,
  input,
  meta: {touched, error, warning},
  isMobile,
  isSearchable,
  ariaLabeledBy,
}) {
  let key = useRef(false);

  const [visibleTags, setVisibleTags] = useState(optionsList);
  const [selectedTagList, setSelectedTagList] = useState(selectedTags);

  useEffect(() => {
    setSelectedTagList(selectedTags);
    key.current = !key.current;
  }, [selectedTags]);

  useEffect(() => {
    setVisibleTags(optionsList);
  }, [selectedTagList, onTagChangeAction, optionsList]);

  const handleTagRemove = (tag) => {
    const filteredList = selectedTagList.filter((x) => x.id !== tag.id);
    setSelectedTagList(filteredList);
    input.onChange(filteredList);
    if (onTagChangeAction) {
      onTagChangeAction(filteredList);
    }
  };

  const handleAddTag = (tag) => {
    const newList = [...selectedTagList, tag];
    setSelectedTagList(newList);
    input.onChange(newList);
    if (onTagChangeAction) {
      onTagChangeAction(newList);
    }
    key.current = !key.current;
  };

  const handleToggleTag = (tag) => {
    if (selectedTagList.some((x) => x.id === tag.id)) {
      handleTagRemove(tag);
    } else {
      handleAddTag(tag);
    }
  };

  return (
    <Fragment>
      <CheckboxSelectAscend
        name={name}
        id={id}
        labelText={labelText}
        options={visibleTags !== undefined ? visibleTags : optionsList}
        onChange={handleToggleTag}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={isDisabled}
        closeMenuOnSelect={closeMenuOnSelect}
        value={null}
        selectorGlyph={<SearchSvg className={style.settingsSvg_fill_orange} />}
        selectedTags={selectedTags}
        isSearchable={isSearchable}
        ariaLabeledBy={ariaLabeledBy}
      />
      <TagList
        key={key.current}
        tags={selectedTagList}
        removeAction={handleTagRemove}
        isMobile={isMobile}
      />
      {touched &&
        ((error && <div className="form-input-error">{error}</div>) ||
          (warning && <div className="form-input-error">{warning}</div>) || (
            <div className="form-input-error">&nbsp;</div>
          ))}
      {!touched && <div className="form-input-error">&nbsp;</div>}
    </Fragment>
  );
}

CheckboxSelector.propTypes = {
  input: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  name: PropTypes.string,
  labelText: PropTypes.string,
  id: PropTypes.any,
  optionsList: PropTypes.array,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  selectedTags: PropTypes.array,
  onTagChangeAction: PropTypes.func,
  isMobile: PropTypes.bool,
  isSearchable: PropTypes.bool,
  ariaLabeledBy: PropTypes.string,
};

CheckboxSelector.defaultProps = {
  optionsList: [],
  selectedTags: [],
  isSearchable: true,
};
