import {call, put, takeLatest, select, delay} from 'redux-saga/effects';
import {makeRequest} from 'src/services/api';
import * as types from 'src/actions/ActionTypes';

// Start polling resume status, waiting for parsing to finish.
function* pollGetStatus(action) {
  for (let i = 0; i < 15; i++) {
    try {
      const response = yield call(makeRequest, {
        method: 'GET',
        path: `resumes/${action.uploadId}/status`,
        isAuthenticated: false,
      });

      if (!response.data.success) {
        // Wait and try again in a bit.
        yield delay(1000);
        continue;
      }

      const state = yield select();

      if (
        state.seekers &&
        state.seekers.seeker.experience &&
        state.seekers.seeker.experience.length > 0
      ) {
        // don't overwrite existing experience if it exists
        response.data.resume.summary.experience =
          state.seekers.seeker.experience;
      }

      yield put({type: types.GET_STATUS_SUCCESS, response});

      // If the status shows the resume was parsed, we are done.
      if (response.data.resume.parsedAt) {
        yield put({type: types.POLL_GET_STATUS_SUCCESS});

        // Retrieve campuses for this institution
        const {institutionId} = response.data.resume.summary;
        if (institutionId) {
          const campusResponse = yield call(makeRequest, {
            method: 'GET',
            path: `campuses?institutionId=${institutionId}`,
            isAuthenticated: false,
          });
          campusResponse.body = campusResponse.data;
          yield put({
            type: types.CAMPUSES__GET_SUCCESS,
            response: campusResponse,
          });
        }

        return;
      }
    } catch (error) {
      yield put({type: types.GET_STATUS_FAILURE, error});
    }
  }
  // If we get to this point, we've tried too many times
  yield put({type: types.POLL_GET_STATUS_TIMEDOUT});
}

//
// export watchers for each saga.
//
export const sagas = [takeLatest(types.POLL_GET_STATUS_REQUEST, pollGetStatus)];
