import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import MaHeader from './MaHeader';
import track from 'react-tracking';
import renderSelectField from '../controls/SelectFieldRenderer';
import {validate} from '../../utils/modernApprenticeship/modernApprenticeshipCommon';
import MaResumeUpload from './MaResumeUpload';
import HavingTrouble from '../connectionForm/HavingTrouble';
import {Row} from 'reactstrap';
import {maReferralSources} from 'src/utils/fieldHelper';
import ApprenticePathwaySelect from 'src/components/ApprenticePathwaySelect';

const validateForm = (values) => {
  const fields = ['maReferralSource', 'apprenticePathwaySelections'];
  let messages = validate(fields, values);

  if (
    !messages['apprenticePathwaySelections'] &&
    (values.apprenticePathwaySelections || []).length === 0
  ) {
    messages.apprenticePathwaySelections = 'Required';
  }

  return messages;
};

@track(
  {eventName: 'ModernApprenticeshipInterestAssessment'},
  {dispatchOnMount: true}
)
class MaInterestAssessment extends React.Component {
  state = {
    apprenticePathways: [],
    apprenticePathwayObjects: [],
  };

  render() {
    let headerText = {
      title: 'Modern Apprenticeship',
      titleStyle: 'normal-headline bold',
      subTitle: 'High School Student Interest Form',
      subTitleStyle: 'normal-body bold',
    };
    const {isMobile, previousPage} = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="bd-whitebackground">
              <MaHeader
                titleStyle={headerText.titleStyle}
                title={headerText.title}
                subTitle={headerText.subTitle}
                subTitleStyle={headerText.subTitleStyle}
              />
              <div
                className="secondary-body"
                style={{fontStyle: 'italic'}}
              ></div>
              <Form
                onSubmit={this.props.onSubmit}
                validate={validateForm}
                keepDirtyOnReinitialize={true}
                initialValues={{
                  apprenticePathwaySelections: this.props.highSchoolChanged
                    ? undefined
                    : this.props.initialValues.apprenticePathwaySelections,
                  maReferralSource: this.props.initialValues.maReferralSource,
                }}
              >
                {({handleSubmit, form}) => {
                  return (
                    <form onSubmit={handleSubmit} style={{padding: '48px'}}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="onboarding-field-container-bottom col-md-6">
                              <Field name="apprenticePathwaySelections">
                                {(props) => (
                                  <>
                                    <ApprenticePathwaySelect
                                      name={props.input.name}
                                      valueObjects={props.input.value || []}
                                      onChange={props.input.onChange}
                                      isMulti={true}
                                      highSchoolId={
                                        this.props.initialValues
                                          .highSchoolId !== null
                                          ? this.props.initialValues
                                              .highSchoolId
                                          : undefined
                                      }
                                      hasRequestedHighSchool={
                                        this.props.initialValues
                                          .highSchoolId !== null
                                      }
                                      meta={props.meta}
                                    >
                                      <label
                                        className="secondary-body"
                                        id="labelmaReferralSource"
                                      >
                                        <span>
                                          Which apprentice pathway(s) are you
                                          interested in?
                                        </span>
                                      </label>
                                    </ApprenticePathwaySelect>
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className="onboarding-field-container-bottom col-md-6">
                              <Field
                                name="maReferralSource"
                                component={renderSelectField}
                                title={
                                  <span>
                                    How did you hear about this program?
                                  </span>
                                }
                                padLabel={false}
                                labelBold={false}
                                options={maReferralSources}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <MaResumeUpload
                          handleUploadStated={() => {}}
                          handleUploadCompleted={() => {}}
                          handleUploadError={() => {}}
                          initialValues={this.props.initialValues}
                        />
                      </div>
                      <br />
                      <Row className="row padding-top-sixteen">
                        <div
                          className={`col-md-12 ${
                            isMobile
                              ? 'd-flex justify-content-center flexColumn'
                              : ''
                          } `}
                        >
                          <button
                            type="submit"
                            style={{
                              width: '210px',
                              height: '40px',
                              alignSelf: 'center',
                            }}
                            className={`clickable btn btn-primary ${
                              isMobile ? '' : 'float-right'
                            }`}
                          >
                            Continue
                          </button>

                          {previousPage !== null && (
                            <button
                              className={`buttonAsLink mt-2 ${
                                isMobile ? '' : 'float-right me-3'
                              }`}
                              onClick={(e) => {
                                this.props.previousPage(
                                  e,
                                  form.getState().values
                                );
                              }}
                              style={{
                                width: 'fit-content',
                                alignSelf: 'center',
                              }}
                            >
                              Previous Page
                            </button>
                          )}
                        </div>
                      </Row>
                      <HavingTrouble />
                    </form>
                  );
                }}
              </Form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

MaInterestAssessment.propTypes = {
  onSubmit: PropTypes.func,
  previousPage: PropTypes.func,
  segment: PropTypes.object,
  isMobile: PropTypes.bool,
  referralSources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  initialValues: PropTypes.object,
  highSchoolChanged: PropTypes.bool,
};

MaInterestAssessment.defaultProps = {
  previousPage: null,
  segment: {},
};

const mapStateToProps = (state) => {
  return {
    segment: state.invitationCodes.segment,
    apprenticePathwaySelections: state.apprenticePathways,
  };
};

export default connect(mapStateToProps)(MaInterestAssessment);
