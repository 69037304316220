import * as types from '../actions/ActionTypes';
import {filter, findIndex} from 'lodash';
import {toCurrency} from '../utils/wageHelper';
import {format} from 'date-fns';

const initialState = {
  jobs: [],
  job: {},
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  matches: [],
  jobMatches: {},
  jobWatches: {},
  jobApplications: {},
  jobInvitations: {},
  jobsByTeam: {},
  handpickedByJob: {},
  jobCardsByJob: {},
  jobCache: {},
  jobCacheLoading: false,
  allJobs: [],
  reports: [],
  allJobsLoading: false,
  allJobsPage: 0,
  allJobsItemsPerPage: 25,
  allJobsTotalItems: 0,
  jobsLoading: false,
  jobLoading: false,
  stats: {
    draftJobs: 0,
    publishedJobs: 0,
    totalJobs: 0,
  },
  roleRank: 0,
  scraped: 0,
  segments: [],
  applicationDataLoading: false,
  applicationData: {},
  applyInProgress: false,
  summaries: [],
  summariesLoading: false,
  appliedToClicks: [],
  appliedToClicksLoading: false,
  appliedToClickCount: 0,
  reportSuccessful: true,
  reportSaving: false,
};

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case types.JOBS__CLEAR_ALL:
      return {...state, jobs: []};
    case types.JOBS__GET_REQUEST:
      return {...state, job: {}, jobLoading: true};
    case types.JOBS__GET_SUCCESS:
      return {
        ...state,
        job: {
          ...action.response.body,
          minimumYearlySalary: toCurrency(
            action.response.body.minimumYearlySalary
          ),
          maximumYearlySalary: toCurrency(
            action.response.body.maximumYearlySalary
          ),
          minimumHourlyWage: toCurrency(action.response.body.minimumHourlyWage),
          maximumHourlyWage: toCurrency(action.response.body.maximumHourlyWage),
        },
        jobLoading: false,
      };
    case types.JOBS__CLEAR_SELECTED_JOB:
      return {...state, job: {}};
    case types.JOBS__GET_FOR_EDIT_REQUEST:
      return {...state, jobForEdit: undefined};
    case types.JOBS__GET_FOR_CLONE_REQUEST:
      return {...state, jobForClone: undefined};
    case types.JOBS__GET_FOR_EDIT_SUCCESS:
      let editBody = action.data || action?.response?.body;

      editBody = {
        ...editBody,
        drugTest:
          editBody.drugTest === null
            ? 'unknown'
            : editBody.drugTest
            ? 'yes'
            : 'no',
        backgroundCheck:
          editBody.backgroundCheck === null
            ? 'unknown'
            : editBody.backgroundCheck
            ? 'yes'
            : 'no',
        transportationAssistance:
          editBody.transportationAssistance === null
            ? 'unknown'
            : editBody.transportationAssistance
            ? 'yes'
            : 'no',
        publicTransportationAccess:
          editBody.publicTransportationAccess === null
            ? 'unknown'
            : editBody.publicTransportationAccess
            ? 'yes'
            : 'no',
      };

      return {
        ...state,
        jobForEdit: {
          ...editBody,
          minimumYearlySalary: toCurrency(editBody.minimumYearlySalary),
          maximumYearlySalary: toCurrency(editBody.maximumYearlySalary),
          minimumHourlyWage: toCurrency(editBody.minimumHourlyWage),
          maximumHourlyWage: toCurrency(editBody.maximumHourlyWage),
        },
      };
    case types.JOBS__GET_FOR_CLONE_SUCCESS:
      let cloneBody = action.response.body;
      cloneBody = {
        ...cloneBody,
        drugTest: cloneBody.drugTest ? 'yes' : 'no',
        backgroundCheck: cloneBody.backgroundCheck ? 'yes' : 'no',
        transportationAssistance: cloneBody.transportationAssistance
          ? 'yes'
          : 'no',
        publicTransportationAccess: cloneBody.publicTransportationAccess
          ? 'yes'
          : 'no',
      };
      return {
        ...state,
        jobForClone: {
          ...cloneBody,
          minimumYearlySalary: toCurrency(
            action.response.body.minimumYearlySalary
          ),
          maximumYearlySalary: toCurrency(
            action.response.body.maximumYearlySalary
          ),
          minimumHourlyWage: toCurrency(action.response.body.minimumHourlyWage),
          maximumHourlyWage: toCurrency(action.response.body.maximumHourlyWage),
          published: false,
          url: null,
        },
      };

    case types.JOBS__GET_ALL_REQUEST:
      return {...state, jobs: [], jobsLoading: true};

    case types.JOBS__GET_ALL_SUCCESS:
      return {...state, jobs: action.response.body.jobs, jobsLoading: false};

    case types.JOBS__GET_ALL_FAILURE:
      return {...state, jobs: [], jobsLoading: false};

    case types.JOBS__GET_ALL_ADVANCED_REQUEST:
      return {
        ...state,
        allJobs: [],
        allJobsLoading: true,
      };
    case types.JOBS__GET_ALL_ADVANCED_SUCCESS:
      return {
        ...state,
        allJobs: action.response.body.jobs,
        allJobsPage: action.response.body.page,
        allJobsItemsPerPage: action.response.body.itemsPerPage,
        allJobsTotalItems: action.response.body.totalItems,
        allJobsLoading: false,
      };
    case types.JOBS__GET_BY_TEAM_REQUEST:
      return {
        ...state,
        jobs: [],
        jobsLoading: true,
      };
    case types.JOBS__GET_BY_TEAM_SUCCESS:
      return {
        ...state,
        jobs: action.response.body.jobs,
        jobsLoading: false,
      };
    case types.JOBS__GET_BY_TEAM_FAILURE:
      return {
        ...state,
        jobsLoading: false,
      };
    case types.JOBS__GET_BY_TEAM_ADVANCED_REQUEST:
      return {
        ...state,
        jobs: [],
        jobsLoading: true,
      };
    case types.JOBS__GET_BY_TEAM_ADVANCED_SUCCESS:
      return {
        ...state,
        jobs: action.response.body.jobs.map((job) => {
          return {
            ...job,
            matchData: {
              ...job.matchData,
              showMatchChart:
                job.matchData?.employmentTypeFit &&
                job.matchData?.degreeRankFit &&
                job.matchData?.experienceLevelFit,
            },
          };
        }),
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        jobsLoading: false,
      };
    // Start of new caching reducers. Need to migrate to these
    case types.JOBS__GET_TO_CACHE_REQUEST:
      return {
        ...state,
        jobCacheLoading: true,
      };
    case types.JOBS__GET_TO_CACHE_FAILURE:
      return {
        ...state,
        jobCacheLoading: false,
        showJobNotFoundModal: true,
      };
    case types.JOBS__GET_TO_CACHE_SUCCESS:
      const jobCache = {
        ...state.jobCache,
        [action.payload.jobId]: action.response.body,
      };
      return {...state, jobCache: jobCache, jobCacheLoading: false};

    case types.JOBS__GET_TO_CACHE_NOT_FOUND:
      let value = action.showJobNotFoundModal;
      return {
        ...state,
        jobCacheLoading: false,
        showJobNotFoundModal: value,
      };
    case types.JOBS__GET_JOB_CARD_SUCCESS:
      const jobCardResponseBody = action.response.body.jobCard;
      return {
        ...state,
        jobCardsByJob: {
          ...state.jobCardsByJob,
          [action.payload.jobId]: {
            ...jobCardResponseBody,
            matches: jobCardResponseBody.matches.map((matchItem) => {
              return {
                ...matchItem,
                match: {
                  ...matchItem.match,
                  showMatchChart:
                    matchItem.match.employmentTypeFit &&
                    matchItem.match.degreeRankFit &&
                    matchItem.match.experienceLevelFit,
                },
              };
            }),
          },
        },
      };
    case types.JOBS__GET_BY_TEAM_TO_CACHE_SUCCESS:
      const jobsByTeam = {
        ...state.jobsByTeam,
        [action.payload.teamId]: action.response.body.jobs,
      };
      return {...state, jobsByTeam: jobsByTeam};
    case types.JOBS__GET_HANDPICKED_TO_CACHE_SUCCESS:
      const handpickedByJob = {
        ...state.handpickedByJob,
        [action.payload.jobId]: action.response.body.handpicked,
      };
      return {...state, handpickedByJob: handpickedByJob};
    case types.JOBS__GET_MATCHES_SUCCESS:
      const matches = {
        ...state.jobMatches,
        [action.payload.jobId]: action.response.body.matches,
      };
      return {
        ...state,
        jobMatches: matches,
        matches: action.response.body.matches,
      };
    case types.JOBS__GET_WATCHES_SUCCESS:
      const watches = {
        ...state.jobWatches,
        [action.payload.jobId]: action.response.body.watches,
      };
      return {
        ...state,
        jobWatches: watches,
        watches: action.response.body.watches,
      };
    case types.JOBS__GET_APPLICATIONS_SUCCESS:
      const applications = {
        ...state.jobApplications,
        [action.payload.jobId]: action.response.body.applications,
      };
      return {
        ...state,
        jobApplications: applications,
        applications: action.response.body.applications,
      };
    case types.JOBS__GET_INVITATIONS_SUCCESS:
      const invitations = {
        ...state.jobInvitations,
        [action.payload.jobId]: action.response.body.invitations,
      };
      return {
        ...state,
        jobInvitations: invitations,
        invitations: action.response.body.invitations,
      };
    case types.JOBS__GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.response.body.reports,
      };
    case types.JOBS__DELETE_SUCCESS:
      return {
        ...state,
        jobs: filter(state.jobs, (job) => {
          return job.id !== action.payload.jobId;
        }),
        allJobs: filter(state.allJobs, (job) => {
          return job.id !== action.payload.jobId;
        }),
      };
    case types.JOBS__CREATE_SUCCESS:
      return {
        ...state,
        jobs: [...state.jobs, action.response.body],
        allJobs: [...state.allJobs, action.response.body],
      };
    case types.JOBS__GET_JOB_STATS_SUCCESS:
      return {...state, stats: action.response.body};
    case types.JOBS__UPDATE_SUCCESS:
      const jobsIndex = findIndex(state.jobs, (job) => {
        return job.id === action.payload.job.id;
      });
      const allJobsIndex = findIndex(state.allJobs, (job) => {
        return job.id === action.payload.job.id;
      });
      var updatedJobs = [];
      var updatedAllJobs = [];
      if (jobsIndex !== -1) {
        updatedJobs = [
          ...state.jobs.slice(0, jobsIndex),
          action.response.body,
          ...state.jobs.slice(jobsIndex + 1),
        ];
      }
      if (allJobsIndex !== -1) {
        updatedAllJobs = [
          ...state.allJobs.slice(0, allJobsIndex),
          action.response.body,
          ...state.allJobs.slice(allJobsIndex + 1),
        ];
      }
      return {
        ...state,
        jobs: updatedJobs,
        allJobs: updatedAllJobs,
      };
    case types.JOBS__GET_CATEGORY_SUCCESS:
      return {
        ...state,
        jobCategories: action.response.body.jobCategories,
      };
    case types.JOBS__GET_APPLICATION_DATA_REQUEST:
      return {...state, applicationDataLoading: true};
    case types.JOBS__GET_APPLICATION_DATA_FAILURE:
      return {...state, applicationDataLoading: false, applicationData: {}};
    case types.JOBS__GET_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        applicationDataLoading: false,
        applicationData: action.response.body,
      };
    case types.JOBS__GET_APPLICATIONS_REQUEST:
      return {...state, applyInProgress: true};
    case types.JOBS__APPLY_FAILURE:
    case types.JOBS__APPLY_SUCCESS:
      return {...state, applyInProgress: true};
    case types.JOBS__GET_ALL_SUMMARIES_REQUEST:
      return {...state, summariesLoading: true};
    case types.JOBS__GET_ALL_SUMMARIES_SUCCESS:
      return {
        ...state,
        summariesLoading: false,
        summaries: action.response.body.jobs.map((j) => ({
          ...j,
          label: `${j.name || 'Unknown Name'} - ${format(
            new Date(j.createdAt),
            'MM/dd/yyyy'
          )}`,
        })),
      };
    case types.JOBS__GET_ALL_SUMMARIES_FAILURE:
      return {
        ...state,
        summariesLoading: false,
      };
    case types.JOBS__GET_APPLIED_TO_CLICKS_REQUEST:
      return {...state, appliedToClicksLoading: true};
    case types.JOBS__GET_APPLIED_TO_CLICKS_SUCCESS:
      return {
        ...state,
        appliedToClicksLoading: false,
        appliedToClicks: action.response.body.appliedToClicks,
        appliedToClickCount: action.response.body.appliedToClicks.length,
      };
    case types.JOBS__GET_APPLIED_TO_CLICKS_FAILURE:
      return {
        ...state,
        appliedToClicksLoading: false,
      };
    case types.JOBS__APPLY_TO_RESPONSE_SUCCESS:
      return {
        ...state,
        appliedToClickCount: action.response.body.appliedToClickCount,
      };
    case types.JOBS__UNDO_APPLY_TO_RESPONSE_SUCCESS:
      return {
        ...state,
        appliedToClickCount: action.response.body.appliedToClickCount,
      };
    case types.JOBS__REMOVE_APPLY_TO_CLICK:
      return {
        ...state,
        appliedToClicks: state.appliedToClicks.filter(
          (x) => x.id !== action.id
        ),
      };

    case types.JOBS__EMPLOYER_JOBS_SORTED_REQUEST:
      return {
        ...state,
        jobsLoading: true,
        jobsByEmployerSorted: [],
      };

    case types.JOBS__EMPLOYER_JOBS_SORTED_SUCCESS:
      const data = action?.response?.body;
      return {
        ...state,
        jobsLoading: false,
        totalJobsByEmployerSorted: data.totalItems,
        jobsByEmployerSorted: data.jobs.map((job) => {
          return {
            ...job,
          };
        }),
      };

    case types.JOBS__EMPLOYER_JOBS_SORTED_FAILURE:
      return {
        ...state,
        jobsLoading: false,
      };

    case types.JOBS__EMPLOYER_JOBS_UNPUBLISH_REQUEST:
      return {
        ...state,
        requestingUnpublish: true,
      };

    case types.JOBS__EMPLOYER_JOBS_UNPUBLISH_SUCCESS:
      const jobs = state.jobs.filter((j) => j.id !== action.payload.job.id);
      return {
        ...state,
        requestingUnpublish: false,
        jobs: jobs,
      };

    case types.JOBS__EMPLOYER_JOBS_UNPUBLISH_FAILURE:
      return {
        ...state,
        requestingUnpublish: false,
      };

    case types.JOBS__REPORT_ISSUE_REQUEST:
      return {
        ...state,
        reportSaving: true,
      };

    case types.JOBS__REPORT_ISSUE_SUCCESS:
      return {
        ...state,
        reportSuccessful: true,
        reportSaving: false,
      };

    case types.JOBS__REPORT_ISSUE_FAILURE:
      return {
        ...state,
        reportSuccessful: false,
        reportSaving: false,
      };

    default:
      return state;
  }
}
