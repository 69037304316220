import * as types from '../actions/ActionTypes';
import {filter, findIndex} from 'lodash';

const initialState = {
  teams: [],
  team: {
    name: '',
    employerId: 1,
    summary: '',
    details: '',
    teamSize: '',
    url: '',
    location: '',
    skillTags: [],
    workStyleTags: [],
    jobs: [],
  },
  stats: {
    draftTeams: 0,
    publishedTeams: 0,
    totalTeams: 0,
    draftJobs: 0,
    publishedJobs: 0,
    totalJobs: 0,
  },
  teamCache: {},
  page: 0,
  itemsPerPage: 25,
  totalItems: 0,
  teamsLoading: false,
  statsByTeam: {
    draftJobs: 0,
    publishedJobs: 0,
    totalJobs: 0,
  },
  teamFilterOptions: [],
  teamFilterOptionsLoading: false,
  accessProhibitedError: false,
};

export default function teams(state = initialState, action) {
  switch (action.type) {
    case types.TEAMS__GET_REQUEST:
      return {...state};
    case types.TEAMS__GET_FOR_EDIT_REQUEST:
      return {...state, teamForEdit: undefined};
    case types.TEAMS__GET_ALL_REQUEST:
      return {
        ...state,
        teams: [],
        teamsLoading: true,
        accessProhibitedError: false,
      };
    case types.TEAMS__GET_ALL_ADVANCED_REQUEST:
      return {
        ...state,
        teams: [],
        teamsLoading: true,
        accessProhibitedError: false,
      };
    case types.TEAMS__GET_ALL_ADVANCED_SUCCESS:
      return {
        ...state,
        teams: action.response.body.teams,
        page: action.response.body.page,
        itemsPerPage: action.response.body.itemsPerPage,
        totalItems: action.response.body.totalItems,
        teamsLoading: false,
        accessProhibitedError: false,
      };
    case types.TEAMS__GET_ALL_ADVANCED_FAILURE:
      return {
        ...state,
        teams: [],
        teamsLoading: false,
        accessProhibitedError: true,
      };
    case types.TEAMS__CREATE_SUCCESS:
      return {
        ...state,
        teams: [...state.teams, action.response.body],
      };
    case types.TEAMS__GET_ALL_SUCCESS:
      return {
        ...state,
        teams: action.response.body.teams,
        teamsLoading: false,
        accessProhibitedError: false,
      };

    case types.TEAMS__GET_ALL_FAILURE:
      return {
        ...state,
        teams: [],
        teamsLoading: false,
        accessProhibitedError: true,
      };
    case types.TEAMS__CREATE_WATCH_SUCCESS:
    case types.TEAMS__GET_SUCCESS:
      return {
        ...state,
        team: action.response.body,
        accessProhibitedError: false,
      };
    case types.TEAMS__GET_FOR_EDIT_SUCCESS:
      return {...state, teamForEdit: action.response.body};
    case types.TEAMS__GET_TO_CACHE_SUCCESS:
      const teamCache = {
        ...state.teamCache,
        [action.payload.team_id]: action.response.body,
      };
      return {...state, teamCache: teamCache, accessProhibitedError: false};
    case types.TEAMS__GET_TO_CACHE_FAILURE:
      return {...state, accessProhibitedError: true};
    case types.TEAMS__UPDATE_SUCCESS:
      let index = findIndex(state.teams, (team) => {
        return team.id === action.payload.team.id;
      });
      return {
        ...state,
        teams: [
          ...state.teams.slice(0, index),
          action.response.body,
          ...state.teams.slice(index + 1),
        ],
        team: action.response.body,
        teamCache: {
          ...state.teamCache,
          [action.payload.team.id]: action.response.body,
        },
      };
    case types.TEAMS__DELETE_SUCCESS:
      return {
        ...state,
        teams: filter(state.teams, (team) => {
          return team.id !== action.payload.teamId;
        }),
      };
    case types.TEAMS__DELETE_WATCH_SUCCESS:
      if (!state.team) return state;
      return {
        ...state,
        team: {
          ...state.team,
          watchers_count: state.team.watchers_count - 1,
        },
      };
    //
    // Handle modifying jobs from the Team Profile Page
    //
    case types.JOBS__CREATE_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          jobs: [...state.team.jobs, action.response.body],
        },
      };
    case types.JOBS__UPDATE_SUCCESS:
      const jobIndex = findIndex(state.team.jobs, (job) => {
        return job.id === action.payload.job.id;
      });
      return {
        ...state,
        team: {
          ...state.team,
          jobs: [
            ...state.team.jobs.slice(0, jobIndex),
            action.response.body,
            ...state.team.jobs.slice(jobIndex + 1),
          ],
        },
      };
    case types.JOBS__DELETE_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          jobs: filter(state.team.jobs, (job) => {
            return job.id !== action.payload.jobId;
          }),
        },
      };
    default:
      return state;
  }
}
