import React from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/esm/Container';
import Row from 'reactstrap/esm/Row';
import Col from 'reactstrap/esm/Col';

const ConnectionHeader = ({
  title,
  subTitle,
  logo,
  progressImage,
  titleStyle,
  subTitleStyle,
}) => {
  return (
    <Container style={{backgroundColor: 'red'}}>
      <Row>
        <Col className="col-2">
          <img style={{width: '76px'}} alt="" src={logo} />
        </Col>
        <Col>
          <Row className={`${titleStyle} justify-content-center`}>{title}</Row>
          {progressImage && (
            <Row className="justify-content-center ps-2 pt-3 pb-3">
              <img alt="" src={progressImage} />
            </Row>
          )}
        </Col>
        <Col className="col-2" />
      </Row>
      <Row className={`${subTitleStyle} justify-content-center`}>
        {subTitle}
      </Row>
    </Container>
  );
};

ConnectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  progressImage: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleStyle: PropTypes.string,
};

export default ConnectionHeader;
