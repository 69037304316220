import React from 'react';
import PropTypes from 'prop-types';

export default function ReportConnectionSummary(props) {
  const {seeker, roleAttributed, role, notes} = props;

  return (
    <>
      <div className="row pb-2">
        <label className="bold col-4">Job Seeker:</label>
        <span className="col">
          {seeker.name || `${seeker.firstName} ${seeker.lastName}`}
        </span>
      </div>

      <div className="row pb-2">
        <label className="bold col-4">Company:</label>
        <span className="col">{role.employerName}</span>
      </div>

      <div className="row pb-2">
        <label className="bold col-4">Role:</label>
        <span className="col">{role.jobName}</span>
      </div>

      {role.employmentType && (
        <div className="row pb-2">
          <label className="bold col-4">Role Type:</label>
          <span className="col">{role.employmentType.label}</span>
        </div>
      )}

      {role.isRemote !== null && (
        <div className="row pb-2">
          <label className="bold col-4">Is Remote:</label>
          <span className="col">{role.isRemote ? 'Yes' : 'No'}</span>
        </div>
      )}

      {role.city && (
        <div className="row pb-2">
          <label className="bold col-4">City:</label>
          <span className="col">{role.city}</span>
        </div>
      )}

      {role.state && (
        <div className="row pb-2">
          <label className="bold col-4">State:</label>
          <span className="col">{role.state.label}</span>
        </div>
      )}

      {(role.zipCode || role.jobZipCode) && (
        <div className="row pb-2">
          <label className="bold col-4">ZIP Code:</label>
          <span className="col">{role.zipCode || role.jobZipCode}</span>
        </div>
      )}

      <div className="row pb-2">
        <label className="bold col-4">Support:</label>
        <span className="col">{roleAttributed ? 'Yes' : 'No'} </span>
      </div>

      <div className="row pb-2">
        <label className="bold col-4">Feedback:</label>
        <span className="col">{notes === null ? 'N/A' : notes} </span>
      </div>

      {!role.zipCode && !role.jobZipCode && (
        <div className="mt-4 pb-2 text-align-center">
          <label className="bold">
            Note: The connection to be created does not include a ZIP code.
          </label>
        </div>
      )}
    </>
  );
}

ReportConnectionSummary.propTypes = {
  seeker: PropTypes.object,
  roleAttributed: PropTypes.bool,
  role: PropTypes.object,
  notes: PropTypes.any,
};
