import React from 'react';
import PropTypes from 'prop-types';
import OverallMatchWidget from '../OverallMatchWidget';
import {CloseSvg} from 'src/assets/svg/svgComponents';

export default function RecruiterMatchCard(props) {
  const [skillsPercentage, stylePercentage] = props.matchData.matchLevels;

  const {
    degreeRankFit,
    experienceLevelFit,
    employmentTypeFit,
    overallFit,
    isApprenticeProgram,
  } = props.matchData;

  const failReasons = [];

  if (degreeRankFit === false) {
    failReasons.push('Education Level');
  }

  if (degreeRankFit === undefined && isApprenticeProgram === true) {
    failReasons.push('Education Level');
    failReasons.push('Apprentice Program');
  }

  if (!experienceLevelFit) {
    failReasons.push('Experience Level Preference');
  }
  if (employmentTypeFit === false) {
    failReasons.push('Employment Type Preference');
  }

  const failComponent = () => {
    return (
      <div className="normal-caption text-align-center pt-2">
        Candidate did not match to this role due to:
        <ul className="list-group" style={{padding: '8px 0 8px 25px'}}>
          {failReasons.map((reason, index) => {
            return (
              <li
                key={index}
                className="list-group-item"
                style={{
                  border: 'none',
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <CloseSvg style={{marginRight: '5px'}} />
                <strong style={{marginTop: '-4px'}}>{reason}</strong>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <table
        style={{textAlign: 'center', height: '100%', width: '100%'}}
        role="presentation"
      >
        <tbody>
          <tr>
            <td
              style={{
                width: '33%',
                verticalAlign: 'middle',
                borderRight: '1px solid rgba(0, 78, 125, 0.25)',
              }}
            >
              <div className="secondary-body bold extraspace">Skills Match</div>
              <hr className="black-bar" />
              <div className="normal-headline bold">{`${skillsPercentage}%`}</div>
            </td>
            <td
              style={{
                width: '33%',
                verticalAlign: 'middle',
                borderRight: '1px solid rgba(203, 225, 255, 0.25)',
              }}
            >
              <div className="secondary-body bold extraspace">Work Style</div>
              <hr className="primary-bar" />
              <div className="active-headline bold">{`${stylePercentage}%`}</div>
            </td>
          </tr>

          <tr>
            <td
              colSpan="3"
              style={{verticalAlign: 'middle', padding: '8px 8px 8px 8px'}}
            >
              {failReasons.length === 0 && (
                <div className="bd-graybackground">
                  <OverallMatchWidget overallFit={overallFit} isRecruiter />
                </div>
              )}
              {failReasons.length > 0 && failComponent()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

RecruiterMatchCard.propTypes = {
  matchData: PropTypes.object.isRequired,
  jobTraits: PropTypes.object,
  cardStyle: PropTypes.object,
};

RecruiterMatchCard.defaultProps = {
  cardStyle: {},
};
