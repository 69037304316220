export const toNumber = (number) => {
  if (!number) {
    return '';
  }

  if (typeof number === 'number') return number;

  return Number(number.replace(/[^0-9.]+/g, ''));
};

export const toCurrency = (number) => {
  if (!number) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(number);
};

export const toNumberString = (number) => {
  if (!number) {
    return '';
  }
  return new Intl.NumberFormat().format(toNumber(number));
};

export const buildSalaryRange = () => {
  let values = [];
  for (let i = 25000; i <= 100000; i += 1000) {
    values.push({
      value: toCurrency(i),
      displayValue: i >= 100000 ? toCurrency(i) + '+' : toCurrency(i),
      hourly: (i / 2080).toFixed(2),
    });
  }
  return values;
};

export const buildHourlyRange = () => {
  let values = [];
  for (let i = 8; i <= 40; i++) {
    let display = toCurrency(i);
    if (i === 8) {
      display += ' / hour or below';
    }
    if (i === 40) {
      display += '+';
    }
    values.push({
      value: toCurrency(i),
      displayValue: display,
      hourly: i.toFixed(2),
    });
  }
  return values;
};
export const wageApiHelper = (props) => {
  if (props.minimumYearlySalary) {
    props = {
      ...props,
      minimumYearlySalary: `${props.minimumYearlySalary}`.replace(
        /[^0-9.]+/g,
        ''
      ), // Take out any text
    };
  }
  if (props.maximumYearlySalary) {
    props = {
      ...props,
      maximumYearlySalary: `${props.maximumYearlySalary}`.replace(
        /[^0-9.]+/g,
        ''
      ),
    };
  }

  if (props.minimumHourlyWage === '') {
    //Field is a decimal we need to pass null not empty string
    props = {...props, minimumHourlyWage: null};
  }

  if (props.minimumHourlyWage) {
    props = {
      ...props,
      minimumHourlyWage: props.minimumHourlyWage.replace(/[^0-9.]+/g, ''), // Take out any text
    };
  }

  if (props.maximumHourlyWage === '') {
    //Field is a decimal we need to pass null not empty string
    props = {...props, maximumHourlyWage: null};
    return props;
  }

  if (props.maximumHourlyWage) {
    props = {
      ...props,
      maximumHourlyWage: props.maximumHourlyWage.replace(/[^0-9.]+/g, ''), // Take out any text
    };
  }

  return props;
};

export const formatWage = (number, displayDollarSign, decimalsToDisplay) => {
  if (!number) {
    return '';
  }

  const format = displayDollarSign ? 'currency' : 'decimal';

  const formatter = new Intl.NumberFormat('en-US', {
    style: format,
    currency: 'USD',
    minimumFractionDigits: decimalsToDisplay,
    maximumFractionDigits: decimalsToDisplay,
  });

  return formatter.format(toNumber(number));
};

export const getWageRangeText = (job, displayDollarSign) => {
  if (!job.isPaidRole) {
    return 'Unpaid';
  }

  if (job.payType === 'Salary') {
    if (job.maximumYearlySalary) {
      return `${formatWage(
        job.minimumYearlySalary,
        displayDollarSign,
        0
      )} - ${formatWage(
        job.maximumYearlySalary,
        displayDollarSign,
        0
      )} per year`;
    }

    if (!job.minimumYearlySalary) {
      return 'Minimum Annual: Not specified';
    }

    return `${formatWage(
      job.minimumYearlySalary,
      displayDollarSign,
      0
    )} per year`;
  }

  if (job.payType === 'Hourly') {
    if (
      (job.minimumHourlyWage === '8.00' || job.minimumHourlyWage === '$8.00') &&
      !job.maximumHourlyWage
    )
      return `${formatWage(
        job.minimumHourlyWage,
        displayDollarSign,
        2
      )} per hour or below`;

    if (job.maximumHourlyWage) {
      return `${formatWage(
        job.minimumHourlyWage,
        displayDollarSign,
        2
      )} - ${formatWage(job.maximumHourlyWage, displayDollarSign, 2)} per hour`;
    }

    if (!job.minimumHourlyWage) {
      return 'Minimum Hourly: Not specified';
    }

    return `${formatWage(
      job.minimumHourlyWage,
      displayDollarSign,
      2
    )} per hour`;
  }

  return 'Pay not specified';
};
