import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import CandidateAvatar from './CandidateAvatar';
import CandidateNameWithEdit from './CandidateNameWithEdit';
import CandidateEducationText from './CandidateEducationText';
import CandidateMajorText from './CandidateMajorText';
import CandidateMinorText from './CandidateMinorText';

class CandidateInfoWithEdit extends React.Component {
  render() {
    const {seeker, allowEdit, isMaSegment} = this.props;
    const showEducation =
      seeker.segments && seeker.segments.some((x) => x.requireEducationData);

    return (
      <>
        <div className="col-md-1">
          <br />
          <CandidateAvatar seeker={seeker} />
        </div>
        <div className="col-md-9">
          <CandidateNameWithEdit
            allowEdit={allowEdit}
            seeker={seeker}
            onEditClick={this.props.onEditClick}
          />
          {showEducation && (
            <Fragment>
              <CandidateEducationText
                seeker={seeker}
                isMaSegment={isMaSegment}
              />
              {isMaSegment === false && <CandidateMajorText seeker={seeker} />}
              {isMaSegment === false && <CandidateMinorText seeker={seeker} />}
            </Fragment>
          )}
        </div>
        <div className="col-md-2">{this.props.actionIcons}</div>
      </>
    );
  }
}

CandidateInfoWithEdit.propTypes = {
  seeker: PropTypes.object,
  actionIcons: PropTypes.node,
  onEditClick: PropTypes.func,
  allowEdit: PropTypes.bool.isRequired,
  isMaSegment: PropTypes.bool,
};

CandidateInfoWithEdit.defaultProps = {
  seeker: {},
  allowEdit: false,
  isMaSegment: false,
};

export default CandidateInfoWithEdit;
