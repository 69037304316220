import React from 'react';
import PropTypes from 'prop-types';

import {formatDate} from '../utils/miscHelper';
import invite from '../assets/images/invite.svg';
import {addressToString} from 'src/utils/miscHelper';

class InviteToApplyJobSelect extends React.Component {
  handleSelected = (event) => {
    this.props.onChange({
      [event.target.name]: event.target.checked,
    });
  };

  render() {
    const renderAddress = (job) => {
      return (
        <>
          <i className="nc-icon-glyph pin darkgray" />
          &nbsp;&nbsp;
          {job.remoteStatus === 'Remote'
            ? 'Remote'
            : !job.addressLine1
            ? 'Not Selected'
            : addressToString(job)}
        </>
      );
    };

    return (
      <div className="bd-whitebackground padding-16 ">
        <div className="custom-control custom-checkbox form-check">
          <input
            id={this.props.jobId}
            name={this.props.jobId}
            disabled={!!this.props.invitationDate}
            type="checkbox"
            checked={!!this.props.invitationDate || !!this.props.selected}
            onChange={this.handleSelected}
            className="custom-control-input form-check-input"
          />
          <label className="custom-control-label" htmlFor={this.props.jobId}>
            {this.props.jobName} - {this.props.teamName} -{' '}
            {this.props.employerName}
            <br />
            {renderAddress(this.props.job)}
            <br />
            {this.props.applied === true && (
              <div>
                <br />
                <i className="secondary-body">Already Applied</i>
              </div>
            )}
            {this.props.invitationDate && (
              <div>
                <br />
                <img src={invite} alt="invited-icon" />
                <i className="secondary-body">
                  invited on {formatDate(this.props.invitationDate)}
                </i>
              </div>
            )}
          </label>
        </div>
      </div>
    );
  }
}

InviteToApplyJobSelect.propTypes = {
  jobId: PropTypes.number.isRequired,
  jobName: PropTypes.string.isRequired,
  location: PropTypes.string,
  teamName: PropTypes.string.isRequired,
  applied: PropTypes.bool,
  employerName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  invitationDate: PropTypes.string,
  selected: PropTypes.bool,
  job: PropTypes.object,
};

export default InviteToApplyJobSelect;
