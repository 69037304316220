import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import _ from 'lodash';
import track from 'react-tracking';

import * as teamsActions from 'src/actions/teams';
import * as seekersActions from 'src/actions/seekers';
import * as jobsActions from 'src/actions/jobs';
import * as tableActions from 'src/actions/teamProfileDataTable';

import * as Layout from 'src/layout';
import TagsWidget from 'src/components/TagsWidget';
import JobProfile from 'src/components/JobProfile';
import Spotlight from 'src/components/Spotlight';
import IsDraftWidget from 'src/components/IsDraftWidget';
import {teamIsWatched} from 'src/utils/teamHelper';
import {jobIsWatched} from 'src/utils/jobHelper';
import {jobIsApplied} from 'src/utils/jobHelper';
import {findMatchData} from 'src/utils/matchHelper';
import {CreateJobForm, EditJobForm} from 'src/forms/JobForm';
import {EditTeamForm} from 'src/forms/TeamForm';
import TeamJobCard from 'src/pages/teamProfile/TeamJobCard';
import RecruiterJobCard from 'src/pages/teamProfile/RecruiterJobCard';
import BenchItem from 'src/pages/teamProfile/BenchItem';
import Cardinator from 'src/pages/teamProfile/Cardinator';
import TeamDetails from 'src/pages/teamProfile/TeamDetails';
import {HeartFilledWithoutBorderSvg} from 'src/assets/svg/svgComponents';
import editSvg from '../../assets/images/Edit.svg';
import linkSvg from '../../assets/svg/icons/link.svg';
import CodesForm from '../admin/codes/CodesForm';
import * as codesActions from '../../actions/invitationCodes';
import * as flashActions from '../../actions/flash';
import PreviousButton from 'src/components/PreviousButton';
import {has_permission, PERMISSIONS} from '../../services/authorizationApi';
import CandidateNotifyMissingRole from '../../components/Candidate/CandidateNotifyMissingRole';
import styles from './TeamProfilePage.module.scss';

const blankCode = {
  label: '',
};
@track({eventName: 'TeamProfilePage'}, {dispatchOnMount: true})
class TeamProfilePage extends React.Component {
  state = {
    teamIsWatched: false,
    teamFormOpen: false,
    jobProfileOpen: false,
    jobFormOpen: false,
    jobId: '',
    itemsPerPage: 8,
    formCodesIsOpen: false,
    invitationCode: blankCode,
  };

  componentDidMount() {
    const teamId = this.props.match.params.id;
    this.props.getTeamToCache(teamId);

    if (!(teamId in this.props.teamProfileDataTable)) {
      this.props.createTeamProfileDataTableTeam(teamId);
    }

    const tableState = this.getTableState();

    if (
      this.props.role === 'COMPANY_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
      this.props.role === 'ASCEND_RECRUITER'
    ) {
      this.props.getJobsByTeamAdvanced(
        teamId,
        tableState.currentPage,
        this.state.itemsPerPage,
        '-published',
        {},
        true
      );
    } else if (this.props.role === 'JOB_SEEKER') {
      this.props.getJobsByTeamAdvanced(
        teamId,
        tableState.currentPage,
        this.state.itemsPerPage,
        '',
        {seekerId: this.props.seekerId, includeMatchData: true},
        false
      );
      this.props.getSeeker(this.props.seekerId);
    }
    has_permission(PERMISSIONS.ASCEND_RECRUITER).then((resp) => {
      this.showAdminLinks = resp.data.hasPermission;
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.showJobNotFoundModal !== this.props.showJobNotFoundModal) {
      if (this.props.showJobNotFoundModal) {
        this.setState({
          jobProfileOpen: false,
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const teamId = nextProps.match.params.id;
    if (nextProps.seeker && nextProps.seeker.watchedTeams) {
      return {
        teamIsWatched: teamIsWatched(Number(teamId), nextProps.seeker),
      };
    }
    return null;
  }

  getTableState = () => {
    const teamId = this.props.match.params.id;
    if (teamId in this.props.teamProfileDataTable) {
      return this.props.teamProfileDataTable[teamId];
    } else {
      return {
        currentPage: 0,
        searchTerm: '',
        sortBy: '',
        sortAscending: true,
      };
    }
  };

  getTeam = (teamId) => {
    if (teamId in this.props.teamCache) {
      return this.props.teamCache[teamId];
    }
    return {};
  };

  handleEditTeamClicked = () => {
    const teamId = this.props.match.params.id;
    this.props.getTeamForEdit(teamId);
    this.setState({
      teamFormOpen: true,
    });
  };

  handleTeamEditSubmit = (team) => {
    this.props.updateTeam(team);
    this.setState({teamFormOpen: false});
  };

  handleTeamDeleteConfirmed = (teamId) => {
    this.props.deleteTeam(teamId);
  };

  handlePageChange = (pageNumber) => {
    const teamId = this.props.match.params.id;
    this.props.updateCurrentPage(teamId, pageNumber);
    if (
      this.props.role === 'COMPANY_RECRUITER' ||
      this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
      this.props.role === 'ASCEND_RECRUITER'
    ) {
      this.props.getJobsByTeamAdvanced(
        teamId,
        pageNumber,
        this.state.itemsPerPage,
        '-published',
        {},
        true
      );
    } else if (this.props.role === 'JOB_SEEKER') {
      this.props.getJobsByTeamAdvanced(
        teamId,
        pageNumber,
        this.state.itemsPerPage,
        '',
        {},
        false
      );
    }
  };

  handleApproveClick = () => {
    const teamId = this.props.match.params.id;
    this.props.updateTeam({id: teamId, published: true});
    this.setState({teamFormOpen: false});
  };

  handleTeamWatchedChanged = () => {
    const teamId = this.props.match.params.id;
    if (this.state.teamIsWatched === false) {
      this.props.createTeamWatch(teamId);
    } else {
      this.props.deleteTeamWatch(teamId);
    }
    this.setState({teamIsWatched: !this.state.teamIsWatched});
  };

  handleJobWatchedChanged = (job, watched) => {
    if (watched) {
      this.props.createJobWatch(job);
    } else {
      this.props.deleteJobWatch(job.id);
    }
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  handleCreateJobClicked = () => {
    this.setState({
      jobFormOpen: true,
      jobFormMode: 'create',
    });
  };

  handleEditJobClicked = (jobId) => {
    this.props.getJobForEdit(jobId);
    this.setState({
      jobFormOpen: true,
      jobIdToEdit: jobId,
      jobFormMode: 'edit',
    });
  };

  handleEmployerClicked = (id) => {
    this.props.history.push('/employer/' + id);
  };

  handleCompanyClicked = (employerId) => {
    if (employerId) {
      this.props.history.push('/employer/' + employerId);
    }
  };

  handleCodesClicked = () => {
    const teamId = this.props.match.params.id;

    if (teamId) {
      this.props.setEditInvitationCode({
        label: '',
        shortUrl: '',
        destinationPage: '/team/' + teamId,
      });
      this.setState({
        formCodesIsOpen: true,
        headerText: 'New Invitation Code',
        submitType: 'CREATE',
        invitationCode: blankCode,
        formType: 'new',
      });
    }
  };

  handleDeleteJobClicked = (jobId) => {
    this.props.deleteJob(jobId);
  };

  handleJobEditSubmit = (job) => {
    this.props.updateJob(job);
    this.setState({jobFormOpen: false});
  };

  handleJobCreateSubmit = (job) => {
    const teamId = this.props.match.params.id;
    const team = this.getTeam(teamId);
    const employerId = team.employerId;
    this.props.createJob({...job, teamId, employerId});
    this.setState({jobFormOpen: false});
  };

  handleJobClicked = (jobId) => {
    this.props.getJobToCache(jobId);
    this.setState({
      jobProfileOpen: true,
      jobId: jobId,
    });
  };

  handleModalClose = () => {
    this.setState({
      jobFormOpen: false,
      teamFormOpen: false,
      jobProfileOpen: false,
      formCodesIsOpen: false,
    });
  };

  handleWatchChanged = (job, watched) => {
    if (watched) {
      this.props.createJobWatch(job);
    } else {
      this.props.deleteJobWatch(job.id);
    }
  };

  buildRecruiterJobCard = (job, role) => {
    return (
      <div key={job.id}>
        <RecruiterJobCard
          job={job}
          role={role}
          onDeleteClick={() => this.handleDeleteJobClicked(job.id)}
          onJobEditClick={() => this.handleEditJobClicked(job.id)}
          onJobClick={() => this.handleJobClicked(job.id)}
        />
        <br />
      </div>
    );
  };

  buildSeekerJobCard = (job, role) => {
    const watchedJobIds = this.props.seeker.watchedJobs.map((j) => j.id);
    const jobIsWatched = _.includes(watchedJobIds, job.id);

    let matchData = {
      matchLevels: [0, 0, 0],
      ascendFit: false,
      overallFit: false,
    };
    if (job.matchData) {
      matchData = job.matchData;
    }

    const matchesInterest = job.apprenticePathwayId
      ? this.props.seeker.apprenticePathways.findIndex(
          (pathway) => pathway.value === job.apprenticePathwayId
        ) !== -1
      : false;

    return (
      <div key={job.id}>
        <TeamJobCard
          name={job.name}
          datePosted={job.publishedAt}
          summary={job.info1}
          matchData={matchData}
          isApprenticeProgram={this.props.isApprenticeProgram}
          watched={jobIsWatched}
          onWatchChanged={(watched) =>
            this.handleJobWatchedChanged(job, watched)
          }
          onJobClick={() => this.handleJobClicked(job.id)}
          matchesInterest={matchesInterest}
        />
        <br />
      </div>
    );
  };

  handleInvitationCodeSubmit = (invitationCode) => {
    this.props.createInvitationCodeAsync(invitationCode).then((response) => {
      const codeUrl = `${window.location.protocol}//${window.location.host}/signup?code=${response.code}`;

      navigator.clipboard
        .writeText(codeUrl)
        .then(() =>
          this.props.addFlashMessage(
            'Sign-up link copied. Paste to use.',
            'notification'
          )
        );
    });

    this.setState({formCodesIsOpen: false});
  };

  render() {
    const {seeker, role, jobs} = this.props;

    const teamId = this.props.match.params.id;
    const team = this.getTeam(teamId);

    let job = {};
    for (var i = 0, jobLen = jobs.length; i < jobLen; i++) {
      if (jobs[i].id === this.state.jobId) job = jobs[i];
    }

    const tableState = this.getTableState();

    const isDataAvailable = () => {
      if (!team) return false;

      if (!team.id) return false;

      if (role === 'JOB_SEEKER') {
        if (!seeker.id) return false;
      }
      return true;
    };

    const shouldDisplaySpotlight = () => {
      return team.spotlightName && team.spotlightInfo && team.spotlightTitle;
    };

    const seekerJobCards = () => {
      return (
        <Cardinator
          jobs={jobs}
          currentPage={tableState.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          totalItems={this.props.totalItems}
          itemsLoading={this.props.jobsLoading}
          handlePageChange={this.handlePageChange}
          cardObject={this.buildSeekerJobCard}
        />
      );
    };

    const recruiterJobCards = () => {
      return (
        <Cardinator
          jobs={jobs}
          currentPage={tableState.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          totalItems={this.props.totalItems}
          itemsLoading={this.props.jobsLoading}
          handlePageChange={this.handlePageChange}
          cardObject={this.buildRecruiterJobCard}
        />
      );
    };

    const seekerActionIcons = () => {
      if (this.state.teamIsWatched === true) {
        return (
          <Layout.ActionIcons>
            <Layout.ActionIcon
              svgImage={
                <HeartFilledWithoutBorderSvg
                  width="24"
                  height="24"
                  className="svg_color_fill_pink_heart"
                />
              }
              svgAsImage={true}
              message="Favorite this team."
              ariaPressed={this.state.teamIsWatched}
              onClick={() => this.handleTeamWatchedChanged()}
            />
          </Layout.ActionIcons>
        );
      } else {
        return (
          <Layout.ActionIcons>
            <Layout.ActionIcon
              svgImage={
                <HeartFilledWithoutBorderSvg
                  width="22"
                  height="22"
                  strokeWidth="0.5"
                  className="svg_color_fill_gray_heart svg_color_stroke_gray_heart"
                />
              }
              svgAsImage={true}
              ariaPressed={this.state.teamIsWatched}
              message="Favorite this team."
              onClick={() => this.handleTeamWatchedChanged()}
            />
          </Layout.ActionIcons>
        );
      }
    };

    const recruiterActionIcons = (employerId) => {
      return (
        <Layout.ActionIcons>
          <Layout.ActionIcon
            svg={editSvg}
            message="Edit Team"
            onClick={() => this.handleEditTeamClicked()}
          />
          <Layout.ActionIcon
            glyph="buildings"
            message="Employer Profile"
            onClick={() => this.handleCompanyClicked(employerId)}
          />
          <Layout.ActionIcon
            svg={linkSvg}
            message="Create sign-up link for this page"
            onClick={() => this.handleCodesClicked()}
          />
        </Layout.ActionIcons>
      );
    };

    const candidateBench = () => {
      const bench = team.watchedSeekers;
      return (
        <>
          <div className="padding-bottom-sixteen">
            <h4 className="inlineblock normal-caption bold">
              SAVED CANDIDATES ({bench.length})
            </h4>
          </div>
          {bench.map((seeker) => {
            return (
              <div
                key={seeker.id}
                className="secondary-subheader extraspace padding-bottom-sixteen nowrap"
              >
                <BenchItem
                  avatarUrl={seeker.avatarUrl}
                  seekerId={seeker.id}
                  seekerName={`${seeker.firstName} ${seeker.lastName}`}
                />
              </div>
            );
          })}
        </>
      );
    };

    const renderName = (team) => {
      if (team && team.id) {
        return (
          <div className="profile-header bold padding-bottom-eight">
            <h1>{team.name}</h1>
          </div>
        );
      } else {
        return (
          <div className="profile-header bold padding-bottom-eight">&nbsp;</div>
        );
      }
    };

    const renderTeamSummary = (summary) => {
      if (summary !== '') {
        return (
          <div>
            <div className="normal-subheader bold">
              <h2>Team Summary</h2>
            </div>
            <br />
            <div className="secondary-subheader">{team.summary}</div>
            <br />
          </div>
        );
      }
    };

    const renderDraftWidget = (team) => {
      if (team && team.id && !team.published) {
        return (
          <IsDraftWidget
            titleString="You're viewing a draft of your team's profile."
            editClick={this.handleEditTeamClicked}
            approveClick={this.handleApproveClick}
          />
        );
      }
    };

    return (
      <DocumentTitle title="Team Profile">
        <div className="wholepage">
          {this.props.accessProhibitedError && (
            <div className={styles.accessProhibitedMessage}>
              Access Prohibited. You do not have permission to view other
              employer's jobs.
            </div>
          )}

          {this.props.showJobNotFoundModal && (
            <CandidateNotifyMissingRole
              isOpen={this.props.showJobNotFoundModal}
              onClose={() => this.props.setJobNotFoundModal(false)}
            />
          )}
          <CodesForm
            formType={'CREATE'}
            isOpen={this.state.formCodesIsOpen}
            invitationCode={this.state.invitationCode}
            onSubmit={this.handleInvitationCodeSubmit}
            headerText={'New Invitation Code'}
            onClose={this.handleModalClose}
            destinationPage={'/team/' + this.props.match.params.id}
          />

          {(this.props.role === 'COMPANY_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
            this.props.role === 'ASCEND_RECRUITER') &&
            renderDraftWidget(team)}
          <Layout.PageHeader
            title={null}
            showAdminLinks={this.showAdminLinks}
          />

          {(this.props.role === 'ASCEND_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
            this.props.role === 'COMPANY_RECRUITER') &&
            this.state.jobFormMode === 'edit' && (
              <EditJobForm
                id={this.state.jobIdToEdit}
                onSubmit={this.handleJobEditSubmit}
                onDelete={() =>
                  this.handleDeleteJobClicked(this.state.jobIdToEdit)
                }
                isOpen={this.state.jobFormOpen}
                onClose={this.handleModalClose}
              />
            )}
          {this.props.role === 'ASCEND_RECRUITER' &&
            this.state.jobFormMode === 'create' && (
              <CreateJobForm
                onSubmit={this.handleJobCreateSubmit}
                isOpen={this.state.jobFormOpen}
                onClose={this.handleModalClose}
                employerId={team.employerId}
                showTeams={false}
              />
            )}
          {(this.props.role === 'ASCEND_RECRUITER' ||
            this.props.role === 'COMPANY_RECRUITER_ADMIN' ||
            this.props.role === 'COMPANY_RECRUITER') && (
            <EditTeamForm
              id={teamId}
              onSubmit={this.handleTeamEditSubmit}
              onDelete={() => this.handleTeamDeleteConfirmed(teamId)}
              isOpen={this.state.teamFormOpen}
              onClose={this.handleModalClose}
            />
          )}
          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <PreviousButton />
              <div className="container">
                <div className="row">
                  <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    {renderName(team)}
                    <div className="secondary-subheader">
                      <button
                        role="link"
                        tabIndex={0}
                        className="border-0 inlineblock clickable orangeHover"
                        style={{
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                        }}
                        onClick={() =>
                          this.handleEmployerClicked(team.employerId)
                        }
                      >
                        <i className="nc-icon-glyph-med building" />
                        &nbsp;&nbsp;{team.employerName}
                      </button>
                      <div className="inlineblock">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <HeartFilledWithoutBorderSvg
                          width="22"
                          height="22"
                          strokeWidth="0.5"
                          className="svg_color_fill_gray_heart svg_color_stroke_gray_heart"
                        />
                        &nbsp;&nbsp;{team.watchersCount} Followers
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {role === 'JOB_SEEKER' && seekerActionIcons()}
                    {this.props.role === 'ASCEND_RECRUITER' &&
                      recruiterActionIcons(team.employerId)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                {isDataAvailable() && (
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <TagsWidget
                          tags={team.skillTags
                            .map((tag) => ({
                              tagName: tag.name,
                              definition: tag.definition ?? null,
                            }))
                            .sort((x) => x.name)}
                          title="SKILLS & EXPERTISE"
                          tagClass="skilltag"
                          readOnly={true}
                        />
                      </div>
                      {job && job.id && (
                        <JobProfile
                          key={job.id}
                          isOpen={this.state.jobProfileOpen}
                          job={job}
                          onClose={this.handleModalClose}
                          onWatchChanged={(watched) =>
                            this.handleWatchChanged(job, watched)
                          }
                          watched={jobIsWatched(
                            this.state.jobId,
                            this.props.seeker
                          )}
                          applied={jobIsApplied(
                            this.state.jobId,
                            this.props.seeker
                          )}
                          onAppliedChanged={(applied) =>
                            this.handleAppliedChanged(this.state.jobId, applied)
                          }
                          matchData={findMatchData(
                            this.state.jobId,
                            this.props.seeker.matches
                          )}
                        />
                      )}

                      <div className="col-md-6">
                        {renderTeamSummary(team.summary)}

                        <div>
                          <div className="normal-subheader bold inlineblock">
                            <h3>
                              Available Positions ({team.publishedJobCount})
                            </h3>
                          </div>
                          {role === 'ASCEND_RECRUITER' && (
                            <div
                              className="clickable inlineblock float-right active-body"
                              onClick={this.handleCreateJobClicked}
                            >
                              + Create Role
                            </div>
                          )}
                        </div>
                        <br />
                        {role === 'JOB_SEEKER' && seekerJobCards()}
                        {role !== 'JOB_SEEKER' && recruiterJobCards()}
                        {shouldDisplaySpotlight() && (
                          <Spotlight
                            headline="Employee Spotlight"
                            avatarUrl={team.spotlightAvatarUrl}
                            name={team.spotlightName}
                            title={team.spotlightTitle}
                            info={team.spotlightInfo}
                          />
                        )}
                      </div>

                      <div className="col-md-3">
                        <TeamDetails team={team} />
                        <hr />
                        {(role === 'COMPANY_RECRUITER' ||
                          role === 'COMPANY_RECRUITER_ADMIN') &&
                          candidateBench()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <br />
            </main>
          </div>
          <Layout.PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

TeamProfilePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getTeamToCache: PropTypes.func.isRequired,
  getTeamForEdit: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  getSeeker: PropTypes.func.isRequired,
  createTeamWatch: PropTypes.func.isRequired,
  deleteTeamWatch: PropTypes.func.isRequired,
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  getJobsByTeamAdvanced: PropTypes.func.isRequired,
  getJobForEdit: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  teamCache: PropTypes.shape({
    id: PropTypes.number,
    team: PropTypes.object,
  }),
  jobs: PropTypes.array,
  role: PropTypes.string,
  seeker: PropTypes.object,
  seekerId: PropTypes.string,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  jobsLoading: PropTypes.bool.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  teamProfileDataTable: PropTypes.object.isRequired,
  createTeamProfileDataTableTeam: PropTypes.func.isRequired,
  createInvitationCode: PropTypes.func.isRequired,
  createInvitationCodeAsync: PropTypes.func.isRequired,
  setEditInvitationCode: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func,
  isApprenticeProgram: PropTypes.bool,
  getJobToCache: PropTypes.func.isRequired,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
  accessProhibitedError: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    teamCache: state.teams.teamCache,
    role: state.profile.role,
    seeker: state.seekers.seeker,
    jobs: state.jobs.jobs,
    seekerId: state.profile.seekerId,
    isApprenticeProgram: state.profile.isApprenticeProgram,
    itemsPerPage: state.jobs.itemsPerPage,
    totalItems: state.jobs.totalItems,
    jobsLoading: state.jobs.jobsLoading,
    teamProfileDataTable: state.teamProfileDataTable.byTeam,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
    accessProhibitedError: state.teams.accessProhibitedError,
  };
};

const mapDispatchToProps = {
  ...teamsActions,
  ...seekersActions,
  ...jobsActions,
  ...tableActions,
  ...codesActions,
  ...flashActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamProfilePage)
);
