import React, {useRef} from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import PushButtonArray from 'src/components/controls/buttons/PushButtonArray/PushButtonArray';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';

export default function DemographicsPage({
  next,
  previous,
  intakeCache,
  headerImage,
}) {
  const formRef = useRef({});
  const otherDemographicFields = [
    'veteran',
    'firstGenStudent',
    'pellGrant',
    'twentyFirstCenturyScholar',
  ];

  const clearFormDemographicFields = (form) => {
    for (const field of otherDemographicFields) {
      form.mutators.setValue(field, false);
    }
  };

  const VETERAN = 0;
  const FIRST_GEN_STUDENT = 1;
  const PELL_GRANT = 2;
  const TWENTY_FIRST_CENTURY_SCHOLAR = 3;
  const PREFER_NOT_TO_DISCLOSE = 4;
  const NONE_OF_THE_ABOVE = 5;

  const clearPreferNotToDisclose = (form) => {
    form.mutators.setValue(
      'demographics',
      form
        .getState()
        .values.demographics.filter(
          (x) => x.buttonValue !== PREFER_NOT_TO_DISCLOSE
        )
    );
  };

  const clearNoneOfTheAbove = (form) => {
    form.mutators.setValue(
      'demographics',
      form
        .getState()
        .values.demographics.filter((x) => x.buttonValue !== NONE_OF_THE_ABOVE)
    );
  };

  const buildButtonList = () => [
    {
      buttonText: 'Prefer not to answer',
      buttonValue: PREFER_NOT_TO_DISCLOSE,
      buttonAction: (props, form) => {
        clearFormDemographicFields(form);
        const includesPreferNotToDisclose = form
          .getState()
          .values.demographics.some(
            (x) => x.buttonValue === PREFER_NOT_TO_DISCLOSE
          );
        if (includesPreferNotToDisclose) {
          form.mutators.setValue('demographics', [
            {buttonValue: PREFER_NOT_TO_DISCLOSE},
          ]);
        }
      },
    },
    {
      buttonText: 'Active Military or Veteran',
      buttonValue: VETERAN,
      buttonAction: (props, form) => {
        clearPreferNotToDisclose(form);
        clearNoneOfTheAbove(form);
        form.mutators.setValue(
          'veteran',
          form
            .getState()
            .values.demographics.some((x) => x.buttonValue === VETERAN)
        );
      },
    },
    {
      buttonText: 'First-Generation College Student',
      buttonValue: FIRST_GEN_STUDENT,
      buttonAction: (props, form) => {
        clearPreferNotToDisclose(form);
        clearNoneOfTheAbove(form);
        form.mutators.setValue(
          'firstGenStudent',
          form
            .getState()
            .values.demographics.some(
              (x) => x.buttonValue === FIRST_GEN_STUDENT
            )
        );
      },
    },
    {
      buttonText: 'Federal Pell Grant Eligible Student',
      buttonValue: PELL_GRANT,
      buttonAction: (props, form) => {
        clearPreferNotToDisclose(form);
        clearNoneOfTheAbove(form);
        form.mutators.setValue(
          'pellGrant',
          form
            .getState()
            .values.demographics.some((x) => x.buttonValue === PELL_GRANT)
        );
      },
    },
    {
      buttonText: 'Indiana 21st Century Scholar',
      buttonValue: TWENTY_FIRST_CENTURY_SCHOLAR,
      buttonAction: (props, form) => {
        clearPreferNotToDisclose(form);
        clearNoneOfTheAbove(form);
        form.mutators.setValue(
          'twentyFirstCenturyScholar',
          form
            .getState()
            .values.demographics.some(
              (x) => x.buttonValue === TWENTY_FIRST_CENTURY_SCHOLAR
            )
        );
      },
    },
    {
      buttonText: 'None of the Above',
      buttonValue: NONE_OF_THE_ABOVE,
      buttonAction: (props, form) => {
        clearPreferNotToDisclose(form);
        clearFormDemographicFields(form);
        const includesNoneOfTheAbove = form
          .getState()
          .values.demographics.some((x) => x.buttonValue === NONE_OF_THE_ABOVE);
        if (includesNoneOfTheAbove) {
          form.mutators.setValue('demographics', [
            {buttonValue: NONE_OF_THE_ABOVE},
          ]);
        }
      },
    },
  ];

  const readFormValues = () => {
    if (typeof formRef?.current?.getState === 'function') {
      const val = formRef?.current?.getState()?.values;

      return val;
    }
  };

  return (
    <>
      <PageHeader
        title="Do any of these options apply to you?"
        subTitle="Select all that apply"
        headerImage={headerImage}
      />
      <div className={styles.pageContent}>
        <Form
          onSubmit={next}
          validate={(values) => {
            const errors = {};
            if (values.demographics.length === 0) {
              errors['demographics'] = 'Please select at least one option';
            }
            return errors;
          }}
          mutators={{
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          keepDirtyOnReinitialize={true}
          initialValues={{
            demographics: intakeCache.demographics || [],
          }}
        >
          {({handleSubmit, form}) => {
            formRef.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  component={PushButtonArray}
                  name="demographics"
                  buttonList={buildButtonList()}
                  displayVertical={true}
                  multiSelect={true}
                  form={form}
                />
              </form>
            );
          }}
        </Form>
      </div>
      <PageFooter
        next={() => formRef?.current?.submit()}
        previous={previous}
        getValues={readFormValues}
      />
    </>
  );
}

DemographicsPage.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.object,
  headerImage: PropTypes.any,
};
