import React from 'react';
import PropTypes from 'prop-types';

export default function BusyButtonMini({
  buttonText,
  busyText,
  onClick,
  buttonIcon,
  style,
  float,
  disabled,
  alignText,
  className,
  buttonType,
  busy,
}) {
  const buttonDisplay = () => {
    if (busy) {
      return (
        <>
          <span
            className={'align-middle spinner-border spinner-border-sm me-2'}
            aria-hidden="true"
          />
          <span role="status">{busyText}</span>
        </>
      );
    } else {
      return (
        <>
          {buttonIcon && (
            <i className={buttonIcon + ' nc-icon-glyph-med me-2'} />
          )}
          {buttonText}
        </>
      );
    }
  };

  const handleClick = async () => {
    if (onClick) {
      await onClick();
    }
  };

  return (
    <button
      type={buttonType}
      className={
        (disabled || busy ? '' : 'clickable') +
        ' btn btn-primary fullwidth ' +
        float +
        ' ' +
        alignText +
        className
      }
      style={style}
      onClick={handleClick}
      disabled={disabled || busy}
    >
      {buttonDisplay()}
    </button>
  );
}

BusyButtonMini.propTypes = {
  buttonText: PropTypes.string.isRequired,
  busyText: PropTypes.string,
  onClick: PropTypes.func,
  buttonIcon: PropTypes.string,
  style: PropTypes.object,
  float: PropTypes.string,
  disabled: PropTypes.bool,
  alignText: PropTypes.string,
  className: PropTypes.string,
  buttonType: PropTypes.string,
  busy: PropTypes.bool,
};

BusyButtonMini.defaultProps = {
  disabled: false,
  buttonType: 'button',
  busy: false,
};
