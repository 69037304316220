import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '../../utils/commonHooks';
import {connect} from 'react-redux';
import 'url-search-params-polyfill';

import * as usersActions from 'src/actions/users';
import {actions as authActions} from 'src/reducers/authentication';

import * as Layout from 'src/layout';
import PasswordModal from 'src/pages/activate/PasswordModal';
import MessageModal from 'src/pages/activate/MessageModal';
import ErrorModal from 'src/pages/activate/ErrorModal';
import {getUtmKeys, setUtmKeys} from 'src/utils/utmKeys';

class ActivatePage extends React.Component {
  state = {
    passwordModalIsOpen: true,
  };

  // Redirect if there's no token
  componentDidMount() {
    const utmKeys = getUtmKeys();
    localStorage.clear();
    setUtmKeys(utmKeys);
    if (!this.getParam('token')) {
      this.props.history.push('/');
    }
  }

  handleModalClose = () => {
    this.setState({
      passwordModalIsOpen: false,
      errorModalIsOpen: false,
    });
  };

  getParam = (paramname) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramname);
  };

  hasParam = (p) => {
    const params = new URLSearchParams(window.location.search);
    return params.has(p);
  };

  handleSubmit = (values) => {
    const token = this.getParam('token');
    const changeEmail = this.hasParam('changeEmail');
    const password = values.password;
    this.setState({passwordModalIsOpen: false});
    this.props.activateUser(token, password, changeEmail);
  };

  handleSignInClick = () => {
    this.props.history.push('/');
  };

  handleNewTokenClick = () => {};

  handleErrorSubmit = () => {
    this.setState({errorModalIsOpen: false, passwordModalIsOpen: true});
  };

  render() {
    const renderMessageModal = () => {
      const {activationError, activationSuccess} = this.props;
      if (!activationError && !activationSuccess) {
        return null;
      }
      if (activationSuccess) {
        return (
          <MessageModal
            isOpen={true}
            onSubmit={this.handleSignInClick}
            message="Your account has been activated."
            buttonText="Sign In"
            onClose={this.handleModalClose}
          />
        );
      }
      switch (activationError.type) {
        case 'AlreadyActivatedError':
          return (
            <MessageModal
              isOpen={true}
              onClose={this.handleModalClose}
              onSubmit={this.handleSignInClick}
              message="Your account has already been activated. Password not changed."
              buttonText="Sign In"
            />
          );
        case 'ActivationTokenExpiredError':
          return (
            <MessageModal
              isOpen={true}
              onClose={this.handleModalClose}
              onSubmit={this.handleNewTokenClick}
              message="Your activation token has expired."
              buttonText="Get new token"
            />
          );
        default:
          if (!this.state.errorModalIsOpen) {
            this.setState({errorModalIsOpen: true});
          }
          return (
            <ErrorModal
              isOpen={this.state.errorModalIsOpen}
              onClose={this.handleModalClose}
              onSubmit={this.handleErrorSubmit}
            />
          );
      }
    };

    return (
      <div>
        <Layout.PageHeader showAuthLink={false}>
          <span className="normal-display-white bold">
            Welcome to the Ascend Network
          </span>
        </Layout.PageHeader>

        <main id="main" tabIndex="-1">
          <PasswordModal
            isOpen={this.state.passwordModalIsOpen}
            onClose={this.handleModalClose}
            onSubmit={this.handleSubmit}
          />

          {renderMessageModal()}
        </main>
      </div>
    );
  }
}

ActivatePage.propTypes = {
  history: PropTypes.object.isRequired,
  activateUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  activationError: PropTypes.object,
  activationSuccess: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    activationError: state.users.activationError,
    activationSuccess: state.users.activationSuccess,
  };
}

const pageActions = {
  ...usersActions,
  ...authActions,
};

export default withRouter(connect(mapStateToProps, pageActions)(ActivatePage));
