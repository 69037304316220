import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Form, Field} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {Alert} from 'reactstrap';
import _ from 'lodash';

import * as industriesActions from 'src/actions/industries';
import * as regionsActions from 'src/actions/regions';
import * as zipcodesActions from 'src/actions/zipcodes';

import {
  MultiSelectField,
  AvatarField,
  TextField,
  OnOffSwitch,
  SelectField,
  TextAreaField,
  ResourceFieldArray,
} from 'src/formFields';

import {AscendModal, AscendModalBody} from 'src/layout';
import AreYouSureModal from 'src/components/AreYouSureModal';
import {
  trimFields,
  multiselectChangeHandler,
  getSortedLookup,
} from 'src/utils/fieldHelper';
import * as segmentActions from '../actions/SegmentActions';
import {PERMISSIONS} from '../services/authorizationApi';
import * as usersActions from '../actions/users';
import enterprise from '../assets/images/Enterprise.svg';
import {OnChange} from 'react-final-form-listeners';
import LocationFieldArray from 'src/formFields/LocationFieldArray';
import PhotoFieldArray from '../formFields/PhotoFieldArray';
import LoadingOverlay from 'react-loading-overlay';
import {
  isValidHttpsUrl,
  INVALID_URL_ERROR_MESSAGE,
} from 'src/utils/fieldHelper';

LoadingOverlay.propTypes = undefined;

const validate = (values) => {
  const errors = {};

  const nameTrimmed = (values.name || '').trim();
  if (nameTrimmed.length === 0) {
    errors.name = 'Error: Name cannot be blank.';
  } else if (nameTrimmed === '.' || nameTrimmed === ' ') {
    errors.name = 'Error: Name cannot be just a period or space.';
  } else if (nameTrimmed.length < 3) {
    errors.name = 'Error: Name must be 3 characters or more.';
  }

  if (_.isEmpty(values.segments)) {
    errors.segments = 'Error: One or more segments are required.';
  }

  if (
    values.tagline &&
    values.tagline.length > 0 &&
    values.tagline.length < 10
  ) {
    errors.tagline = 'Error: Tagline must be more than 10 characters.';
  } else if (!values.tagline) {
    errors.tagline = 'Error: Tagline cannot be blank';
  }

  if (values.published === true) {
    if (values.about && values.about.length > 0 && values.about.length < 10) {
      errors.about = 'Error: About Us must be more than 10 characters.';
    } else if (!values.about) {
      errors.about = 'Error: About Us cannot be blank';
    }
    if (_.isEmpty(values.regions)) {
      errors.regions = 'Error: Required';
    }
    if (_.isEmpty(values.industries)) {
      errors.industries = 'Error: Required';
    }
    if (!values.zipcodeId) {
      errors.zipcodeId = 'Error: Neighborhood is required.';
    }
    if (values.url && !isValidHttpsUrl(values.url)) {
      errors.url = `Error: ${INVALID_URL_ERROR_MESSAGE}`;
    }
    if (values.twitter && !isValidHttpsUrl(values.twitter)) {
      errors.twitter = `Error: ${INVALID_URL_ERROR_MESSAGE}`;
    }
    if (values.facebook && !isValidHttpsUrl(values.facebook)) {
      errors.facebook = `Error: ${INVALID_URL_ERROR_MESSAGE}`;
    }
    if (values.instagram && !isValidHttpsUrl(values.instagram)) {
      errors.instagram = `Error: ${INVALID_URL_ERROR_MESSAGE}`;
    }
    if (values.linkedin && !isValidHttpsUrl(values.linkedin)) {
      errors.linkedin = `Error: ${INVALID_URL_ERROR_MESSAGE}`;
    }
  }
  return errors;
};

const validateResource = (values) => {
  let error = undefined;
  if (!_.isEmpty(values)) {
    values.forEach((resource) => {
      if (resource) {
        if (!resource.url) {
          error = 'Error: All Resources require a URL'; //https://www.grammar.com/a-vs-an-when-to-use/
        } else if (!isValidHttpsUrl(resource.url)) {
          error = "Error: All URLs must be valid, starting with 'https://'";
        }
      }
    });
  }
  return error;
};

const validateLocation = (employer) => {
  if (!Array.isArray(employer.locations) || employer.locations.length === 0) {
    return []; // No validation errors if there are no locations
  }
  const errors = [];
  let primaryLocationCount = 0;

  employer.locations.forEach((loc, index) => {
    const locationErrors = {};

    // Address Line 1
    if (!loc.addressLine1 || loc.addressLine1.trim().length < 3) {
      locationErrors.addressLine1 =
        'Address Line 1 is required and must be at least 3 characters long';
    }

    // City
    if (!loc.city || loc.city.trim().length < 2) {
      locationErrors.city =
        'City is required and must be at least 2 characters long';
    }

    // State
    if (!loc.stateId) {
      locationErrors.stateId = 'State is required';
    }

    // Postal Code
    if (!loc.postalCode || !/^\d{5}(-\d{4})?$/.test(loc.postalCode.trim())) {
      locationErrors.postalCode = 'Valid 5-digit ZIP code is required';
    }

    // Count primary locations
    if (loc.isPrimaryLocation) {
      primaryLocationCount++;
    }

    if (Object.keys(locationErrors).length > 0) {
      errors[index] = locationErrors;
    }
  });

  // Check primary location requirement only if there are locations and the employer is published
  if (employer.published && employer.locations.length > 0) {
    if (primaryLocationCount === 0) {
      if (!errors[0]) errors[0] = {};
      errors[0].isPrimaryLocation = 'Error: Primary location is required';
    } else if (primaryLocationCount > 1) {
      const errorMessage = 'Error: Only one primary location allowed';
      employer.locations.forEach((loc, index) => {
        if (loc.isPrimaryLocation) {
          if (!errors[index]) errors[index] = {};
          errors[index].isPrimaryLocation = errorMessage;
        }
      });
    }
  }

  // If there are any errors, return them; otherwise, return undefined
  return errors.length > 0 ? errors : [];
};

const validatePhoto = (values) => {
  let error = undefined;
  if (!_.isEmpty(values)) {
    values.forEach((image) => {
      if (image && image.altText === undefined) {
        error = 'Error: Alternate text is required for each image!';
      }
    });
  }

  return error;
};

const defaults = {
  published: false,
  resources: [],
  locations: [],
};

class EmployerForm extends React.Component {
  state = {
    areYouSureModalIsOpen: false,
    photos: [],
    showBusy: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.props.getIndustries();
    this.props.getRegions();
    this.props.getZipcodes();
    this.props.getSegments();
    this.props.getUsersByPermission(PERMISSIONS.CAN_BE_ACCOUNT_MANAGER);
  }

  handleDeleteClicked = () => {
    this.setState({
      areYouSureModalIsOpen: true,
    });
  };

  handleDeleteConfirmed = () => {
    this.props.onDelete();
    this.props.onClose();
    this.setState({
      areYouSureModalIsOpen: false,
    });
  };

  handleAreYouSureClosed = () => {
    this.setState({
      areYouSureModalIsOpen: false,
    });
  };

  onSubmit = (data) => {
    const fields = [
      'name',
      'location',
      'tagline',
      'url',
      'about',
      'companySize',
      'instagram',
      'facebook',
      'linkedin',
      'twitter',
      'mainPhotoAlt',
    ];
    const newData = trimFields(data, fields);
    const industryIds = data.industries ? data.industries.map((t) => t.id) : [];
    const regionIds = data.regions ? data.regions.map((t) => t.id) : [];
    const zipcodeId = data.zipcodeId;

    let resources = [];
    if (data.resources) {
      resources = data.resources.filter((x) => x != null);
    }

    const fullEmployer = {
      ...newData,
      name: data.name.trim(),
      zipcodeId,
      industryIds,
      regionIds,
      resources,
    };

    const validationErrors = validateLocation(fullEmployer);
    if (validationErrors.length > 0) {
      // Show validation errors in the form
      this.setState({formErrors: validationErrors});
      return;
    }

    this.props.onSubmit(fullEmployer);
    this.formRef.current.reset();
  };

  handleClosed = () => {
    this.formRef.current.reset();
    this.props.onClose();
  };

  render() {
    const industries = getSortedLookup(this.props.industries, ['name']);

    const regions = getSortedLookup(this.props.regions, ['name']);

    const zipcodes = getSortedLookup(this.props.zipcodes, ['name']);

    const segments = getSortedLookup(this.props.segments, ['name'], 'varies');

    const {
      isOpen,
      mode,
      role,
      industriesLoading,
      regionsLoading,
      zipcodesLoading,
      segmentsLoading,
      usersLoading,
    } = this.props;

    const initialValues = {
      ...defaults,
      ...this.props.initialValues,
      locations: this.props.initialValues?.locations || [],
    };

    return (
      <div>
        <AreYouSureModal
          areYouSureText="Are you sure that you want to remove this employer?"
          isOpen={this.state.areYouSureModalIsOpen}
          onClose={this.handleAreYouSureClosed}
          onYes={this.handleDeleteConfirmed}
        />
        <AscendModal isOpen={isOpen} onClose={this.handleClosed}>
          <AscendModalBody hasClose={true}>
            <div className="padding-32">
              <Form
                onSubmit={this.onSubmit}
                validate={validate}
                mutators={{...arrayMutators}}
                initialValues={initialValues}
              >
                {({handleSubmit, form, errors}) => {
                  this.formRef.current = form;

                  return (
                    <form>
                      <OnChange name="industries">
                        {(industries) => {
                          if (industries) {
                            multiselectChangeHandler(
                              'industries',
                              industries,
                              form.change
                            );
                          }
                        }}
                      </OnChange>
                      <OnChange name="regions">
                        {(regions) => {
                          if (regions) {
                            multiselectChangeHandler(
                              'regions',
                              regions,
                              form.change
                            );
                          }
                        }}
                      </OnChange>
                      <center>
                        <img
                          src={enterprise}
                          height="54"
                          width="54"
                          alt="enterprise"
                        />
                        <h1 className="normal-title padding-top-thirtytwo padding-bottom-eight bold">
                          {mode === 'edit' && 'Edit Employer'}
                          {mode === 'create' && 'New Employer'}
                        </h1>
                      </center>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <h2 className="normal-title bold padding-top-sixteen padding-bottom-sixteen">
                          General Information
                        </h2>
                        <div
                          className="align-self-center required-field"
                          style={{fontSize: '14px'}}
                        >
                          * indicates required fields
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="name"
                            component={TextField}
                            label={'Employer Name'}
                            size={36}
                            maxLength={64}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {errors?.locations &&
                            Array.isArray(errors.locations) &&
                            errors.locations.some(
                              (location) => location?.isPrimaryLocation
                            ) && (
                              <div className="alert alert-danger">
                                {
                                  errors.locations.find(
                                    (location) => location?.isPrimaryLocation
                                  )?.isPrimaryLocation
                                }
                              </div>
                            )}
                          <FieldArray
                            name="locations"
                            component={LocationFieldArray}
                            maxFields={3}
                            validate={() =>
                              validateLocation(form.getState().values)
                            }
                            required={false} // allow empty locations for remote employers
                            onChange={(locations) => {
                              // Find the index of the newly selected primary location
                              const newPrimaryIndex = locations.findIndex(
                                (loc) => loc.isPrimaryLocation
                              );

                              // If a new primary location was selected
                              if (newPrimaryIndex !== -1) {
                                // Update all locations, ensuring only one is primary
                                const updatedLocations = locations.map(
                                  (loc, index) => ({
                                    ...loc,
                                    isPrimaryLocation:
                                      index === newPrimaryIndex,
                                  })
                                );

                                // Update the form values
                                form.change('locations', updatedLocations);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="tagline"
                            component={TextAreaField}
                            label="Tagline"
                            rows={2}
                            maxLength={140}
                            required={true}
                            hideBorder={false}
                          />
                        </div>
                      </div>
                      <h2 className="normal-title padding-bottom-sixteen bold">
                        Logo
                      </h2>

                      <div className="row">
                        <div className="col-md-6">
                          <Field name="logoUrl" component={AvatarField} />
                        </div>
                        <div className="col-md-6" />
                      </div>

                      <h2 className="normal-title bold padding-bottom-sixteen">
                        Employer Details
                      </h2>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="companySize"
                            component={TextField}
                            label="Employer Size"
                            size={36}
                            maxLength={64}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="url"
                            component={TextField}
                            label="Employer Website"
                            size={36}
                            maxLength={140}
                            parse={(value) => value}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="about"
                            component={TextAreaField}
                            label="About Us"
                            rows={6}
                            maxLength={500}
                            hideBorder={false}
                            showCounter={true}
                            counterRightAlign={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="industries"
                            label="Industries"
                            isDisabled={industries.length === 0}
                            isLoading={industriesLoading}
                            component={MultiSelectField}
                            width="100%"
                            options={industries}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="regions"
                            label="Regions"
                            isDisabled={regions.length === 0}
                            isLoading={regionsLoading}
                            component={MultiSelectField}
                            width="100%"
                            options={regions}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="employerRank"
                            label="Employer Rank"
                            component={SelectField}
                            options={[
                              {label: 'High', value: 1},
                              {label: 'Medium', value: 2},
                              {label: 'Low', value: 3},
                            ]}
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            name="segments"
                            label={'Segments'}
                            isDisabled={segments.length === 0}
                            isLoading={segmentsLoading}
                            component={MultiSelectField}
                            width="100%"
                            options={segments}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="normal-title padding-bottom-sixteen bold">
                        <div className="row padding-bottom-thirtytwo">
                          <div className="col-md-6">
                            <span className="secondary-body">
                              Account Manager
                            </span>
                            <Field
                              name="accountManagerId"
                              component={SelectField}
                              isLoading={usersLoading}
                              options={this.props.usersList.map((x) => {
                                return {
                                  value: x.userId,
                                  label: `${x.firstName} ${x.lastName}`,
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>

                      <h2 className="normal-title bold padding-bottom-sixteen">
                        Social Media
                      </h2>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="instagram"
                            component={TextField}
                            icon={
                              <i className="inlineblock nc-icon-glyph-med instagram" />
                            }
                            label="Instagram"
                            size={36}
                            maxLength={64}
                            parse={(value) => value}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="twitter"
                            component={TextField}
                            icon={
                              <i className="inlineblock nc-icon-glyph-med twitter" />
                            }
                            label="Twitter"
                            size={36}
                            maxLength={64}
                            parse={(value) => value}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="facebook"
                            component={TextField}
                            icon={
                              <i className="inlineblock nc-icon-glyph-med facebook" />
                            }
                            label="Facebook"
                            size={36}
                            maxLength={64}
                            parse={(value) => value}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="linkedin"
                            component={TextField}
                            icon={
                              <i className="inlineblock nc-icon-glyph-med linkedin" />
                            }
                            label="Linkedin"
                            size={36}
                            maxLength={64}
                            parse={(value) => value}
                          />
                        </div>
                      </div>

                      <h2 className="normal-title padding-bottom-sixteen bold">
                        Live / Eat / Play / Engage Data
                      </h2>

                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="zipcodeId"
                            label="Neighborhood"
                            isDisabled={zipcodes.length === 0}
                            isLoading={zipcodesLoading}
                            component={SelectField}
                            options={zipcodes}
                          />
                        </div>
                        <div className="col-md-6" />
                      </div>

                      <h2
                        id="empResources"
                        className="normal-title padding-bottom-sixteen bold"
                      >
                        Resources
                      </h2>

                      <FieldArray
                        name="resources"
                        component={ResourceFieldArray}
                        maxFields={20}
                        autofocus={mode === 'create'}
                        validate={validateResource}
                      />

                      <div className="row">
                        <div className="col-md-12">
                          <LoadingOverlay
                            active={this.state.showBusy}
                            spinner={true}
                            text="Uploading Files ..."
                            styles={{
                              wrapper: {overflow: 'hidden'},
                              overlay: (base) => ({
                                ...base,
                                position: 'fixed',
                              }),
                            }}
                          >
                            <FieldArray
                              name="employerPhotos"
                              component={PhotoFieldArray}
                              maxFields={10}
                              validate={validatePhoto}
                              setUploadingStatus={(x) =>
                                this.setState({showBusy: x})
                              }
                            />
                          </LoadingOverlay>
                        </div>
                      </div>

                      <br />

                      {form.getState().submitFailed &&
                        form.getState().invalid && (
                          <div
                            className="padding-bottom-sixteen"
                            style={{clear: 'right'}}
                          >
                            <Alert color="danger">
                              Employer is invalid, check fields for errors.
                            </Alert>
                          </div>
                        )}
                      <div
                        style={{clear: 'right'}}
                        className="padding-bottom-thirtytwo"
                      >
                        {mode === 'create' && (
                          <div>
                            <div className="float-left">
                              <Field name="published" component={OnOffSwitch} />
                            </div>
                            <div className="float-right" role="group">
                              <button
                                className="clickable btn btn-primary"
                                onClick={handleSubmit}
                                disabled={form.getState().submitting}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}

                        {mode === 'edit' && (
                          <div>
                            <div className="float-left">
                              <Field name="published" component={OnOffSwitch} />
                            </div>
                            <div className="float-right" role="group">
                              {role === 'ASCEND_RECRUITER' && (
                                <button
                                  type="button"
                                  className="clickable btn btn-transparent"
                                  onClick={this.handleDeleteClicked}
                                  disabled={form.getState().submitting}
                                >
                                  Archive
                                </button>
                              )}
                              <button
                                className="clickable btn btn-primary"
                                onClick={handleSubmit}
                                disabled={form.getState().submitting}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
          </AscendModalBody>
        </AscendModal>
      </div>
    );
  }
}

EmployerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  role: PropTypes.string,
  published: PropTypes.bool,
  getIndustries: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  industries: PropTypes.array,
  industriesLoading: PropTypes.bool,
  regions: PropTypes.array,
  regionsLoading: PropTypes.bool,
  getZipcodes: PropTypes.func.isRequired,
  zipcodes: PropTypes.array,
  zipcodesLoading: PropTypes.bool,
  segments: PropTypes.array,
  segmentsLoading: PropTypes.bool,
  getSegments: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  usersLoading: PropTypes.bool,
  getUsersByPermission: PropTypes.func.isRequired,
  employerRank: PropTypes.number,
  initialValues: PropTypes.object,
};

EmployerForm.defaultProps = {
  isOpen: false,
  usersList: [],
};

const actions = {
  ...industriesActions,
  ...regionsActions,
  ...zipcodesActions,
  ...segmentActions,
  ...usersActions,
};

let CreateEmployerForm = (props) => <EmployerForm {...props} mode="create" />;

const createMapStateToProps = (state) => {
  return {
    initialValues: defaults,
    role: state.profile.role,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    zipcodes: state.zipcodes.zipcodes,
    zipcodesLoading: state.zipcodes.zipcodesLoading,
    segments: state.segments.segmentList,
    segmentsLoading: state.segments.segmentsLoading,
    usersList: state.users.usersListByPermission,
    usersLoading: state.users.usersLoading,
    getUsersByPermission: PropTypes.func.isRequired,
  };
};

CreateEmployerForm = connect(
  createMapStateToProps,
  actions
)(CreateEmployerForm);

let EditEmployerForm = (props) => <EmployerForm {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  const published = state.employers.employerForEdit
    ? state.employers.employerForEdit.published
    : false;
  return {
    role: state.profile.role,
    initialValues: state.employers.employerForEdit,
    industries: state.industries.industries,
    industriesLoading: state.industries.industriesLoading,
    regions: state.regions.regions,
    regionsLoading: state.regions.regionsLoading,
    zipcodes: state.zipcodes.zipcodes,
    zipcodesLoading: state.zipcodes.zipcodesLoading,
    published,
    segments: state.segments.segmentList,
    segmentsLoading: state.segments.segmentsLoading,
    usersList: state.users.usersListByPermission,
    usersLoading: state.users.usersLoading,
    getUsersByPermission: PropTypes.func.isRequired,
  };
};

EditEmployerForm = connect(editMapStateToProps, actions)(EditEmployerForm);

export {CreateEmployerForm, EditEmployerForm};
