import React from 'react';
import PropTypes from 'prop-types';
import Routes from './routes';
import track from 'react-tracking';
import * as analytics from './utils/analytics';
import FlashMessages from 'src/containers/common/FlashMessages';

import {HistoryRouter as Router} from 'redux-first-history/rr6';

@track({}, {dispatch: (data) => analytics.track(data)})
class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    const ReactPixel = require('react-facebook-pixel');
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: true, // enable logs
    };

    // Note: Only set the FACEBOOK_PIXEL_ID in the prod environment
    // Otherwise page views will be tracked on non-prod environments
    const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
    ReactPixel.default.init(facebookPixelId, options);
  }
  render() {
    return (
      <div>
        <div aria-live="assertive" aria-atomic="true">
          <FlashMessages />
        </div>

        <Router history={this.props.history}>
          <Routes />
        </Router>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
  profile: PropTypes.object,
};

export default App;
