import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {MultiSelect} from 'react-multi-select-component';

import * as institutionsActions from 'src/actions/institutions';
import * as degreeRankTypeActions from 'src/actions/DegreeTypeActions';
import * as employmentTypeActions from 'src/actions/employmentTypes';
import * as roleTypeActions from 'src/actions/roleTypes';
import * as industryActions from 'src/actions/industries';
import * as locationActions from 'src/actions/regions';
import * as skillActions from 'src/actions/skillTags';
import * as recruiterActions from 'src/actions/ascendRecruiters';
import * as meetingStatusActions from 'src/actions/meetingStatuses';
import * as profileStatusActions from 'src/actions/profileStatuses';

import {getSortedLookup} from 'src/utils/fieldHelper';
import {BusyButton} from 'src/components/BusyButton';

import * as seekersActions from 'src/actions/seekers';
import {CandidateAdminTabs} from 'src/utils/CandidateAdminTabs';

class AdvancedFilterPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unappliedFilters: false,
    };
  }

  componentDidMount() {
    if (this.props.degreeTypeRank.length === 0) {
      this.props.getDegreeTypesRank();
    }
    if (this.props.employmentTypes.length === 0) {
      this.props.getEmploymentTypes();
    }
    if (this.props.roleTypes.length === 0) {
      this.props.getRoleTypes();
    }
    if (this.props.ascendRecruiters.length === 0) {
      this.props.getAscendRecruiters();
    }
    if (this.props.skillTags.length === 0) {
      this.props.getAllSkillTags();
    }
    if (this.props.industryPreferences.length === 0) {
      this.props.getIndustries();
    }
    if (this.props.locationPreferences.length === 0) {
      this.props.getRegions();
    }
    if (this.props.meetingStatuses.length === 0) {
      this.props.getMeetingStatuses();
    }
    if (this.props.profileStatuses.length === 0) {
      this.props.getProfileStatuses();
    }
    if (this.props.segments.length === 0) {
      this.props.getSegments();
    }
  }

  tabFilters = () => {
    const currentTab =
      this.props.selectedTab === CandidateAdminTabs.ACTIVE
        ? 'active'
        : 'inactive';
    return this.props[currentTab].multiSelect;
  };

  handleFilterChange = (changedFilter) => {
    this.props.saveCandidateAdvancedIndividualFilters(
      this.props.selectedTab,
      changedFilter
    );

    this.setState({
      unappliedFilters: true,
    });
  };

  handleSubmit = () => {
    this.props.onApplyButtonClicked();
    this.setState({
      unappliedFilters: false,
    });
  };

  sortExcludeAny = (lookupList) => {
    return getSortedLookup(lookupList, 'name', 'Any', false);
  };

  sortIncludeNoPreference = (lookupList) => {
    return getSortedLookup(lookupList, 'name', 'No Preference', true);
  };

  addNoAssignedRecruiter = (lookupList) => {
    lookupList.splice(0, 0, {label: 'No Assigned Recruiter', value: -1});
    return lookupList;
  };

  valueRenderer = (selected, _options, label) => {
    let truncatedSelectionString = selected.map(({label}) => label).join(',');
    if (truncatedSelectionString.length > 50) {
      truncatedSelectionString =
        truncatedSelectionString.substring(0, 50) + '...';
    }

    return selected.length ? truncatedSelectionString : label;
  };

  render() {
    const {
      employmentTypes,
      degreeTypeRank,
      roleTypes,
      locationPreferences,
      ascendRecruiters,
      skillTags,
      profileStatuses,
      industryPreferences,
      isMaRecruiter,
      segments,
    } = this.props;

    const profileStatusesToShow = this.sortExcludeAny(
      profileStatuses.filter((status) => {
        if (this.props.selectedTab === CandidateAdminTabs.ACTIVE) {
          return status.showOnActiveTab === true;
        }
        return status.showOnActiveTab === false;
      })
    );

    const filters = this.tabFilters();

    const dropDownComponents = [
      {
        divClassName: 'col-md-2 offset-md-1 mb-1',
        showForMARecruiters: false,
        customProps: {
          options: this.sortExcludeAny(segments),
          className: 'dropdownText',
          value: filters.segmentIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'segmentIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Segment(s)'),
        },
      },
      {
        divClassName: 'col-md-2',
        showForMARecruiters: false,
        customProps: {
          options: this.sortExcludeAny(employmentTypes),
          className: 'dropdownText',
          value: filters.employmentTypeIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'employmentTypeIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Employment Type(s)'),
        },
      },
      {
        divClassName: 'col-md-2',
        showForMARecruiters: false,
        customProps: {
          options: this.sortIncludeNoPreference(roleTypes),
          className: 'dropdownText',
          value: filters.roleTypeIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'roleTypeIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Role Type(s)'),
        },
      },
      {
        divClassName: `col-md-2 ${isMaRecruiter ? 'offset-md-3' : ''}`,
        showForMARecruiters: true,
        customProps: {
          options: profileStatusesToShow,
          className: 'dropdownText',
          value: filters.profileStatusIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'profileStatusIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Profile Status(es)'),
        },
      },
      {
        divClassName: 'col-md-2 mb-1',
        showForMARecruiters: false,
        customProps: {
          options: this.sortIncludeNoPreference(locationPreferences),
          className: 'dropdownText',
          value: filters.regionIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'regionIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Location Preference(s)'),
        },
      },
      {
        divClassName: 'col-md-2 offset-md-1 mb-1',
        showForMARecruiters: false,
        customProps: {
          options: this.sortIncludeNoPreference(industryPreferences),
          className: 'dropdownText',
          value: filters.industryIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'industryIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Industry Preference(s)'),
        },
      },
      {
        divClassName: 'col-md-2',
        showForMARecruiters: false,
        customProps: {
          options: this.sortExcludeAny(degreeTypeRank),
          className: 'dropdownText',
          value: filters.degreeTypeRankIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'degreeTypeRankIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Degree Rank Type(s)'),
        },
      },
      {
        divClassName: 'col-md-2',
        showForMARecruiters: true,
        customProps: {
          options: this.addNoAssignedRecruiter(
            this.sortExcludeAny(ascendRecruiters)
          ),
          className: 'dropdownText',
          value: filters.recruiterIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'recruiterIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Recruiter(s)'),
        },
      },
      {
        divClassName: 'col-md-2',
        showForMARecruiters: false,
        customProps: {
          options: this.sortExcludeAny(skillTags),
          className: 'dropdownText',
          value: filters.skillTagIds,
          onChange: (x) =>
            this.handleFilterChange({name: 'skillTagIds', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Skill(s)'),
        },
      },
      {
        divClassName: `col-md-2`,
        showForMARecruiters: true,
        customProps: {
          options: [
            {value: 1, label: 'Flagged'},
            {value: 0, label: 'Not Flagged'},
          ],
          className: 'dropdownText',
          value: filters.seekerSupportFlag,
          onChange: (x) =>
            this.handleFilterChange({name: 'seekerSupportFlag', value: x}),
          valueRenderer: (selected, options) =>
            this.valueRenderer(selected, options, 'Flag Option(s)'),
        },
      },
    ];

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            {dropDownComponents
              .filter(
                (ddc) =>
                  !isMaRecruiter || ddc.showForMARecruiters === isMaRecruiter
              )
              .map((dropDownComponent, index) => {
                return (
                  <div key={index} className={dropDownComponent.divClassName}>
                    <MultiSelect {...dropDownComponent.customProps} />
                  </div>
                );
              })}
          </div>
          <div className="row">
            <div
              className="col-sm-12 mb-2 mt-2"
              style={{display: 'flex', justifyContent: 'center'}}
            >
              <BusyButton
                disabled={!this.state.unappliedFilters}
                onClick={() => {
                  this.handleSubmit();
                }}
                busyText={'Searching'}
                buttonText=" Apply Filters"
                className="clickable btn btn-primary col-3"
                style={{width: '200px', height: '40px'}}
                float={'float-right'}
                alignText={'text-center'}
              >
                Apply Filters
              </BusyButton>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

AdvancedFilterPanel.propTypes = {
  showSegment: PropTypes.string,
  showDegreeType: PropTypes.string,
  showDegreeRankType: PropTypes.string,
  showEmploymentType: PropTypes.string,
  showIndustry: PropTypes.string,
  showLocation: PropTypes.string,
  showRecruiter: PropTypes.string,
  showSkill: PropTypes.string,
  showMeetingStatus: PropTypes.string,
  ShowProfileStatus: PropTypes.string,
  showRoleType: PropTypes.string,
  onApplyButtonClicked: PropTypes.func,
  saveCandidateAdvancedIndividualFilters: PropTypes.func,
  isMaRecruiter: PropTypes.bool,

  degreeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getDegreeTypes: PropTypes.func,

  meetingStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getMeetingStatuses: PropTypes.func,

  profileStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getProfileStatuses: PropTypes.func,

  degreeTypeRank: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getDegreeTypesRank: PropTypes.func,

  employmentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getEmploymentTypes: PropTypes.func,

  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getSegments: PropTypes.func,

  roleTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getRoleTypes: PropTypes.func,

  industryPreferences: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getIndustries: PropTypes.func,

  locationPreferences: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getRegions: PropTypes.func,

  ascendRecruiters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getAscendRecruiters: PropTypes.func,

  skillTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  getAllSkillTags: PropTypes.func,

  onClose: PropTypes.func,
  selectedTab: PropTypes.string,
  profile: PropTypes.object,
  active: PropTypes.object,
  inactive: PropTypes.object,
};

const mapStateToProps = (state) => {
  const isMaOnlyRecruiter = () => {
    if (state.profile.segmentIds.length === 0) {
      return false;
    }
    const maSegmentIds = state.segments.segmentList
      .filter((s) => s.isApprenticeProgram)
      .map((s) => s.id);
    return state.profile.segmentIds.every((s) => maSegmentIds.includes(s));
  };

  return {
    seekers: state.seekers.seekers,
    degreeTypes: state.institutions.degreeTypes,
    degreeTypeRank: state.degreeTypeRank.degreeRank,
    employmentTypes: state.employmentTypes.employmentTypes,
    roleTypes: state.roleTypes.roleTypes,
    ascendRecruiters: state.ascendRecruiters.ascendRecruiters,
    skillTags: state.skillTags.tags,
    industryPreferences: state.industries.industries,
    locationPreferences: state.regions.regions,
    meetingStatuses: state.meetingStatuses.meetingStatuses,
    profileStatuses: state.profileStatuses.profileStatuses,
    profile: state.profile,
    isMaRecruiter: isMaOnlyRecruiter(),
    segments: state.segments.segmentList,
    ...state.candidateFilters,
  };
};

const mapDispatchToProps = {
  ...institutionsActions,
  ...degreeRankTypeActions,
  ...employmentTypeActions,
  ...roleTypeActions,
  ...recruiterActions,
  ...skillActions,
  ...industryActions,
  ...locationActions,
  ...seekersActions,
  ...meetingStatusActions,
  ...profileStatusActions,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterPanel);
