import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import arrowrightSvg from '../../assets/images/ArrowRight.svg';

class OneCategory extends React.Component {
  render() {
    const {onClick, selected, label} = this.props;
    return (
      <div
        className="normal-subheader bold padding-top-eight padding-bottom-eight rowbottom rowright"
        onClick={onClick}
        tabIndex={0}
        aria-label={label}
        role="button"
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onClick();
          }
        }}
      >
        <table width="100%">
          <tbody>
            <tr>
              <td width="12%">
                <img
                  className={classNames({
                    'gly-rotate-180': selected,
                    'gly-rotate-90': !selected,
                  })}
                  alt=""
                  src={arrowrightSvg}
                />
              </td>
              <td width="88%">{label}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

OneCategory.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default OneCategory;
