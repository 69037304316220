export const filterWorkStyleTag = (tag) => {
  const newTag = tag.replace(/^Prefers( a)? /, '');
  return newTag.charAt(0).toUpperCase() + newTag.slice(1);
};

const overlap = (a, b) => {
  var count = 0;
  for (var i = 0; i < a.length; i++) {
    for (var j = 0; j < b.length; j++) {
      if (a[i] === b[j].name) count += 1;
    }
  }
  return count > 1;
};

const creativityTags = [
  'Prefers Work with Open-ended Problems',
  'Prefers Work with Clearly Defined Tasks',
];

const teamworkTags = ['Prefers Independent Work', 'Prefers Teamwork'];

const leadershipTags = [
  'Prefers Task-focused Team Role',
  'Prefers a Leadership Role on a Team',
];

const travelTags = [
  'Prefers Majority Travel',
  'Prefers Some Travel',
  'Prefers No Travel',
];

const scheduleTags = [
  'Prefers a Consistent Schedule',
  'Prefers a Flexible Schedule',
];

const groupTags = [
  'Prefers Significant Engagement with People',
  'Prefers Minor Engagement with People',
];

const goalsTags = ['Prefers Minimal Guidance', 'Prefers Structured Guidance'];

export const illegalWorkStyleTags = (tags) => {
  if (!tags) {
    return false;
  }
  if (
    overlap(creativityTags, tags) ||
    overlap(teamworkTags, tags) ||
    overlap(leadershipTags, tags) ||
    overlap(travelTags, tags) ||
    overlap(scheduleTags, tags) ||
    overlap(groupTags, tags) ||
    overlap(goalsTags, tags)
  ) {
    return true;
  }
  return false;
};
