import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from '../../utils/commonHooks';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import track from 'react-tracking';
import MaOnboardingPageOne from '../../components/modernApprenticeship/onboarding/MaOnboardingPageOne';
import LoadingOverlay from 'react-loading-overlay';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {Line} from 'rc-progress';
import * as questionsActions from 'src/actions/questions';
import * as seekersActions from '../../actions/SeekerActions';
import * as profileActions from 'src/actions/profile';
import PropTypes from 'prop-types';

@track({eventName: 'MaOnboardingPage'}, {dispatchOnMount: true})
class MaOnboardingPage extends React.Component {
  state = {
    page: 1,
    totalPages: 2,
    prevPage: [],
    isMobile: false,
    seekerInfo: {},
  };

  getSeekerId = () => {
    let seekerId = null;
    if (this.props.loggedInSeekerId) {
      seekerId = this.props.loggedInSeekerId;
    }
    return seekerId;
  };

  componentDidMount() {
    this.props.getMaOnboardingQuestions();
    const handler = (e) => this.setState({matches: e.matches});
    window.matchMedia('(max-width: 430px)').addListener(handler); // This is the only solution that works in safari at the moment
    this.setState(() => ({
      isMobile: window.matchMedia('(max-width: 430px').matches,
    }));
  }

  storePreviousPage = (page) => {
    if (this.state.prevPage && this.state.prevPage.includes(page) === false) {
      const pages = this.state.prevPage.concat(page);
      this.setState({prevPage: pages});
      return pages;
    }
    return this.state.prevPage;
  };

  moveToPage = (props) => {
    window.scrollTo(0, 0);
    this.setState({page: props.page});
  };

  previousPage = (e, values) => {
    const updatedSeekerInfo = {...this.state.seekerInfo, ...values};
    let pageHistory = this.state.prevPage.filter((x) => x !== this.state.page);

    this.setState({prevPage: pageHistory, seekerInfo: updatedSeekerInfo});
    let page = pageHistory.slice(-1)[0];

    if (this.state.page > 1) {
      this.moveToPage({
        page: page,
        pages: pageHistory,
        totalPages: this.state.totalPages,
      });
    }
    e.preventDefault();
  };

  nextPage = (values) => {
    const updatedSeekerInfo = {...this.state.seekerInfo, ...values};
    let pageHistory = this.storePreviousPage(this.state.page);

    let nextPage =
      this.state.page === this.state.totalPages
        ? this.state.page
        : this.state.page + 1;

    this.setState({seekerInfo: updatedSeekerInfo});

    this.moveToPage({
      page: nextPage,
      totalPages: this.state.totalPages,
      pages: pageHistory,
    });
  };

  handleSubmit = async (values) => {
    const {maOnboardingQuestions, onboardingComplete, updateSeekerAction} =
      this.props;

    const formValues = {...this.state.seekerInfo, ...values} || {};
    const seekerId = this.getSeekerId();

    this.setState({seekerInfo: formValues});

    const seekerAnswers = maOnboardingQuestions
      .filter((x) => !x.isRecommendationTrait)
      .map((q) => {
        const answerText = formValues[`page1Question${q.id}`];

        return {
          seekerQuestionId: q.id,
          answerText: answerText,
        };
      });

    const now = new Date();
    const data = {
      id: seekerId,
      onboardedOn: now,
      reonboardedOn: now,
      seekerAnswers,
      published: true,
      publishedAt: new Date(),
    };
    await updateSeekerAction(data, true);
    onboardingComplete();

    this.props.history.push('/candidatehome');
  };

  render() {
    const {page, totalPages} = this.state;
    const {loading, maOnboardingQuestions} = this.props;
    return (
      <DocumentTitle title="Modern Apprenticeship Onboarding">
        <div className="fullindy">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <LoadingOverlay
                  active={loading}
                  spinner={true}
                  text="Loading information ..."
                  fadeSpeed={200}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      height: '100vh',
                      position: 'fixed',
                    }),
                  }}
                >
                  <span>&nbsp;</span>
                </LoadingOverlay>
              </div>
            </div>
            <div className="row">
              <div className="offset-md-2 col-md-8 normal-body-white">
                <center>
                  {Math.round((page / totalPages) * 100)}% Complete &nbsp;
                  <Line
                    percent={(page / totalPages) * 100}
                    strokeColor="#FF843E"
                    trailColor="#FFFFFF"
                    width="85%"
                  />
                </center>
              </div>
              <div className="col-md-2" />
              <ReactTooltip
                id="tooltip"
                className="solid-tooltip-dark"
                closeOnEsc={true}
              />
            </div>
            <div className="row">
              {page === 1 && (
                <MaOnboardingPageOne
                  isMobile={this.state.isMobile}
                  onSubmit={this.handleSubmit}
                  questions={maOnboardingQuestions.filter(
                    (q) => !q.isRecommendationTrait
                  )}
                  initialValues={this.state.seekerInfo}
                />
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.questions.maOnboardingQuestionsLoading,
    maOnboardingQuestions: state.questions.maOnboardingQuestions,
    loggedInSeekerId: state.profile.seekerId,
  };
};
const mapDispatchToProps = {
  ...questionsActions,
  ...seekersActions,
  ...profileActions,
};

MaOnboardingPage.defaultProps = {
  loading: false,
  maOnboardingQuestions: [],
};

MaOnboardingPage.propTypes = {
  getMaOnboardingQuestions: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  maOnboardingQuestions: PropTypes.array,
  updateSeekerAction: PropTypes.func.isRequired,
  loggedInSeekerId: PropTypes.string,
  history: PropTypes.object.isRequired,
  onboardingComplete: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaOnboardingPage)
);
