import React from 'react';
import PropTypes from 'prop-types';

import {Form, Field} from 'react-final-form';

export default function CreateConnectionIsNetworkRole(props) {
  const {onChange, onSubmit, isNetworkConnection} = props;

  return (
    <Form onSubmit={onSubmit}>
      {() => {
        return (
          <form>
            <label className="normal-body">
              Did you find this role on the Network?
              <div className="row ms-2 mt-2">
                <label className="radio normal-body">
                  Yes
                  <Field
                    name="isNetworkRole"
                    component="input"
                    type="radio"
                    value={true}
                    checked={isNetworkConnection === true}
                    onChange={() => onChange(true)}
                    autoFocus
                  />
                  <span className="radio-checkmark-orange" />
                </label>

                <label className="radio normal-body">
                  No
                  <Field
                    name="isNetworkRole"
                    component="input"
                    type="radio"
                    value={false}
                    checked={isNetworkConnection === false}
                    onChange={() => onChange(false)}
                  />
                  <span className="radio-checkmark-orange" />
                </label>
              </div>
            </label>
          </form>
        );
      }}
    </Form>
  );
}

CreateConnectionIsNetworkRole.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  isNetworkConnection: PropTypes.bool,
};
