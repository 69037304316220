import {checkCacheValid} from 'redux-cache';

import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export const getIndustries = () => {
  return (dispatch, getState) => {
    const isCacheValid = checkCacheValid(getState, 'industries');
    if (isCacheValid) {
      return null;
    }

    dispatch({
      types: [
        types.INDUSTRIES__GET_REQUEST,
        types.INDUSTRIES__GET_SUCCESS,
        types.INDUSTRIES__GET_FAILURE,
      ],
      uri: `${apiUrl}/industries`,
      method: 'GET',
    });
  };
};
