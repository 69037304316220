import * as types from '../actions/ActionTypes';

const initialState = {
  workStyles: [],
  workStylesLoading: false,
};

export default function workStyles(state = initialState, action) {
  switch (action.type) {
    case types.WORK_STYLES__GET_REQUEST:
      return {...state, workStyles: [], workStylesLoading: true};
    case types.WORK_STYLES__GET_SUCCESS:
      return {
        ...state,
        workStyles: action.response.body.workStyles,
        workStylesLoading: false,
      };
    default:
      return state;
  }
}
