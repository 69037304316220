import React from 'react';
import styles from './FilterPanel.module.scss';
import PropTypes from 'prop-types';
import AdvancedFilterPanel from 'src/components/controls/filters/AdvancedFilterPanel';
import {connect} from 'react-redux';
import PushButton from 'src/components/controls/buttons/PushButton';
import InputSearch from 'src/components/controls/inputs/InputSearch';
import * as SVG from 'src/assets/svg/svgComponents';
import * as seekersActions from 'src/actions/seekers';
import {CANDIDATE_SEARCH_FIELDS} from 'src/utils/fieldHelper';
import {
  CandidateAdminTabs,
  CandidateAdminTabNames,
} from 'src/utils/CandidateAdminTabs';
class FilterPanel extends React.Component {
  state = {
    dropdownOpen: false,
    searchFilters: [],
    filtersVisible: false,
  };

  filters = () => {
    return this.props.selectedTab !== CandidateAdminTabs.TASKS
      ? this.props[CandidateAdminTabNames[this.props.selectedTab]]
      : this.props.active;
  };

  handleEnterKeyPress = (searchBoxText) => {
    var filters = [];
    filters.push('searchTerm=' + searchBoxText);
    filters.push('searchFields=' + CANDIDATE_SEARCH_FIELDS);
    this.props.filterButtonAction({
      searchTerm: searchBoxText,
      searchFilters: filters,
      searchUpdated: true,
    });
  };

  handleClearAll = () => {
    this.setState({searchTerm: ''});
    this.props.handleClearAll();
  };

  setDateHandler = (selectedDates) => {
    var index = this.state.selectedButtons.findIndex(
      (item) => item.id === 'SelectedDates'
    );
    if (index !== -1) {
      this.setState({
        selectedButtons: [this.state.selectedButtons.splice(index, 1)],
      });
    }
    if (selectedDates) {
      this.setState({
        selectedButtons: [
          ...this.state.selectedButtons,
          {
            key: 'SelectedDates',
            value: selectedDates,
          },
        ],
      });
    } else this.state.selectedButtons([...this.state.selectedButtons, []]);
  };

  buildAdvancedFilters = () => {
    this.props.filterButtonAction({});
    this.setState({dropdownOpen: !this.state.dropdownOpen});
  };

  buildPushButtonFilters = (e) => {
    var filterSelectedButtons = [...this.filters().toggleButtons];
    var filterSelectedButtonFilters = [...this.filters().toggleButtonFilters];

    var index = filterSelectedButtonFilters.findIndex(
      (item) => item === e.buttonAttr
    );
    var buttonIndex = filterSelectedButtons.findIndex((item) => item === e.id);

    //remove existing references for this button
    if (index !== -1) {
      filterSelectedButtonFilters.splice(index, 1);
    }
    if (buttonIndex !== -1) {
      filterSelectedButtons.splice(buttonIndex, 1);
    }
    if (e.isToggled) {
      filterSelectedButtonFilters.push(e.buttonAttr);
      filterSelectedButtons.push(e.id);
    }

    this.props.filterButtonAction({
      toggleButtons: filterSelectedButtons,
      toggleButtonFilters: filterSelectedButtonFilters,
      toggleButtonFiltersUpdated: true,
    });
  };

  toggleDropdown = (e) => {
    setTimeout(() => {
      this.setState({dropdownOpen: !this.state.dropdownOpen});
    }, 30);
  };

  buildFilterButtons = (data) => {
    return data.map((x, index) => {
      var isSelected = false;

      var toggleIndex = this.filters().toggleButtons.findIndex(
        (item) => item === x.id
      );

      if (toggleIndex !== -1) isSelected = true;
      return (
        <PushButton
          key={x.id}
          id={x.id}
          buttonText={x.buttonText}
          buttonSvg={x.buttonSvg || null}
          action={this.buildPushButtonFilters}
          buttonAttr={x.buttonAttr}
          childProps={{}}
          isToggled={isSelected}
          enabled={x.enabled && !this.props.itemsLoading}
          ariaPressed={isSelected}
        />
      );
    });
  };

  buildSearchFilterButtons = (inputVal) => {
    return (
      <InputSearch
        style={{
          backgroundColor: 'var(--neutral-gray-5)',
        }}
        containerStyle={this.props.showFilter ? {} : {maxWidth: '25%'}}
        onEnterKey={this.handleEnterKeyPress}
        placeHolder={this.props.searchHelperText}
        value={inputVal}
        disabled={this.props.itemsLoading}
      />
    );
  };

  render() {
    return (
      <div style={{background: '#fff'}}>
        <div className={styles.content_container}>
          <div className={`${styles.aside_1} border-end`}>
            {this.buildSearchFilterButtons(this.filters().searchTerm)}
          </div>
          {this.props.showFilter && (
            <div className={styles.main} id="btnGroup">
              <div className={styles.quickFilters}>
                {this.buildFilterButtons(this.props.filterButtons)}
                <PushButton
                  className="nowrap"
                  buttonText={`${
                    this.state.filtersVisible ? 'Hide' : 'Show'
                  } Advanced Filters`}
                  buttonSvg={
                    <SVG.FilterSvg
                      alt=""
                      style={{marginLeft: '5px'}}
                      className="svg_color_fill_orange"
                    ></SVG.FilterSvg>
                  }
                  isToggled={this.props.selectedMultiSelectFilters.length !== 0}
                  ariaExpanded={this.state.filtersVisible}
                  action={() => {
                    this.setState({
                      filtersVisible: !this.state.filtersVisible,
                    });
                  }}
                  enabled={!this.props.itemsLoading}
                />
              </div>
              <div style={{marginTop: '5px'}}>
                {this.props.selectedMultiSelectFilters.length !== 0 && (
                  <span className={styles.advancedFilterNotice}>
                    <SVG.FilterSvg
                      alt=""
                      className="svg_color_fill_orange"
                      style={{marginTop: '-2px'}}
                    ></SVG.FilterSvg>{' '}
                    {this.props.selectedMultiSelectFilters.length} advanced
                    filters applied
                  </span>
                )}
                <button
                  className={`${styles.clearAllFiltersButton}`}
                  onClick={this.handleClearAll}
                  disabled={this.props.itemsLoading}
                >
                  <SVG.ResetSvg height="15px" width="15px" />
                  <span>Clear All Filters</span>
                </button>
              </div>
            </div>
          )}
        </div>
        {this.state.filtersVisible && (
          <AdvancedFilterPanel
            showDegreeType={'hide'}
            onApplyButtonClicked={this.buildAdvancedFilters}
            onClose={() => {
              this.setState({
                filtersVisible: !this.state.filtersVisible,
              });
            }}
          />
        )}
      </div>
    );
  }
}
FilterPanel.propTypes = {
  timelineFilterButtonAction: PropTypes.func,
  filterButtons: PropTypes.array,
  filterButtonAction: PropTypes.func.isRequired,
  searchHelperText: PropTypes.string,
  statusNumbersTitle: PropTypes.string,
  dateStartFilter: PropTypes.string,
  dateEndFilter: PropTypes.string,
  showFilter: PropTypes.bool,
  onAdvancedFilterChange: PropTypes.func,
  handleClearAll: PropTypes.func,
  currentTabId: PropTypes.string,
  selectedTab: PropTypes.string,
  selectedMultiSelectFilters: PropTypes.array,
  active: PropTypes.object,
  inactive: PropTypes.object,
  itemsLoading: PropTypes.bool,
};

FilterPanel.defaultProps = {
  searchHelperText: 'Search by name',
  showFilter: true,
  itemsLoading: false,
};
const mapStateToProps = (state) => {
  return {
    seekers: state.seekers.seekers,
    ...state.candidateFilters,
  };
};

const mapDispatchToProps = {
  ...seekersActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
