import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {withRouter} from '../utils/commonHooks';

class AdminSubMenu extends React.Component {
  render() {
    const defaultClass = 'candidate-profile-nav-link';
    const activeClass = 'candidate-profile-nav-link-selected';
    const pathname = window.location.pathname;

    return (
      <>
        <div className="row">
          <div className="col-12">&nbsp;</div>
        </div>
        <div role="navigation" aria-label="profile links">
          <NavLink
            to={`/candidate/${this.props.seekerId}`}
            className={
              (pathname === `/candidate/${this.props.seekerId}`
                ? activeClass
                : defaultClass) + ` navitem inlineblock `
            }
          >
            Profile
          </NavLink>
          <NavLink
            to={`/insights/${this.props.seekerId}`}
            className={
              (pathname === `/insights/${this.props.seekerId}`
                ? activeClass
                : defaultClass) + ` navitem inlineblock padding-left-thirtytwo`
            }
          >
            Candidate Insights
          </NavLink>
        </div>
        <div className="row">
          <div className="col-12" style={{paddingLeft: '0', paddingRight: '0'}}>
            <hr />
          </div>
        </div>
      </>
    );
  }
}

AdminSubMenu.propTypes = {
  location: PropTypes.object.isRequired,
  seekerId: PropTypes.any,
};

export default withRouter(AdminSubMenu);
