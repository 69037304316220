import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {BusyButton} from 'src/components/BusyButton';

export default function Wizard({
  onSubmit,
  initialValues,
  children,
  showPrevious,
  handleNext,
}) {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialValues || {});

  const next = (values) => {
    setPage(Math.min(page + 1, children.length - 1));
    setValues(values);
  };

  const previous = () => setPage(Math.max(page - 1, 0));

  const validate = (values) => {
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = (values) => {
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      handleNext();
      next(values);
    }
  };

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;
  return (
    <Form initialValues={values} validate={validate} onSubmit={handleSubmit}>
      {({handleSubmit, submitting, values, form}) => (
        <form onSubmit={handleSubmit}>
          {activePage}
          <div className="normal-subheader padding-bottom-sixteen text-align-center">
            <div className="buttons">
              {page > 0 && showPrevious && (
                <button
                  type="button"
                  onClick={previous}
                  className={`btn btn-secondary`}
                >
                  Previous Page
                </button>
              )}
              {!isLastPage && (
                <button type="submit" className={`btn btn-primary`}>
                  Next Page
                </button>
              )}
              {isLastPage && (
                <BusyButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={submitting || form.getState().valid === false}
                  className={`btn btn-primary`}
                  style={{width: 'fit-content'}}
                  buttonText="Submit"
                  busyText="Submitting"
                />
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
}

Wizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
  children: PropTypes.any,
  showPrevious: PropTypes.bool,
  handleNext: PropTypes.func,
};

Wizard.defaultProps = {
  showPrevious: false,
};

Wizard.Page = ({children}) => children;
