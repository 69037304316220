import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Form} from 'react-final-form';

import {
  AscendRadioField,
  radioYesNoOptions,
} from '../../../formFields/ascendRadioField/AscendRadioField';

class WorkStatus extends React.Component {
  validate = (values) => {
    const errors = {};
    const requiredFields = ['sponsorStatus', 'workStatusAllowed'];
    _.forEach(requiredFields, (field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };
  render() {
    const {next, previous, headerImage} = this.props;

    return (
      <>
        <PageHeader headerImage={headerImage} />
        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            sponsorStatus: this.props.intakeCache?.sponsorStatus,
            workStatusAllowed: this.props.intakeCache?.workStatusAllowed,
          }}
        >
          {({handleSubmit, form}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <div
                    className="mb-0"
                    style={{maxWidth: '600px', textAlign: 'left'}}
                  >
                    <AscendRadioField
                      name="workStatusAllowed"
                      options={radioYesNoOptions}
                      labelCssClass={'mt-0 onboarding-form-label'}
                      label="Are you legally authorized to be employed in the United
                    States?"
                    />

                    <AscendRadioField
                      name="sponsorStatus"
                      options={radioYesNoOptions}
                      labelCssClass={'mt-0 onboarding-form-label'}
                      label="Will you now or in the future require sponsorship for
                    employment visa status (e.g. H-1B status)?"
                    />
                  </div>
                </div>
                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
WorkStatus.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any,
  headerImage: PropTypes.any,
};

export default connect()(WorkStatus);
