import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../components/vendor/DocumentTitle';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from '../utils/commonHooks';
import _ from 'lodash';

import * as seekerActionsAsync from '../actions/SeekerActions';

import * as jobsActions from '../actions/jobs';
import * as employersActions from '../actions/employers';
import * as jobAndTeamSearchActions from '../actions/jobAndTeamSearch';
import * as seekersActions from 'src/actions/seekers';

import SimpleJobCard from '../components/SimpleJobCard';
import JobProfile from '../components/JobProfile';
import * as Layout from '../layout';
import {
  includesApprenticeProgram,
  splitArrayIntoRows,
} from '../utils/miscHelper';
import {jobIsApplied, jobIsWatched} from '../utils/jobHelper';
import {mergeMatches} from '../utils/seekerHelper';
import NoMatches from '../components/NoMatches';
import {track} from 'src/utils/analytics';
import DidYouApply from 'src/components/DidYouApply';
import MaCandidateStatus from '../components/modernApprenticeship/candidateProfile/MaCandidateStatus';

import CandidateNotifyMissingRole from '../components/Candidate/CandidateNotifyMissingRole';
import LoadingOverlay from 'react-loading-overlay';
import RecommendedEndCard from 'src/components/RecommendedEndCard';
import MyProgress from 'src/components/MyProgress';
import styles from './CandidateHomePage.module.scss';
import JobCardCarousel from '../components/JobCardCarousel';
import SkillsModal from 'src/components/SkillsModal';
import BestMatches from 'src/components/BestMatches';
import HelpFromAnExpert from 'src/components/HelpFromAnExpert';
import {HeartDefaultSvg, MatchBestfitSvg} from 'src/assets/svg/svgComponents';
import CandidateHomeLoader from '../utils/contentLoaders/candidateHomeLoader';
import CandidateHomeLoaderMobile from '../utils/contentLoaders/candidateHomeLoaderMobile';

class CandidateHomePage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      currentEmployerPage: 0,
      profileOpen: false,
      jobId: '',
      nmcIndex: Math.floor(Math.random() * 3),
      showBanner: false,
      isMaSegment: false,
      isMobile: false,
      seekerId: null,
    };
  }

  searchForId = (jobId) => {
    this.props.getJobToCache(jobId);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const handler = (e) => this.setState({matches: e.matches});
    window.matchMedia('(max-width: 990px)').addListener(handler); // This is the only solution that works in safari at the moment

    this.setState(() => ({
      isMobile: window.matchMedia('(max-width: 990px').matches,
    }));

    const {
      loggedInSeekerId,
      match,
      getEmployersAdvanced,
      getSeekerJobMatches,
      getSeekerJobStats,
    } = this.props;

    let seekerId = null;
    if (loggedInSeekerId) {
      seekerId = loggedInSeekerId;
    } else if (match.params.id) {
      seekerId = match.params.id;
    } else {
      return;
    }
    this.setState({seekerId: seekerId});

    if (!this.props.employersLoading && !this.props.employersLoaded) {
      getEmployersAdvanced(
        this.state.currentEmployerPage,
        3,
        '-roles',
        {},
        false
      );
    }

    const jobSortBy = this.props.profile?.isApprenticeProgram
      ? '-matchesMyInterests'
      : '-random';

    if (
      this.props.seeker?.id === null &&
      this.props.loggedInSeekerId &&
      !this.props.jobsLoading &&
      !this.props.jobsLoaded
    ) {
      getSeekerJobMatches(seekerId, 0, 8, jobSortBy, {excludeOptOut: true});
    }
    getSeekerJobStats(seekerId);

    if (seekerId && !this.props.seekerLoading) {
      this.props.getSeekerAction(seekerId);
    }

    if (!this.props.candidateHomePageTracked) {
      this.props.mixPanelTrackCandidateHomePage();
      track({
        eventName: 'Candidate Home Page',
      });
    }
  }
  isLoading = () => {
    return (
      this.props.employersLoading ||
      this.props.seekerLoading ||
      this.props.jobsLoading ||
      this.props.optOutLoading ||
      this.props.watchRequestLoading
    );
  };
  handleJobClicked = (id) => {
    this.searchForId(id);
    this.setState({
      profileOpen: true,
      jobId: id,
    });
  };

  handleTeamClicked = (id) => {
    this.props.history.push('/team/' + id);
  };

  handleEmployerClicked = (id) => {
    this.props.history.push('/employer/' + id);
  };

  handleSurveyClick = () => {
    this.props.history.push('/profile');
  };

  handleModalClose = () => {
    this.setState({profileOpen: false});
  };

  handleWatchChanged = (job, watched) => {
    if (watched) {
      this.props.createJobWatch(job);
    } else {
      this.props.deleteJobWatch(job.id);
    }
  };

  handleAppliedChanged = (jobId, applied) => {
    if (applied) {
      this.props.createJobApplied(jobId);
    } else {
      this.props.deleteJobApplied(jobId);
    }
  };

  getEndCard = (jobCount, seeker) => {
    if (jobCount && jobCount <= 9 && !includesApprenticeProgram(seeker)) {
      return <RecommendedEndCard seekerId={this.props.seeker.id} />;
    }
  };

  simpleMatchCards = (seeker) => {
    const role = this.props.role;
    let matches = mergeMatches(seeker);

    // Fix column differences
    const matchJobs = matches.map((match) => {
      return {
        ...match,
        id: match.jobId,
        name: match.jobName,
        watched: jobIsWatched(match.jobId, this.props.seeker),
        published: true,
      };
    });
    return (
      <JobCardCarousel
        jobs={matchJobs}
        isApprenticeProgram={includesApprenticeProgram(this.props.seeker)}
        onJobClick={this.handleJobClicked}
        isMaSegment={this.state.isMaSegment}
        isSeeker={role === 'JOB_SEEKER'}
        title={'Role Recommendations'}
        titleLink={`/find_roles`}
        titleLinkClassName={styles.svg_role_recommendations_link}
        onEmployerClick={this.handleEmployerClicked}
        onTeamClick={this.handleTeamClicked}
        viewerType={role === 'JOB_SEEKER' ? 'CANDIDATE' : 'RECRUITER'}
        endCard={this.getEndCard(matches.length, seeker)}
        showMenu={true}
        seekerId={this.props.seeker.id}
        icon={
          <MatchBestfitSvg
            height={'20px'}
            width={'20px'}
            style={{marginRight: '5px', marginBottom: '4px'}}
          />
        }
      />
    );
  };

  simpleWatchCards = (seeker) => {
    const matches = mergeMatches(seeker);
    let watchedJobs = seeker.watchedJobs;

    const jobList = watchedJobs.map((job) => {
      let match = matches.find((match) => match.jobId === job.id);

      if (match) {
        const hasInvitedOn = match.invitedOn;
        const inviteData = hasInvitedOn ? {invitedOn: match.invitedOn} : {};
        return {
          ...job,
          ...inviteData,
          matchData: {
            ...match.matchData,
            showMatchChart:
              match.matchData.employmentTypeFit &&
              match.matchData.degreeRankFit &&
              match.matchData.experienceLevelFit,
          },
        };
      } else {
        return job;
      }
    });
    return (
      <JobCardCarousel
        jobs={jobList}
        isApprenticeProgram={false}
        onJobClick={this.handleJobClicked}
        isMaSegment={false}
        isSeeker={true}
        title={'Favorited Roles'}
        titleLink={`/find_roles?savedRoles`}
        titleLinkClassName={styles.svg_saved_roles_link}
        onEmployerClick={this.handleEmployerClicked}
        onTeamClick={this.handleTeamClicked}
        icon={
          <HeartDefaultSvg
            height={'20px'}
            width={'20px'}
            style={{marginRight: '7px', marginBottom: '4px'}}
          />
        }
      />
    );
  };

  jobRow = (row) => {
    const role = this.props.role;

    return row.map((job) => {
      let matchData;
      if (!job.matchData) {
        matchData = {
          matchLevels: [0, 0, 0],
          ascendFit: false,
          overallFit: false,
          overallScore: 0,
          skillsScore: 0,
          stylesScore: 0,
          traitsScore: 0,
        };
      } else {
        matchData = job.matchData;
      }

      let invitedDate = null;
      if ('invitedOn' in job) {
        invitedDate = new Date(job.invitedOn);
      }

      return (
        <div className="col-md-3" key={job.id}>
          <SimpleJobCard
            showWatchIcon={role === 'JOB_SEEKER'}
            viewerType={'CANDIDATE'}
            watched={jobIsWatched(job.id, this.props.seeker)}
            invitedDate={invitedDate}
            invitedByName={matchData.invitedByName}
            invitedByRole={matchData.invitedByRole}
            onWatchChanged={(watched) => this.handleWatchChanged(job, watched)}
            name={job.name}
            team={job.team.name}
            employer={job.employer.name}
            matchData={matchData}
            onJobClick={() => this.handleJobClicked(job.id)}
            onTeamClick={() => this.handleTeamClicked(job.teamId)}
            onEmployerClick={() => this.handleEmployerClicked(job.employerId)}
            isMaSegment={this.state.isMaSegment}
            isApprenticeProgram={includesApprenticeProgram(this.props.seeker)}
          />
        </div>
      );
    });
  };

  simpleJobCards = (jobs) => {
    let truncJobs = jobs;
    truncJobs.length = Math.min(truncJobs.length, 8);
    const rows = splitArrayIntoRows(truncJobs, 4);
    return rows.map((row, i) => {
      return (
        <div key={i} className="col-11">
          <div className="row">{this.jobRow(row)}</div>
          <br />
        </div>
      );
    });
  };

  dismissNotification = () => {
    localStorage.setItem(
      'covid_notification_timeout',
      new Date().getTime() + 3600000
    );
    this.setState({showBanner: false});
  };

  getNotificationBanner = () => {
    const {showBanner} = this.state;
    let expiresAt = localStorage.getItem('covid_notification_timeout');
    if (expiresAt && new Date().getTime() < expiresAt) {
      return <Fragment />;
    }

    if (!showBanner) {
      return <Fragment />;
    }

    return (
      <div
        id="covid-alert"
        className="alert alert-dismissible"
        role="alert"
        style={{
          backgroundColor: '#004F7E',
          color: '#ffffff',
          width: '100%',
        }}
      >
        <h3>COVID-19 + Virtual Event Support</h3>
        <p style={{marginBottom: '0'}}>
          Ascend is continuing to connect people to opportunities in Indiana.
          Amid the COVID-19 pandemic, our team is working diligently to keep the
          Network job and internship opportunities up-to-date. The Network posts
          roles that are live on the employer’s career page, although some
          hiring processes may be delayed. Before applying, we encourage you to
          review the employer’s career page for updates. Additionally, we invite
          you to join any relevant virtual events hosted by Ascend at{' '}
          <a
            href="https://bit.ly/eventsascend"
            target="_blank"
            rel="noreferrer"
            style={{color: '#57BEEB'}}
          >
            bit.ly/eventsascend
          </a>
          . Questions? Reach out to your recruiter. We’re here to help!
        </p>
        <button
          type="button"
          className="ms-2 mb-1 close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={this.dismissNotification}
        >
          <span
            aria-hidden="true"
            className="align-self-top"
            style={{color: '#ffffff'}}
          >
            &times;
          </span>
        </button>
      </div>
    );
  };

  handleSkillsSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({skillsModalIsOpen: false});
  };

  showHelpFromAnExpert() {
    const {seeker} = this.props;
    return (
      !this.props.seekerLoading &&
      seeker.segments.length === 1 &&
      !seeker.segments[0].isApprenticeProgram
    );
  }

  render() {
    const COMPLETE_AND_APPROVED = 9;
    const findMatchData = () => {
      const match = _.find(this.props.seeker.matches, (i) => {
        return i.jobId === this.state.jobId;
      });
      return match ? match.matchData : null;
    };

    var job = {};
    if (this.state.jobId in this.props.jobCache) {
      job = this.props.jobCache[this.state.jobId];
    }

    return (
      <>
        <DocumentTitle title="Seeker Home">
          <div className="wholepage">
            <Layout.PageHeader
              title={null}
              notificationBanner={
                this._isMounted ? this.getNotificationBanner() : null
              }
              headerStyleOverride={{marginBottom: '8px', fontSize: '16px'}}
            />
            <SkillsModal
              isOpen={this.state.skillsModalIsOpen}
              onClose={() => {
                this.setState({skillsModalIsOpen: false});
              }}
              selectedSkills={
                this.props.seeker.skillTags ? this.props.seeker.skillTags : []
              }
              onChange={(selectedSkills) => {
                this.props.interviewSkillsChanged(selectedSkills);
              }}
              onSubmit={this.handleSkillsSubmit}
            />
            {this.props.showJobNotFoundModal && (
              <CandidateNotifyMissingRole
                isOpen={this.props.showJobNotFoundModal}
                onClose={() => this.props.setJobNotFoundModal(false)}
              />
            )}
            {!this.state.isMobile && this.isLoading() && (
              <span role="status">
                <div className="fullwidth ">{CandidateHomeLoader()}</div>
              </span>
            )}
            {this.state.isMobile && this.isLoading() && (
              <span role="status">
                <div className="fullwidth ">{CandidateHomeLoaderMobile()}</div>
              </span>
            )}

            {!this.isLoading() && (
              <div className="bd-pagebody">
                <main id="main" tabIndex="-1" className={styles.main}>
                  {job && job.id && (
                    <JobProfile
                      key={job.id}
                      job={job}
                      isOpen={this.state.profileOpen}
                      onClose={this.handleModalClose}
                      onWatchChanged={(watched) =>
                        this.handleWatchChanged(job, watched)
                      }
                      watched={jobIsWatched(
                        this.state.jobId,
                        this.props.seeker
                      )}
                      applied={jobIsApplied(
                        this.state.jobId,
                        this.props.seeker
                      )}
                      onAppliedChanged={(applied) =>
                        this.handleAppliedChanged(this.state.jobId, applied)
                      }
                      matchData={findMatchData()}
                    />
                  )}

                  <div
                    className="container"
                    style={{
                      width: includesApprenticeProgram(this.props.seeker)
                        ? 'initial'
                        : '75vw',
                    }}
                  >
                    {this.state.seekerId &&
                      this.props.seeker.published === true && (
                        <DidYouApply
                          handleJobClick={this.handleJobClicked}
                          maxHorizontalCardDisplay={4}
                          seekerId={this.state.seekerId}
                        />
                      )}
                    {includesApprenticeProgram(this.props.seeker) &&
                      !this.props.seeker.maOnboardCompleteCardsViewedAt && (
                        <MaCandidateStatus
                          candidateOnboarded={
                            this.props.profile.onboarded ||
                            this.props.profile.reonboarded
                          }
                          isSchoolApproved={this.props.seeker.isSchoolApproved}
                          isAdminApproved={this.props.seeker.accepted}
                          handleSurveyClick={this.handleSurveyClick}
                          seekerId={this.props.seeker.id}
                          isCompleteAndApproved={
                            this.props.seeker.profileStatusId ===
                            COMPLETE_AND_APPROVED
                          }
                        />
                      )}
                    {/* NOT MA.  Not Published */}
                    {this.props.seeker.id &&
                      this.props.seeker.published === false &&
                      !includesApprenticeProgram(this.props.seeker) && (
                        <div className="row">
                          <div className="col-12">
                            <Link
                              to={`/profile`}
                              className="padding-bottom-sixteen no-decoration col-12"
                            >
                              <h2 className="font-20 bold">
                                Role Recommendations
                              </h2>
                            </Link>
                            <NoMatches />
                          </div>
                        </div>
                      )}
                    {/* Not MA.  Published.  Matches Found => Show Recommendations */}
                    {this.props.seeker.id &&
                      !this.props.jobsLoading &&
                      this.props.seeker.published === true &&
                      !includesApprenticeProgram(this.props.seeker) &&
                      this.props.seeker.matches.length > 0 && (
                        <LoadingOverlay
                          active={this.props.optOutLoading}
                          spinner={true}
                          text="Loading..."
                        >
                          {this.simpleMatchCards(this.props.seeker)}
                        </LoadingOverlay>
                      )}
                    {/* Not MA.  Published.  No Matches Found => Browse Roles */}
                    {this.props.seeker.id &&
                      this.props.seeker.published === true &&
                      !includesApprenticeProgram(this.props.seeker) &&
                      this.props.seeker.matches.length === 0 && (
                        <div
                          className="mb-3"
                          style={{
                            marginLeft: this.state.isMobile
                              ? 'initial'
                              : '58px',
                            marginRight: this.state.isMobile
                              ? 'initial'
                              : '58px',
                          }}
                        >
                          <div className="flex-row align-items-center d-flex flex-nowrap pb-1">
                            <span
                              style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexShrink: 0,
                              }}
                            >
                              <MatchBestfitSvg
                                height={'20px'}
                                width={'20px'}
                                className="svg_color_fill_blue"
                              />{' '}
                              &nbsp;&nbsp;
                              <h2
                                className="font-20 bold no-decoration col-auto p-0 mb-0"
                                style={{color: 'var(--secondary-2)'}}
                              >
                                Recommended Roles{' '}
                                <span className="normal-caption bold no-decoration pt-1">
                                  &nbsp;&nbsp;0
                                </span>
                              </h2>
                            </span>
                          </div>

                          <div
                            className={`bd-whitebackground ${styles.banner}`}
                          >
                            <span
                              className={`ps-4 pb-4 pt-4 pe-4 ${styles.content}`}
                            >
                              <p style={{fontSize: '14px', marginBottom: 0}}>
                                You’re not matching to any roles yet. We match
                                you based on your preferences and skills. Try
                                expanding your preferences or adding skills to
                                your profile and you’ll get new matches in
                                minutes.
                              </p>
                            </span>

                            <span className={`pe-4 ${styles.action}`}>
                              <button
                                className={`btn btn-primary me-2`}
                                style={{
                                  width: 'fit-content',
                                }}
                                onClick={() =>
                                  this.setState({skillsModalIsOpen: true})
                                }
                              >
                                Add Skills
                              </button>
                              <button
                                className={`btn btn-secondary-neutral`}
                                style={{
                                  width: 'fit-content',
                                }}
                                onClick={() =>
                                  this.props.history.push('/profile')
                                }
                              >
                                Update Preferences
                              </button>
                            </span>
                          </div>
                        </div>
                      )}
                    {/* MA.  Published OR NOT published */}
                    {this.props.seeker.id &&
                      includesApprenticeProgram(this.props.seeker) && (
                        <div className="row">
                          {/* Show jobs or recommendations based on whether there are recommendations */}
                          {this.props.seeker.matches.length !== 0 &&
                            this.simpleMatchCards(this.props.seeker)}

                          {this.props.seeker.matches.length === 0 &&
                            this.simpleJobCards(this.props.jobs)}
                        </div>
                      )}

                    {!this.props.seekerLoading && !this.props.jobsLoading && (
                      <LoadingOverlay
                        active={this.props.watchRequestLoading}
                        spinner={true}
                        text="Loading..."
                        styles={{wrapper: {minHeight: '154px'}}}
                      >
                        {this.props.seeker.watchedJobs.filter(
                          (job) => job.published === true
                        ).length !== 0 &&
                          this.simpleWatchCards(this.props.seeker)}

                        {this.props.seeker.watchedJobs.filter(
                          (job) => job.published === true
                        ).length === 0 && (
                          <div
                            className="mb-3"
                            style={{
                              marginLeft: this.state.isMobile
                                ? 'initial'
                                : '58px',
                              marginRight: this.state.isMobile
                                ? 'initial'
                                : '58px',
                            }}
                          >
                            <div className="flex-row align-items-center d-flex flex-nowrap pb-1">
                              <HeartDefaultSvg
                                height={'20px'}
                                width={'20px'}
                                className="svg_color_stroke_blue"
                              />{' '}
                              &nbsp;&nbsp;
                              <span
                                className="font-20 bold col-auto p-0 mb-0"
                                style={{color: 'var(--secondary-2)'}}
                              >
                                Favorited Roles{' '}
                                <span className="normal-caption bold no-decoration pt-1">
                                  &nbsp;&nbsp;0
                                </span>
                              </span>
                            </div>

                            <div
                              className={`bd-whitebackground ${styles.banner}`}
                            >
                              <span
                                className={`ps-4 pb-4 pt-4 pe-4 ${styles.content}`}
                              >
                                <p style={{fontSize: '14px', marginBottom: 0}}>
                                  First, take a look at some roles. When you see
                                  one you’re interested in, hit the heart icon.
                                  Then, it’ll appear here for you to reference
                                  in the future.
                                </p>
                              </span>
                              <span className={`pe-4 ${styles.action}`}>
                                <button
                                  className={`btn btn-secondary-neutral`}
                                  onClick={() =>
                                    this.props.history.push('/find_roles')
                                  }
                                >
                                  Explore Roles
                                </button>
                              </span>
                            </div>
                          </div>
                        )}
                      </LoadingOverlay>
                    )}

                    {this.showHelpFromAnExpert() && (
                      <HelpFromAnExpert
                        ascendRecruiter={this.props.seeker.ascendRecruiter}
                        firstPublishedAt={this.props.seeker.firstPublishedAt}
                      />
                    )}
                  </div>
                  {!this.props.seekerLoading &&
                    this.props.seeker?.id &&
                    !includesApprenticeProgram(this.props.seeker) && (
                      <div className={`container ${styles.sidebar}`}>
                        <MyProgress seekerId={this.props.seeker.id} />
                        {!includesApprenticeProgram(this.props.seeker) && (
                          <BestMatches />
                        )}
                      </div>
                    )}

                  <br />
                </main>
              </div>
            )}
            <Layout.PageFooter />
          </div>
        </DocumentTitle>
      </>
    );
  }
}

CandidateHomePage.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInSeekerId: PropTypes.string,
  role: PropTypes.string,
  seeker: PropTypes.object,
  jobCache: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    job: PropTypes.object,
  }),
  createJobWatch: PropTypes.func.isRequired,
  deleteJobWatch: PropTypes.func.isRequired,
  createJobApplied: PropTypes.func.isRequired,
  deleteJobApplied: PropTypes.func.isRequired,
  getJobToCache: PropTypes.func.isRequired,
  getEmployersAdvanced: PropTypes.func.isRequired,
  employers: PropTypes.array.isRequired,
  employersItemsPerPage: PropTypes.number.isRequired,
  employersTotalItems: PropTypes.number.isRequired,
  employersLoading: PropTypes.bool.isRequired,
  employersLoaded: PropTypes.bool.isRequired,
  getSeekerAction: PropTypes.func.isRequired,
  getSeekerJobMatches: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  candidateHomePageTracked: PropTypes.bool.isRequired,
  mixPanelTrackCandidateHomePage: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  seekerLoading: PropTypes.bool.isRequired,
  jobsLoading: PropTypes.bool.isRequired,
  jobsLoaded: PropTypes.bool.isRequired,
  showJobNotFoundModal: PropTypes.bool,
  setJobNotFoundModal: PropTypes.func,
  optOutLoading: PropTypes.bool.isRequired,
  watchRequestLoading: PropTypes.bool.isRequired,
  getSeekerJobStats: PropTypes.func.isRequired,
  interviewSkillsChanged: PropTypes.func.isRequired,
  updateSeekerAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loggedInSeekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    candidateHomePageTracked: state.seekers.candidateHomePageTracked,
    jobCache: state.jobs.jobCache,
    showJobNotFoundModal: state.jobs.showJobNotFoundModal,
    role: state.profile.role,
    employers: state.employers.employers,
    employersItemsPerPage: state.employers.itemsPerPage,
    employersTotalItems: state.employers.totalItems,
    employersLoading: state.employers.employersLoading,
    employersLoaded: state.employers.employersLoaded,
    jobs: state.jobAndTeamSearch.jobs,
    seekerLoading: state.seekers.seekerLoading,
    jobsLoading: state.jobAndTeamSearch.jobsLoading,
    jobsLoaded: state.jobAndTeamSearch.jobsLoaded,
    optOutLoading: state.seekers.optOutLoading,
    watchRequestLoading: state.seekers.watchRequestLoading,
  };
};

const mapDispatchToProps = {
  ...seekersActions,
  ...seekerActionsAsync,
  ...jobsActions,
  ...employersActions,
  ...jobAndTeamSearchActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateHomePage)
);
