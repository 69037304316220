import {makeRequest} from 'src/services/api.js';

export const getEmployerCache = (employerId) => {
  return makeRequest({
    method: 'GET',
    path: `employers/${employerId}/cache`,
  });
};

export const getSeekerCache = (publicSeekerId) => {
  return makeRequest({
    method: 'GET',
    path: `seekers/${publicSeekerId}/cache`,
  });
};

export const getJobCache = (jobId) => {
  return makeRequest({
    method: 'GET',
    path: `jobs/${jobId}/cache`,
  });
};
