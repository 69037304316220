import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';

class ApplyToJobHeader extends Component {
  render() {
    const {headerText, subHeaderText} = this.props;
    return (
      <div className="bd-darkbluebackground">
        <Row>
          <Col className="normal-headline bold justify-content-center pt-4 text-align-center">
            {headerText}
          </Col>
        </Row>
        <Row>
          <Col className="normal-headline justify-content-center pt-2 pb-4 text-align-center">
            {subHeaderText}
          </Col>
        </Row>
      </div>
    );
  }
}

ApplyToJobHeader.propTypes = {
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
};

export default ApplyToJobHeader;
