import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {ExperienceFieldArray} from 'src/formFields';
import {connect} from 'react-redux';

import * as Layout from '../layout';
import {BusyButton} from './BusyButton';

class ExperienceModal extends React.Component {
  blankExperience = {
    company: '',
    notes: '',
    timeframe: '',
    title: '',
  };

  requiredArray = (value) =>
    value && value.length > 0 ? undefined : 'Required';

  render() {
    const {isOpen, onClose} = this.props;
    if (this.props.initialValues) {
      return (
        <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
          <Layout.AscendModalBody hasClose={true}>
            <Form
              onSubmit={this.props.onSubmit}
              mutators={{...arrayMutators}}
              initialValues={{
                experience: this.props.initialValues.experience || [
                  this.blankExperience,
                ],
              }}
            >
              {({handleSubmit}) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <center>
                      <div className="normal-title padding-bottom-eight bold">
                        Edit Experience
                      </div>
                    </center>
                    <hr />
                    <br />
                    <div>
                      <FieldArray
                        name="experience"
                        component={ExperienceFieldArray}
                        maxFields={20}
                        validate={this.requiredArray}
                        blankExperience={this.blankExperience}
                      />
                    </div>
                    <div className="text-align-center nowrap">
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-transparent me-4"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <BusyButton
                          onClick={handleSubmit}
                          buttonText={'Save'}
                          busyText={'Saving'}
                          style={{width: '210px', height: '40px'}}
                          float={'float-right'}
                          buttonIcon={'save'}
                          alignText={'text-center'}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </Layout.AscendModalBody>
        </Layout.AscendModal>
      );
    } else {
      return <div />;
    }
  }
}

ExperienceModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
};

const actions = {};

let EditExperienceModal = (props) => <ExperienceModal {...props} mode="edit" />;

const editMapStateToProps = (state) => {
  return {
    initialValues: state.seekers.seekerForEdit,
  };
};

EditExperienceModal = connect(
  editMapStateToProps,
  actions
)(EditExperienceModal);

export {EditExperienceModal};
