import * as types from './ActionTypes';
import {makeRequest} from '../services/api';

export const getSegments =
  (addAny = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.SEGMENTS__GET_REQUEST,
      });

      let response = await makeRequest({method: 'GET', path: 'segments/'});
      if (response.status === 200) {
        let actionType = addAny
          ? types.SEGMENTS__GET_SUCCESS_ADD_ANY
          : types.SEGMENTS__GET_SUCCESS;
        dispatch({
          type: actionType,
          response: {body: response.data},
        });
      }
    } catch (err) {
      dispatch({
        type: types.SEGMENTS__GET_FAILURE,
        payload: err,
      });
    }
  };

export const getPartnerSegment = (partnerUrl) => async (dispatch) => {
  if (partnerUrl === undefined || partnerUrl === '/') {
    return;
  }

  try {
    dispatch({
      type: types.SEGMENTS__GET_PARTNER_REQUEST,
    });

    // Append the partnerUrl as a query parameter
    let path = `segments/partner/?url=${encodeURIComponent(partnerUrl)}`;

    let response = await makeRequest({
      method: 'GET',
      path: path,
    });

    if (response.status === 200) {
      dispatch({
        type: types.SEGMENTS__GET_PARTNER_SUCCESS,
        response: {body: response.data},
      });
    }
  } catch (err) {
    dispatch({
      type: types.SEGMENTS__GET_PARTNER_FAILURE,
      payload: err,
    });
  }
};
