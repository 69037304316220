import React, {useState} from 'react';
import styles from './ClientSuccessManager.module.scss';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar.js';
import {DuplicateSvg, InvitationSvg} from '../../assets/svg/svgComponents';
import {Tooltip as ReactTooltip} from 'react-tooltip';

const ClientSuccessManager = ({accountManager}) => {
  const [dataTip, setDataTip] = useState('Copy Email');
  const copyEmail = () => {
    navigator.clipboard.writeText(accountManager.email).then(() => {
      setDataTip('Email Copied');
      setTimeout(function () {
        setDataTip('Copy Email');
      }, 3000);
    });
  };

  if (!accountManager) {
    return (
      <div className={styles.successManager}>
        <h3>Looks like you don't have a contact assigned yet...</h3>
        <p>
          For some reason, you don't have an assigned point of contact yet.
          Please reach out to{' '}
          <a href="mailto:support@ascendindiana.com">
            support@ascendindiana.com
          </a>{' '}
          so we can assist in assigning you to a point of contact.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.successManager}>
      <ReactTooltip
        id="tooltip"
        className="solid-tooltip-dark"
        clickable={true}
        closeOnEsc={true}
      />
      <div className={styles.successManager__title}>
        Your Client Success Manager
      </div>

      <div className={styles.successManager__content}>
        <Avatar height={44} width={44} url={accountManager.profileAvatarUrl} />

        <div className={styles.successManager__details}>
          <div className={styles.successManager__name}>
            {accountManager.name}
          </div>

          <div className={styles.successManager__email}>
            <p>
              <InvitationSvg className="email_icon_gray" />
              <span className={styles.successManager__emailText}>
                {accountManager.email}
              </span>
              <button
                onClick={copyEmail}
                className="buttonAsLink"
                data-tooltip-id="tooltip"
                data-tooltip-html={dataTip}
              >
                <DuplicateSvg className="duplicate_icon_gray" />
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ClientSuccessManager.propTypes = {
  accountManager: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    profileAvatarUrl: PropTypes.string.isRequired,
  }),
};

export default ClientSuccessManager;
