import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Tooltip as ReactTooltip} from 'react-tooltip';

class TagsEditLink extends React.Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div
        className="clickable candidate-profile-edit-link bold orangeHover"
        style={{textDecoration: 'underline', fontSize: '12pt'}}
        onClick={this.handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick(e);
          }
          return;
        }}
        tabIndex={0}
        role="button"
        aria-label={'Edit Skills & Expertise'}
      >
        Edit
      </div>
    );
  }
}

TagsEditLink.propTypes = {
  onClick: PropTypes.func,
};

class TagsWidget extends React.Component {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 430px)').matches,
    });
  }
  render() {
    const tagClass = classNames('inlineblock', 'rounded', this.props.tagClass);

    let tags = this.props.tags.map((tag, i) => {
      return (
        <div
          tabIndex={0}
          key={i}
          className={tagClass}
          data-tooltip-id={'tooltip'}
          data-tooltip-content={tag.definition ? tag.definition : null}
        >
          {tag.tagName}
        </div>
      );
    });

    if (tags.length === 0) {
      if (this.props.varies) {
        tags = <div className={tagClass}>Varies by Role</div>;
      } else {
        tags = <div className={tagClass}>None Selected</div>;
      }
    }

    return (
      <div>
        {!this.state.isMobile && (
          <ReactTooltip
            className="solid-tooltip-dark"
            id={'tooltip'}
            closeOnEsc={true}
            place={'top'}
          />
        )}
        <div>
          <div className="inlineblock normal-caption bold">
            <h2
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {this.props.title}
            </h2>
          </div>
          {!this.props.readOnly && (
            <div className="inlineblock float-right">
              <TagsEditLink onClick={this.props.onEdit} />
            </div>
          )}
        </div>
        <div>{tags}</div>
      </div>
    );
  }
}

TagsWidget.propTypes = {
  onEdit: PropTypes.func,
  title: PropTypes.string,
  tagClass: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  varies: PropTypes.bool,
};

TagsWidget.defaultProps = {
  tags: [{tagName: 'Tag Name', definition: 'Tag definition'}],
  tagClass: 'skilltag',
  readOnly: false,
  varies: false,
};

export default TagsWidget;
