import * as types from '../actions/ActionTypes';

const initialState = {
  currentPage: 0,
  currentItemsPerPage: 25,
  searchTerm: '',
  sortBy: '',
  sortAscending: true,
};

export default function ascendDataTable(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_DT__UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case types.SEARCH_DT__UPDATE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
        currentPage: 0,
      };
    case types.SEARCH_DT__UPDATE_SORT_TERM:
      return {
        ...state,
        sortBy: action.sortBy,
        sortAscending: action.sortAscending,
        currentPage: 0,
      };
    default:
      return state;
  }
}
