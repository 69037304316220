import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';

class AscendSelect extends React.Component {
  //F5F5F5  3061a9
  render() {
    const colourStyles = {
      option: (styles, {isDisabled, isFocused, isSelected}) => {
        return {
          ...styles,

          backgroundColor: isDisabled
            ? null
            : isFocused
            ? '#3061a9'
            : isSelected
            ? 'white'
            : 'white',
          color: isDisabled
            ? '#BEBEBE'
            : isFocused
            ? 'white'
            : isSelected
            ? 'secondary-subheader'
            : 'secondary-subheader',
          cursor: isDisabled
            ? 'not-allowed'
            : isFocused
            ? 'pointer'
            : 'default',
        };
      },
      singleValue: (styles) => ({
        ...styles,
        color: 'secondary-subheader',
      }),
      input: (styles) => ({
        ...styles,
        color: 'secondary-subheader',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: 'secondary-subheader',
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: 'var(--neutral-gray-1)',
      }),
    };

    const Option = (props) => {
      return <components.Option {...props} />;
    };

    const MenuList = (props) => {
      return <components.MenuList {...props} />;
    };

    const Input = (props) => {
      return <components.Input {...props} />;
    };

    const ariaSelect = (props) => (
      <Select
        menuId="unique_menu_id"
        styles={colourStyles}
        onChange={(e) => props.onChange(e)}
        options={props.options}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        placeholder={props.placeholder}
        visible={props.visible}
        value={props.value}
        defaultValue={props.defaultValue}
        isMulti={this.props.multiSelect}
        isSearchable={props.searchable}
        aria-label={props.ariaLabel}
        components={{Option, MenuList, Input}}
        {...props}
      />
    );

    const {
      label,
      options,

      minWidth,
      visible,
    } = this.props;
    return (
      <div>
        {visible && options && (
          <Fragment>
            <span className="secondary-body">
              <div id="selectLabel">{label}</div>
            </span>
            <div
              className="form form-group"
              style={
                this.props.fullWidth
                  ? {width: '100%'}
                  : minWidth
                  ? {width: minWidth + 'px'}
                  : {width: '150px'}
              }
            >
              {ariaSelect(this.props)}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

AscendSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  minWidth: PropTypes.number,
  visible: PropTypes.bool,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  multiSelect: PropTypes.bool,
  ariaLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
};

AscendSelect.defaultProps = {
  isLoading: false,
  isDisabled: false,
  visible: true,
  searchable: false,
  multiSelect: false,
  ariaLabel: '',
};

export default AscendSelect;
