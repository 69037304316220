import * as types from './ActionTypes';

const apiUrl = process.env.REACT_APP_API_URL;

export function getCompanies() {
  return {
    types: [
      types.COMPANIES__GET_REQUEST,
      types.COMPANIES__GET_SUCCESS,
      types.COMPANIES__GET_FAILURE,
    ],
    uri: `${apiUrl}/companies`,
    method: 'GET',
    isAuthenticated: false,
  };
}
