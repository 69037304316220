import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';

import {required} from '../../../utils/intake/intakeCommon';
import PushButtonArray from '../../../components/controls/buttons/PushButtonArray/PushButtonArray';

class ExperienceLevel extends React.Component {
  validate = (values) => {
    const errors = {};
    const requiredFields = ['experienceLevelIds'];
    _.forEach(requiredFields, (field) => {
      if (!values[field] || values[field].length === 0) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  buildButtonList = () => {
    const buttons = this.props.listOptions.map((x) => {
      return {buttonText: `${x.name} (${x.years})`, buttonValue: x.id};
    });
    return buttons;
  };

  render() {
    const {next, previous, headerImage} = this.props;

    return (
      <>
        <PageHeader
          title="What experience level are you looking for?"
          headerImage={headerImage}
        />
        <Form
          onSubmit={next}
          validate={this.validate}
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            experienceLevelIds:
              this.props.intakeCache?.experienceLevelIds || [],
          }}
        >
          {({handleSubmit, form}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <div>
                    <div className="d-flex flex-column align-items-center">
                      <span className={styles.pageText}>
                        Select all that apply. We will only recommend roles to
                        you with the same experience level(s) you choose.
                      </span>
                      <div style={{maxWidth: '250px'}}>
                        <Field
                          component={PushButtonArray}
                          name="experienceLevelIds"
                          validate={required}
                          buttonList={this.buildButtonList()}
                          displayVertical={true}
                          multiSelect={true}
                          form={form}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
ExperienceLevel.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  listOptions: PropTypes.array.isRequired,
  headerImage: PropTypes.any,
};

export default ExperienceLevel;
