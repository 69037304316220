import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import {Field, Form} from 'react-final-form';

import {getEmploymentTypes} from 'src/actions/employmentTypes';
import {required} from '../../../utils/intake/intakeCommon';
import PushButtonArray from '../../../components/controls/buttons/PushButtonArray/PushButtonArray';

class EmploymentType extends React.Component {
  componentDidMount() {
    if (this.props.employmentTypes.length === 0) {
      this.props.getEmploymentTypes();
    }
  }

  validate = (values) => {
    const errors = {};
    const requiredFields = ['employmentTypeIds'];
    _.forEach(requiredFields, (field) => {
      if (!values[field] || values[field].length === 0) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };
  buildButtonList = () => {
    const buttons = this.props.employmentTypes
      .filter((et) => et.showDuringOnboarding)
      .map((x) => {
        return {buttonText: x.name, buttonValue: x.id};
      });
    return buttons;
  };

  render() {
    const {next, previous, headerImage} = this.props;

    return (
      <>
        <PageHeader
          title="What type(s) of employment are you looking for?"
          headerImage={headerImage}
        />
        <Form
          onSubmit={next}
          validate={this.validate}
          initialValues={{
            employmentTypeIds: this.props.intakeCache?.employmentTypeIds || [],
          }}
        >
          {({handleSubmit, form}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.pageContent}>
                  <Field
                    component={PushButtonArray}
                    name="employmentTypeIds"
                    validate={required}
                    buttonList={this.buildButtonList()}
                    displayVertical={true}
                    multiSelect={true}
                    form={form}
                    style={{width: '277px'}}
                  />
                </div>
                <PageFooter
                  next={() => form.submit()}
                  previous={previous}
                  getValues={() => form.getState().values}
                />
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
EmploymentType.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  intakeCache: PropTypes.any.isRequired,
  isMobile: PropTypes.bool,
  employmentTypes: PropTypes.array,
  getEmploymentTypes: PropTypes.func,
  headerImage: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    employmentTypes: state.employmentTypes.employmentTypes,
    employmentTypesLoading: state.employmentTypes.employmentTypesLoading,
  };
}
const actions = {
  getEmploymentTypes,
};

export default connect(mapStateToProps, actions)(EmploymentType);
