import React, {Component} from 'react';
import PropTypes from 'prop-types';

class DownloadResumeLink extends Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <a
        href={this.props.url}
        onClick={this.handleClick}
        className="active-subheader"
        style={{textDecoration: 'underline'}}
      >
        Download Resume
      </a>
    );
  }
}

DownloadResumeLink.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
};

export default DownloadResumeLink;
