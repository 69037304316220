import React from 'react';
import PropTypes from 'prop-types';

class CandidateName extends React.Component {
  render() {
    const {seeker} = this.props;
    let text = '\u00A0';
    if (seeker.id) {
      text = `${seeker.firstName} ${seeker.lastName}`;
    }
    return (
      <h2 className="profile-header bold padding-top-thirtytwo padding-bottom-sixteen mb-0">
        &nbsp;{text}
      </h2>
    );
  }
}

CandidateName.propTypes = {
  seeker: PropTypes.object,
};

CandidateName.defaultProps = {
  seeker: {},
};

export default CandidateName;
