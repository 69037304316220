import React from 'react';
import DocumentTitle from '../../components/vendor/DocumentTitle';
import {connect} from 'react-redux';

class GoodbyePage extends React.Component {
  render() {
    var goodbyeDiv = <div />;

    goodbyeDiv = (
      <div>
        <br />
        <p className="normal-headline bold">Good Luck!</p>
        <br />
        <p className="secondary-title">
          Congratulations, and thanks again for working with Ascend in your
          search for a role. Please click below to log out of the system. Best
          of luck!
        </p>
        <br />
        <a
          className="clickable btn btn-primary"
          href="http://ascendindiana.com"
        >
          Log Out of the Ascend Network
        </a>
      </div>
    );

    return (
      <DocumentTitle title="Goodbye">
        <div>
          <div className="fullindy">
            <br />
            <div className="container">
              <div className="row h-100">
                <div className="col-md-2" />
                <div className="col-md-8 my-auto">
                  <div className="bd-whitebackground padding-32">
                    <div className="row">
                      <div className="col-md-1" />
                      <div className="col-md-10">{goodbyeDiv}</div>
                      <div className="col-md-1" />
                    </div>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

GoodbyePage.propTypes = {};

function mapStateToProps(state, ownProps) {}

export default connect(mapStateToProps)(GoodbyePage);
