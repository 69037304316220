const UTM_KEYS_TO_PERSIST = [
  'utmCampaign',
  'utmSource',
  'utmMedium',
  'utmContent',
  'utmTerm',
];

export const getUtmKeys = () => {
  const utmKeys = {};
  for (let key of UTM_KEYS_TO_PERSIST) {
    const value = localStorage.getItem(key);
    if (!value) continue;
    utmKeys[key] = value;
  }

  return utmKeys;
};

export const setUtmKeys = (utmKeys) => {
  for (let key of UTM_KEYS_TO_PERSIST) {
    if (!utmKeys[key]) continue;
    localStorage.setItem(key, utmKeys[key]);
  }
};
