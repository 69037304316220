import {DEFAULT_KEY, generateCacheTTL} from 'redux-cache';

import * as types from '../actions/ActionTypes';

const initialState = {
  [DEFAULT_KEY]: null,
  strategicBusinessUnits: [],
  strategicBusinessUnitsLoading: false,
};

export default function strategicBusinessUnits(state = initialState, action) {
  switch (action.type) {
    case types.STRATEGIC_BUSINESS_UNITS__GET_REQUEST:
      return {
        ...state,
        strategicBusinessUnits: [],
        strategicBusinessUnitsLoading: true,
      };
    case types.STRATEGIC_BUSINESS_UNITS__GET_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(3600000), // one hour in ms
        strategicBusinessUnits: action.response.body.strategicBusinessUnits,
        strategicBusinessUnitsLoading: false,
      };
    default:
      return state;
  }
}
