import {CandidateAdminTabs} from 'src/utils/CandidateAdminTabs';
import * as types from '../actions/ActionTypes';
const initialFilters = {
  searchTerm: '',
  toggleButtons: [],
  toggleButtonFilters: [],
  allFilters: [],
  multiSelect: {
    segmentIds: [],
    employmentTypeIds: [],
    recruiterIds: [],
    degreeTypeRankIds: [],
    regionIds: [],
    industryIds: [],
    meetingStatusIds: [],
    profileStatusIds: [],
    roleTypeIds: [],
    skillTagIds: [],
    seekerSupportFlag: [],
    accepted: [],
    isSchoolApproved: [],
    highSchoolIds: [],
  },
  searchFilters: [],
};

const initialState = {
  selectedTab: '1',
  active: initialFilters,
  inactive: initialFilters,
  maApprovals: {
    ...initialFilters,
    allFilters: ['isSchoolApproved=null', 'accepted=null'],
    multiSelect: {
      ...initialFilters.multiSelect,
      accepted: [{value: 'null', label: 'Pending'}],
      isSchoolApproved: [{value: 'null', label: 'Pending'}],
    },
  },
  approvals: {
    ...initialFilters,
    allFilters: ['accepted=null'],
    multiSelect: {
      ...initialFilters.multiSelect,
      accepted: [{value: 'null', label: 'Pending'}],
    },
  },
};

export default function candidateFilters(state = initialState, action) {
  var active = activeCandidateFilters(state.active, action);
  var inactive = inactiveCandidateFilters(state.inactive, action);
  var maApprovals = maApprovalsCandidateFilters(state.maApprovals, action);
  var approvals = approvalsCandidateFilters(state.approvals, action);
  var selectedTab = state.selectedTab;
  switch (action.type) {
    case types.CANDIDATE_UPDATE_ALLFILTERS:
    case types.CANDIDATE_CHANGE_TAB:
      selectedTab = action.selectedTab;
      break;
    default:
      break;
  }
  return {
    active,
    inactive,
    maApprovals,
    approvals,
    selectedTab,
  };
}

function inactiveCandidateFilters(state = initialState.inactive, action) {
  if (action.selectedTab !== CandidateAdminTabs.INACTIVE) return state;

  switch (action.type) {
    case types.CANDIDATE_FILTERS_CLEAR:
      return {...initialState.inactive};

    case types.CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.CANDIDATE_ADVANCED_FILTER_SELECT:
      return {
        ...state,
        advancedFilters: action.advancedFilters,
      };
    case types.CANDIDATE_FILTER_SEARCHTERM_UPDATE:
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchFilters: action.searchFilters,
      };
    case types.CANDIDATE_FILTER_BUTTON_TOGGLE:
      return {
        ...state,
        toggleButtons: action.toggleButtons,
        toggleButtonFilters: action.toggleButtonFilters,
      };
    case types.CANDIDATE_UPDATE_ALLFILTERS:
      return {
        ...state,
        allFilters: action.allFilters,
      };
    default:
      return state;
  }
}

function activeCandidateFilters(state = initialState.active, action) {
  if (action.selectedTab !== CandidateAdminTabs.ACTIVE) return state;

  switch (action.type) {
    case types.CANDIDATE_FILTERS_CLEAR:
      return {...initialState.active};

    case types.CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.CANDIDATE_ADVANCED_FILTER_SELECT:
      return {
        ...state,
        advancedFilters: action.advancedFilters,
      };
    case types.CANDIDATE_FILTER_SEARCHTERM_UPDATE:
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchFilters: action.searchFilters,
      };

    case types.CANDIDATE_FILTER_BUTTON_TOGGLE:
      return {
        ...state,
        toggleButtons: action.toggleButtons,
        toggleButtonFilters: action.toggleButtonFilters,
      };

    case types.CANDIDATE_UPDATE_ALLFILTERS:
      return {
        ...state,
        allFilters: action.allFilters,
      };

    default:
      return state;
  }
}

function maApprovalsCandidateFilters(state = initialState.maApprovals, action) {
  if (action.selectedTab !== CandidateAdminTabs.MA_APPROVALS) return state;

  switch (action.type) {
    case types.CANDIDATE_FILTERS_CLEAR:
      return {
        ...initialState.maApprovals,
        allFilters: [],
        multiSelect: {
          ...initialState.maApprovals.multiSelect,
          accepted: [],
          isSchoolApproved: [],
        },
      };

    case types.CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.CANDIDATE_ADVANCED_FILTER_SELECT:
      return {
        ...state,
        advancedFilters: action.advancedFilters,
      };
    case types.CANDIDATE_FILTER_SEARCHTERM_UPDATE:
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchFilters: action.searchFilters,
      };
    case types.CANDIDATE_FILTER_BUTTON_TOGGLE:
      return {
        ...state,
        toggleButtons: action.toggleButtons,
        toggleButtonFilters: action.toggleButtonFilters,
      };
    case types.CANDIDATE_UPDATE_ALLFILTERS:
      return {
        ...state,
        allFilters: action.allFilters,
      };
    default:
      return state;
  }
}

function approvalsCandidateFilters(state = initialState.approvals, action) {
  if (action.selectedTab !== CandidateAdminTabs.APPROVALS) return state;

  switch (action.type) {
    case types.CANDIDATE_FILTERS_CLEAR:
      return {
        ...initialState.approvals,
        allFilters: [],
        multiSelect: {
          ...initialState.approvals.multiSelect,
          accepted: [],
          isSchoolApproved: [],
        },
      };

    case types.CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.CANDIDATE_ADVANCED_FILTER_SELECT:
      return {
        ...state,
        advancedFilters: action.advancedFilters,
      };
    case types.CANDIDATE_FILTER_SEARCHTERM_UPDATE:
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchFilters: action.searchFilters,
      };
    case types.CANDIDATE_FILTER_BUTTON_TOGGLE:
      return {
        ...state,
        toggleButtons: action.toggleButtons,
        toggleButtonFilters: action.toggleButtonFilters,
      };
    case types.CANDIDATE_UPDATE_ALLFILTERS:
      return {
        ...state,
        allFilters: action.allFilters,
      };
    default:
      return state;
  }
}
