import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const HavingTrouble = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">&nbsp;</div>
      </div>
      <div className={props.className}>
        <div className="col-12">
          Having trouble? Send us an email at&nbsp;
          <a
            style={{textDecoration: 'underline'}}
            href="mailto:support@ascendindiana.com"
          >
            support@ascendindiana.com
          </a>
          .
        </div>
      </div>
    </Fragment>
  );
};
HavingTrouble.propTypes = {
  className: PropTypes.string,
};
HavingTrouble.defaultProps = {
  className: 'row justify-content-center text-align-bottom-tinytext',
};

export default HavingTrouble;
