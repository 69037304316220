import React from 'react';
import styles from '../onboarding.module.scss';
import PropTypes from 'prop-types';
import {AscendFullLogoSvg} from '../../../assets/svg/svgComponents';

export default function PageHeader({
  title,
  subTitle,
  labelText,
  headerImage,
  id,
}) {
  return (
    <>
      <header className={styles.pageHeader}>
        <div className="d-flex flex-column align-items-center ">
          <AscendFullLogoSvg style={{height: '25px'}} />

          {headerImage && (
            <headerImage.headerImageSvg
              className={styles.headerSvg}
              viewBox={headerImage.viewBox}
              alt=""
            />
          )}

          <h3 id={id} className={styles.pageHeaderTitle}>
            {title}
          </h3>
          {labelText && (
            <label className="onboarding-form-label">{labelText}</label>
          )}
          {subTitle && (
            <div className={styles.pageSubHeaderTitle}>{subTitle}</div>
          )}
        </div>
      </header>
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.any,
  labelText: PropTypes.string,
  headerImage: PropTypes.any,
  id: PropTypes.string,
};
PageHeader.defaultProps = {
  title: 'Create Your Account',
  labelText: '',
};
