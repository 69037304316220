import React, {useEffect, useState} from 'react';
import DocumentTitle from 'src/components/vendor/DocumentTitle';
import {Field, Form} from 'react-final-form';
import {FormPasswordInput, TextField} from 'src/formFields';
import {useParams} from 'react-router';
import {signupStyles} from 'src/utils/signupStyles';
import styles from './Signup.module.scss';
import {useIsMobile} from 'src/utils/commonHooks';
import auth0 from 'auth0-js';
import {Link, useSearchParams} from 'react-router-dom';
import {Google} from 'src/assets/svg/svgComponents';
import {useDispatch, useSelector} from 'react-redux';
import {actions as authActions} from 'src/reducers/authentication';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, A11y, Pagination} from 'swiper/modules';
import {BusyButton} from 'src/components/BusyButton';
import {types} from 'src/reducers/authentication';
import AscendNetworkLogo from 'src/assets/svg/icons/Ascend-Network-logo.svg';

export default function Signup() {
  const dispatch = useDispatch();
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  let {partner: partnerParam} = useParams();
  const partner = partnerParam?.toLowerCase();
  const [searchParameters] = useSearchParams();
  const isMobile = useIsMobile(991);
  let loginError = useSelector((state) => state.authentication.loginError);
  const [signupStyle, setSignupStyle] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const lowercaseRegex = new RegExp(/.*[a-z]/);
  const uppercaseRegex = new RegExp(/.*[A-Z]/);
  const numberRegex = new RegExp(/.*\d/);
  const lengthRegex = new RegExp(/.{8,}$/);
  const specialCharsRegex = new RegExp(
    /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/
  );

  const rules = [
    {label: 'Lower case letters (a-z)', pattern: lowercaseRegex},
    {label: 'Upper case letters (A-Z)', pattern: uppercaseRegex},
    {label: 'Numbers (0-9)', pattern: numberRegex},
    {label: 'Special characters (!@#$%^&*)', pattern: specialCharsRegex},
  ];

  const matchesThreeRules = (password) => {
    const lower = lowercaseRegex.test(password);
    const upper = uppercaseRegex.test(password);
    const number = numberRegex.test(password);
    const special = specialCharsRegex.test(password);

    return lower + upper + number + special >= 3;
  };

  useEffect(() => {
    if (partner) {
      localStorage.setItem('partnerURL', partner);
      if (signupStyles[partner] === undefined) {
        setSignupStyle(signupStyles.ascend);
        return;
      }
      setSignupStyle(signupStyles[partner]);
    } else {
      setSignupStyle(signupStyles.ascend);
    }

    if (searchParameters.get('code')) {
      localStorage.setItem('invitationCode', searchParameters.get('code'));
    }
  }, [partner, searchParameters]);

  useEffect(() => {
    dispatch({type: types.LOGIN_ERROR, payload: null});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['email', 'password'];

    requiredFields.forEach((field) => {
      if (!values[field] || values[field] === '') {
        errors[field] = 'Required';
      }

      if (
        !lengthRegex.test(values.password) ||
        !matchesThreeRules(values.password)
      ) {
        errors.password = 'Password does not meet requirements';
      }
    });

    return errors;
  };

  const onSubmit = (data) => {
    dispatch(authActions.signupUser(null, data.email, data.password));
  };

  const handleGoogleLogin = () => {
    const webAuth = new auth0.WebAuth({
      domain: auth0Domain,
      clientID: auth0ClientId,
      redirectUri: window.location.origin + '/callback',
      responseType: 'token id_token',
      scope: 'openid email user_metadata app_metadata',
    });

    webAuth.authorize({connection: 'google-oauth2'});
  };

  return (
    <DocumentTitle title="Sign Up">
      <div className="wholepage" id="signup">
        <div
          className="bd-pagebody"
          style={{
            fontFamily: signupStyle.font,
            backgroundColor: signupStyle.backgroundColor,
            backgroundImage: `url(${signupStyle.backgroundImage})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <main id="main" tabIndex="-1">
            <div
              className={`container d-flex justify-content-center align-items-center`}
              style={{
                flexDirection: isMobile ? 'column' : 'row-reverse',
                height: isMobile ? 'initial' : '100vh',
              }}
            >
              {isMobile && (
                <>
                  {!partner && (
                    <h1
                      className="mt-2 pb-2"
                      style={{fontSize: '22px', color: signupStyle.fontColor}}
                    >
                      Welcome to the Ascend Network!
                    </h1>
                  )}
                  {partner && (
                    <>
                      <img
                        style={signupStyle.logoMobileStyle}
                        src={signupStyle.logo}
                        height={signupStyle.mobileLogoHeight}
                        alt={`${partner} logo`}
                      />
                      <div className="nowrap d-flex justify-content-center align-items-center mb-4">
                        <p
                          className="mb-0 me-3"
                          style={{
                            color: signupStyle.fontColor,
                            fontSize: '12px',
                          }}
                        >
                          In partnership with
                        </p>{' '}
                        <img
                          src={signupStyle.ascendLogo}
                          height={29}
                          alt="ascend logo"
                        />
                      </div>
                    </>
                  )}
                  <p
                    className="pb-2 text-align-center"
                    style={{color: signupStyle.fontColor}}
                    dangerouslySetInnerHTML={{__html: signupStyle.subText}}
                  />
                  {Object.keys(signupStyle).length > 0 && (
                    <Swiper
                      className="mb-0"
                      style={{
                        '--swiper-navigation-size': '33px',
                        '--swiper-navigation-sides-offset': '15px',
                        '--swiper-pagination-bullet-inactive-color':
                          signupStyle.dotInactiveColor,
                        '--swiper-pagination-bullet-opacity': 1,
                        '--swiper-pagination-color': signupStyle.dotColor,
                        '--swiper-pagination-bullet-size': '12px',
                        '--swiper-pagination-bullet-inactive-opacity': 1,
                        '--swiper-pagination-bottom': '15px',
                        '--swiper-navigation-top-offset': '37%',
                      }}
                      tabIndex={isMobile ? -1 : 0}
                      cssMode={true}
                      modules={[Navigation, A11y, Pagination]}
                      navigation={true}
                      pagination={true}
                      loop={true}
                      centeredSlides={true}
                      spaceBetween={30}
                    >
                      {signupStyle.cardContent.map((content, index) => {
                        return (
                          <SwiperSlide
                            className="bd-whitebackground rounded"
                            key={index}
                          >
                            <h2
                              style={{
                                fontSize: '16px',
                                color: 'var(--neutral-gray-1)',
                              }}
                              className="mb-3 mt-4"
                            >
                              {content.title}
                            </h2>
                            <p className="ps-5 pe-5">{content.body}</p>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  )}
                </>
              )}
              {!isMobile && (
                <div style={{paddingLeft: '6rem', textAlign: 'center'}}>
                  {!partner && (
                    <h1
                      className="mt-2 pb-2"
                      style={{fontSize: '22px', color: signupStyle.fontColor}}
                    >
                      Welcome to the Ascend Network!
                    </h1>
                  )}
                  {partner && (
                    <>
                      <img
                        className=" mb-4"
                        src={signupStyle.logo}
                        style={signupStyle?.logoDesktopStyle}
                        alt={`${partner} logo`}
                      />
                      <div className="nowrap d-flex justify-content-center align-items-center mb-4">
                        <p
                          className="mb-0 me-3"
                          style={{
                            color: signupStyle.fontColor,
                            fontSize: '12px',
                          }}
                        >
                          In partnership with
                        </p>{' '}
                        <img
                          src={signupStyle.ascendLogo}
                          height={29}
                          alt="ascend logo"
                        />
                      </div>
                    </>
                  )}
                  <p
                    className="pb-2 text-align-center"
                    style={{color: signupStyle.fontColor, maxWidth: '650px'}}
                    dangerouslySetInnerHTML={{__html: signupStyle.subText}}
                  />
                  {Object.keys(signupStyle).length > 0 && (
                    <p className="bold" style={{color: signupStyle.fontColor}}>
                      {signupStyle.cardContent[0].title}:
                    </p>
                  )}
                  <div className={styles.cardContainer}>
                    {Object.keys(signupStyle).length > 0 &&
                      signupStyle.cardContent.map((content, index) => {
                        return (
                          <div
                            className={`bd-whitebackground rounded ${styles.card}`}
                            key={index}
                          >
                            <span
                              className={styles.cardNumber}
                              style={{
                                backgroundColor: signupStyle.circleColor,
                              }}
                            >
                              {index + 1}
                            </span>
                            <p className="ms-3" style={{textAlign: 'left'}}>
                              {content.body}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              <div
                className="bd-whitebackground d-flex flex-column align-items-center rounded mb-2"
                style={{
                  width: isMobile ? '90vw' : '33vw',
                  height: 'fit-content',
                  maxWidth: '530px',
                  boxShadow: '0 5px 8px rgba(0, 0, 0, 0.15)',
                  padding: isMobile
                    ? '32px 32px 24px 32px'
                    : '48px 48px 24px 48px',
                  justifyContent: 'space-around',
                }}
              >
                <div className="text-align-center mb-4">
                  <img
                    className=" mb-4"
                    src={AscendNetworkLogo}
                    alt={`${partner ? partner : 'ascend'} logo`}
                  />
                  <h2 style={{fontSize: '32px'}}>Create Your Account</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: signupStyle.widgetSubText,
                    }}
                  />
                </div>
                <Form onSubmit={onSubmit} validate={validate}>
                  {({handleSubmit, form}) => {
                    return (
                      <form
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 'initial',
                          maxWidth: '350px',
                        }}
                      >
                        <Field
                          name="email"
                          component={TextField}
                          size={32}
                          maxLength={128}
                          label="Email"
                          labelCssClass={'login-label'}
                        />

                        <Field
                          name="password"
                          label="Password"
                          showLabel={true}
                          showReset={false}
                          component={FormPasswordInput}
                          labelCssClass={'login-label'}
                          onFocus={() => {
                            setShowPassword(true);
                          }}
                        />

                        {showPassword && (
                          <div
                            style={{fontSize: '12px'}}
                            className="d-flex flex-column align-self-center mt-2"
                          >
                            <p
                              className={
                                form.getFieldState('password')?.value &&
                                form
                                  .getFieldState('password')
                                  ?.value.match(lengthRegex)
                                  ? styles.passed
                                  : styles.failed
                              }
                            >
                              At least 8 characters in length
                            </p>
                            <p
                              className={
                                form.getFieldState('password')?.value &&
                                matchesThreeRules(
                                  form.getFieldState('password')?.value
                                )
                                  ? styles.passed
                                  : styles.failed
                              }
                            >
                              Contain at least 3 of the following 4 types of
                              characters:
                            </p>
                            <ul>
                              {rules.map((rule, index) => {
                                const cn =
                                  form.getFieldState('password')?.value &&
                                  form
                                    .getFieldState('password')
                                    ?.value.match(rule.pattern)
                                    ? styles.passed
                                    : '';
                                return (
                                  <li key={index} className={`ms-2 ${cn}`}>
                                    {rule.label}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}

                        {loginError && (
                          <div className="form-input-error">{loginError}</div>
                        )}

                        <BusyButton
                          className={`btn ${
                            styles[signupStyle.buttonClass]
                          } mt-2`}
                          onClick={handleSubmit}
                          buttonText={'Sign Up >'}
                          busyText={'Sign Up >'}
                        />
                      </form>
                    );
                  }}
                </Form>
                <div className={`${styles.or} mt-3 mb-3 bold`}>or</div>
                <button
                  className={`${styles.socialLogin} btn`}
                  onClick={handleGoogleLogin}
                >
                  <Google height={16} width={16} /> &nbsp;&nbsp; Sign Up with
                  Google
                </button>
                <div
                  className="text-align-center mt-3"
                  style={{fontSize: '14px'}}
                >
                  <p>
                    Already have an account?{' '}
                    <Link className="underline bold" to={'/login'}>
                      Log in
                    </Link>
                    .
                  </p>
                </div>
                <p style={{fontSize: '13px'}}>
                  © {new Date().getFullYear()} Ascend Indiana. All rights
                  reserved.
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DocumentTitle>
  );
}
