import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ButtonGroupField extends React.Component {
  render() {
    const {
      input: {value, onChange},
      meta: {touched, error, warning},
      options,
      disabled = false,
    } = this.props;
    return (
      <div>
        <div className="btn-group" role="group">
          {options.map((opt, i) => (
            <button
              key={opt}
              type="button"
              disabled={disabled}
              className={classNames(
                'clickable',
                'btn',
                {'btn-white': value !== opt},
                {'btn-lightblue': value === opt}
              )}
              onClick={() => onChange(opt)}
            >
              {opt}
            </button>
          ))}
        </div>
        <div>
          {touched &&
            ((error && <div className="form-input-error">{error}</div>) ||
              (warning && (
                <div className="form-input-error">{warning}</div>
              )) || <div className="form-input-error">&nbsp;</div>)}
          {!touched && <div className="form-input-error">&nbsp;</div>}
        </div>
      </div>
    );
  }
}

ButtonGroupField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

export default ButtonGroupField;
