import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import {connect} from 'react-redux';

import {PageHeader, PageFooter} from 'src/layout';
import ReportsTable from 'src/components/ReportsTable';

import * as institutionsActions from 'src/actions/institutions';
import {has_permission, PERMISSIONS} from 'src/services/authorizationApi';

class UniversitiesPage extends React.Component {
  _isMounted = false;
  state = {
    canViewCandidatePII: false,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    has_permission(PERMISSIONS.JOB_SEEKER_PII).then((resp) => {
      if (!this._isMounted) return;
      this.setState({canViewCandidatePII: resp.data.hasPermission});
    });

    this.props.getInstitutionReports();
  }

  render() {
    const {reports, institutionReportsLoading} = this.props;
    return (
      <DocumentTitle title="Universities Reports">
        <div className="wholepage">
          <PageHeader title="Administration" showAdminLinks={true} />

          <div className="bd-pagebody">
            <main id="main" tabIndex="-1">
              <div className="container">
                <br />
                {this.props.reports.length > 0 &&
                  !this.state.canViewCandidatePII && (
                    <h2>You do not have permission to view Candidate PII</h2>
                  )}
                {this.state.canViewCandidatePII && (
                  <div className="padding-bottom-sixteen">
                    <ReportsTable
                      title="University Candidate Reports"
                      reports={reports}
                      itemsLoading={institutionReportsLoading}
                    />
                  </div>
                )}
              </div>
            </main>
          </div>
          <PageFooter />
        </div>
      </DocumentTitle>
    );
  }
}

UniversitiesPage.propTypes = {
  reports: PropTypes.array.isRequired,
  getInstitutionReports: PropTypes.func.isRequired,
  institutionReportsLoading: PropTypes.bool,
};

const actions = {
  ...institutionsActions,
};

function mapStateToProps(state, ownProps) {
  return {
    reports: state.institutions.reports,
    institutionReportsLoading: state.institutions.institutionReportsLoading,
  };
}

export default connect(mapStateToProps, actions)(UniversitiesPage);
