import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import * as institutionsActions from 'src/actions/institutions';

import * as Layout from '../layout';
import {TextField} from 'src/formFields';
import _ from 'lodash';
import ChangeEmailModal from 'src/components/profile/ChangeEmailModal';
import {BusyButton} from './BusyButton';

const isPhone = (number) => {
  return number.replace(/\D/g, '').length === 10;
};

const requiredFields = ['phone'];

const validate = (values) => {
  const errors = {};

  if (values.phone && !isPhone(values.phone)) {
    errors.phone = 'Valid US phone number required';
  }

  _.forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

class DetailsModal extends React.Component {
  state = {
    changeEmailModalIsOpen: false,
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const {isOpen, onClose, profile} = this.props;

    const getEmailField = () => {
      if (profile.role === 'JOB_SEEKER') {
        return (
          <Field
            name="email"
            component={TextField}
            disabled={true}
            size={32}
            maxLength={128}
            label="Email"
            labelActionText={'Update Email'}
            labelActionCallBack={() => {
              this.setState({changeEmailModalIsOpen: true});
            }}
            parse={(value) => value}
          />
        );
      } else {
        return (
          <Field
            name="email"
            component={TextField}
            disabled={true}
            size={32}
            maxLength={128}
            label="Email"
            parse={(value) => value}
          />
        );
      }
    };

    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            keepDirtyOnReinitialize={true}
            initialValues={{
              url: this.props.seeker.url,
              phone: this.props.seeker.phone,
              linkedinProfile: this.props.seeker.linkedinProfile,
              email: this.props.seeker.email,
            }}
          >
            {({handleSubmit}) => {
              return (
                <form onKeyPress={this.onKeyPress}>
                  <div className="padding-32">
                    <center>
                      <div className="normal-title padding-bottom-eight bold">
                        Edit Details
                      </div>
                    </center>
                    <hr />

                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="url"
                          component={TextField}
                          label="Url"
                          size={32}
                          maxLength={128}
                          parse={(value) => value}
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="phone"
                          component={TextField}
                          size={32}
                          maxLength={128}
                          label="Mobile Phone"
                          parse={(value) => value}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="linkedinProfile"
                          component={TextField}
                          label="Linkedin"
                          size={32}
                          maxLength={128}
                          parse={(value) => value}
                        />
                      </div>
                      <div className="col-md-6">{getEmailField()}</div>
                      <div className="col-md-6" />
                    </div>
                  </div>
                  <div className="text-align-center nowrap">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-transparent me-4"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <BusyButton
                        onClick={
                          this.state.changeEmailModalIsOpen
                            ? (e) => e.preventDefault()
                            : handleSubmit
                        }
                        buttonText={'Save'}
                        busyText={'Saving'}
                        style={{width: '210px', height: '40px'}}
                        float={'float-right'}
                        buttonIcon={'save'}
                        alignText={'text-center'}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </Layout.AscendModalBody>
        <ChangeEmailModal
          isOpen={this.state.changeEmailModalIsOpen}
          onClose={() => {
            this.setState({changeEmailModalIsOpen: false});
          }}
          onCancel={() => {
            this.setState({changeEmailModalIsOpen: false});
          }}
        />
      </Layout.AscendModal>
    );
  }
}

DetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'clone']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  seeker: PropTypes.object,
  profile: PropTypes.object.isRequired,
};

const actions = {
  ...institutionsActions,
};

// Thank you to Ascend for the opportunity to work on such a rewarding project.
// For those that come after me, may you easily squash any bugs I have left behind.
// Here's to many great placements on the network---Andrew Kaczorek

let EditDetailsModal = (props) => <DetailsModal {...props} mode="edit" />;

const mapStateToProps = (state) => {
  return {
    initialValues: state.seekers.seekerForEdit,
    seeker: state.seekers.seekerForEdit,
    profile: state.profile,
  };
};

EditDetailsModal = connect(mapStateToProps, actions)(EditDetailsModal);

export {EditDetailsModal};
