import React, {useEffect} from 'react';
import DocumentTitle from 'src/components/vendor/DocumentTitle';
import {Field, Form} from 'react-final-form';
import {FormPasswordInput, TextField} from 'src/formFields';
import styles from './Login.module.scss';
import {useIsMobile} from 'src/utils/commonHooks';
import auth0 from 'auth0-js';
import {Link} from 'react-router-dom';
import {Google} from 'src/assets/svg/svgComponents';
import {useDispatch, useSelector} from 'react-redux';
import {actions as authActions} from 'src/reducers/authentication';
import {types} from 'src/reducers/authentication';
import Swoops from 'src/assets/images/Swoops_Background.svg';
import AscendNetworkLogo from 'src/assets/svg/icons/Ascend-Network-logo.svg';

export default function Login() {
  const dispatch = useDispatch();
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const isMobile = useIsMobile(990);
  let loginError = useSelector((state) => state.authentication.loginError);

  let emailChangePasswordSuccess = useSelector(
    (state) => state.authentication.emailChangePasswordSuccess
  );

  useEffect(() => {
    dispatch({type: types.LOGIN_ERROR, payload: null});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['email', 'password'];

    requiredFields.forEach((field) => {
      if (!values[field] || values[field] === '') {
        errors[field] = 'Required';
      }
    });

    return errors;
  };

  const onSubmit = (data) => {
    dispatch(authActions.loginUser(null, data.email, data.password));
  };

  const handleGoogleLogin = () => {
    const webAuth = new auth0.WebAuth({
      domain: auth0Domain,
      clientID: auth0ClientId,
      redirectUri: window.location.origin + '/callback',
      responseType: 'token id_token',
      scope: 'openid email user_metadata app_metadata',
    });

    webAuth.authorize({connection: 'google-oauth2'});
  };

  return (
    <DocumentTitle title="Login">
      <div className="wholepage">
        <div
          className="bd-pagebody"
          style={{
            fontFamily: 'Manrope',
            backgroundColor: '#F2F6F9',
            backgroundImage: `url(${Swoops})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <main id="main" tabIndex="-1">
            <div
              className="container d-flex justify-content-center align-items-center mt-2 mb-2"
              style={{height: isMobile ? 'initial' : '100vh'}}
            >
              <div
                className="bd-whitebackground d-flex flex-column align-items-center rounded ps-3 pe-3"
                style={{
                  width: isMobile ? '90vw' : '33vw',
                  height: 'fit-content',
                  maxWidth: '530px',
                  boxShadow: '0 5px 8px rgba(0, 0, 0, 0.15)',
                }}
              >
                <div className="text-align-center mb-4">
                  <img
                    className="mt-4 mb-4"
                    src={AscendNetworkLogo}
                    alt="ascend logo"
                  />
                  <h1 style={{fontSize: '32px'}}>Log In</h1>
                </div>

                {emailChangePasswordSuccess && (
                  <div
                    className={`${styles.emailChangePasswordSuccess} mb-3 p-3`}
                    style={{
                      width: isMobile ? '90vw' : '33vw',
                      maxWidth: '530px',
                    }}
                  >
                    We’ve just sent you an email to reset your password. If you
                    do not receive an email, please email{' '}
                    <a
                      style={{textDecoration: 'underline'}}
                      href="mailto:support@ascendindiana.com"
                    >
                      support@ascendindiana.com
                    </a>{' '}
                    with your name and we will respond to help you login.
                  </div>
                )}

                <Form onSubmit={onSubmit} validate={validate}>
                  {({handleSubmit}) => {
                    return (
                      <form
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: '350px',
                        }}
                      >
                        <Field
                          name="email"
                          component={TextField}
                          size={32}
                          maxLength={128}
                          label="Email"
                          labelCssClass={'login-label'}
                        />

                        <Field
                          name="password"
                          label="Password"
                          showLabel={true}
                          showReset={true}
                          component={FormPasswordInput}
                          labelCssClass={'login-label'}
                        />

                        {loginError && (
                          <div className="form-input-error">{loginError}</div>
                        )}

                        <button
                          className={`btn ${styles.btn_ascend} mt-2`}
                          onClick={handleSubmit}
                        >
                          Log In {'>'}
                        </button>
                      </form>
                    );
                  }}
                </Form>
                <div className={styles.or}>or</div>
                <button
                  className={`${styles.socialLogin} btn`}
                  onClick={handleGoogleLogin}
                >
                  <Google height={16} width={16} /> &nbsp;&nbsp; Log In with
                  Google
                </button>
                <div
                  className="text-align-center mt-3"
                  style={{fontSize: '14px'}}
                >
                  <p>
                    Job seekers{' '}
                    <Link className="underline" to={'/signup'}>
                      sign up here
                    </Link>
                    .
                  </p>
                  <p>
                    If you are having trouble logging in, please reach out to{' '}
                    <Link
                      className="underline"
                      target="_blank"
                      to="mailto:support@ascendindiana.com"
                    >
                      support@ascendindiana.com
                    </Link>{' '}
                    so we can assist you.
                  </p>
                  <p>
                    © {new Date().getFullYear()} Ascend Indiana. All rights
                    reserved. |{' '}
                    <Link
                      className="underline"
                      target="_blank"
                      to="https://ascendindiana.com/terms-of-service"
                    >
                      Terms of Service
                    </Link>{' '}
                    |{' '}
                    <Link
                      className="underline"
                      target="_blank"
                      to="https://ascendindiana.com/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DocumentTitle>
  );
}
