import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {ResumeField} from 'src/formFields';
import {connect} from 'react-redux';
import * as Layout from '../layout';
import _ from 'lodash';
import {
  actions as resumeActions,
  getSummaryForForm,
} from 'src/reducers/resumes';
import {BusyButton} from './BusyButton';
import resumeStatus from 'src/assets/images/resume_status.gif';

const requiredFields = ['resume'];

const validate = (values) => {
  const errors = {};

  _.forEach(requiredFields, (field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const ResumeStatusBar = (props) => (
  <img src={resumeStatus} alt="resume status bar" />
);

class ResumeUploadModal extends React.Component {
  state = {
    isUploading: false,
    isUploaded: false,
    uploadFailed: false,
    formValid: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {isUploaded} = this.state;

    if (isUploaded && !this.state.formValid) {
      this.setState({
        formValid: true,
      });
    }
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  handleUploadError = (error) => {
    this.setState({
      uploadFailed: true,
      isUploading: false,
      isUploaded: false,
      formValid: false,
    });
  };

  // If a resume has been uploaded, we need to start polling for parsed
  // info.
  handleUploadFinished = ({uuid}) => {
    this.setState({
      isUploading: false,
      isUploaded: true,
      uploadFailed: false,
      formValid: false,
    });
    this.props.pollGetStatus(uuid);
  };

  handleUploadStarted = () => {
    this.setState({
      isUploading: true,
      isUploaded: false,
      uploadFailed: false,
      formValid: false,
    });
  };

  render() {
    const {isOpen, onClose} = this.props;

    const {
      onSubmit,
      resumeIsParsing,
      resumeParsingTimedOut,
      isApprenticeshipCandidate,
    } = this.props;

    const maResumeFieldProps = {};
    if (isApprenticeshipCandidate) {
      maResumeFieldProps.fileTypes = new RegExp('.pdf$', 'i');
      maResumeFieldProps.fileTypesAccept = 'application/pdf';
    }

    const {isUploaded, isUploading, uploadFailed} = this.state;

    const renderStatus = () => {
      if (isUploading) {
        return (
          <div>
            Uploading resume...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (uploadFailed) {
        return <span>Upload failed. Please try a different file.</span>;
      } else if (isUploaded && resumeIsParsing) {
        return (
          <div>
            Secure upload in process ...
            <br />
            <ResumeStatusBar />
          </div>
        );
      } else if (isUploaded && resumeParsingTimedOut) {
        return <span>Could not extract results from resume.</span>;
      } else if (isUploaded) {
        return (
          <div>Upload Succeeded. Click "Save" to commit your changes.</div>
        );
      }
    };

    if (this.props.initialValues) {
      return (
        <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
          <Layout.AscendModalBody hasClose={true}>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              keepDirtyOnReinitialize={true}
              initialValues={{resume: this.props.initialValues.resume}}
            >
              {({handleSubmit}) => {
                return (
                  <form onKeyPress={this.onKeyPress}>
                    <div className="row">
                      <div className="col-12" style={{textAlign: 'center'}}>
                        <div className="row">
                          <div className="col-12">
                            <label className="normal-title padding-bottom-eight bold">
                              Resume Upload
                            </label>
                          </div>
                          <div className="col-12 disabled-body">
                            File size limited to 10MB
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 offset-3">
                            <hr />
                            <Field
                              name="resume"
                              component={ResumeField}
                              onStart={this.handleUploadStarted}
                              onError={this.handleUploadError}
                              onFinish={this.handleUploadFinished}
                              {...maResumeFieldProps}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="normal-subheader bold padding-bottom-sixteen"
                          style={{
                            margin: 'auto',
                            width: '75%',
                            textAlign: 'center',
                          }}
                        >
                          {renderStatus()}
                        </div>
                      </div>
                    </div>
                    <div className="text-align-center nowrap">
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-transparent me-4"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <BusyButton
                          onClick={handleSubmit}
                          buttonText={'Save'}
                          busyText={'Saving'}
                          style={{width: '210px', height: '40px'}}
                          float={'float-right'}
                          buttonIcon={'save'}
                          alignText={'text-center'}
                          disabled={!this.state.formValid}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </Layout.AscendModalBody>
        </Layout.AscendModal>
      );
    } else {
      return <div />;
    }
  }
}

ResumeUploadModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pollGetStatus: PropTypes.func.isRequired,
  resumeIsParsing: PropTypes.bool.isRequired,
  resumeParsingTimedOut: PropTypes.bool.isRequired,
  resumeSummary: PropTypes.object,
  isApprenticeshipCandidate: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
};

const actions = {
  ...resumeActions,
};

let EditResumeUploadModal = (props) => (
  <ResumeUploadModal {...props} mode="edit" />
);

const editMapStateToProps = (state) => {
  let isApprenticeProgram = false;
  if (state.seekers.seekerForEdit?.segments.length === 1) {
    isApprenticeProgram =
      state.seekers.seekerForEdit?.segments[0].isApprenticeProgram;
  }
  return {
    initialValues: {
      ...state.seekers.seekerForEdit,
      resume: {
        uuid: state.seekers.seekerForEdit.resumeUuid
          ? state.seekers.seekerForEdit.resumeUuid
          : null,
        uploadedFilename: state.seekers.seekerForEdit.resumeUploadedFilename
          ? state.seekers.seekerForEdit.resumeUploadedFilename
          : null,
      },
    },
    resumeIsParsing: state.resumes.isParsing,
    resumeParsingTimedOut: state.resumes.timedOut,
    resumeSummary: getSummaryForForm(state),
    isApprenticeshipCandidate: isApprenticeProgram,
  };
};

EditResumeUploadModal = connect(
  editMapStateToProps,
  actions
)(EditResumeUploadModal);

export {EditResumeUploadModal};
