import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {actions as authActions} from 'src/reducers/authentication';

import LoadingPage from 'src/pages/LoadingPage';

class RouteUserPage extends React.Component {
  componentDidMount() {
    if (!this.props.redirecting) {
      this.props.redirectByRole(this.props.profile);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.profile !== prevProps.profile &&
      this.props.redirecting === false
    ) {
      this.props.redirectByRole(this.props.profile);
    }
  }

  render() {
    return (
      <LoadingPage
        title="Redirecting home."
        message="Routing your browser to your home page."
      />
    );
  }
}

RouteUserPage.propTypes = {
  redirectByRole: PropTypes.func.isRequired,
  redirecting: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    redirecting: state.authentication.redirecting,
  };
};

const mapDispatchToProps = {
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteUserPage);
