import React from 'react';
import PropTypes from 'prop-types';
import * as Layout from 'src/layout';
import {TextAreaField} from 'src/formFields';
import {BusyButton} from './BusyButton';
import {Field, Form} from 'react-final-form';

class RecruiterSaysModal extends React.Component {
  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const {isOpen, onClose} = this.props;
    return (
      <Layout.AscendModal size="lg" isOpen={isOpen} onClose={onClose}>
        <Layout.AscendModalBody hasClose={true}>
          <div className="padding-32">
            <center>
              <div className="normal-title padding-bottom-eight bold">
                <h1>Their Recruiter Says</h1>
              </div>
            </center>
            <hr />
            <div className="disabled-body padding-bottom-sixteen"></div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  onSubmit={this.props.onSubmit}
                  keepDirtyOnReinitialize={true}
                  initialValues={{
                    highlightsNotes: this.props.highlightsNotes,
                  }}
                >
                  {({handleSubmit, form}) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex flex-column align-items-center text-center">
                          <span>
                            Share some highlights about this candidate.
                          </span>
                          <div
                            style={{
                              maxWidth: '500px',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                              textAlign: 'left',
                            }}
                          >
                            <Field
                              id="highlightsNotes"
                              name="highlightsNotes"
                              component={TextAreaField}
                              label=""
                              maxLength={500}
                              showCounter={true}
                            />
                          </div>
                        </div>

                        <div className="row justify-content-center nowrap">
                          <button
                            type="button"
                            className="btn btn-transparent col-2 me-4"
                            onClick={onClose}
                          >
                            Cancel
                          </button>

                          <BusyButton
                            buttonText={'Save'}
                            busyText={'Saving'}
                            className="col-2"
                            style={{width: '210px', height: '40px'}}
                            float={'float-right'}
                            buttonIcon={'save'}
                            alignText={'text-center'}
                            onClick={handleSubmit}
                            disabled={form.getState().dirty === false}
                          />
                        </div>
                      </form>
                    );
                  }}
                </Form>
              </div>
            </div>
          </div>
        </Layout.AscendModalBody>
      </Layout.AscendModal>
    );
  }
}

RecruiterSaysModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  highlightsNotes: PropTypes.any.isRequired,
};

export {RecruiterSaysModal};
