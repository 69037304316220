import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {withRouter} from '../../utils/commonHooks';

class CandidateSubMenu extends React.Component {
  render() {
    const defaultClass = 'candidate-profile-nav-link';
    const activeClass = 'candidate-profile-nav-link-selected';
    const pathname = window.location.pathname;

    return (
      <>
        <div className="row">
          <div className="col-12">&nbsp;</div>
        </div>
        <div role="navigation" aria-label="profile links">
          <NavLink
            to="/profile"
            className={
              (pathname === '/profile' ? activeClass : defaultClass) +
              ` navitem inlineblock `
            }
          >
            Profile
          </NavLink>
          <NavLink
            to="/favorites"
            className={
              (pathname === '/favorites' ? activeClass : defaultClass) +
              ` navitem inlineblock padding-left-thirtytwo`
            }
          >
            Favorites
          </NavLink>
          <NavLink
            to="/applications"
            className={
              (pathname === '/applications' ? activeClass : defaultClass) +
              ` navitem inlineblock  padding-left-thirtytwo`
            }
          >
            Applications
          </NavLink>
        </div>
        <div className="row">
          <div className="col-12" style={{paddingLeft: '0', paddingRight: '0'}}>
            <hr />
          </div>
        </div>
      </>
    );
  }
}

CandidateSubMenu.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(CandidateSubMenu);
