import React from 'react';
import styles from './CompanyPostJobs.module.scss';
import LoadingOverlay from 'react-loading-overlay';
import * as Layout from '../../../layout';
import {withRouter} from '../../../utils/commonHooks';
import {Field, Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import DocumentTitle from '../../../components/vendor/DocumentTitle';
import HavingTrouble from '../../../components/connectionForm/HavingTrouble';
import {AscendRadioField} from '../../../formFields/ascendRadioField/AscendRadioField';
import {CloseSvg} from '../../../assets/svg/svgComponents';

import {TextAreaField, TextField} from '../../../formFields';
import OutlineButton from '../../../components/controls/buttons/OutlineButton';
import FileDrop from '../../../components/FileDrop/FileDrop';
import axios from 'axios';
import PropTypes from 'prop-types';
import PreviousButton from 'src/components/PreviousButton';
import {
  isValidHttpsUrl,
  INVALID_URL_ERROR_MESSAGE,
} from 'src/utils/fieldHelper';
LoadingOverlay.propTypes = undefined;

class CompanyPostJobs extends React.Component {
  formRef = React.createRef();

  state = {
    submitType: '',
    showThankYou: false,
    showMissingFiles: false,
  };

  componentDidMount() {
    this.formRef.current.mutators.push('jobList', {
      url: '',
      salary: '',
      details: '',
    });
    this.setState({submitType: 'url'});
  }
  render() {
    const radioOptions = [
      {value: 'url', label: 'URL for the job description'},
      {value: 'pdf', label: 'PDF or Word Document'},
    ];
    const uploadJobs = async (job) => {
      await axios.request({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/employers/post_a_role`,
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: localStorage.getItem('id_token'),
        },
        data: job,
      });
    };
    const getJobList = () => {
      let fieldData = this.formRef?.current?.getState();
      return fieldData?.values?.jobList;
    };

    const doSubmit = (props) => {
      const jobList = getJobList();
      if (props.formType === 'pdf') {
        if (jobList.length === 0) {
          this.setState({showMissingFiles: true});
          return;
        }
      }

      const newJobs = jobList.map((jobInfo) => {
        return {
          jobUrl: props.formType === 'pdf' ? jobInfo.s3PublicUrl : jobInfo.url,
          salaryRange: jobInfo.salary,
          additionalDetails: jobInfo.details,
        };
      });

      uploadJobs({jobsToPost: newJobs});

      this.setState({
        showThankYou: true,
        submitType: 'url',

        showBusy: false,
      });
      this.formRef.current.reset();
    };

    const handleSubmitTypeChange = (props) => {
      this.formRef.current.reset();

      // Fields arrays are stored differently than fields cleared by reset()
      const rows = new Array(10);
      for (let i = 0; i < rows.length; i++) {
        rows[i] = i;
      }
      this.formRef.current.mutators.removeBatch('jobList', rows);

      if (props === 'url') {
        this.formRef.current.mutators.push('jobList', {
          url: '',
          salary: '',
          details: '',
        });
      }
      this.setState({
        submitType: props,
      });
    };

    const validateUrl = (value) => {
      if (!value) {
        return 'Required';
      }

      if (this.state.submitType === 'url') {
        if (!isValidHttpsUrl(value)) {
          return INVALID_URL_ERROR_MESSAGE;
        }
      }

      return undefined;
    };

    const buildDetails = () => {
      return (
        <FieldArray
          name="jobList"
          validateFields={['url']}
          validate={(value) =>
            value && value.length > 0 ? undefined : 'Required'
          }
        >
          {({fields}) => {
            return fields.map((name, index) => (
              <div key={name}>
                <button
                  type="button"
                  className={`ms-2 mb-1 ${styles.closeButton}`}
                  data-dismiss="alert"
                  aria-label={
                    this.state.submitType === 'url'
                      ? 'Remove Url'
                      : 'Remove File'
                  }
                  onClick={() => fields.remove(index)}
                >
                  <span
                    aria-hidden="true"
                    className="align-self-start"
                    style={{color: 'var(--neutral-gray-2) '}}
                  >
                    <CloseSvg />
                  </span>
                </button>

                <Field
                  name={`${name}.url`}
                  validate={validateUrl}
                  component={TextField}
                  label={
                    this.state.submitType === 'url'
                      ? `Full URL to job description #${index + 1}`
                      : `File #${index + 1}`
                  }
                  labelBold={true}
                  disabled={this.state.submitType === 'pdf'}
                  maxLength={2000}
                />
                <Field
                  name={`${name}.salary`}
                  component={TextField}
                  label="Salary range or wage (optional, but recommended)"
                  labelBold={true}
                />
                <Field
                  name={`${name}.details`}
                  component={TextAreaField}
                  label="Additional Details (optional)"
                  labelBold={true}
                  hideBorder={false}
                />

                <hr />
              </div>
            ));
          }}
        </FieldArray>
      );
    };

    const handleAddAnotherUrl = () => {
      this.formRef.current.mutators.push('jobList', {
        url: '',
        salary: '',
        details: '',
      });
    };

    const handlePostMoreRoles = () => {
      this.setState({
        showThankYou: false,
      });
      this.formRef.current.reset();
      handleAddAnotherUrl();
    };

    const handleAddFileSection = (props) => {
      let jobList = getJobList();

      if (jobList && jobList?.some((x) => x.fileName === props.fileName)) {
        return; // No duplicates
      }

      this.formRef.current.mutators.push('jobList', {
        url: props.fileName,
        salary: '',
        details: '',
        s3PublicUrl: props.s3PublicUrl,
        fileName: props.fileName,
      });
    };

    return (
      <DocumentTitle title="Post a Role to Ascend">
        <LoadingOverlay
          active={this.state.showBusy}
          spinner={true}
          text="Uploading Files ..."
          styles={{
            wrapper: {
              overflow: 'hidden',
            },
            overlay: (base) => ({
              ...base,
            }),
          }}
        >
          <div className="wholepage">
            <Layout.PageHeader />

            <main id="main" tabIndex="-1">
              <div className={styles.container}>
                <div className={styles.leftPanel}>
                  <PreviousButton />
                </div>
                <div className={styles.mainContent}>
                  {this.state.showThankYou === false && (
                    <div className={styles.title}>
                      Post a role on the Ascend Network
                      <div className="titleSubheader">
                        Once uploaded, roles will typically be live within 48
                        hours.
                      </div>
                    </div>
                  )}

                  <Form
                    onSubmit={doSubmit}
                    keepDirtyOnReinitialize={true}
                    destroyOnUnregister={true}
                    mutators={{
                      ...arrayMutators,

                      setValue: ([field, value], state, {changeValue}) => {
                        changeValue(state, field, () => value);
                      },
                    }}
                    initialValues={{formType: 'url'}}
                  >
                    {({
                      handleSubmit,
                      form: {
                        mutators: {push, pop},
                      },
                      form,
                      errors,
                      submitting,
                      pristine,
                      submitFailed,
                      values,
                    }) => {
                      this.formRef.current = form;
                      return (
                        <form onSubmit={handleSubmit}>
                          {this.state.showThankYou === false && (
                            <AscendRadioField
                              name="formType"
                              label="I have a :"
                              options={radioOptions}
                              radioHeight=""
                              radioMarginTop=""
                              labelCssClass={styles.radioLabel}
                              onChange={handleSubmitTypeChange}
                            />
                          )}

                          {this.state.submitType === 'pdf' &&
                            this.state.showThankYou === false && (
                              <>
                                <FileDrop
                                  fileReceived={false}
                                  handleS3File={handleAddFileSection}
                                  setUploadingStatus={(x) =>
                                    this.setState({showBusy: x})
                                  }
                                />
                                <div className="disabled-subheader p-2">
                                  Up to 10 files can be uploaded. If you have
                                  more than 10 files, please send them 10 at a
                                  time in separate form submissions.
                                </div>
                                <hr />

                                {buildDetails()}

                                {this.state.showMissingFiles && (
                                  <div className="form-input-error mb-3">
                                    No roles have been selected. Add at least
                                    one role to submit.
                                  </div>
                                )}
                                <div className="d-flex align-items-end justify-content-center">
                                  <button
                                    disabled={
                                      submitting ||
                                      pristine ||
                                      this.state.showMissingFiles
                                    }
                                    type="submit"
                                    className={`ms-2 btn btn-primary ${styles.navigationButton}`}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </>
                            )}

                          {this.state.showThankYou === false &&
                            this.state.submitType === 'url' && (
                              <>
                                {buildDetails()}

                                <div className="d-flex align-items-end justify-content-center ">
                                  <OutlineButton
                                    buttonText="Add Another"
                                    className={styles.addAnotherButton}
                                    onClick={handleAddAnotherUrl}
                                  />

                                  <button
                                    disabled={submitting || pristine}
                                    type="submit"
                                    className={`ms-2 btn btn-primary ${styles.navigationButton}`}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </>
                            )}

                          {this.state.showThankYou === true && (
                            <div>
                              <div className={styles.title}>
                                Thank you for your submission!
                              </div>
                              <div className="disabled-subheader text-align-center">
                                Your role(s) should be published within 48
                                hours. If you have questions, reach out to us at{' '}
                                <a href="mailto:support@ascendindiana.com">
                                  support@ascendindiana.com
                                </a>
                                .
                              </div>
                              <br />

                              <div className="disabled-subheader text-align-center">
                                Have feedback for us? Use the Feedback form
                                found at the bottom of the page.
                              </div>
                              <br />
                              <br />
                              <div className="d-flex align-items-end justify-content-center ">
                                <OutlineButton
                                  buttonText="Post More Roles"
                                  className={styles.postMoreRolesButton}
                                  onClick={handlePostMoreRoles}
                                />

                                <button
                                  onClick={() =>
                                    this.props.history.push('/find_candidates/')
                                  }
                                  type="submit"
                                  className={`ms-2 btn btn-primary ${styles.thankYouButtons}`}
                                >
                                  Explore Candidates
                                </button>
                              </div>
                            </div>
                          )}

                          <div style={{marginBottom: '100px'}}>
                            <HavingTrouble
                              className={
                                'row justify-content-center text-align-bottom-regularText'
                              }
                            />
                          </div>
                        </form>
                      );
                    }}
                  </Form>
                </div>
              </div>
            </main>

            <div
              style={{
                position: 'fixed',
                bottom: '0',
                width: '100%',
              }}
            >
              <Layout.PageFooter />
            </div>
          </div>
        </LoadingOverlay>
      </DocumentTitle>
    );
  }
}

CompanyPostJobs.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(CompanyPostJobs);
