import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import * as flashActions from '../actions/flash';

import Avatar from '../components/Avatar';
import noavatar from '../assets/images/noavatar.svg';
import ImageCropperModal from 'src/components/ImageCropper/ImageCropperModal';

class RoundAvatarField extends React.Component {
  state = {image: null, isCropModalOpen: false};
  refUploader = React.createRef();

  onUploadFinish = (avatarUrl) => {
    this.props.addFlashMessage('Avatar uploaded.', 'notification');
    this.props.input.onChange(avatarUrl);
    this.setState({isCropModalOpen: false});
  };

  onClose = () => {
    this.setState({isCropModalOpen: false});
  };

  render() {
    const {
      input: {value},
    } = this.props;
    return (
      <>
        <ImageCropperModal
          isOpen={this.state.isCropModalOpen}
          onClose={this.onClose}
          onSubmit={this.onUploadFinish}
          previousImage={value}
        />

        <div
          className="padding-bottom-thirtytwo"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            tabIndex={0}
            role="button"
            aria-label="Upload a Photo"
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter') {
                this.setState({isCropModalOpen: true});
              }
            }}
            onClick={() => this.setState({isCropModalOpen: true})}
          >
            <div className="col-md-4">
              <Avatar
                style={{backgroundColor: '#ebebeb', borderRadius: '50%'}}
                height={89}
                width={89}
                url={value ? value : noavatar}
              />
            </div>
          </span>

          <div>
            <div className="normal-subheader bold padding-bottom-eight">
              Upload Photo (optional)
            </div>
            <div className="disabled-caption padding-bottom-twelve">
              Image should be less than 10MB
            </div>
          </div>
        </div>
      </>
    );
  }
}

RoundAvatarField.propTypes = {
  input: PropTypes.object,
  addFlashMessage: PropTypes.func.isRequired,
};

const actions = {
  ...flashActions,
};

export default connect(null, actions)(RoundAvatarField);
