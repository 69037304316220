import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import aboutUs from 'src/assets/images/AboutUs-Icons-3-02.svg';
import {useIsMobile} from 'src/utils/commonHooks';
import {useReward} from 'react-rewards';

export default function ReportConnectionConfirmation(props) {
  const {roleAttributed, connectionSaveFailed} = props;

  const isMobile = useIsMobile(991);

  const {reward} = useReward(`connectionReward`, 'confetti', {
    startVelocity: 10,
    spread: 200,
    lifetime: 400,
  });

  useEffect(() => {
    if (roleAttributed && !connectionSaveFailed) {
      reward();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleAttributed, connectionSaveFailed]);

  return (
    <>
      <div className={isMobile ? '' : 'row'}>
        <div
          className="col text-align-center align-self-center"
          aria-label="An error occurred"
        >
          {connectionSaveFailed && (
            <div className="secondary-body padding-bottom-twentyfour">
              An error occurred while saving the connection.
            </div>
          )}

          {roleAttributed && !connectionSaveFailed && (
            <div className="col align-items-center text-align-center">
              <img src={aboutUs} alt="" />
            </div>
          )}

          {roleAttributed && !connectionSaveFailed && (
            <>
              <div
                className="normal-title padding-bottom-eight bold"
                aria-label="Great work"
                id={`connectionReward`}
              >
                Great work!
              </div>

              <div className="secondary-body padding-bottom-twentyfour">
                Congrats on finding your next opportunity
              </div>
              <div className="secondary-body padding-bottom-twentyfour">
                You may now close the page
              </div>
            </>
          )}

          {!roleAttributed && !connectionSaveFailed && (
            <>
              <div
                className="normal-title padding-bottom-eight bold"
                aria-label="Thanks for letting us know"
              >
                Thanks for letting us know!
              </div>

              <div className="secondary-body padding-bottom-twentyfour">
                This connection will not be tracked because you did not
                attribute securing your role to Ascend
              </div>
              <div className="secondary-body padding-bottom-twentyfour">
                You may now close the page
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

ReportConnectionConfirmation.propTypes = {
  roleAttributed: PropTypes.bool,
  connectionSaveFailed: PropTypes.bool.isRequired,
};
