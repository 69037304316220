import _ from 'lodash';
import * as types from './ActionTypes';
import {cleanForUpdate} from '../utils/seekerHelper';
import {makeRequest} from '../services/api';

const apiUrl = process.env.REACT_APP_API_URL;

export function validateConsentRequest(seekerId, completionCode) {
  const body = {completionCode: completionCode};
  return {
    types: [
      types.SEEKERS__VALIDATE_CONSENT_REQUEST,
      types.SEEKERS__VALIDATE_CONSENT_SUCCESS,
      types.SEEKERS__VALIDATE_CONSENT_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}/consent/validate`,
    method: 'POST',
    body: body,
    payload: {body},
  };
}

export function createSeeker(seeker) {
  return {
    types: [
      types.SEEKERS__CREATE_REQUEST,
      types.SEEKERS__CREATE_SUCCESS,
      types.SEEKERS__CREATE_FAILURE,
    ],
    uri: `${apiUrl}/seekers`,
    method: 'POST',
    body: seeker,
    payload: {seeker},
    isAuthenticated: false,
    successMessage: 'Information saved.',
    failureMessage: 'Error saving information.',
  };
}

export function createSeekerFeedback(seeker) {
  const body = {...seeker};
  delete body.id;

  return {
    types: [
      types.SEEKERS__CREATE_FEEDBACK_REQUEST,
      types.SEEKERS__CREATE_FEEDBACK_SUCCESS,
      types.SEEKERS__CREATE_FEEDBACK_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seeker.id}/feedback`,
    method: 'POST',
    body: body,
    payload: {seeker},
    isAuthenticated: false,
  };
}

export function getSeekers(filters = {}) {
  let uri = `${apiUrl}/seekers`;
  if ('accepted' in filters) {
    uri += '?accepted';
  }
  return {
    types: [
      types.SEEKERS__GET_ALL_REQUEST,
      types.SEEKERS__GET_ALL_SUCCESS,
      types.SEEKERS__GET_ALL_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getSeekerReports() {
  return {
    types: [
      types.SEEKERS__GET_REPORTS_REQUEST,
      types.SEEKERS__GET_REPORTS_SUCCESS,
      types.SEEKERS__GET_REPORTS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/reports`,
    method: 'GET',
  };
}

export function saveCandidateAdvancedIndividualFilters(selectedTab, payload) {
  return {
    type: types.CANDIDATE_ADVANCED_INDIVIDUAL_FILTER_SELECT,
    selectedTab: selectedTab,
    payload,
  };
}

export function saveCandidateSearchFilters(
  searchTerm,
  searchFilters,
  selectedTab
) {
  return {
    type: types.CANDIDATE_FILTER_SEARCHTERM_UPDATE,
    searchTerm: searchTerm,
    searchFilters: searchFilters,
    selectedTab: selectedTab,
  };
}

export function saveCandidateToggleButtonFilters(
  toggleButtons,
  toggleButtonFilters,
  selectedTab
) {
  return {
    type: types.CANDIDATE_FILTER_BUTTON_TOGGLE,
    toggleButtons: toggleButtons,
    toggleButtonFilters: toggleButtonFilters,
    selectedTab: selectedTab,
  };
}

export function saveCandidateClearFilters(selectedTab) {
  return {
    type: types.CANDIDATE_FILTERS_CLEAR,
    selectedTab: selectedTab,
  };
}

export function saveCandidateAllFilters(allFilters, selectedTab) {
  return {
    type: types.CANDIDATE_UPDATE_ALLFILTERS,
    allFilters: allFilters,
    selectedTab: selectedTab,
  };
}

export function saveCandidateSelectedTab(selectedTab) {
  return {
    type: types.CANDIDATE_CHANGE_TAB,
    selectedTab: selectedTab,
  };
}

export function getLightSeekersAdvanced(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  candidateFilters = [],
  tabName = 'active'
) {
  let uri = `${apiUrl}/seekers/light`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  _.forEach(candidateFilters, (e) => {
    p.push(e);
  });

  p.push('includeUnPublished');

  p = p.filter((p1) => p1 !== '' && p1 !== null);
  if (p.length > 0) {
    uri += '?' + p.join('&');
  }

  return {
    types: [
      types.SEEKERS__GET_ALL_ADVANCED_REQUEST,
      types.SEEKERS__GET_ALL_ADVANCED_SUCCESS,
      types.SEEKERS__GET_ALL_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    payload: {
      tabName: tabName,
    },
  };
}

export function getSeekerCountForFilters(candidateFilters = []) {
  let uri = `${apiUrl}/seekers/filter_counts`;
  let parameters = candidateFilters;

  uri += '?' + parameters.filter((p) => p !== '' && p !== null).join('&');

  return {
    types: [
      types.SEEKERS__GET_FILTER_COUNTS_REQUEST,
      types.SEEKERS__GET_FILTER_COUNTS_SUCCESS,
      types.SEEKERS__GET_FILTER_COUNTS_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function getSeekersAdvanced(
  page = 0,
  itemsPerPage = 25,
  sortTerm = '',
  filters = {},
  candidateFilters = [],
  includeUnPublished = false,
  isActiveTab = true,
  watchingEmployerId = ''
) {
  let uri = `${apiUrl}/seekers`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }

  if (watchingEmployerId !== '')
    p.push('watchingEmployerId=' + watchingEmployerId);

  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });
  _.forEach(candidateFilters, (e) => {
    p.push(e);
  });

  if (includeUnPublished === true) {
    p.push('includeUnPublished');
  }

  p.push('filterOnExperience');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  if (watchingEmployerId !== '') {
    return {
      types: [
        types.SEEKERS__GET_SAVED_CANDIDATES_REQUEST,
        types.SEEKERS__GET_SAVED_CANDIDATES_SUCCESS,
        types.SEEKERS__GET_SAVED_CANDIDATES_FAILURE,
      ],
      uri: uri,
      method: 'GET',
      payload: {
        watchingEmployerId: watchingEmployerId,
      },
    };
  }

  return {
    types: [
      types.SEEKERS__GET_ALL_ADVANCED_REQUEST,
      types.SEEKERS__GET_ALL_ADVANCED_SUCCESS,
      types.SEEKERS__GET_ALL_ADVANCED_FAILURE,
    ],
    uri: uri,
    method: 'GET',
    payload: {
      isActiveTab: isActiveTab,
    },
  };
}

export function getSeeker(seekerId) {
  return {
    types: [
      types.SEEKERS__GET_REQUEST,
      types.SEEKERS__GET_SUCCESS,
      types.SEEKERS__GET_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}`,
    method: 'GET',
    payload: {seekerId},
  };
}

export function getSeekerForEdit(seekerId) {
  return {
    types: [
      types.SEEKERS__GET_FOR_EDIT_REQUEST,
      types.SEEKERS__GET_FOR_EDIT_SUCCESS,
      types.SEEKERS__GET_FOR_EDIT_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}`,
    method: 'GET',
    payload: {seekerId},
  };
}

export function getSeekersStats(startDate = '', endDate = '') {
  let queryString = `${startDate !== '' ? '?startDate=' + startDate : ''}${
    endDate !== '' ? '&endDate=' + endDate : ''
  }`;

  return {
    types: [
      types.SEEKERS__GET_STATS_REQUEST,
      types.SEEKERS__GET_STATS_SUCCESS,
      types.SEEKERS__GET_STATS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/stats${queryString}`,
    method: 'GET',
  };
}

export function getMySeekersStats(
  startDate = '',
  endDate = '',
  ascendRecruiterId = -1
) {
  let queryString = `${startDate !== '' ? '?startDate=' + startDate : ''}${
    endDate !== '' ? '&endDate=' + endDate : ''
  }`;

  if (startDate === '' && ascendRecruiterId > -1) {
    queryString = `${'?ascendRecruiterId=' + ascendRecruiterId}`;
  }

  if (startDate !== '' && ascendRecruiterId > -1) {
    queryString += `${'&ascendRecruiterId=' + ascendRecruiterId}`;
  }

  queryString = queryString.replace(/\s/g, '');

  return {
    types: [
      types.SEEKERS__GET_MY_STATS_REQUEST,
      types.SEEKERS__GET_MY_STATS_SUCCESS,
      types.SEEKERS__GET_MY_STATS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/my-stats${queryString}`,
    method: 'GET',
  };
}

export function getSeekerJobStats(seekerId) {
  return {
    types: [
      types.SEEKERS__GET_JOB_STATS_REQUEST,
      types.SEEKERS__GET_JOB_STATS_SUCCESS,
      types.SEEKERS__GET_JOB_STATS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}/job_stats`,
    method: 'GET',
  };
}

export function getSeekerTeamStats(seekerId) {
  return {
    types: [
      types.SEEKERS__GET_TEAM_STATS_REQUEST,
      types.SEEKERS__GET_TEAM_STATS_SUCCESS,
      types.SEEKERS__GET_TEAM_STATS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}/team_stats`,
    method: 'GET',
  };
}

export function getSurveyStats() {
  return {
    types: [
      types.SEEKERS__GET_SURVEY_STATS_REQUEST,
      types.SEEKERS__GET_SURVEY_STATS_SUCCESS,
      types.SEEKERS__GET_SURVEY_STATS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/surveystats`,
    method: 'GET',
  };
}

export function getPlacementStats() {
  return {
    types: [
      types.SEEKERS__GET_PLACEMENT_STATS_REQUEST,
      types.SEEKERS__GET_PLACEMENT_STATS_SUCCESS,
      types.SEEKERS__GET_PLACEMENT_STATS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/placementstats`,
    method: 'GET',
  };
}

export function getSeekerJobMatches(
  seekerId,
  page = 0,
  itemsPerPage = 10,
  sortTerm = '',
  filters = {}
) {
  let uri = `${apiUrl}/jobs`;
  let p = [];
  p.push('page=' + page);
  p.push('itemsPerPage=' + itemsPerPage);
  if (sortTerm !== '') {
    p.push('sort=' + sortTerm);
  }
  _.forEach(filters, (value, key) => {
    p.push(`${key}=${value}`);
  });

  p.push(`seekerId=${seekerId}`);

  p.push('includeMatchData');

  if (p.length > 0) {
    uri += '?' + p.join('&');
  }
  return {
    types: [
      types.SEEKERS__GET_JOB_MATCHES_REQUEST,
      types.SEEKERS__GET_JOB_MATCHES_SUCCESS,
      types.SEEKERS__GET_JOB_MATCHES_FAILURE,
    ],
    uri: uri,
    method: 'GET',
  };
}

export function updateSeeker(seeker, messages = {}) {
  let body = cleanForUpdate(seeker);
  const successMessage = messages.success || 'Candidate saved.';
  const failureMessage = messages.failure || 'Error saving candidate.';

  return {
    types: [
      types.SEEKERS__UPDATE_REQUEST_WITH_LOADING,
      types.SEEKERS__UPDATE_SUCCESS,
      types.SEEKERS__UPDATE_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seeker.id}`,
    method: 'PATCH',
    body: body,
    payload: {seeker},
    successMessage,
    failureMessage,
  };
}

export function finishSeeker(completionCode, seekerId) {
  return {
    types: [
      types.SEEKERS__FINISH_REQUEST,
      types.SEEKERS__FINISH_SUCCESS,
      types.SEEKERS__FINISH_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}/finish?code=${completionCode}`,
    method: 'GET',
    payload: {seekerId},
  };
}

export function completeSeeker(seeker, messages = {}) {
  let body = cleanForUpdate(seeker);
  const successMessage = messages.success || 'Candidate saved.';
  const failureMessage = messages.failure || 'Error saving candidate.';

  return {
    types: [
      types.SEEKERS__COMPLETE_REQUEST,
      types.SEEKERS__COMPLETE_SUCCESS,
      types.SEEKERS__COMPLETE_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seeker.id}/finish`,
    method: 'PATCH',
    body: body,
    payload: {seeker},
    successMessage,
    failureMessage,
  };
}

export function deleteSeeker(seekerId) {
  return {
    types: [
      types.SEEKERS__DELETE_REQUEST,
      types.SEEKERS__DELETE_SUCCESS,
      types.SEEKERS__DELETE_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}`,
    method: 'DELETE',
    payload: {seekerId},
    successMessage: 'Seeker deleted.',
    failureMessage: 'Error deleting seeker.',
  };
}

export function interviewTraitLevelChanged(trait, level) {
  return {
    type: types.SEEKERS__TRAIT_LEVEL_CHANGED,
    trait: trait,
    level: level,
  };
}

export function interviewTraitNoteChanged(trait, note) {
  return {type: types.SEEKERS__TRAIT_NOTE_CHANGED, trait: trait, note: note};
}

export function interviewAchievementNoteChanged(note) {
  return {type: types.SEEKERS__ACHIEVEMENT_NOTE_CHANGED, note: note};
}

export function interviewInterestsNoteChanged(note) {
  return {type: types.SEEKERS__INTERESTS_NOTE_CHANGED, note: note};
}

export function interviewHighlightsNoteChanged(note) {
  return {type: types.SEEKERS__HIGHLIGHTS__CHANGED, note: note};
}

export function interviewAchievementLevelChanged(level) {
  return {type: types.SEEKERS__ACHIEVEMENT_LEVEL_CHANGED, level: level};
}

export function interviewContactInfoChanged(data) {
  return {type: types.SEEKERS__CONTACT_INFO_CHANGED, data: data};
}

export function interviewEducationChanged(data) {
  return {type: types.SEEKERS__EDUCATION_CHANGED, data: data};
}

export function interviewSummaryChanged(data) {
  return {type: types.SEEKERS__SUMMARY_CHANGED, data: data};
}

export function interviewResumeChanged(data) {
  return {type: types.SEEKERS__RESUME_CHANGED, data: data};
}

export function interviewAvatarChanged(data) {
  return {type: types.SEEKERS__AVATAR_CHANGED, data: data};
}

export function interviewExperienceChanged(data) {
  return {type: types.SEEKERS__EXPERIENCE_CHANGED, data: data};
}

export function interviewSkillsChanged(data) {
  return {type: types.SEEKERS__SKILLS_CHANGED, data: data};
}

export async function getNotes(seekerId) {
  const response = await makeRequest({
    method: 'GET',
    path: `seekers/${seekerId}/notes`,
  });
  if (response.status === 200) {
    return response.data.seekerNotes;
  }
}

export async function addNote(seekerId, note) {
  const response = await makeRequest({
    method: 'POST',
    path: `seekers/${seekerId}/notes`,
    body: note,
  });
  if (response.status === 200) {
    return response.data;
  }
}

export async function editNote(noteId, note) {
  const response = await makeRequest({
    method: 'PATCH',
    path: `seekers/notes/${noteId}`,
    body: note,
  });
  if (response.status === 200) {
    return response.data;
  }
}

export async function deleteNote(noteId) {
  const response = await makeRequest({
    method: 'DELETE',
    path: `seekers/notes/${noteId}`,
  });
  if (response.status === 200) {
    return response.data;
  }
}

export function incrementSeekersListNoteCount(seekerId) {
  return (dispatch) => {
    dispatch({
      type: types.SEEKERS__INCREMENT_NOTE_COUNT,
      seekerId,
    });
  };
}

export function decrementSeekersListNoteCount(seekerId) {
  return (dispatch) => {
    dispatch({
      type: types.SEEKERS__DECREMENT_NOTE_COUNT,
      seekerId,
    });
  };
}

export function toggleSeekerSupportFlag(seekerId) {
  let path = `seekers/${seekerId}/toggle_support_flag`;

  makeRequest({
    method: 'POST',
    path: path,
    isAuthenticated: true,
    contentType: 'application/json',
  });

  return (dispatch) => {
    dispatch({
      type: types.SEEKERS__TOGGLE_SUPPORT_FLAG,
      seekerId,
    });
  };
}

export const updateMaSeekerViewedApprovedStatus = async (seekerId) => {
  makeRequest({
    method: 'POST',
    path: `seekers/ma_seeker_viewed_approved_status/${seekerId}`,
    body: {seekerId: seekerId},
    isAuthenticated: true,
  }).then((response) => {
    return response;
  });
};

export function seekerApprovalChanged(seeker) {
  return {type: types.SEEKERS__UPDATE_APPROVAL, seeker: seeker};
}

export function seekerAdminApprovalChanged(seeker) {
  return {type: types.SEEKERS__UPDATE_ADMIN_APPROVAL, seeker: seeker};
}

export function getSeekerInviteResponse(seekerId) {
  return {
    types: [
      types.SEEKERS__INVITE_RESPONSE_REQUEST,
      types.SEEKERS__INVITE_RESPONSE_SUCCESS,
      types.SEEKERS__INVITE_RESPONSE_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}/invitation_matches`,
    payload: {seekerId},
    method: 'GET',
  };
}

export function updateSeekerInviteList(seekers, jobs, duplicates = null) {
  let seekersToUpdate = [];

  if (duplicates) {
    seekersToUpdate = seekers.map((seeker) => {
      let jobsInvitedToApplyFor = jobs.filter(
        (job) => job.id !== duplicates.jobId
      );
      if (jobsInvitedToApplyFor.length > 0) {
        jobsInvitedToApplyFor = jobsInvitedToApplyFor.map((x) => ({
          ...x,
          inviteCreatedAt: new Date(),
        }));
        if (seeker.jobsInvitedToApplyFor) {
          seeker.jobsInvitedToApplyFor = [
            ...seeker.jobsInvitedToApplyFor,
            ...jobsInvitedToApplyFor,
          ];
        } else {
          seeker.jobsInvitedToApplyFor = [...jobsInvitedToApplyFor];
        }
      }
      return seeker;
    });
  } else {
    seekersToUpdate = seekers.map((seeker) => {
      let jobsInvitedToApplyFor = jobs;
      jobsInvitedToApplyFor = jobsInvitedToApplyFor.map((x) => ({
        ...x,
        inviteCreatedAt: new Date(),
      }));
      if (seeker.jobsInvitedToApplyFor) {
        seeker.jobsInvitedToApplyFor = [
          ...seeker.jobsInvitedToApplyFor,
          ...jobsInvitedToApplyFor,
        ];
      } else {
        seeker.jobsInvitedToApplyFor = [...jobsInvitedToApplyFor];
      }
      return seeker;
    });
  }

  return {
    type: types.SEEKERS__INVITE_LIST_UPDATED,
    seekersToUpdate: seekersToUpdate,
  };
}

export function updateSeekerForEdit(seekerForEdit) {
  return (dispatch) => {
    dispatch({
      type: types.SEEKERS__SEEKER_FOR_EDIT_CHANGED,
      seekerForEdit,
    });
  };
}

export function updateChangeEmailUser(changeEmailUser) {
  return (dispatch) => {
    dispatch({
      type: types.SEEKERS__CHANGE_EMAIL_USER_CHANGED,
      changeEmailUser,
    });
  };
}

export function optOutOfJob(jobId, seekerId) {
  return {
    types: [
      types.SEEKERS__OPT_OUT_REQUEST,
      types.SEEKERS__OPT_OUT_SUCCESS,
      types.SEEKERS__OPT_OUT_FAILURE,
    ],
    uri: `${apiUrl}/seekers/seeker_job_opt_out`,
    body: {jobId: jobId, seekerId: seekerId},
    payload: {jobId: jobId, seekerId: seekerId},
    isAuthenticated: true,
    method: 'POST',
  };
}

export function undoOptOutOfJob(jobId, seekerId) {
  return {
    types: [
      types.SEEKERS__UNDO_OPT_OUT_REQUEST,
      types.SEEKERS__UNDO_OPT_OUT_SUCCESS,
      types.SEEKERS__UNDO_OPT_OUT_FAILURE,
    ],
    uri: `${apiUrl}/seekers/undo_seeker_job_opt_out`,
    body: {jobId: jobId, seekerId: seekerId},
    payload: {jobId: jobId, seekerId: seekerId},
    isAuthenticated: true,
    method: 'POST',
  };
}

export function updateSavedCandidatesRequiresRefresh(requireRefresh = true) {
  return {
    type: types.SEEKERS__SAVED_CANDIDATES_REQUIRES_REFRESH,
    savedCandidatesRequiresRefresh: requireRefresh,
  };
}

export function setCandidateListFilterActive(active) {
  return (dispatch) => {
    dispatch({
      type: types.SEEKERS__CANDIDATE_LIST_FILTER_ACTIVE,
      active: active,
    });
  };
}

export function getSeekerApplications(seekerId) {
  return {
    types: [
      types.SEEKERS__GET_APPLICATIONS_REQUEST,
      types.SEEKERS__GET_APPLICATIONS_SUCCESS,
      types.SEEKERS__GET_APPLICATIONS_FAILURE,
    ],
    uri: `${apiUrl}/seekers/${seekerId}/applications`,
    payload: {seekerId},
    method: 'GET',
    isAuthenticated: false,
  };
}

export function getSeekerForConnection(seekerId) {
  return {
    types: [
      types.SEEKERS__GET_FOR_CONNECTION_REQUEST,
      types.SEEKERS__GET_FOR_CONNECTION_SUCCESS,
      types.SEEKERS__GET_FOR_CONNECTION_FAILURE,
    ],
    uri: `${apiUrl}/seekers/connections/${seekerId}`,
    isAuthenticated: false,
    payload: {seekerId},
    method: 'GET',
  };
}
