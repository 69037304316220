import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as Layout from 'src/layout';
import {BusyButton} from 'src/components/BusyButton';
import {Line} from 'rc-progress';

import CreateConnectionSelectSeeker from './CreateConnectionSelectSeeker';
import CreateConnectionIsNetworkRole from './CreateConnectionIsNetworkRole';
import CreateConnectionSelectRole from './CreateConnectionSelectRole';
import CreateConnectionAttributeRoleToNetwork from './CreateConnectionAttributeRoleToNetwork';
import CreateConnectionNotes from './CreateConnectionNotes';
import CreateConnectionSummary from './CreateConnectionSummary';
import CreateConnectionConfirmation from './CreateConnectionConfirmation';
import CreateConnectionAdditionalInformation from './CreateConnectionAdditionalInformation';
import * as roleSelectionTypeOptions from 'src/pages/admin/connections/CreateConnectionConstants';
import LoadingOverlay from 'react-loading-overlay';

import {
  createConnection,
  getSeekerConnectionFromTask,
  getRecentConnection,
} from 'src/actions/connections';
import * as ACTIONS from 'src/actions/ActionTypes.js';
import AreYouSureModal from 'src/components/AreYouSureModal';
import RecentlyReportedConnectionModal from './RecentlyReportedConnectionModal';
import {isValidPostalCode} from 'src/utils/fieldHelper';

export default function CreateConnectionModal(params) {
  const dispatch = useDispatch();
  const seekerParam = params.seeker;
  const task = params.task;
  const dismissTaskItem = params.dismissTaskItem;

  const EMPLOY_INDY_SEGMENT_ID = 2;

  const [confirmSeekerChangeOpen, setConfirmSeekerChangeOpen] = useState(false);
  const [newSeeker, setNewSeeker] = useState(null);

  const [
    recentlyReportedConnectionModalOpen,
    setRecentlyReportedConnectionModalOpen,
  ] = useState(false);

  let currentStep = useSelector((state) => state.connections.currentStep);

  let percentageComplete = useSelector(
    (state) => state.connections.percentageComplete
  );

  let newConnection = useSelector((state) => state.connections.connection);

  let seekerName = useSelector(
    (state) => state.connections.connection.seekerDisplayName
  );

  let connectionsSaving = useSelector(
    (state) => state.connections.connectionsSaving
  );
  let connectionSaveFailed = useSelector(
    (state) => state.connections.connectionSaveFailed
  );

  let connectionReviewLoading = useSelector(
    (state) => state.connections.connectionReviewLoading
  );

  let recentConnectionLoading = useSelector(
    (state) => state.connections.recentConnectionLoading
  );

  let recentConnection = useSelector(
    (state) => state.connections.recentConnection
  );

  const recordConnectionChange = (changes) => {
    dispatch({
      type: ACTIONS.CONNECTIONS__UPDATE_STATE,
      data: changes,
    });
  };

  const collectAdditionalInformation = useMemo(() => {
    return (
      newConnection.seeker?.segments.findIndex(
        (x) => x.id === EMPLOY_INDY_SEGMENT_ID
      ) > -1
    );
  }, [newConnection.seeker]);

  const baseStep = {
    stepNumber: 0,
    component: {},
    args: {},
    headerText: seekerParam
      ? `Track a Connection${seekerName ? ` for ${seekerName}` : ''}`
      : 'Track a Connection',
    subHeaderText: 'Tell us more about this connection',
    isComplete: () => null,
    showBackButton: true,
    useInPercentageCalculation: false,
    isActive: true,
  };

  const steps = [
    {
      ...baseStep,
      stepNumber: 1,
      component: CreateConnectionSelectSeeker,
      args: {
        onChange: (e) => {
          // did they select anything?  really only need to check one field
          if (newConnection.isNetworkConnection !== null) {
            setNewSeeker(e);
            setConfirmSeekerChangeOpen(true);
          } else {
            recordConnectionChange({seeker: e});
          }
        },
        seeker: newConnection.seeker,
      },
      subHeaderText:
        'Choose a job seeker that you would like to track a connection for',
      showBackButton: false,
      isComplete: (c) => {
        return c.seeker !== null;
      },
      useInPercentageCalculation: true,
    },
    {
      ...baseStep,
      stepNumber: 2,
      component: CreateConnectionIsNetworkRole,
      args: {
        onChange: (e) => {
          recordConnectionChange({isNetworkConnection: e});
        },
        isNetworkConnection: newConnection.isNetworkConnection,
      },
      showBackButton: seekerParam ? false : true,
      isComplete: (c) => {
        return c.isNetworkConnection !== null;
      },
      useInPercentageCalculation: true,
    },
    {
      ...baseStep,
      stepNumber: 3,
      component: CreateConnectionSelectRole,
      args: {
        onChange: (e) => {
          recordConnectionChange({role: e});
        },
        isNetworkConnection: newConnection.isNetworkConnection,
        role: newConnection.role,
        seeker: newConnection.seeker,
        isTask: task !== null,
      },
      isComplete: (c) => {
        if (
          c.role.selectionType === roleSelectionTypeOptions.MANUAL ||
          !c.isNetworkConnection
        ) {
          // manual must have company, title, type, zipcode (if not remote)
          return (
            c.role.employerName !== '' &&
            c.role.jobName !== '' &&
            c.role.employmentType !== null &&
            (c.role.isRemote === true || c.role.zipCode !== '')
          );
        }

        //selecting a role
        return c.role.jobId !== null && c.role.employmentType !== null;
      },
      useInPercentageCalculation: true,
    },
    {
      ...baseStep,
      stepNumber: 4,
      component: CreateConnectionAdditionalInformation,
      args: {
        onChange: (e) => {
          recordConnectionChange(e);
        },
        connection: newConnection,
      },
      isComplete: (c) => {
        return (
          c.willingToShareSuccessStory !== null &&
          isValidPostalCode(c.homePostalCode)
        );
      },
      useInPercentageCalculation: collectAdditionalInformation,
      isActive: collectAdditionalInformation,
    },
    {
      ...baseStep,
      stepNumber: 5,
      component: CreateConnectionAttributeRoleToNetwork,
      args: {
        onChange: (e) => {
          recordConnectionChange({roleAttributed: e});
        },

        roleAttributed: newConnection.roleAttributed,
        isNetworkConnection: newConnection.isNetworkConnection,
      },
      isComplete: (c) => {
        return (
          c.roleAttributed['isAttributed'] !== null &&
          (!c.roleAttributed['requiresOther'] ||
            c.roleAttributed['otherText'] !== null)
        );
      },
      useInPercentageCalculation: true,
    },

    {
      ...baseStep,
      stepNumber: 6,
      component: CreateConnectionNotes,
      args: {
        notes: newConnection.notes,
        notesViewed: newConnection.notesViewed,

        onChange: (e) => {
          recordConnectionChange({notes: e});
        },
        onView: (e) => {
          recordConnectionChange({notesViewed: e});
        },
      },
      subHeaderText: (
        <>
          Add notes to this connection.
          <br />
          Notes submitted here will be saved to the Candidate’s profile notes.
        </>
      ),
      useInPercentageCalculation: true,
      isComplete: (c) => c.notesViewed,
    },
    {
      ...baseStep,
      stepNumber: 7,
      component: CreateConnectionSummary,
      args: {
        connection: newConnection,
      },
      headerText: 'Summary',
      subHeaderText: `Submitting a connection report for ${seekerName}`,
    },
    {
      ...baseStep,
      stepNumber: 8,
      component: CreateConnectionConfirmation,
      args: {
        roleAttributed: newConnection.roleAttributed,
        seeker: newConnection.seeker,
        // eslint-disable-next-line no-use-before-define
        onClose: () => closeForm(),
        connectionSaveFailed: connectionSaveFailed,
      },
      showBackButton: false,
    },
  ];

  const resetForm = () => {
    dispatch({
      type: ACTIONS.SEEKERS__GET_APPLICATIONS_RESET,
    });

    dispatch({
      type: ACTIONS.CONNECTIONS__CREATE_RESET,
      step: steps[0],
    });
  };

  const closeForm = () => {
    resetForm();
    params.onClose();
  };

  const handleSelectSeeker = () => {
    resetForm();
    recordConnectionChange({seeker: newSeeker});
    setNewSeeker(null);
    setConfirmSeekerChangeOpen(false);
  };

  const calculatePercentageComplete = () => {
    if (!currentStep) {
      return 0;
    }
    let totalSteps = steps.filter(
      (step) => step.useInPercentageCalculation && step.isActive
    ).length;

    let stepsComplete = steps.filter(
      (step) =>
        step.useInPercentageCalculation &&
        step.isActive &&
        step.isComplete(newConnection) === true
    ).length;

    if (seekerParam) {
      totalSteps--;
      stepsComplete--;
    }

    return Math.round(100 * (stepsComplete / totalSteps));
  };

  const handleNextClick = () => {
    dispatch({
      type: ACTIONS.CONNECTIONS__UPDATE_CURRENT_STEP,
      step: steps.find(
        (step) => step.stepNumber > currentStep.stepNumber && step.isActive
      ),
      percentageComplete: calculatePercentageComplete(),
    });
  };

  const renderStep = () => {
    const step = steps.find((x) => x.stepNumber === currentStep?.stepNumber);

    if (step) {
      const args = step.args;
      return <step.component onSubmit={handleNextClick} {...args} />;
    }
  };

  useEffect(() => {
    let initialStepIndex = 0;
    if (seekerParam) {
      recordConnectionChange({seeker: seekerParam});
      initialStepIndex = 1;
    }

    dispatch({
      type: ACTIONS.CONNECTIONS__UPDATE_CURRENT_STEP,
      step: steps[initialStepIndex],
      percentageComplete: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seekerParam, newConnection.seeker]);

  useEffect(() => {
    if (task) {
      dispatch(getSeekerConnectionFromTask(task.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const renderBackButton = () => {
    if (currentStep?.showBackButton) {
      return (
        <button
          type="button"
          className="btn btn-secondary col-2 me-4"
          onClick={() => {
            dispatch({
              type: ACTIONS.CONNECTIONS__UPDATE_CURRENT_STEP,
              step: steps.findLast(
                (step) =>
                  step.stepNumber < currentStep.stepNumber && step.isActive
              ),
              percentageComplete: newConnection.percentageComplete,
            });
          }}
        >
          Back
        </button>
      );
    }
    return null;
  };

  const handleSubmit = () => {
    let location = `${newConnection.role.city || ''} ${
      newConnection.role.state?.label || ''
    }`.trim();

    const zipCode = (newConnection.role.zipCode || '').trim();

    if (zipCode.length > 0) {
      if (location.length > 0) {
        location += ',';
      }
      location += zipCode;
    }

    let roleAttributedText = `${newConnection.roleAttributed.label}${
      newConnection.roleAttributed.otherText
        ? `: ${newConnection.roleAttributed.otherText}`
        : ''
    }`;

    const submittedConnection = {
      seekerConnectionId: newConnection.seekerConnectionId,
      seekerPublicId: newConnection.seeker.id,
      seekerName: seekerName,
      employerId: newConnection.role.employerId,
      employerName: newConnection.role.employerId
        ? null
        : newConnection.role.employerName,
      jobId: newConnection.role.jobId,
      jobName: newConnection.role.jobId ? null : newConnection.role.jobName,
      employmentTypeId: newConnection.role.employmentType.value,
      networkEmployer: newConnection.role.employerId ? 1 : 0,
      networkJob: newConnection.role.jobId ? 1 : 0,
      networkSeeker: 1,
      placementDate: new Date(),
      offerRescinded: 0,
      ascendRecruiterId: newConnection.seeker.ascendRecruiterId,
      location: location.trim(),
      isAttributedToNetwork: newConnection.roleAttributed.isAttributed,
      isAttributedToNetworkReason: roleAttributedText,
      seekerNotes: !newConnection.seekerNotes
        ? null
        : newConnection.seekerNotes,
      notes: !newConnection.notes ? null : newConnection.notes,
      willingToShareSuccessStory: newConnection.willingToShareSuccessStory,
      startDate: newConnection.startDate,
      startingSalary: newConnection.startingSalary,
      homePostalCode: newConnection.homePostalCode,
    };

    dispatch(createConnection(submittedConnection));
  };

  useEffect(() => {
    if (
      currentStep?.stepNumber === steps.length - 1 &&
      connectionSaveFailed === false &&
      connectionsSaving === false
    ) {
      dispatch({
        type: ACTIONS.CONNECTIONS__UPDATE_CURRENT_STEP,
        step: steps[steps.length - 1],
        percentageComplete: calculatePercentageComplete(),
      });

      if (task) {
        dismissTaskItem(task);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionSaveFailed, connectionsSaving, dismissTaskItem]);

  useEffect(() => {
    if (newConnection.seeker?.id) {
      dispatch(getRecentConnection(newConnection.seeker?.id));
    }
  }, [newConnection.seeker?.id, dispatch]);

  useEffect(() => {
    if (recentConnection) {
      setRecentlyReportedConnectionModalOpen(true);
    }
  }, [recentConnection]);

  return (
    <Layout.AscendModal size="lg" isOpen={params.isOpen} onClose={closeForm}>
      <Layout.AscendModalBody hasClose={true}>
        <LoadingOverlay
          role="status"
          active={
            connectionsSaving ||
            connectionReviewLoading ||
            recentConnectionLoading
          }
          spinner={true}
          text={connectionsSaving ? 'Saving...' : 'Loading...'}
          fadeSpeed={200}
          styles={{
            overlay: (base) => ({
              ...base,
              position: 'fixed',
            }),
          }}
        >
          <div className="bd-graybackground pt-1 pb-1 ps-5 pe-5">
            {(currentStep?.stepNumber < steps.length || connectionsSaving) && (
              <center>
                <div className="normal-title padding-bottom-eight bold">
                  {connectionsSaving && <>Summary</>}
                  {!connectionsSaving && <>{currentStep?.headerText}</>}
                </div>

                <div className="secondary-body text-align-center padding-bottom-twentyfour">
                  {connectionsSaving && (
                    <>Submitting a connection report for {seekerName}</>
                  )}
                  {!connectionsSaving && <>{currentStep?.subHeaderText}</>}
                </div>
                {!task && (
                  <>
                    <div className="text-align-right">
                      {percentageComplete}% Complete
                    </div>
                    <div>
                      <Line
                        percent={percentageComplete}
                        strokeColor="#275084"
                        trailColor="#cbe1ff"
                        strokeWidth={1}
                      />
                    </div>
                  </>
                )}
                <hr />
              </center>
            )}

            <div className="row bd-whitebackground pt-2">{renderStep()}</div>

            <br />
            <div className="row justify-content-center">
              {renderBackButton()}
              {currentStep?.stepNumber < steps.length - 1 && currentStep && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary col-2"
                    onClick={handleNextClick}
                    disabled={currentStep.isComplete(newConnection) === false}
                  >
                    Next
                  </button>
                </>
              )}
              {currentStep?.stepNumber === steps.length - 1 && (
                <BusyButton
                  onClick={handleSubmit}
                  buttonText={'Submit Feedback'}
                  busyText={'Submitting'}
                  className="col-3"
                  style={{width: '210px', height: '40px'}}
                  float={'float-right'}
                  buttonIcon={'save'}
                  alignText={'text-center'}
                  disabled={currentStep.isComplete(newConnection) === false}
                >
                  Submit Feedback
                </BusyButton>
              )}
            </div>
          </div>

          <AreYouSureModal
            areYouSureText={
              <>
                Are you sure you want to change the seeker?
                <br />
                Previously entered connection data will be lost.
              </>
            }
            isOpen={confirmSeekerChangeOpen}
            onClose={() => setConfirmSeekerChangeOpen(false)}
            onYes={() => handleSelectSeeker()}
            noText="Cancel"
            centerVertically={true}
            size="m"
          />

          {recentConnection && (
            <RecentlyReportedConnectionModal
              isOpen={
                recentlyReportedConnectionModalOpen && !recentConnectionLoading
              }
              onClose={() => setRecentlyReportedConnectionModalOpen(false)}
              recentConnection={recentConnection}
            />
          )}
        </LoadingOverlay>
      </Layout.AscendModalBody>
    </Layout.AscendModal>
  );
}
