import React, {createRef, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import infoBlueCircleSvg from '../../assets/images/info-blue-circle.svg';

export const AscendCheckGroupField = ({
  options,
  name,
  inputValue,
  onChange,
  dataPlace,
  meta: {touched, error, warning},
}) => {
  const [noPrefId, setNoPrefId] = useState();
  const tipRefs = [];

  useEffect(() => {
    const item = options.find((x) => x.label === 'No Preference');
    if (item) {
      setNoPrefId(item.value);
    }
  }, [options]);

  return (
    <div
      style={{width: '100%'}}
      className="padding-bottom-eight custom-control custom-checkbox ps-4"
    >
      <ReactTooltip
        id="tooltip"
        className="solid-tooltip-dark"
        clickable={true}
        closeOnEsc={true}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <div className="d-flex flex-column" style={{width: '100%'}}>
          {options.map(({label, value, tips, id}, index) => {
            tipRefs.push(createRef());
            const handleCheck = (event) => {
              if (value === noPrefId && event.target.checked) {
                const newSelectedList = [];
                newSelectedList.push(value);
                onChange(newSelectedList);
                return true;
              }

              const selected = [...inputValue];

              if (selected.some((z) => z === noPrefId)) {
                selected.splice(selected.indexOf(noPrefId), 1);
              }

              if (event.target.checked) {
                selected.push(value);
              } else {
                selected.splice(selected.indexOf(value), 1);
              }

              return onChange(selected);
            };

            return (
              <div
                key={index}
                style={{width: '100%', textAlign: 'left'}}
                className="padding-bottom-eight custom-control custom-checkbox form-check mb-0"
              >
                <input
                  type="checkbox"
                  id={`${index}${id}`}
                  name={name}
                  value={value}
                  onChange={handleCheck}
                  checked={inputValue.includes(value)}
                  className="custom-control-input form-check-input checkbox-formatting"
                />
                <label
                  className="custom-control-label form-check-label ps-2 mb-0"
                  htmlFor={`${index}${id}`}
                  style={{float: 'left'}}
                >
                  {label}&nbsp;
                </label>
                {tips && (
                  <button
                    type="button"
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                    }}
                  >
                    <img
                      ref={(ref) => {
                        tipRefs[index].current = ref;
                      }}
                      className="clickable"
                      style={{
                        width: '1.25rem',
                        float: 'left',
                        marginTop: '4px',
                      }}
                      alt={tips}
                      src={infoBlueCircleSvg}
                      data-tooltip-id="tooltip"
                      data-tooltip-content={tips}
                      data-tooltip-place={dataPlace}
                    />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {touched &&
        ((error && <div className="form-input-error">{error}</div>) ||
          (warning && <div className="form-input-error">{warning}</div>) || (
            <div className="form-input-error">&nbsp;</div>
          ))}
      {!touched && <div className="form-input-error">&nbsp;</div>}
    </div>
  );
};
AscendCheckGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object,
  inputValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      tips: PropTypes.string,
    })
  ).isRequired,
  dataPlace: PropTypes.string,
};
