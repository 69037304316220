import React from 'react';
import PropTypes from 'prop-types';
import Mark from '../components/Mark';
import {withRouter} from 'src/utils/commonHooks';
import {connect} from 'react-redux';
import SkillsModal from './SkillsModal';
import * as seekersActions from 'src/actions/seekers';
import * as seekerActionsAsync from '../actions/SeekerActions';

class OverallMatchWidget extends React.Component {
  state = {
    skillsModalIsOpen: false,
    seekerId: null,
  };

  componentDidMount() {
    const {loggedInSeekerId} = this.props;

    let seekerId = null;
    if (loggedInSeekerId) {
      seekerId = loggedInSeekerId;
    } else {
      return;
    }
    this.setState({seekerId: seekerId});

    if (seekerId && !this.props.seekerLoading && !this.props.seeker) {
      this.props.getSeekerAction(seekerId);
    }
  }

  handleSkillsSubmit = async (values) => {
    const {updateSeekerAction, seeker} = this.props;

    const updatedSeeker = {
      ...seeker,
      ...values,
    };
    if (!updatedSeeker.id) {
      return;
    }

    await updateSeekerAction(updatedSeeker);
    this.setState({skillsModalIsOpen: false});
  };

  render() {
    var fitText = 'Not a strong fit for this role';
    var fitColor = '#dde7ee';
    if (this.props.overallFit === true) {
      fitText = 'Strong fit for this role';
      fitColor = '#FF5F8D';
    }
    return (
      <>
        {!this.props.isRecruiter && (
          <SkillsModal
            isOpen={this.state.skillsModalIsOpen}
            onClose={() => {
              this.setState({skillsModalIsOpen: false});
            }}
            selectedSkills={
              this.props.seeker.skillTags ? this.props.seeker.skillTags : []
            }
            onChange={(selectedSkills) => {
              this.props.interviewSkillsChanged(selectedSkills);
            }}
            onSubmit={this.handleSkillsSubmit}
          />
        )}

        <div>
          <table style={{textAlign: 'center', height: '100%', width: '100%'}}>
            <tbody>
              <tr>
                <td style={{width: '25%', verticalAlign: 'middle'}}>
                  <div>
                    <Mark color={fitColor} width="38px" height="48px" />
                  </div>
                </td>
                <td style={{width: '50%', verticalAlign: 'middle'}}>
                  <div className="secondary-body bold padding-top-eight">
                    Overall Match
                  </div>
                  <hr className="red-bar" />
                  <div className="normal-caption padding-bottom-eight nowrap">
                    {fitText}
                  </div>
                </td>
                <td style={{width: '25%', verticalAlign: 'middle'}}>
                  <div></div>
                </td>
              </tr>
              <tr>
                <td style={{width: '25%', verticalAlign: 'middle'}}>
                  <div></div>
                </td>
                {!this.props.isRecruiter && (
                  <td>
                    <button
                      onClick={() => this.setState({skillsModalIsOpen: true})}
                      className={`btn buttonAsLink bold`}
                      style={{width: 'max-content'}}
                    >
                      Update Skills
                    </button>{' '}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

OverallMatchWidget.propTypes = {
  overallFit: PropTypes.bool.isRequired,
  loggedInSeekerId: PropTypes.string,
  seeker: PropTypes.object,
  seekerLoading: PropTypes.bool.isRequired,
  interviewSkillsChanged: PropTypes.func.isRequired,
  updateSeekerAction: PropTypes.func.isRequired,
  getSeekerAction: PropTypes.func.isRequired,
  isRecruiter: PropTypes.bool,
};

OverallMatchWidget.defaultProps = {isRecruiter: false};

const mapStateToProps = (state) => {
  return {
    loggedInSeekerId: state.profile.seekerId,
    seeker: state.seekers.seeker,
    seekerLoading: state.seekers.seekerLoading,
  };
};

const mapDispatchToProps = {
  ...seekersActions,
  ...seekerActionsAsync,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverallMatchWidget)
);
